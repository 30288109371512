import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FloorTypeService } from 'src/app/service/floor-type/floor-type.service';
import { NotifierService } from 'angular-notifier';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-delete-floor-type',
  templateUrl: './delete-floor-type.component.html',
  styleUrls: ['./delete-floor-type.component.scss']
})
export class DeleteFloorTypeComponent implements OnInit {

  private floorTypeId: number;
  private sub = new SubSink();
  @Output() floorTypeDeleted = new EventEmitter<boolean>();


  constructor(private floorTYpeService: FloorTypeService, private notifier: NotifierService) { }

  ngOnInit() {
  }


  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  deleteFloorType() {
    this.sub.add(this.floorTYpeService.deleteFloorType(this.floorTypeId)
      .subscribe(response => {
        this.updateTheView(true);
        this.notifier.notify('success', (response as any).message);
      }));
  }

  updateTheView(deleted: boolean) {
    this.floorTypeDeleted.emit(deleted);
  }

  setDeedNatureId(id: number) {
    this.floorTypeId = id;
  }


}






