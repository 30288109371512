export class NotificationDetails {
  subject:string;
  valuationRequestId:string;

  constructor(
    subject:string,
    valuationRequestId:string,
  ){
    this.subject = subject;
    this.valuationRequestId = valuationRequestId;
  }
}
