export class SendCollectionPayment{

  bank:string;
  branch:string;
  refNo:string;
  reciptNo:string;
  deedNo:string;
  amount:number;
  installmentNo:number;
  collectionOfficerName:string;
  opinionId:number;
  refundId:number;
  date:string;

  constructor(
    bank:string,
    branch:string,
    refNo:string,
    reciptNo:string,
    deedNo:string,
    amount:number,
    installmentNo:number,
    collectionOfficerName:string,
    opinionId:number,
    refundId:number,
    date:string,
  ){
    this.bank = bank;
    this.branch = branch;
    this.refNo = refNo;
    this.reciptNo =reciptNo;
    this.deedNo = deedNo;
    this.amount = amount;
    this.installmentNo = installmentNo;
    this.collectionOfficerName = collectionOfficerName;
    this.opinionId = opinionId;
    this.refundId = refundId;
    this.date = date;
  }

}
