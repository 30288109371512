export class InspectionSubPropertyModel{

  id:number;
  name:string;
  description:string;
  inspectionPropertyType:number;
  dataStatus:string;


  constructor(
    id:number,
    name:string,
    description:string,
    inspectionPropertyType:number,
    dataStatus:string,
  ){
    this.id = id;
    this.name = name;
    this.description = description;
    this.inspectionPropertyType = inspectionPropertyType;
    this.dataStatus = dataStatus;
  }
}
