import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { ReportServiceService } from "../service/report-service.service";
import { NotifierService } from "angular-notifier";
import * as XLSX from "xlsx";

@Component({
	selector: 'app-assessor-distribution-report',
	templateUrl: './assessor-distribution-report.component.html',
	styleUrls: ['./assessor-distribution-report.component.scss']
})
export class AssessorDistributionReportComponent implements OnInit, AfterViewInit {

	selectedItemsLA = [];
	dropdownListLA = [];

	selectedItemsASS = [];
	dropdownListASS = [];

	selectedItemsARB = [];
	dropdownListARB = [];

	selectedItemsND = [];
	dropdownListND = [];

	public inputs: any = {};

	dropdownSettingsASS: IDropdownSettings = {};
	dropdownSettingsLA: IDropdownSettings = {};
	dropdownSettingsARB: IDropdownSettings = {};
	dropdownSettingsND: IDropdownSettings = {};

	public showPrint: boolean = false;

	fromDate: string = null;
	toDate: string = null;
	nature_of_deed: string = null;
	totalOpinion: number = 0;
	totalDeclare: number = 0;
	totalDifference: number = 0;
	totalOpinionStamp: number = 0;
	totalDeclareStamp: number = 0;
	totalDifferenceStamp: number = 0;

	public selectedDetails: any;

	public reportDetails: any = [];

	constructor(public modalService: NgbModal,
		public reportService: ReportServiceService,
		private notifier: NotifierService) { }

	ngOnInit() {

		this.dropdownSettingsASS = {
			singleSelection: false,
			idField: 'id',
			textField: 'name',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};


		this.dropdownSettingsLA = {
			singleSelection: false,
			idField: 'id',
			textField: 'localAuthority',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		this.dropdownSettingsARB = {
			singleSelection: false,
			idField: 'id',
			textField: 'office',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		this.dropdownSettingsND = {
			singleSelection: false,
			idField: 'id',
			textField: 'name',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		this.get_all_local_authority();
		this.get_all_allocated_branches();
		this.get_all_assessors();
		this.get_all_deeds();

	}

	ngAfterViewInit() {
		// this.get_report();
	}

	onItemSelectLA(item: any) {
		console.log(item);
	}
	onSelectAllLA(items: any) {
		console.log(items);
	}

	download_excel() {
		this.get_excel_report();
	}

	captureInputs() {
		// validate from date
		/*if (this.fromDate == null) {
			this.notifier.notify('error', 'Please enter submit from date!');
			return false;
		}*/

		// validate to date
		/*if (this.toDate == null) {
			this.notifier.notify('error', 'Please enter submit to date!');
			return false;
		}*/

		// capture local authorities data
		var selectedLA_id = "";
		var selectedLA_name = "";
		if (this.selectedItemsLA != null) {
			this.selectedItemsLA.forEach(value => {
				selectedLA_id += value.id + ",";
				selectedLA_name += value.localAuthority + ", ";
			});

			selectedLA_id = selectedLA_id.slice(0, -1);
			selectedLA_name = selectedLA_name.slice(0, -1);

			if (this.dropdownListLA != null && this.dropdownListLA.length == this.selectedItemsLA.length) {
				selectedLA_name = "All";
			}
		}

		// capture branches data
		var selectedARB_id = "";
		var selectedARB_name = "";
		if (this.selectedItemsARB != null) {
			this.selectedItemsARB.forEach(value => {
				selectedARB_id += value.id + ",";
				selectedARB_name += value.office + ",";
			});

			selectedARB_id = selectedARB_id.slice(0, -1);
			selectedARB_name = selectedARB_name.slice(0, -1);

			if (this.dropdownListARB != null && this.dropdownListARB.length == this.selectedItemsARB.length) {
				selectedARB_name = "All";
			}
		}

		// capture assessor data
		var selectedASS_id = "";
		var selectedASS_name = "";
		if (this.selectedItemsASS != null) {
			this.selectedItemsASS.forEach(value => {
				selectedASS_id += "'" + value.id + "',";
				selectedASS_name += value.name + ", ";
			});

			selectedASS_id = selectedASS_id.slice(0, -1);
			selectedASS_name = selectedASS_name.slice(0, -1);

			if (this.dropdownListASS != null && this.dropdownListASS.length == this.selectedItemsASS.length) {
				selectedASS_name = "All";
			}
		}

		// capture deeds data
		var selectedND_id = "";
		var selectedND_name = "";
		if (this.selectedItemsND != null) {
			this.selectedItemsND.forEach(value => {
				selectedND_id += "" + value.name + ",";
				selectedND_name += value.name + ", ";
			});

			selectedND_id = selectedND_id.slice(0, -1);
			selectedND_name = selectedND_name.slice(0, -1);

			if (this.dropdownListND != null && this.dropdownListND.length == this.selectedItemsND.length) {
				selectedND_name = "All";
			}
		}

		this.inputs = {
			fromDate: this.fromDate,
			toDate: this.toDate,
			branchIds: selectedARB_id,
			localAuthorityIds: selectedLA_id,
			assessorIds: selectedASS_id,
			deedIds: selectedND_id,
			branchNames: selectedARB_name,
			localAuthorityNames: selectedLA_name,
			assessorNames: selectedASS_name,
			deedNames: selectedND_name
		}

		return true;
	}

	get_excel_report() {
    if (this.fromDate == null) {
      this.notifier.notify('error', 'Please enter submit from date!');
      return false;
    }
		if (this.captureInputs()) {
			this.reportService.getAssessorDistributionReport(this.inputs.fromDate, this.inputs.toDate, this.inputs.localAuthorityIds, this.inputs.assessorIds, this.inputs.deedIds, this.inputs.branchIds).subscribe(value => {
				if (value.data != null && value.data.data != null && value.data.data.length > 0) {
					this.reportDetails = value.data.data;
					this.totalOpinion = value.data.totalOpinion;
					this.totalDeclare = value.data.totalDeclare;
					this.totalDifference = value.data.totalDifference;
					this.totalOpinionStamp = value.data.totalOpinionStamp;
					this.totalDeclareStamp = value.data.totalDeclareStamp;
					this.totalDifferenceStamp = value.data.totalAdditionalStamp;

					setTimeout(() => {
						var printContents = document.getElementById('excel_print');
						const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(printContents);
            Object.keys(ws).forEach(function(s){
              if(ws[s].t === 'n'){
                ws[s].z = '0';
                ws[s].t = 's';
              }
            });
						const wb: XLSX.WorkBook = XLSX.utils.book_new();
						XLSX.utils.book_append_sheet(wb, ws, 'Assessor Distribution');
						XLSX.writeFile(wb, 'Assessor Distribution Report.xlsx');
					}, 1000);

				} else {
					this.notifier.notify('error', 'No records found!');
					console.log("No data found");
				}
			});
		}
	}

	onItemSelectASS(item: any) {
		console.log(item);
	}
	onSelectAllASS(items: any) {
		console.log(items);
	}

	onItemSelectARB(item: any) {
		console.log(item);
	}
	onSelectAllARB(items: any) {
		console.log(items);
	}

	closeFurtherReqRemark() {
		this.modalService.dismissAll();
	}

	get_report() {
    let dataLength = 0;
    // if (this.fromDate == null) {
    //   this.notifier.notify('error', 'Please enter submit from date!');
    //   return false;
    // }
		if (this.captureInputs()) {
		//	console.log(this.inputs);
			this.reportService.getAssessorDistributionReport(this.inputs.fromDate, this.inputs.toDate, this.inputs.localAuthorityIds, this.inputs.assessorIds, this.inputs.deedIds, this.inputs.branchIds).subscribe(value => {
        dataLength = value.data.data.length;
				if (value.data != null && value.data.data != null && value.data.data.length > 0) {
					this.reportDetails = value.data.data;
					this.totalOpinion = value.data.totalOpinion;
					this.totalDeclare = value.data.totalDeclare;
					this.totalDifference = value.data.totalDifference;
					this.totalOpinionStamp = value.data.totalOpinionStamp;
					this.totalDeclareStamp = value.data.totalDeclareStamp;
					this.totalDifferenceStamp = value.data.totalAdditionalStamp;
					this.showPrint = true;

				setTimeout(() => {
					this.printReceipt();
				}, 1000);

				}else{
				  this.notifier.notify('error', 'No records found!');
				  console.log("No data found");
				}
			});
		}
    return dataLength;
	}



	get_all_local_authority() {
		this.reportService.getAllLA().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListLA = value.data;
			} else {
				console.log("No Local authority return");
			}
		});
	}

	get_all_deeds() {
		this.reportService.getAllDeedNature().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListND = value.data;
			} else {
				console.log("No Nature of Deed return");
			}
		});
	}


	get_all_allocated_branches() {
		this.reportService.getAllAB().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListARB = value.data;
			} else {
				console.log("No Allocated Regional Branch return");
			}
		});
	}

	get_all_assessors() {
		this.reportService.getAllASS().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListASS = value.data;
			} else {
				console.log("No Allocated Regional Branch return");
			}
		});
	}

	printReceipt(): void {
		this.showPrint = true;
		let printContents: any;
		let popupWin: any;
		printContents = document.getElementById('assessorDistributionReportTemplate').innerHTML;

		popupWin = window.open();
		popupWin.document.open();
		popupWin.document.write(`
    <html>
    <head>
        <title>Assessor Distribution Report</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <!--<script src="../../../../assets/js/paged.js"></script>-->
        <!--<script src="../../../../assets/js/paged.polyfill.js"></script>-->
        <style>
          @page {
            size: A3 landscape;
            @bottom-right {
              content: "Page " counter(page) " of " counter(pages);
            }
            .page{
                page-break-after: always;
            }
          }

            body
            {
                margin: 10mm 10mm 0mm 10mm;

            }
            table{
              border: 1px solid #000;
              border-collapse: collapse;
            }
            tbody{
              page-break-before: always;
            }

            table th, table td
            {
                padding: 5px;
                margin: 0px;
                border: 1px solid #000;
            }
            .page{
                page-break-after: always;
            }

        </style>
    </head>
    <body onload="window.print();">
            ${printContents}
    </body>
    </html>`
		);
		popupWin.document.close();

		// this.printPreview.emit(false);
	}




}
