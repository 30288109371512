import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import { environment } from 'src/environments/environment';
import { District } from 'src/app/model/district';
import { tap, catchError } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import {IDistrictsPage} from '../../model/interfaces/districts-page';
import { getPaymentRecipt } from 'src/app/model/get-paymment-recipt';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  valuationRequestUrl = '/valuation-request'
  refundRequestUrl = '/refund-opinion';
  reimbursementApplicationUrl ='/reimbursement-application';
  rimAccountPaymentUrl ='/rim-account-payment';
  collection_url = "/collection";


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };


  constructor(private http: HttpClient, private notifier: NotifierService) { }

  checkForFiles(valuationRequestID: any, fileType: string) {
    return this.http.get<any>(environment.api_url + '/valuation-request/' + valuationRequestID + '/' + fileType);
  }

  checkForFilesByValuationRequestId(valuationRequestId: number) {
    return this.http.get<any>(environment.api_url + '/documents/' + valuationRequestId);
  }

  loadFileById(id, type): Observable<any> {
    return this.http.get<any>(environment.api_url + '/valuation-request/' + id + '/file/' + type);
  }

  loadFileByIdAndName(getPaymentRecipts:getPaymentRecipt): Observable<any>{
    return this.http.post<any>(environment.api_url + '/valuation-request/file-and-name/',getPaymentRecipts);
  }

  loadDocumentsByHistoryId(historyId, documentTitle) {
    return this.http.get<any>(`${environment.api_url}${this.valuationRequestUrl}` + '/' + historyId + '/historyFile/' + documentTitle);
  }

  loadDocumentsByRefundRequestId(refundRequestId, documentTitle) {
    return this.http.get<any>(`${environment.api_url}${this.refundRequestUrl}` + '/' + refundRequestId + '/file/' + documentTitle);
  }

  loadDocumentsByReimbursementApplicationId(reimbursementId, documentTitle) {
    return this.http.get<any>(`${environment.api_url}${this.reimbursementApplicationUrl}` + '/' + reimbursementId + '/file/' + documentTitle);
  }

  loadDocumentsByCollectionPaymentId(paymentId, documentTitle) {
    return this.http.get<any>(`${environment.api_url}${this.collection_url}` + '/' + paymentId + '/file/' + documentTitle);
  }

  loadDocumentByRimPaymentId(paymentId, documentTitle){
    return this.http.get<any>(`${environment.api_url}${this.reimbursementApplicationUrl}${this.rimAccountPaymentUrl}` + '/' + paymentId + '/file/' + documentTitle);
  }

}
