import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PropertyFacilityService } from 'src/app/service/property-facility/property-facility.service';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-delete-property-facility',
  templateUrl: './delete-property-facility.component.html',
  styleUrls: ['./delete-property-facility.component.scss']
})
export class DeletePropertyFacilityComponent implements OnInit, OnDestroy {

  private propertyFacilityId: number;

  private sub = new SubSink();

  @Output() paymentMethodDeleted = new EventEmitter<boolean>();

  constructor(private propertyFacilityService: PropertyFacilityService, private notifier: NotifierService) { }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  deletePropertyFacility() {
    this.sub.add(this.propertyFacilityService.deletePropertyFacility(this.propertyFacilityId)
      .subscribe(response => {

        this.updateTheView(true);
        this.notifier.notify('success', (response as any).message);
      }));
  }



  updateTheView(deleted: boolean) {
    this.paymentMethodDeleted.emit(deleted);
  }

  setPropertyFacilityId(id: number) {
    this.propertyFacilityId = id;
  }

}
