import { Injectable } from '@angular/core';
import { ValuationRequest } from '../../model/valuation-request';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { ValuationRequestExchange } from '../../model/valuation-request-exchange';
import { IValuationRequestPage } from '../../model/interfaces/valuation-request-page';
import { IValuationRequest } from '../../model/interfaces/valuation-request';
import { IValuationRequestResponse } from '../../model/interfaces/ValuationRequestResponse';
import { INotary } from '../../model/interfaces/notary';
import { ValuationUpdate } from '../../model/valuationUpdate';
import { NotaryDetails } from '../../model/notary-details';
import { INotaryDetailsResponse } from '../../model/interfaces/notary-response';
import { Router } from '@angular/router';
import { ValuationRequestUpdate } from 'src/app/model/valuation-request-update';
import { ValuationRequestList } from 'src/app/model/valuation_request_list';
import { PermissionLetter } from 'src/app/model/permission-letter';
import { SiteVisitSchedule } from 'src/app/model/siteVisitSchedule';
import { ValuationReject } from 'src/app/model/valuation-reject';
import { DeedDetail } from 'src/app/model/deed-detail';
import {IPaymentExternal} from '../../model/interfaces/paymentExternal';
import { ValuationRequestRemark } from 'src/app/model/valuation-request-remark';
import { SurveyModel } from 'src/app/model/survery';
import { FurtherRequirementAndReject } from 'src/app/model/further-requirement-and-reject';
import { ValuationRequestFurtherRequirmentAutoSave } from '../../model/valuationRequestFurtherRequirmentAutoSave';

@Injectable({
  providedIn: "root",
})
export class ValuationRequestService {
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };
  private valuationRequestUrl = "/valuation-request"; // URL to web api

  private audittraceUrl = "/audittrace"; // URL to web api

  private userRequestUrl = "/getnotaryregistered_details"; // URL to user api

  valuationRequestDocUploadUrl = "/documents";

  constructor(
    private http: HttpClient,
    private notifier: NotifierService,
    private router: Router
  ) {}

  updateValuationRequestStatus(
    valuationRequestId: number,
    valuationRequestStatus: string
  ): Observable<boolean> {
    return this.http.get<any>(
      `${environment.api_url}${this.valuationRequestUrl}/update-valuation-request-status/${valuationRequestId}/${valuationRequestStatus}`
    );
  }

  auditList(id: any): Observable<any> {
    return this.http.get<any>(
      `${environment.api_url}` + this.audittraceUrl + "/" + id
    );
  }

  valuationHistoryList(surveyModel: SurveyModel): Observable<any> {
    return this.http.get<any>(
      `${environment.api_url}` +
        this.valuationRequestUrl +
        "/previous-history/" +
        surveyModel.surveyPlanNo
    );
  }

  getAppealCount(valId: any): Observable<any> {
    return this.http.get<any>(
      environment.api_url +
        this.valuationRequestUrl +
        "/get-appeal-count/" +
        valId
    );
  }

  valuationRequestDeedDetail(deedDetail: DeedDetail): Observable<any> {
    return this.http.post<any>(
      `${environment.api_url}` +
        this.valuationRequestUrl +
        "/insert/deed_detail",
      deedDetail
    );
  }

  valuationRejectRequest(valuationReject: ValuationReject): Observable<any> {
    return this.http.post<any>(
      `${environment.api_url}` + this.valuationRequestUrl + "/reject",
      valuationReject
    );
  }

  valuationRejectIrrelevantRequest(
    valuationRejectIrrelevant: ValuationReject
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.api_url}` + this.valuationRequestUrl + "/rejectIrrelevant",
      valuationRejectIrrelevant
    );
  }

  valuationRequestFurtherRequirement(
    valuationRequestUpdate: ValuationRequestUpdate
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.api_url}` +
        this.valuationRequestUrl +
        "/furtherRequirement",
      valuationRequestUpdate
    );
  }

  sendFurtherDetails(valuationReqId: number): Observable<any> {
    return this.http.get<any>(
      environment.api_url +
        this.valuationRequestUrl +
        "/send-further-details/" +
        valuationReqId
    );
  }

  declineReject(valuationId: number): Observable<any> {
    return this.http.get<any>(
      environment.api_url +
        this.valuationRequestUrl +
        "/decline-reject/" +
        valuationId
    );
  }

  rejectApplication(
    rejectApplicationModel: FurtherRequirementAndReject
  ): Observable<any> {
    const formData = new FormData();
    formData.append("valuationId", rejectApplicationModel.valuationId);
    formData.append("userName", rejectApplicationModel.userName);
    formData.append("userRole", rejectApplicationModel.userRole);
    formData.append("remark", rejectApplicationModel.remark);
    formData.append(
      "furtherRequirementPosition",
      rejectApplicationModel.furtherRequirementPosition
    );
    if (rejectApplicationModel.uploadedFile != undefined) {
      formData.append(
        "uploadedFile",
        rejectApplicationModel.uploadedFile,
        rejectApplicationModel.uploadedFile.name
      );
    }

    // for (let i = 0; i < selectPathsCollection.length; i++) {
    //   for (let x = 0; x < selectPathsCollection[i].length; x++) {
    //     if (i === 0) {
    //       formData.append('deputyCommissionerDocuments', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
    //     }
    //   }
    // }

    return this.http
      .post<any>(
        `${environment.api_url}${this.valuationRequestUrl}/reject`,
        formData
      )
      .pipe(
        tap((_) => this.log(`Valuation Application reject`)),
        catchError((err) => {
          return throwError(err);
        }),
        catchError(this.handleError<any>("Valuation Application reject"))
      );
  }

  requestFurtherRequirement(
    furtherRequirementModel: FurtherRequirementAndReject
  ): Observable<any> {
    const formData = new FormData();
    formData.append("valuationId", furtherRequirementModel.valuationId);
    formData.append("userName", furtherRequirementModel.userName);
    formData.append("userRole", furtherRequirementModel.userRole);
    formData.append("remark", furtherRequirementModel.remark);
    formData.append(
      "furtherRequirementPosition",
      furtherRequirementModel.furtherRequirementPosition
    );
    if (furtherRequirementModel.uploadedFile != undefined) {
      formData.append(
        "uploadedFile",
        furtherRequirementModel.uploadedFile,
        furtherRequirementModel.uploadedFile.name
      );
    }

    // for (let i = 0; i < selectPathsCollection.length; i++) {
    //   for (let x = 0; x < selectPathsCollection[i].length; x++) {
    //     if (i === 0) {
    //       formData.append('deputyCommissionerDocuments', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
    //     }
    //   }
    // }

    return this.http
      .post<any>(
        `${environment.api_url}${this.valuationRequestUrl}/request/furtherRequirement`,
        formData
      )
      .pipe(
        tap((_) =>
          this.log(`Valuation Application request further requirement`)
        ),
        catchError((err) => {
          return throwError(err);
        }),
        catchError(
          this.handleError<any>(
            "Valuation Application request further requirement"
          )
        )
      );
  }

  valuationRequestAutoSaveFurtherRequirement(
    valuationRequest: ValuationRequestFurtherRequirmentAutoSave
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.api_url}` +
        this.valuationRequestUrl +
        "/auto-save/furtherRequirementV2",
      valuationRequest
    );
  }

  valuationRequestSubmitFurtherRequirement(
    valuationRequest: ValuationRequest
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.api_url}` +
        this.valuationRequestUrl +
        "/submit/furtherRequirementV2",
      valuationRequest
    );
  }

  valuationRequestUpdate(
    valuationRequestUpdate: ValuationRequestUpdate
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.api_url}` + this.valuationRequestUrl + "/update",
      valuationRequestUpdate
    );
  }

  valuationRequestList(
    valuationRequestList: ValuationRequestList
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.api_url}` + this.valuationRequestUrl + "/searchlist",
      valuationRequestList
    );
  }

  getValuationRequestList(
    userId: String,
    userType: String,
    status: String,
    offset: String,
    limit: String
  ): Observable<any> {
    return this.http.get<any>(
      environment.api_url +
        this.valuationRequestUrl +
        "/get-requests/" +
        userId +
        "/" +
        userType +
        "/" +
        status +
        "/" +
        offset +
        "/" +
        limit
    );
  }

  searchValuationRequestByDate(
    keyword: string,
    status: string,
    offset: number,
    limit: number,
    mode: string,
    userId: string,
    userType: string
  ): Observable<IValuationRequestPage> {
    return this.http
      .get<IValuationRequestPage>(
        `${environment.api_url}${this.valuationRequestUrl}/search/date?keyword=${keyword}&valuation_request_status=${status}&offset=${offset}&limit=${limit}&mode=${mode}&userId=${userId}&userType=${userType}`
      )
      .pipe(
        tap((_) => this.log(`valuation-request find by status = ${status}`)),
        catchError((err) => {
          this.notifier.notify("error", err);
          return throwError(err);
        }),
        catchError(this.handleError<any>("valuation-request find by status"))
      );
  }

  appealValuationRequestList(
    valuationRequestList: ValuationRequestList
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.api_url}` +
        this.valuationRequestUrl +
        "/appeal/searchlist",
      valuationRequestList
    );
  }

  valuationRequestAssigned(id: any): Observable<any> {
    return this.http.get<any>(
      `${environment.api_url}` +
        this.valuationRequestUrl +
        "/assignedvaluation/" +
        id
    );
  }

  getDeedDetails(id: any): Observable<any> {
    return this.http.get<any>(
      `${environment.api_url}` +
        this.valuationRequestUrl +
        "/get-last-deed-details/" +
        id
    );
  }

  getStampDuty(id: any): Observable<any> {
    return this.http.get(
      environment.api_url + this.valuationRequestUrl + "/stamp-duty/" + id
    );
  }

  getPropertyValue(id: any): Observable<any> {
    return this.http.get(
      environment.api_url + this.valuationRequestUrl + "/property-value/" + id
    );
  }

  checkboxValuationRequestStatus(id: any): Observable<any> {
    return this.http.get<any>(
      `${environment.api_url}` +
        this.valuationRequestUrl +
        "/checkboxstatus/" +
        id
    );
  }

  uploadPaymentDocuments(
    selectPaths: any[],
    valuationRequestId: string
  ): Observable<any> {
    const formData = new FormData();
    // formData.append('paymentDoc', selectPaths[0], selectPaths[0].name);
    formData.append("valuationRequestId", valuationRequestId);
    for (let i = 0; i < selectPaths.length; i++) {
      for (let x = 0; x < selectPaths[i].length; x++) {
        if (i === 0) {
          formData.append("deedDoc", selectPaths[i][x], selectPaths[i][x].name);
        }
      }
    }
    // return  this.http.post<any>(`${environment.api_url}/valuation-request${this.valuationRequestDocUploadUrl}`, formData)
    //   .pipe(tap(_ => this.log(`upload valuation request Documents=${selectPaths}`)),
    //     catchError(err => {
    //       return throwError(err);
    //     }),
    //     catchError(this.handleError<any>('upload valuation request Documents'))
    //   );

    return this.http
      .post<any>(
        `${environment.api_url}/valuation-request/document-payment`,
        formData
      )
      .pipe(
        tap((_) =>
          this.log(`upload valuation request Documents=${selectPaths}`)
        ),
        catchError((err) => {
          return throwError(err);
        }),
        catchError(this.handleError<any>("upload valuation request Documents"))
      );
  }

  uploadRejectApplicationDocuments(
    selectPaths: any[],
    valuationRequestId: string
  ): Observable<any> {
    const formData = new FormData();
    formData.append("file", selectPaths[0], selectPaths[0].name);
    formData.append("data", valuationRequestId);

    return this.http
      .post<any>(
        `${environment.api_url}${this.valuationRequestDocUploadUrl}/reject-document`,
        formData
      )
      .pipe(
        tap((_) =>
          this.log(`upload valuation request Documents=${selectPaths}`)
        ),
        catchError((err) => {
          return throwError(err);
        }),
        catchError(this.handleError<any>("upload valuation request Documents"))
      );
  }

  createPermissionLetter(permissionLetter: PermissionLetter): Observable<any> {
    return this.http
      .post<any>(
        `${environment.api_url}${this.valuationRequestUrl}/permissionLetter`,
        JSON.stringify(permissionLetter),
        this.httpOptions
      )
      .pipe(
        tap((_) => this.log(`valuation-request create=${permissionLetter}`)),
        catchError((err) => {
          this.notifier.notify("error", err);
          return throwError(err);
        }),
        catchError(this.handleError<any>("valuation-request creation Error"))
      );
  }

  findPermissionLettersByValuationRequest(
    valuationRequestId: number
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.api_url}${this.valuationRequestUrl}/permissionLetters/${valuationRequestId}`
    );
  }

  createSiteVisitSchedule(
    siteVisitSchedule: SiteVisitSchedule
  ): Observable<any> {
    return this.http
      .post<any>(
        `${environment.api_url}${this.valuationRequestUrl}/siteVisitScheduler`,
        JSON.stringify(siteVisitSchedule),
        this.httpOptions
      )
      .pipe(
        tap((_) => this.log(`valuation-request create=${siteVisitSchedule}`)),
        catchError((err) => {
          this.notifier.notify("error", err);
          return throwError(err);
        }),
        catchError(this.handleError<any>("valuation-request creation Error"))
      );
  }

  createValuationRequestRemark(
    valuationRequestRemark: ValuationRequestRemark
  ): Observable<any> {
    return this.http
      .post<any>(
        `${environment.api_url}${this.valuationRequestUrl}/valuationRequestRemark`,
        JSON.stringify(valuationRequestRemark),
        this.httpOptions
      )
      .pipe(
        tap((_) =>
          this.log(`valuation-request create=${valuationRequestRemark}`)
        ),
        catchError((err) => {
          this.notifier.notify("error", err);
          return throwError(err);
        }),
        catchError(this.handleError<any>("valuation-request creation Error"))
      );
  }

  /** GET valuation-request find by status */
  findValuationRequestInternalUserByStatus(
    status: string,
    offset: number,
    limit: number
  ): Observable<IValuationRequestPage> {
    return this.http
      .get<IValuationRequestPage>(
        `${environment.api_url}${this.valuationRequestUrl}/search?valuation_request_status=${status}&offset=${offset}&limit=${limit}`
      )
      .pipe(
        tap((_) => this.log(`valuation-request find by status = ${status}`)),
        catchError((err) => {
          this.notifier.notify("error", err);
          return throwError(err);
        }),
        catchError(this.handleError<any>("valuation-request find by status"))
      );
  }

  /** GET valuation-request find by status */
  findAppealValuationRequestInternalUserByStatus(
    status: string,
    offset: number,
    limit: number
  ): Observable<IValuationRequestPage> {
    return this.http
      .get<IValuationRequestPage>(
        `${environment.api_url}${this.valuationRequestUrl}/appeal/search?valuation_request_status=${status}&offset=${offset}&limit=${limit}`
      )
      .pipe(
        tap((_) => this.log(`valuation-request find by status = ${status}`)),
        catchError((err) => {
          this.notifier.notify("error", err);
          return throwError(err);
        }),
        catchError(this.handleError<any>("valuation-request find by status"))
      );
  }

  /** POST valuation-request to the server */
  createValuationRequest(valuationRequest: ValuationRequest): Observable<any> {
    sessionStorage.setItem(
      "valuationRequest",
      JSON.stringify(valuationRequest)
    );
    console.log("Valuation Req : " + JSON.stringify(valuationRequest));

    return this.http
      .post<any>(
        `${environment.api_url}${this.valuationRequestUrl}`,
        JSON.stringify(valuationRequest),
        this.httpOptions
      )
      .pipe(
        tap((_) => this.log(`valuation-request create=${valuationRequest}`)),
        catchError((err) => {
          this.notifier.notify("error", err);
          return throwError(err);
        }),
        catchError(this.handleError<any>("valuation-request creation Error"))
      );
  }

  /** POST valuation-request exchange to the server */
  createValuationRequestExchange(
    valuationRequestExchange: ValuationRequestExchange
  ): Observable<any> {
    return this.http
      .post<any>(
        `${environment.api_url}${this.valuationRequestUrl}/exchange`,
        JSON.stringify(valuationRequestExchange),
        this.httpOptions
      )
      .pipe(
        tap((_) =>
          this.log(
            `valuation-request exchange create=${valuationRequestExchange}`
          )
        ),
        catchError((err) => {
          this.notifier.notify("error", err);
          return throwError(err);
        }),
        catchError(this.handleError<any>("valuation-request exchange create"))
      );
  }

  /** GET valuation-request find by status */
  findValuationRequestByStatus(
    status: string,
    offset: number,
    limit: number,
    mode: string
  ): Observable<IValuationRequestPage> {
    return this.http
      .get<IValuationRequestPage>(
        `${environment.api_url}${this.valuationRequestUrl}/search?valuation_request_status=${status}&offset=${offset}&limit=${limit}&mode=${mode}`
      )
      .pipe(
        tap((_) => this.log(`valuation-request find by status = ${status}`)),
        catchError((err) => {
          this.notifier.notify("error", err);
          return throwError(err);
        }),
        catchError(this.handleError<any>("valuation-request find by status"))
      );
  }

  /** GET valuation-request find by status */
  findValuationRequest(
    status: string,
    offset: number,
    limit: number,
    mode: string
  ): Observable<IValuationRequestResponse> {
    return this.http
      .get<IValuationRequestResponse>(
        `${environment.api_url}${this.valuationRequestUrl}/all?valuation_request_status=${status}&offset=${offset}&limit=${limit}&mode=${mode}`
      )
      .pipe(
        tap((_) => this.log(`valuation-request find by status = ${status}`)),
        catchError((err) => {
          this.notifier.notify("error", err);
          return throwError(err);
        }),
        catchError(this.handleError<any>("valuation-request find by status"))
      );
  }

  /** GET valuation-request find by Id */
  findByValuationRequestId(
    valuationRequestId: number
  ): Observable<IValuationRequestResponse> {
    return this.http
      .get<IValuationRequestResponse>(
        `${environment.api_url}${this.valuationRequestUrl}/${valuationRequestId}`
      )
      .pipe(
        tap((_) =>
          this.log(`valuation-request find by id = ${valuationRequestId}`)
        ),
        catchError((err) => {
          this.notifier.notify("error", err);
          return throwError(err);
        }),
        catchError(this.handleError<any>("valuation-request find by id"))
      );
  }

  /** GET valuation-request-payment find by Id */
  findByValuationRequestIdPayment(
    valuationRequestId: number
  ): Observable<IPaymentExternal> {
    return this.http
      .get<IPaymentExternal>(
        `${environment.api_url}/payment/${valuationRequestId}`
      )
      .pipe(
        tap((_) =>
          this.log(`valuation-request find by id = ${valuationRequestId}`)
        ),
        catchError((err) => {
          this.notifier.notify("error", err);
          return throwError(err);
        }),
        catchError(this.handleError<any>("valuation-request find by id"))
      );
  }

  setValuatationForValuationRequest(
    valuationRequestId: number,
    valuationUpdate: ValuationUpdate
  ): Observable<any> {
    console.log(valuationRequestId + " = " + valuationUpdate);
    return this.http
      .post<any>(
        `${environment.api_url}${this.valuationRequestUrl}/${valuationRequestId}/valuation`,
        JSON.stringify(valuationUpdate),
        this.httpOptions
      )
      .pipe(
        tap((_) =>
          this.log(
            `make valuation for valuation request id =${valuationRequestId}`
          )
        ),
        catchError((err) => {
          this.notifier.notify("error", err);
          return throwError(err);
        }),
        catchError(
          this.handleError<any>("make valuation for valuation request")
        )
      );
  }

  // Update valuation request
  setPaymentDetailsForValuationRequest(
    valuationRequestId: number,
    valuationRequest: ValuationRequest
  ): Observable<any> {
    return this.http
      .post<any>(
        `${environment.api_url}${this.valuationRequestUrl}/${valuationRequestId}/updatePayment`,
        JSON.stringify(valuationRequest),
        this.httpOptions
      )
      .pipe(
        tap((_) => this.log(`valuation-request create=${ValuationUpdate}`)),
        catchError((err) => {
          this.notifier.notify(
            "error",
            "Error occurred in payment gateway ,Check your request in view assessments "
          );
          this.router.navigate(["/view-assessment/all-request"]);
          return throwError(err);
        }),
        catchError(this.handleError<any>("valuation-request creation Error"))
      );
  }

  setValuationPaymentDetailsForValuationRequest(
    valuationRequestId: number,
    valuationRequest: ValuationRequest
  ): Observable<any> {
    console.log(valuationRequestId);
    return this.http
      .post<any>(
        `${environment.api_url}${this.valuationRequestUrl}/${valuationRequestId}/updateValuationPayment`,
        JSON.stringify(valuationRequest),
        this.httpOptions
      )
      .pipe(
        tap((_) => this.log(`valuation-request create=${ValuationUpdate}`)),
        catchError((err) => {
          this.notifier.notify(
            "error",
            "Error occurred in payment gateway ,Check your request in view assessments "
          );
          this.router.navigate(["/view-assessment/all-request"]);
          return throwError(err);
        }),
        catchError(this.handleError<any>("valuation-request creation Error"))
      );
  }

  // GET Notary Details for valuation request
  // getNotaryDetailsByUserID(notaryuserID: string): Observable<IValuationRequestResponse> {
  //   return this.http.get<INotary>(`${environment.user_api_url}${this.userRequestUrl}/notary?user_id=${notaryuserID}`)
  //     .pipe(tap(_ => this.log(`Notary details by id = ${notaryuserID}`)),
  //       catchError(err => {
  //         this.notifier.notify('error', err);
  //         return throwError(err);
  //       }),
  //       catchError(this.handleError<any>('find notary by user id')));
  // }

  getNotaryDetailsByUserID(id: any): Observable<any> {
    return this.http.get<any>(
      `${environment.api_url}` +
        this.valuationRequestUrl +
        "/notary-details/" +
        id
    );
  }

  isRequestValidApprovedRequest(id: any): Observable<any> {
    return this.http.get<any>(
      `${environment.api_url}` +
        this.valuationRequestUrl +
        "/is-request-calculation-completed/" +
        id
    );
  }

  getNotaryDetails(notaryUserID: string): Observable<INotaryDetailsResponse> {
    return this.http
      .get<INotary>(
        `${environment.user_api_url}${this.userRequestUrl}/notary?user_id=${notaryUserID}`
      )
      .pipe(
        tap((_) => this.log(`Notary details by id = ${notaryUserID}`)),
        catchError((err) => {
          this.notifier.notify("error", err);
          return throwError(err);
        }),
        catchError(this.handleError<any>("find notary by user id"))
      );
  }

  /** GET valuation-request find by status */
  searchValuationRequest(
    keyword: string,
    status: string,
    offset: number,
    limit: number,
    mode: string
  ): Observable<IValuationRequestPage> {
    return this.http
      .get<IValuationRequestPage>(
        `${environment.api_url}${this.valuationRequestUrl}/search/keyword?keyword=${keyword}&valuation_request_status=${status}&offset=${offset}&limit=${limit}&mode=${mode}`
      )
      .pipe(
        tap((_) => this.log(`valuation-request find by status = ${status}`)),
        catchError((err) => {
          this.notifier.notify("error", err);
          return throwError(err);
        }),
        catchError(this.handleError<any>("valuation-request find by status"))
      );
  }

  searchValuationRequestByString(
    keyword: string,
    status: string,
    offset: number,
    limit: number,
    mode: string
  ): Observable<IValuationRequestPage> {
    return this.http
      .get<IValuationRequestPage>(
        `${environment.api_url}${this.valuationRequestUrl}/searchString/keyword?keyword=${keyword}&valuation_request_status=${status}&offset=${offset}&limit=${limit}&mode=${mode}`
      )
      .pipe(
        tap((_) => this.log(`valuation-request find by status = ${status}`)),
        catchError((err) => {
          this.notifier.notify("error", err);
          return throwError(err);
        }),
        catchError(this.handleError<any>("valuation-request find by status"))
      );
  }

  /** GET valuation-request find by status */
  searchFilteredValuationRequest(
    keyword: string,
    status: string,
    offset: number,
    limit: number,
    mode: string
  ): Observable<IValuationRequestResponse> {
    return this.http
      .get<IValuationRequestResponse>(
        `${environment.api_url}${this.valuationRequestUrl}/search/keyword?keyword=${keyword}&valuation_request_status=${status}&offset=${offset}&limit=${limit}&mode=${mode}`
      )
      .pipe(
        tap((_) => this.log(`valuation-request find by status = ${status}`)),
        catchError((err) => {
          this.notifier.notify("error", err);
          return throwError(err);
        }),
        catchError(this.handleError<any>("valuation-request find by status"))
      );
  }

  downloadpdf(id: string): Observable<any> {
    return this.http.get(
      `${environment.api_url}${this.valuationRequestUrl}/download/${id}`,
      { responseType: "blob" }
    );

    // return this.http.get(`${environment.api_url}${this.valuationRequestUrl}/download/${id}`);
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error}`);
      // notify user
      this.notifier.notify("error", `${operation} failed`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a company category message with the audit service */
  private log(message: string) {
    // Todo: create a service and send operational audit message
  }

  uploadCalculationFiles(selectPaths: any[]): Observable<any> {
    const formData = new FormData();
    formData.append("paymentDoc", selectPaths[0], selectPaths[0].name);

    return this.http
      .post<any>(
        `${environment.api_url}/valuation-request${this.valuationRequestDocUploadUrl}`,
        formData
      )
      .pipe(
        tap((_) =>
          this.log(`upload valuation request Documents=${selectPaths}`)
        ),
        catchError((err) => {
          return throwError(err);
        }),
        catchError(this.handleError<any>("upload valuation request Documents"))
      );
  }

  getPaymentDetailsWithoutNonRefundable(valId: Number): Observable<any> {
    return this.http.get<any>(
      environment.api_url +
        this.valuationRequestUrl +
        "/get-payment-without-non-payable-amount/" +
        valId
    );
  }
}
