import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { PaymentMethodService } from 'src/app/service/payment-method/payment-method.service';
import { UpdatePaymentMethodComponent } from '../update-payment-method/update-payment-method.component';
import { DeletePaymentMethodComponent } from '../delete-payment-method/delete-payment-method.component';
import { SubSink } from 'subsink';
import { DataService } from 'src/app/service/data.service';
import {IPaymentMethod} from '../../../../../../model/interfaces/payment-method';

@Component({
  selector: 'app-view-payment-method',
  templateUrl: './view-payment-method.component.html',
  styleUrls: ['./view-payment-method.component.scss']
})
export class ViewPaymentMethodComponent implements OnInit, OnDestroy {


  @ViewChild(UpdatePaymentMethodComponent, { static: false })
  private updatePaymentMethodComponent: UpdatePaymentMethodComponent;
  @ViewChild(DeletePaymentMethodComponent, { static: false })
  private deletePaymentMethodComponent: DeletePaymentMethodComponent;


  paymentMethods: IPaymentMethod [];
  private sub = new SubSink();
  pageNo: number;

  // pagination
  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];

  constructor(private paymentMethodService: PaymentMethodService, private dataService: DataService) { }

  ngOnInit() {
    this.selectedPageNo = 0;
    this.limit = 10;

    this.paymentMethodService.getAll().subscribe(
      response => {
        var res = response.data.length;
        this.pageNo = Math.round((res / 10)+1);

      })


    this.sub.add(this.dataService.updateDataTablePaymentMethod
      .subscribe(update => this.onAdded(update)));

    this.sub.add(this.paymentMethodService.getPayementMethod(0, 10)
      .subscribe(
        (res) => {
          this.paymentMethods = (res as any).data;
        }
      ));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  triggerUpdate(id: number, code: string, description: string) {
    this.updatePaymentMethodComponent.setIdAndDescription(id, code, description);
  }

  triggerDelete(id: number) {
    this.deletePaymentMethodComponent.setPaymentMethodId(id);
  }

  onUpdated(updatedSuccessfully: boolean) {
    if (updatedSuccessfully) {
      this.updateTheTable();
    }
  }
  pageable(i: number) {
    console.log("i" + i)
    console.log(i * 10 + " - " + ((i * 10) + 10));
    this.paymentMethodService.getPayementMethod((i), ((i * 10) + 10)).subscribe(responce => {
      this.paymentMethods = (responce as any).data;
    });
  }
  counter(i: number) {
    return new Array(i);
  }

  onDeleted(deletedSuccessfully: boolean) {
    if (deletedSuccessfully) {
      this.updateTheTable();
    }
  }
  onAdded(addedSuccessfully: boolean) {
    if (addedSuccessfully) {
      this.updateTheTable();
    }
  }

  // update the table
  private updateTheTable() {
    this.sub.add(this.paymentMethodService.getPayementMethod(0, 10)
      .subscribe(
        (res) => {
          this.paymentMethods = (res as any).data;
        }));
  }

  /** search payment method*/
  searchPaymentMethod(keyword: string) {
    this.selectedPageNo = 0;
    this.sub.add(this.paymentMethodService.searchPaymentMethod(keyword, this.selectedPageNo, this.limit)
      .subscribe((res) => {
        this.paymentMethods =  res.data.content;
        this.paginationButtonCounter(res.data.totalPages);
        this.numOfPages = res.data.totalPages;
      }));
  }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }


}










