export class Payment {
  id: string;
  paymentMethod: string;
  amount: string;
  referenceNo: string;
  paidDate: any;
  branch: string;
  bank: string;
  transactionStatus: any;
  valuationRequestId:number;
  remark:any;
}
