import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewEmployeeComponent } from './view-employee/view-employee.component';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {

  @ViewChild(ViewEmployeeComponent, {static: false})
  private viewEmployee: ViewEmployeeComponent;

  keyword: string;


  constructor() { }

  ngOnInit() {
  }

  triggerSearch(event: any) {
    if (this.keyword === '') {
      this.viewEmployee.onUpdated(true);
    } else {
      this.viewEmployee.searchEmployee(this.keyword);
    }
  }

}
