import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NotifierService } from 'angular-notifier';
import { SubSink } from "subsink";
import { NgxSpinnerService } from "ngx-spinner";
import { PDFProgressData } from 'ng2-pdf-viewer';

import { FileService } from 'src/app/service/file/file.service';
import { Constant } from 'src/app/util/constant';
import { ValuationRequestService } from "src/app/service/valuation-request/valuation-request.service";
import { ValuationRequest } from 'src/app/model/valuation-request';
import { NotificationModel } from 'src/app/model/notification-model';
import { NotificationService } from 'src/app/service/notification/notification.service';
import { NotificationEmailSend } from 'src/app/model/notification-email-send';
import { ValuationRequestUpdate } from 'src/app/model/valuation-request-update';

@Component({
  selector: 'app-view-other-iu-exchange',
  templateUrl: './view-other-iu-exchange.component.html',
  styleUrls: ['./view-other-iu-exchange.component.scss']
})
export class ViewOtherIuExchangeComponent implements OnInit {

  @Input()
  valuationRequestData: ValuationRequest;

  @Input()
  userId: string;
  @Input()
  valuationRequestId: number;
  notaryDetails: ValuationRequest;
  private sub = new SubSink();
  notaryId: string;
  verificationStatus: number;
  deedNatureValue: any;
  currentUserRoleCode: string;
  userdata: any = [];
  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() status: EventEmitter<any> = new EventEmitter<any>();
  files;
  images = [];
  originalImages: any[] = [];
  filesList: File[] = [];
  names: string[] = [];
  public imgURL: any;
  public imgURL1: any;
  public documentUploaded: string;
  public documentUploaded1: string;

  noDocs = false;
  dataDone = false;

  public fileNumberFormat: string;
  public generatedFileNumber: string;
  valuationdata: any = [];
  public notificationModel = new NotificationModel("", "", "", "", "", "", "");
  public valuationRequestUpdateModel = new ValuationRequestUpdate('', '', '', '', '', '');
  public notificationEmailSendModel = new NotificationEmailSend();
  selectFileSm = 0;
  typeID: any;
  previewFileData: any;
  type: any;

  previouslyFurtherRequirementRequested: boolean;

  constructor(
    private fileService: FileService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public valuationRequestService: ValuationRequestService,
    public router: Router,
    public modalService: NgbModal,
    private notifier: NotifierService,
    public notificationService: NotificationService,
    private spinner: NgxSpinnerService
  ) {
    this.previouslyFurtherRequirementRequested = false;
  }

  ngOnInit() {
    this.fileNumberFormat = localStorage.getItem('fileNumberFormat');
    this.valuationdata = JSON.parse(sessionStorage.getItem('valuationRequest'));
    this.pad(this.valuationdata.id, 7);
    this.fileNumberFormat = localStorage.getItem('fileNumberFormat');
    if(sessionStorage.getItem('deedType')==='exchange'){
      this.typeID = +this.valuationRequestId
    }else{
      this.typeID = +this.route.snapshot.paramMap.get('id');
    }
    this._prepare();
    window.scroll(0,0);
  }

  _prepare() {
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.checkPermission();
    this.retrieveAssignedValuationRequest();
    this.showFurtherRequirementDocuments();
    this.checkStatus(true)
  }

  checkPermission() {
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.currentUserRoleCode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.currentUserRoleCode = Constant.ROLE_ACCESSOR;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER){
        this.currentUserRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.currentUserRoleCode = Constant.ROLE_COMMISSIONER;
        break;
      }
    }
  }

  public checkStatus(type:boolean): void{
    this.status.emit(type);
  }

  retrieveAssignedValuationRequest() {
    this.valuationRequestService.valuationRequestAssigned(this.route.snapshot.paramMap.get('id')).subscribe(
      data => {
        if (data.data !== null) {
          this.valuationRequestUpdateModel.dcommissionerId = data.data.dcommissionerId;
          this.valuationRequestUpdateModel.acccessorId = data.data.accessorId;
          this.valuationRequestUpdateModel.remark = data.data.remark;
        } else {
          console.log("Valuation Request not found");
        }
      }
    )
  }

  pad(num: number, size: number) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    this.generatedFileNumber = s;
    this.generatedFileNumber = '#VR' + this.generatedFileNumber;
  }

  public redirectComponent(): void {
    this.onPageCountChanged.emit(8);
  }

  public redirectPreviousComponent(): void {
    this.onPageCountChanged.emit(6);
  }

  onProgress(progressData: PDFProgressData) {
    if (progressData.loaded == progressData.total) {
      this.spinner.hide();
    }
  }

  previewFile(type) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadFileById(this.typeID, type).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(()=>{
          this.spinner.hide();
        }, 2000);

      }else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }

  showFurtherRequirementDocuments() {
    if(this.valuationRequestData.historyList.length > 0) {
      this.previouslyFurtherRequirementRequested = true;
    }
  }

}
