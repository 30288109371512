import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(private http: HttpClient) {
  }

  resetPassword(email): Observable<any> {
    return this.http.get<any>(environment.user_api_url + '/reset/init?email=' + email);
  }

  // tslint:disable-next-line:variable-name
  changePassword(cpassword, pass, re_pass): Observable<any> {
    return this.http.post<any>(environment.api_url + '/changepassword?oldPass=' + cpassword + '&newPass=' + pass + '&newConPass=' + re_pass, '');
  }

  resetPasswordValidate(key): Observable<any> {
    return this.http.get<any>(environment.user_api_url + '/reset/validate/' + key);
  }

  setupNewPassword(key, email, password): Observable<any> {
    return this.http.post<any>(environment.user_api_url + '/reset/password/' + key + '/' + email + '?newPassword=' + password, '');
  }

  getValidators():Observable<any>{
    return this.http.get<any>(environment.user_api_url + '/reset/validator');

  }

}
