import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DeedNatureService } from '../../,,/../../../../../../service/deed-nature/deed-nature.service';
import { UpdateDeedNatureComponent } from '../update-deed-nature/update-deed-nature.component';
import { DeleteDeedNatureComponent } from '../delete-deed-nature/delete-deed-nature.component';
import { SubSink } from 'subsink';
import { DataService } from 'src/app/service/data.service';
import {IDeedNature} from "../../../../../../../model/interfaces/deed-nature";

@Component({
  selector: 'app-view-deed-nature',
  templateUrl: './view-deed-nature.component.html',
  styleUrls: ['./view-deed-nature.component.scss']
})
export class ViewDeedNatureComponent implements OnInit,OnDestroy {


  deedNatures: IDeedNature [];
  private sub = new SubSink();
  pageNo:number;

  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];


@ViewChild(UpdateDeedNatureComponent, {static: false})
  private updateDeedNatureComponent: UpdateDeedNatureComponent;
  @ViewChild(DeleteDeedNatureComponent, {static: false})
  private deleteDeedNatureComponent: DeleteDeedNatureComponent;


  constructor(private   deedNatureService:DeedNatureService,private dataService: DataService) { }

  ngOnInit() {
    this.selectedPageNo = 0;
    this.limit = 10;

    this.deedNatureService.getAll().subscribe(
      response => {
        var res = response.data.length;
        this.pageNo = Math.round((res / 10)+1);

      })


   this.sub.add (this.deedNatureService.getDeedNatures(0,10)
    .subscribe(
      (res) => {
        this.deedNatures = (res as any).data;
      }
    ));

    this.sub.add(this.dataService.updateDataTableDeedNature
      .subscribe(update => this.onAdded(update)));

  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  pageable(i: number) {
    console.log("i" + i)
    console.log(i * 10 + " - " + ((i * 10) + 10));
    this.deedNatureService.getDeedNatures((i), ((i * 10) + 10)).subscribe(responce => {
      this.deedNatures = (responce as any).data;
    });
  }
  counter(i: number) {
    return new Array(i);
  }

  triggerUpdate(id: number, name:string,description: string) {
    this.updateDeedNatureComponent.setIdAndDescription(id, name,description);
  }

  triggerDelete(id: number) {
    this.deleteDeedNatureComponent.setDeedNatureId(id);
  }

  onUpdated(updatedSuccessfully: boolean) {
    if (updatedSuccessfully) {
      this.updateTheTable();
    }
  }

  onDeleted(deletedSuccessfully: boolean) {
    if (deletedSuccessfully) {
      this.updateTheTable();
    }
  }
  onAdded(addedSuccessfully: boolean) {
    if (addedSuccessfully) {
      this.updateTheTable();
    }
  }
    private updateTheTable() {
     this.sub.add( this.deedNatureService.getDeedNatures(0,10)
      .subscribe(
        (res) => {
          this.deedNatures =  (res as any).data;
        }));
    }

    searchDeedNature(keyword: string) {
      this.selectedPageNo = 0;
      this.sub.add(this.deedNatureService.searchDeedNature(keyword, this.selectedPageNo, this.limit)
        .subscribe((res) =>{
          this.deedNatures = res.data.content;
          this.paginationButtonCounter(res.data.totalPages);
          this.numOfPages = res.data.totalPages;
        })
      );
    }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }
}
