import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { DocumentTypeService } from 'src/app/service/document-type/document-type.service';
import { DataService } from 'src/app/service/data.service';
import { NotifierService } from 'angular-notifier';
import { DocumentType } from 'src/app/model/document-type';

@Component({
  selector: 'app-search-document-type',
  templateUrl: './search-document-type.component.html',
  styleUrls: ['./search-document-type.component.scss']
})
export class SearchDocumentTypeComponent implements OnInit, OnDestroy {

  addDocumentTypeForm: FormGroup;
  private sub = new SubSink();

  constructor(private documentTypeService: DocumentTypeService,
              private formBuilder: FormBuilder,
              private notifier: NotifierService,
              private dataService: DataService) {
  }

  ngOnInit() {
    this.addDocumentTypeForm = this.formBuilder.group({
      description: ['', [Validators.required]],
      name: ['', [Validators.required]],
      code: ['', [Validators.required]]
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  get f() { return this.addDocumentTypeForm.controls; }

  saveDocumentType() {
    if (this.addDocumentTypeForm.valid) {

      const documentType = new DocumentType();

      documentType.description = this.getDescription();
      documentType.code = this.getCode();
      documentType.name = this.getName();
      this.sub.add(this.documentTypeService.createDocumentTYpe(documentType)
        .subscribe(response => {
          this.notifier.notify('success', (response as any).message);
          this.dataService.updateTableDocumentType(true);
        }));

      this.reset();
    }
  }

  getCode() {
    return this.addDocumentTypeForm.get('code').value;
  }
  getDescription() {
    return this.addDocumentTypeForm.get('description').value;
  }
  getName() {
    return this.addDocumentTypeForm.get('name').value;
  }

  reset() {
    this.addDocumentTypeForm.reset();
  }
}
