import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import { environment } from 'src/environments/environment';
import { GramaNiladariDivision } from 'src/app/model/grama-niladari-division';
import { tap, catchError } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import {IDistrictsPage} from '../../model/interfaces/districts-page';

@Injectable({
  providedIn: 'root'
})
export class GramaNiladariDivisionService {

  private gramaNiladariDivisionUrl = '/grama-niladari-divisions';  // URL to web api

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  getGramanIladariADivision(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.gramaNiladariDivisionUrl + '?offset=' + offset + '&limit=' + limit + '');

  }

  getAll(): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.gramaNiladariDivisionUrl + '/all');

  }


  /* Search GramaNiladhari from server*/
  searchGramaNiladhari(keyword: string, offset: number, limit: number): Observable<any> {
    const params = {
      params: {
        offset: offset.toString(),
        limit: limit.toString(),
        keyword
      }
    };
    return this.http.get<any>(`${environment.api_url}${this.gramaNiladariDivisionUrl}/search`, params)
      .pipe(tap(_ => this.log('search GramaNiladhari')),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('get GramaNiladhari'))
      );
  }

  createGramaNiladariDivision(gramaNiladariDivision: GramaNiladariDivision): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.gramaNiladariDivisionUrl}`,
      JSON.stringify(gramaNiladariDivision), this.httpOptions)
      .pipe(tap(_ => this.log(`update grama  niladari division  create=${gramaNiladariDivision}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('grama  niladari division create'))
      );
  }

  updateGramaNiladariDivision(id: number, gramaNiladariDivision: GramaNiladariDivision): Observable<any> {
    return this.http.put(`${environment.api_url}${this.gramaNiladariDivisionUrl}/${id}`, gramaNiladariDivision)
      .pipe(tap(_ => this.log(`update grama  niladari division id=${gramaNiladariDivision.id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('grama  niladari division update'))
      );
  }

  searchGramaNiladhariDetails(id: number): Observable<IDistrictsPage> {
    return this.http.get<any[]>(`${environment.api_url}${this.gramaNiladariDivisionUrl}/searchById/${id}`)

      .pipe(tap(_ => this.log('search district')),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('get district'))
      );

  }

  /** DELETE: delete the company category on the server */
  deleteGramaNiladariDivision(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.gramaNiladariDivisionUrl}/${id}`)
      .pipe(tap(_ => this.log(`update grama  niladari division id=${id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('grama  niladari division'))
      );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {


      console.error(error);

      this.log(`${operation} failed: ${error.message}`);
      this.notifier.notify('error', `${operation} failed`);
      return of(result as T);
    };
  }
  private log(message: string) {

  }

}
