export class Company {
  public id: number;
  public code: string;
  public description: string;
  public companyCategoryOther: string;
  public name: string;
  public addressLine1: string;
  public addressLine2: string;
  public city: string;
  public province: string;
  public postalCode: string;
  public type: string;
  public companyTypeOther: string;
  public companyRegistrationNo: string;
  public companyRegistrationDate: string;
  public ownerName: string;
  public ownerContactNum: string;
  public document: string;
  public companyCategory: string;
  public companyMobile: string;
  public companyOfficeNo: string;
  public companyEmail: string;
}
