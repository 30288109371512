import {Component, OnInit} from '@angular/core';
import {Payment} from '../../../../../model/payment';
import {PaymentService} from '../../../../../service/payment/payment.service';

@Component({
  selector: 'app-further-requirement-update',
  templateUrl: './further-requirement-update.component.html',
  styleUrls: ['./further-requirement-update.component.scss']
})
export class FurtherRequirementUpdateComponent implements OnInit {

  netureSelect = '0';

  constructor(private paymentService: PaymentService) {
  }

  ngOnInit() {
  }

  payWithLgps() {
    const payment = new Payment();
    payment.amount = '10.00';
    this.paymentService.initiatePayment(payment).subscribe(res => {
      window.location = res.data;
    }, error => {
      console.log(error);
    });
  }
}
