import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FloorSubTypeService } from 'src/app/service/floor-sub-type/floor-sub-type.service';
import { NotifierService } from 'angular-notifier';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-delete-floor-sub-type',
  templateUrl: './delete-floor-sub-type.component.html',
  styleUrls: ['./delete-floor-sub-type.component.scss']
})
export class DeleteFloorSubTypeComponent implements OnInit, OnDestroy {

  private floorSubTypeId: number;

  private sub = new SubSink();
  @Output()floorSubTypeDeleted = new EventEmitter<boolean>();


  constructor( private floorSubTypeService: FloorSubTypeService, private notifier: NotifierService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  deleteFloorSubType() {
    this.sub.add(this.floorSubTypeService.deleteFloorSubType(this.floorSubTypeId)
    .subscribe(response => {
        this.updateTheView(true);
        this.notifier.notify( 'success', (response as any).message);
      }));
  }

  updateTheView(deleted: boolean) {
    this.floorSubTypeDeleted.emit(deleted);
  }

  setDeedNatureId(id: number) {
    this.floorSubTypeId = id;
  }



}
