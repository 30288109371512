import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { CalculationDataProcess } from "src/app/service/calculationDataProcess/calculation-data-process.service";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { CALCULATION_HEADINGS } from "../../constant-headings/heading";
import * as moment from "moment";
import Swal from "sweetalert2";
import { ActivatedRoute } from "@angular/router";
import { CalculationService } from "src/app/service/calculation/calculation.service";
import { Constant } from "src/app/util/constant";
declare var $: any;

@Component({
  selector: "exchange-condominium",
  templateUrl: "./exchange-condominium.html",
  styleUrls: ["./exchange-condominium.scss"]
})
export class ExchangeCondominiumComponent implements OnInit {
  @Output() emitData: EventEmitter<any> = new EventEmitter<any>();
  @Output() enableTab: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  state;
  condominiumForm1: FormGroup;

  @Input()
  updateData;

  @Input()
  selectedDataRow;

  maxDate: any;
  panelNumber = 1;
  previousValues:any[] = [];
  totalRecords:number;
  page=1;

  _CALCULATION_HEADINGS = CALCULATION_HEADINGS;

  improvements: FormArray;
  improvementTotal: number = 0;
  dynamicLblValue = "GROSS";
  
  constructor(
    public formBuilder: FormBuilder,
    private dataProcess: CalculationDataProcess,
    private activatedRoute: ActivatedRoute,
    public apiService: CalculationService,
  ) {}

  ngOnInit() {
    this.condominiumForm1 = this.formBuilder.group({
      valueType: true,
      rentPerSqFt: [null, Validators.required],
      extent: [null, Validators.required],
      monthlyRent: null,
      annualRent: null,
      deductionPrecentage: [null, Validators.required],
      deductionOutgoing: null,
      annualIncome: null,
      marketValue: [null, Validators.required],
      yp: [null, Validators.required],
      rateOfReturn: [null, Validators.required],
      valueOfTheProperty: null,
      previousDeedValue: null,
      decisionOfCommitee: null,
      improvements: this.formBuilder.array([this.createImprovemntItem()]),
      headingDate: null
    });

    if (this.updateData != null) {
      this.setData(this.updateData);
    }
    this.getPreviousMarketValue();
    this.maxDate = new Date();
  }

  getPreviousMarketValue(){
    let refNo;
    this.activatedRoute.params.subscribe(params => {
      refNo = params["id"];
    });
    console.log(refNo)
    this.apiService.getPreviousMarket(refNo,Constant.CALCULATION_CONDOMINIUM).subscribe(data=>{
      if(data.data !== null){
        this.previousValues.push(data.data);
      }      
      this.totalRecords=this.previousValues.length;
    })
  }
  setData(data) {
    for (let i = 0; i < data.improvements.length; i++) {
      if (i > 0) {
        this.improvements = this.condominiumForm1.get(
          "improvements"
        ) as FormArray;
        this.improvements.push(this.createImprovemntItem());
      }
      const formImprovements = (<FormArray>(
        this.condominiumForm1.get("improvements")
      )).at(i);
      formImprovements.patchValue({
        description: data.improvements[i].description,
        extent: data.improvements[i].extent,
        rentPerSqFt: data.improvements[i].rentPerSqFt,
        totValue: data.improvements[i].extent * data.improvements[i].rentPerSqFt
      });
    }
    this.condominiumForm1.get("extent").setValue(data.extent);
    this.condominiumForm1
      .get("deductionPrecentage")
      .setValue(data.deductionPrecentage);

    this.condominiumForm1
      .get("decisionOfCommitee")
      .setValue(data.decisionOfCommitte == null ? "" : data.decisionOfCommitte);

    this.condominiumForm1.get("rentPerSqFt").setValue(data.rentPerSqft);

    let rateOfReturn = 100 / data.yp;
    this.condominiumForm1.get("rateOfReturn").setValue(rateOfReturn.toFixed(4));

    if(data.previousDeedValue !== undefined && data.previousDeedValue !== "" && data.previousDeedValue !== null){
      this.condominiumForm1
        .get("previousDeedValue")
        .setValue((data.previousDeedValue).toFixed(2));
    }


      //toggle button gross- net
    if(data.valueType == "true"){
      this.condominiumForm1.get("valueType").setValue(true);
    }else{
      this.condominiumForm1.get("valueType").setValue(false);
    }

    this.condominiumForm1
      .get("headingDate")
      .setValue(moment(data.headingDate).format("YYYY-MM-DD"));
    console.log("---");
    console.log(data.headingDate);
    console.log(this.condominiumForm1.value);

    this.calculateData();
  }

  viewOpinionDetails(valuationId:any):void{
    window.open('view-internal-user-application/' + valuationId)
  }

  get condominium1() {
    return this.condominiumForm1.controls;
  }

  isValidField(formControl) {
    return (
      (this.condominium1[formControl].touched ||
        this.condominium1[formControl].dirty) &&
      this.condominium1[formControl].errors
    );
  }

  createImprovemntItem(): FormGroup {
    return this.formBuilder.group({
      id: null,
      description: null,
      extent: null,
      rentPerSqFt: null,
      totValue: null
    });
  }

  addItem(): void {
    this.improvements = this.condominiumForm1.get("improvements") as FormArray;
    this.improvements.push(this.createImprovemntItem());
  }

  getImprovement(): FormArray {
    return this.condominiumForm1.get("improvements") as FormArray;
  }

  removeRow(ti) {
    this.getImprovement().removeAt(ti);
  }

  calculateData() {
    let monthlyRent =
      this.condominiumForm1.get("rentPerSqFt").value *
      this.condominiumForm1.get("extent").value;
    this.condominiumForm1.get("monthlyRent").setValue(monthlyRent.toFixed(2));

    let annualRental = this.condominiumForm1.get("monthlyRent").value * 12;
    this.condominiumForm1.get("annualRent").setValue(annualRental.toFixed(2));

    if (this.condominiumForm1.get("valueType").value) {
      let deductionForOutgoing =
        (this.condominiumForm1.get("annualRent").value *
          this.condominiumForm1.get("deductionPrecentage").value) /
        100;
      this.condominiumForm1
        .get("deductionOutgoing")
        .setValue(deductionForOutgoing.toFixed(2));
    }

    let annualIncome =
      this.condominiumForm1.get("annualRent").value -
      this.condominiumForm1.get("deductionOutgoing").value;

    this.condominiumForm1.get("annualIncome").setValue(annualIncome.toFixed(2));

    let yp = 0;

    if (this.condominiumForm1.get("rateOfReturn").value != null) {
      yp = 100 / this.condominiumForm1.get("rateOfReturn").value;
    }
    
    this.condominiumForm1.get("yp").setValue(yp.toFixed(4));
    let marketValue = 0;

    marketValue =
      this.condominiumForm1.get("annualIncome").value *
      this.condominiumForm1.get("yp").value;

    this.condominiumForm1.get("marketValue").setValue(marketValue.toFixed(2));
    this.improvementTotal = 0;
    // for (let imp of this.condominiumForm1.get("improvements").value) {
    //   this.improvementTotal =
    //     this.improvementTotal + imp.extent * imp.rentPerSqFt;
    // }

    for (let imp of this.condominiumForm1.get("improvements").value) {
      // this.improvementTotal =
      //   this.improvementTotal + imp.extent * imp.rentPerSqFt;
      this.improvementTotal = this.improvementTotal + imp.totValue;
    }

    let valueOfTheProperty = 0;
    valueOfTheProperty = this.improvementTotal + marketValue;

    this.condominiumForm1
      .get("valueOfTheProperty")
      .setValue(valueOfTheProperty.toFixed(2));
  }

  calculateImprovementTableValue(index, type) {
    const formCon = (<FormArray>this.condominiumForm1.get("improvements")).at(
      index
    );
    if (type != "totValue") {
      let currentVal = !formCon.value.totValue;
      formCon.patchValue({
        totValue: formCon.value.extent * formCon.value.rentPerSqFt
      });
    } else {
      formCon.patchValue({
        totValue: formCon.value.totValue
      });
    }
    this.calculateData();
  }

  emitValueOfTheProperty(state) {
    let data;
    if (this.condominiumForm1.get("headingDate").value == null || this.condominiumForm1.get("headingDate").value == "" || this.condominiumForm1.get("headingDate").value == undefined) {
      Swal.fire("Error", "Please select heading date ", "error");
      data = {
        dateSelected: false,
        state: this.state
      };
    } else {
      if (state == this.state) {
        let data = {
          proceedData: this.processDataToSave(),
          valueOfTheProperty: this.condominiumForm1.get("valueOfTheProperty")
            .value
        };
        this.emitData.next(data);
      }
      // console.log(this.condominiumForm1.get("valueOfTheProperty").value)
    }
    this.enableTab.emit(data);
  }

  processDataToSave() {
    return this.dataProcess.processExchangeCondominiumData(
      this.condominiumForm1
    );
  }

  valueTypeChangeAction() {
    if (!this.condominiumForm1.get("valueType").value) {
      this.condominiumForm1.get("deductionPrecentage").setValue(null);
      this.condominiumForm1.get("deductionOutgoing").setValue(null);
    }
    this.calculateData();
  }
}
