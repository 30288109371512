import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ReportServiceService } from "../service/report-service.service";
import { NotifierService } from "angular-notifier";
import * as XLSX from "xlsx";

@Component({
	selector: 'app-land-registry-details-report',
	templateUrl: './land-registry-details-report.component.html',
	styleUrls: ['./land-registry-details-report.component.scss']
})
export class LandRegistryDetailsReportComponent implements OnInit {

	// for local authorities
	selectedItemsLA = [];
	dropdownListLA = [];
	dropdownSettingsLA: IDropdownSettings = {};

	// for assessor
	selectedItemsASS = [];
	dropdownListASS = [];
	dropdownSettingsASS: IDropdownSettings = {};

	// for allocated regional branches
	selectedItemsARB = [];
	dropdownListARB = [];
	dropdownSettingsARB: IDropdownSettings = {};

	// for grama niladhari division
	selectedItemsGN = [];
	dropdownListGN = [];
	dropdownSettingsGN: IDropdownSettings = {};

	// for nature of deed
	selectedItemsND = [];
	dropdownListND = [];
	dropdownSettingsND: IDropdownSettings = {};

	// for property type
	selectedItemsPT = [];
	dropdownListPT = [];
	dropdownSettingsPT: IDropdownSettings = {};

	// for property type
	selectedItemsLR = [];
	dropdownListLR = [];
	dropdownSettingsLR: IDropdownSettings = {};

	// for attested date range
	fromDate: string = null;
	toDate: string = null;

	// report data
	public reportDetails: any = [];
	public showPrint: boolean = false;
	public selectedDetails: any;

	// report data footer data
	public total_count: number = 0;
	public total_gross_rent: number = 0;
	public total_outgoing: number = 0;
	public total_net_rent: number = 0;
	public total_yp_rent: number = 0;
	public total_values_of_properties: number = 0;
	public total_values: number = 0;
	public total_values_of_opinion_given: number = 0;

	constructor(public modalService: NgbModal,
		public reportService: ReportServiceService,
		private notifier: NotifierService) { }

	ngOnInit() {
		// set up assessor dropdown
		this.dropdownSettingsASS = {
			singleSelection: false,
			idField: 'id',
			textField: 'name',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		// set up local authority dropdown
		this.dropdownSettingsLA = {
			singleSelection: false,
			idField: 'id',
			textField: 'localAuthority',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		// set up allocated regional branches dropdown
		this.dropdownSettingsARB = {
			singleSelection: false,
			idField: 'id',
			textField: 'office',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		// set up grama niladhari dropdown
		this.dropdownSettingsGN = {
			singleSelection: false,
			idField: 'id',
			textField: 'description',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		// set up property type dropdown
		this.dropdownSettingsPT = {
			singleSelection: false,
			idField: 'id',
			textField: 'name',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		// set up land registry dropdown
		this.dropdownSettingsLR = {
			singleSelection: false,
			idField: 'id',
			textField: 'landRegistry',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		// set up nature of deed dropdown
		this.dropdownSettingsND = {
			singleSelection: false,
			idField: 'id',
			textField: 'name',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		// invoke services and load data to dropdown
		this.get_all_local_authority();
		this.get_all_assessors();
		this.get_all_allocated_branches();
		this.get_all_grama_niladhari();
		this.get_all_property_type();
		this.get_all_land_registry();
		this.get_all_nature_of_deed();
	}

	/*************** DROP DOWN SERVICES ***************/
	// retrieve local authority data from service
	get_all_local_authority() {
		this.reportService.getAllLA().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListLA = value.data;
			} else {
				console.log("No Local authority return");
			}
		});
	}

	// retrieve allocated branches data from service
	get_all_allocated_branches() {
		this.reportService.getAllAB().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListARB = value.data;
				console.log(value.data);
			} else {
				console.log("No Allocated Regional Branch return");
			}
		});
	}

	// retrieve assessor data from service
	get_all_assessors() {
		this.reportService.getAllASS().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListASS = value.data;
				console.log(value.data);
			} else {
				console.log("No Assessors return");
			}
		});
	}

	// retrieve grama niladhari data from service
	get_all_grama_niladhari() {
		this.reportService.getAllGramaNiladhari().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListGN = value.data;
			} else {
				console.log("No Grama Niladhari return");
			}
		});
	}

	// retrieve property type data from service
	get_all_property_type() {
		this.reportService.getAllPropertyType().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListPT = value.data;
			} else {
				console.log("No Property Type return");
			}
		});
	}

	// retrieve property type data from service
	get_all_land_registry() {
		this.reportService.getAllLandRegistry().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListLR = value.data;
			} else {
				console.log("No Land Registry return");
			}
		});
	}

	// retrieve nature of deed data from service
	get_all_nature_of_deed() {
		this.reportService.getAllDeedNature().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListND = value.data;
			} else {
				console.log("No Land Registry return");
			}
		});
	}
	/*************** DROP DOWN SERVICES ***************/

	/*************** UI EVENT ***************/
	// event click on close button
	closeFurtherReqRemark() {
		this.modalService.dismissAll();
	}

	createExcel() {
		this.captureSelectedDetails();
		this.getReportData();
		this.showPrint = true;
		setTimeout(() => {
			var printContents = document.getElementById('excel_print');
			const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(printContents);
			const wb: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'Land Registry Dtls.');
			XLSX.writeFile(wb, 'Land Registry Details Report.xlsx');
		}, 1000);
	}

	generatePdfReport() {
		this.captureSelectedDetails();
		this.getReportData();
		this.showPrint = true;
		setTimeout(() => {
			this.createPdfReport();
		}, 1000);

	}

	onItemSelectLA(item: any) {
		// console.log(item);
	}
	onSelectAllLA(items: any) {
		// console.log(items);
	}

	onItemSelectASS(item: any) {
		// console.log(item);
	}
	onSelectAllASS(items: any) {
		// console.log(items);
	}

	onItemSelectARB(item: any) {
		// console.log(item);
	}
	onSelectAllARB(items: any) {
		// console.log(items);
	}

	onItemSelectGN(items: any) {
		// console.log(items);
	}

	onSelectAllGN(items: any) {
		// console.log(items);
	}

	onItemSelectND(items: any) {
		// console.log(items);
	}

	onSelectAllND(items: any) {
		// console.log(items);
	}

	onItemSelectPT(items: any) {
		// console.log(items);
	}

	onSelectAllPT(items: any) {
		// console.log(items);
	}

	onItemSelectLR(items: any) {
		// console.log(items);
	}

	onSelectAllLR(items: any) {
		// console.log(items);
	}
	/*************** UI EVENT ***************/

	/*************** CAPTURE UI DATA ***************/
	captureSelectedDetails(): void {

		// var date_range = ((this.fromDate == null) && (this.toDate == null)) ? "All Date" : this.fromDate + ' to ' + this.toDate;
    let lastDate = "";
    if(this.toDate == null || this.toDate == ""){
      lastDate = "Today";
    }else {
      lastDate = this.toDate;
    }

    var date_range = ((this.fromDate == null || this.fromDate == "") && (this.toDate == null || this.toDate == "")) ? "All Date" : (this.fromDate == null) ? ' to ' + lastDate : this.fromDate + ' to ' + lastDate;


		// capture selected local authorities
		var selectedLA_Names = "";
		var selectedLA = "";
		if (this.selectedItemsLA != null) {
			this.selectedItemsLA.forEach(value => {
				selectedLA += value.id + ",";
				selectedLA_Names += value.name + ",";
			});
		} else {
			selectedLA_Names = "ALL";
			selectedLA = "";
		}

		// capture selected assessors
		var selectedASS = "";
		var selectedASS_Names = "";
		if (this.selectedItemsASS != null) {
			this.selectedItemsASS.forEach(value => {
				selectedASS += value.id + ",";
				selectedASS_Names += value.name + ",";
			});
		} else {
			selectedASS = "";
			selectedASS_Names = "ALL";
		}

		// capture selected allocated regional branches
		var selectedARB = "";
		var selectedARB_Names = "";
		if (this.selectedItemsARB != null) {
			this.selectedItemsARB.forEach(value => {
				selectedARB += value.id + ",";
				selectedARB_Names += value.name + ",";
			});
		} else {
			selectedARB = "";
			selectedARB_Names = "ALL";
		}

		// capture selected grama niladhari
		var selectedGN = "";
		var selectedGN_Names = "";
		if (this.selectedItemsGN != null) {
			this.selectedItemsGN.forEach(value => {
				selectedGN += value.id + ",";
				selectedGN_Names += value.name + ",";
			});
		} else {
			selectedGN = "";
			selectedGN_Names = "ALL";
		}

		// capture selected property type
		var selectedPT = "";
		var selectedPT_Names = "";
		if (this.selectedItemsPT != null) {
			this.selectedItemsPT.forEach(value => {
				selectedPT += value.id + ",";
				selectedPT_Names += value.name + ",";
			});
		} else {
			selectedPT = "";
			selectedPT_Names = "ALL";
		}

		// capture selected land registry
		var selectedLR = "";
		var selectedLR_Names = "";
		if (this.selectedItemsLR != null) {
			this.selectedItemsLR.forEach(value => {
				selectedLR += value.id + ",";
				selectedLR_Names += value.name + ",";
			});
		} else {
			selectedLR = "";
			selectedLR_Names = "ALL";
		}

		// capture selected deed nature
		var selectedDN = "";
		var selectedDN_Names = "";
		if (this.selectedItemsND != null) {
			this.selectedItemsND.forEach(value => {
				selectedDN += value.id + ",";
				selectedDN_Names += value.name + ",";
			});
		} else {
			selectedDN = "";
			selectedDN_Names = "ALL";
		}

		this.selectedDetails = {
			selected_date: date_range,

			selectedLA_id: selectedLA,
			selectedLA_names: selectedLA_Names,

			selectedASS_id: selectedASS,
			selectedASS_names: selectedASS_Names,

			selectedARB_id: selectedARB,
			selectedARB_names: selectedARB_Names,

			selectedGN_id: selectedGN,
			selectedGN_names: selectedGN_Names,

			selectedDN_id: selectedDN,
			selectedDN_names: selectedDN_Names,

			selectedPT_id: selectedPT,
			selectedPT_names: selectedPT_Names,

			selectedLR_id: selectedLR,
			selectedLR_names: selectedLR_Names
		};
	}
	/*************** CAPTURE UI DATA ***************/

	/*************** GET REPORT DATA ***************/
	getReportData(): void {
		this.reportService.getLandRegistryDetailsReport(this.fromDate, this.toDate, this.selectedDetails.selectedARB_id, this.selectedDetails.selectedLR_id, this.selectedDetails.selectedLA_id, this.selectedDetails.selectedGN_id, this.selectedDetails.selectedDN_id, this.selectedDetails.selectedPT_id).subscribe(value => {
			if (value.data.length > 0) {
				this.reportDetails = value;
				this.total_count = value.data.length;

				// this.calculateFooterData(value.data);

			} else {
				this.notifier.notify('error', 'No records found!');
				console.log("No data found");
			}
		});
	}
	/*************** GET REPORT DATA ***************/

	/*************** GENERATE REPORT ***************/
	createPdfReport(): void {

		let printContents: any;
		let popupWin: any;
		printContents = document.getElementById('landRegistryDetailsReportContent').innerHTML;

		popupWin = window.open();
		popupWin.document.open();
		popupWin.document.write(`
    <html>
    <head>
        <title>Land Registry Details</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <!--<script src="../../../../assets/js/paged.js"></script>-->
        <!--<script src="../../../../assets/js/paged.polyfill.js"></script>-->
        <style>
          @page {
            size: A3 landscape;
            @bottom-right {
              content: "Page " counter(page) " of " counter(pages);
            }
            .page{
                page-break-after: always;
            }
          }

            body
            {
                margin: 10mm 10mm 0mm 10mm;

            }
            table{
              border: 1px solid #000;
              border-collapse: collapse;
            }
            tbody{
              page-break-before: always;
            }

            table th, table td
            {
                padding: 5px;
                margin: 0px;
                border: 1px solid #000;
            }
            .page{
                page-break-after: always;
            }

        </style>
    </head>
    <body onload="window.print();">
            ${printContents}
    </body>
    </html>`
		);
		popupWin.document.close();
	}
	/*************** GENERATE REPORT ***************/
}
