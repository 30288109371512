import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotifierService } from 'angular-notifier';
import { PDFProgressData } from 'ng2-pdf-viewer';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';

import { CalculationDataProcess } from "src/app/service/calculationDataProcess/calculation-data-process.service";
import { RecreationLandBuildingComponent } from "./landbuilding-recreation/recreation-land-building.component";
import { RecreationLandComponent } from "./land-recreation/recreation-land.component";
import { RecreationCondominiumComponent } from "./condominium/recreation-condominium.component";
import { CalculationService } from "src/app/service/calculation/calculation.service";
import { Constant } from "src/app/util/constant";
import { FileUploadComponent } from 'src/app/component/dashboards/dashboard-external/assesment/file-upload/file-upload.component';
import { DocumentUploadService } from 'src/app/service/document-upload/document-upload.service';
import { CALCULATION_HEADINGS } from "../constant-headings/heading";
import * as moment from "moment";
import { DataModel } from "../models/data-model";
import Swal from "sweetalert2";

@Component({
  selector: "app-re-creation",
  templateUrl: "./re-creation.component.html",
  styleUrls: ["./re-creation.component.scss"]
})
export class ReCreationComponent implements OnInit {
  @Input()
  showSubPanel: any;

  @Output() sandDataModel: EventEmitter<any> = new EventEmitter<any>();

  @Output() frontPageEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output() resetCalculation: EventEmitter<any> = new EventEmitter<any>();

  committeeDescision: string;
  showDecisionComDocView: boolean = false;

  @ViewChild('fileUploadDecisionOfCommittee', {static: false}) fileUploadDecisionOfCommittee: FileUploadComponent;
  selectPaths = [];
  otherSelectedPaths = [];
  selectPathsCollection = [];
  errorList = []

  dataDone = false;
  noDocs = false;
  previewFileData: any;

  disableSaveButton: boolean;
  disableNextButton: boolean;
  maxDate: Date;

  constructor(
    private dataProcess: CalculationDataProcess,
    private activatedRoute: ActivatedRoute,
    public apiService: CalculationService,
    private documentUploadService: DocumentUploadService,
    private notifier: NotifierService,
    private spinner: NgxSpinnerService,
    public sanitizer: DomSanitizer
  ) {
    this.disableNextButton = false;
    this.disableSaveButton = false;
  }

  componentIdObj = {
    showCondominium: false,
    showLand: false,
    showLandBuilding: false
  };

  finalModel = {
    valueOfTheProperty: null,
    marketValue: null,
    stampDuty: null,
    roundOffValue: null
  };

  previousValues:any[] = [];
  totalRecords:number;
  page=1;

  step = 0;

  panelNumber = 1;
  dynamicLblValue = "gross";

  totalFirstSecMarketValue : number = 0.00;
  totalSecondSecMarketValue : number = 0.00;

  dateOne:any;
  dateTwo:any;

  @Input()
  mainFormDataModel;

  @Input()
  selectedRecord;

  maxPanelNo = 0;

  showSaveButton = false;

  apiCalculationCall = 0;
  headingDateTwo = null;

  @ViewChild(RecreationLandBuildingComponent, { static: false })
  recreationLandBuildingComponent: RecreationLandBuildingComponent;

  @ViewChild(RecreationLandComponent, { static: false })
  recreationLandComponent: RecreationLandComponent;

  @ViewChild(RecreationCondominiumComponent, { static: false })
  recreationCondominiumComponent: RecreationCondominiumComponent;

  saveData = null;

  temp;

  readyToSave = false;

  _CALCULATION_HEADINGS = CALCULATION_HEADINGS;

  dataModel = new DataModel();

  stampDutyAlreadyCalculated: boolean = false;

  ngOnInit() {
    // Checking whether a new Calculation or an existing one
    // If an existing calculation, save button is hidden
    this.disableSaveButton = JSON.parse(atob(sessionStorage.getItem('disableSave')));
    this.showInitialComponent();

    if(this.selectedRecord != null) {
      this.showDecisionComDocView = true;
      this.retrievDecisionOfCommitte();

      this.headingDateTwo = moment(this.selectedRecord.headingDateTwo).format("YYYY-MM-DD");

      this.dataModel.headingDateTwo = moment(this.selectedRecord.headingDateTwo).format("YYYY-MM-DD");

      // Set round off value when viewing a calculation record
      this.finalModel.roundOffValue = this.selectedRecord.roundOffValue.toFixed(2);
    }

    if (
      this.selectedRecord != null &&
      this.selectedRecord.calculationLandBuilding.length > 0
    ) {
      this.showSubPanel.showLandBuilding = true;
    }
    if (
      this.selectedRecord != null &&
      this.selectedRecord.calculationCondominiums.length > 0
    ) {
      this.showSubPanel.showCondominium = true;
    }
    if (
      this.selectedRecord != null &&
      this.selectedRecord.calculationLand.length > 0
    ) {
      this.showSubPanel.showLand = true;
    }

    window.scroll(0,0);
    this.getPreviousMarketValue();

    this.maxDate = new Date();
    localStorage.removeItem('headingOne');
    localStorage.removeItem('headingTwo');

  }

  setStep(index: number) {
    this.step = index;
  }

  viewOpinionDetails(valuationId:any):void{
    window.open('view-internal-user-application/'+ valuationId)
  }

  validateNextStep(){
    if(this.recreationLandBuildingComponent.dataModel.fristSet.marketValue == null){
      this.disableNextButton = true;
    }
  }
  nextStep() {
    if(this.showSubPanel.showLandBuilding == true){
      if(this.showSubPanel.showLandBuilding && this.step == 1){
        if((this.recreationLandBuildingComponent.dataModel.secondSet.marketValue == null || this.recreationLandBuildingComponent.dataModel.secondSet.marketValue == 0.00 ) 
        || 
        (this.recreationLandBuildingComponent.dataModel.fristSet.marketValue == null || this.recreationLandBuildingComponent.dataModel.fristSet.marketValue == 0.00)){
          Swal.fire("Error", "Please fill Land with Building details", "error");
          return;
        }
        else{
          this.dateOne=localStorage.getItem('headingOne');
          this.dateTwo=localStorage.getItem('headingTwo');
          this.step++;
          this.showSaveButton = this.maxPanelNo + 1 == this.step ? true : false;
          if (this.maxPanelNo + 1 == this.step) {
            if (this.showSubPanel.showCondominium) {
              this.recreationCondominiumComponent.saveDataModel();
            }
            if (this.showSubPanel.showLand) {
              this.recreationLandComponent.saveDataModel();
            }
            if (this.showSubPanel.showLandBuilding) {
              this.recreationLandBuildingComponent.saveDataModel();
            }
          }
        }
      }
      else{
        this.dateOne=localStorage.getItem('headingOne');
        this.dateTwo=localStorage.getItem('headingTwo');
        this.step++;
        this.showSaveButton = this.maxPanelNo + 1 == this.step ? true : false;
        if (this.maxPanelNo + 1 == this.step) {
          if (this.showSubPanel.showCondominium) {
            this.recreationCondominiumComponent.saveDataModel();
          }
          if (this.showSubPanel.showLand) {
            this.recreationLandComponent.saveDataModel();
          }
          if (this.showSubPanel.showLandBuilding) {
            this.recreationLandBuildingComponent.saveDataModel();
          }
        }
      }
    }
    else{
      this.dateOne=localStorage.getItem('headingOne');
      this.dateTwo=localStorage.getItem('headingTwo');
      this.step++;
      this.showSaveButton = this.maxPanelNo + 1 == this.step ? true : false;
      if (this.maxPanelNo + 1 == this.step) {
        if (this.showSubPanel.showCondominium) {
          this.recreationCondominiumComponent.saveDataModel();
        }
        if (this.showSubPanel.showLand) {
          this.recreationLandComponent.saveDataModel();
        }
        if (this.showSubPanel.showLandBuilding) {
          this.recreationLandBuildingComponent.saveDataModel();
        }
      }
    }
  }


  getPreviousMarketValue(){
    let refNo;
    this.activatedRoute.params.subscribe(params => {
      refNo = params["id"];
    });
    this.apiService.getPreviousMarket(refNo,Constant.CALCULATION_RECREATION).subscribe(data=>{
      if(data.data !== null){
        this.previousValues.push(data.data);
      }      
      this.totalRecords=this.previousValues.length;
    })
  }

  setSubPanel(data) {
    this.componentIdObj.showCondominium = data.showCondominium;
    this.componentIdObj.showLand = data.showLand;
    this.componentIdObj.showLandBuilding = data.showLandBuilding;
  }

  prevStep() {
    this.dateOne=localStorage.getItem('headingOne');
    this.dateTwo=localStorage.getItem('headingTwo');
    this.totalFirstSecMarketValue = 0.00;
    this.totalSecondSecMarketValue = 0.00;
    this.showSaveButton = false;
    this.step--;
    if (this.step == -1) {
      this.frontPageEmitter.next(1);
      this.resetCalculation.next(1);
    }
  }

  async saveCalculationData(data) {
    this.apiCalculationCall;
    this.totalFirstSecMarketValue = this.totalFirstSecMarketValue + parseFloat(data.data.fristSet.valueOfTheProperty);
    this.totalSecondSecMarketValue = this.totalSecondSecMarketValue + parseFloat(data.data.secondSet.marketValue);
    if (data.type == "condominium") {
      let dataReq = await this.dataProcess.condominiumDataProcessToSave(
        data.data,
        this.mainFormDataModel,
        this.selectedRecord
      );

      if (this.saveData == null) {
        this.saveData = dataReq;
      } else {
        this.saveData.calculationCondominiums = dataReq.calculationCondominiums;
      }
    }

    if (data.type == "land") {
      let dataReq = await this.dataProcess.landDataProcessToSave(
        data.data,
        this.mainFormDataModel,
        this.selectedRecord
      );

      if (this.saveData == null) {
        this.saveData = dataReq;
      } else {
        this.saveData.calculationLand = dataReq.calculationLand;
      }
    }

    if (data.type == "land-building") {
      let dataReq = await this.dataProcess.landAndBuildingDataProcessToSave(
        data.data,
        this.mainFormDataModel,
        this.selectedRecord
      );

      if (this.saveData == null) {
        this.saveData = dataReq;
      } else {
        this.saveData.calculationLandBuilding = dataReq.calculationLandBuilding;
      }
    }

    this.calculateStampDuty();
  }

  sendRecreationDataToParentView() {

    if(this.mainFormDataModel.selectedDate != "after"){

      if(localStorage.getItem('headingTwo') != null && localStorage.getItem('headingTwo') != undefined && localStorage.getItem('headingTwo') != ""){
        this.sendDataToSave();
      }else{
        this.notifier.notify("error", "Please Select Heading Date(s)");
      }

    }else{
      if(localStorage.getItem('headingOne') != null && localStorage.getItem('headingOne') != undefined && localStorage.getItem('headingOne') != "" && localStorage.getItem('headingTwo') != null && localStorage.getItem('headingTwo') != undefined && localStorage.getItem('headingTwo') != ""){
        this.sendDataToSave();
      }else{
        this.notifier.notify("error", "Please Select Heading Date(s)");
      }
    }

  }

  sendDataToSave(){
    if (this.showSubPanel.showCondominium) {
      this.recreationCondominiumComponent.saveDataModel();
    }
    if (this.showSubPanel.showLand) {
      this.recreationLandComponent.saveDataModel();
    }
    if (this.showSubPanel.showLandBuilding) {
      this.recreationLandBuildingComponent.saveDataModel();
    }
    // this.saveData.headingDateTwo = this.headingDateTwo;
    this.saveData.roundOffValue = this.finalModel.roundOffValue;
    this.saveData.stampDuty = this.finalModel.stampDuty;

    localStorage.removeItem('headingOne');
    localStorage.removeItem('headingTwo');

    this.sandDataModel.emit(this.saveData);
  }

  showInitialComponent() {
    if (this.showSubPanel.showCondominium) {
      this.componentIdObj.showCondominium = true;
      return;
    }
    if (this.showSubPanel.showLand) {
      this.componentIdObj.showLand = true;
      return;
    }
    if (this.showSubPanel.showLandBuilding) {
      this.componentIdObj.showLandBuilding = true;
      return;
    }
  }

  getNumberPanel(panel) {
    if (panel == "c") {
      let rNo;
      if (this.showSubPanel.showCondominium) {
        rNo = 1;
      }
      this.setMaxPanelNo(rNo);
      return rNo;
    }

    if (panel == "l") {
      let rNo;
      if (this.showSubPanel.showCondominium && this.showSubPanel.showLand) {
        rNo = 2;
      }

      if (!this.showSubPanel.showCondominium && this.showSubPanel.showLand) {
        rNo = 1;
      }
      this.setMaxPanelNo(rNo);
      return rNo;
    }

    if (panel == "lb") {
      let rNo;
      if (
        this.showSubPanel.showCondominium &&
        this.showSubPanel.showLand &&
        this.showSubPanel.showLandBuilding
      ) {
        rNo = 3;
      }

      if (
        !this.showSubPanel.showCondominium &&
        this.showSubPanel.showLand &&
        this.showSubPanel.showLandBuilding
      ) {
        rNo = 2;
      }

      if (
        this.showSubPanel.showCondominium &&
        !this.showSubPanel.showLand &&
        this.showSubPanel.showLandBuilding
      ) {
        rNo = 2;
      }

      if (
        !this.showSubPanel.showCondominium &&
        !this.showSubPanel.showLand &&
        this.showSubPanel.showLandBuilding
      ) {
        rNo = 1;
      }
      this.setMaxPanelNo(rNo);
      return rNo;
    }

    if (panel == "s") {
      return this.maxPanelNo + 1;
    }
  }

  setMaxPanelNo(number) {
    this.maxPanelNo = this.maxPanelNo < number ? number : this.maxPanelNo;
  }

  checkBeforeCalculateStampDuty() {
    if (!this.stampDutyAlreadyCalculated) {
      this.calculateStampDuty();
    }
  }

  calculateStampDuty() {
    let finalValue: any = 0;
    if (this.saveData != null) {
      if (
        this.saveData.calculationCondominiums != null &&
        this.saveData.calculationCondominiums.length > 0 &&
        this.saveData.calculationCondominiums != undefined
      ) {
        if (this.saveData.calculationCondominiums.length > 1) {
          finalValue = (
            Number(finalValue) +
            Number(this.saveData.calculationCondominiums[1].lowestValue)
          ).toFixed(2);
        } else {
          finalValue = (
            Number(finalValue) +
            Number(this.saveData.calculationCondominiums[0].lowestValue)
          ).toFixed(2);
        }
      }
      if (
        this.saveData.calculationLand != null &&
        this.saveData.calculationLand.length > 0 &&
        this.saveData.calculationLand != undefined
      ) {
        if (this.saveData.calculationLand.length > 1) {
          finalValue = (
            Number(finalValue) +
            Number(this.saveData.calculationLand[1].lowestValue)
          ).toFixed(2);
        } else {
          finalValue = (
            Number(finalValue) +
            Number(this.saveData.calculationLand[0].lowestValue)
          ).toFixed(2);
        }
      }
      if (
        this.saveData.calculationLandBuilding != null &&
        this.saveData.calculationLandBuilding.length > 0 &&
        this.saveData.calculationLandBuilding != undefined
      ) {
        if (this.saveData.calculationLandBuilding.length > 1) {
          finalValue = (
            Number(finalValue) +
            Number(this.saveData.calculationLandBuilding[1].lowestValue)
          ).toFixed(2);
        } else {
          finalValue = (
            Number(finalValue) +
            Number(this.saveData.calculationLandBuilding[0].lowestValue)
          ).toFixed(2);
        }
      }

      // previous stampDuty Calculation

      // if (!isNaN(finalValue)) {
      //   this.finalModel.marketValue = finalValue;
      // }

      // if (
      //   this.selectedRecord == null &&
      //   (this.saveData.roundOffValu == "" ||
      //     this.saveData.roundOffValue == null ||
      //     this.saveData.roundOffValue < 1)
      // ) {
      //   this.finalModel.roundOffValue = this.finalModel.marketValue;
      // } else {
      //   this.finalModel.roundOffValue = this.saveData.roundOffValue;
      // }

      // this.finalModel.roundOffValue =
      //   this.saveData.roundOffValue == null || this.saveData.roundOffValue == "0.00"
      //     ? this.saveData.marketValue
      //     : this.saveData.roundOffValue;

      // new stampDuty Calculation getting sum of first sections and seconds sections and compare the min value and set to marketValue

      if (this.mainFormDataModel.selectedNatureOfDeed == "transfer") {
        this.finalModel.marketValue = this.totalSecondSecMarketValue;
      } else {
        if (this.totalFirstSecMarketValue > this.totalSecondSecMarketValue) {
          this.finalModel.marketValue = this.totalSecondSecMarketValue;
        } else {
          this.finalModel.marketValue = this.totalFirstSecMarketValue;
        }
      }

      if (this.selectedRecord != null) {
        this.finalModel.roundOffValue = this.selectedRecord.roundOffValue.toFixed(2);
      } else {
        this.finalModel.roundOffValue = this.finalModel.marketValue.toFixed(2);
      }
      let totalStampDuty: any = 0;
      let marketValue = 100000;
      let balanceStampDutyPrecentage = 4;
      if (this.mainFormDataModel.selectedNatureOfDeed == "gift") {
        marketValue = 50000;
        balanceStampDutyPrecentage = 2;
      }
      let fMarketVal: any = 0;
      if (this.finalModel.roundOffValue > marketValue) {
        fMarketVal = (Number(marketValue) * 3) / 100;
        let sMarketVal: any = 0;
        sMarketVal =
          ((Number(this.finalModel.roundOffValue) - Number(marketValue)) *
            balanceStampDutyPrecentage) /
          100;

        totalStampDuty = parseFloat(fMarketVal) + parseFloat(sMarketVal);

        this.finalModel.stampDuty = totalStampDuty.toFixed(2);
      } else {
        let fMarketVal = (Number(this.finalModel.roundOffValue) * 3) / 100;
        this.finalModel.stampDuty = fMarketVal;
      }

      this.saveData.stampDuty = this.finalModel.stampDuty;

      this.saveData.valueOfTheProperty = this.finalModel.marketValue;

      // this.finalModel.roundOffValue == 0 ||
      // this.finalModel.roundOffValue == null
      //   ? this.saveData.stampDuty
      //   : this.finalModel.roundOffValue;

      // this.saveData.roundOffValue =
      //   this.finalModel.roundOffValue == 0 ||
      //   this.finalModel.roundOffValue == null
      //     ? this.saveData.stampDuty
      //     : this.finalModel.roundOffValue;
    } else {
      this.readyToSave = false;
    }
    if (
      this.saveData.stampDuty == 0 ||
      this.saveData.stampDuty == "" ||
      this.saveData.valueOfTheProperty == 0 ||
      this.saveData.valueOfTheProperty == "" || isNaN(this.saveData.stampDuty) || isNaN(this.saveData.valueOfTheProperty)
    ) {
      this.readyToSave = false;
    } else {
      this.readyToSave = true;
    }
  }

  roundOffValueChangeEvent() {
    this.stampDutyAlreadyCalculated = true;

    let totalStampDuty: any = 0;
    let marketValue = 100000;
    let balanceStampDutyPrecentage = 4;
    if (this.mainFormDataModel.selectedNatureOfDeed == "gift") {
      marketValue = 50000;
      balanceStampDutyPrecentage = 2;
    }
    let fMarketVal: any = 0;
    if (this.finalModel.roundOffValue > marketValue) {
      fMarketVal = (Number(marketValue) * 3) / 100;
      let sMarketVal: any = 0;
      sMarketVal =
        ((Number(this.finalModel.roundOffValue) - Number(marketValue)) *
          balanceStampDutyPrecentage) /
        100;

      totalStampDuty = parseFloat(fMarketVal) + parseFloat(sMarketVal);

      this.finalModel.stampDuty = totalStampDuty.toFixed(2);
    } else {
      let fMarketVal = (Number(this.finalModel.roundOffValue) * 3) / 100;
      this.finalModel.stampDuty = fMarketVal;
    }

    this.saveData.stampDuty = this.finalModel.stampDuty;

    this.saveData.valueOfTheProperty = this.finalModel.marketValue;

    if (
      this.saveData.stampDuty == 0 ||
      this.saveData.stampDuty == "" ||
      this.saveData.valueOfTheProperty == 0 ||
      this.saveData.valueOfTheProperty == "" || isNaN(this.saveData.stampDuty) || isNaN(this.saveData.valueOfTheProperty)
    ) {
      this.readyToSave = false;
    } else {
      this.readyToSave = true;
    }
  }

  setDecisionOfCommitteeDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  saveDocumentsAndDecision(savedCalculationId) {

    this.fileUploadDecisionOfCommittee.outPutImagesSet();
    this.otherSelectedPaths = this.selectPaths;
    this.selectPathsCollection.push(this.otherSelectedPaths[0]);

    this.documentUploadService.uploadCalculationDocuments(this.selectPathsCollection, savedCalculationId, this.committeeDescision).subscribe(
      data => {},
      err => {
        this.notifier.notify('error', 'Document upload unsuccessful');
      }
    );
  }

  retrievDecisionOfCommitte() {
    this.apiService.getDecisionOfCommitteeByCalculationId(this.selectedRecord.id).subscribe(
      data => {
        if(data.data.decisionOfCommittee != "undefined") {
          this.committeeDescision = data.data.decisionOfCommittee;
        } else {
          this.committeeDescision = "-"
        }
      }
    )
  }

  // RETRIEVE DOCUMENTS - START
  onProgress(progressData: PDFProgressData) {
    if (progressData.loaded == progressData.total) {
      this.spinner.hide();
    }
  }

  previewFile(title) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.apiService.loadDocumentsByCalculationId(this.selectedRecord.id, title).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(() => {
          this.spinner.hide();
        }, 2000);

      } else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }
  // RETRIEVE DOCUMENTS - END

  saveDataModel() {
    // Use if needed to save the model
  }

}
