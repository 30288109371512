import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FileService } from 'src/app/service/file/file.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

import { RefundOpinionServiceService } from 'src/app/service/RefundOpinionService/refund-opinion-service.service';
import { DocumentUploadService } from 'src/app/service/document-upload/document-upload.service';
import { DocumentUploadOneComponent } from 'src/app/component/document/document-upload/document-upload-one/document-upload-one.component';
import { DocumentUploadTwoComponent } from 'src/app/component/document/document-upload/document-upload-two/document-upload-two.component';
import { DocumentUploadThreeComponent } from 'src/app/component/document/document-upload/document-upload-three/document-upload-three.component';
import { DocumentUploadFourComponent } from 'src/app/component/document/document-upload/document-upload-four/document-upload-four.component';
import { DocumentUploadFiveComponent } from 'src/app/component/document/document-upload/document-upload-five/document-upload-five.component';
import { DocumentUploadSixComponent } from 'src/app/component/document/document-upload/document-upload-six/document-upload-six.component';
import { DocumentUploadSevenComponent } from 'src/app/component/document/document-upload/document-upload-seven/document-upload-seven.component';
import { DocumentUploadEightComponent } from 'src/app/component/document/document-upload/document-upload-eight/document-upload-eight.component';
import { DocumentUploadNineComponent } from 'src/app/component/document/document-upload/document-upload-nine/document-upload-nine.component';
import { DocumentUploadTenComponent } from 'src/app/component/document/document-upload/document-upload-ten/document-upload-ten.component';
import { DocumentUploadElevenComponent } from 'src/app/component/document/document-upload/document-upload-eleven/document-upload-eleven.component';
import { DocumentUploadTwelveComponent } from 'src/app/component/document/document-upload/document-upload-twelve/document-upload-twelve.component';
import { DocumentUploadThirteenComponent } from 'src/app/component/document/document-upload/document-upload-thirteen/document-upload-thirteen.component';
import { refundRequestCommon } from 'src/app/model/refundRequestCommon';
import { NotificationModel } from 'src/app/model/notification-model';
import { NotificationService } from 'src/app/service/notification/notification.service';
import { ValuationRequestService } from 'src/app/service/valuation-request/valuation-request.service';
import { ValuationRequest } from 'src/app/model/valuation-request';
import { Constant } from 'src/app/util/constant';
import { NotificationModel2 } from 'src/app/model/notification-model2';

@Component({
  selector: 'app-refund-application-documents',
  templateUrl: './refund-application-documents.component.html',
  styleUrls: ['./refund-application-documents.component.scss']
})
export class RefundApplicationDocumentsComponent implements OnInit {

  @ViewChild('documentUploadNIC', {static: false}) documentUploadNIC: DocumentUploadOneComponent;
  @ViewChild('documentUploadAffidivit', {static: false}) documentUploadAffidivit: DocumentUploadTwoComponent;
  @ViewChild('documentUploadReceipt', {static: false}) documentUploadReceipt: DocumentUploadThreeComponent;
  @ViewChild('documentUploadCertifiedDeed', {static: false}) documentUploadCertifiedDeed: DocumentUploadFourComponent;
  @ViewChild('documentUploadOtherOne', {static: false}) documentUploadOtherOne: DocumentUploadFiveComponent;
  @ViewChild('documentUploadCopyOfPreviousDeed', {static: false}) documentUploadCopyOfPreviousDeed: DocumentUploadSixComponent;
  @ViewChild('documentUploadDraftDeed', {static: false}) documentUploadDraftDeed: DocumentUploadSevenComponent;
  @ViewChild('documentUploadSurveyPlanBuildingPlan', {static: false}) documentUploadSurveyPlanBuildingPlan: DocumentUploadEightComponent;
  @ViewChild('documentUploadRoutePlan', {static: false}) documentUploadRoutePlan: DocumentUploadNineComponent;
  @ViewChild('documentUploadAssessmentNotice', {static: false}) documentUploadAssessmentNotice: DocumentUploadTenComponent;
  @ViewChild('documentUploadValuationReport', {static: false}) documentUploadValuationReport: DocumentUploadElevenComponent;
  @ViewChild('documentUploadGranteeNIC', {static: false}) documentUploadGranteeNIC: DocumentUploadTwelveComponent;
  @ViewChild('documentUploadOtherTwo', {static: false}) documentUploadOtherTwo: DocumentUploadThirteenComponent;

  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();

  hasOpinion: boolean;
  releventToWP:boolean;
  public common = new refundRequestCommon('','','','','','','',null,null,null,null,null,null);

  selectPaths = [];
  otherSelectedPaths = [];
  selectPathsCollection = [];
  errorList = [];
  public notificationModel = new NotificationModel("", "", "", "", "", "", "");
  public notificationModel2 = new NotificationModel2("", "", "", "", "", "", "","","");
  public valuationRequest = new ValuationRequest();
  constructor(
    private fileService: FileService,
    private route: ActivatedRoute,
    public router: Router,
    private refundService: RefundOpinionServiceService,
    private notifier: NotifierService,
    private documentUploadService: DocumentUploadService,
    public modalService: NgbModal,
    public notificationService: NotificationService,
    public valuationRequestService: ValuationRequestService,
  ) {
    this.releventToWP=false;
  }


  ngOnInit() {
    if (sessionStorage.getItem('hasOpinion') == "true") {
      this.hasOpinion = true;
    } else if(sessionStorage.getItem('hasPreviousOpinion') == "false") {
      this.hasOpinion = false;
    }
    this.retrieveDeedDetails()
  }

  retrieveDeedDetails() {
    if (sessionStorage.getItem("hasOpinion") === "true") {
      this.valuationRequestService
        .findByValuationRequestId(parseInt(localStorage.getItem("opinionNo")))
        .subscribe((res) => {
          this.valuationRequest = res.data;
        });
    }
  }

  setNICDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setAffidivitDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setReceiptDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setCertifiedDeedDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setOtherOneDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setCopyOfPreviousDeedDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setDraftDeedDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setSurveyPlanBuildingPlanDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setRoutePlanDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setAssessmentNoticeDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setValuationReportDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setGranteeNICDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setOtherTwoDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setEveryChildComponentUploadingDocuments() {
    this.documentUploadNIC.outputDocumentsOneSet();
    this.documentUploadAffidivit.outputDocumentsTwoSet();
    this.documentUploadReceipt.outputDocumentsThreeSet();
    this.documentUploadCertifiedDeed.outputDocumentsFourSet();
    this.documentUploadOtherOne.outputDocumentsFiveSet();
    if(this.releventToWP == true){
      if (this.hasOpinion == false) {
        this.documentUploadCopyOfPreviousDeed.outputDocumentsSixSet();
        this.documentUploadDraftDeed.outputDocumentsSevenSet();
        this.documentUploadSurveyPlanBuildingPlan.outputDocumentsEightSet();
        this.documentUploadRoutePlan.outputDocumentsNineSet();
        this.documentUploadAssessmentNotice.outputDocumentsTenSet();
        this.documentUploadValuationReport.outputDocumentsElevenSet();
        this.documentUploadGranteeNIC.outputDocumentsTwelveSet();
        this.documentUploadOtherTwo.outputDocumentsThirteenSet();
      }
    }
  }

  checkDocuments() {
    this.errorList = [];
    // Hold every child uploading file data until final step
    if (this.documentUploadNIC.filesList1.length === 0) {
      this.errorList.push('NIC');
    }

    if (this.documentUploadAffidivit.filesList2.length === 0) {
      this.errorList.push('Affidivit');
    }

    if (this.documentUploadReceipt.filesList3.length === 0) {
      this.errorList.push('Receipt / s');
    }

    if (this.documentUploadCertifiedDeed.filesList4.length === 0) {
      this.errorList.push('Certified Deed');
    }

    if(this.releventToWP == true){
      if (this.hasOpinion == false) {
        if (this.documentUploadCopyOfPreviousDeed.filesList6.length === 0) {
          this.errorList.push('Copy of Previous Deed');
        }

        if (this.documentUploadDraftDeed.filesList7.length === 0) {
          this.errorList.push('Draft Deed');
        }

        if (this.documentUploadSurveyPlanBuildingPlan.filesList8.length === 0) {
          this.errorList.push('Survey Plan / Building Plan');
        }

        if (this.documentUploadRoutePlan.filesList9.length === 0) {
          this.errorList.push('Route Plan');
        }

        if (this.documentUploadGranteeNIC.filesList12.length === 0) {
          this.errorList.push('Photo copy of Grantee\'s NIC');
        }
      }
    }

    if (this.errorList.length === 0) {
      this.setEveryChildComponentUploadingDocuments();
      this.otherSelectedPaths = this.selectPaths;
      this.saveDocuments();
    } else {
      const re = /,/gi;
      const errormsg = this.errorList.toString().replace(re, '<br/>');

      Swal.fire({
        title: 'Please upload following required documents to proceed',
        icon: 'error',
        html: '<div style="color: red;text-align: left;">' + errormsg + '</div>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
          'OK',
      });
    }

  }

  saveDocuments() {
    this.selectPathsCollection.push(this.otherSelectedPaths[0]);
    this.selectPathsCollection.push(this.otherSelectedPaths[1]);
    this.selectPathsCollection.push(this.otherSelectedPaths[2]);
    this.selectPathsCollection.push(this.otherSelectedPaths[3]);
    this.selectPathsCollection.push(this.otherSelectedPaths[4]);
    if(this.releventToWP == true){
      if (this.hasOpinion == false) {
        this.selectPathsCollection.push(this.otherSelectedPaths[5]);
        this.selectPathsCollection.push(this.otherSelectedPaths[6]);
        this.selectPathsCollection.push(this.otherSelectedPaths[7]);
        this.selectPathsCollection.push(this.otherSelectedPaths[8]);
        this.selectPathsCollection.push(this.otherSelectedPaths[9]);
        this.selectPathsCollection.push(this.otherSelectedPaths[10]);
        this.selectPathsCollection.push(this.otherSelectedPaths[11]);
        this.selectPathsCollection.push(this.otherSelectedPaths[12]);

      }
    }

    // Todo: Move below function to last step of Refund Request submission
    this.documentUploadService.uploadRefundRequestDocuments(this.selectPathsCollection, localStorage.getItem('refundId')).subscribe(
      res => {
        console.log('res',res);

        // let refundGrantor = parseInt(localStorage.getItem('rGrantorId'));
        // let refundNotaryId = parseInt(localStorage.getItem('rNotaryId'));
        // let refundReasonId = parseInt(localStorage.getItem('rReasonId'));
        // let refundRequestId = parseInt(localStorage.getItem('refundId'));

        // this.common.refundGrantorId = refundGrantor;
        // this.common.refundNotaryId = refundNotaryId;
        // this.common.refundReasonId = refundReasonId;


        // this.refundService.updateRefundTableById(refundRequestId,this.common).subscribe(data=>{
        //   console.log(data);
        // })

        this.refundService.saveReleventDocument(localStorage.getItem('refundId'),this.releventToWP).subscribe(data=>{
          console.log(data.data)

          
        })
        // console.log(this.releventToWP)
        this.notifier.notify('success', 'Refund Application successfully Submitted');

        this.refundService.getRefundRequestByID(parseInt(localStorage.getItem('refundId'))).subscribe( (res) => {
          this.notificationModel2.deedNature = 'Refund-'+this.valuationRequest.deedNature;
          this.notificationModel2.subject = Constant.NEW_REFUND_APPLICATION;
          this.notificationModel2.url = res.data.id;
          this.notificationModel2.userId = res.data.commissionerId;
          this.notificationModel2.userRoleCode = Constant.ROLE_COMMISSIONER
          this.notificationModel2.categoryStatus = Constant.NOTIFICATION_CATEGORY_REFUND
          this.notificationService
            .createNotification(this.notificationModel2)
            .subscribe((data) => {
              console.log(data);
             });
          })


        sessionStorage.removeItem('hasOpinion');
        this.router.navigate(['/dashboard']);
      }, err => {
        this.notifier.notify('error', 'Document upload failed');
      }
    );
  }

  public redirectPreviousComponent(): void {
    this.onPageCountChanged.emit(2);
  }

  submitRefundRequest() {
    this.checkDocuments()
    sessionStorage.removeItem('hasOpinion');
  }



  changeForReleventOption(){
    if(this.releventToWP== true){
      this.releventToWP=false;
    }
    else{
      this.releventToWP=true;
    }
  }
}


