import {Component, OnInit, Input, ViewChild, Output} from '@angular/core';
import {Property} from '../../../../../../../model/property';
import {PropertyDeedDetailsComponent} from './property-deed-details/property-deed-details.component';
import {PropertyExtendComponent} from './property-extend/property-extend.component';
import {PropertyAssessmentComponent} from './property-assessment/property-assessment.component';
import {PropertyLoanDetailsComponent} from './property-loan-details/property-loan-details.component';
import {PropertyOtherDetailsComponent} from './property-other-details/property-other-details.component';
import {FormGroup} from '@angular/forms';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss']
})
export class PropertyComponent implements OnInit {

  selectTab = 1;
  @Input()
  properties: Property[];
  @Input() valuationRequest;

  @Output()
  propertyDeedDetailsForms: FormGroup;
  isPropertyDeedDetailsValid: boolean;
  isPropertyAssessmentDetailsValid: boolean;
  isPropertyExtentDetailsValid: boolean;
  isPropertyOtherDetailsValid: boolean;
  isPropertyLoanDetailsValid: boolean;
  @ViewChild(PropertyDeedDetailsComponent, {static: true})
  private propertyDeedDetailsComponent: PropertyDeedDetailsComponent;
  @ViewChild(PropertyExtendComponent, {static: true})
  private propertyExtendComponent: PropertyExtendComponent;
  @ViewChild(PropertyAssessmentComponent, {static: true})
  private propertyAssessmentComponent: PropertyAssessmentComponent;
  @ViewChild(PropertyLoanDetailsComponent, {static: true})
  private propertyLoanDetailsComponent: PropertyLoanDetailsComponent;
  @ViewChild(PropertyOtherDetailsComponent, {static: true})
  private propertyOtherDetailsComponent: PropertyOtherDetailsComponent;

  constructor(private notifier: NotifierService) {
  }

  ngOnInit() {
  }

  addPropertyDeedDetails(): boolean {
    this.isPropertyDeedDetailsValid = this.propertyDeedDetailsComponent.saveDeedDetails();
    if (this.isPropertyDeedDetailsValid === true) {
      this.selectTab = this.selectTab + 1;
      return true;
    } else {
      this.notifier.notify('error', 'Please give required deed details correctly');
      return false;
    }
  }

  addPropertyAssessmentDetails(): boolean {
    this.isPropertyAssessmentDetailsValid = this.propertyAssessmentComponent.saveAssessmentDetails();
    if (this.isPropertyAssessmentDetailsValid === true) {
      this.selectTab = this.selectTab + 1;
      return true;
    } else {
      this.notifier.notify('error', 'Please give required property details correctly');
      return false;
    }
  }

  backPropertyAssessmentDetails(): boolean {
    this.isPropertyAssessmentDetailsValid = this.propertyAssessmentComponent.saveAssessmentDetails();
    if (this.isPropertyAssessmentDetailsValid === true) {
      this.selectTab = this.selectTab - 1;
      return true;
    } else {
      this.notifier.notify('error', 'Please give required property details correctly');
      return false;
    }
  }

  addPropertyExtendsDetails(): boolean {
    this.isPropertyExtentDetailsValid = this.propertyExtendComponent.saveExtentDetails();
    if (this.isPropertyExtentDetailsValid === true) {
      this.selectTab = this.selectTab + 1;
      return true;
    } else {
      this.notifier.notify('error', 'Please give property extent');
      return false;
    }
  }

  backPropertyExtendsDetails(): boolean {
    this.isPropertyExtentDetailsValid = this.propertyExtendComponent.saveExtentDetails();
    if (this.isPropertyExtentDetailsValid === true) {
      this.selectTab = this.selectTab - 1;
      return true;
    } else {
      this.notifier.notify('error', 'Please give property extent');
      return false;
    }
  }

  addPropertyOtherDetails(): boolean {
    this.isPropertyOtherDetailsValid = this.propertyOtherDetailsComponent.saveOtherDetails();
    if (this.isPropertyOtherDetailsValid === true) {
      this.selectTab = this.selectTab + 1;
      return true;
    }
  }

  backPropertyOtherDetails(): boolean {
    this.isPropertyOtherDetailsValid = this.propertyOtherDetailsComponent.saveOtherDetails();
    if (this.isPropertyOtherDetailsValid === true) {
      this.selectTab = this.selectTab - 1;
      return true;
    }
  }

  addPropertyLoanDetails(): boolean {
    this.isPropertyLoanDetailsValid = this.propertyLoanDetailsComponent.saveLoanDetails();
    if (this.isPropertyLoanDetailsValid === true) {
      this.selectTab = this.selectTab + 1;
      return true;
    }
  }

  backPropertyLoanDetails(): boolean {
    this.selectTab = this.selectTab - 1;
    return true;
  }

  addPropertyBuildingDetails(): boolean {
    return true;
  }

  backPropertyBuildingDetails(): boolean {
    // this.isPropertyLoanDetailsValid = this.propertyLoanDetailsComponent.saveLoanDetails();
    // if(this.isPropertyLoanDetailsValid === true) {
    this.selectTab = this.selectTab - 1;
    return true;
    // }
  }

  propertyValidationCheck(): boolean {
    this.isPropertyDeedDetailsValid = this.propertyDeedDetailsComponent.saveDeedDetails();
    this.isPropertyAssessmentDetailsValid = this.propertyAssessmentComponent.saveAssessmentDetails();
    this.isPropertyExtentDetailsValid = this.propertyExtendComponent.saveExtentDetails();
    this.isPropertyOtherDetailsValid = this.propertyOtherDetailsComponent.saveOtherDetails();
    this.isPropertyLoanDetailsValid = this.propertyLoanDetailsComponent.saveLoanDetails();

    if ((this.isPropertyDeedDetailsValid === true) && (this.isPropertyAssessmentDetailsValid === true) && (this.isPropertyExtentDetailsValid === true)
      && (this.isPropertyOtherDetailsValid === true) && (this.isPropertyLoanDetailsValid === true)) {
      return true;
    } else {
      this.notifier.notify('error', 'Please Fill Property Details');
      return false;
    }
  }
}
