import { Component, OnInit, OnDestroy } from '@angular/core';
import { PaymentMethod } from 'src/app/model/payment-method';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/service/data.service';
import { PaymentMethodService } from 'src/app/service/payment-method/payment-method.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-search-payment-method',
  templateUrl: './search-payment-method.component.html',
  styleUrls: ['./search-payment-method.component.scss']
})
export class SearchPaymentMethodComponent implements OnInit, OnDestroy {

  addPaymentMethodForm: FormGroup;

  private sub = new SubSink();

  constructor(private formBuilder: FormBuilder,
              private paymentMethodSevice: PaymentMethodService,
              private dataService: DataService,
              private notifier: NotifierService) { }

  ngOnInit() {
    this.addPaymentMethodForm = this.formBuilder.group({
      code: ['', [Validators.required]],
      paymentMethod: ['', [Validators.required]]
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  get f() { return this.addPaymentMethodForm.controls; }

  savePaymentMethod() {
    if (this.addPaymentMethodForm.valid) {

      const paymentMethod = new PaymentMethod();

      paymentMethod.paymentMethod = this.getDescription();
      paymentMethod.code = this.getCode();
      this.sub.add(this.paymentMethodSevice.createPaymentMethod(paymentMethod)
        .subscribe(response => {
          this.dataService.updateTablePaymentMethod(true);
          this.notifier.notify('success', (response as any).message);
        }));

      this.reset();
    }
  }

  getCode() {
    return this.addPaymentMethodForm.get('code').value;
  }

  getDescription() {
    return this.addPaymentMethodForm.get('paymentMethod').value;
  }

  reset() {
    this.addPaymentMethodForm.reset();
  }
}
