import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import Swal from 'sweetalert2';
import Cropper from 'cropperjs';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-file-upload-cash',
  templateUrl: './file-upload-cash.component.html',
  styleUrls: ['./file-upload-cash.component.scss']
})

export class FileUploadComponentCash implements OnInit {

  selectedElement: SafeResourceUrl;
  pdfsrc: any;

  constructor(private sanitizer: DomSanitizer ) {
  }

  @Output()
  outPutImagesCash = new EventEmitter<File[]>();
  filesList: File[] = [];
  images = [];
  originalImages: any[] = [];
  names: string[] = [];
  selectedIndex = 0;
  files;
  showEdit = false;
  showEditPdf= false;

  public progress = 0;

  cropper;

  ngOnInit() {

  }

  uploadChange(event) {
    this.files = event.target.files;

    this.progress = 0;
    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      if (file.type==="application/pdf"||file.type==="image/png"||file.type==="image/jpeg"){
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event: any) => {
        this.progress = 100;
        this.images.push(_event.currentTarget.result);
        this.originalImages.push(_event.currentTarget.result);
        this.filesList.push(file);
      };
      this.names.push(file.name);
      this.progress = 0;
    }else {

      Swal.fire({
        text: 'File type not allowed here!',
        icon: 'warning',
        showCancelButton: false,

      });
      event.srcElement.value = null;
    }
    }

  }

  changeImg(event) {

    if (this.cropper !== undefined) {
      this.cropper.destroy();
    }
    const image = document.getElementById('imagecahs') as HTMLImageElement;
    image.src = event.target.src;
    this.cropper = new Cropper(image, {
      crop(event) {
      },
    });
  }

  outPutImagesSet() {
    this.outPutImagesCash.emit(this.filesList);
  }

  edit(event, index) {
    // this.selectedElement=this.sanitizer.bypassSecurityTrustResourceUrl(this.images[index]);
    if (this.filesList[index].type==="application/pdf"){
      this.showEdit = false;
      this.showEditPdf = true;
      this.pdfsrc=null;
      this.pdfsrc=this.images[index];
      // document.getElementById('myiframe-cash').setAttribute('src',this.images[index])
    }else {
      this.showEdit = true;
      this.showEditPdf = false;
      console.log(event.target.parentElement.parentElement.children);
      const image = document.getElementById('imagecahs') as HTMLImageElement;
      image.src = event.target.parentElement.parentElement.children[0].src;
      if (this.cropper !== undefined) {
        this.cropper.destroy();
      }
      this.cropper = new Cropper(image, {
        rotatable: true,
        crop(event) {

        },
      });
    }

    this.selectedIndex = index;
  }

  delete(index) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.images.splice(index, 1);
        this.originalImages.splice(index, 1);
        this.names.splice(index, 1);
        this.filesList.splice(index, 1);
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );

      }
    });

    // this.images.splice(index, 1);
    // this.originalImages.splice(index, 1);
    // this.names.splice(index, 1);
    // this.filesList.splice(index, 1);


  }

  closeEditor() {
    this.showEdit = false;
    this.showEditPdf = false;
  }

  rotate(angle: number) {
    this.cropper.move(1, -1).rotate(angle);
  }

  resetImage() {
    this.cropper.destroy();
    this.images[this.selectedIndex] = this.originalImages[this.selectedIndex];
    const image = document.getElementById('imagecahs') as HTMLImageElement;
    image.src = this.images[this.selectedIndex];
    this.cropper = new Cropper(image, {
      rotatable: true,
      crop(event) {
      },
    });
  }

  crop() {
    this.images[this.selectedIndex] = this.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    this.cropper.destroy();

    this.images[this.selectedIndex] = this.images[this.selectedIndex];
    const image = document.getElementById('imagecahs') as HTMLImageElement;
    image.src = this.images[this.selectedIndex];
    this.cropper = new Cropper(image, {
      rotatable: true,
      crop(event) {
      },
    });
  }

}
