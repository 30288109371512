import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getUserDetails } from 'src/app/model/getUserDetails';
import { RefundRequestModal } from 'src/app/model/refundRequests';
import { RefundOpinionServiceService } from 'src/app/service/RefundOpinionService/refund-opinion-service.service';
import { Constant } from 'src/app/util/constant';

@Component({
  selector: 'app-view-refund-collection-details',
  templateUrl: './view-refund-collection-details.component.html',
  styleUrls: ['./view-refund-collection-details.component.scss']
})
export class ViewRefundCollectionDetailsComponent implements OnInit {

  constructor(private refundService:RefundOpinionServiceService, private router:Router) { }

  userdata: any = [];
  userrolecode: string;
  id:string;
  refundRequest:RefundRequestModal [] =[];
  public getUserDetails  = new getUserDetails('','');
  noResults = false;

  ngOnInit() {
    this.getUsers();

  }
  // pagination
  totalRecords:number
  page:number =1


  getUsers(){
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.getUserDetails.userId = this.userdata.id;

    for (let role of this.userdata.roles) {
          if (role.code === Constant.ROLE_SUPER_ADMIN) {
            this.userrolecode = Constant.ROLE_SUPER_ADMIN;
            break;
          } else if (role.code === Constant.ROLE_COMMISSIONER) {
            this.userrolecode = Constant.ROLE_COMMISSIONER;
            break;
          } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
            this.userrolecode = Constant.ROLE_DEPUTY_COMMISSIONER;
            break;
          } else if (role.code === Constant.ROLE_ACCESSOR) {
            this.userrolecode = Constant.ROLE_ACCESSOR;
            break;
          }else if (role.code === Constant.ROLE_Collection_Branch) {
            this.userrolecode = Constant.ROLE_Collection_Branch;
            break;
          }
        }
      this.getUserDetails.userRole = this.userrolecode;
      this.refundService.getCollectionPendingDetails(this.userrolecode).subscribe(
        data =>{
          this.refundRequest = data.data;
          this.totalRecords = data.data.length;
        }
      )
    // this.refundService.getRefundRequests().subscribe(
    //   data =>{
    //     this.refundRequest = data.data;
    //     this.totalRecords = data.data.length;
    //   }
    // );
  }

  sendRefundId(id){
    localStorage.setItem('refundRequest' , id);
    this.router.navigate(['/view-refund-internal-user-application/', id]);
  }

  search(){
    if(this.id == ""){
      this.ngOnInit();
      this.noResults = false;
    }else if(Object.keys(this.refundRequest).length === 0){
      this.noResults = true;
    }else{
      this.noResults = false;
      this.refundRequest =this.refundRequest.filter(data=>{
        return data.deed_number.match(this.id);
      })

      this.refundRequest =this.refundRequest.filter(data=>{
        return data.id.match(this.id);
      })
    }
  }

}
