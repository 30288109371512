export class InspectionLandBuildingFeature {
  public buildingFeatures: string;
  public nos: string;
  public areaFt: any;
  public areaMt: any;

  constructor(
    buildingFeatures: string,
    nos: string,
    areaFt: any,
    areaMt: any
  ) {
    this.buildingFeatures = buildingFeatures;
    this.nos = nos;
    this.areaFt = areaFt;
    this.areaMt = areaMt;
  }
}
