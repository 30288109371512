export class NotificationModel {
    id: string;
    userRoleCode: string;
    deedNature: string;
    url: string;
    subject: string;
    userId: string;
    categoryStatus: string;

    constructor(
        id: string,
        userRoleCode: string,
        deedNature: string,
        url: string,
        subject: string,
        userId: string,
        categoryStatus: string
    ){
        this.id = id;
        this.userRoleCode = userRoleCode;
        this.deedNature = deedNature;
        this.url = url;
        this.subject = subject;
        this.userId = userId;
        this.categoryStatus = categoryStatus;
    }
}
