import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import { environment } from 'src/environments/environment';
import { PropertyFacility } from 'src/app/model/property-facility';
import { tap, catchError } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import {ICompanyCategoryPage} from '../../model/interfaces/company-category-page';
import {IPropertyFacilityPage} from '../../model/interfaces/property-facility-page';
import {IPaymentMethodPage} from '../../model/interfaces/payment-method-page';

@Injectable({
  providedIn: 'root'
})
export class PropertyFacilityService {

  private propertyFacilityUrl = '/property-facilities';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  getPropertyFacility(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.propertyFacilityUrl + '?offset=' + offset + '&limit=' + limit + '');
  }


  getAll(): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.propertyFacilityUrl + '/all');

  }

  createPropertyFacility(propertyFacility: PropertyFacility): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.propertyFacilityUrl}`,
      JSON.stringify(propertyFacility), this.httpOptions)
      .pipe(tap(_ => this.log(`update Property facility create=${propertyFacility}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('Property facility  create'))
      );
  }

  updatePropertyFacility(id: number, propertyFacility: PropertyFacility): Observable<any> {
    return this.http.put(`${environment.api_url}${this.propertyFacilityUrl}/${id}`, propertyFacility)
      .pipe(tap(_ => this.log(`update Property facility  id=${propertyFacility.id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('Property facility update'))
      );
  }

  /** DELETE: delete the company category on the server */
  deletePropertyFacility(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.propertyFacilityUrl}/${id}`)
      .pipe(tap(_ => this.log(`update Property facility  id=${id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('Property facility'))
      );
  }


  /* Search Property Facility from server*/
  searchPropertyFacility(keyword: string, offset: number, limit: number): Observable<IPropertyFacilityPage> {
    // Parameters
    const params = {
      params: {
        offset: offset.toString(),
        limit: limit.toString(),
        keyword
      }
    };

    return this.http.get<IPropertyFacilityPage>(`${environment.api_url}${this.propertyFacilityUrl}/search`, params)
      .pipe(tap(_ => this.log(`get all Property Facility`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('get Property Facility'))
      );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      this.notifier.notify('error', `${operation} failed`);
      return of(result as T);
    };
  }
  private log(message: string) {

  }

}
