import { Component, EventEmitter, OnInit, Output  } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { NotifierService } from 'angular-notifier';
import { PDFDocumentProxy, PDFProgressData } from 'ng2-pdf-viewer';
import { NgxSpinnerService } from 'ngx-spinner';
import { collectionDetails } from 'src/app/model/collectionDetails';
import { CollectionPayment } from 'src/app/model/CollectionPayment';
import { SendCollectionPayment } from 'src/app/model/sendCollectionPayment';
import { CollectionServiceService } from 'src/app/service/collectionService/collection-service.service';
import { FileService } from 'src/app/service/file/file.service';
import { RefundOpinionServiceService } from 'src/app/service/RefundOpinionService/refund-opinion-service.service';
import { Constant } from 'src/app/util/constant';
import { AddPaymentDetailsDialogComponent } from './add-payment-details-dialog/add-payment-details-dialog.component';

@Component({
  selector: 'app-view-collection-details',
  templateUrl: './view-collection-details.component.html',
  styleUrls: ['./view-collection-details.component.scss']
})
export class ViewCollectionDetailsComponent implements OnInit {

  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();

  dataDone = false;
  noDocs = false;
  previewFileData: any;

  RefundID = parseInt(localStorage.getItem("refundRequest"))
  activateButton:boolean;
  collectionStatuss:string;
  isCollectionStatusNew:boolean;
  fileNumber:string;
  refundNumber:string;
  collectionPayment:CollectionPayment[]=[];
  collection = new SendCollectionPayment('','','','','',0,0,'',0,0,'')
  collectionDetails:any;
  public collectionUpload = new collectionDetails(0,0,'',0);
  isTrue: boolean = false;

    // pagination
    totalRecords:number
    page:number =1
  userdata: any;
  permissiongranted: boolean;

  constructor(public dialog: MatDialog, private formBuilder: FormBuilder, private collectionSer:CollectionServiceService,private notifier: NotifierService, private refundService:RefundOpinionServiceService,private fileService: FileService,
    private spinner: NgxSpinnerService,public sanitizer: DomSanitizer) {
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
   }

  collectionForm= this.formBuilder.group({
    stampDuty:[''],
    CollectedAmmount:[''],
    Balance:[''],
    CollectionNo:[''],
    Installment:[''],
  })


  ngOnInit() {
    this.checkPermission();
    this.getCollectionDetails();
    this.getCollectionPayment();
    this.collectionStatus();
    this.collectionStatus();
  }

  _start(){
    if(this.collectionForm.controls['Balance'].value <= 0.00){
      this.activateButton = true;
    }else{
      this.activateButton = false;
    }
    this.refundNumber=localStorage.getItem('refundNumber');
    this.fileNumber=localStorage.getItem('fileNumber');
  }

  addPayment(){
    sessionStorage.removeItem('collectionPayment')
    const dialogRef = this.dialog.open(AddPaymentDetailsDialogComponent,{
      width:'50%',
      data:{bal :this.collectionForm.controls['Balance'].value}
    });
    dialogRef.afterClosed().subscribe(result =>{
      this.ngOnInit();
    });
  }

  getCollectionDetails(){
    this.collectionSer.getCollectionDetails(this.RefundID).subscribe(data =>{
      this.collectionDetails = data.data;
      this.collectionForm.controls['stampDuty'].setValue(this.collectionDetails.stampDuty);
      this.collectionForm.controls['CollectedAmmount'].setValue(this.collectionDetails.collectedAmmount);
      this.collectionForm.controls['Balance'].setValue(this.collectionDetails.stampDuty - this.collectionDetails.collectedAmmount);
      this.collectionForm.controls['CollectionNo'].setValue(this.collectionDetails.collectionNumber);
      this.collectionForm.controls['Installment'].setValue(this.collectionDetails.istallmentCount);

      this._start();
    })
  }

  getCollectionPayment(){
    this.collectionSer.getCollectionPaymentDetails(this.RefundID).subscribe(data =>{
      this.collectionPayment = data.data;
      this.totalRecords = data.data.length;
    })
  }

  save(){
    this.collectionUpload.stampDuty = this.collectionForm.controls['stampDuty'].value;
    this.collectionUpload.collectionNumber = this.collectionForm.controls['CollectionNo'].value;
    this.collectionUpload.collectedAmmount = this.collectionForm.controls['CollectedAmmount'].value;
    this.collectionUpload.istallmentCount = this.collectionForm.controls['Installment'].value;

    this.collectionSer.updateCollectionDetails(this.RefundID, this.collectionUpload).subscribe(data=>{
      this.notifier.notify('success', 'Succeessfully Updated');
      this.ngOnInit();
    });

  }

  checkPermission() {
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.permissiongranted = true;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.permissiongranted = true;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.permissiongranted = false;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.permissiongranted = false;
        break;
      } else if (role.code === Constant.ROLE_TAX_OFFICER) {
        this.permissiongranted = false;
        break;
      } else if(role.code === Constant.ROLE_Collection_Branch){
        this.permissiongranted = true;
        this.isTrue = true;
        break;
      }else {
        this.permissiongranted = false;
        break;
      }
    }
  }

  fullPaymentCollected(){
    this.refundService.collectionPaymentCompleted(this.RefundID).subscribe(data=>{
      console.log(data.data)
      this.notifier.notify('success', 'Full  payment completed successfully');
      this.ngOnInit();
    })
  }

  collectionStatus(){
    this.refundService.getCollectionStatus(this.RefundID).subscribe(data=>{
      this.collectionStatuss = data.data;
      this.isNewCollectionStatus(this.collectionStatuss)
    })
  }

  isNewCollectionStatus(status:string){
    if(status == Constant.REFUND_PAYMENT_COMPLETED){
      this.isCollectionStatusNew = true;
    }else{
      this.isCollectionStatusNew = false;
    }
  }

    // RETRIEVE DOCUMENTS - START
    onProgress(progressData: PDFProgressData) {
      if (progressData.loaded == progressData.total) {
        this.spinner.hide();
      }
    }

  previewFile(title,id) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadDocumentsByCollectionPaymentId(id, title).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(() => {
          this.spinner.hide();
        }, 2000);

      } else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }
  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }
  public redirectComponent(): void {
    this.onPageCountChanged.emit(8);
  }
}
