import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { InspectionElectricitySubTypeModel } from 'src/app/model/inspection-master-data-model/inspection-electricity-sub-type-model';
import { InspectionElectricitySubTypeService } from 'src/app/service/inspection-master-data-service/inspection-electricity-sub-type/inspection-electricity-sub-type.service';
import { InspectionElectricityTypeService } from 'src/app/service/inspection-master-data-service/inspection-electricity-type/inspection-electricity-type.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inspection-electricity-sub-type',
  templateUrl: './inspection-electricity-sub-type.component.html',
  styleUrls: ['./inspection-electricity-sub-type.component.scss']
})
export class InspectionElectricitySubTypeComponent implements OnInit,OnDestroy {


  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  createElectricitySubTypeForm: FormGroup;
  updateElectricitySubTypeForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  electrictityTypeList: any =[];
  activationButton: boolean;
  public createElectricitySubTypeModel = new InspectionElectricitySubTypeModel(null,'','','',null,'');
  public updateElectricitySubTypeModel = new InspectionElectricitySubTypeModel(null,'','','',null,'');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:any;
  selectedId:number;
  ElectricitySubTypes: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionElectricitySubTypeService: InspectionElectricitySubTypeService,
    private inspectionElectricityTypeService: InspectionElectricityTypeService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createElectricitySubTypeForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['', [Validators.pattern(this.NoWhitespaceRegExp)]],
      electricityType:['',[Validators.required]],
    });
    this.updateElectricitySubTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['', [Validators.pattern(this.NoWhitespaceRegExp)]],
      electricityType:['',[Validators.required]],
    });

    this.getElectricityTypes();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }


  //get electricity types start
  getElectricityTypes(){
    this.inspectionElectricityTypeService.getAllElectricityType().subscribe(res=>{
      this.electrictityTypeList = res.data;
    })
  }
  //get electricity types end

  //create Electricity type - start

  get f() { return this.createElectricitySubTypeForm.controls; }

  setElectricitySubTypeModelToSave(){
    this.createElectricitySubTypeModel.name = this.createElectricitySubTypeForm.get('name').value;
    this.createElectricitySubTypeModel.description = this.createElectricitySubTypeForm.get('description').value;
    this.createElectricitySubTypeModel.code = this.createElectricitySubTypeForm.get('code').value;
    this.createElectricitySubTypeModel.electricityType = this.createElectricitySubTypeForm.get('electricityType').value;
    this.createElectricitySubTypeModel.dataStatus = "ACTIVE";
  }

    //save Electricity type model
  saveElectricitySubType(){
    if(this.createElectricitySubTypeForm.valid){

      this.setElectricitySubTypeModelToSave();
      this.sub.add(
        this.inspectionElectricitySubTypeService.createElectricitySubType(this.createElectricitySubTypeModel).subscribe(data=>{
          this.notifier.notify('success', (data as any).message);
          this.updateTheTable();
          this.reset();
        })
        );
    }else{
      this.f.name.markAsTouched();
      this.f.code.markAsTouched();
      this.f.electricityType.markAsTouched();

    }

  }

  reset(){
    this.createElectricitySubTypeForm.reset()
  }
  //create Electricity type - end

  //update Electricity type - start

    //get update controllers
  get f1() { return this.updateElectricitySubTypeForm.controls; }

    //set data To Electricity Type model
  setElectricitySubType(subProperty:any){
    this.updateElectricitySubTypeForm.get('id').setValue(subProperty.id);
    this.updateElectricitySubTypeForm.get('name').setValue(subProperty.name);
    this.updateElectricitySubTypeForm.get('description').setValue(subProperty.description);
    this.updateElectricitySubTypeForm.get('electricityType').setValue(subProperty.electricityType.id);
    this.updateElectricitySubTypeForm.get('code').setValue(subProperty.code);

    if(subProperty.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateElectricitySubTypeModel.id = this.updateElectricitySubTypeForm.get('id').value;
    this.updateElectricitySubTypeModel.name = this.updateElectricitySubTypeForm.get('name').value;
    this.updateElectricitySubTypeModel.description = this.updateElectricitySubTypeForm.get('description').value;
    this.updateElectricitySubTypeModel.electricityType = this.updateElectricitySubTypeForm.get('electricityType').value;
    this.updateElectricitySubTypeModel.code = this.updateElectricitySubTypeForm.get('code').value;

    if(this.activationButton == true){
      this.updateElectricitySubTypeModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateElectricitySubTypeModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateElectricitySubType(){
    if(this.updateElectricitySubTypeForm.valid){
      this.setDataToModel();
      this.sub.add(this.inspectionElectricitySubTypeService.updateElectricitySubType(this.updateElectricitySubTypeModel.id, this.updateElectricitySubTypeModel).subscribe(data=>{
        this.notifier.notify('success', (data as any).message);
        this.updateTheTable();
      }))
    }else{
      this.f1.name.markAsTouched();
      this.f1.code.markAsTouched();
      this.f1.electricityType.markAsTouched();
    }

  }

  //update Electricity type - end

//search and view table start
  pageable(i: number) {
    this.inspectionElectricitySubTypeService.getElectricitySubType((i-1),10).subscribe(res=>{
      this.ElectricitySubTypes = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionElectricitySubTypeService.getElectricitySubType(0, 10)
      .subscribe(
        (res) => {
          this.ElectricitySubTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search Electricity Type
    searchElectricitySubType(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else if(!isNaN(this.keyword)){
        this.sub.add(this.inspectionElectricitySubTypeService.searchElectricityTypeById(this.keyword).subscribe(res=>{
          this.ElectricitySubTypes = [];
          this.ElectricitySubTypes[0]=  (res as any).data;
          this.currentPage = 1;
          this.totalItems = 1;
        }))
      }else{
        this.sub.add(this.inspectionElectricitySubTypeService.searchElectricitySubType(this.keyword,0,10).subscribe(res=>{
          this.ElectricitySubTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionElectricitySubTypeService.deleteElectricitySubType(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end
}
