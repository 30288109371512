import {Component, OnInit, ViewChild} from '@angular/core';
import {SubSink} from 'subsink';
import {IDocumentType} from '../../../../../../model/interfaces/document-type';
import {DocumentTypeService} from '../../../../../../service/document-type/document-type.service';
import {DataService} from '../../../../../../service/data.service';
import {UpdateUserComponent} from '../update-user/update-user.component';
import {DeleteUserComponent} from '../delete-user/delete-user.component';
import {UserService} from "../../../../../../service/user/user.service";
import {IPropertyUsage} from "../../../../../../model/interfaces/propertyUsage";
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent implements OnInit {


  private sub = new SubSink();

  pageNo: number;

  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];

  @ViewChild(UpdateUserComponent, { static: false })
  private updateUserTypeComponent: UpdateUserComponent;
  @ViewChild(UpdateUserComponent, { static: false })
  private deleteUserTypeComponent: DeleteUserComponent;
  Users = [];
  currentPage: any;
  totalItems: any;
  userId: any;


  constructor(private userService: UserService, private dataService: DataService, private notifier: NotifierService,) { }

  ngOnInit() {
    this.selectedPageNo = 0;
    this.limit = 10;

    this.userService.getAll().subscribe(
      response => {
        var res = response.data.length;
        this.pageNo = Math.round((res / 10)+1);

      }
    );

    // this.sub.add(this.dataService.updateDataTableDocumentType
    //   .subscribe(update => this.onAdded(update)));

    this.sub.add(this.userService.getAllUser(0, 10)
      .subscribe(
        (res) => {
          // this.documentTypes = (res as any).data;
          this.Users = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }
      ));
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  pageable(i: number) {
  //   console.log("i" + i)
  //   console.log(i * 10 + " - " + ((i * 10) + 10));
    this.userService.getAllUser((i-1), 10).subscribe(responce => {
      this.Users = (responce as any).data.content;
      this.currentPage = (responce as any).data.number+1;
      this.totalItems = (responce as any).data.totalElements;
    });
  }
  counter(i: number) {
    return new Array(i);
  }


  triggerDelete(User) {
    // this.deleteUserTypeComponent.setUserId(User);
    this.userId = User.id;
  }

  onUpdated(updatedSuccessfully: boolean) {
    if (updatedSuccessfully) {
      this.updateTheTable();
    }
  }
  //
  onDeleted(deletedSuccessfully: boolean) {
    if (deletedSuccessfully) {
      this.updateTheTable();
    }
  }

  deleteUserType() {
    this.sub.add(this.userService.inactivateUser(this.userId).subscribe(res=>{
      this.onUpdated(true);
      this.notifier.notify( 'success', "User deactivate successfully");
    }))
  }

  //
  // onAdded(addedSuccessfully: boolean) {
  //   if (addedSuccessfully) {
  //     this.updateTheTable();
  //   }
  // }
  // private updateTheTable() {
  //   this.documentTypeService.getDocumentTypes(0, 10).subscribe(
  //     (res) => {
  //       this.documentTypes = (res as any).data;
  //     });
  // }

  searchUser(keyword: string) {
    this.selectedPageNo = 0;
    this.sub.add(this.userService.getSearchUser(keyword, this.selectedPageNo, this.limit)
      .subscribe((res) =>{
        this.Users = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
        // this.documentTypes = res.data.content;
        // this.paginationButtonCounter(res.data.totalPages);
        // this.numOfPages = res.data.totalPages;
      })
    );
  }

  //update table
  updateTheTable(){
    this.sub.add(this.userService.getAllUser(0, 10)
    .subscribe(
      (res) => {
        // this.documentTypes = (res as any).data;
        this.Users = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      }
    ));
  }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

  triggerUpdate(id: any, firstName: any, lastName: any, RoleType: any, email: any, status: number | string) {

  }
}
