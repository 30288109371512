import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {ValuationRequest} from '../../../../../../model/valuation-request';
import {GranteeComponent} from './grantee/grantee.component';
import {PropertyComponent} from './property/property.component';
import {PaymentComponent} from './payment/payment.component';
import {ValuationRequestService} from '../../../../../../service/valuation-request/valuation-request.service';
import {SubSink} from 'subsink';
import {NotifierService} from 'angular-notifier';
import {Router} from '@angular/router';
import {OtherComponent} from './other/other.component';
import {DocumentUploadService} from '../../../../../../service/document-upload/document-upload.service';
import {GrantorComponent} from './grantor/grantor.component';
import {DeclarationComponent} from './declaration/declaration.component';
import Swal from 'sweetalert2';
import {literalArr} from '@angular/compiler/src/output/output_ast';
import { Constant } from 'src/app/util/constant';
import { NotificationModel } from 'src/app/model/notification-model';
import { NotificationService } from 'src/app/service/notification/notification.service';
import { FileService } from 'src/app/service/file/file.service';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {

  @Input()
  selectedOption: string;
  @Input()
  valuationRequest: ValuationRequest;
  selectMenu = 1;
  granteeSelectedPaths = [];
  grantorSelectedPaths = [];
  otherSelectedPaths = [];
  errorList = [];
  documentsIdList = [];
  isValidated: boolean;
  response: string;
  saveButton = 'Submit';
  onlineSelectedPaths = [];
  isDraft : boolean;

  private sub = new SubSink();
  @ViewChild(GranteeComponent, {static: false})
  private granteeComponent: GranteeComponent;
  @ViewChild(GrantorComponent, {static: false})
  private grantorComponent: GrantorComponent;
  @ViewChild(PropertyComponent, {static: false})
  private propertyComponent: PropertyComponent;
  @ViewChild(OtherComponent, {static: false})
  private otherComponent: OtherComponent;
  @ViewChild(DeclarationComponent, {static: false})
  private declarationComponent: DeclarationComponent;
  @ViewChild(PaymentComponent, {static: false})
  private paymentComponent: PaymentComponent;

  public updateStatus: string;
  public updateValuationRequestId: number;

  public notificationModel = new NotificationModel("", "", "", "", "", "", "");
  comUserId: string;

  previouslyDocumentsUploaded: boolean = false;
  btnDisable: boolean;

  constructor(private valuationRequestService: ValuationRequestService,
              private notifier: NotifierService,
              private documentUploadService: DocumentUploadService,
              public notificationService: NotificationService,
              private router: Router,
              private fileService: FileService) {
  }

  ngOnInit() {
    this.updateValuationRequestId = Number(localStorage.getItem('valuation_request_id'));
    this.updateStatus = sessionStorage.getItem('valuationUpdate');
    if (this.updateStatus === 'true') {
      this.loadUpdatedValuationInfo();
      this.checkForUploadedDocuments();
    }

    console.log(this.selectMenu)
  }

  loadUpdatedValuationInfo() {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(this.updateValuationRequestId)
      .subscribe((res) => {
        this.valuationRequest = res.data;
      }));
  }

  autoSave() {
    if (JSON.parse(localStorage.getItem('valuation_request_id')) === null) {
      this.saveInitialValuationRequest();
    } else {
      this.updateValuationRequest();
    }
    console.log(this.valuationRequest);
  }

  addGrantersAndGranteesToValuationRequest() {
    if (this.granteeComponent.checkGranteeAvailability() && this.grantorComponent.checkGranterAvailability()) {
      this.btnDisable = true

      if (!this.granteeComponent.grantees[0].isGranteeIndividual) {
        this.granteeComponent.setEveryChildComponentUploadingDocuments();
        this.granteeSelectedPaths = this.granteeComponent.selectPaths;
      } else {
        this.granteeSelectedPaths = [];
      }
      if (!this.grantorComponent.granters[0].isGrantorIndividual) {
        this.grantorComponent.setEveryChildComponentUploadingDocuments();
        this.grantorSelectedPaths = this.grantorComponent.selectPaths;

      } else {
        this.grantorSelectedPaths = [];
      }

      console.log('grantorSelectedPaths', this.grantorSelectedPaths)
      console.log('granteeSelectedPaths', this.granteeSelectedPaths)
      console.log(this.valuationRequest.id)
      if(this.valuationRequest.id !== undefined && this.updateStatus === 'true')
      {
        this.updateGranterGanteesValuationRequest();
      }
      else
      {
        this.initiateGranterGanteesValuationRequest();
      }
      // this.selectMenu = this.selectMenu + 1;
    } else {
      this.btnDisable = false
      this.notifier.notify('error', 'Please Add Grantor and Grantee');
    }
  }

  saveInitialValuationRequest() {
    this.valuationRequest.created_date = new Date();
    this.valuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
    this.valuationRequest.noOfGranters = this.valuationRequest.granters.length;
    this.valuationRequest.valuationRequestStatus = Constant.VALUATION_REQUEST_DRAFT_STATUS;
    this.sub.add(this.valuationRequestService.createValuationRequest(this.valuationRequest)
      .subscribe(res => {
        localStorage.setItem('valuation_request_id', res.data.id);
        this.valuationRequest.id = res.data.id;
      }));
  }

  backToGranterGrantee() {
    this.selectMenu = this.selectMenu - 1;
    this.btnDisable = false
    // this.granteeComponent.
  }

  addPropertiesToValuationRequest() {
    this.isValidated = this.propertyComponent.propertyValidationCheck();
    if (this.isValidated === true) {
      if(this.valuationRequest.id !== undefined && this.updateStatus === 'true')
      {
        this.updateDraftPropertyBuildingValuationRequest();
      }
      else
      {
        this.updatePropertyBuildingValuationRequest();
      }

    }
  }

  updateGranterGanteesValuationRequest()
  {
    let draftValuationRequest = new ValuationRequest();
    draftValuationRequest.id = this.valuationRequest.id;
    draftValuationRequest.grantees = this.valuationRequest.grantees;
    draftValuationRequest.granters = this.valuationRequest.granters;
    draftValuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
    draftValuationRequest.noOfGranters = this.valuationRequest.granters.length;
    draftValuationRequest.properties = this.valuationRequest.properties;
    draftValuationRequest.valuationRequestStatus = this.valuationRequest.valuationRequestStatus;
    draftValuationRequest.valuation = this.valuationRequest.valuation;
    draftValuationRequest.payment = this.valuationRequest.payment[0];
    draftValuationRequest.transactionStatus = this.valuationRequest.transactionStatus;
    draftValuationRequest.stampDuty = this.valuationRequest.stampDuty;
    draftValuationRequest.deedNature = this.valuationRequest.deedNature;
    console.log(draftValuationRequest);
    this.sub.add(this.valuationRequestService.createValuationRequest(draftValuationRequest)
      .subscribe(res => {
        localStorage.setItem('valuation_request_id', res.data.id);
        this.isDraft = true;
        this.selectMenu = this.selectMenu + 1;
      }));
  }

  initiateGranterGanteesValuationRequest()
  {
    console.log("inside initiateGranterGanteesValuationRequest()")
    console.log(localStorage.getItem('valuation_request_id'))
    this.valuationRequest.id = Number(localStorage.getItem('valuation_request_id'));
    this.valuationRequest.created_date = new Date();
    this.valuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
    this.valuationRequest.noOfGranters = this.valuationRequest.granters.length;
    this.valuationRequest.valuationRequestStatus = Constant.VALUATION_REQUEST_DRAFT_STATUS;
    console.log(this.valuationRequest);
    this.sub.add(this.valuationRequestService.createValuationRequest(this.valuationRequest)
    .subscribe(res => {
      localStorage.setItem('valuation_request_id', res.data.id);
      this.isDraft = false;
      this.selectMenu = this.selectMenu + 1;
    }));
  }

  updatePropertyBuildingValuationRequest() {
    console.log(localStorage.getItem('valuation_request_id'))
    console.log("inside updatePropertyBuildingValuationRequest()")
    this.valuationRequest.id = Number(localStorage.getItem('valuation_request_id'));
    this.valuationRequest.created_date = new Date();
    this.valuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
    this.valuationRequest.noOfGranters = this.valuationRequest.granters.length;
    this.valuationRequest.valuationRequestStatus = Constant.VALUATION_REQUEST_DRAFT_STATUS;
    console.log(this.valuationRequest);
    this.sub.add(this.valuationRequestService.createValuationRequest(this.valuationRequest)
      .subscribe(res => {
        localStorage.setItem('valuation_request_id', res.data.id);
        this.isDraft = false;
        this.selectMenu = this.selectMenu + 1;
      }));
  }

  updateDraftPropertyBuildingValuationRequest() {
    let draftValuationRequest = new ValuationRequest();
    draftValuationRequest.id = this.valuationRequest.id;
    draftValuationRequest.grantees = this.valuationRequest.grantees;
    draftValuationRequest.granters = this.valuationRequest.granters;
    draftValuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
    draftValuationRequest.noOfGranters = this.valuationRequest.granters.length;
    draftValuationRequest.properties = this.valuationRequest.properties;
    draftValuationRequest.valuationRequestStatus = this.valuationRequest.valuationRequestStatus;
    draftValuationRequest.valuation = this.valuationRequest.valuation;
    draftValuationRequest.payment = this.valuationRequest.payment[0];
    draftValuationRequest.transactionStatus = this.valuationRequest.transactionStatus;
    draftValuationRequest.stampDuty = this.valuationRequest.stampDuty;
    draftValuationRequest.deedNature = this.valuationRequest.deedNature;
    // this.valuationRequest.id = Number(localStorage.getItem('valuation_request_id'));
    // this.valuationRequest.created_date = new Date();
    // this.valuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
    // this.valuationRequest.noOfGranters = this.valuationRequest.granters.length;
    // this.valuationRequest.valuationRequestStatus = Constant.VALUATION_REQUEST_DRAFT_STATUS;
    console.log(draftValuationRequest);
    this.sub.add(this.valuationRequestService.createValuationRequest(draftValuationRequest)
      .subscribe(res => {
        localStorage.setItem('valuation_request_id', res.data.id);
        this.isDraft = true;
        this.selectMenu = this.selectMenu + 1;
      }));
  }

  updateValuationRequest() {
    this.valuationRequest.id = Number(localStorage.getItem('valuation_request_id'));
    this.valuationRequest.created_date = new Date();
    this.valuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
    this.valuationRequest.noOfGranters = this.valuationRequest.granters.length;
    this.valuationRequest.valuationRequestStatus = Constant.VALUATION_REQUEST_DRAFT_STATUS;
    this.sub.add(this.valuationRequestService.createValuationRequest(this.valuationRequest)
      .subscribe(res => {
        // this.selectMenu = this.selectMenu + 1;
      }));
  }

  addPaymentDetailsToValuationRequest() {
    if (this.paymentComponent.setPaymentDetailsToValuationRequest()) {
      if (this.paymentComponent.paymentMethod === 'Bank-Payment') {
        this.paymentComponent.setBankChildComponentUploadingDocuments();
      }

      if (this.paymentComponent.paymentMethod === 'Cash-Payment') {
        this.paymentComponent.setCashChildComponentUploadingDocuments();
      }

      // this.paymentSelectedPaths = this.paymentComponent.selectPaths;
      return true;
    } else {
      return false;
    }
  }

  backToDeclaration() {
    // this.declarationComponent.setCheckboxValue();
    this.selectMenu = this.selectMenu - 1;
  }

  submitValuationRequest() {
    try {
      if (this.addPaymentDetailsToValuationRequest()) {
        if (this.previouslyDocumentsUploaded) {
          this.submitPreviouslyDocumentsUploadedValuationRequest();
        } else {
          this.holdDocumentsUntilValuationRequestLastStep();
      }
        this.saveButton = 'Saving..';
      } else {
        this.saveButton = 'Submit';
        this.notifier.notify('error', 'Please check payment details');
      }
    } catch (e) {
      this.saveButton = 'Submit';
      this.notifier.notify('error', 'Please try again');
      console.log(e)
    }

  }

  // Check if there are documents uploaded for the current valuation request ID
  checkForUploadedDocuments() {
    this.fileService.checkForFilesByValuationRequestId(this.updateValuationRequestId).subscribe(
      data => {
        this.previouslyDocumentsUploaded = data.data;
      }
    );
  }

  proceedToUploadDocuments() {
    if (this.previouslyDocumentsUploaded) {
      this.selectMenu = this.selectMenu + 1;
    } else {
      this.checkDocuments();
    }
  }

  checkDocuments() {
    this.errorList = [];
    // hold every child uploading file data
    if (this.otherComponent.fileUploadCopyOfPreviousDeed.filesList.length === 0) {
      this.errorList.push('Copy of Previous Deed');
    }

    if (this.otherComponent.fileUploadRoutePlan.filesList3.length === 0) {
      this.errorList.push('Route Plan');
    }

    if (this.otherComponent.fileUploadDraftDeed.filesList1.length === 0) {
      this.errorList.push('Draft Deed');
    }

    if (this.otherComponent.fileUploadGranteeNic.filesList5.length === 0) {
      this.errorList.push('Photo Copy of Grantee\'s NIC');
    }

    if (this.otherComponent.fileUploadSurveyPlan.filesList2.length === 0) {
      this.errorList.push('Survey Plan / Building Plan');
    }


    if (this.errorList.length === 0) {
      this.otherComponent.setEveryChildComponentUploadingDocuments();
      this.otherSelectedPaths = this.otherComponent.selectPaths;
      this.selectMenu = this.selectMenu + 1;
    } else {
      const re = /,/gi;
      console.log(this.errorList.toString());
      const errormsg = this.errorList.toString().replace(re, '<br/>');
      console.log(errormsg);

      Swal.fire({
        title: 'Please upload following required documents to proceed',
        icon: 'error',
        html: '<div style="color: red;text-align: left;">' + errormsg + '</div>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
          'OK',
      });

      // this.otherComponent.colorChanged();
      // this.notifier.notify('error', 'There are Required Documents : ' + this.errorList.toString() );
    }

  }

  // If there are previously uploaded Documents, only upload the payment document and update the valuation request
  submitPreviouslyDocumentsUploadedValuationRequest() {
    if (this.paymentComponent.paymentMethod == 'Online-Payment') {
      this.paymentComponent.selectPaths.push(this.onlineSelectedPaths);
    }

    this.documentUploadService.uploadRepaymentDocuments(this.paymentComponent.selectPaths).subscribe(
      res => {
        this.documentsIdList = (res as any).data;

        let draftValuationRequest = new ValuationRequest();
        draftValuationRequest.id = this.valuationRequest.id;
        draftValuationRequest.grantees = this.valuationRequest.grantees;
        draftValuationRequest.granters = this.valuationRequest.granters;
        draftValuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
        draftValuationRequest.noOfGranters = this.valuationRequest.granters.length;
        draftValuationRequest.properties = this.valuationRequest.properties;
        draftValuationRequest.valuationRequestStatus = Constant.VALUATION_REQUEST_COMPLETE_STATUS;
        draftValuationRequest.valuation = this.valuationRequest.valuation;
        draftValuationRequest.payment = this.valuationRequest.payment[0];
        draftValuationRequest.payment.amount = this.valuationRequest.payment.amount;
        draftValuationRequest.payment.bank = this.valuationRequest.payment.bank;
        draftValuationRequest.payment.branch = this.valuationRequest.payment.branch;
        draftValuationRequest.payment.paymentMethod = this.valuationRequest.payment.paymentMethod;
        draftValuationRequest.payment.paidDate = this.valuationRequest.payment.paidDate;
        draftValuationRequest.payment.referenceNo = this.valuationRequest.payment.referenceNo;
        draftValuationRequest.transactionStatus = this.valuationRequest.transactionStatus;
        draftValuationRequest.stampDuty = this.valuationRequest.stampDuty;
        draftValuationRequest.deedNature = this.valuationRequest.deedNature;
        draftValuationRequest.documentsIdList = this.documentsIdList;

        this.sub.add(this.valuationRequestService.createValuationRequest(draftValuationRequest).subscribe(response => {
          const data = response as any;
          localStorage.setItem('abc',data.data);

          // Send notifications
          this.valuationRequestService.findByValuationRequestId(+localStorage.getItem('valuation_request_id')).subscribe(
            data => {
              if (data.data.transactionStatus == 'SUCCEED') {
                this.comUserId = data.data.commissionerId;
                this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
                this.notificationModel.deedNature = sessionStorage.getItem('selectedType');
                this.notificationModel.url = localStorage.getItem('valuation_request_id');
                this.notificationModel.subject = Constant.ASSIGNED_TO_DEPUTY_COMMISSIONER_AND_ASSESSOR;
                this.notificationModel.userId = this.comUserId;

                this.notificationService.createNotification(this.notificationModel).subscribe(
                  data => {

                    this.notifier.notify('success', 'Request created successfully');
                    localStorage.removeItem('grantees');
                    localStorage.removeItem('granters');
                    localStorage.removeItem('deed');
                    localStorage.removeItem('assessment');
                    localStorage.removeItem('extend');
                    localStorage.removeItem('other');
                    localStorage.removeItem('loan');
                    localStorage.removeItem('building');
                    localStorage.removeItem('valuation_request_id');
                    localStorage.removeItem('first_valuation_request_id');
                    localStorage.removeItem('second_valuation_request_id');
                    sessionStorage.removeItem('valuationRequest');
                    sessionStorage.removeItem('selectedType');
                    sessionStorage.removeItem('valuationUpdate');

                  }
                );
              }
            }
          );

          window.location = data.data.encryptedRequest;

        }, (error => {
          this.saveButton = 'Submit';
          this.notifier.notify('error', 'Please try again');
        })));
      }, err => {
        this.saveButton = 'Submit';
        this.notifier.notify('error', 'Document upload failed...');
      }
    );
  }

  // Upload all the documents along with the payment document and update the valuation request
  holdDocumentsUntilValuationRequestLastStep() {
    if (this.paymentComponent.paymentMethod == 'Online-Payment') {
      this.paymentComponent.selectPaths.push(this.onlineSelectedPaths);
    }
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths[0]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths[1]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths[2]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths[3]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths[4]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths[5]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths[6]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths[7]);
    this.paymentComponent.selectPaths.push(this.granteeSelectedPaths);
    this.paymentComponent.selectPaths.push(this.grantorSelectedPaths);

    console.log('docs', this.paymentComponent.selectPaths);
    // Todo: below function need to move last step of valuation request

    this.documentUploadService.uploadValuationRequestDocuments(this.paymentComponent.selectPaths).subscribe(
      res => {
        this.documentsIdList = (res as any).data;

        let draftValuationRequest = new ValuationRequest();
        if(this.isDraft && !this.previouslyDocumentsUploaded) {
          draftValuationRequest.id = this.valuationRequest.id;
          draftValuationRequest.grantees = this.valuationRequest.grantees;
          draftValuationRequest.granters = this.valuationRequest.granters;
          draftValuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
          draftValuationRequest.noOfGranters = this.valuationRequest.granters.length;
          draftValuationRequest.properties = this.valuationRequest.properties;
          draftValuationRequest.valuationRequestStatus = Constant.VALUATION_REQUEST_COMPLETE_STATUS;
          draftValuationRequest.valuation = this.valuationRequest.valuation;
          draftValuationRequest.payment = this.valuationRequest.payment[0];
          draftValuationRequest.payment.amount = this.valuationRequest.payment.amount;
          draftValuationRequest.payment.bank = this.valuationRequest.payment.bank;
          draftValuationRequest.payment.branch = this.valuationRequest.payment.branch;
          draftValuationRequest.payment.paymentMethod = this.valuationRequest.payment.paymentMethod;
          draftValuationRequest.payment.paidDate = this.valuationRequest.payment.paidDate;
          draftValuationRequest.payment.referenceNo = this.valuationRequest.payment.referenceNo;
          draftValuationRequest.transactionStatus = this.valuationRequest.transactionStatus;
          draftValuationRequest.stampDuty = this.valuationRequest.stampDuty;
          draftValuationRequest.deedNature = this.valuationRequest.deedNature;
          draftValuationRequest.documentsIdList = this.documentsIdList;
        } else {
          this.valuationRequest.documentsIdList = this.documentsIdList;
          this.valuationRequest.created_date = new Date();
          this.valuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
          this.valuationRequest.noOfGranters = this.valuationRequest.granters.length;
          this.valuationRequest.id = Number(localStorage.getItem('valuation_request_id'));
          this.valuationRequest.valuationRequestStatus = Constant.VALUATION_REQUEST_COMPLETE_STATUS;
          draftValuationRequest = this.valuationRequest;
        }

        this.sub.add(this.valuationRequestService.createValuationRequest(draftValuationRequest).subscribe(response => {
          const savedRequest = response as any;
          localStorage.setItem('abc',savedRequest.data);

          // Send notifications
          this.valuationRequestService.findByValuationRequestId(+localStorage.getItem('valuation_request_id')).subscribe(
            data => {
              if (data.data.transactionStatus == 'SUCCEED') {
                this.comUserId = data.data.commissionerId;
                this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
                this.notificationModel.deedNature = sessionStorage.getItem('selectedType');
                this.notificationModel.url = localStorage.getItem('valuation_request_id');
                this.notificationModel.subject = Constant.ASSIGNED_TO_DEPUTY_COMMISSIONER_AND_ASSESSOR;
                this.notificationModel.userId = this.comUserId;
                this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                this.notificationService.createNotification(this.notificationModel).subscribe(
                  data => {

                    this.notifier.notify('success', 'Request created successfully');
                    localStorage.removeItem('grantees');
                    localStorage.removeItem('granters');
                    localStorage.removeItem('deed');
                    localStorage.removeItem('assessment');
                    localStorage.removeItem('extend');
                    localStorage.removeItem('other');
                    localStorage.removeItem('loan');
                    localStorage.removeItem('building');
                    localStorage.removeItem('valuation_request_id');
                    localStorage.removeItem('first_valuation_request_id');
                    localStorage.removeItem('second_valuation_request_id');
                    sessionStorage.removeItem('valuationRequest');
                    sessionStorage.removeItem('selectedType');
                    sessionStorage.removeItem('valuationUpdate');

                  }
                );
              }
            }
          );

          window.location = savedRequest.data.encryptedRequest;

        }, (error => {
          this.saveButton = 'Submit';
          this.notifier.notify('error', 'Please try again');
        })));
      }, err => {
        this.saveButton = 'Submit';
        this.notifier.notify('error', 'Document upload failed...');
      }
    );

  }

  checkOrNot() {
    if (this.declarationComponent.checkOrNot()) {
      this.selectMenu = this.selectMenu + 1;
    } else {
      this.notifier.notify('error', 'Please agree before moving to payment');
    }
  }

}


// $('.tabs').tabs('methodName');

