import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import Swal from "sweetalert2";
import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { NotifierService } from 'angular-notifier';
import { PDFProgressData } from 'ng2-pdf-viewer';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';

import { CalculationDataProcess } from "src/app/service/calculationDataProcess/calculation-data-process.service";
import { DataModel } from "../models/data-model";
import { Improvements } from "../models/improvements";
import { ValueOfTheLand } from "../models/landValues";
import { CALCULATION_HEADINGS } from "../constant-headings/heading";
import { CalculationService } from "src/app/service/calculation/calculation.service";
import { Constant } from "src/app/util/constant";
import { FileUploadComponent } from 'src/app/component/dashboards/dashboard-external/assesment/file-upload/file-upload.component';
import { DocumentUploadService } from 'src/app/service/document-upload/document-upload.service';

@Component({
  selector: "app-land-building",
  templateUrl: "./land-building.component.html",
  styleUrls: ["./land-building.component.scss"]
})
export class LandBuildingComponent implements OnInit {
  panelNumber = 1;

  @Input()
  mainFormDataModel;

  @Input()
  selectedRecord;

  buildingValueTotal: any = 0;
  buildingValueTotal_: any = 0;

  landValueTotal: any = 0;
  landValueTotal_: any = 0;

  headingDateOne = null;
  headingDateTwo = null;

  hideBackButton = false;

  _CALCULATION_HEADINGS = CALCULATION_HEADINGS;

  @Output() sandDataModel: EventEmitter<any> = new EventEmitter<any>();

  @Output() frontPageEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output() resetCalculation: EventEmitter<any> = new EventEmitter<any>();

  dataModel = new DataModel();

  previousMarketValues = [];

  maxDate: any;
  previousValues:any[] = [];
  totalRecords:number;
  page=1;

  committeeDescision: string;
  showDecisionComDocView: boolean = false;

  @ViewChild('fileUploadDecisionOfCommittee', {static: false}) fileUploadDecisionOfCommittee: FileUploadComponent;
  selectPaths = [];
  otherSelectedPaths = [];
  selectPathsCollection = [];
  errorList = []

  dataDone = false;
  noDocs = false;
  previewFileData: any;

  disableSaveButton: boolean;

  constructor(
    private dataProcess: CalculationDataProcess,
    private activatedRoute: ActivatedRoute,
    public apiService: CalculationService,
    private documentUploadService: DocumentUploadService,
    private notifier: NotifierService,
    private spinner: NgxSpinnerService,
    public sanitizer: DomSanitizer
  ) {
    this.disableSaveButton = false;
  }

  ngOnInit() {

    // Checking whether a new Calculation or an existing one
    // If an existing calculation, save button is hidden
    this.disableSaveButton = JSON.parse(atob(sessionStorage.getItem('disableSave')));

    if (this.selectedRecord != null) {
      this.showDecisionComDocView = true;
      this.retrievDecisionOfCommitte();
      this.dataModel = this.dataProcess.landAndBuildingDataProcessToView(
        this.selectedRecord
      );

      if (this.selectedRecord.natureOfDeed == "gift") {
        for(let i=0; i<this.dataModel.fristSet.valueOfTheBuildings.length; i++) {
          this.dataModel.fristSet.valueOfTheBuildings[i].constructionCost = Number(this.dataModel.fristSet.valueOfTheBuildings[i].constructionCost).toFixed(2);
          this.dataModel.fristSet.valueOfTheBuildings[i].contractionCost = Number(this.dataModel.fristSet.valueOfTheBuildings[i].contractionCost).toFixed(2);
        }
      }
      for(let i=0; i<this.dataModel.secondSet.valueOfTheBuildings.length; i++) {
        this.dataModel.secondSet.valueOfTheBuildings[i].constructionCost = Number(this.dataModel.secondSet.valueOfTheBuildings[i].constructionCost).toFixed(2);
        this.dataModel.secondSet.valueOfTheBuildings[i].contractionCost = Number(this.dataModel.secondSet.valueOfTheBuildings[i].contractionCost).toFixed(2);
      }
      
      // if(this.selectedRecord.calculationLandBuilding[1].roundOffValue != null){
        // this.dataModel.secondSet.roundOffValue = this.selectedRecord.calculationLandBuilding[1].roundOffValue;
      // }
      this.headingDateOne = moment(this.selectedRecord.headingDateOne).format(
        "YYYY-MM-DD"
      );
      this.headingDateTwo = moment(this.selectedRecord.headingDateTwo).format(
        "YYYY-MM-DD"
      );
      this.mainFormDataModel.selectedDate = this.selectedRecord.date;
      this.mainFormDataModel.selectedNatureOfDeed = this.selectedRecord.natureOfDeed;
      this.mainFormDataModel.selectedPropertyType = this.selectedRecord.propertyType;

      if (this.mainFormDataModel.selectedNatureOfDeed == "gift") {
        this.calculateValuationOfTheland(undefined, 1);
        this.calculateValuationOfTheBuilding(1, undefined);
        this.calculateAutoGeneratedValuesForFirstPanel();
      }
      this.calculateValuationOfTheland(undefined, 2);
      this.calculateValuationOfTheBuilding(2, undefined);
      this.calculateAutoGeneratedValuesForSecondPanel();
    }

    if (
      (this.mainFormDataModel.selectedNatureOfDeed == "exchange" ||
        this.mainFormDataModel.selectedNatureOfDeed == "transfer") &&
      (this.mainFormDataModel.selectedPropertyType == "land" ||
        this.mainFormDataModel.selectedPropertyType == "land-with-building")
    ) {
      this.hideBackButton = true;
      this.panelNumber = 2;
    } else {
      this.hideBackButton = false;
      this.panelNumber = 1;
    }

    // this.getPreviousMarketValueDetails();
    this.getPreviousMarketValue();
    this.maxDate = new Date();
    window.scroll(0,0);
  }

  // getPreviousMarketValueDetails() {
  //   this.previousMarketValues = [];
  //   let refNo;
  //   this.activatedRoute.params.subscribe(params => {
  //     refNo = params["id"];
  //   });
  //   this.apiService.getPreviousMarketValueDetails(refNo).subscribe(data => {
  //     this.previousMarketValues = data.data;
  //   });
  // }
  getPreviousMarketValue(){
    let refNo;
    this.activatedRoute.params.subscribe(params => {
      refNo = params["id"];
    });
    this.apiService.getPreviousMarket(refNo,Constant.CALCULATION_LAND_BUILDING).subscribe(data=>{
      if(data.data !== null){
        this.previousValues.push(data.data);
      }      
      this.totalRecords=this.previousValues.length;
    })
  }
  setInitiate() {
    this.dataModel = new DataModel();
  }

  viewOpinionDetails(valuationId:any):void{
    window.open('view-internal-user-application/' + valuationId)
  }


  changePanelNumber(position) {
    if (position == "back") {
      if (this.panelNumber == 2) {
        this.panelNumber = this.panelNumber - 1;
      }
    }
    if (position == "next") {
      if (
        this.headingDateOne == null &&
        this.mainFormDataModel.selectedDate == "after"
      ) {
        Swal.fire("Error", "Please select first heading date ", "error");
        return;
      }else if(!this.dataModel.fristSet.marketValue){
        this.notifier.notify('error', 'Market value Is required');
        return;
      }
      
      if (this.panelNumber == 1) {
        this.panelNumber = this.panelNumber + 1;
      }
    }
    window.scroll(0,0);
  }

  addNewRow(position, type) {
    if (position == 1) {
      if (type == "vl") {
        this.dataModel.fristSet.valueOfTheLand.push({
          id: null,
          landExtend: null,
          perPerchValue: null,
          value: null
        });
      }

      if (type == "vb") {
        this.dataModel.fristSet.valueOfTheBuildings.push({
          id: null,
          buildingNo: null,
          floorNo: null,
          extent: null,
          constructionCost: null,
          contractionCost: null
        });
      }
      if (type == "improvements") {
        this.dataModel.fristSet.improvements.push({
          id: null,
          description: null,
          extent: null,
          rentPerSqFt: null,
          value: null
        });
      }
    }
    if (position == 2) {
      if (type == "vl") {
        this.dataModel.secondSet.valueOfTheLand.push(new ValueOfTheLand());
      }

      if (type == "vb") {
        this.dataModel.secondSet.valueOfTheBuildings.push({
          id: null,
          buildingNo: null,
          floorNo: null,
          extent: null,
          constructionCost: null,
          contractionCost: null
        });
      }
      if (type == "improvements") {
        this.dataModel.secondSet.improvements.push({
          id: null,
          description: null,
          extent: null,
          rentPerSqFt: null,
          value: null
        });
      }
    }
  }

  removeRow(index, position, type) {
    if (position == 1) {
      if (type == "vl") {
        this.dataModel.fristSet.valueOfTheLand.splice(index, 1);
        this.calculateValuationOfTheland(undefined, position);
      }

      if (type == "vb") {
        this.dataModel.fristSet.valueOfTheBuildings.splice(index, 1);
        this.calculateValuationOfTheBuilding(position, undefined);
      }
      if (type == "improvements") {
        this.dataModel.fristSet.improvements.splice(index, 1);
        this.calculateImprovementsTableValueFiled(undefined, position);
      }
    }

    if (position == 2) {
      if (type == "vl") {
        this.dataModel.secondSet.valueOfTheLand.splice(index, 1);
        this.calculateValuationOfTheland(undefined, position);
      }

      if (type == "vb") {
        this.dataModel.secondSet.valueOfTheBuildings.splice(index, 1);
        this.calculateValuationOfTheBuilding(position, undefined);
      }
      if (type == "improvements") {
        this.dataModel.secondSet.improvements.splice(index, 1);
        this.calculateImprovementsTableValueFiled(index, position);
      }
    }
  }

  calculateValuationOfTheBuilding(position, index) {
    if (position == 1) {
      if (index != undefined) {
        this.dataModel.fristSet.valueOfTheBuildings[index].contractionCost = (
          Number(this.dataModel.fristSet.valueOfTheBuildings[index].extent) *
          Number(
            this.dataModel.fristSet.valueOfTheBuildings[index].constructionCost
          )
        ).toFixed(2);
      }
      this.buildingValueTotal = 0;
      this.dataModel.fristSet.valueOfTheBuildings.forEach(element => {
        this.buildingValueTotal = (
          Number(this.buildingValueTotal) + Number(element.contractionCost)
        ).toFixed(2);
      });
      this.calculateAutoGeneratedValuesForFirstPanel();
    }
    if (position == 2) {
      if (index != undefined) {
        this.dataModel.secondSet.valueOfTheBuildings[index].contractionCost = (
          Number(this.dataModel.secondSet.valueOfTheBuildings[index].extent) *
          Number(
            this.dataModel.secondSet.valueOfTheBuildings[index].constructionCost
          )
        ).toFixed(2);
      }
      this.buildingValueTotal_ = 0;
      this.dataModel.secondSet.valueOfTheBuildings.forEach(element => {
        this.buildingValueTotal_ = (
          Number(this.buildingValueTotal_) + Number(element.contractionCost)
        ).toFixed(2);
      });
      this.calculateAutoGeneratedValuesForSecondPanel();
    }
  }

  calculateValuationOfTheland(index, position) {
    if (position == 1) {
      this.landValueTotal = 0;
      if (index != undefined) {
        this.dataModel.fristSet.valueOfTheLand[index].value = (
          Number(this.dataModel.fristSet.valueOfTheLand[index].landExtend) *
          Number(this.dataModel.fristSet.valueOfTheLand[index].perPerchValue)
        ).toFixed(2);
      }

      this.dataModel.fristSet.valueOfTheLand.forEach(element => {
        this.landValueTotal = (
          Number(this.landValueTotal) + Number(element.value)
        ).toFixed(2);
      });
      this.calculateAutoGeneratedValuesForFirstPanel();
    }

    if (position == 2) {
      this.landValueTotal_ = 0;
      if (index != undefined) {
        this.dataModel.secondSet.valueOfTheLand[index].value = (
          Number(this.dataModel.secondSet.valueOfTheLand[index].landExtend) *
          Number(this.dataModel.secondSet.valueOfTheLand[index].perPerchValue)
        ).toFixed(2);
      }

      this.dataModel.secondSet.valueOfTheLand.forEach(element => {
        this.landValueTotal_ = (
          Number(this.landValueTotal_) + Number(element.value)
        ).toFixed(2);
      });
      this.calculateAutoGeneratedValuesForSecondPanel();
    }
  }

  calculateImprovementsTableValueFiled(index, position) {
    let totalVal = 0;
    if (position == 1) {
      if (index != undefined) {
        this.dataModel.fristSet.improvements[index].value = (
          Number(this.dataModel.fristSet.improvements[index].extent) *
          Number(this.dataModel.fristSet.improvements[index].rentPerSqFt)
        ).toFixed(2);
      }

      this.dataModel.fristSet.improvements.forEach(element => {
        totalVal = Number(totalVal) + Number(element.value);
      });
      this.dataModel.fristSet.total = totalVal.toFixed(2);
      this.calculateAutoGeneratedValuesForFirstPanel();
    }

    if (position == 2) {
      if (index != undefined) {
        this.dataModel.secondSet.improvements[index].value = (
          Number(this.dataModel.secondSet.improvements[index].extent) *
          Number(this.dataModel.secondSet.improvements[index].rentPerSqFt)
        ).toFixed(2);
      }

      this.dataModel.secondSet.improvements.forEach(element => {
        totalVal = Number(totalVal) + Number(element.value);
      });
      this.dataModel.secondSet.total = totalVal.toFixed(2);
      this.calculateAutoGeneratedValuesForSecondPanel();
    }
  }

  calculateAutoGeneratedValuesForFirstPanel() {
    this.dataModel.fristSet.depreciation =
      (Number(this.buildingValueTotal) *
        Number(this.dataModel.fristSet.deductionPrecentage)) /
      100;

    this.dataModel.fristSet.depreciation = this.dataModel.fristSet.depreciation.toFixed(
      2
    );

    this.dataModel.fristSet.depreciationContruction =
      Number(this.buildingValueTotal) - this.dataModel.fristSet.depreciation;

    this.dataModel.fristSet.depreciationContruction = this.dataModel.fristSet.depreciationContruction.toFixed(
      2
    );

    this.dataModel.fristSet.valueOfTheProperty = (
      Number(this.landValueTotal) +
      Number(this.dataModel.fristSet.depreciationContruction) +
      Number(this.dataModel.fristSet.total)
    ).toFixed(2);

    this.dataModel.fristSet.marketValue = (
      Number(this.landValueTotal) +
      Number(this.dataModel.fristSet.depreciationContruction) +
      Number(this.dataModel.fristSet.total)
    ).toFixed(2);

    if (
      this.dataModel.secondSet.lowestValue != "" ||
      this.dataModel.secondSet.lowestValue != null
    ) {
      this.calculateAutoGeneratedValuesForSecondPanel();
    }
  }

  calculateAutoGeneratedValuesForSecondPanel() {
    this.dataModel.secondSet.depreciation =
      (Number(this.buildingValueTotal_) *
        Number(this.dataModel.secondSet.deductionPrecentage)) /
      100;

    this.dataModel.secondSet.depreciation = this.dataModel.secondSet.depreciation.toFixed(
      2
    );

    this.dataModel.secondSet.depreciationContruction = (
      Number(this.buildingValueTotal_) -
      Number(this.dataModel.secondSet.depreciation)
    ).toFixed(2);

    this.dataModel.secondSet.valueOfTheProperty = (
      Number(this.dataModel.secondSet.total) +
      Number(this.dataModel.secondSet.depreciationContruction) +
      Number(this.landValueTotal_)
    ).toFixed(2);

    this.dataModel.secondSet.marketValue =
      Number(this.landValueTotal_) +
      Number(this.dataModel.secondSet.depreciationContruction);

    this.dataModel.secondSet.lowestValue = this.dataModel.secondSet.valueOfTheProperty;
    if (this.mainFormDataModel.selectedNatureOfDeed == "gift"
    && this.dataModel.secondSet.marketValue > this.dataModel.fristSet.marketValue
    ) {
      this.dataModel.secondSet.lowestValue =this.dataModel.fristSet.marketValue;

    }else{
      this.dataModel.secondSet.lowestValue =this.dataModel.secondSet.marketValue;
    }

    // this.dataModel.secondSet.roundOffValue = this.dataModel.secondSet.lowestValue;
    if (this.selectedRecord == null || (this.dataModel.secondSet.roundOffValue == "" || this.dataModel.secondSet.roundOffValue == null || this.dataModel.secondSet.roundOffValue < 1)) {
      this.dataModel.secondSet.roundOffValue = this.dataModel.secondSet.lowestValue;
    } else {
      this.dataModel.secondSet.roundOffValue = this.dataModel.secondSet.roundOffValue;
    }
    // if(this.mainFormDataModel.selectedNatureOfDeed == "transfer"){
      // this.dataModel.secondSet.roundOffValue = this.selectedRecord.calculationLandBuilding[0].roundOffValue;
      // }else{
      // this.dataModel.secondSet.roundOffValue = this.selectedRecord.calculationLandBuilding[1].roundOffValue;
      // }


    // this.dataModel.secondSet.roundOffValue =
    //   this.dataModel.secondSet.roundOffValue == "" ||
    //   this.dataModel.secondSet.roundOffValue == null ||
    //   this.dataModel.secondSet.roundOffValue == "0.00"
    //     ? this.dataModel.secondSet.lowestValue
    //     : this.dataModel.secondSet.roundOffValue;

    let totalStampDuty = 0;
    let marketValue = 100000;
    let balanceStampDutyPrecentage = 4;
    if (this.mainFormDataModel.selectedNatureOfDeed == "gift") {
      marketValue = 50000;
      balanceStampDutyPrecentage = 2;
    }
    if (this.dataModel.secondSet.roundOffValue > marketValue) {
      let fMarketVal = (marketValue * 3) / 100;

      let sMarketVal =
        ((this.dataModel.secondSet.roundOffValue - marketValue) *
          balanceStampDutyPrecentage) /
        100;
      totalStampDuty = fMarketVal + sMarketVal;
      this.dataModel.secondSet.stampDuty = totalStampDuty;
    } else {
      let fMarketVal = (this.dataModel.secondSet.roundOffValue * 3) / 100;
      this.dataModel.secondSet.stampDuty = fMarketVal.toString();
    }
    // this.dataModel.secondSet.roundOffValue =
    //   this.dataModel.secondSet.roundOffValue == "" ||
    //   this.dataModel.secondSet.roundOffValue == null
    //     ? this.dataModel.secondSet.lowestValue
    //     : this.dataModel.secondSet.roundOffValue;

  }

  roundOffValueChangeEvent() {
    let totalStampDuty = 0;
    let marketValue = 100000;
    let balanceStampDutyPrecentage = 4;
    if (this.mainFormDataModel.selectedNatureOfDeed == "gift") {
      marketValue = 50000;
      balanceStampDutyPrecentage = 2;
    }
    if (this.dataModel.secondSet.roundOffValue > marketValue) {
      let fMarketVal = (marketValue * 3) / 100;

      let sMarketVal =
        ((this.dataModel.secondSet.roundOffValue - marketValue) *
          balanceStampDutyPrecentage) /
        100;
      totalStampDuty = fMarketVal + sMarketVal;
      this.dataModel.secondSet.stampDuty = totalStampDuty;
    } else {
      let fMarketVal = (this.dataModel.secondSet.roundOffValue * 3) / 100;
      this.dataModel.secondSet.stampDuty = fMarketVal.toString();
    }
  }

  saveDataModel() {
    if (
      this.dataModel.secondSet.stampDuty == null ||
      this.dataModel.secondSet.stampDuty < 1 ||
      this.dataModel.secondSet.stampDuty == ""
    ) {
      Swal.fire(
        "Error!",
        "Stamp duty not generated,Please check all fileds",
        "error"
      );
      return;
    }

    if (this.mainFormDataModel.selectedDate == "after") {
      if (this.headingDateOne == null) {
        Swal.fire("Error!", "Please select first heading date ", "error");
        return;
      }
    }

    if (this.headingDateTwo == null) {
      Swal.fire("Error!", "Please select second heading date ", "error");
      return;
    }

    this.dataModel.headingDateOne = this.headingDateOne;
    this.dataModel.headingDateTwo = this.headingDateTwo;
    this.sandDataModel.emit(this.dataModel);
  }

  goToFontPage() {
    this.frontPageEmitter.next(1);
    this.resetCalculation.next(1);
  }

  setDecisionOfCommitteeDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  saveDocumentsAndDecision(savedCalculationId) {

    this.fileUploadDecisionOfCommittee.outPutImagesSet();
    this.otherSelectedPaths = this.selectPaths;
    this.selectPathsCollection.push(this.otherSelectedPaths[0]);

    this.documentUploadService.uploadCalculationDocuments(this.selectPathsCollection, savedCalculationId, this.committeeDescision).subscribe(
      data => {},
      err => {
        this.notifier.notify('error', 'Document upload unsuccessful');
      }
    );
  }

  retrievDecisionOfCommitte() {
    this.apiService.getDecisionOfCommitteeByCalculationId(this.selectedRecord.id).subscribe(
      data => {
        if(data.data.decisionOfCommittee != "undefined") {
          this.committeeDescision = data.data.decisionOfCommittee;
        } else {
          this.committeeDescision = "-"
        }
      }
    )
  }

  // RETRIEVE DOCUMENTS - START
  onProgress(progressData: PDFProgressData) {
    if (progressData.loaded == progressData.total) {
      this.spinner.hide();
    }
  }

  previewFile(title) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.apiService.loadDocumentsByCalculationId(this.selectedRecord.id, title).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(() => {
          this.spinner.hide();
        }, 2000);

      } else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }
  // RETRIEVE DOCUMENTS - END
}
