import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { PropertyUsageSubtype } from 'src/app/model/propertyUsageSubtype';
import { PropertyUsageSubTypeNewModel } from 'src/app/model/propertyUsageSubTypeNewModel';
import { InspectionCommonService } from 'src/app/service/inspection-common/inspection-common.service';
import { PropertyUsageSubtypeService } from 'src/app/service/property-usage-subtype/property-usage-subtype.service';
import { PropertyUsageService } from 'src/app/service/property-usage/property-usage.service';
import { PropertyUsageSubTypeNewService } from 'src/app/service/subPropertyUsageSubTypeNew/property-usage-sub-type-new.service';
import { SubSink } from 'subsink';


@Component({
  selector: 'app-property-usage-sub-type-new',
  templateUrl: './property-usage-sub-type-new.component.html',
  styleUrls: ['./property-usage-sub-type-new.component.scss']
})
export class PropertyUsageSubTypeNewComponent implements OnInit, OnDestroy {

  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  createPropertyUsageSubType: FormGroup;
  updatePropertyUsageSubTypeForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  propertyUsageTypeList: any =[];
  activationButton: boolean;
  public createPropertyUsageSubTypeModel = new PropertyUsageSubTypeNewModel(null,'','',null);
  public updatePropertyUsageSubTypeModel = new PropertyUsageSubTypeNewModel(null,'','',null);

  private sub = new SubSink();
  subProperties: any;
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;


  constructor(
    private formBuilder: FormBuilder,
    private propertyUsageService:PropertyUsageService,
    private inspectionPropertyUsageSubTypeService: PropertyUsageSubTypeNewService,
    private notifier: NotifierService,
    
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createPropertyUsageSubType = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['',[Validators.pattern(this.NoWhitespaceRegExp)]],
      property_usage: ['', [Validators.required]],
    });
    this.updatePropertyUsageSubTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['', [Validators.pattern(this.NoWhitespaceRegExp)]],
      property_usage: ['', [Validators.required]],
    });
    this.loadPropertyUsageType();
    this.updateTheTable();
    
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }

  get f() { return this.createPropertyUsageSubType.controls; }

  
  loadPropertyUsageType() {
    this.propertyUsageService.getAllPropertyUsage().subscribe(
      data => {
        this.propertyUsageTypeList = data.data;
      }
    )
  }
  


  //create sub property - start

  setPropertyUsageSubTypeModelToSave(){
    this.createPropertyUsageSubTypeModel.name = this.createPropertyUsageSubType.get('name').value;
    this.createPropertyUsageSubTypeModel.description = this.createPropertyUsageSubType.get('description').value;
    this.createPropertyUsageSubTypeModel.propertyUsageId = this.createPropertyUsageSubType.get('property_usage').value;
  }

    //save sub property model
  savePropertyUsageSubType(){
    this.inspectionPropertyUsageSubTypeService.getPropertyUsageSubTypeType(0,this.totalItems).subscribe(res=>{
      const subPropertiesArray = (res as any).data.content;
      if (subPropertiesArray.map((propertyust)=>String(propertyust.name.replace(/\s/g, ""))).includes(String(this.createPropertyUsageSubType.get('name').value.replace(/\s/g, "")))){
        this.notifier.notify('error', 'Property sub type name "'+this.createPropertyUsageSubType.get('name').value+'" is exist');
        return;
      }else{
        if(this.createPropertyUsageSubType.valid){
          this.setPropertyUsageSubTypeModelToSave();
          this.sub.add(
            this.inspectionPropertyUsageSubTypeService.createPropertyUsageSubTypeType(this.createPropertyUsageSubTypeModel).subscribe(data=>{
              this.notifier.notify('success', (data as any).message);
              this.updateTheTable();
              this.pageable;
              this.reset();
            })
            );
        }else{
            this.f.name.markAsTouched();
            this.f.property_usage.markAsTouched();
          }
      }})
  }

  reset(){
    this.createPropertyUsageSubType.reset()
  }
  //create sub property - end

  //update sub property - start

    //get update controllers
  get f1() { return this.updatePropertyUsageSubTypeForm.controls; }

    //set data To employee
  setPropertyUsageSubType(propertyUsageSubType:any){
    this.updatePropertyUsageSubTypeForm.get('id').setValue(propertyUsageSubType.id);
    this.updatePropertyUsageSubTypeForm.get('name').setValue(propertyUsageSubType.name);
    this.updatePropertyUsageSubTypeForm.get('description').setValue(propertyUsageSubType.description);
    this.updatePropertyUsageSubTypeForm.get('property_usage').setValue(propertyUsageSubType.propertyUsage.id);


  }


    //setting data model to update
  setDataToModel(){
    this.updatePropertyUsageSubTypeModel.id = this.updatePropertyUsageSubTypeForm.get('id').value;
    this.updatePropertyUsageSubTypeModel.name = this.updatePropertyUsageSubTypeForm.get('name').value;
    this.updatePropertyUsageSubTypeModel.description = this.updatePropertyUsageSubTypeForm.get('description').value;
    this.updatePropertyUsageSubTypeModel.propertyUsageId = this.updatePropertyUsageSubTypeForm.get('property_usage').value;


  }


    //send updated data to db
  updatePropertyUsageSubType(){

    this.inspectionPropertyUsageSubTypeService.getPropertyUsageSubTypeType(0,this.totalItems).subscribe(res=>{
      const subPropertiesArray = (res as any).data.content;
      if (subPropertiesArray.map((propertyust)=>String(propertyust.name.replace(/\s/g, ""))).includes(String(this.updatePropertyUsageSubTypeForm.get('name').value.replace(/\s/g, "")))){
        this.notifier.notify('error', 'Property sub type name "'+this.updatePropertyUsageSubTypeForm.get('name').value+'" is exist');
        return;
      }else{
        if(this.updatePropertyUsageSubTypeForm.valid){
          this.setDataToModel();
          this.sub.add(this.inspectionPropertyUsageSubTypeService.updatePropertyUsageSubTypeType(this.updatePropertyUsageSubTypeModel.id, this.updatePropertyUsageSubTypeModel).subscribe(data=>{
            this.notifier.notify('success', (data as any).message);
            this.updateTheTable();
          }))
        }else{
          this.f1.property_usage.markAsTouched();
          this.f1.name.markAsTouched();
        }
      }})


  }

  //update sub property - end

//search and view table start
  pageable(i: number) {
    this.inspectionPropertyUsageSubTypeService.getPropertyUsageSubTypeType((i-1),10).subscribe(res=>{
      this.subProperties = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionPropertyUsageSubTypeService.getPropertyUsageSubTypeType(0, 10)
      .subscribe(
        (res) => {
          console.log(res)

          this.subProperties = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search Employee
    searchPropertyUsageSubType(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionPropertyUsageSubTypeService.searchPropertyUsageSubTypeType(this.keyword,0,10).subscribe(res=>{
          this.subProperties = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionPropertyUsageSubTypeService.deletePropertyUsageSubTypeType(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end


      
}
