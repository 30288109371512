import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { DocumentUploadCollectionPaymentComponent } from 'src/app/component/document/document-upload/document-upload-collection-payment/document-upload-collection-payment.component';
import { SendCollectionPayment } from 'src/app/model/sendCollectionPayment';
import { BankService } from 'src/app/service/bank/bank.service';
import { CollectionServiceService } from 'src/app/service/collectionService/collection-service.service';
import { DocumentUploadService } from 'src/app/service/document-upload/document-upload.service';
import { FileService } from 'src/app/service/file/file.service';

@Component({
  selector: 'app-add-payment-details-dialog',
  templateUrl: './add-payment-details-dialog.component.html',
  styleUrls: ['./add-payment-details-dialog.component.scss']
})
export class AddPaymentDetailsDialogComponent implements OnInit {
  @ViewChild('documentUploadPayent', {static: false}) documentUploadPayent: DocumentUploadCollectionPaymentComponent;

  selectPaths = [];
  selectPathsCollection = [];
  otherSelectedPaths = [];
  allBanks: any;
  balance:number = this.data.bal;
  collection = new SendCollectionPayment('','','','','',0,0,'',0,0,'')
  deedNo:number;
  hasOpinion:boolean
  userdata: any;
  RefundID = parseInt(localStorage.getItem("refundRequest"))
  paymentId:string;


  constructor(private formBuilder: FormBuilder,
    private collectionSer:CollectionServiceService,
    private bankService: BankService,
    private matDialogRef : MatDialogRef<AddPaymentDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private notifier: NotifierService,
    private documentUploadService: DocumentUploadService,
    private spinner: NgxSpinnerService,
    private fileService: FileService,
) {
    this.deedNo = parseInt(sessionStorage.getItem('deedNo'));
    this.paymentId="";

   }



  uploadPaymentForm = this.formBuilder.group({
      reciptNo:[''],
      referenceNo:[''],
      bank:[''],
      branch:[''],
      date:[''],
      amount:['',[Validators.required,Validators.pattern("^[0-9]*$")]],
      deedNo:[''],
  })



  fetchAllBanks() {
    this.bankService.getAll().subscribe(result => {
      if (result) {
        this.allBanks = result.data;
      } else {
      }
    }, error => {
    });
  }

  ngOnInit() {
    this.fetchAllBanks();
    this.setData();
  }

  setData(){
    if(sessionStorage.getItem('hasOpinion') == 'true'){
      this.uploadPaymentForm.controls['deedNo'].setValue(this.deedNo)
      this.hasOpinion = true;
    }else if(sessionStorage.getItem('hasOpinion') == 'false'){
      this.hasOpinion = false;
    }
  }
  upload(){

    if(this.uploadPaymentForm.valid){
      this.collection.amount = this.uploadPaymentForm.controls['amount'].value
      this.collection.bank =this.uploadPaymentForm.controls['bank'].value
      this.collection.branch = this.uploadPaymentForm.controls['branch'].value
      this.collection.deedNo = this.uploadPaymentForm.controls['deedNo'].value
      this.collection.reciptNo = this.uploadPaymentForm.controls['reciptNo'].value
      this.collection.refNo = this.uploadPaymentForm.controls['referenceNo'].value
      this.collection.date = this.uploadPaymentForm.controls['date'].value
      this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
      this.collection.refundId = this.RefundID;
      this.collection.collectionOfficerName = this.userdata.firstName + " " + this.userdata.lastName
      this.collectionSer.saveCollectionPayment(this.collection).subscribe(result=>{


        this.paymentId = (result as any).data.id;
        this.saveDocuments(this.selectPaths,this.paymentId);


      })

      this.setEveryChildComponentUploadingDocuments();
      this.notifier.notify('success', 'Succeessfully Updated');
      this.matDialogRef.close(this.collection)


    }else{
      this.notifier.notify('error', 'Fail')
      this.pay.amount.markAsTouched();
    }

  }


  get pay(){
    return this.uploadPaymentForm.controls;
  }


  setEveryChildComponentUploadingDocuments() {
    this.documentUploadPayent.outputDocumentsPaymentDetailsSet();
  }

  setCollectionPayment(documents: File[]) {
    this.selectPaths.push(documents);
  }

  saveDocuments(selectPathsCollection , collectionId:string) {
    this.selectPathsCollection.push(this.otherSelectedPaths[0]);
    this.documentUploadService.uploadCollectionPayment(selectPathsCollection, collectionId).subscribe(
      data => {
      },
      err => {
        this.notifier.notify('error', 'Document upload unsuccessful');
      }
    );

  }
}
