import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InspectionSecurityTypeService {

  private inspectionSecurityTypeUrl = '/inspection-security-type';


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  createSecurityType(SecurityTypeModel:InspectionCommonDataModel):Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionSecurityTypeUrl}`,SecurityTypeModel)
  }

  getSecurityType(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.inspectionSecurityTypeUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  updateSecurityType(id: number, SecurityTypeModel:InspectionCommonDataModel): Observable<any> {
    return this.http.put(`${environment.api_url}${this.inspectionSecurityTypeUrl}/${id}`, SecurityTypeModel);
  }

  searchSecurityType(keyWord:string,offset, limit):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.inspectionSecurityTypeUrl +'/search?keyword='+ keyWord + '&offset=' + offset + '&limit=' + limit + '');
  }

  searchSecurityTypeById(id:number):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.inspectionSecurityTypeUrl +'/'+ id);
  }

  deleteSecurityType(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.inspectionSecurityTypeUrl}/${id}`);
  }
}
