import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ProvinceService } from 'src/app/service/province/province.service';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-delete-province',
  templateUrl: './delete-province.component.html',
  styleUrls: ['./delete-province.component.scss']
})
export class DeleteProvinceComponent implements OnInit, OnDestroy {

  private provinceId: number;

  @Output() provinceDeleted = new EventEmitter<boolean>();

  private sub = new SubSink();
  constructor(private provinceService: ProvinceService, private notifier: NotifierService) { }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  updateTheView(deleted: boolean) {
    this.provinceDeleted.emit(deleted);
  }

  setProvinceId(id: number) {
    this.provinceId = id;
  }

  deleteProvince() {
    this.sub.add(this.provinceService.deleteProvince(this.provinceId)
    .subscribe(response => {
      this.updateTheView(true);
      this.notifier.notify('success', (response as any).message);
      }));
  }

}








