import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CompanyCategoryService} from '../../../../../../../service/company-category/company-category.service';
import {CompanyCategory} from '../../../../../../../model/companyCategory';
import {NotifierService} from 'angular-notifier' ;
import {SubSink} from 'subsink';
import {DataService} from '../../../../../../../service/data.service';

@Component({
  selector: 'app-add-company-category',
  templateUrl: './add-company-category.component.html',
  styleUrls: ['./add-company-category.component.scss']
})
export class AddCompanyCategoryComponent implements OnInit, OnDestroy {

  addCompanyCategoryForm: FormGroup;

  private sub = new SubSink();

  constructor(private companyCategoryService: CompanyCategoryService,
              private formBuilder: FormBuilder,
              private notifier: NotifierService,
              private dataService: DataService) {
  }

  ngOnInit() {
    this.addCompanyCategoryForm = this.formBuilder.group({
      description: ['', [Validators.required]],
      name: ['', [Validators.required]]
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  saveCompanyCategory() {
    if (this.addCompanyCategoryForm.valid) {
      const companyCategory = new CompanyCategory();
      companyCategory.name = this.getName();
      companyCategory.description = this.getDescription();

      this.sub.add(this.companyCategoryService.createCompanyCategory(companyCategory)
        .subscribe(response => {
          this.notifier.notify('success', (response as any).message);
          this.dataService.updateTableCompanyCategory(true);
        }));
    } else {
      this.addCompanyCategoryForm.markAllAsTouched();
    }
  }

  getName() {
    return this.addCompanyCategoryForm.get('name').value;
  }

  getDescription() {
    return this.addCompanyCategoryForm.get('description').value;
  }
}
