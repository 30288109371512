import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { NotificationModelUpdate } from 'src/app/model/notification-model-update';
import { RefundRequestCount } from 'src/app/model/refundRequestCount';
import { DashboardService } from 'src/app/service/dashboard/dashboard.service';
import { Constant } from 'src/app/util/constant';

@Component({
  selector: 'app-rim-secondary-dashboard',
  templateUrl: './rim-secondary-dashboard.component.html',
  styleUrls: ['./rim-secondary-dashboard.component.scss']
})
export class RimSecondaryDashboardComponent implements OnInit {

  public refundRequestCountModel = new RefundRequestCount('', '', '');
  public notificationModelUpdate = new NotificationModelUpdate();

  refundNotificationCode = Constant.NOTIFICATION_CATEGORY_REFUND
  opinionNotificationCode = Constant.NOTIFICATION_CATEGORY_OPINION
  appealNotificationCode = Constant.NOTIFICATION_CATEGORY_APPEAL
  rimNotificationCode = Constant.NOTIFICATION_CATEGORY_RIM

  numberOfNotifications: any;
  newRefundApplicationsCount: any;
  inprogressRefundApplicationsCount: any;
  rejectCount: any;
  completedCount:any;

  userData: any = [];
  userRoleCode: string;
  userName: string;

  notifications: any = [];

  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.userName = sessionStorage.getItem('username');
    this.obtainUserData();
    this.listNotifications();
  }

  obtainUserData() {
    this.userData = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.refundRequestCountModel.userId = this.userData.id;
    for (let role of this.userData.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.userRoleCode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.userRoleCode = Constant.ROLE_ACCESSOR;
        break;
      } else if (role.code === Constant.ROLE_TAX_OFFICER) {
        this.userRoleCode = Constant.ROLE_TAX_OFFICER;

        // Used to check whether the tax officer visited to Payable deeds print screen
        sessionStorage.setItem('visitedToPrintScreen', JSON.stringify(false));
        
        break;
      } else if (role.code === Constant.ROLE_ACCOUNTANT) {
        this.userRoleCode = Constant.ROLE_ACCOUNTANT;
        break;
      }
    }
    this.getNewApplicationCount();
    this.getInprogressApplicationCount();
    this.getCompletedApplicationCount();
    this.getRejectedApplicationCount();
  }

  getNewApplicationCount() {
    this.refundRequestCountModel.refundApplicationStatus = Constant.RIM_REQUEST_PENDING_CODE;
    this.refundRequestCountModel.userRoleCode = this.userRoleCode;
    this.dashboardService.getRimAplicationCount(this.refundRequestCountModel).subscribe(value => {
      this.newRefundApplicationsCount = value;
    });
  }

  getInprogressApplicationCount() {
    this.refundRequestCountModel.refundApplicationStatus = Constant.RIM_REQUEST_INPROGRESS_CODE;
    this.refundRequestCountModel.userRoleCode = this.userRoleCode;
    this.dashboardService.getRimAplicationCount(this.refundRequestCountModel).subscribe(value => {
      this.inprogressRefundApplicationsCount = value;
    });
  }
  getCompletedApplicationCount() {
    this.refundRequestCountModel.refundApplicationStatus = Constant.RIM_COMPLETED_APPLICATION;
    this.refundRequestCountModel.userRoleCode = this.userRoleCode;
    this.dashboardService.getRimAplicationCount(this.refundRequestCountModel).subscribe(value => {
      this.completedCount = value;
    });
  }
  getRejectedApplicationCount() {
    this.refundRequestCountModel.refundApplicationStatus = Constant.RIM_REQUEST_REJECTED_CODE;
    this.refundRequestCountModel.userRoleCode = this.userRoleCode;
    this.dashboardService.getRimAplicationCount(this.refundRequestCountModel).subscribe(value => {
      this.rejectCount = value;
    });
  }
  listNotifications() {
    this.dashboardService.getNotificationList().subscribe(
      data => {
        this.notifications = data.data;
        this.numberOfNotifications = this.notifications.length;
      }
    )
  }

  updateNotification(notificationId) {
    this.notificationModelUpdate.notificationId = notificationId;

    this.dashboardService.updateNotification(this.notificationModelUpdate).subscribe(
      data => {}
    )
  }

  logout(){
    this.authService.logout();
  }

}
