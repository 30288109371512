import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SubSink } from "subsink";
import { NotifierService } from "angular-notifier";
import { DataService } from "../../../../../../service/data.service";
import { UserService } from "../../../../../../service/user/user.service";
import { ProvinceService } from "../../../../../../service/province/province.service";
import { Province } from "../../../../../../model/province";
import { Role } from "../../../../../../model/role";
import { User } from "../../../../../../model/user";
import { UserType } from "../../../../../../model/userType";
import { passwordPolicy } from "src/app/model/passwordPolicy";
import { PasswordPolicyService } from "src/app/service/passwordPolicy/password-policy.service";
import { EmployeeService } from "src/app/service/employee/employee.service";
import { Employee } from "src/app/model/employee";

import {ConfirmedValidator} from '../../../../../validators/confirmed.validator';

@Component({
  selector: "app-search-user",
  templateUrl: "./search-user.component.html",
  styleUrls: ["./search-user.component.scss"]
})
export class SearchUserComponent implements OnInit, OnDestroy {
  addUserTypeForm: FormGroup;
  private sub = new SubSink();

  employeeId:any;
  firstName: any;
  lastName: any;
  email: any;
  password: any;
  rePassword: any;
  userRoles: UserType[];
  userId: string;
  roleName: any;
  accessorRoleCode: string;
  dCommisionerRoleCode: string;
  username: any;
  accessorCode: any;
  province: any;
  district: any;
  provincesOrdered: Province[];
  districts = [];
  selectedProvince: string;
  selectedUserRole: UserType;
  userRole: UserType;
  map: any;
  passwordPolicyPattern ='^(?=(.*[A-Z]){$7@})(?=(.*[0-9]){$1@})(?=(.*[#$@!%&*?]){$3@})[A-Za-z0-9#$@!%&*?]{$5@,$6@}$';

  public passwordPolicyModel = new passwordPolicy(null,'','','','','','','','','');
  public employeeModel = new Employee(null,"","","","","","","","","","","","","");
  @ViewChild("provinceEl", { static: true })
  provinceEl: ElementRef<HTMLSelectElement>;
  disableTextfield: boolean;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private provinceService: ProvinceService,
    private passwordPolicyService: PasswordPolicyService,
    private employeeService: EmployeeService,
  ) {
    this.disableTextfield = true;
  }

  ngOnInit() {
    //get password policy and edit pattern
    this.getPasswordPolicy();
    var passwordPattern = new RegExp(sessionStorage.getItem('passwordPolicy'));
    // this.disableTextfield = false;

    this.addUserTypeForm = this.formBuilder.group({
      employeeId:["",[Validators.pattern('^[0-9]*$')]],
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      userRole: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required,Validators.pattern(passwordPattern)]],
      re_password: ["", [Validators.required]],
      username: ["", [Validators.required]],
      accessorCode: ["", [Validators.required]],
      province: ["", [Validators.required]],
      district: ["", [Validators.required]],
      checkbox: [""]
    },{
      validator: ConfirmedValidator('password', 're_password')
    });
    this.fetchAllRoles();

    // get All Ordered Provinces
    this.getAllProvinces();
  }


  getPasswordPolicy(){
    this.passwordPolicyService.getActivePasswordPolicy().subscribe(data=>{
      if(data.data == null){
        this.passwordPolicyModel.minNumaric = "03";
        // this.passwordPolicyModel.maxNumaric = "";
        this.passwordPolicyModel.minSpecialChar = "03";
        // this.passwordPolicyModel.maxSpecialChar = "";
        this.passwordPolicyModel.minLength = "06";
        this.passwordPolicyModel.maxLength = "08";
        this.passwordPolicyModel.minUpperChar = "01";
      }else{
        this.passwordPolicyModel=(data as any).data;
      }
      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$1@",this.passwordPolicyModel.minNumaric);
      // this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$2@",this.passwordPolicyModel.maxNumaric);
      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$3@",this.passwordPolicyModel.minSpecialChar);
      // this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$4@",this.passwordPolicyModel.maxSpecialChar);
      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$5@",this.passwordPolicyModel.minLength);
      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$6@",this.passwordPolicyModel.maxLength);
      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$7@",this.passwordPolicyModel.minUpperChar);

      sessionStorage.setItem('passwordPolicy',this.passwordPolicyPattern);
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  get f() {
    return this.addUserTypeForm.controls;
  }

  saveUserType() {
    if (this.addUserTypeForm.valid) {
      const body = {
        employeeId:this.employeeId,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
        status: "ACTIVE",
        userType: this.userId,
        username: this.username,
        accessorCode: this.accessorCode,
        province: this.province,
        district: this.district
      };

      this.userService.saveInternalUser(body).subscribe(
        result => {
          console.log(result);
          if (result) {
            // this.resetForm();
            this.reset();
            this.notifier.notify("success", result.message);
          }
        },
        error => this.notifier.notify("error", error)
      );
    }
  }

  fetchAllRoles() {
    this.userService.fetchAllUserRoles().subscribe(result => {
      // const userRoleArray = [];
      // userRoleArray.push(result.data);
      this.userRoles = result.data;

      this.map = new Map();
      this.userRoles.forEach(element => {
        if (element.code === "RO-0004") {
          this.accessorRoleCode = element.id;
        }
        if (element.code === "RO-0006") {
          this.dCommisionerRoleCode = element.id;
        }
        this.map.set(element.id, element.code);
      });

      console.log(this.userRoles);
    });
  }

  getAllProvinces() {
    this.sub.add(
      this.provinceService.getProvincesOrderd().subscribe(res => {
        this.provincesOrdered = (res as any).data;
      })
    );
  }

  selectUserRole(ev) {
    this.userId = ev.target.value;
    // this.map.forEach(element => {
    //   console.log('id >>>>>>>>>>>>>>>>>>>>' + element.key);
    //   if (element.id === this.userId) {
    //     this.roleName = element.code;
    //     console.log('role Code >>>>>>>>>>>> ' + this.roleName);
    //   }
    // });

    this.enableAccessorCode();
  }

  reset() {
    this.addUserTypeForm.reset();
    // this.disableTextfield = false;
  }

  enableAccessorCode() {
    this.addUserTypeForm.get("province").reset();
    this.addUserTypeForm.get("district").reset();
    this.addUserTypeForm.patchValue({ accessorCode: null });

    if (this.userId.toString() === this.accessorRoleCode.toString()) {
      this.addUserTypeForm.get("accessorCode").enable();
      this.addUserTypeForm.get("province").enable();
    } else {
      this.addUserTypeForm.get("accessorCode").disable();
    }

    if (this.userId.toString() === this.dCommisionerRoleCode.toString()) {
      this.addUserTypeForm.get("province").enable();
    }

    if (
      this.userId.toString() !== this.accessorRoleCode.toString() &&
      this.userId.toString() !== this.dCommisionerRoleCode.toString()
    ) {
      this.addUserTypeForm.get("province").disable();
      this.addUserTypeForm.get("district").disable();
    }
  }

  getDistrictByProvince(value) {
    console.log("Selected Province Code >>>>>>" + value);
    this.addUserTypeForm.get("district").reset();
    if (value !== null) {
      this.addUserTypeForm.get("district").enable();
      const provinceElement = this.provinceEl.nativeElement;
      this.selectedProvince =
        provinceElement.options[provinceElement.selectedIndex].innerText;
      this.sub.add(
        this.provinceService
          .getDistrictByProvince(Number(value))
          .subscribe(res => {
            this.districts = (res as any).data;
          })
      );
    }
  }
  searchUser(){
    this.sub.add(this.employeeService.getASelectedEmployee(this.employeeId).subscribe(data=>{
      this.notifier.notify('default',(data as any).message);
      console.log(data.data);
      this.employeeModel = (data as any).data;
      if(this.employeeModel){
        this.addUserTypeForm.get('firstName').setValue(this.employeeModel.firstName);
        this.addUserTypeForm.get('lastName').setValue(this.employeeModel.lastName);
        this.addUserTypeForm.get('email').setValue(this.employeeModel.email);
        // this.disableTextfield = true;
      }

    }))
  }
}
