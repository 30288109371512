import {Component, OnDestroy, OnInit} from '@angular/core';
import { Province } from 'src/app/model/province';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { SubSink } from 'subsink';
import { ProvinceService } from 'src/app/service/province/province.service';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-search-province',
  templateUrl: './search-province.component.html',
  styleUrls: ['./search-province.component.scss']
})
export class SearchProvinceComponent implements OnInit, OnDestroy {


  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");

  addProvinceForm: FormGroup;
  private sub = new SubSink();
  constructor(private formBuilder: FormBuilder, private provinceService: ProvinceService,
              private dataService: DataService, private notifier: NotifierService) { }

  ngOnInit() {
    this.addProvinceForm = this.formBuilder.group({
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]]
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  saveProvince() {
    if (this.addProvinceForm.valid) {

      const province = new Province();

      province.description = this.getDescription();
      province.code = this.getCode();
      this.sub.add(this.provinceService.createProvince(province)
        .subscribe(response => {
          this.notifier.notify('success', (response as any).message);
          this.dataService.updateTableProvince(true);
        }));

      this.reset();
    }else {
      this.addProvinceForm.markAllAsTouched();
    }
  }

  get f() { return this.addProvinceForm.controls; }
  getDescription() {
    return this.addProvinceForm.get('description').value;
  }

  getCode() {
    return this.addProvinceForm.get('code').value;
  }

  reset() {
    this.addProvinceForm.reset();
  }
}

