import {Component, OnInit, OnDestroy} from '@angular/core';
import {CompanyCategoryService} from 'src/app/service/company-category/company-category.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {DataService} from 'src/app/service/data.service';
import {SubSink} from 'subsink';
import {CompanyCategory} from 'src/app/model/companyCategory';

@Component({
  selector: 'app-search-company-category',
  templateUrl: './search-company-category.component.html',
  styleUrls: ['./search-company-category.component.scss']
})
export class SearchCompanyCategoryComponent implements OnInit, OnDestroy {
  addCompanyCategoryForm: FormGroup;

  private sub = new SubSink();

  constructor(private companyCategoryService: CompanyCategoryService,
              private formBuilder: FormBuilder,
              private notifier: NotifierService,
              private dataService: DataService) {
  }

  get f() {
    return this.addCompanyCategoryForm.controls;
  }

  ngOnInit() {
    this.addCompanyCategoryForm = this.formBuilder.group({
      description: [''],
      name: ['', [Validators.required]]
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  saveCompanyCategory() {
    if (this.addCompanyCategoryForm.valid) {
      const companyCategory = new CompanyCategory();
      companyCategory.name = this.getName();
      companyCategory.description = this.getDescription();

      this.sub.add(this.companyCategoryService.createCompanyCategory(companyCategory)
        .subscribe(response => {
          this.notifier.notify('success', (response as any).message);
          this.dataService.updateTableCompanyCategory(true);
        }));

      this.reset();
    } else {
      this.addCompanyCategoryForm.markAllAsTouched();
    }
  }

  getName() {
    return this.addCompanyCategoryForm.get('name').value;
  }

  getDescription() {
    return this.addCompanyCategoryForm.get('description').value;
  }

  reset() {
    this.addCompanyCategoryForm.reset();
  }
}



