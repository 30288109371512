import { LandRegistryComponent } from './component/dashboards/dashboard-internal/admin/land-registry/land-registry.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './component/login/login.component';
import {DashboardExternalComponent} from './component/dashboards/dashboard-external/dashboard-external.component';
import {RegistrationComponent} from './component/registration/registration.component';
import {DashboardInternalComponent} from './component/dashboards/dashboard-internal/dashboard-internal.component';
import {AdminComponent} from './component/dashboards/dashboard-internal/admin/admin.component';
import {BuildingTypeComponent} from './component/dashboards/dashboard-internal/admin/building-type/building-type.component';
import {BuildnigSubTypeComponent} from './component/dashboards/dashboard-internal/admin/buildnig-sub-type/buildnig-sub-type.component';
import {DocumentTypeComponent} from './component/dashboards/dashboard-internal/admin/document-type/document-type.component';
import {FloorSubTypeComponent} from './component/dashboards/dashboard-internal/admin/floor-sub-type/floor-sub-type.component';
import {FloorTypeComponent} from './component/dashboards/dashboard-internal/admin/floor-type/floor-type.component';
import {GramaNiladariDivisionComponent} from './component/dashboards/dashboard-internal/admin/grama-niladari-division/grama-niladari-division.component';
import {LocalAuthorityComponent} from './component/dashboards/dashboard-internal/admin/local-authority/local-authority.component';
import {OfficeComponent} from './component/dashboards/dashboard-internal/admin/office/office.component';
import {PaymentMethodComponent} from './component/dashboards/dashboard-internal/admin/payment-method/payment-method.component';
import {ProvinceComponent} from './component/dashboards/dashboard-internal/admin/province/province.component';
import {CompanyCategoryComponent} from './component/dashboards/dashboard-internal/admin/company/company-category/company-category.component';
import {CompanyTypeComponent} from './component/dashboards/dashboard-internal/admin/company/company-type/company-type.component';
import {DeedNatureComponent} from './component/dashboards/dashboard-internal/admin/deed-nature/deed-nature/deed-nature.component';
import {NewApplicationComponent} from './component/dashboards/dashboard-external/assesment/new-application/new-application.component';
import {ViewRequestsComponent} from './component/dashboards/dashboard-external/assesment/view-assesment-request/view-requests/view-requests.component';
import {ExchangeComponent} from './component/dashboards/dashboard-external/assesment/new-application/exchange/exchange.component';
import {TransferComponent} from './component/dashboards/dashboard-external/assesment/new-application/transfer/transfer.component';
import {GiftComponent} from './component/dashboards/dashboard-external/assesment/new-application/gift/gift.component';
import {PropertyUsageComponent} from './component/dashboards/dashboard-internal/admin/property/property-usage/property-usage.component';
import {ProprtyTypeComponent} from './component/dashboards/dashboard-internal/admin/property/proprty-type/proprty-type.component';
import {PropertyFacilityComponent} from './component/dashboards/dashboard-internal/admin/property/property-facility/property-facility.component';
import {ViewAssessmentRequestComponent} from './component/dashboards/dashboard-external/assesment/view-assesment-request/view-assessment-request.component';
import {PaymentResponseComponent} from './component/dashboards/dashboard-external/assesment/new-application/payment-response/payment-response.component';
import {ForgotPasswordTempComponent} from './component/forgot-password-temp/forgot-password-temp.component';
import {ResetRedirectComponent} from './component/reset-redirect/reset-redirect.component';
import {BankComponent} from './component/dashboards/dashboard-internal/admin/bank/bank.component';
import {ChangePasswordComponent} from './component/change-password/change-password.component';
import {ViewAssessmentRequestInternalComponent} from './component/dashboards/dashboard-internal/assesment-internal/view-assessment-request-internal/view-assessment-request-internal.component';
import {ViewRequestsInternalComponent} from './component/dashboards/dashboard-internal/assesment-internal/view-assessment-request-internal/view-requests-internal/view-requests-internal.component';
import {UserTypeComponent} from './component/dashboards/dashboard-internal/admin/user/user-type.component';
import {DownloadModuleComponent} from './component/download-module/download-module.component';
import {RefundApplicationExternalUserComponent} from './component/dashboards/dashboard-external/refund-application-external-user/refund-application-external-user.component';
import {DashboardInternalUserComponent} from './component/dashboards/dashboard-internal-user/dashboard-internal-user.component';
import {ViewAssessmentRequestInternalUserComponent} from './component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-access-internal-user/view-assessment-request-iu.component';
import {WizardInternalUserComponent} from './component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/wizard-internal-user.component';
import {WizardInternalExchangeComponent} from './component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-exchange/wizard-internal-exchange.component';
import {FurtherRequirementUpdateComponent} from './component/dashboards/dashboard-external/further-requirement/further-requirement-update/further-requirement-update.component';
import {ExchangeFrComponent} from './component/dashboards/dashboard-external/further-requirement/exchange-fr/exchange-fr.component';
import {TransferFrComponent} from './component/dashboards/dashboard-external/further-requirement/transfer-fr/transfer-fr.component';
import {GiftFrComponent} from './component/dashboards/dashboard-external/further-requirement/gift-fr/gift-fr.component';
import { ViewRefundRequestUiComponent } from "./component/dashboards/dashboard-internal-user/refund-internal-user/view-refund-request-ui/view-refund-request-ui.component";
import { FirstDashboardInternalUserComponent } from './component/dashboards/dashboard-internal-user/first-dashboard-internal-user/first-dashboard-internal-user.component';
import { RefundInternalUserDashboardComponent } from './component/dashboards/dashboard-internal-user/refund-internal-user/refund-internal-user-dashboard/refund-internal-user-dashboard.component';
import { AsignAccessorForRefundComponent } from './component/dashboards/dashboard-internal-user/refund-internal-user/assign-assessor-for-refund/asign-accessor-for-refund.component';
import { ViewRefundSideNavigationComponent } from './component/dashboards/dashboard-internal-user/refund-internal-user/view-refund-request-ui/view-refund-new-applications/view-refund-side-navigation/view-refund-side-navigation.component';
import { ReportsComponent } from './component/dashboards/dashboard-internal-user/reports/reports.component';
import { StampDutyOpinionReportsComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/stamp-duty-opinion-reports.component';
import { OpinionRegistrationComponent } from './component/dashboards/dashboard-internal-user/reports/opinion-registration/opinion-registration.component';
// import { RefundOpinionReportsComponent } from './component/dashboards/dashboard-internal-user/reports/refund-opinion-reports/refund-opinion-reports.component';
// import { ReimbursementReportsComponent } from './component/dashboards/dashboard-internal-user/reports/reimbursement-reports/reimbursement-reports.component';
import { RimSideNavigationComponent } from './component/dashboards/dashboard-internal-user/reimb-intenal-user/rim-side-navigation/rim-side-navigation.component';
import { RimSecondaryDashboardComponent } from './component/dashboards/dashboard-internal-user/reimb-intenal-user/rim-secondary-dashboard/rim-secondary-dashboard.component';
import { RimViewNewApplicationSideNavComponent } from './component/dashboards/dashboard-internal-user/reimb-intenal-user/rim-secondary-dashboard/rim-view-new-application-side-nav/rim-view-new-application-side-nav.component';
import {ViewAppealRequestComponent} from './component/dashboards/dashboard-external/assesment/view-appeal-request/view-appeal-request.component';
import {DashboardAppealComponent} from './component/dashboards/dashboard-appeal/dashboard-appeal.component';
import {ViewAssessmentAppealComponent} from './component/dashboards/dashboard-appeal/view-assessment-appeal/view-assessment-appeal.component';
import {AppealWizardComponent} from './component/dashboards/dashboard-appeal/view-assessment-appeal/appeal-wizard/appeal-wizard.component';
import { AuthGuard } from './auth/auth.guard';
import { UserRoleGuard } from './auth/user-role-guard';
import { InternalUserAuth } from './auth/internal-user-auth';
import { PasswordPolicyComponent } from './component/dashboards/dashboard-internal/admin/password-policy/password-policy.component';
import { EmployeeComponent } from './component/dashboards/dashboard-internal/admin/employee/employee.component';
import { InspectionMasterDataComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-master-data.component';
import { InspectionSubPropertyComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-sub-property/inspection-sub-property.component';
import { InspectionReservationTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-reservation-type/inspection-reservation-type.component';
import { InspectionLandShapeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-land-shape/inspection-land-shape.component';
import { InspectionLandSlopeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-land-slope/inspection-land-slope.component';
import { InspectionWaterAvailabilityTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-water-availability-type/inspection-water-availability-type.component';
import { InspectionWaterSourceComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-water-source/inspection-water-source.component';
import { InspectionElectricityTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-electricity-type/inspection-electricity-type.component';
import { InspectionElectricitySubTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-electricity-sub-type/inspection-electricity-sub-type.component';
import { InspectionLandSutabilityComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-land-sutability/inspection-land-sutability.component';
import { InspectionTransportedTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-transported-type/inspection-transported-type.component';
import { InspectionEducationTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-education-type/inspection-education-type.component';
import { InspectionIndustrialTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-industrial-type/inspection-industrial-type.component';
import { InspectionHealthTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-health-type/inspection-health-type.component';
import { InspectionSecurityTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-security-type/inspection-security-type.component';
import { InspectionTelecomunicationTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-telecommunication-type/inspection-telecommunication-type.component';
import { InspectionBusinessTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-business-type/inspection-business-type.component';
import { InspectionIndustrialAreaTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-industrial-area-type/inspection-industrial-area-type.component';
import { InspectionCultivationTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-cultivation-type/inspection-cultivation-type.component';
import { InspectionLandTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-land-type/inspection-land-type.component';
import { InspectionTownComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-town/inspection-town.component';
import { InspectionCondominiumTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-condominium-type/inspection-condominium-type.component';
import { InspectionFacilityTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-facility-type/inspection-facility-type.component';
import { InspectionParkingTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-parking-type/inspection-parking-type.component';
import { InspectionGardenTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-garden-type/inspection-garden-type.component';
import { InspectionViewTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-view-type/inspection-view-type.component';
import { InspectionAgreementTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-agreement-type/inspection-agreement-type.component';
import { InspectionAccomodationTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-accomodation-type/inspection-accomodation-type.component';
import { InspectionSurroundTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-surround-type/inspection-surround-type.component';
import { PropertyUsageSubTypeNewComponent } from './component/dashboards/dashboard-internal/admin/property/property-usage-sub-type-new/property-usage-sub-type-new.component';
import { DistrictNewComponent } from './component/dashboards/dashboard-internal/admin/district/district-new/district-new.component';
import { GramaNiladariDivisionNewComponent } from './component/dashboards/dashboard-internal/admin/grama-niladari-division/grama-niladari-division-new/grama-niladari-division-new.component';
import { PrintNonPayableComponent } from './component/dashboards/dashboard-internal-user/reimb-intenal-user/print-non-payable/print-non-payable.component';
import { PrintPayableComponent } from './component/dashboards/dashboard-internal-user/reimb-intenal-user/print-payable/print-payable.component';
import { InspectionCategoryPropertyTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-category-property-type/inspection-category-property-type.component';
import {CommisionerAppealApplicationComponent} from './component/admin-dashboards/commisioner/commisioner-appeal-application/commisioner-appeal-application.component';
import {CommisionerOpinionApplicationComponent} from './component/admin-dashboards/commisioner/commisioner-opinion-application/commisioner-opinion-application.component';
import {CommisionerRefundApplicationComponent} from './component/admin-dashboards/commisioner/commisioner-refund-application/commisioner-refund-application.component';
import {CommisionerReimbursementApplicationComponent} from './component/admin-dashboards/commisioner/commisioner-reimbursement-application/commisioner-reimbursement-application.component';
import {DeputyCommisionerComponent} from './component/admin-dashboards/deputy-commisioner/deputy-commisioner.component';
import {DCommisionerAppealApplicationComponent} from './component/admin-dashboards/deputy-commisioner/d-commisioner-appeal-application/d-commisioner-appeal-application.component';
import {DCommisionerOpinionApplicationComponent} from './component/admin-dashboards/deputy-commisioner/d-commisioner-opinion-application/d-commisioner-opinion-application.component';
import {DCommisionerRefundApplicationComponent} from './component/admin-dashboards/deputy-commisioner/d-commisioner-refund-application/d-commisioner-refund-application.component';
import {DCommisionerReimbursementApplicationComponent} from './component/admin-dashboards/deputy-commisioner/d-commisioner-reimbursement-application/d-commisioner-reimbursement-application.component';
import {AssessorComponent} from './component/admin-dashboards/assessor/assessor.component';
import {AssessorAppealApplicationComponent} from './component/admin-dashboards/assessor/assessor-appeal-application/assessor-appeal-application.component';
import {AssessorOpinionApplicationComponent} from './component/admin-dashboards/assessor/assessor-opinion-application/assessor-opinion-application.component';
import {AssessorRefundApplicationComponent} from './component/admin-dashboards/assessor/assessor-refund-application/assessor-refund-application.component';
import {AssessorReimbursementApplicationComponent} from './component/admin-dashboards/assessor/assessor-reimbursement-application/assessor-reimbursement-application.component';
import {TaxOfficerComponent} from './component/admin-dashboards/tax-officer/tax-officer.component';
import {TaxOfficerAppealApplicationComponent} from './component/admin-dashboards/tax-officer/tax-officer-appeal-application/tax-officer-appeal-application.component';
import {TaxOfficerOpinionApplicationComponent} from './component/admin-dashboards/tax-officer/tax-officer-opinion-application/tax-officer-opinion-application.component';
import {TaxOfficerRefundApplicationComponent} from './component/admin-dashboards/tax-officer/tax-officer-refund-application/tax-officer-refund-application.component';
import {TaxOfficerReimbursementApplicationComponent} from './component/admin-dashboards/tax-officer/tax-officer-reimbursement-application/tax-officer-reimbursement-application.component';
import {CommisionerComponent} from './component/admin-dashboards/commisioner/commisioner.component';
import { FirstDashboardExternalUserComponent } from './component/dashboards/dashboard-external/first-dashboard-external-user/first-dashboard-external-user.component';
import { RefundExternalUserDashboardComponent } from './component/dashboards/dashboard-external/secondary-dashboards-external/refund-external-user-dashboard/refund-external-user-dashboard.component';
import { ViewRefundApplicationComponent } from './component/dashboards/dashboard-external/refund-application-external-user/view-refund-details/view-refund-application/view-refund-application.component';
import {RefundOpinionReportsComponent} from './component/dashboards/dashboard-internal-user/reports/refund-opinion-reports/refund-opinion-reports.component';
import {ReimbursementReportsComponent} from './component/dashboards/dashboard-internal-user/reports/reimbursement-reports/reimbursement-reports.component';

const routes: Routes = [
  {path: '', component: LoginComponent, data: {title: 'Login'}},
  {path: 'login', component: LoginComponent, data: {title: 'Login'}},
  // {path: 'dashboard', component: DashboardExternalComponent, data: {title: 'Dashboard External'}, canActivate:[AuthGuard,UserRoleGuard]},
  {path: 'dashboard', component: FirstDashboardExternalUserComponent, data: {title: 'Dashboard External'}, canActivate:[AuthGuard,UserRoleGuard]},
  {path: 'opinion-external-user-dashboard', component: DashboardExternalComponent, data: {title: 'Dashboard Opinion'}, canActivate:[AuthGuard,UserRoleGuard]},
  {path: 'refund-external-user-dashboard', component: RefundExternalUserDashboardComponent, data: {title: 'Dashboard Refund'}, canActivate:[AuthGuard,UserRoleGuard]},
  {path: 'registration', component: RegistrationComponent, data: {title: 'Registration'}},
  {path: 'forgotPassword', component: ForgotPasswordTempComponent, data: {title: 'Forgot Password'}},
  {path: 'reset', component: ResetRedirectComponent, data: {title: 'Reset Password'}},
  {path: 'changePassword', component: ChangePasswordComponent, canActivate:[AuthGuard]},
  {path: 'dashboard-internal-user', component: DashboardInternalUserComponent, data: {title: 'Dashboard Internal User'}, canActivate:[AuthGuard,InternalUserAuth]},
  {path: 'dashboard-appeal', component: DashboardAppealComponent, data: {title: 'Dashboard Appeal'}, canActivate:[AuthGuard,InternalUserAuth]},
  {path: 'dashboard-internal', component: DashboardInternalComponent, data: {title: 'Dashboard Internal'}, canActivate:[AuthGuard,InternalUserAuth]},
  {path: 'opinion-registration', component: OpinionRegistrationComponent, data: {title: 'Opinion Registration'}, canActivate:[AuthGuard]},
  {path: 'main-dashboard-internal-user', component: FirstDashboardInternalUserComponent, data: {title: 'First Dashboard Internal User'}, canActivate:[AuthGuard,InternalUserAuth]},
  {path: 'refund-internal-user-dashboard', component: RefundInternalUserDashboardComponent, data: {title: 'Refund Internal User Dashboard'}, canActivate:[AuthGuard,InternalUserAuth]},
  {path: 'reimbursment-internal-user-dashboard', component: RimSecondaryDashboardComponent, data: {title: 'Reimbursment Internal User Dashboard'}, canActivate:[AuthGuard,InternalUserAuth]},
  {
    path: 'admin', component: AdminComponent, data: {title: 'Admin'}, canActivate:[AuthGuard,InternalUserAuth],
    children: [
      {path: 'company-category', component: CompanyCategoryComponent},
      {path: 'company-type', component: CompanyTypeComponent},
      {path: 'user-type', component: UserTypeComponent},
      {path: 'building-type', component: BuildingTypeComponent},
      {path: 'building-sub-type', component: BuildnigSubTypeComponent},
      {path: 'district', component: DistrictNewComponent},
      {path: 'document-type', component: DocumentTypeComponent},
      {path: 'floor-sub-type', component: FloorSubTypeComponent},
      {path: 'floor-type', component: FloorTypeComponent},
      {path: 'grama-niladari-division', component: GramaNiladariDivisionNewComponent},
      {path: 'local-authority', component: LocalAuthorityComponent},
      {path: 'office', component: OfficeComponent},
      {path: 'payment-method', component: PaymentMethodComponent},
      {path: 'property-usage', component: PropertyUsageComponent},
      {path: 'property-type', component: ProprtyTypeComponent},
      {path: 'property-usage-subtype', component: PropertyUsageSubTypeNewComponent},
      // {path: 'property-usage-subtype', component: PropertyUsageSubtypeComponent},
      {path: 'province', component: ProvinceComponent},
      {path: 'deed-nature', component: DeedNatureComponent},
      {path: 'company-category', component: CompanyCategoryComponent},
      {path: 'company-type', component: CompanyTypeComponent},
      {path: 'building-type', component: BuildingTypeComponent},
      {path: 'building-sub-type', component: BuildnigSubTypeComponent},
      // {path: 'district', component: DistrictComponent},
      {path: 'document-type', component: DocumentTypeComponent},
      {path: 'floor-sub-type', component: FloorSubTypeComponent},
      {path: 'floor-type', component: FloorTypeComponent},
      // {path: 'grama-niladari-division', component: GramaNiladariDivisionComponent},
      {path: 'local-authority', component: LocalAuthorityComponent},
      {path: 'office', component: OfficeComponent},
      {path: 'payment-method', component: PaymentMethodComponent},
      {path: 'property-facility', component: PropertyFacilityComponent},
      {path: 'property-usage', component: PropertyUsageComponent},
      {path: 'proprty-type', component: ProprtyTypeComponent},
      {path: 'province', component: ProvinceComponent},
      {path: 'bank', component: BankComponent},
      {path: 'password-policy', component: PasswordPolicyComponent},
      {path: 'employee', component: EmployeeComponent},
      {path: 'land-registry', component: LandRegistryComponent},
    ]
  },{
    path: 'inspection-master-data', component: InspectionMasterDataComponent, data: {title: 'Inspection Master Data'}, canActivate:[AuthGuard,InternalUserAuth],
    children: [
      {path: 'inspection-sub-property', component: InspectionSubPropertyComponent},
      {path: 'inspection-reservation-type', component: InspectionReservationTypeComponent},
      {path: 'inspection-land-shape', component: InspectionLandShapeComponent},
      {path: 'inspection-land-slope', component: InspectionLandSlopeComponent},
      {path: 'inspection-water-availability-type', component: InspectionWaterAvailabilityTypeComponent},
      {path: 'inspection-water-source', component: InspectionWaterSourceComponent},
      {path: 'inspection-electricity', component: InspectionElectricityTypeComponent},
      {path: 'inspection-electricity-sub', component: InspectionElectricitySubTypeComponent},
      {path: 'inspection-land-suitability', component: InspectionLandSutabilityComponent},
      {path: 'inspection-transported-type', component: InspectionTransportedTypeComponent},
      {path: 'inspection-education-type', component: InspectionEducationTypeComponent},
      {path: 'inspection-industrial-type', component: InspectionIndustrialTypeComponent},
      {path: 'inspection-health-type', component: InspectionHealthTypeComponent},
      {path: 'inspection-security-type', component: InspectionSecurityTypeComponent},
      {path: 'inspection-telecomunication-type', component: InspectionTelecomunicationTypeComponent},
      {path: 'inspection-business-type', component: InspectionBusinessTypeComponent},
      {path: 'inspection-industrial-area-type', component: InspectionIndustrialAreaTypeComponent},
      {path: 'inspection-cultivation-type', component: InspectionCultivationTypeComponent},
      {path: 'inspection-land-type', component: InspectionLandTypeComponent},
      {path: 'inspection-town', component: InspectionTownComponent},
      {path: 'inspection-condominium-type', component: InspectionCondominiumTypeComponent},
      {path: 'inspection-facility-type', component: InspectionFacilityTypeComponent},
      {path: 'inspection-parking-type', component: InspectionParkingTypeComponent},
      {path: 'inspection-garden-type', component: InspectionGardenTypeComponent},
      {path: 'inspection-view-type', component: InspectionViewTypeComponent},
      {path: 'inspection-agreement-type', component: InspectionAgreementTypeComponent},
      {path: 'inspection-accomodation-type', component: InspectionAccomodationTypeComponent},
      {path: 'inspection-surround-type', component: InspectionSurroundTypeComponent},
      {path: 'inspection-category-property-type', component: InspectionCategoryPropertyTypeComponent},

    ]
  },
  {
    path: 'reports', component: ReportsComponent, data: {title: 'Reports'}, canActivate:[AuthGuard,InternalUserAuth],
    children: [
      {path: 'stamp-duty-opinion-reports', component: StampDutyOpinionReportsComponent, data: {title: 'Stamp Duty Opinion Reports'}},
      // {path: 'refund-opinion-reports', component: RefundOpinionReportsComponent, data: {title: 'Refund Opinion Reports'}},
      // {path: 'reimbursement-reports', component: ReimbursementReportsComponent, data: {title: 'Reimbursements Reports'}}
    ]
  },
  {
    path: 'reports', component: ReportsComponent, data: {title: 'Reports'}, canActivate:[AuthGuard,InternalUserAuth],
    children: [
      {path: 'refund-opinion-reports', component: RefundOpinionReportsComponent, data: {title: 'Refund Opinion Reports'}},
      // {path: 'refund-opinion-reports', component: RefundOpinionReportsComponent, data: {title: 'Refund Opinion Reports'}},
      // {path: 'reimbursement-reports', component: ReimbursementReportsComponent, data: {title: 'Reimbursements Reports'}}
    ]
  },
  {
    path: 'reports', component: ReportsComponent, data: {title: 'Reports'}, canActivate:[AuthGuard,InternalUserAuth],
    children: [
      {path: 'reimbursement-reports', component: ReimbursementReportsComponent, data: {title: 'Reimbursement Reports'}},
    ]
  },
  {
    path: 'new-application', component: NewApplicationComponent, canActivate:[AuthGuard,UserRoleGuard],
    children: [
      {path: 'exchange', component: ExchangeComponent},
      {path: 'transfer', component: TransferComponent},
      {path: 'gift', component: GiftComponent},

    ]
  },
  {
    path: 'further-requirement-update', component: FurtherRequirementUpdateComponent, canActivate:[AuthGuard,UserRoleGuard],
    children: [
      {path: 'exchange-further-requirement', component: ExchangeFrComponent},
      {path: 'transfer-further-requirement', component: TransferFrComponent},
      {path: 'gift-further-requirement', component: GiftFrComponent},

    ]
  }, {
    path: 'view-assessment-internal-user/:type', component: ViewAssessmentRequestInternalUserComponent, canActivate:[AuthGuard,InternalUserAuth]
  }, {
    path: 'view-assessment-appeal/:type', component: ViewAssessmentAppealComponent, canActivate:[AuthGuard,InternalUserAuth]
  },
  {
    path: 'view-assessment/:type', component: ViewAssessmentRequestComponent, canActivate:[AuthGuard,UserRoleGuard]
    // path: 'view-assessment', component: ViewAssessmentRequestComponent,
    // children: [
    //   {path: 'pending-requests', component: PendingRequestsComponent},
    //   {path: 'completed-requests', component: CompletedRequestsComponent},
    //   {path: 'rejected-requests', component: RejectedRequestsComponent},
    // ]
  },
  {
    path: 'view-refund-assessment/:type', component: ViewRefundApplicationComponent, canActivate:[AuthGuard,UserRoleGuard]
  },
  {path: 'view-request/:id', component: ViewRequestsComponent, canActivate:[AuthGuard,UserRoleGuard]},
  {path: 'payment-response', component: PaymentResponseComponent, canActivate:[AuthGuard,UserRoleGuard]},
  {path: 'view-assessment-internal/:type', component: ViewAssessmentRequestInternalComponent, canActivate:[AuthGuard,InternalUserAuth]},
  {path: 'view-request-internal/:id', component: ViewRequestsInternalComponent, canActivate:[AuthGuard,InternalUserAuth]},
  {path: 'download-module', component: DownloadModuleComponent, canActivate:[AuthGuard]},
  {path: 'view-internal-user-application/:id', component: WizardInternalUserComponent, canActivate:[AuthGuard,InternalUserAuth]},
  {path: 'view-appeal-application/:id', component: AppealWizardComponent, canActivate:[AuthGuard,InternalUserAuth]},

  // REFUND APPLICATION
  {path: 'refund-application/:id', component: RefundApplicationExternalUserComponent, canActivate:[AuthGuard,UserRoleGuard]},
  {path: 'refund-application', component: RefundApplicationExternalUserComponent, canActivate:[AuthGuard,UserRoleGuard]},
  {path: 'view-internal-exchange-application/:id', component: WizardInternalExchangeComponent, canActivate:[AuthGuard,InternalUserAuth]},
  {path: 'view-refund-req/:type',component: ViewRefundRequestUiComponent, canActivate:[AuthGuard,InternalUserAuth ]},
  {path: 'asign-accessor',component: AsignAccessorForRefundComponent, canActivate:[AuthGuard,InternalUserAuth]},
  {path: 'view-refund-internal-user-application/:id', component: ViewRefundSideNavigationComponent, canActivate:[AuthGuard,InternalUserAuth]},

  //REIMBURSEMENT APPLICATION
  {path: 'reimbursment-application/:type',component: RimSideNavigationComponent, canActivate:[AuthGuard,InternalUserAuth]},
  {path: 'reimbursement-application-view/:type',component: RimViewNewApplicationSideNavComponent, canActivate:[AuthGuard,InternalUserAuth]},
  {path: 'print-non-payable', component: PrintNonPayableComponent, data: {title: 'Print non-payable'}, canActivate:[AuthGuard, InternalUserAuth]},
  {path: 'print-payable', component: PrintPayableComponent, data: {title: 'Print payable'}, canActivate:[AuthGuard, InternalUserAuth]},
  {path: 'view-appeal-request/:id', component: ViewAppealRequestComponent, canActivate:[AuthGuard,UserRoleGuard]},


  // #### ADMIN DASHBOARD - Commissioner
  {path: 'dashboard/commissioner', component: CommisionerComponent, data: {title: 'Dashboard Commissioner'}},
  // {path: 'dashboard/commisioner/appeal-application', component: CommisionerAppealApplicationComponent, data: {title: 'Dashboard Commissioner'}, canActivate:[AuthGuard,UserRoleGuard]},
  // {path: 'dashboard/commisioner/opinion-application', component: CommisionerOpinionApplicationComponent, data: {title: 'Dashboard Commissioner'}, canActivate:[AuthGuard,UserRoleGuard]},
  // {path: 'dashboard/commisioner/refund-application', component: CommisionerRefundApplicationComponent, data: {title: 'Dashboard Commissioner'}, canActivate:[AuthGuard,UserRoleGuard]},
  // {path: 'dashboard/commisioner/reimbursement-application', component: CommisionerReimbursementApplicationComponent, data: {title: 'Dashboard Commissioner'}, canActivate:[AuthGuard,UserRoleGuard]},

  // #### ADMIN DASHBOARD - Deputy Commisioner
  {path: 'dashboard/deputy-commissioner', component: DeputyCommisionerComponent, data: {title: 'Dashboard Deputy Commissioner'}},
  // {path: 'dashboard/deputy-commisioner/appeal-application', component: DCommisionerAppealApplicationComponent, data: {title: 'Dashboard Deputy Commissioner'}, canActivate:[AuthGuard,UserRoleGuard]},
  // {path: 'dashboard/deputy-commisioner/opinion-application', component: DCommisionerOpinionApplicationComponent, data: {title: 'Dashboard Deputy Commissioner'}, canActivate:[AuthGuard,UserRoleGuard]},
  // {path: 'dashboard/deputy-commisioner/refund-application', component: DCommisionerRefundApplicationComponent, data: {title: 'Dashboard Deputy Commissioner'}, canActivate:[AuthGuard,UserRoleGuard]},
  // {path: 'dashboard/deputy-commisioner/reimbursement-application', component: DCommisionerReimbursementApplicationComponent, data: {title: 'Dashboard Deputy Commissioner'}, canActivate:[AuthGuard,UserRoleGuard]},

  // #### ADMIN DASHBOARD - Assessor
  {path: 'dashboard/assessor', component: AssessorComponent, data: {title: 'Dashboard Assessor'}},
  // {path: 'dashboard/assessor/appeal-application', component: AssessorAppealApplicationComponent, data: {title: 'Dashboard Assessor'}, canActivate:[AuthGuard,UserRoleGuard]},
  // {path: 'dashboard/assessor/opinion-application', component: AssessorOpinionApplicationComponent, data: {title: 'Dashboard Assessor'}, canActivate:[AuthGuard,UserRoleGuard]},
  // {path: 'dashboard/assessor/refund-application', component: AssessorRefundApplicationComponent, data: {title: 'Dashboard Assessor'}, canActivate:[AuthGuard,UserRoleGuard]},
  // {path: 'dashboard/assessor/reimbursement-application', component: AssessorReimbursementApplicationComponent, data: {title: 'Dashboard Assessor'}, canActivate:[AuthGuard,UserRoleGuard]},

  // #### ADMIN DASHBOARD - Tax Officer
  {path: 'dashboard/tax-officer', component: TaxOfficerComponent, data: {title: 'Dashboard Tax Officer'}, canActivate:[AuthGuard,UserRoleGuard]},
  // {path: 'dashboard/tax-officer/appeal-application', component: TaxOfficerAppealApplicationComponent, data: {title: 'Dashboard Tax Officer'}, canActivate:[AuthGuard,UserRoleGuard]},
  // {path: 'dashboard/tax-officer/opinion-application', component: TaxOfficerOpinionApplicationComponent, data: {title: 'Dashboard Tax Officer'}, canActivate:[AuthGuard,UserRoleGuard]},
  // {path: 'dashboard/tax-officer/refund-application', component: TaxOfficerRefundApplicationComponent, data: {title: 'Dashboard Tax Officer'}, canActivate:[AuthGuard,UserRoleGuard]},
  // {path: 'dashboard/tax-officer/reimbursement-application', component: TaxOfficerReimbursementApplicationComponent, data: {title: 'Dashboard Tax Officer'}, canActivate:[AuthGuard,UserRoleGuard]},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
