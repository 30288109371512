import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { InspectionTownModel } from 'src/app/model/inspection-master-data-model/inspection-town-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InspectionTownService {

  private inspectionTownUrl = '/inspection-town';


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  createTown(TownModel:InspectionTownModel):Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionTownUrl}`,TownModel)
  }

  getTown(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.inspectionTownUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  updateTown(id: number, TownModel:InspectionTownModel): Observable<any> {
    return this.http.put(`${environment.api_url}${this.inspectionTownUrl}/${id}`, TownModel);
  }

  searchTown(keyWord:string,offset, limit):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.inspectionTownUrl +'/search?keyword='+ keyWord + '&offset=' + offset + '&limit=' + limit + '');
  }

  deleteTown(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.inspectionTownUrl}/${id}`);
  }
}
