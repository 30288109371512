import {Component, OnInit, ViewChild} from '@angular/core';
import {ViewPropertyFacilityComponent} from '../property-facility/view-property-facility/view-property-facility.component';
import {ViewPropertyUsageComponent} from './view-property-usage/view-property-usage.component';

@Component({
  selector: 'app-property-usage',
  templateUrl: './property-usage.component.html',
  styleUrls: ['./property-usage.component.scss']
})
export class PropertyUsageComponent implements OnInit {

  @ViewChild(ViewPropertyUsageComponent, {static: false})
  private viewPropertyUsageComponent: ViewPropertyUsageComponent;
  keyword: any;

  constructor() { }

  ngOnInit() {
  }

  triggerSearch(event: any) {
    if (this.keyword === '') {
      this.viewPropertyUsageComponent.onUpdated(true);

    } else if(!isNaN(this.keyword)){
      this.viewPropertyUsageComponent.searchPropertyUsageById(this.keyword);

    }else {
      this.viewPropertyUsageComponent.searchPropertyUsage(this.keyword);
    }
  }

}
