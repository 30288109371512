import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { InspectionCommonService } from 'src/app/service/inspection-common/inspection-common.service';
import { InspectionLand } from 'src/app/model/inspection-land';
import { Constant } from 'src/app/util/constant';
import { InspectionService } from 'src/app/service/inspection/inspection.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as L2 from 'leaflet';
import { InspectionCommon } from 'src/app/model/inspection-common';
import { InspectionDataServiceService } from 'src/app/service/data-service/inspection-data-service.service';
import { NotifierService } from 'angular-notifier';
import { NgForm } from '@angular/forms';
import { checkCompletedOrPaypend } from '../../wizard-internal-user/view-requests-internal-user/Util/checkStatus';

@Component({
  selector: 'app-inspection-report-land-party2',
  templateUrl: './inspection-report-land-party2.component.html',
  styleUrls: ['./inspection-report-land-party2.component.scss']
})
export class InspectionReportLandParty2Component implements OnInit {

  @ViewChild('f', null)
  f: NgForm;
  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('popupSuccessMessage', null) private popupMigrated: TemplateRef<object>;

  @Input()
  valuationRequestId: string;

  @Input()
  isAppeal:boolean;

  @Input()
  valuationRequestStatus: string;

  public initialview: boolean;

  selectedPropertyType: any;
  selectedSubPropertyType: any;

  public viewCommericalLand: boolean;
  public viewAgricultureLand: boolean;
  public viewMixModeLand: boolean;
  public viewRecreationLand: boolean;

  public model = new InspectionLand();

  public reservationTypeList: any = [];
  public landShapeTypeList: any = [];
  public landSlopeTypeList: any = [];
  public landLocationTypeList: any = [];
  public waterAvailabilityTypeList: any = [];
  public waterTypeList: any = [];
  public electricityTypeList: any = [];
  public electricitySubTypeList: any = [];
  public landSuitabilityTypeList: any = [];
  public transportTypeList: any = [];
  public educationTypeList: any = [];
  public industrialTypeList: any = [];
  public healthTypeList: any = [];
  public securityTypeList: any = [];
  public telecommunicationTypeList: any = [];
  public businessTypeList: any = [];
  public industrialAreaTypeList: any = [];
  public cultivationTypeList: any = [];
  public ageCultivationTypeList: any = [];
  public landTypeList: any = [];
  public roiTypeList: any = [];

  public areaOfLand: any = [];
  public userdata: any = [];

  locationDetails: any;
  longitude: any;
  latitude: any;

  SubProperty :any

  public inspectionReportId: string;
  public recreationInspectionReportId: string;

  public inspectionCommonModel = new InspectionCommon('');

  public isUpdate: boolean;

  // Recreation fields
  public selectedRecreationSubPropertyType: any;
  public selectedRecreationType: boolean;
  public selectedRecreationSubmitType: boolean;
  public landRecreation: boolean;

  public propertySubTypeList: any = [];

  public landwithbuilding: boolean;

  public selectedRecreationUpdateType: boolean;

  valuationdata: any = [];

  public isSubPropertyChange: boolean;

  checkStatusApplication  = checkCompletedOrPaypend;

  constructor(
    public modalService: NgbModal,
    public inspectionCommonService: InspectionCommonService,
    public inspectionService: InspectionService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private notifier: NotifierService
  ) {
    this.selectedRecreationSubmitType = false;
    this.selectedRecreationType = false;
    this.isUpdate = false;
    this.initialview = true;
    this.viewCommericalLand = false;
    this.viewAgricultureLand = false;
    this.viewMixModeLand = false;
    this.viewRecreationLand = false;
    this.landwithbuilding = false;
    this.selectedRecreationUpdateType = false;
    this.landRecreation = false;
    this.isSubPropertyChange = false;

    let exchangeSelectedParty = sessionStorage.getItem('selectedPartyInExchange');

    // Valuation request ID is assigned according to selected party
    if(exchangeSelectedParty === '1') {
      this.model.valuationRequestId = this.valuationdata.id;
    } else if(exchangeSelectedParty === '2') {
      this.model.valuationRequestId = this.valuationdata.id + 1;
    }
  }

  ngOnInit() {
    this.valuationdata = JSON.parse(sessionStorage.getItem('valuationRequest'));
    this.model.planNo = this.valuationdata.properties[0]['surveyorPlanNo'];
    this.model.lotNo = this.valuationdata.properties[0]['lotNo'];
    this.landwithbuilding = JSON.parse(localStorage.getItem('landwithbuilding'));
    this.selectedRecreationUpdateType = JSON.parse(atob(localStorage.getItem('selectedRecreationUpdateType')));
    this.selectedRecreationType = JSON.parse(atob(localStorage.getItem('selectedRecreationType')));
    this.selectedRecreationSubPropertyType = JSON.parse(atob(localStorage.getItem('selectedRecreationSubPropertyType')));
    this.SubProperty = localStorage.getItem('subPropertyId') ? JSON.parse(localStorage.getItem('subPropertyId') ) :'';


    this._prepare();
    window.scroll(0,0);
  }

  _prepare() {

    let exchangeSelectedParty = sessionStorage.getItem('selectedPartyInExchange');

    // Valuation request ID is assigned according to selected party
    if(exchangeSelectedParty === '1') {
      this.model.valuationRequestId = this.valuationdata.id;
    } else if(exchangeSelectedParty === '2') {
      this.model.valuationRequestId = this.valuationdata.id + 1;
    }

    this.recreationInspectionReportId = atob(localStorage.getItem('recreationLandInspectionId'));
    if (this.recreationInspectionReportId !== "undefined") {
      this.model.inspectionId = this.recreationInspectionReportId;
      if (!JSON.parse(localStorage.getItem('viewlandwithbuildingdata'))) {
        this.loadCustomPropertyType();
      }
    }
    if (atob(localStorage.getItem('childinspectionId')) !== 'undefined') {
      this.inspectionReportId = atob(localStorage.getItem('childinspectionId'));
      if (this.inspectionReportId !== "undefined") {
        if(!this.landwithbuilding){
          this.selectedSubPropertyType = JSON.parse(atob(localStorage.getItem('subPropertyType')));
        }else{
          this.loadPropertyType();
        }
        this.isUpdate = true;
        this.model.inspectionId = this.inspectionReportId;
        this.retrieveUpdateFormDate();
        this.formLayoutInit();
      }
    } else {
      this.inspectionReportId = atob(localStorage.getItem('inspectionId'));
      if (this.inspectionReportId !== "undefined") {
        this.selectedSubPropertyType = JSON.parse(atob(localStorage.getItem('subPropertyType')));
        this.isUpdate = true;
        this.model.inspectionId = this.inspectionReportId;
        this.retrieveUpdateFormDate();
        this.formLayoutInit();
      }
    }
    this.getSearchDetails("Colombo");
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    // this.valuationRequestId = this.route.snapshot.paramMap.get('id');
    // Check whether if it is a recreation
    if (this.selectedRecreationType || this.landwithbuilding) {
      this.loadPropertyType();
    } else {
      this.selectedPropertyType = JSON.parse(atob(localStorage.getItem('propertyType')));
      this.selectedSubPropertyType = JSON.parse(atob(localStorage.getItem('subPropertyType')));
    }

    // Used in Recreation Land with Building
    if (this.selectedRecreationType && this.landwithbuilding) {
      this.retrieveUpdateFormDataForRecreation();
    }
    this.loadReservationTypeList();
    this.landShapeTypeListService();
    this.landSlopeTypeListService();
    this.landLocationTypeListService();
    this.waterAvailabilityTypeListService();
    this.waterTypeListService();
    this.electricityTypeListService();
    this.electricitySubTypeListService();
    this.landSuitabilityTypeListService();
    this.transportTypeListService();
    this.educationTypeListService();
    this.industrialTypeListService();
    this.healthTypeListService();
    this.securityTypeListService();
    this.telecommunicationTypeListService();
    this.businessTypeListService();
    this.industrialAreaTypeListService();
    this.cultivationTypeListService();
    this.ageCultivationTypeListService();
    this.landTypeListService();
    this.roiTypeListService();
    this.formLayoutInit();
  }

  getSearchDetails(location: String) {
    console.log("called")
    this.http.post<any>('https://nominatim.openstreetmap.org/search?q=' + location + '&format=geojson', { title: 'Angular POST Request Example' }).subscribe(data => {
      this.locationDetails = data;
      if (this.locationDetails.features.length > 0) {
        this.longitude = this.locationDetails.features[0].geometry.coordinates[0];
        this.latitude = this.locationDetails.features[0].geometry.coordinates[1];
        document.getElementById('geomap2').innerHTML = "<div id='map2' style='width: 100%; height: 100%;'></div>";
        var map = new L2.Map('map2', { attributionControl: false });
        map.setView(new L2.LatLng(this.latitude, this.longitude), 9);
        L2.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
          attribution: ''
        }).addTo(map);
        L2.marker([this.latitude, this.longitude]).addTo(map).bindPopup(this.locationDetails.features[0].properties.display_name).openPopup();
      } else {
        document.getElementById('geomap2').innerHTML = "<div id='map2' style='width: 100%; height: 100%;'></div>";
        var map = new L2.Map('map2', { attributionControl: false });
        map.setView(new L2.LatLng(6.9270786, 79.861243), 9);
      }
    })
  }

  NearestLocationSearch(event) {
    if (event === '' || event === undefined) {
      this.model.nearestTown = 'Colombo';
    }
    this.http.post<any>('https://nominatim.openstreetmap.org/search?q=' + this.model.nearestTown + '&format=geojson', { title: 'Angular POST Request Example' }).subscribe(data => {
      this.locationDetails = data;
      if (this.locationDetails.features.length > 0) {
        this.longitude = this.locationDetails.features[0].geometry.coordinates[0];
        this.latitude = this.locationDetails.features[0].geometry.coordinates[1];
        document.getElementById('geomap2').innerHTML = "<div id='map2' style='width: 100%; height: 100%;'></div>";
        var map = new L2.Map('map2', { attributionControl: false });
        map.setView(new L2.LatLng(this.latitude, this.longitude), 9);
        map.zoomIn(9);
        L2.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
          attribution: ''
        }).addTo(map);
        L2.marker([this.latitude, this.longitude]).addTo(map).bindPopup(this.locationDetails.features[0].properties.display_name).openPopup();
      } else {
        document.getElementById('geomap2').innerHTML = "<div id='map2' style='width: 100%; height: 100%;'></div>";
        var map = new L2.Map('map2', { attributionControl: false });
        map.setView(new L2.LatLng(6.9270786, 79.861243), 9);
      }
    })
  }

  recreationUpdatePermission() {
    let page;
    if (this.selectedRecreationUpdateType) {
      const dataList = JSON.parse(atob(localStorage.getItem("recreationupdatereq")));
      if (dataList.length > 0) {
        for (let i = 0; i < dataList.length; i++) {
          if (dataList[i].inspectionType === Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM) {
            localStorage.setItem('recreationCondominiumInspectionId', btoa(dataList[i].inspectionId));
            page = "condominium";
            break;
          } else if (dataList[i].inspectionType === Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING) {
            localStorage.setItem('recreationLandBuildingInspectionId', btoa(dataList[i].inspectionId));
            page = "land_building";
            break;
          }
        }
      }
    }
    this.model.inspectionId = this.recreationInspectionReportId;
    if (page === "condominium") {
      localStorage.setItem("isLand", JSON.stringify(true));
      localStorage.setItem("inspectionlandmodel", btoa(JSON.stringify(this.model)));
      this.onPageCountChanged.emit(12);
    } else if (page === "land_building") {
      localStorage.setItem("isLand", JSON.stringify(true));
      localStorage.setItem("inspectionlandmodel", btoa(JSON.stringify(this.model)));
      this.onPageCountChanged.emit(13);
    }
  }

  loadCustomPropertyType() {
    this.inspectionCommonService.propertyUsageTypeList().subscribe(
      data => {
        const filtereddata = data.data.filter(i => i.id === Constant.INSPECTION_PROPERTY_TYPE_LAND_ID);
        this.selectedPropertyType = filtereddata[0];
        this.loadCustomPropertySubType();
      }
    )
  }

  loadCustomPropertySubType() {
    // DB table name: 'inspection_sub_property_type'
    this.inspectionCommonService.propertyUsageSubTypeList().subscribe(
      data => {
        const filtereddata = data.data.filter(i => i.inspectionPropertyType.id === this.selectedPropertyType.id);
        this.propertySubTypeList = filtereddata;
        this.recreationRetrieveUpdateFormDate();
      }
    )
  }

  loadPropertyType() {
    this.inspectionCommonService.propertyUsageTypeList().subscribe(
      data => {
        const filtereddata = data.data.filter(i => i.id === Constant.INSPECTION_PROPERTY_TYPE_LAND_ID);
        this.selectedPropertyType = filtereddata[0];
        this.loadPropertySubType();
      }
    )
  }

  loadPropertySubType() {
    this.inspectionCommonService.propertyUsageSubTypeList().subscribe(
      data => {
        const filtereddata = data.data.filter(i => i.inspectionPropertyType.id === this.selectedPropertyType.id);
        this.propertySubTypeList = filtereddata;
        this.permissionCheck();
      }
    )
  }


  permissionCheck() {
    let page;
    if (this.selectedRecreationType) {
      if (this.selectedRecreationSubPropertyType.length > 0) {
        this.landRecreation = true;
        for (let i = 0; i < this.selectedRecreationSubPropertyType.length; i++) {
          if (this.selectedRecreationSubPropertyType[i].id === 2) {
            page = "condominium";
            break;
          } else if (this.selectedRecreationSubPropertyType[i].id === 3) {
            page = "land_building";
            break;
          }
        }
      }
    }
    if (page !== "condominium" && page !== "land_building") {
      this.selectedRecreationSubmitType = true;
    }
  }

  selectedSubPropertyTypeForNormal(event) {
    const filtereddata = this.propertySubTypeList.filter(i => i.id === Number(event));
    this.selectedSubPropertyType = filtereddata[0];
    this.formLayoutInit();
  }

  selectedSubPropertyTypeForRecreation(event) {
    console.log(this.model.propertySubTypeId)
    const filtereddata = this.propertySubTypeList.filter(i => i.id === Number(event));
    this.selectedSubPropertyType = filtereddata[0];
    this.formLayoutInit();
    this.isSubPropertyChange = true;
  }

  recreationNavigation() {
    let page;
    this.model.valuationRequestId = this.valuationRequestId;
    this.model.propertyTypeId = this.selectedPropertyType['id'];
    this.model.propertySubTypeId = '2';
    this.model.createdBy = this.userdata['firstName'];
    this.model.inspectionType = Constant.INSPECTION_TYPE_RECREATION_LAND;

    if (this.selectedRecreationType) {
      if (this.selectedRecreationSubPropertyType.length > 0) {
        for (let i = 0; i < this.selectedRecreationSubPropertyType.length; i++) {
          if (this.selectedRecreationSubPropertyType[i].id === 2) {
            page = "condominium";
            break;
          } else if (this.selectedRecreationSubPropertyType[i].id === 3) {
            page = "land_building";
            break;
          }
        }
      }
    }
    if (page === "condominium") {
      localStorage.setItem("isLand", JSON.stringify(true));
      localStorage.setItem("inspectionlandmodel", btoa(JSON.stringify(this.model)));
      this.onPageCountChanged.emit(12);
    } if (page === "land_building") {
      localStorage.setItem("isLand", JSON.stringify(true));
      localStorage.setItem("inspectionlandmodel", btoa(JSON.stringify(this.model)));
      this.onPageCountChanged.emit(13);
    }
  }

  navigateToLandwithBuilding() {
    if(this.model.propertySubTypeId === null || this.model.propertySubTypeId === undefined || this.model.propertySubTypeId === ""){
      this.notifier.notify("error", "Please select Sub Property type ");
    }else{
      this.model.valuationRequestId = this.valuationRequestId;
      this.model.propertyTypeId = this.selectedPropertyType['id'];
      if (this.selectedSubPropertyType !== undefined) {
        this.model.propertySubTypeId = this.selectedSubPropertyType['id'];
      }
      this.model.createdBy = this.userdata['firstName'];
      localStorage.setItem("isLand", JSON.stringify(true));
      localStorage.setItem("inspectionlandmodel", btoa(JSON.stringify(this.model)));

      localStorage.setItem('autosaveupdate', JSON.stringify(true));
      this.onPageCountChanged.emit(13);
    }
  }

  backToLandBuildingMainPage(){
    // localStorage.removeItem('subPropertyId')
    if(this.model.propertySubTypeId === null || this.model.propertySubTypeId === undefined || this.model.propertySubTypeId === ""){
      this.notifier.notify("error", "Please select Sub Property type ");
    }else{
      this.navigateToLandwithBuilding();
    }
  }

  retrieveUpdateFormDate() {
    this.inspectionCommonModel.code = this.inspectionReportId;
    this.inspectionService.inspectionLandDetailList(this.inspectionCommonModel).subscribe(
      data => {
        if (JSON.parse(localStorage.getItem('viewlandwithbuildingdata'))) {
          this.model = JSON.parse(atob(localStorage.getItem("inspectionlandmodel")));
          if(this.model.nearestTown !== null && this.model.nearestTown !== undefined && this.model.nearestTown !== ""){
            this.getSearchDetails(this.model.nearestTown);
          }else{
            this.getSearchDetails("Colombo");
          }
          this.formLayoutInitLandBuilding();
        } else {
          this.model = data.data;
          if(this.model.nearestTown !== null && this.model.nearestTown !== undefined && this.model.nearestTown !== ""){
            this.getSearchDetails(this.model.nearestTown);
          }else{
            this.getSearchDetails("Colombo");
          }
          this.formLayoutInitLandBuilding();
          this.calculateArea();
          this.inspectionCommonService.propertyUsageTypeList().subscribe(
            data => {
              const filtereddata = data.data.filter(i => i.id === Constant.INSPECTION_PROPERTY_TYPE_LAND_ID);
              this.selectedPropertyType = filtereddata[0];
            }
          )
        }
      }
    )
  }

  retrieveUpdateFormDataForRecreation() {
    this.inspectionCommonModel.code = localStorage.getItem('recreationLandId');
    console.log(this.inspectionCommonModel.code);
    this.inspectionService.inspectionLandDetailList(this.inspectionCommonModel).subscribe(
      data => {
        if (JSON.parse(localStorage.getItem('viewlandwithbuildingdata'))) {
          this.model = JSON.parse(atob(localStorage.getItem("inspectionlandmodel")));
          if(this.model.nearestTown !== null && this.model.nearestTown !== undefined && this.model.nearestTown !== ""){
            this.getSearchDetails(this.model.nearestTown);
          }else{
            this.getSearchDetails("Colombo");
          }
          this.formLayoutInit();
        } else {
          this.model = data.data;
          this.inspectionCommonService.propertyUsageTypeList().subscribe(
            data => {
              const filtereddata = data.data.filter(i => i.id === Constant.INSPECTION_PROPERTY_TYPE_LAND_ID);
              this.selectedPropertyType = filtereddata[0];
            }
          )
          this.formLayoutInit();
        }
      }
    )
  }

  recreationRetrieveUpdateFormDate() {
    this.inspectionCommonModel.code = this.recreationInspectionReportId;
    this.inspectionService.inspectionLandDetailList(this.inspectionCommonModel).subscribe(
      data => {
        this.model = data.data;
        if(this.model.nearestTown !== null && this.model.nearestTown !== undefined && this.model.nearestTown !== ""){
          this.getSearchDetails(this.model.nearestTown);
        }else{
          this.getSearchDetails("Colombo");
        }
        this.model.inspectionId = this.recreationInspectionReportId;
        const filtereddata = this.propertySubTypeList.filter(i => i.id === Number(this.model.propertySubTypeId));
        this.selectedSubPropertyType = filtereddata[0];
        this.formLayoutInit();
      }
    )
  }


  public redirectPreviousComponent(): void {
    this.onPageCountChanged.emit(10);
  }

  openPopupMessage(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });
  }

  formLayoutInitLandBuilding() {
    if (parseInt(this.model.propertySubTypeId) == Constant.INSPECTION_REPORT_LAND_COMMERCIAL) {
      this.viewCommericalLand = true;
      this.viewAgricultureLand = false;
      this.viewMixModeLand = false;
      this.viewRecreationLand = false;
    } else if (parseInt(this.model.propertySubTypeId) == Constant.INSPECTION_REPORT_LAND_AGRICULTURE) {
      this.viewCommericalLand = false;
      this.viewAgricultureLand = true;
      this.viewMixModeLand = false;
      this.viewRecreationLand = false;
    } else if (parseInt(this.model.propertySubTypeId) == Constant.INSPECTION_REPORT_LAND_MIXMODE) {
      this.viewCommericalLand = false;
      this.viewAgricultureLand = false;
      this.viewMixModeLand = true;
      this.viewRecreationLand = false;
    } else if (parseInt(this.model.propertySubTypeId) == Constant.INSPECTION_REPORT_LAND_RECREATION) {
      this.viewCommericalLand = false;
      this.viewAgricultureLand = false;
      this.viewMixModeLand = false;
      this.viewRecreationLand = true;
    } else {
      this.viewCommericalLand = false;
      this.viewAgricultureLand = false;
      this.viewMixModeLand = false;
      this.viewRecreationLand = false;
    }
  }
  formLayoutInit(){
    if (this.selectedSubPropertyType["id"] == Constant.INSPECTION_REPORT_LAND_COMMERCIAL) {
      this.viewCommericalLand = true;
      this.viewAgricultureLand = false;
      this.viewMixModeLand = false;
      this.viewRecreationLand = false;
    } else if (this.selectedSubPropertyType["id"] == Constant.INSPECTION_REPORT_LAND_AGRICULTURE) {
      this.viewCommericalLand = false;
      this.viewAgricultureLand = true;
      this.viewMixModeLand = false;
      this.viewRecreationLand = false;
    } else if (this.selectedSubPropertyType["id"] == Constant.INSPECTION_REPORT_LAND_MIXMODE) {
      this.viewCommericalLand = false;
      this.viewAgricultureLand = false;
      this.viewMixModeLand = true;
      this.viewRecreationLand = false;
    } else if (this.selectedSubPropertyType["id"] == Constant.INSPECTION_REPORT_LAND_RECREATION) {
      this.viewCommericalLand = false;
      this.viewAgricultureLand = false;
      this.viewMixModeLand = false;
      this.viewRecreationLand = true;
    } else {
      this.viewCommericalLand = false;
      this.viewAgricultureLand = false;
      this.viewMixModeLand = false;
      this.viewRecreationLand = false;
    }
  }

  loadReservationTypeList() {
    this.inspectionCommonService.reservationTypeList().subscribe(
      data => {
        this.reservationTypeList = data.data;
      }
    )
  }

  landShapeTypeListService() {
    this.inspectionCommonService.landShapeTypeList().subscribe(
      data => {
        this.landShapeTypeList = data.data;
      }
    )
  }

  landSlopeTypeListService() {
    this.inspectionCommonService.landSlopeTypeList().subscribe(
      data => {
        this.landSlopeTypeList = data.data;
      }
    )
  }

  landLocationTypeListService() {
    this.inspectionCommonService.landLocationTypeList().subscribe(
      data => {
        this.landLocationTypeList = data.data;
      }
    )
  }

  waterAvailabilityTypeListService() {
    this.inspectionCommonService.waterAvailabilityTypeList().subscribe(
      data => {
        this.waterAvailabilityTypeList = data.data;
      }
    )
  }

  waterTypeListService() {
    this.inspectionCommonService.waterTypeList().subscribe(
      data => {
        this.waterTypeList = data.data;
      }
    )
  }

  electricityTypeListService() {
    this.inspectionCommonService.electricityTypeList().subscribe(
      data => {
        this.electricityTypeList = data.data;
      }
    )
  }

  electricitySubTypeListService() {
    this.inspectionCommonService.electricitySubTypeList().subscribe(
      data => {
        this.electricitySubTypeList = data.data;
      }
    )
  }

  landSuitabilityTypeListService() {
    this.inspectionCommonService.landSuitabilityTypeList().subscribe(
      data => {
        this.landSuitabilityTypeList = data.data;
      }
    )
  }

  transportTypeListService() {
    this.inspectionCommonService.transportTypeList().subscribe(
      data => {
        this.transportTypeList = data.data;
      }
    )
  }

  educationTypeListService() {
    this.inspectionCommonService.educationTypeList().subscribe(
      data => {
        this.educationTypeList = data.data;
      }
    )
  }

  industrialTypeListService() {
    this.inspectionCommonService.industrialTypeList().subscribe(
      data => {
        this.industrialTypeList = data.data;
      }
    )
  }

  healthTypeListService() {
    this.inspectionCommonService.healthTypeList().subscribe(
      data => {
        this.healthTypeList = data.data;
      }
    )
  }

  securityTypeListService() {
    this.inspectionCommonService.securityTypeList().subscribe(
      data => {
        this.securityTypeList = data.data;
      }
    )
  }

  telecommunicationTypeListService() {
    this.inspectionCommonService.telecommunicationTypeList().subscribe(
      data => {
        this.telecommunicationTypeList = data.data;
      }
    )
  }

  businessTypeListService() {
    this.inspectionCommonService.businessTypeList().subscribe(
      data => {
        this.businessTypeList = data.data;
      }
    )
  }

  industrialAreaTypeListService() {
    this.inspectionCommonService.industrialAreaTypeList().subscribe(
      data => {
        this.industrialAreaTypeList = data.data;
      }
    )
  }

  cultivationTypeListService() {
    this.inspectionCommonService.cultivationTypeList().subscribe(
      data => {
        this.cultivationTypeList = data.data;
      }
    )
  }

  ageCultivationTypeListService() {
    this.inspectionCommonService.ageCultivationTypeList().subscribe(
      data => {
        this.ageCultivationTypeList = data.data;
      }
    )
  }

  landTypeListService() {
    this.inspectionCommonService.landTypeList().subscribe(
      data => {
        this.landTypeList = data.data;
      }
    )
  }

  roiTypeListService() {
    this.inspectionCommonService.roiTypeList().subscribe(
      data => {
        this.roiTypeList = data.data;
      }
    )
  }

  loadNextComponent() {
    if (this.isSubPropertyChange || this.model.propertySubTypeId !== null || this.selectedSubPropertyType.name != undefined) {
      this.initialview = false;
      window.scroll(0,0);
      localStorage.removeItem('subPropertyId')
    } else {
      this.notifier.notify('error', 'Please select a sub type first');
    }
  }

  exitForm() {
    localStorage.removeItem('subPropertyId')
    this.onPageCountChanged.emit(10);
  }

  loadPreviousComponent() {
    this.initialview = true;
  }

  reset() {
    this.initialview = true;
    this.model = new InspectionLand();
  }

  redirectToMainTable() {
    this.modalService.dismissAll();
    this.redirectPreviousComponent();
  }

  onSubmit() {
    let exchangeSelectedParty = sessionStorage.getItem('selectedPartyInExchange');

    // Valuation request ID is assigned according to selected party
    if(exchangeSelectedParty === '1') {
      this.model.valuationRequestId = this.valuationdata.id;
    } else if(exchangeSelectedParty === '2') {
      this.model.valuationRequestId = this.valuationdata.id + 1;
    }

    // this.model.valuationRequestId = this.valuationdata.id;
    this.model.propertyTypeId = this.selectedPropertyType['id'];
    this.model.propertySubTypeId = this.selectedSubPropertyType['id'];
    this.model.createdBy = this.userdata['firstName'];
    this.model.inspectionType = Constant.INSPECTION_TYPE_LAND;
    this.inspectionService.inspectionLandInsert(this.model).subscribe(
      data => {
        this.openPopupMessage(this.popupMigrated);
        localStorage.removeItem('subPropertyId');
        this.reset();
      },
      err => {
        this.notifier.notify('error', 'Error occurred while processing. Please try again.');
      }
    )

  }

  onUpdate() {
    this.model.createdBy = this.userdata['firstName'];
    this.model.inspectionType = Constant.INSPECTION_TYPE_LAND;
    this.model.inspectionId = atob(localStorage.getItem('inspectionId'));
    this.inspectionService.inspectionLandUpdate(this.model).subscribe(
      data => {
        this.openPopupMessage(this.popupMigrated);
        localStorage.removeItem('subPropertyId');
        this.reset();
      }, err => {
        this.notifier.notify('error', 'Error occurred while processing. Please try again.');
      }
    )
  }

  onRecreationUpdate() {
    // Property type is needed in Re-creation Land with Building
    this.model.propertyTypeId = this.selectedPropertyType['id'];
    this.model.inspectionId = atob(localStorage.getItem('inspectionId'));
    this.inspectionService.inspectionLandUpdate(this.model).subscribe(
      data => {
        console.log(data)
        // localStorage.setItem("isLand", JSON.stringify(true));
        localStorage.setItem("inspectionlandmodel", btoa(JSON.stringify(this.model)));
        localStorage.setItem('autosaveupdate', JSON.stringify(true));
        this.onPageCountChanged.emit(13);
      }, err => {
        this.notifier.notify('error', 'Error occurred while processing. Please try again.');
      }
    )
  }

  ngOnDestroy() {
    this.isUpdate = false;
    if (!JSON.parse(localStorage.getItem('landwithbuilding'))) {
      localStorage.setItem('inspectionId', btoa(undefined));
    }
  }

  calculateArea(){
    if(this.model.length !== undefined && this.model.length !== null && this.model.width !== undefined && this.model.width !== null) {
      this.areaOfLand = (
        (Number(this.model.length) *
        Number(this.model.width)) / 272.25
      ).toFixed(2);
    }else{
      this.areaOfLand = (0.00).toFixed(2);
    }
  }
}

