export class District {
    id: number;
    code: string;
    province: number;
    description: string;

    constructor(
      id: number,
      code: string,
      province: number,
      description: string,
    ){
      this.id = id;
      this.code = code;
      this.province = province;
      this.description = description;
    }
}
