import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { InspectionIndustrialTypeService } from 'src/app/service/inspection-master-data-service/inspection-industrial-type/inspection-industrial-type.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inspection-industrial-type',
  templateUrl: './inspection-industrial-type.component.html',
  styleUrls: ['./inspection-industrial-type.component.scss']
})
export class InspectionIndustrialTypeComponent implements OnInit,OnDestroy {

  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  createIndustrialTypeForm: FormGroup;
  updateIndustrialTypeForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  propertyTypeList: any =[];
  activationButton: boolean;
  public createIndustrialTypeModel = new InspectionCommonDataModel(null,'','','','');
  public updateIndustrialTypeModel = new InspectionCommonDataModel(null,'','','','');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;
  IndustrialTypes: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionIndustrialTypeService: InspectionIndustrialTypeService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createIndustrialTypeForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });
    this.updateIndustrialTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });

    // this.loadPropertyType();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }



  //create Industrial Type - start

  get f() { return this.createIndustrialTypeForm.controls; }

  setIndustrialTypeModelToSave(){
    this.createIndustrialTypeModel.name = this.createIndustrialTypeForm.get('name').value;
    this.createIndustrialTypeModel.description = this.createIndustrialTypeForm.get('description').value;
    this.createIndustrialTypeModel.code = this.createIndustrialTypeForm.get('code').value;
    this.createIndustrialTypeModel.dataStatus = "ACTIVE";
  }

    //save Industrial Type model
  saveIndustrialType(){
    if(this.createIndustrialTypeForm.valid){
      this.setIndustrialTypeModelToSave();
      this.sub.add(
        this.inspectionIndustrialTypeService.createIndustrialType(this.createIndustrialTypeModel).subscribe(data=>{
          this.notifier.notify('success', (data as any).message);
          this.updateTheTable();
          this.reset();
        })
        );
    }else{
      this.createIndustrialTypeForm.markAllAsTouched();
    }

  }

  reset(){
    this.createIndustrialTypeForm.reset()
  }
  //create Industrial Type - end

  //update Industrial Type - start

    //get update controllers
  get f1() { return this.updateIndustrialTypeForm.controls; }

    //set data To Industrial Type Type model
  setIndustrialType(industrial:any){
    this.updateIndustrialTypeForm.get('id').setValue(industrial.id);
    this.updateIndustrialTypeForm.get('name').setValue(industrial.name);
    this.updateIndustrialTypeForm.get('description').setValue(industrial.description);
    this.updateIndustrialTypeForm.get('code').setValue(industrial.code);

    if(industrial.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateIndustrialTypeModel.id = this.updateIndustrialTypeForm.get('id').value;
    this.updateIndustrialTypeModel.name = this.updateIndustrialTypeForm.get('name').value;
    this.updateIndustrialTypeModel.description = this.updateIndustrialTypeForm.get('description').value;
    this.updateIndustrialTypeModel.code = this.updateIndustrialTypeForm.get('code').value;

    if(this.activationButton == true){
      this.updateIndustrialTypeModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateIndustrialTypeModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateIndustrialType(){
    this.setDataToModel();
    this.sub.add(this.inspectionIndustrialTypeService.updateIndustrialType(this.updateIndustrialTypeModel.id, this.updateIndustrialTypeModel).subscribe(data=>{
      this.notifier.notify('success', (data as any).message);
      this.updateTheTable();
    }))
  }

  //update Industrial Type - end

//search and view table start
  pageable(i: number) {
    this.inspectionIndustrialTypeService.getIndustrialType((i-1),10).subscribe(res=>{
      this.IndustrialTypes = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionIndustrialTypeService.getIndustrialType(0, 10)
      .subscribe(
        (res) => {
          this.IndustrialTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search Industrial Type Type
    searchIndustrialType(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionIndustrialTypeService.searchIndustrialType(this.keyword,0,10).subscribe(res=>{
          this.IndustrialTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionIndustrialTypeService.deleteIndustrialType(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end

}
