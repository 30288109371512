import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ValuationStatus } from 'src/app/model/valuation-status';
import { PermissionLetter } from 'src/app/model/permission-letter';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GranteeService {
  private requestUrl = '/grantee';  // URL to web api
  private subject = new Subject<any>();
  constructor(
    private http: HttpClient
  ) { }

  sendGranteeStatusChange(){
    this.subject.next();
  }

  getGranteeStatusChange():Observable<any>{
    return this.subject.asObservable();
  }

  granteeVerificationStatus(ValuationStatus: ValuationStatus): Observable<any> {
    return this.http.post<any>(`${environment.api_url}` + this.requestUrl + '/status', ValuationStatus);
  }

  granteeUpdateVerificationStatus(ValuationStatus: ValuationStatus): Observable<any> {
    return this.http.post<any>(`${environment.api_url}` + this.requestUrl + '/updatestatus', ValuationStatus);
  }

  insertPermissionLetter(permissionLetter: PermissionLetter): Observable<any> {
    return this.http.post<any>(`${environment.api_url}` + this.requestUrl + '/insertPermissionLetter', permissionLetter);
  }

}
