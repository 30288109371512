import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LocalAuthorityService } from 'src/app/service/local-authority/local-authority.service';
import { LocalAuthrority } from 'src/app/model/local-authrority';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-update-local-authority',
  templateUrl: './update-local-authority.component.html',
  styleUrls: ['./update-local-authority.component.scss']
})
export class UpdateLocalAuthorityComponent implements OnInit,OnDestroy {

  updateLocalAuthorityForm: FormGroup;

  localAuthority: string;
  localAuthorityNo: number;

  private sub=new SubSink();
  @Output() localAuthorityUpdated = new EventEmitter<boolean>();

  constructor(private formBuilder: FormBuilder, private localAuthorityService: LocalAuthorityService,private notifier:NotifierService) { }

  ngOnInit() {
    this.updateLocalAuthorityForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      localAuthorityNo: ['', [Validators.required]],
      localAuthority: ['', [Validators.required]]
    });
  }
  ngOnDestroy(){
    this.sub.unsubscribe();
  }

  updateLocalAuthority() {
    if (this.updateLocalAuthorityForm.valid) {
      const localAuthrority = new LocalAuthrority();
      localAuthrority.localAuthorityNo = this.getLocalAuthorityNo();
      localAuthrority.localAuthority = this.getLocalAuthorityName();

     this.sub.add( this.localAuthorityService.updateLocalAuthority(this.getId(), localAuthrority)
        .subscribe(response => {

          this.updateTheView(true);
      this.notifier.notify('success',(response as any).message)
          }));
    }
  }

  getId() {
    return this.updateLocalAuthorityForm.get('id').value;
  }
  setIdAndDescription(id: number, localAuthorityNo: number, localAuthority: string) {
    this.updateLocalAuthorityForm.get('id').setValue(id);
    this.updateLocalAuthorityForm.get('localAuthorityNo').setValue(localAuthorityNo);
    this.updateLocalAuthorityForm.get('localAuthority').setValue(localAuthority);
  }

  getLocalAuthorityNo() {
    return this.updateLocalAuthorityForm.get('localAuthorityNo').value;
  }
  getLocalAuthorityName() {
    return this.updateLocalAuthorityForm.get('localAuthority').value;
  }

  updateTheView(updated: boolean) {
    this.localAuthorityUpdated.emit(updated);

  }
}
