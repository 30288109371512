import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DeedNatureService } from 'src/app/service/deed-nature/deed-nature.service';
import { DeedNature } from 'src/app/model/deed-nature';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-update-deed-nature',
  templateUrl: './update-deed-nature.component.html',
  styleUrls: ['./update-deed-nature.component.scss']
})
export class UpdateDeedNatureComponent implements OnInit {

  updateDeedNatureForm: FormGroup;
  private sub = new SubSink();

  description: string;

  @Output() deedNatureUpdated = new EventEmitter<boolean>();

  constructor(private formBuilder: FormBuilder, private deedNatureService: DeedNatureService, private notifier: NotifierService) { }

  ngOnInit() {
    this.updateDeedNatureForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required]],
      description: ['', [Validators.required]]
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updateDeedNature() {
    if (this.updateDeedNatureForm.valid) {
      const deedNature = new DeedNature();
      deedNature.description = this.getDescription();
      deedNature.name = this.getName();
      this.sub.add(this.deedNatureService.updateDeedNature(this.getId(), deedNature)
        .subscribe(response => {
          this.updateTheView(true);
          this.notifier.notify('success', (response as any).message);
        }));
    }
  }
  getName() {
    return this.updateDeedNatureForm.get('name').value;
  }
  getId() {
    return this.updateDeedNatureForm.get('id').value;
  }
  setIdAndDescription(id: number, name:string,description: string) {
    this.updateDeedNatureForm.get('id').setValue(id);
    this.updateDeedNatureForm.get('name').setValue(name);
    this.updateDeedNatureForm.get('description').setValue(description);
  }

  getDescription() {
    return this.updateDeedNatureForm.get('description').value;
  }

  updateTheView(updated: boolean) {
    this.deedNatureUpdated.emit(updated);
  }
}










