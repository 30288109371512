import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { opinionNo } from '../../dashboard-external.component';
import { RefundOpinionServiceService } from 'src/app/service/RefundOpinionService/refund-opinion-service.service';
import { refundRequestCommon } from 'src/app/model/refundRequestCommon';
import { Constant } from 'src/app/util/constant';

@Component({
  selector: 'app-get-opinion-dialog',
  templateUrl: './get-opinion-dialog.component.html',
  styleUrls: ['./get-opinion-dialog.component.scss']
})
export class GetOpinionDialogComponent implements OnInit {
  OpinionMod = 0;
  public common = new refundRequestCommon('','','','','','','',null,null,null,null,null,null)
  constructor(private router:Router, public dialogRef: MatDialogRef<GetOpinionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: opinionNo,private refundService:RefundOpinionServiceService
    )  {
      // dialogRef.disableClose = true;
    }

  ngOnInit() {
    sessionStorage.removeItem('hasOpinion');
    localStorage.removeItem('refundId');
    sessionStorage.removeItem('refundRequestId')
    this.common.status = Constant.REFUND_REQUEST_DRAFT_CODE; 
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  noOpinion():void{
    sessionStorage.setItem('hasOpinion','false');
    this.common.hasOpinion = false;
    this.refundService.createRefundRequestById(this.common).subscribe(data => {
      console.log(data);
      let getRefundId = (data as any).data.id;
      localStorage.setItem('refundId',getRefundId);
      sessionStorage.setItem('refundRequestId', getRefundId);
      sessionStorage.setItem('hasPreviousOpinion', 'false');
    })
    this.dialogRef.close();
    this.router.navigate(['/refund-application']);
  }
}
