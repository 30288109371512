import { Component, OnInit, ViewChild } from '@angular/core';
import { FloorTypeService } from '../../../../../../service/floor-type/floor-type.service';
import { UpdateFloorTypeComponent } from '../update-floor-type/update-floor-type.component';
import { DeleteFloorTypeComponent } from '../delete-floor-type/delete-floor-type.component';
import { DataService } from 'src/app/service/data.service';
import { SubSink } from 'subsink';
import {FloorType} from '../../../../../../model/floor-type';


@Component({
  selector: 'app-view-floor-type',
  templateUrl: './view-floor-type.component.html',
  styleUrls: ['./view-floor-type.component.scss']
})
export class ViewFloorTypeComponent implements OnInit {

  floorTypes: FloorType[];
  private sub = new SubSink();
  pageNo: number;

  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];

  @ViewChild(UpdateFloorTypeComponent, { static: false })
  private updateFloorTypeComponent: UpdateFloorTypeComponent;
  @ViewChild(DeleteFloorTypeComponent, { static: false })
  private deleteFloorTypeComponent: DeleteFloorTypeComponent;
  currentPage: any;
  totalItems: any;


  constructor(private floorTypeService: FloorTypeService, private dataService: DataService) { }

  ngOnInit() {
    this.selectedPageNo = 0;
    this.limit = 10;

    this.floorTypeService.getAll().subscribe(
      response => {
        const res = response.data.length;
        this.pageNo = Math.round(res / 10);


      }
    );

    this.sub.add(this.dataService.updateDataTableFloorType
      .subscribe(update => this.onAdded(update)));

    this.sub.add(this.floorTypeService.getFloorTypes(0, 10)
      .subscribe(
        (res) => {
          this.floorTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }
      ));
  }

  pageable(i: number) {
    console.log('i' + i);
    console.log(i * 10 + ' - ' + ((i * 10) + 10));
    this.floorTypeService.getFloorTypes((i-1), 10).subscribe(responce => {
      this.floorTypes = (responce as any).data.content;
      this.currentPage = (responce as any).data.number+1;
      this.totalItems = (responce as any).data.totalElements;
    });
  }
  counter(i: number) {
    return new Array(i);
  }

  triggerUpdate(id: number, code: string, name: string, description: string) {
    this.updateFloorTypeComponent.setIdAndDescription(id, code, name, description);
  }

  triggerDelete(id: number) {
    this.deleteFloorTypeComponent.setDeedNatureId(id);
  }

  onUpdated(updatedSuccessfully: boolean) {
    if (updatedSuccessfully) {
      this.updateTheTable();
    }
  }

  onDeleted(deletedSuccessfully: boolean) {
    if (deletedSuccessfully) {
      this.updateTheTable();
    }
  }
  onAdded(addedSuccessfully: boolean) {
    if (addedSuccessfully) {
      this.updateTheTable();
    }
  }
  private updateTheTable() {
    this.sub.add(this.floorTypeService.getFloorTypes(0, 10)
      .subscribe(
        (res) => {
          this.floorTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

  searchFloorTypes(keyword: string) {
    this.selectedPageNo = 0;
    this.sub.add(this.floorTypeService.searchFloorType(keyword, this.selectedPageNo, this.limit)
      .subscribe((res) => {
        this.floorTypes = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
        // this.floorTypes = res.data.content;
        // this.paginationButtonCounter(res.data.totalPages);
        // this.numOfPages = res.data.totalPages;
      })
    );
  }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

}
