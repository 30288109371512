import {Grantee} from './grantee';
import {Granter} from './granter';
import {Payment} from './payment';
import {Property} from './property';
import {NotaryDetails} from './notary-details';
import {INotary} from './interfaces/notary';
import { History } from './history';

export class ValuationRequest {
  id: number;
  exchangeId: number;
  deedNature: string;
  created_date: any;
  fileNumberFormat: string;
  grantees: Grantee [] = [];
  granters: Granter [] = [];
  historyList: History[] = [];
  notaries: INotary;
  // notaries: NotaryDetails;
  noOfGrantees: number;
  noOfGranters: number;
  payment: Payment;
  properties: Property [] = [];
  stampDuty: number;
  valuation: number;
  documentsIdList: string [] = [];
  valuationRequestStatus: any;
  transactionStatus: any;
  remark: any;
  user_id: string;
  isChecked: any;
  accessorId : string;
  accessorStatus : string;
  dcommissionerId : string;
  dcommissionerStatus : string;
  commissionerId : string;
  commissionerStatus : string;
  appeal:boolean;
}
