import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RimPayableDeeds } from 'src/app/model/rim-payable-deeds';

@Component({
  selector: 'app-print-payable',
  templateUrl: './print-payable.component.html',
  styleUrls: ['./print-payable.component.scss']
})
export class PrintPayableComponent implements OnInit {

  payableDeedsList: RimPayableDeeds[] = [];

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.payableDeedsList = JSON.parse(sessionStorage.getItem('payableDeedList'));
  }

  print() {
    window.print();
  }

  redirecToPrevious() {
    this.router.navigate(['/reimbursment-application/payble-nonpayable']);

    // Using to check whether the Tax officer is returning from this Print Payable screen
    sessionStorage.setItem('visitedToPrintScreen', JSON.stringify(true));
  }

}
