import {Company} from './company';

export class Granter {
  nameWithInitials: string;
  firstName: string;
  middleName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  province: string;
  postalCode: string;
  nic: string;
  contactNo: string;
  email: string;
  valuationRequest: string;
  granterCompany: boolean;
  isGrantorIndividual: boolean;
  company: Company;
  id: String;
}
