import {Component, OnInit, ViewChild} from '@angular/core';
import {ViewDeedNatureComponent} from './view-deed-nature/view-deed-nature.component';

@Component({
  selector: 'app-deed-nature',
  templateUrl: './deed-nature.component.html',
  styleUrls: ['./deed-nature.component.scss']
})
export class DeedNatureComponent implements OnInit {
  @ViewChild(ViewDeedNatureComponent, {static: false})
  private viewDeedNatureComponent: ViewDeedNatureComponent;
  keyword: string;

  constructor() { }

  ngOnInit() {
  }

  triggerSearch(event: any) {
    if (this.keyword === '') {
      this.viewDeedNatureComponent.onUpdated(true);
    } else {
      this.viewDeedNatureComponent.searchDeedNature(this.keyword);
    }
  }
}
