import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {UpdatePropertyFacilityComponent} from '../update-property-facility/update-property-facility.component';
import {DeletePropertyFacilityComponent} from '../delete-property-facility/delete-property-facility.component';
import {PropertyFacilityService} from 'src/app/service/property-facility/property-facility.service';
import {SubSink} from 'subsink';
import {DataService} from 'src/app/service/data.service';
import {IPropertyFacility} from '../../../../../../../model/interfaces/property-facility';

@Component({
  selector: 'app-view-property-facility',
  templateUrl: './view-property-facility.component.html',
  styleUrls: ['./view-property-facility.component.scss']
})
export class ViewPropertyFacilityComponent implements OnInit, OnDestroy {


  @ViewChild(UpdatePropertyFacilityComponent, {static: false})
  private updatePropertyFacilityComponent: UpdatePropertyFacilityComponent;
  @ViewChild(DeletePropertyFacilityComponent, {static: false})
  private deletePropertyFacilityComponent: DeletePropertyFacilityComponent;


  propertyFacility: IPropertyFacility [];

  private sub = new SubSink();

  pageNo: number;

  // pagination
  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];
  currentPage: any;
  totalItems: any;

  constructor(private propertyFacilityService: PropertyFacilityService, private dataService: DataService) {
  }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.propertyFacilityService.getAll().subscribe(
      response => {
        var res = response.data.length;
        this.pageNo = Math.round((res / 10)+1);

      });

    this.sub.add(this.dataService.UpdateDataTablePropertyFacility.subscribe(update => this.onAdded(update)));

    this.sub.add(this.propertyFacilityService.getPropertyFacility(0, 10)
      .subscribe(
        (res) => {
          this.propertyFacility = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }
      ));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  triggerUpdate(id: number, code: string, name: string, description: string) {
    this.updatePropertyFacilityComponent.setIdAndDescription(id, code, name, description);
  }

  triggerDelete(id: number) {
    this.deletePropertyFacilityComponent.setPropertyFacilityId(id);
  }

  onUpdated(updatedSuccessfully: boolean) {
    if (updatedSuccessfully) {
      this.updateTheTable();
    }
  }

  onDeleted(deletedSuccessfully: boolean) {
    if (deletedSuccessfully) {
      this.updateTheTable();
    }
  }

  onAdded(addedSuccessfully: boolean) {
    if (addedSuccessfully) {
      this.updateTheTable();
    }
  }

  pageable(i: number) {
    this.propertyFacilityService.getPropertyFacility((i-1),10).subscribe(responce => {
      this.propertyFacility = (responce as any).data.content;
      this.currentPage = (responce as any).data.number+1;
      this.totalItems = (responce as any).data.totalElements;
    });
  }

  counter(i: number) {
    return new Array(i);
  }

  // update the table
  private updateTheTable() {
    this.sub.add(this.propertyFacilityService.getPropertyFacility(0, 10)
      .subscribe(
        (res) => {
          this.propertyFacility = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

  // search by property facility
  searchPropertyFacility(keyword: string) {
    this.selectedPageNo = 0;
    this.sub.add(this.propertyFacilityService.searchPropertyFacility(keyword, this.selectedPageNo, this.limit)
      .subscribe((res) => {
        // this.propertyFacility = res.data.content;
        // this.paginationButtonCounter(res.data.totalPages);
        // this.numOfPages = res.data.totalPages;
        this.propertyFacility = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      }));
  }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }


}
