export class LandRegistry{
    id:number;
    landRegistry:string;
    landRegistryNo:number;
    dataStatus:string;


    constructor(
      id:number,
      landRegistry:string,
      landRegistryNo:number,
      dataStatus:string,
    ){
      this.id = id;
      this.landRegistry = landRegistry;
      this.landRegistryNo = landRegistryNo;
      this.dataStatus = dataStatus;
    }
  }
