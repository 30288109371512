// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// For 16000 port environment
// export const environment = {
//   production: true,
//   auth_server_url: 'http://43.224.124.96:8091/auth-server',
//   api_url: 'http://43.224.124.96:8094/wprd-web-service/api',
//   user_api_url: 'http://43.224.124.96:8898',
//   grant_type: 'password',
//   client_id: 'web',
//   client_secret: '123456'
// };

// For previous live environment
// export const environment = {
//   production: true,
//   auth_server_url: 'http://43.224.124.96:9091/auth-server',
//   api_url: 'http://43.224.124.96:9094/wprd-web-service/api',
//   user_api_url: 'http://43.224.124.96:9098',
//   grant_type: 'password',
//   client_id: 'web',
//   client_secret: '123456'
// };

// For QA environment, 93 port
// export const environment = {
//   production: true,
//   auth_server_url: 'http://41.216.184.150:8092/auth-server',
//   api_url: 'http://41.216.184.150:8095/wprd-web-service/api',
//   user_api_url: 'http://41.216.184.150:8088',
//   grant_type: 'password',
//   client_id: 'web',
//   client_secret: '123456'
// };

// For QA environment, 92 port
// export const environment = {
//   production: true,
//   auth_server_url: 'http://41.216.184.150:8091/auth-server',
//   api_url: 'http://41.216.184.150:8095/wprd-web-service/api',
//   user_api_url: 'http://41.216.184.150:8898',
//   grant_type: 'password',
//   client_id: 'web',
//   client_secret: '123456'
// };

// export const environment = {
//   production: true,
//   auth_server_url: 'https://sdop.wp.gov.lk/auth-server',
//   api_url: 'https://sdop.wp.gov.lk/wprd-web-service/api',
//   user_api_url: 'https://sdop.wp.gov.lk/user',
//   grant_type: 'password',
//   client_id: 'web',
//   client_secret: '123456'
// };

// For Development environment
export const environment = {
  production: false,
  auth_server_url: 'https://gisetl.nsdi.gov.lk/auth-server',
  api_url: 'https://gisetl.nsdi.gov.lk/wprd-web-service/api',
  user_api_url: 'https://gisetl.nsdi.gov.lk/user',
  grant_type: 'password',
  client_id: 'web',
  client_secret: '123456'
};

//8443
// export const environment = {
//   production: true,
//   auth_server_url: "https://sdop.wp.gov.lk:8443/auth/auth-server",
//   api_url: "https://sdop.wp.gov.lk:8443/backend/wprd-web-service/api",
//   user_api_url: "https://sdop.wp.gov.lk:8443/user",
//   grant_type: "password",
//   client_id: "web",
//   client_secret: "123456",
// };

// For QA environment, 92 port ---- New
// export const environment = {
//   production: true,
//   auth_server_url: 'http://41.216.184.150:8091/auth-server',
//   api_url: 'http://41.216.184.150:8094/wprd-web-service/api',
//   user_api_url: 'http://41.216.184.150:8898',
//   grant_type: 'password',
//   client_id: 'web',
//   client_secret: '123456'
// };

// For QA environment, 93 port ---- New
// export const environment = {
//   production: true,
//   auth_server_url: 'http://41.216.184.150:8092/auth-server',
//   api_url: 'http://41.216.184.150:8095/wprd-web-service/api',
//   user_api_url: 'http://41.216.184.150:8088',
//   grant_type: 'password',
//   client_id: 'web',
//   client_secret: '123456'
// };

// For UAT environment, 16000 port ---- New
// export const environment = {
//   production: true,
//   auth_server_url: 'http://41.216.184.150:8091/auth-server',
//   api_url: 'http://41.216.184.150:8094/wprd-web-service/api',
//   user_api_url: 'http://41.216.184.150:8898',
//   grant_type: 'password',
//   client_id: 'web',
//   client_secret: '123456'
// };

// For UAT environment, 93 port :- UAT New
// export const environment = {
//   production: true,
//   auth_server_url: 'http://41.216.184.120:8092/auth-server',
//   api_url: 'http://41.216.184.120:8094/wprd-web-service/api',
//   user_api_url: 'http://41.216.184.120:8899',
//   grant_type: 'password',
//   client_id: 'web',
//   client_secret: '123456'
// };

// For UAT environment, 92 port :- UAT New
// export const environment = {
//   production: true,
//   auth_server_url: 'http://41.216.184.120:8091/auth-server',
//   api_url: 'http://41.216.184.120:8095/wprd-web-service/api',
//   user_api_url: 'http://41.216.184.120:8898',
//   grant_type: 'password',
//   client_id: 'web',
//   client_secret: '123456'
// };

// For UAT environment, 16000 port :- UAT New
// export const environment = {
//   production: true,
//   auth_server_url: 'http://43.224.124.96:8091/auth-server',
//   api_url: 'http://43.224.124.96:8094/wprd-web-service/api',
//   user_api_url: 'http://43.224.124.96:8898',
//   grant_type: 'password',
//   client_id: 'web',
//   client_secret: '123456'
// };

// 192.168.85.9
// export const environment = {
//   production: true,
//   auth_server_url: 'http://192.168.85.9:9091/auth-server',
//   api_url: 'http://192.168.85.9:9094/wprd-web-service/api',
//   user_api_url: 'http://192.168.85.9:9098',
//   grant_type: 'password',
//   client_id: 'web',
//   client_secret: '123456'
// };

// 192.168.85.5
// export const environment = {
//   production: true,
//   auth_server_url: "https://sdop.wp.gov.lk:8091/auth-server",
//   api_url: "https://sdop.wp.gov.lk:8094/wprd-web-service/api",
//   user_api_url: "https://sdop.wp.gov.lk:8898",
//   grant_type: "password",
//   client_id: "web",
//   client_secret: "123456",
// };

// staging Environment
// export const environment = {
//   production: true,
//   auth_server_url: "https://sdop.wp.gov.lk:8443/auth-server",
//   api_url: "https://sdop.wp.gov.lk:8443/wprd-web-service",
//   user_api_url: "https://sdop.wp.gov.lk:8443/user",
//   grant_type: "password",
//   client_id: "web",
//   client_secret: "123456",
// };

// export const environment = {
//   production: true,
//   auth_server_url: "https://sdop.wp.gov.lk:7443/auth/auth-server",
//   api_url: "https://sdop.wp.gov.lk:7443/backend/wprd-web-service",
//   user_api_url: "https://sdop.wp.gov.lk:7443/userend/user",
//   grant_type: "password",
//   client_id: "web",
//   client_secret: "123456",
// };

//QA New 7443
// export const environment = {
//   production: true,
//   auth_server_url: "https://sdop.wp.gov.lk:7443/auth/auth-server",
//   api_url: "https://sdop.wp.gov.lk:7443/backend/wprd-web-service/api",
//   user_api_url: "https://sdop.wp.gov.lk:7443/user",
//   grant_type: "password",
//   client_id: "web",
//   client_secret: "123456",
// };

//QA New 7091
// export const environment = {
//   production: true,
//   auth_server_url: "https://sdop.wp.gov.lk:7091/auth-server",
//   api_url: "https://sdop.wp.gov.lk:7094/wprd-web-service/api",
//   user_api_url: "https://sdop.wp.gov.lk:7898",
//   grant_type: "password",
//   client_id: "web",
//   client_secret: "123456",
// };

// epic QA
// export const environment = {
//   production: true,
//   auth_server_url: 'http://41.216.184.150:8091/auth-server',
//   api_url: 'http://41.216.184.150:8094/wprd-web-service/api',
//   user_api_url: 'http://41.216.184.150:8898/',
//   grant_type: 'password',
//   client_id: 'web',
//   client_secret: '123456'
// };


// export const environment = {
//   production: false,
//   auth_server_url: 'http://localhost:8081/auth-server',
//   // auth_server_url: 'http://172.20.10.8:8081/auth-server',
//   // auth_server_url: 'http://192.168.43.44:8081/auth-server',
//   api_url: 'http://localhost:8080/wprd-web-service/api',
//   // api_url: 'http://172.20.10.8:8080/wprd-web-service/api',
//   // api_url: 'http://192.168.43.44:8080/wprd-web-service/api',
//   user_api_url: 'http://localhost:8888',
//   // user_api_url: 'http://172.20.10.8:8888',
//   // user_api_url: 'http://192.168.43.44:8888',
//   grant_type: 'password',
//   client_id: 'web',
//   client_secret: '123456'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
