import { Component, OnInit } from '@angular/core';
import {PaymentService} from '../../../../../service/payment/payment.service';
import {Payment} from '../../../../../model/payment';

@Component({
  selector: 'app-new-application',
  templateUrl: './new-application.component.html',
  styleUrls: ['./new-application.component.scss']
})
export class NewApplicationComponent implements OnInit {

  netureSelect = '0';

  constructor(private paymentService: PaymentService) { }

  ngOnInit() {
  }

  payWithLgps() {
    const payment = new Payment();
    payment.amount = '10.00';
    this.paymentService.initiatePayment(payment).subscribe( res => {
        window.location = res.data;
    }, error => {
        console.log(error);
    });
  }
}
