import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import Swal from 'sweetalert2';
import Cropper from 'cropperjs';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-file-upload5',
  templateUrl: './file-upload5.component.html',
  styleUrls: ['./file-upload5.component.scss']
})

export class FileUpload5Component implements OnInit {

  selectedElement: SafeResourceUrl;
  pdfsrc: null;

  constructor(private sanitizer: DomSanitizer) {
  }

  @Output()
  outPutImages5 = new EventEmitter<File[]>();
  filesList5: File[] = [];
  images5 = [];
  originalImages5: any[] = [];
  names5: string[] = [];
  selectedIndex = 0;
  files5;
  showEdit = false;
  showEditPdf = false;
  public progress = 0;

  cropper;
  imgid=uuidv4();
  ngOnInit() {

  }

  uploadChange5(event) {
    this.files5 = event.target.files;
    this.progress = 0;
    for (let i = 0; i < this.files5.length; i++) {
      const file = this.files5[i];
      if (file.type === "application/pdf" || file.type === "image/png" || file.type === "image/jpeg") {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          this.progress = 100;
          this.images5.push(_event.currentTarget.result);
          this.originalImages5.push(_event.currentTarget.result);
          this.filesList5.push(file);
        };
        this.names5.push(file.name);
        this.progress = 0;
      } else {

        Swal.fire({
          text: 'File type not allowed here!',
          icon: 'warning',
          showCancelButton: false,

        });
        event.srcElement.value = null;
      }
    }

  }

  changeImg(event) {

    if (this.cropper !== undefined) {
      this.cropper.destroy();
    }
    const image = document.getElementById(this.imgid) as HTMLImageElement;
    image.src = event.target.src;
    this.cropper = new Cropper(image, {
      crop(event) {
      },
    });
  }

  outPutImagesSet() {
    this.outPutImages5.emit(this.filesList5);
  }

  edit(event, index) {
    // this.selectedElement=this.sanitizer.bypassSecurityTrustResourceUrl(this.images5[index]);
    if (this.filesList5[index].type === "application/pdf") {

      // document.getElementById('myiframe-nic').setAttribute('src',this.images5[index])
      this.showEdit = false;
      this.showEditPdf = true;
      this.pdfsrc = null;
      this.pdfsrc = this.images5[index];
    } else {
      this.showEdit = true;
      this.showEditPdf = false;
      const image = document.getElementById(this.imgid) as HTMLImageElement;
      image.src = event.target.parentElement.parentElement.children[0].src;
      if (this.cropper !== undefined) {
        this.cropper.destroy();
      }
      this.cropper = new Cropper(image, {
        rotatable: true,
        crop(event) {

        },
      });
    }

    this.selectedIndex = index;
  }

  delete(index) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5085d6',
      cancelButtonColor: '#d55',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.images5.splice(index, 1);
        this.originalImages5.splice(index, 1);
        this.names5.splice(index, 1);
        this.filesList5.splice(index, 1);
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );

      }
    });

    // this.images.splice(index, 1);
    // this.originalImages.splice(index, 1);
    // this.names.splice(index, 1);
    // this.filesList.splice(index, 1);


  }

  closeEditor() {
    this.showEdit = false;
    this.showEditPdf = false;
  }

  rotate(angle: number) {
    this.cropper.move(1, -1).rotate(angle);
  }

  resetImage() {
    this.cropper.destroy();
    this.images5[this.selectedIndex] = this.originalImages5[this.selectedIndex];
    const image = document.getElementById(this.imgid) as HTMLImageElement;
    image.src = this.images5[this.selectedIndex];
    this.cropper = new Cropper(image, {
      rotatable: true,
      crop(event) {
      },
    });
  }

  crop() {
    this.images5[this.selectedIndex] = this.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    this.cropper.destroy();

    this.images5[this.selectedIndex] = this.images5[this.selectedIndex];
    const image = document.getElementById(this.imgid) as HTMLImageElement;
    image.src = this.images5[this.selectedIndex];
    this.cropper = new Cropper(image, {
      rotatable: true,
      crop(event) {
      },
    });
  }

}
