import { InspectionAccomodation } from './inspection-accomodation';

export class InspectionCondominiumUnit {

  public floor: string;
  public unitNumber: string;
  public assessNumber: string;
  public extentSQM: any;
  public extentSQFT: any;
  public assessaryUnit: string;
  public shareValue: string;
  public inspectionAccomodation: Array<InspectionAccomodation>;

  constructor(
    floor: string,
    unitNumber: string,
    assessNumber: string,
    extentSQM: any,
    extentSQFT: any,
    assessaryUnit: string,
    shareValue: string,
    inspectionAccomodation: Array<InspectionAccomodation>
  ) {
    this.floor = floor;
    this.unitNumber = unitNumber;
    this.assessNumber = assessNumber;
    this.extentSQFT = extentSQFT;
    this.extentSQM = extentSQM;
    this.assessaryUnit = assessaryUnit;
    this.shareValue = shareValue;
    this.inspectionAccomodation = inspectionAccomodation;
  }

}
