import { IPropertyFacility } from './interfaces/property-facility';
import { IBuilding } from './interfaces/building';
import { ILoan } from './interfaces/loan';

export class PropertyHistoryModel {
  deedNature: string;
  previousDeedNo: string;
  previousDeedNature: string;
  previousDeedDate: string;
  previousRegistrationNo: string;
  previousRegistrationDate: string;
  folioVolume: string;
  localAuthority: string;
  cmcNo: string;
  surveyorPlanNo: string;
  surveyorPlanDate: string;
  surveyorName: string;
  surveyorLicenseNo: string;
  lotNo: string;
  nameOfTheProperty: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  province: string;
  district: string;
  gramaNiladariDivision: string;
  nearestTown: string;
  townNo: number;
  distanceFromTheNearestTown: string;
  propertyUsage: string;
  propertyUsageSubType: string;
  otherPropertyUsage: string;
  otherPropertyUsageSubtype: string;
  detailsOfTheCropCultivation: string;
  extent: string;
  zoneNo: string;
  otherDetails: string;
  propertyRented: boolean;
  monthlyRent: number;
  decidedPurchaseValue: number;
  dateForMarketValue: any;
  prevOpinion: boolean;
  prevOpinionNo: string;
  valuation: number;
  stampDuty: number;
  fileNumberFormat: string;
  valuationRequest: string;
  bankValuation: any;
  propertyFacilitiesValue: string;
  propertyFacilities: IPropertyFacility[];
  buildings: IBuilding[];
  loan: ILoan;
  propertyRentedValue: any;
  isPropertyUsedLoan: boolean;
}
