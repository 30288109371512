import {Component, OnInit, Inject, Input, ViewChild, ElementRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubSink} from 'subsink';
import {NotifierService} from 'angular-notifier';
import {Building} from '../../../../../../../../model/building';
import {FloorTypeService} from '../../../../../../../../service/floor-type/floor-type.service';
import {FloorSubTypeService} from '../../../../../../../../service/floor-sub-type/floor-sub-type.service';
import {Property} from '../../../../../../../../model/property';
import {BuildingFloor} from '../../../../../../../../model/building-floor';
import {FutureDateValidator} from "../../../../../../../validators/futuredate.validator";

declare var $: any;

@Component({
  selector: 'app-property-building',
  templateUrl: './property-building.component.html',
  styleUrls: ['./property-building.component.scss']
})
export class PropertyBuildingComponent implements OnInit {

  @ViewChild('floorTypeEl', {static: true})
  floorTypeEl: ElementRef<HTMLSelectElement>;
  @ViewChild('floorSubTypeEl', {static: true})
  floorSubTypeEl: ElementRef<HTMLSelectElement>;
  @ViewChild('modelCloseBtn', {static: false})
  modelCloseBtn: ElementRef;

  script;
  @Input() propertyOne: Property;
  addBuildingForm: FormGroup;
  floorTypes = [];
  floorSubTypes = [];
  buildingFloors = [];
  tempBuildingFloors = [];
  selectedFloorType: string;
  selectedFloorSubType: string;
  selectedBuildingIndex: number;
  selectedFloorIndex: number;
  buildingIndex: number;
  isUpdate = false;
  noOfFloors: number;
  maxDate: any;
  planDate: any;
  yearofConstruct: any;
  updatingBuilding: Building;
  addedPlanNo: string;
  newPlanNo: string;
  // Validation
  onlyNumbersPattern = '^[0-9]*$';
  onlyDecimalNumbersPattern = '^[0-9]+(\\.[0-9]{1,2})*$';
  onlyCharactersNumbersPattern = '^[_A-z0-9 ]*((-|\\s)*[_A-z0-9 ])*$';
  uniqueNumer = this.getModalID();
  private sub = new SubSink();

  constructor(
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private floorTypeService: FloorTypeService,
    private floorSubTypeService: FloorSubTypeService) {
    // $(document).ready(function() {
    //   $('select.selectpicker').selectpicker();
    // });
  }

  // @Inject(DOCUMENT) private readonly document: any,

  get f() {
    return this.addBuildingForm.controls;
  }

  ngOnInit() {

    // this.AddJs('assets/js/bootstrap.bundle.min.js');
    // this.AddJs('assets/js/bootstrap-select.min.js');
    this.addBuildingForm = this.formBuilder.group({
      // planno: ['', [Validators.required]],
      planno: [''],
      // plandate: ['', [Validators.required]],
      plandate: [''],
      yearofconstruct: ['', [Validators.required]],
      totalfloorarea: [{value: 0, disabled: true}],
      floorNumber: ['', [Validators.required]],
      floorUnit: ['', [Validators.required]],
      floortype: ['', [Validators.required]],
      floorsubtype: ['', [Validators.required]],
      floorArea: ['', [Validators.required, Validators.pattern(this.onlyDecimalNumbersPattern)]],
      floorscount: [{
        value: this.getNumberOfFloors(),
        disabled: true
      }]
    }, {
      validator: FutureDateValidator('plandate')
    });

    this.sub.add(this.floorTypeService.getAll()
      .subscribe(
        (res) => {
          this.floorTypes = (res as any).data;
        }
      ));

    this.sub.add(this.floorSubTypeService.getAll()
      .subscribe(
        (res) => {
          this.floorSubTypes = (res as any).data;
        }
      ));

    this.maxDate = new Date();
  }

  getModalID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  // AddJs(path) {
  //   const element = document.getElementById(path);
  //   if (element === null) {
  //     this.script = this.document.createElement('script');
  //     this.script.type = 'text/javascript';
  //     this.script.async = true;
  //     this.script.id = path;
  //     this.script.src = path;
  //     this.document.body.appendChild(this.script);
  //   }
  // }

  // adding the floor number
  getNumberOfFloors() {
    const map = new Map();
    this.buildingFloors.forEach(element => {
      map.set(element.floorNum, element.floorUnit);
    });

    return map.size;
  }

  addBuilding() {
    if (this.checkBuildingValidity()) {
      const building = new Building();
      // this.newPlanNo = this.getPlanNo();
      // if (this.propertyOne.buildings.length > 0) {
      //   this.addedPlanNo = this.propertyOne.buildings[0].planNo;
      //   if (this.addedPlanNo === this.newPlanNo) {
      //     this.notifier.notify('error', 'Can not add the same Plan Number');
      //   } else {
      //     building.planNo = this.getPlanNo();
      //     building.planDate = this.getPlanDate();
      //     building.yearOfConstruct = this.getYearOfConstruct();
      //     building.noOfFloors = this.getNumberOfFloors();
      //     building.totalFloorArea = this.getTotalFloorArea();
      //     building.buildingFloors = this.buildingFloors;
      //     // push to the array
      //     this.propertyOne.buildings.push(building);
      //     this.buildingFloors = [];
      //     this.modelClose();
      //     this.notifier.notify('success', 'Building Added Successfully');
      //     this.isUpdate = false;
      //   }

      building.planNo = this.getPlanNo();
      building.planDate = this.getPlanDate();
      building.yearOfConstruct = this.getYearOfConstruct();
      building.noOfFloors = this.getNumberOfFloors();
      building.totalFloorArea = this.getTotalFloorArea();
      building.buildingFloors = this.buildingFloors;
      // push to the array
      this.propertyOne.buildings.push(building);
      this.buildingFloors = [];
      this.modelClose();
      this.notifier.notify('success', 'Building Added Successfully')
      this.isUpdate = false;
    } else {
      this.notifier.notify('error', 'Building Added Failed');
    }

  }

  checkBuildingValidity() {
    this.addBuildingForm.get('planno').markAsTouched();
    this.addBuildingForm.get('plandate').markAsTouched();
    this.addBuildingForm.get('yearofconstruct').markAsTouched();
    if (this.buildingFloors.length <= 0) {
      this.checkFloorValidity();
    }

    // check validity
    if (this.addBuildingForm.get('plandate').value !== null && this.addBuildingForm.get('plandate').value !== '') {
      // if (this.checkPlanDateWithConstructYear()) {
        if (this.addBuildingForm.get('planno').valid && this.addBuildingForm.get('plandate').valid
          && this.addBuildingForm.get('yearofconstruct').valid && this.buildingFloors.length > 0) {
          return true;
        } else {
          return false;
        }
      // } else {
      //   this.notifier.notify('error', 'Plan date should be a past date compared to the Date of Construct');
      //   this.addBuildingForm.get('yearofconstruct').markAsTouched();
      //   this.addBuildingForm.get('plandate').markAsTouched();
      // }
    } else {
      if (this.addBuildingForm.get('planno').valid && this.addBuildingForm.get('yearofconstruct').valid && this.buildingFloors.length > 0) {
          return true;
        } else {
          return false;
        }
    }
  }

  addFloor() {
    if (this.checkFloorValidity()) {
      const buildingFloor = this.buildBuildingFloor();
      this.buildingFloors.push(buildingFloor);
      // this.addBuildingForm.get('floorscount').setValue(this.buildingFloors.length);
      this.calculateTotalArea();
      this.resetBuilding();
    }
  }

  buildBuildingFloor(): BuildingFloor {
    const floor = new BuildingFloor();
    floor.floorNum = this.addBuildingForm.get('floorNumber').value;
    floor.floorUnit = this.addBuildingForm.get('floorUnit').value;
    floor.floorType = this.selectedFloorType;
    floor.floorSubType = this.selectedFloorSubType;
    floor.area = Number(this.addBuildingForm.get('floorArea').value);
    this.noOfFloors = Number(this.addBuildingForm.get('floorscount').value);
    return floor;
  }

  getPlanNo() {
    return this.addBuildingForm.get('planno').value;
  }

  getPlanDate() {
    return this.addBuildingForm.get('plandate').value;
  }

  // getUnitNumber() {
  //   return this.addBuildingForm.get('unitno').value;
  // }
  getYearOfConstruct() {
    return this.addBuildingForm.get('yearofconstruct').value;
  }

  getNoOfFloors() {
    return Number(this.addBuildingForm.get('floorscount').value);
  }

  getTotalFloorArea() {
    return Number(this.addBuildingForm.get('totalfloorarea').value);
  }

  getFloorSubTypeByFloorTypeId(value) {
    console.log(value);
    if (null !== value) {
      const floorTypeElement = this.floorTypeEl.nativeElement;
      this.addBuildingForm.get('floorsubtype').reset();
      this.selectedFloorType = floorTypeElement.options[floorTypeElement.selectedIndex].innerText;
      this.sub.add(this.floorTypeService.getFloorSubTypesByFloorTypeId(Number(value), 0, 200)
        .subscribe(
          (res) => {
            this.floorSubTypes = (res as any).data.content;
          }
        ));
    }
  }

  // getFloorCount() {
  //   const floorCount = this.addBuildingForm.get('floorNumber').value;
  //   this.addBuildingForm.get('floorscount').setValue(floorCount +);
  //
  // }

  clearForm() {
    this.propertyOne.buildings = [];
    this.buildingFloors = [];
    this.addBuildingForm.reset();
  }

  onChangeSubType(event) {
    if (event.target.value !== null) {
      const floorSubTypeElement = this.floorSubTypeEl.nativeElement;
      this.selectedFloorSubType = floorSubTypeElement.options[floorSubTypeElement.selectedIndex].innerText;
    }
  }

  removeFloor(index: number) {
    this.buildingFloors.splice(index, 1);
    this.notifier.notify('success', 'Floor data removed successfully');
    this.calculateTotalArea();
    this.getNumberOfFloors();
  }

  calculateTotalArea(): number {
    let result = 0;
    this.buildingFloors.forEach(function (floor) {
      result = result + floor.area;
    });
    this.addBuildingForm.get('totalfloorarea').setValue(Number(result));
    return result;
  }

  checkFloorValidity(): boolean {
    this.addBuildingForm.get('floorNumber').markAsTouched();
    this.addBuildingForm.get('floorUnit').markAsTouched();
    this.addBuildingForm.get('floortype').markAsTouched();
    this.addBuildingForm.get('floorsubtype').markAsTouched();
    this.addBuildingForm.get('floorArea').markAsTouched();

    if (this.addBuildingForm.get('floortype').valid && this.addBuildingForm.get('floorNumber').valid &&
      this.addBuildingForm.get('floorUnit').valid && this.addBuildingForm.get('floortype').valid
      && this.addBuildingForm.get('floorsubtype').valid &&
      this.addBuildingForm.get('floorArea').valid) {
      return true;
    } else {
      return false;
    }
  }

  removeBuilding(selectedBuildingIndex: number) {
    this.propertyOne.buildings.splice(selectedBuildingIndex, 1);
    this.notifier.notify('success', 'Building data removed successfully');
  }

  update(index: number) {
    this.isUpdate = true;
    this.selectedBuildingIndex = index;
    this.updatingBuilding = this.propertyOne.buildings[this.selectedBuildingIndex];
    this.buildingFloors = this.updatingBuilding.buildingFloors;
    this.addBuildingForm.patchValue({
      planno: this.propertyOne.buildings[0].planNo,
      plandate: this.propertyOne.buildings[0].planDate,
      yearofconstruct: this.propertyOne.buildings[0].yearOfConstruct,
      floorscount: this.propertyOne.buildings[0].noOfFloors,
      totalfloorarea: this.propertyOne.buildings[0].totalFloorArea
    });
    // this.addBuildingForm.get('planno').markAsTouched();
    // this.addBuildingForm.get('plandate').markAsTouched();
    // this.addBuildingForm.get('yearofconstruct').markAsTouched();
  }

  updateBuilding(i: number) {
    if (this.checkBuildingValidity()) {
      const building = new Building();
      building.planNo = this.getPlanNo();
      building.planDate = this.getPlanDate();
      // building.unitNumber = this.getUnitNumber();
      building.yearOfConstruct = this.getYearOfConstruct();
      // building.noOfFloors = this.buildingFloors.length;
      building.noOfFloors = this.getNumberOfFloors();
      building.totalFloorArea = this.getTotalFloorArea();
      building.buildingFloors = this.buildingFloors;
      // update the array
      this.propertyOne.buildings[i] = building;
      this.buildingFloors = [];
      this.isUpdate = false;
      this.notifier.notify('success', 'Building Updated Successfully')
      this.modelClose();
    } else {
      this.notifier.notify('error', 'Building Update Failed');
    }
  }

  resetForm() {
    this.addBuildingForm.reset();
    this.buildingFloors = [];
    this.isUpdate = false;
  }

  resetBuilding() {
    this.addBuildingForm.get('floorNumber').reset();
    this.addBuildingForm.get('floorUnit').reset();
    this.addBuildingForm.get('floortype').reset();
    this.addBuildingForm.get('floorsubtype').reset();
    this.addBuildingForm.get('floorArea').reset();
  }

  modelClose() {
    this.refresh();
    this.modelCloseBtn.nativeElement.click();
    // this.granteeCheck = true;
  }

  refresh() {
    this.addBuildingForm.reset();
  }

  checkPlanDateWithConstructYear() {
    this.planDate = this.getPlanDate().split('-');
    this.yearofConstruct = this.getYearOfConstruct().split('-');
    if (this.planDate < this.yearofConstruct) {
      return true;
    } else {
      return false;
    }
  }
}
