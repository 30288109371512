import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PropertyTypeService } from 'src/app/service/property-type/property-type.service';
import { FloorType } from 'src/app/model/floor-type';
import { PropertyType } from 'src/app/model/property-type';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-update-property-type',
  templateUrl: './update-property-type.component.html',
  styleUrls: ['./update-property-type.component.scss']
})
export class UpdatePropertyTypeComponent implements OnInit,OnDestroy {

  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  updatePropertyTypeForm: FormGroup;

  description: string;
  private sub=new SubSink();

  @Output() propertyTypeUpdated = new EventEmitter<boolean>();

  constructor(private formBuilder: FormBuilder, private propertyTypeServiece: PropertyTypeService,private notifier:NotifierService) { }

  ngOnInit() {

    this.updatePropertyTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]]
    })
  }
  ngOnDestroy(){
    this.sub.unsubscribe();
  }

  updatePropertyType() {

    this.propertyTypeServiece.getAll().subscribe(
      response => {
        const totalItems = response.data.length
        this.propertyTypeServiece.getPropertyTypes(0,totalItems).subscribe(res=>{
          const propertyTypesArray = (res as any).data.content;
          if (propertyTypesArray.map((PropertyT)=>String(PropertyT.name)).includes(String(this.updatePropertyTypeForm.get('name').value))){
            this.notifier.notify('error', 'Property type name "'+this.updatePropertyTypeForm.get('name').value +'" is exist');
            return;
          }else{
            if (this.updatePropertyTypeForm.valid) {
              const propertyType = new PropertyType();
              propertyType.description = this.getDescription();
              propertyType.name = this.getName();
              this.sub.add(this.propertyTypeServiece.updatePropertyType(this.getId(), propertyType)
                .subscribe(response => {
                  
                  this.updateTheView(true);
                this.notifier.notify('success',(response as any).message)
                  }));
            }
          }})
        })

  }

  get f() { return this.updatePropertyTypeForm.controls; }

  getId() {
    return this.updatePropertyTypeForm.get('id').value;
  }
  setIdAndDescription(id: number,name:string ,description: string) {
    this.updatePropertyTypeForm.get('id').setValue(id);
    this.updatePropertyTypeForm.get('name').setValue(name);
    this.updatePropertyTypeForm.get('description').setValue(description);
  }

  getDescription() {
    return this.updatePropertyTypeForm.get('description').value;
  }
  getName() {
    return this.updatePropertyTypeForm.get('name').value;
  }
  updateTheView(updated: boolean) {
    this.propertyTypeUpdated.emit(updated);
  }
}







