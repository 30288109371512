import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-opinion-information',
  templateUrl: './opinion-information.component.html',
  styleUrls: ['./opinion-information.component.scss']
})
export class OpinionInformationComponent implements OnInit {
  @Input()
  valuationRequestId: number;

  constructor() { }

  ngOnInit() {
  }

}
