import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Payment} from '../../../../../../../model/payment';
import {PaymentService} from '../../../../../../../service/payment/payment.service';
import {BankService} from '../../../../../../../service/bank/bank.service';
import {FileUpload6Component} from '../../../file-upload-other/file-upload6.component';
import {FileUploadComponent} from '../../../file-upload/file-upload.component';
import {FileUploadComponentBank} from '../../../file-upload-bank/file-upload-bank.component';
import {FileUploadComponentCash} from '../../../file-upload-cash/file-upload-cash.component';
import {NotifierService} from 'angular-notifier';
import {FutureDateValidator} from "../../../../../../validators/futuredate.validator";


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  @ViewChild('fileUploadBank', {static: false}) fileUploadBank: FileUploadComponentBank;
  @ViewChild('fileUploadCash', {static: false}) fileUploadCash: FileUploadComponentCash;

  @Input() payment: Payment;
  selectPaths = [] = [];
  initialAmount: string;
  paymentMethod: string;
  selectPaymentMethod = 1;
  selectedPaymentType = -1;
  cashPaymentForm: FormGroup;
  bankPaymentForm: FormGroup;
  cardPaymentForm: FormGroup;
  allBanks: any;
  maxDate: any;


  // Validations
  onlyCharactersNumbersPattern = '^[_A-z0-9 ]*((-|\\s)*[_A-z0-9 ])*$';
  receiptNoPattern = '^[0-9]*$';
  onlyTextPattern = '^[ a-zA-Z]*$';

  constructor(private formBuilder: FormBuilder, private notifier: NotifierService,
              private paymentService: PaymentService,
              private bankService: BankService) {
  }

  get f() {
    return this.cashPaymentForm.controls;
  }

  get f1() {
    return this.cardPaymentForm.controls;
  }

  get f2() {
    return this.bankPaymentForm.controls;
  }

  ngOnInit() {
    this.cashPaymentForm = this.formBuilder.group({
      receiptNo: ['', [Validators.required]],
      date: ['', [Validators.required]],
      amount: ['']
    }, {
      validator: FutureDateValidator('date')
    });

    this.bankPaymentForm = this.formBuilder.group({
      // referenceno: ['', [Validators.required, Validators.pattern(this.onlyCharactersNumbersPattern)]],
      referenceno: ['', [Validators.required]],
      date: ['', [Validators.required]],
      amount: [''],
      bank: ['', [Validators.required]],
      branch: ['', [Validators.required]]
    }, {
      validator: FutureDateValidator('date')
    });

    this.cardPaymentForm = this.formBuilder.group({
      date: [''],
      amount: ['']
    });

    this.maxDate = new Date();

    this.fetchAllBanks();

    this.initialAmount = '250.00';
  }

  makeActive(event: any, type: string) {
    let isAvtive = false;
    if (event.target.parentElement.classList.contains('active')) {
      isAvtive = true;
    }
    this.paymentMethod = type;
    const elements = event.target.parentElement.parentElement.querySelectorAll('.active');
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('active');
    }
    if (isAvtive) {
      event.target.parentElement.classList.remove('active');
    } else {
      event.target.parentElement.classList.add('active');
    }
  }

  setCopyOfBankReceiptDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  setCopyOfCashReceiptDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  setPaymentDetailsToValuationRequest() {
    this.payment.amount = this.initialAmount;
    this.payment.paymentMethod = this.paymentMethod;
    if (this.payment.paymentMethod !== 'null' && this.payment.paymentMethod !== undefined) {
      if (this.payment.paymentMethod === 'Bank-Payment') {
        this.bankPaymentForm.markAllAsTouched();

        if (this.bankPaymentForm.get('date').value == "") {
          this.notifier.notify('error', 'Please enter Paid Date');
          return false;
        } else {
          if (this.bankPaymentForm.get('branch').valid &&
          this.bankPaymentForm.get('bank').valid &&
          this.bankPaymentForm.get('referenceno').valid &&
          this.bankPaymentForm.get('date').valid) {

            if (this.fileUploadBank.filesList.length === 0) {
              this.notifier.notify('error', 'Please add Bank Receipt');
              return false;
            } else {
              this.payment.branch = this.bankPaymentForm.get('branch').value;
              this.payment.bank = this.bankPaymentForm.get('bank').value;
              this.payment.referenceNo = this.bankPaymentForm.get('referenceno').value;
              this.payment.paidDate = this.bankPaymentForm.get('date').value;
              return true;
            }
          } else {
            return false;
          }
        }

        
      } else if (this.payment.paymentMethod === 'Cash-Payment') {
        // setting data to cash-payment
        this.cashPaymentForm.markAllAsTouched();

        if (this.cashPaymentForm.get('date').value == "") {
          this.notifier.notify('error', 'Please enter Paid Date');
          return false;
        } else {
          if (this.cashPaymentForm.valid) {
            if (this.fileUploadCash.filesList.length === 0) {
              this.notifier.notify('error', 'Please add Cash Receipt');
              return false;
            } else {
              this.payment.referenceNo = this.cashPaymentForm.get('receiptNo').value;
              this.payment.paidDate = this.cashPaymentForm.get('date').value;
              return true;
            }
          } else {
            return false;
          }
        }
        
      } else {
        this.cardPaymentForm.markAllAsTouched();
        if (this.cardPaymentForm.valid) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      this.notifier.notify('error', 'Please select one payment method');
    }
  }

  paymentValidationCheck() {
    return true;
  }

  setPaymentToValuationRequest() {
    const payment = new Payment();
    payment.amount = this.initialAmount;
    this.payment = payment;
    /*// payment.amount = this.cashPaymentForm.get('amount').value;
    this.paymentService.initiatePayment(payment).subscribe( res => {
      window.location = res.data;
    }, error => {
      console.log(error);
    });*/
  }

  fetchAllBanks() {
    this.bankService.getAll().subscribe(result => {
      if (result) {
        this.allBanks = result.data;
      } else {

      }
    }, error => {
    });
  }

  setBankChildComponentUploadingDocuments() {
    this.fileUploadBank.outPutImagesSet();
  }

  setCashChildComponentUploadingDocuments() {
    this.fileUploadCash.outPutImagesSet();
  }
}
