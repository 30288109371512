import {Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2';

import {DashboardService} from '../../../service/dashboard/dashboard.service';
import {ActivatedRoute, Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GetOpinionDialogComponent } from './refund-application-external-user/get-opinion-dialog/get-opinion-dialog.component';
import { RefundOpinionServiceService } from 'src/app/service/RefundOpinionService/refund-opinion-service.service';
import { refundRequestCommon } from 'src/app/model/refundRequestCommon';
import { NotificationModelUpdate } from 'src/app/model/notification-model-update';
import { AuthService } from 'src/app/auth/auth.service';


export interface opinionNo{
  opinion:number;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard-external.component.html',
  styleUrls: ['./dashboard-external.component.scss']
})
export class DashboardExternalComponent implements OnInit {

  public common = new refundRequestCommon('','','','','','','',null,null,null,null,null,null)

  pendingCount: number;
  completedCount: number;
  rejectedCount: number;
  allRequestCount: number;
  paymentPendingCount: number;
  newRequestCount: number;
  furtherRequirementCount: number;
  userName: string;
  selectedComponent: string;
  draftRequestCount: number;
  notifications: any = [];
  numberOfNotifications: number;
  OpinionVal: number;
  sendOpinion:number;
  opinionNo:any;

  public notificationModelUpdate = new NotificationModelUpdate();
  opinionDetails: any;

  constructor(
    private dashboardService: DashboardService,
    private rout: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private authService: AuthService,
    private refundService: RefundOpinionServiceService
    ) {
  }

  ngOnInit() {
    this.userName = sessionStorage.getItem('username');
    this.pendingCountChange();
    this.completedCountChange();
    this.rejectedCountChange();
    this.furtherRequirementCountChange();
    this.draftCountChange();
    this.paymentPendingCountChange();
    this._prepare();
    localStorage.removeItem('opinionNo');
    localStorage.removeItem('fileNo');
    sessionStorage.removeItem('hasOpinion');
    sessionStorage.removeItem('refundRequestId');
  }

  _prepare() {
    this.listNotifications();
  }

  draftCountChange() {
    this.dashboardService.getRequestCount('DRAFT', 'EXTERNAL').subscribe(value => {
      this.draftRequestCount = value;
    });
  }

  pendingCountChange() {
    this.dashboardService.getRequestCount('PENDING','EXTERNAL').subscribe(value => {
      this.pendingCount = value;
    });
  }

  paymentPendingCountChange() {
    this.dashboardService.getRequestCount('PAYPEND','EXTERNAL').subscribe(value => {
      this.paymentPendingCount = value;
    });
  }

  completedCountChange() {
    this.dashboardService.getRequestCount('APPLICATION_COMPLETED','EXTERNAL').subscribe(value => {
      this.completedCount = value;
    });
  }

  rejectedCountChange() {
    this.dashboardService.getRequestCount('REJECTED','EXTERNAL').subscribe(value => {
      this.rejectedCount = value;
    });
  }

  furtherRequirementCountChange() {
    this.dashboardService.getRequestCount('FURTHER_REQUIREMENT','EXTERNAL').subscribe(value => {
      this.furtherRequirementCount = value;
    });
  }

  clickGift() {
    // Same method exists in header-external.component.ts
    this.selectedComponent = 'Gift';
    localStorage.setItem('valuation_request_id', null);
    localStorage.setItem('first_valuation_request_id', null);
    localStorage.setItem('second_valuation_request_id', null);
    sessionStorage.setItem('valuationUpdate', 'false');
    sessionStorage.setItem('selectedType', this.selectedComponent);
    this.router.navigate(['/new-application/gift']);
  }

  clickTransfer() {
    this.selectedComponent = 'Transfer';
    localStorage.setItem('valuation_request_id', null);
    localStorage.setItem('first_valuation_request_id', null);
    localStorage.setItem('second_valuation_request_id', null);
    sessionStorage.setItem('valuationUpdate', 'false');
    sessionStorage.setItem('selectedType', this.selectedComponent);
    this.router.navigate(['/new-application/transfer']);
  }

  clickExchange() {
    this.selectedComponent = 'Exchange';
    localStorage.setItem('valuation_request_id', null);
    localStorage.setItem('first_valuation_request_id', null);
    localStorage.setItem('second_valuation_request_id', null);
    sessionStorage.setItem('valuationUpdate', 'false');
    sessionStorage.setItem('selectedType', this.selectedComponent);
    this.router.navigate(['/new-application/exchange']);
  }

  listNotifications() {
    this.dashboardService.getNotificationList().subscribe(
      data => {
        this.notifications = data.data;
        this.numberOfNotifications = this.notifications.length;
      }
    )
  }

  updateNotification(notificationId) {
    this.notificationModelUpdate.notificationId = notificationId;

    this.dashboardService.updateNotification(this.notificationModelUpdate).subscribe(
      data => {
        console.log(data);
      }
    )
  }

  furtherRequirementApplication(deedNature,id){
    if (deedNature === 'Gift') {
      // Below localStorage items are being used in "other-fr.components.ts"
      localStorage.setItem('valuation_request_id', id);
      sessionStorage.setItem('valuationUpdate', 'true');
      sessionStorage.setItem('selectedType', 'Gift');
      this.router.navigate(['/further-requirement-update/gift-further-requirement']);
    } else if (deedNature === 'Transfer') {
      localStorage.setItem('valuation_request_id', id);
      sessionStorage.setItem('valuationUpdate', 'true');
      sessionStorage.setItem('selectedType', 'Transfer');
      this.router.navigate(['/further-requirement-update/transfer-further-requirement']);
    } else if (deedNature === 'Exchange') {
      localStorage.setItem('valuation_request_id', id);
      sessionStorage.setItem('valuationUpdate', 'true');
      sessionStorage.setItem('selectedType', 'Exchange');
      this.router.navigate(['/further-requirement-update/exchange-further-requirement']);
    }
  }

  openDialog() : void{
    const dialogRef =this.dialog.open(GetOpinionDialogComponent,{
      data:{opinion:this.OpinionVal}
    });
    dialogRef.afterClosed().subscribe(
      result => {
        this.refundService.getOpinionDetails(result).subscribe( 
          data => {
            this.opinionDetails = data.data;
            
            if (this.opinionDetails === false) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter valid Opinion Number!',
                allowOutsideClick: false,
                allowEscapeKey:false,
              });
            } else if (this.opinionDetails.stamp_duty === "null") {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Stamp Duty not Calculated!',
                allowOutsideClick: false,
                allowEscapeKey:false,
              });
            } else if (this.opinionDetails.isCalculationCompleted === false) {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Opinion Calculations not Completed!",
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
            } else {
              localStorage.setItem("opinionNo", result);
              this.opinionNo = localStorage.getItem("opinionNo");
              this.sendOpinion = parseInt(this.opinionNo);

              if (this.opinionNo !== "undefined") {
                this.router.navigate(["/refund-application", result]);
                sessionStorage.setItem("hasOpinion", "true");

                /* create common table refund request with opinion
                    need to check without opinion, check get-opinion-dialog.TS
                */
                localStorage.removeItem("refundId");
                this.common.hasOpinion = true;
                this.common.deedNumber = this.opinionNo;
                this.refundService
                  .createRefundRequestById(this.common)
                  .subscribe((data) => {
                    let getRefundid = (data as any).data.id;
                    localStorage.setItem("refundId", getRefundid);
                    sessionStorage.setItem("refundRequestId", getRefundid);
                    sessionStorage.setItem("hasPreviousOpinion", "true");
                  });
              }
            }
          }
        );
      }
    );
  }


  logout(){
    this.authService.logout();
  }

}
