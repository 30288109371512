import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PropertyUsageService} from '../../../../../../../service/property-usage/property-usage.service';
import {PropertyUsage} from '../../../../../../../model/propertyUsage';
import {SubSink} from 'subsink';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-update-property-usage',
  templateUrl: './update-property-usage.component.html',
  styleUrls: ['./update-property-usage.component.scss']
})
export class UpdatePropertyUsageComponent implements OnInit, OnDestroy {

  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");

  updatePropertyUsageForm: FormGroup;
  description: string;
  private sub = new SubSink();
  @Output() propertyUsageUpdated = new EventEmitter<boolean>();

  constructor(private propertyUsageService: PropertyUsageService,
              private formBuilder: FormBuilder,
              private notifier: NotifierService ) { }

  ngOnInit() {
    this.updatePropertyUsageForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['', [Validators.pattern(this.NoWhitespaceRegExp)]]
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  get f() { return this.updatePropertyUsageForm.controls; }
  updatePropertyUsage() {
    if (this.updatePropertyUsageForm.valid) {
      const propertyUsage = new PropertyUsage();
      propertyUsage.description = this.getDescription();
      propertyUsage.code=this.getCode();
      propertyUsage.name=this.getName();

      this.sub.add(this.propertyUsageService.updatePropertyUsage(this.getId(), propertyUsage)
        .subscribe(response => {
          this.updateTheView(true);
          this.notifier.notify( 'success', (response as any).message);
        }));
    }else{
      this.updatePropertyUsageForm.markAllAsTouched();
    }
  }

  getId() {
    return this.updatePropertyUsageForm.get('id').value;
  }

  setIdAndDescription(id: number, code:string,name:string,description: string) {
    this.updatePropertyUsageForm.get('id').setValue(id);
    this.updatePropertyUsageForm.get('name').setValue(name);
    this.updatePropertyUsageForm.get('code').setValue(code);
    this.updatePropertyUsageForm.get('description').setValue(description);
  }

  getDescription() {
    return this.updatePropertyUsageForm.get('description').value;
  }
  getCode() {
    return this.updatePropertyUsageForm.get('code').value;
  }
  getName() {
    return this.updatePropertyUsageForm.get('name').value;
  }

  updateTheView(updated: boolean) {
    this.propertyUsageUpdated.emit(updated);
  }

}
