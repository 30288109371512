import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ValuationRequestService} from '../../../../../../service/valuation-request/valuation-request.service';
import {SubSink} from 'subsink';
import {ValuationRequest} from '../../../../../../model/valuation-request';
import {HttpClient} from '@angular/common/http';
import FileSaver from 'file-saver';
import { Constant } from 'src/app/util/constant';
import { NotificationDetails } from 'src/app/model/notificationDetails';
import { NotificationService } from 'src/app/service/notification/notification.service';
import { ValuationRequestList } from 'src/app/model/valuation_request_list';

@Component({
  selector: 'app-pending-payment-request',
  templateUrl: './pending-payment-request.component.html',
  styleUrls: ['./pending-payment-request.component.scss']
})
export class PendingPaymentRequestComponent implements OnInit, OnDestroy {

  // pagination
  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];
  searchKeyword = '';
  nextPage: number;

  @Output() paymentPendingRequestCount = new EventEmitter<number>();
  valuationRequests: any;
  filteredValuationRequests = [];
  noResults = false;
  exchangeId: number;
  valuation: ValuationRequest;
  private sub = new SubSink();

  public model = new ValuationRequestList('', '', '', '', '', '');
  searchKeywordDeed: any;
  
  public notificationDetails = new NotificationDetails('','');
  searchKeywordDate: any;

  constructor(
    private valuationRequestService: ValuationRequestService,
    public notificationService: NotificationService,
    ) {
  }

  ngOnInit() {
    this.selectedPageNo = 0;
    this.limit = 10;
    this.searchKeyword = '';
    this.nextPage = 0;

    this.getAllResults();

  }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

  getPage(pageNo: number) {
    this.selectedPageNo = pageNo;
    this.sub.add(this.valuationRequestService.findValuationRequestByStatus(
      'PAYPEND', this.selectedPageNo, this.limit, 'EXTERNAL').subscribe(
      (res) => {
        this.valuationRequests = res.data.content;
        if (Object.keys(this.valuationRequests).length === 0) {
          this.noResults = true;
        } else {
          this.noResults = false;
        }
        this.paginationButtonCounter(res.data.totalPages);
        this.numOfPages = res.data.totalPages;
        // Setting the pagination for the table No. of Requests
        if (this.selectedPageNo != 0) {
          this.nextPage = 10 * this.selectedPageNo;
        } else {
          this.nextPage = 0;
        }
      }));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updateNotification(valuationReqId){
    this.notificationDetails.subject = Constant.CERTIFICATE_APPROVED_BY_COMMISSIONER;
    this.notificationDetails.valuationRequestId = valuationReqId;
    this.notificationService.updateReleventNotification(this.notificationDetails).subscribe();
  }

  search() {
    this.selectedPageNo = 0;
    this.limit = 10;
    if (this.searchKeyword.trim() !== '' && this.searchKeyword != null) {
      this.sub.add(this.valuationRequestService.searchValuationRequest(this.searchKeyword,
        'PAYPEND', this.selectedPageNo, this.limit, 'EXTERNAL').subscribe(
        (res) => {
          this.valuationRequests = res.data.content;
          if (Object.keys(this.valuationRequests).length === 0) {
            this.noResults = true;
          } else {
            this.noResults = false;
          }
          this.paginationButtonCounter(res.data.totalPages);
          this.numOfPages = res.data.totalPages;
          this.paymentPendingRequestCount.emit(res.data.totalElements);
        }));
    } else {

      this.getAllResults();
    }
  }

  searchByString(){
    this.selectedPageNo = 0;
    this.limit = 10;
    this.model.valuationStatus = Constant.VALIATION_PAYMENT_PENDING;

    if (this.searchKeywordDeed.trim()!=='' && this.searchKeywordDeed != null) {
      this.sub.add(this.valuationRequestService.searchValuationRequestByString(this.searchKeywordDeed,
        this.model.valuationStatus,  0, this.limit,  'EXTERNAL').subscribe(
        (res) => {
          this.valuationRequests = res.data.content;
          if (Object.keys(this.valuationRequests).length === 0) {
            this.noResults = true;
          } else this.noResults = false;
          this.paginationButtonCounter(res.data.totalPages);
          this.numOfPages = res.data.totalPages;
        }));
    } else {
      this.getAllResults();
    }
  }

  searchDate() {
    this.model.offset = `0`;
    this.model.limit = '10';
    this.model.valuationStatus = Constant.VALIATION_PAYMENT_PENDING;

    if (this.searchKeywordDate.trim() !== '' && this.searchKeywordDate != null) {
      this.sub.add(this.valuationRequestService.searchValuationRequestByDate(this.searchKeywordDate,
        this.model.valuationStatus, parseInt(this.model.offset), parseInt(this.model.limit), 'EXTERNAL',this.model.userId, this.model.userType).subscribe(
          (res) => {
            this.valuationRequests = res.data.content;
            if (Object.keys(this.valuationRequests).length === 0) {
              this.noResults = true;
            } else this.noResults = false;
            this.paginationButtonCounter(res.data.totalPages);
            this.numOfPages = res.data.totalPages;
          }));
      } else {
        this.getAllResults();
      }
  }

  filterValuationRequests() {
    for (this.valuation of this.valuationRequests) {
      if (this.valuation.exchangeId !== null) {
        if (this.exchangeId !== this.valuation.exchangeId) {
          this.exchangeId = this.valuation.exchangeId;
          this.filteredValuationRequests.push(this.valuation);
        }
      } else {
        this.filteredValuationRequests.push(this.valuation);
      }
    }
  }

  downloadpdf(id) {
    this.valuationRequestService.downloadpdf(id).subscribe(response => {
      let blob: any = new Blob([response], {type: 'application/pdf'});
      // const url= window.URL.createObjectURL(blob);
      // // window.open(url);
      // window.location.href = url;
      FileSaver.saveAs(blob, 'valuation_application_' + id + '.pdf');
    }), error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
    // console.log('lllll')
    // return this.valuationRequestService.downloadpdf(id);
  }

  getAllResults() {
    this.sub.add(this.valuationRequestService.findValuationRequestByStatus(
      'PAYPEND', this.selectedPageNo, this.limit, 'EXTERNAL').subscribe(
      (res) => {
        this.valuationRequests = res.data.content;
        // this.filterValuationRequests();
        if (Object.keys(this.valuationRequests).length === 0) {
          this.noResults = true;
        } else {
          this.noResults = false;
        }
        this.paginationButtonCounter(res.data.totalPages);
        this.numOfPages = res.data.totalPages;
        this.paymentPendingRequestCount.emit(res.data.totalElements);
      }));
  }
}
