import {Injectable} from '@angular/core';
import {FormControl} from '@angular/forms';
import {UsernameCheckService} from '../../service/usernameCheck.service';

@Injectable()
export class UsernameValidator {

  debouncer: any;

  constructor(public userProvider: UsernameCheckService) {

  }

  checkUsername(control: FormControl): any {

    clearTimeout(this.debouncer);

    return new Promise(resolve => {

      this.debouncer = setTimeout(() => {

        this.userProvider.validateUsername(control.value).subscribe((res) => {
          if (res) {
            resolve({'usernameInUse': true});

          } else {
            resolve(null);
          }
        }, (err) => {
          resolve({'usernameInUse': true});
        });

      }, 1000);

    });
  }

}
