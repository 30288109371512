import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InspectionCommonService {
  private inspectionRequestUrl = '/valuation-request';
  private userRequestUrl = '/inspection/common'; // URL to user api
  private localAuthorityRequestUrl = '/'; // URL to user api
  private propertyUsageUrl = '/inspection/common/inspectionpropertytype/all';
  private propertyUsageSubTypeUrl = '/inspection/common/inspectionsubpropertytype/all';
  private districtTypeUrl = '/districts';
  private townTypeUrl = '/towns';
  constructor(
    private http: HttpClient
  ) { }

  propertyUsageTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.propertyUsageUrl}`);
  }

  propertyUsageSubTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.propertyUsageSubTypeUrl}`);
  }

  // CONDOMINIUM SERVICES

  accomodationTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/accomodationType/all');
  }

  districtTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.districtTypeUrl}` + '/all');
  }

  townTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.townTypeUrl}`);
  }

  gardenTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/gardenType/all');
  }

  parkTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/parkType/all');
  }

  agreementTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/agreementtype/all');
  }

  condominiumTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/condominiumType/all');
  }

  facilityTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/facilityType/all');
  }

  incomeLevelTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/incomeLevelType/all');
  }

  specialTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/specialType/all');
  }

  surroundTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/surroundType/all');
  }

  // LAND SERVICES

  reservationTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/reservation/all');
  }

  landShapeTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/landshape/all');
  }

  landSlopeTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/landslope/all');
  }

  landLocationTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}` + '/local-authorities/all');
  }

  waterAvailabilityTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/wateravailability/all');
  }

  waterTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/watertype/all');
  }

  electricityTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/electricitytype/all');
  }

  electricitySubTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/electricitysubtype/all');
  }

  landSuitabilityTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/landsuitabilitytype/all');
  }

  transportTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/tranporttype/all');
  }

  educationTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/educationtype/all');
  }

  industrialTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/industrialtype/all');
  }

  healthTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/healthtype/all');
  }

  securityTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/securitytype/all');
  }

  telecommunicationTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/telecommunicationtype/all');
  }

  businessTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/businesstype/all');
  }

  industrialAreaTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/industrialareatype/all');
  }

  cultivationTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/cultivationtype/all');
  }

  ageCultivationTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/agecultivationtype/all');
  }

  landTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/landtype/all');
  }

  roiTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/roitype/all');
  }

  // Land with building
  categoryPropertyTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/categoryPropertyType/all');
  }

  buildingFeatureTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/buildingFeatureType/all');
  }

  buildingSubFeatureTypeList(): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}` + '/buildingSubFeatureType/all');
  }

  buildingSubFeatureTypeListByFeatureId(featureId:number): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.userRequestUrl}/buildingSubFeatureType/${featureId}`)
  }

  siteVisitScheduleList(reqID: number): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.inspectionRequestUrl}` + '/site-visit-schedule/'+reqID);
  }
}
