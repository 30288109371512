import { HttpClient } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NotifierService } from "angular-notifier";
import * as L from "leaflet";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { InspectionAccomodation } from "src/app/model/inspection-accomodation";
import { InspectionAccomodationDetail } from "src/app/model/inspection-accomodation-detail";
import { InspectionLandBuildingMain } from "src/app/model/inspection-building-main";
import { InspectionCommon } from "src/app/model/inspection-common";
import { InspectionCondominium } from "src/app/model/inspection-condominium";
import { InspectionCondominiumUnit } from "src/app/model/inspection-condominium-unit";
import { InspectionLand } from "src/app/model/inspection-land";
import { InspectionRecreation } from "src/app/model/inspection-recreation";
import { InspectionCommonService } from "src/app/service/inspection-common/inspection-common.service";
import { InspectionService } from "src/app/service/inspection/inspection.service";
import { Constant } from "src/app/util/constant";
import { v4 as uuidv4 } from "uuid";
import { checkCompletedOrPaypend } from "../Util/checkStatus";

@Component({
  selector: "app-inspection-report-recreation-condominium",
  templateUrl: "./inspection-report-recreation-condominium.component.html",
  styleUrls: ["./inspection-report-recreation-condominium.component.scss"],
})
export class InspectionReportRecreationCondominiumComponent implements OnInit {
  @ViewChild("dynamicAccomodation", null)
  private dynamicAccomodation: TemplateRef<object>;

  @ViewChild("popupSuccessMessage", null)
  private popupMigrated: TemplateRef<object>;

  @ViewChild("popupUpdateSuccessMessage", null)
  private popupMigratedUpdate: TemplateRef<object>;

  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  valuationRequestId: string;
  @Input()
  valuationRequestStatus: string;

  selectedRecreationSubPropertyType: any;

  showNextButton: boolean;

  public model1 = new InspectionCondominium();
  public modelLand = new InspectionLand();
  public modelLandBuildingLand = new InspectionLand();
  public recreationModel = new InspectionRecreation(
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  );
  public modellandbuildingmain = new InspectionLandBuildingMain(
    null,
    "",
    "",
    "",
    "",
    null,
    [],
    null,
    "",
    "",
    null,
    null,
  );
  public userdata: any = [];

  public selectedRecreationType: boolean;

  public disableSaveButton: boolean;

  selectedPropertyType: any;
  showBackButton: boolean;

  public filteredfacilitytypelist = [];
  public filteredspecialtypelist = [];
  public filteredsurroundtypelist = [];

  // Multi Select Dropdown Settings
  dropdownSettings1: NgMultiSelectDropDownModule;
  dropdownSettings2: NgMultiSelectDropDownModule;
  dropdownSettings3: NgMultiSelectDropDownModule;
  // Selected dropdown values
  selectedFacilityTypeItems = [];
  selectedSpecialViewItems = [];
  selectedSurroundTypeItems = [];

  public townList: any = [];
  public districtList: any = [];
  public localAuthorityList: any = [];
  public localAuthorityListtmp: any = [];
  public condominiumList: any = [];

  public facilityList: any = [];
  public specialViewList: any = [];
  public waterList: any = [];
  public electricityList: any = [];
  public securityList: any = [];
  public gardenList: any = [];
  public parkList: any = [];
  public agreementList: any = [];
  public incomeLevelTypeList: any = [];
  public surroundTypeList: any = [];
  public accomodationTypeList: any = [];
  public insertedFloorNo: string;
  public insertedUnitNo: string;

  public formaccomodationModel = new InspectionAccomodation();

  public condominiumUnitModel = new InspectionCondominiumUnit(
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    []
  );
  public accomodationModel = new InspectionAccomodation();
  public accomodationDetailModel = new InspectionAccomodationDetail();

  public condominiumUnitLoopCount: number;
  public condominiumUnitCount: number;

  public condominiumAccomodationCount: number;

  public condominiumAccomodationDetailLoopCount: number;
  public condominiumAccomodationDetailCount: number;

  public selectedcondominiumUnitCount: number;

  // inserting each row of condominium unit
  public condominiumUnitModelList: any = [];

  // inserting each row of accomodation unit
  public condominiumAccomodationModelList: any = [];

  // inserting each row of accomodation detail unit
  public condominiumAccomodationDetailModelList: any = [];

  public inspectionReportId: string;
  public recreationInspectionReportId: string;

  public inspectionCommonModel = new InspectionCommon("");

  public isUpdate: boolean;

  public inspectionCondominiumUnitsUpdateList: any = [];

  /*
   * recreation condominium devided in to 3 main views
   */
  firstView: boolean;
  secondView: boolean;
  finalView: boolean;
  propertySubTypeList: any;
  selectedSubPropertyType: any;
  isSubPropertyChange: boolean;

  locationDetails: any;
  longitude: any;
  latitude: any;
  addRecord: boolean;
  valuationdata: any;
  disableMainSaveBtn: boolean;

  checkStatusApplication  = checkCompletedOrPaypend;


  constructor(
    public modalService: NgbModal,
    public inspectionCommonService: InspectionCommonService,
    private route: ActivatedRoute,
    public inspectionService: InspectionService,
    private http: HttpClient,
    private notifier: NotifierService
  ) {
    this.selectedRecreationType = false;
    this.showNextButton = true;
    this.showBackButton = true;
    this.firstView = true;
    this.secondView = false;
    this.finalView = false;

    this.isUpdate = false;
    // this.selectedRecreationSubmitType = false;
    this.selectedRecreationType = false;
    // this.onlycondominiumselected = false;

    this.selectedcondominiumUnitCount = 0;
    this.condominiumUnitCount = 1;
    this.condominiumAccomodationCount = 0;
    this.condominiumAccomodationDetailCount = 1;

    // this.selectedRecreationUpdateType = false;
    this.addRecord = false;
    this.isSubPropertyChange = false;
    this.disableSaveButton = true;
    this.disableMainSaveBtn = false;
  }

  ngOnInit() {
    this.valuationdata = JSON.parse(sessionStorage.getItem("valuationRequest"));
    this.getSearchDetails("Colombo");

    this.isUpdate = JSON.parse(localStorage.getItem("recreationDetails"))
    this.selectedRecreationSubPropertyType = JSON.parse(
      atob(localStorage.getItem("selectedRecreationSubPropertyType"))
    );
    this.selectedRecreationType = JSON.parse(
      atob(localStorage.getItem("selectedRecreationType"))
    );
    this.selectedPropertyType = JSON.parse(
      atob(localStorage.getItem("propertyType"))
    );
    this._prepare();

    if (
      JSON.parse(atob(localStorage.getItem("selectedRecreationUpdateType"))) ===
      true
    ) {
      this.getInspectionRecreationById(
        JSON.parse(localStorage.getItem("inspectionRecreationCondominiummodel"))
          .id
      );
      // preview data
      localStorage.setItem("recreationDetails", JSON.stringify(true));
    } else {
      let recreationCondominium = localStorage.getItem(
        "inspectionRecreationCondominiummodel"
      );
      if (
        JSON.parse(
          localStorage.getItem("inspectionRecreationCondominiummodel")
        ) != null
      ) {
        this.model1 = JSON.parse(recreationCondominium);
        this.getSearchDetails(this.model1.nearestTown);
        console.log(this.model1);
        // data saved but not re-preview
        localStorage.setItem("recreationDetails", JSON.stringify(true));
      } else {
        this.model1.planNo = this.valuationdata.properties[0]["surveyorPlanNo"];
        this.model1.lotNo = this.valuationdata.properties[0]["lotNo"];
        // data not saved
        localStorage.setItem("recreationDetails", JSON.stringify(false));
      }
    }

    // this.loadRecreationData();

    window.scroll(0, 0);

    this.dropdownSettings1 = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      // itemsShowLimit: 3,   // Removing the limit - Bug ID: 2305
      unSelectAllText: "Unselect",
    };
    this.dropdownSettings2 = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      // itemsShowLimit: 3,   // Removing the limit - Bug ID: 2305
      unSelectAllText: "Unselect",
    };
    this.dropdownSettings3 = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      // itemsShowLimit: 3,   // Removing the limit - Bug ID: 2305
      unSelectAllText: "Unselect",
    };
    console.log(this.model1.nearestTown);
  }

  _prepare() {
    this.userdata = JSON.parse(atob(sessionStorage.getItem("userinfo")));

    this.recreationInspectionReportId = atob(
      localStorage.getItem("recreationCondominiumInspectionId")
    );
    if (this.recreationInspectionReportId !== "undefined") {
      this.model1.inspectionId = this.recreationInspectionReportId;
      this.loadCustomPropertyType();
    }
    this.inspectionReportId = atob(localStorage.getItem("inspectionId"));
    if (this.inspectionReportId !== "undefined") {
      this.isUpdate = true;
      this.retrieveUpdateFormDate();
    }
    this.userdata = JSON.parse(atob(sessionStorage.getItem("userinfo")));
    //checking this is the last component in recreation
    if (this.selectedRecreationSubPropertyType.length > 0) {
      for (let i = 0; i < this.selectedRecreationSubPropertyType.length; i++) {
        if (this.selectedRecreationSubPropertyType[i].id === 3) {
          this.showNextButton = true;
          break;
        } else {
          this.showNextButton = false;
        }
      }
      for (let i = 0; i < this.selectedRecreationSubPropertyType.length; i++) {
        if (this.selectedRecreationSubPropertyType[i].id === 1) {
          this.showBackButton = true;
          break;
        } else {
          this.showBackButton = false;
        }
      }
    }
    this.townTypeList();
    this.districtTypeList();
    this.localAuthorityTypeList();
    this.condominiumTypeList();
    this.facilityTypeList();
    this.specialTypeList();
    this.waterTypeList();
    this.electricityTypeList();
    this.securityTypeList();
    this.gardenTypeList();
    this.parkTypeList();
    this.agreementTypeList();
    this.incomeLevelList();
    this.surroundList();
    this.accomodationTypeLevelList();
    this.loadPropertyType();
  }

  loadRecreationData() {
    let recreationCondominium = localStorage.getItem(
      "inspectionRecreationCondominiummodel"
    );
    if (
      JSON.parse(
        localStorage.getItem("inspectionRecreationCondominiummodel")
      ) != null
    ) {
      this.model1 = JSON.parse(recreationCondominium);
      this.getSearchDetails(this.model1.nearestTown);
      console.log(this.model1.nearestTown)
      console.log(JSON.parse(recreationCondominium));
    }
  }

  getInspectionRecreationById(id: string) {
    this.inspectionService.getInspectionById(id).subscribe((data) => {
      console.log(data.data);
      this.model1 = data.data.inspectionCondominiumDto;
      this.condominiumUnitModelList = this.model1.inspectionCondominiumUnits;
      this.selectedFacilityTypeItems = this.model1.facilityTypes;
      this.selectedSpecialViewItems = this.model1.specialViewTypes;
      this.selectedSurroundTypeItems = this.model1.surroundTypes;
      if (this.model1.nearestTown != null || this.model1.nearestTown != undefined) {
        this.getSearchDetails(this.model1.nearestTown);
        console.log(this.model1.nearestTown)
      }
    });
  }

  selectedDistrict(event) {
    const filteredresult = this.localAuthorityListtmp.filter(
      (i) => i.district_id === Number(event.target.value)
    );
    if(filteredresult.length !==0){
      this.localAuthorityList = filteredresult;
    }
  }

  loadCustomPropertyType() {
    this.inspectionCommonService.propertyUsageTypeList().subscribe((data) => {
      const filtereddata = data.data.filter(
        (i) => i.id === Constant.INSPECTION_PROPERTY_TYPE_CONDOMINIUM_ID
      );
      this.selectedPropertyType = filtereddata[0];
      this.loadCustomPropertySubType();
    });
  }

  loadCustomPropertySubType() {
    this.inspectionCommonService
      .propertyUsageSubTypeList()
      .subscribe((data) => {
        const filtereddata = data.data.filter(
          (i) => i.inspectionPropertyType.id === this.selectedPropertyType.id
        );
        this.propertySubTypeList = filtereddata;
        this.recreationRetrieveUpdateFormData();
      });
  }

  recreationRetrieveUpdateFormData() {
    this.inspectionCommonModel.code = this.recreationInspectionReportId;
    this.inspectionService
      .inspectionCondominimumDetailList(this.inspectionCommonModel)
      .subscribe((data) => {
        this.model1 = data.data;
        this.model1.inspectionId = this.recreationInspectionReportId;
        if (this.model1.nearestTown != null || this.model1.nearestTown != undefined) {
          this.getSearchDetails(this.model1.nearestTown);
          console.log(this.model1.nearestTown)
        }
        const filtereddata = this.propertySubTypeList.filter(
          (i) => i.id === Number(this.model1.propertySubTypeId)
        );
        this.selectedSubPropertyType = filtereddata[0];
      });
  }

  loadPropertyType() {
    this.inspectionCommonService.propertyUsageTypeList().subscribe((data) => {
      const filtereddata = data.data.filter(
        (i) => i.id === Constant.INSPECTION_PROPERTY_TYPE_CONDOMINIUM_ID
      );
      this.selectedPropertyType = filtereddata[0];
      this.loadPropertySubType();
    });
  }

  loadPropertySubType() {
    this.inspectionCommonService
      .propertyUsageSubTypeList()
      .subscribe((data) => {
        const filtereddata = data.data.filter(
          (i) => i.inspectionPropertyType.id === this.selectedPropertyType.id
        );
        this.propertySubTypeList = filtereddata;
      });
  }

  selectedSubPropertyTypeForRecreation(event) {
    const filtereddata = this.propertySubTypeList.filter(
      (i) => i.id === Number(event)
    );
    this.selectedSubPropertyType = filtereddata[0];
    this.isSubPropertyChange = true;
  }

  //  relevent details for dropdowns  -- start

  initialModel() {
    // Facility Type
    for (let i = 0; i < this.selectedFacilityTypeItems.length; i++) {
      const selectedId = this.selectedFacilityTypeItems[i]["id"];
      const filteredFacility = this.facilityList.filter(
        (i) => i.id === selectedId
      );
      this.filteredfacilitytypelist[i] = filteredFacility[0];
    }
    this.model1.facilityTypes = this.filteredfacilitytypelist;
    this.model1.facilityTypes.forEach((x) => {
      delete x.id;
    });

    // Special Type
    for (let i = 0; i < this.selectedSpecialViewItems.length; i++) {
      const selectedId = this.selectedSpecialViewItems[i]["id"];
      const filteredFacility = this.specialViewList.filter(
        (i) => i.id === selectedId
      );
      this.filteredspecialtypelist[i] = filteredFacility[0];
    }
    this.model1.specialViewTypes = this.filteredfacilitytypelist;
    this.model1.specialViewTypes.forEach((x) => {
      delete x.id;
    });

    // Surround Type
    for (let i = 0; i < this.selectedSurroundTypeItems.length; i++) {
      const selectedId = this.selectedSurroundTypeItems[i]["id"];
      const filteredFacility = this.surroundTypeList.filter(
        (i) => i.id === selectedId
      );
      this.filteredsurroundtypelist[i] = filteredFacility[0];
    }
    this.model1.surroundTypes = this.filteredfacilitytypelist;
    this.model1.surroundTypes.forEach((x) => {
      delete x.id;
    });

    this.model1.valuationRequestId = this.valuationRequestId;
    this.model1.propertyTypeId = this.selectedPropertyType["id"];
    this.model1.propertySubTypeId = this.selectedSubPropertyType["id"];
    this.model1.createdBy = this.userdata["firstName"];
    this.model1.inspectionType =
      Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM;
    localStorage.setItem(
      "inspectioncondominiummodel",
      btoa(JSON.stringify(this.model1))
    );
  }

  retrieveUpdateFormDate() {
    this.inspectionCommonModel.code = this.inspectionReportId;
    this.inspectionService
      .inspectionCondominimumDetailList(this.inspectionCommonModel)
      .subscribe((data) => {
        this.model1 = data.data;
        this.selectedFacilityTypeItems = this.model1.facilityTypes;
        this.selectedSpecialViewItems = this.model1.specialViewTypes;
        this.selectedSurroundTypeItems = this.model1.surroundTypes;
        if (
          this.model1.nearestTown != null ||
          this.model1.nearestTown != undefined
        ) {
          this.getSearchDetails(this.model1.nearestTown);
          console.log(this.model1.nearestTown)
        }
      });
  }

  removeMainCondominiumUnit(index: any) {
    if (this.model1.inspectionCondominiumUnits.length > 0) {
      this.model1.inspectionCondominiumUnits[
        index
      ].inspectionAccomodation.splice(index, 1);
      this.model1.inspectionCondominiumUnits.splice(index, 1);
    } else {
      this.model1.inspectionCondominiumUnits.splice(index, 1);
    }
  }

  removeSubCondominiumUnit(index: any) {
    this.model1.inspectionCondominiumUnits[
      this.selectedcondominiumUnitCount
    ].inspectionAccomodation.splice(index, 1);
  }

  newAccomdationGrid(event, i) {
    if (event.target.checked === true) {
      this.selectedcondominiumUnitCount = i;
      this.openPopupAccomodation(this.dynamicAccomodation);
    }
  }

  newAccomdationGridInsert(inspection, i) {
    this.insertedFloorNo = inspection.floor;
    this.insertedUnitNo = inspection.unitNumber;
    this.accomodationModel.floor = this.insertedFloorNo;
    this.accomodationModel.unitNumber = this.insertedUnitNo;
    this.accomodationDetailModel.numbers = "";
    this.accomodationDetailModel.remark = "";
    this.accomodationDetailModel.accomodationCategoryId = "";
    this.disableSaveButton = true;
    this.selectedcondominiumUnitCount = i;
    this.openPopupAccomodation(this.dynamicAccomodation);
  }

  viewAccomdationGridInsert(i) {
    this.selectedcondominiumUnitCount = i;
  }

  getSubFloorValue(event, i) {
    this.accomodationModel.floor = event;
  }

  getSubUnitNoValue(event, i) {
    this.accomodationModel.unitNumber = event;
  }

  getCategoryValue(event, i) {
    this.condominiumAccomodationDetailLoopCount = i;
    this.accomodationDetailModel.accomodationCategoryId = event;
  }

  getSubNumberValue(event, i) {
    this.condominiumAccomodationDetailLoopCount = i;
    this.accomodationDetailModel.numbers = event;
  }

  getRemarkValue(event, i) {
    this.condominiumAccomodationDetailLoopCount = i;
    this.accomodationDetailModel.remark = event;
  }

  accomodationTypeLevelList() {
    this.inspectionCommonService.accomodationTypeList().subscribe((data) => {
      this.accomodationTypeList = data.data;
    });
  }

  incomeLevelList() {
    this.inspectionCommonService.incomeLevelTypeList().subscribe((data) => {
      this.incomeLevelTypeList = data.data;
    });
  }

  surroundList() {
    this.inspectionCommonService.surroundTypeList().subscribe((data) => {
      this.surroundTypeList = data.data;
    });
  }

  agreementTypeList() {
    this.inspectionCommonService.agreementTypeList().subscribe((data) => {
      this.agreementList = data.data;
    });
  }

  gardenTypeList() {
    this.inspectionCommonService.gardenTypeList().subscribe((data) => {
      this.gardenList = data.data;
    });
  }

  parkTypeList() {
    this.inspectionCommonService.parkTypeList().subscribe((data) => {
      this.parkList = data.data;
    });
  }

  securityTypeList() {
    this.inspectionCommonService.securityTypeList().subscribe((data) => {
      this.securityList = data.data;
    });
  }

  electricityTypeList() {
    this.inspectionCommonService.electricityTypeList().subscribe((data) => {
      this.electricityList = data.data;
    });
  }

  waterTypeList() {
    this.inspectionCommonService.waterTypeList().subscribe((data) => {
      this.waterList = data.data;
    });
  }

  specialTypeList() {
    this.inspectionCommonService.specialTypeList().subscribe((data) => {
      this.specialViewList = data.data;
    });
  }

  facilityTypeList() {
    this.inspectionCommonService.facilityTypeList().subscribe((data) => {
      this.facilityList = data.data;
    });
  }

  townTypeList() {
    this.inspectionCommonService.townTypeList().subscribe((data) => {
      this.townList = data.data;
    });
  }

  districtTypeList() {
    this.inspectionCommonService.districtTypeList().subscribe((data) => {
      this.districtList = data.data;
    });
  }

  localAuthorityTypeList() {
    this.inspectionCommonService.landLocationTypeList().subscribe((data) => {
      this.localAuthorityList = data.data;
      this.localAuthorityListtmp = data.data;
    });
  }

  condominiumTypeList() {
    this.inspectionCommonService.condominiumTypeList().subscribe((data) => {
      this.condominiumList = data.data;
    });
  }

  //  relevent details for dropdowns  -- end

  //accomodation -- start

  convertSqmToSqft() {
    this.condominiumUnitModel.extentSQFT =
      this.condominiumUnitModel.extentSQM * 10.7639;
  }

  convertSftToSqm() {
    this.condominiumUnitModel.extentSQM = (
      this.condominiumUnitModel.extentSQFT / 10.7639
    ).toFixed(2);
  }

  incrementAccomodationCount() {
    this.condominiumAccomodationCount++;
    this.accomodationModel = new InspectionAccomodation();
    this.condominiumAccomodationDetailCount = 1;
    this.model1.inspectionCondominiumUnits[
      this.selectedcondominiumUnitCount
    ].inspectionAccomodation.push(this.formaccomodationModel[0]);
    this.condominiumAccomodationModelList = [];
    this.condominiumAccomodationDetailModelList = [];
    this.formaccomodationModel = new InspectionAccomodation();
    this.modalService.dismissAll();
  }

  incrementUnitCount() {
    if (this.condominiumUnitModelList.length > 0) {
      for (let i = 0; i < this.condominiumUnitModelList.length; i++) {
        if (
          this.condominiumUnitModel.floor ==
            this.model1.inspectionCondominiumUnits[i].floor &&
          this.condominiumUnitModel.unitNumber ==
            this.model1.inspectionCondominiumUnits[i].unitNumber
        ) {
          this.notifier.notify(
            "error",
            "Please enter a different Floor or Unit number"
          );
          this.addRecord = false;
          break;
        } else {
          this.addRecord = true;
        }
      }

      if (this.addRecord == true) {
        this.condominiumUnitCount++;
        this.condominiumUnitModelList.push(this.condominiumUnitModel);
        this.model1.inspectionCondominiumUnits = this.condominiumUnitModelList;
        this.condominiumUnitModel = new InspectionCondominiumUnit(
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          []
        );
      }
    } else {
      this.condominiumUnitCount++;
      this.condominiumUnitModelList.push(this.condominiumUnitModel);
      this.model1.inspectionCondominiumUnits = this.condominiumUnitModelList;
      this.condominiumUnitModel = new InspectionCondominiumUnit(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        []
      );
    }
  }

  incrementAccomodationDetailCount() {
    // this is to loop the form
    this.condominiumAccomodationDetailCount =
      this.condominiumAccomodationDetailCount + 1;
    // this.condominiumAccomodationModelList[this.condominiumAccomodationCount] = this.accomodationModel;
    this.condominiumAccomodationModelList[0] = this.accomodationModel;
    // condominium accomodation model1
    this.formaccomodationModel = this.condominiumAccomodationModelList;
    this.condominiumAccomodationDetailModelList.push(
      this.accomodationDetailModel
    );
    // condominium accomodation detail model1
    this.formaccomodationModel[0].inspectionAccomodationDetails =
      this.condominiumAccomodationDetailModelList;
    this.accomodationDetailModel = new InspectionAccomodationDetail();

    this.disableSaveButton = false;
  }

  //accomodation -- end

  // popup methods-- start

  openPopupAccomodation(content) {
    this.modalService.open(content, {
      windowClass: "my-class",
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      backdrop: "static",
    });
  }
  // popup methods-- end

  //Redirect to main page
  redirectToMainPage() {
    this.onPageCountChanged.emit(10);
  }

  // first view navigation -- 2nd view back button -- start
  navigateToFirstView() {
    window.scroll(0, 0);
    this.firstView = true;
    this.secondView = false;
    this.finalView = false;
  }
  // first view navigation -- 2nd view back button -- end

  // second view navigation -- first view next button/ third view back button -- start
  navigateToSecondView() {
    if (this.model1.propertySubTypeId != undefined) {
      window.scroll(0, 0);
      this.firstView = false;
      this.secondView = true;
      this.finalView = false;
    } else {
      this.notifier.notify("error", `Please select Sub type first.`);
    }
  }
  // second view navigation -- first view next button/ third view back button -- end

  // third view navigation -- third view next button -- start
  navigateToFinalView() {
    window.scroll(0, 0);
    this.firstView = false;
    this.secondView = false;
    this.finalView = true;
  }
  // third view navigation -- third view next button -- end

  //final view next button - recreation land
  recreationNavigation() {
    window.scroll(0, 0);
    let page;
    this.model1.valuationRequestId = this.valuationRequestId;
    this.model1.propertyTypeId = this.selectedPropertyType["id"];
    this.model1.createdBy = this.userdata["firstName"];
    this.model1.inspectionType =
      Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM;
    this.getMultySelectionData();
    if (this.selectedRecreationType) {
      if (this.selectedRecreationSubPropertyType.length > 0) {
        for (
          let i = 0;
          i < this.selectedRecreationSubPropertyType.length;
          i++
        ) {
          if (this.selectedRecreationSubPropertyType[i].id === 3) {
            page = "land_building";
            break;
          }
        }
      }
    }
    if (page === "land_building") {
      // localStorage.setItem("isLand", JSON.stringify(true));
      this.selectedRecreationSubPropertyType.length === 3
        ? localStorage.setItem("isLand", JSON.stringify(true))
        : localStorage.setItem("isCondominium", JSON.stringify(true));
      localStorage.setItem(
        "inspectionRecreationCondominiummodel",
        JSON.stringify(this.model1)
      );
      this.onPageCountChanged.emit(17);
    }
  }

  //final BACK button - recreation land
  recreationBackNavigation() {
    window.scroll(0, 0);
    let page;
    this.model1.valuationRequestId = this.valuationRequestId;
    this.model1.propertyTypeId = this.selectedPropertyType["id"];
    this.model1.createdBy = this.userdata["firstName"];
    this.model1.inspectionType =
      Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM;
    this.getMultySelectionData();
    if (this.selectedRecreationType) {
      if (this.selectedRecreationSubPropertyType.length > 0) {
        for (
          let i = 0;
          i < this.selectedRecreationSubPropertyType.length;
          i++
        ) {
          if (this.selectedRecreationSubPropertyType[i].id === 1) {
            page = "land";
            break;
          }
        }
      }
    }
    if (page === "land") {
      localStorage.setItem("isLand", JSON.stringify(true));
      localStorage.setItem(
        "inspectionRecreationCondominiummodel",
        JSON.stringify(this.model1)
      );
      localStorage.setItem("isLandWithBuildingRecreation", JSON.stringify(false));
      this.onPageCountChanged.emit(16);
    }
  }

  //geo map function
  getSearchDetails(location: String) {
    this.http
      .post<any>(
        "https://nominatim.openstreetmap.org/search?q=" +
          location +
          "&format=geojson",
        { title: "Angular POST Request Example" }
      )
      .subscribe((data) => {
        this.locationDetails = data;
        console.log(this.locationDetails);
        if (this.locationDetails.features.length > 0) {
          this.longitude =
            this.locationDetails.features[0].geometry.coordinates[0];
          this.latitude =
            this.locationDetails.features[0].geometry.coordinates[1];
          document.getElementById("geomap").innerHTML =
            "<div id='map' style='width: 100%; height: 100%;'></div>";
          var map = new L.Map("map", { attributionControl: false });
          map.setView(new L.LatLng(this.latitude, this.longitude), 9);
          map.zoomIn(9);
          L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
            attribution: "",
          }).addTo(map);
          L.marker([this.latitude, this.longitude])
            .addTo(map)
            .bindPopup(this.locationDetails.features[0].properties.display_name)
            .openPopup();
        } else {
          document.getElementById("geomap").innerHTML =
            "<div id='map' style='width: 100%; height: 100%;'></div>";
          var map = new L.Map("map", { attributionControl: false });
          map.setView(new L.LatLng(6.9270786, 79.861243), 9);
        }
      });
  }

  // save and update methods

  onSubmit() {
    this.disableMainSaveBtn = true;
    var recreationGroupId;
    if (JSON.parse(localStorage.getItem("recreationGroupId")) != null) {
      recreationGroupId = localStorage.getItem("recreationGroupId");
      this.model1.recreationGroupId = JSON.parse(
        localStorage.getItem("recreationGroupId")
      );
    } else {
      recreationGroupId = uuidv4();
      localStorage.setItem(
        "recreationGroupId",
        JSON.stringify(recreationGroupId)
      );
    }
    let haveErrors:boolean = true;

    if (this.selectedRecreationType) {
      if (this.selectedRecreationSubPropertyType.length > 0) {
        for (
          let i = 0;
          i < this.selectedRecreationSubPropertyType.length;
          i++
        ) {
          if (this.selectedRecreationSubPropertyType[i].id === 1) {
            if (
              JSON.parse(
                localStorage.getItem("inspectionRecreationlandmodel")
              ) != null
            ) {
              this.modelLand = JSON.parse(
                localStorage.getItem("inspectionRecreationlandmodel")
              );

              JSON.parse(localStorage.getItem("recreationDetails")) === true ?
              this.modelLand.id = parseInt(localStorage.getItem("inspectionRecreationLandId")): this.modelLand.id = null

              this.modelLand.inspectionType =
                Constant.INSPECTION_TYPE_RECREATION_LAND;
              this.modelLand.recreationGroupId = JSON.parse(
                localStorage.getItem("recreationGroupId")
              );
              this.modelLand.createdBy = this.userdata["firstName"];
              this.modelLand.createdUserId = this.userdata["id"];
              this.recreationModel.isLand = true;
                if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
                  this.recreationModel.parentId = parseInt(this.modelLand.inspectionId);
                }
              if(this.modelLand.propertySubTypeId != null && this.modelLand.propertySubTypeId != undefined  && this.modelLand.propertySubTypeId != ""){
                haveErrors = false;
              }else{
                haveErrors = true;
              }
            } else {
              haveErrors = true;
              // if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
              //   haveErrors = true;
              // this.notifier.notify(
              //   "error",
              //   "Please select Land Sub type first."
              // );
              // }else{
              //   haveErrors = false;
              // }
            }
          } else if (this.selectedRecreationSubPropertyType[i].id === 2) {
            if (this.recreationModel.isLand !== true) {
              this.recreationModel.isCondominium = true;
              if(this.recreationModel.parentId === null){
                if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
                  this.recreationModel.parentId = parseInt(this.model1.inspectionId);
                }
              }
            } else {
              this.recreationModel.isCondominium = false;
            }
            JSON.parse(localStorage.getItem("recreationDetails")) === true ?
            this.model1.id = parseInt(localStorage.getItem("inspectionRecreationCondominiumId")): this.model1.id  = null;
            haveErrors = false;
          } else if (this.selectedRecreationSubPropertyType[i].id === 3) {
            if (
              JSON.parse(
                localStorage.getItem("inspectionLandBuilding-Land")
              ) != null
            ) {
              this.modelLandBuildingLand = JSON.parse(
                localStorage.getItem("inspectionLandBuilding-Land")
              );
              JSON.parse(localStorage.getItem("recreationDetails")) === true ?
              this.modelLandBuildingLand.id = parseInt(localStorage.getItem("inspectionRecreationLandwithBuilding-landId")): this.modelLandBuildingLand.id = null
              
              this.modelLandBuildingLand.inspectionType =
                Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING_LAND;
              this.modelLandBuildingLand.recreationGroupId = JSON.parse(
                localStorage.getItem("recreationGroupId")
              );
              this.modelLandBuildingLand.createdBy = this.userdata["firstName"];
              this.modelLandBuildingLand.createdUserId = this.userdata["id"];
              if(this.recreationModel.isLand !== true || this.recreationModel.isCondominium !== true){
                this.recreationModel.isLandBuilding = true;
              }else{
                this.recreationModel.isLandBuilding = false;
              }
              if(this.recreationModel.parentId === null){
                if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
                  this.recreationModel.parentId = parseInt(this.modelLandBuildingLand.inspectionId);
                }
              }
              if(this.modelLandBuildingLand.propertySubTypeId != null && this.modelLandBuildingLand.propertySubTypeId != undefined && this.modelLandBuildingLand.propertySubTypeId != ""){
                haveErrors = false;
              }else{
                haveErrors = true;
              }
            } else {
              haveErrors = true;
              // if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
              //   haveErrors = true;
              //   this.notifier.notify(
              //     "error",
              //     "Please Create Land in Land with Building First."
              //   );
              // }else{
              //   haveErrors = false;
              // }

            }
            if(JSON.parse(localStorage.getItem("inspectionLandBuilding-Building")) != null){
              this.modellandbuildingmain = JSON.parse(localStorage.getItem("inspectionLandBuilding-Building"));

              JSON.parse(localStorage.getItem("recreationDetails")) === true ?
                this.modellandbuildingmain.id = parseInt(localStorage.getItem("inspectionRecreationLandwithBuilding-buildingId")): this.modellandbuildingmain.id = null
              
            }
          }
        }
      }
    }
    this.getMultySelectionData();
    this.model1.valuationRequestId = this.valuationRequestId;
    this.model1.propertyTypeId = this.selectedPropertyType["id"];
    this.model1.createdBy = this.userdata["firstName"];
    this.model1.inspectionType =
      Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM;
    this.model1.createdUserId = this.userdata["id"];
    this.model1.recreationGroupId = JSON.parse(
      localStorage.getItem("recreationGroupId")
    );

    this.recreationModel.inspectionLandDto = this.modelLand;
    this.recreationModel.inspectionCondominiumDto = this.model1;
    this.recreationModel.inspectionLandBuildingDto = this.modellandbuildingmain;

    if(haveErrors === false){
      this.disableMainSaveBtn = false;
      if (JSON.parse(localStorage.getItem("recreationDetails")) === true) {
        this.inspectionService
          .inspectionRecreationUpdate(this.recreationModel)
          .subscribe(
            (data) => {
              this.openPopupMessage(this.popupMigratedUpdate);
              this.inspectionService
                .getLastInspectionRecreationRecords(
                  this.model1.createdUserId,
                  JSON.parse(localStorage.getItem("recreationGroupId"))
                )
                .subscribe((data) => {
                  console.log(data.data);
                  for (let i = 0; i < data.data.length; i++) {
                    switch (data.data[i].inspectionType) {
                      case Constant.INSPECTION_TYPE_RECREATION_LAND:
                        localStorage.setItem(
                          "inspectionRecreationLandId",
                          data.data[i].id
                        );
                        break;
                      case Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM:
                        localStorage.setItem(
                          "inspectionRecreationCondominiumId",
                          data.data[i].id
                        );
                        break;
                      case Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING_LAND:
                        localStorage.setItem(
                          "inspectionRecreationLandwithBuilding-landId",
                          data.data[i].id
                        );
                        break;
                      case Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING_BUILDING:
                        localStorage.setItem(
                          "inspectionRecreationLandwithBuilding-buildingId",
                          data.data[i].id
                        );
                        break;
                    }
                  }
                  localStorage.setItem("recreationDetails", JSON.stringify(true));
                });
            },
            (err) => {
              this.notifier.notify(
                "error",
                "Error occurred while processing. Please try again."
              );
            }
          );
      } else {
        this.inspectionService
          .inspectionRecreationInsert(this.recreationModel)
          .subscribe(
            (data) => {
              this.isUpdate = false;
              this.openPopupMessage(this.popupMigrated);
              this.inspectionService
                .getLastInspectionRecreationRecords(
                  this.model1.createdUserId,
                  JSON.parse(localStorage.getItem("recreationGroupId"))
                )
                .subscribe((data) => {
                  console.log(data.data);
                  for (let i = 0; i < data.data.length; i++) {
                    switch (data.data[i].inspectionType) {
                      case Constant.INSPECTION_TYPE_RECREATION_LAND:
                        localStorage.setItem(
                          "inspectionRecreationLandId",
                          data.data[i].id
                        );
                        break;
                      case Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM:
                        localStorage.setItem(
                          "inspectionRecreationCondominiumId",
                          data.data[i].id
                        );
                        break;
                      case Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING_LAND:
                        localStorage.setItem(
                          "inspectionRecreationLandwithBuilding-landId",
                          data.data[i].id
                        );
                        break;
                      case Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING_BUILDING:
                        localStorage.setItem(
                          "inspectionRecreationLandwithBuilding-buildingId",
                          data.data[i].id
                        );
                        break;
                    }
                  }
                  localStorage.setItem("recreationDetails", JSON.stringify(true));
                  this.isUpdate = true;
                });
            },
            (err) => {
              this.notifier.notify(
                "error",
                "Error occurred while processing. Please try again."
              );
              this.isUpdate = false;
            }
          );
      }
    }else{
      this.disableMainSaveBtn = false;
      this.isUpdate = false;
      this.notifier.notify(
        "error",
        "Please Fill all Required Fields."
      );
    }

  }

  getMultySelectionData(){
        // Facility Type
        for (let i = 0; i < this.selectedFacilityTypeItems.length; i++) {
          const selectedId = this.selectedFacilityTypeItems[i]['id'];
          const filteredFacility = this.facilityList.filter(i => i.id === selectedId);
          this.filteredfacilitytypelist[i] = filteredFacility[0];
        }
        this.model1.facilityTypes = this.filteredfacilitytypelist;
        // this.model.facilityTypes.forEach(x => {
        //   delete x.id;
        // });
    
        // Special Type
        for (let i = 0; i < this.selectedSpecialViewItems.length; i++) {
          const selectedId = this.selectedSpecialViewItems[i]['id'];
          const filteredFacility = this.specialViewList.filter(i => i.id === selectedId);
          this.filteredspecialtypelist[i] = filteredFacility[0];
        }
        this.model1.specialViewTypes = this.filteredspecialtypelist;
        // this.model.specialViewTypes.forEach(x => {
        //   delete x.id;
        // });
    
        // Surround Type
        for (let i = 0; i < this.selectedSurroundTypeItems.length; i++) {
          const selectedId = this.selectedSurroundTypeItems[i]['id'];
          const filteredFacility = this.surroundTypeList.filter(i => i.id === selectedId);
          this.filteredsurroundtypelist[i] = filteredFacility[0];
        }
        this.model1.surroundTypes = this.filteredsurroundtypelist;
  }

  // model Methods
  closeModel() {
    this.modalService.dismissAll();
  }

  //popup Methods
  openPopupMessage(content) {
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      backdrop: "static",
    });
  }
}
