import { Injectable } from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Payment} from '../../model/payment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NotifierService} from 'angular-notifier';
import { PaymentUpdate } from 'src/app/model/payment-update';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private paymentServiceUrl = '/payment';  // URL to web api

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  updatePaymentStatus(payment: PaymentUpdate): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.paymentServiceUrl}/updatestatus`,
    payment);
  }

  /** POST payment to the server */
  initiatePayment(payment: Payment): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.paymentServiceUrl}/initiate`,
      JSON.stringify(payment), this.httpOptions)
      .pipe(tap(_ => this.log(`create payment=${payment}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('payment create'))
      );
  }

  addPayment(payment: Payment): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.paymentServiceUrl}/add`,
      JSON.stringify(payment), this.httpOptions)
      .pipe(tap(_ => this.log(`add payment=${payment}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('payment add'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error.message); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
      // notify user
      this.notifier.notify('error', `${operation} failed`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  /** Log a payment message with the audit service */
  private log(message: string) {
    // Todo: create a service and send operational audit message
  }
}
