import {Component, Input, OnInit} from '@angular/core';
import {IProperty} from '../../../../../../../model/interfaces/property';
import {IBuilding} from '../../../../../../../model/interfaces/building';
import {Property} from '../../../../../../../model/property';
import {ValuationRequest} from '../../../../../../../model/valuation-request';
import {ValuationRequestService} from '../../../../../../../service/valuation-request/valuation-request.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SubSink} from 'subsink';
import {PropertyUsageService} from '../../../../../../../service/property-usage/property-usage.service';
import {PropertyUsageSubtypeService} from '../../../../../../../service/property-usage-subtype/property-usage-subtype.service';
import {DeedNatureService} from '../../../../../../../service/deed-nature/deed-nature.service';
import {LocalAuthorityService} from '../../../../../../../service/local-authority/local-authority.service';
import {ProvinceService} from '../../../../../../../service/province/province.service';
import {DistrictService} from '../../../../../../../service/district/district.service';
import {GramaNiladariDivisionService} from '../../../../../../../service/grama-niladari-division/grama-niladari-division.service';
import {Building} from '../../../../../../../model/building';
import {CompanyTypeService} from '../../../../../../../service/company-type/company-type.service';
import {CompanyCategoryService} from '../../../../../../../service/company-category/company-category.service';
import {BankService} from '../../../../../../../service/bank/bank.service';
import {CompanyCategory} from '../../../../../../../model/companyCategory';
import {CompanyType} from '../../../../../../../model/companyType';
import {PropertyUsageSubtype} from '../../../../../../../model/propertyUsageSubtype';

@Component({
  selector: 'app-view-property',
  templateUrl: './view-property.component.html',
  styleUrls: ['./view-property.component.scss']
})
export class ViewPropertyComponent implements OnInit {

  @Input()
  properties: IProperty [];
 @Input()
 valuationRequest: ValuationRequest;

  buildingList: IBuilding [];
  buildingFloors = [];
  selectedBuildingIndex: number;
  updatingBuilding: IBuilding;
  propertyOne: IProperty;
  assessment: IProperty;
  extend: IProperty;
  other: IProperty;
  loan: IProperty;
  buildingListUD: IProperty;
  propertyUsages = [];
  propertyUsageSubTypes = [];
  deedNatures = [];
  localAuthorities = [];
  provincesorderd: [];
  districts = [];
  gramaniladhariDivisions = [];
  companyCategories: CompanyCategory[];
  companyType: CompanyType[];
  allBanks: any;
  valuationRequestId: any;
  loanUndefined: [];


  propertyOneExchange: Property;
  assessmentExchange: Property;
  extendExchange: Property;
  otherExchange: Property;
  loanExchange: Property;
  buildingListUDExchange: Property;

  isExchangeValuation: boolean;
  isPreviousOpinion: string;
  isPropertyRented: string;
  isPropertyUsedLoan: boolean;
  isPropertyUsedLoanExchange: boolean;

  valuationRequestExchange: ValuationRequest;

  private sub = new SubSink();
  isExchange: boolean;

  constructor(private route: ActivatedRoute,
              private valuationRequestService: ValuationRequestService,
              private propertyUsageService: PropertyUsageService,
              private propertyUsageSubtypeService: PropertyUsageSubtypeService,
              private deedNatureService: DeedNatureService,
              private localAuthorityService: LocalAuthorityService,
              private companyTypeService: CompanyTypeService,
              private companyCategoryService: CompanyCategoryService,
              private bankService: BankService,
              private provinceService: ProvinceService,
              private districtService: DistrictService,
              private gramaNiladhariDivisionService: GramaNiladariDivisionService,
              private  router: Router) {
    // this.valuationRequest = new ValuationRequest();
  }

  ngOnInit() {
    // JSON.parse(sessionStorage.getItem('valuationRequest'));
    this.valuationRequestId = this.valuationRequest.id;

    this.buildingList = [];
    console.log('interface ' + JSON.stringify(this.properties));

    this.buildingListUD = this.properties[0];

    if (this.valuationRequest.deedNature === 'Exchange') {
      this.isExchange = true;
      this.getValuationRequest(this.valuationRequest.exchangeId);
    } else {
      this.isExchange = false;
    }

    this.sub.add(this.deedNatureService.getAll()
      .subscribe(
        (res) => {
          this.deedNatures = (res as any).data;
        }
      ));

    this.sub.add(this.provinceService.getProvincesOrderd().subscribe(
      (res) => {
        this.provincesorderd = (res as any).data;
      }
    ));

    // get All Company Categories
    this.sub.add(this.companyCategoryService.getAll().subscribe(
      res => {
        this.companyCategories = (res as any).data;
      }
    ));

    // get All company Type
    this.sub.add(this.companyTypeService.getAll().subscribe(
      res => {
        this.companyType = (res as any).data;
      }
    ));

    this.sub.add(this.localAuthorityService.getAllByOrderByLocalAuthorityAsc()
      .subscribe(
        (res) => {
          this.localAuthorities = (res as any).data;
        }
      ));

    this.sub.add(this.districtService.getAll()
      .subscribe(
        (res) => {
          this.districts = (res as any).data;
        }
      ));

    this.sub.add(this.gramaNiladhariDivisionService.getAll()
      .subscribe(
        (res) => {
          console.log(res);
          this.gramaniladhariDivisions = (res as any).data;
        }));

    this.sub.add(this.propertyUsageService.getAll()
      .subscribe(
        (res) => {
          this.propertyUsages = (res as any).data;
        }
      ));
    this.sub.add(this.propertyUsageSubtypeService.getAll()
      .subscribe(
        (res) => {
          this.propertyUsageSubTypes = (res as any).data;
        }
      ));

    this.sub.add(this.bankService.getAll()
      .subscribe(
        (res) => {
          this.allBanks = (res as any).data;
        }));

    // if (this.other.prevOpinion) {
    //   this.isPreviousOpinion = 'Yes';
    // } else {
    //   this.isPreviousOpinion = 'No';
    // }
    // if (this.other.propertyRented) {
    //   this.isPropertyRented = 'Yes';
    // } else {
    //   this.isPropertyRented = 'No';
    // }

    if (this.properties[0].loan !== null) {
      this.isPropertyUsedLoan = true;
      // console.log('Property not not >>>>>>>>>>>>>>>>' + this.properties[0].loan.bank);
    } else {
      this.isPropertyUsedLoan = false;
      // console.log('Property >>>>>>>>>>>>>>>>' + this.properties[0].loan.bank);
      // this.properties[0].loan.bank = null;
      // this.properties[0].loan.branch = null;
      // this.properties[0].loan.loanNumber = null;
      // this.properties[0].loan.amount = null;
      // console.log('Property After>>>>>>>>>>>>>>>>' + this.properties[0].loan.bank);
    }
  }

  loadBuilding(property: IProperty, index: number) {
    this.buildingList = property.buildings;

    this.selectedBuildingIndex = index;
    this.updatingBuilding = this.buildingList[ this.selectedBuildingIndex];
    this.buildingFloors = this.updatingBuilding.buildingFloors;
      // this.addBuildingForm.get('planno').markAsTouched();
      // this.addBuildingForm.get('plandate').markAsTouched();
      // this.addBuildingForm.get('yearofconstruct').markAsTouched();
  }

  loadBuildingExchange(property: Property, index: number) {
    this.buildingList = property.buildings;

    this.selectedBuildingIndex = index;
    this.updatingBuilding = this.buildingList[ this.selectedBuildingIndex];
    this.buildingFloors = this.updatingBuilding.buildingFloors;


  }

  getValuationRequest(valuationRequestId: number) {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(valuationRequestId)
      .subscribe((res) => {
        this.valuationRequestExchange = res.data;
        // this.propertyOneExchange = this.valuationRequestExchange.properties[0];
        // this.assessmentExchange = this.valuationRequestExchange.properties[0];
        // this.extendExchange = this.valuationRequestExchange.properties[0];
        // this.otherExchange = this.valuationRequestExchange.properties[0];
        // this.loanExchange = this.valuationRequestExchange.properties[0];
        this.buildingListUDExchange = this.valuationRequestExchange.properties[0];

        if (this.valuationRequestExchange.properties[0].loan !== null) {
          this.isPropertyUsedLoanExchange = true;
        } else {
          this.isPropertyUsedLoanExchange = false;
        }
      }));
  }

  // backToList() {
  //   this.sub.add(this.valuationRequestService.findByValuationRequestId(Number(this.valuationRequestId))
  //     .subscribe((res) => {
  //       this.valuationRequest = res.data;
  //
  //       console.log(this.valuationRequest.valuationRequestStatus + '................');
  //
  //       if (this.valuationRequest.valuationRequestStatus === 'PENDING') {
  //         this.router.navigate(['/view-assessment/pending-requests']);
  //       }
  //       if (this.valuationRequest.valuationRequestStatus === 'COMPLETED') {
  //         this.router.navigate(['/view-assessment/completed-requests']);
  //       }
  //       if (this.valuationRequest.valuationRequestStatus === 'REJECTED') {
  //         this.router.navigate(['/view-assessment/rejected-requests']);
  //       }
  //     }));
  // }
}
