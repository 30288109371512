export class NotificationModel2 {
    id: string;
    userRoleCode: string;
    deedNature: string;
    url: string;
    subject: string;
    userId: string;
    categoryStatus: string;
    toEmail:string;
    emailBody:string;

    constructor(
        id: string,
        userRoleCode: string,
        deedNature: string,
        url: string,
        subject: string,
        userId: string,
        categoryStatus: string,
        toEmail:string,
        emailBody:string,
    ){
        this.id = id;
        this.userRoleCode = userRoleCode;
        this.deedNature = deedNature;
        this.url = url;
        this.subject = subject;
        this.userId = userId;
        this.categoryStatus = categoryStatus;
        this.toEmail = toEmail;
        this.emailBody = emailBody;
    }
}
