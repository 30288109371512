import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminDashboardService {

  constructor(private http: HttpClient) {
  }

  fetchSectionCount(userId, role): Observable<any> {
    return this.http.get(environment.api_url + '/dashboard/newCount/' + userId + '/' + role);
  }

  //For applications

  fetchAllApplications(userId, role, startDate, endDate, branch, taxOfficer): Observable<any> {
    return this.http.get(environment.api_url + '/dashboard/getAll/' + userId + '/' + role + '?start=' + startDate + '&end=' + endDate + '&branch=' + branch + '&taxOfficer=' + taxOfficer);
  }

  fetchAppealApplications(userId, role, startDate, endDate, branch, taxOfficer): Observable<any> {
    return this.http.get(environment.api_url + '/dashboard/apeal/' + userId + '/' + role + '?start=' + startDate + '&end=' + endDate + '&branch=' + branch + '&taxOfficer=' + taxOfficer);
  }

  fetchOpinionApplications(userId, role, startDate, endDate, branch, taxOfficer): Observable<any> {
    return this.http.get(environment.api_url + '/dashboard/opinion/' + userId + '/' + role + '?start=' + startDate + '&end=' + endDate + '&branch=' + branch + '&taxOfficer=' + taxOfficer);
  }

  fetchRefundApplications(userId, role, startDate, endDate, branch, taxOfficer): Observable<any> {
    return this.http.get(environment.api_url + '/dashboard/refund/' + userId + '/' + role + '?start=' + startDate + '&end=' + endDate + '&branch=' + branch + '&taxOfficer=' + taxOfficer);
  }

  //For all
  fetchAllPieChartDetails(userId, role): Observable<any> {
    return this.http.get(environment.api_url + '/dashboard/getPieChart/' + userId + '/' + role);
  }

  fetchAllBankChartDetails(userId, role, startDate, endDate): Observable<any> {
    return this.http.get(environment.api_url + '/dashboard/collection/' + userId + '/' + role + '?start=' + startDate + '&' + 'end=' + endDate);
  }

  fetchAllBarChartDetails(userId, role, start, end): Observable<any> {
    return this.http.get(environment.api_url + '/dashboard/getBarChart/' + userId + '/' + role + '?start=' + start + '&' + 'end=' + end);
  }

  //For opinion
  fetchOpinionPieChartDetails(userId, role): Observable<any> {
    return this.http.get(environment.api_url + '/dashboard/opinion/getPieChart/' + userId + '/' + role);
  }

  fetchOpinionBankChartDetails(userId, role, startDate, endDate): Observable<any> {
    return this.http.get(environment.api_url + '/dashboard/opinion/collection/' + userId + '/' + role + '?start=' + startDate + '&' + 'end=' + endDate);
  }

  fetchOpinionBarChartDetails(userId, role, start, end): Observable<any> {
    return this.http.get(environment.api_url + '/dashboard/opinion/getBarChart/' + userId + '/' + role + '?start=' + start + '&' + 'end=' + end);
  }


  //For appeal
  fetchAppealPieChartDetails(userId, role): Observable<any> {
    return this.http.get(environment.api_url + '/dashboard/apeal/getPieChart/' + userId + '/' + role);
  }

  fetchAppealBankChartDetails(userId, role, startDate, endDate): Observable<any> {
    return this.http.get(environment.api_url + '/dashboard/apeal/collection/' + userId + '/' + role + '?start=' + startDate + '&' + 'end=' + endDate);
  }

  fetchAppealBarChartDetails(userId, role, start, end): Observable<any> {
    return this.http.get(environment.api_url + '/dashboard/apeal/getBarChart/' + userId + '/' + role + '?start=' + start + '&' + 'end=' + end);
  }

  //For refund
  fetchRefundPieChartDetails(userId, role): Observable<any> {
    return this.http.get(environment.api_url + '/dashboard/refund/getPieChart/' + userId + '/' + role);
  }

}
