import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { CalculationDataProcess } from "src/app/service/calculationDataProcess/calculation-data-process.service";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { CALCULATION_HEADINGS } from "../../constant-headings/heading";
import * as moment from "moment";
import Swal from "sweetalert2";
import { ActivatedRoute } from "@angular/router";
import { CalculationService } from "src/app/service/calculation/calculation.service";
import { Constant } from "src/app/util/constant";
declare var $: any;

@Component({
  selector: "exchange-land",
  templateUrl: "./exchange-land.html",
  styleUrls: ["./exchange-land.scss"]
})
export class ExchangeLandComponent implements OnInit {
  @Output() emitData: EventEmitter<any> = new EventEmitter<any>();
  @Output() enableTab: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  state;
  landForm: FormGroup;

  @Input()
  updateData;

  @Input()
  selectedDataRow;

  maxDate: any;

  valueOfTheLand: FormArray;
  improvements: FormArray;

  improvementTotal: number = 0;
  valueOfTheLandTotal: number = 0;

  previousValues:any[] = [];
  totalRecords:number;
  page=1;

  _CALCULATION_HEADINGS = CALCULATION_HEADINGS;

  constructor(
    public formBuilder: FormBuilder,
    private dataProcess: CalculationDataProcess,
    private activatedRoute: ActivatedRoute,
    public apiService: CalculationService,
  ) {}

  ngOnInit() {
    this.landForm = this.formBuilder.group({
      valueOfTheLand: this.formBuilder.array([this.createValueOfTheLandItem()]),
      improvements: this.formBuilder.array([this.createImprovemntItem()]),
      valueOfTheLandRemark: null,
      total: [null, Validators.required],
      valueOfTheProperty: [null, Validators.required],
      previousDeedValue: null,
      headingDate: null
    });

    if (this.updateData != null) {
      this.setData(this.updateData);
    }
    this.getPreviousMarketValue();
    this.maxDate = new Date();
  }

  getPreviousMarketValue(){
    let refNo;
    this.activatedRoute.params.subscribe(params => {
      refNo = params["id"];
    });
    this.apiService.getPreviousMarket(refNo,Constant.CALCULATION_LAND).subscribe(data=>{
      if(data.data !== null){
        this.previousValues.push(data.data);
      }      
      this.totalRecords=this.previousValues.length;
    })
  }

  viewOpinionDetails(valuationId:any):void{
    window.open('view-internal-user-application/' + valuationId)
  }

  setData(data) {
    for (let i = 0; i < data.valueOfTheLand.length; i++) {
      if (i > 0) {
        this.valueOfTheLand = this.landForm.get("valueOfTheLand") as FormArray;
        this.valueOfTheLand.push(this.createValueOfTheLandItem());
      }
      let formLand = (<FormArray>this.landForm.get("valueOfTheLand")).at(i);

      formLand.patchValue({
        value: data.valueOfTheLand[i].value,
        landExtend: data.valueOfTheLand[i].landExtend,
        perPerchValue: data.valueOfTheLand[i].perPerchValue
      });
    }

    for (let i = 0; i < data.improvements.length; i++) {
      if (i > 0) {
        this.improvements = this.landForm.get("improvements") as FormArray;
        this.improvements.push(this.createImprovemntItem());
      }
      const formImprovements = (<FormArray>(
        this.landForm.get("improvements")
      )).at(i);
      formImprovements.patchValue({
        description: data.improvements[i].description,
        extent: data.improvements[i].extent,
        rentPerSqFt: data.improvements[i].rentPerSqFt,
        totValue: data.improvements[i].extent * data.improvements[i].rentPerSqFt
      });
    }
    if(data.previousDeedValue !== null && data.previousDeedValue !== undefined && data.previousDeedValue !== ""){
      this.landForm.get("previousDeedValue").setValue((data.previousDeedValue).toFixed(2));
    }
    this.landForm.get("headingDate").setValue(moment(data.headingDate).format("YYYY-MM-DD"));
    this.calculateData();

    this.landForm.get('valueOfTheLandRemark').setValue(data.landRemark)
    // for (let imp of this.landForm.get("improvements").value) {
    //   this.improvementTotal =
    //     this.improvementTotal + imp.extent * imp.rentPerSqFt;
    // }
  }

  get _landForm() {
    return this.landForm.controls;
  }

  isValidField(formControl) {
    return (
      (this._landForm[formControl].touched ||
        this._landForm[formControl].dirty) &&
      this._landForm[formControl].errors
    );
  }

  createValueOfTheLandItem(): FormGroup {
    return this.formBuilder.group({
      id: null,
      landExtend: null,
      perPerchValue: null,
      value: null
    });
  }

  createImprovemntItem(): FormGroup {
    return this.formBuilder.group({
      id: null,
      description: null,
      extent: null,
      rentPerSqFt: null,
      totValue: null
    });
  }

  addItem(type): void {
    if (type == "vl") {
      this.valueOfTheLand = this.landForm.get("valueOfTheLand") as FormArray;
      this.valueOfTheLand.push(this.createValueOfTheLandItem());
    }
    if (type == "im") {
      this.improvements = this.landForm.get("improvements") as FormArray;
      this.improvements.push(this.createImprovemntItem());
    }
  }

  getValueOfTheLand(): FormArray {
    return this.landForm.get("valueOfTheLand") as FormArray;
  }

  getImprovement(): FormArray {
    return this.landForm.get("improvements") as FormArray;
  }

  removeRow(ti, type) {
    if (type == "vl") {
      this.getValueOfTheLand().removeAt(ti);
      this.calculateValuationOfTheland(ti,undefined);
    }
    if (type == "im") {
      this.getImprovement().removeAt(ti);
    }
  }

  calculateData() {
    this.valueOfTheLandTotal = 0;
    this.improvementTotal = 0;
    for (let imp of this.landForm.get("valueOfTheLand").value) {
      this.valueOfTheLandTotal =
        this.valueOfTheLandTotal + imp.landExtend * imp.perPerchValue;
    }
    this.landForm.get("total").setValue(this.improvementTotal.toFixed(2));

    for (let imp of this.landForm.get("improvements").value) {
      // this.improvementTotal =
      //   this.improvementTotal + imp.extent * imp.rentPerSqFt;

      this.improvementTotal = this.improvementTotal + imp.totValue;
    }
    this.landForm.get("total").setValue(this.improvementTotal.toFixed(2));

    let valueOfTheProperty = this.improvementTotal + this.valueOfTheLandTotal;
    this.landForm
      .get("valueOfTheProperty")
      .setValue(valueOfTheProperty.toFixed(2));
  }

  calculateImprovementTableValue(index, type) {
    const formCon = (<FormArray>this.landForm.get("improvements")).at(index);
    // let currentVal = !formCon.value.totValue;
    // formCon.patchValue({
    //   totValue: (formCon.value.extent * formCon.value.rentPerSqFt).toFixed(2)
    // });

    if (type != "totValue") {
      let currentVal = !formCon.value.totValue;
      formCon.patchValue({
        totValue: formCon.value.extent * formCon.value.rentPerSqFt
      });
    } else {
      formCon.patchValue({
        totValue: formCon.value.totValue
      });
    }
    this.calculateData();
  }

  calculateValuationOfTheland(index, type) {
    const formLand = (<FormArray>this.landForm.get("valueOfTheLand")).at(index);
    this.calculateData();
    formLand.patchValue({
      value: (formLand.value.landExtend * formLand.value.perPerchValue).toFixed(
        2
      )
    });
  }

  emitValueOfTheProperty(state) {
    let data;
    if (this.landForm.get("headingDate").value == null || this.landForm.get("headingDate").value == "" || this.landForm.get("headingDate").value == undefined) {
      Swal.fire("Error", "Please select  heading date ", "error");
      data = {
        dateSelected: false,
        state: this.state
      };
    } else {
      if (state == this.state) {
        let data = {
          proceedData: this.processDataToSave(),
          valueOfTheProperty: this.landForm.get("valueOfTheProperty").value
        };
        this.emitData.next(data);
      }
    }
    this.enableTab.emit(data);
  }

  processDataToSave() {
    return this.dataProcess.processExchangeLandData(this.landForm);
  }
}
