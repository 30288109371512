import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  // company category domain
  private searchSourceCompanyCategory = new BehaviorSubject<string>('');
  keyWordCompanyCategory = this.searchSourceCompanyCategory.asObservable();
  private updateSourceCompanyCategory = new BehaviorSubject<boolean>(false);
  updateDataTableCompanyCategory = this.updateSourceCompanyCategory.asObservable();

  // property usage domain
  private searchSourcePropertyUsage = new BehaviorSubject<string>('');
  keyWordPropertyUsage = this.searchSourcePropertyUsage.asObservable();
  private updateSourcePropertyUsage = new BehaviorSubject<boolean>(false);
  updateDataTablePropertyUsage = this.updateSourcePropertyUsage.asObservable();

  // property usage subtype domain
  private searchSourcePropertyUsageSubType = new BehaviorSubject<string>('');
  keyWordPropertyUsageSubType = this.searchSourcePropertyUsageSubType.asObservable();
  private updateSourcePropertyUsageSubType = new BehaviorSubject<boolean>(false);
  updateDataTablePropertyUsageSubType = this.updateSourcePropertyUsageSubType.asObservable();

  // Company Type domain
  private searchSourceCompanyType = new BehaviorSubject<string>('');
  keywordCompanyType = this.searchSourceCompanyType.asObservable();
  private updateSourceCompanyType = new BehaviorSubject<boolean>(false);
  updateDataTableCompanyType = this.updateSourceCompanyType.asObservable();

  // deed nature domain
  private searchSourceDeedNature = new BehaviorSubject<string>('');
  keywordDeedNature = this.searchSourceDeedNature.asObservable();
  private updateSourceDeedNature = new BehaviorSubject<boolean>(false);
  updateDataTableDeedNature = this.updateSourceDeedNature.asObservable();

  // province domain
  private searchSourceProvince = new BehaviorSubject<string>('');
  keywordProvince = this.searchSourceProvince.asObservable();
  private updateSourceProvince = new BehaviorSubject<boolean>(false);
  UpdateDataTableProvince = this.updateSourceProvince.asObservable();

  // district domain
  private searchSourceDistrict = new BehaviorSubject<string>('');
  keywordDistrict = this.searchSourceDistrict.asObservable();
  private updatesourceDistrict = new BehaviorSubject<boolean>(false);
  updateDtaTableDistrict = this.updatesourceDistrict.asObservable();

  // grama niladari division domain
  private searchSOurceGramaNiladariDivision = new BehaviorSubject<string>('');
  keywordGramaNiladariDivision = this.searchSOurceGramaNiladariDivision.asObservable();
  private updateSourceGramaNiladariDisivion = new BehaviorSubject<boolean>(false);
  updateDataTableGramaNildariDivision = this.updateSourceGramaNiladariDisivion.asObservable();

  //  payment methoda domain
  private searchSourcePaymentMethod = new BehaviorSubject<string>('');
  keywordPaymentMethod = this.searchSourcePaymentMethod.asObservable();
  private updateSourcePayementMethod = new BehaviorSubject<boolean>(false);
  updateDataTablePaymentMethod = this.updateSourcePayementMethod.asObservable();

  // office domain
  private searchSorceOffice = new BehaviorSubject<string>('');
  keywordOffice = this.searchSorceOffice.asObservable();
  private updateSourceOffice = new BehaviorSubject<boolean>(false);
  updateDataTableOffice = this.updateSourceOffice.asObservable();

    // Bank domain
    private searchSorceBank = new BehaviorSubject<string>('');
    keywordBank = this.searchSorceBank.asObservable();
    private updateSourceBank = new BehaviorSubject<boolean>(false);
    updateDataTableBank = this.updateSourceOffice.asObservable();

  // document type domain
  private searchSourceDocumentType = new BehaviorSubject<string>('');
  keywordDocuemntType = this.searchSourceDocumentType.asObservable();
  private updateSourceDocumnetType = new BehaviorSubject<boolean>(false);
  updateDataTableDocumentType = this.updateSourceDocumnetType.asObservable();

  // property type doamain
  private searchSourcePropertyType = new BehaviorSubject<string>('');
  keywordPropertyType = this.searchSourcePropertyType.asObservable();
  private updateSourcePropertyType = new BehaviorSubject<boolean>(false);
  updateDataTablePropertyType = this.updateSourcePropertyType.asObservable();

  // local autharity domain
  private searchSourceLocalAuthority = new BehaviorSubject<string>('');
  keywordLocalAuthority = this.searchSourceLocalAuthority.asObservable();
  private updateSourceLocalAuthority = new BehaviorSubject<boolean>(false);
  updateDataTableLocalAuthority = this.updateSourceLocalAuthority.asObservable();

  // floor Type domain
  private searchSourceFloorType = new BehaviorSubject<string>('');
  keywordFloorType = this.searchSourceFloorType.asObservable();
  private updateSourceFloorType = new BehaviorSubject<boolean>(false);
  updateDataTableFloorType = this.updateSourceFloorType.asObservable();

  // floor sub type
  private searchSourceFloorSubType = new BehaviorSubject<string>('');
  keywordFloorSubType = this.searchSourceFloorSubType.asObservable();
  private updateSourceFloorSubType = new BehaviorSubject<boolean>(false);
  updateDataTableFloorSubType = this.updateSourceFloorSubType.asObservable();

  // property facility
  private searchSourcePropertyFacility = new BehaviorSubject<string>('');
  keywordPropertyFacility = this.searchSourcePropertyFacility.asObservable();
  private updateSourcePropertyFacility = new BehaviorSubject<boolean>(false);
  UpdateDataTablePropertyFacility = this.updateSourcePropertyFacility.asObservable();

  // password policy
  private searchSourcePasswordPolicy = new BehaviorSubject<string>('');
  keywordPasswordPolicy = this.searchSourcePasswordPolicy.asObservable();
  private updateSourcePasswordPolicy = new BehaviorSubject<boolean>(false);
  updateDataTablePasswordPolicy = this.updateSourcePasswordPolicy.asObservable();


  // Employee
  private searchSourceEmployee = new BehaviorSubject<string>('');
  keywordEmployee = this.searchSourceEmployee.asObservable();
  private updateSourceEmployee = new BehaviorSubject<boolean>(false);
  updateDataTableEmployee = this.updateSourceEmployee.asObservable();

  constructor() { }
  // update company category table
  updateTableCompanyCategory(updateTable: boolean) {
    this.updateSourceCompanyCategory.next(updateTable);
  }
  // search company category keyword
  searchCompanyCategory(keyWord: string) {
    this.searchSourceCompanyCategory.next(keyWord);
  }
  // update PropertyUsage table
  updateTablePropertyUsage(updateTable: boolean) {
    this.updateSourcePropertyUsage.next(updateTable);
  }
  // search property usage keyword
  searchPropertyUsage(keyWord: string) {
    this.searchSourcePropertyUsage.next(keyWord);
  }
  updateTablePropertyUsageSubType(updateTable: boolean) {
    this.updateSourcePropertyUsageSubType.next(updateTable);
  }
  // update company type table
  updateTableCompanyType(updateTable: boolean) {
    this.updateSourceCompanyType.next(updateTable);
  }
  // search company type keyword
  searchCompanyType(keyWord: string) {
    this.searchSourceCompanyType.next(keyWord);
  }
  // update deed nature table
  updateTableDeedNature(updateTable: boolean) {
    this.updateSourceDeedNature.next(updateTable);
  }
  // search deed nature
  searchDeedNature(keyWord: string) {
    this.searchSourceDeedNature.next(keyWord);
  }
  // update province table
  updateTableProvince(updateTable: boolean) {
    this.updateSourceProvince.next(updateTable);
  }
  // search Province
  searchProvince(keyWord: string) {
    this.searchSourceProvince.next(keyWord);
  }
  // update district table
  updateTableDistrict(updateTable: boolean) {
    this.updatesourceDistrict.next(updateTable);
  }
  // search District
  searchDistrict(keyWord: string) {
    this.searchSourceDistrict.next(keyWord);
  }
  // update Grama Niladari Division Table
  updateTableGramaNiladariDivision(updateTable: boolean) {
    this.updateSourceGramaNiladariDisivion.next(updateTable);
  }
  // search grama Niladari Division
  searchGramaNiladariDivision(keyWord: string) {
    this.searchSOurceGramaNiladariDivision.next(keyWord);
  }
  // update payement method table
  updateTablePaymentMethod(updateTable: boolean) {
    this.updateSourcePayementMethod.next(updateTable);
  }
  // search payment method
  searchPaymentMethod(keyWord: string) {
    this.searchSourcePaymentMethod.next(keyWord);
  }
  // update office
  updateTableOffice(updateTable: boolean) {
    this.updateSourceOffice.next(updateTable);
  }
  // search Office
  searchOffice(keyWord: string) {
    this.searchSorceOffice.next(keyWord);
  }
    // update Bank
    updateTableBank(updateTable: boolean) {
      this.updateSourceBank.next(updateTable);
    }
    // search Bank
    searchBank(keyWord: string) {
      this.searchSorceBank.next(keyWord);
    }
  // update document type
  updateTableDocumentType(updateTable: boolean) {
    this.updateSourceDocumnetType.next(updateTable);
  }
  // search Document type
  searchDocumentType(keyWord: string) {
    this.searchSourceDocumentType.next(keyWord);
  }
  // update property type
  updatePropertyType(updateTable: boolean) {
    this.updateSourcePropertyType.next(updateTable);
  }
  // search property type
  searchPropertyType(keyword: string) {
    this.searchSourcePropertyType.next(keyword);
  }
  // update local auhtority
  updateLocalAuthority(updateTable: boolean) {
    this.updateSourceLocalAuthority.next(updateTable);
  }
  // search local authority
  searchLocalauthority(keyWord: string) {
    this.searchSourceLocalAuthority.next(keyWord);
  }
  // update floor type
  updateFloorType(updateTable: boolean) {
    this.updateSourceFloorType.next(updateTable);
  }
  // search floor type
  searchFloorType(keyWord: string) {
    this.searchSourceFloorType.next(keyWord);
  }
  // update floor sub type
  updateFloorSubType(updateTable: boolean) {
    this.updateSourceFloorSubType.next(updateTable);
  }
  // search floor sub type
  searchFloorSubType(keyWord: string) {
    this.searchSourceFloorSubType.next(keyWord);
  }
  // update property facility
  updatePropertyFacility(updateTable: boolean) {
    this.updateSourcePropertyFacility.next(updateTable);
  }
  // search Property facility
  searchPropertyFacility(keyWord: string) {
    this.searchSourcePropertyFacility.next(keyWord);
  }

  // update password policyy
  updatePasswordPolicy(updateTable:boolean){
    this.updateSourcePasswordPolicy.next(updateTable);
  }
  //search password policy
  searchPasswordPolicy(keyWord:string){
    this.searchSourcePasswordPolicy.next(keyWord);
  }

  // update password policyy
  updateEmployee(updateTable:boolean){
    this.updateSourceEmployee.next(updateTable);
  }
  //search password policy
  searchEmployee(keyWord:string){
    this.searchSourceEmployee.next(keyWord);
  }
}
