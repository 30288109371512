import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/service/data.service';
import { EmployeeService } from 'src/app/service/employee/employee.service';
import { SubSink } from 'subsink';
import { UpdateEmployeeComponent } from '../update-employee/update-employee.component';

@Component({
  selector: 'app-view-employee',
  templateUrl: './view-employee.component.html',
  styleUrls: ['./view-employee.component.scss']
})
export class ViewEmployeeComponent implements OnInit,OnDestroy {

  @ViewChild(UpdateEmployeeComponent,{static : false})
  private updateEmployeeComponent: UpdateEmployeeComponent;

  private sub = new SubSink();
  selectedPageNo: number;
  limit: number;
  currentPage: any;
  totalItems: any;
  employee: any;

  constructor(
    private employeeService : EmployeeService,
    private dataService: DataService
    ) { }


  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.selectedPageNo = 0;
    this.limit = 10;

    this.sub.add(this.dataService.updateDataTableEmployee.subscribe(update => this.onUpdated(update)));

    this.onUpdated(true);
  }

  pageable(i: number) {
    this.employeeService.getEmployeis((i-1), 10).subscribe(responce => {
      this.employee = (responce as any).data.content;
      this.currentPage = (responce as any).data.number+1;
      this.totalItems = (responce as any).data.totalElements;
    });
  }

  counter(i: number) {
    return new Array(i);
  }

    //send data to model component
  triggerUpdate(employee:any){
    this.updateEmployeeComponent.setEmployee(employee);
  }

  onUpdated(updatedSuccessfully: boolean) {
    if (updatedSuccessfully) {
      this.updateTheTable();
    }
  }

  //Search Employee
  searchEmployee(keyWord:string){
    this.sub.add(this.employeeService.searchEmployee(keyWord,0,10).subscribe(res=>{
      this.employee = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    }))
  }

  // update the table
  private updateTheTable() {
   this.sub.add(this.employeeService.getEmployeis(0, 10)
     .subscribe(
       (res) => {
         this.employee = (res as any).data.content;
         this.currentPage = (res as any).data.number+1;
         this.totalItems = (res as any).data.totalElements;
       }));
 }
}
