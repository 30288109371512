import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from "@angular/core";
import * as moment from "moment";

import { CalculationDataProcess } from "src/app/service/calculationDataProcess/calculation-data-process.service";
import { ValueOfTheLand } from "../../models/landValues";
import { DataModel } from "../../models/data-model";
import { CALCULATION_HEADINGS } from "../../constant-headings/heading";

@Component({
  selector: "app-recreation-land",
  templateUrl: "./recreation-land.component.html",
  styleUrls: ["./recreation-land.component.scss"]
})
export class RecreationLandComponent implements OnInit,OnChanges {
  panelNumber = 1;

  @Input()
  mainFormDataModel;

  buildingValueTotal: any = 0;

  hideBackButton = false;

  landValueTotal: any = 0;
  landValueTotal_: any = 0;

  headingDateOne = null;
  headingDateTwo = null;

  @Input()
  showSubPanel;

  @Input()
  selectedRecord;

  @Input()
  party;

  @Input()
  headingOne

  @Input()
  headingTwo

  @Output() sandDataModel: EventEmitter<any> = new EventEmitter<any>();

  dataModel = new DataModel();

  maxDate: any;

  _CALCULATION_HEADINGS = CALCULATION_HEADINGS;

  constructor(private dataProcess: CalculationDataProcess) {}
  // ngOnChanges(changes: SimpleChanges): void {
  //   throw new Error("Method not implemented.");
  // }

  ngOnInit() {
    if (this.selectedRecord != null) {
      this.mainFormDataModel.selectedNatureOfDeed = this.selectedRecord.natureOfDeed;
      this.mainFormDataModel.selectedPropertyType = this.selectedRecord.propertyType;
      this.dataModel = this.dataProcess.landProcessToView(this.selectedRecord);

      this.dataModel.headingDateOne = moment(this.selectedRecord.headingDateOne).format("YYYY-MM-DD");
      this.dataModel.headingDateTwo = moment(this.selectedRecord.headingDateTwo).format("YYYY-MM-DD");

      this.mainFormDataModel.selectedDate = this.selectedRecord.date;

      if (this.mainFormDataModel.selectedNatureOfDeed == "gift") {
        this.calculateValuationOfTheland(undefined, 1);
        this.calculateImprovementsTableValueFiled(undefined, 1);
      }
      this.calculateValuationOfTheland(undefined, 2);
      this.calculateImprovementsTableValueFiled(undefined, 2);
    }

    if (
      (this.mainFormDataModel.selectedNatureOfDeed == "exchange" ||
        this.mainFormDataModel.selectedNatureOfDeed == "transfer") &&
      this.showSubPanel.showLand
    ) {
      this.hideBackButton = true;
      this.panelNumber = 2;
    } else {
      this.hideBackButton = false;
      this.panelNumber = 1;
    }
    this.maxDate = new Date();
    window.scroll(0,0);
  }

  changePanelNumber(position) {
    if (position == "back") {
      if (this.panelNumber == 2) {
        this.panelNumber = this.panelNumber - 1;
      }
    }
    if (position == "next") {
      if (this.panelNumber == 1) {
        this.panelNumber = this.panelNumber + 1;
      }
    }
    window.scroll(0,0);
  }

  ngOnChanges(changes:SimpleChanges){
    // this.dataModel.headingDateOne = changes['headingOne'] ? changes['headingOne'].currentValue  :'';
    // this.dataModel.headingDateTwo = changes['headingTwo'] ? changes['headingTwo'].currentValue :'';

    if (this.selectedRecord == null) {
      if(changes['headingOne']){
        if(changes['headingOne'].currentValue){
          this.dataModel.headingDateOne = changes['headingOne'].currentValue;
        }else{
          this.dataModel.headingDateOne = localStorage.getItem('headingOne');  
        }
      }else{
        this.dataModel.headingDateOne = localStorage.getItem('headingOne') ? localStorage.getItem('headingOne') :'';
      }
  
      if(changes['headingTwo']){
        if(changes['headingTwo'].currentValue){
          this.dataModel.headingDateTwo = changes['headingTwo'].currentValue;
        }else{
          this.dataModel.headingDateTwo = localStorage.getItem('headingTwo');
        }
      }else{
        this.dataModel.headingDateTwo = localStorage.getItem('headingTwo') ? localStorage.getItem('headingTwo') :'';
      }
    }
  }

  headingDateOneChange(){
    localStorage.setItem('headingOne', this.dataModel.headingDateOne);
  }

  headingDateTwoChange(){
    localStorage.setItem('headingTwo', this.dataModel.headingDateTwo);
  }

  addNewRow(position, type) {
    if (position == 1) {
      if (type == "vl") {
        this.dataModel.fristSet.valueOfTheLand.push(new ValueOfTheLand());
      }
      if (type == "improvements") {
        this.dataModel.fristSet.improvements.push({
          id: null,
          description: null,
          extent: null,
          rentPerSqFt: null,
          value: null
        });
      }
    }
    if (position == 2) {
      if (type == "vl") {
        this.dataModel.secondSet.valueOfTheLand.push(new ValueOfTheLand());
      }
      if (type == "improvements") {
        this.dataModel.secondSet.improvements.push({
          id: null,
          description: null,
          extent: null,
          rentPerSqFt: null,
          value: null
        });
      }
    }
  }

  removeRow(index, position, type) {
    if (position == 1) {
      if (type == "vl") {
        this.dataModel.fristSet.valueOfTheLand.splice(index, 1);
        this.calculateValuationOfTheland(undefined, position);
      }

      if (type == "improvements") {
        this.dataModel.fristSet.improvements.splice(index, 1);
        this.calculateImprovementsTableValueFiled(undefined, position);
      }
    }

    if (position == 2) {
      if (type == "vl") {
        this.dataModel.secondSet.valueOfTheLand.splice(index, 1);
        this.calculateValuationOfTheland(undefined, position);
      }

      if (type == "improvements") {
        this.dataModel.secondSet.improvements.splice(index, 1);
        this.calculateImprovementsTableValueFiled(index, position);
      }
    }
  }

  calculateValuationOfTheland(index, position) {
    if (position == 1) {
      this.landValueTotal = 0;
      if (index != undefined) {
        this.dataModel.fristSet.valueOfTheLand[index].value = (
          Number(this.dataModel.fristSet.valueOfTheLand[index].landExtend) *
          Number(this.dataModel.fristSet.valueOfTheLand[index].perPerchValue)
        ).toFixed(2);
      }

      this.dataModel.fristSet.valueOfTheLand.forEach(element => {
        this.landValueTotal = (
          Number(this.landValueTotal) + Number(element.value)
        ).toFixed(2);
      });
    }

    if (position == 2) {
      this.landValueTotal_ = 0;
      if (index != undefined) {
        this.dataModel.secondSet.valueOfTheLand[index].value = (
          Number(this.dataModel.secondSet.valueOfTheLand[index].landExtend) *
          Number(this.dataModel.secondSet.valueOfTheLand[index].perPerchValue)
        ).toFixed(2);
      }

      this.dataModel.secondSet.valueOfTheLand.forEach(element => {
        this.landValueTotal_ = (
          Number(this.landValueTotal_) + Number(element.value)
        ).toFixed(2);
      });
    }

    this.calculateImprovementsTableValueFiled(undefined, position);
  }

  calculateImprovementsTableValueFiled(index, position) {
    let totalVal: any = 0;
    if (position == 1) {
      if (index != undefined) {
        this.dataModel.fristSet.improvements[index].value = (
          Number(this.dataModel.fristSet.improvements[index].extent) *
          Number(this.dataModel.fristSet.improvements[index].rentPerSqFt)
        ).toFixed(2);
      }

      this.dataModel.fristSet.improvements.forEach(element => {
        totalVal = (Number(totalVal) + Number(element.value)).toFixed(2);
      });
      this.dataModel.fristSet.total = totalVal;
      this.dataModel.fristSet.valueOfTheProperty = (
        Number(this.dataModel.fristSet.total) + Number(this.landValueTotal)
      ).toFixed(2);
      this.dataModel.fristSet.marketValue = this.dataModel.fristSet.valueOfTheProperty;

      if (
        this.dataModel.secondSet.lowestValue != "" ||
        this.dataModel.secondSet.lowestValue != null
      ) {
        if (
          this.dataModel.secondSet.valueOfTheProperty >
            this.dataModel.fristSet.valueOfTheProperty &&
          this.mainFormDataModel.selectedNatureOfDeed == "gift"
        ) {
          this.dataModel.secondSet.lowestValue = this.dataModel.fristSet.valueOfTheProperty;
        } else {
          this.dataModel.secondSet.lowestValue = this.dataModel.secondSet.valueOfTheProperty;
        }
      }
    }

    if (position == 2) {
      if (index != undefined) {
        this.dataModel.secondSet.improvements[index].value = (
          Number(this.dataModel.secondSet.improvements[index].extent) *
          Number(this.dataModel.secondSet.improvements[index].rentPerSqFt)
        ).toFixed(2);
      }

      this.dataModel.secondSet.improvements.forEach(element => {
        totalVal = (Number(totalVal) + Number(element.value)).toFixed(2);
      });
      this.dataModel.secondSet.total = totalVal;
      this.dataModel.secondSet.valueOfTheProperty = (Number(this.landValueTotal_)).toFixed(2);
      this.dataModel.secondSet.marketValue = this.dataModel.secondSet.valueOfTheProperty;
      this.dataModel.secondSet.lowestValue = this.dataModel.secondSet.valueOfTheProperty;
      if (
        this.dataModel.secondSet.valueOfTheProperty >
          this.dataModel.fristSet.valueOfTheProperty &&
        this.mainFormDataModel.selectedNatureOfDeed == "gift"
      ) {
        this.dataModel.secondSet.lowestValue = this.dataModel.fristSet.valueOfTheProperty;
      }

      this.dataModel.secondSet.roundOffValue =
        this.dataModel.secondSet.roundOffValue == "" ||
        this.dataModel.secondSet.roundOffValue == null ||
        this.dataModel.secondSet.roundOffValue < 1
          ? this.dataModel.secondSet.lowestValue
          : this.dataModel.secondSet.roundOffValue;
      this.dataModel.secondSet.valueOfTheProperty = (Number(this.landValueTotal_)).toFixed(2);

      let totalStampDuty = 0;
      let marketValue = 100000;
      let balanceStampDutyPrecentage = 4;
      if (this.mainFormDataModel.selectedNatureOfDeed == "gift") {
        marketValue = 50000;
        balanceStampDutyPrecentage = 2;
      }
      if (this.dataModel.secondSet.roundOffValue > marketValue) {
        let fMarketVal = (marketValue * 3) / 100;

        let sMarketVal =
          ((this.dataModel.secondSet.roundOffValue - marketValue) *
            balanceStampDutyPrecentage) /
          100;
        totalStampDuty = fMarketVal + sMarketVal;
        this.dataModel.secondSet.stampDuty = Number(totalStampDuty).toFixed(2);
      } else {
        let fMarketVal = (this.dataModel.secondSet.roundOffValue * 3) / 100;
        this.dataModel.secondSet.stampDuty = Number(fMarketVal).toFixed(2);
      }
    }
  }

  saveDataModel() {
    let data = {
      data: this.dataModel,
      type: "land",
      party: this.party
    };
    this.sandDataModel.emit(data);
  }
}
