import {Component, OnInit, ViewChild} from '@angular/core';
import {ViewUserComponent} from './view-user/view-user.component';

@Component({
  selector: 'app-document-type',
  templateUrl: './user-type.component.html',
  styleUrls: ['./user-type.component.scss']
})
export class UserTypeComponent implements OnInit {
  @ViewChild(ViewUserComponent, {static: false})
  private viewUserTypeComponent: ViewUserComponent;
  keyword: string;

  constructor() { }

  ngOnInit() {
  }

  triggerSearch(event: any) {
    if (this.keyword === '') {
      this.viewUserTypeComponent.onUpdated(true);
    } else {
      this.viewUserTypeComponent.searchUser(this.keyword);
    }
  }

}
