import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import * as jsPDF from 'jspdf';

import { rimUpdateDCommissionerIdAndStatus } from 'src/app/model/rimUpdateDCommissionerIdAndStatus';
import { DistrictService } from 'src/app/service/district/district.service';
import { ProvinceService } from 'src/app/service/province/province.service';
import { RimApplicationService } from 'src/app/service/reimbursement/rim-application.service';
import { UserService } from 'src/app/service/user/user.service';
import { Constant } from 'src/app/util/constant';
import { ReimbursementPayableAndNonPayableDeeds } from 'src/app/model/rim-payable-nonpayable';
import { ReimbursementApplicationUpdate } from 'src/app/model/rim-application-update';
import { RimBankStatement } from 'src/app/model/rim-bank-statement';
import { DatePipe } from '@angular/common';
import { RimNoticeLetter } from 'src/app/model/rim-notice-letter';
import { DocumentUploadOneComponent } from 'src/app/component/document/document-upload/document-upload-one/document-upload-one.component';


@Component({
  selector: 'app-rim-payble-nonpayble',
  templateUrl: './rim-payble-nonpayble.component.html',
  styleUrls: ['./rim-payble-nonpayble.component.scss']
})
export class RimPaybleNonpaybleComponent implements OnInit {

  @Output() onPageTypeChanged: EventEmitter<any> = new EventEmitter<any>();
  reimbursementFileNumber: any;
  @ViewChild('documentUploadLocalAuthorityLetter', {static: false}) documentUploadLocalAuthorityLetter: DocumentUploadOneComponent;
  public updateDCommissionerStatus = new rimUpdateDCommissionerIdAndStatus(null, '');
  public payableAndNonPayableDeedsModel = new ReimbursementPayableAndNonPayableDeeds();
  public reimbursementApplicationUpdateModel = new ReimbursementApplicationUpdate();

  nonPayableDeedList: any = [];
  payableDeedList: any = [];
  payableNonePayDocs: any = [];
  commonArray = new Array<number>();

  commentBoxTrue=false;
  selectPaths = [];
  isTrue=false;
  showTable = false;
  payableTotal: any;
  nonpayableTotal: any;
  pyableStampDuty: any;
  nonPayableStampDuty: any;
  remarkValue = [];
  payableRemark =[];
  commentValue:any;

  nonPayablePage = 1;
  payablePage = 1;
  bankStatementPage = 1;

  file: File;
  arrayBuffer: any;
  filelist: any;

  assignedprovinceCode: any;
  assigneddistrictCode: any;
  dcommissionerId: any;
  rimId: number;
  provincelist: any;
  districtlist: any;
  filteredcommissionerlist: any;
  dcommissionerlist: any;
  userlist: any[] = [];
  fromDate: any;
  toDate: any;

  userData: any = [];
  userRoleCode: string;
  showToCommissioner: boolean;
  showToDeputyCommissioner: boolean;
  showToTaxOfficer: boolean;
  showToAccountant: boolean;
  showNonPayableAndPayableDeedTables: boolean;
  totalRecords1: number;

  // Properties for Notice letter
  showNoticeLetter: boolean;
  noticeLetterContent: string;
  selectedNoticeLetter: string;
  noticeLetters: RimNoticeLetter[];
  public reimbursementNoticeLetterModel = new RimNoticeLetter();

  constructor(
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    public provinceService: ProvinceService,
    public districtService: DistrictService,
    public userService: UserService,
    private rimService: RimApplicationService,
    private router: Router,
    private datePipe: DatePipe,
  ) {
    this.assignedprovinceCode = 'undefined';
    this.assigneddistrictCode = 'undefined';
    this.dcommissionerId = 'undefined';
    this.rimId = parseInt(sessionStorage.getItem('rimId'));
    this.showToTaxOfficer = false;
    this.showToAccountant = false;
    this.showToDeputyCommissioner = false;
    this.showToCommissioner = false;
    this.showNonPayableAndPayableDeedTables = false;
    this.showNoticeLetter = false;
  }

  assignDCommissionerForm = this.formBuilder.group({
    province: ['undefined'],
    district: ['undefined'],
    dCommisioner: ['undefined'],
  });

  ngOnInit() {
    this.reimbursementFileNumber = localStorage.getItem('rimFileNumberFormat');
    this._prepare();
  }

  _prepare() {
    this.loadProvinceList();
    this.retrieveUserList();
    this.obtainUserData();
    this.getBankStatementDetails();
    this.listNoticeLetters();

    // Assigning values to "rimPayableDeedList" & "rimNonPayableDeedList" if Tax officer is returning from Payable print screen
    if (JSON.parse(sessionStorage.getItem('visitedToPrintScreen'))) {
      this.payableAndNonPayableDeedsModel.rimPayableDeedList = JSON.parse(sessionStorage.getItem('payableDeedList'));
      this.payableAndNonPayableDeedsModel.rimNonPayableDeedList = JSON.parse(sessionStorage.getItem('currentListOfNonPayableDeedList'));
    } else {
      this.getAllNonPayableDeedsByReimbursementId();
    }
  }

  openNoticeLetterModal() {
    this.showNoticeLetter = true;
  }

  saveNoticeLetter() {
    this.reimbursementNoticeLetterModel.reimbursementId = sessionStorage.getItem('rimId');
    this.reimbursementNoticeLetterModel.noticeLetterContent = this.noticeLetterContent;

    this.rimService.saveRefundNoticeLetter(this.reimbursementNoticeLetterModel).subscribe(
      data => {
        this.noticeLetterContent = "";
        this.noticeLetters.push(this.reimbursementNoticeLetterModel);
      }
    )
  }

  openNoticeLetter(noticeLetter) {
    this.selectedNoticeLetter = noticeLetter.noticeLetterContent;
  }

  listNoticeLetters() {
    this.rimService.getNoticelettersByReimbursementId(+this.rimId).subscribe(
      data => {
        this.noticeLetters = data.data;
      }
    )
  }

  getAllNonPayableDeedsByReimbursementId() {
    this.rimService.getFormExcelFileDataByReimbursementId(this.rimId).subscribe(
      data => {
        this.payableAndNonPayableDeedsModel.rimNonPayableDeedList = data.data;
      }
    )
  }

  getFromDate() {
    let FromDate = this.datePipe.transform(this.fromDate, 'dd-MMM-yyyy');
    let ToDate = this.datePipe.transform(this.toDate, 'dd-MMM-yyyy');
  }

  searchFromDate() {
    if (this.fromDate == null && this.toDate == null) {
      console.log('please enter Dates');
      this.ngOnInit();
    } else if (this.fromDate != null && this.toDate == null) {
      var FromDate = this.datePipe.transform(this.fromDate, 'dd-MMM-yyyy').toString();
      var Result = this.payableAndNonPayableDeedsModel.rimBankStatementList.filter(function (obj) {
        return new Date(FromDate) == new Date(obj.date);
      })
      this.payableAndNonPayableDeedsModel.rimBankStatementList = Result;
    } else {
      var FromDate = this.datePipe.transform(this.fromDate, 'dd-MMM-yyyy').toString();
      var ToDate = this.datePipe.transform(this.toDate, 'dd-MMM-yyyy').toString();
      var Result = this.payableAndNonPayableDeedsModel.rimBankStatementList.filter(function (obj) {
        return new Date(obj.date) >= new Date(FromDate) && new Date(obj.date) <= new Date(ToDate);
      })
      if(Result.length === 0){
        this.isTrue=true;
      }
      this.totalRecords1 =Result.length;
      this.payableAndNonPayableDeedsModel.rimBankStatementList = Result;
    }

  }

  obtainUserData() {
    this.userData = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    for (let role of this.userData.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.userRoleCode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_COMMISSIONER;
        this.showToCommissioner = true;
        this.showNonPayableAndPayableDeedTables = true;
        this.commentBoxTrue = true;
        this.getAllNonPayableDeedstoViewByReimbursementId();
        this.getAllPayableDeedstoViewByReimbursementId();
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
        this.showToDeputyCommissioner = true;
        this.showNonPayableAndPayableDeedTables = true;
        this.commentBoxTrue = true;
        this.getAllNonPayableDeedstoViewByReimbursementId();
        this.getAllPayableDeedstoViewByReimbursementId();
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.userRoleCode = Constant.ROLE_ACCESSOR;
        this.showNonPayableAndPayableDeedTables = true;
        this.getAllNonPayableDeedstoViewByReimbursementId();
        this.getAllPayableDeedstoViewByReimbursementId();
        break;
      } else if (role.code === Constant.ROLE_TAX_OFFICER) {
        this.userRoleCode = Constant.ROLE_TAX_OFFICER;
        this.showToTaxOfficer = true;
        break;
      } else if (role.code === Constant.ROLE_ACCOUNTANT) {
        this.userRoleCode = Constant.ROLE_ACCOUNTANT;
        this.showToAccountant = true;
        this.showNonPayableAndPayableDeedTables = true;
        this.commentBoxTrue = true;
        this.getAllNonPayableDeedstoViewByReimbursementId();
        this.getAllPayableDeedstoViewByReimbursementId();
        break;
      }
    }
  }

  nonPayableChecked(id) {
    this.payableAndNonPayableDeedsModel.rimPayableDeedList.push(this.payableAndNonPayableDeedsModel.rimNonPayableDeedList.filter(x => (x.id === id))[0]);
    this.payableAndNonPayableDeedsModel.rimNonPayableDeedList = this.payableAndNonPayableDeedsModel.rimNonPayableDeedList.filter(x => (x.id !== id));
  }

  payableChecked(id) {
    this.payableAndNonPayableDeedsModel.rimNonPayableDeedList.push(this.payableAndNonPayableDeedsModel.rimPayableDeedList.filter(x => (x.id === id))[0]);
    this.payableAndNonPayableDeedsModel.rimPayableDeedList = this.payableAndNonPayableDeedsModel.rimPayableDeedList.filter(x => (x.id !== id));
  }

  getNonPayableTotal1(startPoint, endPoint) {
    let total = new Array<number>();
    for (let i = startPoint; i < endPoint; i++) {
      if (i < this.payableAndNonPayableDeedsModel.rimNonPayableDeedList.length) {
        total.push(parseFloat(this.payableAndNonPayableDeedsModel.rimNonPayableDeedList[i].deedValue.replace(/,/g, '')))
      }
    }
    let sum = total.reduce((a, b) => a + b, 0).toLocaleString();
    return sum;
  }

  getNonPayableSDTotal1(startPoint, endPoint) {
    let total = new Array<number>();
    for (let i = startPoint; i < endPoint; i++) {
      if (i < this.payableAndNonPayableDeedsModel.rimNonPayableDeedList.length) {
        total.push(parseFloat(this.payableAndNonPayableDeedsModel.rimNonPayableDeedList[i].stampDutyValue.replace(/,/g, '')))
      }
    }
    let sum = total.reduce((a, b) => a + b, 0).toLocaleString();
    return sum;
  }

  getPayableTotal1(startPoint, endPoint) {
    let total = new Array<number>();
    for (let i = startPoint; i < endPoint; i++) {
      if (i < this.payableAndNonPayableDeedsModel.rimPayableDeedList.length) {
        total.push(parseFloat(this.payableAndNonPayableDeedsModel.rimPayableDeedList[i].deedValue.replace(/,/g, '')))
      }
    }
    let sum = total.reduce((a, b) => a + b, 0).toLocaleString();
    return sum;
  }

  getPayableSDTotal1(startPoint, endPoint) {
    let total = new Array<number>();
    for (let i = startPoint; i < endPoint; i++) {
      if (i < this.payableAndNonPayableDeedsModel.rimPayableDeedList.length) {
        total.push(parseFloat(this.payableAndNonPayableDeedsModel.rimPayableDeedList[i].stampDutyValue.replace(/,/g, '')))
      }
    }
    let sum = total.reduce((a, b) => a + b, 0).toLocaleString();
    return sum;
  }

  setPayableNonePayableDocument(documents: File[]){
    this.selectPaths.push(documents);
  }

  setEveryChildComponentUploadingDocuments() {
    this.documentUploadLocalAuthorityLetter.outputDocumentsOneSet();
  }

  savePayableAndNonPayableDeeds() {
    this.setEveryChildComponentUploadingDocuments();
    for (var i = 0; i < this.payableAndNonPayableDeedsModel.rimNonPayableDeedList.length; i++) {
      this.payableAndNonPayableDeedsModel.rimNonPayableDeedList[i].reimbursementApplicationId = sessionStorage.getItem('rimId');
      this.payableAndNonPayableDeedsModel.rimNonPayableDeedList[i].remark = this.remarkValue[i];
    }

    for (var i = 0; i < this.payableAndNonPayableDeedsModel.rimPayableDeedList.length; i++) {
      this.payableAndNonPayableDeedsModel.rimPayableDeedList[i].reimbursementApplicationId = sessionStorage.getItem('rimId');
      this.payableAndNonPayableDeedsModel.rimPayableDeedList[i].remark = this.payableRemark[i];
    }

    this.rimService.savePayableAndNonPayableDocs(this.selectPaths,sessionStorage.getItem('rimId')).subscribe();
    this.rimService.savePayableAndNonPayableDeeds(this.payableAndNonPayableDeedsModel).subscribe();
  }

  /* *** ASSIGN DEPUTY COMMISSIONER - START *** */
  loadProvinceList() {
    this.provinceService.getAll().subscribe(
      data => {
        this.provincelist = data.data;
      }
    )
  }

  selectedProvince(event) {
    this.districtService.searchDistrictDetails(event).subscribe(
      data => {
        this.districtlist = data.data;
        this.assigneddistrictCode = 'undefined';
        this.dcommissionerId = 'undefined';
      }
    )
  }

  selectedDistrict(event) {
    this.dcommissionerId = 'undefined';
    this.filteredcommissionerlist = this.dcommissionerlist.filter(i => i.districtCode === event);
  }

  retrieveUserList() {
    this.userService.retrieveUserList().subscribe(
      data => {
        this.userlist = data.data;
        this.dcommissionerlist = this.userlist.filter(i => i['roles'][0]['code'] === Constant.ROLE_DEPUTY_COMMISSIONER);
      }
    )
  }

  selectDeputyCommissioner(deputyCommissionerId: any) {
    this.dcommissionerId = deputyCommissionerId;
  }

  assignDCommissioner() {

    this.updateDCommissionerStatus.rimId = this.rimId;
    this.updateDCommissionerStatus.status = Constant.RIM_REQUEST_PENDING_CODE;
    console.log(this.updateDCommissionerStatus)
    if(this.assignedprovinceCode == 'undefined'){
      this.notifier.notify('error', 'Please Select a Province')
    } else {
      if(this.assigneddistrictCode == 'undefined'){
        this.notifier.notify('error', 'Please Select a District')
      } else {
        if (this.dcommissionerId == 'undefined') {
          this.notifier.notify('error', 'Please Select a Deputy Commissioner')
        } else {
          this.rimService.updateRimDCommissionerStatus(this.dcommissionerId, this.updateDCommissionerStatus).subscribe(data => {
            this.notifier.notify('success', 'Deputy Commissioner Status Successfully Assigned')
            this.router.navigate(['/main-dashboard-internal-user'])
          })
        }
      }
    }
    
  }
  /* *** ASSIGN DEPUTY COMMISSIONER - END *** */

  updateReimbursementApplication() {
    this.savePayableAndNonPayableDeeds();
    this.assignDCommissioner();
  }
  
  selectedRemark(event){
    this.remarkValue.push(event);

  }
  payRemark(event){
    this.payableRemark.push(event);
  }

  // DEPUTY COMMISSIONER RECOMMEND PROCESS - START
  // setDCommissionerDocumentArray(documents: File[]) {
  //   this.selectPaths.push(documents);
  // }

  // checkDeputyCommissionerDocuments() {
  //   this.errorList = [];
  //   if (this.documentUploadDCommissioner.filesList14.length === 0) {
  //     this.errorList.push('Deputy Commissioner Documents');
  //   }

  //   if (this.errorList.length === 0) {
  //     this.documentUploadDCommissioner.outputDocumentsFourteenSet();
  //     this.otherSelectedPaths = this.selectPaths;
  //     this.saveDCommissionerDocuments();
  //   } else {
  //     const re = /,/gi;
  //     const errormsg = this.errorList.toString().replace(re, '<br/>');
  //   }
  // }

  // saveDCommissionerDocuments() {
  //   this.selectPathsCollection.push(this.otherSelectedPaths[0]);

  //   this.documentUploadService.uploadRefundFormsDeputyCommissionerDocuments(this.selectPathsCollection, localStorage.getItem('refundRequest'), this.deputyCommissionerComment).subscribe(
  //     data => {
  //     },
  //     err => {
  //       this.notifier.notify('error', 'Document upload unsuccessful');
  //     }
  //   );
  // }

  selectedComment(event){
    this.commentValue = event;
  }

  saveComment(){
    this.reimbursementApplicationUpdateModel.userRole =this.userRoleCode;
    this.reimbursementApplicationUpdateModel.reimbursementId = sessionStorage.getItem('rimId');
    this.reimbursementApplicationUpdateModel.comment = this.commentValue;
    this.rimService.updatePayableAndNonPayableComment(this.reimbursementApplicationUpdateModel).subscribe();
  }

  updateStatusForRecommendProcess() {
    this.saveComment();
    this.reimbursementApplicationUpdateModel.reimbursementId = sessionStorage.getItem('rimId');
    console.log(this.reimbursementApplicationUpdateModel,"reccomond")
    this.rimService.updateReimbursementStatusForRecommendProcess(this.reimbursementApplicationUpdateModel).subscribe(
      data => {
        Swal.fire({
          icon: 'success',
          title: 'Successfully Recommended',
          showConfirmButton: false,
          timer: 2000
        });
        this.router.navigate(['/main-dashboard-internal-user']);
      },
      err => {
        this.notifier.notify('error', 'Reimbursement Application recommendation unsuccessful');
      }
    );
  }

  onfileImport(event: any) {

    this.file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: false });
      var isValidFormat = true;

      for (let i = 0; Object.keys(arraylist[0]).length > i; i++) {
        if (
          Object.keys(arraylist[0])[i] === "Date" ||
          Object.keys(arraylist[0])[i] === "Reference No." ||
          Object.keys(arraylist[0])[i] === "Description" ||
          Object.keys(arraylist[0])[i] === "CR" ||
          Object.keys(arraylist[0])[i] === "DR" ||
          Object.keys(arraylist[0])[i] === "Balance" ||
          Object.keys(arraylist[0])[i] === "__EMPTY"
        ) {
          isValidFormat = true;
          this.showTable = true;
        } else {
          isValidFormat = false;
          this.showTable = false;
          this.file = null;
          break;
        }
      }

      if (isValidFormat) {
        var dataList = new Array();

        for (var i = 0; i < arraylist.length; i++) {
          var row = new RimBankStatement();
          row.date = arraylist[i]["Date"];
          row.referenceNo = arraylist[i]["Reference No."];
          row.description = arraylist[i]["Description"];
          row.cr = arraylist[i]["CR"];
          row.dr = arraylist[i]["DR"];
          row.balance = arraylist[i]["Balance"];
          row.reimbursementApplicationId = sessionStorage.getItem('rimId');
          this.payableAndNonPayableDeedsModel.rimBankStatementList.push(row);
          dataList.push(row);
        }
        this.filelist = dataList;
        
      }else{
        this.notifier.notify("error", "Please refer attached CSV for knowing the correct CSV format.");
      }
    }
  }

  recommendReimbursementApplication() {
    Swal.fire({
      title: 'Please Confirm',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#589C0E',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Continue!'
    }).then((result) => {
      // this.checkDeputyCommissionerDocuments();
      this.updateStatusForRecommendProcess();
    });
  }
  // DEPUTY COMMISSIONER RECOMMEND PROCESS - END

  // COMMISSIONER APPROVE PROCESS - START
  // checkCommissionerDocuments() {
  //   this.errorList = [];
  //   if (this.documentUploadDCommissioner.filesList14.length === 0) {
  //     this.errorList.push('Commissioner Documents');
  //   }

  //   if (this.errorList.length === 0) {
  //     this.documentUploadDCommissioner.outputDocumentsFourteenSet();
  //     this.otherSelectedPaths = this.selectPaths;
  //     this.saveCommissionerDocuments();
  //   } else {
  //     const re = /,/gi;
  //     const errormsg = this.errorList.toString().replace(re, '<br/>');
  //   }
  // }

  // saveCommissionerDocuments() {
  //   this.selectPathsCollection.push(this.otherSelectedPaths[0]);

  //   this.documentUploadService.uploadRefundFormsCommissionerDocuments(this.selectPathsCollection, localStorage.getItem('refundRequest'), this.commissionerComment).subscribe(
  //     data => {
  //     },
  //     err => {
  //       this.notifier.notify('error', 'Document upload unsuccessful');
  //     }
  //   );
  // }

  updateStatusForApproveProcess() {
    this.saveComment();
    this.reimbursementApplicationUpdateModel.reimbursementId = sessionStorage.getItem('rimId');
    console.log(this.reimbursementApplicationUpdateModel,"approve")
    this.rimService.updateReimbursementStatusForApproveProcess(this.reimbursementApplicationUpdateModel).subscribe(
      data => {
        Swal.fire({
          icon: 'success',
          title: 'Successfully Approved',
          showConfirmButton: false,
          timer: 2000
        });
        this.router.navigate(['/main-dashboard-internal-user']);
      },
      err => {
        this.notifier.notify('error', 'Reimbursement Application Approval unsuccessful');
      }
    );
  }

  approveReimbursementApplication() {
    Swal.fire({
      title: 'Please Confirm',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#589C0E',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Continue!'
    }).then((result) => {
      // this.checkCommissionerDocuments();
      this.updateStatusForApproveProcess();
    })
  }
  // COMMISSIONER APPROVE PROCESS - END

  /* COMMISSIONER, DEP. COMMISSIONER AND ACCOUNTANT VIEW - START */
  getAllNonPayableDeedstoViewByReimbursementId() {
    this.rimService.getAllNonPayableTableDataByReimbursementId(this.rimId).subscribe(
      data => {
        this.nonPayableDeedList = data.data;
      }
    )
  }

  getNonPayableTotal(startPoint, endPoint) {
    let total = new Array<number>();
    for (let i = startPoint; i < endPoint; i++) {
      if (i < this.nonPayableDeedList.length) {
        total.push(parseFloat(this.nonPayableDeedList[i].deedValue.replace(/,/g, '')))
      }
    }
    let sum = total.reduce((a, b) => a + b, 0).toLocaleString();
    return sum;
  }

  getNonPayableSDTotal(startPoint, endPoint) {
    let total = new Array<number>();
    for (let i = startPoint; i < endPoint; i++) {
      if (i < this.nonPayableDeedList.length) {
        total.push(parseFloat(this.nonPayableDeedList[i].stampDutyValue.replace(/,/g, '')))
      }
    }
    let sum = total.reduce((a, b) => a + b, 0).toLocaleString();
    return sum;

  }

  getAllPayableDeedstoViewByReimbursementId() {
    this.rimService.getAllPayableTableDataByReimbursementId(this.rimId).subscribe(
      data => {
        this.payableDeedList = data.data;
      }
    )
  }

  getPayableTotal(startPoint, endPoint) {
    let total = new Array<number>();
    for (let i = startPoint; i < endPoint; i++) {
      if (i < this.payableDeedList.length) {
        total.push(parseFloat(this.payableDeedList[i].deedValue.replace(/,/g, '')))
      }
    }
    let sum = total.reduce((a, b) => a + b, 0).toLocaleString();
    return sum;
  }

  getPayableSDTotal(startPoint, endPoint) {
    let total = new Array<number>();
    for (let i = startPoint; i < endPoint; i++) {
      if (i < this.payableDeedList.length) {
        total.push(parseFloat(this.payableDeedList[i].stampDutyValue.replace(/,/g, '')))
      }
    }
    let sum = total.reduce((a, b) => a + b, 0).toLocaleString();
    return sum;

  }
  /* COMMISSIONER, DEP. COMMISSIONER AND ACCOUNTANT VIEW - END */

  /* view bank statement */
  getBankStatementDetails() {
    this.rimService.getBankStatement(parseInt(sessionStorage.getItem('rimId'))).subscribe(
      data => {
        if (data.message == 'Bad request') {
          this.payableAndNonPayableDeedsModel.rimBankStatementList.push(null);
        } else if (data.message == 'Request received successfully') {
          this.payableAndNonPayableDeedsModel.rimBankStatementList = data.data;
          this.totalRecords1 = data.data.length;
        }
      }
    )
  }

  /* page emite*/
  public redirectPreviousComponent(): void {
    if (this.userRoleCode == Constant.ROLE_ACCESSOR) {
      this.onPageTypeChanged.emit('assign-tax-officer');
    } else {
      this.onPageTypeChanged.emit('documents');
    }

  }

  printNonPayable() {
    this.router.navigate(['/print-non-payable']);

    if (this.showToTaxOfficer) {
      sessionStorage.setItem('nonPayableDeedList', JSON.stringify(this.payableAndNonPayableDeedsModel.rimNonPayableDeedList));
    } else {
      sessionStorage.setItem('nonPayableDeedList', JSON.stringify(this.nonPayableDeedList));
    }
  }

  // Using jsPDF
  // printNonPayable() {
  //   // jsPDF documentation - http://raw.githack.com/MrRio/jsPDF/master/docs/jsPDF.html

  //   const doc = new jsPDF('l', 'mm', 'a4');
  //   doc.setFontSize(11);
  //   let row = 25;
  //   let index = 1;

  //   doc.setFontStyle("bold");
  //   // doc.text("string", column, row);
  //   doc.text("Name", 15, 12);
  //   doc.text("Deed No.", 40, 12);
  //   doc.text("Deed Date.", 60, 12);
  //   doc.text("Nature", 85, 12);
  //   doc.text("Deed Value", 111, 12);
  //   doc.text("(LKR)", 116, 16);
  //   doc.text("Bank", 145, 12);
  //   doc.text("Branch", 175, 12);
  //   doc.text("Receipt No.", 204, 12);
  //   doc.text("Stamp Duty", 228, 12);
  //   doc.text("Date", 233, 16);
  //   doc.text("Stamp Duty", 258, 12);
  //   doc.text("Value (LKR)", 258, 16);

  //   doc.setFontType('normal');
  //   doc.setFontSize(9);

  //   let nonPayableDeedsListToPrint;

  //   if (this.showToTaxOfficer) {
  //     nonPayableDeedsListToPrint = this.payableAndNonPayableDeedsModel.rimNonPayableDeedList;
  //   } else {
  //     nonPayableDeedsListToPrint = this.nonPayableDeedList;
  //   }

  //   for (const nonPayableDeed of nonPayableDeedsListToPrint) {
  //     doc.text(index.toString() + '.', 4, row);
  //     doc.text(nonPayableDeed.name, 10, row);
  //     doc.text(nonPayableDeed.deedNo, 45, row);
  //     doc.text(nonPayableDeed.deedDate, 60, row);
  //     doc.text(nonPayableDeed.nature, 85, row);
  //     doc.text(nonPayableDeed.deedValue, 110, row);

  //     doc.text(nonPayableDeed.bank, 145, row);
  //     doc.text(nonPayableDeed.branch, 175, row);
  //     doc.text(nonPayableDeed.receiptNo, 205, row);
  //     doc.text(nonPayableDeed.stampDutyDate, 230, row);
  //     doc.text(nonPayableDeed.stampDutyValue, 258, row);
  //     index += 1;
  //     row += 10;
  //   }

  //   doc.save('NonPayableDeeds.pdf');
  // }

  checkBeforePrintPayable() {
    if (this.showToTaxOfficer && this.payableAndNonPayableDeedsModel.rimPayableDeedList.length === 0) {
      this.notifier.notify('error', 'Cannot Print! List of Payable Deeds is Empty.');
    } else if (!this.showToTaxOfficer && this.payableDeedList.length === 0) {
      this.notifier.notify('error', 'Cannot Print! List of Payable Deeds is Empty.');
    } else {
      this.printPayable();
    }
  }

  printPayable() {
    this.router.navigate(['/print-payable']);
    if (this.showToTaxOfficer) {
      sessionStorage.setItem('payableDeedList', JSON.stringify(this.payableAndNonPayableDeedsModel.rimPayableDeedList));
      sessionStorage.setItem('currentListOfNonPayableDeedList', JSON.stringify(this.payableAndNonPayableDeedsModel.rimNonPayableDeedList));
    } else {
      sessionStorage.setItem('payableDeedList', JSON.stringify(this.payableDeedList));
    }
  }

  moveToNextComponent() {
    this.saveComment();
    this.onPageTypeChanged.emit("payment-details");
  }

  // printPayable() {
  //   // ToDo: Add document title
  //   const doc1 = new jsPDF('l', 'mm', 'a4');
  //   doc1.setFontSize(11);
  //   let row = 25;
  //   let index = 1;

  //   doc1.setFontStyle("bold");
  //   // doc.text("string", column, row);
  //   doc1.text('Name', 15, 12);
  //   doc1.text('Deed No.', 40, 12);
  //   doc1.text("Deed Date.", 60, 12);
  //   doc1.text("Nature", 85, 12);
  //   doc1.text("Deed Value", 111, 12);
  //   doc1.text("(LKR)", 116, 16);
  //   doc1.text("Bank", 145, 12);
  //   doc1.text("Branch", 175, 12);
  //   doc1.text("Receipt No.", 204, 12);
  //   doc1.text("Stamp Duty", 228, 12);
  //   doc1.text("Date", 233, 16);
  //   doc1.text("Stamp Duty", 258, 12);
  //   doc1.text("Value (LKR)", 258, 16);

  //   doc1.setFontType('normal');
  //   doc1.setFontSize(9);

  //   let payableDeedsListToPrint;

  //   if (this.showToTaxOfficer) {
  //     payableDeedsListToPrint = this.payableAndNonPayableDeedsModel.rimPayableDeedList;
  //   } else {
  //     payableDeedsListToPrint = this.payableDeedList;
  //   }

  //   for (const payableDeed of payableDeedsListToPrint) {
  //     doc1.text(index.toString() + '.', 4, row);
  //     doc1.text(payableDeed.name, 10, row);
  //     doc1.text(payableDeed.deedNo, 45, row);
  //     doc1.text(payableDeed.deedDate, 60, row);
  //     doc1.text(payableDeed.nature, 85, row);
  //     doc1.text(payableDeed.deedValue, 110, row);

  //     doc1.text(payableDeed.bank, 145, row);
  //     doc1.text(payableDeed.branch, 175, row);
  //     doc1.text(payableDeed.receiptNo, 205, row);
  //     doc1.text(payableDeed.stampDutyDate, 230, row);
  //     doc1.text(payableDeed.stampDutyValue, 258, row);
  //     index += 1;
  //     row += 10;
  //   }

  //   doc1.save('PayableDeeds.pdf');

  // }

}
