export class RefundIssueDetails {
    id: number;
    issueDate: string;
    chequeNo: string;
    issueAmount: number;
    name: string;
    nic: string;
    address: string;
    mobile: string;
    email: string;
    issuedOffice: string;
    refundRequestId: string;

    constructor() {}
}