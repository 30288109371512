export class InspectionBuildingConstructionDetail {

  public buildingSubFeatureTypeId: string;
  public value: string;

  constructor(
    buildingSubFeatureTypeId: string,
    value: string
  ) {
    this.buildingSubFeatureTypeId = buildingSubFeatureTypeId;
    this.value = value;
  }
}
