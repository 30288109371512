import { ValueOfTheLand } from "./landValues";
import { BuildingValues } from "./buildingValues";
import { Improvements } from "./improvements";

export class SecondSet {
  valueType: any = true;
  rentPerSqFt: any = null;
  extent: any = null;
  monthlyRent: any = null;
  annualRent: any = null;
  deductionPrecentage: any = null;
  deductionOutgoing: any = null;
  annualIncome: any = null;
  marketValue: any = null;
  externalTotal: any = null;
  valueOfTheProperty: any = null;
  stampDuty: any = null;
  roundOffValue: any = null;
  previousDeedValue: any = null;
  yp: any = null;
  rateOfReturn: any = null;
  total: any = null;
  decisionOfCommitee: any = null;
  id: any = null;
  valueOfTheLand: ValueOfTheLand[] = [
    {
      id: null,
      landExtend: null,
      perPerchValue: null,
      value: null
    }
  ];
  valueOfTheLandRemark: any = null;
  valueOfTheBuildings: BuildingValues[] = [
    {
      id: null,
      buildingNo: null,
      floorNo: null,
      extent: null,
      constructionCost: null,
      contractionCost: null
    }
  ];
  valueOfTheBuildingsRemark: any = null;
  improvements: Improvements[] = [
    {
      id: null,
      description: null,
      extent: null,
      rentPerSqFt: null,
      value: null
    }
  ];
  depreciation: any = null;
  depreciationContruction: any = null;
  lowestValue: any = null;
  headingDate: any = null;
}
