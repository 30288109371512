import { Component, Input, OnInit, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { IProperty } from '../../../../../../../model/interfaces/property';
import { IBuilding } from '../../../../../../../model/interfaces/building';
import { Property } from '../../../../../../../model/property';
import { ValuationRequest } from '../../../../../../../model/valuation-request';
import { ValuationRequestService } from '../../../../../../../service/valuation-request/valuation-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SubSink } from 'subsink';
import { PropertyUsageService } from "../../../../../../../service/property-usage/property-usage.service";
import { PropertyUsageSubtypeService } from "../../../../../../../service/property-usage-subtype/property-usage-subtype.service";
import { DeedNatureService } from "../../../../../../../service/deed-nature/deed-nature.service";
import { LocalAuthorityService } from "../../../../../../../service/local-authority/local-authority.service";
import { ProvinceService } from "../../../../../../../service/province/province.service";
import { DistrictService } from "../../../../../../../service/district/district.service";
import { GramaNiladariDivisionService } from "../../../../../../../service/grama-niladari-division/grama-niladari-division.service";
import { ValuationStatus } from 'src/app/model/valuation-status';
import { PropertyService } from 'src/app/service/property/property.service';
import { BankService } from 'src/app/service/bank/bank.service';
import { NotifierService } from 'angular-notifier';
import { Constant } from 'src/app/util/constant';
import { NotificationModel } from 'src/app/model/notification-model';
import { NotificationService } from 'src/app/service/notification/notification.service';
import { NotificationEmailSend } from 'src/app/model/notification-email-send';
import { ValuationRequestUpdate } from 'src/app/model/valuation-request-update';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-view-property-iu',
  templateUrl: './view-property-iu.component.html',
  styleUrls: ['./view-property-iu.component.scss']
})
export class ViewPropertyInternalUserComponent implements OnInit {
  getPropertyStatus:Subscription;

  @Input()
  properties: IProperty[];

  @Input()
  otherPartyId:string;

  buildingList: IBuilding[];
  propertyOne: IProperty;
  assessment: IProperty;
  extend: IProperty;
  other: IProperty;
  loan: IProperty;
  buildingListUD: IProperty;
  propertyUsages = [];
  propertyUsageSubTypes = [];
  deedNatures = [];
  localAuthorities = [];
  provincesorderd: [];
  districts = [];
  gramaniladhariDivisions = [];
  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() status1: EventEmitter<any> = new EventEmitter<any>();
  propertyId: string;
  verificationStatus: number;
  deedNatureValue: any;
  currentUserRoleCode: string;
  userdata: any = [];
  propertyOneExchange: Property;
  assessmentExchange: Property;
  extendExchange: Property;
  otherExchange: Property;
  loanExchange: Property;
  buildingListUDExchange: Property;
  isExchangeValuation: boolean;
  isPreviousOpinion: string;
  isPropertyRented: string;
  valuationRequest: ValuationRequest;
  valuationRequestExchange: ValuationRequest;
  isDeedTypeExchange:boolean;
  files;
  images = [];
  originalImages: any[] = [];
  filesList: File[] = [];
  names: string[] = [];
  public imgURL: any;
  public imgURL1: any;
  public documentUploaded: string;
  public documentUploaded1: string;

  public modelStatus = new ValuationStatus('', '');
  public notificationModel = new NotificationModel("", "", "", "", "", "", "");
;
  public valuationRequestUpdateModel = new ValuationRequestUpdate('', '', '', '', '', '');
  public notificationEmailSendModel = new NotificationEmailSend();
  private sub = new SubSink();
  public fileNumberFormat: string;
  public generatedFileNumber: string;
  valuationdata: any = [];
  status: boolean;

  public bankList: any = [];

  fileNumberFormatAvailable: boolean = true;

  constructor(private route: ActivatedRoute,
    private valuationRequestService: ValuationRequestService,
    private propertyUsageService: PropertyUsageService,
    private propertyUsageSubtypeService: PropertyUsageSubtypeService,
    private deedNatureService: DeedNatureService,
    private localAuthorityService: LocalAuthorityService,
    private provinceService: ProvinceService,
    private districtService: DistrictService,
    private gramaNiladhariDivisionService: GramaNiladariDivisionService,
    public propertyService: PropertyService,
    public router: Router,
    public bankService: BankService,
    public modalService: NgbModal,
    private notifier: NotifierService,
    public notificationService: NotificationService
  ) {
    this.valuationRequest = new ValuationRequest();
    this.getPropertyStatus = propertyService.getPropertyStatus().subscribe(()=>{
      this.ngOnInit();
    })
  }

  ngOnInit() {
    this.fileNumberFormat = localStorage.getItem('fileNumberFormat');
    if (this.fileNumberFormat == "null" || this.fileNumberFormat == '') {
      this.fileNumberFormatAvailable = false;
    }

    this._prepare();
    this.valuationRequest = JSON.parse(sessionStorage.getItem('valuationRequest'));

    if (this.valuationRequest.deedNature === 'Exchange') {
      this.getOtherPartyVerificationStatus();
      this.isExchangeValuation = false;
      console.log('deednature if =' + this.valuationRequest.deedNature);
    } else {
      this.isExchangeValuation = true;
      console.log('deednature else =' + this.valuationRequest.deedNature);
    }
    this.buildingList = [];
    console.log('interface ' + JSON.stringify(this.properties));

    this.propertyOne = this.properties[0];
    this.assessment = this.properties[0];
    this.extend = this.properties[0];

    this.other = this.properties[0];
    this.loan = this.properties[0];
    this.buildingListUD = this.properties[0];

    if (this.valuationRequest.deedNature === 'Exchange') {
      // console.log('exchangeId == ' + this.valuationRequest.exchangeId);
      this.getValuationRequest(this.valuationRequest.exchangeId);
    }
    this.sub.add(this.bankService.getAll().subscribe(
      res => {
        this.bankList = (res as any).data;
      }
    ));
    // this.getValuationRequest(357);
    this.sub.add(this.propertyUsageService.getAll()
      .subscribe(
        (res) => {
          this.propertyUsages = (res as any).data;
        }
      ));
    this.sub.add(this.propertyUsageSubtypeService.getAll()
      .subscribe(
        (res) => {
          this.propertyUsageSubTypes = (res as any).data;
        }
      ));
    this.sub.add(this.deedNatureService.getAll()
      .subscribe(
        (res) => {
          this.deedNatures = (res as any).data;
        }
      ));
    this.sub.add(this.localAuthorityService.getAllByOrderByLocalAuthorityAsc()
      .subscribe(
        (res) => {
          this.localAuthorities = (res as any).data;
        }
      ));
    this.sub.add(this.provinceService.getProvincesOrderd().subscribe(
      (res) => {
        this.provincesorderd = (res as any).data;
      }
    ));
    this.sub.add(this.districtService.getAll()
      .subscribe(
        (res) => {
          this.districts = (res as any).data;
        }
      ));
    this.sub.add(this.gramaNiladhariDivisionService.getAll()
      .subscribe(
        (res) => {
          this.gramaniladhariDivisions = (res as any).data;
        }
      ));
    if (this.other.prevOpinion) {
      this.isPreviousOpinion = 'Yes';
    } else {
      this.isPreviousOpinion = 'No';
    }
    if (this.other.propertyRented) {
      this.isPropertyRented = 'Yes';
    } else {
      this.isPropertyRented = 'No';
    }
    window.scroll(0,0);
    this.isDeedTypeExchange = sessionStorage.getItem('deedType')==='exchange';
  }

  extendUnitConvertion(unit:any):String{
    if(unit == "feet"){
      return "SQ.Feet";
    }else if(unit == "perch"){
      return "Perch";
    }else if(unit == "acre"){
      return "Acre";
    }else if(unit == "hectare"){
      return "hectare";
    }else{
      return "N/A"
    }
  }
  _prepare() {
    this.valuationdata = JSON.parse(sessionStorage.getItem('valuationRequest'));
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.pad(this.valuationdata.id, 7);
    this.propertyId = this.properties[0]['id'];
    this.getVerificationStatus();
    this.checkPermission();
    this.retrieveAssignedValuationRequest();
  }

  checkPermission() {
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.currentUserRoleCode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.currentUserRoleCode = Constant.ROLE_ACCESSOR;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER){
        this.currentUserRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.currentUserRoleCode = Constant.ROLE_COMMISSIONER;
        break;
      }
    }
  }

  retrieveAssignedValuationRequest() {
    this.valuationRequestService.valuationRequestAssigned(this.route.snapshot.paramMap.get('id')).subscribe(
      data => {
        if (data.data !== null) {
          this.valuationRequestUpdateModel.dcommissionerId = data.data.dcommissionerId;
          this.valuationRequestUpdateModel.acccessorId = data.data.accessorId;
          this.valuationRequestUpdateModel.remark = data.data.remark;
        } else {
          console.log("Valuation Request not found");
        }
      }
    )
  }

  pad(num: number, size: number) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    this.generatedFileNumber = s;
    this.generatedFileNumber = '#VR' + this.generatedFileNumber;
  }

  public redirectComponent(): void {
    this.onPageCountChanged.emit(6);
  }

  public redirectPreviousComponent(): void {
    this.onPageCountChanged.emit(4);
  }

  getOtherPartyVerificationStatus(){
    this.modelStatus.id = this.otherPartyId;
    this.propertyService.propertyVerificationStatus(this.modelStatus).subscribe(
      data => {
        this.status = data.data;
      }
    )
  }

  getVerificationStatus() {
    this.modelStatus.id = this.propertyId;
    this.propertyService.propertyVerificationStatus(this.modelStatus).subscribe(
      data => {
        this.verificationStatus = data.data;
      }
    )
  }

  updateVerificationStatus(event) {
    this.modelStatus.id = this.propertyId;
    if (event.target.checked === true) {
      this.modelStatus.verificationStatus = '1';
      this.propertyService.propertyUpdateVerificationStatus(this.modelStatus).subscribe(
        data => {
          this.getVerificationStatus();
          if(this.isDeedTypeExchange){
            this.getOtherPartyVerificationStatus();
            this.sendPropertyStatusChange();
          }
        }
      )
    } else {
      this.modelStatus.verificationStatus = '0';
      this.propertyService.propertyUpdateVerificationStatus(this.modelStatus).subscribe(
        data => {
          this.getVerificationStatus();
          if(this.isDeedTypeExchange){
            this.getOtherPartyVerificationStatus();
            this.sendPropertyStatusChange();
          }
        }
      )
    }
    this.checkStatus(true);
  }

  sendPropertyStatusChange(){
    this.propertyService.sendPropertyStatus();
  }

  loadBuilding(property: IProperty) {
    this.buildingList = property.buildings;
  }

  getValuationRequest(valuationRequestId: number) {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(valuationRequestId)
      .subscribe((res) => {
        this.valuationRequestExchange = res.data;
        this.propertyOneExchange = this.valuationRequestExchange.properties[0];
        this.assessmentExchange = this.valuationRequestExchange.properties[0];
        this.extendExchange = this.valuationRequestExchange.properties[0];
        this.otherExchange = this.valuationRequestExchange.properties[0];
        this.loanExchange = this.valuationRequestExchange.properties[0];
        this.buildingListUDExchange = this.valuationRequestExchange.properties[0];
      }));
  }

  public checkStatus(type:boolean): void{
    this.status1.emit(type);
  }
}
