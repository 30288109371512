import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SubSink} from 'subsink';
import {NotifierService} from 'angular-notifier';
import {DataService} from '../../../../../../../service/data.service';
import {PropertyUsageSubtypeService} from '../../../../../../../service/property-usage-subtype/property-usage-subtype.service';
import {PropertyUsageSubtype} from '../../../../../../../model/propertyUsageSubtype';
import {PropertyUsageService} from '../../../../../../../service/property-usage/property-usage.service';

@Component({
  selector: 'app-add-property-usage-subtype',
  templateUrl: './add-property-usage-subtype.component.html',
  styleUrls: ['./add-property-usage-subtype.component.scss']
})
export class AddPropertyUsageSubtypeComponent implements OnInit, OnDestroy {

  private sub = new SubSink();
  propertyUsages = [];
  addPropertyUsageSubtypeForm: FormGroup;

  constructor(private propertyUsageSubtypeService: PropertyUsageSubtypeService,
              private propertyUsageService: PropertyUsageService,
              private formBuilder: FormBuilder,
              private notifier: NotifierService,
              private dataService: DataService) { }

  ngOnInit() {
    this.sub.add(this.propertyUsageService.getPropertyUsages(0, 10).subscribe(
      (res) => {
        this.propertyUsages =  (res as any).data;
      }));

    this.addPropertyUsageSubtypeForm = this.formBuilder.group({
      description: ['', [Validators.required]],
      name: ['', [Validators.required]],
      propertyUsageId: ['', [Validators.required]]
    });
    this.addPropertyUsageSubtypeForm.controls.propertyUsageId.setValue('Choose', {onlySelf: true});
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  savePropertyUsageSubtype() {
    this.addPropertyUsageSubtypeForm.markAllAsTouched();
    if (this.addPropertyUsageSubtypeForm.valid) {
      const propertyUsageSubtype = new PropertyUsageSubtype();
      propertyUsageSubtype.description = this.getDescription();
      propertyUsageSubtype.name = this.getName();


      this.sub.add(this.propertyUsageSubtypeService.createPropertyUsageSubType(this.getPropertyUsageId(), propertyUsageSubtype)
        .subscribe(response => {
          this.notifier.notify( 'success', (response as any).message);
          this.dataService.updateTablePropertyUsageSubType(true);
        }));
    }
  }

  // getCode() {
  //   return this.addCompanyCategoryForm.get('code').value;
  // }

  getDescription() {
    return this.addPropertyUsageSubtypeForm.get('description').value;
  }
  getName() {
    return this.addPropertyUsageSubtypeForm.get('name').value;
  }
  getPropertyUsageId() {
    return this.addPropertyUsageSubtypeForm.get('propertyUsageId').value;
  }
}
