import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { GramaNiladariDivisionService } from 'src/app/service/grama-niladari-division/grama-niladari-division.service';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-delete-grama-niladari-division',
  templateUrl: './delete-grama-niladari-division.component.html',
  styleUrls: ['./delete-grama-niladari-division.component.scss']
})
export class DeleteGramaNiladariDivisionComponent implements OnInit, OnDestroy {

  private gndId: number;

  @Output() gndDeleted = new EventEmitter<boolean>();

  private sub = new SubSink();

  constructor(private gramaNiladariDivisionService: GramaNiladariDivisionService, private notifier: NotifierService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  deleteGramaNiladariDivision() {
    this.sub.add(this.gramaNiladariDivisionService.deleteGramaNiladariDivision(this.gndId).subscribe(response => {

      this.updateTheView(true);
      this.notifier.notify('success', (response as any).message);
    }));
  }

  updateTheView(deleted: boolean) {
    this.gndDeleted.emit(deleted);
  }

  setDistrictId(id: number) {
    this.gndId = id;
  }


}
