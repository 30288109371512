import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentTypeService } from '../../../../../../service/document-type/document-type.service';
import { DocumentType } from 'src/app/model/document-type';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-add-document-type',
  templateUrl: './add-document-type.component.html',
  styleUrls: ['./add-document-type.component.scss']
})
export class AddDocumentTypeComponent implements OnInit, OnDestroy {

  addDocumentTypeForm: FormGroup;
  private sub = new SubSink();

  constructor(private documentTypeService: DocumentTypeService,
              private formBuilder: FormBuilder,
              private notifier: NotifierService,
              private dataService: DataService) {
  }

  ngOnInit() {
    this.addDocumentTypeForm = this.formBuilder.group({
      description: ['', [Validators.required]],
      name: ['', [Validators.required]],
      code: ['', [Validators.required]]
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  saveDocumentType() {
    if (this.addDocumentTypeForm.valid) {

      const documentType = new DocumentType();

      documentType.description = this.getDescription();
      documentType.code = this.getCode();
      documentType.name = this.getName();
      this.sub.add(this.documentTypeService.createDocumentTYpe(documentType)
        .subscribe(response => {
          this.notifier.notify('success', (response as any).message);
          this.dataService.updateTableDocumentType(true);
        }));
    }
  }

  getCode() {
    return this.addDocumentTypeForm.get('code').value;
  }
  getDescription() {
    return this.addDocumentTypeForm.get('description').value;
  }
  getName() {
    return this.addDocumentTypeForm.get('name').value;
  }


}
