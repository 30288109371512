import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SubSink } from 'subsink';
import { Constant } from 'src/app/util/constant';
import { ValuationRequest } from 'src/app/model/valuation-request';
import { ValuationRequestService } from 'src/app/service/valuation-request/valuation-request.service';

@Component({
  selector: 'app-view-refund-side-navigation',
  templateUrl: './view-refund-side-navigation.component.html',
  styleUrls: ['./view-refund-side-navigation.component.scss']
})
export class ViewRefundSideNavigationComponent implements OnInit {
  selectSideNav;
  private sub = new SubSink();
  valuationRequest: ValuationRequest;

  userdata: any = [];

  permissiongranted: boolean;
  hasOpinion: any;
  withOpinion: boolean;
  showOpinionCalculation: boolean;

  // Subscription to monitor the navigation
  navigationSubscription;

  constructor(
    private route: ActivatedRoute,
    private valuationRequestService: ValuationRequestService,
    public router: Router
  ) {
    this.permissiongranted = false;
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.valuationRequest = new ValuationRequest();
  }

  ngOnInit() {
    this._prepare();
    this.checkOpinion();
  }

  _prepare() {
    this.checkPermission();
    const valuationRequestId = +this.route.snapshot.paramMap.get('id');
    this.valuationRequest.id = valuationRequestId;
  }

  public changeComponent(page: any): void {
    this.selectSideNav = page;
  }

  checkPermission() {
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.permissiongranted = true;
        this.selectSideNav = 1;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.permissiongranted = true;
        this.selectSideNav = 1;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.permissiongranted = true;
        this.selectSideNav = 1;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.permissiongranted = false;
        this.checkWithOpinionOrNot();
        break;
      } else if (role.code === Constant.ROLE_TAX_OFFICER) {
        this.permissiongranted = false;
        this.selectSideNav = 8;
        break;
      } else {
        this.permissiongranted = false;
        this.selectSideNav = 2;
        break;
      }
    }
  }

  checkOpinion() {
    this.hasOpinion = sessionStorage.getItem('hasOpinion');
    if (this.hasOpinion == 'true') {
      this.showOpinionCalculation = false;
      this.withOpinion = true;
    } else if (this.hasOpinion == 'false') {
      this.showOpinionCalculation = true;
      this.withOpinion = false;
    }
  }

  checkWithOpinionOrNot(){
    if(sessionStorage.getItem('hasOpinion') == 'true') {
      this.selectSideNav = 2;
    } else {
      this.selectSideNav = 3;
    }
  }


}
