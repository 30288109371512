import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NotifierService } from "angular-notifier";
import { DataModel } from "src/app/component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-calculation/models/data-model";
// import { DataModel } from "src/app/component/dashboards/dashboard-external/assesment/view-assesment-request/view-requests/view-calculation/models/data-model";

@Injectable({
  providedIn: "root"
})
export class CalculationDataProcess {
  selectedDataModel;
  isRefundRequest: string = 'false';
  refundRequestId: string = null;

  constructor(private http: HttpClient, private notifier: NotifierService) {}

  landAndBuildingDataProcessToSave(data, initialDataModel, update) {
    let calculationLandBuilding = [];
    if (initialDataModel.selectedNatureOfDeed == "gift") {
      calculationLandBuilding.push({
        calculationId: null,
        landRemark: data.fristSet.valueOfTheLandRemark,
        buildingRemark: data.fristSet.valueOfTheBuildingsRemark,
        deductionPrecentage: data.fristSet.deductionPrecentage,
        propertyValue: data.fristSet.valueOfTheProperty,
        valueOfTheBuilding: data.fristSet.valueOfTheBuildings,
        valueOfTheLand: data.fristSet.valueOfTheLand,
        improvements: data.fristSet.improvements
      });
    }

    calculationLandBuilding.push({
      calculationId: null,
      landRemark: data.secondSet.valueOfTheLandRemark,
      buildingRemark: data.secondSet.valueOfTheBuildingsRemark,
      deductionPrecentage: data.secondSet.deductionPrecentage,
      propertyValue: data.secondSet.valueOfTheProperty,
      lowestValue: data.secondSet.lowestValue,
      valueOfTheBuilding: data.secondSet.valueOfTheBuildings,
      valueOfTheLand: data.secondSet.valueOfTheLand,
      improvements: data.secondSet.improvements,
      stampDuty: data.secondSet.stampDuty,
      roundOffValue: data.secondSet.roundOffValue,
      previousDeedValue: data.secondSet.previousDeedValue,
      headingDate:data.secondSet.headingDate
    });

    let proceededData;

    this.isRefundRequest = sessionStorage.getItem('refundRequest');
    if(this.isRefundRequest == 'true'){
      // this.refundRequestId = '446';
      this.refundRequestId = localStorage.getItem('refundRequest');
    }

    if (update) {
      return this.updateLandBuildingDetail(data, initialDataModel);
    } else {
      proceededData = {
        natureOfDeed: initialDataModel.selectedNatureOfDeed,
        date: initialDataModel.selectedDate,
        considerCurrentDate: initialDataModel.considerCurrentDate,
        propertyType: initialDataModel.selectedPropertyType,
        referenceNo: initialDataModel.refNo,
        valueOfTheProperty: data.secondSet.lowestValue,
        stampDuty: data.secondSet.stampDuty,
        roundOffValue: data.secondSet.roundOffValue,
        calculationLandBuilding: calculationLandBuilding,
        refundRequestId: this.refundRequestId,
        headingDateOne:
          data.headingDateOne == "Invalid date" ? null : data.headingDateOne,
        headingDateTwo: data.headingDateTwo
      };

      return proceededData;
    }
  }

  updateLandBuildingDetail(data, initialDataModel) {
    console.log(data);
    if (initialDataModel.selectedNatureOfDeed == "gift") {
      this.selectedDataModel.calculationLandBuilding[0].landRemark =
        data.fristSet.valueOfTheLandRemark;
      this.selectedDataModel.calculationLandBuilding[0].buildingRemark =
        data.fristSet.valueOfTheBuildingsRemark;
      this.selectedDataModel.calculationLandBuilding[0].deductionPrecentage =
        data.fristSet.deductionPrecentage;
      this.selectedDataModel.calculationLandBuilding[0].propertyValue =
        data.fristSet.valueOfTheProperty;
      this.selectedDataModel.calculationLandBuilding[0].valueOfTheBuilding =
        data.fristSet.valueOfTheBuildings;
      this.selectedDataModel.calculationLandBuilding[0].valueOfTheLand =
        data.fristSet.valueOfTheLand;
      this.selectedDataModel.calculationLandBuilding[0].improvements =
        data.fristSet.improvements;

      this.selectedDataModel.calculationLandBuilding[1].landRemark =
        data.secondSet.valueOfTheLandRemark;
      this.selectedDataModel.calculationLandBuilding[1].buildingRemark =
        data.secondSet.valueOfTheBuildingsRemark;
      this.selectedDataModel.calculationLandBuilding[1].deductionPrecentage =
        data.secondSet.deductionPrecentage;
      this.selectedDataModel.calculationLandBuilding[1].propertyValue =
        data.secondSet.valueOfTheProperty;
      this.selectedDataModel.calculationLandBuilding[1].valueOfTheBuilding =
        data.secondSet.valueOfTheBuildings;
      this.selectedDataModel.calculationLandBuilding[1].valueOfTheLand =
        data.secondSet.valueOfTheLand;
      this.selectedDataModel.calculationLandBuilding[1].improvements =
        data.secondSet.improvements;
      this.selectedDataModel.calculationLandBuilding[1].stampDuty =
        data.secondSet.stampDuty;
      this.selectedDataModel.calculationLandBuilding[1].roundOffValue =
        data.secondSet.roundOffValue;
      this.selectedDataModel.calculationLandBuilding[1].previousDeedValue =
        data.secondSet.previousDeedValue;
    } else {
      this.selectedDataModel.calculationLandBuilding[0].landRemark =
        data.secondSet.valueOfTheLandRemark;
      this.selectedDataModel.calculationLandBuilding[0].buildingRemark =
        data.secondSet.valueOfTheBuildingsRemark;
      this.selectedDataModel.calculationLandBuilding[0].deductionPrecentage =
        data.secondSet.deductionPrecentage;
      this.selectedDataModel.calculationLandBuilding[0].propertyValue =
        data.secondSet.valueOfTheProperty;
      this.selectedDataModel.calculationLandBuilding[0].valueOfTheBuilding =
        data.secondSet.valueOfTheBuildings;
      this.selectedDataModel.calculationLandBuilding[0].valueOfTheLand =
        data.secondSet.valueOfTheLand;
      this.selectedDataModel.calculationLandBuilding[0].improvements =
        data.secondSet.improvements;
      this.selectedDataModel.calculationLandBuilding[0].stampDuty =
        data.secondSet.stampDuty;
      this.selectedDataModel.calculationLandBuilding[0].roundOffValue =
        data.secondSet.roundOffValue;
      this.selectedDataModel.calculationLandBuilding[0].previousDeedValue =
        data.secondSet.previousDeedValue;
    }

    this.selectedDataModel.stampDuty = data.secondSet.stampDuty;
    this.selectedDataModel.valueOfTheProperty =
      data.secondSet.valueOfTheProperty;

    this.selectedDataModel.natureOfDeed = initialDataModel.selectedNatureOfDeed;
    this.selectedDataModel.date = initialDataModel.selectedDate;
    this.selectedDataModel.considerCurrentDate = initialDataModel.considerCurrentDate;
    this.selectedDataModel.propertyType = initialDataModel.selectedPropertyType;
    this.selectedDataModel.referenceNo = initialDataModel.refNo;
    this.selectedDataModel.valueOfTheProperty = data.secondSet.lowestValue;
    this.selectedDataModel.stampDuty = data.secondSet.stampDuty;

    return this.selectedDataModel;

  }

  condominiumDataProcessToSave(data, initialDataModel, update) {
    let calculationCondominiums = [];
    if (initialDataModel.selectedNatureOfDeed == "gift") {
    calculationCondominiums.push({
      calculationId: null,
      valueType: data.fristSet.valueType,
      rentPerSqft: data.fristSet.rentPerSqFt,
      extent: data.fristSet.extent,
      deductionPrecentage: data.fristSet.deductionPrecentage,
      yp: data.fristSet.yp,
      rateOfReturn: data.fristSet.rateOfReturn,
      stampDuty: data.secondSet.stampDuty,
      roundOffValue: data.secondSet.roundOffValue,
      total: data.fristSet.total,
      valueOfTheProperty: data.fristSet.valueOfTheProperty,
      improvements: data.fristSet.improvements
    });
  }

    calculationCondominiums.push({
      calculationId: null,
      rentPerSqft: data.secondSet.rentPerSqFt,
      extent: data.secondSet.extent,
      deductionPrecentage: data.secondSet.deductionPrecentage,
      yp: data.secondSet.yp,
      rateOfReturn: data.secondSet.rateOfReturn,
      improvements: data.secondSet.improvements,
      valueOfTheProperty: data.secondSet.valueOfTheProperty,
      lowestValue: data.secondSet.lowestValue,
      stampDuty: data.secondSet.stampDuty,
      roundOffValue: data.secondSet.roundOffValue,
      decisionOfCommitee: data.secondSet.decisionOfCommitte,
      previousDeedValue: data.secondSet.previousDeedValue,
      headingDate:data.secondSet.headingDate,
      valueType:data.secondSet.valueType
    });

    let proceededData;

    this.isRefundRequest = sessionStorage.getItem('refundRequest');
    if(this.isRefundRequest == 'true'){
      this.refundRequestId = localStorage.getItem('refundRequest');
    }

    if (update) {
      return this.updateCondominiumDetails(data, initialDataModel);
    } else {
      proceededData = {
        natureOfDeed: initialDataModel.selectedNatureOfDeed,
        date: initialDataModel.selectedDate,
        considerCurrentDate: initialDataModel.considerCurrentDate,
        propertyType: initialDataModel.selectedPropertyType,
        referenceNo: initialDataModel.refNo,
        valueOfTheProperty: data.secondSet.lowestValue,
        stampDuty: data.secondSet.stampDuty,
        roundOffValue: data.secondSet.roundOffValue,
        calculationCondominiums: calculationCondominiums,
        refundRequestId: this.refundRequestId,
        headingDateOne:
          data.headingDateOne == "Invalid date" ? null : data.headingDateOne,
        headingDateTwo: data.headingDateTwo
      };

      return proceededData;
    }
  }

  updateCondominiumDetails(data, initialDataModel) {
    console.log(this.selectedDataModel);
    if (initialDataModel.selectedNatureOfDeed == "gift") {
      this.selectedDataModel.calculationCondominiums[0].valueType =
        data.fristSet.valueType;
      this.selectedDataModel.calculationCondominiums[0].rentPerSqft =
        data.fristSet.rentPerSqFt;
      this.selectedDataModel.calculationCondominiums[0].extent =
        data.fristSet.extent;
      this.selectedDataModel.calculationCondominiums[0].deductionPrecentage =
        data.fristSet.deductionPrecentage;
      this.selectedDataModel.calculationCondominiums[0].yp = data.fristSet.yp;
      this.selectedDataModel.calculationCondominiums[0].improvements =
        data.fristSet.improvements;
      this.selectedDataModel.calculationCondominiums[0].total =
        data.fristSet.total;
      this.selectedDataModel.calculationCondominiums[0].valueOfTheProperty =
        data.fristSet.valueOfTheProperty;

      this.selectedDataModel.calculationCondominiums[0].valueType =
        data.secondSet.valueType;
      this.selectedDataModel.calculationCondominiums[1].rentPerSqft =
        data.secondSet.rentPerSqFt;
      this.selectedDataModel.calculationCondominiums[1].extent =
        data.secondSet.extent;
      this.selectedDataModel.calculationCondominiums[1].deductionPrecentage =
        data.secondSet.deductionPrecentage;
      this.selectedDataModel.calculationCondominiums[1].yp = data.secondSet.yp;
      this.selectedDataModel.calculationCondominiums[1].total =
        data.secondSet.total;
      this.selectedDataModel.calculationCondominiums[1].valueOfTheProperty =
        data.secondSet.valueOfTheProperty;
      this.selectedDataModel.calculationCondominiums[1].improvements =
        data.secondSet.improvements;
      this.selectedDataModel.calculationCondominiums[1].roundOffValue =
        data.secondSet.roundOffValue;
      this.selectedDataModel.calculationCondominiums[1].stampDuty =
        data.secondSet.stampDuty;
      this.selectedDataModel.calculationCondominiums[1].decisionOfCommitte =
        data.secondSet.decisionOfCommitte;
      this.selectedDataModel.calculationCondominiums[1].previousDeedValue =
        data.secondSet.previousDeedValue;
    } else {
      this.selectedDataModel.calculationCondominiums[0].valueType =
        data.secondSet.valueType;
      this.selectedDataModel.calculationCondominiums[0].rentPerSqft =
        data.secondSet.rentPerSqFt;
      this.selectedDataModel.calculationCondominiums[0].extent =
        data.secondSet.extent;
      this.selectedDataModel.calculationCondominiums[0].deductionPrecentage =
        data.secondSet.deductionPrecentage;
      this.selectedDataModel.calculationCondominiums[0].yp = data.secondSet.yp;
      this.selectedDataModel.calculationCondominiums[0].total =
        data.secondSet.total;
      this.selectedDataModel.calculationCondominiums[0].valueOfTheProperty =
        data.secondSet.valueOfTheProperty;
      this.selectedDataModel.calculationCondominiums[0].improvements =
        data.secondSet.improvements;
      this.selectedDataModel.calculationCondominiums[0].roundOffValue =
        data.secondSet.roundOffValue;
      this.selectedDataModel.calculationCondominiums[0].stampDuty =
        data.secondSet.stampDuty;
      this.selectedDataModel.calculationCondominiums[0].decisionOfCommitte =
        data.secondSet.decisionOfCommitte;

      this.selectedDataModel.calculationCondominiums[0].previousDeedValue =
        data.secondSet.previousDeedValue;
    }

    this.selectedDataModel.stampDuty = data.secondSet.stampDuty;
    this.selectedDataModel.valueOfTheProperty =
      data.secondSet.valueOfTheProperty;

    return this.selectedDataModel;
  }

  landDataProcessToSave(data, initialDataModel, update) {
    let calculationLand = [];
    if (initialDataModel.selectedNatureOfDeed == "gift") {
      calculationLand.push({
        calculationId: null,
        landRemark: data.fristSet.valueOfTheLandRemark,
        deductionPrecentage: data.fristSet.deductionPrecentage,
        propertyValue: data.fristSet.valueOfTheProperty,
        valueOfTheLand: data.fristSet.valueOfTheLand,
        improvements: data.fristSet.improvements
      });
    }

    calculationLand.push({
      calculationId: null,
      landRemark: data.secondSet.valueOfTheLandRemark,
      propertyValue: data.secondSet.valueOfTheProperty,
      lowestValue: data.secondSet.lowestValue,
      valueOfTheLand: data.secondSet.valueOfTheLand,
      improvements: data.fristSet.improvements,
      stampDuty: data.secondSet.stampDuty,
      roundOffValue: data.secondSet.roundOffValue,
      previousDeedValue: data.secondSet.previousDeedValue,
      headingDate:data.secondSet.headingDate
    });

    let proceededData;

    this.isRefundRequest = sessionStorage.getItem('refundRequest');
    if(this.isRefundRequest == 'true'){
      this.refundRequestId = localStorage.getItem('refundRequest');
    }

    if (update) {
      return this.updateLandDetail(data, initialDataModel);
    } else {
      proceededData = {
        natureOfDeed: initialDataModel.selectedNatureOfDeed,
        date: initialDataModel.selectedDate,
        considerCurrentDate: initialDataModel.considerCurrentDate,
        propertyType: initialDataModel.selectedPropertyType,
        referenceNo: initialDataModel.refNo,
        valueOfTheProperty: data.secondSet.lowestValue,
        improvements: data.fristSet.improvements,
        stampDuty: data.secondSet.stampDuty,
        roundOffValue: data.secondSet.roundOffValue,
        calculationLand: calculationLand,
        refundRequestId: this.refundRequestId,
        headingDateOne:
          data.headingDateOne == "Invalid date" ? null : data.headingDateOne,
        headingDateTwo: data.headingDateTwo
      };

      console.log(proceededData)
      return proceededData;
    }
  }

  landAndBuildingDataProcessToView(data) {
    this.selectedDataModel = data;
    let buildingValueTotalFirst = 0;
    let buildingValueTotalSecond = 0;
    let landValueTotalFirst = 0;
    let landValueTotalSecond = 0;
    let improvementTotal = 0;
    let improvementTotal_ = 0;

    if (data.calculationLandBuilding[0].valueOfTheBuilding.length > 0) {
      data.calculationLandBuilding[0].valueOfTheBuilding.forEach(element => {
        buildingValueTotalFirst = parseFloat(
          buildingValueTotalFirst +
            element.contractionCost +
            element.constructionCost
        );
      });
    } else {
      data.calculationLandBuilding[0].valueOfTheBuilding.push({
        id: null,
        buildingNo: null,
        floorNo: null,
        extent: null,
        constructionCost: null,
        contractionCost: null
      });
    }

    if (data.calculationLandBuilding.length > 1) {
      if (data.calculationLandBuilding[1].valueOfTheBuilding.length > 0) {
        data.calculationLandBuilding[1].valueOfTheBuilding.forEach(element => {
          buildingValueTotalSecond = parseFloat(
            buildingValueTotalSecond +
              element.contractionCost +
              element.constructionCost
          );
        });
      } else {
        data.calculationLandBuilding[1].valueOfTheBuilding.push({
          id: null,
          buildingNo: null,
          floorNo: null,
          extent: null,
          constructionCost: null,
          contractionCost: null
        });
      }
    }

    if (data.calculationLandBuilding[0].valueOfTheLand.length > 0) {
      data.calculationLandBuilding[0].valueOfTheLand.forEach(element => {
        landValueTotalFirst = parseFloat(landValueTotalFirst + element.value);
      });
    } else {
      data.calculationLandBuilding[0].valueOfTheLand.push({
        landExtend: null,
        perPerchValue: null,
        value: null
      });
    }

    if (data.calculationLandBuilding.length > 1) {
      if (data.calculationLandBuilding[1].valueOfTheLand.length > 0) {
        data.calculationLandBuilding[1].valueOfTheLand.forEach(element => {
          landValueTotalSecond = parseFloat(
            landValueTotalSecond + element.value
          );
        });
      } else {
        data.calculationLandBuilding[1].valueOfTheLand.push({
          landExtend: null,
          perPerchValue: null,
          value: null
        });
      }
    }

    if (data.calculationLandBuilding[0].improvements.length > 0) {
      data.calculationLandBuilding[0].improvements.forEach(element => {
        improvementTotal = parseFloat(improvementTotal + element.value);
      });
    } else {
      data.calculationLandBuilding[0].improvements.push({
        description: null,
        extent: null,
        rentPerSqFt: null,
        value: null
      });
    }

    if (data.calculationLandBuilding.length > 1) {
      if (data.calculationLandBuilding[1].improvements.length > 0) {
        data.calculationLandBuilding[1].improvements.forEach(element => {
          improvementTotal_ = parseFloat(improvementTotal + element.value);
        });
      } else {
        data.calculationLandBuilding[1].improvements.push({
          description: null,
          extent: null,
          rentPerSqFt: null,
          value: null
        });
      }
    }

    let dataModel = new DataModel();

    if (data.natureOfDeed == "gift") {
      dataModel.fristSet.id = data.calculationLandBuilding[0].id;
      dataModel.fristSet.valueOfTheLand =
        data.calculationLandBuilding[0].valueOfTheLand;
      dataModel.fristSet.valueOfTheLandRemark =
        data.calculationLandBuilding[0].landRemark;
      dataModel.fristSet.valueOfTheBuildings =
        data.calculationLandBuilding[0].valueOfTheBuilding;
      dataModel.fristSet.valueOfTheBuildingsRemark =
        data.calculationLandBuilding[0].buildingRemark;
      dataModel.fristSet.deductionPrecentage =
        data.calculationLandBuilding[0].deductionPrecentage;
      dataModel.fristSet.depreciation = null;
      dataModel.fristSet.depreciationContruction = null;
      dataModel.fristSet.improvements =
        data.calculationLandBuilding[0].improvements;
      dataModel.fristSet.total = improvementTotal;
      dataModel.fristSet.valueOfTheProperty = null;
      dataModel.fristSet.marketValue = null;
      dataModel.headingDateOne = data.headingDateOne;

      dataModel.headingDateTwo = data.headingDateTwo;
      dataModel.secondSet.id = data.calculationLandBuilding[1].id;
      dataModel.secondSet.valueOfTheLand =
        data.calculationLandBuilding[1].valueOfTheLand;
      dataModel.secondSet.valueOfTheLandRemark =
        data.calculationLandBuilding[1].landRemark;
      dataModel.secondSet.valueOfTheBuildings =
        data.calculationLandBuilding[1].valueOfTheBuilding;
      dataModel.secondSet.valueOfTheBuildingsRemark =
        data.calculationLandBuilding[1].buildingRemark;
      dataModel.secondSet.improvements =
        data.calculationLandBuilding[1].improvements;
      dataModel.secondSet.total = improvementTotal;
      dataModel.secondSet.deductionPrecentage =
        data.calculationLandBuilding[1].deductionPrecentage;
      dataModel.secondSet.depreciation = null;
      dataModel.secondSet.depreciationContruction = null;
      dataModel.secondSet.valueOfTheProperty = null;
      dataModel.secondSet.marketValue = null;
      dataModel.secondSet.lowestValue = null;
      dataModel.secondSet.previousDeedValue = null;
      dataModel.secondSet.stampDuty = null;
      dataModel.secondSet.roundOffValue =
        data.calculationLandBuilding[1].roundOffValue;
      dataModel.secondSet.previousDeedValue =
        data.calculationLandBuilding[1].previousDeedValue;
    } else {
      dataModel.fristSet.id = null;
      dataModel.fristSet.valueOfTheLand = null;
      dataModel.fristSet.valueOfTheLandRemark = null;
      dataModel.fristSet.valueOfTheBuildings = null;
      dataModel.fristSet.valueOfTheBuildingsRemark = null;
      dataModel.fristSet.deductionPrecentag = null;
      dataModel.fristSet.depreciation = null;
      dataModel.fristSet.depreciationContruction = null;
      dataModel.fristSet.improvements = null;
      dataModel.fristSet.total = null;
      dataModel.fristSet.valueOfTheProperty = null;
      dataModel.fristSet.marketValue = null;

      dataModel.secondSet.id = data.calculationLandBuilding[0].id;
      dataModel.secondSet.valueOfTheLand =
        data.calculationLandBuilding[0].valueOfTheLand;
      dataModel.secondSet.valueOfTheLandRemark =
        data.calculationLandBuilding[0].landRemark;
        dataModel.secondSet.valueOfTheBuildingsRemark =
        data.calculationLandBuilding[0].buildingRemark;
      dataModel.secondSet.valueOfTheBuildings =
        data.calculationLandBuilding[0].valueOfTheBuilding;
      dataModel.secondSet.improvements =
        data.calculationLandBuilding[0].improvements;
      dataModel.secondSet.total = improvementTotal;
      dataModel.secondSet.deductionPrecentage =
        data.calculationLandBuilding[0].deductionPrecentage;
      dataModel.secondSet.depreciation = null;
      dataModel.secondSet.depreciationContruction = null;
      dataModel.secondSet.valueOfTheProperty = null;
      dataModel.secondSet.marketValue = null;
      dataModel.secondSet.lowestValue = null;
      dataModel.secondSet.previousDeedValue = null;
      dataModel.secondSet.stampDuty = null;
      dataModel.secondSet.roundOffValue =
        data.calculationLandBuilding[0].roundOffValue;
      dataModel.secondSet.previousDeedValue =
        data.calculationLandBuilding[0].previousDeedValue;
      dataModel.headingDateTwo = data.headingDateTwo;
    }

    return dataModel;
  }

  condominiumDataProcessToView(data) {
    this.selectedDataModel = data;
    console.log(data);
    let improvementTotal = 0;
    let improvementTotal_ = 0;
    let dataModel = new DataModel();
    if (data.natureOfDeed == "gift") {
      if (data.calculationCondominiums[0].improvements.length > 0) {
        data.calculationCondominiums[0].improvements.forEach(element => {
          improvementTotal = parseFloat(improvementTotal + element.value);
        });
        data.calculationCondominiums[1].improvements.forEach(element => {
          improvementTotal_ = parseFloat(improvementTotal_ + element.value);
        });
      } else {
        data.calculationCondominiums[0].improvements.push({
          description: null,
          extent: null,
          rentPerSqFt: null,
          value: null
        });
      }

      dataModel.fristSet.valueType = data.calculationCondominiums[0].valueType;
      dataModel.fristSet.rentPerSqFt =
        data.calculationCondominiums[0].rentPerSqft;
      dataModel.fristSet.extent = data.calculationCondominiums[0].extent;
      dataModel.fristSet.monthlyRent = null;
      dataModel.fristSet.annualRent = null;
      dataModel.fristSet.deductionPrecentage =
        data.calculationCondominiums[0].deductionPrecentage;
      dataModel.fristSet.deductionOutgoing = null;
      dataModel.fristSet.annualIncome = null;
      dataModel.fristSet.marketValue = null;
      dataModel.fristSet.yp = data.calculationCondominiums[0].yp;

      dataModel.fristSet.externalTotal = null;
      dataModel.fristSet.improvements =
        data.calculationCondominiums[0].improvements;
      dataModel.fristSet.total = improvementTotal;
      dataModel.fristSet.valueOfTheProperty =
        data.calculationCondominiums[0].valueOfTheProperty;
      dataModel.headingDateOne = data.headingDateOne;

      dataModel.headingDateTwo = data.headingDateTwo;
      dataModel.secondSet.valueType = data.calculationCondominiums[0].valueType;
      dataModel.secondSet.rentPerSqFt =
        data.calculationCondominiums[1].rentPerSqft;
      dataModel.secondSet.extent = data.calculationCondominiums[1].extent;
      dataModel.secondSet.monthlyRent = null;
      dataModel.secondSet.annualRent = null;
      dataModel.secondSet.deductionPrecentage =
        data.calculationCondominiums[1].deductionPrecentage;
      dataModel.secondSet.deductionOutgoing = null;
      dataModel.secondSet.annualIncome = null;
      dataModel.secondSet.marketValue = null;
      dataModel.secondSet.externalTotal = null;
      dataModel.secondSet.valueOfTheProperty =
        data.calculationCondominiums[1].valueOfTheProperty;
      dataModel.secondSet.stampDuty = data.calculationCondominiums[1].stampDuty;
      dataModel.secondSet.roundOffValue = data.calculationCondominiums[1].roundOffValue;
      dataModel.secondSet.previousDeedValue = null;
      dataModel.secondSet.yp = data.calculationCondominiums[1].yp;
      dataModel.secondSet.rateOfReturn =
        100 / data.calculationCondominiums[1].yp;
      dataModel.secondSet.total = improvementTotal_;
      dataModel.secondSet.improvements =
        data.calculationCondominiums[1].improvements;
      dataModel.secondSet.decisionOfCommitee =
        data.calculationCondominiums[1].decisionOfCommitte;
      dataModel.secondSet.previousDeedValue =
        data.calculationCondominiums[1].previousDeedValue;
    } else {
      dataModel.secondSet.valueType = data.calculationCondominiums[0].valueType;
      dataModel.secondSet.rentPerSqFt =
        data.calculationCondominiums[0].rentPerSqft;
      dataModel.secondSet.extent = data.calculationCondominiums[0].extent;
      dataModel.secondSet.monthlyRent = null;
      dataModel.secondSet.annualRent = null;
      dataModel.secondSet.deductionPrecentage =
        data.calculationCondominiums[0].deductionPrecentage;
      dataModel.secondSet.deductionOutgoing = null;
      dataModel.secondSet.annualIncome = null;
      dataModel.secondSet.marketValue = null;
      dataModel.secondSet.externalTotal = null;
      dataModel.secondSet.valueOfTheProperty =
        data.calculationCondominiums[0].valueOfTheProperty;
      dataModel.secondSet.stampDuty = data.calculationCondominiums[0].stampDuty;
      dataModel.secondSet.roundOffValue =
        data.calculationCondominiums[0].roundOffValue;
      dataModel.secondSet.previousDeedValue = null;
      dataModel.secondSet.yp = data.calculationCondominiums[0].yp;
      dataModel.secondSet.improvements =
        data.calculationCondominiums[0].improvements;
      dataModel.secondSet.total = improvementTotal;
      dataModel.secondSet.rateOfReturn =
        100 / data.calculationCondominiums[0].yp;
      dataModel.secondSet.decisionOfCommitee =
        data.calculationCondominiums[0].decisionOfCommitte;
      dataModel.secondSet.previousDeedValue =
        data.calculationCondominiums[0].previousDeedValue;
      dataModel.headingDateTwo = data.headingDateTwo;
    }

    return dataModel;
  }

  landProcessToView(data) {
    let dataModel = new DataModel();

    this.selectedDataModel = data;
    let landValueTotalFirst: any = 0;
    let landValueTotalSecond: any = 0;
    let improvementTotal: any = 0;
    let improvementTotal_: any = 0;

    if (data.calculationLand[0].valueOfTheLand.length > 0) {
      data.calculationLand[0].valueOfTheLand.forEach(element => {
        landValueTotalFirst = (
          Number(landValueTotalFirst) + Number(element.value)
        ).toFixed(2);
      });
    } else {
      data.calculationLand[0].valueOfTheLand.push({
        landExtend: null,
        perPerchValue: null,
        value: null
      });
    }

    if (data.calculationLand.length > 1) {
      if (data.calculationLand[1].valueOfTheLand.length > 0) {
        data.calculationLand[1].valueOfTheLand.forEach(element => {
          landValueTotalSecond = (
            Number(landValueTotalSecond) + Number(element.value)
          ).toFixed(2);
        });
      } else {
        data.calculationLand[1].valueOfTheLand.push({
          landExtend: null,
          perPerchValue: null,
          value: null
        });
      }
    }

    if (data.calculationLand[0].improvements.length > 0) {
      data.calculationLand[0].improvements.forEach(element => {
        improvementTotal = (
          Number(improvementTotal) + Number(element.value)
        ).toFixed(2);
      });
    } else {
      data.calculationLand[0].improvements.push({
        description: null,
        extent: null,
        rentPerSqFt: null,
        value: null
      });
    }

    if (
      data.calculationLand[1] != undefined &&
      data.calculationLand[1].improvements.length > 0
      ) {
      data.calculationLand[1].improvements.forEach(element => {
        improvementTotal_ = (
          Number(improvementTotal_) + Number(element.value)
        ).toFixed(2);
      });
    } else {
      if (data.calculationLand[1] != undefined) {
        data.calculationLand[1].improvements.push({
          description: null,
          extent: null,
          rentPerSqFt: null,
          value: null
        });
      }
    }
    if (data.natureOfDeed == "gift") {
      dataModel.fristSet.id = data.calculationLand[0].id;
      dataModel.fristSet.valueOfTheLand =
        data.calculationLand[0].valueOfTheLand;
      dataModel.fristSet.valueOfTheLandRemark =
        data.calculationLand[0].landRemark;
      dataModel.fristSet.deductionPrecentag =
        data.calculationLand[0].deductionPrecentage;
      dataModel.fristSet.improvements = data.calculationLand[0].improvements;
      dataModel.fristSet.total = improvementTotal;
      dataModel.fristSet.valueOfTheProperty = null;
      dataModel.fristSet.marketValue = null;
      dataModel.headingDateOne = data.headingDateOne;

      dataModel.headingDateTwo = data.headingDateTwo;
      dataModel.secondSet.id = data.calculationLand[1].id;
      dataModel.secondSet.valueOfTheLand =
        data.calculationLand[1].valueOfTheLand;
      dataModel.secondSet.valueOfTheLandRemark =
        data.calculationLand[1].landRemark;
      dataModel.secondSet.improvements = data.calculationLand[1].improvements;
      dataModel.secondSet.total = improvementTotal_;
      dataModel.secondSet.valueOfTheProperty = null;
      dataModel.secondSet.marketValue = null;
      dataModel.secondSet.lowestValue = null;
      dataModel.secondSet.previousDeedValue = null;
      dataModel.secondSet.stampDuty = null;
      dataModel.secondSet.roundOffValue = data.calculationLand[1].roundOffValue;
      dataModel.secondSet.previousDeedValue =
        data.calculationLand[1].previousDeedValue;
    } else {
      dataModel.fristSet.id = null;
      dataModel.fristSet.valueOfTheLand = null;
      dataModel.fristSet.valueOfTheLandRemark = null;
      dataModel.fristSet.deductionPrecentag = null;
      dataModel.fristSet.improvements = null;
      dataModel.fristSet.total = null;
      dataModel.fristSet.valueOfTheProperty = null;
      dataModel.fristSet.marketValue = null;

      dataModel.secondSet.id = data.calculationLand[0].id;
      dataModel.secondSet.valueOfTheLand =
        data.calculationLand[0].valueOfTheLand;
      dataModel.secondSet.valueOfTheLandRemark =
        data.calculationLand[0].landRemark;
      dataModel.secondSet.improvements = data.calculationLand[0].improvements;
      dataModel.secondSet.total = improvementTotal_;
      dataModel.secondSet.valueOfTheProperty = null;
      dataModel.secondSet.marketValue = null;
      dataModel.secondSet.lowestValue = null;
      dataModel.secondSet.previousDeedValue = null;
      dataModel.secondSet.stampDuty = null;
      dataModel.secondSet.roundOffValue = data.calculationLand[0].roundOffValue;
      dataModel.secondSet.previousDeedValue =
        data.calculationLand[0].previousDeedValue;
      dataModel.headingDateTwo = data.headingDateTwo;
    }

    return dataModel;
  }

  updateLandDetail(data, initialDataModel) {
    if (initialDataModel.selectedNatureOfDeed == "gift") {
      this.selectedDataModel.calculationLand[0].landRemark =
        data.fristSet.valueOfTheLandRemark;
      this.selectedDataModel.calculationLand[0].propertyValue =
        data.fristSet.valueOfTheProperty;
      this.selectedDataModel.calculationLand[0].valueOfTheLand =
        data.fristSet.valueOfTheLand;
      this.selectedDataModel.calculationLand[0].improvements =
        data.fristSet.improvements;
      (this.selectedDataModel.headingDateOne =
        data.headingDateOne == "Invalid date" ? null : data.headingDateOne),
        (this.selectedDataModel.calculationLand[1].landRemark =
        data.secondSet.valueOfTheLandRemark);
      this.selectedDataModel.calculationLand[1].propertyValue =
        data.secondSet.valueOfTheProperty;
      this.selectedDataModel.calculationLand[1].valueOfTheLand =
        data.secondSet.valueOfTheLand;
      this.selectedDataModel.calculationLand[1].improvements =
        data.secondSet.improvements;
      this.selectedDataModel.calculationLand[1].stampDuty =
        data.secondSet.stampDuty;
      this.selectedDataModel.calculationLand[1].roundOffValue =
        data.secondSet.roundOffValue;
      this.selectedDataModel.headingDateTwo = data.headingDateTwo;
      this.selectedDataModel.calculationLand[1].previousDeedValue =
        data.secondSet.previousDeedValue;
    } else {
      this.selectedDataModel.calculationLand[0].landRemark =
        data.secondSet.valueOfTheLandRemark;
      this.selectedDataModel.calculationLand[0].propertyValue =
        data.secondSet.valueOfTheProperty;
      this.selectedDataModel.calculationLand[0].valueOfTheLand =
        data.secondSet.valueOfTheLand;
      this.selectedDataModel.calculationLand[0].improvements =
        data.secondSet.improvements;
      this.selectedDataModel.calculationLand[0].stampDuty =
        data.secondSet.stampDuty;
      this.selectedDataModel.calculationLand[0].roundOffValue =
        data.secondSet.roundOffValue;
      this.selectedDataModel.headingDateOne = data.headingDateTwo;
      this.selectedDataModel.calculationLand[0].previousDeedValue =
        data.secondSet.previousDeedValue;
    }

    this.selectedDataModel.stampDuty = data.secondSet.stampDuty;
    this.selectedDataModel.valueOfTheProperty =
      data.secondSet.valueOfTheProperty;

    return this.selectedDataModel;
  }

  processExchangeCondominiumData(data) {
    let dataModel = new DataModel();
    dataModel.secondSet.valueType = data.get("valueType").value;
    dataModel.secondSet.rentPerSqFt = data.get("rentPerSqFt").value;

    dataModel.secondSet.extent = data.get("extent").value;

    dataModel.secondSet.monthlyRent = data.get("monthlyRent").value;

    dataModel.secondSet.annualRent = data.get("annualRent").value;

    dataModel.secondSet.deductionPrecentage = data.get(
      "deductionPrecentage"
    ).value;
    dataModel.secondSet.deductionOutgoing = data.get("deductionOutgoing").value;
    dataModel.secondSet.annualIncome = data.get("annualIncome").value;

    dataModel.secondSet.marketValue = data.get("marketValue").value;

    dataModel.secondSet.yp = data.get("yp").value;

    // dataModel.secondSet.rateOfReturn = data.get(
    //   "rateOfReturn"
    // ).value;
    dataModel.secondSet.valueOfTheProperty = data.get(
      "valueOfTheProperty"
    ).value;
    dataModel.secondSet.previousDeedValue = data.get("previousDeedValue").value;
    dataModel.secondSet.decisionOfCommitee = data.get(
      "decisionOfCommitee"
    ).value;
    dataModel.secondSet.improvements = data.get("improvements").value;
    dataModel.secondSet.headingDate = data.get("headingDate").value;

    return dataModel;
  }

  processExchangeLandData(data) {
    let dataModel = new DataModel();
    dataModel.secondSet.valueOfTheLandRemark = data.get(
      "valueOfTheLandRemark"
    ).value;
    dataModel.secondSet.valueOfTheProperty = data.get(
      "valueOfTheProperty"
    ).value;
    dataModel.secondSet.valueOfTheLand = data.get("valueOfTheLand").value;
    dataModel.secondSet.improvements = data.get("improvements").value;
    dataModel.secondSet.stampDuty = 0;
    dataModel.secondSet.roundOffValue = 0;
    dataModel.secondSet.previousDeedValue = data.get("previousDeedValue").value;
    dataModel.secondSet.total = data.get("total").value;
    dataModel.secondSet.headingDate = data.get("headingDate").value;
      console.log(dataModel)
    return dataModel;
  }

  processExchangeLandBuildingData(data) {
    let dataModel = new DataModel();
    dataModel.secondSet.valueOfTheLand = data.get("valueOfTheLand").value;
    dataModel.secondSet.valueOfTheBuildings = data.get(
      "valueOfTheBuildings"
    ).value;
    dataModel.secondSet.improvements = data.get("improvements").value;
    dataModel.secondSet.valueOfTheLandRemark = data.get(
      "valueOfTheLandRemark"
    ).value;
    dataModel.secondSet.valueOfTheBuildingsRemark = data.get(
      "valueOfTheBuildingsRemark"
    ).value;
    dataModel.secondSet.total = data.get("total").value;
    dataModel.secondSet.deductionPrecentage = data.get(
      "deductionPrecentage"
    ).value;
    dataModel.secondSet.depreciation = data.get("depreciation").value;
    dataModel.secondSet.depreciationContruction = data.get(
      "depreciationContruction"
    ).value;
    dataModel.secondSet.valueOfTheProperty = data.get(
      "valueOfTheProperty"
    ).value;
    dataModel.secondSet.marketValue = data.get("marketValue").value;
    dataModel.secondSet.previousDeedValue = data.get("previousDeedValue").value;
    dataModel.secondSet.headingDate = data.get("headingDate").value;

    return dataModel;
  }

  setUpdateDataToService(data) {
    this.selectedDataModel = data;
  }
}
