import { Component, OnDestroy,EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { passwordPolicy } from 'src/app/model/passwordPolicy';
import { PasswordPolicyService } from 'src/app/service/passwordPolicy/password-policy.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-update-password-policy',
  templateUrl: './update-password-policy.component.html',
  styleUrls: ['./update-password-policy.component.scss']
})
export class UpdatePasswordPolicyComponent implements OnInit,OnDestroy {

  @Output() updatePasswordPolicyEvent= new EventEmitter<boolean>();

  updatePasswordPolicyForm:FormGroup;
  activationButton = false;


  public passwordPolicyModel = new passwordPolicy(null,'','','','','','','','','');
  passwordPolicyPattern ='^(?=(.*[A-Z]){$7@})(?=(.*[0-9]){$1@})(?=(.*[#$@!%&*?]){$3@})[A-Za-z0-9#$@!%&*?]{$5@,$6@}$';

  constructor(private formBuilder: FormBuilder,private notifier: NotifierService,private passwordPolicyService:PasswordPolicyService) {
  }

  private sub = new SubSink();


  ngOnInit() {
    this.updatePasswordPolicyForm = this.formBuilder.group({
      id:['',[Validators.required]],
      minLength:['',[Validators.required]],
      maxLength:['',[Validators.required]],
      minNumaric:['',[Validators.required]],
      // maxNumaric:[''],
      minSpecial:['',[Validators.required]],
      // maxSpecial:[''],
      minUpperChar: ['', [Validators.required]],
    });
  }

  //get data from view component
  setPasswordPolicy(passwordPolicy:any){
    this.updatePasswordPolicyForm.get('id').setValue(passwordPolicy.id);
    this.updatePasswordPolicyForm.get('minLength').setValue(passwordPolicy.minLength);
    this.updatePasswordPolicyForm.get('maxLength').setValue(passwordPolicy.maxLength);
    this.updatePasswordPolicyForm.get('minNumaric').setValue(passwordPolicy.minNumaric);
    // this.updatePasswordPolicyForm.get('maxNumaric').setValue(passwordPolicy.maxNumaric);
    this.updatePasswordPolicyForm.get('minSpecial').setValue(passwordPolicy.minSpecialChar);
    // this.updatePasswordPolicyForm.get('maxSpecial').setValue(passwordPolicy.maxSpecialChar);
    this.updatePasswordPolicyForm.get('minUpperChar').setValue(passwordPolicy.minUpperChar);

    if(passwordPolicy.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }

  changeActivation(){
    if(this.activationButton == true){
      this.passwordPolicyModel.dataStatus = "DEACTIVE"
      this.activationButton = false;
    }else{
      this.passwordPolicyModel.dataStatus = "ACTIVE"
      this.activationButton = true;
    }
  }
  checkPasswordPolicyForm():boolean{
    if(parseInt(this.updatePasswordPolicyForm.get('minLength').value)==0){
      this.notifier.notify('error','Minimum Length at least 01');
      return false;
    }
    else if(parseInt(this.updatePasswordPolicyForm.get('maxLength').value)>20){
      this.notifier.notify('error','Maxmimum Length should below 20');
      return false;
    }
    else if(parseInt(this.updatePasswordPolicyForm.get('minLength').value)>parseInt(this.updatePasswordPolicyForm.get('maxLength').value)){
      this.notifier.notify('error','Maxmimum Length should below Minimum Length');
      return false;
    }
    // else if(parseInt(this.updatePasswordPolicyForm.get('minNumaric').value)>parseInt(this.updatePasswordPolicyForm.get('maxNumaric').value)){
    //   this.notifier.notify('error','Maxmimum Numaric Length should below Minimum Numaric Length');
    //   return false;
    // }
    // else if(parseInt(this.updatePasswordPolicyForm.get('minSpecial').value)>parseInt(this.updatePasswordPolicyForm.get('maxSpecial').value)){
    //   this.notifier.notify('error','Maxmimum Special Character Length should below Minimum Special Character  Length');
    //   return false;
    // }
    else if(
      (parseInt(this.updatePasswordPolicyForm.get('maxLength').value)<parseInt(this.updatePasswordPolicyForm.get('minNumaric').value)) ||
      (parseInt(this.updatePasswordPolicyForm.get('maxLength').value)<parseInt(this.updatePasswordPolicyForm.get('minSpecial').value)) ||
      (parseInt(this.updatePasswordPolicyForm.get('maxLength').value)<parseInt(this.updatePasswordPolicyForm.get('minUpperChar').value))
      ){
        this.notifier.notify('error','All values should below maximum length')
        return false;
      }else if(
        parseInt(this.updatePasswordPolicyForm.get('maxLength').value)<(parseInt(this.updatePasswordPolicyForm.get('minNumaric').value) + parseInt(this.updatePasswordPolicyForm.get('minSpecial').value) + parseInt(this.updatePasswordPolicyForm.get('minUpperChar').value)))
      {
        this.notifier.notify('error','Total of Minimum Numaric characters, minimum special characters and minimum upper characters should below Maximum length')
        return false;
      }
      else{
        return true;
      }
  }

  setPasswordPolicyModel(){
    this.passwordPolicyModel.id = this.updatePasswordPolicyForm.get('id').value;
    this.passwordPolicyModel.minLength = this.updatePasswordPolicyForm.get('minLength').value;
    this.passwordPolicyModel.maxLength = this.updatePasswordPolicyForm.get('maxLength').value;
    this.passwordPolicyModel.minNumaric = this.updatePasswordPolicyForm.get('minNumaric').value;
    // this.passwordPolicyModel.maxNumaric = this.updatePasswordPolicyForm.get('maxNumaric').value;
    this.passwordPolicyModel.minSpecialChar = this.updatePasswordPolicyForm.get('minSpecial').value;
    // this.passwordPolicyModel.maxSpecialChar = this.updatePasswordPolicyForm.get('maxSpecial').value;
    this.passwordPolicyModel.minUpperChar = this.updatePasswordPolicyForm.get('minUpperChar').value;
    if(this.activationButton == true){
      this.passwordPolicyModel.dataStatus = "ACTIVE";
    }else{
      this.passwordPolicyModel.dataStatus = "DEACTIVE";
    }
    console.log(this.passwordPolicyModel)
  }
  get f() { return this.updatePasswordPolicyForm.controls; }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


  updateTheView(update:boolean) {
    this.updatePasswordPolicyEvent.emit(update);
  }


  updatePasswordPolicy(){
    if(this.checkPasswordPolicyForm()){
      console.log(this.passwordPolicyModel)
      this.setPasswordPolicyModel()
      this.sub.add(this.passwordPolicyService.updatePasswordPolicy(this.passwordPolicyModel.id,this.passwordPolicyModel).subscribe(data=>{
        this.notifier.notify('success', (data as any).message);
        this.updateTheView(true);
        console.log(data.data);
        this.getPasswordPolicy();
      }))
    }else{
      this.notifier.notify('error', 'Password Policy Form not Valid');
    }
  }

  getPasswordPolicy(){
    this.passwordPolicyService.getActivePasswordPolicy().subscribe(data=>{
      if(data.data == null){
        this.passwordPolicyModel.minNumaric = "03";
        // this.passwordPolicyModel.maxNumaric = "";
        this.passwordPolicyModel.minSpecialChar = "03";
        // this.passwordPolicyModel.maxSpecialChar = "";
        this.passwordPolicyModel.minLength = "06";
        this.passwordPolicyModel.maxLength = "08";
        this.passwordPolicyModel.minUpperChar = "01";
      }else{
        this.passwordPolicyModel=(data as any).data;
      }
      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$1@",this.passwordPolicyModel.minNumaric);
      // this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$2@",this.passwordPolicyModel.maxNumaric);
      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$3@",this.passwordPolicyModel.minSpecialChar);
      // this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$4@",this.passwordPolicyModel.maxSpecialChar);
      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$5@",this.passwordPolicyModel.minLength);
      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$6@",this.passwordPolicyModel.maxLength);
      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$7@",this.passwordPolicyModel.minUpperChar);

      sessionStorage.setItem('passwordPolicy',this.passwordPolicyPattern);
    });
  }
}
