import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InspectionLandSutabilityService {

  private inspectionLandSutabilityUrl = '/inspection-land-suitability';


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  createLandSutability(LandSutabilityModel:InspectionCommonDataModel):Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionLandSutabilityUrl}`,LandSutabilityModel)
  }

  getLandSutability(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.inspectionLandSutabilityUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  updateLandSutability(id: number, LandSutabilityModel:InspectionCommonDataModel): Observable<any> {
    return this.http.put(`${environment.api_url}${this.inspectionLandSutabilityUrl}/${id}`, LandSutabilityModel);
  }

  searchLandSutability(keyWord:string,offset, limit):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.inspectionLandSutabilityUrl +'/search?keyword='+ keyWord + '&offset=' + offset + '&limit=' + limit + '');
  }

  deleteLandSutability(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.inspectionLandSutabilityUrl}/${id}`);
  }
}
