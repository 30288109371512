import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IPayment} from '../../../../../../../model/interfaces/payment';
import {FileService} from '../../../../../../../service/file/file.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {PaymentService} from '../../../../../../../service/payment/payment.service';
import {BankService} from '../../../../../../../service/bank/bank.service';
import {ValuationRequestService} from '../../../../../../../service/valuation-request/valuation-request.service';
import {Payment} from '../../../../../../../model/payment';
import {FileUploadComponentBank} from '../../../file-upload-bank/file-upload-bank.component';
import {FileUploadComponentCash} from '../../../file-upload-cash/file-upload-cash.component';
import {SubSink} from 'subsink';
import {ValuationRequest} from '../../../../../../../model/valuation-request';
import {NgxSpinnerService} from 'ngx-spinner';
import {DocumentUploadService} from '../../../../../../../service/document-upload/document-upload.service';
import {FutureDateValidator} from '../../../../../../validators/futuredate.validator';
import { NotificationModel } from 'src/app/model/notification-model';
import { Constant } from 'src/app/util/constant';
import { NotificationService } from 'src/app/service/notification/notification.service';

@Component({
  selector: 'app-view-payment',
  templateUrl: './view-payment.component.html',
  styleUrls: ['./view-payment.component.scss']
})
export class ViewPaymentComponent implements OnInit {

  // @Input()
  // payment: IPayment;

  paymentList: Payment [];
  payment: Payment;
  paymentTable : Payment;
  selectFileSm = 0;
  valuationRequestId: any;
  valuationID: any;
  previewFileData: any;
  type: any;

  // Redo payments for failed payment
  valuationRequest: ValuationRequest;
  isFailedPayment: boolean;
  isSuccessPayment: boolean;
  // selectedPaths = [] = [];
  initialAmount: string;
  paymentMethod: string;
  selectPaymentMethod = 1;
  selectedPaymentType = -1;
  cashPaymentForm: FormGroup;
  bankPaymentForm: FormGroup;
  cardPaymentForm: FormGroup;
  allBanks: any;
  maxDate: any;
  exchangeValuationID: number;
  referenceNumber: string;
  refNum: string;
  isBankOrCard: boolean;
  saveButton = 'Submit';
  // file upload
  selectedPaths = [] = [];
  selectPaths = [];
  errorList = [];
  otherSelectedPaths = [];
  documentsIdList = [];
  filesList: File[] = [];
  dataDone = false;
  @ViewChild('fileUploadBank', {static: false}) fileUploadBank: FileUploadComponentBank;
  @ViewChild('fileUploadCash', {static: false}) fileUploadCash: FileUploadComponentCash;
  // Validations
  onlyCharactersNumbersPattern = '^[_A-z0-9 ]*((-|\\s)*[_A-z0-9 ])*$';
  receiptNoPattern = '^[0-9]*$';
  onlyTextPattern = '^[ a-zA-Z]*$';
  private sub = new SubSink();
  noDocs = false;
  deedNatureValue: any;
  public notificationModel = new NotificationModel("", "", "", "", "", "", "");
  assessorUserId: string;
  dComUserId: string;
  comUserId: string;
  notaryUserId: string;

  constructor(private fileService: FileService, private route: ActivatedRoute,
              private formBuilder: FormBuilder, private notifier: NotifierService,
              private paymentService: PaymentService, private bankService: BankService,
              private valuationRequestService: ValuationRequestService,
              public sanitizer: DomSanitizer, private  router: Router,
              private spinner: NgxSpinnerService,
              private documentUploadService: DocumentUploadService,
              public notificationService: NotificationService) {
  }

  // Redo Payments for failed payment
  get f() {
    return this.cashPaymentForm.controls;
  }

  get f1() {
    return this.cardPaymentForm.controls;
  }

  get f2() {
    return this.bankPaymentForm.controls;
  }

  ngOnInit() {
    this.valuationRequestId = +this.route.snapshot.paramMap.get('id');
    this.valuationID = this.valuationRequestId;
    this.getPaymentDetails();
    // console.log(this.payment);

    this.fetchAllBanks();

    // Redo payments for failed payment
    this.cashPaymentForm = this.formBuilder.group({
      receiptNo: ['', [Validators.required, Validators.pattern(this.receiptNoPattern)]],
      date: ['', [Validators.required]],
      amount: ['']
    }, {
      validator: FutureDateValidator('date')
    });

    this.bankPaymentForm = this.formBuilder.group({
      // referenceno: ['', [Validators.required, Validators.pattern(this.onlyCharactersNumbersPattern)]],
      referenceno: ['', [Validators.required, Validators.pattern(this.receiptNoPattern)]],
      date: ['', [Validators.required]],
      amount: [''],
      bank: ['', [Validators.required]],
      branch: ['', [Validators.required, Validators.pattern(this.onlyCharactersNumbersPattern)]]
    }, {
      validator: FutureDateValidator('date')
    });

    this.cardPaymentForm = this.formBuilder.group({
      date: [''],
      amount: ['']
    });

    this.maxDate = new Date();
    this.initialAmount = '250.00';
    this.paymentMethod = 'null';
  }

  previewFile(type) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadFileById(this.valuationRequestId, type).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(() => {
          this.spinner.hide();
        }, 2000);

      } else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }

  getPaymentDetails() {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(this.valuationRequestId)
      .subscribe((res) => {
        this.valuationRequest = res.data;

        this.referenceNumber = '#VR' + JSON.stringify(this.valuationRequestId).padStart(10, '0');
        console.log(this.referenceNumber + '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
        // if (this.valuationRequest.deedNature === 'Exchange') {
        //   if (this.payment.amount === null) {
        //     this.valuationRequestId = this.valuationRequest.exchangeId;
        //   }
        // }

        this.getValuationRequestPayment();
      }));
  }

  makeActive(event: any, type: string) {
    let isAvtive = false;
    if (event.target.parentElement.classList.contains('active')) {
      isAvtive = true;
    }
    this.paymentMethod = type;
    const elements = event.target.parentElement.parentElement.querySelectorAll('.active');
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('active');
    }
    if (isAvtive) {
      event.target.parentElement.classList.remove('active');
    } else {
      event.target.parentElement.classList.add('active');
    }
  }

  setCopyOfBankReceiptDocArray(images: File[]) {
    // this.selectPaths.push(images);
    this.filesList = images;
  }

  setCopyOfCashReceiptDocArray(images: File[]) {
    // this.selectPaths.push(images);
    this.filesList = images;
  }

  setBankChildComponentUploadingDocuments() {
    this.fileUploadBank.outPutImagesSet();
  }

  setCashChildComponentUploadingDocuments() {
    this.fileUploadCash.outPutImagesSet();
  }

  fetchAllBanks() {
    this.bankService.getAll().subscribe(result => {
      if (result) {
        this.allBanks = result.data;
      } else {

      }
    }, error => {
    });
  }

  // Update valuation request
  setPaymentDetailsPayment() {
    this.payment.amount = '250.00';

    if (this.paymentMethod !== 'null') {
      if (this.paymentMethod === 'Bank-Payment') {
        this.bankPaymentForm.markAllAsTouched();

        if (this.bankPaymentForm.get('branch').valid &&
          this.bankPaymentForm.get('bank').valid &&
          this.bankPaymentForm.get('referenceno').valid &&
          this.bankPaymentForm.get('date').valid) {

          if (this.fileUploadBank.filesList.length === 0) {
            this.notifier.notify('error', 'Please upload Bank Receipt');
            return false;
          } else {
            this.payment.paymentMethod = this.paymentMethod;
            this.payment.branch = this.bankPaymentForm.get('branch').value;
            this.payment.bank = this.bankPaymentForm.get('bank').value;
            this.payment.referenceNo = this.bankPaymentForm.get('referenceno').value;
            return true;
          }
        } else {
          return false;
        }
      } else if (this.paymentMethod === 'Cash-Payment') {
        // setting data to cash-payment
        this.cashPaymentForm.markAllAsTouched();
        if (this.cashPaymentForm.valid) {
          if (this.fileUploadCash.filesList.length === 0) {
            this.notifier.notify('error', 'Please upload Cash Receipt');
            return false;
          } else {
            this.payment.paymentMethod = this.paymentMethod;
            this.payment.referenceNo = this.cashPaymentForm.get('receiptNo').value;
            this.payment.paidDate = this.cashPaymentForm.get('date').value;
            return true;
          }
        } else {
          return false;
        }
      } else {
        this.cardPaymentForm.markAllAsTouched();
        if (this.cardPaymentForm.valid) {
          this.payment.paymentMethod = this.paymentMethod;
          this.payment.paidDate = this.cardPaymentForm.get('date').value;
          return true;
        } else {
          return false;
        }
      }
    } else {
      this.notifier.notify('error', 'Please select one payment method');
    }
  }

  updateValuationRequest() {
    if (this.setPaymentDetailsPayment() && this.checkDocuments()) {

      // Document uploader
      console.log('docs', this.filesList);
      this.saveButton = 'Saving..';
      this.documentUploadService.uploadPaymentDocuments(this.filesList).subscribe(
        response => {
          this.documentsIdList = (response as any).data;

          console.log('added document : >>>>>>>>>>>>>>>> ' + this.documentsIdList.length);
          ///////////////////
          console.log('this.valuationRequest', this.valuationRequest);
          this.valuationRequest.payment = this.payment;
          this.valuationRequest.documentsIdList = this.documentsIdList;

          this.sub.add(this.valuationRequestService.setPaymentDetailsForValuationRequest(this.valuationRequest.id, this.valuationRequest)
            .subscribe(res => {
              if (this.payment.paymentMethod !== 'Online-Payment') {
                this.notifier.notify('success', 'Payment made successfully');

              // **** SEND NOTIFICATIONS - START ****
              this.valuationRequestService.findByValuationRequestId(+this.route.snapshot.paramMap.get('id')).subscribe(
                data => {
                  this.deedNatureValue = data.data.deedNature;

                  this.assessorUserId = data.data.accessorId;
                  this.dComUserId = data.data.dcommissionerId;
                  this.comUserId = data.data.commissionerId;
                  this.notaryUserId = data.data.user_id;


                  this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
                  this.notificationModel.deedNature = this.deedNatureValue;
                  this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                  this.notificationModel.subject = Constant.PAYMENT_MADE_BY_NOTARY;
                  this.notificationModel.userId = this.comUserId;
                  this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                  this.notificationService.createNotification(this.notificationModel).subscribe(
                    data => {
                      console.log(data);

                      this.notificationModel.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
                      this.notificationModel.deedNature = this.deedNatureValue;
                      this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                      this.notificationModel.subject = Constant.PAYMENT_MADE_BY_NOTARY;
                      this.notificationModel.userId = this.dComUserId;
                      this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                      this.notificationService.createNotification(this.notificationModel).subscribe(
                        data => {
                          console.log(data);

                          this.notificationModel.userRoleCode = Constant.ROLE_ACCESSOR;
                          this.notificationModel.deedNature = this.deedNatureValue;
                          this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                          this.notificationModel.subject = Constant.PAYMENT_MADE_BY_NOTARY;
                          this.notificationModel.userId = this.assessorUserId;
                          this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                          this.notificationService.createNotification(this.notificationModel).subscribe(
                            data => {
                              console.log(data);
                            }
                          )

                        }
                      )

                    }
                  )
                }
              )
              // **** SEND NOTIFICATIONS - END ****

              }
              const data = res as any;
              console.log(data);
              window.location = data.data.encryptedRequest;
            }, (error => {
              this.notifier.notify('error', 'Payment unsuccessful');
              this.saveButton = 'Submit';
            })));
        });
    } else {
      this.saveButton = 'Submit';
      this.notifier.notify('error', 'Please check payment details');
    }
  }

  getValuationRequestPayment() {
    this.sub.add(this.valuationRequestService.findByValuationRequestIdPayment(this.valuationRequestId)
      .subscribe((res) => {
        this.payment = res.data[0];
        this.paymentTable = res.data;
        // console.log('paymentlist', this.paymentList);
        // console.log('paymentlistsize', this.paymentList.length);
        // for (let i = 0; i < this.paymentList.length; i++) {
        //   if (Number(this.paymentList[i].amount) < 300) {
        //     this.payment = this.paymentList[i];
        //   }
        // }
        // this.referenceNumber = '#VR' + this.payment.referenceNo.padStart(10, '0');

        console.log('NEW ID................', this.valuationRequest.id);
        console.log('VAL PAY................', this.payment);
        console.log('Trans................', this.payment.transactionStatus);

        if (this.payment.transactionStatus === 'SUCCEED') {
          this.isFailedPayment = false;
          this.isSuccessPayment = true;
        } else {
          this.isFailedPayment = true;
          this.isSuccessPayment = false;
        }

        if (this.payment.paymentMethod === 'Online-Payment') {
          this.isBankOrCard = true;
          this.refNum = this.payment.id;
          console.log(this.payment.id + '>>>>>>>>>>>>>>>>>>>');
        }
        if (this.payment.paymentMethod === 'Bank-Payment') {
          this.isBankOrCard = true;
          this.refNum = this.payment.referenceNo;
        }
        if (this.payment.paymentMethod === 'Cash-Payment') {
          this.isBankOrCard = false;
          this.refNum = this.payment.referenceNo;
        }
      }));
  }

  checkDocuments() {
    this.errorList = [];
    // hold every child uploading file data

    if (this.payment.paymentMethod === 'Bank-Payment') {
      if (this.fileUploadBank.filesList.length === 0) {
        this.errorList.push('Bank Receipt');
      } else {
        this.setBankChildComponentUploadingDocuments();
      }
    }

    if (this.payment.paymentMethod === 'Cash-Payment') {
      if (this.fileUploadCash.filesList.length === 0) {
        this.errorList.push('Cash Receipt');
      } else {
        this.setCashChildComponentUploadingDocuments();
      }
    }

    if (this.errorList.length === 0) {
      this.otherSelectedPaths = this.selectPaths;
      return true;
    } else {
      const re = /,/gi;
      console.log(this.errorList.toString());
      const errormsg = this.errorList.toString().replace(re, '<br/>');
      console.log(errormsg);
      return false;
    }
  }

  // backToList() {
  //   if (this.valuationRequest.valuationRequestStatus === 'PENDING') {
  //     this.router.navigate(['/view-assessment/pending-requests']);
  //   }
  //   if (this.valuationRequest.valuationRequestStatus === 'COMPLETED') {
  //     this.router.navigate(['/view-assessment/completed-requests']);
  //   }
  //   if (this.valuationRequest.valuationRequestStatus === 'REJECTED') {
  //     this.router.navigate(['/view-assessment/rejected-requests']);
  //   }
  // }
}
