import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterLinkActive} from '@angular/router';
import {DashboardService} from '../../../../../service/dashboard/dashboard.service';

@Component({
  selector: 'app-view-assessment-request',
  templateUrl: './view-assessment-request.component.html',
  styleUrls: ['./view-assessment-request.component.scss']
})
export class ViewAssessmentRequestComponent implements OnInit {

  selectValRequest: string;
  pendingCount: number;
  paymentPendingCount: number;
  completedCount: number;
  rejectedCount: number;
  allRequestCount: number;
  draftCount:number;
  furtherRequirementCount: number;

  type: string;

  constructor(private route: ActivatedRoute, private dashboardService: DashboardService,
              private  router: Router) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      console.log(params);
      if (params.get('type') === 'all-request') {
        this.type = 'pending-requests';
      } else {
        this.type = params.get('type');
      }
      this.selectValRequest = this.type;
    });
    this.draftCountChange();
    this.allCountChange();
    this.pendingCountChange();
    this.completedCountChange();
    this.rejectedCountChange();
    this.paymentPendingCountChange();
    this.furtherRequirementCountChange();
  }

  allCountChange() {
    this.dashboardService.getRequestCount('all','EXTERNAL').subscribe(value => {
      this.allRequestCount = value;
    });
  }
  pendingCountChange() {
    this.dashboardService.getRequestCount('PENDING','EXTERNAL').subscribe(value => {
      this.pendingCount = value;
    });
  }
  paymentPendingCountChange() {
    this.dashboardService.getRequestCount('PAYPEND','EXTERNAL').subscribe(value => {
      this.paymentPendingCount = value;
    });
  }
  completedCountChange() {
    this.dashboardService.getRequestCount('APPLICATION_COMPLETED','EXTERNAL').subscribe(value => {
      this.completedCount = value;
    });
  }
  rejectedCountChange() {
    this.dashboardService.getRequestCount('REJECTED','EXTERNAL').subscribe(value => {
      this.rejectedCount = value;
    });
  }

  furtherRequirementCountChange() {
    this.dashboardService.getRequestCount('FURTHER_REQUIREMENT','EXTERNAL').subscribe(value => {
      this.furtherRequirementCount = value;
    });
  }

  draftCountChange() {
    this.dashboardService.getRequestCount('DRAFT','EXTERNAL').subscribe(value => {
      this.draftCount = value;
    });
  }

  // toPendingList() {
  //   this.type = 'PENDING';
  //   this.router.navigate(['/view-assessment/pending-requests']);
  // }
  //
  // toCompleteList() {
  //   this.type = 'COMPLETED';
  //   this.router.navigate(['/view-assessment/completed-requests']);
  // }
  //
  // toRejectList() {
  //   this.type = 'REJECTED';
  //   this.router.navigate(['/view-assessment/rejected-requests']);
  // }
}
