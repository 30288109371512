import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ValuationRequestCount } from 'src/app/model/valuation_request_count';
import { Constant } from 'src/app/util/constant';

@Component({
  selector: 'app-refund-application-header',
  templateUrl: './refund-application-header.component.html',
  styleUrls: ['./refund-application-header.component.scss']
})
export class RefundApplicationHeaderComponent implements OnInit {

  userdata: any = [];
  userrolecode: string;

  selectedComponent: string;

  public valuatinRequestCountModel = new ValuationRequestCount('', '', '');

  constructor(
    private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    this.obtainUserData();
  }

  obtainUserData() {
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.valuatinRequestCountModel.userId = this.userdata.id;
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.userrolecode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.userrolecode = Constant.ROLE_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.userrolecode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.userrolecode = Constant.ROLE_ACCESSOR;
        break;
      }
    }
  }

  clickGift() {
    // Same method exists in header-external.component.ts
    this.selectedComponent = 'Gift';
    localStorage.setItem('valuation_request_id', null);
    localStorage.setItem('first_valuation_request_id', null);
    localStorage.setItem('second_valuation_request_id', null);
    sessionStorage.setItem('valuationUpdate', 'false');
    sessionStorage.setItem('selectedType', this.selectedComponent);
    this.router.navigate(['/new-application/gift']);
  }

  clickTransfer() {
    this.selectedComponent = 'Transfer';
    localStorage.setItem('valuation_request_id', null);
    localStorage.setItem('first_valuation_request_id', null);
    localStorage.setItem('second_valuation_request_id', null);
    sessionStorage.setItem('valuationUpdate', 'false');
    sessionStorage.setItem('selectedType', this.selectedComponent);
    this.router.navigate(['/new-application/transfer']);
  }

  clickExchange() {
    this.selectedComponent = 'Exchange';
    localStorage.setItem('valuation_request_id', null);
    localStorage.setItem('first_valuation_request_id', null);
    localStorage.setItem('second_valuation_request_id', null);
    sessionStorage.setItem('valuationUpdate', 'false');
    sessionStorage.setItem('selectedType', this.selectedComponent);
    this.router.navigate(['/new-application/exchange']);
  }

  destroyOpinion(){
    localStorage.removeItem('opinionNo');
    localStorage.removeItem('fileNo');
  }

  logout(){
    this.authService.logout();
  }

}
