import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InspectionLandSlopeService {

  private inspectionLandSlopeUrl = '/inspection-land-slope';


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  createLandSlope(landSlopeModel:InspectionCommonDataModel):Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionLandSlopeUrl}`,landSlopeModel)
  }

  getLandSlope(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.inspectionLandSlopeUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  updateLandSlope(id: number, landSlopeModel:InspectionCommonDataModel): Observable<any> {
    return this.http.put(`${environment.api_url}${this.inspectionLandSlopeUrl}/${id}`, landSlopeModel);
  }

  searchLandSlope(keyWord:string,offset, limit):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.inspectionLandSlopeUrl +'/search?keyword='+ keyWord + '&offset=' + offset + '&limit=' + limit + '');
  }

  deleteLandSlope(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.inspectionLandSlopeUrl}/${id}`);
  }
}
