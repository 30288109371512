import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DistrictService } from 'src/app/service/district/district.service';
import { ProvinceService } from 'src/app/service/province/province.service';
import { District } from 'src/app/model/district';
import { NotifierService } from 'angular-notifier';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-update-district',
  templateUrl: './update-district.component.html',
  styleUrls: ['./update-district.component.scss']
})
export class UpdateDistrictComponent implements OnInit,OnDestroy {

  updateDistrictForm: FormGroup;

  description: string;
  code: string;
  province: number;
  id: number;

  provinces = [];
  private sub = new SubSink();


  @Output() districtUpdated = new EventEmitter<boolean>();


  constructor(private formBuilder: FormBuilder, private districtService: DistrictService,
    private provinceService: ProvinceService,
    private notifier: NotifierService) { }

  ngOnInit() {

    this.updateDistrictForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      code: ['', [Validators.required]],
      province: ['', [Validators.required]],
      description: ['', [Validators.required]]
      // floorTypeName: ['', [Validators.required]]
    })

  this.sub.add(this.provinceService.getProvinces(0,10)
    .subscribe(
      (res) => {
        this.provinces = (res as any).data;
      }
    ));

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


  updateDistrict() {
    // 6("daojcnao")
    // if (this.updateDistrictForm.valid) {
      // const district = new District();

      // district.description = this.getDescription();
      // district.code = this.getCode();
      // district.province = this.getProvince();

      // this.sub.add(this.districtService.updateDistrict(this.getId(), district)
        // .subscribe(response => {
          // this.updateTheView(true);
          // this.notifier.notify( 'success', (response as any).message);

          // }));
    // }
  }

  getId() {
    return this.updateDistrictForm.get('id').value;
  }


  getDescription() {
    return this.updateDistrictForm.get('description').value;
  }
  getCode() {
    return this.updateDistrictForm.get('code').value;
  }
  getProvince() {
    return this.updateDistrictForm.get('province').value;
  }
  setIdAndDescription(id: number, code: string, province: number, description: string) {
    this.updateDistrictForm.get('id').setValue(id);
    this.updateDistrictForm.get('code').setValue(code);
    this.updateDistrictForm.get('province').setValue(province);
    this.updateDistrictForm.get('description').setValue(description);
    // this.updateFloorSubTypeForm.get('floorType').setValue(floorType)
  }

  updateTheView(updated: boolean) {
    this.districtUpdated.emit(updated);
  }



}
