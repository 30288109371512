import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import Swal from 'sweetalert2';
import Cropper from 'cropperjs';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-file-upload4-iuser',
  templateUrl: './file-upload4-iuser.component.html',
  styleUrls: ['./file-upload4-iuser.component.scss']
})

export class FileUpload4InternalUserComponent implements OnInit {

  selectedElement: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
  }

  @Output()
  outPutImages4 = new EventEmitter<File[]>();
  filesList4: File[] = [];
  images4 = [];
  originalImages4: any[] = [];
  names4: string[] = [];
  selectedIndex = 0;
  files4;
  showEdit = false;
  showEditPdf = false;

  public progress = 0;

  cropper;

  ngOnInit() {

  }

  uploadChange4(event) {
    this.files4 = event.target.files;
    this.progress = 0;
    for (let i = 0; i < this.files4.length; i++) {
      const file = this.files4[i];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event: any) => {
        this.progress = 100;
        this.images4.push(_event.currentTarget.result);
        this.originalImages4.push(_event.currentTarget.result);
        this.filesList4.push(file);
      };
      this.names4.push(file.name);
      this.progress = 0;
    }

  }

  changeImg(event) {

    if (this.cropper !== undefined) {
      this.cropper.destroy();
    }
    const image = document.getElementById('image4') as HTMLImageElement;
    image.src = event.target.src;
    this.cropper = new Cropper(image, {
      crop(event) {
      },
    });
  }

  outPutImagesSet() {
    this.outPutImages4.emit(this.filesList4);
  }

  edit(event, index) {

    if (this.filesList4[index].type === "application/pdf") {
      this.showEdit = false;
      this.showEditPdf = true;
      document.getElementById('myiframe4').setAttribute('src',this.images4[index])
    } else {
      this.showEdit = true;
      this.showEditPdf = false;
      console.log(event.target.parentElement.parentElement.children);
      const image = document.getElementById('image4') as HTMLImageElement;
      image.src = event.target.parentElement.parentElement.children[0].src;
      if (this.cropper !== undefined) {
        this.cropper.destroy();
      }
      this.cropper = new Cropper(image, {
        rotatable: true,
        crop(event) {

        },
      });
    }

    this.selectedIndex = index;
  }

  delete(index) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4085d6',
      cancelButtonColor: '#d44',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.images4.splice(index, 1);
        this.originalImages4.splice(index, 1);
        this.names4.splice(index, 1);
        this.filesList4.splice(index, 1);
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );

      }
    });

    // this.images.splice(index, 1);
    // this.originalImages.splice(index, 1);
    // this.names.splice(index, 1);
    // this.filesList.splice(index, 1);


  }

  closeEditor() {
    this.showEdit = false;
    this.showEditPdf = false;
  }

  rotate(angle: number) {
    this.cropper.move(1, -1).rotate(angle).scale(1, -1);
  }

  resetImage() {
    this.cropper.destroy();
    this.images4[this.selectedIndex] = this.originalImages4[this.selectedIndex];
    const image = document.getElementById('image4') as HTMLImageElement;
    image.src = this.images4[this.selectedIndex];
    this.cropper = new Cropper(image, {
      rotatable: true,
      crop(event) {
      },
    });
  }

  crop() {
    this.images4[this.selectedIndex] = this.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    this.cropper.destroy();

    this.images4[this.selectedIndex] = this.images4[this.selectedIndex];
    const image = document.getElementById('image4') as HTMLImageElement;
    image.src = this.images4[this.selectedIndex];
    this.cropper = new Cropper(image, {
      rotatable: true,
      crop(event) {
      },
    });
  }

}
