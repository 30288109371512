import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {SubSink} from 'subsink';
import {ValuationRequestList} from '../../../../../model/valuation_request_list';
import {ValuationRequestService} from '../../../../../service/valuation-request/valuation-request.service';
import {Constant} from '../../../../../util/constant';

declare let jsPDF;

@Component({
  selector: 'app-appeal-completed-payment-application',
  templateUrl: './appeal-completed-payment-application.component.html',
  styleUrls: ['./appeal-completed-payment-application.component.scss']
})
export class AppealCompletedPaymentApplicationComponent implements OnInit, OnDestroy {


  private sub = new SubSink();
  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];

  valuationRequests: any;
  @Output() completedRequestCount = new EventEmitter<number>();
  noResults: boolean = false;

  public model = new ValuationRequestList('', '', '', '', '', '');
  valuationrequestlist: any = [];

  userdata: any = [];
  userrolecode: string;
  valuationRequestId: number;
  valuationRequest: any = [];

      // pagination-
      totalRecords:number;
      page:number =1

  constructor(
    private valuationRequestService: ValuationRequestService
  ) {
  }

  ngOnInit() {
    this._prepare();
  }

  _prepare() {
    this.obtainUserData();
  }

  obtainUserData() {
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.model.userId = this.userdata.id;
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.userrolecode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.userrolecode = Constant.ROLE_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.userrolecode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.userrolecode = Constant.ROLE_ACCESSOR;
        break;
      }
    }
    this.model.userType = this.userrolecode;

    this.loadNewApplicationData();
  }

  loadNewApplicationData() {
    this.model.valuationStatus = Constant.VALIATION_PAYMENT_COMPLETED;
    this.valuationRequestService.appealValuationRequestList(this.model).subscribe(
      data => {
        this.valuationrequestlist = data.data;
        if (this.valuationRequests.length === 0) {
          this.noResults = true;
        } else {
          this.noResults = false;
          this.totalRecords = data.data.length;
          // this.paginationButtonCounter(data.data.totalPages);
          // this.numOfPages = data.data.totalPages;
        }
      }
    );
  }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

  obtainFileNumber(fileNumber, valuationRequestStatus) {
    localStorage.setItem('fileNumberFormat', fileNumber);
  }

  getPage(pageNo: number) {
    this.selectedPageNo = pageNo;
    this.sub.add(this.valuationRequestService.findAppealValuationRequestInternalUserByStatus(
      'COMPLETED', this.selectedPageNo, this.limit).subscribe(
      (res) => {
        this.valuationRequests = res.data.content;
        if (Object.keys(this.valuationRequests).length === 0) {
          this.noResults = true;
        }
        this.paginationButtonCounter(res.data.totalPages);
        this.numOfPages = res.data.totalPages;
      }));
  }

  addWrappedText({text, textWidth, doc, fontSize = 10, fontType = 'normal', lineSpacing = 7, xPosition = 10, initialYPosition = 10, pageWrapInitialYPosition = 10}) {
    var textLines = doc.splitTextToSize(text, textWidth); // Split the text into lines
    var pageHeight = doc.internal.pageSize.height;        // Get page height, well use this for auto-paging
    doc.setFontType(fontType);
    doc.setFontSize(fontSize);

    var cursorY = initialYPosition;

    textLines.forEach(lineText => {
      if (cursorY > pageHeight) { // Auto-paging
        doc.addPage();
        cursorY = pageWrapInitialYPosition;
      }
      doc.text(xPosition, cursorY, lineText);
      cursorY += lineSpacing;
    });
  }

  generatePdf(valuationRequestId) {
    this.valuationRequestId = valuationRequestId;
    const doc = new jsPDF();
    this.valuationRequestService.findByValuationRequestId(valuationRequestId)
      .subscribe((res) => {
        this.valuationRequest = res.data;
        const filteredgranters = this.valuationRequest.granters;
        const filteredgrantees = this.valuationRequest.grantees;
        const filteredproperties = this.valuationRequest.properties;
        const filteredbuilding = this.valuationRequest.properties[0].buildings;
        const filteredpayment = this.valuationRequest.payment;
        const filteredloans = this.valuationRequest.properties[0].loan;
        doc.setFontStyle('bold');
        doc.text('Valuation Request.', 105, 20, null, null, 'center');
        doc.setFontSize(10);
        doc.text('Grantor', 20, 30);
        doc.setFont('times');
        doc.setFontType('normal');
        doc.text('Name with initials', 20, 40);
        doc.text(filteredgranters[0].nameWithInitials !== null ? filteredgranters[0].nameWithInitials : '--', 100, 40);
        doc.text('First name', 20, 50);
        doc.text(filteredgranters[0].firstName !== null ? filteredgranters[0].firstName : '--', 100, 50);
        doc.text('Middle name', 20, 60);
        doc.text(filteredgranters[0].middleName !== null ? filteredgranters[0].middleName : '--', 100, 60);
        doc.text('Last name', 20, 70);
        doc.text(filteredgranters[0].lastName, 100, 70);
        doc.text('Address Line 1', 20, 80);
        this.addWrappedText({
          text: filteredgranters[0].addressLine1 !== null ? filteredgranters[0].addressLine1 : '--',
          textWidth: 150,
          doc,

          lineSpacing: 7,
          xPosition: 100,
          initialYPosition: 80,
          pageWrapInitialYPosition: 10
        });
        doc.text('Address Line 2', 20, 90);
        this.addWrappedText({
          text: filteredgranters[0].addressLine2 !== null ? filteredgranters[0].addressLine2 : '--',
          textWidth: 100,
          doc,

          lineSpacing: 7,
          xPosition: 100,
          initialYPosition: 90,
          pageWrapInitialYPosition: 10
        });
        doc.text('City', 20, 110);
        doc.text(filteredgranters[0].city !== null ? filteredgranters[0].city : '--', 100, 110);
        doc.text('NIC', 20, 120);
        doc.text(filteredgranters[0].nic !== null ? filteredgranters[0].nic : '--', 100, 120);
        doc.text('Email', 20, 130);
        doc.text(filteredgranters[0].email !== null ? filteredgranters[0].email : '--', 100, 130);
        doc.setFontStyle('bold');
        doc.text('Grantee', 20, 140);
        doc.setFont('times');
        doc.setFontType('normal');
        doc.setFontSize(10);
        doc.text('Name with initials', 20, 150);
        doc.text(filteredgrantees[0].nameWithInitials !== null ? filteredgrantees[0].nameWithInitials : '--', 100, 150);
        doc.text('First name', 20, 160);
        doc.text(filteredgrantees[0].firstName !== null ? filteredgrantees[0].firstName : '--', 100, 160);
        doc.text('Middle name', 20, 170);
        doc.text(filteredgrantees[0].middleName !== null ? filteredgrantees[0].middleName : '--', 100, 170);
        doc.text('Last name', 20, 180);
        doc.text(filteredgrantees[0].lastName !== null ? filteredgrantees[0].lastName : '--', 100, 180);
        doc.text('Address Line 1', 20, 190);
        this.addWrappedText({
          text: filteredgrantees[0].addressLine1 !== null ? filteredgrantees[0].addressLine1 : '--',
          textWidth: 150,
          doc,

          lineSpacing: 7,
          xPosition: 100,
          initialYPosition: 190,
          pageWrapInitialYPosition: 10
        });
        doc.text('Address Line 2', 20, 200);
        this.addWrappedText({
          text: filteredgrantees[0].addressLine2 !== null ? filteredgrantees[0].addressLine2 : '--',
          textWidth: 100,
          doc,

          lineSpacing: 7,
          xPosition: 100,
          initialYPosition: 200,
          pageWrapInitialYPosition: 10
        });
        doc.text('City', 20, 220);
        doc.text(filteredgrantees[0].city !== null ? filteredgrantees[0].city : '--', 100, 220);
        doc.text('NIC', 20, 230);
        doc.text(filteredgrantees[0].nic !== null ? filteredgrantees[0].nic : '--', 100, 230);
        doc.text('Email', 20, 240);
        doc.text(filteredgrantees[0].email !== null ? filteredgrantees[0].email : '--', 100, 240);
        doc.addPage();
        doc.setFontSize(10);
        doc.setFontStyle('bold');
        doc.text('Property', 20, 20);
        doc.setFont('times');
        doc.setFontType('normal');
        doc.text('Deed Nature', 20, 30);
        doc.text(filteredproperties[0].deedNature !== null ? filteredproperties[0].deedNature : '--', 100, 30);
        doc.text('Previous deed number', 20, 40);
        doc.text(filteredproperties[0].previousDeedNo !== null ? filteredproperties[0].previousDeedNo : '--', 100, 40);
        doc.text('Previous Deed Registered Date', 20, 50);
        doc.text(filteredproperties[0].previousDeedDate !== null ? filteredproperties[0].previousDeedDate : '--', 100, 50);
        doc.text('Previous registration number', 20, 60);
        doc.text(filteredproperties[0].previousRegistrationNo !== null ? filteredproperties[0].previousRegistrationNo : '--', 100, 60);
        doc.text('Previous registration date', 20, 70);
        doc.text(filteredproperties[0].previousRegistrationDate !== null ? filteredproperties[0].previousRegistrationDate : '--', 100, 70);
        doc.text('Survey plan number', 20, 80);
        doc.text(filteredproperties[0].surveyorPlanNo !== null ? filteredproperties[0].surveyorPlanNo : '--', 100, 80);
        doc.text('Survey plan date', 20, 90);
        doc.text(filteredproperties[0].surveyorPlanDate !== null ? filteredproperties[0].surveyorPlanDate : '--', 100, 90);
        doc.text('Survey name', 20, 100);
        doc.text(filteredproperties[0].surveyorName !== null ? filteredproperties[0].surveyorName : '--', 100, 100);
        doc.text('Survey license number', 20, 110);
        doc.text(filteredproperties[0].surveyorLicenseNo !== null ? filteredproperties[0].surveyorLicenseNo : '--', 100, 110);
        doc.text('Name of the property', 20, 120);
        doc.text(filteredproperties[0].nameOfTheProperty !== null ? filteredproperties[0].nameOfTheProperty : '--', 100, 120);
        doc.text('Address Line 1', 20, 130);
        this.addWrappedText({
          text: filteredproperties[0].addressLine1 !== null ? filteredproperties[0].addressLine1 : '--',
          textWidth: 150,
          doc,

          lineSpacing: 7,
          xPosition: 100,
          initialYPosition: 130,
          pageWrapInitialYPosition: 10
        });
        doc.text('Address Line 2', 20, 140);
        this.addWrappedText({
          text: filteredproperties[0].addressLine2 !== null ? filteredproperties[0].addressLine2 : '--',
          textWidth: 100,
          doc,

          lineSpacing: 7,
          xPosition: 100,
          initialYPosition: 140,
          pageWrapInitialYPosition: 10
        });
        doc.text('City', 20, 150);
        doc.text(filteredproperties[0].city !== null ? filteredproperties[0].city : '--', 100, 150);
        doc.text('Postal Code', 20, 160);
        doc.text(filteredproperties[0].postalCode !== null ? filteredproperties[0].postalCode : '--', 100, 160);
        doc.text('Nearest Town', 20, 170);
        doc.text(filteredproperties[0].nearestTown !== null ? filteredproperties[0].nearestTown : '--', 100, 170);
        doc.text('Details of the crop cultivation', 20, 180);
        doc.text(filteredproperties[0].detailsOfTheCropCultivation !== null ? filteredproperties[0].detailsOfTheCropCultivation : '--', 100, 180);
        doc.text('Decided purchase value', 20, 190);
        doc.text(filteredproperties[0].decidedPurchaseValue !== null ? filteredproperties[0].decidedPurchaseValue.toString() : '--', 100, 190);
        doc.text('Date for market value', 20, 200);
        doc.text(filteredproperties[0].dateForMarketValue !== null ? filteredproperties[0].dateForMarketValue.toString() : '--', 100, 200);
        doc.text('Bank valuation', 20, 210);
        doc.text(filteredproperties[0].bankValuation !== null ? filteredproperties[0].bankValuation.toString() : '--', 100, 210);
        doc.addPage();
        doc.setFontSize(10);
        doc.setFontStyle('bold');
        doc.text('Building', 20, 20);
        doc.setFont('times');
        doc.setFontType('normal');
        if (filteredbuilding.length > 0) {
          doc.text('Plan number', 20, 30);
          doc.text(filteredbuilding[0].planNo !== null ? filteredbuilding[0].planNo : '--', 100, 30);
          doc.text('Plan date', 20, 40);
          doc.text(filteredbuilding[0].planDate !== null ? filteredbuilding[0].planDate.toString() : '--', 100, 40);
          doc.text('Unit number', 20, 50);
          doc.text((filteredbuilding[0].unitNumber !== null) ? filteredbuilding[0].unitNumber.toString() : '-', 100, 50);
          doc.text('Year of construct', 20, 60);
          doc.text(filteredbuilding[0].yearOfConstruct !== null ? filteredbuilding[0].yearOfConstruct.toString() : '--', 100, 60);
          doc.text('Number of floors', 20, 70);
          doc.text(filteredbuilding[0].noOfFloors !== null ? filteredbuilding[0].noOfFloors.toString() : '--', 100, 70);
          doc.text('Total floor area', 20, 80);
          doc.text(filteredbuilding[0].totalFloorArea !== null ? filteredbuilding[0].totalFloorArea.toString() : '--', 100, 80);
        }
        doc.setFontSize(10);
        doc.setFontStyle('bold');
        doc.text('Building', 20, 20);
        doc.setFont('times');
        doc.setFontType('normal');
        doc.text('Loan number', 20, 90);
        if (filteredloans !== null) {
          doc.text((filteredloans.loanNumber !== null) ? filteredloans.loanNumber.toString() : '--', 100, 90);
          doc.text('Loan amount', 20, 100);
          doc.text(filteredloans.amount !== null ? filteredloans.amount.toString() : '--', 100, 100);
          doc.text('Date', 20, 110);
          doc.text((filteredloans.date !== null) ? filteredloans.date : '--', 100, 110);
          doc.text('Bank', 20, 120);
          doc.text((filteredloans.bank !== null) ? filteredloans.bank : '--', 100, 120);
          doc.text('Branch', 20, 130);
          doc.text((filteredloans.branch !== null) ? filteredloans.branch : '--', 100, 130);
          doc.text('Other details', 20, 140);
          doc.text((filteredloans.otherDetails !== null) ? filteredloans.otherDetails : '--', 100, 140);
        }
        doc.setFontSize(10);
        doc.setFontStyle('bold');
        doc.text('Payment', 20, 150);
        doc.setFont('times');
        doc.setFontType('normal');
        doc.text('Payment method', 20, 160);
        doc.text((filteredpayment[0].paymentMethod !== null) ? filteredpayment[0].paymentMethod : '--', 100, 160);
        doc.text('Payment amount', 20, 170);
        doc.text(filteredpayment[0].amount.toString(), 100, 170);
        doc.text('Reference number', 20, 180);
        doc.text((filteredpayment[0].referenceNo !== null) ? filteredpayment[0].referenceNo : '--', 100, 180);
        doc.text('Paid date', 20, 190);
        doc.text((filteredpayment[0].paidDate !== null) ? filteredpayment[0].paidDate : '--', 100, 190);
        doc.text('Branch', 20, 200);
        doc.text((filteredpayment[0].branch !== null) ? filteredpayment[0].branch : '--', 100, 200);
        doc.text('Bank', 20, 210);
        doc.text((filteredpayment[0].bank !== null) ? filteredpayment[0].bank : '--', 100, 210);
        doc.text('Transaction status', 20, 220);
        doc.text((filteredpayment[0].transactionStatus !== null) ? filteredpayment[0].transactionStatus : '--', 100, 220);
        doc.save('valuationrequest.pdf');
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
