import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { InspectionTransportTypeService } from 'src/app/service/inspection-master-data-service/inspection-transport-type/inspection-transport-type.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inspection-transported-type',
  templateUrl: './inspection-transported-type.component.html',
  styleUrls: ['./inspection-transported-type.component.scss']
})
export class InspectionTransportedTypeComponent implements OnInit,OnDestroy {
  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  createTransportTypeForm: FormGroup;
  updateTransportTypeForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  propertyTypeList: any =[];
  activationButton: boolean;
  public createTransportTypeModel = new InspectionCommonDataModel(null,'','','','');
  public updateTransportTypeModel = new InspectionCommonDataModel(null,'','','','');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;
  TransportTypes: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionTransportTypeService: InspectionTransportTypeService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createTransportTypeForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });
    this.updateTransportTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });

    // this.loadPropertyType();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }



  //create Transport Type - start

  get f() { return this.createTransportTypeForm.controls; }

  setTransportTypeModelToSave(){
    this.createTransportTypeModel.name = this.createTransportTypeForm.get('name').value;
    this.createTransportTypeModel.description = this.createTransportTypeForm.get('description').value;
    this.createTransportTypeModel.code = this.createTransportTypeForm.get('code').value;
    this.createTransportTypeModel.dataStatus = "ACTIVE";
  }

    //save Transport Type model
  saveTransportType(){
    if(this.createTransportTypeForm.valid){

      this.setTransportTypeModelToSave();
      this.sub.add(
        this.inspectionTransportTypeService.createTransportType(this.createTransportTypeModel).subscribe(data=>{
          this.notifier.notify('success', (data as any).message);
          this.updateTheTable();
          this.reset();
        })
        );
    }else{
      this.createTransportTypeForm.markAllAsTouched();
    }

  }

  reset(){
    this.createTransportTypeForm.reset()
  }
  //create Transport Type - end

  //update Transport Type - start

    //get update controllers
  get f1() { return this.updateTransportTypeForm.controls; }

    //set data To Transport Type Type model
  setTransportType(transport:any){
    this.updateTransportTypeForm.get('id').setValue(transport.id);
    this.updateTransportTypeForm.get('name').setValue(transport.name);
    this.updateTransportTypeForm.get('description').setValue(transport.description);
    this.updateTransportTypeForm.get('code').setValue(transport.code);

    if(transport.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateTransportTypeModel.id = this.updateTransportTypeForm.get('id').value;
    this.updateTransportTypeModel.name = this.updateTransportTypeForm.get('name').value;
    this.updateTransportTypeModel.description = this.updateTransportTypeForm.get('description').value;
    this.updateTransportTypeModel.code = this.updateTransportTypeForm.get('code').value;

    if(this.activationButton == true){
      this.updateTransportTypeModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateTransportTypeModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateTransportType(){
    this.setDataToModel();
    this.sub.add(this.inspectionTransportTypeService.updateTransportType(this.updateTransportTypeModel.id, this.updateTransportTypeModel).subscribe(data=>{
      this.notifier.notify('success', (data as any).message);
      this.updateTheTable();
    }))
  }

  //update Transport Type - end

//search and view table start
  pageable(i: number) {
    this.inspectionTransportTypeService.getTransportType((i-1),10).subscribe(res=>{
      this.TransportTypes = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionTransportTypeService.getTransportType(0, 10)
      .subscribe(
        (res) => {
          this.TransportTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search Transport Type Type
    searchTransportType(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionTransportTypeService.searchTransportType(this.keyword,0,10).subscribe(res=>{
          this.TransportTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionTransportTypeService.deleteTransportType(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end
}
