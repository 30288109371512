export const CALCULATION_HEADINGS = {
  CALCULATION_HEADINGS_GIFT: {
    firstPanelHeading_before: "01. OPEN MARKET VALUE AS AT 31ST MARCH 1977",
    firstPanelHeading_after: "01. MARKET VALUE AS AT THE DATE OF THE PROPERTY ACQUIRED BY THE DONOR",
    secondPanelHeading_before: "02. OPEN MARKET VALUE OF THE PROPERTY",
    secondPanelHeading_after: "02. OPEN MARKET VALUE OF THE PROPERTY"
  },

  CALCULATION_HEADINGS_EXCHANGE: {
    firstPanelHeading_before: null,
    firstPanelHeading_after: null,
    secondPanelHeading:
      "OPEN MARKET VALUE OF THE DATE OF SUCH PROPERTY EXCHANGED"
  },

  CALCULATION_HEADINGS_TRANSFER: {
    firstPanelHeading_before: null,
    firstPanelHeading_after: null,
    secondPanelHeading: "OPEN MARKET VALUE OF THE DATE OF SUCH PROPERTY TRANSFERRED"
  },

  getHeading(selectedDate, selectedNatureOfDeed, panelNumber) {
    // console.log(selectedDate, selectedNatureOfDeed, panelNumber);
    let headings = [];
    if (selectedNatureOfDeed == "gift") {
      if (selectedDate == "before" && panelNumber == 1) {
        headings.push(CALCULATION_HEADINGS.CALCULATION_HEADINGS_GIFT.firstPanelHeading_before);
      } else if (selectedDate == "before" && panelNumber == 2) {
        headings.push(CALCULATION_HEADINGS.CALCULATION_HEADINGS_GIFT.secondPanelHeading_before);
      }
        
      if (selectedDate == "after" && panelNumber == 1) {
        headings.push(CALCULATION_HEADINGS.CALCULATION_HEADINGS_GIFT.firstPanelHeading_after);
      } else if (selectedDate == "after" && panelNumber == 2) {
        headings.push(CALCULATION_HEADINGS.CALCULATION_HEADINGS_GIFT.secondPanelHeading_after);
      }
    }

    if (selectedNatureOfDeed == "exchange") {
      headings.push(
        CALCULATION_HEADINGS.CALCULATION_HEADINGS_EXCHANGE
          .firstPanelHeading_after
      );
      headings.push(
        CALCULATION_HEADINGS.CALCULATION_HEADINGS_EXCHANGE.secondPanelHeading
      );
    }

    if (selectedNatureOfDeed == "transfer") {
      headings.push(CALCULATION_HEADINGS.CALCULATION_HEADINGS_TRANSFER.secondPanelHeading);
      headings.push(CALCULATION_HEADINGS.CALCULATION_HEADINGS_TRANSFER.secondPanelHeading);
    }

    return headings;
  }
};
