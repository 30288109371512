import { Component, OnInit, OnDestroy } from '@angular/core';
import { District } from 'src/app/model/district';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { DataService } from 'src/app/service/data.service';
import { NotifierService } from 'angular-notifier';
import { ProvinceService } from 'src/app/service/province/province.service';
import { DistrictService } from 'src/app/service/district/district.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-search-district',
  templateUrl: './search-district.component.html',
  styleUrls: ['./search-district.component.scss']
})
export class SearchDistrictComponent implements OnInit, OnDestroy {

  addDistrictForm: FormGroup;
  private sub = new SubSink();

  provinces = [];


  constructor(private districtService: DistrictService,
              private provinceService: ProvinceService,
              private formBuilder: FormBuilder,
              private notifier: NotifierService,
              private dataService: DataService) { }

  ngOnInit() {

    this.addDistrictForm = this.formBuilder.group({
      province: ['', [Validators.required]],
      code: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });

    this.sub.add(this.provinceService.getProvinces(0, 10).subscribe(
      (res) => {
        this.provinces = (res as any).data;
      }
    ));
  }
  get f() { return this.addDistrictForm.controls; }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  saveDistrict() {
    if (this.addDistrictForm.valid) {
      // const district = new District();
//
      // district.description = this.getDescription();
      // district.code = this.getCode();
      // district.province = this.getProvince();
//
      // this.sub.add(this.districtService.createDistrict(district)
        // .subscribe(response => {
          // this.notifier.notify( 'success', (response as any).message);
          // this.dataService.updateTableDistrict(true);
          // }));
//
      // this.reset();
    }
  }
  getDescription() {
    return this.addDistrictForm.get('description').value;
  }

  getCode() {
    return this.addDistrictForm.get('code').value;
  }
  getProvince() {
    return this.addDistrictForm.get('province').value;
  }

  reset() {
    this.addDistrictForm.reset();
  }
}

