import { Component, OnInit, Input, EventEmitter, Output, ViewChild, QueryList, ViewChildren } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatTabGroup } from "@angular/material/tabs";
import { NotifierService } from 'angular-notifier';
import { PDFProgressData } from 'ng2-pdf-viewer';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';

import { DataModel } from "../models/data-model";
import { ValueOfTheLand } from "../models/landValues";
import { CalculationDataProcess } from "src/app/service/calculationDataProcess/calculation-data-process.service";
import { CALCULATION_HEADINGS } from "../constant-headings/heading";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ExchangeCondominiumComponent } from "./exchange-condominium/exchange-condominium";
import { ExchangeLandBuildingComponent } from "./exchange-land-building/exchange-land-building";
import { ExchangeLandComponent } from "./exchange-land/exchange-land";
import { ReCreationComponent } from "../re-creation/re-creation.component";
import { FileUploadComponent } from 'src/app/component/dashboards/dashboard-external/assesment/file-upload/file-upload.component';
import { DocumentUploadService } from 'src/app/service/document-upload/document-upload.service';
import { CalculationService } from "src/app/service/calculation/calculation.service";
import { checkCompletedOrPaypend } from "../../Util/checkStatus";

@Component({
  selector: "exchange-calculation",
  templateUrl: "./exchange-calculation.html",
  styleUrls: ["./exchange-calculation.scss"]
})
export class ExchangeCalculationComponent implements OnInit {
  @Output() emitData: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("tabGroup", { static: false }) tabGroup: MatTabGroup;

  @Output() _saveExchangeRecreationData: EventEmitter<any> = new EventEmitter<any>();
  @Output() status: EventEmitter<any> = new EventEmitter<any>();

  checkStatusApplication  = checkCompletedOrPaypend;

  @Input()
  mainFormDataModel;

  @Input()
  selectedRecord;

  panelNumber = 1;
  partyOnePanel = 1;
  partyTwoPanel = 1;

  tab = {
    isActiveF: false,
    isActiveT: false,
    isActiveTh: false
  };

  updateLandData1: any;
  updateLandData2: any;

  updateLandBuildingData1: any;
  updateLandBuildingData2: any;

  updateCondominiumData1: any;
  updateCondominiumData2: any;

  dataModel = new DataModel();

  subModel = {
    partyOne: {
      selectedNatureOfDeed: null,
      selectedDate: null,
      considerCurrentDate: null,
      selectedPropertyType: null
    },
    partyTwo: {
      selectedNatureOfDeed: null,
      selectedDate: null,
      considerCurrentDate: null,
      selectedPropertyType: null
    }
  };

  dropdownSettings: NgMultiSelectDropDownModule = {
    unSelectAllText: "Unselect"
  };

  dropdownList = [];
  selectedItems = [];
  selectedItemsSecond = [];

  showSubPanel = {
    showCondominium: false,
    showLand: false,
    showLandBuilding: false
  };

  showSubSecondPanel = {
    showCondominium: false,
    showLand: false,
    showLandBuilding: false
  };

  stampDutyForm: FormGroup;
  partyOneValueOfTheProperty: any;
  partyTwoValueOfTheProperty: any;

  updateCondominiumData = null;
  updateLandData = null;
  updateLandBuildingData = null;

  finalDataModel: any = {
    calculationCondominiums: [],
    calculationLand: [],
    calculationLandBuilding: [],
    date: null,
    headingDateOne: null,
    headingDateTwo: null,
    natureOfDeed: null,
    propertyType: null,
    referenceNo: null,
    stampDuty: null,
    valueOfTheProperty: null
  };

  // @ViewChild(ExchangeCondominiumComponent, { static: false })
  // exchangeCondominiumComponent: ExchangeCondominiumComponent;

  // @ViewChild(ExchangeLandBuildingComponent, { static: false })
  // exchangeLandBuildingComponent: ExchangeLandBuildingComponent;

  // @ViewChild(ExchangeLandComponent, { static: false })
  // exchangeLandComponent: ExchangeLandComponent;

  @ViewChildren(ExchangeCondominiumComponent) exchangeCondominiumComponent: QueryList<ExchangeCondominiumComponent>;
  @ViewChildren(ExchangeLandBuildingComponent) exchangeLandBuildingComponent: QueryList<ExchangeLandBuildingComponent>;
  @ViewChildren(ExchangeLandComponent) exchangeLandComponent: QueryList<ExchangeLandComponent>;
  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() resetCalculation: EventEmitter<any> = new EventEmitter<any>();

  partyOneProceesData: any;
  partyTwoProceesData: any;

  @ViewChild(ReCreationComponent, { static: false })
  reCreationComponent: ReCreationComponent;
  saveData: any;

  committeeDescision: string;
  showDecisionComDocView: boolean = false;

  @ViewChild('fileUploadDecisionOfCommittee', {static: false}) fileUploadDecisionOfCommittee: FileUploadComponent;
  selectPaths = [];
  otherSelectedPaths = [];
  selectPathsCollection = [];
  errorList = [];
  partyOneType:any;
  partyTwoType:any;

  dataDone = false;
  noDocs = false;
  previewFileData: any;

  disableSaveButton: boolean;
  disableSave: boolean;

  constructor(
    private dataProcess: CalculationDataProcess,
    public formBuilder: FormBuilder,
    public apiService: CalculationService,
    private documentUploadService: DocumentUploadService,
    private notifier: NotifierService,
    private spinner: NgxSpinnerService,
    public sanitizer: DomSanitizer
  ) {
    this.disableSaveButton = false;
  }

  ngOnInit() {
    window.scroll(0, 0)

    // Checking whether a new Calculation or an existing one
    // If an existing calculation, save button is hidden
    this.disableSaveButton = JSON.parse(atob(sessionStorage.getItem('disableSave')));

    this.dropdownList = [
      { id: 1, text: "Condominium" },
      { id: 2, text: "Land" },
      { id: 3, text: "Land with Building" }
    ];
    this.selectedItems = [];
    this.selectedItemsSecond = [];

    this.stampDutyForm = this.formBuilder.group({
      stampDuty: [null, Validators.required],
      roundOffValue: [null, Validators.required],
      propertyValue: [{ value: null, disabled: true }, Validators.required]
    });
    this.clearFields();

    if (this.selectedRecord != null) {
      this.showDecisionComDocView = true;
      this.retrievDecisionOfCommitte();
      this.dataProcess.setUpdateDataToService(this.selectedRecord);

      var str = this.selectedRecord.propertyType;
      var arr = str.split(",");
      this.partyOneType = arr.splice(0,1).join("");
      this.partyTwoType = arr.join(",");

      // if (this.selectedRecord.calculationCondominiums.length > 0) {
      //   this.subModel.partyOne.selectedPropertyType = "condominium";
      //   this.updateCondominiumData1 = this.selectedRecord.calculationCondominiums[0];
      // }
      // if (
      //   this.selectedRecord.calculationLand.length > 0 &&
      //   this.subModel.partyOne.selectedPropertyType == null
      // ) {
      //   this.updateLandData1 = this.selectedRecord.calculationLand[0];
      //   this.subModel.partyOne.selectedPropertyType = "land";
      // }
      // if (
      //   this.selectedRecord.calculationLandBuilding.length > 0 &&
      //   this.subModel.partyOne.selectedPropertyType == null
      // ) {
      //   this.updateLandBuildingData1 = this.selectedRecord.calculationLandBuilding[0];
      //   this.subModel.partyOne.selectedPropertyType = "land-with-building";
      // }

      // if (
      //   this.selectedRecord.calculationCondominiums.length > 0 &&
      //   this.subModel.partyOne.selectedPropertyType != "condominium"
      // ) {
      //   this.updateCondominiumData2 = this.selectedRecord.calculationCondominiums[0];
      //   this.subModel.partyTwo.selectedPropertyType = "condominium";
      // }

      // if (
      //   this.selectedRecord.calculationLand.length > 0 &&
      //   this.subModel.partyOne.selectedPropertyType != "land"
      // ) {
      //   this.updateLandData2 = this.selectedRecord.calculationLand[0];
      //   this.subModel.partyTwo.selectedPropertyType = "land";
      // }

      // if (
      //   this.selectedRecord.calculationLandBuilding.length > 0 &&
      //   this.subModel.partyOne.selectedPropertyType != "land-with-building"
      // ) {
      //   this.updateLandBuildingData2 = this.selectedRecord.calculationLandBuilding[0];
      //   this.subModel.partyTwo.selectedPropertyType = "land-with-building";
      // }

      if(this.partyOneType == "condominium"){
        this.subModel.partyOne.selectedPropertyType = "condominium";
        this.updateCondominiumData1 = this.selectedRecord.calculationCondominiums[0];
      }else if(this.partyOneType == "land"){
        this.subModel.partyOne.selectedPropertyType = "land";
        this.updateLandData1 = this.selectedRecord.calculationLand[0];
      }else if(this.partyOneType == "land-with-building"){
        this.subModel.partyOne.selectedPropertyType = "land-with-building";
        this.updateLandBuildingData1 = this.selectedRecord.calculationLandBuilding[0];
      }

      if(this.partyTwoType == "condominium"){
        this.subModel.partyTwo.selectedPropertyType = "condominium";
        if(this.selectedRecord.calculationCondominiums.length > 1){
          this.updateCondominiumData2 = this.selectedRecord.calculationCondominiums[1];
        }else{
          this.updateCondominiumData2 = this.selectedRecord.calculationCondominiums[0];
        }
      }else if(this.partyTwoType == "land"){
        this.subModel.partyTwo.selectedPropertyType = "land";
        if(this.selectedRecord.calculationLand.length > 1){
          this.updateLandData2 = this.selectedRecord.calculationLand[1];
        }else{
          this.updateLandData2 = this.selectedRecord.calculationLand[0];
        }
      }else if(this.partyTwoType == "land-with-building"){
        this.subModel.partyTwo.selectedPropertyType = "land-with-building";
        if(this.selectedRecord.calculationLandBuilding.length > 1){
          this.updateLandBuildingData2 = this.selectedRecord.calculationLandBuilding[1];
        }else{
          this.updateLandBuildingData2 = this.selectedRecord.calculationLandBuilding[0];
        }
      }

      // if (this.selectedRecord.calculationLand.length > 1) {
      //   this.updateLandData1 = this.selectedRecord.calculationLand[0];
      //   this.updateLandData2 = this.selectedRecord.calculationLand[1];
      //   this.subModel.partyOne.selectedPropertyType = "land";
      //   this.subModel.partyTwo.selectedPropertyType = "land";
      // }

      // if (this.selectedRecord.calculationLandBuilding.length > 1) {
      //   this.updateLandBuildingData1 = this.selectedRecord.calculationLandBuilding[0];
      //   this.updateLandBuildingData2 = this.selectedRecord.calculationLandBuilding[1];
      //   this.subModel.partyOne.selectedPropertyType = "land-with-building";
      //   this.subModel.partyTwo.selectedPropertyType = "land-with-building";
      // }

      // if (this.selectedRecord.calculationCondominiums.length > 1) {
      //   this.updateCondominiumData1 = this.selectedRecord.calculationCondominiums[0];
      //   this.updateCondominiumData2 = this.selectedRecord.calculationCondominiums[1];
      //   this.subModel.partyOne.selectedPropertyType = "condominium";
      //   this.subModel.partyTwo.selectedPropertyType = "condominium";
      // }

      this.partyOnePanel = 2;
      this.partyTwoPanel = 2;

      this.stampDutyForm.get("roundOffValue").setValue((this.selectedRecord.roundOffValue).toFixed(2));
    }
  }

  clearFields() {
    this.partyOneValueOfTheProperty = null;
    this.partyTwoValueOfTheProperty = null;
    this.partyOneProceesData = null;
    this.partyTwoProceesData = null;
    this.saveData = null;
  }

  get _stampDutyForm() {
    return this.stampDutyForm.controls;
  }

  isValidField(formControl) {
    return (
      (this._stampDutyForm[formControl].touched ||
        this._stampDutyForm[formControl].dirty) &&
      this._stampDutyForm[formControl].errors
    );
  }

  onItemSelect(item: any) {}
  onSelectAll(items: any) {
    console.log(items);
  }

  changePanel(number) {
    this.panelNumber = number;
  }

  changeSubPanel(party, number) {
    if (party == 1) {
      if (this.subModel.partyOne.selectedPropertyType == null) {
        this.notifier.notify('error', 'Please select a Property type')
      } else {
        this.partyOnePanel = number;
        this.selectedItems.forEach(element => {
          if (element.id == "1") {
            this.showSubPanel.showCondominium = true;
          }
          if (element.id == "2") {
            this.showSubPanel.showLand = true;
          }
          if (element.id == "3") {
            this.showSubPanel.showLandBuilding = true;
          }
        });
      }
    }
    if (party == 2) {
      if (this.subModel.partyTwo.selectedPropertyType == null) {
        this.notifier.notify('error', 'Please select a Property type')
      } else {
        this.partyTwoPanel = number;
        this.selectedItemsSecond.forEach(element => {
          if (element.id == "1") {
            this.showSubSecondPanel.showCondominium = true;
          }
          if (element.id == "2") {
            this.showSubSecondPanel.showLand = true;
          }
          if (element.id == "3") {
            this.showSubSecondPanel.showLandBuilding = true;
          }
        });
      }
    }
  }

  getFinalValues(event) {
    if(event.index == 2)
    {
      if (this.subModel.partyOne.selectedPropertyType == "condominium") {
        // this.exchangeCondominiumComponent.emitValueOfTheProperty(1);
        this.exchangeCondominiumComponent.first.emitValueOfTheProperty(1);
      } else if (this.subModel.partyOne.selectedPropertyType == "land") {
        this.exchangeLandComponent.first.emitValueOfTheProperty(1);
      } else if (
        this.subModel.partyOne.selectedPropertyType == "land-with-building"
      ) {
        this.exchangeLandBuildingComponent.first.emitValueOfTheProperty(1);
      }

      if (this.subModel.partyTwo.selectedPropertyType == "condominium") {
        this.exchangeCondominiumComponent.last.emitValueOfTheProperty(2);
      } else if (this.subModel.partyTwo.selectedPropertyType == "land") {
        this.exchangeLandComponent.last.emitValueOfTheProperty(2);
      } else if (
        this.subModel.partyTwo.selectedPropertyType == "land-with-building"
      ) {
        this.exchangeLandBuildingComponent.last.emitValueOfTheProperty(2);
      }
    }

  }

  fetchPropertyValuesPartyOne(data) {
    this.partyOneValueOfTheProperty = data.valueOfTheProperty;
    this.partyOneProceesData = data.proceedData;

    // If this is a saved calculation, set the values to Property Value, Round off value and Stamp duty
    if (this.selectedRecord != null) {
      this.setValuesForSavedCalculations();
    } else {
      this.calculateStampDuty();
      this.checkPartyValue()
    }
  }

  fetchPropertyValuesPartyTwo(data) {
    this.partyTwoValueOfTheProperty = data.valueOfTheProperty;
    this.partyTwoProceesData = data.proceedData;

    // If this is a saved calculation, set the values to Property Value, Round off value and Stamp duty
    if (this.selectedRecord != null) {
      this.setValuesForSavedCalculations();
    } else {
      this.calculateStampDuty();
      this.checkPartyValue()
    }
  }

  setValuesForSavedCalculations() {
    let highestValue;
    if (parseInt(this.partyOneValueOfTheProperty) < parseInt(this.partyTwoValueOfTheProperty)) {
      highestValue = this.partyTwoValueOfTheProperty;
    } else if (parseInt(this.partyOneValueOfTheProperty) > parseInt(this.partyTwoValueOfTheProperty)) {
      highestValue = this.partyOneValueOfTheProperty;
    } else {
      highestValue = this.partyOneValueOfTheProperty;
    }
    this.stampDutyForm.get("propertyValue").setValue(highestValue);
    this.stampDutyForm.get("roundOffValue").setValue((this.selectedRecord.roundOffValue).toFixed(2));
    this.stampDutyForm.get("stampDuty").setValue(this.selectedRecord.stampDuty);
  }

  checkPartyValue(){
    if(this.partyOneValueOfTheProperty == null || this.partyTwoValueOfTheProperty == null || isNaN(this.partyOneValueOfTheProperty) || isNaN(this.partyTwoValueOfTheProperty) || this.partyOneValueOfTheProperty == "0.00" || this.partyTwoValueOfTheProperty == "0.00"){
      this.disableSave = false;
    }else{
      this.disableSave = true;
    }
  }

  calculateStampDuty() {
    // let highestValue =
    //   this.partyOneValueOfTheProperty > this.partyTwoValueOfTheProperty
    //     ? this.partyOneValueOfTheProperty
    //     : this.partyTwoValueOfTheProperty;

    let highestValue;
    if(parseInt(this.partyOneValueOfTheProperty) < parseInt(this.partyTwoValueOfTheProperty)){
      highestValue = this.partyTwoValueOfTheProperty;
    }else if(parseInt(this.partyOneValueOfTheProperty) > parseInt(this.partyTwoValueOfTheProperty)){
      highestValue = this.partyOneValueOfTheProperty;
    }else{
      highestValue = this.partyOneValueOfTheProperty;
    }
    console.log('highest value.....' + highestValue)

    this.stampDutyForm.get("propertyValue").setValue(highestValue);
    this.stampDutyForm.get("roundOffValue").setValue(highestValue);

    if (
      this.partyOneValueOfTheProperty > 0 &&
      this.partyTwoValueOfTheProperty > 0
    ) {
        let stampDutyCalculationValue = 0;

        if (+this.partyOneValueOfTheProperty >= +this.partyTwoValueOfTheProperty) {
          stampDutyCalculationValue = this.partyOneValueOfTheProperty;
        } else {
          stampDutyCalculationValue = this.partyTwoValueOfTheProperty;
        }
        let totalStampDuty = 0;
        let marketValue = 100000;
        let balanceStampDutyPrecentage = 4;
        // if (this.mainFormDataModel.selectedNatureOfDeed == "gift") {
        //   marketValue = 50000;
        //   balanceStampDutyPrecentage = 2;
        // }
        if (stampDutyCalculationValue > marketValue) {
          let fMarketVal = (marketValue * 3) / 100;

          let sMarketVal =
            ((stampDutyCalculationValue - marketValue) *
              balanceStampDutyPrecentage) /
            100;
          totalStampDuty = fMarketVal + sMarketVal;
          this.stampDutyForm
            .get("stampDuty")
            .setValue(totalStampDuty.toFixed(2));
        } else {
          let fMarketVal = (stampDutyCalculationValue * 3) / 100;
          this.stampDutyForm.get("stampDuty").setValue(fMarketVal.toFixed(2));
        }
    }
  }

  roundOffValueChangeEvent() {
    let totalStampDuty = 0;
    let marketValue = 100000;
    let balanceStampDutyPrecentage = 4;
    // if (this.mainFormDataModel.selectedNatureOfDeed == "gift") {
    //   marketValue = 50000;
    //   balanceStampDutyPrecentage = 2;
    // }

    if (this.stampDutyForm.get("roundOffValue").value > marketValue) {
      let fMarketVal = (marketValue * 3) / 100;

      let sMarketVal =
        ((this.stampDutyForm.get("roundOffValue").value - marketValue) *
          balanceStampDutyPrecentage) /
        100;
      totalStampDuty = fMarketVal + sMarketVal;
      // this.dataModel.secondSet.stampDuty = totalStampDuty;
      this.stampDutyForm.get("stampDuty").setValue(totalStampDuty.toFixed(2));
    } else {
      let fMarketVal =
        (this.stampDutyForm.get("roundOffValue").value * 3) / 100;
      this.stampDutyForm.get("stampDuty").setValue(fMarketVal.toFixed(2));
      // this.dataModel.secondSet.stampDuty = fMarketVal.toString();
    }
  }

  saveEchangeData() {
    let data = {
      partyOne: {
        type: this.subModel.partyOne.selectedPropertyType,
        proceedData: this.partyOneProceesData
      },
      partyTwo: {
        type: this.subModel.partyTwo.selectedPropertyType,
        proceedData: this.partyTwoProceesData
      },
      stampDutyForm: this.stampDutyForm,
      partyOneValue: this.partyOneValueOfTheProperty,
      partyTwoValue: this.partyTwoValueOfTheProperty,
      lowestValue:
        this.partyOneValueOfTheProperty > this.partyTwoValueOfTheProperty
          ? this.partyOneValueOfTheProperty
          : this.partyTwoValueOfTheProperty
    };

    this.emitData.next(data);

  }

  saveExchangeRecreationData() {
    this.stampDutyForm
      .get("roundOffValue")
      .setValue(this.finalDataModel.stampDuty);
    this.saveData.roundOffValue = this.stampDutyForm.get("roundOffValue").value;

    console.log(this.saveData);
  }

  chechStampDutyVaue(value) {
    this.saveData = value;
    console.log(value);
    if (
      this.finalDataModel.calculationCondominiums.length > 0 &&
      value.calculationCondominiums != undefined &&
      value.calculationCondominiums != null &&
      value.calculationCondominiums.length > 0
    ) {
      let match = false;
      let i = 0;
      this.finalDataModel.calculationCondominiums.forEach(element => {
        if (element.party == value.calculationCondominiums[0].party) {
          match = true;
          this.finalDataModel.calculationCondominiums[i] =
            value.calculationCondominiums[0];
          // element.calculationCondominiums = value.calculationCondominiums[0];
        }
        i++;
      });
      if (!match) {
        this.finalDataModel.calculationCondominiums.push(
          value.calculationCondominiums[0]
        );
      }
    } else {
      if (
        value.calculationCondominiums != undefined &&
        value.calculationCondominiums != null
      ) {
        this.finalDataModel.calculationCondominiums =
          value.calculationCondominiums;
      }
    }

    if (
      this.finalDataModel.calculationLand.length > 0 &&
      value.calculationLand != undefined &&
      value.calculationLand != null &&
      value.calculationLand.length > 0
    ) {
      let match = false;
      let i = 0;
      this.finalDataModel.calculationLand.forEach(element => {
        if (element.party == value.calculationLand[0].party) {
          match = true;
          this.finalDataModel.calculationLand[i] = value.calculationLand[0];
        }
        i++;
      });
      if (!match) {
        this.finalDataModel.calculationLand.push(value.calculationLand[0]);
      }
    } else {
      if (value.calculationLand != undefined && value.calculationLand != null) {
        this.finalDataModel.calculationLand = value.calculationLand;
      }
    }

    if (
      this.finalDataModel.calculationLandBuilding.length > 0 &&
      value.calculationLandBuilding != undefined &&
      value.calculationLandBuilding != null &&
      value.calculationLandBuilding.length > 0
    ) {
      let match = false;
      let i = 0;
      this.finalDataModel.calculationLandBuilding.forEach(element => {
        if (element.party == value.calculationLandBuilding[0].party) {
          match = true;
          this.finalDataModel.calculationLandBuilding[i] =
            value.calculationLandBuilding[0];
        }
        i++;
      });
      if (!match) {
        this.finalDataModel.calculationLandBuilding.push(
          value.calculationLandBuilding[0]
        );
      }
    } else {
      if (
        value.calculationLandBuilding != undefined &&
        value.calculationLandBuilding != null
      ) {
        this.finalDataModel.calculationLandBuilding =
          value.calculationLandBuilding;
      }
    }

    if (this.finalDataModel.valueOfTheProperty != null) {
      if (this.finalDataModel.valueOfTheProperty < this.checkLowestValue()) {
        this.finalDataModel.valueOfTheProperty = this.checkLowestValue();
      }
    } else {
      this.finalDataModel.valueOfTheProperty = this.checkLowestValue();
    }

    this.finalDataModel.date = value.date;
    this.finalDataModel.headingDateOne = value.headingDateOne;
    this.finalDataModel.headingDateTwo = value.headingDateTwo;
    this.finalDataModel.natureOfDeed = value.natureOfDeed;
    this.finalDataModel.propertyType = value.propertyType;
    this.finalDataModel.referenceNo = value.referenceNo;

    if (this.finalDataModel.stampDuty != null) {
      if (this.finalDataModel.stampDuty < value.stampDuty) {
        this.finalDataModel.stampDuty = value.stampDuty;
      }
    } else {
      this.finalDataModel.stampDuty = value.stampDuty;
    }

    this.saveData.roundOffValue = this.finalDataModel.valueOfTheProperty;
    this.saveData.stampDuty = this.finalDataModel.stampDuty;

    this.stampDutyForm.get("stampDuty").setValue(this.finalDataModel.stampDuty);
    this.stampDutyForm
      .get("roundOffValue")
      .setValue(this.finalDataModel.valueOfTheProperty);
  }

  checkLowestValue() {
    let finalValue: any = 0;
    if (this.saveData != null) {
      if (
        this.saveData.calculationCondominiums != null &&
        this.saveData.calculationCondominiums.length > 0 &&
        this.saveData.calculationCondominiums != undefined
      ) {
        if (this.saveData.calculationCondominiums.length > 1) {
          finalValue = (
            Number(finalValue) +
            Number(this.saveData.calculationCondominiums[1].lowestValue)
          ).toFixed(2);
        } else {
          finalValue = (
            Number(finalValue) +
            Number(this.saveData.calculationCondominiums[0].lowestValue)
          ).toFixed(2);
        }
      }
      if (
        this.saveData.calculationLand != null &&
        this.saveData.calculationLand.length > 0 &&
        this.saveData.calculationLand != undefined
      ) {
        if (this.saveData.calculationLand.length > 1) {
          finalValue = (
            Number(finalValue) +
            Number(this.saveData.calculationLand[1].lowestValue)
          ).toFixed(2);
        } else {
          finalValue = (
            Number(finalValue) +
            Number(this.saveData.calculationLand[0].lowestValue)
          ).toFixed(2);
        }
      }
      if (
        this.saveData.calculationLandBuilding != null &&
        this.saveData.calculationLandBuilding.length > 0 &&
        this.saveData.calculationLandBuilding != undefined
      ) {
        if (this.saveData.calculationLandBuilding.length > 1) {
          finalValue = (
            Number(finalValue) +
            Number(this.saveData.calculationLandBuilding[1].lowestValue)
          ).toFixed(2);
        } else {
          finalValue = (
            Number(finalValue) +
            Number(this.saveData.calculationLandBuilding[0].lowestValue)
          ).toFixed(2);
        }
      }

      if (!isNaN(finalValue)) {
        return finalValue;
      }
      return 0;
    }
  }

  enableTab(event) {
    if (event != undefined) {
      if (event.state == 1) {
        if (event.dateSelected) {
          this.tabGroup.selectedIndex = 1;
        } else {
          this.tabGroup.selectedIndex = 0;
        }
      }
      if (event.state == 2) {
        if (event.dateSelected) {
          this.tabGroup.selectedIndex = 2;
        } else {
          this.tabGroup.selectedIndex = 1;
        }
      }
    }
  }

  setDecisionOfCommitteeDocArray(images: File[]) {
    console.log(images)
    this.selectPaths.push(images);
  }

  saveDocumentsAndDecision(savedCalculationId) {
    this.fileUploadDecisionOfCommittee.outPutImagesSet();
    this.otherSelectedPaths = this.selectPaths;
    this.selectPathsCollection.push(this.otherSelectedPaths[0]);

    this.documentUploadService.uploadCalculationDocuments(this.selectPathsCollection, savedCalculationId, this.committeeDescision).subscribe(
      data => {},
      err => {
        this.notifier.notify('error', 'Document upload unsuccessful');
      }
    );
  }

  retrievDecisionOfCommitte() {
    this.apiService.getDecisionOfCommitteeByCalculationId(this.selectedRecord.id).subscribe(
      data => {
        if(data.data.decisionOfCommittee != "undefined") {
          this.committeeDescision = data.data.decisionOfCommittee;
        } else {
          this.committeeDescision = "-"
        }
      }
    )
  }

  // RETRIEVE DOCUMENTS - START
  onProgress(progressData: PDFProgressData) {
    if (progressData.loaded == progressData.total) {
      this.spinner.hide();
    }
  }

  previewFile(title) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.apiService.loadDocumentsByCalculationId(this.selectedRecord.id, title).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(() => {
          this.spinner.hide();
        }, 2000);

      } else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }
  // RETRIEVE DOCUMENTS - END

  redirectBack() {
    this.onPageCountChanged.emit(11);
  }
}
