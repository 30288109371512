import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { OpinionLetter } from '../../model/opinion-letter';

@Injectable({
    providedIn: 'root'
})
export class OpinionLetterService {

    private opinionLettertUrl = '/opinion-letter'

    constructor(private http: HttpClient) {
    }

    fetchAllOpinionLettersByValuationReqId(valuationReqId : number): Observable<any[]> {
        return this.http.get<any>(environment.api_url + this.opinionLettertUrl+"/"+valuationReqId);
    }

    saveOpinionLetter(opinionLetter : OpinionLetter): Observable<any> {
        return this.http.post<any>(environment.api_url + this.opinionLettertUrl, opinionLetter);
    }

    updateOpinionLetter(opinionLetter : OpinionLetter): Observable<any> {
        return this.http.put<any>(environment.api_url  + this.opinionLettertUrl + '/'+opinionLetter.id, opinionLetter);
    }

}
