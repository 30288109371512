import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { PDFDocumentProxy, PDFProgressData } from 'ng2-pdf-viewer';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';

import { DocumentUploadOneComponent } from 'src/app/component/document/document-upload/document-upload-one/document-upload-one.component';
import { DocumentUploadTwoComponent } from 'src/app/component/document/document-upload/document-upload-two/document-upload-two.component';
import { DocumentUploadThreeComponent } from 'src/app/component/document/document-upload/document-upload-three/document-upload-three.component';
import { DocumentUploadFourComponent } from 'src/app/component/document/document-upload/document-upload-four/document-upload-four.component';
import { DocumentUploadFiveComponent } from 'src/app/component/document/document-upload/document-upload-five/document-upload-five.component';
import { DocumentUploadSixComponent } from 'src/app/component/document/document-upload/document-upload-six/document-upload-six.component';
import { DocumentUploadFourteenComponent } from 'src/app/component/document/document-upload/document-upload-fourteen/document-upload-fourteen.component';

import { Constant } from 'src/app/util/constant';
import { DocumentUploadService } from 'src/app/service/document-upload/document-upload.service';
import { RefundFormRemarks } from 'src/app/model/refundFormRemarks';
import { RefundOpinionServiceService } from 'src/app/service/RefundOpinionService/refund-opinion-service.service';
import { refundRequestUpdate } from 'src/app/model/refundRequestUpdate';
import { FileService } from 'src/app/service/file/file.service';

@Component({
  selector: 'app-refund-forms',
  templateUrl: './refund-forms.component.html',
  styleUrls: ['./refund-forms.component.scss']
})
export class RefundFormsComponent implements OnInit {

  @ViewChild('documentUploadBankCopy', {static: false}) documentUploadBankCopy: DocumentUploadOneComponent;
  @ViewChild('documentUploadForm21', {static: false}) documentUploadForm21: DocumentUploadTwoComponent;
  @ViewChild('documentUploadForm26', {static: false}) documentUploadForm26: DocumentUploadThreeComponent;
  @ViewChild('documentUploadForm205', {static: false}) documentUploadForm205: DocumentUploadFourComponent;
  @ViewChild('documentUploadBankStatement', {static: false}) documentUploadBankStatement: DocumentUploadFiveComponent;
  @ViewChild('documentUploadOther', {static: false}) documentUploadOther: DocumentUploadSixComponent;
  @ViewChild('documentUploadDCommissioner', {static: false}) documentUploadDCommissioner: DocumentUploadFourteenComponent;

  public refundFormRemarksModel = new RefundFormRemarks();
  public refundRequestUpdate = new refundRequestUpdate('','','','','','');

  fileNumber: string;
  refundNumber: string;

  userData: any = [];
  userRoleCode: string;

  selectPaths = [];
  otherSelectedPaths = [];
  selectPathsCollection = [];
  errorList = []

  bankCopyRemark: string;
  form21Remark: string;
  form26Remark: string;
  form205Remark: string;
  bankStatementRemark: string;
  otherRemark: string;
  taxOfficerComment: string;
  deputyCommissionerComment: string;
  commissionerComment: string;

  savedFormRemarksId: any;

  showToCommissionerAndDeputyCommissioner: boolean;
  hideForassessor:boolean;
  showToCommissioner: boolean;
  showToDCommissioner: boolean;
  showToTaxOfficer: boolean;
  showToAccountant: boolean;

  dataDone = false;
  noDocs = false;
  previewFileData: any;

  constructor(
    public router: Router,
    private refundOpinionService: RefundOpinionServiceService,
    private documentUploadService: DocumentUploadService,
    private spinner: NgxSpinnerService,
    private fileService: FileService,
    private notifier: NotifierService,
    public sanitizer: DomSanitizer
  ) {
    this.showToCommissionerAndDeputyCommissioner = false;
    this.showToCommissioner = false;
    this.showToDCommissioner = false;
    this.showToTaxOfficer = false;
    this.showToAccountant = false;
   }

  ngOnInit() {
    this.fileNumber = localStorage.getItem('fileNumber');
    this.refundNumber = localStorage.getItem('refundNumber');
    this.obtainUserData();
  }

  obtainUserData() {
    this.userData = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    for (let role of this.userData.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.userRoleCode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_COMMISSIONER;
        this.showToCommissionerAndDeputyCommissioner = true;
        this.showToCommissioner = true;
        this.hideForassessor = true;
        this.retrieveRemarksAndComments();
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
        this.showToCommissionerAndDeputyCommissioner = true;
        this.showToDCommissioner = true;
        this.hideForassessor = true;
        this.retrieveRemarksAndComments();
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.userRoleCode = Constant.ROLE_ACCESSOR;
        this.showToCommissionerAndDeputyCommissioner = true;
        this.hideForassessor = false;
        break;
      } else if (role.code === Constant.ROLE_TAX_OFFICER) {
        this.userRoleCode = Constant.ROLE_TAX_OFFICER;
        this.showToTaxOfficer = true;
        break;
      } else if (role.code === Constant.ROLE_ACCOUNTANT) {
        this.userRoleCode = Constant.ROLE_ACCOUNTANT;
        this.showToAccountant = true;
        this.retrieveRemarksAndComments();
        break;
      }
    }
  }
    
  setBankCopyDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setForm21DocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setForm26DocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setForm205DocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setBankStatementDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setOtherDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setEveryChildComponentUploadingDocuments() {
    this.documentUploadBankCopy.outputDocumentsOneSet();
    this.documentUploadForm21.outputDocumentsTwoSet();
    this.documentUploadForm26.outputDocumentsThreeSet();
    this.documentUploadForm205.outputDocumentsFourSet();
    this.documentUploadBankStatement.outputDocumentsFiveSet();
    this.documentUploadOther.outputDocumentsSixSet();
  }

  checkDocuments() {
    this.errorList = [];
    // Hold every child uploading file data until final step
    if (this.documentUploadBankCopy.filesList1.length === 0) {
      this.errorList.push('Bank Copy');
    }

    if (this.documentUploadForm21.filesList2.length === 0) {
      this.errorList.push('Form 21');
    }

    if (this.documentUploadForm26.filesList3.length === 0) {
      this.errorList.push('Form 26');
    }

    if (this.documentUploadForm205.filesList4.length === 0) {
      this.errorList.push('Form 205');
    }

    if (this.documentUploadBankStatement.filesList5.length === 0) {
      this.errorList.push('Bank Statement');
    }

    if (this.errorList.length === 0) {
      this.setEveryChildComponentUploadingDocuments();
      this.otherSelectedPaths = this.selectPaths;
      this.saveDocuments();
      this.saveRefundFormRemarks();
      this.updateTaxOfficerStatus();
    } else {
      const re = /,/gi;
      const errormsg = this.errorList.toString().replace(re, '<br/>');

      Swal.fire({
        title: 'Please upload following required documents to proceed',
        icon: 'error',
        html: '<div style="color: red;text-align: left;">' + errormsg + '</div>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
          'OK',
      });
    }
  }

  saveDocuments() {
    this.selectPathsCollection.push(this.otherSelectedPaths[0]);
    this.selectPathsCollection.push(this.otherSelectedPaths[1]);
    this.selectPathsCollection.push(this.otherSelectedPaths[2]);
    this.selectPathsCollection.push(this.otherSelectedPaths[3]);
    this.selectPathsCollection.push(this.otherSelectedPaths[4]);
    this.selectPathsCollection.push(this.otherSelectedPaths[5]);

    this.documentUploadService.uploadRefundFormsDocuments(this.selectPathsCollection, localStorage.getItem('refundRequest')).subscribe(
      data => {
      },
      err => {
        this.notifier.notify('error', 'Document upload unsuccessful');
      }
    );
  }

  verifyRefundRequest() {
    Swal.fire({
      title: 'Please Confirm',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#589C0E',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Continue!'
    }).then((result) => {
      if(result.dismiss) {
        console.log("Cancel");
      } else {
        this.checkDocuments();
      }
    });
  }

  saveRefundFormRemarks() {
    this.refundFormRemarksModel.refundRequestId = localStorage.getItem('refundRequest');
    this.refundFormRemarksModel.bankCopyRemark = this.bankCopyRemark;
    this.refundFormRemarksModel.form21Remark = this.form21Remark;
    this.refundFormRemarksModel.form26Remark = this.form26Remark;
    this.refundFormRemarksModel.form205Remark = this.form205Remark;
    this.refundFormRemarksModel.bankStatementRemark = this.bankStatementRemark;
    this.refundFormRemarksModel.otherRemark = this.otherRemark;
    this.refundFormRemarksModel.taxOfficerComment = this.taxOfficerComment;

    this.refundOpinionService.saveRefundFormRemarks(this.refundFormRemarksModel).subscribe(
      data => {
      },
      err => {
        this.notifier.notify('error', 'Saving Remarks unsuccessful');
      }
    )
  }

  updateTaxOfficerStatus() {
    this.refundRequestUpdate.refundId = localStorage.getItem('refundRequest');
    this.refundOpinionService.updateTaxOfficer(this.refundRequestUpdate).subscribe(
      data => {
        Swal.fire({
          icon: 'success',
          title: 'Successfully Verified',
          showConfirmButton: false,
          timer: 2000
        });
        this.router.navigate(['/refund-internal-user-dashboard']);
      },
      err => {
        this.notifier.notify('error', 'Refund Application verification unsuccessful');
      }
    );
  }

  // RETRIEVE DOCUMENTS - START
  onProgress(progressData: PDFProgressData) {
    if (progressData.loaded == progressData.total) {
      this.spinner.hide();
    }
  }

  previewFile(title) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadDocumentsByRefundRequestId(localStorage.getItem('refundRequest'), title).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(() => {
          this.spinner.hide();
        }, 2000);

      } else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }
  // RETRIEVE DOCUMENTS - END

  retrieveRemarksAndComments() {
    this.refundOpinionService.getRefundFormRemarksAndCommentsByRefundRequestId(+localStorage.getItem('refundRequest')).subscribe(
      data => {
        this.refundFormRemarksModel = data.data;
      }
    )
  }

  // DEPUTY COMMISSIONER RECOMMEND PROCESS - START
  setDCommissionerDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  checkDeputyCommissionerDocuments() {
    this.errorList = [];
    if (this.documentUploadDCommissioner.filesList14.length === 0) {
      this.errorList.push('Deputy Commissioner Documents');
    }

    if (this.errorList.length === 0) {
      this.documentUploadDCommissioner.outputDocumentsFourteenSet();
      this.otherSelectedPaths = this.selectPaths;
      this.saveDCommissionerDocuments();
    } else {
      const re = /,/gi;
      const errormsg = this.errorList.toString().replace(re, '<br/>');
    }
  }

  saveDCommissionerDocuments() {
    this.selectPathsCollection.push(this.otherSelectedPaths[0]);

    this.documentUploadService.uploadRefundFormsDeputyCommissionerDocuments(this.selectPathsCollection, localStorage.getItem('refundRequest'), this.deputyCommissionerComment).subscribe(
      data => {
      },
      err => {
        this.notifier.notify('error', 'Document upload unsuccessful');
      }
    );
  }

  updateRefundRequestStatusForRecommendProcess() {
    this.refundRequestUpdate.refundId = localStorage.getItem('refundRequest');
    this.refundOpinionService.updateRefundRequestStatusForRecommendProcess(this.refundRequestUpdate).subscribe(
      data => {
        Swal.fire({
          icon: 'success',
          title: 'Successfully Recommended',
          showConfirmButton: false,
          timer: 2000
        });
        this.router.navigate(['/refund-internal-user-dashboard']);
      },
      err => {
        this.notifier.notify('error', 'Refund Application recommendation unsuccessful');
      }
    );
  }

  recommendRefundRequest() {
    Swal.fire({
      title: 'Please Confirm',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#589C0E',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Continue!'
    }).then((result) => {
      if(result.dismiss) {
        console.log("Cancel");
      } else {
        this.checkDeputyCommissionerDocuments();
        this.updateRefundRequestStatusForRecommendProcess();
      }
    });
  }
  // DEPUTY COMMISSIONER RECOMMEND PROCESS - END

  // COMMISSIONER APPROVE PROCESS - START
  checkCommissionerDocuments() {
    this.errorList = [];
    if (this.documentUploadDCommissioner.filesList14.length === 0) {
      this.errorList.push('Commissioner Documents');
    }

    if (this.errorList.length === 0) {
      this.documentUploadDCommissioner.outputDocumentsFourteenSet();
      this.otherSelectedPaths = this.selectPaths;
      this.saveCommissionerDocuments();
    } else {
      const re = /,/gi;
      const errormsg = this.errorList.toString().replace(re, '<br/>');
    }
  }

  saveCommissionerDocuments() {
    this.selectPathsCollection.push(this.otherSelectedPaths[0]);

    this.documentUploadService.uploadRefundFormsCommissionerDocuments(this.selectPathsCollection, localStorage.getItem('refundRequest'), this.commissionerComment).subscribe(
      data => {
      },
      err => {
        this.notifier.notify('error', 'Document upload unsuccessful');
      }
    );
  }

  updateRefundRequestStatusForApproveProcess() {
    this.refundRequestUpdate.refundId = localStorage.getItem('refundRequest');
    this.refundOpinionService.updateRefundRequestStatusForApprovalProcess(this.refundRequestUpdate).subscribe(
      data => {
        Swal.fire({
          icon: 'success',
          title: 'Successfully Approved',
          showConfirmButton: false,
          timer: 2000
        });
        this.router.navigate(['/refund-internal-user-dashboard']);
      },
      err => {
        this.notifier.notify('error', 'Refund Application Approval unsuccessful');
      }
    );
  }

  approveRefundRequest() {
    Swal.fire({
      title: 'Please Confirm',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#589C0E',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Continue!'
    }).then((result) => {
      if(result.dismiss) {
        console.log("Cancel");
      } else {
        this.checkCommissionerDocuments();
        this.updateRefundRequestStatusForApproveProcess();
      }
    });
  }
  // COMMISSIONER APPROVE PROCESS - END

  cancelForms() {
    // TODO: Implement the functionality
  }
  
}
