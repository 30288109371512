import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';
import {PropertyFacilityService} from '../../../../../../../service/property-facility/property-facility.service';
import {Property} from '../../../../../../../model/property';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-property-other-details-fr',
  templateUrl: './property-other-details-fr.component.html',
  styleUrls: ['./property-other-details-fr.component.scss']
})
export class PropertyOtherDetailsFrComponent implements OnInit {

  @Input() propertyOne: Property;
  otherDetailsForm: FormGroup;
  facilities = [];
  selectedItems = [];
  today: any;

  // Setting dropdown features
  dropdownSettings: NgMultiSelectDropDownModule = {};
  requiredField: boolean = false;
  // Validation
  onlyNumbersPattern = '^[0-9]*$';
  onlyNumbersAndDecimalValuePattern = '^[0-9_.,]*$';
  onlyCharactersNumbersPattern = '^[_A-z0-9 ]*((-|\\s)*[_A-z0-9 ])*$';
  private sub = new SubSink();

  constructor(private formBuilder: FormBuilder,
              private notifier: NotifierService,
              private facilityService: PropertyFacilityService) {
  }

  get f() {
    return this.otherDetailsForm.controls;
  }

  get rented() {
    return this.otherDetailsForm.get('rented') as FormControl;
  }

  get propertyRentedValue() {
    return this.otherDetailsForm.get('propertyRentedValue') as FormControl;
  }

  get isOpinion() {
    return this.otherDetailsForm.get('isOpinion') as FormControl;
  }

  get opinionNo() {
    return this.otherDetailsForm.get('opinionNo') as FormControl;
  }

  ngOnInit() {

    // Loading Facilities
    this.sub.add(this.facilityService.getAll()
      .subscribe(
        (res) => {
          this.facilities = (res as any).data;
        }
      ));

    if (sessionStorage.getItem('valuationUpdate') === 'true') {
      this.propertyOne.propertyFacilities == null ? this.selectedItems = [] :this.selectedItems = this.propertyOne.propertyFacilities; 
    } else {
      this.propertyOne.propertyFacilities = [];
    }

    // Setting of dropdown list
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 5,
      allowSearchFilter: false
    };
    this.setStatus();


    this.otherDetailsForm = this.formBuilder.group({
      facility: [this.selectedItems, [Validators.required]],
      rented: [this.propertyOne.propertyRented, [Validators.required]],
      propertyRentedValue: [{
        value: this.propertyOne.propertyRentedValue,
        disabled: true
      }, [Validators.required, Validators.pattern(this.onlyNumbersAndDecimalValuePattern), Validators.min(1)]],
      decidedPurchaseValue: [this.propertyOne.decidedPurchaseValue, [Validators.required, Validators.pattern(this.onlyNumbersAndDecimalValuePattern), Validators.min(1)]],
      relevantDateOfMarketValue: [this.propertyOne.dateForMarketValue],
      bankValuation: [this.propertyOne.bankValuation, [Validators.pattern(this.onlyNumbersAndDecimalValuePattern)]],
      isOpinion: [this.propertyOne.prevOpinion, [Validators.required]],
      opinionNo: [{
        value: this.propertyOne.prevOpinionNo,
        disabled: true
      }, [Validators.required]],
    });

    // Checking data to enable fields
    if (this.propertyRentedValue.value != null && this.propertyRentedValue.value > 0) {
      this.propertyRentedValue.enable();
    } else {
      this.propertyRentedValue.disable();
      this.propertyRentedValue.setValue(null);
    }

    if (this.opinionNo.value != null && this.opinionNo.value > 0) {
      this.opinionNo.enable();
    } else {
      this.opinionNo.disable();
      this.opinionNo.setValue(null);
    }
    this.today = new Date();
  }

  setStatus() {
    (this.selectedItems.length > 0) ? this.requiredField = true : this.requiredField = false;
  }

  onItemSelect(item: any) {
    console.log(item);
    console.log(this.facilities);
    this.setClass();
  }

  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.facilities);
    this.setClass();
  }

  setClass() {
    this.setStatus();
    if (this.selectedItems.length > 0) {
      return 'validField'
    }
    else {
      return 'invalidField'
    }
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  onDeSelectAll(items: any) {
    console.log(items);
  }

  shareCheckedList(item: any[]) {
    console.log(item);
  }

  shareIndividualCheckedList(item: {}) {
    console.log(item);
  }

  saveOtherDetails(): boolean {
    this.otherDetailsForm.markAllAsTouched();
    if (this.otherDetailsForm.valid) {
      this.setPropertyOtherDetails();
      return true;
    } else {
      return false;
    }
  }

  setPropertyOtherDetails(): void {
    this.propertyOne.propertyFacilities = this.selectedItems;
    this.propertyOne.propertyRented = this.otherDetailsForm.get('rented').value;
    this.propertyOne.prevOpinion = (this.otherDetailsForm.get('isOpinion').value);

    this.propertyOne.propertyRentedValue = this.otherDetailsForm.get('propertyRentedValue').value;
    this.propertyOne.decidedPurchaseValue = Number(this.otherDetailsForm.get('decidedPurchaseValue').value);
    this.propertyOne.dateForMarketValue = this.today;
    this.propertyOne.bankValuation = Number(this.otherDetailsForm.get('bankValuation').value);
    this.propertyOne.prevOpinionNo = this.otherDetailsForm.get('opinionNo').value;
    if (!this.propertyOne.prevOpinionNo) {
      this.propertyOne.prevOpinionNo = 0;
    }
    if (!this.propertyOne.propertyRentedValue)
      this.propertyOne.propertyRentedValue = 0;
    localStorage.setItem('other', JSON.stringify(this.propertyOne));
  }

  onChangeRent(): void {
    if (this.rented.value === 1) {
      this.propertyRentedValue.enable();
    } else {
      this.propertyRentedValue.setValue(null);
      this.propertyRentedValue.disable();
    }
  }

  onChangeOpinion(data) {
    if (data === 'true') {
      this.opinionNo.enable();
    } else {
      this.opinionNo.disable();
      this.opinionNo.setValue(null);
    }
  }

  onChangeRented(data) {
    if (data === 'true') {
      this.propertyRentedValue.enable();
    } else {
      this.propertyRentedValue.disable();
      this.propertyRentedValue.setValue(null);
    }
  }

}
