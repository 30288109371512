export class GramaniladariDivisionModel{

  id: number;
  code: string;
  description: string;
  district: number;

  constructor(
    id: number,
    code: string,
    description: string,
    district: number,
  ){
    this.id = id;
    this.code = code;
    this.description = description;
    this.district = district;
  }
}
