import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NotifierService} from 'angular-notifier';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BankService {

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  constructor(private http: HttpClient,
              private notifier: NotifierService) {
  }

  createBank(bank): Observable<any> {
    return this.http.post(environment.api_url + '/banks/save', bank);
  }

  deleteBank(bankId): Observable<any> {
    return this.http.delete(environment.api_url + '/banks/delete/' + bankId);
  }

  getAll(): Observable<any> {
    return this.http.get(environment.api_url + '/banks/all');
  }

  getBanks(number1: number, number2: number): Observable<any> {
    return this.http.get(environment.api_url + '/banks'  + '?offset=' + number1 + '&limit=' + number2 + '');
  }

  searchBank(keyword: string, selectedPageNo: number, limit: number): Observable<any> {

    const params = {
      params: {
        offset: selectedPageNo.toString(),
        limit: limit.toString(),
        keyword
      }
    };

    return this.http.get(environment.api_url + '/banks/search',params);
  }

  updateBank(id: any, bank): Observable<any> {
    return this.http.post(environment.api_url + '/banks/save', bank);
  }
}
