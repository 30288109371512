import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-view-appeal-request',
  templateUrl: './view-appeal-request.component.html',
  styleUrls: ['./view-appeal-request.component.scss']
})
export class ViewAppealRequestComponent implements OnInit {

  public valuationRequestId: any;
  public selectSideNav: number;

  constructor(
    public route: ActivatedRoute,
    public router: Router
  ) {
  }

  ngOnInit() {
    this._prepare();
  }

  // Initialize
  _prepare() {
    // Obtaining the valuation request id from url
    this.valuationRequestId = this.route.snapshot.paramMap.get('id');

    // initializing
    this.selectSideNav = 1;
  }

  // Returning to the previous page
  backToList() {
    this.router.navigate(['/view-request/' + this.route.snapshot.paramMap.get('id')]);
  }
}
