import {Component, OnInit, ViewChild} from '@angular/core';
import {ViewCompanyCategoryComponent} from './view-company-category/view-company-category.component';

@Component({
  selector: 'app-company-category',
  templateUrl: './company-category.component.html',
  styleUrls: ['./company-category.component.scss']
})
export class CompanyCategoryComponent implements OnInit {

  @ViewChild(ViewCompanyCategoryComponent, {static: false})
  private viewCompanyCategoryComponent: ViewCompanyCategoryComponent;
  keyword: string;

  constructor() { }

  ngOnInit() {
  }

   triggerSearch(event: any) {
    if (this.keyword === '') {
      this.viewCompanyCategoryComponent.onUpdated(true);
    } else {
      this.viewCompanyCategoryComponent.searchCompanyCategory(this.keyword);
    }
  }
}
