import {Component, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ValuationRequestService} from 'src/app/service/valuation-request/valuation-request.service';
import {DomSanitizer} from '@angular/platform-browser';
import {UserService} from 'src/app/service/user/user.service';
import {Constant} from 'src/app/util/constant';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PermissionLetter} from 'src/app/model/permission-letter';
import {SiteVisitSchedule} from 'src/app/model/siteVisitSchedule';
import {HttpClient} from '@angular/common/http';
import * as L from 'leaflet';
import {InspectionCommonService} from 'src/app/service/inspection-common/inspection-common.service';
import {NotifierService} from 'angular-notifier';
import {NotificationModel} from 'src/app/model/notification-model';
import {NotificationService} from 'src/app/service/notification/notification.service';
import {PropertyService} from 'src/app/service/property/property.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { checkCompletedOrPaypend } from '../view-requests-internal-user/Util/checkStatus';


@Pipe({name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {
  }

  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-site-visit-schedule',
  templateUrl: './site-visit-schedule.component.html',
  styleUrls: ['./site-visit-schedule.component.scss']
})
export class SiteVisitScheduleComponent implements OnInit {

  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() status: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('popupSuccessMessage', null) private popupMigrated: TemplateRef<object>;
  @ViewChild('popupSuccessMessage1', null) private popupMigrated1: TemplateRef<object>;


  @Input()
  valuationRequestId: number;
  @Input()
  isAppeal:boolean;
  @Input()
  permissionLetters: PermissionLetter[];
  @Input()
  isReject:boolean;
  @Input()
  valuationRequestStatus: string;

  appealCount:number;
  public permissionLetter = new PermissionLetter();
  siteVisitSchedules: SiteVisitSchedule[];
  public siteVisitSchedule = new SiteVisitSchedule();
  permissionLetterContent: any;
  permissionLetterHeader: string;
  selectedLetter: string;

  @Input()
  propertyName: string;

  scheduleDate: any;
  scheduleTime: any;
  propertyVenue: string;
  inspectionSummary: any;
  userlist: any = [];
  userlist1: any = [];
  siteVisitScheduleResource: any = [];
  resourcesList: any = [];
  resourcesList1: any = [];
  dropdownSettings: NgMultiSelectDropDownModule;
  selectedResources = [];
  locationDetails: any;
  longitude: any;
  latitude: any;
  userList: any = [];
  deedNatureValue: any;
  commissionerName: string;
  siteVisitScheduleAdded: boolean;
  propertyDetails: any;
  minDate: any;
  showAllocateResources: boolean;
  systemDate: any;
  isUpcomingSchedule: boolean;
  showSecondPermissionLetterButton: boolean;
  disableSaveButton: boolean;

  public openLetter: boolean;

  public initialview: boolean;

  public fileNumberFormat: string;

  public listSiteVisitScheduleList: any = [];

  public generatedFileNumber: string;
  valuationdata: any = [];
  requestID: number;

  public model = new SiteVisitSchedule();

  public notificationModel = new NotificationModel("", "", "", "", "", "", "");
;

  public selectedResourceNames: any = [];

  public filterdSitevisitSchedule:any = [];

  fileNumberFormatAvailable: boolean = true;

  checkStatusApplication  = checkCompletedOrPaypend;

  constructor(
    public modalService: NgbModal,
    private valuationRequestService: ValuationRequestService,
    public userService: UserService,
    private http: HttpClient,
    public inspectionCommonService: InspectionCommonService,
    private notifier: NotifierService,
    public notificationService: NotificationService,
    private route: ActivatedRoute,
    private propertyService: PropertyService
  ) {
    this.initialview = true;
    this.siteVisitScheduleAdded = false;
    this.showAllocateResources = true;
    this.isUpcomingSchedule = false;
    this.showSecondPermissionLetterButton = true;
    this.disableSaveButton = false;
    this.appealCount = 0;
  }


  editorConfig: AngularEditorConfig = {
    editable: true,
    toolbarHiddenButtons: [
      [
        'insertImage',
        'insertVideo'
      ]
    ]
  };

  ngOnInit() {
    this.valuationdata = JSON.parse(sessionStorage.getItem('valuationRequest'));
    this.requestID = (this.valuationdata.id);
    this.pad(this.valuationdata.id, 7);

    this.fileNumberFormat = localStorage.getItem('fileNumberFormat');
    if (this.fileNumberFormat == "null" || this.fileNumberFormat == '') {
      this.fileNumberFormatAvailable = false;
    }

    this.openPopupMessage(this.popupMigrated);
    this._prepare();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'fullName',
      unSelectAllText: 'Unselect'
    };
    this.getSearchDetails('Colombo');
    this.listSiteVisitSchedule();
    this.listPermissionLetters();
    this.getPropertyDetails();

    this.minDate = new Date();
    let date = ('0' + this.minDate.getDate()).slice(-2);
    let month = ('0' + (this.minDate.getMonth() + 1)).slice(-2);
    let year = this.minDate.getFullYear();
    this.systemDate = year + '-' + month + '-' + date;
    window.scroll(0, 0);

    localStorage.removeItem('selectedSiteVisitScheduleId');
    localStorage.removeItem('lastSiteVisitSceduleId');

  }

  pad(num: number, size: number) {
    let s = num + '';
    while (s.length < size) {
      s = '0' + s;
    }
    this.generatedFileNumber = s;
    if(this.isAppeal == true){
    this.generatedFileNumber = '#A' + this.generatedFileNumber;
    }else{
    this.generatedFileNumber = '#VR' + this.generatedFileNumber;
    }
  }

  listSiteVisitSchedule() {
    this.inspectionCommonService.siteVisitScheduleList(this.requestID).subscribe(
      data => {
        this.listSiteVisitScheduleList = data.data;
      }
    );
  }

  getAppealCount(){
    this.valuationRequestService.getAppealCount(this.route.snapshot.paramMap.get('id')).subscribe(data=>{
      this.appealCount = data.data;
    })
  }

  getSearchDetails(location: String) {
    this.http.post<any>('https://nominatim.openstreetmap.org/search?q=' + location + '&format=geojson', {title: 'Angular POST Request Example'}).subscribe(data => {
      this.locationDetails = data;
      console.log(this.locationDetails);
      if (this.locationDetails.features.length > 0) {
        this.longitude = this.locationDetails.features[0].geometry.coordinates[0];
        this.latitude = this.locationDetails.features[0].geometry.coordinates[1];
        console.log('lon : ' + this.longitude);
        console.log('lat : ' + this.latitude);
        document.getElementById('geomap').innerHTML = "<div id='map' style='width: 100%; height: 100%;'></div>";
        var map = new L.Map('map');
        map.setView(new L.LatLng(this.latitude, this.longitude), 9);
        map.zoomIn(9);
        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
          attribution: ''
        }).addTo(map);
        L.marker([this.latitude, this.longitude]).addTo(map).bindPopup(this.locationDetails.features[0].properties.display_name).openPopup();
      } else {
        document.getElementById('geomap').innerHTML = '<div id=\'map\' style=\'width: 100%; height: 100%;\'></div>';
        var map = new L.Map('map');
        map.setView(new L.LatLng(6.9270786, 79.861243), 9);
      }
    });
  }

  updatedGeolocation(event) {
    if (event === '' || event == undefined) {
      this.siteVisitSchedule.propertyVenue = 'Colombo';
    }
    this.http.post<any>('https://nominatim.openstreetmap.org/search?q=' + this.siteVisitSchedule.propertyVenue + '&format=geojson', { title: 'Angular POST Request Example' }).subscribe(data => {
      this.locationDetails = data;
      console.log(this.locationDetails);
      if (this.locationDetails.features.length > 0) {
        this.longitude = this.locationDetails.features[0].geometry.coordinates[0];
        this.latitude = this.locationDetails.features[0].geometry.coordinates[1];
        document.getElementById('geomap').innerHTML = "<div id='map' style='width: 100%; height: 100%;'></div>";
        var map = new L.Map('map', { attributionControl: false });
        map.setView(new L.LatLng(this.latitude, this.longitude), 9);
        map.zoomIn(9);
        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
          attribution: ''
        }).addTo(map);
        L.marker([this.latitude, this.longitude]).addTo(map).bindPopup(this.locationDetails.features[0].properties.display_name).openPopup();
      } else {
        document.getElementById('geomap').innerHTML = "<div id='map' style='width: 100%; height: 100%;'></div>";
        var map = new L.Map('map', { attributionControl: false });
        map.setView(new L.LatLng(6.9270786, 79.861243), 9);
      }
    })
  }

viewInfo(site) {
  if(site.property_name == 'null'){
    this.siteVisitSchedule.propertyName ='';
  }else{
    this.siteVisitSchedule.propertyName = site.property_name;
  }
  this.scheduleDate = site.schedule_date;
  this.scheduleTime = site.schedule_time;
  this.siteVisitSchedule.propertyVenue = site.property_venue;
  if(site.inspection_summary == 'null'){
  this.inspectionSummary = '';
  }else{
  this.inspectionSummary = site.inspection_summary;
  }
  this.showAllocateResources = false;

  if(site.schedule_date > this.systemDate) {
    localStorage.setItem('selectedSiteVisitScheduleId',site.id);
    this.prepareLetter();
    this.isUpcomingSchedule = true;
    this.showSecondPermissionLetterButton = false;
    this.disableSaveButton = true;
  }
  this.getSearchDetails(site.property_venue);


}

  _prepare() {
    this.getAppealCount();
    this.retrieveUserList();
  }

  prepareLetter(){
    this.permissionLetterHeader =
    '   <div style="font-size: 11px; line-height:1.3; width: 100%; font-family: \"Roboto\", sans-serif; position: relative; font-weight: 400; margin: 0; padding: 0;">  '  +
    '           <div style="padding: 0px 20px;">  '  +
    '               <header style="border-bottom: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    '                 <div style="display: flex; width: 100%;">  '  +
    '                   <div style="width: 15%; display: flex; align-items: right;"><img src="/assets/images/emblm_black_white.jpg" style="height: 120px;"></div>  '  +
    '                   <div style="width: 85%; margin-top: 15px; text-align: justify;"> ' +
    '                     <span> ' +
    '                        <span style="font-size: 16px; display: flex; align-items: center; width: 100%; font-weight: 900;"><span style="width: 43%; test-align: justify">ආදායම් දෙපාර්තමේන්තුව</span><span style="width: 2%; test-align: center"> - </span><span style="width: 2%; test-align: center"></span><span style="test-align: left">බස්නාහිර පළාත් සභාව</span></span>  ' +
    '                        <span style="font-size: 15px; display: flex; align-items: center; width: 100%; font-weight: 900;"><span style="width: 43%; test-align: left">இறைவரித் திணைக்களம்</span><span style="width: 2%; test-align: center"> - </span><span style="width: 2%; test-align: center"></span><span style="test-align: left">மேல் மாகாண சபை</span></span> ' +
    '                        <span style="font-size: 14px; display: flex; align-items: center; width: 100%; font-weight: 900;"><span style="width: 43%; test-align: left">DEPARTMENT OF REVENUE</span><span style="width: 2%; test-align: center"> - </span><span style="width: 2%; test-align: center"></span><span style="test-align: left">WESTERN PROVINCIAL COUNCIL</span></span>  ' +
    '                     <span><br> ' +
                          // ඩෙන්සිල් කොබ්බැකඩුව මාවත and මගේ අංකය division
    '                     <div style="display: flex; width: 100%;">  '  +
                            // ඩෙන්සිල් කොබ්බැකඩුව මාවත
    '                       <div style="width: 62%; vertical-align: top;"> ' +
    '                         <span style="font-size: 11px; font-weight: 600;">No.204, ඩෙන්සිල් කොබ්බැකඩුව මාවත, බත්තරමුල්ල. </span>  '  +
    '                         </br> ' +
    '                         <span style="font-size: 10px;">இல.204, டென்சில் கொப்பேகடுவ மாவத்தை, பத்தரமுல்ல. </span>  '  +
    '                         </br> ' +
    '                         <span style="font-size: 10px;">No.204, Denzil Kobbekaduwa Mawatha, Battaramulla. </span>  '  +
    '                       </div> ' +
                            // මගේ අංකය
    '                       <div style="width: 38%; line-height: 12px;">  ' +
    '                         <div style="width: 100%; display: flex; align-items: center;">  ' +
    '                           <div style="width: 27%; font-size: 9px;">  ' +
    '                             <span> මගේ අංකය </span>  '  +
    '                             </br> ' +
    '                             <span> எனது இல </span>  '  +
    '                             </br> ' +
    '                             <span> My No. </span>  '  +
    '                           </div>  '  +
    '                           <div style="width: 73%;"> ' +
    '                             <span style="font-size: 25px;"> } <span style="font-size: 9px;">$51@</span> </span> ' +
    '                           </div>  '  +
    '                         </div>  '  +
    '                       </div> ' +
    '                     </div> ' +
    '                   </div> ' +
    '                 </div>  '  +
                      // Contact details + ඔබේ අංකය + දිනය
    '                 <div style="display: flex; align-items: center; width: 100%; margin-bottom: 10px;">  '  +
                        // Contact details
    '                   <div style="width: 60%;"> ' +
    '                     <span style="font-size: 11px; font-weight: 600;"> ආදායම් කොමසාරිස් / இறைவரி ஆணையாளர் / </span> ' +
    '                     <br>  ' +
    '                     <span style="font-size: 11px; font-weight: 600;"> Commissioner of Revenue </span> ' +
    '                     <br/> ' +
    '                     <div style="display: flex; align-items: center; width: 100%;">  '  +
    '                       <div style="width: 50%; line-height: 14px;"> ' +
    '                         <span> දුරකථන / தொலைபேசி / Telephone </span> ' +
    '                         <br/> ' +
    '                         <span> ෆැක්ස් / தொலை நகல் / Fax  </span> ' +
    '                       </div>  '  +
    '                       <div style="width: 50%;"> ' +
    '                         <span style="font-size: 25px; padding-right: 5px;">} <span style="font-size: 12px;">0112 077270</span></span> ' +
    '                       </div>  '  +
    '                     </div>  '  +
    '                   </div>  '  +
    '                   <div style="width: 7.50%;"> ' +
    '                   </div> ' +
                        // ඔබේ අංකය + දිනය
    '                   <div style="width: 32.50%;"> ' +
    '                     <div style="width: 100%; display: flex; align-items: center;">  ' +
    '                       <div style="width: 27%; font-size: 9px;">  ' +
    '                         <span> ඔබේ අංකය </span>  '  +
    '                         </br> ' +
    '                         <span> உமது இல </span>  '  +
    '                         </br> ' +
    '                         <span> Your No. </span>  '  +
    '                       </div>  '  +
    '                       <div style="width: 73%;"> ' +
    '                         <span style="font-size: 25px; padding-right: 5px;"> } <span style="font-size: 9px;">$52@</span></span> ' +
    '                       </div>  '  +
    '                     </div>  '  +
    '                     <div style="width: 100%; display: flex; align-items: center; margin-top: 5px;">  ' +
    '                       <div style="width: 27%; font-size: 9px;">  ' +
    '                         <span> දිනය </span>  '  +
    '                         </br> ' +
    '                         <span> திகதி </span>  '  +
    '                         </br> ' +
    '                         <span> Date </span>  '  +
    '                       </div>  '  +
    '                       <div style="width: 73%;"> ' +
    '                         <span style="font-size: 25px; padding-right: 5px;"> } <span style="font-size: 9px;">$50@</span></span></span> ' +
    '                       </div>  '  +
    '                     </div>  '  +
    '                   </div>  '  +
    '                 </div>  '  +
    '               </header>  '  +
    '           </div>  ' +
    '   </div>  ';

    this.permissionLetterContent =  '   <div style="line-height:1.3; width: 100%; font-family: \"Roboto\", sans-serif; font-weight: 400; margin: 0; padding: 0;">  '  +
    '           <div style="padding: 0px 20px;">  '  +
    '               <main>  '  +
    '                   <div style="padding-top: 5px">  '  +
    '                       <div style="text-align: center; font-style: normal; font-weight: bold; text-decoration: underline; font-size: 25px;">  '  +
    '                           <p>Letter Of Authorization</p>  '  +
    '                       </div>  '  +
    '                       <div style="font-size: 20px;">  '  +
    '                           <p>I <strong> $3@ </strong>, Commissioner of Revenue / Deputy Commissioner of Revenue Department Western Province.  Senior Assessor / Assessor   '  +
    '                            Mr/Mrs/Miss <strong> $4@ </strong> '+
    ' and Senior Tax Officer/Senior Tax Officer Mr/Mrs/Miss <strong> $7@ </strong> are' +
    '                            hereby authorized to act on property at <strong> $5@ </strong> and '+
    '                            situated with in <strong> $6@ </strong> (M/C, U/C, P/S) area. Under Power vested me Section 54(3) in Financial Statute of the'+
    '                            Western Province No. 06 of 1990.</p>'+
    '                           <div style="float: right">  <p>  ...............................................................<br> Commissioner of Revenue/ Deputy Commissioner</p>'  +
    '                           </div>  '  +
    '                           <div  style="padding-top: 75px; font-size: 15px;"><p>54 (2) The commissioner may require any officer in a public office or corporation or bank, or approved credit agency before whom any instrument chargeable with duty is produced or '+
    '                           comes in performance of his functions;</p> '  +
    '                            <p>   (a) to examine such instrument in order to ascertain whether it is duly stamped; </p>  '  +
    '                            <p>   (b) to impound such instrument. </p>  '  +
    '                            <p>  54 (3) Where any person is required under subsection (2) to examine or impound any instrument, it shall be the duty of such person to impound or examine such instrument, '+
    '                                 as the case may be, and take such further step as may be required by the Commissioner. </p></div> '  +
    '                       </div>  '  +
    '                   </div>  '  +
    '   				        <div>  '  +
    '   					         <table style=" width: 100%; border: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    '   						           <tbody>  '  +
    '   							            <tr style="font-size: 15px;">  '  +
    '                                 <td style="padding-left: 15px; border-right: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    '   								                  නියෝජ්‍ය ආදායම් කොමසාරිස් <br>  '  +
    '   								                  Deputy Commissioner of Revenue <br>  '  +
    '   								                  Telephone : 0112077235<br>  '  +
    '   								                  Fax       : 0112077235   '  +
    '   							                </td>  '  +
    '   							                <td style="padding-left: 15px; border-right: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    '   								                  ජ්‍යෙෂ්ඨ තක්සේරුකරු  <br>  '  +
    '   								                  Senior Assessor<br>  '  +
    '   								                  Telephone : 0112077163<br>  '  +
    '   								                  Fax       : 0112078964  '  +
    '   							                 </td>  '  +
    '                              </tr>  '  +
    '   						         </tbody>  '  +
    '   					        </table>  '  +
    '  				          </div>  ' ;
    '               </main>  '  +
    '           </div>  '  +
    '      </div>  '



    this.getPermissionLetterData();
  }

  getPermissionLetterData() {
    this.permissionLetterHeader = this.permissionLetterHeader.replace('$50@', this.scheduleDate);
    if(localStorage.getItem('selectedSiteVisitScheduleId') != null && localStorage.getItem('selectedSiteVisitScheduleId') != undefined){
      this.permissionLetterHeader = this.permissionLetterHeader.replace('$1@', localStorage.getItem('selectedSiteVisitScheduleId'));
    }else{
      this.permissionLetterHeader = this.permissionLetterHeader.replace('$1@', localStorage.getItem('lastSiteVisitSceduleId'));
    }
    this.permissionLetterHeader = this.permissionLetterHeader.replace('$51@', this.fileNumberFormat);
    this.permissionLetterHeader = this.permissionLetterHeader.replace('$52@', this.generatedFileNumber);

    this.userService
      .getUsersByRoleCode(Constant.ROLE_COMMISSIONER)
      .subscribe((data) => {
        this.userlist1 = data.data.userList;
        this.resourcesList1 = this.userlist1
          .map((commissioner) => {
            commissioner.commissionerName =
              commissioner.firstName + " " + commissioner.lastName;
            this.permissionLetterContent = this.permissionLetterContent.replace(
              "$3@",
              commissioner.commissionerName
            );
            return commissioner;
          });
      });

    if(localStorage.getItem('selectedSiteVisitScheduleId') != null && localStorage.getItem('selectedSiteVisitScheduleId') != undefined ){
      this.selectedResourceNames = [];
      this.permissionLetterContent = this.permissionLetterContent.replace('$5@', this.siteVisitSchedule.propertyName);
      this.permissionLetterContent = this.permissionLetterContent.replace('$6@', this.siteVisitSchedule.propertyVenue);

      this.filterdSitevisitSchedule = this.listSiteVisitScheduleList.filter(i=>(i['id'] === localStorage.getItem('selectedSiteVisitScheduleId')));
      this.filterdSitevisitSchedule.forEach(element => {
        this.selectedResourceNames.push(element.first_name+ ' ' + element.last_name + ' ')
      });
      this.permissionLetterContent = this.permissionLetterContent.replace('$4@', this.selectedResourceNames);
      this.permissionLetterContent = this.permissionLetterContent.replace('$7@', '............................................');
    }else{
      this.propertyService.findPropertyByValuationRequest(this.valuationRequestId).subscribe(
        data => {
          console.log(data.data.nameOfTheProperty);
          this.permissionLetterContent = this.permissionLetterContent.replace('$5@', this.siteVisitSchedule.propertyName);
          this.permissionLetterContent = this.permissionLetterContent.replace('$6@', this.siteVisitSchedule.propertyVenue);
          // if (data.data.nameOfTheProperty != null && data.data.nameOfTheProperty != undefined) {
          //   this.permissionLetterContent = this.permissionLetterContent.replace('$5@', data.data.nameOfTheProperty);
          // } else {
          //   this.permissionLetterContent = this.permissionLetterContent.replace('$5@', this.siteVisitSchedule.propertyName);
          // }

          // if ((data.data.addressLine1 != null && data.data.addressLine1 != undefined) && (data.data.addressLine2 != null && data.data.addressLine2 != undefined)) {
          //   if(data.data.addressLine2 != null && data.data.addressLine2 != undefined){
          //     this.permissionLetterContent = this.permissionLetterContent.replace('$6@', data.data.addressLine1 +' '+ data.data.addressLine2);
          //   }else{
          //     this.permissionLetterContent = this.permissionLetterContent.replace('$6@', data.data.addressLine1);
          //   }

          // } else {
          //   this.permissionLetterContent = this.permissionLetterContent.replace('$6@', this.siteVisitSchedule.propertyVenue);
          // }
        }
      );
      this.permissionLetterContent = this.permissionLetterContent.replace('$4@', this.selectedResourceNames);

      this.permissionLetterContent = this.permissionLetterContent.replace('$7@', '............................................');
    }
  }

  retrieveUserList() {
    this.userService.retrieveMainInternalUserList().subscribe((data) => {
      this.userlist = data.data.userList;
      this.resourcesList = this.userlist
        .map((resource) => {
          resource.fullName = resource.firstName + " " + resource.lastName;
          return resource;
        });
    });
  }

  openPopupMessage(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static'});
  }

  public stayOnThisPage(): void {
    this.modalService.dismissAll();
  }

  public redirectToInspection(): void {
    this.modalService.dismissAll();
    this.onPageCountChanged.emit(10);
  }

  public redirectComponent(): void {
    this.onPageCountChanged.emit(10);
  }

  public redirectPreviousComponent(): void {
    this.onPageCountChanged.emit(8);
  }

  savePermissionLetter() {
    if(this.permissionLetterContent != ""){
      this.permissionLetter.valuationReqId = this.valuationRequestId;
      this.permissionLetter.letterContent = this.permissionLetterHeader + this.permissionLetterContent;
      this.valuationRequestService.createPermissionLetter(this.permissionLetter).subscribe(
        data => {
          this.prepareLetter();
          this.permissionLetters.push(this.permissionLetter);
        }
      );
    }else{
      this.notifier.notify('error', 'Cannot save an empty letter');
    }

    // this.permissionLetter.valuationReqId = this.valuationRequestId;
    // this.permissionLetter.letterContent = this.defaultContent + this.permissionLetterContent;
    // this.valuationRequestService.createPermissionLetter(this.permissionLetter).subscribe(
    //   data => {
    //     this.permissionLetters.push(this.permissionLetter);
    //     this.permissionLetterContent = "";
    //     console.log('*********** ', data);
    //   }
    // )
  }

  openPermissionLetter(letter) {
    this.selectedLetter = letter.letterContent;
    console.log('*********** ', letter);
  }

  listPermissionLetters() {
    this.valuationRequestService.findPermissionLettersByValuationRequest(this.valuationRequestId).subscribe(
      data => {
        this.permissionLetters = data.data;
      }
    );
  }

  saveSiteVisitSchedule() {
    this.disableSaveButton = true;
    this.propertyService.findPropertyByValuationRequest(this.valuationRequestId).subscribe(
      data => {
        this.propertyDetails = data.data;
        this.getSearchDetails(data.data.addressLine2);
      });

        // this.siteVisitSchedule.propertyName = this.propertyName;
        // this.siteVisitSchedule.propertyVenue = this.propertyVenue;
        this.siteVisitSchedule.valuationReqId = this.valuationRequestId;
        this.siteVisitSchedule.scheduleDate = this.scheduleDate;
        this.siteVisitSchedule.scheduleTime = this.scheduleTime;
        this.siteVisitSchedule.inspectionSummary = this.inspectionSummary;
        this.siteVisitSchedule.siteVisitScheduleResource = [];
        this.selectedResources.forEach(element => {
          this.siteVisitSchedule.siteVisitScheduleResource.push({
            user: element
          });
        });

        if (this.siteVisitSchedule.scheduleDate === undefined || this.siteVisitSchedule.scheduleTime === undefined || this.siteVisitSchedule.siteVisitScheduleResource.length === 0) {
          this.notifier.notify('error', 'Please fill the Required Fields');
          this.disableSaveButton = false;

        } else {
          this.valuationRequestService.createSiteVisitSchedule(this.siteVisitSchedule).subscribe(
            data => {
              if(!data.data){
              this.disableSaveButton = false;
              }else{
                let id = (data as any).data.id
                localStorage.setItem('lastSiteVisitSceduleId', id)
                // this.listSiteVisitScheduleList.push(this.siteVisitSchedule);
                this.listSiteVisitSchedule();

                // Enabling Permission Letter button
                this.siteVisitScheduleAdded = true;
                this.notifier.notify('success', 'Saved Successfully.');
                this.disableSaveButton = true;
                this.openPopupMessage(this.popupMigrated1);
                this.reset();
                this.selectedResources.forEach(element => {
                  this.userList.push(this.resourcesList.filter(i => ((i['id'] === element.id)))[0]);
                });
                console.log(this.userList)
                this.userList.forEach(element => {

                  // Assigning allocated resources' names to "selectedResourceNames" array
                  this.selectedResourceNames.push(element.fullName + ' ');
                });

                   // console.log(element.roles[0].code);
                   this.userList.forEach(role => {

                     // **** SEND NOTIFICATIONS - START ****
                     this.valuationRequestService.findByValuationRequestId(+this.route.snapshot.paramMap.get('id')).subscribe(
                       data => {
                         this.deedNatureValue = data.data.deedNature;

                         this.notificationModel.userRoleCode = role.roles[0].code;
                         this.notificationModel.deedNature = this.deedNatureValue;
                         this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                         this.notificationModel.subject = Constant.SITE_VISIT_SCHEDULE_CREATED;
                         this.notificationModel.userId = role.id
                         this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                         this.notificationService.createNotification(this.notificationModel).subscribe(
                           data => {
                             console.log(data);
                           }
                         );
                       }
                     );
                     // **** SEND NOTIFICATIONS - END ****
                   });
                this.prepareLetter();

                // this.permissionLetters.push(this.permissionLetter);
                // this.permissionLetterContent = "";
                this.modalService.dismissAll();
              }
            },
            err => {
              this.notifier.notify('error', 'Error occurred while processing. Please try again.');
              this.disableSaveButton = false;
            }
          );
        }
    //   }
    // )


  }

  reset() {
    this.initialview = true;
    this.model = new SiteVisitSchedule();
  }

  letterAccess() {
    this.openLetter = true;

    // if(this.propertyName == undefined || this.propertyName == ""){
    //   this.notifier.notify('error', 'Please fill the required fields first');
    //   this.openLetter = false;
    //   this.modalService.dismissAll();
    // } else if(this.propertyVenue == undefined || this.propertyVenue == ""){
    //   this.notifier.notify('error', 'Please fill the required fields first');
    //   this.openLetter = false;
    //   this.modalService.dismissAll();
    // } else{
    //   this.openLetter = true;
    // }
  }

  getPropertyDetails() {
    this.propertyService.findPropertyByValuationRequest(this.valuationRequestId).subscribe(
      data => {
        console.log(data);
        this.propertyDetails = data.data;
        this.getSearchDetails(data.data.addressLine2);
        this.siteVisitSchedule.propertyName = data.data.nameOfTheProperty;

        if(data.data.addressLine2 != null && data.data.addressLine2 != undefined){
          this.siteVisitSchedule.propertyVenue = data.data.addressLine1 + ' ' + data.data.addressLine2;
        }else{
          this.siteVisitSchedule.propertyVenue = data.data.addressLine1
        }
      }
    )
  }

  public checkStatus(type:boolean): void{
    this.status.emit(type);
  }
}
