import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RefundOpinionServiceService } from 'src/app/service/RefundOpinionService/refund-opinion-service.service';
import { RefundRequestGrantorDetails } from 'src/app/model/refundRequestGrantorDetails';
import { RefundNotaryDetails } from 'src/app/model/refundNotaryDetails';
import { RefundResonDetails } from 'src/app/model/refundResonDetails';
import { Constant } from 'src/app/util/constant';
import { ValuationStatus } from 'src/app/model/valuation-status';

@Component({
  selector: 'app-view-refund-details',
  templateUrl: './view-refund-details.component.html',
  styleUrls: ['./view-refund-details.component.scss']
})
export class ViewRefundDetailsComponent implements OnInit {

  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();

  public refundRequestGrantorDetailsModel = new RefundRequestGrantorDetails();
  public refundRequestNotaryDetailsModel = new RefundNotaryDetails();
  public refundRequestReasonForRefundModel = new RefundResonDetails();

  fileNumber: string;
  refundNumber: string;
  verificationStatus: number = 0;
  public modelStatus = new ValuationStatus('', '');

  hasOpinion: any;
  withOpinion: boolean;

  userData: any = [];
  userRoleCode: string;
  refundReasons:any =[];
  filteredRefundReason: any=[];
  reason:any;

  disableBackButton: boolean = true;

  constructor(
    private refundOpinionService: RefundOpinionServiceService
  ) {
  }

  ngOnInit() {
    this.retrieveContactPersonDetails();
    this.retrieveNotaryDetails();
    this.getAllRefundReason();
    this.retrieveReasonForRefundContents();
    this.fileNumber = localStorage.getItem('fileNumber');
    this.refundNumber = localStorage.getItem('refundNumber');
    this._prepare();
  }

  _prepare() {
    this.checkOpinion();
    this.obtainUserData();
  }

  checkOpinion() {
    this.hasOpinion = sessionStorage.getItem('hasOpinion');
    if (this.hasOpinion == 'true') {
      this.withOpinion = true;
    } else if (this.hasOpinion == 'false') {
      this.withOpinion = false;
    }
  }

  obtainUserData() {
    this.userData = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    for (let role of this.userData.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.userRoleCode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.userRoleCode = Constant.ROLE_ACCESSOR;
        this.disableBackButton = false;
        break;
      } else if (role.code === Constant.ROLE_TAX_OFFICER) {
        this.userRoleCode = Constant.ROLE_TAX_OFFICER;
        break;
      } else if (role.code === Constant.ROLE_ACCOUNTANT) {
        this.userRoleCode = Constant.ROLE_ACCOUNTANT;
        break;
      }
    }
  }

  retrieveContactPersonDetails() {
    this.refundOpinionService.getContactPersonDetailsByRefundRequestId(+localStorage.getItem('refundRequest')).subscribe(
      data => {
        this.refundRequestGrantorDetailsModel = data.data;
      }
    )
  }

  retrieveNotaryDetails() {
    this.refundOpinionService.getNotaryDetailsByRefundRequestId(+localStorage.getItem('refundRequest')).subscribe(
      data => {
        this.refundRequestNotaryDetailsModel = data.data;
      }
    )
  }

  retrieveReasonForRefundContents() {
    this.refundOpinionService.getReasonForRefundContentsByRefundRequestId(+localStorage.getItem('refundRequest')).subscribe(
      data => {
        this.refundRequestReasonForRefundModel = data.data;
        this.filteredRefundReason = this.refundReasons.filter(i=>i.code === this.refundRequestReasonForRefundModel.reason_for_refund);

        this.reason= this.filteredRefundReason[0].description
      }
    )
  }

  getAllRefundReason(){
    this.refundOpinionService.getAllRefundDetails().subscribe(data=>{
      this.refundReasons = data.data;
    })
  }

  public redirectComponent(): void {
    this.onPageCountChanged.emit(4);
  }

  public redirectPreviousComponent(): void {
    if (this.userRoleCode == Constant.ROLE_COMMISSIONER || this.userRoleCode == Constant.ROLE_DEPUTY_COMMISSIONER) {
      this.onPageCountChanged.emit(1);
    }
  }

  updateVerificationStatus(event){
    if (event.target.checked === true) {
      this.verificationStatus = 1;
    }else{
      this.verificationStatus = 0;
    }
    this.modelStatus.id = localStorage.getItem('fileNumber');
    this.modelStatus.verificationStatus = '1';
    console.log(this.modelStatus,"staus")
    this.refundOpinionService.refundUpdateVerificationStatus(this.modelStatus).subscribe();
  }

}
