import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { NotifierService } from 'angular-notifier';
import { RegexFormatDirective } from 'src/app/directives/regex-format.directive';
import { passwordPolicy } from 'src/app/model/passwordPolicy';
import { DataService } from 'src/app/service/data.service';
import { PasswordPolicyService } from 'src/app/service/passwordPolicy/password-policy.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-create-password-policy',
  templateUrl: './create-password-policy.component.html',
  styleUrls: ['./create-password-policy.component.scss']
})
export class CreatePasswordPolicyComponent implements OnInit {
  createPasswordPolicyForm: FormGroup;
  public passwordPolicyModel = new passwordPolicy(null,'','','','','','','','','');
  passwordPolicyPattern ='^(?=(.*[A-Z]){$7@})(?=(.*[0-9]){$1@})(?=(.*[#$@!%&*?]){$3@})[A-Za-z0-9#$@!%&*?]{$5@,$6@}$';

  private sub = new SubSink();

  constructor(
    private formBuilder: FormBuilder,
    private passowrdPolicyService : PasswordPolicyService,
    private notifier : NotifierService,
    private dataService : DataService,
    ) { }

  ngOnInit() {
    this.createPasswordPolicyForm = this.formBuilder.group({
      minLength: ['', [Validators.required]],
      maxLength: ['', [Validators.required]],
      minNumaric: ['', [Validators.required]],
      // maxNumaric: [''],
      minSpecial: ['', [Validators.required]],
      // maxSpecial: [''],
      minUpperChar: ['', [Validators.required]],
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  get f() { return this.createPasswordPolicyForm.controls; }

  reset() {
    this.createPasswordPolicyForm.reset();
  }

  checkPasswordPolicyForm():boolean{
    if(parseInt(this.createPasswordPolicyForm.get('minLength').value)==0){
      this.notifier.notify('error','Minimum Length at least 01');
      return false;
    }
    else if(parseInt(this.createPasswordPolicyForm.get('maxLength').value)>20){
      this.notifier.notify('error','Maxmimum Length should below 20');
      return false;
    }
    else if(parseInt(this.createPasswordPolicyForm.get('minLength').value)>parseInt(this.createPasswordPolicyForm.get('maxLength').value)){
      this.notifier.notify('error','Maxmimum Length should below Minimum Length');
      return false;
    }
    // else if(parseInt(this.createPasswordPolicyForm.get('minNumaric').value)>parseInt(this.createPasswordPolicyForm.get('maxNumaric').value)){
    //   this.notifier.notify('error','Maxmimum Numaric Length should below Minimum Numaric Length');
    //   return false;
    // }
    // else if(parseInt(this.createPasswordPolicyForm.get('minSpecial').value)>parseInt(this.createPasswordPolicyForm.get('maxSpecial').value)){
    //   this.notifier.notify('error','Maxmimum Special Character Length should below Minimum Special Character  Length');
    //   return false;
    // }
    else if(
      (parseInt(this.createPasswordPolicyForm.get('maxLength').value)<parseInt(this.createPasswordPolicyForm.get('minNumaric').value)) ||
      (parseInt(this.createPasswordPolicyForm.get('maxLength').value)<parseInt(this.createPasswordPolicyForm.get('minSpecial').value)) ||
      (parseInt(this.createPasswordPolicyForm.get('maxLength').value)<parseInt(this.createPasswordPolicyForm.get('minUpperChar').value))

      ){
        this.notifier.notify('error','All values should below maximum length')
        return false;
      }else if(
        parseInt(this.createPasswordPolicyForm.get('maxLength').value)<(parseInt(this.createPasswordPolicyForm.get('minNumaric').value) + parseInt(this.createPasswordPolicyForm.get('minSpecial').value) + parseInt(this.createPasswordPolicyForm.get('minUpperChar').value)))
      {
        this.notifier.notify('error','Total of Minimum Numaric characters, minimum special characters and minimum upper characters should below Maximum length')
        return false;
      }
      else{
        return true;
      }
  }

  setPasswordPolicyModel(){
    this.passwordPolicyModel.minLength = this.createPasswordPolicyForm.get('minLength').value;
    this.passwordPolicyModel.maxLength = this.createPasswordPolicyForm.get('maxLength').value;
    this.passwordPolicyModel.minNumaric = this.createPasswordPolicyForm.get('minNumaric').value;
    // this.passwordPolicyModel.maxNumaric = this.createPasswordPolicyForm.get('maxNumaric').value;
    this.passwordPolicyModel.minSpecialChar = this.createPasswordPolicyForm.get('minSpecial').value;
    // this.passwordPolicyModel.maxSpecialChar = this.createPasswordPolicyForm.get('maxSpecial').value;
    this.passwordPolicyModel.minUpperChar = this.createPasswordPolicyForm.get('minUpperChar').value;
  }
  savePasswordPolicy(){
    if(this.checkPasswordPolicyForm()){
      this.setPasswordPolicyModel();
      this.sub.add(this.passowrdPolicyService.createPasswordPolicy(this.passwordPolicyModel).subscribe(data=>{
        this.notifier.notify('success', (data as any).message);
        this.dataService.updatePasswordPolicy(true);
        this.createPasswordPolicyForm.reset();
        this.getPasswordPolicy();
      }));
    }else{
      this.notifier.notify('error', 'Password Policy Form not Valid');
    }
  }

  getPasswordPolicy(){
    this.passowrdPolicyService.getActivePasswordPolicy().subscribe(data=>{
      if(data.data == null){
        this.passwordPolicyModel.minNumaric = "03";
        // this.passwordPolicyModel.maxNumaric = "";
        this.passwordPolicyModel.minSpecialChar = "03";
        // this.passwordPolicyModel.maxSpecialChar = "";
        this.passwordPolicyModel.minLength = "06";
        this.passwordPolicyModel.maxLength = "08";
        this.passwordPolicyModel.minUpperChar = "01";
      }else{
        this.passwordPolicyModel=(data as any).data;
      }
      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$1@",this.passwordPolicyModel.minNumaric);
      // this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$2@",this.passwordPolicyModel.maxNumaric);
      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$3@",this.passwordPolicyModel.minSpecialChar);
      // this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$4@",this.passwordPolicyModel.maxSpecialChar);
      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$5@",this.passwordPolicyModel.minLength);
      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$6@",this.passwordPolicyModel.maxLength);
      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$7@",this.passwordPolicyModel.minUpperChar);

      sessionStorage.setItem('passwordPolicy',this.passwordPolicyPattern);
    });
  }
}
