import { HttpClient } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NotifierService } from "angular-notifier";
import * as L from "leaflet";
import { InspectionLandBuildingMain } from "src/app/model/inspection-building-main";
import { InspectionCondominium } from "src/app/model/inspection-condominium";
import { InspectionLand } from "src/app/model/inspection-land";
import { InspectionRecreation } from "src/app/model/inspection-recreation";
import { InspectionRecreationCommon } from "src/app/model/inspectionRecreationCommon";
import { InspectionCommonService } from "src/app/service/inspection-common/inspection-common.service";
import { InspectionService } from "src/app/service/inspection/inspection.service";
import { Constant } from "src/app/util/constant";
import { v4 as uuidv4 } from "uuid";
import { checkCompletedOrPaypend } from "../Util/checkStatus";

@Component({
  selector: "app-inspection-report-recreation-land",
  templateUrl: "./inspection-report-recreation-land.component.html",
  styleUrls: ["./inspection-report-recreation-land.component.scss"],
})
export class InspectionReportRecreationLandComponent implements OnInit {
  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("popupSuccessMessage", null)
  private popupMigrated: TemplateRef<object>;

  @ViewChild("popupUpdateSuccessMessage", null)
  private popupMigratedUpdate: TemplateRef<object>;

  @Input()
  valuationRequestId: string;

  @Input()
  valuationRequestStatus: string;

  selectedRecreationSubPropertyType: any;

  showNextButton: boolean;

  public model = new InspectionLand();
  public modelCondominium = new InspectionCondominium();
  public modelLandBuildingLand = new InspectionLand();

  public recreationModel = new InspectionRecreation(
    null,
    null,
    null,
    null,
    null,
    null, 
    null,
    null
  );
  public modellandbuildingmain = new InspectionLandBuildingMain(
    null,
    "",
    "",
    "",
    "",
    null,
    [],
    null,
    "",
    "",
    null,
    null
  );
  public userdata: any = [];
  public propertySubTypeList: any = [];

  public selectedRecreationType: boolean;

  selectedPropertyType: any;

  firstView: boolean;
  finalView: boolean;

  public reservationTypeList: any = [];
  public landShapeTypeList: any = [];
  public landSlopeTypeList: any = [];
  public landLocationTypeList: any = [];
  public waterAvailabilityTypeList: any = [];
  public waterTypeList: any = [];
  public electricityTypeList: any = [];
  public electricitySubTypeList: any = [];
  public landSuitabilityTypeList: any = [];
  public transportTypeList: any = [];
  public educationTypeList: any = [];
  public industrialTypeList: any = [];
  public healthTypeList: any = [];
  public securityTypeList: any = [];
  public telecommunicationTypeList: any = [];
  public businessTypeList: any = [];
  public industrialAreaTypeList: any = [];
  public cultivationTypeList: any = [];
  public ageCultivationTypeList: any = [];
  public landTypeList: any = [];
  public roiTypeList: any = [];

  public areaOfLand: any = [];

  SubProperty: any;
  selectedSubPropertyType: any;
  viewCommericalLand: boolean;
  viewAgricultureLand: boolean;
  viewMixModeLand: boolean;
  viewRecreationLand: boolean;
  isSubPropertyChange: boolean;
  landRecreation: boolean;
  selectedRecreationSubmitType: boolean;
  landwithbuilding: boolean;
  valuationdata: any;
  isLandBuilding: boolean;

  locationDetails: any;
  longitude: any;
  latitude: any;
  isLandWithBuildingRecreation: boolean;
  modelLand = new InspectionLand();
  landWithBuildingId:any;
  isUpdate:boolean;
  disableSaveBtn: boolean;
  disableBuildingSaveBtn: boolean;

  checkStatusApplication  = checkCompletedOrPaypend;

  constructor(
    public modalService: NgbModal,
    public inspectionCommonService: InspectionCommonService,
    public inspectionService: InspectionService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private notifier: NotifierService
  ) {
    this.selectedRecreationSubmitType = false;
    this.selectedRecreationType = false;
    this.showNextButton = true;
    this.firstView = true;
    this.finalView = false;
    this.viewCommericalLand = false;
    this.viewAgricultureLand = false;
    this.viewMixModeLand = false;
    this.viewRecreationLand = false;
    this.isSubPropertyChange = false;
    this.landRecreation = false;
    this.landwithbuilding = false;
    this.isLandBuilding = false;
    this.isUpdate = false;
    this.disableSaveBtn = false;
    this.disableBuildingSaveBtn = false;
  }

  ngOnInit() {
    this.valuationdata = JSON.parse(sessionStorage.getItem("valuationRequest"));
    this.isUpdate = JSON.parse(localStorage.getItem("recreationDetails"))
    // this.model.planNo = this.valuationdata.properties[0]["surveyorPlanNo"];
    // this.model.lotNo = this.valuationdata.properties[0]["lotNo"];
    this.landwithbuilding = JSON.parse(
      localStorage.getItem("landwithbuilding")
    );
    this.selectedRecreationSubPropertyType = JSON.parse(
      atob(localStorage.getItem("selectedRecreationSubPropertyType"))
    );
    this.selectedRecreationType = JSON.parse(
      atob(localStorage.getItem("selectedRecreationType"))
    );
    this.selectedPropertyType = JSON.parse(
      atob(localStorage.getItem("propertyType"))
    );
    this.model.planNo = this.valuationdata.properties[0]["surveyorPlanNo"];
    this.model.lotNo = this.valuationdata.properties[0]["lotNo"];
    this.isLandWithBuildingRecreation = JSON.parse(
      localStorage.getItem("isLandWithBuildingRecreation")
    );
    if (
      JSON.parse(localStorage.getItem("isLandWithBuildingRecreation")) === true
    ) {
      this.isLandBuilding = true;
      this.model.propertySubTypeId = JSON.parse(
        localStorage.getItem("subPropertyId")
      );
      localStorage.setItem("recreationDetails", JSON.stringify(false));
      this.landWithBuildingId = localStorage.getItem('inspectionRecreationLandwithBuilding-landId');
      console.log(this.landWithBuildingId)
      if(this.landWithBuildingId !== "null"){
        localStorage.setItem("recreationDetails", JSON.stringify(true));
        this.isUpdate =true;
        this.getInspectionRecreationById(this.landWithBuildingId);
      }
      this.formInit();
    } else {
      this.isLandBuilding = false;
      if (
        JSON.parse(
          atob(localStorage.getItem("selectedRecreationUpdateType"))
        ) === true
      ) {
        this.getInspectionRecreationById(
          JSON.parse(localStorage.getItem("inspectionRecreationlandmodel")).id
        );
        this.isUpdate =true;
        // preview data
        localStorage.setItem("recreationDetails", JSON.stringify(true));
      } else {
        let recreationLand = localStorage.getItem(
          "inspectionRecreationlandmodel"
        );
        if (
          JSON.parse(localStorage.getItem("inspectionRecreationlandmodel")) !=
          null
        ) {
          this.model = JSON.parse(recreationLand);
          this.getSearchDetails(this.model.nearestTown);

          this.formInit();
          console.log(this.model);
          // data saved but not re-preview
          localStorage.setItem("recreationDetails", JSON.stringify(this.isUpdate));
        } else {
          this.model.planNo =
            this.valuationdata.properties[0]["surveyorPlanNo"];
          this.model.lotNo = this.valuationdata.properties[0]["lotNo"];
          // data not saved
          localStorage.setItem("recreationDetails", JSON.stringify(false));
        }
      }
    }

    // let recreationLand = localStorage.getItem("inspectionRecreationlandmodel");
    // if (
    //   JSON.parse(localStorage.getItem("inspectionRecreationlandmodel")) != null
    // ) {
    //   this.model = JSON.parse(recreationLand);
    //   this.formInit();
    // }
    this._prepare();

    window.scroll(0, 0);
  }

  _prepare() {
    this.userdata = JSON.parse(atob(sessionStorage.getItem("userinfo")));

    //checking this is the last component in recreation
    if (this.selectedRecreationSubPropertyType.length === 1) {
      this.showNextButton = false;
    }

    // Check whether if it is a recreation
    if (this.selectedRecreationType || this.isLandBuilding) {
      this.loadPropertyType();
    } else {
      this.selectedPropertyType = JSON.parse(
        atob(localStorage.getItem("propertyType"))
      );
      this.selectedSubPropertyType = JSON.parse(
        atob(localStorage.getItem("subPropertyType"))
      );
    }

    //default location
    this.getSearchDetails("Colombo");

    // get all of other common details
    this.loadReservationTypeList();
    this.landShapeTypeListService();
    this.landSlopeTypeListService();
    this.landLocationTypeListService();
    this.waterAvailabilityTypeListService();
    this.waterTypeListService();
    this.electricityTypeListService();
    this.electricitySubTypeListService();
    this.landSuitabilityTypeListService();
    this.transportTypeListService();
    this.educationTypeListService();
    this.industrialTypeListService();
    this.healthTypeListService();
    this.securityTypeListService();
    this.telecommunicationTypeListService();
    this.businessTypeListService();
    this.industrialAreaTypeListService();
    this.cultivationTypeListService();
    this.ageCultivationTypeListService();
    this.landTypeListService();
    this.roiTypeListService();
    this.formLayoutInit();
  }

  //filter property sub type
  selectedSubPropertyTypeForRecreation(event) {
    // console.log(this.model.propertySubTypeId);
    const filtereddata = this.propertySubTypeList.filter(
      (i) => i.id === Number(event)
    );
    this.selectedSubPropertyType = filtereddata[0];
    this.formLayoutInit();
    this.isSubPropertyChange = true;
  }

  getInspectionRecreationById(id: string) {
    this.inspectionService.getInspectionById(id).subscribe((data) => {
      console.log(data.data);
      if(this.isLandBuilding){
        this.model = data.data.inspectionLandBuildingDto.inspectionLandDto;
        this.getSearchDetails(this.model.nearestTown);
      }else{
        this.model = data.data.inspectionLandDto;
        this.getSearchDetails(this.model.nearestTown);
      }
      this.formInit();
    });
  }

  //selected property sub type
  formLayoutInit() {
    if (
      this.selectedSubPropertyType["id"] ===
      Constant.INSPECTION_REPORT_LAND_COMMERCIAL
    ) {
      this.viewCommericalLand = true;
      this.viewAgricultureLand = false;
      this.viewMixModeLand = false;
      this.viewRecreationLand = false;
    } else if (
      this.selectedSubPropertyType["id"] ===
      Constant.INSPECTION_REPORT_LAND_AGRICULTURE
    ) {
      this.viewCommericalLand = false;
      this.viewAgricultureLand = true;
      this.viewMixModeLand = false;
      this.viewRecreationLand = false;
    } else if (
      this.selectedSubPropertyType["id"] ===
      Constant.INSPECTION_REPORT_LAND_MIXMODE
    ) {
      this.viewCommericalLand = false;
      this.viewAgricultureLand = false;
      this.viewMixModeLand = true;
      this.viewRecreationLand = false;
    } else if (
      this.selectedSubPropertyType["id"] ===
      Constant.INSPECTION_REPORT_LAND_RECREATION
    ) {
      this.viewCommericalLand = false;
      this.viewAgricultureLand = false;
      this.viewMixModeLand = false;
      this.viewRecreationLand = true;
    } else {
      this.viewCommericalLand = false;
      this.viewAgricultureLand = false;
      this.viewMixModeLand = false;
      this.viewRecreationLand = false;
    }
  }

  formInit() {
    if (
      parseInt(this.model.propertySubTypeId) ===
      Constant.INSPECTION_REPORT_LAND_COMMERCIAL
    ) {
      this.viewCommericalLand = true;
      this.viewAgricultureLand = false;
      this.viewMixModeLand = false;
      this.viewRecreationLand = false;
    } else if (
      parseInt(this.model.propertySubTypeId) ===
      Constant.INSPECTION_REPORT_LAND_AGRICULTURE
    ) {
      this.viewCommericalLand = false;
      this.viewAgricultureLand = true;
      this.viewMixModeLand = false;
      this.viewRecreationLand = false;
    } else if (
      parseInt(this.model.propertySubTypeId) ===
      Constant.INSPECTION_REPORT_LAND_MIXMODE
    ) {
      this.viewCommericalLand = false;
      this.viewAgricultureLand = false;
      this.viewMixModeLand = true;
      this.viewRecreationLand = false;
    } else if (
      parseInt(this.model.propertySubTypeId) ===
      Constant.INSPECTION_REPORT_LAND_RECREATION
    ) {
      this.viewCommericalLand = false;
      this.viewAgricultureLand = false;
      this.viewMixModeLand = false;
      this.viewRecreationLand = true;
    } else {
      this.viewCommericalLand = false;
      this.viewAgricultureLand = false;
      this.viewMixModeLand = false;
      this.viewRecreationLand = false;
    }
  }

  // load property types
  loadPropertyType() {
    this.inspectionCommonService.propertyUsageTypeList().subscribe((data) => {
      console.log(data.data);
      const filtereddata = data.data.filter(
        (i) => i.id === Constant.INSPECTION_PROPERTY_TYPE_LAND_ID
      );
      this.selectedPropertyType = filtereddata[0];
      this.loadPropertySubType();
    });
  }

  // load property sub types
  loadPropertySubType() {
    this.inspectionCommonService
      .propertyUsageSubTypeList()
      .subscribe((data) => {
        const filtereddata = data.data.filter(
          (i) => i.inspectionPropertyType.id === this.selectedPropertyType.id
        );
        this.propertySubTypeList = filtereddata;
        this.permissionCheck();
      });
  }

  //check selected other recreation types
  permissionCheck() {
    let page;
    if (this.selectedRecreationType) {
      if (this.selectedRecreationSubPropertyType.length > 0) {
        for (
          let i = 0;
          i < this.selectedRecreationSubPropertyType.length;
          i++
        ) {
          if (this.selectedRecreationSubPropertyType[i].id === 2) {
            page = "condominium";
            break;
          } else if (this.selectedRecreationSubPropertyType[i].id === 3) {
            page = "land_building";
            break;
          }
        }
      }
    }
    if (page === "condominium") {
      localStorage.setItem("isLand", JSON.stringify(true));
      // localStorage.setItem("inspectionlandmodel", btoa(JSON.stringify(this.model)));
    }
    if (page === "land_building") {
      localStorage.setItem("isLand", JSON.stringify(true));
      // localStorage.setItem("inspectionlandmodel", btoa(JSON.stringify(this.model)));
    }
  }

  //get all relevent details for dropdowns -- start

  loadReservationTypeList() {
    this.inspectionCommonService.reservationTypeList().subscribe((data) => {
      this.reservationTypeList = data.data;
    });
  }

  landShapeTypeListService() {
    this.inspectionCommonService.landShapeTypeList().subscribe((data) => {
      this.landShapeTypeList = data.data;
    });
  }

  landSlopeTypeListService() {
    this.inspectionCommonService.landSlopeTypeList().subscribe((data) => {
      this.landSlopeTypeList = data.data;
    });
  }

  landLocationTypeListService() {
    this.inspectionCommonService.landLocationTypeList().subscribe((data) => {
      this.landLocationTypeList = data.data;
    });
  }

  waterAvailabilityTypeListService() {
    this.inspectionCommonService
      .waterAvailabilityTypeList()
      .subscribe((data) => {
        this.waterAvailabilityTypeList = data.data;
      });
  }

  waterTypeListService() {
    this.inspectionCommonService.waterTypeList().subscribe((data) => {
      this.waterTypeList = data.data;
    });
  }

  electricityTypeListService() {
    this.inspectionCommonService.electricityTypeList().subscribe((data) => {
      this.electricityTypeList = data.data;
    });
  }

  electricitySubTypeListService() {
    this.inspectionCommonService.electricitySubTypeList().subscribe((data) => {
      this.electricitySubTypeList = data.data;
    });
  }

  landSuitabilityTypeListService() {
    this.inspectionCommonService.landSuitabilityTypeList().subscribe((data) => {
      this.landSuitabilityTypeList = data.data;
    });
  }

  transportTypeListService() {
    this.inspectionCommonService.transportTypeList().subscribe((data) => {
      this.transportTypeList = data.data;
    });
  }

  educationTypeListService() {
    this.inspectionCommonService.educationTypeList().subscribe((data) => {
      this.educationTypeList = data.data;
    });
  }

  industrialTypeListService() {
    this.inspectionCommonService.industrialTypeList().subscribe((data) => {
      this.industrialTypeList = data.data;
    });
  }

  healthTypeListService() {
    this.inspectionCommonService.healthTypeList().subscribe((data) => {
      this.healthTypeList = data.data;
    });
  }

  securityTypeListService() {
    this.inspectionCommonService.securityTypeList().subscribe((data) => {
      this.securityTypeList = data.data;
    });
  }

  telecommunicationTypeListService() {
    this.inspectionCommonService
      .telecommunicationTypeList()
      .subscribe((data) => {
        this.telecommunicationTypeList = data.data;
      });
  }

  businessTypeListService() {
    this.inspectionCommonService.businessTypeList().subscribe((data) => {
      this.businessTypeList = data.data;
    });
  }

  industrialAreaTypeListService() {
    this.inspectionCommonService.industrialAreaTypeList().subscribe((data) => {
      this.industrialAreaTypeList = data.data;
    });
  }

  cultivationTypeListService() {
    this.inspectionCommonService.cultivationTypeList().subscribe((data) => {
      this.cultivationTypeList = data.data;
    });
  }

  ageCultivationTypeListService() {
    this.inspectionCommonService.ageCultivationTypeList().subscribe((data) => {
      this.ageCultivationTypeList = data.data;
    });
  }

  landTypeListService() {
    this.inspectionCommonService.landTypeList().subscribe((data) => {
      this.landTypeList = data.data;
    });
  }

  roiTypeListService() {
    this.inspectionCommonService.roiTypeList().subscribe((data) => {
      this.roiTypeList = data.data;
    });
  }
  // relevent details for dropdown -- end

  // geo map -- start

  // search location
  getSearchDetails(location: String) {
    // console.log("called");
    if((location === null || location === "" || location === undefined) && (this.model.nearestTown === undefined || this.model.nearestTown === "" || this.model.nearestTown === null)) {
      location ="Colombo";
    }else{
      location = this.model.nearestTown;
    }
    this.http
      .post<any>(
        "https://nominatim.openstreetmap.org/search?q=" +
          location +
          "&format=geojson",
        { title: "Angular POST Request Example" }
      )
      .subscribe((data) => {
        this.locationDetails = data;
        if (this.locationDetails.features.length > 0) {
          this.longitude =
            this.locationDetails.features[0].geometry.coordinates[0];
          this.latitude =
            this.locationDetails.features[0].geometry.coordinates[1];
          document.getElementById("geomap").innerHTML =
            "<div id='map' style='width: 100%; height: 100%;'></div>";
          var map = new L.Map("map", { attributionControl: false });
          map.setView(new L.LatLng(this.latitude, this.longitude), 9);
          L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
            attribution: "",
          }).addTo(map);
          L.marker([this.latitude, this.longitude])
            .addTo(map)
            .bindPopup(this.locationDetails.features[0].properties.display_name)
            .openPopup();
        } else {
          document.getElementById("geomap").innerHTML =
            "<div id='map' style='width: 100%; height: 100%;'></div>";
          var map = new L.Map("map", { attributionControl: false });
          map.setView(new L.LatLng(6.9270786, 79.861243), 9);
        }
      });
  }

  //nearest location
  NearestLocationSearch(event) {
    if (event === "" ) {
      this.model.nearestTown = "Colombo";
    }
    this.http
      .post<any>(
        "https://nominatim.openstreetmap.org/search?q=" +
          this.model.nearestTown +
          "&format=geojson",
        { title: "Angular POST Request Example" }
      )
      .subscribe((data) => {
        this.locationDetails = data;
        if (this.locationDetails.features.length > 0) {
          this.longitude =
            this.locationDetails.features[0].geometry.coordinates[0];
          this.latitude =
            this.locationDetails.features[0].geometry.coordinates[1];
          document.getElementById("geomap").innerHTML =
            "<div id='map' style='width: 100%; height: 100%;'></div>";
          var map = new L.Map("map", { attributionControl: false });
          map.setView(new L.LatLng(this.latitude, this.longitude), 9);
          map.zoomIn(9);
          L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
            attribution: "",
          }).addTo(map);
          L.marker([this.latitude, this.longitude])
            .addTo(map)
            .bindPopup(this.locationDetails.features[0].properties.display_name)
            .openPopup();
        } else {
          document.getElementById("geomap").innerHTML =
            "<div id='map' style='width: 100%; height: 100%;'></div>";
          var map = new L.Map("map", { attributionControl: false });
          map.setView(new L.LatLng(6.9270786, 79.861243), 9);
        }
      });
  }
  // geo map -- end

  // calculate area -- start
  calculateArea(){
    if(this.model.length !== undefined && this.model.length !== null && this.model.width !== undefined && this.model.width !== null) {
      this.areaOfLand = (
        (Number(this.model.length) *
        Number(this.model.width)) / 272.25
      ).toFixed(2);
    }else{
      this.areaOfLand = (0.00).toFixed(2);
    }
  }
  // calculate area -- end

  //Redirect to main page
  redirectToMainPage() {
    this.onPageCountChanged.emit(10);
  }

  // first view -- next button
  navigateToSecondView() {
    if (this.model.propertySubTypeId != undefined) {
      window.scroll(0, 0);
      this.firstView = false;
      this.finalView = true;
    } else {
      this.notifier.notify("error", `Please select Sub type first.`);
    }
  }

  //last view -- back button
  navigateToFirstView() {
    window.scroll(0, 0);
    this.firstView = true;
    this.finalView = false;
  }

  //final next button - recreation land
  recreationNavigation() {
    let page;
    this.model.valuationRequestId = this.valuationRequestId;
    this.model.propertyTypeId = this.selectedPropertyType["id"];
    this.model.createdBy = this.userdata["firstName"];
    this.model.inspectionType = Constant.INSPECTION_TYPE_RECREATION_LAND;

    // console.log(this.model.createdBy);
    this.model.inspectionType = Constant.INSPECTION_TYPE_RECREATION_LAND;
    if (this.selectedRecreationType) {
      if (this.selectedRecreationSubPropertyType.length > 0) {
        for (
          let i = 0;
          i < this.selectedRecreationSubPropertyType.length;
          i++
        ) {
          if (this.selectedRecreationSubPropertyType[i].id === 2) {
            page = "condominium";
            break;
          } else if (this.selectedRecreationSubPropertyType[i].id === 3) {
            page = "land_building";
            break;
          }
        }
      }
    }
    if (page === "condominium") {
      localStorage.setItem("isLand", JSON.stringify(true));
      localStorage.setItem(
        "inspectionRecreationlandmodel",
        JSON.stringify(this.model)
      );
      localStorage.setItem("isLandWithBuildingRecreation", JSON.stringify(false))
      this.onPageCountChanged.emit(18);
    }
    if (page === "land_building") {
      localStorage.setItem("isLand", JSON.stringify(true));
      localStorage.setItem(
        "inspectionRecreationlandmodel",
        JSON.stringify(this.model)
      );
      localStorage.setItem("isLandWithBuildingRecreation", JSON.stringify(true))
      this.onPageCountChanged.emit(17);
    }
  }

  // redirect to land with building main page in land with building
  navigationRedirectLandBuildingMain() {
    window.scroll(0, 0);
    localStorage.setItem(
      "inspectionLandBuilding-Land",
      JSON.stringify(this.model)
    );
    this.onPageCountChanged.emit(17);
  }

  //popup Methods
  openPopupMessage(content) {
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      backdrop: "static",
    });
  }

  //save and update Methods

  // is save common method
  saveAndUpdate() {
    if (JSON.parse(localStorage.getItem("recreationDetails")) === true) {
      this.inspectionService
        .inspectionRecreationUpdate(this.recreationModel)
        .subscribe(
          (data) => {
            this.openPopupMessage(this.popupMigratedUpdate);
            this.inspectionService
              .getLastInspectionRecreationRecords(
                this.model.createdUserId,
                JSON.parse(localStorage.getItem("recreationGroupId"))
              )
              .subscribe((data) => {
                console.log(data.data);
                for (let i = 0; i < data.data.length; i++) {
                  switch (data.data[i].inspectionType) {
                    case Constant.INSPECTION_TYPE_RECREATION_LAND:
                      localStorage.setItem(
                        "inspectionRecreationLandId",
                        data.data[i].id
                      );
                      break;
                    case Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM:
                      localStorage.setItem(
                        "inspectionRecreationCondominiumId",
                        data.data[i].id
                      );
                      break;
                    case Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING_LAND:
                      localStorage.setItem(
                        "inspectionRecreationLandwithBuilding-landId",
                        data.data[i].id
                      );
                      break;
                    case Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING_BUILDING:
                      localStorage.setItem(
                        "inspectionRecreationLandwithBuilding-buildingId",
                        data.data[i].id
                      );
                      break;
                  }
                }
                localStorage.setItem("recreationDetails", JSON.stringify(true));
                this.isUpdate = true;
                if(this.isLandBuilding == true) {
                  this.navigationRedirectLandBuildingMain();
                }
              });
          },
          (err) => {
            this.notifier.notify(
              "error",
              "Error occurred while processing. Please try again."
            );
          }
        );
    } else {
      this.inspectionService
        .inspectionRecreationInsert(this.recreationModel)
        .subscribe(
          (data) => {
            this.openPopupMessage(this.popupMigrated);
            this.inspectionService
              .getLastInspectionRecreationRecords(
                this.model.createdUserId,
                JSON.parse(localStorage.getItem("recreationGroupId"))
              )
              .subscribe((data) => {
                // console.log(data.data);
                for (let i = 0; i < data.data.length; i++) {
                  switch (data.data[i].inspectionType) {
                    case Constant.INSPECTION_TYPE_RECREATION_LAND:
                      localStorage.setItem(
                        "inspectionRecreationLandId",
                        data.data[i].id
                      );
                      break;
                    case Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM:
                      localStorage.setItem(
                        "inspectionRecreationCondominiumId",
                        data.data[i].id
                      );
                      break;
                    case Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING_LAND:
                      localStorage.setItem(
                        "inspectionRecreationLandwithBuilding-landId",
                        data.data[i].id
                      );
                      break;
                    case Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING_BUILDING:
                      localStorage.setItem(
                        "inspectionRecreationLandwithBuilding-buildingId",
                        data.data[i].id
                      );
                      break;
                  }
                }
                localStorage.setItem("recreationDetails", JSON.stringify(true));
                if(this.isLandBuilding == true) {
                  this.navigationRedirectLandBuildingMain();
                }
              });
          },
          (err) => {
            this.notifier.notify(
              "error",
              "Error occurred while processing. Please try again."
            );
          }
        );
    }
  }

  //submit method
  onSubmit() {
    this.disableSaveBtn = true;
    var recreationGroupId;
    if (JSON.parse(localStorage.getItem("recreationGroupId")) != null) {
      recreationGroupId = localStorage.getItem("recreationGroupId");
      this.model.recreationGroupId = JSON.parse(
        localStorage.getItem("recreationGroupId")
      );
    } else {
      recreationGroupId = uuidv4();
      localStorage.setItem(
        "recreationGroupId",
        JSON.stringify(recreationGroupId)
      );
    }
    let haveErrors:boolean = true;
    if (this.selectedRecreationType) {
      if (this.selectedRecreationSubPropertyType.length > 0) {
        for (
          let i = 0;
          i < this.selectedRecreationSubPropertyType.length;
          i++
        ) {
          if(this.selectedRecreationSubPropertyType[i].id === 1){
              if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
                this.recreationModel.parentId = parseInt(this.model.inspectionId);
              }
              JSON.parse(localStorage.getItem("recreationDetails")) === true ?
              this.model.id = parseInt(localStorage.getItem("inspectionRecreationLandId")): this.model.id = null
              haveErrors = false;
          }
          if (this.selectedRecreationSubPropertyType[i].id === 2) {
            if (
              JSON.parse(
                localStorage.getItem("inspectionRecreationCondominiummodel")
              ) != null
            ) {
              this.modelCondominium = JSON.parse(
                localStorage.getItem("inspectionRecreationCondominiummodel")
              );
              JSON.parse(localStorage.getItem("recreationDetails")) === true ?
              this.modelCondominium.id = parseInt(localStorage.getItem("inspectionRecreationCondominiumId")): this.modelCondominium.id  = null;

              this.modelCondominium.inspectionType =
                Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM;
              this.modelCondominium.recreationGroupId = JSON.parse(
                localStorage.getItem("recreationGroupId")
              );
              this.modelCondominium.createdBy = this.userdata["firstName"];
              this.modelCondominium.createdUserId = this.userdata["id"];
              this.recreationModel.isCondominium = false;
              if(this.recreationModel.parentId === null){
                if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
                  this.recreationModel.parentId = parseInt(this.modelCondominium.inspectionId);
                }
              }
              if(this.modelCondominium.propertySubTypeId != undefined && this.modelCondominium.propertySubTypeId != null && this.modelCondominium.propertySubTypeId != ""){
                haveErrors = false;
              }else{
                haveErrors = true;
              }
              
            } else {
              haveErrors = true;
              // if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
              //   haveErrors = true;
              //   this.notifier.notify(
              //     "error",
              //     "Please select Sub type first in all types."
              //   );
              // }else{
              //   haveErrors = false;
              // }
            }
          } else if (this.selectedRecreationSubPropertyType[i].id === 3) {
            if (
              JSON.parse(
                localStorage.getItem("inspectionLandBuilding-Land")
              ) != null
            ) {
              this.modelLandBuildingLand = JSON.parse(localStorage.getItem("inspectionLandBuilding-Land"));

              JSON.parse(localStorage.getItem("recreationDetails")) === true ?
                this.modelLandBuildingLand.id = parseInt(localStorage.getItem("inspectionRecreationLandwithBuilding-landId")): this.modelLandBuildingLand.id = null

              this.modelLandBuildingLand.inspectionType = Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING_LAND;
              this.modelLandBuildingLand.recreationGroupId = JSON.parse(
                localStorage.getItem("recreationGroupId")
              );
              this.modelLandBuildingLand.createdBy = this.userdata["firstName"];
              this.modelLandBuildingLand.createdUserId = this.userdata["id"];

              if(this.recreationModel.isLand !== true || this.recreationModel.isCondominium !== true){
                this.recreationModel.isLandBuilding = true;
              }else{
                this.recreationModel.isLandBuilding = false;
              }

              if(this.recreationModel.parentId === null){
                if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
                  this.recreationModel.parentId = parseInt(this.modelLandBuildingLand.inspectionId);
                }
              }
              if(this.modelLandBuildingLand.propertySubTypeId != null && this.modelLandBuildingLand.propertySubTypeId != undefined && this.modelLandBuildingLand.propertySubTypeId != ""){
                haveErrors = false;              
              }else{
                haveErrors = true;
              }
            } else {
              haveErrors = true;

              // if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
              //   haveErrors = true;
              //   this.notifier.notify(
              //     "error",
              //     "Please Create Land in Land with Building First."
              //   );
              // }else{
              //   haveErrors = false;
              // }
             
            }
            if(JSON.parse(localStorage.getItem("inspectionLandBuilding-Building")) != null){
              this.modellandbuildingmain = JSON.parse(localStorage.getItem("inspectionLandBuilding-Building"));
              
              JSON.parse(localStorage.getItem("recreationDetails")) === true ?
                this.modellandbuildingmain.id = parseInt(localStorage.getItem("inspectionRecreationLandwithBuilding-buildingId")):this.modellandbuildingmain.id = null
            }
          }
        }
      }
    }
    this.model.valuationRequestId = this.valuationRequestId;
    this.model.propertyTypeId = this.selectedPropertyType["id"];
    this.model.createdBy = this.userdata["firstName"];
    this.model.inspectionType = Constant.INSPECTION_TYPE_RECREATION_LAND;
    this.model.createdUserId = this.userdata["id"];
    this.model.recreationGroupId = JSON.parse(
      localStorage.getItem("recreationGroupId")
    );

    this.recreationModel.inspectionLandDto = this.model;
    this.recreationModel.inspectionCondominiumDto = this.modelCondominium;
    this.recreationModel.inspectionLandBuildingDto = this.modellandbuildingmain;

    this.recreationModel.isLand = true;
    
    if(this.model.propertySubTypeId === null || this.model.propertySubTypeId === undefined || this.model.propertySubTypeId === ""){
      haveErrors = true;
    }
    if(haveErrors === false){
      this.disableSaveBtn = false;
      this.saveAndUpdate();
    }   else{
      this.disableSaveBtn = false;
      this.notifier.notify(
        "error",
        "Please Fill all Required Fields."
      );
    } 
  }

  //land building save

  onSubmitLandBuilding() {
    // create group id -- start
    this.disableBuildingSaveBtn = true;
    var recreationGroupId;
    if (JSON.parse(localStorage.getItem("recreationGroupId")) != null) {
      recreationGroupId = localStorage.getItem("recreationGroupId");
      this.model.recreationGroupId = JSON.parse(
        localStorage.getItem("recreationGroupId")
      );
    } else {
      recreationGroupId = uuidv4();
      localStorage.setItem(
        "recreationGroupId",
        JSON.stringify(recreationGroupId)
      );
    }
    // create group id -- end

    let haveErrors:boolean = true;
    // get previous details -- start
    if (this.selectedRecreationType) {
      if (this.selectedRecreationSubPropertyType.length > 0) {
        for (
          let i = 0;
          i < this.selectedRecreationSubPropertyType.length;
          i++
        ) {
          if (this.selectedRecreationSubPropertyType[i].id === 1) {
            if (
              JSON.parse(
                localStorage.getItem("inspectionRecreationlandmodel")
              ) != null
            ) {
              this.modelLand = JSON.parse(
                localStorage.getItem("inspectionRecreationlandmodel")
              );
              JSON.parse(localStorage.getItem("recreationDetails")) === true ?
              this.modelLand.id = parseInt(localStorage.getItem("inspectionRecreationLandId")): this.model.id = null

              this.modelLand.inspectionType =
                Constant.INSPECTION_TYPE_RECREATION_LAND;
              this.modelLand.recreationGroupId = JSON.parse(
                localStorage.getItem("recreationGroupId")
              );
              this.modelLand.createdBy = this.userdata["firstName"];
              this.modelLand.createdUserId = this.userdata["id"];
              this.recreationModel.isLand = true;

                if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
                  this.recreationModel.parentId = parseInt(this.modelLand.inspectionId);
                }
              if(this.modelLand.propertySubTypeId != null && this.modelLand.propertySubTypeId != undefined && this.modelLand.propertySubTypeId != ""){
                haveErrors = false
              }else{
                haveErrors = true;
              }
            } else {
              haveErrors = true;
              // if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
              //   haveErrors = true;
              //   this.notifier.notify(
              //     "error",
              //     "Please select Land Sub type first."
              //   );
              // }else{
              //   haveErrors = false;
              // }
            }
          } else if (this.selectedRecreationSubPropertyType[i].id === 2) {
            if (
              JSON.parse(
                localStorage.getItem("inspectionRecreationCondominiummodel")
              ) != null
            ) {
              this.modelCondominium = JSON.parse(
                localStorage.getItem("inspectionRecreationCondominiummodel")
              );
              JSON.parse(localStorage.getItem("recreationDetails")) === true ?
              this.modelCondominium.id = parseInt(localStorage.getItem("inspectionRecreationCondominiumId")): this.modelCondominium.id  = null;

              this.modelCondominium.inspectionType =
                Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM;
              this.modelCondominium.recreationGroupId = JSON.parse(
                localStorage.getItem("recreationGroupId")
              );

              this.modelCondominium.createdBy = this.userdata["firstName"];
              this.modelCondominium.createdUserId = this.userdata["id"];
              if (this.recreationModel.isLand === true) {
                this.recreationModel.isCondominium = false;
              } else {
                this.recreationModel.isCondominium = true;
              }
              if(this.recreationModel.parentId === null){
                if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
                  this.recreationModel.parentId = parseInt(this.modelCondominium.inspectionId);
                }
              }
              if(this.modelCondominium.propertySubTypeId != null && this.modelCondominium.propertySubTypeId != undefined && this.modelCondominium.propertySubTypeId != ""){
                haveErrors = false;
              }else{
                haveErrors = true;
              }
            } else {
              haveErrors = true;
              // if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
              //   haveErrors = true;
              //   this.notifier.notify(
              //     "error",
              //     "Please select Sub type first in all types."
              //   );
              // }else{
              //   haveErrors = false;
              // }

            }
          }else if (this.selectedRecreationSubPropertyType[i].id === 3) {
            if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
              if (
                JSON.parse(
                  localStorage.getItem("inspectionLandBuilding-Land")
                ) != null
              ) {
                this.modelLandBuildingLand = JSON.parse(
                  localStorage.getItem("inspectionLandBuilding-Land")
                );
                JSON.parse(localStorage.getItem("recreationDetails")) === true ?
                this.modelLandBuildingLand.id = parseInt(localStorage.getItem("inspectionRecreationLandwithBuilding-landId")): this.modelLandBuildingLand.id = null
                
                this.modelLandBuildingLand.inspectionType =
                  Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING_LAND;
                this.modelLandBuildingLand.recreationGroupId = JSON.parse(
                  localStorage.getItem("recreationGroupId")
                );
                this.modelLandBuildingLand.createdBy = this.userdata["firstName"];
                this.modelLandBuildingLand.createdUserId = this.userdata["id"];
                if(this.recreationModel.isLand !== true || this.recreationModel.isCondominium !== true){
                  this.recreationModel.isLandBuilding = true;
                }else{
                  this.recreationModel.isLandBuilding = false;
                }
                if(this.recreationModel.parentId === null){
                  if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
                    this.recreationModel.parentId = parseInt(this.modelLandBuildingLand.inspectionId);
                  }
                }
                haveErrors = false;
                if(this.modelLandBuildingLand.propertySubTypeId !=null && this.modelLandBuildingLand.propertySubTypeId != undefined && this.modelLandBuildingLand.propertySubTypeId != ""){
                  haveErrors = false;
                }else{
                  haveErrors = true;
                }
              } else {
                haveErrors = true;
              }
            }else{
              haveErrors = false;
            }

            if(JSON.parse(localStorage.getItem("inspectionLandBuilding-Building")) != null){
              this.modellandbuildingmain = JSON.parse(localStorage.getItem("inspectionLandBuilding-Building"));

              JSON.parse(localStorage.getItem("recreationDetails")) === true ?
                this.modellandbuildingmain.id = parseInt(localStorage.getItem("inspectionRecreationLandwithBuilding-buildingId")):this.modellandbuildingmain.id = null
            }
            if(this.recreationModel.parentId === null){
              if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
                this.recreationModel.parentId = parseInt(this.model.inspectionId);
              }
            }
          }
        }
      }
    }
    // get previous details -- end

    this.model.valuationRequestId = this.valuationRequestId;
    this.model.propertyTypeId = this.selectedPropertyType["id"];
    console.log(this.model.propertyTypeId);
    this.model.createdBy = this.userdata["firstName"];
    this.model.inspectionType =
      Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING_LAND;
    this.model.createdUserId = this.userdata["id"];
    this.model.recreationGroupId = JSON.parse(
      localStorage.getItem("recreationGroupId")
    );

    if (
      !(
        this.recreationModel.isCondominium == true ||
        this.recreationModel.isLand == true
      )
    ) {
      this.recreationModel.isLandBuilding = true;
    } else {
      this.recreationModel.isLandBuilding = false;
    }

    if(this.isLandBuilding && JSON.parse(localStorage.getItem("recreationDetails")) === true){

      this.modelLandBuildingLand = this.model;
      this.modelLandBuildingLand.valuationRequestId =this.route.snapshot.paramMap.get('id');
  
      this.recreationModel.inspectionLandDto = this.modelLand;
      this.recreationModel.inspectionCondominiumDto = this.modelCondominium;
      this.recreationModel.inspectionLandBuildingDto_landDto = this.modelLandBuildingLand;
      // this.recreationModel.inspectionLandBuildingDto = this.modellandbuildingmain;

      if(this.modelLandBuildingLand.propertySubTypeId === null || this.modelLandBuildingLand.propertySubTypeId === undefined || this.modelLandBuildingLand.propertySubTypeId === ""){
        haveErrors = true;
      }

    }else{
      this.modellandbuildingmain.inspectionLandDto = this.model;
      this.modellandbuildingmain.createdBy = this.userdata["firstName"];
      this.modellandbuildingmain.createdUserId = this.userdata["id"];
      this.modellandbuildingmain.recreationGroupId = JSON.parse(
        localStorage.getItem("recreationGroupId")
      );
      this.modellandbuildingmain.valuationRequestId = this.valuationRequestId;
      this.modellandbuildingmain.isLand = true;
  
  
      this.recreationModel.inspectionLandDto = this.modelLand;
      this.recreationModel.inspectionCondominiumDto = this.modelCondominium;
      this.recreationModel.inspectionLandBuildingDto = this.modellandbuildingmain;

      if(this.modellandbuildingmain.inspectionLandDto.propertySubTypeId === null || this.modellandbuildingmain.inspectionLandDto.propertySubTypeId === undefined || this.modellandbuildingmain.inspectionLandDto.propertySubTypeId === ""){
        haveErrors = true;
      }
    }




    localStorage.setItem(
      "inspectionLandBuilding-Land",
      JSON.stringify(this.model)
    ); //save land with building land details in localhost
    if(haveErrors === false) {
      this.disableBuildingSaveBtn = false;
     this.saveAndUpdate();
    }else{
      this.disableBuildingSaveBtn = false;
      this.notifier.notify(
        "error",
        "Please Fill all Required Fields."
      );
    }
  }

  // model Methods
  closeModel() {
    this.modalService.dismissAll();
  }
}
