import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ValuationStatus } from 'src/app/model/valuation-status';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuildingService {
  private subject = new Subject<any>();
  private requestUrl = '/building-types';  // URL to web api
  constructor(
    private http: HttpClient
  ) { }

  sendBuildingStatus(){
    this.subject.next();
  }

  getBuildingStatus():Observable<any>{
    return this.subject.asObservable();
  }

  buildingVerificationStatus(ValuationStatus: ValuationStatus): Observable<any> {
    return this.http.post<any>(`${environment.api_url}` + this.requestUrl + '/status', ValuationStatus);
  }

  buildingUpdateVerificationStatus(ValuationStatus: ValuationStatus): Observable<any> {
    return this.http.post<any>(`${environment.api_url}` + this.requestUrl + '/updatestatus', ValuationStatus);
  }

}
