import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OfficeService } from 'src/app/service/office/office.service';
import { Office } from 'src/app/model/office';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-add-office',
  templateUrl: './add-office.component.html',
  styleUrls: ['./add-office.component.scss']
})
export class AddOfficeComponent implements OnInit, OnDestroy {

  addOfficeForm: FormGroup;
  private sub = new SubSink();

  constructor(private formBuilder: FormBuilder,
              private officeService: OfficeService,
              private notifier: NotifierService,
              private dataService: DataService) { }

  ngOnInit() {
    this.addOfficeForm = this.formBuilder.group({
      code: ['', [Validators.required]],
      office: ['', [Validators.required]]
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  saveOffice() {
    if (this.addOfficeForm.valid) {

      const office = new Office();

      office.office = this.getDescription();
      office.code = this.getCode();

      this.sub.add(this.officeService.createOffice(office)
        .subscribe(response => {
          this.notifier.notify( 'success' , 'Successfully Added Office');
          this.dataService.updateTableOffice(true);
        }));
    }
  }

  getCode() {
    return this.addOfficeForm.get('code').value;
  }

  getDescription() {
    return this.addOfficeForm.get('office').value;
  }

}
