import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { refundRequestCommon } from 'src/app/model/refundRequestCommon';
import { RefundOpinionServiceService } from 'src/app/service/RefundOpinionService/refund-opinion-service.service';
import Swal from 'sweetalert2';
import { GetOpinionDialogComponent } from '../refund-application-external-user/get-opinion-dialog/get-opinion-dialog.component';


@Component({
  selector: 'app-header-external',
  templateUrl: './header-external.component.html',
  styleUrls: ['./header-external.component.scss']
})
export class HeaderExternalComponent implements OnInit {

  public common = new refundRequestCommon('','','','','','','',null,null,null,null,null,null);

  selectedComponent: string;

  OpinionVal: number;
  sendOpinion:number;
  opinionNo:any;
  opinionDetails: any;

  numberOfNotifications: any;
  notifications: any;

  constructor(
    private rout: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private authService: AuthService,
    private refundService: RefundOpinionServiceService) { }

  ngOnInit() {
    this.selectedComponent = sessionStorage.getItem('selectedType');
  }

  clickGift() {
    // Same method exists in dashboard-external.component.ts
    this.selectedComponent = 'Gift';
    localStorage.setItem('valuation_request_id', null);
    sessionStorage.setItem('selectedType', this.selectedComponent);
    localStorage.setItem('first_valuation_request_id', null);
    localStorage.setItem('second_valuation_request_id', null);
    sessionStorage.setItem('valuationUpdate', 'false');
    this.router.navigate(['/new-application/gift']);
  }

  clickTransfer() {
    this.selectedComponent = 'Transfer';
    localStorage.setItem('valuation_request_id', null);
    localStorage.setItem('first_valuation_request_id', null);
    localStorage.setItem('second_valuation_request_id', null);
    sessionStorage.setItem('selectedType', this.selectedComponent);
    sessionStorage.setItem('valuationUpdate', 'false');
    this.router.navigate(['/new-application/transfer']);
  }

  clickExchange() {
    this.selectedComponent = 'Exchange';
    localStorage.setItem('valuation_request_id', null);
    localStorage.setItem('first_valuation_request_id', null);
    localStorage.setItem('second_valuation_request_id', null);
    sessionStorage.setItem('selectedType', this.selectedComponent);
    sessionStorage.setItem('valuationUpdate', 'false');
    this.router.navigate(['/new-application/exchange']);
  }

  openDialog() : void{
    const dialogRef =this.dialog.open(GetOpinionDialogComponent,{
      data:{opinion:this.OpinionVal}
    });
   dialogRef.afterClosed().subscribe(
      result => {
        this.refundService.getOpinionDetails(result).subscribe( 
          data => {
            this.opinionDetails = data.data;
            
            if (this.opinionDetails === false) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enter valid Opinion Number!',
                allowOutsideClick: false,
                allowEscapeKey:false,
              });
            } else if (this.opinionDetails.stamp_duty === "null") {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Stamp Duty not Calculated!',
                allowOutsideClick: false,
                allowEscapeKey:false,
              });
            } else {
              localStorage.setItem('opinionNo', result);
              this.opinionNo=localStorage.getItem('opinionNo');
              this.sendOpinion = parseInt(this.opinionNo);
        
              if( this.opinionNo!== 'undefined'){
        
                this.router.navigate(['/refund-application',result]);
                sessionStorage.setItem('hasOpinion','true');
        
                /* create common table refund request with opinion
                    need to check without opinion, check get-opinion-dialog.TS
                */
                localStorage.removeItem('refundId');
                this.common.hasOpinion = true;
                this.common.deedNumber = this.opinionNo;
                this.refundService.createRefundRequestById(this.common).subscribe(data =>{
                  let getRefundid = (data as any).data.id;
                  localStorage.setItem('refundId', getRefundid);
                  sessionStorage.setItem('refundRequestId', getRefundid);
                  sessionStorage.setItem('hasPreviousOpinion', 'true');
                });
              }
            }
          }
        );
      }
    );
  }

  furtherRequirementApplication(deedNature,id){
    if (deedNature === 'Gift') {
      // Below localStorage items are being used in "other-fr.components.ts"
      localStorage.setItem('valuation_request_id', id);
      sessionStorage.setItem('valuationUpdate', 'true');
      sessionStorage.setItem('selectedType', 'Gift');
      this.router.navigate(['/further-requirement-update/gift-further-requirement']);
    } else if (deedNature === 'Transfer') {
      localStorage.setItem('valuation_request_id', id);
      sessionStorage.setItem('valuationUpdate', 'true');
      sessionStorage.setItem('selectedType', 'Transfer');
      this.router.navigate(['/further-requirement-update/transfer-further-requirement']);
    } else if (deedNature === 'Exchange') {
      localStorage.setItem('valuation_request_id', id);
      sessionStorage.setItem('valuationUpdate', 'true');
      sessionStorage.setItem('selectedType', 'Exchange');
      this.router.navigate(['/further-requirement-update/exchange-further-requirement']);
    }
  }

  logout(){
    this.authService.logout();
  }

}
