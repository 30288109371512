export class ValuationRequestUpdate {
  valuationId: string;
  dcommissionerId: string;
  acccessorId: string;
  remark: string;
  userRole: string;
  referenceNumber: string;

  constructor(
    valuationId: string,
    dcommissionerId: string,
    acccessorId: string,
    remark: string,
    userRole: string,
    referenceNumber: string
  ) {
    this.valuationId = valuationId;
    this.dcommissionerId = dcommissionerId;
    this.acccessorId = acccessorId;
    this.remark = remark;
    this.userRole = userRole;
    this.referenceNumber = referenceNumber;
  }
}
