import { InspectionLandBuilding } from './inspection-land-building';
import { InspectionLand } from './inspection-land';

export class InspectionLandBuildingMain {
  public id:number;
  public inspectionId: string;

  public propertyTypeId: string;
  public propertySubTypeId: string;
  public valuationRequestId: string;
  public inspectionType: string;
  public isRecreation: boolean;
  public createdBy: string;
  public createdUserId: string;
  public recreationGroupId: string;

  public buildingInformationList: Array<InspectionLandBuilding>;

  public inspectionLandDto: InspectionLand;

  public isLand: boolean;

  public isBuilding: boolean;

  constructor(
    id:number,
    propertyTypeId: string,
    propertySubTypeId: string,
    valuationRequestId: string,
    createdBy: string,
    isRecreation: boolean,
    buildingInformationList: Array<InspectionLandBuilding>,
    inspectionLandDto: InspectionLand,
    createdUserId: string,
    recreationGroupId: string,
    isLand : boolean,
    isBuilding: boolean,
  ) {
    this.id = id;
    this.propertyTypeId = propertyTypeId;
    this.propertySubTypeId = propertySubTypeId;
    this.valuationRequestId = valuationRequestId;
    this.isRecreation = isRecreation;
    this.createdBy = createdBy;
    this.createdUserId = createdUserId;
    this.recreationGroupId = recreationGroupId;
    this.buildingInformationList = buildingInformationList;
    this.inspectionLandDto = inspectionLandDto;
    this.isLand = isLand;
    this.isBuilding = isBuilding;
  }
}
