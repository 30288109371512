import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InspectionBusinessTypeService {

  private inspectionBusinessTypeUrl = '/inspection-business-type';


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  createBusinessType(BusinessTypeModel:InspectionCommonDataModel):Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionBusinessTypeUrl}`,BusinessTypeModel)
  }

  getBusinessType(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.inspectionBusinessTypeUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  updateBusinessType(id: number, BusinessTypeModel:InspectionCommonDataModel): Observable<any> {
    return this.http.put(`${environment.api_url}${this.inspectionBusinessTypeUrl}/${id}`, BusinessTypeModel);
  }

  searchBusinessType(keyWord:string,offset, limit):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.inspectionBusinessTypeUrl +'/search?keyword='+ keyWord + '&offset=' + offset + '&limit=' + limit + '');
  }

  searchBusinessTypeById(id:number):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.inspectionBusinessTypeUrl +'/'+ id);
  }

  deleteBusinessType(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.inspectionBusinessTypeUrl}/${id}`);
  }
}
