import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { InspectionElectricitySubTypeModel } from 'src/app/model/inspection-master-data-model/inspection-electricity-sub-type-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InspectionElectricitySubTypeService {

  private inspectionElectricitySubTypeUrl = '/inspection-electricity-sub-type';


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  createElectricitySubType(ElectricitySubTypeModel:InspectionElectricitySubTypeModel):Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionElectricitySubTypeUrl}`,ElectricitySubTypeModel)
  }

  getElectricitySubType(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.inspectionElectricitySubTypeUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  updateElectricitySubType(id: number, ElectricitySubTypeModel:InspectionElectricitySubTypeModel): Observable<any> {
    return this.http.put(`${environment.api_url}${this.inspectionElectricitySubTypeUrl}/${id}`, ElectricitySubTypeModel);
  }

  searchElectricitySubType(keyWord:string,offset, limit):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.inspectionElectricitySubTypeUrl +'/search?keyword='+ keyWord + '&offset=' + offset + '&limit=' + limit + '');
  }

  searchElectricityTypeById(id:number):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.inspectionElectricitySubTypeUrl +'/'+ id);
  }

  deleteElectricitySubType(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.inspectionElectricitySubTypeUrl}/${id}`);
  }
}
