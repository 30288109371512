import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {Appeal} from '../../../../../../model/appeal';
import {AppealService} from '../../../../../../service/appeal/appeal.service';
import {NotifierService} from 'angular-notifier';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileService } from 'src/app/service/file/file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ValuationRequestService } from 'src/app/service/valuation-request/valuation-request.service';
import { ValuationStatus } from 'src/app/model/valuation-status';

@Component({
  selector: 'app-appeal-request-form',
  templateUrl: './appeal-request-form.component.html',
  styleUrls: ['./appeal-request-form.component.scss']
})
export class AppealRequestFormComponent implements OnInit {
  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  valuationRequestId: number;
  @ViewChild('popupSuccessMessage', null) private popupMigrated: TemplateRef<object>;

  saveGrantee: boolean;
  saveNotary: boolean;

  public appealModel = new Appeal('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '');
  public modelStatus = new ValuationStatus('', '');

  files;
  filesList: File[] = [];
  images = [];
  originalImages: any[] = [];
  names: string[] = [];

  public imgURL1: any;
  public imgURL2: any;
  generatedFileNumber: string;
  fileNumberFormat: string;
  noDocs = false;
  dataDone = false;
  previewFileData: any;
  verificationStatus: number = 0;
  constructor(
    public appealService: AppealService,
    private notifier: NotifierService,
    public router: Router,
    private fileService: FileService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public sanitizer: DomSanitizer,
    private valuationRequestService: ValuationRequestService,
    public modalService: NgbModal
  ) {
  }

  ngOnInit() {
    this._prepare();
  }

  _prepare() {
    this.loadInfo();
    this.pad(JSON.parse(sessionStorage.getItem('valuationRequest')).id, 7);
    this.fileNumberFormat = JSON.parse(sessionStorage.getItem('valuationRequest')).fileNumberFormat;
  }

  public redirectComponent(): void {
    this.onPageCountChanged.emit(3);
  }

  pad(num: number, size: number) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    this.generatedFileNumber = s;
    this.generatedFileNumber = '#A' + this.generatedFileNumber;
  }

  loadInfo() {
    this.appealService.appealInsertedInfo(this.valuationRequestId).subscribe(
      data => {
        this.appealModel = data.data;
      }
    );
  }

  openPopupMessage(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static'});
  }

  onSubmit() {

    this.appealModel.valuationRequestId = this.valuationRequestId.toString();
    this.appealService.insertAppeal(this.appealModel).subscribe(
      res => {
        console.log(res.data);
        // var id =(res as any).data.id
        // this.appealService.uploadAppealDocuments(this.filesList, id).subscribe(
        //   data => {
        //     this.openPopupMessage(this.popupMigrated);
        //   }, err => {
        //     this.notifier.notify('error', 'Error occurred while uploading.');
        //   }
        // );
      },
      err => {
        this.notifier.notify('error', 'Error occurred while processing the appeal request.');
      }
    );
  }

  redirectToMainTable() {
    this.modalService.dismissAll();
    this.router.navigate(['/view-request/' + this.valuationRequestId]);
  }

  previewFile(type) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadFileById(+this.route.snapshot.paramMap.get('id'), type).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(()=>{
          this.spinner.hide();
        }, 2000);

      }else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }

  updateVerificationStatus(event){
    if (event.target.checked === true) {
      this.verificationStatus = 1;
    }else{
      this.verificationStatus = 0;
    }
    this.modelStatus.id = JSON.parse(sessionStorage.getItem('valuationRequest')).id;
    this.modelStatus.verificationStatus = '1';
    console.log(this.modelStatus,"staus")
    this.appealService.appealUpdateVerificationStatus(this.modelStatus).subscribe();
  }

}
