import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CompanyCategoryService} from '../../../../../../../service/company-category/company-category.service';
import {UpdateCompanyCategoryComponent} from '../update-company-category/update-company-category.component';
import {DeleteCompanyCategoryComponent} from '../delete-company-category/delete-company-category.component';
import {SubSink} from 'subsink';
import {DataService} from '../../../../../../../service/data.service';
import {ICompanyCategory} from '../../../../../../../model/interfaces/company-category';


@Component({
  selector: 'app-view-company-category',
  templateUrl: './view-company-category.component.html',
  styleUrls: ['./view-company-category.component.scss']
})
export class ViewCompanyCategoryComponent implements OnInit, OnDestroy {

  @ViewChild(UpdateCompanyCategoryComponent, {static: false})
  private updateCompanyCategoryComponent: UpdateCompanyCategoryComponent;
  @ViewChild(DeleteCompanyCategoryComponent, {static: false})
  private deleteCompanyCategoryComponent: DeleteCompanyCategoryComponent;

  private sub = new SubSink();
  companyCategories: ICompanyCategory [];
  updateTable: boolean;
  keyword: string;

  // pagination
  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];
  currentPage: any;
  totalItems: any;

  constructor(private companyCategoryService: CompanyCategoryService,
              private dataService: DataService) { }

   ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.companyCategoryService.getCompanyCategories(this.selectedPageNo, this.limit).subscribe(
      (res) => {
        // this.companyCategories =  res.data.content;
        // this.paginationButtonCounter(res.data.totalPages);
        // this.numOfPages = res.data.totalPages;
        this.companyCategories = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      });

    this.sub.add(this.dataService.updateDataTableCompanyCategory
      .subscribe(update => this.onAdded(update)));

  }

  pageable(pageNo: number) {
    this.selectedPageNo = pageNo-1;
    this.sub.add(this.companyCategoryService.getCompanyCategories(this.selectedPageNo, this.limit).subscribe(res => {
      // this.companyCategories =  res.data.content;
      // this.paginationButtonCounter(res.data.totalPages);
      // this.numOfPages = res.data.totalPages;
      this.companyCategories = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    }));
  }

  triggerUpdate(id: number, name: string, description: string) {
    this.updateCompanyCategoryComponent.setIdAndDescription(id, name, description);
  }

  triggerDelete(id: number) {
    this.deleteCompanyCategoryComponent.setCompanyCategoryId(id);
  }

  onUpdated(updatedSuccessfully: boolean) {
    if (updatedSuccessfully) {
      this.updateTheTable();
    }
  }

  onDeleted(deletedSuccessfully: boolean) {
    if (deletedSuccessfully) {
      this.updateTheTable();
    }
  }

  onAdded(addedSuccessfully: boolean) {
    if (addedSuccessfully) {
      this.updateTheTable();
    }
  }

  // update the table
  private updateTheTable() {
    this.selectedPageNo = 0;
    this.sub.add(this.companyCategoryService.getCompanyCategories(this.selectedPageNo, this.limit).subscribe(
      (res) => {
        // this.companyCategories =  res.data.content;
        // this.paginationButtonCounter(res.data.totalPages);
        // this.numOfPages = res.data.totalPages;
        this.companyCategories = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      }));
  }

  searchCompanyCategory(keyword: string) {
    this.selectedPageNo = 0;
    this.keyword = keyword;
    this.sub.add(this.companyCategoryService.searchCompanyCategories (keyword, this.selectedPageNo, this.limit)
      .subscribe((res) => {
        // this.companyCategories =  res.data.content;
        // this.paginationButtonCounter(res.data.totalPages);
        // this.numOfPages = res.data.totalPages;
        this.companyCategories = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      }));
  }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
