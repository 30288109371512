import { Component, OnInit } from '@angular/core';
import {GetOpinionDialogComponent} from './get-opinion-dialog/get-opinion-dialog.component';


@Component({
  selector: 'app-refund-application-external-user',
  templateUrl: './refund-application-external-user.component.html',
  styleUrls: ['./refund-application-external-user.component.scss']
})
export class RefundApplicationExternalUserComponent implements OnInit {

   hasOpinion:Boolean;


  param : any;

  selectSideNav=0;





  constructor() {

  }
  public changeComponent(page: any): void {
    this.selectSideNav = page;
  }
  ngOnInit() {
    if (sessionStorage.getItem('hasOpinion')== "true") {
      this.selectSideNav=1;
      this.hasOpinion=true;
    } else {
      this.selectSideNav=2;
      this.hasOpinion=false;
    }
  }

}
