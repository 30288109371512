import {Component, OnInit, ViewChild} from '@angular/core';
import { isNumber } from 'util';
import {ViewLocalAuthorityComponent} from './view-local-authority/view-local-authority.component';


@Component({
  selector: 'app-local-authority',
  templateUrl: './local-authority.component.html',
  styleUrls: ['./local-authority.component.scss']
})
export class LocalAuthorityComponent implements OnInit {
@ViewChild(ViewLocalAuthorityComponent,{static : false})
  private viewLocalAuthorityComponent: ViewLocalAuthorityComponent;
  keyword: any;

  constructor() { }

  ngOnInit() {
  }

  triggerSearch(event: any) {
    if (this.keyword === '') {
      this.viewLocalAuthorityComponent.onUpdated(true);
    } 
    else if(!isNaN(this.keyword)){
      this.viewLocalAuthorityComponent.searchLocalAuthorityByIdAndNo(this.keyword) 
    }
    else {
      this.viewLocalAuthorityComponent.searchLocalAuthority(this.keyword);
    }
  }

}
