import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ValuationRequestUpdate} from '../../../../../../model/valuation-request-update';
import {NotificationModel} from '../../../../../../model/notification-model';
import {ValuationRequest} from '../../../../../../model/valuation-request';
import {PropertyHistoryModel} from '../../../../../../model/propertyHistory';
import {Property} from '../../../../../../model/property';
import {SurveyModel} from '../../../../../../model/survery';
import {ProvinceService} from '../../../../../../service/province/province.service';
import {DistrictService} from '../../../../../../service/district/district.service';
import {FileService} from '../../../../../../service/file/file.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {UserService} from '../../../../../../service/user/user.service';
import {ValuationRequestService} from '../../../../../../service/valuation-request/valuation-request.service';
import {NotifierService} from 'angular-notifier';
import {NotificationService} from '../../../../../../service/notification/notification.service';
import {Constant} from '../../../../../../util/constant';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-appeal-assigner',
  templateUrl: './appeal-assigner.component.html',
  styleUrls: ['./appeal-assigner.component.scss']
})
export class AppealAssignerComponent implements OnInit {
  @ViewChild('f', null)
  f: NgForm;

  provincelist: any;
  districtlist: any;
  provinceselectedstatus: boolean;
  userdata: any = [];
  valuationdata: any = [];
  dcommissionpermissiongranted: boolean;
  accessorpermissiongranted: boolean;
  dcommissionerSelectedStatus: boolean;

  userlist: any = [];
  dcommissionerlist: any = [];
  accessorlist: any = [];
  filteredcommissionerlist: any = [];
  filteredaccessorlist: any = [];

  selectFileSm = 0;
  typeID: any;
  previewFileData: any;
  type: any;
  deedNatureValue: any;
  userrolecode: string;
  deputyCommissionerAllocated: boolean;
  accessorAllocated: boolean;

  public selectedProvinceCode: string;
  public selectedDistrictCode: string;

  public assignedprovinceCode: any;
  public assigneddistrictCode: any;

  currentYear: number = new Date().getFullYear();

  public model = new ValuationRequestUpdate('', '', '', '', '', '');
  public notificationModel = new NotificationModel("", "", "", "", "", "", "");

  public valuationRequest = new ValuationRequest();
  public valuationRequestHistory = new ValuationRequest();

  public propertyModel = new PropertyHistoryModel();
  properties: Property[];

  public surveyModel = new SurveyModel('');

  public valuationHistoryList: any = [];
  public audittraceList: any = [];
  assessorUserId: string;
  dComUserId: string;
  comUserId: string;
  notaryUserId: string;

  noDocs = false;
  dataDone = false;

  constructor(
    public provinceService: ProvinceService,
    public districtService: DistrictService,
    private fileService: FileService,
    private route: ActivatedRoute,
    public router: Router,
    public sanitizer: DomSanitizer,
    public userService: UserService,
    public valuationRequestService: ValuationRequestService,
    private notifier: NotifierService,
    private spinner: NgxSpinnerService,
    public notificationService: NotificationService
  ) {
    this.provinceselectedstatus = true;
    this.dcommissionpermissiongranted = false;
    this.accessorpermissiongranted = false;
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.valuationdata = JSON.parse(sessionStorage.getItem('valuationRequest'));
    this.valuationRequestHistory = JSON.parse(sessionStorage.getItem('valuationRequest'));
    this.dcommissionerSelectedStatus = true;
    this.deputyCommissionerAllocated = false;
    this.accessorAllocated = false;
  }

  ngOnInit() {
    this._prepare();
    this.checkForDCommissioner();
    this.properties = this.valuationRequestHistory.properties;
    this.surveyModel.surveyPlanNo = this.properties[0]['surveyorPlanNo'];
    this.loadPreviousValuationHistoryInfo(this.surveyModel);
    this.loadAudittrace();
  }

  loadAudittrace() {
    this.valuationRequestService.auditList(this.route.snapshot.paramMap.get('id')).subscribe(
      data => {
        this.audittraceList = data.data;
      }
    );
  }


  loadPreviousValuationHistoryInfo(surveyModel: SurveyModel) {
    this.valuationRequestService.valuationHistoryList(surveyModel).subscribe(
      data => {
        this.valuationHistoryList = data.data;
      }
    );
  }

  _prepare() {
    this.loadProvinceList();
    this.checkPermission();
    this.typeID = this.route.snapshot.paramMap.get('id');
    this.retrievedAssignedValuationRequest();
  }

  retrievedAssignedValuationRequest() {
    this.valuationRequestService.valuationRequestAssigned(this.route.snapshot.paramMap.get('id')).subscribe(
      data => {
        if (data.data !== null) {
          this.assignedprovinceCode = data.data.provinceCode;
          this.assigneddistrictCode = data.data.districtCode;
          this.model.dcommissionerId = data.data.dcommissionerId;
          this.model.acccessorId = data.data.accessorId;
          this.model.remark = data.data.remark;
          this.retrieveUserListFiltered(this.assigneddistrictCode);
          // this.selectedProvince(this.assignedprovinceCode);
          this.selectedProvince(this.assignedprovinceCode);
        } else {
          this.retrieveUserList();
        }
      }
    );
  }

  retrieveUserList() {
    this.userService.retrieveAssessorAndDComList().subscribe((data) => {
      this.userlist = data.data;
      this.dcommissionerlist = data.data.dComList;
      this.accessorlist = data.data.assessorList;
    });
  }

  retrieveUserListFiltered(assigneddistrictCode: any) {
    this.userService.retrieveAssessorAndDComList().subscribe((data) => {
      this.userlist = data.data;
      this.dcommissionerlist = data.data.dComList;
      this.accessorlist = data.data.assessorList;
      this.filteredcommissionerlist = this.dcommissionerlist.filter(
        (i) => i.districtCode === assigneddistrictCode
      );
      this.filteredaccessorlist = this.accessorlist.filter(
        (i) => i.districtCode === assigneddistrictCode
      );
    });
  }

  checkPermission() {
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.dcommissionpermissiongranted = true;
        this.accessorpermissiongranted = true;
        this.userrolecode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.dcommissionpermissiongranted = true;
        this.accessorpermissiongranted = true;
        this.userrolecode = Constant.ROLE_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.dcommissionpermissiongranted = false;
        this.accessorpermissiongranted = true;
        this.userrolecode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else {
        this.dcommissionpermissiongranted = false;
        this.accessorpermissiongranted = false;
      }
    }
  }

  loadProvinceList() {
    this.provinceService.getAll().subscribe(
      data => {
        this.provincelist = data.data;
      }
    );
  }

  selectedProvince(event) {
    const selectedProvince = this.provincelist.filter(i => i.id === Number(event));
    if (JSON.stringify(selectedProvince) !== '[]') {
      const selectedProvinceCode = selectedProvince[0]['description'];
      var provincematcher = selectedProvinceCode.match(/\b(\w)/g);
      var provinceacronym = provincematcher.join('');
      this.selectedProvinceCode = provinceacronym;
    }
    this.districtService.searchDistrictDetails(event).subscribe(
      data => {
        this.provinceselectedstatus = false;
        this.districtlist = data.data;
      }
    );
  }

  selectedDistrict(event) {
    const selectedDistrict = this.districtlist.filter(i => i.id === Number(event));
    if (JSON.stringify(selectedDistrict) !== '[]') {
      const selectedDistrictCode = selectedDistrict[0]['description'];
      var districtmatcher = selectedDistrictCode.match(/\b(\w)/g);
      var districtacronym = districtmatcher.join('');
      this.selectedDistrictCode = districtacronym;
    }
    this.filteredcommissionerlist = this.dcommissionerlist.filter(i => i.districtCode === event);
    this.filteredaccessorlist = this.accessorlist.filter(i => i.districtCode === event);
  }

  // previewFile(id, type) {
  //   this.fileService.loadFileById(id, type).subscribe(result => {
  //     if (result) {
  //       let temp = [];
  //       temp.push(result.data);
  //       this.previewFileData = temp;
  //     }
  //   });
  // }

  previewFile(type) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadFileById(+this.route.snapshot.paramMap.get('id'), type).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(()=>{
          this.spinner.hide();
        }, 2000);

      }else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }

  selectedDeputyCommissioner(event) {
    this.model.dcommissionerId = event;
    this.dcommissionerSelectedStatus = false;
    this.deputyCommissionerAllocated = true;
  }

  selectedAccessor(event) {
    const selectedUser = this.filteredaccessorlist.filter(i => i.id === event);
    this.valuationdata = JSON.parse(sessionStorage.getItem('valuationRequest'));
    const valuationId = JSON.stringify(this.valuationdata['id']);
    var strSequenceId = '' + valuationId;
    while (strSequenceId.length < 6) {
      strSequenceId = '0' + strSequenceId;
    }
    const referenceNumber = this.selectedProvinceCode + '/' + this.selectedDistrictCode + '/A/' + this.currentYear + '/' + selectedUser[0]['accessorCode'] + '/' + strSequenceId;
    this.model.referenceNumber = referenceNumber;
    this.model.acccessorId = event;
    this.accessorAllocated = true;
  }

  onSubmit() {
    this.valuationdata = JSON.parse(sessionStorage.getItem('valuationRequest'));
    this.model.valuationId = JSON.stringify(this.valuationdata['id']);
    this.model.userRole = this.userrolecode;

    this.valuationRequestService.findByValuationRequestId(+this.route.snapshot.paramMap.get('id')).subscribe(
      data => {
        this.deedNatureValue = data.data.deedNature;
        this.assessorUserId = data.data.accessorId;
        this.dComUserId = data.data.dcommissionerId;
        this.comUserId = data.data.commissionerId;
        this.notaryUserId = data.data.user_id;
        console.log('Deed nature: ' + this.deedNatureValue);

        if (this.deputyCommissionerAllocated == true && this.accessorAllocated == true) {
          this.notificationModel.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
          this.notificationModel.deedNature = this.deedNatureValue;
          this.notificationModel.url = this.route.snapshot.paramMap.get('id');
          this.notificationModel.subject = Constant.ASSIGNED_TO_DEPUTY_COMMISSIONER_AND_ASSESSOR;
          this.notificationModel.userId = this.dComUserId;
          this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_APPEAL

          this.notificationService.createNotification(this.notificationModel).subscribe(
            data => {
              this.notificationModel.userRoleCode = Constant.ROLE_ACCESSOR;
              this.notificationModel.deedNature = this.deedNatureValue;
              this.notificationModel.url = this.route.snapshot.paramMap.get('id');
              this.notificationModel.subject = Constant.ASSIGNED_TO_DEPUTY_COMMISSIONER_AND_ASSESSOR;
              this.notificationModel.userId = this.assessorUserId;
              this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_APPEAL

              this.notificationService.createNotification(this.notificationModel).subscribe(
                data => {
                }
              );
            }
          );
        } else if (this.accessorAllocated === true) {
          this.notificationModel.userRoleCode = Constant.ROLE_ACCESSOR;
          this.notificationModel.deedNature = this.deedNatureValue;
          this.notificationModel.url = this.route.snapshot.paramMap.get('id');
          this.notificationModel.subject = Constant.ASSIGNED_TO_DEPUTY_COMMISSIONER_AND_ASSESSOR;
          this.notificationModel.userId = this.assessorUserId;
          this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_APPEAL

          this.notificationService.createNotification(this.notificationModel).subscribe(
            data => {
            }
          );
        } else if (this.deputyCommissionerAllocated === true) {
          this.notificationModel.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
          this.notificationModel.deedNature = this.deedNatureValue;
          this.notificationModel.url = this.route.snapshot.paramMap.get('id');
          this.notificationModel.subject = Constant.ASSIGNED_TO_DEPUTY_COMMISSIONER_AND_ASSESSOR;
          this.notificationModel.userId = this.dComUserId;
          this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_APPEAL

          this.notificationService.createNotification(this.notificationModel).subscribe(
            data => {
            }
          );
        }
      }
    );
    this.valuationRequestService.valuationRequestUpdate(this.model).subscribe(
      data => {
        if (data.data === true) {
          this.router.navigate(['/main-dashboard-internal-user']);
          this.notifier.notify('success', 'Successfully Assigned');
        }
      }
    );
  }

  checkForDCommissioner() {
    if (this.dcommissionpermissiongranted === false) {
      this.dcommissionerSelectedStatus = false;
    }
  }

}
