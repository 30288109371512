import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubSink} from 'subsink';
import {NotifierService} from 'angular-notifier';
import {Property} from '../../../../../../../../model/property';

@Component({
  selector: 'app-property-extend',
  templateUrl: './property-extend.component.html',
  styleUrls: ['./property-extend.component.scss']
})
export class PropertyExtendComponent implements OnInit {

  @Input() propertyOne: Property;
  propertyExtentForm: FormGroup;
  txtValue: string = null;
  convtoval: number;
  units = new Map().set('feet', 1)
    .set('perch', 272.25)
    .set('acre', 43560)
    .set('hectare', 107639);
  // Validation
  onlyNumbersPattern = '^[0-9.]*$';
  onlyCharactersNumbersPattern = '^[_A-z0-9 ]*((-|\\s)*[_A-z0-9 ])*$';
  private sub = new SubSink();

  constructor(private formBuilder: FormBuilder,
              private notifier: NotifierService) {
  }

  get f() {
    return this.propertyExtentForm.controls;
  }

  ngOnInit() {
    this.propertyExtentForm = this.formBuilder.group({

      // extentPerch: [this.propertyOne.extent, [Validators.required, Validators.pattern(this.onlyNumbersPattern)]],
      // zoneNo: [this.propertyOne.zoneNo, [Validators.pattern(this.onlyCharactersNumbersPattern)]],
      otherDetails: [this.propertyOne.otherDetails],
      convfrom: [this.propertyOne.extentFromUnit, [Validators.required]],
      convto: [this.propertyOne.extentToUnit, [Validators.required]],
      convfromval: [this.propertyOne.extentFromValue == null ? this.propertyOne.extentFromValue:this.propertyOne.extentFromValue.toFixed(4), [Validators.required, Validators.pattern(this.onlyNumbersPattern)]],
      convtoval: [this.propertyOne.extentToValue == null ? this.propertyOne.extentToValue :this.propertyOne.extentToValue.toFixed(4),[Validators.required]]
    });
  }

  isDisabled() {
    if (this.propertyExtentForm.get('convfrom').value != null) {
      this.propertyExtentForm.get('convto').enable();
      this.propertyExtentForm.get('convtoval').enable();
      // const perchValue = this.propertyExtentForm.get('extentPerch').value;
      const fromUnit = this.propertyExtentForm.get('convfrom').value;
      const toUnit = this.propertyExtentForm.get('convto').value;
      const convfromval = this.propertyExtentForm.get('convfromval').value;
      this.convtoval = +(convfromval * this.units.get(fromUnit) / this.units.get(toUnit)).toFixed(4);
      // this.propertyExtentForm.get('convtoval').setValue(convfromval * this.units.get(fromUnit) / this.units.get(toUnit));
      // this.propertyExtentForm.get('extentHectare').setValue(perchValue * 0.00253);
    } else{
      this.propertyExtentForm.get('convto').disable();
      this.propertyExtentForm.get('convto').setValue(null);
      this.propertyExtentForm.get('convtoval').disable();
    }
  }

  saveExtentDetails(): boolean {
    this.propertyExtentForm.markAllAsTouched();
    if (this.propertyExtentForm.valid) {
      this.setPropertyExtentDetails();
      return true;
    } else {
      return false;
    }
  }

  setPropertyExtentDetails(): void {
    this.propertyOne.otherDetails = this.propertyExtentForm.get('otherDetails').value;
    this.propertyOne.extentToUnit = this.propertyExtentForm.get('convto').value;
    this.propertyOne.extentFromUnit = this.propertyExtentForm.get('convfrom').value;
    this.propertyOne.extentFromValue = parseFloat(this.propertyExtentForm.get('convfromval').value.replace(/,/g, ''));
    this.propertyOne.extentToValue = parseFloat(this.propertyExtentForm.get('convtoval').value.replace(/,/g, ''));
    localStorage.setItem('extend', JSON.stringify(this.propertyOne));
  }
}
