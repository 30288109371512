import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RefundRequestCount } from 'src/app/model/refundRequestCount';
import { DashboardService } from 'src/app/service/dashboard/dashboard.service';
import { Constant } from 'src/app/util/constant';

@Component({
  selector: 'app-rim-view-new-application-side-nav',
  templateUrl: './rim-view-new-application-side-nav.component.html',
  styleUrls: ['./rim-view-new-application-side-nav.component.scss']
})
export class RimViewNewApplicationSideNavComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router:Router,
    private dashboardService: DashboardService
    ) { }

  // type = "new-application"
  type: string;
  selectValRequest:string;

  public refundRequestCountModel = new RefundRequestCount('', '', '');

  numberOfNotifications: any;
  newRefundApplicationsCount: any;
  inprogressRefundApplicationsCount: any;
  rejectCount: any;
  completedCount:any;

  userData: any = [];
  userRoleCode: string;

  ngOnInit() {


this.route.paramMap.subscribe(params => {
      if (params.get('type') === 'all-request') {
        this.type = 'new-application';
        this.router.navigate(['/reimbursement-application-view',this.type])
      } else {
        this.type = params.get('type');
        this.router.navigate(['/reimbursement-application-view',this.type])
      }
      this.selectValRequest = this.type;

    });

    this.getType(this.type)
    this.obtainUserData()
  }

  getType(type){
    this.router.navigate(['/reimbursement-application-view/',type])
  }

  obtainUserData() {
    this.userData = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.refundRequestCountModel.userId = this.userData.id;
    for (let role of this.userData.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.userRoleCode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.userRoleCode = Constant.ROLE_ACCESSOR;
        break;
      } else if (role.code === Constant.ROLE_TAX_OFFICER) {
        this.userRoleCode = Constant.ROLE_TAX_OFFICER;
        break;
      } else if (role.code === Constant.ROLE_ACCOUNTANT) {
        this.userRoleCode = Constant.ROLE_ACCOUNTANT;
        break;
      }
    }
    this.getNewApplicationCount();
    this.getInprogressApplicationCount();
    this.getCompletedApplicationCount();
    this.getRejectedApplicationCount();
  }

  getNewApplicationCount() {
    this.refundRequestCountModel.refundApplicationStatus = Constant.RIM_REQUEST_PENDING_CODE;
    this.refundRequestCountModel.userRoleCode = this.userRoleCode;
    this.dashboardService.getRimAplicationCount(this.refundRequestCountModel).subscribe(value => {
      this.newRefundApplicationsCount = value;
    });
  }

  getInprogressApplicationCount() {
    this.refundRequestCountModel.refundApplicationStatus = Constant.RIM_REQUEST_INPROGRESS_CODE;
    this.refundRequestCountModel.userRoleCode = this.userRoleCode;
    this.dashboardService.getRimAplicationCount(this.refundRequestCountModel).subscribe(value => {
      this.inprogressRefundApplicationsCount = value;
    });
  }
  getCompletedApplicationCount() {
    this.refundRequestCountModel.refundApplicationStatus = Constant.RIM_COMPLETED_APPLICATION;
    this.refundRequestCountModel.userRoleCode = this.userRoleCode;
    this.dashboardService.getRimAplicationCount(this.refundRequestCountModel).subscribe(value => {
      this.completedCount = value;
    });
  }
  getRejectedApplicationCount() {
    this.refundRequestCountModel.refundApplicationStatus = Constant.RIM_REQUEST_REJECTED_CODE;
    this.refundRequestCountModel.userRoleCode = this.userRoleCode;
    this.dashboardService.getRimAplicationCount(this.refundRequestCountModel).subscribe(value => {
      this.rejectCount = value;
    });
  }
}
