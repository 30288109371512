import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { InspectionSubPropertyModel } from 'src/app/model/inspection-master-data-model/inspection-sub-property';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InspectionSubPropertyService {

  private inspectionSubPropertyUrl = '/inspection-sub-property';


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  createSubProperty(subPropertyModel:InspectionSubPropertyModel):Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionSubPropertyUrl}`,subPropertyModel)
  }

  getSubProperties(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.inspectionSubPropertyUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  updateSubProperty(id: number, subPropertyModel:InspectionSubPropertyModel): Observable<any> {
    return this.http.put(`${environment.api_url}${this.inspectionSubPropertyUrl}/${id}`, subPropertyModel);
  }

  searchSubProperty(keyWord:string,offset, limit):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.inspectionSubPropertyUrl +'/search?keyword='+ keyWord + '&offset=' + offset + '&limit=' + limit + '');
  }

  deleteSubProperty(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.inspectionSubPropertyUrl}/${id}`);
  }
}
