import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubSink} from 'subsink';
import {DocumentTypeService} from '../../../../../../service/document-type/document-type.service';
import {NotifierService} from 'angular-notifier';
import {DocumentType} from '../../../../../../model/document-type';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent implements OnInit {


  updateUserTypeForm: FormGroup;
  private sub = new SubSink();
  description: string;
  code: string;
  userRoles = [
    'Super Admin',
    'Admin'
  ];

  @Output() userTypeUpdate = new EventEmitter<boolean>();

  constructor(private formBuilder: FormBuilder,
              private documentTypeService: DocumentTypeService,
              private notifier: NotifierService) { }

  ngOnInit() {
    this.updateUserTypeForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      userRole: ['', [Validators.required]],
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      re_password: ['', [Validators.required]],
    })
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updateUser() {
    // if (this.updateUserTypeForm.valid) {
    //   const documentType = new DocumentType();
    //   documentType.description = this.getDescription();
    //   documentType.code = this.getCode();
    //   documentType.name=this.getName();
    //   this.sub.add(this.documentTypeService.updateDocumentType(this.getId(), documentType)
    //     .subscribe(response => {
    //       this.updateTheView(true);
    //       this.notifier.notify( 'success', (response as any).message);
    //
    //     }));
    // }
  }

  getId() {
    return this.updateUserTypeForm.get('id').value;
  }
  // setIdAndDescription(id: number, code: string,name:string, description: string) {
  //   this.updateUserTypeForm.get('id').setValue(id);
  //   this.updateUserTypeForm.get('code').setValue(code);
  //   this.updateUserTypeForm.get('name').setValue(name);
  //   this.updateUserTypeForm.get('description').setValue(description);
  // }
  //
  // getDescription() {
  //   return this.updateUserTypeForm.get('description').value;
  // }
  // getName() {
  //   return this.updateUserTypeForm.get('name').value;
  // }
  // getCode() {
  //   return this.updateUserTypeForm.get('code').value;
  // }
  //
  // updateTheView(updated: boolean) {
  //   // this.userTypeUpdate.emit(updated);
  // }

}
