import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CompanyCategoryService} from '../../../../../../../service/company-category/company-category.service';
import {CompanyCategory} from '../../../../../../../model/companyCategory';
import {NotifierService} from 'angular-notifier';
import {SubSink} from 'subsink';

@Component({
  selector: 'app-update-company-category',
  templateUrl: './update-company-category.component.html',
  styleUrls: ['./update-company-category.component.scss']
})
export class UpdateCompanyCategoryComponent implements OnInit, OnDestroy {

  description: string;
  updateCompanyCategoryForm: FormGroup;
  @Output() companyCategoryUpdated = new EventEmitter<boolean>();
  private sub = new SubSink();

  constructor(private companyCategoryService: CompanyCategoryService,
              private formBuilder: FormBuilder,
              private notifier: NotifierService) {
  }

  get f() {
    return this.updateCompanyCategoryForm.controls;
  }

  ngOnInit() {
    this.updateCompanyCategoryForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required]],
      description: ['']
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updateCompanyCategory() {
    if (this.updateCompanyCategoryForm.valid) {
      const companyCategory = new CompanyCategory();
      companyCategory.description = this.getDescription();
      companyCategory.name = this.getName();
      this.sub.add(this.companyCategoryService.updateCompanyCategory(this.getId(), companyCategory)
        .subscribe(response => {
          this.updateTheView(true);
          this.notifier.notify('success', (response as any).message);
        }));
    } else {
      this.notifier.notify('error', 'Can not update with empty fields');
    }
  }

  getId() {
    return this.updateCompanyCategoryForm.get('id').value;
  }

  getName() {
    return this.updateCompanyCategoryForm.get('name').value;
  }

  setIdAndDescription(id: number, name: string, description: string) {
    this.updateCompanyCategoryForm.get('id').setValue(id);
    this.updateCompanyCategoryForm.get('name').setValue(name);
    this.updateCompanyCategoryForm.get('description').setValue(description);
  }

  getDescription() {
    return this.updateCompanyCategoryForm.get('description').value;
  }

  updateTheView(updated: boolean) {
    this.companyCategoryUpdated.emit(updated);
  }
}
