export class RefundRequestCount {
    userId: string;
    refundApplicationStatus: string;
    userRoleCode: string;
  
    constructor(
      userId: string,
      refundApplicationStatus: string,
      userRoleCode: string
    ) {
      this.userId = userId;
      this.refundApplicationStatus = refundApplicationStatus;
      this.userRoleCode = userRoleCode;
    }
  }