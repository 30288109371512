import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { collectionDetails } from 'src/app/model/collectionDetails';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CollectionServiceService {

  collection_url = "/collection";

  constructor(
    private http: HttpClient,
    private notifier: NotifierService
  ) { }


    getCollectionDetails(refundId: number): Observable<any> {
      return this.http.get(environment.api_url + this.collection_url +"/"+ refundId)
    }

    getCollectionPaymentDetails(refundId: number): Observable<any> {
      return this.http.get(environment.api_url + this.collection_url +"/payment/"+ refundId)
    }

    saveCollectionPayment(collectionDetails): Observable<any>{
      return this.http.post(environment.api_url + this.collection_url +"/save-payment", collectionDetails)
    }


    updateCollectionDetails(refundId : number, collectionDetails): Observable<any>{
      return this.http.put(environment.api_url + this.collection_url + "/upload-collection-details/"+ refundId, collectionDetails)
    }

    
}
