import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-valuation',
  templateUrl: './valuation.component.html',
  styleUrls: ['./valuation.component.scss']
})
export class ValuationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
