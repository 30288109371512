import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { PDFProgressData } from 'ng2-pdf-viewer';
import { DomSanitizer } from '@angular/platform-browser';

import {FileUploadComponent} from '../../../file-upload/file-upload.component';
import {FileUpload1Component} from '../../../file-upload-draft-deed/file-upload1.component';
import {FileUpload2Component} from '../../../file-upload-survey_building_plan/file-upload2.component';
import {FileUpload3Component} from '../../../file-upload-route_plan/file-upload3.component';
import {FileUpload4Component} from '../../../file-upload-assessment_valuation/file-upload4.component';
import {FileUpload5Component} from '../../../file-upload-nic/file-upload5.component';
import {FileUpload6Component} from '../../../file-upload-other/file-upload6.component';
import {Property} from '../../../../../../../model/property';
import {FileUploadAssessmentComponent} from "../../../file-upload-assessment/file-upload-assessment.component";
import { FileService } from 'src/app/service/file/file.service';
// import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-other',
  templateUrl: './other.component.html',
  styleUrls: ['./other.component.scss']
})
export class OtherComponent implements OnInit {

  @ViewChild('fileUploadCopyOfPreviousDeed', {static: false}) fileUploadCopyOfPreviousDeed: FileUploadComponent;
  @ViewChild('fileUploadDraftDeed', {static: false}) fileUploadDraftDeed: FileUpload1Component;
  @ViewChild('fileUploadSurveyPlan', {static: false}) fileUploadSurveyPlan: FileUpload2Component;
  @ViewChild('fileUploadRoutePlan', {static: false}) fileUploadRoutePlan: FileUpload3Component;
  @ViewChild('fileUploadValuationReport', {static: false}) fileUploadValuationReport: FileUpload4Component;
  @ViewChild('fileUploadGranteeNic', {static: false}) fileUploadGranteeNic: FileUpload5Component;
  @ViewChild('fileUploadOther', {static: false}) fileUploadOther: FileUpload6Component;
  @ViewChild('fileUploadAssessmentNotice', {static: false}) fileUploadAssmentNotice: FileUploadAssessmentComponent;


  @Input() propertyOne: Property;
  selectPaths = [];
  // granterForm: FormGroup;

  valuationRequestId: any;

  previouslyDocumentsUploaded: boolean = false;

  // Document view fields
  noDocs = false;
  dataDone = false;
  previewFileData: any;

  constructor(
    private spinner: NgxSpinnerService,
    public sanitizer: DomSanitizer,
    private fileService: FileService
    // private formBuilder: FormBuilder
  ) { }
  //
  // get f() {
  //   return this.granterForm.controls;
  // }

  ngOnInit() {
    this.valuationRequestId = localStorage.getItem('valuation_request_id');
    this.checkForUploadedDocuments();
  }

  setCopyOfPreviousDeedDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  setDraftDeedDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  setSurveyPlanDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  setRoutePlanDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  setValuationRequestDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  setGranteeDeedDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  setOtherDeedDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  setNoticeDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  //
  setEveryChildComponentUploadingDocuments() {
    this.fileUploadCopyOfPreviousDeed.outPutImagesSet();
    this.fileUploadDraftDeed.outPutImagesSet();
    this.fileUploadSurveyPlan.outPutImagesSet();
    this.fileUploadRoutePlan.outPutImagesSet();
    this.fileUploadValuationReport.outPutImagesSet();
    this.fileUploadGranteeNic.outPutImagesSet();
    this.fileUploadOther.outPutImagesSet();
    this.fileUploadAssmentNotice.outPutImagesSet();
  }

  // Check if there are documents uploaded for the current valuation request ID
  checkForUploadedDocuments() {
    this.fileService.checkForFilesByValuationRequestId(this.valuationRequestId).subscribe(
      data => {
        console.log(data);
        this.previouslyDocumentsUploaded = data.data;
        sessionStorage.setItem('previouslyDocumentsUploaded', JSON.stringify(data.data));
      }
    );
  }

  previewFile(type) {
    console.log("called")
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadFileById(this.valuationRequestId, type).subscribe(result => {
      console.log(result)
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(()=>{
          this.spinner.hide();
        }, 2000);

      }else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  onProgress(progressData: PDFProgressData) {
    if (progressData.loaded == progressData.total) {
      this.spinner.hide();
    }
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }

}
