import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { PropertyTypeService } from '../../../../../../../service/property-type/property-type.service';
import { UpdatePropertyTypeComponent } from '../update-property-type/update-property-type.component';
import { DeletePropertyTypeComponent } from '../delete-property-type/delete-property-type.component';
import { SubSink } from 'subsink';
import { DataService } from 'src/app/service/data.service';
import {IpropertyType} from '../../../../../../../model/interfaces/propertyType';


@Component({
  selector: 'app-view-property-type',
  templateUrl: './view-property-type.component.html',
  styleUrls: ['./view-property-type.component.scss']
})
export class ViewPropertyTypeComponent implements OnInit, OnDestroy {

  propertyTypes: IpropertyType[];
  pageNo: number;
  private sub = new SubSink();
  @ViewChild(UpdatePropertyTypeComponent, { static: false })
  private updatePropertyTypeComponent: UpdatePropertyTypeComponent;
  @ViewChild(DeletePropertyTypeComponent, { static: false })
  private deletePropertyTypeComponent: DeletePropertyTypeComponent;

  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];
  currentPage: any;
  totalItems: any;

  constructor(private propertyTypeService: PropertyTypeService, private dataService: DataService) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.propertyTypeService.getAll().subscribe(
      response => {
        const res = response.data.length;
        this.pageNo = Math.round(res / 10);

      });


    this.sub.add(this.dataService.updateDataTablePropertyType.subscribe(update => this.onAdded(update)));
    this.sub.add(this.propertyTypeService.getPropertyTypes(0, 10)
      .subscribe(
        (res) => {
          // this.propertyTypes = (res as any).data;
          this.propertyTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }
      ));
  }
  pageable(i: number) {

    this.propertyTypeService.getPropertyTypes((i-1),10).subscribe(responce => {
      // this.propertyTypes = (responce as any).data;
      this.propertyTypes = (responce as any).data.content;
      this.currentPage = (responce as any).data.number+1;
      this.totalItems = (responce as any).data.totalElements;
    });
  }
  counter(i: number) {
    return new Array(i);
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  triggerUpdate(id: number, name: string, description: string) {
    this.updatePropertyTypeComponent.setIdAndDescription(id, name, description);
  }

  triggerDelete(id: number) {
    this.deletePropertyTypeComponent.setDeedNatureId(id);
  }

  onUpdated(updatedSuccessfully: boolean) {
    if (updatedSuccessfully) {
      this.updateTheTable();
    }
  }

  onDeleted(deletedSuccessfully: boolean) {
    if (deletedSuccessfully) {
      this.updateTheTable();
    }
  }
  onAdded(addedSuccessfully: boolean) {
    if (addedSuccessfully) {
      this.updateTheTable();
    }
  }
  private updateTheTable() {
    this.sub.add(this.propertyTypeService.getPropertyTypes(0, 10)
      .subscribe(
        (res) => {
          this.propertyTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

  // search by property Type
  searchPropertyType(keyword: string) {
    this.selectedPageNo = 0;
    this.sub.add(this.propertyTypeService.searchPropertyType(keyword, this.selectedPageNo, this.limit)
      .subscribe((res) => {
        this.propertyTypes = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
        // this.propertyTypes = res.data.content;
        // this.paginationButtonCounter(res.data.totalPages);
        // this.numOfPages = res.data.totalPages;
      }));
  }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

}

