import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { getUserDetails } from 'src/app/model/getUserDetails';
import { rimGetapplication } from 'src/app/model/rimGetapplication';
import { RimApplicationService } from 'src/app/service/reimbursement/rim-application.service';
import { Constant } from 'src/app/util/constant';

@Component({
  selector: 'app-rim-view-application',
  templateUrl: './rim-view-application.component.html',
  styleUrls: ['./rim-view-application.component.scss']
})
export class RimViewApplicationComponent implements OnInit {
  userdata: any = [];
  userrolecode: string;
  id: string;
  status: string = this.getStatusType();
  pageHedding: string;
  rimApplication: rimGetapplication [] = [];
  public getUserDetails = new getUserDetails('', '');
  noResults = false;

  taxOfficerLoggedIn: boolean;
  commissionerLoggedIn: boolean;
  deputyCommissionerLoggedIn: boolean;
  accountantLoggedIn: boolean;

  constructor(
    private rimService: RimApplicationService,
    private router: Router,
    private ARoute: ActivatedRoute
  ) {
    this.taxOfficerLoggedIn = false;
    this.deputyCommissionerLoggedIn = false;
    this.commissionerLoggedIn = false;
    this.accountantLoggedIn = false;
  }

  ngOnInit() {
    this.getUsers();
    this.getData();
  }

  // pagination
  totalRecords: number;
  page: number = 1;

  getStatusType(): string {
    let type = this.ARoute.snapshot.paramMap.get('type');
    let status;
    if (type == 'new-application') {
      status = Constant.RIM_REQUEST_PENDING_CODE;
      this.pageHedding = 'REIMBURSMENT NEW APPLICATIONS';
    } else if (type == 'pending-requests') {
      status = Constant.RIM_REQUEST_INPROGRESS_CODE;
      this.pageHedding = 'REIMBURSMENT INPROGRESS APPLICATIONS';
    } else if (type == 'completed') {
      status = Constant.RIM_REQUEST_COMPLETED_CODE;
      this.pageHedding = 'REIMBURSMENT COMPLETED APPLICATIONS';
    } else if (type == 'reject') {
      status = Constant.RIM_REQUEST_REJECTED_CODE;
      this.pageHedding = 'REIMBURSMENT REJECTED APPLICATIONS';
    }

    return status;
  }

  getUsers() {
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.getUserDetails.userId = this.userdata.id;

    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.userrolecode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.userrolecode = Constant.ROLE_COMMISSIONER;
        this.commissionerLoggedIn = true;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.userrolecode = Constant.ROLE_DEPUTY_COMMISSIONER;
        this.deputyCommissionerLoggedIn = true;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.userrolecode = Constant.ROLE_ACCESSOR;
        break;
      } else if (role.code === Constant.ROLE_TAX_OFFICER) {
        this.userrolecode = Constant.ROLE_TAX_OFFICER;
        this.taxOfficerLoggedIn = true;
        break;
      } else if (role.code === Constant.ROLE_ACCOUNTANT) {
        this.userrolecode = Constant.ROLE_ACCOUNTANT;
        this.accountantLoggedIn = true;
        break;
      }
    }
  }

  sendRimApplicationId(id, filenumber) {
    let ID = String(id)
    let fileNumberformat = filenumber;
    sessionStorage.setItem('rimId', ID);
    sessionStorage.setItem('reviewRimApplication', btoa(JSON.stringify(true)));
    sessionStorage.setItem('newReimbursement', JSON.stringify(false));
    localStorage.setItem('rimFileNumberFormat', fileNumberformat + ID);

    if (this.taxOfficerLoggedIn == true) {
      this.router.navigate(['/reimbursment-application/payble-nonpayable']);
    } else if (this.deputyCommissionerLoggedIn == true) {
      this.router.navigate(['/reimbursment-application/payble-nonpayable']);
    } else if (this.commissionerLoggedIn == true) {
      this.router.navigate(['/reimbursment-application/documents']);
    } else if (this.accountantLoggedIn == true) {
      this.router.navigate(['/reimbursment-application/documents']);
    } else {
      this.router.navigate(['/reimbursment-application/new-request']);
    }
  }

  getData() {
    this.rimService.getRimApplications(this.userrolecode, this.userdata.id, this.getStatusType()).subscribe(data => {

      this.rimApplication = data.data;
      this.totalRecords = data.data.length;

    })
  }

  search() {
    if (this.id == '') {
      this.ngOnInit();
      this.noResults = false;
    }else if(Object.keys(this.rimApplication).length === 0){
      this.noResults = true;
    } else {
      this.noResults = false;
      this.rimApplication = this.rimApplication.filter(data => {
        let id = String(data.id);
        return id.match(this.id);
      });
    }
  }
}
