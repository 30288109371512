import { Constant } from "src/app/util/constant"

export const checkCompletedOrPaypend = {

    isProcessCompleted(appStatus:string){
        if(appStatus === Constant.VALIATION_COMPLETED_APPLICATION || appStatus === Constant.VALIATION_PAYMENT_PENDING){
            return true;
        }else{
            return false;
        }
    },

    isFurtherRequirementByUser(userStatus:string){
        if(userStatus === Constant.VALUATION_REQUEST_FURTHER_REQUIREMENT_CODE){
            return true;
        }else{
            return false;
        }
    }
}