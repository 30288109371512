export class ValuationStatus {
  id: string;
  verificationStatus: string;

  constructor(
    id: string,
    verificationStatus: string
  ) {
    this.id = id;
    this.verificationStatus = verificationStatus;
  }
}
