import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SubSink} from 'subsink';
import {ProvinceService} from '../../../../../../../../service/province/province.service';
import {DistrictService} from '../../../../../../../../service/district/district.service';
import {GramaNiladariDivisionService} from '../../../../../../../../service/grama-niladari-division/grama-niladari-division.service';
import {NotifierService} from 'angular-notifier';
import {PropertyUsageService} from '../../../../../../../../service/property-usage/property-usage.service';
import {PropertyUsageSubtypeService} from '../../../../../../../../service/property-usage-subtype/property-usage-subtype.service';
import {IProperty} from '../../../../../../../../model/interfaces/property';
import {Property} from '../../../../../../../../model/property';
import {Province} from '../../../../../../../../model/province';

@Component({
  selector: 'app-property-assessment',
  templateUrl: './property-assessment.component.html',
  styleUrls: ['./property-assessment.component.scss']
})
export class PropertyAssessmentComponent implements OnInit {
  @ViewChild('provinces1', {static: true})
  provinces1: ElementRef<HTMLSelectElement>;
  @ViewChild('district1', {static: true})
  district1: ElementRef<HTMLSelectElement>;

  @Input() property: IProperty;
  @Input() propertyOne: Property;
  assessmentForm: FormGroup;
  provinces = [];
  provincesorderd: Province[];
  districts = [];
  gramaniladhariDivisions = [];
  propertyUsages = [];
  propertyUsageSubTypes = [];
  // districts = [];
  provinceName: string;
  districtCode: number;
  provinceCode: number;
  selectedProvince: string;
  selectedDistrict: string;
  @ViewChild('provinceEl', {static: true})
  provinceEl: ElementRef<HTMLSelectElement>;
  @ViewChild('districtEl', {static: true})
  districtEl: ElementRef<HTMLSelectElement>;
  @ViewChild('propertyUsageEl', {static: true})
  propertyUsageEl: ElementRef<HTMLSelectElement>;
  @ViewChild('propertyUsageSubTypeEl', {static: true})
  propertyUsageSubTypeEl: ElementRef<HTMLSelectElement>;
  selectedPropertyUsage: string;
  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  // propertyUsageOther: string;

  // Validation
  onlyNumbersPattern = '^[0-9]*$';
  onlyNumbersAndDecimalPointPattern = '^[0-9_.]*$';
  addressPattern = /^[ a-zA-Z0-9./,-/']*$/;
  cityPattern = '^[ a-zA-Z0-9-.]*$';
  nearestTownPattern = '^[ a-zA-Z-.]*$';
  onlyCharactersNumbersPattern = '^[_A-z0-9 ]*((-|\\s)*[_A-z0-9 ])*$';

  private sub = new SubSink();

  constructor(private provinceService: ProvinceService,
              private districtService: DistrictService,
              private gramaNiladhariDivisionService: GramaNiladariDivisionService,
              private propertyUsageService: PropertyUsageService,
              private propertyUsageSubtypeService: PropertyUsageSubtypeService,
              private formBuilder: FormBuilder,
              private notifier: NotifierService) {
  }

  get f() {
    return this.assessmentForm.controls;
  }

  get district() {
    return this.assessmentForm.get('district') as FormControl;
  }

  get gramaNiladhariDivision() {
    return this.assessmentForm.get('gramaNiladhariDivision') as FormControl;
  }


  ngOnInit() {
    console.log("tassessmentForm")
    this.selectedPageNo = 0;
    this.limit = 10;
    // this.propertyUsageOther = 'null';

    this.assessmentForm = this.formBuilder.group({
      addressLine1: [this.propertyOne.addressLine1, [Validators.required, Validators.pattern(this.addressPattern)]],
      addressLine2: [this.propertyOne.addressLine2, [Validators.pattern(this.addressPattern)]],
      city: [this.propertyOne.city, [Validators.required, Validators.pattern(this.cityPattern)]],
      postalCode: [this.propertyOne.postalCode, [Validators.pattern(this.onlyNumbersPattern)]],
      province: [this.propertyOne.province, [Validators.required]],
      district: [this.propertyOne.district, [Validators.required]],
      gramaNiladhariDivision: [this.propertyOne.gramaNiladariDivision, [Validators.required]],
      nearestTown: [this.propertyOne.nearestTown, [Validators.pattern(this.nearestTownPattern)]],
      townNo: [this.propertyOne.townNo, [Validators.pattern(this.onlyNumbersPattern)]],
      distance: [this.propertyOne.distanceFromTheNearestTown, [Validators.pattern(this.onlyNumbersAndDecimalPointPattern)]],
      // propertyUsage: [this.propertyOne.propertyUsage, [Validators.required]],
      propertyUsage: [this.propertyOne.propertyUsage],
      otherPropertyUsage: [this.propertyOne.otherPropertyUsage, [Validators.required]],
      // propertyUsageSubType: [this.propertyOne.propertyUsageSubType, [Validators.required]],
      propertyUsageSubType: [this.propertyOne.propertyUsageSubType],
      detailsOfCultivation: [this.propertyOne.detailsOfTheCropCultivation]
    });
    if (this.district.value != null) {
      this.assessmentForm.get('district').enable();
      this.assessmentForm.patchValue({
        district: this.propertyOne.district
      });
    } else {
      this.assessmentForm.get('district').disable();
      this.assessmentForm.value.district = null;
    }
    if (this.gramaNiladhariDivision.value != null) {
      this.assessmentForm.get('gramaNiladhariDivision').enable();
      this.assessmentForm.patchValue({
        gramaNiladhariDivision: this.propertyOne.gramaNiladariDivision
      });
    } else {
      this.assessmentForm.get('gramaNiladhariDivision').disable();
      this.assessmentForm.value.gramaNiladhariDivision = null;
    }

    this.assessmentForm.value.province = null;
    this.assessmentForm.value.propertyUsage = null;
    this.assessmentForm.value.propertyUsageSubType = null;

    this.sub.add(this.provinceService.getAll()
      .subscribe(
        (res) => {
          this.provinces = (res as any).data;
        }
      ));

    // get All provinces odered
    this.sub.add(this.provinceService.getProvincesOrderd().subscribe(
      (res) => {
        this.provincesorderd = (res as any).data;
      }
    ));

    // this.sub.add(this.districtService.getAll()
    //   .subscribe(
    //     (res) => {
    //       this.districts = (res as any).data;
    //     }
    //   ));

    // this.sub.add(this.gramaNiladhariDivisionService.getAll()
    //   .subscribe(
    //     (res) => {
    //       this.gramaniladhariDivisions = (res as any).data;
    //     }
    //   ));

    this.sub.add(this.propertyUsageService.getAll()
      .subscribe(
        (res) => {
          this.propertyUsages = (res as any).data;
        }
      ));


    if (this.propertyOne.province) {

      this.sub.add(this.propertyUsageService.getDistrictByProvince(Number(this.propertyOne.province))
        .subscribe(
          (res) => {
            this.districts = (res as any).data;
          }
        ));
    }


    if (this.propertyOne.district) {
      this.sub.add(this.propertyUsageService.getGramaNiladaryByDistrict(Number(this.propertyOne.district))
        .subscribe(
          (res) => {
            console.log(res);
            this.gramaniladhariDivisions = (res as any).data;
          }
        ));
    }
    
    if (this.propertyOne.propertyUsage) {

      this.sub.add(this.propertyUsageService.getPropertyUsageSubTypeByPropertyUsage(Number(this.propertyOne.propertyUsage), 0, 200)
        .subscribe(
          (res) => {
            this.propertyUsageSubTypes = (res as any).data.content;
          }
        ));
    }
    this.onChange(this.propertyOne.propertyUsage);
  }

  saveAssessmentDetails(): boolean {
    this.assessmentForm.markAllAsTouched();
    if (this.assessmentForm.valid) {
      this.setPropertyAssessmentDetails();
      return true;
    } else {
      return false;
    }
  }

  setPropertyAssessmentDetails(): void {
    this.propertyOne.addressLine1 = this.assessmentForm.get('addressLine1').value;
    this.propertyOne.addressLine2 = this.assessmentForm.get('addressLine2').value;
    this.propertyOne.city = this.assessmentForm.get('city').value;
    this.propertyOne.postalCode = this.assessmentForm.get('postalCode').value;
    this.propertyOne.province = this.assessmentForm.get('province').value;
    this.propertyOne.district = this.assessmentForm.get('district').value;
    this.propertyOne.gramaNiladariDivision = this.assessmentForm.get('gramaNiladhariDivision').value;
    console.log('Prop one and district >>>>>>>>>>>' + this.propertyOne.district + '>>>>>>>>>>>>>>' + this.assessmentForm.get('district').value);
    this.propertyOne.nearestTown = this.assessmentForm.get('nearestTown').value;
    this.propertyOne.townNo = Number(this.assessmentForm.get('townNo').value);
    this.propertyOne.distanceFromTheNearestTown = this.assessmentForm.get('distance').value;
    this.propertyOne.propertyUsage = this.assessmentForm.get('propertyUsage').value;
    this.propertyOne.otherPropertyUsage = this.assessmentForm.get('otherPropertyUsage').value;
    this.propertyOne.propertyUsageSubType = this.assessmentForm.get('propertyUsageSubType').value;
    console.log('property one and sub type >>>>>>>>>>>>>>' + this.propertyOne.propertyUsageSubType + '>>>>>>>>>>>>>>>>>>' + this.assessmentForm.get('propertyUsageSubType').value);
    this.propertyOne.detailsOfTheCropCultivation = this.assessmentForm.get('detailsOfCultivation').value;

    localStorage.setItem('assessment', JSON.stringify(this.propertyOne));
  }

  clearForm() {
    this.assessmentForm.reset();
  }

  onChange(data) {
    if (data === '107') {
      this.assessmentForm.get('detailsOfCultivation').enable();
    } else {
      this.assessmentForm.patchValue({detailsOfCultivation: null});
      this.assessmentForm.get('detailsOfCultivation').disable();
    }

    if (data === '110') {
      this.assessmentForm.get('otherPropertyUsage').enable();
      this.assessmentForm.get('propertyUsageSubType').disable();
      this.assessmentForm.get('propertyUsageSubType').reset();
    } else {
      this.assessmentForm.patchValue({otherPropertyUsage: null});
      this.assessmentForm.get('otherPropertyUsage').disable();
      this.assessmentForm.get('propertyUsageSubType').enable();
    }
  }

  // onChangeSubType(data) {
  //   if (data === '107') {
  //     this.assessmentForm.get('propertyUsageSubTypeOther').enable();
  //   }
  // }

  getPropertyUsageByPropertyUsageSubType(value) {
    if (value !== '110') {
      this.assessmentForm.get('propertyUsageSubType').enable();
      this.assessmentForm.get('propertyUsageSubType').reset();
      const propertyUsageElement = this.propertyUsageEl.nativeElement;
      this.selectedPropertyUsage = propertyUsageElement.options[propertyUsageElement.selectedIndex].innerText;
      this.sub.add(this.propertyUsageService.getPropertyUsageSubTypeByPropertyUsage(Number(value), 0, 200)
        .subscribe(
          (res) => {
            this.propertyUsageSubTypes = (res as any).data.content;
          }
        ));
    }
  }

  getDistrictByProvince(value) {
    this.assessmentForm.get('gramaNiladhariDivision').reset();
    this.assessmentForm.get('district').reset();
    if (value !== null) {
      this.assessmentForm.get('district').enable();
      const provinceElement = this.provinceEl.nativeElement;
      this.selectedProvince = provinceElement.options[provinceElement.selectedIndex].innerText;
      this.sub.add(this.propertyUsageService.getDistrictByProvince(Number(value))
        .subscribe(
          (res) => {
            this.districts = (res as any).data;
          }
        ));
    }
  }

  getGramaNiladariByDistrict(value) {
    if (value !== null) {
      this.assessmentForm.get('gramaNiladhariDivision').enable();
      const districtElement = this.districtEl.nativeElement;
      this.selectedDistrict = districtElement.options[districtElement.selectedIndex].innerText;
      this.sub.add(this.propertyUsageService.getGramaNiladaryByDistrict(Number(value))
        .subscribe(
          (res) => {
            console.log(res);
            this.gramaniladhariDivisions = (res as any).data;
          }
        ));
    }
  }
}

// selectProvince(id: number) {
//   const floorSubTypeElement = this.provinces1.nativeElement;
//   this.gramaniladhariDivisions = [];
//   const selecteElemente = floorSubTypeElement.options[floorSubTypeElement.selectedIndex].innerText;
//   this.provinceService.getProvinceById(id).subscribe(
//     (res) => {
//       this.provinceCode = res.data.id;
//       console.log(this.provinceCode);
//       this.sub.add(this.districtService.searchDistrictDetails(this.provinceCode)
//         .subscribe((res) => {
//           this.districts = (res as any).data;
//         })
//       );
//     }
//   );
//   this.selectedPageNo = 0;
// }
//
// selectDistrict(id: number) {
//
//   const district = this.district1.nativeElement;
//   const selecteElements = district.options[district.selectedIndex].innerText;
//   this.selectedPageNo = 0;
//   this.gramaNiladhariDivisionService.searchGramaNiladhariDetails(id).subscribe(
//         (res) => {
//           this.gramaniladhariDivisions = (res as any).data;
//           console.log(this.gramaniladhariDivisions);
//         }
//       );
// }

// getDistrictByProvinceID(value) {
//   console.log(value);
//   if (null !== value) {
//     const provinceElement = this.provinceEl.nativeElement;
//     this.selectedProvince = provinceElement.options[provinceElement.selectedIndex].innerText;
//     this.sub.add(this.provinceService.getDistrictByProvinceId(Number(value), 0, 200)
//       .subscribe(
//         (res) => {
//           this.districts = (res as any).data.content;
//         }
//       ));
//   }
// }
// }
