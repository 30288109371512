import { Injectable } from '@angular/core';
// import { HttpClient } fro 'selenium-webdriver/http';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import { environment } from 'src/environments/environment';

import { DeedNature } from '../../model/deed-nature';
import { tap, catchError } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import {IDeedNaturePage} from '../../model/interfaces/deed-nature-page';
@Injectable({
  providedIn: 'root'
})
export class DeedNatureService {

  private deedNatureUrl = '/deed-nature';

  httpOption = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private notifier: NotifierService ) { }

  getDeedNatures(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.deedNatureUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  getAll(): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.deedNatureUrl + '/all');

  }

  /*Search Deed Nature from the server*/
  searchDeedNature(keyword: string , offset: number, limit: number): Observable<IDeedNaturePage> {
    const params = {
      params: {
        offset: offset.toString(),
        limit: limit.toString(),
        keyword
      }
    };
    return this.http.get<any[]>(`${environment.api_url}${this.deedNatureUrl}/search`, params)
      .pipe(tap(_ => this.log('Search Deed Nature')),
        catchError(this.handleError<any>('get deed nature'))
      );
  }

  createDeedNature(deedNature: DeedNature): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.deedNatureUrl}`,
      JSON.stringify(deedNature), this.httpOption)
      .pipe(tap(_ => this.log(`update deed  nature create=${deedNature}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('deed nature create'))
      );
  }

  updateDeedNature(id: number, deedNature: DeedNature): Observable<any> {
    return this.http.put(`${environment.api_url}${this.deedNatureUrl}/${id}`, deedNature)
      .pipe(tap(_ => this.log(`update deedNature  id=${deedNature.id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
      catchError(this.handleError<any>('deed Nature update'))
    );
  }

  /** DELETE: delete the company category on the server */
  deleteDeedNature(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.deedNatureUrl}/${id}`)
      .pipe(tap(_ => this.log(`update deed Nature  id=${id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
      catchError(this.handleError<any>('deedNature'))
    );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error}`);
      this.notifier.notify('error', `${operation} failed`);

      return of(result as T);
    };
  }
  private log(message: string) {

  }

}
