import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ValuationStatus } from "src/app/model/valuation-status";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { CALCULATION_END_POINTS } from "./calculation-api-end-points";

@Injectable({
  providedIn: "root"
})
export class CalculationService {
  private requestUrl = "/calculation";
  constructor(private http: HttpClient) {}

  getDecisionOfCommitteeByCalculationId(calculationId: number): Observable<any> {
    return this.http.get(environment.api_url + "/valuation-request/decisionOfCommittee/" + calculationId);
  }

  loadDocumentsByCalculationId(calculationId: number, documentTitle) {
    return this.http.get<any>(environment.api_url + "/valuation-request/" + calculationId + "/calculationFile/" + documentTitle);
  }

  getCalculationRecords(referenceNo): Observable<any> {
    let url =
      environment.api_url +
      CALCULATION_END_POINTS.fetchCalculationRecordsByRefNo;
    url = url.replace("{refNo}", referenceNo);
    return this.http.get<any>(url);
  }

  getRedundOpinionCalculationRecords(refundRequestId): Observable<any> {
    let url = environment.api_url + "/calculation/getRefundOpinionCalculationRecords/{refundRequestId}";
    url = url.replace("{refundRequestId}", refundRequestId);
    return this.http.get<any>(url);
  }

  saveCalculationBuildingData(data): Observable<any> {
    let url =
      environment.api_url + CALCULATION_END_POINTS.saveCalculationBuildingData;
    return this.http.post<any>(url, data);
  }

  saveRefundOpinionCalculationData(data): Observable<any> {
    let url =
      environment.api_url + "/calculation/saveRefundOpinionCalculation";
    return this.http.post<any>(url, data);
  }

  saveCalculationCondominiumData(data): Observable<any> {
    let url =
      environment.api_url + CALCULATION_END_POINTS.saveCalculationBuildingData;
    return this.http.post<any>(url, data);
  }

  getValuationRequestDetail(referenceNo): Observable<any> {
    let url =
      environment.api_url + CALCULATION_END_POINTS.fetchValuationRequest;
    url = url.replace("{id}", referenceNo);
    return this.http.get<any>(url);
  }

  // getPreviousMarketValueDetails(referenceNo) {
  //   let url =
  //     environment.api_url +
  //     CALCULATION_END_POINTS._getPreviousMarketValueDetails;
  //   url = url.replace("{refNo}", referenceNo);
  //   return this.http.get<any>(url);
  // }

  getPreviousMarket(referenceNo:String,type:String){
    return this.http.get<any>(environment.api_url+this.requestUrl+'/get-calculation-values-in-similer-areas/'+type+'/'+referenceNo)
  }
}
