import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {NotifierService} from 'angular-notifier';
import {Appeal} from '../../model/appeal';
import {IValuationRequestPage} from '../../model/interfaces/valuation-request-page';
import { ValuationStatus } from 'src/app/model/valuation-status';

@Injectable({
  providedIn: 'root'
})
export class AppealService {

  private appealInsertedInfoUrl = '/appeal/request';
  private appealGranteeUrl = '/appeal/grantee';
  private appealNotaryUrl = '/appeal/notary';
  // private previousAppealUrl = '/appeal/previouslist';
  private previousAppealUrl = '/appeal/previousAppealList';
  private appealDocumentUrl = '/appeal/documents';
  private appealInsertUrl = '/appeal/insert';
  private appealSearchUrl = '/appeal/previous/search';
  private appealCount = '/appeal/get-appeal-count';
  private appealStatusUrl = '/appeal/updatestatus';

  constructor(
    private http: HttpClient,
    private notifier: NotifierService
  ) {
  }

  appealInsertedInfo(id: any): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.appealInsertedInfoUrl}/` + id);
  }

  appealGranteeInfo(id: any): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.appealGranteeUrl}/` + id);
  }

  appealNotaryInfo(id: any): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.appealNotaryUrl}/` + id);
  }

  previousAppealList(id: any): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.previousAppealUrl}/` + id);
  }

  insertAppeal(appeal: Appeal): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.appealInsertUrl}`, appeal);
  }

  getAppealCount(id:any):Observable<any>{
    return this.http.get<any>(`${environment.api_url}${this.appealCount}/`+ id);
  }

  searchAppeal(id: string, offset: number, limit: number): Observable<IValuationRequestPage> {
    return this.http.get<IValuationRequestPage>(
      `${environment.api_url}${this.appealSearchUrl}/?valuation_request_id=${id}&offset=${offset}&limit=${limit}`)
      .pipe(tap(_ => this.log(`valuation-request find by id = ${id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('appeal-request find by id')));
  }

  uploadAppealDocuments(selectPaths: any[], valuationId:any): Observable<any> {
    const formData = new FormData();
    formData.append('valId', valuationId);
    formData.append('appealRequestDoc', selectPaths[0], selectPaths[0].name);
    formData.append('appealOtherDoc', selectPaths[1], selectPaths[1].name);

    return this.http.post<any>(`${environment.api_url}${this.appealDocumentUrl}`, formData)
      .pipe(tap(_ => this.log(`upload appeal Documents=${selectPaths}`)),
        catchError(err => {
          return throwError(err);
        }),
        catchError(this.handleError<any>('upload appeal Documents'))
      );
  }

  appealUpdateVerificationStatus(ValuationStatus: ValuationStatus): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.appealStatusUrl}`, ValuationStatus);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error}`);
      // notify user
      this.notifier.notify('error', `${operation} failed`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    // Todo: create a service and send operational audit message
  }
}
