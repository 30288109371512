export class CheckBoxStatus {
  public valuation_request_id: string;
  public granter_status: string;
  public grantee_status: string;
  public notary_status: string;
  public property_status: string;
  public building_status: string;

  constructor(
    valuation_request_id: string,
    granter_status: string,
    grantee_status: string,
    notary_status: string,
    property_status: string,
    building_status: string
  ) {
    this.valuation_request_id = valuation_request_id;
    this.granter_status = granter_status;
    this.grantee_status = grantee_status;
    this.notary_status = notary_status;
    this.property_status = property_status;
    this.building_status = building_status;
  }
}
