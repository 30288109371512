import {Component, OnInit, ViewChild} from '@angular/core';
import {ViewPropertyFacilityComponent} from '../property/property-facility/view-property-facility/view-property-facility.component';
import {ViewProvinceComponent} from './view-province/view-province.component';

@Component({
  selector: 'app-province',
  templateUrl: './province.component.html',
  styleUrls: ['./province.component.scss']
})
export class ProvinceComponent implements OnInit {

  @ViewChild(ViewProvinceComponent, {static: false})
  private viewProvinceComponent: ViewProvinceComponent;
  keyword: string;

  constructor() { }

  ngOnInit() {
  }

  triggerSearch(event: any) {
    if (this.keyword === '') {
      this.viewProvinceComponent.onUpdated(true);
    } else {
      this.viewProvinceComponent.searchProvince(this.keyword);
    }
  }

}
