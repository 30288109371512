import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { OfficeService } from 'src/app/service/office/office.service';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/service/data.service';
import { Office } from 'src/app/model/office';

@Component({
  selector: 'app-search-office',
  templateUrl: './search-office.component.html',
  styleUrls: ['./search-office.component.scss']
})
export class SearchOfficeComponent implements OnInit, OnDestroy {


  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  addOfficeForm: FormGroup;
  private sub = new SubSink();

  constructor(private formBuilder: FormBuilder,
              private officeService: OfficeService,
              private notifier: NotifierService,
              private dataService: DataService) { }

  ngOnInit() {
    this.addOfficeForm = this.formBuilder.group({
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      office: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]]
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  saveOffice() {
    if (this.addOfficeForm.valid) {

      const office = new Office();

      office.office = this.getDescription();
      office.code = this.getCode();

      this.sub.add(this.officeService.createOffice(office)
        .subscribe(response => {
          this.notifier.notify( 'success' , 'Successfully Added Office');
          this.dataService.updateTableOffice(true);
        }));

      this.reset();
    }else {
      this.addOfficeForm.markAllAsTouched();
    }
  }
  get f() { return this.addOfficeForm.controls; }

  getCode() {
    return this.addOfficeForm.get('code').value;
  }

  getDescription() {
    return this.addOfficeForm.get('office').value;
  }

  reset() {
    this.addOfficeForm.reset();
  }
}
