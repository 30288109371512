import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { InspectionLandTypeService } from 'src/app/service/inspection-master-data-service/inspection-land-type/inspection-land-type.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inspection-land-type',
  templateUrl: './inspection-land-type.component.html',
  styleUrls: ['./inspection-land-type.component.scss']
})
export class InspectionLandTypeComponent implements OnInit,OnDestroy {


  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  createLandTypeForm: FormGroup;
  updateLandTypeForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  propertyTypeList: any =[];
  activationButton: boolean;
  public createLandTypeModel = new InspectionCommonDataModel(null,'','','','');
  public updateLandTypeModel = new InspectionCommonDataModel(null,'','','','');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;
  LandTypes: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionLandTypeService: InspectionLandTypeService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createLandTypeForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });
    this.updateLandTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });

    // this.loadPropertyType();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }



  //create land type - start

  get f() { return this.createLandTypeForm.controls; }

  setLandTypeModelToSave(){
    this.createLandTypeModel.name = this.createLandTypeForm.get('name').value;
    this.createLandTypeModel.description = this.createLandTypeForm.get('description').value;
    this.createLandTypeModel.code = this.createLandTypeForm.get('code').value;
    this.createLandTypeModel.dataStatus = "ACTIVE";
  }

    //save land type model
  saveLandType(){
    if(this.createLandTypeForm.valid){
    this.setLandTypeModelToSave();
    this.sub.add(
      this.inspectionLandTypeService.createLandType(this.createLandTypeModel).subscribe(data=>{
        this.notifier.notify('success', (data as any).message);
        this.updateTheTable();
        this.reset();
      })
      );
    }else{
      this.createLandTypeForm.markAllAsTouched();
    }
  }

  reset(){
    this.createLandTypeForm.reset()
  }
  //create land type - end

  //update land type - start

    //get update controllers
  get f1() { return this.updateLandTypeForm.controls; }

    //set data To land type model
  setLandType(landType:any){
    this.updateLandTypeForm.get('id').setValue(landType.id);
    this.updateLandTypeForm.get('name').setValue(landType.name);
    this.updateLandTypeForm.get('description').setValue(landType.description);
    this.updateLandTypeForm.get('code').setValue(landType.code);

    if(landType.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateLandTypeModel.id = this.updateLandTypeForm.get('id').value;
    this.updateLandTypeModel.name = this.updateLandTypeForm.get('name').value;
    this.updateLandTypeModel.description = this.updateLandTypeForm.get('description').value;
    this.updateLandTypeModel.code = this.updateLandTypeForm.get('code').value;

    if(this.activationButton == true){
      this.updateLandTypeModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateLandTypeModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateLandType(){
    this.setDataToModel();
    this.sub.add(this.inspectionLandTypeService.updateLandType(this.updateLandTypeModel.id, this.updateLandTypeModel).subscribe(data=>{
      this.notifier.notify('success', (data as any).message);
      this.updateTheTable();
    }))
  }

  //update land type - end

//search and view table start
  pageable(i: number) {
    this.inspectionLandTypeService.getLandType((i-1),10).subscribe(res=>{
      this.LandTypes = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionLandTypeService.getLandType(0, 10)
      .subscribe(
        (res) => {
          this.LandTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search land type Type
    searchLandType(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionLandTypeService.searchLandType(this.keyword,0,10).subscribe(res=>{
          this.LandTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionLandTypeService.deleteLandType(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end
}
