import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubSink} from 'subsink';
import {ValuationRequest} from '../../../../../../../model/valuation-request';
import {ValuationRequestService} from '../../../../../../../service/valuation-request/valuation-request.service';
import {NotifierService} from 'angular-notifier';
import {DocumentUploadService} from '../../../../../../../service/document-upload/document-upload.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ValuationUpdate} from '../../../../../../../model/valuationUpdate';
import {FileService} from '../../../../../../../service/file/file.service';
import {DomSanitizer} from '@angular/platform-browser';
import {FileUploadOpinionDocumentComponent} from '../../file-upload/file-upload-opinion-document/file-upload-opinion-document.component';
import Swal from 'sweetalert2';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-view-valuation-internal',
  templateUrl: './view-valuation-internal.component.html',
  styleUrls: ['./view-valuation-internal.component.scss']
})
export class ViewValuationInternalComponent implements OnInit {

  @ViewChild('fileUploadOpinionDocument', {static: false})
  fileUploadOpinionDocument: FileUploadOpinionDocumentComponent;

  selectedPaths = [] = [];
  selectPaths = [];
  filesList: File[] = [];
  errorList = [];
  otherSelectedPaths = [];
  documentsIdList = [];
  selectMenu = 1;

  systemDate: any;
  valuation: string;
  stampduty: string;
  status: string;
  amount: string;
  remark: string;
  valuationStatus: any;
  valuationRequestId: any;
  valuationRequest: ValuationRequest;
  previewFileData: any;
  dataDone = false;
  valuationForm: FormGroup;
  rejectForm: FormGroup;
  selectFileSm = 0;
  // Validations
  amountPattern = '^[0-9.]*$';
  noDocs = false;
  private sub = new SubSink();

  // onlyCharactersNumbersPattern = /^[ a-zA-Z0-9./,-/'*&@]*$/;

  constructor(private valuationRequestService: ValuationRequestService,
              private valuationService: ValuationRequestService,
              private notifier: NotifierService,
              private documentUploadService: DocumentUploadService,
              private route: ActivatedRoute,
              private router: Router,
              private formBuilder: FormBuilder,
              private fileService: FileService,
              public sanitizer: DomSanitizer,
              private spinner: NgxSpinnerService
  ) {

  }

  get f() {
    return this.valuationForm.controls;
  }

  get f1() {
    return this.rejectForm.controls;
  }

  ngOnInit() {

    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    this.systemDate = mm + '/' + dd + '/' + yyyy;

    this.valuationRequestId = +this.route.snapshot.paramMap.get('id');

    this.valuationForm = this.formBuilder.group({
      date: ['', [Validators.required]],
      valuationAmount: ['', [Validators.required, Validators.pattern(this.amountPattern)]],
      stampDutyAmount: ['', [Validators.required, Validators.pattern(this.amountPattern)]]
    });

    this.rejectForm = this.formBuilder.group({
      remark: ['', [Validators.required]]
    });

    this.showValuationStatus(this.valuationRequestId);

  }

  // giveValuationAmount() {
  //   this.valuationRequest.valuation = this.valuationForm.get('valuationAmount').value;
  //   this.valuationRequest.stampDuty = this.valuationForm.get('stampDutyAmount').value;
  //   this.valuationRequest.valuationRequestStatus = 'Completed';
  //   this.sub.add(this.valuationRequestService.createValuationRequest(this.valuationRequest)
  //     .subscribe(res => {
  //       const data = res as any;
  //     }, (error => {
  //     })));
  // }

  rejectValuation(valuationRequestId: number) {
    if (this.checkValidityForReject()) {
      const valuationUpdate = new ValuationUpdate();
      valuationUpdate.valuation = 0;
      valuationUpdate.stampDuty = 0;
      valuationUpdate.status = 'REJECTED';
      valuationUpdate.remark = this.rejectForm.get('remark').value;

      this.sub.add(this.valuationService.setValuatationForValuationRequest(valuationRequestId, valuationUpdate)
        .subscribe(res => {
          this.notifier.notify('success', 'Valuation request rejected');
          const data = res as any;
        }, (error => {
        })));
      this.router.navigate(['/view-assessment-internal/all-request']);
    } else {
      this.notifier.notify('error', 'Please give remark before reject');
    }
  }

  giveValuation(valuationRequestId: number) {
    if (this.checkValidity()) {
      if (this.checkDocument()) {
        const valuationUpdate = new ValuationUpdate();
        valuationUpdate.valuation = this.valuationForm.get('valuationAmount').value;
        valuationUpdate.stampDuty = this.valuationForm.get('stampDutyAmount').value;
        valuationUpdate.status = 'COMPLETED';

        // this.selectedPaths.push(this.otherSelectedPaths[0]);

        console.log('docs', this.filesList);

        this.documentUploadService.uploadOpinionDocuments(this.filesList).subscribe(
          response => {
            this.documentsIdList = (response as any).data;

            console.log('added document : >>>>>>>>>>>>>>>> ' + this.documentsIdList.length);
            ///////////////////
            valuationUpdate.documentsIdList = this.documentsIdList;

            this.sub.add(this.valuationService.setValuatationForValuationRequest(valuationRequestId, valuationUpdate)
              .subscribe(res => {
                this.notifier.notify('success', 'Valuation and Stamp duty given successfully');
                const data = res as any;
                console.log(data);
              }, (error => {
              })));
            this.router.navigate(['/view-assessment-internal/all-request']);
          });
      } else {
        this.notifier.notify('error', 'Opinion Document / Documents must be uploaded');
      }
    } else {
      this.notifier.notify('error', 'Valuation given failed');
    }
  }

  checkDocument(): boolean {
    if (this.fileUploadOpinionDocument.filesListOpinionDocuments.length === 0) {
      return false;
    }else {
      this.setEveryChildComponentUploadingDocuments();
      return true;
    }

  }

  showValuationStatus(valuationRequestId: number) {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(valuationRequestId)
      .subscribe((res) => {
        this.valuationRequest = res.data;
        console.log('Valuation Request', this.valuationRequest);
        this.valuationStatus = this.valuationRequest.valuationRequestStatus;

        if (this.valuationStatus === 'REJECTETD') {
          this.remark = this.valuationRequest.remark;
          // this.rejectedForm.get('remark').setValue(this.remark);
        }
        console.log(this.valuationStatus);
      }));

  }

  previewFile(type) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadFileById(this.valuationRequestId, type).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(() => {
          this.spinner.hide();
        }, 2000);

      } else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }

  setOpinionDocumentsDocArray(images: File[]) {
    this.filesList = images;
  }

  setEveryChildComponentUploadingDocuments() {
    this.fileUploadOpinionDocument.outPutImagesSet();
    // this.setGranteeDocsStorage();
  }

  // checkDocuments() {
  //   this.errorList = [];
  //   // hold every child uploading file data
  //   if (this.fileUploadOpinionDocument.filesListOpinionDocuments.length === 0) {
  //     this.errorList.push('Opinion Document');
  //   }
  //
  //   if (this.errorList.length === 0) {
  //     console.log('Documents Error : ............ ' + this.errorList.length);
  //     this.setEveryChildComponentUploadingDocuments();
  //     this.otherSelectedPaths = this.selectPaths;
  //     return true;
  //   } else {
  //     const re = /,/gi;
  //     console.log(this.errorList.toString());
  //     const errormsg = this.errorList.toString().replace(re, '<br/>');
  //     console.log(errormsg);
  //     return false;
  //   }
  // }

  backToList() {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(Number(this.valuationRequestId))
      .subscribe((res) => {
        this.valuationRequest = res.data;

        console.log(this.valuationRequest.valuationRequestStatus + '................');

        if (this.valuationRequest.valuationRequestStatus === 'PENDING') {
          this.router.navigate(['/view-assessment-internal/all-request']);
        }
        if (this.valuationRequest.valuationRequestStatus === 'COMPLETED') {
          this.router.navigate(['/view-assessment-internal/all-request']);
        }
        if (this.valuationRequest.valuationRequestStatus === 'REJECTED') {
          this.router.navigate(['/view-assessment-internal/all-request']);
        }
      }));
  }

  checkValidity() {
    this.valuationForm.get('valuationAmount').markAsTouched();
    this.valuationForm.get('stampDutyAmount').markAsTouched();

    if (this.valuationForm.get('valuationAmount').valid &&
      this.valuationForm.get('stampDutyAmount').valid) {
      return true;
    } else {
      return false;
    }
  }

  checkValidityForReject() {
    this.rejectForm.get('remark').markAsTouched();

    if (this.rejectForm.get('remark').valid) {
      return true;
    } else {
      return false;
    }
  }
}
