import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { PropertyUsageService } from 'src/app/service/property-usage/property-usage.service';
import { DataService } from 'src/app/service/data.service';
import { NotifierService } from 'angular-notifier';
import { PropertyUsage } from 'src/app/model/propertyUsage';

@Component({
  selector: 'app-search-property-usage',
  templateUrl: './search-property-usage.component.html',
  styleUrls: ['./search-property-usage.component.scss']
})
export class SearchPropertyUsageComponent implements OnInit, OnDestroy {



  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  addPropertyUsageForm: FormGroup;
  private sub = new SubSink();

  constructor(private propertyUsageService: PropertyUsageService,
              private formBuilder: FormBuilder,
              private notifier: NotifierService,
              private dataService: DataService) { }

  ngOnInit() {
    this.addPropertyUsageForm = this.formBuilder.group({
      description: ['', [Validators.pattern(this.NoWhitespaceRegExp)]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]]
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  get f() { return this.addPropertyUsageForm.controls; }
  savePropertyUsage() {
    if (this.addPropertyUsageForm.valid ) {
      const propertyUsage = new PropertyUsage();
      propertyUsage.code = this.getCode();
      propertyUsage.description = this.getDescription();

      propertyUsage.name = this.getName();
      this.sub.add(this.propertyUsageService.createPropertyUsage(propertyUsage)
        .subscribe(response => {
          this.notifier.notify( 'success', (response as any).message);
          this.dataService.updateTablePropertyUsage(true);
        }));

      this.reset();
    }else{
      this.f.name.markAsTouched();
      this.f.code.markAsTouched();
      
    }
  }

  getCode() {
    return this.addPropertyUsageForm.get('code').value;
  }
  getName() {
    return this.addPropertyUsageForm.get('name').value;
  }
  getDescription() {
    return this.addPropertyUsageForm.get('description').value;
  }

  reset() {
    this.addPropertyUsageForm.reset();
  }
}

