import {Injectable} from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import { environment } from 'src/environments/environment';
import { DocumentType } from 'src/app/model/document-type';
import { tap, catchError } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import {IDocumentTypePage} from '../../model/interfaces/document-type-page';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeService {

  private documentUrl = '/document-types';


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  getDocumentTypes(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.documentUrl + '?offset=' + offset + '&limit=' + limit + '');
  }
  getAll(): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.documentUrl + '/all');

  }

  /* Search document type from server*/
  searchDocumentType(keyword: string, offset: number, limit: number): Observable<IDocumentTypePage> {
    const params = {
      params: {
        offset: offset.toString(),
        limit: limit.toString(),
        keyword
      }
    };
    return this.http.get<any[]>(`${environment.api_url}${this.documentUrl}/search`, params)
      .pipe(tap(_ => this.log('search document type')),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('get document type'))
      );
  }

  createDocumentTYpe(documentTypes: DocumentType): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.documentUrl}`,
      JSON.stringify(documentTypes), this.httpOptions)
      .pipe(tap(_ => this.log(`update document type create=${documentTypes}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('document type  create'))
      );
  }

  updateDocumentType(id: number, documentType: DocumentType): Observable<any> {
    return this.http.put(`${environment.api_url}${this.documentUrl}/${id}`, documentType)
      .pipe(tap(_ => this.log(`update document type  id=${id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('document type update'))
      );
  }

  /** DELETE: delete the company category on the server */
  deleteDocumentType(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.documentUrl}/${id}`)
      .pipe(tap(_ => this.log(`update document type  id=${id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('document type'))
      );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      this.notifier.notify('error', `${operation} failed`);
      return of(result as T);
    };
  }
  private log(message: string) {

  }

}
