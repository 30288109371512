import {Component, OnInit, ViewChild} from '@angular/core';
import {ViewBankComponent} from './view-bank/view-bank.component';

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss']
})
export class BankComponent implements OnInit {

  @ViewChild(ViewBankComponent, {static: false})
  private ViewBankComponent: ViewBankComponent;
  keyword: string;

  constructor() {
  }

  ngOnInit() {
  }

  triggerSearch(event: any) {
    if (this.keyword === '') {
      this.ViewBankComponent.onUpdated(true);
    } else {
      this.ViewBankComponent.searchBank(this.keyword);
    }
  }
}
