import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { InspectionIndustrialAreaTypeService } from 'src/app/service/inspection-master-data-service/inspection-industrial-area-type/inspection-industrial-area-type.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inspection-industrial-area-type',
  templateUrl: './inspection-industrial-area-type.component.html',
  styleUrls: ['./inspection-industrial-area-type.component.scss']
})
export class InspectionIndustrialAreaTypeComponent implements OnInit,OnDestroy {


  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");

  createIndustrialAreaTypeForm: FormGroup;
  updateIndustrialAreaTypeForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  propertyTypeList: any =[];
  activationButton: boolean;
  public createIndustrialAreaTypeModel = new InspectionCommonDataModel(null,'','','','');
  public updateIndustrialAreaTypeModel = new InspectionCommonDataModel(null,'','','','');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;
  IndustrialAreaTypes: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionIndustrialAreaTypeService: InspectionIndustrialAreaTypeService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createIndustrialAreaTypeForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });
    this.updateIndustrialAreaTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });

    // this.loadPropertyType();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }



  //create IndustrialArea Type - start

  get f() { return this.createIndustrialAreaTypeForm.controls; }

  setIndustrialAreaTypeModelToSave(){
    this.createIndustrialAreaTypeModel.name = this.createIndustrialAreaTypeForm.get('name').value;
    this.createIndustrialAreaTypeModel.description = this.createIndustrialAreaTypeForm.get('description').value;
    this.createIndustrialAreaTypeModel.code = this.createIndustrialAreaTypeForm.get('code').value;
    this.createIndustrialAreaTypeModel.dataStatus = "ACTIVE";
  }

    //save IndustrialArea Type model
  saveIndustrialAreaType(){
    if(this.createIndustrialAreaTypeForm.valid){
    this.setIndustrialAreaTypeModelToSave();
    this.sub.add(
      this.inspectionIndustrialAreaTypeService.createIndustrialAreaType(this.createIndustrialAreaTypeModel).subscribe(data=>{
        this.notifier.notify('success', (data as any).message);
        this.updateTheTable();
        this.reset();
      })
      );
    }else{
      this.createIndustrialAreaTypeForm.markAllAsTouched();
    }
  }

  reset(){
    this.createIndustrialAreaTypeForm.reset()
  }
  //create IndustrialArea Type - end

  //update IndustrialArea Type - start

    //get update controllers
  get f1() { return this.updateIndustrialAreaTypeForm.controls; }

    //set data To IndustrialArea Type Type model
  setIndustrialAreaType(industrialArea:any){
    this.updateIndustrialAreaTypeForm.get('id').setValue(industrialArea.id);
    this.updateIndustrialAreaTypeForm.get('name').setValue(industrialArea.name);
    this.updateIndustrialAreaTypeForm.get('description').setValue(industrialArea.description);
    this.updateIndustrialAreaTypeForm.get('code').setValue(industrialArea.code);

    if(industrialArea.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateIndustrialAreaTypeModel.id = this.updateIndustrialAreaTypeForm.get('id').value;
    this.updateIndustrialAreaTypeModel.name = this.updateIndustrialAreaTypeForm.get('name').value;
    this.updateIndustrialAreaTypeModel.description = this.updateIndustrialAreaTypeForm.get('description').value;
    this.updateIndustrialAreaTypeModel.code = this.updateIndustrialAreaTypeForm.get('code').value;

    if(this.activationButton == true){
      this.updateIndustrialAreaTypeModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateIndustrialAreaTypeModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateIndustrialAreaType(){
    this.setDataToModel();
    this.sub.add(this.inspectionIndustrialAreaTypeService.updateIndustrialAreaType(this.updateIndustrialAreaTypeModel.id, this.updateIndustrialAreaTypeModel).subscribe(data=>{
      this.notifier.notify('success', (data as any).message);
      this.updateTheTable();
    }))
  }

  //update IndustrialArea Type - end

//search and view table start
  pageable(i: number) {
    this.inspectionIndustrialAreaTypeService.getIndustrialAreaType((i-1),10).subscribe(res=>{
      this.IndustrialAreaTypes = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionIndustrialAreaTypeService.getIndustrialAreaType(0, 10)
      .subscribe(
        (res) => {
          this.IndustrialAreaTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search IndustrialArea Type Type
    searchIndustrialAreaType(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionIndustrialAreaTypeService.searchIndustrialAreaType(this.keyword,0,10).subscribe(res=>{
          this.IndustrialAreaTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionIndustrialAreaTypeService.deleteIndustrialAreaType(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end

}
