import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SubSink} from 'subsink';
import {ValuationRequestService} from '../../../../../../service/valuation-request/valuation-request.service';
import {ValuationRequest} from '../../../../../../model/valuation-request';

@Component({
  selector: 'app-view-requests',
  templateUrl: './view-requests.component.html',
  styleUrls: ['./view-requests.component.scss']
})
export class ViewRequestsComponent implements OnInit {

  selectSideNav = 1;
  private sub = new SubSink();
  valuationRequest: ValuationRequest;

  constructor(private route: ActivatedRoute, private  router: Router,
              private valuationRequestService: ValuationRequestService) {
    this.valuationRequest = new ValuationRequest();
  }

  ngOnInit() {
    const valuationRequestId = +this.route.snapshot.paramMap.get('id');
    this.valuationRequest.id = valuationRequestId;
    this.getValuationRequest(valuationRequestId);
  }

  getValuationRequest(valuationRequestId: number) {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(valuationRequestId)
      .subscribe((res) => {
        this.valuationRequest = res.data;
        sessionStorage.setItem('valuationRequest', JSON.stringify(this.valuationRequest));
        this.valuationRequest.id = valuationRequestId;
        console.log('valuationRequestId = ' + valuationRequestId);
      }));
  }

  backToList() {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(Number(this.valuationRequest.id))
      .subscribe((res) => {
        this.valuationRequest = res.data;

        console.log(this.valuationRequest.valuationRequestStatus + '................');

        if (this.valuationRequest.valuationRequestStatus === 'PENDING') {
          this.router.navigate(['/view-assessment/all-request']);
        }
        if (this.valuationRequest.valuationRequestStatus === 'COMPLETED') {
          this.router.navigate(['/view-assessment/completed']);
        }
        if (this.valuationRequest.valuationRequestStatus === 'REJECTED') {
          this.router.navigate(['/view-assessment/reject']);
        }
        if (this.valuationRequest.valuationRequestStatus === 'PAYPEND') {
          this.router.navigate(['/view-assessment/payment-pending']);
        }
      }));
  }
}
