import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-stamp-duty-opinion-reports',
	templateUrl: './stamp-duty-opinion-reports.component.html',
	styleUrls: ['./stamp-duty-opinion-reports.component.scss']
})
export class StampDutyOpinionReportsComponent implements OnInit {

	@ViewChild('opinion_registration', null) private opinion_registration: TemplateRef<object>;
	@ViewChild('application_payment_details', null) private application_payment_details: TemplateRef<object>;
	@ViewChild('assessor_distribution_report', null) private assessor_distribution: TemplateRef<object>;
	@ViewChild('certified_detail_report', null) private certified_detail: TemplateRef<object>;

	// ********** THIWA ADDED CODE **********
	@ViewChild('bare_land_report', null) private bare_land: TemplateRef<object>;
	@ViewChild('registration_details_report', null) private registration_details: TemplateRef<object>;
	@ViewChild('condominium_report', null) private condominium: TemplateRef<object>;
	@ViewChild('land_registry_details_report', null) private land_registry_details: TemplateRef<object>;
	@ViewChild('land_with_build_report', null) private land_with_build: TemplateRef<object>;
	@ViewChild('notary_details_report', null) private notary_details: TemplateRef<object>;
	@ViewChild('rejected_appeals_report', null) private rejected_appeals: TemplateRef<object>;

	@ViewChild('certified_opinion_details_report', null) private certified_opinion_details: TemplateRef<object>;
	@ViewChild('certified_opinion_count_report', null) private certified_opinion_count: TemplateRef<object>;
	@ViewChild('app_payment_rejected_report', null) private app_payment_rejected: TemplateRef<object>;
	@ViewChild('settled_appeal_summary_report', null) private settled_appeal_summary: TemplateRef<object>;
	@ViewChild('certified_opinion_summary_report', null) private certified_opinion_summary: TemplateRef<object>;
	@ViewChild('certified_opinion_summary_count_report', null) private certified_opinion_summary_count: TemplateRef<object>;
	@ViewChild('not_certified_opinion_report', null) private not_certified_opinion: TemplateRef<object>;
	@ViewChild('evidence_opinion_report', null) private evidence_opinion: TemplateRef<object>;

	// @ViewChild(OpinionRegistrationComponent, null) optionRegistration: OpinionRegistrationComponent;

	public showDynamicGrid: boolean;
	furtherRequirementForm: FormGroup;

	constructor(
		public modalService: NgbModal,
		private formBuilder: FormBuilder
	) { }

	ngOnInit() {
		this.showDynamicGrid = false;
	}

	get f1() {
		return this.furtherRequirementForm.controls;
	}

	openPopupMessage(content) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static',size:'lg' });
	}

	opinion_registration_report() {
		this.showDynamicGrid = true;
		this.openPopupMessage(this.opinion_registration);
	}

	application_payment_details_report() {
		this.showDynamicGrid = true;
		this.openPopupMessage(this.application_payment_details);
	}

	assessor_distribution_report_func() {
		this.showDynamicGrid = true;
		this.openPopupMessage(this.assessor_distribution);
	}

	certified_detail_report_func() {
		this.showDynamicGrid = true;
		this.openPopupMessage(this.certified_detail);
	}

	closeFurtherReqRemark() {
		this.modalService.dismissAll();
	}

	generateReport() {
		this.modalService.dismissAll();
	}

	generate() {
		this.modalService.dismissAll();
	}

	// ********** THIWA ADDED CODE **********
	bare_land_report_func() {
		this.showDynamicGrid = true;
		this.openPopupMessage(this.bare_land);
	}

	registration_details_report_func() {
		this.showDynamicGrid = true;
		this.openPopupMessage(this.registration_details);
	}

	condominium_report_func() {
		this.showDynamicGrid = true;
		this.openPopupMessage(this.condominium);
	}

	land_registry_details_report_func() {
		this.showDynamicGrid = true;
		this.openPopupMessage(this.land_registry_details);
	}

	land_with_build_report_func() {
		this.showDynamicGrid = true;
		this.openPopupMessage(this.land_with_build);
	}

	notary_details_report_func() {
		this.showDynamicGrid = true;
		this.openPopupMessage(this.notary_details);
	}

	rejected_appeals_report_func() {
		this.showDynamicGrid = true;
		this.openPopupMessage(this.rejected_appeals);
	}

	certified_opinion_details_report_func() {
		this.showDynamicGrid = true;
		this.openPopupMessage(this.certified_opinion_details);
	}

	certified_opinion_count_report_func() {
		this.showDynamicGrid = true;
		this.openPopupMessage(this.certified_opinion_count);
	}

	app_payment_rejected_report_func() {
		this.showDynamicGrid = true;
		this.openPopupMessage(this.app_payment_rejected);
	}

	settled_appeal_summary_report_func() {
		this.showDynamicGrid = true;
		this.openPopupMessage(this.settled_appeal_summary);
	}

	certified_opinion_summary_report_func() {
		this.showDynamicGrid = true;
		this.openPopupMessage(this.certified_opinion_summary);
	}

	certified_opinion_summary_count_report_func() {
		this.showDynamicGrid = true;
		this.openPopupMessage(this.certified_opinion_summary_count);
	}

	not_certified_opinion_report_func() {
		this.showDynamicGrid = true;
		this.openPopupMessage(this.not_certified_opinion);
	}

	evidence_opinion_report_func() {
		this.showDynamicGrid = true;
		this.openPopupMessage(this.evidence_opinion);
	}
}
