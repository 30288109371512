import { InspectionLandBuildingFeature } from './inspection-land-building-feature';
import { InspectionBuildingConstruction } from './inspection-building-construction';
import { InspectionBuildingDetails } from './inspection-building-details';

export class InspectionLandBuilding {
  public categoryPropertyId: string;

  public buildingNo: string;
  public buildingName: string;
  public noOfBuildings: string;

  public inspectionLandBuildingFeatures: Array<InspectionLandBuildingFeature>
  public inspectionBuildingConstructions: Array<InspectionBuildingConstruction>;
  public inspectionBuildingDetails: InspectionBuildingDetails;

  constructor(
    categoryPropertyId: string,
    buildingNo: string,
    buildingName: string,
    inspectionLandBuildingFeatures: Array<InspectionLandBuildingFeature>,
    noOfBuildings: string,
    inspectionBuildingConstructions: Array<InspectionBuildingConstruction>,
    inspectionBuildingDetails: InspectionBuildingDetails
  ) {
    this.categoryPropertyId = categoryPropertyId;
    this.buildingNo = buildingNo;
    this.buildingName = buildingName;
    this.inspectionLandBuildingFeatures = inspectionLandBuildingFeatures;
    this.noOfBuildings = noOfBuildings;
    this.inspectionBuildingConstructions = inspectionBuildingConstructions;
    this.inspectionBuildingDetails = inspectionBuildingDetails;
  }
}
