import { Component, OnInit } from '@angular/core';
import {ValuationRequest} from '../../../../../../model/valuation-request';
import {Property} from '../../../../../../model/property';
import {Payment} from '../../../../../../model/payment';
import {Loan} from '../../../../../../model/loan';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss']
})
export class TransferComponent implements OnInit {
  valuationRequest: ValuationRequest;

  constructor() {
    const propertyOne = new Property();
    propertyOne.loan = new Loan();
    this.valuationRequest = new ValuationRequest();
    this.valuationRequest.properties.push(propertyOne);
    this.valuationRequest.payment = new Payment();
    this.valuationRequest.noOfGrantees = 0;
    this.valuationRequest.noOfGranters = 0;
    this.valuationRequest.stampDuty = 0.0;
    this.valuationRequest.deedNature = 'Transfer';
    this.valuationRequest.valuation = 0.0;
    this.valuationRequest.fileNumberFormat = '';
  }

  ngOnInit() {

  }

}
