import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InspectionLandTypeService {

  private inspectionLandTypeUrl = '/inspection-land-type';


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  createLandType(landTypeModel:InspectionCommonDataModel):Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionLandTypeUrl}`,landTypeModel)
  }

  getLandType(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.inspectionLandTypeUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  updateLandType(id: number, landTypeModel:InspectionCommonDataModel): Observable<any> {
    return this.http.put(`${environment.api_url}${this.inspectionLandTypeUrl}/${id}`, landTypeModel);
  }

  searchLandType(keyWord:string,offset, limit):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.inspectionLandTypeUrl +'/search?keyword='+ keyWord + '&offset=' + offset + '&limit=' + limit + '');
  }

  deleteLandType(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.inspectionLandTypeUrl}/${id}`);
  }
}
