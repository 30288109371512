export class PropertyUsageSubTypeNewModel{
  id:number;
  description:string;
  name:string;
  propertyUsageId:number;

  constructor(
    id:number,
    description:string,
    name:string,
    propertyUsageId:number,
  ){
    this.id = id;
    this.description = description;
    this.name = name;
    this.propertyUsageId = propertyUsageId;
  }
}
