import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import {Observable, of, throwError} from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaymentMethod } from 'src/app/model/payment-method';
import { tap, catchError } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import {IPaymentMethodPage} from '../../model/interfaces/payment-method-page';
import {ICompanyCategoryPage} from '../../model/interfaces/company-category-page';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {

  private paymentMethodUrl = '/payment-methods';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  getPayementMethod(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.paymentMethodUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  getAll(): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.paymentMethodUrl + '/all');
  }

  createPaymentMethod(paymentMethod: PaymentMethod): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.paymentMethodUrl}`,
      JSON.stringify(paymentMethod), this.httpOptions)
      .pipe(tap(_ => this.log(`update payment method create=${paymentMethod}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('payment method  create'))
      );
  }

  updatePaymentMethod(id: number, paymentMethod: PaymentMethod): Observable<any> {
    return this.http.put(`${environment.api_url}${this.paymentMethodUrl}/${id}`, paymentMethod)
      .pipe(tap(_ => this.log(`update payment method  id=${paymentMethod.id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('payment method update'))
      );
  }

  /** DELETE: delete the company category on the server */
  deletePaymentMethod(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.paymentMethodUrl}/${id}`)
      .pipe(tap(_ => this.log(`update payment method  id=${id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('paymnet method'))
      );
  }

  /* Search payment method from server*/
  searchPaymentMethod(keyword: string, offset: number, limit: number): Observable<IPaymentMethodPage> {
    // Parameters
    const params = {
      params: {
        offset: offset.toString(),
        limit: limit.toString(),
        keyword
      }
    };
    return this.http.get<IPaymentMethodPage>(`${environment.api_url}${this.paymentMethodUrl}/search`, params)
      .pipe(tap(_ => this.log('search Payment Method')),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('get payment method'))
      );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      this.notifier.notify('error', `${operation} failed`);
      return of(result as T);
    };
  }
  private log(message: string) {

  }

}










