import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InspectionIndustrialAreaTypeService {


  private inspectionIndustrialAreaTypeUrl = '/inspection-industrial-area-type';


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  createIndustrialAreaType(IndustrialAreaTypeModel:InspectionCommonDataModel):Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionIndustrialAreaTypeUrl}`,IndustrialAreaTypeModel)
  }

  getIndustrialAreaType(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.inspectionIndustrialAreaTypeUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  updateIndustrialAreaType(id: number, IndustrialAreaTypeModel:InspectionCommonDataModel): Observable<any> {
    return this.http.put(`${environment.api_url}${this.inspectionIndustrialAreaTypeUrl}/${id}`, IndustrialAreaTypeModel);
  }

  searchIndustrialAreaType(keyWord:string,offset, limit):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.inspectionIndustrialAreaTypeUrl +'/search?keyword='+ keyWord + '&offset=' + offset + '&limit=' + limit + '');
  }

  deleteIndustrialAreaType(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.inspectionIndustrialAreaTypeUrl}/${id}`);
  }
}
