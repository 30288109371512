import { Component, OnInit, OnDestroy } from '@angular/core';
import { GramaNiladariDivision } from 'src/app/model/grama-niladari-division';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { GramaNiladariDivisionService } from 'src/app/service/grama-niladari-division/grama-niladari-division.service';
import { DataService } from 'src/app/service/data.service';
import { NotifierService } from 'angular-notifier';
import { DistrictService } from 'src/app/service/district/district.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-search-grama-niladari-division',
  templateUrl: './search-grama-niladari-division.component.html',
  styleUrls: ['./search-grama-niladari-division.component.scss']
})
export class SearchGramaNiladariDivisionComponent implements OnInit, OnDestroy {

  addGramaNiladariDivisionForm: FormGroup;

  districts = [];
  private sub = new SubSink();

  constructor(private districtService: DistrictService, private notifier: NotifierService, private datService: DataService,
              private gramaNiladariDivisionService: GramaNiladariDivisionService, private formBuilder: FormBuilder) { }

  ngOnInit() {

    this.addGramaNiladariDivisionForm = this.formBuilder.group({
      district: ['', [Validators.required]],
      code: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });

    this.sub.add(this.districtService.getDistricts(0, 10).subscribe(
      (res) => {
        this.districts = (res as any).data;
      }
    ));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  get f() { return this.addGramaNiladariDivisionForm.controls; }

  saveGramaNiladariDivision() {
    if (this.addGramaNiladariDivisionForm.valid) {
      const gramaNiladariDivision = new GramaNiladariDivision();

      gramaNiladariDivision.description = this.getDescription();
      gramaNiladariDivision.code = this.getCode();
      gramaNiladariDivision.district = this.getDistrict();

      this.sub.add(this.gramaNiladariDivisionService.createGramaNiladariDivision(gramaNiladariDivision)
        .subscribe(response => {
          this.notifier.notify('success', (response as any).message);
          this.datService.updateTableGramaNiladariDivision(true);
        }));

      this.reset();
    }
  }
  getDescription() {
    return this.addGramaNiladariDivisionForm.get('description').value;
  }

  getCode() {
    console.log('code' + this.addGramaNiladariDivisionForm.get('code').value);
    return this.addGramaNiladariDivisionForm.get('code').value;
  }
  getDistrict() {
    return this.addGramaNiladariDivisionForm.get('district').value;
  }

  reset() {
    this.addGramaNiladariDivisionForm.reset();
  }
}
