import { Component, OnDestroy, OnInit } from '@angular/core';
import { passwordPolicy } from 'src/app/model/passwordPolicy';
import { PasswordPolicyService } from 'src/app/service/passwordPolicy/password-policy.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {

  passwordPolicyPattern ='^(?=(.*[A-Z]){$7@})(?=(.*[0-9]){$1@})(?=(.*[#$@!%&*?]){$3@})[A-Za-z0-9#$@!%&*?]{$5@,$6@}$';
  public passwordPolicyModel = new passwordPolicy(null,'','','','','','','','','');


  constructor(
    private passwordPolicyService: PasswordPolicyService
  ) { }
  ngOnDestroy() {
    sessionStorage.removeItem('passwordPolicy');
  }

  ngOnInit() {
    this.getPasswordPolicy();
  }

  getPasswordPolicy(){
    this.passwordPolicyService.getActivePasswordPolicy().subscribe(data=>{
      if(data.data == null){
        this.passwordPolicyModel.minNumaric = "03";
        // this.passwordPolicyModel.maxNumaric = "";
        this.passwordPolicyModel.minSpecialChar = "03";
        // this.passwordPolicyModel.maxSpecialChar = "";
        this.passwordPolicyModel.minLength = "06";
        this.passwordPolicyModel.maxLength = "08";
        this.passwordPolicyModel.minUpperChar = "01";
      }else{
        this.passwordPolicyModel=(data as any).data;
      }

      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$1@",this.passwordPolicyModel.minNumaric);
      // this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$2@",this.passwordPolicyModel.maxNumaric);
      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$3@",this.passwordPolicyModel.minSpecialChar);
      // this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$4@",this.passwordPolicyModel.maxSpecialChar);
      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$5@",this.passwordPolicyModel.minLength);
      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$6@",this.passwordPolicyModel.maxLength);
      this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$7@",this.passwordPolicyModel.minUpperChar);

      sessionStorage.setItem('passwordPolicy',this.passwordPolicyPattern);
    });
  }

}
