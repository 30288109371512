import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ValuationRequest} from '../../../../../model/valuation-request';
import {ValuationRequestService} from '../../../../../service/valuation-request/valuation-request.service';
import {SubSink} from 'subsink';
import {NotifierService} from 'angular-notifier';
import {Router} from '@angular/router';
import { PDFProgressData } from 'ng2-pdf-viewer'
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';

import {DocumentUploadService} from '../../../../../service/document-upload/document-upload.service';
import {Constant} from 'src/app/util/constant';
import {GranteeFrComponent} from '../../../dashboard-external/further-requirement/wizard-fr/grantee-fr/grantee-fr.component';
import {GrantorFrComponent} from '../../../dashboard-external/further-requirement/wizard-fr/grantor-fr/grantor-fr.component';
import {PropertyFrComponent} from '../../../dashboard-external/further-requirement/wizard-fr/property-fr/property-fr.component';
import {OtherFrComponent} from '../../../dashboard-external/further-requirement/wizard-fr/other-fr/other-fr.component';
import {DeclarationFrComponent} from '../../../dashboard-external/further-requirement/wizard-fr/declaration-fr/declaration-fr.component';
import { FileService } from 'src/app/service/file/file.service';

@Component({
  selector: 'app-wizard-fr',
  templateUrl: './wizard-fr.component.html',
  styleUrls: ['./wizard-fr.component.scss']
})
export class WizardFrComponent implements OnInit {

  @Input()
  selectedOption: string;
  @Input()
  valuationRequest: ValuationRequest;
  selectMenu = 1;
  granteeSelectedPaths = [];
  grantorSelectedPaths = [];
  otherSelectedPaths = [];
  errorList = [];
  documentsIdList = [];
  isValidated: boolean;
  response: string;
  saveButtonFurtherRequirment = 'Submit Further Requirment';
  onlineSelectedPaths = [];

  selectPathsCollection = [];

  private sub = new SubSink();
  @ViewChild(GranteeFrComponent, {static: false})
  private granteeComponent: GranteeFrComponent;
  @ViewChild(GrantorFrComponent, {static: false})
  private grantorComponent: GrantorFrComponent;
  @ViewChild(PropertyFrComponent, {static: false})
  private propertyComponent: PropertyFrComponent;
  @ViewChild(OtherFrComponent, {static: false})
  private otherComponent: OtherFrComponent;
  @ViewChild(DeclarationFrComponent, {static: false})
  private declarationComponent: DeclarationFrComponent;

  public updateStatus: string;
  public updateValuationRequestId: number;

  dataDone = false;
  private noDocs = false;
  previewFileData: any;
  image: any;

  constructor(
    private valuationRequestService: ValuationRequestService,
    private notifier: NotifierService,
    private documentUploadService: DocumentUploadService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private fileService: FileService,
    public sanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {
    this.updateValuationRequestId = Number(localStorage.getItem('valuation_request_id'));
    this.updateStatus = sessionStorage.getItem('valuationUpdate');

    if (this.updateStatus === 'true') {
      this.loadUpdatedValuationInfo();
    }
  }

  loadUpdatedValuationInfo() {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(this.updateValuationRequestId)
      .subscribe((res) => {
        this.valuationRequest = res.data;
        // console.log(JSON.stringify(this.valuationRequest));
      }));
  }

  autoSave() {
    // if (JSON.parse(localStorage.getItem('valuation_request_id')) === null) {
    //   this.saveInitialValuationRequest();
    // } else {
    //   this.updateValuationRequest();
    // }
  }

  addGrantersAndGranteesToValuationRequest() {
    if (this.granteeComponent.checkGranteeAvailability() && this.grantorComponent.checkGranterAvailability()) {
      if (!this.granteeComponent.grantees[0].isGranteeIndividual) {
        this.granteeComponent.setEveryChildComponentUploadingDocuments();
        this.granteeSelectedPaths = this.granteeComponent.selectPaths;
      } else {
        this.granteeSelectedPaths = [];
      }
      if (!this.grantorComponent.granters[0].isGrantorIndividual) {
        this.grantorComponent.setEveryChildComponentUploadingDocuments();
        this.grantorSelectedPaths = this.grantorComponent.selectPaths;
      } else {
        this.grantorSelectedPaths = [];
      }
      console.log("Save Grantee and Grantor");
      console.log(this.valuationRequest);
      this.autoSaving(this.valuationRequest,'GranteeAndGrantor');
      // this.selectMenu = this.selectMenu + 1;
    } else {
      this.notifier.notify('error', 'Please Add Grantor and Grantee');
    }
  }

  saveInitialValuationRequest() {
    this.valuationRequest.created_date = new Date();
    this.valuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
    this.valuationRequest.noOfGranters = this.valuationRequest.granters.length;
    this.valuationRequest.valuationRequestStatus = Constant.VALUATION_REQUEST_DRAFT_STATUS;
    // this.sub.add(this.valuationRequestService.createValuationRequest(this.valuationRequest)
    //   .subscribe(res => {
    //     localStorage.setItem('valuation_request_id', res.data.id);
    //     this.valuationRequest.id = res.data.id;
    //   }));
  }

  backToGranterGrantee() {
    this.selectMenu = this.selectMenu - 1;
  }

  addPropertiesToValuationRequest() {
    this.isValidated = this.propertyComponent.propertyValidationCheck();
    if (this.isValidated === true) {
      this.updatePropertyBuildingValuationRequest();
    }
  }

  updatePropertyBuildingValuationRequest() {
    // this.valuationRequest.id = Number(localStorage.getItem('valuation_request_id'));
    // this.valuationRequest.created_date = new Date();
    // this.valuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
    // this.valuationRequest.noOfGranters = this.valuationRequest.granters.length;
    // this.valuationRequest.valuationRequestStatus = Constant.VALUATION_REQUEST_DRAFT_STATUS;
    // this.selectMenu = this.selectMenu + 1;
    console.log(this.valuationRequest);
    this.autoSaving(this.valuationRequest,'Property')
    // this.sub.add(this.valuationRequestService.createValuationRequest(this.valuationRequest)
    //   .subscribe(res => {
    //     this.selectMenu = this.selectMenu + 1;
    //   }));
  }

  updateValuationRequest() {
    this.valuationRequest.id = Number(localStorage.getItem('valuation_request_id'));
    this.valuationRequest.created_date = new Date();
    this.valuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
    this.valuationRequest.noOfGranters = this.valuationRequest.granters.length;
    this.sub.add(this.valuationRequestService.createValuationRequest(this.valuationRequest)
      .subscribe(res => {
      }));
  }

  // addPaymentDetailsToValuationRequest() {
  //   if (this.paymentComponent.setPaymentDetailsToValuationRequest()) {
  //     if (this.paymentComponent.paymentMethod === 'Bank-Payment') {
  //       this.paymentComponent.setBankChildComponentUploadingDocuments();
  //     }
  //     if (this.paymentComponent.paymentMethod === 'Cash-Payment') {
  //       this.paymentComponent.setCashChildComponentUploadingDocuments();
  //     }
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  backToDeclaration() {
    this.selectMenu = this.selectMenu - 1;
  }

  submitValuationRequest() {
    // try {
    //   if (this.addPaymentDetailsToValuationRequest()) {
    //     this.holdDocumentsUntilValuationRequestLastStep();
    //     this.saveButton = 'Saving..';
    //   } else {
    //     this.saveButton = 'Submit';
    //     this.notifier.notify('error', 'Please check payment details');
    //   }
    // } catch (e) {
    //   this.saveButton = 'Submit';
    //   this.notifier.notify('error', 'Please try again');
    // }

  }

  checkDocuments() {
    this.errorList = [];

    // if (this.otherComponent.fileUploadCopyOfPreviousDeed.filesList.length === 0) {
    //   this.errorList.push('Copy of Previous Deed');
    // }

    // if (this.otherComponent.fileUploadRoutePlan.filesList3.length === 0) {
    //   this.errorList.push('Route Plan');
    // }

    // if (this.otherComponent.fileUploadDraftDeed.filesList1.length === 0) {
    //   this.errorList.push('Draft Deed');
    // }

    // if (this.otherComponent.fileUploadGranteeNic.filesList5.length === 0) {
    //   this.errorList.push('Photo Copy of Grantee\'s NIC');
    // }

    // if (this.otherComponent.fileUploadSurveyPlan.filesList2.length === 0) {
    //   this.errorList.push('Survey Plan / Building Plan');
    // }


    if (this.errorList.length === 0) {
      this.otherComponent.setEveryChildComponentUploadingDocuments();
      this.otherSelectedPaths = this.otherComponent.selectPaths;
      this.saveDocuments();
      this.selectMenu = this.selectMenu + 1;
    } else {
      const re = /,/gi;
      const errormsg = this.errorList.toString().replace(re, '<br/>');
      Swal.fire({
        title: 'Please upload following required documents to proceed',
        icon: 'error',
        html: '<div style="color: red;text-align: left;">' + errormsg + '</div>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
          'OK',
      });
    }
  }

  saveDocuments() {
    this.selectPathsCollection.push(this.otherSelectedPaths[0]);
    this.selectPathsCollection.push(this.otherSelectedPaths[1]);
    this.selectPathsCollection.push(this.otherSelectedPaths[2]);
    this.selectPathsCollection.push(this.otherSelectedPaths[3]);
    this.selectPathsCollection.push(this.otherSelectedPaths[4]);
    this.selectPathsCollection.push(this.otherSelectedPaths[5]);
    this.selectPathsCollection.push(this.otherSelectedPaths[6]);
    this.selectPathsCollection.push(this.otherSelectedPaths[7]);
    this.selectPathsCollection.push(this.granteeSelectedPaths);
    this.selectPathsCollection.push(this.grantorSelectedPaths);
    this.documentUploadService.uploadValuationRequestFurtherRequirementDocuments(+localStorage.getItem('valuation_request_id'), this.selectPathsCollection).subscribe(
      res => {
        this.documentsIdList = (res as any).data;
        this.valuationRequest.documentsIdList = this.documentsIdList;
        this.valuationRequest.created_date = new Date();
        this.valuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
        this.valuationRequest.noOfGranters = this.valuationRequest.granters.length;
        this.valuationRequest.id = Number(localStorage.getItem('valuation_request_id'));
        this.valuationRequest.valuationRequestStatus = Constant.VALUATION_REQUEST_COMPLETE_STATUS;
      }, err => {
        this.notifier.notify('error', 'Document upload failed...');
      }
    );
  }

  submitValuationRequestFurtherRequirment()
  {
    if (this.declarationComponent.checkOrNot()) {
      console.log(this.valuationRequest);
      this.saveButtonFurtherRequirment = 'Saving..'
      this.valuationRequest.id = Number(localStorage.getItem('valuation_request_id'));

      this.sub.add(this.valuationRequestService.valuationRequestSubmitFurtherRequirement(this.valuationRequest)
          .subscribe(response => {
            // const data = response as any;
            this.notifier.notify('success', 'Further requirement submitted successfully');
            localStorage.removeItem('grantees');
            localStorage.removeItem('granters');
            localStorage.removeItem('deed');
            localStorage.removeItem('assessment');
            localStorage.removeItem('extend');
            localStorage.removeItem('other');
            localStorage.removeItem('loan');
            localStorage.removeItem('building');
            localStorage.removeItem('valuation_request_id');
            // window.location = data.data.encryptedRequest;
            window.location.href = '/dashboard'

          }, (error => {
            this.saveButtonFurtherRequirment = 'Submit Further Requirment';
            this.notifier.notify('error', 'Please try again');
          })));
    } else {
      this.notifier.notify('error', 'Please agree before submitting');
    }

  }

  checkOrNot() {
    if (this.declarationComponent.checkOrNot()) {
      this.selectMenu = this.selectMenu + 1;
    } else {
      this.notifier.notify('error', 'Please agree before moving to payment');
    }
  }

  autoSaving(saveModel : any, position : String)
  {
    let object = {
      valuationRequest : saveModel,
      savingPosition : position,
      type : "GiftOrTransfer"
    }
    console.log(object)
    this.valuationRequestService.valuationRequestAutoSaveFurtherRequirement(object).subscribe(
     res => {
       console.log(res.data);
      this.selectMenu = this.selectMenu + 1;
     },
     err => {}
    );
  }

  // RETRIEVE DOCUMENTS - START
  onProgress(progressData: PDFProgressData) {
    if (progressData.loaded == progressData.total) {
      this.spinner.hide();
    }
  }

  previewFile(historyId, title) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadDocumentsByHistoryId(historyId, title).subscribe(result => {
      if (result) {
        this.image = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(() => {
          this.spinner.hide();
        }, 2000);

      } else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }
  // RETRIEVE DOCUMENTS - END

}
