
export class InspectionCommon {
  public code: string;

  constructor(
    code: string
  ) {
    this.code = code;
  }
}
