export class InspectionLand {
  public id:number;
  public inspectionId: string;

  public propertyTypeId: string;
  public propertySubTypeId: string;
  public valuationRequestId: string;
  public inspectionType: string;

  public createdBy: string;
  public createdUserId: string;
  public recreationGroupId: string;

  // survey
  public planNo: string;
  public lotNo: string;

  // legal aspect
  public buildingLine: string;
  public streetLine: string;
  public restriction: string;
  public reservationId: string;
  public centralGovernmentRules: string;
  public localGovernmentRules: string;
  public udaRegulations: string;
  public otherRules: string;

  // location
  public assessmentNo: string;
  public nearestTown: string;
  public accessNearestTown: string;
  public distanceMainRd: string;
  public landMark: string;
  public roadWidth: string;

  // Property Nature
  public landExtent: string;
  public landShapeId: string;
  public roadFrontage: string;
  public length: string;
  public width: string;
  public boundaryNorth: string;
  public boundarySouth: string;
  public boundaryWest: string;
  public boundaryEast: string;
  public landSlopeId: string;
  public landLocationId: string;
  public waterAvailabilityId: string;
  public waterId: string;
  public electricityTypeId: string;
  public electricitySubTypeId: string;
  public landSuitabilityId: string;
  public cultivation: string;
  public tea: string;

  // Infrastructure
  public transportId: string;
  public educationId: string;
  public industrialId: string;
  public healthId: string;
  public securityId: string;
  public telecommunicationId: string;
  public garbageDisposalAvailability: string;
  public drainageFacilityAvailability: string;
  public neighbourhood: string;
  public community: string;
  public busRoute: string;
  public railways: string;

  // Commerical Land
  public specifiedBusiness: string;
  public natureBusinessId: string;
  public industrialAreaId: string;

  // Agricultural Land
  public cultivationId: string;
  public ageCultivationId: string;
  public forecastingYield: string;
  public investment: string;
  public roi: string;

  // Mix Land
  public landNature: string;
  public landPurpose: string;
  public futureExpectation: string;
  public roiId: string;
  public expectedYield: string;

  // Recreation Land
  public investmentCost: string;
  public landNatures: string;
  public landPurposes: string;
  public futureExpectations: string;
  public recreationRoi: string;
  public recreationExpectedYield: string;
  public goodWill: string;
  public assets: string;
  public customerBase: string;

  // Newly Added
  public boundary1: string;
  public boundary2: string;


  constructor() {

  }
}
