import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders,} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import {User} from '../model/user';
import {map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { Constant } from '../util/constant';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private tokenValid = false;

  constructor(private http: HttpClient,private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    // headers
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Basic ' + btoa(`${environment.client_id}:${environment.client_secret}`));
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    const httpOptions = {
      headers
    };

    // login api call
    return this.http.post<any>(`${environment.auth_server_url}/oauth/token`,
      'grant_type=' + environment.grant_type +
      '&username=' + username +
      '&password=' + password + '',
      httpOptions).pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.access_token) {
          // store user details and oauth2 token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('x-auth', user.access_token);
          localStorage.setItem('x-auth-z', user.refresh_token);
          this.currentUserSubject.next(user);
        }
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('x-auth');
    localStorage.removeItem('x-auth-z');
    sessionStorage.removeItem('userinfo');
    sessionStorage.removeItem('valuationRequest');
    this.currentUserSubject.next(null);
    this.router.navigate(['/']);
  }

  isNotary():boolean{
    if(Constant.ROLE_NOTARY === localStorage.getItem('rc')){
      return true;
    }else{
      return false;
    }
  }

  isNotNotary():boolean{
    if(Constant.ROLE_NOTARY === localStorage.getItem('rc')){
      return false;
    }else{
      return true;
    }
  }


  isAuthenticated(): boolean {
    // const accessToken = localStorage.getItem('x-auth');
    // // check whether access token available
    // // if (accessToken !== null) {
    // if (!!localStorage.getItem('x-auth')) {
    //   // check whether access token valid
    //   if (this.isTokenValidated(accessToken)) {
    //     return true;
    //   }
    // }
    // return false;
    return !!localStorage.getItem('x-auth');
  }

  isTokenValidated(accessToken: string): boolean {
    // headers
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Basic ' + btoa(`${environment.client_id}:${environment.client_secret}`));
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    const httpOptions = {
      headers
    };
    this.tokenValid = false;
    this.http.get<boolean>(`${environment.auth_server_url}/oauth/check_token?token=${accessToken}`, httpOptions)
      .subscribe((data: any) => {
          this.tokenValid = true;
        }, // success path
        (error) => {
          this.tokenValid = false;
        }
    );
    return this.tokenValid;
  }

  getrole(username: string):any{
    return this.http.get<any>(environment.api_url + '/users/user?username=' + username );
  }

}
