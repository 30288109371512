import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { OfficeService } from 'src/app/service/office/office.service';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-delete-office',
  templateUrl: './delete-office.component.html',
  styleUrls: ['./delete-office.component.scss']
})
export class DeleteOfficeComponent implements OnInit, OnDestroy {


  private officeId: number;
  private sub = new SubSink();

  @Output() officeDeleted = new EventEmitter<boolean>();



  constructor(private officeService: OfficeService, private notifier: NotifierService) { }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  deleteOffice() {
    this.sub.add(this.officeService.deleteOffice(this.officeId)
      .subscribe(response => {

        this.updateTheView(true);
        this.notifier.notify('success', 'Successfully Deleted Office');
      }));
  }

  updateTheView(deleted: boolean) {
    this.officeDeleted.emit(deleted);
  }

  setOfficeId(id: number) {
    this.officeId = id;
  }


}
