export class InspectionCommonDataModel{
  id:number;
  name:string;
  code:string;
  description:string;
  dataStatus:string;

  constructor(
      id:number,
      name:string,
      code:string,
      description:string,
      dataStatus:string,
  ){

    this.id = id;
    this.name = name;
    this.code = code;
    this.description = description;
    this.dataStatus = dataStatus;

  }
}
