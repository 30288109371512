import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import { refundRequestUpdate } from "../../model/refundRequestUpdate";
import { getUserDetails } from 'src/app/model/getUserDetails';
import { RefundCalculation } from 'src/app/model/refundCalculation';
import { RefundNoticeLetter } from 'src/app/model/refundNoticeLetter';
import { RefundFormRemarks } from 'src/app/model/refundFormRemarks';
import { RefundIssueDetails } from 'src/app/model/refundIssueDetails';
import { refundRejectRequest } from 'src/app/model/refundRejectRequest';
import { FurtherRequirementAndReject } from 'src/app/model/further-requirement-and-reject';
import {RefundFurtherRequirementAndReject} from '../../model/refund-further-requirement-and-reject';
import { ValuationStatus } from 'src/app/model/valuation-status';

@Injectable({
  providedIn: "root",
})
export class RefundOpinionServiceService {
  constructor(private http: HttpClient, private notifier: NotifierService) {}

  private refundRequestUrl = "/refund-opinion/";
  private refundRequestUrlUpdated = "/refund-opinion";
  private refundStatusUrl = '/refund-opinion/updatestatus';
  private refundDocumentStatusUrl = '/refund-opinion/updateDocumentstatus';
  private RefundDocUrl = "/documents";

  getRefundRequestDetailsById(refundRequestId: number): Observable<any> {
    return this.http.get(
      environment.api_url +
        this.refundRequestUrlUpdated +
        "/refund-request/" +
        refundRequestId
    );
  }

  refundUpdateVerificationStatus(ValuationStatus: ValuationStatus): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.refundStatusUrl}`, ValuationStatus);
  }

  refundDocumentUpdateVerificationStatus(ValuationStatus: ValuationStatus): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.refundDocumentStatusUrl}`, ValuationStatus);
  }


  getRefundRequestDetailsByUserIdStatus(
    status: string,
    offset: number,
    limit: number,
  ): Observable<any> {
    return this.http
      .get<any>(
        `${environment.api_url}${this.refundRequestUrlUpdated}/search?refund_status=${status}&offset=${offset}&limit=${limit}`
      )
      .pipe(
        tap((_) => this.log(`valuation-request find by status = ${status}`)),
        catchError((err) => {
          this.notifier.notify("error", err);
          return throwError(err);
        }),
        catchError(this.handleError<any>("valuation-request find by status"))
      );
  }

  getOpinionDetails(opinionNo: number): Observable<any> {
    return this.http.get(
      environment.api_url + this.refundRequestUrl + opinionNo
    );
  }

  getOpinionPaymentDetails(opinionNo: number): Observable<any> {
    return this.http.get(
      environment.api_url +
        this.refundRequestUrl +
        "get-payment-details/" +
        opinionNo
    );
  }

  saveOpinionDetails(opinionDetails): Observable<any> {
    return this.http.post(
      environment.api_url + this.refundRequestUrl + "details",
      opinionDetails
    );
  }

  getRefundGrantorDetails(opinionNo: number): Observable<any> {
    return this.http.get(
      environment.api_url + this.refundRequestUrl + "grantor/" + opinionNo
    );
  }

  saveOtherDetails(otherRefundDetails): Observable<any> {
    return this.http.post(
      environment.api_url + this.refundRequestUrl + "other-details",
      otherRefundDetails
    );
  }

  updateOtherDetails(refundId: number, otherRefundDetails): Observable<any> {
    return this.http.put(
      environment.api_url +
        this.refundRequestUrl +
        "update-other-details/" +
        refundId,
      otherRefundDetails
    );
  }

  getRefundNotaryDetails(opinionNo: number): Observable<any> {
    return this.http.get(
      environment.api_url + this.refundRequestUrl + "notary/" + opinionNo
    );
  }

  createRefundRequestById(refundRequestCommon): Observable<any> {
    return this.http.post(
      environment.api_url + this.refundRequestUrl + "refund-request",
      refundRequestCommon
    );
  }

  updateRefundTableById(
    refundNo: number,
    refundRequestCommon
  ): Observable<any> {
    return this.http.put(
      environment.api_url +
        this.refundRequestUrl +
        "refund-request/" +
        refundNo,
      refundRequestCommon
    );
  }

  checkRefundIdExist(refundNo: number): Observable<any> {
    return this.http.get(
      environment.api_url + this.refundRequestUrl + "is-exist/" + refundNo
    );
  }

  getAllRefundReason(): Observable<any> {
    return this.http.get(
      environment.api_url + this.refundRequestUrl + "get-refund-reason"
    );
  }

  saveReleventDocument(refundNo: string, status: boolean): Observable<any> {
    return this.http.get(
      environment.api_url +
        this.refundRequestUrl +
        "save-relevent-document-status/" +
        refundNo +
        "/" +
        status
    );
  }

  uploadNic(selectPaths: any[], valuationRequestId: string): Observable<any> {
    const formData = new FormData();
    formData.append("file", selectPaths[0], selectPaths[0].name);
    formData.append("data", valuationRequestId);

    return this.http
      .post<any>(`${environment.api_url}${this.RefundDocUrl}/Nic`, formData)
      .pipe(
        tap((_) => this.log(`upload Refund request Documents=${selectPaths}`)),
        catchError((err) => {
          return throwError(err);
        }),
        catchError(this.handleError<any>("upload Refund request Documents"))
      );
  }

  uploadAffidivit(
    selectPaths: any[],
    valuationRequestId: string
  ): Observable<any> {
    const formData = new FormData();
    formData.append("file", selectPaths[0], selectPaths[0].name);
    formData.append("data", valuationRequestId);

    return this.http
      .post<any>(
        `${environment.api_url}${this.RefundDocUrl}/Affidivit`,
        formData
      )
      .pipe(
        tap((_) => this.log(`upload Refund request Documents=${selectPaths}`)),
        catchError((err) => {
          return throwError(err);
        }),
        catchError(this.handleError<any>("upload Refund request Documents"))
      );
  }

  uploadRecipts(
    selectPaths: any[],
    valuationRequestId: string
  ): Observable<any> {
    const formData = new FormData();
    formData.append("file", selectPaths[0], selectPaths[0].name);
    formData.append("data", valuationRequestId);

    return this.http
      .post<any>(`${environment.api_url}${this.RefundDocUrl}/Recipts`, formData)
      .pipe(
        tap((_) => this.log(`upload Refund request Documents=${selectPaths}`)),
        catchError((err) => {
          return throwError(err);
        }),
        catchError(this.handleError<any>("upload Refund request Documents"))
      );
  }

  uploadCertifiedDeed(
    selectPaths: any[],
    valuationRequestId: string
  ): Observable<any> {
    const formData = new FormData();
    formData.append("file", selectPaths[0], selectPaths[0].name);
    formData.append("data", valuationRequestId);

    return this.http
      .post<any>(
        `${environment.api_url}${this.RefundDocUrl}/CertifiedDeed`,
        formData
      )
      .pipe(
        tap((_) => this.log(`upload Refund request Documents=${selectPaths}`)),
        catchError((err) => {
          return throwError(err);
        }),
        catchError(this.handleError<any>("upload Refund request Documents"))
      );
  }

  uploadOther(selectPaths: any[], valuationRequestId: string): Observable<any> {
    const formData = new FormData();
    formData.append("file", selectPaths[0], selectPaths[0].name);
    formData.append("data", valuationRequestId);

    return this.http
      .post<any>(`${environment.api_url}${this.RefundDocUrl}/Other`, formData)
      .pipe(
        tap((_) => this.log(`upload Refund request Documents=${selectPaths}`)),
        catchError((err) => {
          return throwError(err);
        }),
        catchError(this.handleError<any>("upload Refund request Documents"))
      );
  }

  getOpinionDetailsByRefundRequestId(refundRequestId: number): Observable<any> {
    return this.http.get(
      environment.api_url +
        this.refundRequestUrl +
        "opinionDetails/" +
        refundRequestId
    );
  }

  getContactPersonDetailsByRefundRequestId(
    refundRequestId: number
  ): Observable<any> {
    return this.http.get(
      environment.api_url +
        this.refundRequestUrl +
        "contactPersonDetails/" +
        refundRequestId
    );
  }

  getNotaryDetailsByRefundRequestId(refundRequestId: number): Observable<any> {
    return this.http.get(
      environment.api_url +
        this.refundRequestUrl +
        "notaryDetails/" +
        refundRequestId
    );
  }

  getReasonForRefundContentsByRefundRequestId(
    refundRequestId: number
  ): Observable<any> {
    return this.http.get(
      environment.api_url +
        this.refundRequestUrl +
        "reasonForRefundContents/" +
        refundRequestId
    );
  }

  getRefundRequestDetailsByRefundRequestId(
    refundRequestId: number
  ): Observable<any> {
    return this.http.get(
      environment.api_url +
        this.refundRequestUrl +
        "refundRequestDetails/" +
        refundRequestId
    );
  }

  getRefundCalculationdetails(refundRequestId: number): Observable<any> {
    return this.http.get(
      environment.api_url +
        this.refundRequestUrl +
        "refundCalculation/" +
        refundRequestId
    );
  }

  saveRefundCalculation(
    refundCalculationModel: RefundCalculation
  ): Observable<any> {
    return this.http.post(
      environment.api_url + this.refundRequestUrl + "refundCalculation",
      refundCalculationModel
    );
  }

  saveRefundNoticeLetter(
    refundNoticeLetterModel: RefundNoticeLetter
  ): Observable<any> {
    return this.http.post(
      environment.api_url + this.refundRequestUrl + "noticeLetter",
      refundNoticeLetterModel
    );
  }

  getRefundNoticelettersByRefundRequestId(
    refundRequestId: number
  ): Observable<any> {
    return this.http.get(
      environment.api_url +
        this.refundRequestUrl +
        "noticeLetters/" +
        refundRequestId
    );
  }

  saveRefundFormRemarks(
    refundFormRemarksModel: RefundFormRemarks
  ): Observable<any> {
    return this.http.post(
      environment.api_url + this.refundRequestUrl + "refundFormRemarks",
      refundFormRemarksModel
    );
  }

  sendToTaxOfficer(refundRequestUpdate: refundRequestUpdate) {
    return this.http.post(
      environment.api_url + this.refundRequestUrl + "taxOfficer",
      refundRequestUpdate
    );
  }

  updateTaxOfficer(refundRequestUpdate: refundRequestUpdate) {
    return this.http.post(
      environment.api_url + this.refundRequestUrl + "updateTaxOfficer",
      refundRequestUpdate
    );
  }

  getRefundFormRemarksAndCommentsByRefundRequestId(
    refundRequestId: number
  ): Observable<any> {
    return this.http.get(
      environment.api_url +
        this.refundRequestUrl +
        "remarksAndComments/" +
        refundRequestId
    );
  }

  updateRefundRequestStatusForRecommendProcess(
    refundRequestUpdate: refundRequestUpdate
  ) {
    return this.http.post(
      environment.api_url +
        this.refundRequestUrl +
        "updateRefundRequestRecommend",
      refundRequestUpdate
    );
  }

  updateRefundRequestStatusForApprovalProcess(
    refundRequestUpdate: refundRequestUpdate
  ) {
    return this.http.post(
      environment.api_url +
        this.refundRequestUrl +
        "updateRefundRequestApprove",
      refundRequestUpdate
    );
  }

  saveRefundIssueDetails(
    refundIssueDetailsModel: RefundIssueDetails
  ): Observable<any> {
    return this.http.post(
      environment.api_url + this.refundRequestUrl + "refundIssueDetails",
      refundIssueDetailsModel
    );
  }

  updateAccountantStatus(refundRequestUpdate: refundRequestUpdate) {
    return this.http.post(
      environment.api_url + this.refundRequestUrl + "accountant",
      refundRequestUpdate
    );
  }

  rejectApplication(
    rejectApplicationModel: FurtherRequirementAndReject
  ): Observable<any> {
    const formData = new FormData();
    formData.append("refundRequestId", rejectApplicationModel.valuationId);
    formData.append("userName", rejectApplicationModel.userName);
    formData.append("userRole", rejectApplicationModel.userRole);
    formData.append("remark", rejectApplicationModel.remark);
    formData.append(
      "furtherRequirementPosition",
      rejectApplicationModel.furtherRequirementPosition
    );
    if (rejectApplicationModel.uploadedFile != undefined) {
      formData.append(
        "uploadedFile",
        rejectApplicationModel.uploadedFile,
        rejectApplicationModel.uploadedFile.name
      );
    }

    return this.http
      .post<any>(
        `${environment.api_url}${this.refundRequestUrl}reject`,
        formData
      )
      .pipe(
        tap((_) => this.log(`Refund Application reject`)),
        catchError((err) => {
          return throwError(err);
        }),
        catchError(this.handleError<any>("Refund Application reject"))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error}`);
      // notify user
      this.notifier.notify("error", `${operation} failed`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a company category message with the audit service */
  private log(message: string) {
    // Todo: create a service and send operational audit message
  }

  //internal user Apis

  getRefundRequests(): Observable<any> {
    return this.http.get(environment.api_url + this.refundRequestUrl + "list");
  }

  collectionPaymentCompleted(refundId: number): Observable<any> {
    return this.http.get(
      environment.api_url +
        this.refundRequestUrl +
        "collection-payment-completed/" +
        refundId
    );
  }

  getCollectionStatus(refundId: number): Observable<any> {
    return this.http.get(
      environment.api_url +
        this.refundRequestUrl +
        "get-collection-branch-Status/" +
        refundId
    );
  }

  rejectRefundRequest(refundReject: refundRejectRequest): Observable<any> {
    return this.http.post(
      environment.api_url + this.refundRequestUrl + "reject-refund-application",
      refundReject
    );
  }

  getRefundRequestAsUser(
    userid: string,
    userRole: string,
    status: string
  ): Observable<any> {
    return this.http.get(
      environment.api_url +
        this.refundRequestUrl +
        "send-User-Details/" +
        userid +
        "/" +
        userRole +
        "/" +
        status
    );
  }

  refundRequestUpdate(
    refundRequestUpdate: refundRequestUpdate
  ): Observable<any> {
    return this.http.post<any>(
      environment.api_url + this.refundRequestUrl + "update",
      refundRequestUpdate
    );
  }

  refundRequestAssigned(id: number): Observable<any> {
    return this.http.get<any>(
      environment.api_url + this.refundRequestUrl + "assignedRefund/" + id
    );
  }

  updatecollectionBranch(
    refundRequestUpdate: refundRequestUpdate
  ): Observable<any> {
    return this.http.post<any>(
      environment.api_url + this.refundRequestUrl + "collection-Branch",
      refundRequestUpdate
    );
  }

  getCollectionPendingDetails(userRole: string): Observable<any> {
    return this.http.get(
      environment.api_url +
        this.refundRequestUrl +
        "getCollectionDetails/" +
        userRole +
        ""
    );
  }

  getAllRefundDetails(): Observable<any> {
    return this.http.get(
      environment.api_url + this.refundRequestUrl + "refund-reason-description"
    );
  }

  getRefundRequestByID(refundId: number): Observable<any> {
    return this.http.get(
      environment.api_url +
        this.refundRequestUrl +
        "get-refund-request-details/" +
        refundId
    );
  }

  requestFurtherRequirement(
    furtherRequirementModel: RefundFurtherRequirementAndReject
  ): Observable<any> {

    return this.http
      .post<any>(
        `${environment.api_url}${this.refundRequestUrl}request/furtherRequirement`,
        furtherRequirementModel
      )
      .pipe(
        tap((_) =>
          this.log(`Refund Application request further requirement`)
        ),
        catchError((err) => {
          return throwError(err);
        }),
        catchError(
          this.handleError<any>(
            'Refund Application request further requirement'
          )
        )
      );
  }
}
