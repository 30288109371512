import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { passwordPolicy } from 'src/app/model/passwordPolicy';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PasswordPolicyService {

  private passwordPolicyUrl = '/password-policy';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }


  createPasswordPolicy(passwordPolicy: passwordPolicy): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.passwordPolicyUrl}`,passwordPolicy)
  }

  getPasswordPolicy(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.passwordPolicyUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  updatePasswordPolicy(id: number, passwordPolicyModel: passwordPolicy): Observable<any> {
    return this.http.put(`${environment.api_url}${this.passwordPolicyUrl}/${id}`, passwordPolicyModel)
      .pipe(tap(_ => this.log(`update Password Policy id=${passwordPolicyModel.id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('Password Policy update'))
      );
  }

  getActivePasswordPolicy(){
    return this.http.get(`${environment.api_url}${this.passwordPolicyUrl}/get-main-password-policy`)
    .pipe(tap(_ => this.log(`Get Activated Password Policy`)),
    catchError(err => {
      this.notifier.notify('error', err);
      return throwError(err);
    }),
    catchError(this.handleError<any>('Password Policy Search'))
  );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      this.notifier.notify('error', `${operation} failed`);
      return of(result as T);
    };
  }
  private log(message: string) {

  }
}
