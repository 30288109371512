export class InspectionElectricitySubTypeModel{
  id:number;
  name:string;
  description:string;
  code:string;
  electricityType:number;
  dataStatus:string;


  constructor(
    id:number,
    name:string,
    description:string,
    code:string,
    electricityType:number,
    dataStatus:string,
  ){
    this.id = id;
    this.name = name;
    this.description = description;
    this.code = code;
    this.electricityType = electricityType;
    this.dataStatus = dataStatus;
  }
}
