import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import { environment } from 'src/environments/environment';
import { District } from 'src/app/model/district';
import { tap, catchError } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import {IDistrictsPage} from '../../model/interfaces/districts-page';

@Injectable({
  providedIn: 'root'
})
export class DistrictService {

  private distirctUrl = '/districts';  // URL to web api

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  getDistricts(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.distirctUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  getAll(): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.distirctUrl + '/all');

  }

  /* Search District from server*/
  searchDistrict(keyword: string, offset: number, limit: number): Observable<IDistrictsPage> {
    const params = {
      params: {
        offset: offset.toString(),
        limit: limit.toString(),
        keyword
      }
    };
    return this.http.get<any[]>(`${environment.api_url}${this.distirctUrl}/search/`, params)
      .pipe(tap(_ => this.log('search district')),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('get district'))
      );
  }

  /* Search District from server*/
  searchDistrictDetails(id: number): Observable<IDistrictsPage> {
    return this.http.get<any[]>(`${environment.api_url}${this.distirctUrl}/searchBy/${id}`)
      .pipe(tap(_ => this.log('search district')),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('get district'))
      );
  }

  createDistrict(district: District): Observable<any> {
    console.log('serv' + JSON.stringify(district));
    return this.http.post<any>(`${environment.api_url}${this.distirctUrl}`,
      JSON.stringify(district), this.httpOptions)
      .pipe(tap(_ => this.log(`update district create=${district}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('dustrict create'))
      );
  }

  updateDistrict(id: number, district: District): Observable<any> {
    console.log('url id' + id);
    // console.log("data"+district.floorType);
    // alert();
    return this.http.put(`${environment.api_url}${this.distirctUrl}/${id}`, district)
      .pipe(tap(_ => this.log(`update district id=${district.id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('floor dictrict update'))
      );
  }

  /** DELETE: delete the company category on the server */
  deleteDistrict(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.distirctUrl}/${id}`)
      .pipe(tap(_ => this.log(`update district id=${id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('dictrict'))
      );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      this.notifier.notify('error', `${operation} failed`);
      return of(result as T);
    };
  }

  private log(message: string) {

  }

  // getPropertyUsageSubTypeByPropertyUsage(propertyUsageId: number, offset: number, limit: number): Observable<any> {
  //   const params = {
  //     params: {
  //       offset: offset.toString(),
  //       limit: limit.toString()
  //     }
  //   };
  //   return this.http.get(`${environment.api_url}${this.propertyUsageUrl}/${propertyUsageId}/property-usage-sub-types`, params)
  //     .pipe(tap(_ => this.log(`property usage subType get failed`)),
  //       catchError(err => {
  //         this.notifier.notify('error', err);
  //         return throwError(err);
  //       }),
  //       catchError(this.handleError<any>('property usage subType get failed'))
  //     );
  // }

}
