import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {SubSink} from 'subsink';
import {ValuationRequestService} from '../../../../../../../service/valuation-request/valuation-request.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-view-valuation-iu',
  templateUrl: './view-valuation-iu.html',
  styleUrls: ['./view-valuation-iu.scss']
})
export class ViewValuationInternalUserComponent implements OnInit {
  systemDate: any;
  @Input()
  valuationRequestId;
  valuationForm: FormGroup;
  private sub = new SubSink();
  amount: string;
  stampDuty: string;

  constructor(private valuationService: ValuationRequestService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    this.systemDate = mm + '/' + dd + '/' + yyyy;

    // const valuationRequestId = +this.route.snapshot.paramMap.get('id');
  }

  // giveValuation(valuationRequestId: number) {
  //    this.amount = this.valuationForm.get('valuationAmount').value;
  //    this.stampDuty = this.valuationForm.get('stampDuty').value;
  //
  //    this.sub.add(this.valuationService.setValuatationForValuationRequest(valuationRequestId, Number(this.amount), Number(this.stampDuty))
  //     .subscribe(response => {
  //       // notify
  //     }));
  // }
}
