import {Component, Input, OnInit} from '@angular/core';
import {ValuationRequestService} from '../../../../../../../service/valuation-request/valuation-request.service';
import {ValuationRequest} from '../../../../../../../model/valuation-request';
import {SubSink} from 'subsink';
import {NotaryDetails} from '../../../../../../../model/notary-details';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-view-notary',
  templateUrl: './view-notary.component.html',
  styleUrls: ['./view-notary.component.scss']
})
export class ViewNotaryComponent implements OnInit {
  @Input()
  userId: string;

  notaryDetails: NotaryDetails;
  valuationRequestId: any;
  valuationRequest: ValuationRequest;
  private sub = new SubSink();

  constructor(private valuationRequestService: ValuationRequestService,
              private route: ActivatedRoute, private  router: Router) {
  }

  ngOnInit() {
    this.valuationRequestId = +this.route.snapshot.paramMap.get('id');

    const notaryUserID = this.userId;
    this.getNotaryDetails(notaryUserID);
  }

  getNotaryDetails(notaryUserID: string) {
    this.sub.add(this.valuationRequestService.getNotaryDetails(notaryUserID)
      .subscribe((res) => {
        this.notaryDetails = res.data;
        console.log(this.notaryDetails);
      }));
  }

  // backToList() {
  //   this.sub.add(this.valuationRequestService.findByValuationRequestId(Number(this.valuationRequestId))
  //     .subscribe((res) => {
  //       this.valuationRequest = res.data;
  //
  //       console.log(this.valuationRequest.valuationRequestStatus + '................');
  //
  //       if (this.valuationRequest.valuationRequestStatus === 'PENDING') {
  //         this.router.navigate(['/view-assessment/pending-requests']);
  //       }
  //       if (this.valuationRequest.valuationRequestStatus === 'COMPLETED') {
  //         this.router.navigate(['/view-assessment/completed-requests']);
  //       }
  //       if (this.valuationRequest.valuationRequestStatus === 'REJECTED') {
  //         this.router.navigate(['/view-assessment/rejected-requests']);
  //       }
  //     }));
  // }
}
