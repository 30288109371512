import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProvinceService } from 'src/app/service/province/province.service';
import { Province } from 'src/app/model/province';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-update-province',
  templateUrl: './update-province.component.html',
  styleUrls: ['./update-province.component.scss']
})
export class UpdateProvinceComponent implements OnInit, OnDestroy {

  updateProvinceForm: FormGroup;
  private sub = new SubSink();
  description: string;
  code: String

  @Output() provinceUpdated = new EventEmitter<boolean>();


  constructor(private formBuilder: FormBuilder, private provinceService: ProvinceService, private notifier: NotifierService) { }

  ngOnInit() {

    this.updateProvinceForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      code: ['', [Validators.required]],
      description: ['', [Validators.required]]
    })
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  getId() {
    return this.updateProvinceForm.get('id').value;
  }
  setIdAndDescription(id: number, code: string, description: string) {
    this.updateProvinceForm.get('id').setValue(id);
    this.updateProvinceForm.get('code').setValue(code);
    this.updateProvinceForm.get('description').setValue(description);

  }

  getDescription() {
    return this.updateProvinceForm.get('description').value;
  }
  getCode() {
    return this.updateProvinceForm.get('code').value;
  }

  updateTheView(updated: boolean) {
    this.provinceUpdated.emit(updated);
  }

  updateProvince() {
    if (this.updateProvinceForm.valid) {
      const province = new Province();
      province.description = this.getDescription();
      province.code = this.getCode();
      this.sub.add(this.provinceService.updateProvince(this.getId(), province)
        .subscribe(response => {

          this.updateTheView(true);
          this.notifier.notify('success', (response as any).message);
        }));
    }

  }
}







