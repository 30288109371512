import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { RefundOpinionServiceService } from "src/app/service/RefundOpinionService/refund-opinion-service.service";
import { RefundCalculation } from "src/app/model/refundCalculation";
import { RefundNoticeLetter } from "src/app/model/refundNoticeLetter";
import { refundRequestUpdate } from "src/app/model/refundRequestUpdate";
import Swal from "sweetalert2";
import { Constant } from "src/app/util/constant";
import { ValuationRequestCount } from "src/app/model/valuation_request_count";
import { UserService } from "../../../../../../../../service/user/user.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: "app-refund-calculation",
  templateUrl: "./refund-calculation.component.html",
  styleUrls: ["./refund-calculation.component.scss"],
})
export class RefundCalculationComponent implements OnInit {

  createActualPaidAmountForm: FormGroup;

  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();

  public refundCalculationModel = new RefundCalculation();
  public refundNoticeLetterModel = new RefundNoticeLetter();

  fileNumber: string;
  refundNumber: string;
  refundRequestUpdate = new refundRequestUpdate("", "", "", "", "", "");
  public model = new ValuationRequestCount("", "", "");

  noticeLetterContent: string;
  selectedNoticeLetter: string;
  noticeLetters: RefundNoticeLetter[];

  showNoticeLetter: boolean;
  stampDutyAmount: any;
  refundRequestAmount: any;
  penaltyAmount: any;
  additionalStampDuty: any;
  totalRefundAmount: any;
  actualPaidAmount: any;
  roundOffValue: any;
  collectionNumber: any;
  installmentCount: any;
  remark: any;
  taxOfficerId: any;
  clouse65selected: boolean;
  clouse101selected: boolean;
  userdata: any = [];
  hide: boolean;
  getUserDetails: any;
  userrolecode: string;

  showSaveButton: boolean = true;
  showLetterButton: boolean = true;
  isReadOnly: boolean = false;
  public userlist: any = [];
  disalbeTaxOfficerAssign: boolean = false;
  //refundCalculationForm: FormGroup;
  isTrue: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public modalService: NgbModal,
    private refundOpinionService: RefundOpinionServiceService,
    private router: Router,
    private notifier: NotifierService,
    private userService: UserService
  ) {
    this.showNoticeLetter = false;
    this.clouse65selected = true;
    this.clouse101selected = false;
  }

  ngOnInit() {

    this.createActualPaidAmountForm = this.formBuilder.group({
      ActualPaidAmount: ['', [Validators.required]],
      roundOffValue: ['', [Validators.required]],
      taxOfficerId: ['', [Validators.required]],
      remark: ['',],
      installmentCount: ['',],
      collectionNumber: ['',],
    });


    this.retrieveRefundRequestDetails();
    this.fileNumber = localStorage.getItem("fileNumber");
    this.refundNumber = localStorage.getItem("refundNumber");
    this.listNoticeLetters();
    this.getUsers();
    this.retrieveRefundCalculationDetails();
    this.getTaxOfficerList();
  }


  getTaxOfficerList() {
    this.userService.getUsersByRoleCode(Constant.ROLE_TAX_OFFICER).subscribe(
      (data) => {
        this.userlist = data.data.userList;
        console.log(data.data.userList);
      },
      (error) => {
        this.notifier.notify("error", error);
      }
    );
  }

  openNoticeLetterModal() {
    this.showNoticeLetter = true;
  }

  retrieveRefundRequestDetails() {
    this.refundOpinionService
      .getRefundRequestDetailsByRefundRequestId(
        +localStorage.getItem("refundRequest")
      )
      .subscribe((data) => {
        this.stampDutyAmount = data.data.stampDutyAmount;
        this.refundRequestAmount = data.data.refundRequestAmount;
        this.penaltyAmount = this.refundRequestAmount * 0.05;
        this.totalRefundAmount = this.refundRequestAmount - this.penaltyAmount;
      });
  }

  retrieveRefundCalculationDetails() {
    this.refundOpinionService
      .getRefundCalculationdetails(+localStorage.getItem("refundRequest"))
      .subscribe(
        (data) => {
          if (data.data.calculation == null) {
            this.showSaveButton = true;
          } else {
            this.actualPaidAmount = data.data.calculation.actualPaidAmount;
            this.roundOffValue = data.data.calculation.roundOffValue;
            this.installmentCount = data.data.calculation.installmentCount;
            this.remark = data.data.calculation.remark;
            this.collectionNumber = data.data.calculation.collectionNumber;
            this.isReadOnly = true;
            this.showSaveButton = false;
            this.taxOfficerId = data.data.taxOfficerId;
            if (data.data.taxOfficerId) {
              this.disalbeTaxOfficerAssign = true;
            }
          }
        },
        (err) => console.log(err)
      );
  }

  getUsers() {
    this.userdata = JSON.parse(atob(sessionStorage.getItem("userinfo")));
    this.model.userId = this.userdata.id;
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.userrolecode = Constant.ROLE_SUPER_ADMIN;
        this.hide = true;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.userrolecode = Constant.ROLE_COMMISSIONER;
        this.hide = true;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.userrolecode = Constant.ROLE_DEPUTY_COMMISSIONER;
        this.hide = true;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.userrolecode = Constant.ROLE_ACCESSOR;
        this.hide = true;
        break;
      } else if (role.code === Constant.ROLE_Collection_Branch) {
        this.userrolecode = Constant.ROLE_Collection_Branch;
        this.hide = false;
        this.createActualPaidAmountForm.disable();
        this.showLetterButton = false;
        this.showSaveButton = false;
        this.isTrue=true;
        break;
      } else if (role.code === Constant.ROLE_TAX_OFFICER) {
        this.userrolecode = Constant.ROLE_TAX_OFFICER;
        this.hide = false;
        break;
      }
    }
  }

  get f() { return this.createActualPaidAmountForm.controls; }

  saveRefundCalculation() {
    if(this.createActualPaidAmountForm.valid){
      this.saveCalculation();
     // this.sendToTaxOfficer();
    }else{
      this.f.roundOffValue.markAsTouched();
      this.f.ActualPaidAmount.markAsTouched();
      this.f.taxOfficerId.markAsTouched();
    }
  }

  sendToCollectionBranch() {
    this.refundRequestUpdate.refundId = localStorage.getItem("refundRequest");
    // this.userdata;

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    if(this.createActualPaidAmountForm.valid){
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You will be send this to Collection Branch!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Send it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          this.refundOpinionService
            .updatecollectionBranch(this.refundRequestUpdate)
            .subscribe((data) => {
              console.log(data);
            });
          swalWithBootstrapButtons.fire(
            "Sent!",
            "Your file has been Sent.",
            "success"
          );
          this.router.navigate(["/main-dashboard-internal-user"]);
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your file is has not been sent :)",
            "error"
          );
        }
      });
    }else{
      this.f.roundOffValue.markAsTouched();
      this.f.ActualPaidAmount.markAsTouched();
      this.f.taxOfficerId.markAsTouched();
    }
  }

  validateForm() {
    console.log(this.isTrue)
    if (
      this.roundOffValue &&
      this.actualPaidAmount &&
      this.roundOffValue > 0 &&
      this.actualPaidAmount > 0 &&
      this.taxOfficerId
      
    ) {
      return true;
    }else{
      this.notifier.notify("error", "Please enter values for mandatory fields!");
      return false;
    }
  }

  saveCalculation() {
    if (this.validateForm()) {
      this.refundCalculationModel.refundRequestId =
        localStorage.getItem("refundRequest");
      this.refundCalculationModel.stampDutyAmount = this.stampDutyAmount;
      this.refundCalculationModel.refundRequestAmount =
        this.refundRequestAmount;
      this.refundCalculationModel.actualPaidAmount = this.actualPaidAmount;
      this.refundCalculationModel.penaltyAmount = this.penaltyAmount;
      this.refundCalculationModel.additionalStampDuty =
        this.additionalStampDuty;
      this.refundCalculationModel.totalRefundAmount = this.totalRefundAmount;
      this.refundCalculationModel.roundOffValue = this.roundOffValue;
      this.refundCalculationModel.collectionNumber = this.collectionNumber;
      this.refundCalculationModel.installmentCount = this.installmentCount;
      this.refundCalculationModel.remark = this.remark;
      this.refundCalculationModel.taxOfficerId = this.taxOfficerId;

      this.refundOpinionService
        .saveRefundCalculation(this.refundCalculationModel)
        .subscribe((data) => {});
    }
  }

  saveNoticeLetter() {
    this.refundNoticeLetterModel.refundRequestId =
      localStorage.getItem("refundRequest");
    this.refundNoticeLetterModel.noticeLetterContent = this.noticeLetterContent;

    this.refundOpinionService
      .saveRefundNoticeLetter(this.refundNoticeLetterModel)
      .subscribe((data) => {
        console.log(data);
        this.noticeLetterContent = "";
        this.noticeLetters.push(this.refundNoticeLetterModel);
        this.notifier.notify("success","Notice letter is saved successfully");
      });
  }

  sendToTaxOfficer() {
    this.refundRequestUpdate.refundId = localStorage.getItem("refundRequest");
    this.refundOpinionService
      .sendToTaxOfficer(this.refundRequestUpdate)
      .subscribe((data) => {
        this.notifier.notify("success", "Successfully sent to Tax Officer");
        this.router.navigate(["/refund-internal-user-dashboard"]);
      });
  }

  openNoticeLetter(noticeLetter) {
    this.selectedNoticeLetter = noticeLetter.noticeLetterContent;
  }

  listNoticeLetters() {
    this.refundOpinionService
      .getRefundNoticelettersByRefundRequestId(
        +localStorage.getItem("refundRequest")
      )
      .subscribe((data) => {
        this.noticeLetters = data.data;
        console.log(data);
      });
  }

  changeClouse() {
    if (this.clouse65selected == true) {
      this.clouse65selected = false;
      this.clouse101selected = true;
    } else {
      this.clouse101selected = false;
      this.clouse65selected = true;
    }
  }

  public redirectComponent(): void {
  if(this.createActualPaidAmountForm.valid || this.isTrue===true){
    if (this.showSaveButton) {
      this.saveCalculation();
      this.notifier.notify("success", "Tax Officer is assigned successfully");
    }
    this.onPageCountChanged.emit(7);
    
  }
  
  this.f.roundOffValue.markAsTouched();
  this.f.ActualPaidAmount.markAsTouched();
  this.f.taxOfficerId.markAsTouched();
}

  public redirectPreviousComponent(): void {
    this.onPageCountChanged.emit(4);
  }

  setAccountantDocumentArray(event) {
    // This empty function is a temporary solution for getting the Production build
  }
}
