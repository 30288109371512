import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UpdateLocalAuthorityComponent } from '../update-local-authority/update-local-authority.component';
import { DeleteLocalAuthorityComponent } from '../delete-local-authority/delete-local-authority.component';
import { LocalAuthorityService } from 'src/app/service/local-authority/local-authority.service';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/service/data.service';
import {ILocalAuthrority} from '../../../../../../model/interfaces/local-authority';

@Component({
  selector: 'app-view-local-authority',
  templateUrl: './view-local-authority.component.html',
  styleUrls: ['./view-local-authority.component.scss']
})
export class ViewLocalAuthorityComponent implements OnInit, OnDestroy {

  private sub = new SubSink();
  @ViewChild(UpdateLocalAuthorityComponent, { static: false })
  private updateLocalAuthorityComponent: UpdateLocalAuthorityComponent;
  @ViewChild(DeleteLocalAuthorityComponent, { static: false })
  private deleteLocalAuthorityComponent: DeleteLocalAuthorityComponent;
  currentPage: any;
  totalItems: any;


  constructor(private localAuthorityService: LocalAuthorityService, private notifier: NotifierService, private dataService: DataService) { }

  pageNo: number;
  localAuthorites: ILocalAuthrority[];

  // pagination
  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];

  ngOnInit() {
    this.selectedPageNo = 0;
    this.limit = 10;

    this.localAuthorityService.getAll().subscribe(responce => {
      const res = responce.data.length;
      this.pageNo = Math.round(res / 10);

      // console.log(Math.round(pages));
    });


    this.sub.add(this.dataService.updateDataTableLocalAuthority
      .subscribe(update => this.onAdded(update)));

    this.sub.add(this.localAuthorityService.getLocalAuthorities(0, 10)
      .subscribe(
        (res) => {
          this.localAuthorites = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }
      ));
    }
    ngOnDestroy() {
      this.sub.unsubscribe();
    }

    triggerUpdate(id: number, localAuthorityNo: number , localAuthoritiesName: string) {
      this.updateLocalAuthorityComponent.setIdAndDescription(id, localAuthorityNo, localAuthoritiesName);
    }

    triggerDelete(id: number) {
      this.deleteLocalAuthorityComponent.setLocalAuthorityId(id);
    }

    onUpdated(updatedSuccessfully: boolean) {
      if (updatedSuccessfully) {
        this.updateTheTable();
      }
    }

    onDeleted(deletedSuccessfully: boolean) {
      if (deletedSuccessfully) {
        this.updateTheTable();
      }
    }
    onAdded(addedSuccessfully: boolean) {
      if (addedSuccessfully) {
        this.updateTheTable();
      }
    }

    pageable(i: number) {
      this.localAuthorityService.getLocalAuthorities((i-1), 10).subscribe(responce => {
        this.localAuthorites = (responce as any).data.content;
        this.currentPage = (responce as any).data.number+1;
        this.totalItems = (responce as any).data.totalElements;
      });
    }

      private updateTheTable() {
        this.sub.add(this.localAuthorityService.getLocalAuthorities(0, 10)
        .subscribe(
          (res) => {
            this.localAuthorites =  (res as any).data.content;
            this.currentPage = (res as any).data.number+1;
            this.totalItems = (res as any).data.totalElements;
          }));
      }
  searchLocalAuthority(keyword: string) {
    this.selectedPageNo = 0;
    this.sub.add(this.localAuthorityService.searchLocalAuthority (keyword, this.selectedPageNo, this.limit)
      .subscribe((res) => {
        this.localAuthorites =  (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      }));
  }
  searchLocalAuthorityById(keyword: number) {
    this.selectedPageNo = 0;
    this.sub.add(this.localAuthorityService.searchLocalAuthorityById(keyword)
      .subscribe((res) => {
        this.localAuthorites = [];
        this.localAuthorites[0]=  (res as any).data;
        this.currentPage = 1;
        this.totalItems = 1;
      }));
  }

  searchLocalAuthorityByIdAndNo(keyword: number) {
    this.selectedPageNo = 0;
    this.sub.add(this.localAuthorityService.searchLocalAuthorityByIdAndNo(keyword, this.selectedPageNo, this.limit)
      .subscribe((res) => {
        this.localAuthorites =  (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      }));
  }
  
  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }
}
