export class rimUpdateDCommissionerIdAndStatus{

  rimId:number;
  status:string;

  constructor(

    rimId:number,
    status:string,
  ){

    this.rimId = rimId;
    this.status = status;
  }
}
