import { EventEmitter, Output } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Route, Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { assignTaxOfficer } from "src/app/model/rimAsignTaxOfficer";
import { DistrictService } from "src/app/service/district/district.service";
import { LocalAuthorityService } from "src/app/service/local-authority/local-authority.service";
import { ProvinceService } from "src/app/service/province/province.service";
import { RimApplicationService } from "src/app/service/reimbursement/rim-application.service";
import { UserService } from "src/app/service/user/user.service";
import { Constant } from "src/app/util/constant";

@Component({
  selector: "app-rim-assign-tax-officer",
  templateUrl: "./rim-assign-tax-officer.component.html",
  styleUrls: ["./rim-assign-tax-officer.component.scss"],
})
export class RimAssignTaxOfficerComponent implements OnInit {
  @Output() onPageTypeChanged: EventEmitter<any> = new EventEmitter<any>();

  public assignTaxOfficer = new assignTaxOfficer(0, 0, 0, "", "");
  reimbursementFileNumber: any;
  provincelist: any;
  districtlist: any;
  provinceselectedstatus: boolean;
  getLocalAuthorityNo: any;

  assignedprovinceCode: any;
  assigneddistrictCode: any;
  assignedLocalAuthority: any;
  assignedTaxOfficer: any;
  assignedRemark:any;

  assignTaxOfficer1:any;
  assignLocalAuthority1:any;
  assigndistrict11:any;
  assignprovince1:any;

  getDistrictCode: any;

  selectedProvinceCode: any;
  selectedDistrictCode: any;
  filterdLocalAuthorties: any = [];
  localAuthorities: any = [];
  filteredcommissionerlist: any;
  filteredaccessorlist: any;
  userlist: any;
  taxOfficerList: any;
  assignTaxOfficerForm: FormGroup;

  constructor(
    public rimService: RimApplicationService,
    public provinceService: ProvinceService,
    public districtService: DistrictService,
    public userService: UserService,
    private notifier: NotifierService,
    private formBuilder: FormBuilder,
    private localAuthorityService: LocalAuthorityService,
    private router: Router
  ) {
    this.reimbursementFileNumber = localStorage.getItem("rimFileNumberFormat");
    this.assignedprovinceCode = 25;
  }

  ngOnInit() {


    
    this.loadProvinceList();
    this.retrieveUserList();
    this.getLocalAuthority();
    this._prepare();

    this.assignTaxOfficerForm = this.formBuilder.group({
      province: ["", [Validators.required]],
      district: ["", [Validators.required]],
      localAuthority: ["", [Validators.required]],
      taxOfficer: ["", [Validators.required]],
      remark: ["",]
    });

    this.getFormData();
    
    this.getLocalAuthorityNo = JSON.parse(
      atob(localStorage.getItem("selectedLocalAuthority"))
    );
    
    this.assigneddistrictCode = this.getLocalAuthorityNo.district_id;
    this.assignedLocalAuthority = this.getLocalAuthorityNo.id; 
     
  }

  _prepare() {
    this.selectedProvince(this.assignedprovinceCode);
    // this.selectedDistrict(this.assigneddistrictCode);
  }

  retrieveUserList() {
    this.userService.retrieveUserList().subscribe((data) => {
      this.userlist = data.data;

      this.taxOfficerList = this.userlist.filter(
        (i) => i["roles"][0] !== undefined && i["roles"][0]["code"] === Constant.ROLE_TAX_OFFICER
      );
    });
  }

  get f() {
    return this.assignTaxOfficerForm.controls;
  }
  
  loadProvinceList() {
    this.provinceService.getAll().subscribe((data) => {
      this.provincelist = data.data;
    });
  }
  
  selectedProvince(event) {
    this.districtService.searchDistrictDetails(event).subscribe((data) => {
      this.provinceselectedstatus = false;
      this.districtlist = data.data;
    });
  }
  selected(event){
    sessionStorage.setItem('province', JSON.stringify(event));
  }

  selectedDistrict(event) {
    sessionStorage.setItem('district', JSON.stringify(event));
    // this.localAuthorityService.getLocalAuthoritiesByDistrictId(event).subscribe(data => {
    //   this.filterdLocalAuthorties = data.data;
    // })
  }

  getLocalAuthority() {
    this.localAuthorityService.getAll().subscribe((data) => {
      this.localAuthorities = data.data;
    });
  }

  selectedLocalAuthority(event) {
    sessionStorage.setItem('localAuthority', JSON.stringify(event));
  }

  selectedTaxOfficer(event){
    sessionStorage.setItem('taxOfficer', JSON.stringify(event));
  }
  
  selectedRemark(event){
    sessionStorage.setItem('remark', JSON.stringify(event));
  }


  save() {

    if (this.assignTaxOfficerForm.valid) {

      if(JSON.parse(sessionStorage.getItem('links')) !== null){
        if(JSON.parse(sessionStorage.getItem('docLinks')) !== null){

          this.assignTaxOfficer.district =this.assigneddistrictCode;
          //this.assignTaxOfficerForm.controls["district"].value;
        this.assignTaxOfficer.province =this.assignedprovinceCode;
          //this.assignTaxOfficerForm.controls["province"].value;
        this.assignTaxOfficer.localAuthority =this.assignedLocalAuthority;
          //this.assignTaxOfficerForm.controls["localAuthority"].value;
        this.assignTaxOfficer.taxOfficer =this.assignTaxOfficerForm.controls["taxOfficer"].value;
          //this.assignedTaxOfficer;
        this.assignTaxOfficer.remark =this.assignTaxOfficerForm.controls["remark"].value;
          //this.assignedRemark;
        let applicationId = parseInt(sessionStorage.getItem("rimId"));
        
        this.rimService
          .asignTaxOfficer(applicationId, this.assignTaxOfficer)
          .subscribe((data) => {
            console.log(data.data);
            sessionStorage.removeItem('links');
            sessionStorage.removeItem('docLinks');
            this.router.navigate(["/main-dashboard-internal-user"]);
            localStorage.removeItem("rimFileNumberFormat");
            localStorage.removeItem("selectedLocalAuthority");

            sessionStorage.removeItem('province');
            sessionStorage.removeItem('district');
            sessionStorage.removeItem('localAuthority');
            sessionStorage.removeItem('taxOfficer');
            sessionStorage.removeItem('remark');
          });
        this.notifier.notify("success", "Tax Officer Assign Successfully");

        }else{
          this.notifier.notify("error", "Please Upload Required fields In Documents");
        }

      }else{
        this.notifier.notify("error", "Please Fill Required Fields In Application");
      }

    } else {
      this.notifier.notify("error", "Please Fill Required fields");
    }
  }

  public redirectPreviousComponent(): void {
    this.onPageTypeChanged.emit("documents");
  }


  getFormData(){
    this.assignprovince1 = JSON.parse(sessionStorage.getItem('province'));
    this.assigndistrict11 = JSON.parse(sessionStorage.getItem('district'));
    console.log(this.assigndistrict11,"district")
    this.assignLocalAuthority1 = JSON.parse(sessionStorage.getItem('localAuthority'));
    this.assignTaxOfficer1 = JSON.parse(sessionStorage.getItem('taxOfficer'));
    this.assignedRemark = JSON.parse(sessionStorage.getItem('remark'));
  }
}
