import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FileService} from '../../../../../../../service/file/file.service';
import {DomSanitizer} from '@angular/platform-browser';
import {IGrantee} from '../../../../../../../model/interfaces/grantee';
import {IGranter} from '../../../../../../../model/interfaces/granter';
import {ValuationRequest} from '../../../../../../../model/valuation-request';
import {SubSink} from 'subsink';
import {ValuationRequestService} from '../../../../../../../service/valuation-request/valuation-request.service';
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-view-other-internal',
  templateUrl: './view-other-internal.component.html',
  styleUrls: ['./view-other-internal.component.scss']
})
export class ViewOtherInternalComponent implements OnInit {

  @Input()
  grantees: IGrantee [];

  @Input()
  granters: IGranter [];

  selectFileSm = 0;
  valuationRequestId: any;
  exchangeValuationRequestId: any;
  previewFileData: any;
  type: any;
  isExchange: boolean;
  valuationRequest: ValuationRequest;
  noDocs = false;
  dataDone = false;
  private sub = new SubSink();

  constructor(private fileService: FileService, private route: ActivatedRoute,
              public sanitizer: DomSanitizer, private valuationRequestService: ValuationRequestService,
              private  router: Router,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.valuationRequestId = +this.route.snapshot.paramMap.get('id');
    this.getValuationRequest(this.valuationRequestId);
  }

  previewFile(type) {
    this.spinner.show();
    this.dataDone=false;
    this.noDocs=false;
    this.fileService.loadFileById(this.valuationRequestId, type).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs=false;
        this.dataDone=true;

        setTimeout(()=>{
          this.spinner.hide();
        }, 2000);

      }else {
        this.spinner.hide();
        this.dataDone=true;
        this.noDocs=true;
      }

    }, () => {
      this.dataDone=true;
      this.noDocs=true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }

  getValuationRequest(valuationRequestId: number) {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(valuationRequestId)
      .subscribe((res) => {
        this.valuationRequest = res.data;

        if (this.valuationRequest.deedNature === 'Exchange') {
          this.isExchange = true;
          this.exchangeValuationRequestId = this.valuationRequest.exchangeId;
        } else {
          this.isExchange = false;
        }
      }));
  }

  previewParty2File(type) {
    this.spinner.show();
    this.dataDone=false;
    this.noDocs=false;
    this.fileService.loadFileById(this.exchangeValuationRequestId, type).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.dataDone=true;
        this.noDocs=false;
        setTimeout(()=>{
          this.spinner.hide();
        }, 2000);

      }else {
        this.spinner.hide();
        this.dataDone=true;
        this.noDocs=true;
      }

    }, () => {
      this.spinner.hide();
      this.dataDone=true;
      this.noDocs=true;
    });
  }


}
