import {Company} from './company';

export class Grantee {
  nameWithInitials: string;
  firstName: string;
  middleName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  province: string;
  postalCode: string;
  nic: string;
  contactNo: string;
  email: string;
  valuationRequest: string;
  granteeCompany: boolean;
  isGranteeIndividual: boolean;
  company: Company;
  id : String;
}
