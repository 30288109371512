import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { InspectionFacilityTypeService } from 'src/app/service/inspection-master-data-service/inspection-facility-type/inspection-facility-type.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inspection-facility-type',
  templateUrl: './inspection-facility-type.component.html',
  styleUrls: ['./inspection-facility-type.component.scss']
})
export class InspectionFacilityTypeComponent implements OnInit,OnDestroy {

  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");

  createFacilityTypeForm: FormGroup;
  updateFacilityTypeForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  propertyTypeList: any =[];
  activationButton: boolean;
  public createFacilityTypeModel = new InspectionCommonDataModel(null,'','','','');
  public updateFacilityTypeModel = new InspectionCommonDataModel(null,'','','','');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;
  FacilityTypes: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionFacilityTypeService: InspectionFacilityTypeService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createFacilityTypeForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['', [Validators.pattern(this.NoWhitespaceRegExp)]],
    });
    this.updateFacilityTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['', [Validators.pattern(this.NoWhitespaceRegExp)]],
    });

    // this.loadPropertyType();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }



  //create Facility Type - start

  get f() { return this.createFacilityTypeForm.controls; }

  setFacilityTypeModelToSave(){
    this.createFacilityTypeModel.name = this.createFacilityTypeForm.get('name').value;
    this.createFacilityTypeModel.description = this.createFacilityTypeForm.get('description').value;
    this.createFacilityTypeModel.code = this.createFacilityTypeForm.get('code').value;
    this.createFacilityTypeModel.dataStatus = "ACTIVE";
  }

    //save Facility Type model
  saveFacilityType(){

    this.inspectionFacilityTypeService.getFacilityType(0,this.totalItems).subscribe(res=>{
      const FacilityTypesArray = (res as any).data.content;
      if (FacilityTypesArray.map((FacilityT)=>String(FacilityT.name.replace(/\s/g, ""))).includes(String(this.createFacilityTypeForm.get('name').value.replace(/\s/g, "")))){
        this.notifier.notify('error', 'Facility type name "'+this.createFacilityTypeForm.get('name').value +'" is exist');
        return;
      }
      else{
        if(this.createFacilityTypeForm.valid){
          this.setFacilityTypeModelToSave();
          this.sub.add(
            this.inspectionFacilityTypeService.createFacilityType(this.createFacilityTypeModel).subscribe(data=>{
              this.notifier.notify('success', (data as any).message);
              this.updateTheTable();
              this.reset();
            })
            );
        }else{
          this.f.name.markAsTouched();
          this.f.code.markAsTouched();
        }

  }}) 
  }

  reset(){
    this.createFacilityTypeForm.reset()
  }
  //create Facility Type - end

  //update Facility Type - start

    //get update controllers
  get f1() { return this.updateFacilityTypeForm.controls; }

    //set data To Facility Type Type model
  setFacilityType(facility:any){
    this.updateFacilityTypeForm.get('id').setValue(facility.id);
    this.updateFacilityTypeForm.get('name').setValue(facility.name);
    this.updateFacilityTypeForm.get('description').setValue(facility.description);
    this.updateFacilityTypeForm.get('code').setValue(facility.code);

    if(facility.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateFacilityTypeModel.id = this.updateFacilityTypeForm.get('id').value;
    this.updateFacilityTypeModel.name = this.updateFacilityTypeForm.get('name').value;
    this.updateFacilityTypeModel.description = this.updateFacilityTypeForm.get('description').value;
    this.updateFacilityTypeModel.code = this.updateFacilityTypeForm.get('code').value;

    if(this.activationButton == true){
      this.updateFacilityTypeModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateFacilityTypeModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateFacilityType(){

    this.inspectionFacilityTypeService.getFacilityType(0,this.totalItems).subscribe(res=>{
      const FacilityTypesArray = (res as any).data.content;
      if (FacilityTypesArray.map((FacilityT)=>String(FacilityT.name.replace(/\s/g, ""))).includes(String(this.updateFacilityTypeForm.get('name').value.replace(/\s/g, "")))){
        this.notifier.notify('error', 'Facility type name "'+this.updateFacilityTypeForm.get('name').value +'" is exist');
        return;
      }
      else{
        if(this.updateFacilityTypeForm.valid){
          this.setDataToModel();
          this.sub.add(this.inspectionFacilityTypeService.updateFacilityType(this.updateFacilityTypeModel.id, this.updateFacilityTypeModel).subscribe(data=>{
            this.notifier.notify('success', (data as any).message);
            this.updateTheTable();
          }))
        }else{
          this.f1.name.markAsTouched();
          this.f1.code.markAsTouched();
    
        }
  }}) 
  }

  //update Facility Type - end

//search and view table start
  pageable(i: number) {
    this.inspectionFacilityTypeService.getFacilityType((i-1),10).subscribe(res=>{
      this.FacilityTypes = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionFacilityTypeService.getFacilityType(0, 10)
      .subscribe(
        (res) => {
          this.FacilityTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search Facility Type Type
    searchFacilityType(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionFacilityTypeService.searchFacilityType(this.keyword,0,10).subscribe(res=>{
          this.FacilityTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionFacilityTypeService.deleteFacilityType(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end

}
