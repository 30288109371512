export class ValuationRequestCount {
  userId: string;
  valuationStatus: string;
  userRole: string;

  constructor(
    userId: string,
    valuationStatus: string,
    userRole: string
  ) {
    this.userId = userId;
    this.valuationStatus = valuationStatus;
    this.userRole = userRole;
  }
}
