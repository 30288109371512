import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubSink} from 'subsink';
import {NotifierService} from 'angular-notifier';
import {PropertyUsageSubtype} from '../../../../../../../model/propertyUsageSubtype';
import {PropertyUsageSubtypeService} from '../../../../../../../service/property-usage-subtype/property-usage-subtype.service';
import {PropertyUsageService} from '../../../../../../../service/property-usage/property-usage.service';

@Component({
  selector: 'app-update-property-usage-subtype',
  templateUrl: './update-property-usage-subtype.component.html',
  styleUrls: ['./update-property-usage-subtype.component.scss']
})
export class UpdatePropertyUsageSubtypeComponent implements OnInit, OnDestroy {
  updatePropertyUsageSubtypeForm: FormGroup;
  description: string;
  private sub = new SubSink();
  @Output() propertyUsageSubtypeUpdated = new EventEmitter<boolean>();
  propertyUsages = [];

  constructor(private propertyUsageSubtypeService: PropertyUsageSubtypeService,
              private propertyUsageService: PropertyUsageService,
              private formBuilder: FormBuilder,
              private notifier: NotifierService ) { }

  ngOnInit() {
    this.sub.add(this.propertyUsageService.getAll().subscribe(
      (res) => {
        this.propertyUsages =  (res as any).data;
      }));

    this.updatePropertyUsageSubtypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required]],
      // usage: ['', [Validators.required]],
      propertyUsage: ['select usage',[Validators.required]],
      description: ['', [Validators.required]]
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updatePropertyUsageSubtype() {
    if (this.updatePropertyUsageSubtypeForm.valid) {
      const propertyUsageSubtype = new PropertyUsageSubtype();
      propertyUsageSubtype.description = this.getDescription();
      propertyUsageSubtype.name = this.getName();

      this.sub.add(this.propertyUsageSubtypeService.updatePropertyUsageSubtype(this.getId(), propertyUsageSubtype)
        .subscribe(response => {
          this.updateTheView(true);
          this.notifier.notify( 'success', (response as any).message);
        }));
    }
  }

  getId() {
    return this.updatePropertyUsageSubtypeForm.get('id').value;
  }

  setIdAndDescription(property:any) {
    this.updatePropertyUsageSubtypeForm.get('id').setValue(property.id);
    this.updatePropertyUsageSubtypeForm.get('description').setValue(property.description);
    this.updatePropertyUsageSubtypeForm.get('name').setValue(property.name);
    this.updatePropertyUsageSubtypeForm.get('propertyUsage').setValue(property.propertyUsage.id);
  }

  getDescription() {
    return this.updatePropertyUsageSubtypeForm.get('description').value;
  }

  getName() {
    return this.updatePropertyUsageSubtypeForm.get('name').value;
  }

  getPropertyUsage() {
    return this.updatePropertyUsageSubtypeForm.get('propertyUsage').value;
  }

  updateTheView(updated: boolean) {
    this.propertyUsageSubtypeUpdated.emit(updated);
  }
}
