import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UpdatePropertyUsageSubtypeComponent} from '../update-property-usage-subtype/update-property-usage-subtype.component';
import {DeletePropertyUsageSubtypeComponent} from '../delete-property-usage-subtype/delete-property-usage-subtype.component';
import {PropertyUsageSubtypeService} from '../../../../../../../service/property-usage-subtype/property-usage-subtype.service';
import {DataService} from '../../../../../../../service/data.service';
import {SubSink} from 'subsink';

@Component({
  selector: 'app-view-property-usage-subtype',
  templateUrl: './view-property-usage-subtype.component.html',
  styleUrls: ['./view-property-usage-subtype.component.scss']
})
export class ViewPropertyUsageSubtypeComponent implements OnInit, OnDestroy {

  @ViewChild(UpdatePropertyUsageSubtypeComponent, {static: false})
  private updatePropertyUsageSubtypeComponent: UpdatePropertyUsageSubtypeComponent;
  @ViewChild(DeletePropertyUsageSubtypeComponent, {static: false})
  private deletePropertyUsageSubtypeComponent: DeletePropertyUsageSubtypeComponent;

  private sub = new SubSink();
  propertyUsageSubtypes = [];

  // pagination
  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];

pageNo: number;
  currentPage: any;
  totalItems: any;
  constructor(private propertyUsageSubtypeService: PropertyUsageSubtypeService,
              private dataService: DataService) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.propertyUsageSubtypeService.getAll().subscribe(
      response => {
        const res = response.data.length;
        this.pageNo = Math.round((res / 10) + 1);

      });

    this.sub.add(this.dataService.updateDataTablePropertyUsageSubType
      .subscribe(update => this.onAdded(update)));

    this.propertyUsageSubtypeService.getPropertyUsageSubtypes(0, 10).subscribe(
      (res) => {
        this.propertyUsageSubtypes =  (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  pageable(i: number) {
    console.log('i' + i);
    console.log(i * 10 + ' - ' + ((i * 10) + 10));
    this.propertyUsageSubtypeService.getPropertyUsageSubtypes((i-1),10).subscribe(responce => {
      this.propertyUsageSubtypes = (responce as any).data.content;
      this.currentPage = (responce as any).data.number+1;
      this.totalItems = (responce as any).data.totalElements;
    });
  }
  counter(i: number) {
    return new Array(i);
  }
  triggerUpdate(property:any) {
    console.log(property)
    this.updatePropertyUsageSubtypeComponent.setIdAndDescription(property);

  }

  triggerDelete(id: number) {
    this.deletePropertyUsageSubtypeComponent.setPropertyUsageSubtypeId(id);
  }

  onUpdated(updatedSuccessfully: boolean) {
    if (updatedSuccessfully) {
      this.updateTheTable();
    }
  }
  onAdded(isAdded: boolean) {
    if (isAdded) {
      this.updateTheTable();
    }
  }

  onDeleted(deletedSuccessfully: boolean) {
    if (deletedSuccessfully) {
      this.updateTheTable();
    }
  }

  // update the table
  private updateTheTable() {
    this.propertyUsageSubtypeService.getPropertyUsageSubtypes(0, 10).subscribe(
      (res) => {
        this.propertyUsageSubtypes =  (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      });
  }

 // search the province table
  searchPropertyUsageSubType(keyword: string) {
    this.selectedPageNo = 0;
    this.sub.add(this.propertyUsageSubtypeService.searchPropertyUsageSubType(keyword, this.selectedPageNo, this.limit)
      .subscribe((res) => {
        this.propertyUsageSubtypes =  (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
        // this.propertyUsageSubtypes = res.data.content;
        // this.paginationButtonCounter(res.data.totalPages);
        // this.numOfPages = res.data.totalPages;
      }));
  }
  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

}
