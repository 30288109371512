import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { LandRegistry } from 'src/app/model/landRegistry';
import { LandRegistryService } from 'src/app/service/land-registry/land-registry.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-land-registry',
  templateUrl: './land-registry.component.html',
  styleUrls: ['./land-registry.component.scss']
})
export class LandRegistryComponent implements OnInit,OnDestroy {


  createLandRegistryForm: FormGroup;
  updateLandRegistryForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  propertyTypeList: any =[];
  activationButton: boolean;
  public createLandRegistryModel = new LandRegistry(null,'',null,'');
  public updateLandRegistryModel = new LandRegistry(null,'',null,'');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;
  LandRegistry: any;


  constructor(
    private formBuilder: FormBuilder,
    private landRegistryService: LandRegistryService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createLandRegistryForm = this.formBuilder.group({
      landRegistry: ['', [Validators.required]],
      landRegistryNo: ['', [Validators.required]],
    });
    this.updateLandRegistryForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      landRegistry: ['', [Validators.required]],
      landRegistryNo: ['', [Validators.required]],
    });

    // this.loadPropertyType();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }



  //create land Registry - start

  get f() { return this.createLandRegistryForm.controls; }

  setLandRegistryModelToSave(){
    this.createLandRegistryModel.landRegistry = this.createLandRegistryForm.get('landRegistry').value;
    this.createLandRegistryModel.landRegistryNo = this.createLandRegistryForm.get('landRegistryNo').value;
    this.createLandRegistryModel.dataStatus = "ACTIVE";
  }

    //save land Registry model
  saveLandRegistry(){
    this.setLandRegistryModelToSave();
    this.sub.add(
      this.landRegistryService.createLandRegistry(this.createLandRegistryModel).subscribe(data=>{
        this.notifier.notify('success', (data as any).message);
        this.updateTheTable();
        this.reset();
      })
      );
  }

  reset(){
    this.createLandRegistryForm.reset()
  }
  //create land Registry - end

  //update land Registry - start

    //get update controllers
  get f1() { return this.updateLandRegistryForm.controls; }

    //set data To land Registry model
  setLandRegistry(accomodation:any){
    this.updateLandRegistryForm.get('id').setValue(accomodation.id);
    this.updateLandRegistryForm.get('landRegistry').setValue(accomodation.landRegistry);
    this.updateLandRegistryForm.get('landRegistryNo').setValue(accomodation.landRegistryNo);

    if(accomodation.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateLandRegistryModel.id = this.updateLandRegistryForm.get('id').value;
    this.updateLandRegistryModel.landRegistry = this.updateLandRegistryForm.get('landRegistry').value;
    this.updateLandRegistryModel.landRegistryNo = this.updateLandRegistryForm.get('landRegistryNo').value;

    if(this.activationButton == true){
      this.updateLandRegistryModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateLandRegistryModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateLandRegistry(){
    this.setDataToModel();
    this.sub.add(this.landRegistryService.updateLandRegistry(this.updateLandRegistryModel.id, this.updateLandRegistryModel).subscribe(data=>{
      this.notifier.notify('success', (data as any).message);
      this.updateTheTable();
    }))
  }

  //update land Registry - end

//search and view table start
  pageable(i: number) {
    this.landRegistryService.getLandRegistry((i-1),10).subscribe(res=>{
      this.LandRegistry = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.landRegistryService.getLandRegistry(0, 10)
      .subscribe(
        (res) => {
          this.LandRegistry = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search land Registry Type
    searchLandRegistry(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.landRegistryService.searchLandRegistry(this.keyword,0,10).subscribe(res=>{
          this.LandRegistry = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.landRegistryService.deleteLandRegistry(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end

}
