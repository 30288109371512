import {Component, OnInit, ViewChild} from '@angular/core';
import {ViewPropertyFacilityComponent} from '../property-facility/view-property-facility/view-property-facility.component';
import {ViewPropertyTypeComponent} from './view-property-type/view-property-type.component';

@Component({
  selector: 'app-proprty-type',
  templateUrl: './proprty-type.component.html',
  styleUrls: ['./proprty-type.component.scss']
})
export class ProprtyTypeComponent implements OnInit {

  @ViewChild(ViewPropertyTypeComponent, {static: false})
  private viewPropertyTypeComponent: ViewPropertyTypeComponent;
  keyword: string;

  constructor() { }

  ngOnInit() {
  }

  triggerSearch(event: any) {
    if (this.keyword === '') {
      this.viewPropertyTypeComponent.onUpdated(true);
    } else {
      this.viewPropertyTypeComponent.searchPropertyType(this.keyword);
    }
  }

}
