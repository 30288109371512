import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { SubSink } from "subsink";
import * as XLSX from "xlsx";
import { DatePipe } from "@angular/common";

import { LandRegistryService } from "src/app/service/land-registry/land-registry.service";
import { LocalAuthorityService } from "src/app/service/local-authority/local-authority.service";
import { RimApplicationService } from "src/app/service/reimbursement/rim-application.service";
import { RimForm } from "src/app/model/rim-form";
import { RimApplicationFormExcel } from "src/app/model/rim-application-form-excel";
import { ReimbursementApplicationCreate } from "src/app/model/rim-application-create";
import { NotifierService } from "angular-notifier";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { isArray } from "util";

@Component({
  selector: "app-rim-form",
  templateUrl: "./rim-form.component.html",
  styleUrls: ["./rim-form.component.scss"],
})
export class RimFormComponent implements OnInit {

  
  rimForm: FormGroup;
  rimExcelTableForm:FormGroup;
  rimExcelTableUpdateForm:FormGroup;
  @Output() onPageTypeChanged: EventEmitter<any> = new EventEmitter<any>();

  public reimbursementAppFormModel = new RimForm();
  public reimbursementApplicationCreateModel =
    new ReimbursementApplicationCreate();

  private sub = new SubSink();

  temp = [];
  landRegistries = [];
  localAuthorities = [];
  //landRegistryId: any;
  landRegistryId: any=null;
  landRegistry: string;
  //localAuthorityId: any;
  localAuthorityId:any = null;
  durationFrom: any;
  durationTo: any;
  selectedId:number;
  tempId:number;
  isTrue=false;

  
  bankUpdate:any =null;
  branchUpdate:any =null;
  deedDateUpdate:any =null;
  deedNoUpdate:any =null;
  deedValueUpdate:any =null;
  nameUpdate:any =null;
  natureUpdate:any =null;
  receiptNoUpdate:any =null;
  reimbursementApplicationIdUpdate:any =null;
  stampDutyDateUpdate:any =null;
  stampDutyValueUpdate:any =null;

  showTable = false;
  pageNumber: number = 1;
  totalRecords: number;
  reimbursementFileNumber: string;
  lastPage: Number;

  wbData: [][];
  sheetHeaders: [];
  sheetLength: number;
  uploadedExcel: File;
  file: File;
  arrayBuffer: any;
  filelist: any;
  AdditionalRow = new RimApplicationFormExcel();

  // Fields for Assessor review
  nonPayableDeedList: any = [];
  reviewReimbursementForm: boolean;
  nonPayablePage = 1;
  reviewReimbursementId: any = null;
  toDate:any = null;
  fromDate:any = null;
  maxDate: Date;

  constructor(
    private formBuilder: FormBuilder,
    private landRegistryService: LandRegistryService,
    private localAuthorityService: LocalAuthorityService,
    private reimbursementApplicationService: RimApplicationService,
    private notifier: NotifierService,
    private datePipe: DatePipe
  ) {
    this.reviewReimbursementForm = false;
  }

  ngOnInit() {

    this.rimForm = this.formBuilder.group({
      fromDate: ["", [Validators.required]],
      toDate: ["", [Validators.required]],
      landregistry: ["", [Validators.required]],
      localAuthority: ["", [Validators.required]],

    })
    this.rimExcelTableForm = this.formBuilder.group({
      nName:["", [Validators.required]],
      dNo:["", [Validators.required]],
      dDate:["", [Validators.required]],
      nature:["", [Validators.required]],
      value:["", [Validators.required]],
      bank:["", [Validators.required]],
      branch:["", [Validators.required]],
      rNo:["", [Validators.required]],
      sDate:["", [Validators.required]],
      sValue:["", [Validators.required]],
    })
    this.rimExcelTableUpdateForm = this.formBuilder.group({
      nNameu:["", [Validators.required]],
      dNou:["", [Validators.required]],
      dDateu:["", [Validators.required]],
      natureu:["", [Validators.required]],
      valueu:["", [Validators.required]],
      banku:["", [Validators.required]],
      branchu:["", [Validators.required]],
      rNou:["", [Validators.required]],
      sDateu:["", [Validators.required]],
      sValueu:["", [Validators.required]],
    })

    this.listLandRegistries();
    this.listLocalAuthorities();
   
    setTimeout(
      () =>
        (this.reimbursementFileNumber = localStorage.getItem(
          "rimFileNumberFormat"
        )),
      1000
    );
    setTimeout(
      () => (this.reviewReimbursementId = sessionStorage.getItem("rimId")),
      1000
    );

    // this.reviewReimbursementForm = JSON.parse(atob(sessionStorage.getItem('reviewRimApplication')));

    if (this.reviewReimbursementForm) {
      this.getAllNonPayableTableDataByReimbursementId();
    }

    if (
      this.reviewReimbursementId !== null ||
      this.reviewReimbursementId !== undefined
    ) {
      this.getAllNonPayableTableDataByReimbursementId();
    }

    this.getFormDetailsByReimbursementId();
    this.maxDate = new Date();
  }

  listLandRegistries() {
    this.sub.add(
      this.landRegistryService
        .getAllLandRegistriesOrderByAscending()
        .subscribe((res) => {
          this.landRegistries = (res as any).data;
        })
    );
  }

  listLocalAuthorities() {
    this.sub.add(
      this.localAuthorityService
        .getAllByOrderByLocalAuthorityAsc()
        .subscribe((res) => {
          this.localAuthorities = (res as any).data;
        })
    );
  }

  selectedLandRegistry(event) {
    const selectedLandRegistry = this.landRegistries.filter(
      (i) => i.id === Number(event)
    );
    this.landRegistry = selectedLandRegistry[0].landRegistry;
  }

  selectedAuthority(event) {
    const selectedLocalAuthority = this.localAuthorities.filter(
      (i) => i.id === Number(event)
    );
    localStorage.setItem(
      "selectedLocalAuthority",
      btoa(JSON.stringify(selectedLocalAuthority[0]))
    );
  }

  onfileImport(event: any) {
    this.file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: false });
      var isValidFormat = true;
      // Object.keys(arraylist[0]).map((list) => {
      //   if (
      //     list === "Name" ||
      //     list === "Deed No." ||
      //     list === "Date" ||
      //     list === "Nature" ||
      //     list === "Value(LKR)" ||
      //     list === "Bank" ||
      //     list === "Branch" ||
      //     list === "Receipt No." ||
      //     list === "Date_1" ||
      //     list === "Value(LKR)_1" ||
      //     list === "__EMPTY"
      //   ) {
      //     isValidFormat = true;
      //     this.showTable = true;
      //   } else {
      //     isValidFormat = false;
      //     this.showTable = false;
      //     this.file = null;
      //   }
      // });
      for (let i = 0; Object.keys(arraylist[0]).length > i; i++) {
        if (
          Object.keys(arraylist[0])[i] === "Name" ||
          Object.keys(arraylist[0])[i] === "Deed No." ||
          Object.keys(arraylist[0])[i] === "Date" ||
          Object.keys(arraylist[0])[i] === "Nature" ||
          Object.keys(arraylist[0])[i] === "Value(LKR)" ||
          Object.keys(arraylist[0])[i] === "Bank" ||
          Object.keys(arraylist[0])[i] === "Branch" ||
          Object.keys(arraylist[0])[i] === "Receipt No." ||
          Object.keys(arraylist[0])[i] === "Date_1" ||
          Object.keys(arraylist[0])[i] === "Value(LKR)_1" ||
          Object.keys(arraylist[0])[i] === "__EMPTY"
        ) {
          isValidFormat = true;
          this.showTable = true;
        } else {
          isValidFormat = false;
          this.showTable = false;
          this.file = null;
          break;
        }
      }
      if (isValidFormat) {
        var dataList = new Array();
        for (var i = 0; i < arraylist.length; i++) {
          var row = new RimApplicationFormExcel();
          row.name = arraylist[i]["Name"];
          row.deedNo = arraylist[i]["Deed No."];
          row.deedDate = arraylist[i]["Date"];
          row.nature = arraylist[i]["Nature"];
          row.deedValue = arraylist[i]["Value(LKR)"];
          row.bank = arraylist[i]["Bank"];
          row.branch = arraylist[i]["Branch"];
          row.receiptNo = arraylist[i]["Receipt No."];
          row.stampDutyDate = arraylist[i]["Date_1"];
          row.stampDutyValue = arraylist[i]["Value(LKR)_1"];
          row.reimbursementApplicationId = sessionStorage.getItem("rimId");
          dataList.push(row);
        }
        this.filelist = dataList;
        this.totalRecords = this.filelist.length;
        this.lastPage = Math.ceil(this.totalRecords / 5);
      } else {
        this.notifier.notify("error", "Please Fill Required fields");
      }
    };
  }

  onAddingNewRow() {
    if(this.rimExcelTableForm.valid){
      this.AdditionalRow.reimbursementApplicationId =
      sessionStorage.getItem("rimId");
    this.filelist.push(this.AdditionalRow);
    this.totalRecords = this.filelist.length;
    this.lastPage = Math.ceil(this.totalRecords / 5);
    this.AdditionalRow = new RimApplicationFormExcel();
    }else{
      this.f1.nName.markAsTouched();
      this.f1.dNo.markAsTouched();
      this.f1.dDate.markAsTouched();
      this.f1.nature.markAsTouched();
      this.f1.value.markAsTouched();
      this.f1.bank.markAsTouched();
      this.f1.branch.markAsTouched();
      this.f1.rNo.markAsTouched();
      this.f1.sDate.markAsTouched();
      this.f1.sValue.markAsTouched();
    }
  }

  saveReimbursementForm() {
    this.reimbursementAppFormModel.reimbursementApplicationId =
      sessionStorage.getItem("rimId");
    this.reimbursementAppFormModel.landRegistryId = this.landRegistryId;
    this.reimbursementAppFormModel.landRegistry = this.landRegistry;
    this.reimbursementAppFormModel.localAuthorityId = this.localAuthorityId;
    this.reimbursementAppFormModel.durationFrom = this.durationFrom;
    this.reimbursementAppFormModel.durationTo = this.durationTo;
    this.reimbursementAppFormModel.rimApplicationFormExcelList =
      this.filelist == undefined ? [] : this.filelist;
    this.reimbursementApplicationService
      .saveReimbursementApplicationForm(this.reimbursementAppFormModel)
      .subscribe((data) => {
        this.onPageTypeChanged.emit("documents");
      });
  }

  public changeComponentAndSave(): void {
    if (this.rimForm.valid){
      this.temp.push(this.reimbursementAppFormModel);
      sessionStorage.setItem('links', JSON.stringify(this.temp));

      this.saveReimbursementForm();
      this.toDate=null;
      this.fromDate=null;
    }else{
      this.rimForm.markAllAsTouched();
      this.notifier.notify("error", "Please fill the form data…!");
    }
    
  }

  changeComponent() {
    this.onPageTypeChanged.emit("documents");
  }

  getFormDetailsByReimbursementId() {
    this.reimbursementApplicationService
      .getFormDetailsByReimbursementId(+sessionStorage.getItem("rimId"))
      .subscribe((data) => {
        console.log(data);
        this.durationFrom = data.data.durationFrom;
        this.durationTo = data.data.durationTo;
        this.landRegistryId = data.data.landRegistryId;
        this.localAuthorityId = data.data.localAuthorityId;
      });
  }

  getAllNonPayableTableDataByReimbursementId() {
    this.reimbursementApplicationService
      .getFormExcelFileDataByReimbursementId(+sessionStorage.getItem("rimId"))
      .subscribe((data) => {
        this.nonPayableDeedList = data.data;
        if (this.nonPayableDeedList.length != 0) {
          this.reviewReimbursementForm = true;
        } else {
          this.reviewReimbursementForm = false;
        }
      });
  }

  getSelectedId(id:any){
    //this.loadUpdateForm(id)
    this.selectedId = id;
    this.filelist.splice(this.selectedId,1)
    this.totalRecords = this.filelist.length;
    this.lastPage = Math.ceil(this.totalRecords / 5);
  }

  loadUpdateForm(id:any){
    this.tempId = id;
    this.bankUpdate=this.filelist[id].bank;
    this.branchUpdate=this.filelist[id].branch;
    this.deedDateUpdate=this.filelist[id].deedDate;
    this.deedNoUpdate=this.filelist[id].deedNo;
    this.deedValueUpdate=this.filelist[id].deedValue;
    this.nameUpdate=this.filelist[id].name;
    this.natureUpdate=this.filelist[id].nature;
    this.receiptNoUpdate=this.filelist[id].receiptNo;
    this.stampDutyDateUpdate=this.filelist[id].stampDutyDate;
    this.stampDutyValueUpdate=this.filelist[id].stampDutyValue;
 
  }

  updateExcelTable(){
    
    this.filelist[this.tempId].bank = this.bankUpdate;
    this.filelist[this.tempId].branch=this.branchUpdate;
    this.filelist[this.tempId].deedDate=this.deedDateUpdate;
    this.filelist[this.tempId].deedNo=this.deedNoUpdate;
    this.filelist[this.tempId].deedValue=this.deedValueUpdate;
    this.filelist[this.tempId].name= this.nameUpdate;
    this.filelist[this.tempId].nature=this.natureUpdate;
    this.filelist[this.tempId].receiptNo=this.receiptNoUpdate;
    this.filelist[this.tempId].stampDutyDate=this.stampDutyDateUpdate;
    this.filelist[this.tempId].stampDutyValue=this.stampDutyValueUpdate;
  }


  get f() {
    return this.rimForm.controls;
  }
  get f1() {
    return this.rimExcelTableForm.controls;
  }
  get f2() { return this.rimExcelTableUpdateForm.controls; }

  cancelReimbursementApplication() {
    this.rimForm.reset();
    this.toDate=null;
    this.fromDate=null;
  }

  toDateValidity($event){
    this.toDate =  $event.target.value;
    if(this.fromDate !== null){
      if (!moment($event.target.value).isAfter(moment(this.fromDate))) {
        this.rimForm.controls.toDate.setErrors({invalidDate:true})
      }else{
        this.rimForm.controls.toDate.setErrors(null)
        this.rimForm.controls.fromDate.setErrors(null)   
      }
    }
  }

  fromDateValidity($event){
    this.fromDate =  $event.target.value;
    if (!moment(new Date()).isAfter(moment($event.target.value))) {
      this.rimForm.controls.fromDate.setErrors({futureDate:true})
    }else if (this.toDate !== null && !moment($event.target.value).isAfter(moment(this.toDate))) {
      this.rimForm.controls.fromDate.setErrors(null)
      this.rimForm.controls.toDate.setErrors(null)    
    }else{
      this.rimForm.controls.appointedDate.setErrors(null)
    }
   }
   
}

