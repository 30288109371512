export class getUserDetails{
  public userRole:string;
  public userId:string;

  constructor(
    userRole:string,
    userId:string,
  ){
    this.userId = userId;
    this.userRole = userRole;
  }
}

