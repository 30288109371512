import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PDFDocumentProxy, PDFProgressData } from 'ng2-pdf-viewer';
import { DomSanitizer } from '@angular/platform-browser';

import { FileService } from 'src/app/service/file/file.service';
import { RefundOpinionServiceService } from 'src/app/service/RefundOpinionService/refund-opinion-service.service';
import { ValuationStatus } from 'src/app/model/valuation-status';

@Component({
  selector: 'app-view-documents',
  templateUrl: './view-documents.component.html',
  styleUrls: ['./view-documents.component.scss']
})
export class ViewDocumentsComponent implements OnInit {

  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();

  fileNumber: string;
  refundNumber: string;
  isReleventDocument:boolean;
  verificationStatus: number = 0;
  public modelStatus = new ValuationStatus('', '');

  dataDone = false;
  noDocs = false;
  previewFileData: any;
  withOpinion: boolean;

  constructor(
    private fileService: FileService,
    private spinner: NgxSpinnerService,
    public sanitizer: DomSanitizer,
    private refundService:RefundOpinionServiceService
  ) {

  }

  ngOnInit() {
    this.fileNumber = localStorage.getItem('fileNumber');
    this.refundNumber = localStorage.getItem('refundNumber');
      this.withOpinion = this.checkOpinion();
      this.getRefundDetails();
   }

  checkOpinion():boolean {
    if(sessionStorage.getItem('hasOpinion') == 'true') {
       return true;
    } else if(sessionStorage.getItem('hasOpinion') == 'false') {
       return false;
    }
  }

  onProgress(progressData: PDFProgressData) {
    if (progressData.loaded == progressData.total) {
      this.spinner.hide();
    }
  }

  previewFile(title) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadDocumentsByRefundRequestId(localStorage.getItem('refundRequest'), title).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(() => {
          this.spinner.hide();
        }, 2000);

      } else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }

  public redirectComponent(): void {
    if(sessionStorage.getItem('hasOpinion') == 'true'){
      this.onPageCountChanged.emit(6);

    }else if(sessionStorage.getItem('hasOpinion') == 'false'){
      this.onPageCountChanged.emit(5);
    }
  }

  public redirectPreviousComponent(): void {
      this.onPageCountChanged.emit(3);
  }

  getRefundDetails(){
    this.refundService.getRefundRequestByID(parseInt(localStorage.getItem('refundRequest'))).subscribe(data=>{
      if(data.data.isReleventDocuments == true){
        this.isReleventDocument=true;
      }else if(data.data.isReleventDocuments==false){
        this.isReleventDocument = false;
      }else{
        this.isReleventDocument = true;
      }
    })
  }

  updateVerificationStatus(event){
    if (event.target.checked === true) {
      this.verificationStatus = 1;
    }else{
      this.verificationStatus = 0;
    }
    this.modelStatus.id = localStorage.getItem('refundRequest');
    this.modelStatus.verificationStatus = '1';
    console.log(this.modelStatus,"staus")
    this.refundService.refundDocumentUpdateVerificationStatus(this.modelStatus).subscribe();
  }

}
