import {ApplicationRef, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ForgotPasswordService} from '../../service/forgot_password.service';
import Swal from 'sweetalert2';
import {ConfirmedValidator} from '../validators/confirmed.validator';
import {PasswordStrengthValidator} from '../confirm-validator/password-strength.validators';
import { passwordPolicy } from 'src/app/model/passwordPolicy';
import { RegistrationService } from 'src/app/service/registration.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  password: any;
  re_password: any;
  currentPassword: any;
  resetPasswordForm: FormGroup;
  roleCode:string;
  private sub = new SubSink();
  
  passwordNoOfCharacters = '^.{6,}$';

  passwordPolicyPattern ='^(?=(.*[A-Z]){$7@})(?=(.*[0-9]){$1@})(?=(.*[#$@!%&*?]){$3@})[A-Za-z0-9#$@!%&*?]{$5@,$6@}$';
  public passwordPolicyModel = new passwordPolicy(null,'','','','','','','','','');
  passwordPattern: RegExp;

  constructor(private formBuilder: FormBuilder,
     private router: Router,
     private notifier: NotifierService,
     private forgotPasswordService: ForgotPasswordService,
     private registrationService: RegistrationService,
    ) {
  }

  // resetPasswordForm = new FormGroup({
  //   password: new FormControl(null, Validators.required),
  //   re_password: new FormControl(null, Validators.required),
  //   correntPass: new FormControl(null, Validators.required)
  // },{
  //   validator: ConfirmedValidator('password', 'confirmPassword')
  // });


  get f() {
    return this.resetPasswordForm.controls;
  }

  async ngOnInit() {
    await this.getPasswordPolicy();
    this.roleCode = localStorage.getItem('rc');
    this.passwordPattern = new RegExp(sessionStorage.getItem('passwordPolicy'));
    console.log(this.passwordPattern)
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
      re_password: ['', [Validators.required]],
      currentPassword: ['', [Validators.required]],
    }, {
      validator: ConfirmedValidator('password', 're_password')
    });
  }

  async getPasswordPolicy(){
     this.sub.add(
       this.registrationService.getActivatedPasswordPolicy().subscribe(data=>{
        if(data.data == null){
          this.passwordPolicyModel.minNumaric = "03";
          // this.passwordPolicyModel.maxNumaric = "";
          this.passwordPolicyModel.minSpecialChar = "03";
          // this.passwordPolicyModel.maxSpecialChar = "";
          this.passwordPolicyModel.minLength = "06";
          this.passwordPolicyModel.maxLength = "08";
          this.passwordPolicyModel.minUpperChar = "01";
        }else{
          this.passwordPolicyModel=(data as any).data;
        }
        this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$1@",this.passwordPolicyModel.minNumaric);
        // this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$2@",this.passwordPolicyModel.maxNumaric);
        this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$3@",this.passwordPolicyModel.minSpecialChar);
        // this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$4@",this.passwordPolicyModel.maxSpecialChar);
        this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$5@",this.passwordPolicyModel.minLength);
        this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$6@",this.passwordPolicyModel.maxLength);
        this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$7@",this.passwordPolicyModel.minUpperChar);

        sessionStorage.setItem('passwordPolicy',this.passwordPolicyPattern);
      })
    );
  }

  changePassword() {
    if (this.resetPasswordForm.valid === true) {
      this.forgotPasswordService.changePassword(this.currentPassword, this.password, this.re_password).subscribe(result => {
        if (result) {

          if (result.message === 'Password Changed Successfully') {
            // this.notifier.notify('success', result.message);
            Swal.fire('', result.message, 'success');
            // this.notifier.notify('success', 'PASSWORD RESET EMAIL SENT!');
            this.router.navigate(['/login']);
          } else {
            // console.log(result.message);
            // Swal.fire('', result.message, 'error');
            this.notifier.notify('error', result.message);
          }
        } else {
          this.notifier.notify('', 'Error');
        }
      }, error => {
        this.notifier.notify('', 'Error');
      });
    } else {
      this.validateAllFormFields(this.resetPasswordForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
