import {Component, OnInit, ViewChild} from '@angular/core';
import {ViewPropertyFacilityComponent} from './view-property-facility/view-property-facility.component';

@Component({
  selector: 'app-property-facility',
  templateUrl: './property-facility.component.html',
  styleUrls: ['./property-facility.component.scss']
})
export class PropertyFacilityComponent implements OnInit {

  @ViewChild(ViewPropertyFacilityComponent, {static: false})
  private viewPropertyFacilityComponent: ViewPropertyFacilityComponent;
  keyword: string;

  constructor() { }

  ngOnInit() {
  }

  triggerSearch(event: any) {
    if (this.keyword === '') {
      this.viewPropertyFacilityComponent.onUpdated(true);
    } else {
      this.viewPropertyFacilityComponent.searchPropertyFacility(this.keyword);
    }
  }

}
