import { Component, OnInit, EventEmitter , Output } from '@angular/core';

@Component({
  selector: 'app-reports-sidenav',
  templateUrl: './reports-sidenav.component.html',
  styleUrls: ['./reports-sidenav.component.scss']
})
export class ReportsSidenavComponent implements OnInit {

  @Output() btn_click = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
