import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RefundOpinionServiceService } from 'src/app/service/RefundOpinionService/refund-opinion-service.service';
import { refundOpinionMainDetails } from 'src/app/model/refundOpinionMainDetails';
import { FormGroup, FormControl, FormControlName } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { DatePipe } from '@angular/common';
// import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { Router, RouterLink } from '@angular/router';
import { Constant } from 'src/app/util/constant';
import { refundRequestCommon } from 'src/app/model/refundRequestCommon';
import { NotifierService } from 'angular-notifier';
import { CollectionPayment } from 'src/app/model/CollectionPayment';

@Component({
  selector: 'app-refund-application-opinion-details',
  templateUrl: './refund-application-opinion-details.component.html',
  styleUrls: ['./refund-application-opinion-details.component.scss']
})
export class RefundApplicationOpinionDetailsComponent implements OnInit {

  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();

 fileNo:any = '';
 collectionPayment:CollectionPayment[]=[];


  opinionDetailsForm = new FormGroup({
    fileNumber:new FormControl({value: 'null', disabled: true}),
    deedNumber:new FormControl({value: '', disabled: true}),
    notaryName:new FormControl({value: '', disabled: true}),
    date:new FormControl({value: '', disabled: true}),
    propartyValue:new FormControl({value: '', disabled: true}),
    stampDuty:new FormControl({value: '', disabled: true}),
    bank:new FormControl({value: '', disabled: true}),
    branch:new FormControl({value: '', disabled: true}),
    reciptNo:new FormControl({value: '', disabled: true}),
  })

  opinionDetails:any=[];

  opinionNo = parseInt(localStorage.getItem('opinionNo'));

  public Details = new refundOpinionMainDetails();
  public common = new refundRequestCommon('','','','','','','',null,null,null,null,null,null)

  constructor(
    private refundService:RefundOpinionServiceService,
    public datePipe:DatePipe,
    public snackBar:MatSnackBar,
    private router: Router,
    private notifier: NotifierService
    ) {

     }

  ngOnInit() {
    this.getOpinionDetails();
    this.getOpinionCollectionPayments();
  }

  getOpinionDetails(){
    this.refundService.getOpinionDetails(parseInt(localStorage.getItem('opinionNo'))).subscribe( data =>{
      this.opinionDetails = data.data;

      if(this.opinionDetails){

        this.opinionDetailsForm.controls['fileNumber'].setValue(this.opinionDetails.file_number_format);
        this.opinionDetailsForm.controls['deedNumber'].setValue(this.opinionDetails.id);
        this.opinionDetailsForm.controls['notaryName'].setValue(this.opinionDetails.first_name+' '+this.opinionDetails.middle_name+' '+this.opinionDetails.last_name);
        let date = new Date(this.opinionDetails.created_date);
        let dateFormat = this.datePipe.transform(date, 'yyyy-MMM-dd');
        this.opinionDetailsForm.controls['date'].setValue(dateFormat);
        this.opinionDetailsForm.controls['propartyValue'].setValue(this.opinionDetails.valuation);
        this.opinionDetailsForm.controls['stampDuty'].setValue(this.opinionDetails.stamp_duty);

        var str = this.opinionDetails[0].file_number_format;
        var re =/SD\/OP/gi;
        str =str.replace(re,'R');
        this.fileNo = str;
        localStorage.setItem('fileNo',this.fileNo);


        // this.common.hasOpinion = true;
        // this.common.deedNumber = this.opinionNo;
        // this.refundService.createRefundRequestById(this.common).subscribe(data =>{
        //   console.log(data);
        //   let getRefundid = (data as any).data.id;
        //   localStorage.setItem('refundId', getRefundid);
        // })

      }
      else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please enter valid Opinion Number!',
          allowOutsideClick: false,
          allowEscapeKey:false,
        }).then((result)=>{
          this.router.navigate(['/dashboard']);
        })
      }
    });

  }

  onSubmit(){

     console.log("Saved");
      this.Details.file_number=this.opinionDetailsForm.get('fileNumber').value;
      this.Details.deed_number=this.opinionDetailsForm.get('deedNumber').value;
      this.Details.notary_name=this.opinionDetailsForm.get('notaryName').value;
      this.Details.op_date=this.opinionDetailsForm.get('date').value;
      this.Details.property_value=this.opinionDetailsForm.get('propartyValue').value;
      this.Details.stamp_duty=this.opinionDetailsForm.get('stampDuty').value;
      //this.Details.ststus = Constant.REFUND_REQUEST_DRAFT_CODE;


    this.refundService.saveOpinionDetails(this.Details).subscribe(data=>{
      console.log('data',data);
      let dataId = (data as any).data;
      let reasonOpinionId = dataId.id;
      this.common.refundOpinionId = reasonOpinionId;
      let refundRequestId= localStorage.getItem('refundId');
      this.refundService.updateRefundTableById(parseInt(refundRequestId),this.common).subscribe(data=>{
        console.log(data)
      })
      // this.notifier.notify('success', 'Documents are submitted');
    });

    // this.redirectComponent();
    this.onPageCountChanged.emit(2);

  }


  public redirectComponent(): void {
    // this.onPageCountChanged.emit(2);
    this.router.navigate(['/dashboard']);
  }

  getOpinionCollectionPayments(){
    this.refundService.getOpinionPaymentDetails(this.opinionNo).subscribe(data =>{
      this.collectionPayment = data.data;
    })
  }
}
