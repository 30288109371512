import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../auth/auth.service';
import {Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';
import {UserDetails} from 'src/app/model/user-details';
import {UserService} from 'src/app/service/user/user.service';
import {passwordPolicy} from 'src/app/model/passwordPolicy';
import {RegistrationService} from 'src/app/service/registration.service';
import {SubSink} from 'subsink';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  spinner = false;
  private sub = new SubSink();


  public model = new UserDetails('', '', '');
  passwordPolicyPattern = '^(?=(.*[A-Z]){$7@})(?=(.*[0-9]){$1@})(?=(.*[#$@!%&*?]){$3@})[A-Za-z0-9#$@!%&*?]{$5@,$6@}$';
  public passwordPolicyModel = new passwordPolicy(null, '', '', '', '', '', '', '', '', '');

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private notifier: NotifierService,
    public userService: UserService,
    private registrationService: RegistrationService,
  ) {
  }

  get f() {
    return this.loginForm.controls;
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
    this.clearStart();
    this.getPasswordPolicy();
  }

  getPasswordPolicy() {
    this.sub.add(
      this.registrationService.getActivatedPasswordPolicy().subscribe(data => {
        if (data.data == null) {
          this.passwordPolicyModel.minNumaric = '03';
          // this.passwordPolicyModel.maxNumaric = "";
          this.passwordPolicyModel.minSpecialChar = '03';
          // this.passwordPolicyModel.maxSpecialChar = "";
          this.passwordPolicyModel.minLength = '06';
          this.passwordPolicyModel.maxLength = '08';
          this.passwordPolicyModel.minUpperChar = '01';
        } else {
          this.passwordPolicyModel = (data as any).data;
        }
        this.passwordPolicyPattern = this.passwordPolicyPattern.replace('$1@', this.passwordPolicyModel.minNumaric);
        // this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$2@",this.passwordPolicyModel.maxNumaric);
        this.passwordPolicyPattern = this.passwordPolicyPattern.replace('$3@', this.passwordPolicyModel.minSpecialChar);
        // this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$4@",this.passwordPolicyModel.maxSpecialChar);
        this.passwordPolicyPattern = this.passwordPolicyPattern.replace('$5@', this.passwordPolicyModel.minLength);
        this.passwordPolicyPattern = this.passwordPolicyPattern.replace('$6@', this.passwordPolicyModel.maxLength);
        this.passwordPolicyPattern = this.passwordPolicyPattern.replace('$7@', this.passwordPolicyModel.minUpperChar);

        sessionStorage.setItem('passwordPolicy', this.passwordPolicyPattern);
      })
    );
  }

  clearStart() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('x-auth');
    localStorage.removeItem('x-auth-z');
    sessionStorage.removeItem('userinfo');
    sessionStorage.removeItem('valuationRequest');
  }

  logIn() {
    if (this.loginForm.valid === true) {
      this.spinner = true;
      document.getElementById('formID').style.filter = 'opacity(0.5)';
      this.authService.login(this.getUserName(), this.getPassword()).subscribe((response) => {
          document.getElementById('formID').style.filter = 'opacity(1)';
          this.model.username = this.getUserName();
          this.model.password = this.getPassword();
          this.userService.retrieveUserInfo(this.model).subscribe(data => {
            console.log(data);
            if (data) {
              this.spinner = false;
              const userRole = data.data.roles[0].code;
              localStorage.setItem('rc', userRole);
              sessionStorage.setItem('userinfo', btoa(JSON.stringify(data.data)));
              if (userRole === 'RO-0003') {
                this.router.navigate(['/dashboard']);
                sessionStorage.setItem('username', data.data.username);

              } else if (userRole === 'RO-0005') {
                this.router.navigate(['/dashboard/commissioner']);
                sessionStorage.setItem('username', data.data.username);

              } else if (userRole === 'RO-0006') {
                this.router.navigate(['/dashboard/deputy-commissioner']);
                sessionStorage.setItem('username', data.data.username);

              } else if (userRole === 'RO-0004') {
                this.router.navigate(['/dashboard/assessor']);
                sessionStorage.setItem('username', data.data.username);
              } else {
                this.router.navigate(['/main-dashboard-internal-user']);
                sessionStorage.setItem('username', data.data.username);
              }
            }
          }, error => {
            console.log(error);
            this.spinner = false;
          });
        },
        error => {
          this.spinner = false;
          document.getElementById('formID').style.filter = 'opacity(1)';
          this.notifier.notify('error', 'Invalid Username Or Password');
        });
    }
  }

  getUserName() {
    return this.loginForm.get('username').value;
  }

  getPassword() {
    return this.loginForm.get('password').value;
  }

}
