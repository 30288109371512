import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import {DashboardService} from '../../../service/dashboard/dashboard.service';

@Component({
  selector: 'app-dashboard-internal',
  templateUrl: './dashboard-internal.component.html',
  styleUrls: ['./dashboard-internal.component.scss']
})
export class DashboardInternalComponent implements OnInit {

  constructor(private authService: AuthService,private dashboardService: DashboardService) { }
  role :any;
  pendingCount: number;
  completedCount: number;
  rejectedCount: number;
  allRequestCount: number;
  newRequestCount: number;

  ngOnInit() {
    this.newRequestChange();
    this.allCountChange();
    this.pendingCountChange();
    this.completedCountChange();
    this.rejectedCountChange();
   this.role=localStorage.getItem('rc')
  }

  newRequestChange() {
    this.dashboardService.getRequestCount('NEW','INTERNAL').subscribe(value => {
      this.newRequestCount = value;
    });
  }

  allCountChange() {
    this.dashboardService.getRequestCount('all','INTERNAL').subscribe(value => {
      this.allRequestCount = value;
    });
  }
  pendingCountChange() {
    this.dashboardService.getRequestCount('PENDING','INTERNAL').subscribe(value => {
      this.pendingCount = value;
    });
  }
  completedCountChange() {
    this.dashboardService.getRequestCount('COMPLETED','INTERNAL').subscribe(value => {
      this.completedCount = value;
    });
  }
  rejectedCountChange() {
    this.dashboardService.getRequestCount('REJECTED','INTERNAL').subscribe(value => {
      this.rejectedCount = value;
    });
  }

  logout(){
    this.authService.logout();
  }

}
