import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PropertyFacilityService } from 'src/app/service/property-facility/property-facility.service';
import { PropertyFacility } from 'src/app/model/property-facility';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-add-property-facility',
  templateUrl: './add-property-facility.component.html',
  styleUrls: ['./add-property-facility.component.scss']
})
export class AddPropertyFacilityComponent implements OnInit, OnDestroy {

  addPropertyFacilityForm: FormGroup;

  private sub = new SubSink();

  constructor(private formBuilder: FormBuilder,
              private propertyFacilityService: PropertyFacilityService,
              private notifier: NotifierService,
              private dataService: DataService) { }

  ngOnInit() {
    this.addPropertyFacilityForm = this.formBuilder.group({
      code: ['', [Validators.required]],
      name: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  savePropertyFacility() {
    if (this.addPropertyFacilityForm.valid) {

      const propertyFacility = new PropertyFacility();

      propertyFacility.code = this.getCode();
      propertyFacility.description = this.getDescription();
      propertyFacility.name = this.getName();
      console.log('des' + propertyFacility.description);

      this.sub.add(this.propertyFacilityService.createPropertyFacility(propertyFacility)
        .subscribe(response => {
          this.notifier.notify('success', (response as any).message);
          this.dataService.updatePropertyFacility(true);
        }));

      this.reset();
    }
  }

  getCode() {
    return this.addPropertyFacilityForm.get('code').value;
  }
  getName() {
    return this.addPropertyFacilityForm.get('name').value;
  }
  getDescription() {
    return this.addPropertyFacilityForm.get('description').value;
  }

  reset() {
    this.addPropertyFacilityForm.reset();
  }
}
