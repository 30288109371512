import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import Swal from "sweetalert2";
import * as moment from "moment";
import { NotifierService } from 'angular-notifier';
import { PDFProgressData } from 'ng2-pdf-viewer';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';

import { CalculationDataProcess } from "src/app/service/calculationDataProcess/calculation-data-process.service";
import { DataModel } from "../models/data-model";
import { Improvements } from "../models/improvements";
import { CALCULATION_HEADINGS } from "../constant-headings/heading";
import { CalculationService } from "src/app/service/calculation/calculation.service";
import { ValuationRequestService } from "src/app/service/valuation-request/valuation-request.service";
import { Constant } from "src/app/util/constant";
import { FileUploadComponent } from 'src/app/component/dashboards/dashboard-external/assesment/file-upload/file-upload.component';
import { DocumentUploadService } from 'src/app/service/document-upload/document-upload.service';

@Component({
  selector: "app-condominium",
  templateUrl: "./condominium.component.html",
  styleUrls: ["./condominium.component.scss"]
})
export class CondominiumComponent implements OnInit {
  @Output() sandDataModel: EventEmitter<any> = new EventEmitter<any>();

  @Output() frontPageEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output() resetCalculation: EventEmitter<any> = new EventEmitter<any>();

  files4: any = [];
  progress: number;
  images4: any = [];
  originalImages4: any = [];
  filesList: any = [];
  names4: any = [];

  maxDate: any;
  maxDate1: any;

  showSecondImprovementsTable: boolean;

  committeeDescision: string;
  showDecisionComDocView: boolean = false;

  @ViewChild('fileUploadDecisionOfCommittee', {static: false}) fileUploadDecisionOfCommittee: FileUploadComponent;
  selectPaths = [];
  otherSelectedPaths = [];
  selectPathsCollection = [];
  errorList = []

  dataDone = false;
  noDocs = false;
  previewFileData: any;

  saveButtonClicked: boolean = false;

  constructor(
    private dataProcess: CalculationDataProcess,
    private activatedRoute: ActivatedRoute,
    public apiService: CalculationService,
    public valuationRequestService: ValuationRequestService,
    private documentUploadService: DocumentUploadService,
    private notifier: NotifierService,
    private spinner: NgxSpinnerService,
    public sanitizer: DomSanitizer
  ) {
    this.showSecondImprovementsTable = true;
    this.disableSaveButton = false;
  }

  panelNumber = 1;
  dynamicLblValue = "GROSS";
  previousValues:any[] = [];
  totalRecords:number;
  page=1;

  @Input()
  mainFormDataModel;

  @Input()
  selectedRecord;

  _CALCULATION_HEADINGS = CALCULATION_HEADINGS;

  headingDateOne = null;
  headingDateTwo = null;

  dataModel = new DataModel();

  backButtonShow = true;

  previousMarketValues = [];

  disableSaveButton: boolean;

  ngOnInit() {

    // Checking whether a new Calculation or an existing one
    // If an existing calculation, save button is hidden
    this.disableSaveButton = JSON.parse(atob(sessionStorage.getItem('disableSave')));

    if (this.selectedRecord != null) {
      this.showDecisionComDocView = true;
      this.retrieveDecisionOfCommitte();
      this.dataModel = this.dataProcess.condominiumDataProcessToView(this.selectedRecord);

      this.mainFormDataModel.selectedDate = this.selectedRecord.date;

      // Assigning Rate of Return values
      this.dataModel.fristSet.rateOfReturn = this.selectedRecord.calculationCondominiums[0].rateOfReturn;
      if (this.selectedRecord.natureOfDeed == "gift") {
        this.dataModel.secondSet.rateOfReturn = this.selectedRecord.calculationCondominiums[1].rateOfReturn;
      }

      // Assigning toggle button value on selected calculation record
      // First page
      if (this.dataModel.fristSet.valueType === "true") {
        this.dataModel.fristSet.valueType = true;
      } else {
        this.dataModel.fristSet.valueType = false;
      }
      // Second page
      if (this.dataModel.secondSet.valueType === "true") {
        this.dataModel.secondSet.valueType = true;
      } else {
        this.dataModel.secondSet.valueType = false;
      }

      this.headingDateOne = moment(this.selectedRecord.headingDateOne).format(
        "YYYY-MM-DD"
      );
      this.headingDateTwo = moment(this.selectedRecord.headingDateTwo).format(
        "YYYY-MM-DD"
      );

      this.mainFormDataModel.selectedNatureOfDeed = this.selectedRecord.natureOfDeed;
      this.mainFormDataModel.selectedPropertyType = this.selectedRecord.propertyType;

      this.calculateAutoGeneratedValuesForFirstPanle();
      this.calculateAutoGeneratedValuesForSecondPanel();
    }

    if (this.mainFormDataModel.selectedNatureOfDeed != "gift") {
      this.panelNumber = 2;
      this.backButtonShow = false;
      this.showSecondImprovementsTable = false;
    } else {
      this.panelNumber = 1;
      this.backButtonShow = true;
    }

    if (this.mainFormDataModel.selectedNatureOfDeed != "transfer") {
      this.showSecondImprovementsTable = false;
    }
      this.getPreviousMarketValue();
    window.scroll(0,0);
    this.maxDate = new Date();
    this.maxDate1 = new Date();
  }

  // getPreviousMarketValueDetails() {
  //   this.previousMarketValues = [];
  //   let refNo;
  //   this.activatedRoute.params.subscribe(params => {
  //     refNo = params["id"];
  //   });
  //   this.apiService.getPreviousMarketValueDetails(refNo).subscribe(data => {
  //     this.previousMarketValues = data.data;
  //   });
  // }


  viewOpinionDetails(valuationId:any):void{
    window.open('view-internal-user-application/' + valuationId)
  }

  goToFontPage() {
    this.frontPageEmitter.next(1);
    this.resetCalculation.next(1);
  }

  changePanelNumber(position) {
    if (position == "back") {
      if (this.panelNumber == 2) {
        this.panelNumber = this.panelNumber - 1;
      }
    }
    if (position == "next") {
      if (this.panelNumber == 1) {
        if (
          this.headingDateOne == null &&
          this.mainFormDataModel.selectedDate == "after"
        ) {
          Swal.fire("Error", "Please select first heading date ", "error");
          return;
        }
        this.panelNumber = this.panelNumber + 1;
      }
    }
    window.scroll(0,0);
  }

  valueTypeChangeAction(value) {
    this.dataModel.secondSet.valueType = value;
    if (value) {
      this.dynamicLblValue = "gross";
      this.calculateAutoGeneratedValuesForFirstPanle();
      this.calculateAutoGeneratedValuesForSecondPanel();
    }
    if (!value) {
      this.dynamicLblValue = "net";
      this.dataModel.secondSet.deductionPrecentage = null;
      this.dataModel.secondSet.deductionOutgoing = null;
      this.calculateAutoGeneratedValuesForFirstPanle();
      this.calculateAutoGeneratedValuesForSecondPanel();
    }
  }

  calculateAutoGeneratedValuesForFirstPanle() {
    this.dataModel.fristSet.monthlyRent = (
      this.dataModel.fristSet.rentPerSqFt * this.dataModel.fristSet.extent
    ).toFixed(2);

    this.dataModel.fristSet.annualRent = (
      this.dataModel.fristSet.monthlyRent * 12
    ).toFixed(2);

    this.dataModel.fristSet.deductionOutgoing = (
      (this.dataModel.fristSet.annualRent *
        this.dataModel.fristSet.deductionPrecentage) /
      100
    ).toFixed(2);

    this.dataModel.fristSet.annualIncome = (
      this.dataModel.fristSet.annualRent -
      this.dataModel.fristSet.deductionOutgoing
    ).toFixed(2);
    let yp = 0;
    if (
      this.dataModel.fristSet.rateOfReturn != null &&
      this.dataModel.fristSet.rateOfReturn != 0
    ) {
      // this.dataModel.fristSet.yp = 0;
      yp = 100 / this.dataModel.fristSet.rateOfReturn;
      this.dataModel.fristSet.yp = yp.toFixed(4);

      this.dataModel.fristSet.marketValue = (
        this.dataModel.fristSet.annualIncome * yp
      ).toFixed(2);
    } else {
      this.dataModel.fristSet.marketValue = 0;
    }

    this.dataModel.fristSet.externalTotal = this.dataModel.fristSet.marketValue;

    this.dataModel.fristSet.valueOfTheProperty =
      Number(this.dataModel.fristSet.marketValue) +
      Number(this.dataModel.fristSet.total);

    if (
      this.dataModel.secondSet.lowestValue != "" ||
      this.dataModel.secondSet.lowestValue != null
    ) {
      this.calculateAutoGeneratedValuesForSecondPanel();
    }
  }

  calculateAutoGeneratedValuesForSecondPanel() {
    this.dataModel.secondSet.monthlyRent = (
      this.dataModel.secondSet.rentPerSqFt * this.dataModel.secondSet.extent
    ).toFixed(2);

    this.dataModel.secondSet.annualRent = (
      this.dataModel.secondSet.monthlyRent * 12
    ).toFixed(2);

    if (this.dataModel.secondSet.valueType) {
      (this.dataModel.secondSet.deductionOutgoing =
        (this.dataModel.secondSet.annualRent *
          this.dataModel.secondSet.deductionPrecentage) /
        100).toFixed(2);
    }

    this.dataModel.secondSet.annualIncome = (
      this.dataModel.secondSet.annualRent -
      this.dataModel.secondSet.deductionOutgoing
    ).toFixed(2);

    let yp = 0;
    if (
      this.dataModel.secondSet.rateOfReturn != null &&
      this.dataModel.secondSet.rateOfReturn != 0
    ) {
      yp = 100 / this.dataModel.secondSet.rateOfReturn;
      this.dataModel.secondSet.yp = yp.toFixed(4);
      this.dataModel.secondSet.marketValue = (
        this.dataModel.secondSet.annualIncome * yp
      ).toFixed(2);
    } else {
      this.dataModel.secondSet.marketValue = 0;
    }

    this.dataModel.secondSet.externalTotal = parseFloat(
      this.dataModel.secondSet.marketValue
    ).toFixed(2);
    let lowestVal: any = 0;

    if (this.dataModel.secondSet.total != null) {
      this.dataModel.secondSet.marketValue =
        Number(this.dataModel.secondSet.marketValue) +
        Number(this.dataModel.secondSet.total);
    }

    if (this.mainFormDataModel.selectedNatureOfDeed == "gift") {
      if (
        Number(parseFloat(this.dataModel.secondSet.marketValue).toFixed(2)) >
        Number(parseFloat(this.dataModel.fristSet.valueOfTheProperty).toFixed(2))
      ) {
        lowestVal = this.dataModel.fristSet.valueOfTheProperty;
      } else {
        lowestVal = parseFloat(this.dataModel.secondSet.marketValue).toFixed(2);
      }
    } else {
      lowestVal = this.dataModel.secondSet.marketValue;
    }
    this.dataModel.secondSet.lowestValue = parseFloat(lowestVal).toFixed(2);
    this.dataModel.secondSet.valueOfTheProperty = parseFloat(lowestVal).toFixed(2);

    // this.dataModel.secondSet.roundOffValue =
    // this.dataModel.secondSet.roundOffValue == "" ||
    // this.dataModel.secondSet.roundOffValue == null ||  this.dataModel.secondSet.roundOffValue < 1
    //   ? this.dataModel.secondSet.lowestValue
    //   : this.dataModel.secondSet.roundOffValue;
    // this.dataModel.secondSet.roundOffValue = this.dataModel.secondSet.lowestValue;

    if (this.selectedRecord == null || (this.dataModel.secondSet.roundOffValue == "" || this.dataModel.secondSet.roundOffValue == null || this.dataModel.secondSet.roundOffValue < 1)) {
      this.dataModel.secondSet.roundOffValue = this.dataModel.secondSet.lowestValue;
    } else {
      this.dataModel.secondSet.roundOffValue = parseFloat(this.dataModel.secondSet.roundOffValue).toFixed(2);
    }



    let totalStampDuty = 0;
    let valueOfTheProperty = 100000;
    let balanceStampDutyPrecentage = 4;
    if (this.mainFormDataModel.selectedNatureOfDeed == "gift") {
      valueOfTheProperty = 50000;
      balanceStampDutyPrecentage = 2;
    }
    if (this.dataModel.secondSet.roundOffValue > valueOfTheProperty) {
      let fMarketVal = (valueOfTheProperty * 3) / 100;

      let sMarketVal =
        ((this.dataModel.secondSet.roundOffValue - valueOfTheProperty) *
          balanceStampDutyPrecentage) /
        100;
      totalStampDuty = fMarketVal + sMarketVal;
      this.dataModel.secondSet.stampDuty = totalStampDuty.toFixed(2);
    } else {
      let fMarketVal = (this.dataModel.secondSet.roundOffValue * 3) / 100;
      this.dataModel.secondSet.stampDuty = fMarketVal.toFixed(2);
    }
  }

  roundOffValueChangeEvent() {
    let totalStampDuty = 0;
    let valueOfTheProperty = 100000;
    let balanceStampDutyPrecentage = 4;
    if (this.mainFormDataModel.selectedNatureOfDeed == "gift") {
      valueOfTheProperty = 50000;
      balanceStampDutyPrecentage = 2;
    }
    if (this.dataModel.secondSet.roundOffValue > valueOfTheProperty) {
      let fMarketVal = (valueOfTheProperty * 3) / 100;

      let sMarketVal =
        ((this.dataModel.secondSet.roundOffValue - valueOfTheProperty) *
          balanceStampDutyPrecentage) /
        100;
      totalStampDuty = fMarketVal + sMarketVal;
      this.dataModel.secondSet.stampDuty = totalStampDuty.toFixed(2);
    } else {
      let fMarketVal = (this.dataModel.secondSet.roundOffValue * 3) / 100;
      this.dataModel.secondSet.stampDuty = fMarketVal.toFixed(2);
    }
  }

  addNewRow(position) {
    if (position == 1) {
      this.dataModel.fristSet.improvements.push({
        id: null,
        description: null,
        extent: null,
        rentPerSqFt: null,
        value: null
      });
    }

    if (position == 2) {
      this.dataModel.secondSet.improvements.push({
        id: null,
        description: null,
        extent: null,
        rentPerSqFt: null,
        value: null
      });
    }

    this.calculateImprovementsTableValueFiled(undefined, position);
  }

  removeRow(index, position) {
    if (position == 1) {
      this.dataModel.fristSet.improvements.splice(index, 1);
    }

    if (position == 2) {
      this.dataModel.secondSet.improvements.splice(index, 1);
    }

    this.calculateImprovementsTableValueFiled(undefined, position);
  }

  calculateImprovementsTableValueFiled(index, position) {
    let totalVal: Number = 0;
    let _totalVal: Number = 0;

    if (index != undefined && position == 1) {
      this.dataModel.fristSet.improvements[index].value = (
        this.dataModel.fristSet.improvements[index].extent *
        this.dataModel.fristSet.improvements[index].rentPerSqFt
      ).toFixed(2);
    }
    if (index != undefined && position == 2) {
      this.dataModel.secondSet.improvements[index].value = (
        this.dataModel.secondSet.improvements[index].extent *
        this.dataModel.secondSet.improvements[index].rentPerSqFt
      ).toFixed(2);
    }

    if (position == 1) {
      this.dataModel.fristSet.improvements.forEach(element => {
        totalVal = Number(totalVal) + Number(element.value);
      });
      this.dataModel.fristSet.total = totalVal;
      this.calculateAutoGeneratedValuesForFirstPanle();
    }
    if (position == 2) {
      this.dataModel.secondSet.improvements.forEach(element => {
        _totalVal = Number(_totalVal) + Number(element.value);
      });
      this.dataModel.secondSet.total = _totalVal;
      this.calculateAutoGeneratedValuesForSecondPanel();
    }
  }

  saveDataModel() {
    if (
      this.dataModel.secondSet.stampDuty == null ||
      this.dataModel.secondSet.stampDuty < 1 ||
      this.dataModel.secondSet.stampDuty == ""
    ) {
      Swal.fire(
        "Error",
        "Stamp duty not generated, Please check all fileds",
        "error"
      );
      return;
    }

    if (this.mainFormDataModel.selectedDate == "after") {
      if (this.headingDateOne == null) {
        Swal.fire("Error", "Please select first heading date ", "error");
        return;
      }
    }
    if (this.headingDateTwo == null) {
      Swal.fire("Error", "Please select second heading date ", "error");
      return;
    }

    this.saveButtonClicked = true;
    this.dataModel.headingDateOne = this.headingDateOne;
    this.dataModel.headingDateTwo = this.headingDateTwo;
    this.sandDataModel.emit(this.dataModel);
  }

  validateDataSet() {}

  getPreviousMarketValue(){
    let refNo;
    this.activatedRoute.params.subscribe(params => {
      refNo = params["id"];
    });
    this.apiService.getPreviousMarket(refNo,Constant.CALCULATION_CONDOMINIUM).subscribe(data=>{
      if(data.data !== null){
        this.previousValues.push(data.data);
      }      
      this.totalRecords=this.previousValues.length;
    })
  }

  setInitiate() {
    this.dataModel = new DataModel();
  }

  // validateNumericFileds() {
  //   this.value = this.value.replace(/^\$?[0-9][0-9,]*[0-9]\.?[0-9]{0,2}$/i, "");
  // }

  setDecisionOfCommitteeDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  saveDocumentsAndDecision(savedCalculationId) {

    this.fileUploadDecisionOfCommittee.outPutImagesSet();
    this.otherSelectedPaths = this.selectPaths;
    this.selectPathsCollection.push(this.otherSelectedPaths[0]);

    this.documentUploadService.uploadCalculationDocuments(this.selectPathsCollection, savedCalculationId, this.committeeDescision).subscribe(
      data => {},
      err => {
        this.notifier.notify('error', 'Document upload unsuccessful');
      }
    );
  }

  retrieveDecisionOfCommitte() {
    this.apiService.getDecisionOfCommitteeByCalculationId(this.selectedRecord.id).subscribe(
      data => {
        if(data.data.decisionOfCommittee != "undefined") {
          this.committeeDescision = data.data.decisionOfCommittee;
        } else {
          this.committeeDescision = "-"
        }
      }
    )
  }

  // RETRIEVE DOCUMENTS - START
  onProgress(progressData: PDFProgressData) {
    if (progressData.loaded == progressData.total) {
      this.spinner.hide();
    }
  }

  previewFile(title) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.apiService.loadDocumentsByCalculationId(this.selectedRecord.id, title).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(() => {
          this.spinner.hide();
        }, 2000);

      } else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }
  // RETRIEVE DOCUMENTS - END
}
