import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ReimbursementApplicationCreate } from 'src/app/model/rim-application-create';
import { ReimbursementApplicationUpdate } from 'src/app/model/rim-application-update';
import { RimForm } from 'src/app/model/rim-form';
import { RimNoticeLetter } from 'src/app/model/rim-notice-letter';
import { ReimbursementPayableAndNonPayableDeeds } from 'src/app/model/rim-payable-nonpayable';
import { assignTaxOfficer } from 'src/app/model/rimAsignTaxOfficer';
import { rimSavePayment } from 'src/app/model/rimSavePayment';
import { rimUpdateDCommissionerIdAndStatus } from 'src/app/model/rimUpdateDCommissionerIdAndStatus';


import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RimApplicationService {

  private reimbursementApplicationUrl = '/reimbursement-application';
  reimbursementDocumentUploadUrl ='/documents-payablenonepayable'

  constructor(
    private http: HttpClient
  ) { }

  saveRefundNoticeLetter(reimbursementNoticeLetterModel: RimNoticeLetter): Observable<any> {
    return this.http.post(environment.api_url + this.reimbursementApplicationUrl + "/notice-letter", reimbursementNoticeLetterModel);
  }

  getNoticelettersByReimbursementId(reimbursementId: number): Observable<any> {
    return this.http.get(environment.api_url + this.reimbursementApplicationUrl + "/notice-letters/" + reimbursementId);
  }

  getUploadedFilesByReimbursementId(reimbursementId: number): Observable<any> {
    return this.http.get(environment.api_url + this.reimbursementApplicationUrl +"/" + reimbursementId + "/files" );
  }

  createReimbursementDraftApplication(reimburesementApplicationCreateModel: ReimbursementApplicationCreate): Observable<any> {
    return this.http.post(environment.api_url + this.reimbursementApplicationUrl + "/create-draft", reimburesementApplicationCreateModel);
  }

  saveReimbursementApplicationForm(reimbursementApplicationFormModel: RimForm): Observable<any> {
    return this.http.post(environment.api_url + this.reimbursementApplicationUrl + "/save-form", reimbursementApplicationFormModel);
  }

  asignTaxOfficer(id:number, rimAsigntaxOffice:assignTaxOfficer):Observable<any>{
    return this.http.put(environment.api_url+this.reimbursementApplicationUrl+"/assign-tax-officer/"+id,rimAsigntaxOffice);
  }

  getRimApplications(userRole:string, userId:string, status:string):Observable<any>{
    return this.http.get(environment.api_url+this.reimbursementApplicationUrl+"/get-applications/"+userRole+"/"+userId+"/"+status);
  }

  uploadFormCsvFile(reimbursementAppId: string, file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('rimApplicationId', reimbursementAppId);
    formData.append('file', file);

    const req = new HttpRequest('POST', `${environment.api_url}${this.reimbursementApplicationUrl}/upload-excel`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  saveRimPayment(rimSavePaymentModel:rimSavePayment): Observable<any> {
    return this.http.post(environment.api_url + this.reimbursementApplicationUrl + "/save-rim-payment", rimSavePaymentModel);
  }

  getRimPayment(rimId:number):Observable<any>{
    return this.http.get(environment.api_url+this.reimbursementApplicationUrl+"/get-rim-payment/"+rimId);
  }

  updateRimDCommissionerStatus(dComId: string, rimDCommissionerStatusMode: rimUpdateDCommissionerIdAndStatus): Observable<any> {
    return this.http.post(environment.api_url + this.reimbursementApplicationUrl + '/update-dCommissioner/' + dComId, rimDCommissionerStatusMode);
  }

  getFormExcelFileDataByReimbursementId(reimbursementId :number):Observable<any>{
    return this.http.get(environment.api_url+this.reimbursementApplicationUrl + "/form-excels/" + reimbursementId);
  }

  getFormDetailsByReimbursementId(reimbursementId :number):Observable<any> {
    return this.http.get(environment.api_url+this.reimbursementApplicationUrl + "/rim-application-form-details/" + reimbursementId);
  }

  savePayableAndNonPayableDeeds(reimburesementPaybleAndNonPayableDeedsModel: ReimbursementPayableAndNonPayableDeeds): Observable<any> {
    return this.http.post(environment.api_url + this.reimbursementApplicationUrl + "/payable-nonpayable-update", reimburesementPaybleAndNonPayableDeedsModel);
  }
  savePayableAndNonPayableDocs(selectedPaths = [] ,rimId :string): Observable<any> {
    console.log("This is savePayableAndNonPayableDocs");
    const formData = new FormData();
    formData.append('rimApplicationId', rimId);
    for (let i = 0; i < selectedPaths.length; i++) {
      for (let x = 0; x < selectedPaths[i].length; x++) {
        if (i === 0) {
          console.log(selectedPaths[i][x], selectedPaths[i][x].name);
          formData.append('payNonePayDocs', selectedPaths[i][x], selectedPaths[i][x].name);
        }
      }
    }
    console.log(formData);
    return this.http.post(environment.api_url + this.reimbursementApplicationUrl + "/documents-payablenonepayable", formData);
  }

  updateReimbursementStatusForRecommendProcess(reimbursementApplicationUpdateModel: ReimbursementApplicationUpdate) {
    return this.http.post(environment.api_url + this.reimbursementApplicationUrl + "/update-for-recommend", reimbursementApplicationUpdateModel);
  }

  updatePayableAndNonPayableComment(reimbursementApplicationUpdateModel: ReimbursementApplicationUpdate) {
    return this.http.post(environment.api_url + this.reimbursementApplicationUrl + "/update-for-PayableAndNonPayableComment", reimbursementApplicationUpdateModel);
  }

  updateReimbursementStatusForApproveProcess(reimbursementApplicationUpdateModel: ReimbursementApplicationUpdate) {
    return this.http.post(environment.api_url + this.reimbursementApplicationUrl + "/update-for-approve", reimbursementApplicationUpdateModel);
  }

  getAllNonPayableTableDataByReimbursementId(reimbursementId :number):Observable<any>{
    return this.http.get(environment.api_url+this.reimbursementApplicationUrl + "/non-payable-data/" + reimbursementId);
  }

  getAllPayableTableDataByReimbursementId(reimbursementId :number):Observable<any>{
    return this.http.get(environment.api_url+this.reimbursementApplicationUrl + "/payable-data/" + reimbursementId);
  }

  getBankStatement(rimId:number):Observable<any>{
    return this.http.get(environment.api_url+this.reimbursementApplicationUrl+"/rim-bank-statement/"+rimId);
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {

  }
}
