import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import * as moment from "moment";

import { CalculationDataProcess } from "src/app/service/calculationDataProcess/calculation-data-process.service";
import { CALCULATION_HEADINGS } from "../../constant-headings/heading";
import { DataModel } from "../../models/data-model";
import { ValueOfTheLand } from "../../models/landValues";

@Component({
  selector: "app-recreation-land-building",
  templateUrl: "./recreation-land-building.component.html",
  styleUrls: ["./recreation-land-building.component.scss"]
})
export class RecreationLandBuildingComponent implements OnInit, OnChanges {
  panelNumber = 1;

  @Input()
  mainFormDataModel;

  @Input()
  selectedRecord;

  @Input()
  showSubPanel;

  @Input()
  party;

  @Input()
  headingOne

  @Input()
  headingTwo

  buildingValueTotal: any = 0;
  buildingValueTotal_: any = 0;

  landValueTotal: any = 0;
  landValueTotal_: any = 0;

  hideBackButton = false;
  headingDateOne = null;
  headingDateTwo = null;

  _CALCULATION_HEADINGS = CALCULATION_HEADINGS;

  @Output() sandDataModel: EventEmitter<any> = new EventEmitter<any>();

  dataModel = new DataModel();

  maxDate: any;

  constructor(private dataProcess: CalculationDataProcess) {}


  ngOnChanges(changes:SimpleChanges){
    // this.dataModel.headingDateOne = changes['headingOne'] ? changes['headingOne'].currentValue  :'';
    // this.dataModel.headingDateTwo = changes['headingTwo'] ? changes['headingTwo'].currentValue :'';

    if (this.selectedRecord == null) {
      if(changes['headingOne']){
        if(changes['headingOne'].currentValue){
          this.dataModel.headingDateOne = changes['headingOne'].currentValue;
        }else{
          this.dataModel.headingDateOne = localStorage.getItem('headingOne');  
        }
      }else{
        this.dataModel.headingDateOne = localStorage.getItem('headingOne') ? localStorage.getItem('headingOne') :'';
      }
  
      if(changes['headingTwo']){
        if(changes['headingTwo'].currentValue){
          this.dataModel.headingDateTwo = changes['headingTwo'].currentValue;
        }else{
          this.dataModel.headingDateTwo = localStorage.getItem('headingTwo');
        }
      }else{
        this.dataModel.headingDateTwo = localStorage.getItem('headingTwo') ? localStorage.getItem('headingTwo') :'';
      }
    }

  }

  headingDateOneChange(){
    localStorage.setItem('headingOne', this.dataModel.headingDateOne);
  }

  headingDateTwoChange(){
    localStorage.setItem('headingTwo', this.dataModel.headingDateTwo);
  }

  ngOnInit() {
    // This file will be deleted if you use source tree to commit
    if (this.selectedRecord != null) {
      console.log(this.selectedRecord)
      this.dataModel = this.dataProcess.landAndBuildingDataProcessToView(
        this.selectedRecord
      );

      if (this.selectedRecord.natureOfDeed == "gift") {
        for(let i=0; i<this.dataModel.fristSet.valueOfTheBuildings.length; i++) {
          this.dataModel.fristSet.valueOfTheBuildings[i].constructionCost = Number(this.dataModel.fristSet.valueOfTheBuildings[i].constructionCost).toFixed(2);
          this.dataModel.fristSet.valueOfTheBuildings[i].contractionCost = Number(this.dataModel.fristSet.valueOfTheBuildings[i].contractionCost).toFixed(2);
        }
      }
     
      for(let i=0; i<this.dataModel.secondSet.valueOfTheBuildings.length; i++) {
        this.dataModel.secondSet.valueOfTheBuildings[i].constructionCost = Number(this.dataModel.secondSet.valueOfTheBuildings[i].constructionCost).toFixed(2);
        this.dataModel.secondSet.valueOfTheBuildings[i].contractionCost = Number(this.dataModel.secondSet.valueOfTheBuildings[i].contractionCost).toFixed(2);
      }

      this.dataModel.headingDateOne = moment(this.selectedRecord.headingDateOne).format("YYYY-MM-DD");
      this.dataModel.headingDateTwo = moment(this.selectedRecord.headingDateTwo).format("YYYY-MM-DD");

      this.mainFormDataModel.selectedNatureOfDeed = this.selectedRecord.natureOfDeed;
      this.mainFormDataModel.selectedPropertyType = this.selectedRecord.propertyType;
      this.mainFormDataModel.selectedDate = this.selectedRecord.date;

      if (this.mainFormDataModel.selectedNatureOfDeed == "gift") {
        this.calculateValuationOfTheland(undefined, 1);
        this.calculateValuationOfTheBuilding(1, undefined);
        this.calculateAutoGeneratedValuesForFirstPanel();
      }
      this.calculateValuationOfTheland(undefined, 2);
      this.calculateValuationOfTheBuilding(2, undefined);
      this.calculateAutoGeneratedValuesForSecondPanel();
    }

    if (
      (this.mainFormDataModel.selectedNatureOfDeed == "exchange" ||
        this.mainFormDataModel.selectedNatureOfDeed == "transfer") &&
      this.showSubPanel.showLandBuilding
    ) {
      this.hideBackButton = true;
      this.panelNumber = 2;
    } else {
      this.hideBackButton = false;
      this.panelNumber = 1;
    }
    this.maxDate = new Date();
    window.scroll(0,0);
  }

  changePanelNumber(position) {
    if (position == "back") {
      if (this.panelNumber == 2) {
        this.panelNumber = this.panelNumber - 1;
      }
    }
    if (position == "next") {
      if (this.panelNumber == 1) {
        this.panelNumber = this.panelNumber + 1;
      }
    }
    window.scroll(0,0);
  }

  addNewRow(position, type) {
    if (position == 1) {
      if (type == "vl") {
        this.dataModel.fristSet.valueOfTheLand.push(new ValueOfTheLand());
      }

      if (type == "vb") {
        this.dataModel.fristSet.valueOfTheBuildings.push({
          id: null,
          buildingNo: null,
          floorNo: null,
          extent: null,
          constructionCost: null,
          contractionCost: null
        });
      }
      if (type == "improvements") {
        this.dataModel.fristSet.improvements.push({
          id: null,
          description: null,
          extent: null,
          rentPerSqFt: null,
          value: null
        });
      }
    }
    if (position == 2) {
      if (type == "vl") {
        this.dataModel.secondSet.valueOfTheLand.push(new ValueOfTheLand());
      }

      if (type == "vb") {
        this.dataModel.secondSet.valueOfTheBuildings.push({
          id: null,
          buildingNo: null,
          floorNo: null,
          extent: null,
          constructionCost: null,
          contractionCost: null
        });
      }
      // if (type == "improvements") {
      //   this.dataModel.secondSet.improvements.push({
      //     id: null,
      //     description: null,
      //     extent: null,
      //     rentPerSqFt: null,
      //     value: null
      //   });
      // }
    }
  }

  removeRow(index, position, type) {
    if (position == 1) {
      if (type == "vl") {
        this.dataModel.fristSet.valueOfTheLand.splice(index, 1);
        this.calculateValuationOfTheland(undefined, position);
      }

      if (type == "vb") {
        this.dataModel.fristSet.valueOfTheBuildings.splice(index, 1);
        this.calculateValuationOfTheBuilding(position, undefined);
      }
      if (type == "improvements") {
        this.dataModel.fristSet.improvements.splice(index, 1);
        this.calculateImprovementsTableValueFiled(undefined, position);
      }
    }

    if (position == 2) {
      if (type == "vl") {
        this.dataModel.secondSet.valueOfTheLand.splice(index, 1);
        this.calculateValuationOfTheland(undefined, position);
      }

      if (type == "vb") {
        this.dataModel.secondSet.valueOfTheBuildings.splice(index, 1);
        this.calculateValuationOfTheBuilding(position, undefined);
      }
      // if (type == "improvements") {
      //   this.dataModel.secondSet.improvements.splice(index, 1);
      //   this.calculateImprovementsTableValueFiled(index, position);
      // }
    }
  }

  calculateValuationOfTheBuilding(position, index) {
    if (position == 1) {
      if (index != undefined) {
        this.dataModel.fristSet.valueOfTheBuildings[index].contractionCost = (
          Number(this.dataModel.fristSet.valueOfTheBuildings[index].extent) *
          Number(
            this.dataModel.fristSet.valueOfTheBuildings[index].constructionCost
          )
        ).toFixed(2);
      }
      this.buildingValueTotal = 0;
      this.dataModel.fristSet.valueOfTheBuildings.forEach(element => {
        this.buildingValueTotal = (
          Number(this.buildingValueTotal) + Number(element.contractionCost)
        ).toFixed(2);
      });
      this.calculateAutoGeneratedValuesForFirstPanel();
    }
    if (position == 2) {
      if (index != undefined) {
        this.dataModel.secondSet.valueOfTheBuildings[index].contractionCost = (
          Number(this.dataModel.secondSet.valueOfTheBuildings[index].extent) *
          Number(
            this.dataModel.secondSet.valueOfTheBuildings[index].constructionCost
          )
        ).toFixed(2);
      }
      this.buildingValueTotal_ = 0;
      this.dataModel.secondSet.valueOfTheBuildings.forEach(element => {
        this.buildingValueTotal_ = (
          Number(this.buildingValueTotal_) + Number(element.contractionCost)
        ).toFixed(2);
      });
      this.calculateAutoGeneratedValuesForSecondPanel();
    }
  }

  // calculateValuationOfTheBuilding(position) {
  //   if (position == 1) {
  //     this.buildingValueTotal = 0;
  //     this.dataModel.fristSet.valueOfTheBuildings.forEach(element => {
  //       this.buildingValueTotal = parseFloat(
  //         this.buildingValueTotal +
  //           element.contractionCost +
  //           element.constructionCost
  //       );
  //     });
  //   }
  //   if (position == 2) {
  //     this.buildingValueTotal_ = 0;
  //     this.dataModel.secondSet.valueOfTheBuildings.forEach(element => {
  //       this.buildingValueTotal_ = parseFloat(
  //         this.buildingValueTotal_ +
  //           element.contractionCost +
  //           element.constructionCost
  //       );
  //     });
  //   }
  // }

  calculateValuationOfTheland(index, position) {
    if (position == 1) {
      this.landValueTotal = 0;
      if (index != undefined) {
        this.dataModel.fristSet.valueOfTheLand[index].value = (
          Number(this.dataModel.fristSet.valueOfTheLand[index].landExtend) *
          Number(this.dataModel.fristSet.valueOfTheLand[index].perPerchValue)
        ).toFixed(2);
      }

      this.dataModel.fristSet.valueOfTheLand.forEach(element => {
        this.landValueTotal = (
          Number(this.landValueTotal) + Number(element.value)
        ).toFixed(2);
      });
      this.calculateAutoGeneratedValuesForFirstPanel();
    }

    if (position == 2) {
      this.landValueTotal_ = 0;
      if (index != undefined) {
        this.dataModel.secondSet.valueOfTheLand[index].value = (
          Number(this.dataModel.secondSet.valueOfTheLand[index].landExtend) *
          Number(this.dataModel.secondSet.valueOfTheLand[index].perPerchValue)
        ).toFixed(2);
      }

      this.dataModel.secondSet.valueOfTheLand.forEach(element => {
        this.landValueTotal_ = (
          Number(this.landValueTotal_) + Number(element.value)
        ).toFixed(2);
      });
      this.calculateAutoGeneratedValuesForSecondPanel();
    }
  }

  calculateImprovementsTableValueFiled(index, position) {
    let totalVal: any = 0;
    if (position == 1) {
      if (index != undefined) {
        this.dataModel.fristSet.improvements[index].value = (
          Number(this.dataModel.fristSet.improvements[index].extent) *
          Number(this.dataModel.fristSet.improvements[index].rentPerSqFt)
        ).toFixed(2);
      }

      this.dataModel.fristSet.improvements.forEach(element => {
        totalVal = (Number(totalVal) + Number(element.value)).toFixed(2);
      });
      this.dataModel.fristSet.total = totalVal;
      this.calculateAutoGeneratedValuesForFirstPanel();
    }

    if (position == 2) {
      if (index != undefined) {
        this.dataModel.secondSet.improvements[index].value = (
          Number(this.dataModel.secondSet.improvements[index].extent) *
          Number(this.dataModel.secondSet.improvements[index].rentPerSqFt)
        ).toFixed(2);
      }

      this.dataModel.secondSet.improvements.forEach(element => {
        totalVal = (Number(totalVal) + Number(element.value)).toFixed(2);
      });
      this.dataModel.secondSet.total = totalVal;
      this.calculateAutoGeneratedValuesForSecondPanel();
    }
  }

  calculateAutoGeneratedValuesForFirstPanel() {
    this.dataModel.fristSet.depreciation =
      (this.buildingValueTotal * this.dataModel.fristSet.deductionPrecentage) /
      100;
    this.dataModel.fristSet.depreciation = this.dataModel.fristSet.depreciation.toFixed(
      2
    );

    this.dataModel.fristSet.depreciationContruction = (
      Number(this.buildingValueTotal) -
      Number(this.dataModel.fristSet.depreciation)
    ).toFixed(2);

    this.dataModel.fristSet.valueOfTheProperty = (
      Number(this.landValueTotal) +
      Number(this.dataModel.fristSet.depreciationContruction) +
      Number(this.dataModel.fristSet.total)
    ).toFixed(2);

    this.dataModel.fristSet.marketValue = (
      Number(this.landValueTotal) +
      Number(this.dataModel.fristSet.depreciationContruction) +
      Number(this.dataModel.fristSet.total)
    ).toFixed(2);

    if (
      this.dataModel.secondSet.lowestValue != "" ||
      this.dataModel.secondSet.lowestValue != null
    ) {
      this.calculateAutoGeneratedValuesForSecondPanel();
    }
  }

  calculateAutoGeneratedValuesForSecondPanel() {
    this.dataModel.secondSet.depreciation =
      (this.buildingValueTotal_ *
        this.dataModel.secondSet.deductionPrecentage) /
      100;

    this.dataModel.secondSet.depreciation = this.dataModel.secondSet.depreciation.toFixed(
      2
    );

    this.dataModel.secondSet.depreciationContruction = (
      Number(this.buildingValueTotal_) -
      Number(this.dataModel.secondSet.depreciation)
    ).toFixed(2);

    this.dataModel.secondSet.valueOfTheProperty =
      Number(this.landValueTotal_) +
      Number(this.dataModel.secondSet.depreciationContruction);

    // this.dataModel.secondSet.marketValue = this.dataModel.secondSet.valueOfTheProperty.toFixed(
    //   2
    // );
    this.dataModel.secondSet.marketValue = (
      Number(this.landValueTotal_) +
      Number(this.dataModel.secondSet.depreciationContruction) 
    ).toFixed(2);
    this.dataModel.secondSet.lowestValue = this.dataModel.secondSet.valueOfTheProperty.toFixed(
      2
    );
    if (this.mainFormDataModel.selectedNatureOfDeed == "gift") {
      this.dataModel.secondSet.lowestValue =
        this.dataModel.secondSet.marketValue >
        this.dataModel.fristSet.valueOfTheProperty
          ? this.dataModel.fristSet.valueOfTheProperty
          : this.dataModel.secondSet.marketValue;
    }

    this.dataModel.secondSet.roundOffValue =
    this.dataModel.secondSet.roundOffValue == "" ||
    this.dataModel.secondSet.roundOffValue == null ||
    this.dataModel.secondSet.roundOffValue < 1
      ? this.dataModel.secondSet.lowestValue
      : this.dataModel.secondSet.roundOffValue;

    let totalStampDuty: any = 0;
    let marketValue = 100000;
    let balanceStampDutyPrecentage = 4;
    if (this.mainFormDataModel.selectedNatureOfDeed == "gift") {
      marketValue = 50000;
      balanceStampDutyPrecentage = 2;
    }
    if (this.dataModel.secondSet.roundOffValue > marketValue) {
      let fMarketVal = (Number(marketValue) * 3) / 100;

      let sMarketVal =
        ((this.dataModel.secondSet.roundOffValue - marketValue) *
          balanceStampDutyPrecentage) /
        100;
      totalStampDuty = (Number(fMarketVal) + Number(sMarketVal)).toFixed(2);
      this.dataModel.secondSet.stampDuty = Number(totalStampDuty).toFixed(2);
    } else {
      let fMarketVal = (Number(this.dataModel.secondSet.roundOffValue) * 3) / 100;
      this.dataModel.secondSet.stampDuty = fMarketVal.toFixed(2);
    }
  }

  saveDataModel() {
    let data = {
      data: this.dataModel,
      type: "land-building",
      party: this.party
    };
    this.sandDataModel.emit(data);
  }
}
