import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DeedNature } from 'src/app/model/deed-nature';
import { DeedNatureService } from 'src/app/service/deed-nature/deed-nature.service';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-add-deed-nature',
  templateUrl: './add-deed-nature.component.html',
  styleUrls: ['./add-deed-nature.component.scss']
})
export class AddDeedNatureComponent implements OnInit, OnDestroy {

  addDeedNatureForm: FormGroup;
  private sub = new SubSink();


  constructor(private deedNatureService: DeedNatureService,
              private formBuilder: FormBuilder,
              private notifier: NotifierService,
              private dataService: DataService) { }


  ngOnInit() {
    this.addDeedNatureForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  saveDeedNature() {
    if (this.addDeedNatureForm.valid) {

      const deedNature = new DeedNature();
      deedNature.name = this.getName();
      deedNature.description = this.getDescription();
      this.sub.add(this.deedNatureService.createDeedNature(deedNature)
        .subscribe(response => {
          this.notifier.notify('success', (response as any).message);
          this.dataService.updateTableDeedNature(true);

        }));
    }
  }
  getDescription() {
    return this.addDeedNatureForm.get('description').value;
  }
  getName() {
    return this.addDeedNatureForm.get('name').value;
  }

}
