import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {ActivatedRoute, Router} from '@angular/router';

import {FurtherRequirementAndReject} from 'src/app/model/further-requirement-and-reject';
import {RefundOpinionServiceService} from 'src/app/service/RefundOpinionService/refund-opinion-service.service';
import {Constant} from 'src/app/util/constant';
import {RefundFurtherRequirementAndReject} from '../../../../../../model/refund-further-requirement-and-reject';
import {NotificationModel} from '../../../../../../model/notification-model';
import {NotificationService} from '../../../../../../service/notification/notification.service';

@Component({
  selector: 'app-refund-further-requirement',
  templateUrl: './refund-further-requirement.component.html',
  styleUrls: ['./refund-further-requirement.component.scss']
})
export class RefundFurtherRequirementComponent implements OnInit {

  @ViewChild('furtherReqNotification', null) private enterRemark: TemplateRef<object>;
  @ViewChild('rejectApplication', null) private enterRejectRemark: TemplateRef<object>;

  public furtherRequirementModel = new RefundFurtherRequirementAndReject();
  public rejectModel = new FurtherRequirementAndReject();
  public showDynamicGrid: boolean = false;

  public notificationObject = new NotificationModel('', '', '', '', '', '', '');

  rejectForm: FormGroup;

  furtherRequirementForm: FormGroup;
  furtherRequirementRemarkContent: string;
  applicationRejectRemarkContent: string;

  public documentUploaded: string;
  public documentUploaded1: string;

  files;
  images = [];
  public imgURL: any;
  public imgURL2: any;
  originalImages: any[] = [];
  furtherRequirementFile: File;
  rejectFile: File;
  names: string[] = [];

  userdata: any = [];
  userName: string;
  currentUserRoleCode: string;
  refundApplicationId: number;

  constructor(
    public modalService: NgbModal,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    public router: Router,
    private refundRequestService: RefundOpinionServiceService,
    private route: ActivatedRoute,
    public notificationService: NotificationService,
  ) {
  }

  ngOnInit() {
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.checkPermission();
    this.refundApplicationId = +localStorage.getItem('refundRequest');
  }

  checkPermission() {
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.currentUserRoleCode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.currentUserRoleCode = Constant.ROLE_ACCESSOR;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.currentUserRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.currentUserRoleCode = Constant.ROLE_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_TAX_OFFICER) {
        this.currentUserRoleCode = Constant.ROLE_TAX_OFFICER;
        break;
      } else if (role.code === Constant.ROLE_ACCOUNTANT) {
        this.currentUserRoleCode = Constant.ROLE_ACCOUNTANT;
        break;
      }
    }
  }

  get f() {
    return this.rejectForm.controls;
  }

  get f1() {
    return this.furtherRequirementForm.controls;
  }

  openFurtherRequirementPopUp() {
    this.showDynamicGrid = true;
    this.openPopupMessage(this.enterRemark);
  }

  openPopupMessage(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static'});

    this.rejectForm = this.formBuilder.group({
      remarkTextarea: ['', [Validators.required]]
    });

    this.furtherRequirementForm = this.formBuilder.group({
      furtherRequirementTextarea: ['', [Validators.required]]
    });
  }

  uploadChangeFurtherRequirement(event) {
    this.files = event.target.files;
    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      if (file.type === 'image/png' || file.type === 'image/jpeg') {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          this.imgURL = reader.result;
          this.documentUploaded = 'image';
          this.images.push(_event.currentTarget.result);
          this.originalImages.push(_event.currentTarget.result);
          this.furtherRequirementFile = file;
        };
        this.names.push(file.name);
      } else if (file.type == 'application/pdf') {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          this.imgURL = 'assets/images/adobe-pdf-icon.png';
          this.images.push('assets/images/adobe-pdf-icon.png');
          this.documentUploaded = 'pdf';
          this.furtherRequirementFile = file;
        };
      } else {
        this.notifier.notify('error', 'File type not allowed');
        event.srcElement.value = null;
      }
    }
  }

  requestFurtherReqRemark() {
    this.furtherRequirementModel.refundId = this.route.snapshot.paramMap.get('id');
    this.userName = this.userdata.firstName + ' ' + this.userdata.lastName;
    this.furtherRequirementModel.userName = this.userName;
    this.furtherRequirementModel.userRole = this.currentUserRoleCode;
    this.furtherRequirementModel.remark = this.furtherRequirementRemarkContent;
    this.furtherRequirementModel.furtherRequirementPosition = 'ALL';
    this.furtherRequirementModel.uploadedFile = this.furtherRequirementFile;

    this.refundRequestService.requestFurtherRequirement(this.furtherRequirementModel).subscribe(data => {
      console.log(data);

      this.notificationObject.deedNature = 'Refund-' + data.data.deedNature;
      this.notificationObject.subject = Constant.REQUESTED_FOR_FURTHER_REQUIREMENT;
      this.notificationObject.url = data.data.id;
      this.notificationObject.categoryStatus = Constant.NOTIFICATION_CATEGORY_REFUND;
      this.notificationObject.userId = data.data.userId;
      this.notificationObject.userRoleCode = Constant.ROLE_NOTARY;

      this.notificationService
        .createNotification(this.notificationObject)
        .subscribe((notaryData) => {
          console.log(notaryData);
          if (data.data.commissionerId) {
            this.notificationObject.userId = data.data.commissionerId;
            this.notificationObject.userRoleCode = Constant.ROLE_COMMISSIONER;
            this.notificationService
              .createNotification(this.notificationObject)
              .subscribe((taxOfData) => {
                console.log(taxOfData);
                if (data.data.dcommissionerId) {
                  this.notificationObject.userId = data.data.dcommissionerId;
                  this.notificationObject.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
                  this.notificationService
                    .createNotification(this.notificationObject)
                    .subscribe((dComData) => {
                      console.log(dComData);
                      if (data.data.assessorId) {
                        this.notificationObject.userId = data.data.assessorId;
                        this.notificationObject.userRoleCode = Constant.ROLE_ACCESSOR;
                        this.notificationService
                          .createNotification(this.notificationObject)
                          .subscribe((assData) => {
                            console.log(assData);
                            if (data.data.taxOfficerId) {
                              this.notificationObject.userId = data.data.taxOfficerId;
                              this.notificationObject.userRoleCode = Constant.ROLE_TAX_OFFICER;
                              this.notificationService
                                .createNotification(this.notificationObject)
                                .subscribe((taxOfData) => {
                                  console.log(taxOfData);
                                });
                            }
                          });
                      }
                    });
                }
              });
          }
        });
    });

    // this.valuationRequestService.requestFurtherRequirement(this.furtherRequirementModel).subscribe(
    //   data => {
    //     console.log(data);
    //     if (data.data === true) {

    // **** SEND NOTIFICATIONS - START ****
    // this.valuationRequestService.findByValuationRequestId(+this.route.snapshot.paramMap.get('id')).subscribe(
    //   data => {
    //     this.deedNatureValue = data.data.deedNature;
    //     this.assessorUserId = data.data.accessorId;
    //     this.dComUserId = data.data.dcommissionerId;
    //     this.comUserId = data.data.commissionerId;
    //     this.notaryUserId = data.data.user_id;

    //     // ** SEND SYSTEM NOTIFICATION TO NOTARY - START **
    //     this.notificationModel.userRoleCode = Constant.ROLE_NOTARY;
    //     this.notificationModel.deedNature = this.deedNatureValue;
    //     this.notificationModel.url = this.route.snapshot.paramMap.get('id');
    //     this.notificationModel.subject = Constant.REQUESTED_FOR_FURTHER_REQUIREMENT;
    //     this.notificationModel.userId = this.notaryUserId;

    //     this.notificationService.createNotification(this.notificationModel).subscribe(
    //       data => {
    //         console.log(data);
    //       }
    //     )
    //     // ** SEND SYSTEM NOTIFICATION TO NOTARY - END **

    //     if (this.currentUserRoleCode == Constant.ROLE_ACCESSOR) {
    //       this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
    //       this.notificationModel.deedNature = this.deedNatureValue;
    //       this.notificationModel.url = this.route.snapshot.paramMap.get('id');
    //       this.notificationModel.subject = Constant.REQUESTED_FOR_FURTHER_REQUIREMENT;
    //       this.notificationModel.userId = this.comUserId;

    //       this.notificationService.createNotification(this.notificationModel).subscribe(
    //         data => {
    //           console.log(data);

    //           // *** SEND SYSTEM NOTIFICATION TO DEP. COMMISSIONER - START ***
    //           this.notificationModel.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
    //           this.notificationModel.deedNature = this.deedNatureValue;
    //           this.notificationModel.url = this.route.snapshot.paramMap.get('id');
    //           this.notificationModel.subject = Constant.REQUESTED_FOR_FURTHER_REQUIREMENT;
    //           this.notificationModel.userId = this.dComUserId;

    //           this.notificationService.createNotification(this.notificationModel).subscribe(
    //             data => {
    //               console.log(data);
    //             }
    //           )
    //           // *** SEND SYSTEM NOTIFICATION TO DEP. COMMISSIONER - END ***

    //         }
    //       )
    //     } else if (this.currentUserRoleCode == Constant.ROLE_DEPUTY_COMMISSIONER) {
    //       this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
    //       this.notificationModel.deedNature = this.deedNatureValue;
    //       this.notificationModel.url = this.route.snapshot.paramMap.get('id');
    //       this.notificationModel.subject = Constant.REQUESTED_FOR_FURTHER_REQUIREMENT;
    //       this.notificationModel.userId = this.comUserId;

    //       this.notificationService.createNotification(this.notificationModel).subscribe(
    //         data => {
    //           console.log(data);
    //         }
    //       )
    //     }
    //   }
    // )
    // **** SEND NOTIFICATIONS - END ****

    //     }
    //   }
    // )
    this.notifier.notify('success', 'Successfully sent for Further Requirement');
    this.router.navigate(['/refund-internal-user-dashboard']);

    this.modalService.dismissAll();
  }

  /* REQUEST FURTHER REQUIREMENT - END */

  closeModal() {
    this.modalService.dismissAll();
  }

  /* REJECT APPLICATION - START */
  openRejectPopUp() {
    this.showDynamicGrid = true;
    this.openPopupMessage(this.enterRejectRemark);
  }

  // rejectConfirmation() {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "Do You Want To Reject This Opinion?",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes, Reject It!',
  //     cancelButtonText: 'No, Decline!'
  //   }).then((result) => {
  //     if (result.value) {
  //       // Swal.fire(
  //       //   'Rejected!',
  //       //   'Opinion has been rejected.',
  //       //   'success'
  //       // )
  //       this.openRejectPopUp();
  //     } else if (
  //       /* Read more about handling dismissals below */
  //       result.dismiss === Swal.DismissReason.cancel
  //     ) {
  //       // Swal.fire(
  //       //   'Declined',
  //       //   'Opinion Reject Declined',
  //       //   'success'
  //       // )
  //       // console.log('dec')
  //       this.rejectDecline();
  //     }
  //   })
  // }

  // rejectDecline(){
  //   this.valuationRequestService.declineReject(parseInt(this.route.snapshot.paramMap.get('id'))).subscribe(data=>{
  //     if(data.data == true){
  //       this.router.navigate(['dashboard-internal-user']);
  //       this.notifier.notify('success', 'Declined Successfully');

  //       // **** SEND NOTIFICATIONS - START ****
  //       // this.valuationRequestService.findByValuationRequestId(+this.route.snapshot.paramMap.get('id')).subscribe(
  //       //   data => {
  //       //     this.deedNatureValue = data.data.deedNature;

  //       //     this.assessorUserId = data.data.accessorId;
  //       //     this.dComUserId = data.data.dcommissionerId;
  //       //     this.comUserId = data.data.commissionerId;
  //       //     this.notaryUserId = data.data.user_id;

  //       //     this.notificationModel.userRoleCode = Constant.ROLE_ACCESSOR;
  //       //     this.notificationModel.deedNature = this.deedNatureValue;
  //       //     this.notificationModel.url = this.route.snapshot.paramMap.get('id');
  //       //     this.notificationModel.subject = "Declined Rejected";
  //       //     this.notificationModel.userId = this.assessorUserId;

  //       //     this.notificationService.createNotification(this.notificationModel).subscribe(
  //       //       data => {
  //       //         this.notificationModel.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
  //       //         this.notificationModel.deedNature = this.deedNatureValue;
  //       //         this.notificationModel.url = this.route.snapshot.paramMap.get('id');
  //       //         this.notificationModel.subject = "Declined Rejected";
  //       //         this.notificationModel.userId = this.dComUserId;

  //       //         this.notificationService.createNotification(this.notificationModel).subscribe();
  //       //       })
  //       //   })
  //         // **** SEND NOTIFICATIONS - END ****
  //     }else{
  //       this.notifier.notify('error', 'Application not found');
  //     }

  //   });
  // }

  rejectValuationRequest() {
    this.rejectModel.valuationId = localStorage.getItem('refundRequest');
    console.log(localStorage.getItem('refundRequest'));
    this.userName = this.userdata.firstName + ' ' + this.userdata.lastName;
    this.rejectModel.userName = this.userName;
    this.rejectModel.userRole = this.currentUserRoleCode;
    this.rejectModel.remark = this.applicationRejectRemarkContent;
    this.rejectModel.furtherRequirementPosition = 'DOCUMENTS';
    this.rejectModel.uploadedFile = this.rejectFile;

    this.refundRequestService.rejectApplication(this.rejectModel).subscribe(
      data => {
        this.router.navigate(['/refund-internal-user-dashboard']);
        this.notifier.notify('success', 'Refund Application Rejected');

        // **** SEND NOTIFICATIONS - START ****
        // console.log(this.refundApplicationId)
        // this.refundRequestService.getRefundRequestDetailsById(this.refundApplicationId).subscribe(
        //   data => {
        //     console.log(data)
        // this.deedNatureValue = data.data.deedNature;
        // this.assessorUserId = data.data.accessorId;
        // this.dComUserId = data.data.dcommissionerId;
        // this.comUserId = data.data.commissionerId;
        // this.notaryUserId = data.data.user_id;


        // if (this.currentUserRoleCode == Constant.ROLE_ACCESSOR) {
        //   this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
        //   this.notificationModel.deedNature = this.deedNatureValue;
        //   this.notificationModel.url = this.route.snapshot.paramMap.get('id');
        //   this.notificationModel.subject = Constant.APPLICATION_REJECTED;
        //   this.notificationModel.userId = this.comUserId;

        //   this.notificationService.createNotification(this.notificationModel).subscribe(
        //     data => {
        //       console.log(data);

        //// *** SEND NOTIFICATION TO DEP. COMMISSIONER - START ***
        // this.notificationModel.userRoleCode = Constant.ROLE_ACCESSOR;
        // this.notificationModel.deedNature = this.deedNatureValue;
        // this.notificationModel.url = this.route.snapshot.paramMap.get('id');
        // this.notificationModel.dataStatus = "ACTIVE";

        // this.valuationRequestService.createNotification(this.notificationModel).subscribe(
        //   data => {
        //     console.log(data);
        //   }
        // )
        //// *** SEND NOTIFICATION TO DEP. COMMISSIONER - END ***

        //         }
        //       )
        //     } else if (this.currentUserRoleCode == Constant.ROLE_DEPUTY_COMMISSIONER) {
        //       this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
        //       this.notificationModel.deedNature = this.deedNatureValue;
        //       this.notificationModel.url = this.route.snapshot.paramMap.get('id');
        //       this.notificationModel.subject = Constant.APPLICATION_REJECTED;
        //       this.notificationModel.userId = this.comUserId;

        //       this.notificationService.createNotification(this.notificationModel).subscribe(
        //         data => {
        //           console.log(data);
        //         }
        //       )
        //     } else if (this.currentUserRoleCode == Constant.ROLE_COMMISSIONER) {
        //       this.notificationModel.userRoleCode = Constant.ROLE_NOTARY;
        //       this.notificationModel.deedNature = this.deedNatureValue;
        //       this.notificationModel.url = this.route.snapshot.paramMap.get('id');
        //       this.notificationModel.subject = Constant.APPLICATION_REJECTED;
        //       this.notificationModel.userId = this.notaryUserId;

        //       this.notificationService.createNotification(this.notificationModel).subscribe(
        //         data => {
        //           console.log(data);
        //           this.notificationEmailSendModel.valuationRequestId = +this.route.snapshot.paramMap.get('id');
        //           this.notificationService.sendEmailNotification(this.notificationEmailSendModel).subscribe(
        //             data => {
        //               console.log(data);
        //             }
        //           )
        //         }
        //       )
        //     }
        //   }
        // )
        // **** SEND NOTIFICATIONS - END ****

      }
    );

    this.modalService.dismissAll();
  }

  getRejectRemark() {
    return this.rejectForm.get('remarkTextarea').value;
  }

  uploadChangeReject(event) {
    this.files = event.target.files;
    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      if (file.type === 'image/png' || file.type === 'image/jpeg') {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          this.imgURL2 = reader.result;
          this.documentUploaded1 = 'image';
          this.images.push(_event.currentTarget.result);
          this.originalImages.push(_event.currentTarget.result);
          this.rejectFile = file;
        };
        this.names.push(file.name);
      } else if (file.type == 'application/pdf') {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          this.imgURL2 = 'assets/images/adobe-pdf-icon.png';
          this.documentUploaded1 = 'pdf';
          this.rejectFile = file;
        };
      } else {
        this.notifier.notify('error', 'File type not allowed here');
        event.srcElement.value = null;
      }
    }
  }

  /* REJECT APPLICATION - END */

}
