import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import Swal from "sweetalert2";
import Cropper from "cropperjs";
import {SafeResourceUrl} from "@angular/platform-browser";
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-file-upload-assessment',
  templateUrl: './file-upload-assessment.component.html',
  styleUrls: ['./file-upload-assessment.component.scss']
})
export class FileUploadAssessmentComponent implements OnInit {
  selectedElement: SafeResourceUrl;
  pdfsrc: null;

  constructor() {
  }

  @Output()
  outPutImagesNotice = new EventEmitter<File[]>();
  filesListNotice: File[] = [];
  imagesNotice = [];
  originalImagesNotice: any[] = [];
  namesNotice: string[] = [];
  selectedIndex = 0;
  filesNotice;
  showEdit = false;
  showEditPdf = false;

  public progress = 0;

  cropper;
  imgid=uuidv4();
  ngOnInit() {
  }

  uploadChangeNotice(event) {
    this.filesNotice = event.target.files;
    this.progress = 0;
    for (let i = 0; i < this.filesNotice.length; i++) {
      const file = this.filesNotice[i];
      if (file.type === "application/pdf" || file.type === "image/png" || file.type === "image/jpeg"|| file.type === "image/jpg") {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          this.progress = 100;
          this.imagesNotice.push(_event.currentTarget.result);
          this.originalImagesNotice.push(_event.currentTarget.result);
          this.filesListNotice.push(file);
        };
        this.namesNotice.push(file.name);
        this.progress = 0;
      } else {

        Swal.fire({
          text: 'File type not allowed here!',
          icon: 'warning',
          showCancelButton: false,

        });
        event.srcElement.value = null;
      }
    }

  }

  changeImg(event) {

    if (this.cropper !== undefined) {
      this.cropper.destroy();
    }
    const image = document.getElementById(this.imgid) as HTMLImageElement;
    image.src = event.target.src;
    this.cropper = new Cropper(image, {
      crop(event) {
      },
    });
  }

  outPutImagesSet() {
    this.outPutImagesNotice.emit(this.filesListNotice);
  }

  edit(event, index) {

    if (this.filesListNotice[index].type === "application/pdf") {
      this.showEdit = false;
      this.showEditPdf = true;
      this.pdfsrc = null;
      this.pdfsrc = this.imagesNotice[index];
      // document.getElementById('myiframe-notice').setAttribute('src',this.imagesNotice[index])
    } else {
      this.showEdit = true;
      this.showEditPdf = false;
      const image = document.getElementById(this.imgid) as HTMLImageElement;
      image.src = event.target.parentElement.parentElement.children[0].src;
      if (this.cropper !== undefined) {
        this.cropper.destroy();
      }
      this.cropper = new Cropper(image, {
        rotatable: true,
        crop(event) {

        },
      });
    }

    this.selectedIndex = index;
  }

  delete(index) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4085d6',
      cancelButtonColor: '#d44',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.imagesNotice.splice(index, 1);
        this.originalImagesNotice.splice(index, 1);
        this.namesNotice.splice(index, 1);
        this.filesListNotice.splice(index, 1);
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );

      }
    });

    // this.images.splice(index, 1);
    // this.originalImages.splice(index, 1);
    // this.names.splice(index, 1);
    // this.filesList.splice(index, 1);


  }

  closeEditor() {
    this.showEdit = false;
    this.showEditPdf = false;
  }

  rotate(angle: number) {
    this.cropper.move(1, -1).rotate(angle);
  }

  resetImage() {
    this.cropper.destroy();
    this.imagesNotice[this.selectedIndex] = this.originalImagesNotice[this.selectedIndex];
    const image = document.getElementById(this.imgid) as HTMLImageElement;
    image.src = this.imagesNotice[this.selectedIndex];
    this.cropper = new Cropper(image, {
      rotatable: true,
      crop(event) {
      },
    });
  }

  crop() {
    this.imagesNotice[this.selectedIndex] = this.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    this.cropper.destroy();

    this.imagesNotice[this.selectedIndex] = this.imagesNotice[this.selectedIndex];
    const image = document.getElementById(this.imgid) as HTMLImageElement;
    image.src = this.imagesNotice[this.selectedIndex];
    this.cropper = new Cropper(image, {
      rotatable: true,
      crop(event) {
      },
    });
  }

}
