import {Component, OnInit, ViewChild} from '@angular/core';
import {ViewPropertyUsageSubtypeComponent} from './view-property-usage-subtype/view-property-usage-subtype.component';

@Component({
  selector: 'app-property-usage-subtype',
  templateUrl: './property-usage-subtype.component.html',
  styleUrls: ['./property-usage-subtype.component.scss']
})
export class PropertyUsageSubtypeComponent implements OnInit {

  @ViewChild(ViewPropertyUsageSubtypeComponent, {static: false})
  private viewPropertyUsageSubtypeComponent: ViewPropertyUsageSubtypeComponent;
  keyword: string;

  constructor() { }

  ngOnInit() {
  }

  triggerSearch(event: any) {
    if (this.keyword === '') {
      this.viewPropertyUsageSubtypeComponent.onUpdated(true);
    } else {
      this.viewPropertyUsageSubtypeComponent.searchPropertyUsageSubType(this.keyword);
    }
  }

}
