import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DocumentTypeService } from 'src/app/service/document-type/document-type.service';
import { DocumentType } from 'src/app/model/document-type';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-update-document-type',
  templateUrl: './update-document-type.component.html',
  styleUrls: ['./update-document-type.component.scss']
})
export class UpdateDocumentTypeComponent implements OnInit, OnDestroy {

  updateDocumentTypeForm: FormGroup;
  private sub = new SubSink();
  description: string;
  code: string;

  @Output() documentTypeUpdate = new EventEmitter<boolean>();

  constructor(private formBuilder: FormBuilder,
    private documentTypeService: DocumentTypeService,
    private notifier: NotifierService) { }

  ngOnInit() {
    this.updateDocumentTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      code: ['', [Validators.required]],
      name: ['', [Validators.required]],
      description: ['', [Validators.required]]
    })
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updateDocumentType() {
    if (this.updateDocumentTypeForm.valid) {
      const documentType = new DocumentType();
      documentType.description = this.getDescription();
      documentType.code = this.getCode();
      documentType.name=this.getName();
      this.sub.add(this.documentTypeService.updateDocumentType(this.getId(), documentType)
        .subscribe(response => {
          this.updateTheView(true);
          this.notifier.notify( 'success', (response as any).message);
        
          }));
    }
  }

  getId() {
    return this.updateDocumentTypeForm.get('id').value;
  }
  setIdAndDescription(id: number, code: string,name:string, description: string) {
    this.updateDocumentTypeForm.get('id').setValue(id);
    this.updateDocumentTypeForm.get('code').setValue(code);
    this.updateDocumentTypeForm.get('name').setValue(name);
    this.updateDocumentTypeForm.get('description').setValue(description);
  }

  getDescription() {
    return this.updateDocumentTypeForm.get('description').value;
  }
  getName() {
    return this.updateDocumentTypeForm.get('name').value;
  }
  getCode() {
    return this.updateDocumentTypeForm.get('code').value;
  }

  updateTheView(updated: boolean) {
    this.documentTypeUpdate.emit(updated);
  }

}
