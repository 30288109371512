import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { DashboardService } from 'src/app/service/dashboard/dashboard.service';
import { NotificationModelUpdate } from 'src/app/model/notification-model-update';
import { Constant } from 'src/app/util/constant';
import { AuthService } from 'src/app/auth/auth.service';
import { RefundOpinionServiceService } from 'src/app/service/RefundOpinionService/refund-opinion-service.service';

@Component({
  selector: 'app-header-internal-user',
  templateUrl: './header-internal-user.component.html',
  styleUrls: ['./header-internal-user.component.scss']
})
export class HeaderInternalUserComponent implements OnInit {

  public notificationModelUpdate = new NotificationModelUpdate();

  refundNotificationCode = Constant.NOTIFICATION_CATEGORY_REFUND
  opinionNotificationCode = Constant.NOTIFICATION_CATEGORY_OPINION
  appealNotificationCode = Constant.NOTIFICATION_CATEGORY_APPEAL
  rimNotificationCode = Constant.NOTIFICATION_CATEGORY_RIM

  selectedComponent: string;
  numberOfNotifications: number;
  notifications: any = [];
  userName: string;
  showDashboard: boolean;
  showReports: boolean;
  userRole: string;

  userdata: any = [];
  userrolecode: string;

  constructor(
    private rout: ActivatedRoute,
    private  router: Router,
    private authService: AuthService,
    private dashboardService: DashboardService,
    private refundService:RefundOpinionServiceService
  ) {
    this.showDashboard = true;
    this.showReports = false;
  }

  ngOnInit() {
    // this.selectedComponent = sessionStorage.getItem('selectedType');
    this._prepare();
    this.userName = sessionStorage.getItem('username');


    if (sessionStorage.getItem('selectedHeader') == 'reports') {
      this.showReports = true;
      this.showDashboard = false;
    } else {
      this.showReports = false;
      this.showDashboard = true;
    }
  }

  obtainUserData() {
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.userrolecode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.userrolecode = Constant.ROLE_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.userrolecode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.userrolecode = Constant.ROLE_ACCESSOR;
        break;
      }
    }
  }

  _prepare() {
    this.listNotifications();
    this.obtainUserData();
  }

  clickGift() {
    this.selectedComponent = 'GIFT';
    this.router.navigate(['/new-application/gift']);
  }
  clickTransfer() {
    this.selectedComponent = 'Transfer';
    this.router.navigate(['/new-application/transfer']);
  }

  clickExchange() {
    this.selectedComponent = 'Exchange';
    this.router.navigate(['/new-application/exchange']);
  }

  listNotifications() {
    this.dashboardService.getNotificationList().subscribe(
      data => {
        this.notifications = data.data;
        this.numberOfNotifications = this.notifications.length;
      }
    )
  }



  updateNotification(notificationId, deedNature, url) {

    this.refundService.getRefundRequestByID(parseInt(url)).subscribe( (res) => {
   
      localStorage.removeItem('refundRequest');
      sessionStorage.removeItem('hasOpinion');
      sessionStorage.removeItem('deedNo');
    
      localStorage.setItem('refundRequest' , res.data.id);
      sessionStorage.setItem('hasOpinion', res.data.hasOpinion);
      sessionStorage.setItem('deedNo', res.data.deedNumber);
      sessionStorage.setItem('refundRequest','true');
    
            })

    this.notificationModelUpdate.notificationId = notificationId;

    this.dashboardService.updateNotification(this.notificationModelUpdate).subscribe(
      data => {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          if(deedNature == 'Gift'){
            this.router.navigate(['/view-internal-user-application/', url]);
          } else if(deedNature == 'Transfer'){
            this.router.navigate(['/view-internal-user-application/', url]);
          } else if(deedNature == 'Exchange'){
            this.router.navigate(['/view-internal-exchange-application/', url]);
          }
          // this.router.navigate(['/view-internal-user-application/', this.route.snapshot.paramMap.get('id')]);
          // this.router.navigate([url]);
        });
      }
    )
  }

  logout(){
    this.authService.logout();
  }

}
