export class Constant {
  public static VALUATION_REQUEST_DRAFT_STATUS = 'DRAFT';
  public static VALUATION_REQUEST_COMPLETE_STATUS = 'PENDING';

  public static CALCULATION_LAND = 'land';
  public static CALCULATION_LAND_BUILDING = 'land-with-building';
  public static CALCULATION_CONDOMINIUM = 'condominium';
  public static CALCULATION_BUILDING = 'building';
  public static CALCULATION_RECREATION = 'recreation';

  // User Role Status Codes

  public static ROLE_SUPER_ADMIN = 'RO-0001';
  public static ROLE_COMMISSIONER = 'RO-0005';
  public static ROLE_DEPUTY_COMMISSIONER = 'RO-0006';
  public static ROLE_ACCESSOR = 'RO-0004';
  public static ROLE_NOTARY = 'RO-0003';
  public static ROLE_Collection_Branch = 'RO-0007';
  public static ROLE_TAX_OFFICER = 'RO-0008';
  public static ROLE_ACCOUNTANT = 'RO-0009';


  // Valuation Request Status Codes
  public static VALUATION_REQUEST_FURTHER_REQUIREMENT_CODE = 'FURTHER_REQUIREMENT'; // Further Requirement
  public static VALUATION_REQUEST_PENDING_CODE = 'PENDING'; // New Application
  public static VALUATION_REQUEST_INPROGRESS_CODE = 'INPROGRESS'; // Inprogress Application
  public static VALUATION_REQUEST_COMPLETED_CODE = 'COMPLETED'; // Completed Application
  public static VALUATION_REQUEST_REJECTED_CODE = 'REJECTED'; // Rejected Application
  public static VALIATION_PAYMENT_PENDING = 'PAYPEND' //Payment Pending
  public static VALIATION_COMPLETED_APPLICATION = 'APPLICATION_COMPLETED' //Payment Completed
  public static VALIATION_PAYMENT_COMPLETED = 'PAYMENT_COMPLETED' //Payment Completed
  public static VALIATION_SCHEDULE_CODE = 'SCHEDULE' //Payment Completed
  public static VALIATION_IRRELEVANT_REQUEST_CODE = 'IRRELEVANT_REQUEST' //Irrelevant Completed


  public static PAYMENT_STATUS_PENDING = 'PENDING';
  public static PAYMENT_STATUS_SUCCEED = 'SUCCEED';

  public static PAYMENT_REPORT_STATUS = 'PAYMENT RECEIPT';
  public static DEED_DOCUMENT_STATUS = 'DEED DOCUMENTS';

  public static INSPECTION_REPORT_LAND_RESIDENTIAL = 1
  public static INSPECTION_REPORT_LAND_COMMERCIAL = 2
  public static INSPECTION_REPORT_LAND_AGRICULTURE = 3
  public static INSPECTION_REPORT_LAND_MIXMODE = 4
  public static INSPECTION_REPORT_LAND_OTHER = 5
  public static INSPECTION_REPORT_LAND_RECREATION = 6

  public static INSPECTION_TYPE_LAND = 'land';
  public static INSPECTION_TYPE_CONDOMINIUM = 'condominium';
  public static INSPECTION_TYPE_BUILDING = 'building';

  public static INSPECTION_TYPE_RECREATION_LAND = 'recreation_land';
  public static INSPECTION_TYPE_RECREATION_CONDOMINIUM = 'recreation_condominium';
  public static INSPECTION_TYPE_RECREATION_LAND_BUILDING = 'recreation_land_building';
  
  public static INSPECTION_TYPE_RECREATION_LAND_BUILDING_LAND = 'recreation_land_building-LAND';
  public static INSPECTION_TYPE_RECREATION_LAND_BUILDING_BUILDING = 'recreation_land_building-BUILDING';



  public static INSPECTION_PROPERTY_TYPE_LAND_ID = 1;
  public static INSPECTION_PROPERTY_TYPE_CONDOMINIUM_ID = 2;
  public static INSPECTION_PROPERTY_TYPE_LAND_BUILDING_ID = 3;
  public static INSPECTION_PROPERTY_TYPE_RECREATION_ID = 4;

  // Notificaton subjects
  public static ASSIGNED_TO_DEPUTY_COMMISSIONER_AND_ASSESSOR = 'New Application';//ok-> new application view---
  public static IRRELEVANT_APPLICATION_REJECTED = 'Rejected Irrelevant Application';
  public static REQUESTED_FOR_FURTHER_REQUIREMENT = 'Requested for Further Requirement';//ok->destroy at further - req view
  public static SEND_FURTHER_REQUIREMENT = 'Further Requirement Details Sent'//ok - granter view
  public static APPLICATION_REJECTED = 'Rejected Application';//ok->destroy at Reject - req view
  public static SITE_VISIT_SCHEDULE_CREATED = 'New Site Visit Schedule';
  public static STAMP_DUTY_LETTER_CREATED = 'SDOP Letter Created'; //ok->letter view - openOpinoinLetter
  public static STAMP_DUTY_CERTIFICATE_CREATED = 'SDOP Certificate Created'; //ok-> com - approve button setCertificateState()
  public static CERTIFICATE_APPROVED_BY_COMMISSIONER = 'SDOP Certificate Approved'; //ok->payment endorsment
  public static PAYMENT_MADE_BY_NOTARY = 'New Payment made'; //ok-> payment tab
  public static PAYMENT_CONFIRMED_BY_ASSESSOR = 'Payment Confirmed'; //completed
  public static NEW_REFUND_APPLICATION = 'New Refund Application';


  // Refund Request Status Codes
  public static REFUND_REQUEST_DRAFT_CODE = 'DRAFT'; // Draft Application
  public static REFUND_REQUEST_PENDING_CODE = 'NEW'; // New Application
  public static REFUND_REQUEST_INPROGRESS_CODE = 'INPROGRESS'; // INPROGRESS Application
  public static REFUND_REQUEST_FURTHER_REQUIERMENT_CODE = 'FURTHER_REQUIREMENT';
  public static REFUND_REQUEST_COMPLETED_CODE = 'COMPLETED'; // Completed Application
  public static REFUND_REQUEST_REJECTED_CODE = 'REJECTED'; // Rejected Application
  public static REFUND_PAYMENT_PENDING = 'PAYPEND' //Payment Pending
  public static REFUND_COMPLETED_APPLICATION = 'APPLICATION_COMPLETED' //Payment Completed
  public static REFUND_PAYMENT_COMPLETED = 'PAYMENT_COMPLETED' //Payment Completed
  public static REFUND_SCHEDULE_CODE = 'SCHEDULE' //Payment Completed
  public static OTHER_REFUND_REASON_CODE = 'RROther'//other refund reason code


  // RIM Request Status Codes
  public static RIM_REQUEST_PENDING_CODE = 'NEW'; // New Application
  public static RIM_REQUEST_INPROGRESS_CODE = 'IN_PROGRESS'; // INPROGRESS Application
  public static RIM_REQUEST_FURTHER_REQUIERMENT_CODE = 'FURTHER_REQUIREMENT';
  public static RIM_REQUEST_COMPLETED_CODE = 'COMPLETED'; // Completed Application
  public static RIM_REQUEST_REJECTED_CODE = 'REJECTED'; // Rejected Application
  public static RIM_PAYMENT_PENDING = 'PAYPEND'; // Payment Pending
  public static RIM_COMPLETED_APPLICATION = 'COMPLETED'; // Application Completed
  public static RIM_PAYMENT_COMPLETED = 'PAYMENT_COMPLETED' // Payment Completed
  public static RIM_SCHEDULE_CODE = 'SCHEDULE'; // Payment Completed
  public static OTHER_RIM_REASON_CODE = 'RROther'; // other refund reason code

  // NOTIFICATION CAT STATUS
  public static NOTIFICATION_CATEGORY_OPINION = 'OPINION'
  public static NOTIFICATION_CATEGORY_REFUND = 'REFUND'
  public static NOTIFICATION_CATEGORY_RIM = 'REIMBURSMENT'
  public static NOTIFICATION_CATEGORY_REGISTRATION = 'REGISTRATION'
  public static NOTIFICATION_CATEGORY_APPEAL = 'APPEAL'

}
