import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubSink} from 'subsink';
import {NotifierService} from 'angular-notifier';
import {Property} from '../../../../../../../model/property';

@Component({
  selector: 'app-property-extend-fr',
  templateUrl: './property-extend-fr.component.html',
  styleUrls: ['./property-extend-fr.component.scss']
})
export class PropertyExtendFrComponent implements OnInit {

  @Input() propertyOne: Property;
  propertyExtentForm: FormGroup;
  txtValue: string = null;
  convtoval: number;
  units = new Map().set('feet', 1)
    .set('perch', 272.25)
    .set('acre', 43560)
    .set('hectare', 107639);
  // Validation
  onlyNumbersPattern = '^[0-9.]*$';
  onlyCharactersNumbersPattern = '^[_A-z0-9 ]*((-|\\s)*[_A-z0-9 ])*$';
  private sub = new SubSink();

  constructor(private formBuilder: FormBuilder,
              private notifier: NotifierService) {
  }

  get f() {
    return this.propertyExtentForm.controls;
  }

  ngOnInit() {
    this.propertyExtentForm = this.formBuilder.group({
      otherDetails: [this.propertyOne.otherDetails],
      convfrom: [this.propertyOne.extentFromUnit, [Validators.required]],
      convto: [this.propertyOne.extentToUnit, [Validators.required]],
      convfromval: [this.propertyOne.extentFromValue, [Validators.required, Validators.pattern(this.onlyNumbersPattern)]],
      convtoval: [this.propertyOne.extentToValue, [Validators.required]]
    });
  }

  isDisabled() {
    if (this.propertyExtentForm.get('convfrom').value != null) {
      this.propertyExtentForm.get('convto').enable();
      this.propertyExtentForm.get('convtoval').enable();
      const fromUnit = this.propertyExtentForm.get('convfrom').value;
      const toUnit = this.propertyExtentForm.get('convto').value;
      const convfromval = this.propertyExtentForm.get('convfromval').value;
      this.convtoval = convfromval * this.units.get(fromUnit) / this.units.get(toUnit);
    } else {
      this.propertyExtentForm.get('convto').disable();
      this.propertyExtentForm.get('convto').setValue(null);
      this.propertyExtentForm.get('convtoval').disable();
    }
  }

  saveExtentDetails(): boolean {
    this.propertyExtentForm.markAllAsTouched();
    if (this.propertyExtentForm.valid) {
      this.setPropertyExtentDetails();
      return true;
    } else {
      return false;
    }
  }

  setPropertyExtentDetails(): void {
    this.propertyOne.otherDetails = this.propertyExtentForm.get('otherDetails').value;
    this.propertyOne.extentToUnit = this.propertyExtentForm.get('convto').value;
    this.propertyOne.extentFromUnit = this.propertyExtentForm.get('convfrom').value;
    this.propertyOne.extentFromValue = this.propertyExtentForm.get('convfromval').value;
    this.propertyOne.extentToValue = this.propertyExtentForm.get('convtoval').value;
    localStorage.setItem('extend', JSON.stringify(this.propertyOne));
  }
}
