import {Component, OnInit, ViewChild} from '@angular/core';
import {ViewCompanyCategoryComponent} from '../company/company-category/view-company-category/view-company-category.component';
import {ViewOfficeComponent} from './view-office/view-office.component';

@Component({
  selector: 'app-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.scss']
})
export class OfficeComponent implements OnInit {

  @ViewChild(ViewOfficeComponent, {static: false})
  private viewOfficeComponent: ViewOfficeComponent;
  keyword: string;
  constructor() { }

  ngOnInit() {
  }
  triggerSearch(event: any) {
    if (this.keyword === '') {
      this.viewOfficeComponent.onUpdated(true);
    } else {
      this.viewOfficeComponent.searchOffice(this.keyword);
    }
  }

}
