import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PaymentMethodService } from 'src/app/service/payment-method/payment-method.service';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-delete-payment-method',
  templateUrl: './delete-payment-method.component.html',
  styleUrls: ['./delete-payment-method.component.scss']
})
export class DeletePaymentMethodComponent implements OnInit, OnDestroy {


  private paymentMethodId: number;

  private sub = new SubSink();

  @Output() paymentMethodDeleted = new EventEmitter<boolean>();



  constructor(private paymentMethodService: PaymentMethodService, private notifier: NotifierService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  deletePaymentMethod() {
    this.sub.add(this.paymentMethodService.deletePaymentMethod(this.paymentMethodId)
      .subscribe(response => {
        this.updateTheView(true);
        this.notifier.notify('success', (response as any).message);
      }));
  }

  updateTheView(deleted: boolean) {
    this.paymentMethodDeleted.emit(deleted);
  }

  setPaymentMethodId(id: number) {
    this.paymentMethodId = id;
  }

}



