import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ValuationStatus } from 'src/app/model/valuation-status';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GranterService {
  private requestUrl = '/granter';  // URL to web api
  private subject = new Subject<any>();
  constructor(
    private http: HttpClient
  ) { }

  sendGranterStatusChange(){
    this.subject.next();
  }

  getGranterStatusChange():Observable<any>{
    return this.subject.asObservable();
  }

  granterVerificationStatus(ValuationStatus: ValuationStatus): Observable<any> {
    return this.http.post<any>(`${environment.api_url}` + this.requestUrl + '/status', ValuationStatus);
  }

  granterUpdateVerificationStatus(ValuationStatus: ValuationStatus): Observable<any> {
    return this.http.post<any>(`${environment.api_url}` + this.requestUrl + '/updatestatus', ValuationStatus);
  }

}
