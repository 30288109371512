import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {ListItem} from "ng-multiselect-dropdown/multiselect.model";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ReportServiceService} from "../../stamp-duty-opinion-reports/service/report-service.service";
import {NotifierService} from "angular-notifier";
import * as XLSX from "xlsx";

@Component({
  selector: 'app-final-balance-report',
  templateUrl: './final-balance-report.component.html',
  styleUrls: ['./final-balance-report.component.scss']
})
export class FinalBalanceReportComponent implements OnInit, AfterViewInit {

  fromDate: string = null;
  toDate: string = null;
  requestAmount: any;
  public download_excel_status: boolean = false;
  private showPrint: boolean;
  inputs: any = {};
  currentDate = new Date().toISOString().split('T')[0];

  //section data
  dropdownListST: any = [];
  selectedItemsST: any = [];
  dropdownSettingsST: IDropdownSettings = {};

  section = [
    {id: 1, name: 'Under 65'},
    {id: 2, name: 'Under 101'}
  ]

  //report footer data
  public total_paid_Amount: number = 0;
  public total_requested_Amount: number = 0;
  public total_Under_65: number = 0;
  public total_Under_101: number = 0;


  @ViewChild('TABLE', {static: false}) TABLE: ElementRef;

  public reportDetails: any = [];

  constructor(public modalService: NgbModal,
              public reportService: ReportServiceService,
              private notifier: NotifierService) {
  }

  ngOnInit() {

    //setup section dropdown
    this.dropdownSettingsST = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

    this.get_all_section_type();

  }

  ngAfterViewInit(): void {
    this.printReceipt();
  }

  get_all_section_type() {
    this.dropdownListST = this.section;
  }

  onItemSelectST($event: ListItem) {

  }

  onSelectAllST($event: Array<ListItem>) {

  }

  createExcel() {
    this.download_excel_status = true;
    this.get_excel_report();
  }

  generatePdfReport() {
    this.get_report();
  }

  closeFurtherReqRemark() {
    this.modalService.dismissAll();
  }

  //get details for Excel report
  get_excel_report() {
    if (this.captureInputs()) {
      this.reportService.getFinalBalanceReport(this.fromDate, this.toDate, this.inputs.selectedS_id,this.inputs.requested_amount).subscribe(value => {
        this.reportDetails = value.data.data;
        this.total_requested_Amount = value.data.totalRequestedAmount;
        this.total_paid_Amount = value.data.totalPaidAmount;
        this.total_Under_65 = value.data.underSectionOneAmount;
        this.total_Under_101 = value.data.underSectionTwoAmount;

        setTimeout(() => {
          var printContents = document.getElementById('excel_print');
          const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(printContents);
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Final_Bal.');
          XLSX.writeFile(wb, 'Final Balance(as at 2020) Report.xlsx');
        }, 1000);
      });
    }
  }


  captureInputs() {
    /*
        // capture selected from date
        if (this.fromDate == null) {
          this.notifier.notify('error', 'Please enter from date!');
          return false;
        }

        // capture selected to date
        if (this.toDate == null) {
          this.notifier.notify('error', 'Please enter to date!');
          return false;
        }*/

    this.currentDate = new Date().toISOString().slice(0, 10);

    let lastDate = "";
    if (this.toDate == null || this.toDate == "") {
      lastDate = this.currentDate;
    } else {
      lastDate = this.toDate;
    }

    var date_range = ((this.fromDate == null || this.fromDate == "") && (this.toDate == null || this.toDate == "")) ? "All Date" : (this.fromDate == null) ? ' to ' + lastDate : this.fromDate + ' to ' + lastDate;

    // capture selected section
    var selectedS_Names = "";
    var selectedS = "";
    if (this.selectedItemsST != null) {
      this.selectedItemsST.forEach(value => {
        selectedS += value.id + ",";
        selectedS_Names += value.name + ", ";
      });
    } else {
      selectedS_Names = "N/A";
      selectedS = "";
    }



    this.inputs = {
      requested_amount :this.requestAmount,
      selected_date: date_range,
      selectedS_id: selectedS,
      selectedS_names: selectedS_Names,
    };

    console.log(this.inputs);
    return true;
  }

  //get details for PDF doc
  get_report() { //requestedAmount need to input as a parameter
    if (this.captureInputs()) {
      this.reportService.getFinalBalanceReport(this.fromDate, this.toDate, this.inputs.selectedS_id,this.inputs.requested_amount).subscribe(value => {
        this.reportDetails = value.data.data;
        this.total_requested_Amount = value.data.totalRequestedAmount;
        this.total_paid_Amount = value.data.totalPaidAmount;
        this.total_Under_65 = value.data.underSectionOneAmount;
        this.total_Under_101 = value.data.underSectionTwoAmount;
        setTimeout(() => {
          // alert("asd");
          this.printReceipt();
        }, 1000);
      });
    }
  }

  printReceipt(): void {
    this.showPrint = true;
    let printContents: any;
    let popupWin: any;
    printContents = document.getElementById('receipt').innerHTML;

    popupWin = window.open();
    popupWin.document.open();
    popupWin.document.write(`
    <html>
    <head>
        <title>Final Balance as at 2020 Report</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <style>
          @page {
            size: legal  landscape;
            @bottom-right {
              content: "Page " counter(page) " of " counter(pages);
            }

            .page{
                padding-bottom: 20px;
                page-break-after: always;
            }
          }

            body
            {
                margin: 10mm 10mm 0mm 10mm;

            }
            table{
              border: 1px solid #000;
              border-collapse: collapse;
            }
            tbody{
              page-break-before: always;
            }

            tfoot tr td {
                border: 1px solid #ffffff;
            }

            table th, table td
            {
                padding: 5px;
                margin: 0px;
                border: 1px solid #000;
            }
            .page{
                padding-bottom: 20px;
                page-break-after: always;
            }

        </style>
    </head>
    <body onload="window.print();">
            ${printContents}
    </body>
    </html>`
    );
    popupWin.document.close();
  }
}
