import {AfterViewInit, Component, OnInit} from '@angular/core';
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ReportServiceService} from "../../stamp-duty-opinion-reports/service/report-service.service";
import {NotifierService} from "angular-notifier";
import {Constant} from "../../../../../../util/constant";
import {UserService} from "../../../../../../service/user/user.service";
import * as XLSX from "xlsx";
import {EmployeeService} from "../../../../../../service/employee/employee.service";

@Component({
  selector: 'app-monthly-requested-reimbursement-report',
  templateUrl: './monthly-requested-reimbursement-report.component.html',
  styleUrls: ['./monthly-requested-reimbursement-report.component.scss']
})
export class MonthlyRequestedReimbursementReportComponent implements OnInit,AfterViewInit {
  // for property type
  selectedItemsLR = [];
  selectedItemsTO = [];
  dropdownListLR = [];
  dropdownSettingsLR: IDropdownSettings = {};

//for local authority
  dropdownSettingsLA: IDropdownSettings = {};
  selectedItemsLA = [];
  dropdownListLA = [];
// for tax officer
  dropdownSettingsTO: IDropdownSettings = {};
  dropdownListTO = [];
  assignedTaxOfficer: any;

  taxOfficerList: any;
  userlist: any;
  employeeList: any;
  fromDate: string = null;
  toDate: string = null;
  year: string = null;
  requestedAmount: string = null;
  reason: string = null;
  currentDate =new Date().toISOString().split('T')[0];
  currentYear = new Date().toISOString().split('T')[0];
  public reportDetails: any = [];
  public showPrint: boolean = false;
  public selectedDetails: any;
  public download_excel_status: boolean = false;

  // for status
  selectedItemsStatus = [];
  dropdownListStatus = [];
  dropdownSettingsStatus: IDropdownSettings = {};

  // report data footer data
  public total_count: number = 0;
  public total_declare_value: number = 0;
  constructor(
    public modalService: NgbModal,
    public userService: UserService,

    public employeeService: EmployeeService,
    public reportService: ReportServiceService,
    private notifier: NotifierService) { }

  ngOnInit() {
    // set up local authority dropdown
    this.dropdownSettingsLA = {
      singleSelection: false,
      idField: 'id',
      textField: 'localAuthority',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownSettingsLR = {
      singleSelection: false,
      idField: 'id',
      textField: 'landRegistry',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownSettingsStatus = {
      singleSelection: false,
      idField: 'id',
      textField: 'Status',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownSettingsTO = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    console.log('inside int');
    this.get_all_local_authority();
    this.get_all_land_registry();
   this.setDatatoStatusDropDown();
   this.get_all_TO();
  }
  ngAfterViewInit():void{

  }
  // retrieve local authority data from service
  get_all_local_authority() {
    this.reportService.getAllLA().subscribe(value => {
      if (value.data.length > 0) {
        this.dropdownListLA = value.data;
      } else {
        console.log("No Local authority return");
      }
    });
  }
  // retrieve property type data from service
  get_all_land_registry() {
    this.reportService.getAllLandRegistry().subscribe(value => {
      if (value.data.length > 0) {
        this.dropdownListLR = value.data;
      } else {
        console.log("No Land Registry return");
      }
    });
  }

  get_all_TO() {
    this.reportService.getAllTO().subscribe(value => {
      if (value.data.length > 0) {
        this.dropdownListTO = value.data;
      } else {
        console.log("No Tax officer return");
      }
    });
  }
  // get_all_ETO() {
  //   this.employeeService.searchEmployee().subscribe((data) => {
  //     this.employeeList = data.data;
  //     this.taxOfficerList = this.employeeList.filter(
  //       (i) => i["designation"][0]["id"] === Constant.ROLE_TAX_OFFICER
  //     )
  //   });
  // }
  retrieveUserList() {
    this.userService.retrieveUserList().subscribe((data) => {
      this.userlist = data.data;
      this.taxOfficerList = this.userlist.filter(
        (i) => i["roles"][0]["code"] === Constant.ROLE_TAX_OFFICER
      );
    });
  }
  onItemSelectLR(items: any) {
    //console.log(items);
  }
  onItemSelectTO(items: any) {
    console.log(items);
  }

  onSelectAllLR(items: any) {
    // console.log(items);
  }
  onSelectAllTO(items: any) {
     console.log(items);
  }
  onSelectAllStatus(items: any) {

  }
  setDatatoStatusDropDown(){
    var sectionObjectDraft = {
      id : 1,
      Status: "DRAFT"
    }
    var sectionObjectNew = {
      id : 2,
      Status: "NEW"
    }
    var sectionObjectApproved = {
      id : 3,
      Status: "APPROVED"
    }
    this.dropdownListStatus.push(sectionObjectApproved);
    this.dropdownListStatus.push(sectionObjectNew);
    this.dropdownListStatus.push(sectionObjectDraft)
  }
  getPdfReportView(): void {
    this.showPrint = true;
    let printContents: any;
    let popupWin: any;

    printContents = document.getElementById('monthlyRequestedReimbursementDetails').innerHTML;

    popupWin = window.open();
    popupWin.document.open();
    popupWin.document.write(`
    <html>
    <head>
        <title>Monthly Requested Reimbusrsements</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <!--<script src="../../../../assets/js/paged.js"></script>-->
        <!--<script src="../../../../assets/js/paged.polyfill.js"></script>-->
        <style>
          @page {
            size: A3 landscape;
            @bottom-right {
              content: "Page " counter(page) " of " counter(pages);
            }
            .page{
                page-break-after: always;
            }
          }

            body
            {
                margin: 10mm 10mm 0mm 10mm;

            }
            table{
              border: 1px solid #000;
              border-collapse: collapse;
            }
            tbody{
              page-break-before: always;
            }

            table th, table td
            {
                padding: 5px;
                margin: 0px;
                border: 1px solid #000;
            }
            .page{
                page-break-after: always;
            }

        </style>
    </head>
    <body onload="window.print();">
            ${printContents}
    </body>
    </html>`
    );
    popupWin.document.close();
  }
  getReportData() {
    this.captureSelectedDetails();
    this.showPrint = true;
    let datalength = 0;
    this.reportService.getMonthlyRequestedReimbursementReport(this.fromDate, this.toDate, this.selectedDetails.selectedLR_id, this.selectedDetails.selectedLA_id,this.year, this.selectedDetails.selectedTO_id, this.selectedDetails.selectedStatus_names).subscribe(value => {
      datalength = value.data.length;
      if (value.data.length > 0){
        this.reportDetails = value.data;
        this.total_count = value.data.length;
        this.getTotalColumns(value.data);
        setTimeout(() => {
          this.getPdfReportView();
        }, 1000);
      } else {
        this.notifier.notify('error', 'No records found!');
        console.log("No data found");
      }
        });
    return datalength;
  }
  getTotalColumns(data: []): void {
    data.forEach((record: any) => {
      this.total_declare_value += +record.declaredValue;
    });
  }
  captureSelectedDetails(): void{
    let lastDate = "";
    if(this.toDate == null){
      lastDate = "Today";
    } else{
      lastDate = this.toDate;
    }
    var date_range = ((this.fromDate == null)&&(this.toDate == null))?"All Date": this.fromDate + 'to'+lastDate;
    //capture land registry
    var selectedLR_Names = "";
    var selectedLR = "";
    if(this.selectedItemsLR !=null){
      this.selectedItemsLR.forEach(value => {
        selectedLR += value.id + ",";
        selectedLR_Names += value.landRegistry + ",";
      })
    }
    else{
      selectedLR ="";
      selectedLR_Names = "ALL";
    }
    //for local authority
    var selectedLA_Names = "";
    var selectedLA = "";
    if(this.selectedItemsLA !=null){
      this.selectedItemsLA.forEach(value => {
        selectedLA += value.id + ",";
        selectedLA_Names += value.localAuthority + ",";
      })
    }
    else{
      selectedLA ="";
      selectedLA_Names = "ALL";
    }
    //for status
    var selectedStatus_Names = "";
    var selectedStatus = "";
    if(this.selectedItemsStatus !=null){
      this.selectedItemsStatus.forEach(value => {
        selectedStatus += value.id + ",";
        selectedStatus_Names += value.Status + ",";
      })
    }
    else{
      selectedStatus ="";
      selectedStatus_Names = "ALL";
    }
    // for TO
    var selectedTO_Names = "";
    var selectedTo = "";
    if(this.selectedItemsTO !=null){
      this.selectedItemsTO.forEach(value => {
        selectedTo += value.id + ",";
        selectedTO_Names += value.name + ",";
      })
    }
    else{
      selectedTo ="";
      selectedTO_Names = "ALL";
    }
    this.selectedDetails = {
      selected_date:date_range,
      selectedLA_id: selectedLA,
      selectedLA: selectedLA_Names,
      selectedLR_id: selectedLR,
      selectedLR: selectedLR_Names,
      selectedStatus_id: selectedStatus,
      selectedStatus_names: selectedStatus_Names,
      selectedTO_id: selectedTo,
      selectedTO_name: selectedTO_Names,
      selected_year: this.year

    };
    console.log(this.selectedDetails);
  }
  generatePdfReport() {
    this.getReportData();
  }

  createExcel() {
    console.log('after excel');
    this.captureSelectedDetails();
    this.getReportData();
    this.showPrint = true;
    console.log('after capture');
    setTimeout(() => {
      var printContents = document.getElementById('excel_print');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(printContents);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Rejected App. Payment');
      XLSX.writeFile(wb, 'Application Rejected Details Report.xlsx');
    }, 1000);
  }
  closeModal(): void {
    this.modalService.dismissAll();
  }
  //
  // closeModal(): void {
  //   this.modalService.dismissAll();
  // }

}
