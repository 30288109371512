import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {SubSink} from 'subsink';
import {PropertyUsageService} from '../../../../../../../service/property-usage/property-usage.service';
import {NotifierService} from 'angular-notifier';
import {PropertyUsageSubtypeService} from '../../../../../../../service/property-usage-subtype/property-usage-subtype.service';

@Component({
  selector: 'app-delete-property-usage-subtype',
  templateUrl: './delete-property-usage-subtype.component.html',
  styleUrls: ['./delete-property-usage-subtype.component.scss']
})
export class DeletePropertyUsageSubtypeComponent implements OnInit, OnDestroy {

  private sub = new SubSink();
  private propertyUsageSubtypeId: number;
  @Output() propertyUsageSubtypeDeleted = new EventEmitter<boolean>();

  constructor(private propertyUsageSubtypeService: PropertyUsageSubtypeService,
              private notifier: NotifierService) { }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  deletePropertyUsageSubtype() {
    this.sub.add(this.propertyUsageSubtypeService.deletePropertyUsageSubtype(this.propertyUsageSubtypeId)
      .subscribe(response => {
        this.updateTheView(true);
        this.notifier.notify( 'success', (response as any).message);
      }));
  }

  setPropertyUsageSubtypeId(id: number) {
    this.propertyUsageSubtypeId = id;
  }

  updateTheView(deleted: boolean) {
    this.propertyUsageSubtypeDeleted.emit(deleted);
  }

}
