import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {Property} from '../../../../../../../../model/property';
import {Loan} from '../../../../../../../../model/loan';
import {BankService} from '../../../../../../../../service/bank/bank.service';
import {FutureDateValidator} from "../../../../../../../validators/futuredate.validator";

@Component({
  selector: 'app-property-loan-details',
  templateUrl: './property-loan-details.component.html',
  styleUrls: ['./property-loan-details.component.scss']
})
export class PropertyLoanDetailsComponent implements OnInit {

  @Input() propertyOne: Property;
  loanDetailsForm: FormGroup;

  // Validation
  onlyNumbersPattern = '^[0-9.]*$';
  onlyCharactersNumbersPattern = '^[_A-z0-9 ]*((-|\\s)*[_A-z0-9 ])*$';
  allBanks: any;
  maxDate: any;

  constructor(private formBuilder: FormBuilder,
              private notifier: NotifierService,
              private bankService: BankService) {
  }

  get f() {
    if (this.loanDetailsForm !== undefined) {
      return this.loanDetailsForm.controls;
    } else {
      this.loanDetailsForm = this.formBuilder.group({
        isPropertyUsedLoan: [this.propertyOne.isPropertyUsedLoan, [Validators.required]],
        loanNo: [this.propertyOne.loan.loanNumber, [Validators.pattern(this.onlyCharactersNumbersPattern), Validators.min(1)]],
        amount: [this.propertyOne.loan.amount, [Validators.required, Validators.pattern(this.onlyNumbersPattern), Validators.min(1)]],
        date: [this.propertyOne.loan.date],
        bank: [this.propertyOne.loan.bank, [Validators.required, Validators.pattern(this.onlyCharactersNumbersPattern)]],
        branch: [this.propertyOne.loan.branch, [Validators.required, Validators.pattern(this.onlyCharactersNumbersPattern)]],
        otherDetails: [this.propertyOne.loan.otherDetails]
      }, {
        validator: FutureDateValidator('date')
      });
    }
  }

  get isPropertyUsedLoan() {
    if (this.loanDetailsForm !== undefined) {
      return this.loanDetailsForm.get('isPropertyUsedLoan') as FormControl;
    } else {
      this.loanDetailsForm = this.formBuilder.group({
        isPropertyUsedLoan: [this.propertyOne.isPropertyUsedLoan, [Validators.required]]
      }, {
        validator: FutureDateValidator('date')
      });
    }
  }

  get otherDetails() {
    if (this.loanDetailsForm !== undefined) {
      return this.loanDetailsForm.get('otherDetails') as FormControl;
    } else {
      this.loanDetailsForm = this.formBuilder.group({
        otherDetails: [this.propertyOne.loan.otherDetails]
      }, {
        validator: FutureDateValidator('date')
      });
    }
  }

  ngOnInit() {
    this.fetchAllBanks();
    // this.loanDetailsForm = this.formBuilder.group({
    //   isPropertyUsedLoan: [this.propertyOne.isPropertyUsedLoan, [Validators.required]],
    //   loanNo: [this.propertyOne.loan.loanNumber, [Validators.required, Validators.pattern(this.onlyCharactersNumbersPattern), Validators.min(1)]],
    //   amount: [this.propertyOne.loan.amount, [Validators.required, Validators.pattern(this.onlyNumbersPattern), Validators.min(1)]],
    //   date: [this.propertyOne.loan.date, [Validators.required]],
    //   bank: [this.propertyOne.loan.bank, [Validators.required, Validators.pattern(this.onlyCharactersNumbersPattern)]],
    //   branch: [this.propertyOne.loan.branch, [Validators.required, Validators.pattern(this.onlyCharactersNumbersPattern)]],
    //   otherDetails: [this.propertyOne.loan.otherDetails]
    // }, {
    //   validator: FutureDateValidator('date')
    // });

    // // Checking loan details to enable fields
    // if (this.isPropertyUsedLoan.value === 'true') {
    //   this.loanDetailsForm.get('loanNo').enable();
    //   this.loanDetailsForm.get('amount').enable();
    //   this.loanDetailsForm.get('bank').enable();
    //   this.loanDetailsForm.get('branch').enable();
    //   this.loanDetailsForm.get('date').enable();
    //   this.loanDetailsForm.get('otherDetails').enable();
    // } else {
    //   this.loanDetailsForm.get('loanNo').disable();
    //   this.loanDetailsForm.get('amount').disable();
    //   this.loanDetailsForm.get('bank').disable();
    //   this.loanDetailsForm.get('branch').disable();
    //   this.loanDetailsForm.get('date').disable();
    //   this.loanDetailsForm.get('otherDetails').disable();

    //   this.loanDetailsForm.get('loanNo').reset();
    //   this.loanDetailsForm.get('amount').reset();
    //   this.loanDetailsForm.get('bank').reset();
    //   this.loanDetailsForm.get('branch').reset();
    //   this.loanDetailsForm.get('date').reset();
    //   this.loanDetailsForm.get('otherDetails').reset();
    // }

    // // this.loanDetailsForm.get('loanNo').disable();
    // // this.loanDetailsForm.get('amount').disable();
    // // this.loanDetailsForm.get('bank').disable();
    // // this.loanDetailsForm.get('branch').disable();
    // // this.loanDetailsForm.get('date').disable();
    // // this.loanDetailsForm.get('otherDetails').disable();
    // this.maxDate = new Date();
    
    if (this.propertyOne.loan === null) {
      this.propertyOne.loan = new Loan();
      this.loanDetailsForm = this.formBuilder.group({
        isPropertyUsedLoan: ['false', [Validators.required]],
        loanNo: [this.propertyOne.loan.loanNumber, [Validators.pattern(this.onlyCharactersNumbersPattern), Validators.min(1)]],
        amount: [this.propertyOne.loan.amount, [Validators.required, Validators.pattern(this.onlyNumbersPattern), Validators.min(1)]],
        date: [this.propertyOne.loan.date],
        bank: [this.propertyOne.loan.bank, [Validators.required, Validators.pattern(this.onlyCharactersNumbersPattern)]],
        branch: [this.propertyOne.loan.branch, [Validators.required, Validators.pattern(this.onlyCharactersNumbersPattern)]],
        otherDetails: [this.propertyOne.loan.otherDetails]
      }, {
        validator: FutureDateValidator('date')
      });
      this.loanDetailsForm.get('loanNo').disable();
      this.loanDetailsForm.get('amount').disable();
      this.loanDetailsForm.get('bank').disable();
      this.loanDetailsForm.get('branch').disable();
      this.loanDetailsForm.get('date').disable();
      this.loanDetailsForm.get('otherDetails').disable();
    } else {
      this.loanDetailsForm = this.formBuilder.group({
        isPropertyUsedLoan: ['true', [Validators.required]],
        loanNo: [this.propertyOne.loan.loanNumber, [Validators.pattern(this.onlyCharactersNumbersPattern), Validators.min(1)]],
        amount: [this.propertyOne.loan.amount, [Validators.required, Validators.pattern(this.onlyNumbersPattern), Validators.min(1)]],
        date: [this.propertyOne.loan.date],
        bank: [this.propertyOne.loan.bank, [Validators.required, Validators.pattern(this.onlyCharactersNumbersPattern)]],
        branch: [this.propertyOne.loan.branch, [Validators.required, Validators.pattern(this.onlyCharactersNumbersPattern)]],
        otherDetails: [this.propertyOne.loan.otherDetails]
      }, {
        validator: FutureDateValidator('date')
      });
    }

    this.maxDate = new Date();

    if (this.propertyOne != null) {
      if (this.propertyOne.loan !== null) {
        this.loanDetailsForm.setValue({
          isPropertyUsedLoan: 'true'
        });
        // Checking loan details to enable fields
        if (this.isPropertyUsedLoan !== undefined) {
          if (this.isPropertyUsedLoan.value === 'true') {
            this.loanDetailsForm.get('loanNo').enable();
            this.loanDetailsForm.get('amount').enable();
            this.loanDetailsForm.get('bank').enable();
            this.loanDetailsForm.get('branch').enable();
            this.loanDetailsForm.get('date').enable();
            this.loanDetailsForm.get('otherDetails').enable();
          } else {
            this.loanDetailsForm.get('loanNo').disable();
            this.loanDetailsForm.get('amount').disable();
            this.loanDetailsForm.get('bank').disable();
            this.loanDetailsForm.get('branch').disable();
            this.loanDetailsForm.get('date').disable();
            this.loanDetailsForm.get('otherDetails').disable();

            this.loanDetailsForm.get('loanNo').reset();
            this.loanDetailsForm.get('amount').reset();
            this.loanDetailsForm.get('bank').reset();
            this.loanDetailsForm.get('branch').reset();
            this.loanDetailsForm.get('date').reset();
            this.loanDetailsForm.get('otherDetails').reset();
          }
        }
      }
    }
  }

  saveLoanDetails(): boolean {
    this.loanDetailsForm.markAllAsTouched();
    if (this.loanDetailsForm.valid) {
      this.setPropertyLoanDetails();
      return true;
    } else {
      return false;
    }
  }

  setPropertyLoanDetails(): void {
    this.propertyOne.loan.loanNumber = this.loanDetailsForm.get('loanNo').value;
    this.propertyOne.loan.amount = Number(this.loanDetailsForm.get('amount').value);
    this.propertyOne.loan.bank = this.loanDetailsForm.get('bank').value;
    this.propertyOne.loan.branch = this.loanDetailsForm.get('branch').value;
    this.propertyOne.loan.date = this.loanDetailsForm.get('date').value;
    this.propertyOne.loan.otherDetails = this.loanDetailsForm.get('otherDetails').value;
    this.propertyOne.isPropertyUsedLoan = this.loanDetailsForm.get('isPropertyUsedLoan').value;
    localStorage.setItem('loan', JSON.stringify(this.propertyOne));
  }

  onChange(data) {
    if (data === 'true') {
      this.loanDetailsForm.get('loanNo').enable();
      this.loanDetailsForm.get('amount').enable();
      this.loanDetailsForm.get('bank').enable();
      this.loanDetailsForm.get('branch').enable();
      this.loanDetailsForm.get('date').enable();
      this.loanDetailsForm.get('otherDetails').enable();
    } else {
      this.resetForm();
      this.loanDetailsForm.get('loanNo').disable();
      this.loanDetailsForm.get('amount').disable();
      this.loanDetailsForm.get('bank').disable();
      this.loanDetailsForm.get('branch').disable();
      this.loanDetailsForm.get('date').disable();
      this.loanDetailsForm.get('otherDetails').disable();
    }
  }

  resetForm() {
    this.loanDetailsForm.get('loanNo').reset();
    this.loanDetailsForm.get('amount').reset();
    this.loanDetailsForm.get('bank').reset();
    this.loanDetailsForm.get('branch').reset();
    this.loanDetailsForm.get('date').reset();
    this.loanDetailsForm.get('otherDetails').reset();
    // this.loanDetailsForm.reset();
  }

  fetchAllBanks() {
    this.bankService.getAll().subscribe(result => {
      if (result) {
        this.allBanks = result['data'];
      } else {

      }
    }, error => {
    });
  }

  selectBank(ev) {

  }
}
