import {Component, Input, OnInit} from '@angular/core';
import {IPayment} from '../../../../../../../model/interfaces/payment';
import {FileService} from '../../../../../../../service/file/file.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {ValuationRequest} from '../../../../../../../model/valuation-request';
import {ValuationRequestService} from '../../../../../../../service/valuation-request/valuation-request.service';
import {SubSink} from 'subsink';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-view-payment-internal',
  templateUrl: './view-payment-internal.component.html',
  styleUrls: ['./view-payment-internal.component.scss']
})
export class ViewPaymentInternalComponent implements OnInit {
  @Input()
  payment: IPayment;

  selectFileSm = 0;
  valuationRequestId: any;
  previewFileData: any;
  type: any;
  valuationRequest: ValuationRequest;
  noDocs = false;
  dataDone = false;
  referenceNumber: string;
  refNum: string;
  isBankOrCard: boolean;
  private sub = new SubSink();

  constructor(private fileService: FileService, private route: ActivatedRoute,
              public sanitizer: DomSanitizer, private valuationRequestService: ValuationRequestService,
              private  router: Router, private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.valuationRequestId = +this.route.snapshot.paramMap.get('id');
    console.log(this.payment);
    console.log('valuation Req >>>>>>>>>>>>>>>>>>' + this.valuationRequestId);

    this.referenceNumber = '#VR' + JSON.stringify(this.valuationRequestId).padStart(10, '0');

    this.getPaymentMethod();
  }

  previewFile(type) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadFileById(this.valuationRequestId, type).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(() => {
          this.spinner.hide();
        }, 2000);

      } else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }

  getPaymentMethod() {
    if (this.payment.paymentMethod === 'Online-Payment') {
      this.isBankOrCard = true;
      this.refNum = this.payment.id;
      console.log(this.payment.id + '>>>>>>>>>>>>>>>>>>>');
    }
    if (this.payment.paymentMethod === 'Bank-Payment') {
      this.isBankOrCard = true;
      this.refNum = this.payment.referenceNo;
    }
    if (this.payment.paymentMethod === 'Cash-Payment') {
      this.isBankOrCard = false;
      this.refNum = this.payment.referenceNo;
    }
  }

}
