export class Employee{
  id:number;
  title:string;
  firstName:string;
  lastName:string;
  middleName:string;
  nic:string;
  designation:string;
  districtOffice:string;
  appointedDate:string;
  address:string;
  mobileNo:string;
  officeNo:string;
  email:string;
  status:string;

  constructor(
    id:number,
    title:string,
    firstName:string,
    lastName:string,
    middleName:string,
    nic:string,
    designation:string,
    districtOffice:string,
    appointedDate:string,
    address:string,
    mobileNo:string,
    officeNo:string,
    email:string,
    status:string,
  ){
    this.id = id;
    this.title = title;
    this.firstName = firstName;
    this.lastName = lastName;
    this.middleName = middleName;
    this.nic = nic;
    this.designation = designation;
    this.districtOffice = districtOffice;
    this.appointedDate = appointedDate;
    this.address = address;
    this.mobileNo = mobileNo;
    this.officeNo = officeNo;
    this.email = email;
    this.status = status;
  }
}
