import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ReportServiceService } from "../service/report-service.service";
import { NotifierService } from "angular-notifier";
import * as XLSX from "xlsx";

@Component({
	selector: 'app-condominium-report',
	templateUrl: './condominium-report.component.html',
	styleUrls: ['./condominium-report.component.scss']
})
export class CondominiumReportComponent implements OnInit {

	// for local authorities
	selectedItemsLA = [];
	dropdownListLA = [];
	dropdownSettingsLA: IDropdownSettings = {};

	// for assessor
	selectedItemsASS = [];
	dropdownListASS = [];
	dropdownSettingsASS: IDropdownSettings = {};

	// for allocated regional branches
	selectedItemsARB = [];
	dropdownListARB = [];
	dropdownSettingsARB: IDropdownSettings = {};

	// for submitted date range
	fromDate: string = null;
	toDate: string = null;

	// for condominium name
	condominium_name: string = null;

	// report data
	public reportDetails: any = [];
	public showPrint: boolean = false;
	public selectedDetails: any;

	// report data footer data
	public total_count: number = 0;
	public total_gross_rent: number = 0;
	public total_outgoing: number = 0;
	public total_net_rent: number = 0;
	public total_yp_rent: number = 0;
	public total_values_of_properties: number = 0;
	public total_values: number = 0;
	public total_values_of_opinion_given: number = 0;

	constructor(public modalService: NgbModal,
		public reportService: ReportServiceService,
		private notifier: NotifierService) { }

	ngOnInit() {
		// set up assessor dropdown
		this.dropdownSettingsASS = {
			singleSelection: false,
			idField: 'id',
			textField: 'name',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		// set up local authority dropdown
		this.dropdownSettingsLA = {
			singleSelection: false,
			idField: 'id',
			textField: 'localAuthority',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		// set up allocated regional branches dropdown
		this.dropdownSettingsARB = {
			singleSelection: false,
			idField: 'id',
			textField: 'office',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		// invoke services and load data to dropdown
		this.get_all_local_authority();
		this.get_all_assessors();
		this.get_all_allocated_branches();
	}

	/*************** DROP DOWN SERVICES ***************/
	// retrieve local authority data from service
	get_all_local_authority() {
		this.reportService.getAllLA().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListLA = value.data;
			} else {
				console.log("No Local authority return");
			}
		});
	}

	// retrieve allocated branches data from service
	get_all_allocated_branches() {
		this.reportService.getAllAB().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListARB = value.data;
				console.log(value.data);
			} else {
				console.log("No Allocated Regional Branch return");
			}
		});
	}

	// retrieve assessor data from service
	get_all_assessors() {
		this.reportService.getAllASS().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListASS = value.data;
				console.log(value.data);
			} else {
				console.log("No Assessors return");
			}
		});
	}
	/*************** DROP DOWN SERVICES ***************/

	/*************** UI EVENT ***************/
	// event click on close button
	closeFurtherReqRemark() {
		this.modalService.dismissAll();
	}

	createExcel() {
		this.captureSelectedDetails();
		this.getReportData();
		this.showPrint = true;
		setTimeout(() => {
			var printContents = document.getElementById('excel_print');
			const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(printContents);
			const wb: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'Condominium Dtls.');
			XLSX.writeFile(wb, 'Condominium Report.xlsx');
		}, 1000);
	}

	generatePdfReport() {
		this.captureSelectedDetails();
		this.getReportData();
		this.showPrint = true;
		setTimeout(() => {
			this.createPdfReport();
		}, 1000);

	}

	onItemSelectLA(item: any) {
		// console.log(item);
	}
	onSelectAllLA(items: any) {
		// console.log(items);
	}

	onItemSelectASS(item: any) {
		// console.log(item);
	}
	onSelectAllASS(items: any) {
		// console.log(items);
	}

	onItemSelectARB(item: any) {
		// console.log(item);
	}
	onSelectAllARB(items: any) {
		// console.log(items);
	}
	/*************** UI EVENT ***************/

	/*************** CAPTURE UI DATA ***************/
	captureSelectedDetails(): void {

    let lastDate = "";
    if(this.toDate == null || this.toDate == ""){
      lastDate = "Today";
    }else {
      lastDate = this.toDate;
    }

		var date_range = ((this.fromDate == null || this.fromDate == "") && (this.toDate == null || this.toDate == "")) ? "All Date" : (this.fromDate == null) ? ' to ' + lastDate : this.fromDate + ' to ' + lastDate;

		// capture selected local authorities
		var selectedLA_Names = "";
		var selectedLA = "";
		if (this.selectedItemsLA != null) {
			this.selectedItemsLA.forEach(value => {
				selectedLA += value.id + ",";
				selectedLA_Names += value.localAuthority + ", ";
			});

      selectedLA = selectedLA.slice(0, -1);
      selectedLA_Names = selectedLA_Names.slice(0, -1);

      if (this.selectedItemsLA.length == this.dropdownListLA.length) {
        selectedLA_Names = "All";
      }
		} else {
			selectedLA_Names = "N/A";
			selectedLA = "";
		}

		// capture selected assessors
		var selectedASS = "";
		var selectedASS_Names = "";
		if (this.selectedItemsASS != null) {
			this.selectedItemsASS.forEach(value => {
				selectedASS += value.id + ",";
				selectedASS_Names += value.name + ", ";
			});
		} else {
			selectedASS = "";
			selectedASS_Names = "N/A";
		}

		// capture selected allocated regional branches
		var selectedARB = "";
		var selectedARB_Names = "";
		if (this.selectedItemsARB != null) {
			this.selectedItemsARB.forEach(value => {
				selectedARB += value.id + ",";
				selectedARB_Names += value.office + ", ";
			});
		} else {
			selectedARB = "";
			selectedARB_Names = "N/A";
		}

		this.selectedDetails = {
			selected_date: date_range,

			selectedLA_id: selectedLA,
			selectedLA_names: selectedLA_Names,

			selectedASS_id: selectedASS,
			selectedASS_names: selectedASS_Names,

			selectedARB_id: selectedARB,
			selectedARB_names: selectedARB_Names,

			selected_condominium: this.condominium_name
		};
	}

	/*************** GET REPORT DATA ***************/
	getReportData(): void {
		this.reportService.getCondominiumReport(this.fromDate, this.toDate, this.selectedDetails.selectedASS_id, this.selectedDetails.selectedARB_id, this.selectedDetails.selectedLA_id, this.selectedDetails.selected_condominium).subscribe(value => {
			if (value.data.length > 0) {
				this.reportDetails = value;
				this.total_count = value.data.length;

				// this.calculateFooterData(value.data);

			} else {
				this.notifier.notify('error', 'No records found!');
				console.log("No data found");
			}
		});
	}
	/*************** GET REPORT DATA ***************/

	/*************** GENERATE REPORT ***************/
	createPdfReport(): void {

		let printContents: any;
		let popupWin: any;
		printContents = document.getElementById('condominiumReportContent').innerHTML;

		popupWin = window.open();
		popupWin.document.open();
		popupWin.document.write(`
    <html>
    <head>
        <title>Condominium Details</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <!--<script src="../../../../assets/js/paged.js"></script>-->
        <!--<script src="../../../../assets/js/paged.polyfill.js"></script>-->
        <style>
          @page {
            size: A3 landscape;
            @bottom-right {
              content: "Page " counter(page) " of " counter(pages);
            }
            .page{
                page-break-after: always;
            }
          }

            body
            {
                margin: 10mm 10mm 0mm 10mm;

            }
            table{
              border: 1px solid #000;
              border-collapse: collapse;
            }
            tbody{
              page-break-before: always;
            }

            table th, table td
            {
                padding: 5px;
                margin: 0px;
                border: 1px solid #000;
            }
            .page{
                page-break-after: always;
            }

        </style>
    </head>
    <body onload="window.print();">
            ${printContents}
    </body>
    </html>`
		);
		popupWin.document.close();
	}

	createExcelReport(): void {

	}
	/*************** GENERATE REPORT ***************/
}
