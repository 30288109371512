import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UpdateGramaNiladariDivisionComponent } from '../update-grama-niladari-division/update-grama-niladari-division.component';
import { DeleteGramaNiladariDivisionComponent } from '../delete-grama-niladari-division/delete-grama-niladari-division.component';
import { GramaNiladariDivisionService } from 'src/app/service/grama-niladari-division/grama-niladari-division.service';
import { SubSink } from 'subsink';
import { DataService } from 'src/app/service/data.service';
import {IGramaniladhari} from "../../../../../../model/interfaces/gramaniladhari";

@Component({
  selector: 'app-view-grama-niladari-division',
  templateUrl: './view-grama-niladari-division.component.html',
  styleUrls: ['./view-grama-niladari-division.component.scss']
})
export class ViewGramaNiladariDivisionComponent implements OnInit,OnDestroy {


  gnd: IGramaniladhari[];
  private sub=new SubSink();
  pageNo:number;

  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];

  @ViewChild(UpdateGramaNiladariDivisionComponent, {static: false})
  private updateGramaNiladariDivisionComponent: UpdateGramaNiladariDivisionComponent;
  @ViewChild(DeleteGramaNiladariDivisionComponent, {static: false})
  private deleteGramaNiladariDivisionComponent: DeleteGramaNiladariDivisionComponent;


  constructor(private gramaNiladariDivisionService:GramaNiladariDivisionService,private dataService:DataService) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.gramaNiladariDivisionService.getAll().subscribe(
      response => {
        var res = response.data.length;
        this.pageNo = Math.round((res / 10)+1);

      })

    this.sub.add(this.dataService.updateDataTableGramaNildariDivision.subscribe(update=>this.onAdded(update)));
    this.sub.add(this.gramaNiladariDivisionService.getGramanIladariADivision(0,10).subscribe(
      (res)=>{
          this.gnd=(res as any).data;
      }
    ))
  }
  ngOnDestroy(){
    this.sub.unsubscribe();
  }

  pageable(i: number) {
    console.log("i" + i)
    console.log(i * 10 + " - " + ((i * 10) + 10));
    this.gramaNiladariDivisionService.getGramanIladariADivision((i), ((i * 10) + 10)).subscribe(responce => {
      this.gnd = (responce as any).data;
    });
  }
  counter(i: number) {
    return new Array(i);
  }

  triggerUpdate(id: number,code:string, district:number,description: string) {
    // 6(district);
    this.updateGramaNiladariDivisionComponent.setIdAndDescription(id,code, district,description);
  }

  triggerDelete(id: number) {
    this.deleteGramaNiladariDivisionComponent.setDistrictId(id);
  }

  onUpdated(updatedSuccessfully: boolean) {
    if (updatedSuccessfully) {
      this.updateTheTable();
    }
  }

  onDeleted(deletedSuccessfully: boolean) {
    if (deletedSuccessfully) {
      this.updateTheTable();
    }
  }
  onAdded(addedSuccesfully:boolean){
    if(addedSuccesfully){
    this.updateTheTable();
    }
  }
    private updateTheTable() {
      this.sub.add(this.gramaNiladariDivisionService.getGramanIladariADivision(0,10).subscribe(
        (res) => {
          this.gnd =  (res as any).data;
        }));
    }


    searchGramaNiladhai(keyword: string) {
      this.selectedPageNo = 0;
      this.sub.add(this.gramaNiladariDivisionService.searchGramaNiladhari(keyword,this.selectedPageNo, this.limit)
        .subscribe((res) => {
          this.gnd = res.data.content;
          this.paginationButtonCounter(res.data.totalPages);
          this.numOfPages = res.data.totalPages;
        })
      );
    }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

}
