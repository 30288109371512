import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SubSink} from 'subsink';
import {ValuationRequestService} from '../../../../../../service/valuation-request/valuation-request.service';
import FileSaver from "file-saver";
import { ValuationRequestList } from 'src/app/model/valuation_request_list';
import { Constant } from 'src/app/util/constant';

@Component({
  selector: 'app-completed-requests',
  templateUrl: './completed-requests.component.html',
  styleUrls: ['./completed-requests.component.scss']
})
export class CompletedRequestsComponent implements OnInit {

  // pagination
  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];
  searchKeyword = "";
  nextPage: number;

  public model = new ValuationRequestList('', '', '', '', '', '');


  // valuationRequests: IValuationRequest [] = [];
  valuationRequests: any;
  @Output() completedRequestCount = new EventEmitter<number>();
  noResults: boolean = false;
  private sub = new SubSink();
  searchKeywordDeed: any;
  searchKeywordDate: any;

  constructor(private valuationRequestService: ValuationRequestService) {
  }

  ngOnInit() {
    this.selectedPageNo = 0;
    this.limit = 10;
    this.nextPage = 0;

    this.getAllResults();
    this.sub.add(this.valuationRequestService.findValuationRequestByStatus(
      'APPLICATION_COMPLETED', this.selectedPageNo, this.limit, 'EXTERNAL').subscribe(
      (res) => {
        this.valuationRequests = res.data.content;
        if (Object.keys(this.valuationRequests).length === 0) {
          this.noResults = true;
        } else this.noResults = false;
        this.paginationButtonCounter(res.data.totalPages);
        this.numOfPages = res.data.totalPages;
        this.completedRequestCount.emit(res.data.totalElements);
      }));

  }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

  getPage(pageNo: number) {
    this.selectedPageNo = pageNo;


  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  search() {
    this.selectedPageNo = 0;
    this.limit = 10;

    if (this.searchKeyword.trim() !== "" && this.searchKeyword != null) {
      this.sub.add(this.valuationRequestService.searchValuationRequest(this.searchKeyword,
        'APPLICATION_COMPLETED', this.selectedPageNo, this.limit, 'EXTERNAL').subscribe(
        (res) => {
          this.valuationRequests = res.data.content;
          if (Object.keys(this.valuationRequests).length === 0) {
            this.noResults = true;
          } else this.noResults = false;
          this.paginationButtonCounter(res.data.totalPages);
          this.numOfPages = res.data.totalPages;
        }));
    } else {

      this.getAllResults();
    }


  }

  searchByString(){
    this.selectedPageNo = 0;
    this.limit = 10;
    this.model.valuationStatus = Constant.VALIATION_COMPLETED_APPLICATION;

    if (this.searchKeywordDeed.trim()!=='' && this.searchKeywordDeed != null) {
      this.sub.add(this.valuationRequestService.searchValuationRequestByString(this.searchKeywordDeed,
        this.model.valuationStatus, 0, this.limit,  'EXTERNAL').subscribe(
        (res) => {
          this.valuationRequests = res.data.content;
          if (Object.keys(this.valuationRequests).length === 0) {
            this.noResults = true;
          } else this.noResults = false;
          this.paginationButtonCounter(res.data.totalPages);
          this.numOfPages = res.data.totalPages;
        }));
    } else {
      this.getAllResults();
    }
  }

  searchDate() {
    this.model.offset = `0`;
    this.model.limit = '10';
    this.model.valuationStatus = Constant.VALIATION_COMPLETED_APPLICATION;

    if (this.searchKeywordDate.trim() !== '' && this.searchKeywordDate != null) {
      this.sub.add(this.valuationRequestService.searchValuationRequestByDate(this.searchKeywordDate,
        this.model.valuationStatus, parseInt(this.model.offset), parseInt(this.model.limit), 'EXTERNAL',this.model.userId, this.model.userType).subscribe(
          (res) => {
            this.valuationRequests = res.data.content;
            if (Object.keys(this.valuationRequests).length === 0) {
              this.noResults = true;
            } else this.noResults = false;
            this.paginationButtonCounter(res.data.totalPages);
            this.numOfPages = res.data.totalPages;
          }));
      } else {
        this.getAllResults();
      }
  }

  private getAllResults() {
    this.sub.add(this.valuationRequestService.findValuationRequestByStatus(
      'APPLICATION_COMPLETED', this.selectedPageNo, this.limit, 'EXTERNAL').subscribe(
      (res) => {
        this.valuationRequests = res.data.content;
        if (Object.keys(this.valuationRequests).length === 0) {
          this.noResults = true;
        } else this.noResults = false;
        this.paginationButtonCounter(res.data.totalPages);
        this.numOfPages = res.data.totalPages;

        //Setting the pagination for the table No. of Requests
        if (this.selectedPageNo != 0) {
          this.nextPage = 10 * this.selectedPageNo;
        } else {
          this.nextPage = 0;
        }
      }));
  }
  downloadpdf(id) {
    this.valuationRequestService.downloadpdf(id).subscribe(response => {
      let blob: any = new Blob([response], {type: 'application/pdf'});
      // const url= window.URL.createObjectURL(blob);
      // // window.open(url);
      // window.location.href = url;
      FileSaver.saveAs(blob, "valuation_application_" + id + ".pdf");
    }), error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
    // console.log('lllll')
    // return this.valuationRequestService.downloadpdf(id);
  }
}
