import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Property} from '../../../../../../model/property';
import {ValuationRequest} from '../../../../../../model/valuation-request';
import {Payment} from '../../../../../../model/payment';
import {Loan} from '../../../../../../model/loan';
import {ValuationRequestExchange} from '../../../../../../model/valuation-request-exchange';
import {ValuationRequestService} from '../../../../../../service/valuation-request/valuation-request.service';
import {SubSink} from 'subsink';
import {NotifierService} from 'angular-notifier';
import {OtherComponent} from '../wizard/other/other.component';
import {DocumentUploadService} from '../../../../../../service/document-upload/document-upload.service';
import {GranteeComponent} from '../wizard/grantee/grantee.component';
import {GrantorComponent} from '../wizard/grantor/grantor.component';
import {PropertyComponent} from '../wizard/property/property.component';
import {DeclarationComponent} from '../wizard/declaration/declaration.component';
import {PaymentComponent} from '../wizard/payment/payment.component';
import Swal from 'sweetalert2';
import { Constant } from 'src/app/util/constant';
import { AppOtherParty2Component } from '../wizard/app-other-party2/app-other-party2.component';

@Component({
  selector: 'app-exchange',
  templateUrl: './exchange.component.html',
  styleUrls: ['./exchange.component.scss']
})
export class ExchangeComponent implements OnInit {
  valuationRequest: ValuationRequest;
  valuationRequest2: ValuationRequest;
  deedNatureStatus: string;
  isDraft : boolean;
  granteeSelectedPaths = [];
  grantorSelectedPaths = [];
  otherSelectedPaths1 = [];
  otherSelectedPaths2 = [];
  errorList = [];
  documentsIdList1 = [];
  documentsIdList2 = [];
  fileUploadList1 = [] = [];
  fileUploadList2 = [] = [];
  saveButton = 'Submit';
  onlineSelectedPaths = [];
  //
  //
  selectMenu = 1;
  buttonDisabled = false;
  @ViewChild(GranteeComponent, {static: false})
  private granteeComponent1: GranteeComponent;
  @ViewChild(GrantorComponent, {static: false})
  private grantorComponent1: GrantorComponent;
  @ViewChild('appproperty1', {static: false})
  private propertyComponent1: PropertyComponent;
  @ViewChild('appproperty2', {static: false})
  private propertyComponent2: PropertyComponent;
  @ViewChild('otherComponent1', {static: false})
  private otherComponent1: OtherComponent;
  @ViewChild('otherComponent2', {static: false})
  private otherComponent2: AppOtherParty2Component;
  @ViewChild(DeclarationComponent, {static: false})
  private declarationComponent: DeclarationComponent;
  @ViewChild(PaymentComponent, {static: false})
  private paymentComponent: PaymentComponent;
  private sub = new SubSink();

  public updateStatus: string;
  public updateValuationRequestId: number;

  createCommonPayment: boolean = false;
  btnDisable: boolean;

  constructor(private valuationRequestService: ValuationRequestService,
              private notifier: NotifierService,
              private documentService: DocumentUploadService) {
    const propertyOne = new Property();
    const propertyTwo = new Property();
    const commonPayment = new Payment();
    //
    this.valuationRequest = new ValuationRequest();
    propertyOne.loan = new Loan();
    this.valuationRequest.properties.push(propertyOne);
    this.valuationRequest.payment = commonPayment;
    this.valuationRequest.noOfGrantees = 0;
    this.valuationRequest.noOfGranters = 0;
    this.valuationRequest.stampDuty = 0.0;
    this.valuationRequest.deedNature = 'Exchange';
    this.valuationRequest.valuation = 0.0;
    this.valuationRequest.fileNumberFormat = '';
    //
    this.valuationRequest2 = new ValuationRequest();
    propertyTwo.loan = new Loan();
    this.valuationRequest2.properties.push(propertyTwo);
    this.valuationRequest2.payment = commonPayment;
    this.valuationRequest2.noOfGrantees = 0;
    this.valuationRequest2.noOfGranters = 0;
    this.valuationRequest2.stampDuty = 0.0;
    this.valuationRequest2.deedNature = 'Exchange';
    this.valuationRequest2.valuation = 0.0;
    this.valuationRequest2.fileNumberFormat = '';
  }

  ngOnInit() {
    this.deedNatureStatus = 'Exchange';
    this.updateValuationRequestId = Number(localStorage.getItem('valuation_request_id'));
    this.updateStatus = sessionStorage.getItem('valuationUpdate');

    if (this.updateStatus === 'true') {
      this.loadUpdatedValuationInfo();
    }
  }

  loadUpdatedValuationInfo() {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(this.updateValuationRequestId)
      .subscribe((res) => {
        this.valuationRequest = res.data;
        this.loadExchangeUpdatedValuationInfo(res.data.exchangeId)
        localStorage.setItem('first_valuation_request_id', JSON.stringify(res.data.id));
      }));
  }

  loadExchangeUpdatedValuationInfo(exchangeId: any) {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(exchangeId)
      .subscribe((res) => {
        this.valuationRequest2 = res.data;
        localStorage.setItem('second_valuation_request_id', JSON.stringify(res.data.id));
      }));
  }

  addGrantersAndGranteesToValuationRequest() {
    if (this.granteeComponent1.checkGranteeAvailability() && this.grantorComponent1.checkGranterAvailability()) {
      this.btnDisable = true
      if (!this.granteeComponent1.grantees[0].isGranteeIndividual) {
        this.granteeComponent1.setEveryChildComponentUploadingDocuments();
        this.granteeSelectedPaths = this.granteeComponent1.selectPaths;
      }
      if (!this.grantorComponent1.granters[0].isGrantorIndividual) {
        this.grantorComponent1.setEveryChildComponentUploadingDocuments();
        this.grantorSelectedPaths = this.grantorComponent1.selectPaths;

      }
      console.log('grantorSelectedPaths', this.grantorSelectedPaths)
      console.log('granteeSelectedPaths', this.granteeSelectedPaths)
      // this.selectMenu = this.selectMenu + 1;
      this.btnDisable = true;
      this.exchangeAutoSave()

    } else {
      this.btnDisable = false
      this.notifier.notify('error', 'Please Add Granter and Grantee');
    }
  }


  // addGrantersAndGranteesToValuationRequest() {
  //   if (this.granteeComponent1.checkGranteeAvailability() && this.grantorComponent1.checkGranterAvailability()) {
  //     this.btnDisable = true

  //     if (!this.granteeComponent1.grantees[0].isGranteeIndividual) {
  //       this.granteeComponent1.setEveryChildComponentUploadingDocuments();
  //       this.granteeSelectedPaths = this.granteeComponent1.selectPaths;
  //     } else {
  //       this.granteeSelectedPaths = [];
  //     }
  //     if (!this.grantorComponent1.granters[0].isGrantorIndividual) {
  //       this.grantorComponent1.setEveryChildComponentUploadingDocuments();
  //       this.grantorSelectedPaths = this.grantorComponent1.selectPaths;

  //     } else {
  //       this.grantorSelectedPaths = [];
  //     }

  //     console.log('grantorSelectedPaths', this.grantorSelectedPaths)
  //     console.log('granteeSelectedPaths', this.granteeSelectedPaths)
  //     console.log(this.valuationRequest.id)
  //     this.exchangeAutoSave();
  //     // this.selectMenu = this.selectMenu + 1;
  //   } else {
  //     this.btnDisable = false
  //     this.notifier.notify('error', 'Please Add Grantor and Grantee');
  //   }
  // }

  backToGranterGrantee() {
    this.selectMenu = this.selectMenu - 1;
    this.btnDisable = false
    // this.granteeComponent.
  }


  addPropertiesToValuationRequest() {
    if (this.propertyComponent1.propertyValidationCheck() && this.propertyComponent2.propertyValidationCheck()) {
      this.createCommonPayment = true;
      this.exchangeAutoSave();
      // this.selectMenu = this.selectMenu + 1;
    }
  }

  addPaymentDetailsToValuationRequest() {
    if (this.paymentComponent.setPaymentDetailsToValuationRequest()) {
      if (this.paymentComponent.paymentMethod === 'Bank-Payment') {
        this.paymentComponent.setBankChildComponentUploadingDocuments();
      }

      if (this.paymentComponent.paymentMethod === 'Cash-Payment') {
        this.paymentComponent.setCashChildComponentUploadingDocuments();
      }

      // this.paymentSelectedPaths = this.paymentComponent.selectPaths;
      return true;
    } else {
      return false;
    }
  }

  exchangeAutoSave() {
    if (JSON.parse(localStorage.getItem('first_valuation_request_id')) === null
      && JSON.parse(localStorage.getItem('second_valuation_request_id')) === null) {
      this.saveInitialValuationRequest();
    } else {
      this.isDraft = true;
      this.updateValuationRequest();
    }
  }

  saveInitialValuationRequest() {
    this.valuationRequest.id = Number(localStorage.getItem('first_valuation_request_id'));
    this.valuationRequest2.id = Number(localStorage.getItem('second_valuation_request_id'));
    this.valuationRequest.created_date = new Date();
    this.valuationRequest2.created_date = new Date();
    this.valuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
    this.valuationRequest.noOfGranters = this.valuationRequest.granters.length;
    this.valuationRequest2.noOfGrantees = this.valuationRequest.granters.length;
    this.valuationRequest2.noOfGranters = this.valuationRequest.grantees.length;
    this.valuationRequest.valuationRequestStatus = Constant.VALUATION_REQUEST_DRAFT_STATUS;
    this.valuationRequest2.valuationRequestStatus = Constant.VALUATION_REQUEST_DRAFT_STATUS;

    const valuationRequestExchange = new ValuationRequestExchange();
    valuationRequestExchange.valuationOne = this.valuationRequest;
    valuationRequestExchange.valuationTwo = this.valuationRequest2;
    this.sub.add(this.valuationRequestService.createValuationRequestExchange(valuationRequestExchange)
      .subscribe(res => {
        localStorage.setItem('first_valuation_request_id', res.data[0]);
        localStorage.setItem('second_valuation_request_id', res.data[1]);
        this.selectMenu = this.selectMenu + 1;
        this.isDraft = false;
      }));
  }

  updateValuationRequest() {
    this.valuationRequest.id = Number(localStorage.getItem('first_valuation_request_id'));
    this.valuationRequest2.id = Number(localStorage.getItem('second_valuation_request_id'));
    this.valuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
    this.valuationRequest.noOfGranters = this.valuationRequest.granters.length;
    this.valuationRequest2.noOfGrantees = this.valuationRequest.granters.length;
    this.valuationRequest2.noOfGranters = this.valuationRequest.grantees.length;
    this.valuationRequest.valuationRequestStatus = Constant.VALUATION_REQUEST_DRAFT_STATUS;
    this.valuationRequest2.valuationRequestStatus = Constant.VALUATION_REQUEST_DRAFT_STATUS;
    // this.valuationRequest.payment = this.valuationRequest.payment[0];
    // this.valuationRequest2.payment = this.valuationRequest2.payment[0];
    if(this.isDraft) {
      if (this.createCommonPayment) {
        this.valuationRequest.payment = new Payment();
        this.valuationRequest2.payment = new Payment();
      } else {
          if(this.valuationRequest.payment[0] != undefined){
            this.valuationRequest.payment = this.valuationRequest.payment[0]
          }else{
            this.valuationRequest.payment = new Payment();
          }
          if(this.valuationRequest2.payment[0] != undefined){
            this.valuationRequest2.payment = this.valuationRequest2.payment[0]
          }else{
            this.valuationRequest2.payment = new Payment();
          }
        }
    }
    const valuationRequestExchange = new ValuationRequestExchange();
    valuationRequestExchange.valuationOne = this.valuationRequest;
    valuationRequestExchange.valuationTwo = this.valuationRequest2;
    this.sub.add(this.valuationRequestService.createValuationRequestExchange(valuationRequestExchange)
      .subscribe(res => {
        console.log(JSON.stringify(res));
        // localStorage.setItem('valuation_request_id', res.data.id);
        // this.valuationRequest.id = res.data.id;
        this.selectMenu = this.selectMenu + 1;
        this.isDraft = true;
      }));
  }

  submitValuationRequest() {
    try {
      if (this.addPaymentDetailsToValuationRequest()) {
        this.holdDocumentsUntilValuationRequestLastStep();
        this.saveButton = 'Saving..';
      } else {
        this.fileUploadList1=[];
        this.fileUploadList2=[];
        this.saveButton = 'Submit';
        this.notifier.notify('error', 'Please check payment details');
      }
    } catch (e) {
      this.fileUploadList1=[];
      this.fileUploadList2=[];
      this.saveButton = 'Submit';
      this.notifier.notify('error', 'Please try again');
      console.log(e)
    }


    // if (this.addPaymentDetailsToValuationRequest()) {
    //   const valuationRequestExchange = new ValuationRequestExchange();
    //   valuationRequestExchange.valuationOne = this.valuationRequest;
    //   valuationRequestExchange.valuationTwo = this.valuationRequest2;
    //   this.sub.add(this.valuationRequestService.createValuationRequestExchange(valuationRequestExchange).subscribe(res => {
    //     const data = res as any;
    //     window.location = data.data.encryptedRequest;
    //   }, (error => {
    //     this.notifier.notify('success', 'created successfully');
    //     localStorage.removeItem('grantees');
    //     localStorage.removeItem('granters');
    //     localStorage.removeItem('deed');
    //     localStorage.removeItem('assessment');
    //     localStorage.removeItem('extend');
    //     localStorage.removeItem('other');
    //     localStorage.removeItem('loan');
    //     localStorage.removeItem('building');
    //   })));
    // } else {
    //   this.notifier.notify('error', 'Please check payment details');
    // }

  }

  // ngOnDestroy() {
  //   this.sub.unsubscribe();
  // }

  // fillFormData() {
  //   this.valuationRequest2.granters = this.valuationRequest.grantees;
  //   this.valuationRequest2.grantees = this.valuationRequest.granters;
  //
  //   this.buttonDisabled = true;
  //   // this.granteeComponent2.addButtonEnabled(false);
  //   // this.grantorComponent2.addButtonEnabled(false);
  // }

  holdDocumentsUntilValuationRequestLastStep() {

    if (this.paymentComponent.paymentMethod == 'Online-Payment') {
      this.paymentComponent.selectPaths.push(this.onlineSelectedPaths)
    }

    this.paymentComponent.selectPaths.push(this.otherSelectedPaths1[0]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths1[1]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths1[2]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths1[3]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths1[4]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths1[5]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths1[6]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths1[7]);
    this.paymentComponent.selectPaths.push(this.granteeSelectedPaths);
    this.paymentComponent.selectPaths.push(this.grantorSelectedPaths);

    this.paymentComponent.selectPaths.push(this.otherSelectedPaths2[0]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths2[1]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths2[2]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths2[3]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths2[4]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths2[5]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths2[6]);
    this.paymentComponent.selectPaths.push(this.otherSelectedPaths2[7]);
    this.paymentComponent.selectPaths.push(this.grantorSelectedPaths);
    this.paymentComponent.selectPaths.push(this.granteeSelectedPaths);

    this.fileUploadList1.push(this.paymentComponent.selectPaths[0]); // payment receipt
    this.fileUploadList2.push(this.paymentComponent.selectPaths[0]); // payment receipt

    if(this.otherComponent1.previouslyDocumentsUploaded == false && this.otherComponent2.previouslyDocumentsUploaded == false){
      this.fileUploadList1.push(this.paymentComponent.selectPaths[1]); // other component1 docs
      this.fileUploadList1.push(this.paymentComponent.selectPaths[2]);
      this.fileUploadList1.push(this.paymentComponent.selectPaths[3]);
      this.fileUploadList1.push(this.paymentComponent.selectPaths[4]);
      this.fileUploadList1.push(this.paymentComponent.selectPaths[5]);
      this.fileUploadList1.push(this.paymentComponent.selectPaths[6]);
      this.fileUploadList1.push(this.paymentComponent.selectPaths[7]);
      this.fileUploadList1.push(this.paymentComponent.selectPaths[8]); // end other
      this.fileUploadList1.push(this.paymentComponent.selectPaths[9]); // grantor doc
      this.fileUploadList1.push(this.paymentComponent.selectPaths[10]); // grantor doc
  
      this.fileUploadList2.push(this.paymentComponent.selectPaths[11]); // payment receipt
      this.fileUploadList2.push(this.paymentComponent.selectPaths[12]); // other component2 docs
      this.fileUploadList2.push(this.paymentComponent.selectPaths[13]);
      this.fileUploadList2.push(this.paymentComponent.selectPaths[14]);
      this.fileUploadList2.push(this.paymentComponent.selectPaths[15]);
      this.fileUploadList2.push(this.paymentComponent.selectPaths[16]);
      this.fileUploadList2.push(this.paymentComponent.selectPaths[17]);
      this.fileUploadList2.push(this.paymentComponent.selectPaths[18]);
      this.fileUploadList2.push(this.paymentComponent.selectPaths[19]);
      this.fileUploadList2.push(this.paymentComponent.selectPaths[20]); // granter doc
    }


    console.log('docs payment component', this.paymentComponent.selectPaths);
    console.log('docs fileuploadList1', this.fileUploadList1);
    console.log('docs fileuploadList2', this.fileUploadList2);
    // // Todo: below function need to move last step of valuation request
    // this.documentService.uploadValuationRequestDocuments(this.otherComponent1.selectPaths).subscribe(
    //   res => {
    //     this.valuationRequest.documentsIdList = (res as any).data;
    //   }, err => {
    //
    //   }
    // );
    // this.documentService.uploadValuationRequestDocuments(this.otherComponent2.selectPaths).subscribe(
    //   res => {
    //     this.valuationRequest2.documentsIdList = (res as any).data;
    //   }, err => {
    //
    //   }
    // );

    this.documentService.uploadValuationRequestDocuments(this.fileUploadList1).subscribe(
      response => {
        this.documentsIdList1 = (response as any).data;

        this.documentService.uploadValuationRequestDocuments(this.fileUploadList2).subscribe(
          res => {
            this.documentsIdList2 = (res as any).data;
            this.valuationRequest.documentsIdList = this.documentsIdList1;
            this.valuationRequest2.documentsIdList = this.documentsIdList2;
            this.valuationRequest.created_date = new Date();
            this.valuationRequest2.created_date = new Date();
            this.valuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
            this.valuationRequest.noOfGranters = this.valuationRequest.granters.length;
            this.valuationRequest2.noOfGrantees = this.valuationRequest.granters.length;
            this.valuationRequest2.noOfGranters = this.valuationRequest.grantees.length;
            this.valuationRequest.valuationRequestStatus = Constant.VALUATION_REQUEST_COMPLETE_STATUS;
            this.valuationRequest2.valuationRequestStatus = Constant.VALUATION_REQUEST_COMPLETE_STATUS;
            // if(this.isDraft)
            // {
            //   this.valuationRequest.payment = this.valuationRequest.payment[0];
            //   this.valuationRequest.payment.amount = this.valuationRequest.payment.amount;
            //   this.valuationRequest.payment.bank = this.valuationRequest.payment.bank;
            //   this.valuationRequest.payment.branch = this.valuationRequest.payment.branch;
            //   this.valuationRequest.payment.paymentMethod = this.valuationRequest.payment.paymentMethod;
            //   this.valuationRequest.payment.referenceNo = this.valuationRequest.payment.referenceNo;

            //   this.valuationRequest2.payment = this.valuationRequest2.payment[0];
            //   this.valuationRequest2.payment.amount = this.valuationRequest2.payment.amount;
            //   this.valuationRequest2.payment.bank = this.valuationRequest2.payment.bank;
            //   this.valuationRequest2.payment.branch = this.valuationRequest2.payment.branch;
            //   this.valuationRequest2.payment.paymentMethod = this.valuationRequest2.payment.paymentMethod;
            //   this.valuationRequest2.payment.referenceNo = this.valuationRequest2.payment.referenceNo;
            // }
            const valuationRequestExchange = new ValuationRequestExchange();
            valuationRequestExchange.valuationOne = this.valuationRequest;
            valuationRequestExchange.valuationTwo = this.valuationRequest2;
            valuationRequestExchange.valuationOne.id = Number(localStorage.getItem('first_valuation_request_id'));
            valuationRequestExchange.valuationTwo.id = Number(localStorage.getItem('second_valuation_request_id'));
            console.log('Valuation Exchange............' + valuationRequestExchange);
            this.sub.add(this.valuationRequestService.createValuationRequestExchange(valuationRequestExchange)
              .subscribe(response1 => {
                const data = response1 as any;
                this.notifier.notify('success', 'Request created successfully');
                localStorage.removeItem('grantees');
                localStorage.removeItem('granters');
                localStorage.removeItem('deed');
                localStorage.removeItem('assessment');
                localStorage.removeItem('extend');
                localStorage.removeItem('other');
                localStorage.removeItem('loan');
                localStorage.removeItem('building');
                window.location = data.data.encryptedRequest;
              }, (error => {
                this.saveButton = 'Submit';
                this.notifier.notify('error', 'Please try again');
              })));
            console.log(this.valuationRequest);
          }, err => {
            this.saveButton = 'Submit';
            this.notifier.notify('error', 'Party 2 Document upload failed...');
          });
      }, err => {
        this.saveButton = 'Submit';
        this.notifier.notify('error', 'Party 1 Document upload failed...');
      });
  }

  checkOrNot() {
    if (this.declarationComponent.checkOrNot()) {
      this.selectMenu = this.selectMenu + 1;
    } else {
      this.notifier.notify('error', 'Please be agreed before move payment');
    }
  }

  checkDocuments() {
    this.errorList = [];

    if(this.otherComponent1.previouslyDocumentsUploaded == false && this.otherComponent2.previouslyDocumentsUploaded == false) {
      
      // For party 1
    if (this.otherComponent1.fileUploadCopyOfPreviousDeed.filesList.length === 0) {
      this.errorList.push('Party 1 : Copy of Previous Deed');
    }

    if (this.otherComponent1.fileUploadRoutePlan.filesList3.length === 0) {
      this.errorList.push('Party 1 : Route Plan');
    }

    if (this.otherComponent1.fileUploadDraftDeed.filesList1.length === 0) {
      this.errorList.push('Party 1 : Draft Deed');
    }

    if (this.otherComponent1.fileUploadGranteeNic.filesList5.length === 0) {
      this.errorList.push('Party 1 : Photo Copy of Grantee\'s NIC');
    }

    if (this.otherComponent1.fileUploadSurveyPlan.filesList2.length === 0) {
      this.errorList.push('Party 1 :Survey Plan / Building Plan');
    }


    // For party 2
    if (this.otherComponent2.fileUploadCopyOfPreviousDeed.filesList.length === 0) {
      this.errorList.push('Party 2 : Copy of Previous Deed');
    }

    if (this.otherComponent2.fileUploadRoutePlan.filesList3.length === 0) {
      this.errorList.push('Party 2 : Route Plan');
    }

    if (this.otherComponent2.fileUploadDraftDeed.filesList1.length === 0) {
      this.errorList.push('Party 2 : Draft Deed');
    }

    if (this.otherComponent2.fileUploadGranteeNic.filesList5.length === 0) {
      this.errorList.push('Party 2 : Photo Copy of Grantee\'s NIC');
    }

    if (this.otherComponent2.fileUploadSurveyPlan.filesList2.length === 0) {
      this.errorList.push('Party 2 :Survey Plan / Building Plan');
    }


    if (this.errorList.length === 0) {
      this.otherComponent1.setEveryChildComponentUploadingDocuments();
      this.otherComponent2.setEveryChildComponentUploadingDocuments();
      this.otherSelectedPaths1 = this.otherComponent1.selectPaths;
      this.otherSelectedPaths2 = this.otherComponent2.selectPaths;
      this.selectMenu = this.selectMenu + 1;
    } else {
      const re = /,/gi;
      console.log(this.errorList.toString());
      const errormsg = this.errorList.toString().replace(re, '<br/>');
      console.log(errormsg);

      Swal.fire({
        title: 'Please upload following required documents to proceed',
        icon: 'error',
        html: '<div style="color: red;text-align: left;">' + errormsg + '</div>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
          'OK',
      });

      // this.otherComponent.colorChanged();
      // this.notifier.notify('error', 'There are Required Documents : ' + this.errorList.toString() );
    }
    }else{
      console.log("documents are previously added")
      this.selectMenu = this.selectMenu + 1;
    }

  }
}
