import { Component, OnInit, ViewChild, ElementRef, TemplateRef, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { InspectionCommonService } from 'src/app/service/inspection-common/inspection-common.service';
import { InspectionCondominium } from 'src/app/model/inspection-condominium';
import { ActivatedRoute } from '@angular/router';
import { InspectionCondominiumUnit } from 'src/app/model/inspection-condominium-unit';
import { InspectionAccomodation } from 'src/app/model/inspection-accomodation';
import { InspectionAccomodationDetail } from 'src/app/model/inspection-accomodation-detail';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { InspectionService } from 'src/app/service/inspection/inspection.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constant } from 'src/app/util/constant';
import { InspectionCommon } from 'src/app/model/inspection-common';
import { InspectionDataServiceService } from 'src/app/service/data-service/inspection-data-service.service';
import { InspectionLand } from 'src/app/model/inspection-land';
import { InspectionLandBuilding } from 'src/app/model/inspection-land-building';
import { InspectionRecreation } from 'src/app/model/inspection-recreation';
import { NotifierService } from 'angular-notifier';
import { NgForm } from '@angular/forms';
import {InspectionLandBuildingMain} from '../../../../../../../model/inspection-building-main';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as L from 'leaflet';
import { FacilityType } from 'src/app/model/facility-type';
import { checkCompletedOrPaypend } from '../Util/checkStatus';

@Component({
  selector: 'app-inspection-report-condominium',
  templateUrl: './inspection-report-condominium.component.html',
  styleUrls: ['./inspection-report-condominium.component.scss']
})
export class InspectionReportCondominiumComponent implements OnInit, OnDestroy {
  @ViewChild('popupSuccessMessage', null) private popupMigrated: TemplateRef<object>;
  @ViewChild('dynamicAccomodation', null) private dynamicAccomodation: TemplateRef<object>;

  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('f', null)
  f: NgForm;

  @Input()
  valuationRequestId: string;
  @Input()
  valuationRequestStatus: string;

  public initialView: boolean;
  public secondView: boolean;
  public thirdView: boolean;


  public disableSaveButton: boolean;
  selectedPropertyType: any;
  selectedSubPropertyType: any;

  @Input()
  isAppeal:boolean;

  public filteredfacilitytypelist = [];
  public filteredspecialtypelist = [];
  public filteredsurroundtypelist = [];

  // Multi Select Dropdown Settings
  dropdownSettings1: NgMultiSelectDropDownModule;
  dropdownSettings2: NgMultiSelectDropDownModule;
  dropdownSettings3: NgMultiSelectDropDownModule;
  // Selected dropdown values
  selectedFacilityTypeItems = [];
  selectedSpecialViewItems = [];
  selectedSurroundTypeItems = [];

  public townList: any = [];
  public districtList: any = [];
  public localAuthorityList: any = [];
  public localAuthorityListtmp: any = [];
  public condominiumList: any = [];

  public facilityList: any = [];
  public specialViewList: any = [];
  public waterList: any = [];
  public electricityList: any = [];
  public securityList: any = [];
  public gardenList: any = [];
  public parkList: any = [];
  public agreementList: any = [];
  public incomeLevelTypeList: any = [];
  public surroundTypeList: any = [];
  public accomodationTypeList: any = [];

  public model = new InspectionCondominium();
  public formaccomodationModel = new InspectionAccomodation();

  public condominiumUnitModel = new InspectionCondominiumUnit('', '', '', '', '', '', '', []);
  public accomodationModel = new InspectionAccomodation();
  public accomodationDetailModel = new InspectionAccomodationDetail();

  public condominiumUnitLoopCount: number;
  public condominiumUnitCount: number;

  public condominiumAccomodationCount: number;

  public condominiumAccomodationDetailLoopCount: number;
  public condominiumAccomodationDetailCount: number;

  public selectedcondominiumUnitCount: number;

  // inserting each row of condominium unit
  public condominiumUnitModelList: any = [];

  // inserting each row of accomodation unit
  public condominiumAccomodationModelList: any = [];

  // inserting each row of accomodation detail unit
  public condominiumAccomodationDetailModelList: any = [];

  public userdata: any = [];

  public inspectionReportId: string;
  public recreationInspectionReportId: string;

  public inspectionCommonModel = new InspectionCommon('');

  public isUpdate: boolean;

  public inspectionCondominiumUnitsUpdateList: any = [];

  public selectedRecreationSubPropertyType: any;
  public selectedRecreationType: boolean;
  public selectedRecreationSubmitType: boolean;

  public propertySubTypeList: any = [];

  public inspectionDataServiceService: InspectionDataServiceService;

  public modelland = new InspectionLand();
  public modellandbuilding = new InspectionLandBuilding('','','',[],'',[],null);
  public modellandbuildingmain = new InspectionLandBuildingMain(null,'','','','', null, [], null, '', '', null, null);

  public onlycondominiumselected: boolean;

  public inspectionRecreationModel = new InspectionRecreation(false, false, false, null, null, null, null, null);

  public selectedRecreationUpdateType: boolean;

  valuationdata: any = [];

  public insertedFloorNo: string;
  public insertedUnitNo: string;

  locationDetails: any;
  longitude: any;
  latitude: any;

  public addRecord: boolean;
  public isSubPropertyChange: boolean;

  disableMainSaveBtn : boolean;

  checkStatusApplication  = checkCompletedOrPaypend;

  constructor(
    public modalService: NgbModal,
    public inspectionCommonService: InspectionCommonService,
    private route: ActivatedRoute,
    public inspectionService: InspectionService,
    private http: HttpClient,
    private notifier: NotifierService
  ) {
    this.isUpdate = false;
    this.selectedRecreationSubmitType = false;
    this.selectedRecreationType = false;
    this.initialView = true;
    this.secondView = false;
    this.thirdView = false;
    this.onlycondominiumselected = false;

    this.selectedcondominiumUnitCount = 0;
    this.condominiumUnitCount = 1;
    this.condominiumAccomodationCount = 0;
    this.condominiumAccomodationDetailCount = 1;

    this.selectedRecreationUpdateType = false;
    this.getSearchDetails("Colombo");

    this.addRecord = false;
    this.isSubPropertyChange = false;
    this.disableSaveButton = true;
    this.disableMainSaveBtn = false;

  }

  ngOnInit() {
    this.valuationdata = JSON.parse(sessionStorage.getItem('valuationRequest'));
    this.model.planNo = this.valuationdata.properties[0]['surveyorPlanNo'];
    this.model.lotNo = this.valuationdata.properties[0]['lotNo'];
    this.selectedRecreationUpdateType = JSON.parse(atob(localStorage.getItem('selectedRecreationUpdateType')));
    this.selectedRecreationType = JSON.parse(atob(localStorage.getItem('selectedRecreationType')));
    this.selectedRecreationSubPropertyType = JSON.parse(atob(localStorage.getItem('selectedRecreationSubPropertyType')));
    this._prepare();
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      // itemsShowLimit: 3,   // Removing the limit - Bug ID: 2305
      unSelectAllText: "Unselect"
    };
    this.dropdownSettings2 = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      // itemsShowLimit: 3,   // Removing the limit - Bug ID: 2305
      unSelectAllText: "Unselect"
    };
    this.dropdownSettings3 = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      // itemsShowLimit: 3,   // Removing the limit - Bug ID: 2305
      unSelectAllText: "Unselect"
    };
    window.scroll(0,0);
  }

  _prepare() {
    this.recreationInspectionReportId = atob(localStorage.getItem('recreationCondominiumInspectionId'));
    if (this.recreationInspectionReportId !== "undefined") {
      this.model.inspectionId = this.recreationInspectionReportId;
      this.loadCustomPropertyType();
    }
    this.inspectionReportId = atob(localStorage.getItem('inspectionId'));
    if (this.inspectionReportId !== "undefined") {
      this.isUpdate = true;
      this.retrieveUpdateFormDate();
    }
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    // this.valuationRequestId = this.route.snapshot.paramMap.get('id');
    // Check whether if it is a recreation
    if (this.selectedRecreationType) {
      this.loadPropertyType();
    } else {
      this.selectedPropertyType = JSON.parse(atob(localStorage.getItem('propertyType')));
      this.selectedSubPropertyType = JSON.parse(atob(localStorage.getItem('subPropertyType')));
    }
    this.townTypeList();
    this.districtTypeList();
    this.localAuthorityTypeList();
    this.condominiumTypeList();
    this.facilityTypeList();
    this.specialTypeList();
    this.waterTypeList();
    this.electricityTypeList();
    this.securityTypeList();
    this.gardenTypeList();
    this.parkTypeList();
    this.agreementTypeList();
    this.incomeLevelList();
    this.surroundList();
    this.accomodationTypeLevelList();
  }

  selectedDistrict(event) {
    const filteredresult = this.localAuthorityListtmp.filter(i => i.district_id === Number(event.target.value));
    if(filteredresult.length !==0){
      this.localAuthorityList = filteredresult;
    }
  }

  exitForm() {
    this.onPageCountChanged.emit(10);
  }


  public redirectPreviousComponent(): void {
    this.onPageCountChanged.emit(10);
  }

  openPopupMessage(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });
  }

  openPopupAccomodation(content) {
    this.modalService.open(content, { windowClass: 'my-class', ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });
  }

  counter(i: number) {
    return new Array(i);
  }

  incrementUnitCount() {
    if(this.condominiumUnitModelList.length > 0) {

      for (let i = 0; i<this.condominiumUnitModelList.length; i++) {
        if ((this.condominiumUnitModel.floor == this.model.inspectionCondominiumUnits[i].floor)
          && (this.condominiumUnitModel.unitNumber == this.model.inspectionCondominiumUnits[i].unitNumber)) {
          this.notifier.notify('error', 'Please enter a different Floor or Unit number');
          this.addRecord= false;
          break;
        } else {
          this.addRecord = true;
        }
      }

      if (this.addRecord == true) {
        this.condominiumUnitCount++;
        this.condominiumUnitModelList.push(this.condominiumUnitModel);
        this.model.inspectionCondominiumUnits = this.condominiumUnitModelList;
        this.condominiumUnitModel = new InspectionCondominiumUnit('', '', '', '', '', '', '', []);
      }

    } else {
      this.condominiumUnitCount++;
      this.condominiumUnitModelList.push(this.condominiumUnitModel);
      this.model.inspectionCondominiumUnits = this.condominiumUnitModelList;
      this.condominiumUnitModel = new InspectionCondominiumUnit('', '', '', '', '', '', '', []);
    }
  }

  convertSqmToSqft() {
    this.condominiumUnitModel.extentSQFT = this.condominiumUnitModel.extentSQM * 10.7639;
  }

  convertSftToSqm() {
    this.condominiumUnitModel.extentSQM = (this.condominiumUnitModel.extentSQFT / 10.7639).toFixed(2);
  }

  incrementAccomodationCount() {
    this.condominiumAccomodationCount++;
    this.accomodationModel = new InspectionAccomodation();
    this.condominiumAccomodationDetailCount = 1;
    this.model.inspectionCondominiumUnits[this.selectedcondominiumUnitCount].inspectionAccomodation.push(this.formaccomodationModel[0]);
    this.condominiumAccomodationModelList = [];
    this.condominiumAccomodationDetailModelList = [];
    this.formaccomodationModel = new InspectionAccomodation();
    this.modalService.dismissAll();
  }

  incrementAccomodationDetailCount() {

    this.disableSaveButton = false;
    // this is to loop the form
    this.condominiumAccomodationDetailCount = this.condominiumAccomodationDetailCount + 1;
    // this.condominiumAccomodationModelList[this.condominiumAccomodationCount] = this.accomodationModel;
    this.condominiumAccomodationModelList[0] = this.accomodationModel;
    // condominium accomodation model
    this.formaccomodationModel = this.condominiumAccomodationModelList;
    this.condominiumAccomodationDetailModelList.push(this.accomodationDetailModel);
    // condominium accomodation detail model
    this.formaccomodationModel[0].inspectionAccomodationDetails = this.condominiumAccomodationDetailModelList;
    this.accomodationDetailModel = new InspectionAccomodationDetail();
  }

  recreationUpdatePermission() {
    let page;
    if (this.selectedRecreationUpdateType) {
      const dataList = JSON.parse(atob(localStorage.getItem("recreationupdatereq")));
      const filtereddatalist = dataList.sort((obj1, obj2) => {
        if (obj1.inspectionId < obj2.inspectionId) {
          return 1;
        }
        if (obj1.inspectionId > obj2.inspectionId) {
          return -1;
        }
        return 0;
      });
      if (filtereddatalist.length > 0) {
        for (let i = 0; i < filtereddatalist.length; i++) {
          if (filtereddatalist[i].inspectionType === Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING) {
            localStorage.setItem('recreationLandBuildingInspectionId', btoa(filtereddatalist[i].inspectionId));
            page = "land_building";
            break;
          } else if (filtereddatalist[i].inspectionType === Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM) {
            localStorage.setItem('recreationCondominiumInspectionId', btoa(filtereddatalist[i].inspectionId));
            page = "condominium";
            break;
          }
        }
      }
    }
    this.model.inspectionId = this.recreationInspectionReportId;
    if (page === "condominium") {
      this.recreationUpdate();
    } else if (page === "land_building") {
      localStorage.setItem("isCondominium", JSON.stringify(true));
      localStorage.setItem("inspectioncondominiummodel", btoa(JSON.stringify(this.model)));
      this.onPageCountChanged.emit(13);
    }
  }

  netAnnualRent() {

  }

  loadCustomPropertyType() {
    this.inspectionCommonService.propertyUsageTypeList().subscribe(
      data => {
        const filtereddata = data.data.filter(i => i.id === Constant.INSPECTION_PROPERTY_TYPE_CONDOMINIUM_ID);
        this.selectedPropertyType = filtereddata[0];
        this.loadCustomPropertySubType();
      }
    )
  }

  loadCustomPropertySubType() {
    this.inspectionCommonService.propertyUsageSubTypeList().subscribe(
      data => {
        const filtereddata = data.data.filter(i => i.inspectionPropertyType.id === this.selectedPropertyType.id);
        this.propertySubTypeList = filtereddata;
        this.recreationRetrieveUpdateFormData();
      }
    )
  }

  recreationRetrieveUpdateFormData() {
    this.inspectionCommonModel.code = this.recreationInspectionReportId;
    this.inspectionService.inspectionCondominimumDetailList(this.inspectionCommonModel).subscribe(
      data => {
        this.model = data.data;
        console.log(this.model);
        this.model.inspectionId = this.recreationInspectionReportId;
        const filtereddata = this.propertySubTypeList.filter(i => i.id === Number(this.model.propertySubTypeId));
        this.selectedSubPropertyType = filtereddata[0];
      }
    )
  }

  loadPropertyType() {
    this.inspectionCommonService.propertyUsageTypeList().subscribe(
      data => {
        const filtereddata = data.data.filter(i => i.id === Constant.INSPECTION_PROPERTY_TYPE_CONDOMINIUM_ID);
        this.selectedPropertyType = filtereddata[0];
        this.loadPropertySubType();
      }
    )
  }

  loadPropertySubType() {
    this.inspectionCommonService.propertyUsageSubTypeList().subscribe(
      data => {
        const filtereddata = data.data.filter(i => i.inspectionPropertyType.id === this.selectedPropertyType.id);
        this.propertySubTypeList = filtereddata;
      }
    )
  }

  selectedSubPropertyTypeForRecreation(event) {
    const filtereddata = this.propertySubTypeList.filter(i => i.id === Number(event));
    this.selectedSubPropertyType = filtereddata[0];
    this.validateNavigation();
    this.isSubPropertyChange = true;
  }

  validateNavigation() {
    let page;
    if (this.selectedRecreationType) {
      if (this.selectedRecreationSubPropertyType.length > 0) {
        for (let i = 0; i < this.selectedRecreationSubPropertyType.length; i++) {
          if (this.selectedRecreationSubPropertyType[i].id === 3) {
            page = "land_building";
            break;
          }
        }
      }
    }
    if (page === "land_building") {
      this.selectedRecreationSubmitType = false;
    } else {
      this.selectedRecreationSubmitType = true;
    }
    if (this.selectedRecreationSubPropertyType.length === 1) {
      this.onlycondominiumselected = true;
    } else {
      this.onlycondominiumselected = false;
    }
  }

  recreationNavigation() {
    let page;
    if (this.selectedRecreationType) {
      if (this.selectedRecreationSubPropertyType.length > 0) {
        for (let i = 0; i < this.selectedRecreationSubPropertyType.length; i++) {
          if (this.selectedRecreationSubPropertyType[i].id === 3) {
            page = "land_building";
            break;
          }
        }
      }
    }
    if (page === "land_building") {
      this.initialModel();
      localStorage.setItem("isCondominium", JSON.stringify(true));
      this.onPageCountChanged.emit(13);
    } else {
      this.selectedRecreationSubmitType = true;
    }
    if (this.selectedRecreationSubPropertyType.length === 1) {
      this.onlycondominiumselected = true;
    } else {
      this.onlycondominiumselected = false;
    }
  }

  initialModel() {
    // Facility Type
    for (let i = 0; i < this.selectedFacilityTypeItems.length; i++) {
      const selectedId = this.selectedFacilityTypeItems[i]['id'];
      const filteredFacility = this.facilityList.filter(i => i.id === selectedId);
      this.filteredfacilitytypelist[i] = filteredFacility[0];
    }
    this.model.facilityTypes = this.filteredfacilitytypelist;
    this.model.facilityTypes.forEach(x => {
      delete x.id;
    });

    // Special Type
    for (let i = 0; i < this.selectedSpecialViewItems.length; i++) {
      const selectedId = this.selectedSpecialViewItems[i]['id'];
      const filteredFacility = this.specialViewList.filter(i => i.id === selectedId);
      this.filteredspecialtypelist[i] = filteredFacility[0];
    }
    this.model.specialViewTypes = this.filteredfacilitytypelist;
    this.model.specialViewTypes.forEach(x => {
      delete x.id;
    });

    // Surround Type
    for (let i = 0; i < this.selectedSurroundTypeItems.length; i++) {
      const selectedId = this.selectedSurroundTypeItems[i]['id'];
      const filteredFacility = this.surroundTypeList.filter(i => i.id === selectedId);
      this.filteredsurroundtypelist[i] = filteredFacility[0];
    }
    this.model.surroundTypes = this.filteredfacilitytypelist;
    this.model.surroundTypes.forEach(x => {
      delete x.id;
    });

    this.model.valuationRequestId = this.valuationRequestId;
    this.model.propertyTypeId = this.selectedPropertyType['id'];
    this.model.propertySubTypeId = this.selectedSubPropertyType['id'];
    this.model.createdBy = this.userdata['firstName'];
    this.model.inspectionType = Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM;
    localStorage.setItem("inspectioncondominiummodel", btoa(JSON.stringify(this.model)));

  }

  retrieveUpdateFormDate() {
    this.inspectionCommonModel.code = this.inspectionReportId;
    this.inspectionService.inspectionCondominimumDetailList(this.inspectionCommonModel).subscribe(
      data => {
        this.model = data.data;
        console.log(this.model);

        this.condominiumUnitModelList = this.model.inspectionCondominiumUnits;
        this.selectedFacilityTypeItems = this.model.facilityTypes;
        this.selectedSpecialViewItems = this.model.specialViewTypes;
        this.selectedSurroundTypeItems = this.model.surroundTypes;
        if (data.data.nearestTown != null || data.data.nearestTown != undefined) {
          this.getSearchDetails(data.data.nearestTown);
        }
      }
    )
  }

  removeMainCondominiumUnit(index: any) {
    if (this.model.inspectionCondominiumUnits.length > 0) {
      this.model.inspectionCondominiumUnits[index].inspectionAccomodation.splice(index, 1);
      this.model.inspectionCondominiumUnits.splice(index, 1);
    } else {
      this.model.inspectionCondominiumUnits.splice(index, 1);
    }
  }

  removeSubCondominiumUnit(index: any) {
    this.model.inspectionCondominiumUnits[this.selectedcondominiumUnitCount].inspectionAccomodation.splice(index, 1);
  }

  newAccomdationGrid(event, i) {
    if (event.target.checked === true) {
      this.selectedcondominiumUnitCount = i;
      this.openPopupAccomodation(this.dynamicAccomodation);
    }
  }

  newAccomdationGridInsert(inspection, i) {
    this.insertedFloorNo = inspection.floor;
    this.insertedUnitNo = inspection.unitNumber;
    this.accomodationModel.floor = this.insertedFloorNo;
    this.accomodationModel.unitNumber =  this.insertedUnitNo;
    this.accomodationDetailModel.numbers ="";
    this.accomodationDetailModel.remark = "";
    this.accomodationDetailModel.accomodationCategoryId="";
    this.selectedcondominiumUnitCount = i;
    this.disableSaveButton = true;
    this.openPopupAccomodation(this.dynamicAccomodation);
  }

  viewAccomdationGridInsert(i) {
    this.selectedcondominiumUnitCount = i;
  }

  getSubFloorValue(event, i) {
    this.accomodationModel.floor = event;
  }

  getSubUnitNoValue(event, i) {
    this.accomodationModel.unitNumber = event;
  }

  getCategoryValue(event, i) {
    this.condominiumAccomodationDetailLoopCount = i;
    this.accomodationDetailModel.accomodationCategoryId = event;
  }

  getSubNumberValue(event, i) {
    this.condominiumAccomodationDetailLoopCount = i;
    this.accomodationDetailModel.numbers = event;
  }

  getRemarkValue(event, i) {
    this.condominiumAccomodationDetailLoopCount = i;
    this.accomodationDetailModel.remark = event;
  }

  accomodationTypeLevelList() {
    this.inspectionCommonService.accomodationTypeList().subscribe(
      data => {
        this.accomodationTypeList = data.data;
      }
    )
  }

  incomeLevelList() {
    this.inspectionCommonService.incomeLevelTypeList().subscribe(
      data => {
        this.incomeLevelTypeList = data.data;
      }
    )
  }

  surroundList() {
    this.inspectionCommonService.surroundTypeList().subscribe(
      data => {
        this.surroundTypeList = data.data;
      }
    )
  }

  agreementTypeList() {
    this.inspectionCommonService.agreementTypeList().subscribe(
      data => {
        this.agreementList = data.data;
      }
    )
  }

  gardenTypeList() {
    this.inspectionCommonService.gardenTypeList().subscribe(
      data => {
        this.gardenList = data.data;
      }
    )
  }

  parkTypeList() {
    this.inspectionCommonService.parkTypeList().subscribe(
      data => {
        this.parkList = data.data;
      }
    )
  }

  securityTypeList() {
    this.inspectionCommonService.securityTypeList().subscribe(
      data => {
        this.securityList = data.data;
      }
    )
  }

  electricityTypeList() {
    this.inspectionCommonService.electricityTypeList().subscribe(
      data => {
        this.electricityList = data.data;
      }
    )
  }

  waterTypeList() {
    this.inspectionCommonService.waterTypeList().subscribe(
      data => {
        this.waterList = data.data;
      }
    )
  }

  specialTypeList() {
    this.inspectionCommonService.specialTypeList().subscribe(
      data => {
        this.specialViewList = data.data;
      }
    )
  }

  facilityTypeList() {
    this.inspectionCommonService.facilityTypeList().subscribe(
      data => {
        this.facilityList = data.data;
      }
    )
  }

  townTypeList() {
    this.inspectionCommonService.townTypeList().subscribe(
      data => {
        this.townList = data.data;
      }
    )
  }

  districtTypeList() {
    this.inspectionCommonService.districtTypeList().subscribe(
      data => {
        this.districtList = data.data;
      }
    )
  }

  localAuthorityTypeList() {
    this.inspectionCommonService.landLocationTypeList().subscribe(
      data => {
        this.localAuthorityList = data.data;
        this.localAuthorityListtmp = data.data;
      }
    )
  }

  condominiumTypeList() {
    this.inspectionCommonService.condominiumTypeList().subscribe(
      data => {
        this.condominiumList = data.data;
      }
    )
  }

  loadSecondComponent() {
    if (this.isSubPropertyChange || this.model.propertySubTypeId !== null) {
      this.initialView = false;
      this.secondView = true;
      this.thirdView = false;
      window.scroll(0,0);
    } else {
      this.notifier.notify('error', 'Please select a sub type first');
    }
    
  }

  loadFirstComponent() {
    if (this.model.nearestTown !== undefined) {
      this.getSearchDetails(this.model.nearestTown);
    } else {
      this.getSearchDetails('Colombo');
    }
    this.initialView = true;
    this.secondView = false;
    this.thirdView = false;
    window.scroll(0,0);
  }

  loadThirdComponent() {
    this.initialView = false;
    this.secondView = false;
    this.thirdView = true;
    window.scroll(0,0);
  }

  reset() {
    this.initialView = true;
    this.model = new InspectionCondominium();
    this.formaccomodationModel = new InspectionAccomodation();
    this.condominiumUnitModel = new InspectionCondominiumUnit('', '', '', '', '', '', '', []);
    this.accomodationModel = new InspectionAccomodation();
    this.accomodationDetailModel = new InspectionAccomodationDetail();
    this.disableMainSaveBtn = false;
  }

  redirectToMainTable() {
    this.modalService.dismissAll();
    this.redirectPreviousComponent();
  }

  onSubmit() {
    this.disableMainSaveBtn = true;
    // Facility Type
    for (let i = 0; i < this.selectedFacilityTypeItems.length; i++) {
      const selectedId = this.selectedFacilityTypeItems[i]['id'];
      const filteredFacility = this.facilityList.filter(i => i.id === selectedId);
      this.filteredfacilitytypelist[i] = filteredFacility[0];
    }
    this.model.facilityTypes = this.filteredfacilitytypelist;
    // this.model.facilityTypes.forEach(x => {
    //   delete x.id;
    // });

    // Special Type
    for (let i = 0; i < this.selectedSpecialViewItems.length; i++) {
      const selectedId = this.selectedSpecialViewItems[i]['id'];
      const filteredFacility = this.specialViewList.filter(i => i.id === selectedId);
      this.filteredspecialtypelist[i] = filteredFacility[0];
    }
    this.model.specialViewTypes = this.filteredspecialtypelist;
    // this.model.specialViewTypes.forEach(x => {
    //   delete x.id;
    // });

    // Surround Type
    for (let i = 0; i < this.selectedSurroundTypeItems.length; i++) {
      const selectedId = this.selectedSurroundTypeItems[i]['id'];
      const filteredFacility = this.surroundTypeList.filter(i => i.id === selectedId);
      this.filteredsurroundtypelist[i] = filteredFacility[0];
    }
    this.model.surroundTypes = this.filteredsurroundtypelist;
    // this.model.surroundTypes.forEach(x => {
    //   delete x.id;
    // });

    // Valuation Request Id
    let exchangeSelectedParty = sessionStorage.getItem('selectedPartyInExchange');
    // Valuation request ID is assigned according to selected party
    if(exchangeSelectedParty === '1') {
      this.model.valuationRequestId = this.valuationdata.id;
    } else if(exchangeSelectedParty === '2') {
      this.model.valuationRequestId = this.valuationdata.id + 1;
    }
    // this.model.valuationRequestId = this.valuationdata.id;

    // Property Type Id
    this.model.propertyTypeId = this.selectedPropertyType['id'];
    // Property Sub Type Id
    this.model.propertySubTypeId = this.selectedSubPropertyType['id'];
    this.model.createdBy = this.userdata['firstName'];
    this.model.inspectionType = Constant.INSPECTION_TYPE_CONDOMINIUM;
    this.inspectionService.inspectionCondominiumInsert(this.model).subscribe(
      data => {
        this.openPopupMessage(this.popupMigrated);
        this.reset();
        this.disableMainSaveBtn = false;
      },
      err => {
        this.disableMainSaveBtn = false;
        this.notifier.notify('error', 'Error occurred while processing. Please try again.');
      }
    )
  }

  onUpdate() {
    this.disableMainSaveBtn = true;
    this.model.createdBy = this.userdata['firstName'];
    this.model.inspectionType = Constant.INSPECTION_TYPE_LAND;
    this.model.inspectionId = atob(localStorage.getItem('inspectionId'));
    // Facility Type
    for (let i = 0; i < this.selectedFacilityTypeItems.length; i++) {
      const selectedId = this.selectedFacilityTypeItems[i]['id'];
      const filteredFacility = this.facilityList.filter(i => i.id === selectedId);
      this.filteredfacilitytypelist[i] = filteredFacility[0];
    }
    this.model.facilityTypes = this.filteredfacilitytypelist;


    // Special Type
    for (let i = 0; i < this.selectedSpecialViewItems.length; i++) {
      const selectedId = this.selectedSpecialViewItems[i]['id'];
      const filteredFacility = this.specialViewList.filter(i => i.id === selectedId);
      this.filteredspecialtypelist[i] = filteredFacility[0];
    }
    this.model.specialViewTypes = this.filteredspecialtypelist;


    // Surround Type
    for (let i = 0; i < this.selectedSurroundTypeItems.length; i++) {
      const selectedId = this.selectedSurroundTypeItems[i]['id'];
      const filteredFacility = this.surroundTypeList.filter(i => i.id === selectedId);
      this.filteredsurroundtypelist[i] = filteredFacility[0];
    }
    this.model.surroundTypes = this.filteredsurroundtypelist;

    this.inspectionService.inspectionCondominiumUpdate(this.model).subscribe(
      data => {
        this.openPopupMessage(this.popupMigrated);
        this.disableMainSaveBtn = false;
        this.reset();
      },
      err => {
        this.disableMainSaveBtn = false;
        this.notifier.notify('error', 'Error occurred while processing. Please try again.');
      }
    )
  }

  onSubmitRecreation() {
    // Facility Type
    for (let i = 0; i < this.selectedFacilityTypeItems.length; i++) {
      const selectedId = this.selectedFacilityTypeItems[i]['id'];
      const filteredFacility = this.facilityList.filter(i => i.id === selectedId);
      this.filteredfacilitytypelist[i] = filteredFacility[0];
    }
    this.model.facilityTypes = this.filteredfacilitytypelist;
    // this.model.facilityTypes.forEach(x => {
    //   delete x.id;
    // });

    // Special Type
    for (let i = 0; i < this.selectedSpecialViewItems.length; i++) {
      const selectedId = this.selectedSpecialViewItems[i]['id'];
      const filteredFacility = this.specialViewList.filter(i => i.id === selectedId);
      this.filteredspecialtypelist[i] = filteredFacility[0];
    }
    this.model.specialViewTypes = this.filteredspecialtypelist;
    // this.model.specialViewTypes.forEach(x => {
    //   delete x.id;
    // });

    // Surround Type
    for (let i = 0; i < this.selectedSurroundTypeItems.length; i++) {
      const selectedId = this.selectedSurroundTypeItems[i]['id'];
      const filteredFacility = this.surroundTypeList.filter(i => i.id === selectedId);
      this.filteredsurroundtypelist[i] = filteredFacility[0];
    }
    this.model.surroundTypes = this.filteredsurroundtypelist;
    // this.model.surroundTypes.forEach(x => {
    //   delete x.id;
    // });

    // Valuation Request Id
    this.model.valuationRequestId = this.valuationRequestId;
    // Property Type Id
    this.model.propertyTypeId = this.selectedPropertyType['id'];
    // Property Sub Type Id
    this.model.propertySubTypeId = this.selectedSubPropertyType['id'];
    this.model.createdBy = this.userdata['firstName'];
    this.model.inspectionType = Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM;

    this.inspectionRecreationModel.isLand = JSON.parse(localStorage.getItem("isLand"));
    this.inspectionRecreationModel.isCondominium = true;
    this.inspectionRecreationModel.inspectionLandDto = JSON.parse(atob(localStorage.getItem("inspectionlandmodel")));
    this.inspectionRecreationModel.inspectionCondominiumDto = this.model;
    this.inspectionService.inspectionRecreationInsert(this.inspectionRecreationModel).subscribe(
      data => {
        this.openPopupMessage(this.popupMigrated);
        this.reset();
      },
      err => {
        this.notifier.notify('error', 'Error occurred while processing. Please try again.');
      }
    )
  }

  recreationUpdate() {
    this.model.valuationRequestId = this.valuationRequestId;
    this.model.propertyTypeId = this.selectedPropertyType['id'];
    this.model.propertySubTypeId = this.selectedSubPropertyType['id'];
    this.model.createdBy = this.userdata['firstName'];

    this.inspectionRecreationModel.isLand = JSON.parse(localStorage.getItem("isLand"));
    this.inspectionRecreationModel.isCondominium = true;
    this.inspectionRecreationModel.isLandBuilding = false;

    this.inspectionRecreationModel.inspectionLandDto = JSON.parse(atob(localStorage.getItem("inspectionlandmodel")));
    this.inspectionRecreationModel.inspectionCondominiumDto = this.model;

    this.inspectionService.inspectionRecreationUpdate(this.inspectionRecreationModel).subscribe(
      data => {
        if (data.data) {
          this.openPopupMessage(this.popupMigrated);
          this.reset();
        } else {
          this.notifier.notify('error', 'Error occurred while processing. Please try again.');
        }
      },
      err => {
        this.notifier.notify('error', 'Error occurred while processing. Please try again.');
      }
    )
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  ngOnDestroy() {
    this.isUpdate = false;
    localStorage.setItem('inspectionId', btoa(undefined));
    this.modalService.dismissAll();
  }

  getSearchDetails(location: String) {
    this.http.post<any>('https://nominatim.openstreetmap.org/search?q=' + location + '&format=geojson', { title: 'Angular POST Request Example' }).subscribe(data => {
      this.locationDetails = data;
      console.log(this.locationDetails);
      if (this.locationDetails.features.length > 0) {
        this.longitude = this.locationDetails.features[0].geometry.coordinates[0];
        this.latitude = this.locationDetails.features[0].geometry.coordinates[1];
        document.getElementById('geomap').innerHTML = "<div id='map' style='width: 100%; height: 100%;'></div>";
        var map = new L.Map('map', { attributionControl: false });
        map.setView(new L.LatLng(this.latitude, this.longitude), 9);
        map.zoomIn(9);
        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
          attribution: ''
        }).addTo(map);
        L.marker([this.latitude, this.longitude]).addTo(map).bindPopup(this.locationDetails.features[0].properties.display_name).openPopup();
      } else {
        document.getElementById('geomap').innerHTML = "<div id='map' style='width: 100%; height: 100%;'></div>";
        var map = new L.Map('map', { attributionControl: false });
        map.setView(new L.LatLng(6.9270786, 79.861243), 9);
      }
    })
  }
}
