import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {CompanyTypeService} from '../../../../../../../service/company-type/company-type.service';
import { UpdateCompanyTypeComponent } from '../update-company-type/update-company-type.component';
import { DeleteCompanyTypeComponent } from '../delete-company-type/delete-company-type.component';
import { SubSink } from 'subsink';
import { DataService } from 'src/app/service/data.service';
import {ICompanyType} from '../../../../../../../model/interfaces/company-type';

@Component({
  selector: 'app-view-company-type',
  templateUrl: './view-company-type.component.html',
  styleUrls: ['./view-company-type.component.scss']
})
export class ViewCompanyTypeComponent implements OnInit, OnDestroy {

  companyTypes: ICompanyType [];
  private sub = new SubSink();
  pageNo: number;

  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];

  @ViewChild(UpdateCompanyTypeComponent, {static: false})
  private updateCompanyTypeComponent: UpdateCompanyTypeComponent;
  @ViewChild(DeleteCompanyTypeComponent, {static: false})
  private deleteCompanyTypeComponent: DeleteCompanyTypeComponent;
  currentPage: any;
  totalItems: any;



  constructor(private companyTypeService: CompanyTypeService,   private dataService: DataService) { }

  ngOnInit() {
    this.selectedPageNo = 0;
    this.limit = 10;

    this.companyTypeService.getAll().subscribe(
      response => {
        const res = response.data.length;
        this.pageNo = Math.round(res / 10);

      });

    this.sub.add(this.companyTypeService.getCompanyTypes(0, 10)
    .subscribe(
      (res) => {
        this.companyTypes = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      })
    );

    this.sub.add(this.dataService.updateDataTableCompanyType
      .subscribe(update => this.onAdded(update)));

  }
  pageable(i: number) {
    console.log('i' + i);
    console.log(i * 10 + ' - ' + ((i * 10) + 10));
    this.companyTypeService.getCompanyTypes((i-1), 10).subscribe(responce => {
      this.companyTypes = (responce as any).data.content;
      this.currentPage = (responce as any).data.number+1;
      this.totalItems = (responce as any).data.totalElements;
    });
  }
  counter(i: number) {
    return new Array(i);
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  triggerUpdate(id: number, name: string, description: string) {
    this.updateCompanyTypeComponent.setIdAndDescription(id, name, description);
  }

  triggerDelete(id: number) {
    this.deleteCompanyTypeComponent.setCompanyTypeId(id);
  }

  onUpdated(updatedSuccessfully: boolean) {
    if (updatedSuccessfully) {
      this.updateTheTable();
    }
  }

  onDeleted(deletedSuccessfully: boolean) {
    if (deletedSuccessfully) {
      this.updateTheTable();
    }
  }

  onAdded(addedSuccessfully: boolean) {
    if (addedSuccessfully) {
      this.updateTheTable();
    }
  }

  // update the table
  private updateTheTable() {
   this.sub.add(this.companyTypeService.getCompanyTypes(0, 10)
    .subscribe(
      (res) => {
        this.companyTypes =  (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      }));
  }

  searchCompanyType(keyword: string) {
    this.selectedPageNo = 0;
    this.sub.add(this.companyTypeService.searchCompanyType(keyword, this.selectedPageNo, this.limit)
      .subscribe((res) => {
        this.companyTypes =  (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
        // this.companyTypes = res.data.content;
        // this.paginationButtonCounter(res.data.totalPages);
        // this.numOfPages = res.data.totalPages;
      })
    );
  }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }


}
