import {BuildingFloor} from './building-floor';

export class Building {
  public planNo: string;
  public planDate: any;
  public unitNumber: any;
  public yearOfConstruct: any;
  public noOfFloors: any;
  public totalFloorArea: number;
  public buildingFloors: BuildingFloor[] = [];
}
