import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubSink} from 'subsink';
import {DocumentTypeService} from '../../../../../../service/document-type/document-type.service';
import {NotifierService} from 'angular-notifier';
import {DataService} from '../../../../../../service/data.service';
import {DocumentType} from '../../../../../../model/document-type';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  addUserTypeForm: FormGroup;
  private sub = new SubSink();
  userRoles = [
    'Super Admin',
    'Admin'
  ];

  constructor(private documentTypeService: DocumentTypeService,
              private formBuilder: FormBuilder,
              private notifier: NotifierService,
              private dataService: DataService) {
  }

  ngOnInit() {
    this.addUserTypeForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      userRole: ['', [Validators.required]],
      email: ['', [Validators.required]]
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  saveUserType() {
    if (this.addUserTypeForm.valid) {

      const documentType = new DocumentType();

      documentType.description = this.getDescription();
      documentType.code = this.getCode();
      documentType.name = this.getName();
      this.sub.add(this.documentTypeService.createDocumentTYpe(documentType)
        .subscribe(response => {
          this.notifier.notify('success', (response as any).message);
          this.dataService.updateTableDocumentType(true);
        }));
    }
  }

  getCode() {
    return this.addUserTypeForm.get('code').value;
  }
  getDescription() {
    return this.addUserTypeForm.get('description').value;
  }
  getName() {
    return this.addUserTypeForm.get('name').value;
  }


}
