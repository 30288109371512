import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ReportServiceService } from "../service/report-service.service";
import { NotifierService } from "angular-notifier";
import * as XLSX from "xlsx";

@Component({
	selector: 'app-not-certified-opinion-report',
	templateUrl: './not-certified-opinion-report.component.html',
	styleUrls: ['./not-certified-opinion-report.component.scss']
})
export class NotCertifiedOpinionReportComponent implements OnInit {

	// for period
	selectedItemsP = [];
	dropdownListP = [];
	dropdownSettingsP: IDropdownSettings = {};

	period = [
		{ id: 1, name: '1st 6 months' },
		{ id: 2, name: '2nd 6 Months' }
	]

	// for allocated regional branches
	selectedItemsARB = [];
	dropdownListARB = [];
	dropdownSettingsARB: IDropdownSettings = {};

	// submitted date range
	fromDate: string = null;
	toDate: string = null;
	year: string = null;

	// report data
	public reportDetails: any = [];
	public showPrint: boolean = false;
	public selectedDetails: any;
	public download_excel_status: boolean = false;

	// report data footer data
	public total_count: number = 0;
	public total_opinion_count: number = 0;
	public total_jan_count: number = 0;
	public total_feb_count: number = 0;
	public total_mar_count: number = 0;
	public total_apr_count: number = 0;
	public total_may_count: number = 0;
	public total_jun_count: number = 0;
	public total_jul_count: number = 0;
	public total_aug_count: number = 0;
	public total_sep_count: number = 0;
	public total_oct_count: number = 0;
	public total_nov_count: number = 0;
	public total_dec_count: number = 0;
	public total_transfer_count: number = 0;
	public total_gift_count: number = 0;
	public total_exchange_count: number = 0;

	constructor(
		public modalService: NgbModal,
		public reportService: ReportServiceService,
		private notifier: NotifierService) {

	}

	ngOnInit() {
		// set up period dropdown
		this.dropdownSettingsP = {
			singleSelection: false,
			idField: 'id',
			textField: 'name',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		// set up allocated regional branches dropdown
		this.dropdownSettingsARB = {
			singleSelection: false,
			idField: 'id',
			textField: 'office',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		this.get_all_allocated_branches();
		this.get_all_period();
	}

	/******** LOAD DROP DOWN DATA **************/
	// retrieve period
	get_all_period() {
		this.dropdownListP = this.period;
	}

	// retrieve allocated branches data from service
	get_all_allocated_branches() {
		this.reportService.getAllAB().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListARB = value.data;
			} else {
				console.log("No Allocated Regional Branch return");
			}
		});
	}
	/******** LOAD DROP DOWN DATA **************/

	/******** CAPTURE INPUTS **************/
	captureSelectedDetails() {
	/*	if (this.fromDate == null || this.toDate == null) {
			this.notifier.notify('error', 'Please enter from and to date!');
			return false;*/
		 /*else {*/

    var date_range = ((this.fromDate == null) && (this.toDate == null)) ? "All Date" : this.fromDate + ' to '+ this.toDate ;

			// capture selected period
			var selectedP_Names = "";
			var selectedP = "";
			if (this.selectedItemsP != null) {
				this.selectedItemsP.forEach(value => {
					selectedP += value.id + ",";
					selectedP_Names += value.name + ", ";
				});

				selectedP = selectedP.slice(0, -1);
				selectedP_Names = selectedP_Names.slice(0, -1);
			}

			if (this.selectedItemsP != null && this.dropdownListP != null &&
				this.dropdownListP.length == this.selectedItemsP.length) {
				selectedP_Names = "All";
			}

			// capture selected allocated regional branches
			var selectedARB = "";
			var selectedARB_Names = "";
			if (this.selectedItemsARB != null) {
				this.selectedItemsARB.forEach(value => {
					selectedARB += value.id + ",";
					selectedARB_Names += value.office + ", ";
				});

				selectedARB = selectedARB.slice(0, -1);
				selectedARB_Names = selectedARB_Names.slice(0, -1);
			}

			if (this.selectedItemsARB != null && this.dropdownListARB != null &&
				this.dropdownListARB.length == this.selectedItemsARB.length) {
				selectedARB_Names = "All";
			}


    this.selectedDetails = {
				fromDate: this.fromDate,
				toDate: this.toDate,
				year: this.year,
        selected_date :date_range,
				//selected_date: this.fromDate + ' to ' + this.toDate,

				selectedP_id: selectedP,
				selectedP_names: selectedP_Names,

				selectedARB_id: selectedARB,
				selectedARB_names: selectedARB_Names
			};

			return true;
		}

	/******** CAPTURE INPUTS **************/

	/*************** UI EVENT ***************/
	// event click on close button
	closeFurtherReqRemark() {
		this.modalService.dismissAll();
	}

	createExcel() {
		this.captureSelectedDetails();
		this.getReportData();
		this.showPrint = true;
		setTimeout(() => {
			var printContents = document.getElementById('excel_print');
			const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(printContents);
			const wb: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'Not Certified Opinion Dtls.');
			XLSX.writeFile(wb, 'Not Certified Opinion Report.xlsx');
		}, 1000);
	}

	generatePdfReport() {
		this.captureSelectedDetails();
		this.getReportData();
		this.showPrint = true;
		setTimeout(() => {
			this.createPdfReport();
		}, 1000);

	}

	onItemSelectP(item: any) {
		// console.log(item);
	}
	onSelectAllP(items: any) {
		// console.log(items);
	}

	onItemSelectARB(item: any) {
		// console.log(item);
	}
	onSelectAllARB(items: any) {
		// console.log(items);
	}
	/*************** UI EVENT ***************/

	/*************** GET REPORT DATA ***************/
	getReportData(): void {
		this.reportService.getNotCertifiedOpinionDetailReport(this.selectedDetails.fromDate, this.selectedDetails.toDate, this.selectedDetails.selectedARB_id, this.selectedDetails.selectedP, this.selectedDetails.year).subscribe(value => {
			console.log(value);
			if (value.data.data.length > 0) {
				console.log(value.data);
				this.reportDetails = value.data.data;

				this.total_count = value.data.totalOpinionCount;
				this.total_jan_count = value.data.janTotalOpinionCount;
				this.total_feb_count = value.data.febTotalOpinionCount;
				this.total_mar_count = value.data.marTotalOpinionCount;
				this.total_apr_count = value.data.aprTotalOpinionCount;
				this.total_may_count = value.data.mayTotalOpinionCount;
				this.total_jun_count = value.data.junTotalOpinionCount;
				this.total_jul_count = value.data.julTotalOpinionCount;
				this.total_aug_count = value.data.augTotalOpinionCount;
				this.total_sep_count = value.data.sepTotalOpinionCount;
				this.total_oct_count = value.data.octTotalOpinionCount;
				this.total_nov_count = value.data.novTotalOpinionCount;
				this.total_dec_count = value.data.decTotalOpinionCount;
				this.total_opinion_count = value.data.totalPendingOpinionCount;
				this.total_transfer_count = value.data.transferTotalOpinionCount;
				this.total_gift_count = value.data.giftTotalOpinionCount;
				this.total_exchange_count = value.data.exchangeTotalOpinionCount;
			} else {
				this.notifier.notify('error', 'No records found!');
				console.log("No data found");
			}
		});
	}
	/*************** GET REPORT DATA ***************/

	/*************** GENERATE REPORT ***************/
	createPdfReport(): void {

		let printContents: any;
		let popupWin: any;
		printContents = document.getElementById('notCertifiedOpinion').innerHTML;

		popupWin = window.open();
		popupWin.document.open();
		popupWin.document.write(`
		<html>
		<head>
			<title>Not Certified Opinion Report</title>
			<meta charset="utf-8">
			<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
			<!--<script src="../../../../assets/js/paged.js"></script>-->
			<!--<script src="../../../../assets/js/paged.polyfill.js"></script>-->
			<style>
			  @page {
				size: A3 landscape;
				@bottom-right {
				  content: "Page " counter(page) " of " counter(pages);
				}
				.page{
					page-break-after: always;
				}
			  }

				body
				{
					margin: 10mm 10mm 0mm 10mm;

				}
				table{
				  border: 1px solid #000;
				  border-collapse: collapse;
				}
				tbody{
				  page-break-before: always;
				}

				table th, table td
				{
					padding: 5px;
					margin: 0px;
					border: 1px solid #000;
				}
				.page{
					page-break-after: always;
				}

			</style>
		</head>
		<body onload="window.print();">
				${printContents}
		</body>
		</html>`
		);
		popupWin.document.close();
	  }
	  /*************** GENERATE REPORT ***************/
}
