import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import {Observable, of, throwError} from 'rxjs';
import { environment } from 'src/environments/environment';
import { Province } from 'src/app/model/province';
import { tap, catchError } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import {IProvincePage} from '../../model/interfaces/province-page';
import {IValuationRequest} from "../../model/interfaces/valuation-request";

@Injectable({
  providedIn: 'root'
})
export class ProvinceService {

  private provinceUrl = '/provinces';
  private districtLoadUrl = '/districts';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }


  getProvinces(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.provinceUrl + '?offset=' + offset + '&limit=' + limit + '');
  }
  getProvincesOrderd(): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.provinceUrl + '/allordered');
  }

  getAll(): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.provinceUrl + '/all');

  }

  createProvince(province: Province): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.provinceUrl}`,
      JSON.stringify(province), this.httpOptions)
      .pipe(tap(_ => this.log(`update province type create=${province}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('province  create'))
      );
  }

  updateProvince(id: number, province: Province): Observable<any> {
    return this.http.put(`${environment.api_url}${this.provinceUrl}/${id}`, province)
      .pipe(tap(_ => this.log(`update Province  id=${province.id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
      catchError(this.handleError<any>('Province update'))
    );
  }

  /** DELETE: delete the company category on the server */
  deleteProvince(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.provinceUrl}/${id}`)
      .pipe(tap(_ => this.log(`update Province  id=${id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
      catchError(this.handleError<any>('Province'))
    );
  }

  /* SEARCH: search Province from server*/
  searchProvince(keyword: string, offset: number, limit: number): Observable<IProvincePage> {
    // Parameters
    const params = {
      params: {
        offset: offset.toString(),
        limit: limit.toString(),
        keyword
      }
    };

    return this.http.get<IProvincePage>(`${environment.api_url}${this.provinceUrl}/search`, params)
      .pipe(tap(_ => this.log('search province')),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('get province'))
      );
  }

  getDistrictByProvince(provinceId: number): Observable<any> {
    return this.http.get(`${environment.api_url}${this.districtLoadUrl}/load-district-by-province/${provinceId}`)
      .pipe(tap(_ => this.log(`district has failed to load`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('district has failed to load'))
      );
  }

  // /** GET: Province By Id **/
  // getDistrictByProvinceId(provinceId: number): Observable<any> {
  //   return this.http.get(`${environment.api_url}${this.provinceUrl}/${provinceId}/property-usage-sub-types`)
  //     .pipe(tap(_ => this.log(`district get failed`)),
  //       catchError(err => {
  //         this.notifier.notify('error', err);
  //         return throwError(err);
  //       }),
  //       catchError(this.handleError<any>('district get failed'))
  //     );
  // }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {

  }



}
