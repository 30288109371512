import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { Employee } from 'src/app/model/employee';
import { DataService } from 'src/app/service/data.service';
import { EmployeeService } from 'src/app/service/employee/employee.service';
import { OfficeService } from 'src/app/service/office/office.service';
import { SubSink } from 'subsink';
import * as moment from 'moment';

@Component({
  selector: 'app-update-employee',
  templateUrl: './update-employee.component.html',
  styleUrls: ['./update-employee.component.scss']
})
export class UpdateEmployeeComponent implements OnInit {

  @Output() updateEmployeeEvent= new EventEmitter<boolean>();
  maxDate: Date;
  updateEmployeeForm: FormGroup;

  private sub = new SubSink();
  activationButton = false;
  public employeeModel = new Employee(null,"","","","","","","","","","","","","");


  // Validations
  nicPattern = '^([0-9]{9}[x|X|v|V]|[0-9]{12}|k[a-p|A-p|R|r|S|s|T|t|U|u|V|v|W|w|Y|y][0-9]{8}|[0-9]{7}[a-p|A-p|R|r|S|s|T|t|U|u|V|v|W|w|Y|y])$';
  emailPattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
  phoneNumberPattern = '^(?:0|94|\\+94|0094)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|91)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\\d)\\d{6}$';
  onlyTextPattern = '^[ a-zA-Z]*$';
  onlyTextPatternInitals = '^[ a-zA-Z.]*$';
  addressNumberPattern = /^[ a-zA-Z0-9()-./,\']*$/;
  addressPattern = '^[ a-zA-Z0-9()-.,/]*$';
  cityPattern = '^[ a-zA-Z0-9-]*$';
  allOffice: any;

  constructor(
    private formBuilder: FormBuilder,
    private employeeService: EmployeeService,
    private notifier: NotifierService,
    private dataService: DataService,
    public officeService : OfficeService,
  ) { }

  ngOnInit() {
    this.updateEmployeeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      title: ['', [Validators.required]],
      firstName: ['', [Validators.required,Validators.pattern(this.onlyTextPattern)]],
      lastName: ['', [Validators.required,Validators.pattern(this.onlyTextPattern)]],
      middleName: ['', [Validators.pattern(this.onlyTextPattern)]],
      nic: ['', [Validators.required,Validators.pattern(this.nicPattern)]],
      designation: ['', [Validators.required]],
      districtOffice: ['', [Validators.required]],
      appointedDate: ['', [Validators.required]],
      address: ['', [Validators.required]],
      mobile: ['', [Validators.required,Validators.pattern(this.phoneNumberPattern)]],
      office: ['', [Validators.required,Validators.pattern(this.phoneNumberPattern)]],
      email: ['', [Validators.required,Validators.email]],
    });

    this.maxDate = new Date();
    this.getAllOffice();
  }
  getAllOffice(){
    this.officeService.getAll().subscribe(data=>{
      this.allOffice = data.data;
    })
  }
  get f() { return this.updateEmployeeForm.controls; }


  //set data To employee
  setEmployee(employee:any){
    this.updateEmployeeForm.get('id').setValue(employee.id);
    this.updateEmployeeForm.get('title').setValue(employee.title);
    this.updateEmployeeForm.get('firstName').setValue(employee.firstName);
    this.updateEmployeeForm.get('lastName').setValue(employee.lastName);
    this.updateEmployeeForm.get('middleName').setValue(employee.middleName);
    this.updateEmployeeForm.get('nic').setValue(employee.nic);
    this.updateEmployeeForm.get('designation').setValue(employee.designation);
    this.updateEmployeeForm.get('districtOffice').setValue(employee.districtOffice);
    this.updateEmployeeForm.get('appointedDate').setValue(employee.appointedDate);
    this.updateEmployeeForm.get('address').setValue(employee.address);
    this.updateEmployeeForm.get('mobile').setValue(employee.mobileNo);
    this.updateEmployeeForm.get('office').setValue(employee.officeNo);
    this.updateEmployeeForm.get('email').setValue(employee.email);

    if(employee.status == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }

  }

  changeActivation(){
    if(this.activationButton == true){
      this.employeeModel.status = "DEACTIVE"
      this.activationButton = false;
    }else{
      this.employeeModel.status = "ACTIVE"
      this.activationButton = true;
    }
  }

  updateTheView(update:boolean) {
    this.updateEmployeeEvent.emit(update);
  }

  setDataToModel(){
    this.employeeModel.id = this.updateEmployeeForm.get('id').value;
    this.employeeModel.title = this.updateEmployeeForm.get('title').value;
    this.employeeModel.firstName = this.updateEmployeeForm.get('firstName').value;
    this.employeeModel.lastName = this.updateEmployeeForm.get('lastName').value;
    this.employeeModel.middleName = this.updateEmployeeForm.get('middleName').value;
    this.employeeModel.nic = this.updateEmployeeForm.get('nic').value;
    this.employeeModel.designation = this.updateEmployeeForm.get('designation').value;
    this.employeeModel.districtOffice = this.updateEmployeeForm.get('districtOffice').value;
    this.employeeModel.appointedDate = this.updateEmployeeForm.get('appointedDate').value;
    this.employeeModel.address = this.updateEmployeeForm.get('address').value;
    this.employeeModel.mobileNo = this.updateEmployeeForm.get('mobile').value;
    this.employeeModel.officeNo = this.updateEmployeeForm.get('office').value;
    this.employeeModel.email = this.updateEmployeeForm.get('email').value;

    if(this.activationButton == true){
      this.employeeModel.status = "ACTIVE";
    }else if(this.activationButton == false){
      this.employeeModel.status = "DEACTIVE";
    }
  }

  updateEmployee(){
    if(this.updateEmployeeForm.valid){
      this.setDataToModel();
      this.sub.add(this.employeeService.updateEmployee(this.employeeModel.id, this.employeeModel).subscribe(data=>{
        this.notifier.notify('success', (data as any).message);
        this.updateTheView(true);
      }))
    }else{
      this.updateEmployeeForm.markAllAsTouched();
    }

  }

  selectTitle(event) {

  }

  checkDateValidity($event) {
    if (!moment(new Date()).isAfter(moment($event.target.value))) {
      this.updateEmployeeForm.controls.appointedDate.setErrors({invalidDate:true})
    }else{
      this.updateEmployeeForm.controls.appointedDate.setErrors(null)
    }
  }
}
