import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {CompanyCategory} from '../../../../../../model/companyCategory';
import {PropertyUsageService} from '../../../../../../service/property-usage/property-usage.service';
import {DeedNature} from '../../../../../../model/deed-nature';
import {PropertyFacility} from '../../../../../../model/property-facility';
import {LocalAuthorityService} from '../../../../../../service/local-authority/local-authority.service';
import {PropertyUsageSubtypeService} from '../../../../../../service/property-usage-subtype/property-usage-subtype.service';
import {CompanyType} from '../../../../../../model/companyType';
import {CompanyCategoryService} from '../../../../../../service/company-category/company-category.service';
import {Property} from '../../../../../../model/property';
import {BankService} from '../../../../../../service/bank/bank.service';
import {ProvinceService} from '../../../../../../service/province/province.service';
import {Granter} from '../../../../../../model/granter';
import {Province} from '../../../../../../model/province';
import {Building} from '../../../../../../model/building';
import {CompanyTypeService} from '../../../../../../service/company-type/company-type.service';
import {Grantee} from '../../../../../../model/grantee';
import {DeedNatureService} from '../../../../../../service/deed-nature/deed-nature.service';
import {ValuationRequest} from '../../../../../../model/valuation-request';
import {SubSink} from 'subsink';
import {DomSanitizer} from "@angular/platform-browser";
import {NgxSpinnerService} from "ngx-spinner";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-declaration-fr',
  templateUrl: './declaration-fr.component.html',
  styleUrls: ['./declaration-fr.component.scss']
})
export class DeclarationFrComponent implements OnInit {
  @Input() valuationRequest;
  @Input() valuationRequest2;
  @Input() deedNatureStatus;
  @Input() granteeDocs;
  @Input() grantorDocs;

  marked = false;
  check: boolean;
  grantees: Grantee[];
  granters: Granter[];
  propertyOne: Property;
  assessment: Property;
  extend: Property;
  other: Property;
  loan: Property;
  buildingList: Property;
  provincesorderd: Province[];
  districts = [];
  gramaniladhariDivisions = [];
  propertyUsages = [];
  propertyUsageSubTypes = [];
  companyCategories: CompanyCategory[];
  companyType: CompanyType[];
  localAuthorities = [];
  deedNatures: DeedNature[];
  facilitiesReq1: PropertyFacility[];
  facilitiesReq2: PropertyFacility[];
  isExhangeDeed: boolean;
  province: string;
  granteeCount: number;
  granterCount: number;
  valuationRequestData: ValuationRequest;
  valuationRequestData2: ValuationRequest;
  allBanks: any;
  isdataThere: boolean;
  selectedBuildingIndex: number;
  viewingBuilding: Building;
  buildingDataList: Building [];
  buildingFloors = [];
  facilitiesData = "";
  facilitiesData2 = "";
  selectFileSm = 0;
  counter: number;
  private sub = new SubSink();
  private granteeFiles: any[];
  private grantorFiles: any[];
  previewFileData: any;

  constructor(private deedNatureService: DeedNatureService,
              private provinceService: ProvinceService,
              private companyCategoryService: CompanyCategoryService,
              private companyTypeService: CompanyTypeService,
              private localAuthorityService: LocalAuthorityService,
              private propertyUsageService: PropertyUsageService,
              private propertyUsageSubtypeService: PropertyUsageSubtypeService,
              private bankService: BankService,
              public sanitizer: DomSanitizer,
              private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit() {
    this.valuationRequestData = this.valuationRequest;
    this.valuationRequestData2 = this.valuationRequest2;
    this.grantees = this.valuationRequestData.grantees;
    this.granters = this.valuationRequestData.granters;
    this.other = new Property();
    this.extend = new Property();
    this.loan = new Property();
    this.extend = new Property();
    this.buildingList = new Property();
    this.getGranterGranteeProvince();
    this.propertyOne = (JSON.parse(localStorage.getItem('loan')));
    this.assessment = (JSON.parse(localStorage.getItem('assessment')));

    this.setGrantoeDocsArray();
    this.setGranteeDocsArray();

    if (localStorage.getItem('extend') !== undefined && localStorage.getItem('extend') != null) {
      this.extend = (JSON.parse(localStorage.getItem('extend')));
    }

    if (localStorage.getItem('other') !== undefined && localStorage.getItem('other') != null) {
      this.other = (JSON.parse(localStorage.getItem('other')));
    }

    if (localStorage.getItem('loan') !== undefined && localStorage.getItem('loan') != null) {
      this.loan = (JSON.parse(localStorage.getItem('loan')));
    }

    if (localStorage.getItem('building') !== undefined && localStorage.getItem('building') != null) {
      this.buildingList = (JSON.parse(localStorage.getItem('building')));
    }
    this.checkDeedStatus();
    this.sub.add(this.deedNatureService.getAll()
      .subscribe(
        (res) => {
          this.deedNatures = (res as any).data;
        }
      ));

    this.sub.add(this.provinceService.getProvincesOrderd().subscribe(
      (res) => {
        this.provincesorderd = (res as any).data;
      }
    ));

    // get All Company Categories
    this.sub.add(this.companyCategoryService.getAll().subscribe(
      res => {
        this.companyCategories = (res as any).data;
      }
    ));

    // get All company Type
    this.sub.add(this.companyTypeService.getAll().subscribe(
      res => {
        this.companyType = (res as any).data;
      }
    ));

    this.sub.add(this.localAuthorityService.getAllByOrderByLocalAuthorityAsc()
      .subscribe(
        (res) => {
          this.localAuthorities = (res as any).data;
        }
      ));


    if (this.propertyOne !== null) {

      this.sub.add(this.propertyUsageService.getDistrictByProvince(Number(this.propertyOne.province))
        .subscribe(
          (res) => {
            this.districts = (res as any).data;
          }
        ));

      this.sub.add(this.propertyUsageService.getGramaNiladaryByDistrict(Number(this.propertyOne.district))
        .subscribe(
          (res) => {
            console.log(res);
            this.gramaniladhariDivisions = (res as any).data;
          }
        ));


    }
    this.sub.add(this.propertyUsageService.getAll()
      .subscribe(
        (res) => {
          this.propertyUsages = (res as any).data;
        }
      ));
    this.sub.add(this.propertyUsageSubtypeService.getAll()
      .subscribe(
        (res) => {
          this.propertyUsageSubTypes = (res as any).data;
        }
      ));

    this.bankService.getAll().subscribe(result => {
      if (result) {
        this.allBanks = result.data;
      } else {

      }
    }, error => {
    });
    if (this.valuationRequest) {
      this.isdataThere = true;
    }
    //Facilities array
    this.facilitiesReq1 = this.valuationRequestData.properties[0].propertyFacilities;
    this.counter = 0;

    for (var facilities of this.facilitiesReq1) {
      this.facilitiesData += facilities.name;
      if (this.facilitiesReq1.length - 1 != this.counter) {
        this.facilitiesData += ', ';
      }
      this.counter++;
    }


    this.facilitiesReq2 = this.valuationRequestData2.properties[0].propertyFacilities;
    this.counter = 0;
    for (var facilities of this.facilitiesReq2) {
      this.facilitiesData2 += facilities.name;
      if (this.facilitiesReq2.length - 1 != this.counter) {
        this.facilitiesData2 += ', ';
      }
      this.counter++;
    }
  }

  isAnswerProvided(e, check) {
    if (check) {
      this.check = false;
      this.marked = false;
    } else {
      this.check = true;
      this.marked = true;
    }
  }

  setCheckboxValue() {
    this.check = true;
  }

  checkOrNot() {
    return this.marked;
  }

  makeActive(event: any) {
    console.log('aaa')
    event.target.parentElement.classList.toggle('active');
  }

  // Getting the exhange party 1 & exchane party 2 data
  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChangesvaluationRequest1', this.valuationRequest);
    console.log('ngOnChangesvaluationRequest2', this.valuationRequest2);
  }

  checkDeedStatus() {
    console.log(this.deedNatureStatus)
    if (this.deedNatureStatus === 'Exchange') {
      this.isExhangeDeed = true;
    } else {
      this.isExhangeDeed = false;
    }
  }

  getGranterGranteeProvince() {
    this.granteeCount = this.grantees.length;
    this.granterCount = this.granters.length;
  }

  view(property: Property, index: number) {
    this.buildingDataList = property.buildings;
    this.selectedBuildingIndex = index;
    this.viewingBuilding = this.buildingDataList[this.selectedBuildingIndex];
    this.buildingFloors = this.viewingBuilding.buildingFloors;

  }

  previewFile(type) {
    if (type == "GTEE") {
      this.previewFileData = this.granteeFiles;
      this.spinner.show();

      setTimeout(() => {
        this.spinner.hide();
      }, 2000);
    } else if (type == "GTOR") {
      this.previewFileData = this.grantorFiles;
      this.spinner.show();

      setTimeout(() => {
        this.spinner.hide();
      }, 2000);
    }

  }

  setGranteeDocsArray() {
    let base64images = [];
    for (let i = 0; i < this.granteeDocs.length; i++) {
      const file = this.granteeDocs[i];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event: any) => {
        base64images.push({
          "type": file.type,
          "file": _event.currentTarget.result,
          "filename": file.name
        });
      };

    }
    this.granteeFiles = base64images;
    console.log('granteeFiles', this.granteeFiles)
  }

  setGrantoeDocsArray() {
    let base64images = [];
    for (let i = 0; i < this.grantorDocs.length; i++) {
      const file = this.grantorDocs[i];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event: any) => {
        base64images.push({
          "type": file.type,
          "file": _event.currentTarget.result,
          "filename": file.name
        });
      };

    }
    this.grantorFiles = base64images;
  }

  imagePreview(file) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(file);
  }
}
