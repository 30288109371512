import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { PDFDocumentProxy, PDFProgressData } from 'ng2-pdf-viewer';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import {FileUpload6Component} from '../../../../dashboard-external/assesment/file-upload-other/file-upload6.component';
import {FileUploadComponent} from '../../../../dashboard-external/assesment/file-upload/file-upload.component';
import {FileUpload3Component} from '../../../../dashboard-external/assesment/file-upload-route_plan/file-upload3.component';
import {Property} from '../../../../../../model/property';
import {FileUpload5Component} from '../../../../dashboard-external/assesment/file-upload-nic/file-upload5.component';
import {FileUpload1Component} from '../../../../dashboard-external/assesment/file-upload-draft-deed/file-upload1.component';
import {FileUpload4Component} from '../../../../dashboard-external/assesment/file-upload-assessment_valuation/file-upload4.component';
import {FileUploadAssessmentComponent} from '../../../../dashboard-external/assesment/file-upload-assessment/file-upload-assessment.component';
import {FileUpload2Component} from '../../../../dashboard-external/assesment/file-upload-survey_building_plan/file-upload2.component';
import { FileService } from 'src/app/service/file/file.service';
import { ValuationRequest } from 'src/app/model/valuation-request';

@Component({
  selector: 'app-other-fr',
  templateUrl: './other-fr.component.html',
  styleUrls: ['./other-fr.component.scss']
})
export class OtherFrComponent implements OnInit {

  @ViewChild('fileUploadCopyOfPreviousDeed', {static: false}) fileUploadCopyOfPreviousDeed: FileUploadComponent;
  @ViewChild('fileUploadDraftDeed', {static: false}) fileUploadDraftDeed: FileUpload1Component;
  @ViewChild('fileUploadSurveyPlan', {static: false}) fileUploadSurveyPlan: FileUpload2Component;
  @ViewChild('fileUploadRoutePlan', {static: false}) fileUploadRoutePlan: FileUpload3Component;
  @ViewChild('fileUploadValuationReport', {static: false}) fileUploadValuationReport: FileUpload4Component;
  @ViewChild('fileUploadGranteeNic', {static: false}) fileUploadGranteeNic: FileUpload5Component;
  @ViewChild('fileUploadOther', {static: false}) fileUploadOther: FileUpload6Component;
  @ViewChild('fileUploadAssessmentNotice', {static: false}) fileUploadAssessmentNotice: FileUploadAssessmentComponent;
  @Input() propertyOne: Property;
  selectPaths = [];

  @Input()
  valuationRequest: ValuationRequest;

  valuationRequestId: any;

  dataDone = false;
  noDocs = false;
  previewFileData: any;

  constructor(
    public route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private fileService: FileService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.valuationRequestId = localStorage.getItem('valuation_request_id');
    console.log('this is init other', this.propertyOne);
  }

  onProgress(progressData: PDFProgressData) {
    if (progressData.loaded == progressData.total) {
      this.spinner.hide();
    }
  }

  previewFile(title) {
    console.log('Called');
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadFileById(this.valuationRequestId, title).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(() => {
          this.spinner.hide();
        }, 2000);

      } else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }

  setCopyOfPreviousDeedDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  setDraftDeedDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  setSurveyPlanDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  setRoutePlanDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  setValuationRequestDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  setGranteeDeedDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  setOtherDeedDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  setNoticeDocArray(images: File[]) {
    this.selectPaths.push(images);
  }

  setEveryChildComponentUploadingDocuments() {
    this.fileUploadCopyOfPreviousDeed.outPutImagesSet();
    this.fileUploadDraftDeed.outPutImagesSet();
    this.fileUploadSurveyPlan.outPutImagesSet();
    this.fileUploadRoutePlan.outPutImagesSet();
    this.fileUploadValuationReport.outPutImagesSet();
    this.fileUploadGranteeNic.outPutImagesSet();
    this.fileUploadOther.outPutImagesSet();
    this.fileUploadAssessmentNotice.outPutImagesSet();
  }

}
