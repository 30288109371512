import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Constant } from 'src/app/util/constant';

@Component({
  selector: 'app-rim-side-navigation',
  templateUrl: './rim-side-navigation.component.html',
  styleUrls: ['./rim-side-navigation.component.scss']
})
export class RimSideNavigationComponent implements OnInit {
  type: string;
  selectValRequest: string;
  selectSideNav: any;

  userData: any = [];
  userRoleCode: string;

  showApplicationForm: boolean;
  showDocuments: boolean;
  showAssignTaxOfficer: boolean;

  isNewReimbursement: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router:Router
  ) {
    this.showApplicationForm = false;
    this.showDocuments = false;
    this.showAssignTaxOfficer = false;
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      console.log(params);
      if (params.get('type') === 'new-request') {
        this.type = 'application';
        this.router.navigate(['/reimbursment-application',this.type])
      } else {
        this.type = params.get('type');
        this.router.navigate(['/reimbursment-application',this.type])
      }
      this.selectValRequest = this.type;

    });

    // this.getType(this.type)
    this.obtainUserData();
  }

  obtainUserData() {
    this.userData = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    for (let role of this.userData.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.userRoleCode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_COMMISSIONER;
        this.showApplicationForm =true;
        this.showAssignTaxOfficer = true;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
        this.type = 'documents';
        this.showApplicationForm =true;
        this.showAssignTaxOfficer = true;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.userRoleCode = Constant.ROLE_ACCESSOR;
        this.showApplicationForm =true;
        this.showAssignTaxOfficer = true;

        // If assessor logged in and this is a new reimbursement application, hide "Payable/Nonpayable" and "Payment Details" side nav tiles
        if (JSON.parse(sessionStorage.getItem('newReimbursement'))) {
          this.isNewReimbursement = true;
        }

        break;
      } else if (role.code === Constant.ROLE_TAX_OFFICER) {
        this.userRoleCode = Constant.ROLE_TAX_OFFICER;
        this.type = 'documents';
        this.showApplicationForm =true;
        break;
      } else if (role.code === Constant.ROLE_ACCOUNTANT) {
        this.userRoleCode = Constant.ROLE_ACCOUNTANT;
        this.showApplicationForm =true;
        this.showAssignTaxOfficer = true;
        break;
      }
    }
  }

  getType(type){
    this.router.navigate(['/reimbursment-application/',type])
  }

  public changeComponent(type:any): void {
    this.type = type;
  }

}
