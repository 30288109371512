import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { PropertyUsageSubTypeNewModel } from 'src/app/model/propertyUsageSubTypeNewModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PropertyUsageSubTypeNewService {


  private propertyUsageSubTypeUrl = '/property-usage-sub-type-new';


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  createPropertyUsageSubTypeType(PropertyUsageSubTypeTypeModel:PropertyUsageSubTypeNewModel):Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.propertyUsageSubTypeUrl}`,PropertyUsageSubTypeTypeModel)
  }

  getPropertyUsageSubTypeType(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.propertyUsageSubTypeUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  updatePropertyUsageSubTypeType(id: number, PropertyUsageSubTypeTypeModel:PropertyUsageSubTypeNewModel): Observable<any> {
    return this.http.put(`${environment.api_url}${this.propertyUsageSubTypeUrl}/${id}`, PropertyUsageSubTypeTypeModel);
  }

  searchPropertyUsageSubTypeType(keyWord:string,offset, limit):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.propertyUsageSubTypeUrl +'/search?keyword='+ keyWord + '&offset=' + offset + '&limit=' + limit + '');
  }

  deletePropertyUsageSubTypeType(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.propertyUsageSubTypeUrl}/${id}`);
  }
}
