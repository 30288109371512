import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewPasswordPolicyComponent } from './view-password-policy/view-password-policy.component';

@Component({
  selector: 'app-password-policy',
  templateUrl: './password-policy.component.html',
  styleUrls: ['./password-policy.component.scss']
})
export class PasswordPolicyComponent implements OnInit {
  @ViewChild(ViewPasswordPolicyComponent , { static: false})
  private viewPassPolicy: ViewPasswordPolicyComponent;
  keyword: string;

  constructor() { }

  ngOnInit() {
  }

  triggerSearch(event: any) {
    if (this.keyword === '') {
      this.viewPassPolicy.onUpdated(true);
    } else {
      // this.viewPassPolicy.searchPasswordPolicy(this.keyword);
    }
  }
}
