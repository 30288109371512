import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild
} from "@angular/core";
import { CalculationDataProcess } from "src/app/service/calculationDataProcess/calculation-data-process.service";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { RecreationLandBuildingComponent } from "../../re-creation/landbuilding-recreation/recreation-land-building.component";
import { RecreationLandComponent } from "../../re-creation/land-recreation/recreation-land.component";
import { RecreationCondominiumComponent } from "../../re-creation/condominium/recreation-condominium.component";
declare var $: any;

@Component({
  selector: "exchange-recreation",
  templateUrl: "./exchange-recreation.html",
  styleUrls: ["./exchange-recreation.scss"]
})
export class ExchangeRecreationComponent implements OnInit {
  exchangeReCreationForm: FormGroup;
  step = 0;
  showSaveButton = false;
  @Input()
  showSubPanel: any;

  maxPanelNo = 0;

  componentIdObj = {
    showCondominium: false,
    showLand: false,
    showLandBuilding: false
  };

  @Input()
  mainFormDataModel;

  @Input()
  selectedRecord;

  apiCalculationCall = 0;

  saveData = null;

  finalModel = {
    valueOfTheProperty: 0,
    marketValue: 0,
    stampDuty: 0,
    roundOffValue: 0
  };

  panelNumber = 1;
  dynamicLblValue = "gross";

  readyToSave = false;

  @Input()
  party;

  @ViewChild(RecreationLandBuildingComponent, { static: false })
  recreationLandBuildingComponent: RecreationLandBuildingComponent;

  @ViewChild(RecreationLandComponent, { static: false })
  recreationLandComponent: RecreationLandComponent;

  @ViewChild(RecreationCondominiumComponent, { static: false })
  recreationCondominiumComponent: RecreationCondominiumComponent;

  @Output() sandDataModel: EventEmitter<any> = new EventEmitter<any>();
  @Output() chechStampDutyVaue: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formBuilder: FormBuilder,
    private dataProcess: CalculationDataProcess
  ) {}

  ngOnInit() {
    this.exchangeReCreationForm = this.formBuilder.group({
      marketValue: [null, Validators.required],
      stampDuty: [null, Validators.required],
      roundOffValue: null
    });
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.showSaveButton = this.maxPanelNo + 1 == this.step ? true : false;
    this.step++;

    if (this.maxPanelNo + 1 == this.step) {
      if (this.showSubPanel.showCondominium) {
        this.recreationCondominiumComponent.saveDataModel();
      }
      if (this.showSubPanel.showLand) {
        this.recreationLandComponent.saveDataModel();
      }
      if (this.showSubPanel.showLandBuilding) {
        this.recreationLandBuildingComponent.saveDataModel();
      }
    }
  }

  prevStep() {
    this.showSaveButton = false;
    this.step--;
  }

  getNumberPanel(panel) {
    if (panel == "c") {
      let rNo;
      if (this.showSubPanel.showCondominium) {
        rNo = 1;
      }
      this.setMaxPanelNo(rNo);
      return rNo;
    }

    if (panel == "l") {
      let rNo;
      if (this.showSubPanel.showCondominium && this.showSubPanel.showLand) {
        rNo = 2;
      }

      if (!this.showSubPanel.showCondominium && this.showSubPanel.showLand) {
        rNo = 1;
      }
      this.setMaxPanelNo(rNo);
      return rNo;
    }

    if (panel == "lb") {
      let rNo;
      if (
        this.showSubPanel.showCondominium &&
        this.showSubPanel.showLand &&
        this.showSubPanel.showLandBuilding
      ) {
        rNo = 3;
      }

      if (
        !this.showSubPanel.showCondominium &&
        this.showSubPanel.showLand &&
        this.showSubPanel.showLandBuilding
      ) {
        rNo = 2;
      }

      if (
        this.showSubPanel.showCondominium &&
        !this.showSubPanel.showLand &&
        this.showSubPanel.showLandBuilding
      ) {
        rNo = 2;
      }

      if (
        !this.showSubPanel.showCondominium &&
        !this.showSubPanel.showLand &&
        this.showSubPanel.showLandBuilding
      ) {
        rNo = 1;
      }
      this.setMaxPanelNo(rNo);
      return rNo;
    }

    if (panel == "s") {
      return this.maxPanelNo + 1;
    }
  }

  async saveCalculationData(data) {
    this.apiCalculationCall;
    if (data.type == "condominium") {
      let dataReq = await this.dataProcess.condominiumDataProcessToSave(
        data.data,
        this.mainFormDataModel,
        this.selectedRecord
      );

      if (this.saveData == null) {
        this.saveData = dataReq;
      } else {
        this.saveData.calculationCondominiums = dataReq.calculationCondominiums;
      }
      this.saveData.calculationCondominiums[0].party = data.party;
    }

    if (data.type == "land") {
      let dataReq = await this.dataProcess.landDataProcessToSave(
        data.data,
        this.mainFormDataModel,
        this.selectedRecord
      );

      if (this.saveData == null) {
        this.saveData = dataReq;
      } else {
        this.saveData.calculationLand = dataReq.calculationLand;
      }
      this.saveData.calculationLand[0].party = data.party;
    }

    if (data.type == "land-building") {
      let dataReq = await this.dataProcess.landAndBuildingDataProcessToSave(
        data.data,
        this.mainFormDataModel,
        this.selectedRecord
      );

      if (this.saveData == null) {
        this.saveData = dataReq;
      } else {
        this.saveData.calculationLandBuilding = dataReq.calculationLandBuilding;
      }
      this.saveData.calculationLandBuilding[0].party = data.party;
    }
    // console.log("----yyy----");
    // console.log(this.saveData);

    // console.log(data);
    // console.log("----yyy----");

    this.chechStampDutyVaue.next(this.saveData);
  }

  setMaxPanelNo(number) {
    this.maxPanelNo = this.maxPanelNo < number ? number : this.maxPanelNo;
  }

  sendRecreationDataToParentView() {
    // if (this.showSubPanel.showCondominium) {
    //   this.recreationCondominiumComponent.saveDataModel();
    // }
    // if (this.showSubPanel.showLand) {
    //   this.recreationLandComponent.saveDataModel();
    // }
    // if (this.showSubPanel.showLandBuilding) {
    //   this.recreationLandBuildingComponent.saveDataModel();
    // }

    // this.saveData.roundOffValue = this.finalModel.roundOffValue;
    // this.saveData.stampDuty = this.finalModel.stampDuty;

    this.sandDataModel.emit(this.saveData);
  }
}
