import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { InspectionTelecomunicationTypeService } from 'src/app/service/inspection-master-data-service/inspection-telecomunication-type/inspection-telecomunication-type.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inspection-telecommunication-type',
  templateUrl: './inspection-telecommunication-type.component.html',
  styleUrls: ['./inspection-telecommunication-type.component.scss']
})
export class InspectionTelecomunicationTypeComponent implements OnInit ,OnDestroy {


  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");

  createTelecomunicationTypeForm: FormGroup;
  updateTelecomunicationTypeForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  propertyTypeList: any =[];
  activationButton: boolean;
  public createTelecomunicationTypeModel = new InspectionCommonDataModel(null,'','','','');
  public updateTelecomunicationTypeModel = new InspectionCommonDataModel(null,'','','','');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:any;
  selectedId:number;
  TelecomunicationTypes: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionTelecomunicationTypeService: InspectionTelecomunicationTypeService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createTelecomunicationTypeForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });
    this.updateTelecomunicationTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });

    // this.loadPropertyType();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }



  //create Telecomunication Type - start

  get f() { return this.createTelecomunicationTypeForm.controls; }

  setTelecomunicationTypeModelToSave(){
    this.createTelecomunicationTypeModel.name = this.createTelecomunicationTypeForm.get('name').value;
    this.createTelecomunicationTypeModel.description = this.createTelecomunicationTypeForm.get('description').value;
    this.createTelecomunicationTypeModel.code = this.createTelecomunicationTypeForm.get('code').value;
    this.createTelecomunicationTypeModel.dataStatus = "ACTIVE";
  }

    //save Telecomunication Type model
  saveTelecomunicationType(){
    if(this.createTelecomunicationTypeForm.valid){
      this.setTelecomunicationTypeModelToSave();
      this.sub.add(
        this.inspectionTelecomunicationTypeService.createTelecomunicationType(this.createTelecomunicationTypeModel).subscribe(data=>{
          this.notifier.notify('success', (data as any).message);
          this.updateTheTable();
          this.reset();
        })
        );
    }else{
      this.createTelecomunicationTypeForm.markAllAsTouched();
    }

  }

  reset(){
    this.createTelecomunicationTypeForm.reset()
  }
  //create Telecomunication Type - end

  //update Telecomunication Type - start

    //get update controllers
  get f1() { return this.updateTelecomunicationTypeForm.controls; }

    //set data To Telecomunication Type Type model
  setTelecomunicationType(health:any){
    this.updateTelecomunicationTypeForm.get('id').setValue(health.id);
    this.updateTelecomunicationTypeForm.get('name').setValue(health.name);
    this.updateTelecomunicationTypeForm.get('description').setValue(health.description);
    this.updateTelecomunicationTypeForm.get('code').setValue(health.code);

    if(health.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateTelecomunicationTypeModel.id = this.updateTelecomunicationTypeForm.get('id').value;
    this.updateTelecomunicationTypeModel.name = this.updateTelecomunicationTypeForm.get('name').value;
    this.updateTelecomunicationTypeModel.description = this.updateTelecomunicationTypeForm.get('description').value;
    this.updateTelecomunicationTypeModel.code = this.updateTelecomunicationTypeForm.get('code').value;

    if(this.activationButton == true){
      this.updateTelecomunicationTypeModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateTelecomunicationTypeModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateTelecomunicationType(){
    this.setDataToModel();
    this.sub.add(this.inspectionTelecomunicationTypeService.updateTelecomunicationType(this.updateTelecomunicationTypeModel.id, this.updateTelecomunicationTypeModel).subscribe(data=>{
      this.notifier.notify('success', (data as any).message);
      this.updateTheTable();
    }))
  }

  //update Telecomunication Type - end

//search and view table start
  pageable(i: number) {
    this.inspectionTelecomunicationTypeService.getTelecomunicationType((i-1),10).subscribe(res=>{
      this.TelecomunicationTypes = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionTelecomunicationTypeService.getTelecomunicationType(0, 10)
      .subscribe(
        (res) => {
          this.TelecomunicationTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search Telecomunication Type Type
    searchTelecomunicationType(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else if(!isNaN(this.keyword)){
        this.sub.add(this.inspectionTelecomunicationTypeService.searchTelecomunicationTypeById(this.keyword).subscribe(res=>{
          this.TelecomunicationTypes = [];
          this.TelecomunicationTypes[0]=  (res as any).data;
          this.currentPage = 1;
          this.totalItems = 1;
        }))
      }else{
        this.sub.add(this.inspectionTelecomunicationTypeService.searchTelecomunicationType(this.keyword,0,10).subscribe(res=>{
          this.TelecomunicationTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionTelecomunicationTypeService.deleteTelecomunicationType(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end
}
