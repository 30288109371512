import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DistrictService } from 'src/app/service/district/district.service';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-delete-district',
  templateUrl: './delete-district.component.html',
  styleUrls: ['./delete-district.component.scss']
})
export class DeleteDistrictComponent implements OnInit, OnDestroy {

  private districtId: number;
  private sub = new SubSink();

  @Output()districtDeleted = new EventEmitter<boolean>();


  constructor( private districtService: DistrictService, private notifier: NotifierService) { }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  deleteDistrict() {
    this.sub.add(this.districtService.deleteDistrict(this.districtId)
    .subscribe(response => {
        this.updateTheView(true);
        this.notifier.notify( 'success', (response as any).message);
      }));
  }

  updateTheView(deleted: boolean) {
    this.districtDeleted.emit(deleted);
  }

  setDistrictId(id: number) {
    this.districtId = id;
  }


}
