import {Component, OnInit, ViewChild} from '@angular/core';
import {ViewGramaNiladariDivisionComponent} from './view-grama-niladari-division/view-grama-niladari-division.component';

@Component({
  selector: 'app-grama-niladari-division',
  templateUrl: './grama-niladari-division.component.html',
  styleUrls: ['./grama-niladari-division.component.scss']
})
export class GramaNiladariDivisionComponent implements OnInit {
  @ViewChild(ViewGramaNiladariDivisionComponent , { static: false})
  private viewGrndComponent: ViewGramaNiladariDivisionComponent;
  keyword: string;

  constructor() { }

  ngOnInit() {
  }

  triggerSearch(event: any) {
    if (this.keyword === '') {
      this.viewGrndComponent.onUpdated(true);
    } else {
      this.viewGrndComponent.searchGramaNiladhai(this.keyword);
    }
  }

}
