import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import Swal from 'sweetalert2';
import Cropper from 'cropperjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-document-upload-four',
  templateUrl: './document-upload-four.component.html',
  styleUrls: ['./document-upload-four.component.scss']
})
export class DocumentUploadFourComponent implements OnInit {

  @Output()
  outputDocumentsFour = new EventEmitter<File[]>();
  filesList4: File[] = [];
  documents = [];
  originalDocuments: any[] = [];
  names: string[] = [];
  files;
  public progress = 0;
  cropper;
  imgid = uuidv4();
  showEdit = false;
  showEditPdf = false;
  pdfsrc: null;
  selectedIndex = 0;

  constructor() { }

  ngOnInit() {
  }

  uploadChange(event) {
    this.files = event.target.files;

    this.progress = 0;
    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      if (file.type === "application/pdf" || file.type === "image/png" || file.type === "image/jpeg") {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          this.progress = 100;
          this.documents.push(_event.currentTarget.result);
          this.originalDocuments.push(_event.currentTarget.result);
          this.filesList4.push(file);
        };
        this.names.push(file.name);
        this.progress = 0;
      } else {
        Swal.fire({
          text: 'File type not allowed here!',
          icon: 'warning',
          showCancelButton: false,
        });
        event.srcElement.value = null;
      }
    }
  }

  outputDocumentsFourSet() {
    this.outputDocumentsFour.emit(this.filesList4);
  }

  changeImg(event) {
    if (this.cropper !== undefined) {
      this.cropper.destroy();
    }
    const image = document.getElementById(this.imgid) as HTMLImageElement;
    image.src = event.target.src;
    this.cropper = new Cropper(image, {
      crop(event) {
      },
    });
  }

  edit(event, index) {
    if (this.filesList4[index].type==="application/pdf"){
      this.showEdit = false;
      this.showEditPdf = true;

      this.pdfsrc=null;
      this.pdfsrc=this.documents[index];
    }else {
      this.showEdit = true;
      this.showEditPdf = false;
      console.log(event.target.parentElement.parentElement.children);
      const image = document.getElementById(this.imgid) as HTMLImageElement;
      image.src = event.target.parentElement.parentElement.children[0].src;
      if (this.cropper !== undefined) {
        this.cropper.destroy();
      }
      this.cropper = new Cropper(image, {
        rotatable: true,
        crop(event) {

        },
      });
    }
    this.selectedIndex = index;
  }

  delete(index) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.documents.splice(index, 1);
        this.originalDocuments.splice(index, 1);
        this.names.splice(index, 1);
        this.filesList4.splice(index, 1);
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );
      }
    });
  }

  closeEditor() {
    this.showEdit = false;
    this.showEditPdf = false;
  }

  rotate(angle: number) {
    this.cropper.move(1, -1).rotate(angle);
  }

  resetImage() {
    this.cropper.destroy();
    this.documents[this.selectedIndex] = this.originalDocuments[this.selectedIndex];
    const image = document.getElementById(this.imgid) as HTMLImageElement;
    image.src = this.documents[this.selectedIndex];
    this.cropper = new Cropper(image, {
      rotatable: true,
      crop(event) {
      },
    });
  }

  crop() {
    this.documents[this.selectedIndex] = this.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    this.cropper.destroy();

    this.documents[this.selectedIndex] = this.documents[this.selectedIndex];
    const image = document.getElementById(this.imgid) as HTMLImageElement;
    image.src = this.documents[this.selectedIndex];
    this.cropper = new Cropper(image, {
      rotatable: true,
      crop(event) {
      },
    });
  }

}
