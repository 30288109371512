import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InspectionGardenTypeService {

  private inspectionGardenTypeUrl = '/inspection-garden-type';


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  createGardenType(GardenTypeModel:InspectionCommonDataModel):Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionGardenTypeUrl}`,GardenTypeModel)
  }

  getGardenType(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.inspectionGardenTypeUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  updateGardenType(id: number, GardenTypeModel:InspectionCommonDataModel): Observable<any> {
    return this.http.put(`${environment.api_url}${this.inspectionGardenTypeUrl}/${id}`, GardenTypeModel);
  }

  searchGardenType(keyWord:string,offset, limit):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.inspectionGardenTypeUrl +'/search?keyword='+ keyWord + '&offset=' + offset + '&limit=' + limit + '');
  }

  deleteGardenType(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.inspectionGardenTypeUrl}/${id}`);
  }
}
