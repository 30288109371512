import { Component, Input, OnInit, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PDFProgressData } from 'ng2-pdf-viewer';

import { IGrantee } from '../../../../../../../model/interfaces/grantee';
import { FileService } from 'src/app/service/file/file.service';
import { GranteeService } from 'src/app/service/grantee/grantee.service';
import { ValuationStatus } from 'src/app/model/valuation-status';
import { ValuationReject } from 'src/app/model/valuation-reject';
import { ValuationRequestService } from 'src/app/service/valuation-request/valuation-request.service';
import { Constant } from 'src/app/util/constant';
import { ProvinceService } from 'src/app/service/province/province.service';
import { ValuationRequestRemark } from 'src/app/model/valuation-request-remark';
import { CompanyTypeService } from 'src/app/service/company-type/company-type.service';
import { NotificationModel } from 'src/app/model/notification-model';
import { NotificationService } from 'src/app/service/notification/notification.service';
import { NotificationEmailSend } from 'src/app/model/notification-email-send';
import { ValuationRequestUpdate } from 'src/app/model/valuation-request-update';
import { GranterService } from 'src/app/service/granter/granter.service';
import { SubSink } from 'subsink';
import { Subscription } from 'rxjs';
import { NotificationDetails } from 'src/app/model/notificationDetails';


@Component({
  selector: 'app-view-grantee-iu',
  templateUrl: './view-grantee-iu.component.html',
  styleUrls: ['./view-grantee-iu.component.scss']
})

export class ViewGranteeInternalUserComponent implements OnInit {
  private sub = new SubSink();
  getGranterStatusChange:Subscription;

  @ViewChild('furtherReqNotification', null) private enterRemark: TemplateRef<object>;
  @ViewChild('rejectApplication', null) private enterRejectRemark: TemplateRef<object>;

  @Input()
  grantees: IGrantee[];
  @Input()
  valuationRequestId: number;
  @Input()
  party:string;
  @Input()
  granterId:string;
  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() status1: EventEmitter<any> = new EventEmitter<any>();

  granteeId: string;
  rejectForm: FormGroup;
  furtherRequirementForm: FormGroup;
  verificationStatus: number;

  initialviewaccess: boolean;
  thirdviewaccess: boolean;

  party1:boolean;
  party2:boolean;
  exchangeDisable:boolean;

  furtherRequirementRemarkContent: string;
  applicationRejectRemarkContent: string;
  deedNatureValue: any;
  currentUserRoleCode: string;
  selectFileSm = 0;
  typeID: any;
  previewFileData: any;
  isExchangeDeedType:boolean;
  type: any;
  files;
  public imgURL: any;
  public imgURL1: any;
  public documentUploaded: string;
  public documentUploaded1: string;
  images = [];
  originalImages: any[] = [];
  filesList: File[] = [];
  names: string[] = [];

  noDocs = false;
  dataDone = false;

  public fileNumberFormat: string;
  public showRemarkValidation: boolean;
  public showDynamicGrid: boolean;

  public modelStatus = new ValuationStatus('', '');
  public notificationDetails = new NotificationDetails('','');


  public rejectModel = new ValuationReject('','','','','');
  public notificationModel = new NotificationModel("", "", "", "", "", "", "");
;
  public notificationEmailSendModel = new NotificationEmailSend();
  public valuationRequestUpdateModel = new ValuationRequestUpdate('', '', '', '', '', '');

  userdata: any = [];

  accessDenied: boolean;

  public generatedFileNumber: string;
  valuationdata: any = [];

  public provincelist: any = [];
  public companytypelist: any = [];

  public provinceDescription: string;
  public companyTypeDescription: string;

  valuationRequestRemarks: ValuationRequestRemark[] = [];
  public valuationRequestRemark = new ValuationRequestRemark();
  granterStatus: boolean;
  status: number;

  fileNumberFormatAvailable: boolean = true;

  permissionLetterContent = '';

  constructor(
    private fileService: FileService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public granteeService: GranteeService,
    public granterService: GranterService,
    public router: Router,
    public valuationRequestService: ValuationRequestService,
    public provinceService: ProvinceService,
    private notifier: NotifierService,
    private companyTypeService: CompanyTypeService,
    public modalService: NgbModal,
    public notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService
  ) {
    this.initialviewaccess = true;
    this.thirdviewaccess = false;
    this.accessDenied = false;
    this.generatedFileNumber = '';
    this.showRemarkValidation = false;
    // this.exchangeDisable = true;
    this.granterStatus = false;
    this.getGranterStatusChange = this.granterService.getGranterStatusChange().subscribe(()=>{
      this.ngOnInit();
    })
  }

  ngOnInit() {
    this._prepare();
    this.showDynamicGrid = false;
    window.scroll(0,0);
    this.isExchangeDeedType = sessionStorage.getItem('deedType')==='exchange';
    this.party1 = this.party === "party1"
    this.party2 = this.party === "party2"

    this.getGranterStatus();
    this.updateNotification()
  }

  // get f() {
  //   return this.rejectForm.controls;
  // }

  // get f1() {
  //   return this.furtherRequirementForm.controls;
  // }
  public checkStatus(type:boolean): void{
    this.status1.emit(type);
  }

  openPopupMessage(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });

    this.rejectForm = this.formBuilder.group({
      remarkTextarea: ['', [Validators.required]]
    });

    this.furtherRequirementForm = this.formBuilder.group({
      furtherRequirementTextarea: ['', [Validators.required]]
    });
  }

  updateNotification(){
    this.notificationDetails.subject = Constant.SEND_FURTHER_REQUIREMENT;
    this.notificationDetails.valuationRequestId = this.route.snapshot.paramMap.get('id');
    this.notificationService.updateReleventNotification(this.notificationDetails).subscribe();
  }

  loadProvinceList(){
    this.provinceService.getAll().subscribe(
      data => {
        this.provincelist = data.data;
      }
    )
  }

  loadCompanyTypeList() {
    this.companyTypeService.getAll().subscribe(
      data => {
        this.companytypelist = data.data;
      }
    )
  }

  changeSelectedProvince(province): any {
    const filtereddata = this.provincelist.filter(i => i.id === Number(province));
    if (JSON.stringify(filtereddata) !== '[]') {
      this.provinceDescription = filtereddata[0]['description'];
      return this.provinceDescription;
    }
  }

  changeSelectedCompanyType(company): any {
    const filtereddata = this.companytypelist.filter(i => i.id === Number(company));
    if (JSON.stringify(filtereddata) !== '[]') {
      this.companyTypeDescription = filtereddata[0]['name'];
      return this.companyTypeDescription;
    }
  }

  _prepare() {
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));

    this.fileNumberFormat = localStorage.getItem('fileNumberFormat');
    if (this.fileNumberFormat == "null" || this.fileNumberFormat == '') {
      this.fileNumberFormatAvailable = false;
    }

    setTimeout (() => {
      this.granteeId = this.grantees[0]['id'];
      this.getVerificationStatus();
   }, 1000);
    // this.granteeId = this.grantees[0]['id'];
    // this.getVerificationStatus();

    this.typeID = +this.route.snapshot.paramMap.get('id');
    this.checkPermission();
    this.valuationdata = JSON.parse(sessionStorage.getItem('valuationRequest'));
    this.pad(JSON.parse(sessionStorage.getItem('valuationRequest')).id, 7);
    this.loadProvinceList();
    this.loadCompanyTypeList();
    this.retrieveAssignedValuationRequest();


    if(sessionStorage.getItem('deedType')==='exchange'){
      this.typeID = +this.valuationRequestId;
      // this.exchangeDisable = this.getExchangeDisableStatus();
      // console.log(this.exchangeDisable)
    }else{
      this.typeID = +this.route.snapshot.paramMap.get('id');
    }
  }

  pad(num: number, size: number) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    this.generatedFileNumber = s;
    this.generatedFileNumber = '#VR' + this.generatedFileNumber;
  }

  checkPermission() {
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.currentUserRoleCode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.accessDenied = true;
        this.currentUserRoleCode = Constant.ROLE_ACCESSOR;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER){
        this.currentUserRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.currentUserRoleCode = Constant.ROLE_COMMISSIONER;
        break;
      }
    }
  }

  retrieveAssignedValuationRequest() {
    this.valuationRequestService.valuationRequestAssigned(this.route.snapshot.paramMap.get('id')).subscribe(
      data => {
        if (data.data !== null) {
          this.valuationRequestUpdateModel.dcommissionerId = data.data.dcommissionerId;
          this.valuationRequestUpdateModel.acccessorId = data.data.accessorId;
          this.valuationRequestUpdateModel.remark = data.data.remark;
        }
      }
    )
  }

  public redirectComponent(): void {
    this.onPageCountChanged.emit(3);
  }

  public redirectComponentExchange(): void {
    this.onPageCountChanged.emit(4);
  }

  public redirectPreviousComponent(): void {
    this.onPageCountChanged.emit(1);
  }

  // getGranterStatus():number{
  //   let status
  //   this.modelStatusExchange.id = this.granterId;
  //   this.granterService.granterVerificationStatus(this.modelStatusExchange).subscribe(
  //     data => {
  //       status = data.data;
  //     }
  //   )
  //   return status;
  // }

  getGranterStatus(){
    this.modelStatus.id = this.granterId;
    this.sub.add(this.granterService.granterVerificationStatus(this.modelStatus).subscribe(
      data => {
        this.status = data.data;
      }
    ))
    // if(this.status == 1){
    //   return true;
    // }else{
    //   return false;
    // }
  }

  // getExchangeGranterStatus():any{
  //   this.modelStatus.id = this.granterId;
  //   this.granterService.granterVerificationStatus(this.modelStatus).subscribe(
  //     data => {
  //       return data.data;
  //     }
  //   )
  // }

  // getExchangeDisableStatus():boolean{
  //   var ex1 = this.getExchangeGranterStatus();
  //   var ex2 = this.getGranteeStatus();
  //   if(ex1 == 1 && ex2 == 1){
  //     return false;
  //   }else{
  //     return true;
  //   }
  // }

  getVerificationStatus() {
    this.modelStatus.id = this.granteeId;
    this.granteeService.granteeVerificationStatus(this.modelStatus).subscribe(
      data => {
        this.verificationStatus = data.data;
      }
    )
  }

  getEditorContent(event) {
    console.log(">>>>>>>",event);
  }

//   openRejectPopUp() {
//     this.showDynamicGrid = true;
//     this.openPopupMessage(this.enterRejectRemark);
//   }

//   rejectValuationRequest() {
//     this.showRemarkValidation = false;
//     this.rejectModel.valuationId = this.route.snapshot.paramMap.get('id');
//     this.valuationRequestService.valuationRejectRequest(this.rejectModel).subscribe(
//       data => {
//         this.router.navigate(['dashboard-internal-user']);
//         this.notifier.notify('success', 'Application Rejected');
//       }
//     );

//     if (this.filesList.length > 0){
//       this.valuationRequestService.uploadRejectApplicationDocuments(this.filesList, this.valuationRequestId.toString()).subscribe(
//         data => {}
//       );
//     }

//     // **** SAVE REMARK - START ****
//     // //Saving remark (Uncomment below code if you want to save the remark)
//     // this.valuationRequestRemark.valuationRequestId = this.valuationRequestId;
//     // this.valuationRequestRemark.remark = this.applicationRejectRemarkContent;
//     // this.valuationRequestService.createValuationRequestRemark(this.valuationRequestRemark).subscribe(
//     //   data => {
//     //     //this.notifier.notify('success', 'Remark Successfully Saved');
//     //     console.log(data);
//     //   }
//     // )
//     // **** SAVE REMARK - END ****

//   // }

//   // **** SEND NOTIFICATIONS - START ****
//   this.valuationRequestService.findByValuationRequestId(+this.route.snapshot.paramMap.get('id')).subscribe(
//     data => {
//       this.deedNatureValue = data.data.deedNature;

//       if (this.currentUserRoleCode == Constant.ROLE_ACCESSOR) {
//         this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
//         this.notificationModel.deedNature = this.deedNatureValue;
//         this.notificationModel.url = this.route.snapshot.paramMap.get('id');
//         this.notificationModel.subject = Constant.APPLICATION_REJECTED;

//         this.notificationService.createNotification(this.notificationModel).subscribe(
//           data => {

//             //// *** SEND NOTIFICATION TO DEP. COMMISSIONER - START ***
//             // Uncomment to send notifications to deputy commissioner
//             // this.notificationModel.userRoleCode = Constant.ROLE_ACCESSOR;
//             // this.notificationModel.deedNature = this.deedNatureValue;
//             // this.notificationModel.url = this.route.snapshot.paramMap.get('id');
//             // this.notificationModel.dataStatus = "ACTIVE";

//             // this.valuationRequestService.createNotification(this.notificationModel).subscribe(
//             //   data => {
//             //     console.log(data);
//             //   }
//             // )
//             //// *** SEND NOTIFICATION TO DEP. COMMISSIONER - END ***

//           }
//         )
//       } else if (this.currentUserRoleCode == Constant.ROLE_DEPUTY_COMMISSIONER) {
//         this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
//         this.notificationModel.deedNature = this.deedNatureValue;
//         this.notificationModel.url = this.route.snapshot.paramMap.get('id');
//         this.notificationModel.subject = Constant.APPLICATION_REJECTED;

//         this.notificationService.createNotification(this.notificationModel).subscribe(
//           data => {}
//         )
//       } else if (this.currentUserRoleCode == Constant.ROLE_COMMISSIONER) {
//         this.notificationModel.userRoleCode = Constant.ROLE_NOTARY;
//         this.notificationModel.deedNature = this.deedNatureValue;
//         this.notificationModel.url = this.route.snapshot.paramMap.get('id');
//         this.notificationModel.subject = Constant.APPLICATION_REJECTED;

//         this.notificationService.createNotification(this.notificationModel).subscribe(
//           data => {
//             this.notificationEmailSendModel.valuationRequestId = +this.route.snapshot.paramMap.get('id');
//             this.notificationService.sendEmailNotification(this.notificationEmailSendModel).subscribe(
//               data => {}
//             )
//           }
//         )
//       }
//     }
//   )
//   // **** SEND NOTIFICATIONS - END ****
//   this.modalService.dismissAll();
// }

//   getRejectRemark() {
//     return this.rejectForm.get('remarkTextarea').value;
//   }

  updateVerificationStatus(event) {
    this.modelStatus.id = this.granteeId;
    if (event.target.checked === true) {
      this.modelStatus.verificationStatus = '1';
      this.granteeService.granteeUpdateVerificationStatus(this.modelStatus).subscribe(
        data => {
          this.getVerificationStatus();
          // this.granterStatus = this.getGranterStatus();
          if(this.isExchangeDeedType){
            this.getGranterStatus();
            this.sendGranteeStatusChange();
          }
          // console.log('condition: ', this.granterStatus && this.verificationStatus === 0)
          // console.log('verification: ', this.verificationStatus === 0)
          // console.log('granter Status:',this.granterStatus)
          // if(this.isExchangeDeedType){
          //   this.exchangeDisable = this.getExchangeDisableStatus();
          //   console.log(this.exchangeDisable)
          // }
          // this._prepare();
        }
      )
    } else {
      this.modelStatus.verificationStatus = '0';
      this.granteeService.granteeUpdateVerificationStatus(this.modelStatus).subscribe(
        data => {
          this.getVerificationStatus();
          // this.granterStatus = this.getGranterStatus();
          if(this.isExchangeDeedType){
            this.getGranterStatus();
            this.sendGranteeStatusChange();
          }
          // console.log('condition:',this.granterStatus && this.verificationStatus === 0)
          // console.log('granter Status:',this.granterStatus)
          // console.log('verification: ', this.verificationStatus === 0)
          // if(this.isExchangeDeedType){
          //   this.exchangeDisable = this.getExchangeDisableStatus();
          //   console.log(this.exchangeDisable)
          // }
          // this._prepare();
        }
      )
    }
    this.checkStatus(true);
  }

  sendGranteeStatusChange(){
    this.granteeService.sendGranteeStatusChange();
  }

  initialPageCompleted() {
    this.initialviewaccess = false;
    this.thirdviewaccess = true;
  }

  secondPageCompleted() {
    this.initialviewaccess = false;
    this.thirdviewaccess = true;
  }

  thirdPageCompleted() {
    this.initialviewaccess = false;
    this.thirdviewaccess = true;
  }

  routeBack() {
    if (this.thirdviewaccess) {
      this.initialviewaccess = true;
      this.thirdviewaccess = false;
    } else {
      this.initialviewaccess = true;
      this.thirdviewaccess = false;
    }
  }

  routeBackCompanyEmpty() {
    this.initialviewaccess = true;
    this.thirdviewaccess = false;
  }

  openPermissionLetter() {

  }

  onProgress(progressData: PDFProgressData) {
    if (progressData.loaded == progressData.total) {
      this.spinner.hide();
    }
  }

  previewFile(type) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadFileById(this.typeID, type).subscribe(result => {
      if (result) {
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(()=>{
          this.spinner.hide();
        }, 2000);

      }else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }

  // furtherReqValuationRequest() {
  //     this.showDynamicGrid = true;
  //     this.openPopupMessage(this.enterRemark);
  // }

  // requestFurtherReqRemark() {
  //   this.valuationRequestUpdateModel.valuationId = this.valuationRequestId.toString();
  //   this.valuationRequestUpdateModel.remark = this.furtherRequirementRemarkContent;
  //   this.valuationRequestUpdateModel.userRole = this.currentUserRoleCode;
  //   this.valuationRequestUpdateModel.referenceNumber = this.fileNumberFormat;

  //   this.valuationRequestService.valuationRequestFurtherRequirement(this.valuationRequestUpdateModel).subscribe(
  //     data => {
  //       console.log(data);
  //       if (data.data === true) {
  //       this.notifier.notify('success', 'Successfully sent for Further Requirement');
  //         this.router.navigate(['/dashboard-internal-user']);
  //       }
  //     }
  //   )

  //   if(this.filesList.length > 0){
  //     this.valuationRequestService.uploadFurtherRequirementDocuments(this.filesList, this.valuationRequestId.toString()).subscribe(
  //       data => {
  //         console.log(data);
  //       }
  //     );
  //   }

  //   //Saving remark
  //   this.valuationRequestRemark.valuationRequestId = this.valuationRequestId;
  //   this.valuationRequestRemark.remark = this.furtherRequirementRemarkContent;
  //   this.valuationRequestService.createValuationRequestRemark(this.valuationRequestRemark).subscribe(
  //     data => {
  //       console.log(data);
  //       // this.valuationRequestRemarks.push(this.valuationRequestRemark);
  //       // this.thirdviewaccess = false;

  //       // **** SEND NOTIFICATIONS - START ****
  //       this.valuationRequestService.findByValuationRequestId(+this.route.snapshot.paramMap.get('id')).subscribe(
  //         data => {
  //           this.deedNatureValue = data.data.deedNature;

  //           // ** SEND SYSTEM NOTIFICATION TO NOTARY - START **
  //           this.notificationModel.userRoleCode = Constant.ROLE_NOTARY;
  //           this.notificationModel.deedNature = this.deedNatureValue;
  //           this.notificationModel.url = this.route.snapshot.paramMap.get('id');
  //           this.notificationModel.subject = Constant.REQUESTED_FOR_FURTHER_REQUIREMENT;

  //           this.notificationService.createNotification(this.notificationModel).subscribe(
  //             data => {
  //               console.log(data);
  //             }
  //           );
  //           // ** SEND SYSTEM NOTIFICATION TO NOTARY - END **

  //           if (this.currentUserRoleCode == Constant.ROLE_ACCESSOR) {
  //             this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
  //             this.notificationModel.deedNature = this.deedNatureValue;
  //             this.notificationModel.url = this.route.snapshot.paramMap.get('id');
  //             this.notificationModel.subject = Constant.REQUESTED_FOR_FURTHER_REQUIREMENT;

  //             this.notificationService.createNotification(this.notificationModel).subscribe(
  //               data => {
  //                 console.log(data);

  //                 // *** SEND SYSTEM NOTIFICATION TO DEP. COMMISSIONER - START ***
  //                 this.notificationModel.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
  //                 this.notificationModel.deedNature = this.deedNatureValue;
  //                 this.notificationModel.url = this.route.snapshot.paramMap.get('id');
  //                 this.notificationModel.subject = Constant.REQUESTED_FOR_FURTHER_REQUIREMENT;

  //                 this.notificationService.createNotification(this.notificationModel).subscribe(
  //                   data => {
  //                     console.log(data);
  //                   }
  //                 )
  //                 // *** SEND SYSTEM NOTIFICATION TO DEP. COMMISSIONER - END ***

  //               }
  //             )
  //           } else if (this.currentUserRoleCode == Constant.ROLE_DEPUTY_COMMISSIONER) {
  //             this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
  //             this.notificationModel.deedNature = this.deedNatureValue;
  //             this.notificationModel.url = this.route.snapshot.paramMap.get('id');
  //             this.notificationModel.subject = Constant.REQUESTED_FOR_FURTHER_REQUIREMENT;

  //             this.notificationService.createNotification(this.notificationModel).subscribe(
  //               data => {
  //                 console.log(data);
  //               }
  //             )
  //           }
  //         }
  //       );
  //       // **** SEND NOTIFICATIONS - END ****
  //     }
  //   )
  //   this.modalService.dismissAll();
  // }

  // closeFurtherReqRemark() {
  //   this.modalService.dismissAll();
  // }

  // uploadChangeFurtherRequirement(event) {
  //   this.files = event.target.files;
  //   for (let i = 0; i < this.files.length; i++) {
  //     const file = this.files[i];
  //     if (file.type === "image/png" || file.type === "image/jpeg") {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = (_event: any) => {
  //         this.imgURL = reader.result;
  //         this.documentUploaded = 'image';
  //         this.images.push(_event.currentTarget.result);
  //         this.originalImages.push(_event.currentTarget.result);
  //         this.filesList.push(file);
  //       };
  //       this.names.push(file.name);
  //     } else if (file.type == "application/pdf" ) {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = (_event: any) => {
  //         this.imgURL = "assets/images/adobe-pdf-icon.png";
  //         this.images.push("assets/images/adobe-pdf-icon.png");
  //         this.documentUploaded = 'pdf';
  //         this.filesList.push(file);
  //       }
  //     } else {
  //       this.notifier.notify('error', 'File type not allowed');
  //       event.srcElement.value = null;
  //     }
  //   }
  // }

  // uploadChangeReject(event) {
  //   this.files = event.target.files;
  //   for (let i = 0; i < this.files.length; i++) {
  //     const file = this.files[i];
  //     if (file.type === "image/png" || file.type === "image/jpeg") {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = (_event: any) => {
  //         this.imgURL1 = reader.result;
  //         this.documentUploaded1 = 'image';
  //         this.images.push(_event.currentTarget.result);
  //         this.originalImages.push(_event.currentTarget.result);
  //         this.filesList.push(file);
  //       };
  //       this.names.push(file.name);
  //     } else if (file.type == "application/pdf" ) {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = (_event: any) => {
  //         this.imgURL1 = "assets/images/adobe-pdf-icon.png";
  //         this.documentUploaded1 = 'pdf';
  //         this.filesList.push(file);
  //       }
  //     } else {
  //       this.notifier.notify('error', 'File type not allowed here');
  //       event.srcElement.value = null;
  //     }
  //   }
  // }

  savePermissionLetter() {
    // TODO: Use this for saving the permission letter
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
