import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ValuationStatus } from 'src/app/model/valuation-status';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotaryService {
  private userRequestUrl = '/getnotaryregistered_details'; // URL to user api
  constructor(
    private http: HttpClient
  ) { }

  notaryVerificationStatus(ValuationStatus: ValuationStatus): Observable<any> {
    return this.http.post<any>(`${environment.user_api_url}${this.userRequestUrl}` + '/status', ValuationStatus);
  }

  notaryUpdateVerificationStatus(ValuationStatus: ValuationStatus): Observable<any> {
    return this.http.post<any>(`${environment.user_api_url}${this.userRequestUrl}` + '/updatestatus', ValuationStatus);
  }

}
