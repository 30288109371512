export class DeedDetail {
  valuationRequestId: string;
  notaryName: string;
  deedNo: string;
  attestedDate: string;
  stampDutyAmount: string;
  opinionDate: string;
  paymentDate: string;

  constructor(
    valuationRequestId: string,
    notaryName: string,
    deedNo: string,
    attestedDate: string,
    stampDutyAmount: string,
    opinionDate: string,
    paymentDate: string
  ) {
    this.valuationRequestId = valuationRequestId;
    this.notaryName = notaryName;
    this.deedNo = deedNo;
    this.attestedDate = attestedDate;
    this.stampDutyAmount = stampDutyAmount;
    this.opinionDate = opinionDate;
    this.paymentDate = paymentDate;
  }
}
