import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ReportServiceService } from "../service/report-service.service";
import { NotifierService } from "angular-notifier";
import * as XLSX from "xlsx";

@Component({
	selector: 'app-registration-details-report',
	templateUrl: './registration-details-report.component.html',
	styleUrls: ['./registration-details-report.component.scss']
})
export class RegistrationDetailsReportComponent implements OnInit {

	// for local authorities
	selectedItemsLA = [];
	dropdownListLA = [];
	dropdownSettingsLA: IDropdownSettings = {};

	// for deed nature
	selectedItemsDN = [];
	dropdownListDN = [];
	dropdownSettingsDN: IDropdownSettings = {};

	// for allocated regional branches
	selectedItemsARB = [];
	dropdownListARB = [];
	dropdownSettingsARB: IDropdownSettings = {};

	// submitted date range
	fromDate: string = null;
	toDate: string = null;
	notary: string = null;
  currentDate =new Date().toISOString().split('T')[0];

	// report data
	public reportDetails: any = [];
	public showPrint: boolean = false;
	public selectedDetails: any;
	public download_excel_status: boolean = false;

	// report data footer data
	public total_count: number = 0;
	public total_declare_value: number = 0;

	constructor(public modalService: NgbModal,
		public reportService: ReportServiceService,
		private notifier: NotifierService) {
	}

	ngOnInit() {
		// set up assessor dropdown
		this.dropdownSettingsDN = {
			singleSelection: false,
			idField: 'id',
			textField: 'name',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		// set up local authority dropdown
		this.dropdownSettingsLA = {
			singleSelection: false,
			idField: 'id',
			textField: 'localAuthority',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		// set up allocated regional branches dropdown
		this.dropdownSettingsARB = {
			singleSelection: false,
			idField: 'id',
			textField: 'office',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		// invoke services and load data to dropdown
		this.get_all_local_authority();
		this.get_all_deed_nature();
		this.get_all_allocated_branches();
	}

	/******** LOAD DROP DOWN DATA **************/
	// retrieve local authority data from service
	get_all_local_authority() {
		this.reportService.getAllLA().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListLA = value.data;
			} else {
				console.log("No Local authority return");
			}
		});
	}

	// retrieve allocated branches data from service
	get_all_allocated_branches() {
		this.reportService.getAllAB().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListARB = value.data;
				console.log(value.data);
			} else {
				console.log("No Allocated Regional Branch return");
			}
		});
	}

	// retrieve assessor data from service
	get_all_deed_nature() {
		this.reportService.getAllDeedNature().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListDN = value.data;
				console.log(value.data);
			} else {
				console.log("No Deed Nature return");
			}
		});
	}

	/******** LOAD DROP DOWN DATA **************/

	/******** LOAD REPORT DATA **************/
	getReportData() {
		// capture from date & to date
		// if (this.fromDate == null) {
		// 	this.notifier.notify('error', 'Submit from date is mandatory!');
		// 	console.log("No data found");
		// }

    /*else if (this.toDate == null) {
			this.notifier.notify('error', 'Submit to date is mandatory!');
			console.log("No data found");
		}*/

		this.captureSelectedDetails();

		this.showPrint = true;
    let dataLength = 0;

		this.reportService.getRegisterDetailsReport(this.fromDate, this.toDate, this.selectedDetails.selectedARB_id, this.selectedDetails.selectedLA_id, this.selectedDetails.selectedDN_names.replace(/\s/g, "")).subscribe(value => {
      dataLength = value.data.length;
			if (value.data.length > 0) {
				this.reportDetails = value.data;
				this.total_count = value.data.length;
				this.getTotalColumns(value.data);
        setTimeout(() => {
          this.getPdfReportView();
        }, 1000);
			} else {
				this.notifier.notify('error', 'No records found!');
				console.log("No data found");
			}
		});
    return dataLength;
	}
	/******** LOAD REPORT DATA **************/

	/******** CAPTURE INPUTS **************/
	captureSelectedDetails(): void {
    let lastDate = "";
    if(this.toDate == null){
      lastDate = new Date().toISOString().slice(0, 10);
    } else {
      lastDate = this.toDate;
    }
		var date_range = ((this.fromDate == null) && (this.toDate == null)) ? "All Date" : this.fromDate + ' to ' + lastDate;
		// capture selected local authorities
		var selectedLA_Names = "";
		var selectedLA = "";
		if (this.selectedItemsLA != null) {
			this.selectedItemsLA.forEach(value => {
				selectedLA += value.id + ",";
				selectedLA_Names += value.localAuthority + ", ";
			});
		} else {
			selectedLA_Names = "N/A";
			selectedLA = "";
		}

		// capture selected assessors
		var selectedDN = "";
		var selectedDN_Names = "";
		if (this.selectedItemsDN != null) {
			this.selectedItemsDN.forEach(value => {
				selectedDN += value.id + ",";
				selectedDN_Names += value.name + ", ";
			});
		} else {
			selectedDN = "";
			selectedDN_Names = "ALL";
		}

		// capture selected allocated regional branches
		var selectedARB = "";
		var selectedARB_Names = "";
		if (this.selectedItemsARB != null) {
			this.selectedItemsARB.forEach(value => {
				selectedARB += value.id + ",";
				selectedARB_Names += value.office + ", ";
			});
		} else {
			selectedARB = "";
			selectedARB_Names = "N/A";
		}

		this.selectedDetails = {
			selected_date: date_range,

			selectedLA_id: selectedLA,
			selectedLA_names: selectedLA_Names,

			selectedDN_id: selectedDN,
			selectedDN_names: selectedDN_Names,

			selectedARB_id: selectedARB,
			selectedARB_names: selectedARB_Names,

			selected_notary: this.notary
		};
	}
	/******** CAPTURE INPUTS **************/

	/******** EVENT FUNCTION **************/
	generatePdfReport() {
	  this.getReportData();
	}

	generateExcelReport() {
		// this.getReportData();
		this.download_excel_status = true;
		setTimeout(() => {
			this.getExcelReportView();
		}, 1000);
	}

	closeFurtherReqRemark(): void {
		this.modalService.dismissAll();
	}

	onItemSelectLA(item: any) {
		// console.log(item);
	}
	onSelectAllLA(items: any) {
		// console.log(items);
	}

	onItemSelectARB(item: any) {
		// console.log(item);
	}
	onSelectAllARB(items: any) {
		// console.log(items);
	}

	onItemSelectDN(items: any) {
		// console.log(items);
	}

	onSelectAllDN(items: any) {
		// console.log(items);
	}
	/******** EVENT FUNCTION **************/

	/******** REPORT VIEW **************/
	getPdfReportView(): void {

		let printContents: any;
		let popupWin: any;

		printContents = document.getElementById('registrationDetailsReportContent').innerHTML;

		popupWin = window.open();
		popupWin.document.open();
		popupWin.document.write(`
    <html>
    <head>
        <title>Registration Details</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <!--<script src="../../../../assets/js/paged.js"></script>-->
        <!--<script src="../../../../assets/js/paged.polyfill.js"></script>-->
        <style>
          @page {
            size: A3 landscape;
            @bottom-right {
              content: "Page " counter(page) " of " counter(pages);
            }
            .page{
                page-break-after: always;
            }
          }

            body
            {
                margin: 10mm 10mm 0mm 10mm;

            }
            table{
              border: 1px solid #000;
              border-collapse: collapse;
            }
            tbody{
              page-break-before: always;
            }

            table th, table td
            {
                padding: 5px;
                margin: 0px;
                border: 1px solid #000;
            }
            .page{
                page-break-after: always;
            }

        </style>
    </head>
    <body onload="window.print();">
            ${printContents}
    </body>
    </html>`
		);
		popupWin.document.close();
	}

	getExcelReportView(): void {
		var printContents = document.getElementById('excel_print');
		const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(printContents);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Reg. Details');
		XLSX.writeFile(wb, 'Registration details report.xlsx');
	}

	getTotalColumns(data: []): void {
		data.forEach((record: any) => {
			this.total_declare_value += +record.declaredValue;
		});
	}
	/******** REPORT VIEW **************/
}
