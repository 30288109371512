export class RimApplicationFormExcel {
    name: string;
    deedNo: string;
    deedDate: string;
    nature: string;
    deedValue: string;
    bank: string;
    branch: string;
    receiptNo: string;
    stampDutyDate: string;
    stampDutyValue: string;
    reimbursementApplicationId: string;
}