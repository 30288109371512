import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { FloorSubTypeService } from 'src/app/service/floor-sub-type/floor-sub-type.service';
import { FloorTypeService } from 'src/app/service/floor-type/floor-type.service';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/service/data.service';
import { FloorSubType } from 'src/app/model/floor-sub-type';

@Component({
  selector: 'app-search-floor-sub-type',
  templateUrl: './search-floor-sub-type.component.html',
  styleUrls: ['./search-floor-sub-type.component.scss']
})
export class SearchFloorSubTypeComponent implements OnInit, OnDestroy {

  addFloorSubTypeForm: FormGroup;
  private sub = new SubSink();
  floorType = [];


  constructor(private floorSubTypeService: FloorSubTypeService,
              private floorTypeService: FloorTypeService,
              private formBuilder: FormBuilder,
              private notifier: NotifierService,
              private dataService: DataService) { }

  ngOnInit() {

    this.addFloorSubTypeForm = this.formBuilder.group({
      floorType: ['', [Validators.required]],
      name: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });

    this.sub.add(this.floorTypeService.getAll()
      .subscribe(
        (res) => {
          this.floorType = (res as any).data;
        }
      ));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  saveFloorSubType() {
    if (this.addFloorSubTypeForm.valid) {

      //  console.log("floor type"+this.fl)
      //  console.log("fl 2"+JSON.stringify(this.getFloorType()));
      const floorSubType = new FloorSubType();

      floorSubType.description = this.getDescription();
      floorSubType.name = this.getName();
      floorSubType.floorType = this.getFloorType();

      this.sub.add(this.floorSubTypeService.createFloorSubType(floorSubType)

        .subscribe(response => {
          this.notifier.notify('success', (response as any).message);
          this.dataService.updateFloorSubType(true);
        }));

      this.reset();
    }
  }
  get f() { return this.addFloorSubTypeForm.controls; }

  getDescription() {
    return this.addFloorSubTypeForm.get('description').value;
  }
  getName() {
    return this.addFloorSubTypeForm.get('name').value;
  }

  getFloorType() {
    return this.addFloorSubTypeForm.get('floorType').value;
  }

  reset() {
    this.addFloorSubTypeForm.reset();
  }
}
