export class getPaymentRecipt{
  public filename:String;
  public id:number;
  public fileType:String;

  constructor(
    filename:String,
    id:number,
    fileType:String,
  ){
    this.filename = filename;
    this.id = id;
    this.fileType = fileType;
  }
}
