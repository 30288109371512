import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import { UserDetails } from 'src/app/model/user-details';
import { NotifierService } from 'angular-notifier';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient, private notifier: NotifierService) {}

  fetchAllUserRoles(): Observable<any> {
    return this.http.get<any>(environment.api_url + "/roles/all");
  }

  saveInternalUser(body): Observable<any> {
    return this.http.post<any>(
      environment.api_url + "/users/registration/internaluser",
      body
    );
  }

  getAll(): Observable<{ message: string; data: any }> {
    return this.http.get<{ message: string; data: any }>(
      environment.api_url + "/users/all"
    );
  }

  getAllRange(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(
      environment.api_url +
        "/users/all?offset=" +
        offset +
        "&limit=" +
        limit +
        ""
    );
  }

  getAllUser(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(
      environment.api_url + "/users?offset=" + offset + "&limit=" + limit + ""
    );
  }

  getSearchUser(keyword, offset, limit): Observable<any[]> {
    return this.http.get<any[]>(
      environment.api_url +
        "/users/search?keyword=" +
        keyword +
        "&offset=" +
        offset +
        "&limit=" +
        limit +
        ""
    );
  }

  retrieveUserInfo(user: UserDetails): Observable<any> {
    return this.http.post<any>(environment.api_url + "/users/details", user);
  }

  retrieveUserList(): Observable<any> {
    return this.http.get<any>(environment.api_url + "/users/list");
  }

  retrieveAssessorAndDComList(): Observable<any> {
    return this.http.get<any>(
      environment.api_url + "/users/get-assessors-and-dcom"
    );
  }

  retrieveMainInternalUserList(): Observable<any> {
    return this.http.get<any>(
      environment.api_url + "/users/get-main-internal-user-list"
    );
  }

  inactivateUser(id: string): Observable<any> {
    return this.http.get<any>(
      environment.api_url + "/users/inactivate-user/" + id
    );
  }

  getUsersByRoleCode(userRoleCode: string): Observable<any> {
    const params = {
      params: {
        userRoleCode: userRoleCode.toString(),
      },
    };
    return this.http
      .get<any>(`${environment.api_url}/users/get-users-by-type`, params)
      .pipe(
        tap((_) => this.log("search User by user role")),
        catchError((err) => {
          this.notifier.notify("error", err);
          return throwError(err);
        }),
        catchError(this.handleError<any>("get User by user role"))
      );
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      this.notifier.notify("error", `${operation} failed`);
      return of(result as T);
    };
  }
  private log(message: string) {}
}
