import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FloorTypeService } from 'src/app/service/floor-type/floor-type.service';
import { FloorType } from 'src/app/model/floor-type';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-add-floor-type',
  templateUrl: './add-floor-type.component.html',
  styleUrls: ['./add-floor-type.component.scss']
})
export class AddFloorTypeComponent implements OnInit, OnDestroy {

  addFloorTypeForm: FormGroup;
  private sub = new SubSink();

  constructor(private floorTypeService: FloorTypeService,
              private formBuilder: FormBuilder,
              private notifier: NotifierService,
              private dataService: DataService) { }

  ngOnInit() {

    this.addFloorTypeForm = this.formBuilder.group({
       code: ['', [Validators.required]],
       name: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  saveFloorType() {
    if (this.addFloorTypeForm.valid) {

      const floorType = new FloorType();

      floorType.description = this.getDescription();
      floorType.code = this.getCode();
      floorType.name = this.getName();

      console.log('code floor' + floorType.code);
      this.sub.add(this.floorTypeService.createFloorType(floorType)
        .subscribe(response => {
          this.notifier.notify('success', (response as any).message);
          this.dataService.updateFloorType(true);

        }));
    }
  }
  getDescription() {
    return this.addFloorTypeForm.get('description').value;
  }
  getCode() {
    return this.addFloorTypeForm.get('code').value;
  }
  getName() {
    return this.addFloorTypeForm.get('name').value;
  }

}
