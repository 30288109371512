import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { Payment } from 'src/app/model/payment';
import { BankService } from 'src/app/service/bank/bank.service';
import { PaymentService } from 'src/app/service/payment/payment.service';
import { CalculationService } from "src/app/service/calculation/calculation.service";
import { FileUploadPaymentComponent } from "../../../../../dashboard-external/assesment/file-upload-payment/file-upload-payment.component";
import { DocumentUploadService } from 'src/app/service/document-upload/document-upload.service';
import { ValuationRequest } from '../../../../../../../model/valuation-request';
import { ValuationRequestService } from '../../../../../../../service/valuation-request/valuation-request.service';
import { CertificateService } from 'src/app/service/certificate/certificate.service';
import { SubSink } from 'subsink';
import { Certificate } from 'src/app/model/certificate';
import { NotifierService } from 'angular-notifier';
import {ActivatedRoute, Router} from '@angular/router';
import { AppealService } from 'src/app/service/appeal/appeal.service';
import { Constant } from 'src/app/util/constant';
import { OpinionLetter } from 'src/app/model/opinion-letter';
import { OpinionLetterService } from 'src/app/service/opinion-letter/opinion.letter.service';


@Component({
  selector: 'app-view-valuation',
  templateUrl: './view-valuation.component.html',
  styleUrls: ['./view-valuation.component.scss']
})
export class ViewValuationComponent implements OnInit {
  @ViewChild('fileUploadPayment', { static: false }) fileUploadPayment: FileUploadPaymentComponent;
  @ViewChild('closeBtn', { static: false }) closeBtn: ElementRef;
  @Input()
  valuationRequestId: number;
  @Input()
  fileNumberFormat: string;
  @Input()
  valuationExchangeId: number;
  @Input()
  deedType: string;
  @Input()
  opinionStatus: string;

  model: Payment;
  calculation: any;
  bankList: any[];
  filesList: File[] = [];
  documentsIdList = [];
  valuationRequest: ValuationRequest;
  private sub = new SubSink();
  certificate: Certificate;
  certificateParty2:Certificate;
  paymentForm: FormGroup;
  maxDate: any;
  referenceNoPattern = '^[a-zA-Z0-9-/-]+$';
  stampDuty : string;
  valuationAmount : string;
  appealButtonDisabled: boolean;
  appealButtonChecked: boolean;

  selectedLetter: string;
  opinionLetterList: OpinionLetter[];

  constructor(
    private bankService: BankService,
    private paymentService: PaymentService,
    private calculationService: CalculationService,
    private documentUploadService: DocumentUploadService,
    private valuationRequestService: ValuationRequestService,
    private certificateService: CertificateService,
    private notifier: NotifierService,
    private formBuilder: FormBuilder,
    private appealService: AppealService,
    private route:ActivatedRoute,
    public router: Router,
    private opinionLetterService: OpinionLetterService) { }

  ngOnInit() {
    // console.log(this.fileNumberFormat);
    this._prepare();
    this.model = new Payment();
    this.model.valuationRequestId = this.valuationRequestId;
    this.valuationRequest = new ValuationRequest();
    this.appealButtonChecked = true;

    this.paymentForm = this.formBuilder.group({
      branch: ['', [Validators.required]],
      paidDate: ['', [Validators.required]],
      deedNumber: ['', [Validators.required]],
      bank: ['', [Validators.required]],
      referenceNo: ['', [Validators.required, Validators.pattern(this.referenceNoPattern)]],
      amount: ['', [Validators.required]]
    });

    this.maxDate = new Date();
  }

  _prepare() {
    this.getAppealCount();
    this.calculationService.getCalculationRecords(this.valuationRequestId)
      .subscribe(data => {
        this.calculation = data.data[0];

        this.valuationRequestService.getStampDuty(this.valuationRequestId).subscribe(data=>{
          let StD = data.data;
          this.stampDuty =(StD.toFixed(2)+"").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        })

        this.valuationRequestService.getPropertyValue(this.valuationRequestId).subscribe(data=>{
          let ProV = data.data;
          this.valuationAmount = (ProV.toFixed(2)+"").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        })

        // this.stampDuty = (this.calculation.stampDuty.toFixed(2)+"").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // this.valuationAmount = (this.calculation.valueOfTheProperty.toFixed(2)+"").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      });
    this.bankService.getAll().subscribe(
      data => {
        this.bankList = data.data;
      }
    );

    if(this.deedType === 'Exchange'){
      this.certificateService.fetchApprovedCertificatesByValuationReqId(this.valuationExchangeId).subscribe(
        data => {
          this.certificateParty2 = data.data;
          this.certificateParty2.content = this.certificateParty2.content;
        }
      );
    }

    this.certificateService.fetchApprovedCertificatesByValuationReqId(this.valuationRequestId).subscribe(
      data => {
        this.certificate = data.data;
        this.certificate.content = this.certificate.content;
      }
    );

    this.loadOpinionLetterData();
  }

  loadOpinionLetterData() {
    this.sub.add(this.opinionLetterService.fetchAllOpinionLettersByValuationReqId(this.valuationRequestId).subscribe(
        (res) => {
          this.opinionLetterList = (res as any).data;
          console.log(this.opinionLetterList);
        }
      ));
  }

  openOpinionLetter(letter) {
    this.selectedLetter = letter.content;
  }

  getAppealCount(){
    this.appealService.getAppealCount(this.route.snapshot.paramMap.get('id')).subscribe(data=>{
      let count:number = data.data;
      if(count >= 2 || this.opinionStatus === Constant.VALUATION_REQUEST_REJECTED_CODE){
        this.appealButtonDisabled = true;
      }else{
        this.appealButtonDisabled = false;
      }
    })
  }
  enableAppealButton(event, i){
    if (event.target.checked === true) {
      this.appealButtonChecked = false;
    }
    else{
      this.appealButtonChecked = true;
    }
  }
  requestAppeal() {
    this.router.navigate(['/view-appeal-request/' + this.valuationRequestId]);
  }

  selectedBank(name) {
    this.model.bank = name;
  }

  get f() {
    return this.paymentForm.controls;
  }

  savePayment() {
    this.fileUploadPayment.outPutImagesSet();
    if (this.checkFormValidity() && this.filesList.length > 0) {
      this.documentUploadService.uploadPaymentDocuments(this.filesList).subscribe(
        response => {
          this.documentsIdList = (response as any).data;

          // console.log('added document : >>>>>>>>>>>>>>>> ' + this.documentsIdList.length);
          this.valuationRequest.documentsIdList = this.documentsIdList;

          this.sub.add(this.valuationRequestService.setValuationPaymentDetailsForValuationRequest(this.valuationRequestId, this.valuationRequest)
            .subscribe(res => {
              this.model.bank = this.paymentForm.get('bank').value;
              this.model.branch = this.paymentForm.get('branch').value;
              this.model.paidDate = this.paymentForm.get('paidDate').value;
              this.model.remark = this.paymentForm.get('deedNumber').value;
              this.model.referenceNo = this.paymentForm.get('referenceNo').value;
              let amount = this.paymentForm.get('amount').value;
              this.model.amount = amount.toString();
              this.model.transactionStatus = "ACTIVE"
              this.paymentService.addPayment(this.model).subscribe(
                data => {
                  this.notifier.notify('success', 'Payment added successfully');
                  this.paymentForm.reset();
                  this.closeBtn.nativeElement.click();
                }
              )
            }, (error => {
              this.notifier.notify('error', error);
            })));
        });
    }else if(this.filesList.length == 0){
      this.notifier.notify('error', 'Please Upload Payment Recipts Document');
    }


  }

  setPaymentImageArray(images: File[]) {
    this.filesList = images;
  }


  checkFormValidity(): boolean {
    this.paymentForm.get('branch').markAsTouched();
    this.paymentForm.get('paidDate').markAsTouched();
    this.paymentForm.get('deedNumber').markAsTouched();
    this.paymentForm.get('bank').markAsTouched();
    this.paymentForm.get('referenceNo').markAsTouched();
    this.paymentForm.get('amount').markAsTouched();
    if (this.checkGranteeCompanyFields()) {
      return true;
    } else {
      return false;
    }
  }

  checkGranteeCompanyFields(): boolean {
    if (this.paymentForm.get('branch').valid && this.paymentForm.get('paidDate').valid &&
      this.paymentForm.get('deedNumber').valid && this.paymentForm.get('bank').valid &&
      this.paymentForm.get('referenceNo').valid && this.paymentForm.get('amount').valid) {
      return true;
    } else {
      return false;
    }
  }

}
