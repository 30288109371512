import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, RouterLinkActive} from '@angular/router';
import {Constant} from 'src/app/util/constant';
import {ValuationRequestCount} from 'src/app/model/valuation_request_count';
import {DashboardService} from 'src/app/service/dashboard/dashboard.service';

@Component({
  selector: 'app-view-assessment-request-iu',
  templateUrl: './view-assessment-request-iu.component.html',
  styleUrls: ['./view-assessment-request-iu.component.scss']
})
export class ViewAssessmentRequestInternalUserComponent implements OnInit {

  selectValRequest: string;
  pendingCount: number;
  inprogressCount: number;
  furtherRequirementCount: number;
  completedCount: number;
  rejectedCount: number;
  scheduleCount: number;
  pendingPaymentCount: number;
  completedPaymentCount: number;

  type: string;

  userdata: any = [];
  userrolecode: string;

  public model = new ValuationRequestCount('', '', '');

  constructor(private route: ActivatedRoute, private dashboardService: DashboardService) {
  }

  ngOnInit() {
    this._prepare();
  }

  _prepare() {
    this.route.paramMap.subscribe(params => {
      if (params.get('type') === 'all-request') {
        this.type = 'pending-requests';
      } else {
        this.type = params.get('type');
      }
      this.selectValRequest = this.type;
    });
    this.obtainUserData();
  }

  obtainUserData() {
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.model.userId = this.userdata.id;
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.userrolecode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.userrolecode = Constant.ROLE_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.userrolecode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.userrolecode = Constant.ROLE_ACCESSOR;
        break;
      }
    }
    this.model.userRole = this.userrolecode;

    this.newApplicationCount();
    this.inprogressApplicationCount();
    this.rejectedCountChange();
    this.completedApplicationCountChange();
    this.scheduleCountChange();
    this.pendingPaymentCountChange();
    this.paymentCompletedCountChange();
    this.frCount();
  }

  newApplicationCount() {
    this.model.valuationStatus = Constant.VALUATION_REQUEST_PENDING_CODE;
    this.dashboardService.getRequestInternalUserCount(this.model).subscribe(value => {
      this.pendingCount = value;
    });
  }

  inprogressApplicationCount() {
    this.model.valuationStatus = Constant.VALUATION_REQUEST_INPROGRESS_CODE;
    this.dashboardService.getRequestInternalUserCount(this.model).subscribe(value => {
      this.inprogressCount = value;
    });
  }

  frCount() {
    this.model.valuationStatus = Constant.VALUATION_REQUEST_FURTHER_REQUIREMENT_CODE;
    this.dashboardService.getRequestInternalUserCount(this.model).subscribe(value => {
      this.furtherRequirementCount = value;
    });
  }

  rejectedCountChange() {
    this.model.valuationStatus = Constant.VALUATION_REQUEST_REJECTED_CODE;
    this.dashboardService.getRequestInternalUserCount(this.model).subscribe(value => {
      this.rejectedCount = value;
    });
  }

  completedCountChange() {
    this.model.valuationStatus = Constant.VALUATION_REQUEST_COMPLETED_CODE;
    this.dashboardService.getRequestInternalUserCount(this.model).subscribe(value => {
      this.completedCount = value;
    });
  }

  scheduleCountChange() {
    this.model.valuationStatus = Constant.VALIATION_SCHEDULE_CODE;
    this.dashboardService.getRequestInternalUserCount(this.model).subscribe(value => {
      this.scheduleCount = value;
    });
  }

  pendingPaymentCountChange() {
    this.model.valuationStatus = Constant.VALIATION_PAYMENT_PENDING;
    this.dashboardService.getRequestInternalUserCount(this.model).subscribe(value => {
      this.pendingPaymentCount = value;
    });
  }

  paymentCompletedCountChange() {
    this.model.valuationStatus = Constant.VALIATION_PAYMENT_COMPLETED;
    this.dashboardService.getRequestInternalUserCount(this.model).subscribe(value => {
      this.completedPaymentCount = value;
    });
  }
  completedApplicationCountChange() {
    this.model.valuationStatus = Constant.VALIATION_COMPLETED_APPLICATION;
    this.model.userRole = this.userrolecode;
    this.dashboardService.getRequestInternalUserCount(this.model).subscribe(value => {
      this.completedCount = value;
    });
  }
}
