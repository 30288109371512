import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InspectionLand } from 'src/app/model/inspection-land';
import { InspectionCondominium } from 'src/app/model/inspection-condominium';
import { InspectionLandBuilding } from 'src/app/model/inspection-land-building';
import { InspectionCommon } from 'src/app/model/inspection-common';
import { InspectionRecreation } from 'src/app/model/inspection-recreation';
import { InspectionLandBuildingMain } from 'src/app/model/inspection-building-main';

@Injectable({
  providedIn: 'root'
})
export class InspectionService {
  private inspectionChildRequestUrl = '/inspection/inspectionbuilding/child';
  private inspection ='/inspection';
  private inspectionLandInsertRequestUrl = '/inspection/land/insert';
  private inspectionLandUpdateRequestUrl = '/inspection/land/update';
  private inspectionCondominiumInsertRequestUrl = '/inspection/condominium/insert';
  private inspectionCondominiumUpdateRequestUrl = '/inspection/condominium/update';
  private inspectionLandBuildingInsertRequestUrl = '/inspection/landbuilding/insert';
  private inspectionLandBuildingUpdateRequestUrl = '/inspection/landbuilding/update';
  private inspectionListRequestUrl = '/inspection/list';
  private buildingInformationListRequestUrl = '/inspection/building/list';

  private inspectionLandDetailListRequestUrl = '/inspection/land/list';
  private inspectionCondominiumDetailListRequestUrl = '/inspection/condominium/list';
  private inspectionLandBuildingDetailListRequestUrl = '/inspection/landbuilding/list';

  private inspectionRecreationInsertRequestUrl = '/inspection/recreation/insert';
  private inspectionRecreationChildListUrl = '/inspection/recreation/child/list';
  private inspectionRecreationUpdateUrl = '/inspection/recreation/update';

  constructor(
    private http: HttpClient
  ) { }

  inspectionChildId(inspectionCommon: InspectionCommon): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionChildRequestUrl}`, inspectionCommon);
  }

  inspectionRecreationUpdate(inspectionRecreation: InspectionRecreation): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionRecreationUpdateUrl}`, inspectionRecreation);
  }

  inspectionRecreationInsert(inspectionRecreation: InspectionRecreation): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionRecreationInsertRequestUrl}`, inspectionRecreation);
  }

  buildingInformationList(inspectionCommon: InspectionCommon): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.buildingInformationListRequestUrl}`, inspectionCommon);
  }

  inspectionList(inspectionCommon: InspectionCommon): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionListRequestUrl}`, inspectionCommon);
  }

  // Normal Land - Save
  inspectionLandInsert(inspectionLand: InspectionLand): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionLandInsertRequestUrl}`, inspectionLand);
  }

  inspectionLandUpdate(inspectionLand: InspectionLand): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionLandUpdateRequestUrl}`, inspectionLand);
  }

  inspectionCondominiumInsert(inspectionCondominium: InspectionCondominium): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionCondominiumInsertRequestUrl}`, inspectionCondominium);
  }

  inspectionCondominiumUpdate(inspectionCondominium: InspectionCondominium): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionCondominiumUpdateRequestUrl}`, inspectionCondominium);
  }

  inspectionLandBuildingInsert(inspectionCondominium: InspectionLandBuildingMain): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionLandBuildingInsertRequestUrl}`, inspectionCondominium);
  }

  // Land with building - Update
  inspectionLandBuildingUpdate(inspectionCondominium: InspectionLandBuildingMain): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionLandBuildingUpdateRequestUrl}`, inspectionCondominium);
  }

  inspectionLandDetailList(inspectionCommon: InspectionCommon): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionLandDetailListRequestUrl}`, inspectionCommon);
  }

  inspectionCondominimumDetailList(inspectionCommon: InspectionCommon): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionCondominiumDetailListRequestUrl}`, inspectionCommon);
  }

  inspectionLandBuildingDetailList(inspectionCommon: InspectionCommon): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionLandBuildingDetailListRequestUrl}`, inspectionCommon);
  }

  inspectionRecreationChildList(inspectionCommon: InspectionCommon): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionRecreationChildListUrl}`, inspectionCommon);
  }


  // recreation
  getLastInspectionRecreationRecords(userId:string, groupId:string): Observable<any> {
    return this.http.get(`${environment.api_url}/inspection/recreation/getLastInspectionRecreationRecords/${userId}/${groupId}`);
  }

  getRecreationList(groupId:string): Observable<any> {
    return this.http.get(`${environment.api_url}/inspection/recreation/getRecreationGroup/${groupId}`)
  }

  getInspectionById(id:string): Observable<any> {
    return this.http.get(`${environment.api_url}/inspection/recreation/getInspectionById/${id}`)
  }

  // download pdf
  downloadInspectionReport(valuationID:number, inspectionId:number, reportType: string): Observable<any> {
    return this.http.get(`${environment.api_url}${this.inspection}/download/${valuationID}/${inspectionId}/${reportType}`, { responseType: 'blob' })
  }

}
