import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PropertyUsageService} from '../../../../../../../service/property-usage/property-usage.service';
import {PropertyUsage} from '../../../../../../../model/propertyUsage';
import {SubSink} from 'subsink';
import {NotifierService} from 'angular-notifier';
import {DataService} from '../../../../../../../service/data.service';

@Component({
  selector: 'app-add-property-usage',
  templateUrl: './add-property-usage.component.html',
  styleUrls: ['./add-property-usage.component.scss']
})
export class AddPropertyUsageComponent implements OnInit, OnDestroy {

  addPropertyUsageForm: FormGroup;
  private sub = new SubSink();

  constructor(private propertyUsageService: PropertyUsageService,
              private formBuilder: FormBuilder,
              private notifier: NotifierService,
              private dataService: DataService) { }

  ngOnInit() {
    this.addPropertyUsageForm = this.formBuilder.group({
      description: ['', [Validators.required]],
      name: ['', [Validators.required]],
      code: ['', [Validators.required]]
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  savePropertyUsage() {
    if (this.addPropertyUsageForm.valid) {
      const propertyUsage = new PropertyUsage();
      propertyUsage.code = this.getCode();
      propertyUsage.description = this.getDescription();

      propertyUsage.name = this.getName();
      this.sub.add(this.propertyUsageService.createPropertyUsage(propertyUsage)
        .subscribe(response => {
          this.notifier.notify( 'success', (response as any).message);
          this.dataService.updateTablePropertyUsage(true);
        }));
    }
  }

  getCode() {
    return this.addPropertyUsageForm.get('code').value;
  }
  getName() {
    return this.addPropertyUsageForm.get('name').value;
  }
  getDescription() {
    return this.addPropertyUsageForm.get('description').value;
  }

}
