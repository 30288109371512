import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/service/data.service';
import { PasswordPolicyService } from 'src/app/service/passwordPolicy/password-policy.service';
import { SubSink } from 'subsink';
import { UpdatePasswordPolicyComponent } from '../update-password-policy/update-password-policy.component';

@Component({
  selector: 'app-view-password-policy',
  templateUrl: './view-password-policy.component.html',
  styleUrls: ['./view-password-policy.component.scss']
})
export class ViewPasswordPolicyComponent implements OnInit {

  @ViewChild(UpdatePasswordPolicyComponent,{static : false})
  private updatePasswordPolicyComponent: UpdatePasswordPolicyComponent;

  public sub = new SubSink();


  // pagination
  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];
  passwordPolicy: any;

  currentPage:number;
  pageSize:number;
  totalItems:number;

  constructor(private passwordPolicyService : PasswordPolicyService, private dataService: DataService) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.sub.add(this.dataService.updateDataTablePasswordPolicy.subscribe(update => this.onUpdated(update)));

    this.onUpdated(true);

  }


  pageable(i: number) {
    this.passwordPolicyService.getPasswordPolicy((i-1), 10).subscribe(responce => {
      this.passwordPolicy = (responce as any).data.content;
      this.currentPage = (responce as any).data.number+1;
      this.totalItems = (responce as any).data.totalElements;
    });
  }

  counter(i: number) {
    return new Array(i);
  }

   // update the table
   private updateTheTable() {
     this.sub.add(this.passwordPolicyService.getPasswordPolicy(0, 10)
       .subscribe(
         (res) => {
           this.passwordPolicy = (res as any).data.content;
           this.currentPage = (res as any).data.number+1;
           this.totalItems = (res as any).data.totalElements;
         }));
   }

  onUpdated(updatedSuccessfully: boolean) {
    if (updatedSuccessfully) {
      this.updateTheTable();
    }
  }

  //send data to model component
triggerUpdate(passwordPolicy:any){
  this.updatePasswordPolicyComponent.setPasswordPolicy(passwordPolicy);
}

  // search by password Policy
  searchPropertyFacility(keyword: string) {
    // this.selectedPageNo = 0;
    // this.sub.add(this.propertyFacilityService.searchPropertyFacility(keyword, this.selectedPageNo, this.limit)
    //   .subscribe((res) => {
    //     this.propertyFacility = res.data.content;
    //     this.paginationButtonCounter(res.data.totalPages);
    //     this.numOfPages = res.data.totalPages;
    //   }));
  }
}
