import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-reimbursement-reports',
  templateUrl: './reimbursement-reports.component.html',
  styleUrls: ['./reimbursement-reports.component.scss']
})
export class ReimbursementReportsComponent implements OnInit {
  @ViewChild('monthly_requested_reimbursement', null) private monthly_requested_reimbursement: TemplateRef<object>;
  public showDynamicGrid: boolean;
  constructor(public modalService: NgbModal) { }

  ngOnInit() {
  }
  openPopupMessage(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static',size:'lg' });
  }
  monthly_requested_reimbursement_popup(){
    this.showDynamicGrid = true;
    this.openPopupMessage(this.monthly_requested_reimbursement);
  }
}
