import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Certificate } from '../../model/certificate';

@Injectable({
    providedIn: 'root'
})
export class CertificateService {

    private certificatetUrl = '/certificate'

    constructor(private http: HttpClient) {
    }

    fetchAllCertificatesByValuationReqId(valuationReqId : number): Observable<any[]> {
        return this.http.get<any>(environment.api_url + this.certificatetUrl+"/"+valuationReqId);
    }

    fetchApprovedCertificatesByValuationReqId(valuationReqId : number): Observable<any> {
        return this.http.get(environment.api_url + this.certificatetUrl+"/approvedCertificate/"+valuationReqId);
    }

    fetchCertificateDataByValuationReqId(valuationReqId : number): Observable<any> {
        return this.http.get(environment.api_url + this.certificatetUrl+"/certificateData/"+valuationReqId);
    }

    saveCertificate(certificate : Certificate): Observable<any> {
        return this.http.post<any>(environment.api_url + this.certificatetUrl, certificate);
    }

    updateCertificate(certificate : Certificate): Observable<any> {
        return this.http.post<any>(environment.api_url  + this.certificatetUrl + '/'+certificate.id, certificate);
    }


}
