export class SiteVisitSchedule {
    id:number;
    valuationReqId: number;
    propertyName: string;
    scheduleDate: any;
    scheduleTime: any;
    propertyVenue: string;
    inspectionSummary: string;
    siteVisitScheduleResource: Array<any>;

    constructor() {

    }
}
