import { Component, OnInit } from '@angular/core';
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ReportServiceService} from "../service/report-service.service";
import {NotifierService} from "angular-notifier";
import * as XLSX from "xlsx";

@Component({
  selector: 'app-application-payment-rejected-report',
  templateUrl: './application-payment-rejected-report.component.html',
  styleUrls: ['./application-payment-rejected-report.component.scss']
})
export class ApplicationPaymentRejectedReportComponent implements OnInit {

  // for local authorities
  selectedItemsLA = [];
  dropdownListLA= [];
  dropdownSettingsLA: IDropdownSettings = {};

  // submitted date range
  fromDate: string = null;
  toDate: string = null;

  // report data
  public  reportDetails:any = [];
  public showPrint: boolean = false;
  public selectedDetails: any;

  constructor(
    public modalService: NgbModal,
    public reportService: ReportServiceService,
    private notifier: NotifierService
  ) { }

  ngOnInit() {
    // set up local authority dropdown
    this.dropdownSettingsLA = {
      singleSelection: false,
      idField: 'id',
      textField: 'localAuthority',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.get_all_local_authority();
  }

  /*************** DROP DOWN SERVICES ***************/
  // retrieve local authority data from service
  get_all_local_authority(){
    this.reportService.getAllLA().subscribe(value => {
      if(value.data.length > 0){
        this.dropdownListLA = value.data;
      }else{
        console.log("No Local authority return");
      }
    });
  }
  /*************** DROP DOWN SERVICES ***************/

  /*************** CAPTURE UI DATA ***************/
	captureSelectedDetails(): void {
		// var date_range = ((this.fromDate == null) && (this.toDate == null)) ? "All Date" : this.fromDate + ' to ' + this.toDate;
    let lastDate = "";
    if(this.toDate == null || this.toDate == ""){
      lastDate = "Today";
    }else {
      lastDate = this.toDate;
    }

    var date_range = ((this.fromDate == null || this.fromDate == "") && (this.toDate == null || this.toDate == "")) ? "All Date" : (this.fromDate == null) ? ' to ' + lastDate : this.fromDate + ' to ' + lastDate;


    // capture selected local authorities
		var selectedLA_Names = "";
		var selectedLA = "";
		if (this.selectedItemsLA != null) {
			this.selectedItemsLA.forEach(value => {
				selectedLA += value.id + ",";
				selectedLA_Names += value.localAuthority + ", ";
			});
		} else {
			selectedLA_Names = "N/A";
			selectedLA = "";
		}

		this.selectedDetails = {
			selected_date: date_range,

			selectedLA_id: selectedLA,
			selectedLA_names: selectedLA_Names
		};
	}
	/*************** CAPTURE UI DATA ***************/

  /*************** GET REPORT DATA ***************/
	getReportData(): void {
		this.reportService.getAppPymntRejectReport(this.fromDate, this.toDate, this.selectedDetails.selectedLA_id).subscribe(value => {
			if (value.data.length > 0) {
				this.reportDetails = value;
			} else {
				this.notifier.notify('error', 'No records found!');
				console.log("No data found");
			}
		});
	}
	/*************** GET REPORT DATA ***************/

  /*************** GENERATE REPORT ***************/
	createPdfReport(): void {

		let printContents: any;
		let popupWin: any;
		printContents = document.getElementById('appPaymentDtlsReportContent').innerHTML;

		popupWin = window.open();
		popupWin.document.open();
		popupWin.document.write(`
    <html>
    <head>
        <title>Application Payment (Rejected) Details</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <!--<script src="../../../../assets/js/paged.js"></script>-->
        <!--<script src="../../../../assets/js/paged.polyfill.js"></script>-->
        <style>
          @page {
            size: A3 landscape;
            @bottom-right {
              content: "Page " counter(page) " of " counter(pages);
            }
            .page{
                page-break-after: always;
            }
          }

            body
            {
                margin: 10mm 10mm 0mm 10mm;

            }
            table{
              border: 1px solid #000;
              border-collapse: collapse;
            }
            tbody{
              page-break-before: always;
            }

            table th, table td
            {
                padding: 5px;
                margin: 0px;
                border: 1px solid #000;
            }
            .page{
                page-break-after: always;
            }

        </style>
    </head>
    <body onload="window.print();">
            ${printContents}
    </body>
    </html>`
		);
		popupWin.document.close();
	}
	/*************** GENERATE REPORT ***************/

  /*************** UI EVENT ***************/
	// event click on close button
	closeFurtherReqRemark() {
		this.modalService.dismissAll();
	}

	createExcel() {
		this.captureSelectedDetails();
		this.getReportData();
		this.showPrint = true;
		setTimeout(() => {
			var printContents = document.getElementById('excel_print');
			const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(printContents);
			const wb: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'App Pymnt (Rejected) Dtls.');
			XLSX.writeFile(wb, 'Application Payment (Rejected) Details Report.xlsx');
		}, 1000);
	}

	generatePdfReport() {
		this.captureSelectedDetails();
		this.getReportData();
		this.showPrint = true;
		setTimeout(() => {
			this.createPdfReport();
		}, 1000);

	}


	/*************** UI EVENT ***************/
}
