import { CalculationService } from './../../../../../../../service/calculation/calculation.service';
import { Component, Input, OnInit, OnChanges, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IPayment } from '../../../../../../../model/interfaces/payment';
import { FileService } from '../../../../../../../service/file/file.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { PaymentUpdate } from 'src/app/model/payment-update';
import { Constant } from 'src/app/util/constant';
import { PaymentService } from 'src/app/service/payment/payment.service';
import { ValuationRequestService } from 'src/app/service/valuation-request/valuation-request.service';
import { ValuationRequest } from 'src/app/model/valuation-request';
import { ValuationReject } from 'src/app/model/valuation-reject';
import { DeedDetail } from 'src/app/model/deed-detail';
import { NotifierService } from 'angular-notifier';
import Swal from 'sweetalert2';
// import Swal from 'sweetalert';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { BankService } from 'src/app/service/bank/bank.service';
import { NotificationModel } from 'src/app/model/notification-model';
import { NotificationService } from 'src/app/service/notification/notification.service';
import { NotificationEmailSend } from 'src/app/model/notification-email-send';
import { ValuationRequestRemark } from 'src/app/model/valuation-request-remark';
import { ValuationRequestUpdate } from 'src/app/model/valuation-request-update';
import { FurtherRequirementAndReject } from 'src/app/model/further-requirement-and-reject';
import { getPaymentRecipt } from 'src/app/model/get-paymment-recipt';
import { DocumentUploadDeedInternalPaymentComponent } from 'src/app/component/document/document-upload/document-upload-deed-internal-payment/document-upload-deed-internal-payment.component';
import Cropper from 'cropperjs';
import { v4 as uuidv4 } from 'uuid';
import { NotificationDetails } from 'src/app/model/notificationDetails';
import * as moment from 'moment';
import { checkCompletedOrPaypend } from '../Util/checkStatus';

@Component({
  selector: 'app-view-payment-iu',
  templateUrl: './view-payment-iu.component.html',
  styleUrls: ['./view-payment-iu.component.scss']
})
export class ViewPaymentInternalUserComponent implements OnInit {
  @ViewChild('furtherReqNotification', null) private enterRemark: TemplateRef<object>;
  @ViewChild('rejectApplication', null) private enterRejectRemark: TemplateRef<object>;
  @ViewChild('documentUploadDeedDetails', {static: false}) documentUploadDeedDetails: DocumentUploadDeedInternalPaymentComponent;
  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() status: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  userId: string;
  @Input()
  payment: Array<IPayment>;
  @Input()
  valuationRequestId: string;
  @Input()
  isReject:boolean;
  @Input()
  dComStatus: string;
  @Input()
  isAppeal:boolean;
  @Input()
  valuationRequestStatusNew:string;

  appealCount:number;
  selectPaths = [];
  otherSelectedPaths = [];
  selectPathsCollection = [];

  paymentList: any = [];
  selectFileSm = 0;
  typeID: any;
  previewFileData: any;
  previewFileDataList: any = [];
  type: any;
  selectedPayment: IPayment;
  rejectForm: FormGroup;
  furtherRequirementForm: FormGroup;
  public donePayment: boolean;
  public showDynamicGrid: boolean;
  public modelupdate = new PaymentUpdate('', '');
  public deedDetailModel = new DeedDetail('', '', '', '', '', '', '');
  public valuationRequestUpdateModel = new ValuationRequestUpdate('', '', '', '', '', '');
  public notificationDetails = new NotificationDetails('','');
  public getReciptModel = new getPaymentRecipt('',0,'')
  public valuationRequestRemark = new ValuationRequestRemark();

  furtherRequirementRemarkContent: string;
  applicationRejectRemarkContent: string;
  public rejectModel = new FurtherRequirementAndReject();
  public furtherRequirementModel = new FurtherRequirementAndReject();
  furtherRequirementFile: File;
  rejectFile: File;

  stampDuty:number;
  valuationRequest: ValuationRequest;

  userName: string;
  public fileNumberFormat: string;

  public valuationRequestStatus: string;
  public showRemarkValidation: boolean;
  public completedPaymentApproved: boolean;
  public generatedFileNumber: string;
  valuationdata: any = [];
  files;
  filesList: File[] = [];
  images = [];
  originalImages: any[] = [];
  names: string[] = [];
  deedNatureValue: any;
  currentUserRoleCode: string;
  userdata: any = [];
  public notificationModel = new NotificationModel("", "", "", "", "", "", "");
;
  public notificationEmailSendModel = new NotificationEmailSend();
  public imgURL: any;
  public imgURL1: any;
  public imgURL2: any;
  public documentUploaded: string;
  public documentUploaded1: string;

  isPreviousRejected:boolean;
  isCommissioner:boolean;

  private sub = new SubSink();

  notaryDetails: ValuationRequest;

  tmpPaidDate = new Date().toLocaleDateString();

  bankList: any = [];
  previewDeedFileData: any = [];
  assessorUserId: string;
  dComUserId: string;
  comUserId: string;
  notaryUserId: string;
  isComStatusReject: boolean;
  appeal:boolean;

  fileNumberFormatAvailable: boolean = true;
  checkStatusApplication  = checkCompletedOrPaypend;


  constructor(
    private fileService: FileService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public paymentService: PaymentService,
    public valuationRequestService: ValuationRequestService,
    public router: Router,
    private notifier: NotifierService,
    public bankService: BankService,
    public modalService: NgbModal,
    private formBuilder: FormBuilder,
    public notificationService: NotificationService,
    public calculationService:CalculationService,
  ) {
    this.generatedFileNumber = '';
    this.donePayment = false;
    this.completedPaymentApproved = false;
    this.showDynamicGrid= false;
    this.showRemarkValidation = false;
    this.isPreviousRejected = false;
    this.isCommissioner = false;
    this.appeal = false;
    this.appealCount = 0;
    this.valuationdata = JSON.parse(sessionStorage.getItem('valuationRequest'));
  }

  ngOnInit() {
    this.getBankList();
    this._prepare();
    window.scroll(0,0);
    this.isPreviousRejected = (this.dComStatus === 'REJECTED');
  }

  _prepare() {
    this.getAppealCount();
    this.getStampDuty();
    this.getLastDeedDetails(this.route.snapshot.paramMap.get('id'))
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.checkPermission();
    this.updateNotification(this.route.snapshot.paramMap.get('id'));

    this.pad(JSON.parse(sessionStorage.getItem('valuationRequest')).id, 7);

    this.fileNumberFormat = localStorage.getItem('fileNumberFormat');
    if (this.fileNumberFormat == "null" || this.fileNumberFormat == '') {
      this.fileNumberFormatAvailable = false;
    }

    this.valuationRequestStatus = localStorage.getItem('valuationRequestStatus');
    if (this.valuationRequestStatus === Constant.VALIATION_COMPLETED_APPLICATION || this.valuationRequestStatus === Constant.VALIATION_PAYMENT_PENDING) {
      this.completedPaymentApproved = true;
    }
    this.typeID = +this.route.snapshot.paramMap.get('id');
    this.initializePayment();
    this.loadPaymentData();
    this.loadPaymentReceipt();
    this.loadFileDeedDocument();
    this.retrieveAssignedValuationRequest();
    // this.getStampDuty();
    if(this.isAppeal == true){
      this.appeal = true;
    }else{
      this.appeal = false;
    }
    this.checkStatus(true)
  }

  //remove notification
  updateNotification(valuationReqId){
    this.notificationDetails.subject = Constant.PAYMENT_MADE_BY_NOTARY;
    this.notificationDetails.valuationRequestId = valuationReqId;
    this.notificationService.updateReleventNotification(this.notificationDetails).subscribe();
  }

  checkPermission() {
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.currentUserRoleCode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.currentUserRoleCode = Constant.ROLE_ACCESSOR;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER){
        this.currentUserRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.currentUserRoleCode = Constant.ROLE_COMMISSIONER;
        this.isCommissioner = true;
        break;
      }
    }
  }

  getLastDeedDetails(vId:any){

    this.valuationRequestService.getDeedDetails(vId).subscribe(async data=>{
       if(data.data == null){
        this.getStampDuty();
        this.getNotaryDetails(this.userId);
        this.deedDetailModel.deedNo ='';
        this.deedDetailModel.attestedDate ='';
        this.deedDetailModel.stampDutyAmount = this.stampDuty.toString();

        let payLength = (this.valuationdata.payment.length);
        this.deedDetailModel.paymentDate = await this.valuationdata.payment[payLength-1 ]['paidDate'];
      }else{
        this.deedDetailModel = data.data;
      }
    })

    this.calculationService.getCalculationRecords(this.valuationRequestId).subscribe(data=>{
      let calculationLength = data.data.length
      let lastUpdatedDate = data.data[calculationLength-1].lastUpdateDate
      let convertedDate =moment(lastUpdatedDate).format('YYYY-MM-DD')

      this.deedDetailModel.opinionDate = convertedDate.toString();
    })
  }

  get f() {
    return this.rejectForm.controls;
  }

  get f1() {
    return this.furtherRequirementForm.controls;
  }

  retrieveAssignedValuationRequest() {
    this.valuationRequestService.valuationRequestAssigned(this.route.snapshot.paramMap.get('id')).subscribe(
      data => {
        if (data.data !== null) {
          this.valuationRequestUpdateModel.dcommissionerId = data.data.dcommissionerId;
          this.valuationRequestUpdateModel.acccessorId = data.data.accessorId;
          this.valuationRequestUpdateModel.remark = data.data.remark;
        } else {
          console.log("Valuation Request not found");
        }
      }
    )
  }

  getAppealCount(){
    this.valuationRequestService.getAppealCount(this.route.snapshot.paramMap.get('id')).subscribe(data=>{
      this.appealCount = data.data;
    })
  }

  getStampDuty(){
    this.valuationRequestService.getStampDuty(this.route.snapshot.paramMap.get('id')).subscribe(data=>{
      this.stampDuty = data.data;
      this.deedDetailModel.stampDutyAmount = data.data;
    })
  }

  openPopupMessage(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });

    this.rejectForm = this.formBuilder.group({
      remarkTextarea: ['', [Validators.required]]
    });

    this.furtherRequirementForm = this.formBuilder.group({
      furtherRequirementTextarea: ['', [Validators.required]]
    });
  }

  getBankList(): any {
    this.bankService.getAll().subscribe(
      data => {
        this.bankList = data.data;
      }
    );
  }

  changeSelectedBank(bankCode): any {
    let bankDescription;
    const filtereddata = this.bankList.filter(i => i.id === Number(bankCode));
    if (JSON.stringify(filtereddata) !== '[]') {
      bankDescription = filtereddata[0]['bankName'];
      return bankDescription;
    }
  }

  getNotaryDetails(notaryUserID: string) {
    this.sub.add(this.valuationRequestService.getNotaryDetailsByUserID(notaryUserID)
      .subscribe((res) => {
        this.notaryDetails = res.data;
        if (this.notaryDetails['middleName'] == null) {
          this.deedDetailModel.notaryName = this.notaryDetails['firstName'] + " " + this.notaryDetails['lastName'];
        } else {
          this.deedDetailModel.notaryName = this.notaryDetails['firstName'] + " " + this.notaryDetails['middleName'] + " " + this.notaryDetails['lastName'];
        }
      }));
  }

  pad(num: number, size: number) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    this.generatedFileNumber = s;
    if(this.isAppeal == true){
      this.generatedFileNumber = '#A' + this.generatedFileNumber;
    }else{
      this.generatedFileNumber = '#VR' + this.generatedFileNumber;
    }
  }

  public redirectComponent(): void {
    if (!this.donePayment) {
      this.notifier.notify('error', 'Please check the Payment Status');
    }
    else {
      this.onPageCountChanged.emit(9);
    }
  }

  public redirectPreviousComponent(): void {
    this.onPageCountChanged.emit(7);
  }

  public checkStatus(type:boolean): void{
    this.status.emit(type);
  }
  // uploadChange(event) {
  //   this.files = event.target.files;
  //   for (let i = 0; i < this.files.length; i++) {
  //     const file = this.files[i];
  //     if (file.type === "image/png" || file.type === "image/jpeg") {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = (_event: any) => {
  //         this.imgURL1 = reader.result;
  //         this.images.push(_event.currentTarget.result);
  //         this.originalImages.push(_event.currentTarget.result);
  //         this.filesList.push(file);
  //       };
  //       this.names.push(file.name);
  //     } else if (file.type == "application/pdf" ) {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = (_event: any) => {
  //         this.imgURL = "assets/images/adobe-pdf-icon.png";
  //         this.images.push("assets/images/adobe-pdf-icon.png");
  //         this.documentUploaded = 'pdf';
  //         this.filesList.push(file);
  //       }
  //     } else {
  //       Swal.fire({
  //         text: 'File type not allowed here!',
  //         icon: 'warning',
  //         showCancelButton: false,

  //       });
  //       event.srcElement.value = null;
  //     }
  //   }

  // }

  onSubmit() {
    this.setEveryChildComponentUploadingDocuments()
    this.otherSelectedPaths = this.selectPaths;
    console.log(this.otherSelectedPaths)
    if(this.otherSelectedPaths[0].length === 0){
      this.notifier.notify('error', 'Please upload Documents.');
    }else{
      this.selectPathsCollection.push(this.otherSelectedPaths[0])
      console.log(this.selectPathsCollection)
      this.valuationRequestService.uploadPaymentDocuments(this.selectPathsCollection, this.route.snapshot.paramMap.get('id')).subscribe(
        data => {
          this.deedDetailModel.valuationRequestId = this.route.snapshot.paramMap.get('id');
          this.deedDetailModel.stampDutyAmount = this.stampDuty.toString();
          this.valuationRequestService.valuationRequestDeedDetail(this.deedDetailModel).subscribe(
            data => {
              this.notifier.notify('success', 'Submitted Successfully');
              this.router.navigate(['dashboard-internal-user']);

              // **** SEND NOTIFICATIONS - START ****
              this.valuationRequestService.findByValuationRequestId(+this.route.snapshot.paramMap.get('id')).subscribe(
                data => {
                  this.deedNatureValue = data.data.deedNature;

                  this.assessorUserId = data.data.accessorId;
                  this.dComUserId = data.data.dcommissionerId;
                  this.comUserId = data.data.commissionerId;
                  this.notaryUserId = data.data.user_id;

                  if (this.currentUserRoleCode == Constant.ROLE_ACCESSOR) {
                    this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
                    this.notificationModel.deedNature = this.deedNatureValue;
                    this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                    this.notificationModel.subject = Constant.PAYMENT_CONFIRMED_BY_ASSESSOR;
                    this.notificationModel.userId = this.comUserId;
                    this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                    this.notificationService.createNotification(this.notificationModel).subscribe(
                      data => {
                        console.log(data);

                        this.notificationModel.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
                        this.notificationModel.deedNature = this.deedNatureValue;
                        this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                        this.notificationModel.subject = Constant.PAYMENT_CONFIRMED_BY_ASSESSOR;
                        this.notificationModel.userId = this.dComUserId;
                        this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                        this.notificationService.createNotification(this.notificationModel).subscribe(
                          data => {
                            console.log(data);

                            this.notificationModel.userRoleCode = Constant.ROLE_NOTARY;
                            this.notificationModel.deedNature = this.deedNatureValue;
                            this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                            this.notificationModel.subject = Constant.PAYMENT_CONFIRMED_BY_ASSESSOR;
                            this.notificationModel.userId = this.notaryUserId;
                            this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                            this.notificationService.createNotification(this.notificationModel).subscribe(
                              data => {
                                console.log(data);

                                this.notificationEmailSendModel.valuationRequestId = +this.route.snapshot.paramMap.get('id');
                                this.notificationService.sendEmailNotification(this.notificationEmailSendModel).subscribe(
                                  data => {
                                    console.log(data);
                                  }
                                )

                              }
                             )
                           }
                         )
                       }
                     )
                   }
                 }
               )
               // **** SEND NOTIFICATIONS - END ***
             }, err => {
               this.notifier.notify('error', 'Error occurred while processing. Please try again.');
             }
           )
         }, err => {
           this.notifier.notify('error', 'Error occurred while processing. Please try again.');
         }
       )
     }
  }

  paymentValid() {
    this.notifier.notify('error', 'Fill in the all the required fields before submitting.');
  }

  loadPaymentData() {
    const valuationRequestId = +this.route.snapshot.paramMap.get('id');
    (this.valuationRequestService.findByValuationRequestId(valuationRequestId)
      .subscribe((res) => {
        this.isComStatusReject = res.data.commissionerStatus === Constant.VALUATION_REQUEST_REJECTED_CODE
        this.paymentList = res.data.payment;
        if(this.paymentList[0].transactionStatus === 'SUCCEED'){
          this.donePayment = true;
        }else{
          this.donePayment = false;
        }

      }));
    // (this.valuationRequestService.getPaymentDetailsWithoutNonRefundable(valuationRequestId).subscribe(res=>{
    //   this.paymentList = res.data;

    //   console.log(this.paymentList)
    // }));
  }

  getStampDutyTotal(){
    let total = new Array<number>();
    for(let i = 0 ; i<this.paymentList.length; i++){
      if(i<this.paymentList.length){
        total.push(parseFloat(this.paymentList[i].amount))
      }
    }
    total.shift();
    let sum = total.reduce((a,b)=>a+b,0);
    return sum;
  }


  /* REJECT APPLICATION - START */
  openRejectPopUp() {
    this.showDynamicGrid = true;
    this.openPopupMessage(this.enterRejectRemark);
  }

  rejectConfirmation(){
    Swal.fire({
      title: 'Are you sure?',
      text: "Do You Want To Reject This Opinion?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Reject It!',
      cancelButtonText: 'No, Decline!'
    }).then((result) => {
      if (result.value) {
        // Swal.fire(
        //   'Rejected!',
        //   'Opinion has been rejected.',
        //   'success'
        // )
        this.openRejectPopUp();
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        // Swal.fire(
        //   'Declined',
        //   'Opinion Reject Declined',
        //   'success'
        // )
        // console.log('dec')
        this.rejectDecline();
      }
    })
  }

  rejectDecline(){
    this.valuationRequestService.declineReject(parseInt(this.route.snapshot.paramMap.get('id'))).subscribe(data=>{
      if(data.data == true){
        this.router.navigate(['dashboard-internal-user']);
        this.notifier.notify('success', 'Declined Successfully');

        // **** SEND NOTIFICATIONS - START ****
        this.valuationRequestService.findByValuationRequestId(+this.route.snapshot.paramMap.get('id')).subscribe(
          data => {
            this.deedNatureValue = data.data.deedNature;

            this.assessorUserId = data.data.accessorId;
            this.dComUserId = data.data.dcommissionerId;
            this.comUserId = data.data.commissionerId;
            this.notaryUserId = data.data.user_id;

            this.notificationModel.userRoleCode = Constant.ROLE_ACCESSOR;
            this.notificationModel.deedNature = this.deedNatureValue;
            this.notificationModel.url = this.route.snapshot.paramMap.get('id');
            this.notificationModel.subject = "Declined Rejected";
            this.notificationModel.userId = this.assessorUserId;
            this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

            this.notificationService.createNotification(this.notificationModel).subscribe(
              data => {
                this.notificationModel.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
                this.notificationModel.deedNature = this.deedNatureValue;
                this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                this.notificationModel.subject = "Declined Rejected";
                this.notificationModel.userId = this.dComUserId;
                this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                this.notificationService.createNotification(this.notificationModel).subscribe();
              })
          })
          // **** SEND NOTIFICATIONS - END ****
      }else{
        this.notifier.notify('error', 'Application not found');
      }

    });
  }

  rejectValuationRequest() {
    this.rejectModel.valuationId = this.route.snapshot.paramMap.get('id');
    this.userName = this.userdata.firstName + " " + this.userdata.lastName;
    this.rejectModel.userName = this.userName;
    this.rejectModel.userRole = this.currentUserRoleCode;
    this.rejectModel.remark = this.applicationRejectRemarkContent;
    this.rejectModel.furtherRequirementPosition = "PAYMENT";
    this.rejectModel.uploadedFile = this.rejectFile;

    this.valuationRequestService.rejectApplication(this.rejectModel).subscribe(
      data => {
        this.router.navigate(['dashboard-internal-user']);
        this.notifier.notify('success', 'Application Rejected');

        // **** SEND NOTIFICATIONS - START ****
        this.valuationRequestService.findByValuationRequestId(+this.route.snapshot.paramMap.get('id')).subscribe(
          data => {
            this.deedNatureValue = data.data.deedNature;
            this.assessorUserId = data.data.accessorId;
            this.dComUserId = data.data.dcommissionerId;
            this.comUserId = data.data.commissionerId;
            this.notaryUserId = data.data.user_id;


              if (this.currentUserRoleCode == Constant.ROLE_ACCESSOR) {
                this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
                this.notificationModel.deedNature = this.deedNatureValue;
                this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                this.notificationModel.subject = Constant.APPLICATION_REJECTED;
                this.notificationModel.userId = this.comUserId;
                this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                this.notificationService.createNotification(this.notificationModel).subscribe(
                  data => {
                    console.log(data);

                    //// *** SEND NOTIFICATION TO DEP. COMMISSIONER - START ***
                    // this.notificationModel.userRoleCode = Constant.ROLE_ACCESSOR;
                    // this.notificationModel.deedNature = this.deedNatureValue;
                    // this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                    // this.notificationModel.dataStatus = "ACTIVE";

                    // this.valuationRequestService.createNotification(this.notificationModel).subscribe(
                    //   data => {
                    //     console.log(data);
                    //   }
                    // )
                    //// *** SEND NOTIFICATION TO DEP. COMMISSIONER - END ***

                  }
                )
              } else if (this.currentUserRoleCode == Constant.ROLE_DEPUTY_COMMISSIONER) {
                this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
                this.notificationModel.deedNature = this.deedNatureValue;
                this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                this.notificationModel.subject = Constant.APPLICATION_REJECTED;
                this.notificationModel.userId = this.comUserId;
                this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                this.notificationService.createNotification(this.notificationModel).subscribe(
                  data => {
                    console.log(data);
                  }
                )
              } else if (this.currentUserRoleCode == Constant.ROLE_COMMISSIONER) {
                this.notificationModel.userRoleCode = Constant.ROLE_NOTARY;
                this.notificationModel.deedNature = this.deedNatureValue;
                this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                this.notificationModel.subject = Constant.APPLICATION_REJECTED;
                this.notificationModel.userId = this.notaryUserId;
                this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                this.notificationService.createNotification(this.notificationModel).subscribe(
                  data => {
                    console.log(data);
                    this.notificationEmailSendModel.valuationRequestId = +this.route.snapshot.paramMap.get('id');
                    this.notificationService.sendEmailNotification(this.notificationEmailSendModel).subscribe(
                      data => {
                        console.log(data);
                      }
                    )
                  }
                )
              }
            }
          )
          // **** SEND NOTIFICATIONS - END ****

      }
    )

    this.modalService.dismissAll();
  }

  getRejectRemark() {
    return this.rejectForm.get('remarkTextarea').value;
  }

  uploadChangeReject(event) {
    this.files = event.target.files;
    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      if (file.type === "image/png" || file.type === "image/jpeg") {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          this.imgURL2 = reader.result;
          this.documentUploaded1 = 'image';
          this.images.push(_event.currentTarget.result);
          this.originalImages.push(_event.currentTarget.result);
          this.rejectFile = file;
        };
        this.names.push(file.name);
      } else if (file.type == "application/pdf" ) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          this.imgURL2 = "assets/images/adobe-pdf-icon.png";
          this.documentUploaded1 = 'pdf';
          this.rejectFile = file;
        }
      } else {
        this.notifier.notify('error', 'File type not allowed here');
        event.srcElement.value = null;
      }
    }
  }
  /* REJECT APPLICATION - END */

  /* REQUEST FURTHER REQUIREMENT - START */
  furtherReqValuationRequest() {
    this.showDynamicGrid = true;
    this.openPopupMessage(this.enterRemark);
  }

  uploadChangeFurtherRequirement(event) {
    this.files = event.target.files;
    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      if (file.type === "image/png" || file.type === "image/jpeg") {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          this.imgURL = reader.result;
          this.documentUploaded = 'image';
          this.images.push(_event.currentTarget.result);
          this.originalImages.push(_event.currentTarget.result);
          this.furtherRequirementFile = file;
        };
        this.names.push(file.name);
      } else if (file.type == "application/pdf" ) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          this.imgURL = "assets/images/adobe-pdf-icon.png";
          this.images.push("assets/images/adobe-pdf-icon.png");
          this.documentUploaded = 'pdf';
          this.furtherRequirementFile = file;
        }
      } else {
        this.notifier.notify('error', 'File type not allowed');
        event.srcElement.value = null;
      }
    }
  }

  requestFurtherReqRemark() {
    this.furtherRequirementModel.valuationId = this.route.snapshot.paramMap.get('id');
    this.userName = this.userdata.firstName + " " + this.userdata.lastName;
    this.furtherRequirementModel.userName = this.userName;
    this.furtherRequirementModel.userRole = this.currentUserRoleCode;
    this.furtherRequirementModel.remark = this.furtherRequirementRemarkContent;
    this.furtherRequirementModel.furtherRequirementPosition = "PAYMENT";
    this.furtherRequirementModel.uploadedFile = this.furtherRequirementFile;


    this.valuationRequestService.requestFurtherRequirement(this.furtherRequirementModel).subscribe(
      data => {
        console.log(data);
        if (data.data === true) {

          // **** SEND NOTIFICATIONS - START ****
          this.valuationRequestService.findByValuationRequestId(+this.route.snapshot.paramMap.get('id')).subscribe(
            data => {
              this.deedNatureValue = data.data.deedNature;
              this.assessorUserId = data.data.accessorId;
              this.dComUserId = data.data.dcommissionerId;
              this.comUserId = data.data.commissionerId;
              this.notaryUserId = data.data.user_id;

              // ** SEND SYSTEM NOTIFICATION TO NOTARY - START **
              this.notificationModel.userRoleCode = Constant.ROLE_NOTARY;
              this.notificationModel.deedNature = this.deedNatureValue;
              this.notificationModel.url = this.route.snapshot.paramMap.get('id');
              this.notificationModel.subject = Constant.REQUESTED_FOR_FURTHER_REQUIREMENT;
              this.notificationModel.userId = this.notaryUserId;
              this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

              this.notificationService.createNotification(this.notificationModel).subscribe(
                data => {
                  console.log(data);
                }
              )
              // ** SEND SYSTEM NOTIFICATION TO NOTARY - END **

              if (this.currentUserRoleCode == Constant.ROLE_ACCESSOR) {
                this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
                this.notificationModel.deedNature = this.deedNatureValue;
                this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                this.notificationModel.subject = Constant.REQUESTED_FOR_FURTHER_REQUIREMENT;
                this.notificationModel.userId = this.comUserId;
                this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                this.notificationService.createNotification(this.notificationModel).subscribe(
                  data => {
                    console.log(data);

                    // *** SEND SYSTEM NOTIFICATION TO DEP. COMMISSIONER - START ***
                    this.notificationModel.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
                    this.notificationModel.deedNature = this.deedNatureValue;
                    this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                    this.notificationModel.subject = Constant.REQUESTED_FOR_FURTHER_REQUIREMENT;
                    this.notificationModel.userId = this.dComUserId;
                    this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                    this.notificationService.createNotification(this.notificationModel).subscribe(
                      data => {
                        console.log(data);
                      }
                    )
                    // *** SEND SYSTEM NOTIFICATION TO DEP. COMMISSIONER - END ***

                  }
                )
              } else if (this.currentUserRoleCode == Constant.ROLE_DEPUTY_COMMISSIONER) {
                this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
                this.notificationModel.deedNature = this.deedNatureValue;
                this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                this.notificationModel.subject = Constant.REQUESTED_FOR_FURTHER_REQUIREMENT;
                this.notificationModel.userId = this.comUserId;
                this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                this.notificationService.createNotification(this.notificationModel).subscribe(
                  data => {
                    console.log(data);
                  }
                )
              }
            }
          )
          // **** SEND NOTIFICATIONS - END ****

        }
      }
    )
    this.notifier.notify('success', 'Successfully sent for Further Requirement');
    this.router.navigate(['/dashboard-internal-user']);

    this.modalService.dismissAll();
  }
  /* REQUEST FURTHER REQUIREMENT - END */

  closeModal() {
    this.modalService.dismissAll();
  }

  initializePayment() {
    this.selectedPayment = this.payment[this.payment.length - 1];
    // this.selectedPayment['paidDate'] = new Date().toLocaleDateString();
  }

  previewFile(type, name) {
    // this.fileService.loadFileById(this.typeID, type).subscribe(result => {
    //   if (result) {
    //     let temp = [];
    //     temp.push(result.data);
    //     this.previewFileData = temp;
    //   }
    // });

    this.getReciptModel.fileType = type;
    this.getReciptModel.id = this.typeID;
    this.getReciptModel.filename = name;
    this.fileService.loadFileByIdAndName(this.getReciptModel).subscribe(result => {
      if (result) {
        let temp = [];
        temp.push(result.data);
        this.previewFileData = temp;
      }
    });
  }

  loadPaymentReceipt() {
    this.fileService.loadFileById(this.typeID, Constant.PAYMENT_REPORT_STATUS).subscribe(result => {
      if (result) {
        let temp = [];
        temp.push(result.data);
        console.log(result.data)
        this.previewFileDataList = temp;
      }
    });
  }

  previewFileDeedDocument(type,name){
    //     this.fileService.loadFileById(this.typeID, type).subscribe(result => {
    //   if (result) {
    //     let temp = [];
    //     temp.push(result.data);
    //     this.previewDeedFileData = temp;
    //   }
    // });
    this.getReciptModel.fileType = type;
    this.getReciptModel.id = this.typeID;
    this.getReciptModel.filename = name;
    this.fileService.loadFileByIdAndName(this.getReciptModel).subscribe(result => {
      if (result) {
        let temp = [];
        temp.push(result.data);
        this.previewFileData = temp;
      }
    });
  }


  loadFileDeedDocument(){
    this.fileService.loadFileById(this.typeID, Constant.DEED_DOCUMENT_STATUS).subscribe(result => {
  if (result) {
    let temp = [];
    temp.push(result.data);
    this.previewDeedFileData = temp;
  }
});
}




  changePayment(payment: IPayment) {
    this.selectedPayment = payment;
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }

  paymentVerification(event, payment) {
    if (event.target.checked === true) {
      this.donePayment = true;
      this.modelupdate.paymentId = payment['id'];
      this.modelupdate.paymentStatus = Constant.PAYMENT_STATUS_SUCCEED;
      this.paymentService.updatePaymentStatus(this.modelupdate).subscribe(
        data => {
          if (data.data) {
            const valuationRequestId = +this.route.snapshot.paramMap.get('id');
            (this.valuationRequestService.findByValuationRequestId(valuationRequestId)
              .subscribe((res) => {
                this.valuationRequest = res.data;
                sessionStorage.setItem('valuationRequest', JSON.stringify(this.valuationRequest));
                this.valuationRequest.id = valuationRequestId;
              }));
          }
        }
      )
    } else if (event.target.checked === false) {
      this.donePayment = false;
      this.modelupdate.paymentId = payment['id'];
      this.modelupdate.paymentStatus = Constant.PAYMENT_STATUS_PENDING;
      this.paymentService.updatePaymentStatus(this.modelupdate).subscribe(
        data => {
          if (data.data) {
            const valuationRequestId = +this.route.snapshot.paramMap.get('id');
            (this.valuationRequestService.findByValuationRequestId(valuationRequestId)
              .subscribe((res) => {
                this.valuationRequest = res.data;
                sessionStorage.setItem('valuationRequest', JSON.stringify(this.valuationRequest));
                this.valuationRequest.id = valuationRequestId;
              }));
          }
        }
      )
    }
    this.checkStatus(true);
  }

  setEveryChildComponentUploadingDocuments() {
    this.documentUploadDeedDetails.outputDocumentsDeedDetailsSet();
  }

  setDeedDetails(documents: File[]) {
    this.selectPaths.push(documents);
  }



}
