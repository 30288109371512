export class ValuationRequestList {
  userId: string;
  valuationStatus: string;
  userType: string;
  referenceNumber: string;
  offset: string;
  limit: string;

  constructor(
    userId: string,
    valuationStatus: string,
    userType: string,
    referenceNumber: string,
    offset: string,
    limit: string
  ) {
    this.userId = userId;
    this.valuationStatus = valuationStatus;
    this.userType = userType;
    this.referenceNumber = referenceNumber;
    this.offset = offset;
    this.limit = limit;
  }
}
