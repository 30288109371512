import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PaymentMethodService } from 'src/app/service/payment-method/payment-method.service';
import { PaymentMethod } from 'src/app/model/payment-method';
import { SubSink } from 'subsink';
import { DataService } from 'src/app/service/data.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-add-payment-method',
  templateUrl: './add-payment-method.component.html',
  styleUrls: ['./add-payment-method.component.scss']
})
export class AddPaymentMethodComponent implements OnInit, OnDestroy {

  addPaymentMethodForm: FormGroup;

  private sub = new SubSink();

  constructor(private formBuilder: FormBuilder,
              private paymentMethodService: PaymentMethodService,
              private dataService: DataService,
              private notifier: NotifierService) { }

  ngOnInit() {
    this.addPaymentMethodForm = this.formBuilder.group({
      code: ['', [Validators.required]],
      paymentMethod: ['', [Validators.required]]
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  savePaymentMethod() {
    if (this.addPaymentMethodForm.valid) {

      const paymentMethod = new PaymentMethod();

      paymentMethod.paymentMethod = this.getDescription();
      console.log('des' + paymentMethod.paymentMethod);
      paymentMethod.code = this.getCode();
      this.sub.add(this.paymentMethodService.createPaymentMethod(paymentMethod)
        .subscribe(response => {
          this.dataService.updateTablePaymentMethod(true);
          this.notifier.notify('success', (response as any).message);
        }));
    }
  }

  getCode() {
    return this.addPaymentMethodForm.get('code').value;
  }

  getDescription() {
    return this.addPaymentMethodForm.get('paymentMethod').value;
  }

}








