export class collectionDetails{
  stampDuty:number;
  collectedAmmount:number;
  collectionNumber:string;
  istallmentCount:number;

  constructor(
    stampDuty:number,
    collectedAmmount:number,
    collectionNumber:string,
    istallmentCount:number,
  ){
    this.stampDuty=stampDuty;
    this.collectedAmmount = collectedAmmount;
    this.collectionNumber = collectionNumber;
    this.istallmentCount = istallmentCount;

  }
}
