import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from 'src/app/service/dashboard/dashboard.service';

@Component({
  selector: "app-view-refund-application",
  templateUrl: "./view-refund-application.component.html",
  styleUrls: ["./view-refund-application.component.scss"],
})
export class ViewRefundApplicationComponent implements OnInit {
  selectValRequest: string;
  pendingCount: number;
  paymentPendingCount: number;
  completedCount: number;
  rejectedCount: number;
  allRequestCount: number;
  draftCount: number;
  furtherRequirementCount: number;

  type: string;

  constructor(
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      console.log(params);
      if (params.get("type") === "all-request") {
        this.type = "pending-requests";
      } else {
        this.type = params.get("type");
      }
      this.selectValRequest = this.type;
    });
    this.draftCountChange();
    this.allCountChange();
    this.pendingCountChange();
    this.completedCountChange();
    this.rejectedCountChange();
    this.paymentPendingCountChange();
    this.furtherRequirementCountChange();
  }

  allCountChange() {
    this.dashboardService
      .getRefundRequestCount("all", "EXTERNAL")
      .subscribe((value) => {
        this.allRequestCount = value;
      });
  }
  pendingCountChange() {
    this.dashboardService
      .getRefundRequestCount("NEW", "EXTERNAL")
      .subscribe((value) => {
        this.pendingCount = value;
      });
  }
  paymentPendingCountChange() {
    this.dashboardService
      .getRefundRequestCount("PAYPEND", "EXTERNAL")
      .subscribe((value) => {
        this.paymentPendingCount = value;
      });
  }
  completedCountChange() {
    this.dashboardService
      .getRefundRequestCount("APPLICATION_COMPLETED", "EXTERNAL")
      .subscribe((value) => {
        this.completedCount = value;
      });
  }
  rejectedCountChange() {
    this.dashboardService
      .getRefundRequestCount("REJECTED", "EXTERNAL")
      .subscribe((value) => {
        this.rejectedCount = value;
      });
  }

  furtherRequirementCountChange() {
    this.dashboardService
      .getRefundRequestCount("FURTHER_REQUIREMENT", "EXTERNAL")
      .subscribe((value) => {
        this.furtherRequirementCount = value;
      });
  }

  draftCountChange() {
    this.dashboardService
      .getRefundRequestCount("DRAFT", "EXTERNAL")
      .subscribe((value) => {
        this.draftCount = value;
      });
  }

  // toPendingList() {
  //   this.type = 'PENDING';
  //   this.router.navigate(['/view-assessment/pending-requests']);
  // }
  //
  // toCompleteList() {
  //   this.type = 'COMPLETED';
  //   this.router.navigate(['/view-assessment/completed-requests']);
  // }
  //
  // toRejectList() {
  //   this.type = 'REJECTED';
  //   this.router.navigate(['/view-assessment/rejected-requests']);
  // }
}
