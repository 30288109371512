export class ValuationReject {
  valuationId: string;
  userName: string;
  userRole: string;
  type:string;
  remark:string;

  constructor(
    valuationId: string,
    userName: string,
    userRole: string,
    type:string,
    remark:string,
  ) {
    this.valuationId = valuationId;
    this.userName = userName;
    this.userRole = userRole;
    this.type = type;
    this.remark = remark;
  }
}
