import { Component, Input, OnInit, EventEmitter, Output, ViewChild, TemplateRef } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { PDFProgressData } from 'ng2-pdf-viewer';

import { IGranter } from '../../../../../../../model/interfaces/granter';
import { FileService } from 'src/app/service/file/file.service';
import { ValuationStatus } from 'src/app/model/valuation-status';
import { GranterService } from 'src/app/service/granter/granter.service';
import { ValuationRequestService } from 'src/app/service/valuation-request/valuation-request.service';
import { ProvinceService } from 'src/app/service/province/province.service';
import { Constant } from 'src/app/util/constant';
import { CompanyTypeService } from 'src/app/service/company-type/company-type.service';
import { NotificationModel } from 'src/app/model/notification-model';
import { NotificationService } from 'src/app/service/notification/notification.service';
import { NotificationEmailSend } from 'src/app/model/notification-email-send';
import { ValuationRequestUpdate } from 'src/app/model/valuation-request-update';
import { GranteeService } from 'src/app/service/grantee/grantee.service';
import { SubSink } from 'subsink';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-view-grantor-iu',
  templateUrl: './view-grantor-iu.component.html',
  styleUrls: ['./view-grantor-iu.component.scss']
})
export class ViewGrantorInternalUserComponent implements OnInit {
  getGranteeStatusChange:Subscription;
  private sub = new SubSink();

  @Input()
  granters: IGranter[];

  @Input()
  valuationRequestId: number;

  @Input()
  party:string;

  @Input()
  granteeId:string;

  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() status1: EventEmitter<any> = new EventEmitter<any>();

  granterId: string;
  verificationStatus: number;
  initialviewaccess: boolean;
  thirdviewaccess1: boolean;
  deedNatureValue: any;
  currentUserRoleCode: string;

  public modelStatus = new ValuationStatus('', '');
  public notificationModel = new NotificationModel("", "", "", "", "", "", "");
;
  public notificationEmailSendModel = new NotificationEmailSend();
  public valuationRequestUpdateModel = new ValuationRequestUpdate('', '', '', '', '', '');

  selectFileSm = 0;
  typeID: any;
  previewFileData: any;
  type: any;
  userdata: any = [];
  files;
  images = [];
  originalImages: any[] = [];
  filesList: File[] = [];
  names: string[] = [];
  noDocs = false;
  dataDone = false;
  public imgURL: any;
  public imgURL1: any;
  public documentUploaded: string;
  public documentUploaded1: string;

  public fileNumberFormat: string;
  public generatedFileNumber: string;
  valuationdata: any = [];

  public provincelist: any = [];
  public companytypelist: any = [];
  public provinceDescription: string;
  public companyTypeDescription: string;
  isExchangeDeedType: boolean;
  party1: boolean;
  party2: boolean;

  exchangeDisable:boolean;
  status: number;
  granteeStatus: boolean;

  fileNumberFormatAvailable: boolean = true;
  
  constructor(
    private fileService: FileService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public granterService: GranterService,
    public granteeService: GranteeService,
    public valuationRequestService: ValuationRequestService,
    public router: Router,
    public provinceService: ProvinceService,
    private notifier: NotifierService,
    private companyTypeService: CompanyTypeService,
    public notificationService: NotificationService,
    public modalService: NgbModal,
    private spinner: NgxSpinnerService
  ) {
    this.initialviewaccess = true;
    this.thirdviewaccess1 = false;
    // this.exchangeDisable = true;
    this.granteeStatus = false;
    this.getGranteeStatusChange = this.granteeService.getGranteeStatusChange().subscribe(()=>{
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this._prepare();
    window.scroll(0,0);
    this.isExchangeDeedType = sessionStorage.getItem('deedType')==='exchange';
    this.party1 = this.party === "party1"
    this.party2 = this.party === "party2"
    this.getGranteeStatus();
  }

  _prepare() {
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));

    this.fileNumberFormat = localStorage.getItem('fileNumberFormat');
    if (this.fileNumberFormat == "null" || this.fileNumberFormat == '') {
      this.fileNumberFormatAvailable = false;
    }

    this.granterId = this.granters[0]['id'];
    if(sessionStorage.getItem('deedType')==='exchange'){
      this.typeID = +this.valuationRequestId
      // this.exchangeDisable = this.getExchangeDisableStatus()
    }else{
      this.typeID = +this.route.snapshot.paramMap.get('id');
    }
    this.getVerificationStatus();
    this.valuationdata = JSON.parse(sessionStorage.getItem('valuationRequest'));
    this.pad(this.valuationdata.id, 7);
    this.loadProvinceList();
    this.loadCompanyTypeList();
    this.checkPermission();
    this.retrieveAssignedValuationRequest();
  }

  checkPermission() {
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.currentUserRoleCode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.currentUserRoleCode = Constant.ROLE_ACCESSOR;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER){
        this.currentUserRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.currentUserRoleCode = Constant.ROLE_COMMISSIONER;
        break;
      }
    }
  }

  retrieveAssignedValuationRequest() {
    this.valuationRequestService.valuationRequestAssigned(this.route.snapshot.paramMap.get('id')).subscribe(
      data => {
        if (data.data !== null) {
          this.valuationRequestUpdateModel.dcommissionerId = data.data.dcommissionerId;
          this.valuationRequestUpdateModel.acccessorId = data.data.accessorId;
          this.valuationRequestUpdateModel.remark = data.data.remark;
        }
      }
    )
  }

  loadProvinceList() {
    this.provinceService.getAll().subscribe(
      data => {
        this.provincelist = data.data;

      }
    )
  }

  loadCompanyTypeList() {
    this.companyTypeService.getAll().subscribe(
      data => {
        this.companytypelist = data.data;
      }
    )
  }

  changeSelectedProvince(province): any {
    const filtereddata = this.provincelist.filter(i => i.id === Number(province));
    if (JSON.stringify(filtereddata) !== '[]') {
      this.provinceDescription = filtereddata[0]['description'];
      return this.provinceDescription;
    }
  }

  changeSelectedCompanyType(company): any {
    const filtereddata = this.companytypelist.filter(i => i.id === Number(company));
    if (JSON.stringify(filtereddata) !== '[]') {
      this.companyTypeDescription = filtereddata[0]['name'];
      return this.companyTypeDescription;
    }
  }

  pad(num: number, size: number) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    this.generatedFileNumber = s;
    this.generatedFileNumber = '#VR' + this.generatedFileNumber;
  }

  public redirectComponent(): void {
    this.onPageCountChanged.emit(4);
  }

  public redirectComponentExchange(): void {
    this.onPageCountChanged.emit(3);
  }


  public redirectPreviousComponent(): void {
    this.onPageCountChanged.emit(2);
  }

  getGranteeStatus(){
    this.modelStatus.id = this.granteeId;
    this.sub.add(this.granteeService.granteeVerificationStatus(this.modelStatus).subscribe(
      data => {
        this.status = data.data;
      }
    ))
    // if(this.status === 0){
    //   return false;
    // }else{
    //   return true;
    // }
  }

  // getExchangeGranterStatus():boolean{
  //   this.modelStatus.id = this.granterId;
  //   this.granterService.granterVerificationStatus(this.modelStatus).subscribe(
  //     data => {
  //       this.status = data.data;
  //     }
  //   )
  //   if(this.status === 1){
  //     return false;
  //   }else{
  //     return true;
  //   }
  // }

  // getExchangeDisableStatus():boolean{
  //   var ex1 = this.getExchangeGranterStatus();
  //   var ex2 = this.getGranteeStatus();
  //   if(ex1 == 1 && ex2 == 1){
  //     return false;
  //   }else{
  //     return true;
  //   }
  // }

  getVerificationStatus() {
    this.modelStatus.id = this.granterId;
    this.granterService.granterVerificationStatus(this.modelStatus).subscribe(
      data => {
        this.verificationStatus = data.data;
      }
    )
  }

  updateVerificationStatus(event) {
    this.modelStatus.id = this.granterId;
    if (event.target.checked === true) {
      this.modelStatus.verificationStatus = '1';
      this.granterService.granterUpdateVerificationStatus(this.modelStatus).subscribe(
        data => {
          this.getVerificationStatus();
          // this.granteeStatus = this.getGranteeStatus();
          if(this.isExchangeDeedType){
            this.getGranteeStatus();
            this.sendGranterStatusChange()
          }

          // console.log('condition:',this.granteeStatus && this.verificationStatus === 0)
          // console.log('verification: ', this.verificationStatus === 0)
          // console.log('grantee Status:',this.granteeStatus)
          // if(this.isExchangeDeedType){
          //   this.exchangeDisable = this.getExchangeDisableStatus();
          //   console.log(this.exchangeDisable)
          // }
          // this._prepare();
        }
      )
    } else {
      this.modelStatus.verificationStatus = '0';
      this.granterService.granterUpdateVerificationStatus(this.modelStatus).subscribe(
        data => {
          this.getVerificationStatus();
          if(this.isExchangeDeedType){
            this.getGranteeStatus();
            this.sendGranterStatusChange()
          }
          // this.granteeStatus = this.getGranteeStatus();
          // console.log('condition:',this.granteeStatus && this.verificationStatus === 0)
          // console.log('verification: ', this.verificationStatus === 0)
          // console.log('grantee Status:',this.granteeStatus)
          // if(this.isExchangeDeedType){
          //   this.exchangeDisable = this.getExchangeDisableStatus();
          //   console.log(this.exchangeDisable)
          // }
          // this._prepare();
        }
      )
    }
    this.checkStatus(true)
  }

  sendGranterStatusChange(){
    this.granterService.sendGranterStatusChange();
  }

  initialPageCompleted() {
    this.initialviewaccess = false;
    this.thirdviewaccess1 = true;
  }

  secondPageCompleted() {
    this.initialviewaccess = false;
    this.thirdviewaccess1 = true;
  }

  thirdPageCompleted() {
    this.initialviewaccess = false;
    this.thirdviewaccess1 = true;
  }

  routeBack() {
    if (this.thirdviewaccess1) {
      this.initialviewaccess = true;
      this.thirdviewaccess1 = false;
    } else {
      this.initialviewaccess = true;
      this.thirdviewaccess1 = false;
    }
  }

  routeBackCompanyEmpty() {
    this.initialviewaccess = true;
    this.thirdviewaccess1 = false;
  }

  onProgress(progressData: PDFProgressData) {
    if (progressData.loaded == progressData.total) {
      this.spinner.hide();
    }
  }

  previewFile1(type) {
    this.spinner.show();
    this.fileService.loadFileById(this.typeID, type).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(()=>{
          this.spinner.hide();
        }, 2000);

      }else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public checkStatus(type:boolean): void{
    this.status1.emit(type);
  }

}
