import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { NgForm } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";

import { ProvinceService } from "src/app/service/province/province.service";
import { DistrictService } from "src/app/service/district/district.service";
import { Constant } from "src/app/util/constant";
import { FileService } from "src/app/service/file/file.service";
import { UserService } from "src/app/service/user/user.service";
import { ValuationRequestUpdate } from "src/app/model/valuation-request-update";
import { ValuationRequestService } from "src/app/service/valuation-request/valuation-request.service";
import { NotifierService } from "angular-notifier";
import { NotificationModel } from "src/app/model/notification-model";
import { ValuationRequest } from "src/app/model/valuation-request";
import { NotificationService } from "src/app/service/notification/notification.service";
import { PropertyHistoryModel } from "src/app/model/propertyHistory";
import { Property } from "src/app/model/property";
import { SurveyModel } from "src/app/model/survery";

@Component({
  selector: "app-assign-accessor",
  templateUrl: "./assign-accessor.component.html",
  styleUrls: ["./assign-accessor.component.scss"],
})
export class AssignAccessorComponent implements OnInit {
  @ViewChild("f", null)
  f: NgForm;

  provincelist: any;
  districtlist: any;
  provinceselectedstatus: boolean;
  userdata: any = [];
  valuationdata: any = [];
  dcommissionpermissiongranted: boolean;
  accessorpermissiongranted: boolean;
  dcommissionerSelectedStatus: boolean;
  isAssessorAsign: boolean;
  isDcommisioner: boolean;

  userlist: any = [];
  dcommissionerlist: any = [];
  accessorlist: any = [];
  filteredcommissionerlist: any = [];
  filteredaccessorlist: any = [];

  selectFileSm = 0;
  typeID: any;
  previewFileData: any;
  type: any;
  deedNatureValue: any;
  userrolecode: string;
  deputyCommissionerAllocated: boolean;
  accessorAllocated: boolean;

  public selectedProvinceCode: string;
  public selectedDistrictCode: string;

  public assignedprovinceCode: any;
  public assigneddistrictCode: any;

  currentYear: number = new Date().getFullYear();

  public model = new ValuationRequestUpdate("", "", "", "", "", "");
  public notificationModel = new NotificationModel("", "", "", "", "", "", "");
  public valuationRequest = new ValuationRequest();
  // public valuationRequestHistory = new ValuationRequest();

  public propertyModel = new PropertyHistoryModel();
  properties: Property[];

  public surveyModel = new SurveyModel("");

  // public valuationHistoryList: any = [];
  // public audittraceList: any = [];

  noDocs = false;
  dataDone = false;
  assessorUserId: string;
  dComUserId: string;
  comUserId: string;
  notaryUserId: string;

  constructor(
    public provinceService: ProvinceService,
    public districtService: DistrictService,
    private fileService: FileService,
    private route: ActivatedRoute,
    public router: Router,
    public sanitizer: DomSanitizer,
    public userService: UserService,
    public valuationRequestService: ValuationRequestService,
    private notifier: NotifierService,
    public notificationService: NotificationService,
    private spinner: NgxSpinnerService
  ) {
    this.provinceselectedstatus = true;
    this.dcommissionpermissiongranted = false;
    this.accessorpermissiongranted = false;
    this.isDcommisioner = false;
    this.userdata = JSON.parse(atob(sessionStorage.getItem("userinfo")));
    this.valuationdata = JSON.parse(sessionStorage.getItem("valuationRequest"));
    // this.valuationRequestHistory = JSON.parse(sessionStorage.getItem('valuationRequest'));
    this.dcommissionerSelectedStatus = true;
    this.deputyCommissionerAllocated = false;
    this.accessorAllocated = false;
    this.assigneddistrictCode = "undefined";
    this.model.dcommissionerId = "undefined";
    this.model.acccessorId = "undefined";
  }

  ngOnInit() {
    this._prepare();
    this.checkForDCommissioner();
    // this.properties = this.valuationRequestHistory.properties;
    // this.surveyModel.surveyPlanNo = this.properties[0]['surveyorPlanNo'];
    // this.loadPreviousValuationHistoryInfo(this.surveyModel);
    // this.loadAudittrace();
  }

  // loadAudittrace() {
  //   this.valuationRequestService.auditList(this.route.snapshot.paramMap.get('id')).subscribe(
  //     data => {
  //       this.audittraceList = data.data;
  //       console.log(this.audittraceList)
  //     }
  //   )
  // }

  // loadPreviousValuationHistoryInfo(surveyModel: SurveyModel) {
  //   this.valuationRequestService.valuationHistoryList(surveyModel).subscribe(
  //     data => {
  //       this.valuationHistoryList = data.data;
  //     }
  //   )
  // }

  _prepare() {
    this.loadProvinceList();
    this.checkPermission();
    this.typeID = this.route.snapshot.paramMap.get("id");
    this.retrievedAssignedValuationRequest();
  }

  retrievedAssignedValuationRequest() {
    this.valuationRequestService
      .valuationRequestAssigned(this.route.snapshot.paramMap.get("id"))
      .subscribe((data) => {
        if (data.data !== null) {
          this.assignedprovinceCode = data.data.provinceCode;
          this.assigneddistrictCode = data.data.districtCode;
          this.model.dcommissionerId = data.data.dcommissionerId;
          this.model.acccessorId = data.data.accessorId;
          if (this.model.acccessorId == null) {
            this.isAssessorAsign = false;
            this.dcommissionerSelectedStatus = false;
          } else {
            this.isAssessorAsign = true;
            this.dcommissionerSelectedStatus = true;
          }
          this.model.remark = data.data.remark;
          this.retrieveUserListFiltered(this.assigneddistrictCode);
          this.selectedProvince(this.assignedprovinceCode);
          this.getDistrictList(
            this.assignedprovinceCode,
            this.assigneddistrictCode
          );
          // this.selectedDistrict(this.assigneddistrictCode)
        } else {
          this.retrieveUserList();
        }
      });
  }

  // retrieveUserList() {
  //   this.userService.retrieveUserList().subscribe(
  //     data => {
  //       this.userlist = data.data;
  //       console.log(data.data);
  //       this.dcommissionerlist = this.userlist.filter(i => i['roles'][0]['code'] === Constant.ROLE_DEPUTY_COMMISSIONER);
  //       this.accessorlist = this.userlist.filter(i => i['roles'][0]['code'] === Constant.ROLE_ACCESSOR);
  //     }
  //   )
  // }
  retrieveUserList() {
    this.userService.retrieveAssessorAndDComList().subscribe((data) => {
      this.userlist = data.data;
      this.dcommissionerlist = data.data.dComList;
      this.accessorlist = data.data.assessorList;
      // this.dcommissionerlist = this.userlist.filter(
      //   (i) => i["roles"][0]["code"] === Constant.ROLE_DEPUTY_COMMISSIONER
      // );
      // this.accessorlist = this.userlist.filter(
      //   (i) => i["roles"][0]["code"] === Constant.ROLE_ACCESSOR
      // );
    });
  }

  retrieveUserListFiltered(assigneddistrictCode: any) {
    this.userService.retrieveAssessorAndDComList().subscribe((data) => {
      this.userlist = data.data;
      // this.dcommissionerlist = this.userlist.filter(
      //   (i) => i["roles"][0]["code"] === Constant.ROLE_DEPUTY_COMMISSIONER
      // );
      // this.accessorlist = this.userlist.filter(
      //   (i) => i["roles"][0]["code"] === Constant.ROLE_ACCESSOR
      // );
      this.dcommissionerlist = data.data.dComList;
      this.accessorlist = data.data.assessorList;
      this.filteredcommissionerlist = this.dcommissionerlist.filter(
        (i) => i.districtCode === assigneddistrictCode
      );
      this.filteredaccessorlist = this.accessorlist.filter(
        (i) => i.districtCode === assigneddistrictCode
      );
    });
  }

  // retrieveUserListFiltered(assigneddistrictCode: any) {
  //   this.userService.retrieveUserList().subscribe((data) => {
  //     this.userlist = data.data;
  //     this.dcommissionerlist = this.userlist.filter(
  //       (i) => i["roles"][0]["code"] === Constant.ROLE_DEPUTY_COMMISSIONER
  //     );
  //     this.accessorlist = this.userlist.filter(
  //       (i) => i["roles"][0]["code"] === Constant.ROLE_ACCESSOR
  //     );
  //     this.filteredcommissionerlist = this.dcommissionerlist.filter(
  //       (i) => i.districtCode === assigneddistrictCode
  //     );
  //     this.filteredaccessorlist = this.accessorlist.filter(
  //       (i) => i.districtCode === assigneddistrictCode
  //     );
  //   });
  // }

  checkPermission() {
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.dcommissionpermissiongranted = true;
        this.accessorpermissiongranted = true;
        this.userrolecode = Constant.ROLE_SUPER_ADMIN;
        this.isDcommisioner = false;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.dcommissionpermissiongranted = true;
        this.accessorpermissiongranted = true;
        this.userrolecode = Constant.ROLE_COMMISSIONER;
        this.isDcommisioner = false;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.dcommissionpermissiongranted = false;
        this.accessorpermissiongranted = true;
        this.userrolecode = Constant.ROLE_DEPUTY_COMMISSIONER;
        this.isDcommisioner = true;
        break;
      } else {
        this.dcommissionpermissiongranted = false;
        this.accessorpermissiongranted = false;
        this.isDcommisioner = false;
      }
    }
  }

  loadProvinceList() {
    this.provinceService.getAll().subscribe((data) => {
      this.provincelist = data.data.filter(
        (province) => province.description === "Western Province"
      );
    });
  }

  selectedProvince(event) {
    const selectedProvince = this.provincelist.filter(
      (i) => i.id === Number(event)
    );
    if (JSON.stringify(selectedProvince) !== "[]") {
      const selectedProvinceCode = selectedProvince[0]["description"];
      var provincematcher = selectedProvinceCode.match(/\b(\w)/g);
      var provinceacronym = provincematcher.join("");
      this.selectedProvinceCode = provinceacronym;
    }
    this.districtService.searchDistrictDetails(event).subscribe((data) => {
      this.provinceselectedstatus = false;
      this.districtlist = data.data;
    });
  }

  getDistrictList(d: number, b: number) {
    this.districtService.searchDistrictDetails(d).subscribe((data) => {
      this.districtlist = data.data;
      const selectedDistrict = this.districtlist.filter(
        (i) => i.id === Number(b)
      );
      if (JSON.stringify(selectedDistrict) !== "[]") {
        const selectedDistrictCode = selectedDistrict[0]["code"];
        var districtmatcher = selectedDistrictCode.match(/\b(\w)/g);
        var districtacronym = districtmatcher.join("");
        this.selectedDistrictCode = districtacronym;
      }
    });
  }

  selectedDistrict(event) {
    const selectedDistrict = this.districtlist.filter(
      (i) => i.id === Number(event)
    );
    if (JSON.stringify(selectedDistrict) !== "[]") {
      const selectedDistrictCode = selectedDistrict[0]["code"];
      var districtmatcher = selectedDistrictCode.match(/\b(\w)/g);
      var districtacronym = districtmatcher.join("");
      this.selectedDistrictCode = districtacronym;
    }
    this.filteredcommissionerlist = this.dcommissionerlist.filter(
      (i) => i.districtCode === event
    );
    this.filteredaccessorlist = this.accessorlist.filter(
      (i) => i.districtCode === event
    );
  }

  previewFile(type) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService
      .loadFileById(+this.route.snapshot.paramMap.get("id"), type)
      .subscribe(
        (result) => {
          if (result) {
            const temp = [];
            temp.push(result.data);
            this.previewFileData = result.data;
            this.noDocs = false;
            this.dataDone = true;

            setTimeout(() => {
              this.spinner.hide();
            }, 2000);
          } else {
            this.spinner.hide();
            this.dataDone = true;
            this.noDocs = true;
          }
        },
        () => {
          this.dataDone = true;
          this.noDocs = true;
          this.spinner.hide();
        }
      );
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      "data:" + type + ";base64," + base64
    );
  }

  selectedDeputyCommissioner(event) {
    this.model.dcommissionerId = event;
    this.dcommissionerSelectedStatus = false;
    this.deputyCommissionerAllocated = true;
    this.dComUserId = event;
  }

  selectedAccessor(event) {
    const selectedUser = this.filteredaccessorlist.filter(
      (i) => i.id === event
    );
    this.valuationdata = JSON.parse(sessionStorage.getItem("valuationRequest"));
    const valuationId = JSON.stringify(this.valuationdata["id"]);
    var strSequenceId = "" + valuationId;
    while (strSequenceId.length < 6) {
      strSequenceId = "0" + strSequenceId;
    }
    const referenceNumber =
      this.selectedProvinceCode +
      "/" +
      this.selectedDistrictCode +
      "/SD/OP/" +
      this.currentYear +
      "/" +
      selectedUser[0]["accessorCode"] +
      "/" +
      strSequenceId;
    this.model.referenceNumber = referenceNumber;
    this.model.acccessorId = event;
    this.accessorAllocated = true;
    this.assessorUserId = event;
  }

  onSubmit() {
    this.valuationdata = JSON.parse(sessionStorage.getItem("valuationRequest"));
    if (this.model.acccessorId == "undefined") {
      this.model.acccessorId = "";
    }
    if (this.model.dcommissionerId == "undefined") {
      this.model.dcommissionerId = "";
    }
    this.model.valuationId = JSON.stringify(this.valuationdata["id"]);
    this.model.userRole = this.userrolecode;
    //
    this.valuationRequestService
      .findByValuationRequestId(+this.route.snapshot.paramMap.get("id"))
      .subscribe((data) => {
        this.deedNatureValue = data.data.deedNature;

        console.log("Deed nature: " + this.deedNatureValue);

        if (
          this.deputyCommissionerAllocated == true &&
          this.accessorAllocated == true
        ) {
          this.notificationModel.userRoleCode =
            Constant.ROLE_DEPUTY_COMMISSIONER;
          this.notificationModel.deedNature = this.deedNatureValue;
          this.notificationModel.url = this.route.snapshot.paramMap.get("id");
          this.notificationModel.subject =
            Constant.ASSIGNED_TO_DEPUTY_COMMISSIONER_AND_ASSESSOR;
          this.notificationModel.userId = this.dComUserId;
          this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION


          this.notificationService
            .createNotification(this.notificationModel)
            .subscribe((data) => {
              console.log(data);
              this.notificationModel.userRoleCode = Constant.ROLE_ACCESSOR;
              this.notificationModel.deedNature = this.deedNatureValue;
              this.notificationModel.url =
                this.route.snapshot.paramMap.get("id");
              this.notificationModel.subject =
                Constant.ASSIGNED_TO_DEPUTY_COMMISSIONER_AND_ASSESSOR;
              this.notificationModel.userId = this.assessorUserId;
              this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION


              this.notificationService
                .createNotification(this.notificationModel)
                .subscribe((data) => {
                  console.log(data);
                });
            });
        } else if (this.accessorAllocated == true) {
          this.notificationModel.userRoleCode = Constant.ROLE_ACCESSOR;
          this.notificationModel.deedNature = this.deedNatureValue;
          this.notificationModel.url = this.route.snapshot.paramMap.get("id");
          this.notificationModel.subject =
            Constant.ASSIGNED_TO_DEPUTY_COMMISSIONER_AND_ASSESSOR;
          this.notificationModel.userId = this.assessorUserId;
          this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION


          this.notificationService
            .createNotification(this.notificationModel)
            .subscribe((data) => {
              console.log(data);
            });
        } else if (this.deputyCommissionerAllocated == true) {
          this.notificationModel.userRoleCode =
            Constant.ROLE_DEPUTY_COMMISSIONER;
          this.notificationModel.deedNature = this.deedNatureValue;
          this.notificationModel.url = this.route.snapshot.paramMap.get("id");
          this.notificationModel.subject =
            Constant.ASSIGNED_TO_DEPUTY_COMMISSIONER_AND_ASSESSOR;
          this.notificationModel.userId = this.dComUserId;
          this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION


          this.notificationService
            .createNotification(this.notificationModel)
            .subscribe((data) => {
              console.log(data);
            });
        }
      });
    this.valuationRequestService
      .valuationRequestUpdate(this.model)
      .subscribe((data) => {
        if (data.data === true) {
          this.router.navigate(["/dashboard-internal-user"]);
          this.notifier.notify("success", "Successfully Assigned");
        }
      });
  }

  checkForDCommissioner() {
    if (this.dcommissionpermissiongranted === false) {
      this.dcommissionerSelectedStatus = false;
    }
  }
}
