import {Component, OnInit, ViewChild} from '@angular/core';
import {ViewFloorTypeComponent} from './view-floor-type/view-floor-type.component';

@Component({
  selector: 'app-floor-type',
  templateUrl: './floor-type.component.html',
  styleUrls: ['./floor-type.component.scss']
})
export class FloorTypeComponent implements OnInit {
  @ViewChild(ViewFloorTypeComponent , {static: false})
  private viewFloorTypeComponent: ViewFloorTypeComponent;
  keyword: string;

  constructor() { }

  ngOnInit() {
  }

  triggerSearch(event: any) {
    if (this.keyword === '') {
      this.viewFloorTypeComponent.onUpdated(true);
    } else {
      this.viewFloorTypeComponent.searchFloorTypes(this.keyword);
    }
  }
}
