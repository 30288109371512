export class refundRejectRequest{
  userRole:string;
  refundId:number;

  constructor(
    userRole:string,
    refundId:number,
  ){
    this.userRole = userRole;
    this.refundId = refundId;
  }
}

