import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { SubSink } from 'subsink';
import { ValuationRequestService } from '../../../../../../service/valuation-request/valuation-request.service';
import FileSaver from "file-saver";
import { Router } from '@angular/router';
import { ValuationRequestList } from 'src/app/model/valuation_request_list';
import { Constant } from 'src/app/util/constant';

@Component({
  selector: 'app-draft-application-requests',
  templateUrl: './draft-application-requests.component.html',
  styleUrls: ['./draft-application-requests.component.scss']
})
export class DraftApplicationRequestsComponent implements OnInit {

  // pagination
  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];
  searchKeyword = "";
  nextPage: number;

  // valuationRequests: IValuationRequest [] = [];
  valuationRequests: any;
  @Output() completedRequestCount = new EventEmitter<number>();
  noResults: boolean = false;
  private sub = new SubSink();
  public model = new ValuationRequestList('', '', '', '', '', '');
  searchKeywordDeed: any;
  searchKeywordDate: any;


  constructor(
    private valuationRequestService: ValuationRequestService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.selectedPageNo = 0;
    this.limit = 10;
    this.nextPage = 0;

    this.getAllResults();
    this.sub.add(this.valuationRequestService.findValuationRequestByStatus(
      'DRAFT', this.selectedPageNo, this.limit, 'EXTERNAL').subscribe(
        (res) => {
          this.valuationRequests = res.data.content;
          if (Object.keys(this.valuationRequests).length === 0) {
            this.noResults = true;
          } else this.noResults = false;
          this.paginationButtonCounter(res.data.totalPages);
          this.numOfPages = res.data.totalPages;
          this.completedRequestCount.emit(res.data.totalElements);
        }));

  }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

  getPage(pageNo: number) {
    this.selectedPageNo = pageNo;
    this.sub.add(this.valuationRequestService.findValuationRequestByStatus(
      'DRAFT', this.selectedPageNo, this.limit, 'EXTERNAL').subscribe(
        (res) => {
          this.valuationRequests = res.data.content;
          if (Object.keys(this.valuationRequests).length === 0) {
            this.noResults = true;
          } else this.noResults = false;
          this.paginationButtonCounter(res.data.totalPages);
          this.numOfPages = res.data.totalPages;
          this.completedRequestCount.emit(res.data.totalElements);
        }));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  search() {
    this.selectedPageNo = 0;
    this.limit = 10;

    if (this.searchKeyword.trim() !== "" && this.searchKeyword != null) {
      this.sub.add(this.valuationRequestService.searchValuationRequest(this.searchKeyword,
        'DRAFT', this.selectedPageNo, this.limit, 'EXTERNAL').subscribe(
          (res) => {
            this.valuationRequests = res.data.content;
            if (Object.keys(this.valuationRequests).length === 0) {
              this.noResults = true;
            } else this.noResults = false;
            this.paginationButtonCounter(res.data.totalPages);
            this.numOfPages = res.data.totalPages;
          }));
    } else {

      this.getAllResults();
    }
  }

  searchByString(){
    this.selectedPageNo = 0;
    this.limit = 10;
    this.model.valuationStatus = Constant.VALUATION_REQUEST_DRAFT_STATUS;

    if (this.searchKeywordDeed.trim()!=='' && this.searchKeywordDeed != null) {
      this.sub.add(this.valuationRequestService.searchValuationRequestByString(this.searchKeywordDeed,
        this.model.valuationStatus,  0, this.limit,  'EXTERNAL').subscribe(
        (res) => {
          this.valuationRequests = res.data.content;
          if (Object.keys(this.valuationRequests).length === 0) {
            this.noResults = true;
          } else this.noResults = false;
          this.paginationButtonCounter(res.data.totalPages);
          this.numOfPages = res.data.totalPages;
        }));
    } else {
      this.getAllResults();
    }
  }

  searchDate() {
    this.model.offset = `0`;
    this.model.limit = '10';
    this.model.valuationStatus = Constant.VALUATION_REQUEST_DRAFT_STATUS;

    if (this.searchKeywordDate.trim() !== '' && this.searchKeywordDate != null) {
      this.sub.add(this.valuationRequestService.searchValuationRequestByDate(this.searchKeywordDate,
        this.model.valuationStatus, parseInt(this.model.offset), parseInt(this.model.limit), 'EXTERNAL',this.model.userId, this.model.userType).subscribe(
          (res) => {
            this.valuationRequests = res.data.content;
            if (Object.keys(this.valuationRequests).length === 0) {
              this.noResults = true;
            } else this.noResults = false;
            this.paginationButtonCounter(res.data.totalPages);
            this.numOfPages = res.data.totalPages;
          }));
      } else {
        this.getAllResults();
      }
  }

  private getAllResults() {
    this.sub.add(this.valuationRequestService.findValuationRequestByStatus(
      'DRAFT', this.selectedPageNo, this.limit, 'EXTERNAL').subscribe(
        (res) => {
          this.valuationRequests = res.data.content;
          if (Object.keys(this.valuationRequests).length === 0) {
            this.noResults = true;
          } else this.noResults = false;
          this.paginationButtonCounter(res.data.totalPages);
          this.numOfPages = res.data.totalPages;

          //Setting the pagination for the table No. of Requests
          if (this.selectedPageNo !== 0) {
            this.nextPage = 10 * this.selectedPageNo;
          } else {
            this.nextPage = 0;
          }
        }));
  }

  updateValuationRequest(valuationRequestId) {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(valuationRequestId)
      .subscribe((res) => {
        if (res.data.deedNature === 'Gift') {
          localStorage.setItem('valuation_request_id', valuationRequestId);
          sessionStorage.setItem('valuationUpdate', 'true');
          sessionStorage.setItem('selectedType', 'Gift');
          this.router.navigate(['/new-application/gift']);
        } else if (res.data.deedNature === 'Transfer') {
          localStorage.setItem('valuation_request_id', valuationRequestId);
          sessionStorage.setItem('valuationUpdate', 'true');
          sessionStorage.setItem('selectedType', 'Transfer');
          this.router.navigate(['/new-application/transfer']);
        } else if (res.data.deedNature === 'Exchange') {
          localStorage.setItem('valuation_request_id', valuationRequestId);
          sessionStorage.setItem('valuationUpdate', 'true');
          sessionStorage.setItem('selectedType', 'Exchange');
          this.router.navigate(['/new-application/exchange']);
        }
      }));
  }
}
