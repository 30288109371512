import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { CalculationDataProcess } from "src/app/service/calculationDataProcess/calculation-data-process.service";
import { CALCULATION_HEADINGS } from "../../constant-headings/heading";
import { DataModel } from "../../models/data-model";
import Swal from "sweetalert2";
import * as moment from "moment";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-recreation-condominium",
  templateUrl: "./recreation-condominium.component.html",
  styleUrls: ["./recreation-condominium.component.scss"]
})
export class RecreationCondominiumComponent implements OnInit, OnChanges {
  @Output() sandDataModel: EventEmitter<any> = new EventEmitter<any>();

  constructor(private dataProcess: CalculationDataProcess,
    private notifier: NotifierService,
    ) {}

  panelNumber = 1;
  dynamicLblValue = "gross";

  @Input()
  mainFormDataModel;

  @Input()
  selectedRecord;

  @Input()
  showSubPanel;

  _CALCULATION_HEADINGS = CALCULATION_HEADINGS;
  headingDateOne = null;
  headingDateTwo = null;
  backButtonShow = true;

  @Input()
  headingOne

  @Input()
  headingTwo
  
  @Input()
  party;

  dataModel = new DataModel();
  maxDate: any;

  ngOnChanges(changes:SimpleChanges){
    // this.dataModel.headingDateOne = changes['headingOne'] ? changes['headingOne'].currentValue  :'';
    // this.dataModel.headingDateTwo = changes['headingTwo'] ? changes['headingTwo'].currentValue :'';

    if (this.selectedRecord == null) {
      if(changes['headingOne']){
        if(changes['headingOne'].currentValue){
          this.dataModel.headingDateOne = changes['headingOne'].currentValue;
        }else{
          this.dataModel.headingDateOne = localStorage.getItem('headingOne');  
        }
      }else{
        this.dataModel.headingDateOne = localStorage.getItem('headingOne') ? localStorage.getItem('headingOne') :'';
      }
  
      if(changes['headingTwo']){
        if(changes['headingTwo'].currentValue){
          this.dataModel.headingDateTwo = changes['headingTwo'].currentValue;
        }else{
          this.dataModel.headingDateTwo = localStorage.getItem('headingTwo');
        }
      }else{
        this.dataModel.headingDateTwo = localStorage.getItem('headingTwo') ? localStorage.getItem('headingTwo') :'';
      }
    } 
  }
  
  ngOnInit() {
    // this.dataModel.headingDateOne = localStorage.getItem('headingOne') ? localStorage.getItem('headingOne') :'';
    // this.dataModel.headingDateTwo = localStorage.getItem('headingTwo') ? localStorage.getItem('headingTwo') :'';

    if (this.selectedRecord != null) {
      this.dataModel = this.dataProcess.condominiumDataProcessToView(
        this.selectedRecord
      );

      console.log(this.selectedRecord)

      this.mainFormDataModel.selectedDate = this.selectedRecord.date;

      // Assigning toggle button value on selected calculation record
      // First page
      if (this.dataModel.fristSet.valueType === "true") {
        this.dataModel.fristSet.valueType = true;
      } else {
        this.dataModel.fristSet.valueType = false;
      }
      // Second page
      if (this.dataModel.secondSet.valueType === "true") {
        this.dataModel.secondSet.valueType = true;
      } else {
        this.dataModel.secondSet.valueType = false;
      }

      // Assigning Rate of Return values
      this.dataModel.fristSet.rateOfReturn = this.selectedRecord.calculationCondominiums[0].rateOfReturn;
      if (this.selectedRecord.natureOfDeed == "gift") {
        this.dataModel.secondSet.rateOfReturn = this.selectedRecord.calculationCondominiums[1].rateOfReturn;
      }
      
      this.dataModel.headingDateOne = moment(this.selectedRecord.headingDateOne).format("YYYY-MM-DD");
      this.dataModel.headingDateTwo = moment(this.selectedRecord.headingDateTwo).format("YYYY-MM-DD");

      if(this.mainFormDataModel.selectedNatureOfDeed !== "transfer"){
        if (this.dataModel.fristSet.valueType) {
          console.log(this.dataModel.fristSet.valueType)
          this.dynamicLblValue = "gross";
          this.dataModel.secondSet.valueType = true;
        } else if(!this.dataModel.fristSet.valueType) {
          this.dynamicLblValue = "net";
          this.dataModel.secondSet.valueType = false;
        }
      }


      this.mainFormDataModel.selectedNatureOfDeed = this.selectedRecord.natureOfDeed;
      this.mainFormDataModel.selectedPropertyType = this.selectedRecord.propertyType;

      this.calculateAutoGeneratedValuesForFirstPanle();
      this.calculateAutoGeneratedValuesForSecondPanel();
    }

    if (this.mainFormDataModel.selectedNatureOfDeed != "gift") {
      this.panelNumber = 2;
      this.backButtonShow = false;
    } else {
      this.panelNumber = 1;
      this.backButtonShow = true;
    }
    this.maxDate = new Date();
    window.scroll(0,0);
  }

  headingDateOneChange(){
    localStorage.setItem('headingOne', this.dataModel.headingDateOne);
  }

  headingDateTwoChange(){
    localStorage.setItem('headingTwo', this.dataModel.headingDateTwo);
  }

  changePanelNumber(position) {
    if (position == "back") {
      if (this.panelNumber == 2) {
        this.panelNumber = this.panelNumber - 1;
      }
    }
    if (position == "next") {
      if (this.panelNumber == 1) {
        this.panelNumber = this.panelNumber + 1;
      }
    }
    window.scroll(0,0);
  }

  valueTypeChangeAction(value) {
    this.dataModel.secondSet.valueType = value;
    if (value) {
      this.dynamicLblValue = "gross";
      this.calculateAutoGeneratedValuesForFirstPanle();
      this.calculateAutoGeneratedValuesForSecondPanel();
    }
    if (!value) {
      this.dynamicLblValue = "net";
      this.dataModel.secondSet.deductionPrecentage = 0;
      this.dataModel.secondSet.deductionOutgoing = 0;
      this.dataModel.fristSet.deductionPrecentage = 0;
      this.dataModel.fristSet.deductionOutgoing = 0;
      this.calculateAutoGeneratedValuesForFirstPanle();
      this.calculateAutoGeneratedValuesForSecondPanel();
    }
  }

  calculateAutoGeneratedValuesForFirstPanle() {
    this.dataModel.fristSet.monthlyRent = (
      this.dataModel.fristSet.rentPerSqFt * this.dataModel.fristSet.extent
    ).toFixed(2);

    this.dataModel.fristSet.annualRent = (
      this.dataModel.fristSet.monthlyRent * 12
    ).toFixed(2);

    this.dataModel.fristSet.deductionOutgoing = (
      (this.dataModel.fristSet.annualRent *
        this.dataModel.fristSet.deductionPrecentage) /
      100
    ).toFixed(2);

    this.dataModel.fristSet.annualIncome = (
      this.dataModel.fristSet.annualRent -
      this.dataModel.fristSet.deductionOutgoing
    ).toFixed(2);

    let yp = 0;
    if (
      this.dataModel.fristSet.rateOfReturn != null &&
      this.dataModel.fristSet.rateOfReturn != 0
    ) {
      yp = 100 / this.dataModel.fristSet.rateOfReturn;
      this.dataModel.fristSet.yp = yp;
      this.dataModel.fristSet.marketValue = (
        this.dataModel.fristSet.annualIncome * yp
      ).toFixed(2);
    } else {
      this.dataModel.fristSet.marketValue = 0;
      this.dataModel.fristSet.yp = 0;
    }

    this.dataModel.fristSet.externalTotal = this.dataModel.fristSet.marketValue;

    this.dataModel.fristSet.valueOfTheProperty =
      Number(this.dataModel.fristSet.marketValue) +
      Number(this.dataModel.fristSet.total);

    if (
      this.dataModel.secondSet.lowestValue != "" ||
      this.dataModel.secondSet.lowestValue != null
    ) {
      this.calculateAutoGeneratedValuesForSecondPanel();
    }

    // this.dataModel.fristSet.externalTotal = this.dataModel.fristSet.marketValue;

    // this.dataModel.fristSet.valueOfTheProperty =
    //   this.dataModel.fristSet.marketValue + this.dataModel.fristSet.total;
  }

  calculateAutoGeneratedValuesForSecondPanel() {
    this.dataModel.secondSet.monthlyRent = (
      this.dataModel.secondSet.rentPerSqFt * this.dataModel.secondSet.extent
    ).toFixed(2);

    this.dataModel.secondSet.annualRent = (
      this.dataModel.secondSet.monthlyRent * 12
    ).toFixed(2);

    if (this.dataModel.secondSet.valueType) {
      (this.dataModel.secondSet.deductionOutgoing =
        (this.dataModel.secondSet.annualRent *
          this.dataModel.secondSet.deductionPrecentage) /
        100).toFixed(2);
    }

    this.dataModel.secondSet.annualIncome = (
      this.dataModel.secondSet.annualRent -
      this.dataModel.secondSet.deductionOutgoing
    ).toFixed(2);

    let yp = 0;
    if (
      this.dataModel.secondSet.rateOfReturn != null &&
      this.dataModel.secondSet.rateOfReturn != 0
    ) {
      yp = 100 / this.dataModel.secondSet.rateOfReturn;
      this.dataModel.secondSet.yp = yp;
      this.dataModel.secondSet.marketValue = (
        this.dataModel.secondSet.annualIncome * yp
      ).toFixed(2);
    } else {
      this.dataModel.secondSet.marketValue = 0;
      this.dataModel.secondSet.yp = 0;
    }

    this.dataModel.secondSet.externalTotal = parseFloat(
      this.dataModel.secondSet.marketValue
    ).toFixed(2);
    let lowestVal: any = 0;

    if (this.dataModel.secondSet.total != null) {
      this.dataModel.secondSet.marketValue =
        Number(this.dataModel.secondSet.marketValue) +
        Number(this.dataModel.secondSet.total);
    }

    // if (
    //   parseFloat(this.dataModel.secondSet.marketValue).toFixed(2) >
    //   this.dataModel.fristSet.valueOfTheProperty
    // ) {
    //   lowestVal = this.dataModel.fristSet.valueOfTheProperty;
    // } else {
    //   lowestVal = this.dataModel.secondSet.marketValue;
    // }
    // this.dataModel.secondSet.lowestValue = parseFloat(lowestVal).toFixed(2);
    // this.dataModel.secondSet.valueOfTheProperty = parseFloat(lowestVal).toFixed(
    //   2
    // );

    if (this.mainFormDataModel.selectedNatureOfDeed == "gift") {
      if (
        parseFloat(this.dataModel.secondSet.marketValue).toFixed(2) >
        this.dataModel.fristSet.valueOfTheProperty
      ) {
        lowestVal = this.dataModel.fristSet.valueOfTheProperty;
      } else {
        lowestVal = parseFloat(this.dataModel.secondSet.marketValue).toFixed(2);
      }
    } else {
      lowestVal = this.dataModel.secondSet.marketValue;
    }
    this.dataModel.secondSet.lowestValue = parseFloat(lowestVal).toFixed(2);
    this.dataModel.secondSet.valueOfTheProperty = parseFloat(lowestVal).toFixed(2);

    this.dataModel.secondSet.roundOffValue =
    this.dataModel.secondSet.roundOffValue == "" ||
    this.dataModel.secondSet.roundOffValue == null ||
    this.dataModel.secondSet.roundOffValue < 1
      ? this.dataModel.secondSet.lowestValue
      : this.dataModel.secondSet.roundOffValue;

    let totalStampDuty = 0;
    let valueOfTheProperty = 100000;
    let balanceStampDutyPrecentage = 4;
    if (this.mainFormDataModel.selectedNatureOfDeed == "gift") {
      valueOfTheProperty = 50000;
      balanceStampDutyPrecentage = 2;
    }
    if (this.dataModel.secondSet.roundOffValue > valueOfTheProperty) {
      let fMarketVal = (valueOfTheProperty * 3) / 100;

      let sMarketVal =
        ((this.dataModel.secondSet.roundOffValue - valueOfTheProperty) *
          balanceStampDutyPrecentage) /
        100;
      totalStampDuty = fMarketVal + sMarketVal;
      this.dataModel.secondSet.stampDuty = totalStampDuty.toFixed(2);
    } else {
      let fMarketVal = (this.dataModel.secondSet.roundOffValue * 3) / 100;
      this.dataModel.secondSet.stampDuty = fMarketVal.toFixed(2);
    }
  }

  addNewRow(position) {
    if (position == 1) {
      this.dataModel.fristSet.improvements.push({
        id: null,
        description: null,
        extent: null,
        rentPerSqFt: null,
        value: null
      });
    }

    if (position == 2) {
      this.dataModel.secondSet.improvements.push({
        id: null,
        description: null,
        extent: null,
        rentPerSqFt: null,
        value: null
      });
    }

    this.calculateImprovementsTableValueFiled(undefined, position);
  }

  removeRow(index, position) {
    if (position == 1) {
      this.dataModel.fristSet.improvements.splice(index, 1);
    }
    if (position == 2) {
      this.dataModel.secondSet.improvements.splice(index, 1);
    }

    this.calculateImprovementsTableValueFiled(undefined, position);
  }

  calculateImprovementsTableValueFiled(index, position) {
    // if (index != undefined) {
    //   this.dataModel.fristSet.improvements[index].value = (
    //     this.dataModel.fristSet.improvements[index].extent *
    //     this.dataModel.fristSet.improvements[index].rentPerSqFt
    //   ).toFixed(2);
    // }
    // this.dataModel.fristSet.improvements.forEach(element => {
    //   totalVal = totalVal + element.value;
    // });
    // this.dataModel.fristSet.total = totalVal;

    // let totalVal: Number = 0;

    // if (index != undefined) {
    //   this.dataModel.fristSet.improvements[index].value = (
    //     this.dataModel.fristSet.improvements[index].extent *
    //     this.dataModel.fristSet.improvements[index].rentPerSqFt
    //   ).toFixed(2);
    // }

    // this.dataModel.fristSet.improvements.forEach(element => {
    //   totalVal = Number(totalVal) + Number(element.value);
    // });
    // this.dataModel.fristSet.total = totalVal;

    let totalVal: Number = 0;
    let _totalVal: Number = 0;

    if (index != undefined && position == 1) {
      this.dataModel.fristSet.improvements[index].value = (
        this.dataModel.fristSet.improvements[index].extent *
        this.dataModel.fristSet.improvements[index].rentPerSqFt
      ).toFixed(2);
    }
    if (index != undefined && position == 2) {
      this.dataModel.secondSet.improvements[index].value = (
        this.dataModel.secondSet.improvements[index].extent *
        this.dataModel.secondSet.improvements[index].rentPerSqFt
      ).toFixed(2);
    }

    if (position == 1) {
      this.dataModel.fristSet.improvements.forEach(element => {
        totalVal = Number(totalVal) + Number(element.value);
      });
      this.dataModel.fristSet.total = totalVal;
      this.calculateAutoGeneratedValuesForFirstPanle();
    }
    if (position == 2) {
      this.dataModel.secondSet.improvements.forEach(element => {
        _totalVal = Number(_totalVal) + Number(element.value);
      });
      this.dataModel.secondSet.total = _totalVal;
      this.calculateAutoGeneratedValuesForSecondPanel();
    }
  }

  saveDataModel() {
    let data = {
      data: this.dataModel,
      type: "condominium",
      party: this.party
    };

    this.sandDataModel.emit(data);

    // if(this.headingDateOne != null || this.headingDateTwo != null){
    //   this.sandDataModel.emit(data);
    // }else{
    //   this.notifier.notify('error', 'please set the date');
    // }
  }

  validateDataSet() {}
}
