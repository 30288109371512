export class PaymentUpdate {
  paymentId: string;
  paymentStatus: string;

  constructor(
    paymentId: string,
    paymentStatus: string
  ) {
    this.paymentId = paymentId;
    this.paymentStatus = paymentStatus;
  }
}
