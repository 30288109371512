import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NotifierService } from "angular-notifier";
import { InspectionBuildingConstruction } from "src/app/model/inspection-building-construction";
import { InspectionBuildingConstructionDetail } from "src/app/model/inspection-building-construction-details";
import { InspectionBuildingDetails } from "src/app/model/inspection-building-details";
import { InspectionLandBuildingMain } from "src/app/model/inspection-building-main";
import { InspectionCondominium } from "src/app/model/inspection-condominium";
import { InspectionLand } from "src/app/model/inspection-land";
import { InspectionLandBuilding } from "src/app/model/inspection-land-building";
import { InspectionLandBuildingFeature } from "src/app/model/inspection-land-building-feature";
import { InspectionRecreation } from "src/app/model/inspection-recreation";
import { InspectionCommonService } from "src/app/service/inspection-common/inspection-common.service";
import { InspectionService } from "src/app/service/inspection/inspection.service";
import { Constant } from "src/app/util/constant";
import { v4 as uuidv4 } from "uuid";
import { checkCompletedOrPaypend } from "../Util/checkStatus";

@Component({
  selector: "app-inspection-report-recreation-building",
  templateUrl: "./inspection-report-recreation-building.component.html",
  styleUrls: ["./inspection-report-recreation-building.component.scss"],
})
export class InspectionReportRecreationBuildingComponent implements OnInit {
  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("popupSuccessMessage", null)
  private popupMigrated: TemplateRef<object>;
  
  @Input()
  valuationRequestId: string;
  @Input()
  valuationRequestStatus: string;

  selectedRecreationSubPropertyType: any;

  showBackButton: boolean;

  public modelLand = new InspectionLand();
  public modelLandBuildingLand = new InspectionLand();
  public modelCondominium = new InspectionCondominium();
  public userdata: any = [];

  public selectedRecreationType: boolean;
  public recreationModel = new InspectionRecreation(
    null,
    null,
    null,
    null,
    null,
    null,
    null, 
    null
  );
  public mainModel = new InspectionLandBuildingMain(
    null,
    "",
    "",
    "",
    "",
    null,
    [],
    null,
    "",
    "",
    null,
    null
  );


  submissionModel = new InspectionLandBuildingMain(
    null,
    "",
    "",
    "",
    "",
    null,
    [],
    null,
    "",
    "",
    null,
    null
  );

  public model = new InspectionLandBuilding("", "", "", [], "", [], null);

  public modelBuildingDetails = new InspectionBuildingDetails(
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    ""
  );
  public inspectionLandBuildingFeatureModel = new InspectionLandBuildingFeature(
    "",
    "",
    "",
    ""
  );

  public selectedview: number;

  selectedCategoryPropertyType: any;

  public landwithbuilding: boolean;

  public categoryPropertyList: any = [];
  public buildingFeatureList: any = [];
  public buildingSubFeatureList: any = [];
  public usageList: any = [];
  public inspectionLandBuildingFeatureList: any = [];
  public inspectionBuildingConstructionModel = new InspectionBuildingConstruction('', []);
  public inspectionBuildingConstructionDetailModel = new InspectionBuildingConstructionDetail('', '');
  public filteredbuildingSubFeatureList: any = [];
  public buildingSubFeatureCount: number;
  public buildingSubFeatureListEntered: any = [];
  public buildingFeatureListEntered: any = [];

  selectedPropertyType: any;

  featureType:any = "undefined";
  subFeatureType:any = [];
  ratio:any = [];

  firstView: boolean;
  secondView: boolean;
  finalView: boolean;
  valuationdata: any;
  selectedRecreationUpdateType: any;
  propertySubTypeList: any;
  selectedSubPropertyType: any;
  public showAddBuildingButtonCount: boolean;
  inspectionReportId: string;

  public disableBuildingFeatureMain: boolean;
  public disableSubFeature: boolean;
  public disableCompletedRatio: boolean;
  public showAddBuildingButton: boolean;
  public buildingSubFeatures: any = [];
  landId: any;
  buildingDetailsId: string;
  disableSaveBtn: boolean;

  checkStatusApplication  = checkCompletedOrPaypend;


  constructor(
    public modalService: NgbModal,
    public inspectionCommonService: InspectionCommonService,
    public inspectionService: InspectionService,
    private route: ActivatedRoute,
    private notifier: NotifierService
  ) {
    this.firstView = true;
    this.secondView = false;
    this.finalView = false;
    this.selectedRecreationType = false;
    this.showBackButton = true;
    this.showAddBuildingButtonCount = false;
    this.disableBuildingFeatureMain = false;
    this.disableSubFeature = false;
    this.disableCompletedRatio = false;
    this.showAddBuildingButton = false;
    this.showAddBuildingButtonCount = false;
    this.buildingSubFeatureCount = 0;
    this.landId = undefined;
    this.disableSaveBtn = false;
  }

  ngOnInit() {
    this.valuationdata = JSON.parse(sessionStorage.getItem("valuationRequest"));
    this.selectedRecreationUpdateType = JSON.parse(
      atob(localStorage.getItem("selectedRecreationUpdateType"))
    );
    this.selectedRecreationType = JSON.parse(
      atob(localStorage.getItem("selectedRecreationType"))
    );
    this.selectedRecreationSubPropertyType = JSON.parse(
      atob(localStorage.getItem("selectedRecreationSubPropertyType"))
    );
    this._prepare();
    console.log(this.model.inspectionBuildingConstructions);

    this.landId = localStorage.getItem("inspectionRecreationLandwithBuilding-landId");
    this.buildingDetailsId = localStorage.getItem("inspectionRecreationLandwithBuilding-buildingId");

    if(JSON.parse(atob(localStorage.getItem("selectedRecreationUpdateType"))) ===true){
      this.landId = JSON.parse(localStorage.getItem("inspectionLandBuilding-Land")).id;
      localStorage.setItem("inspectionRecreationLandwithBuilding-landId", this.landId)
      this.buildingDetailsId = JSON.parse(localStorage.getItem("inspectionLandBuilding-Building")).id;
      localStorage.setItem("inspectionRecreationLandwithBuilding-buildingId", this.buildingDetailsId)
      this.getInspectionRecreationById(JSON.parse(localStorage.getItem('inspectionLandBuilding-Building')).id)
      localStorage.setItem("recreationDetails", JSON.stringify(true));
    }else{
      if(JSON.parse(localStorage.getItem("inspectionLandBuilding-Building")) !== null){
        this.mainModel = JSON.parse(localStorage.getItem("inspectionLandBuilding-Building"));
        localStorage.setItem("recreationDetails", JSON.stringify(true));
      }else{
        localStorage.setItem("recreationDetails", JSON.stringify(false));
      }
    }

  }

  _prepare() {
    this.inspectionReportId = atob(localStorage.getItem("inspectionId"));

    this.userdata = JSON.parse(atob(sessionStorage.getItem("userinfo")));
    // this.recreationInspectionReportId = atob(localStorage.getItem('recreationLandBuildingInspectionId'));
    // if (this.recreationInspectionReportId !== "undefined") {
    //   this.mainModel.inspectionId = this.recreationInspectionReportId;
    //   this.loadCustomPropertyType();
    // }
    // this.inspectionReportId = atob(localStorage.getItem('inspectionId'));
    // if (this.inspectionReportId !== "undefined") {
    //   this.isUpdate = true;
    //   this.showAddBuildingButton = true;
    //   this.retrieveUpdateFormDate();
    // }
    // this.valuationRequestId = this.route.snapshot.paramMap.get('id');
    // Check whether if it is a recreation
    if (this.selectedRecreationType) {
      this.loadPropertyType();
    }

    //checking this is the last component in recreation
    if (this.selectedRecreationSubPropertyType.length === 1) {
      this.showBackButton = false;
    }

    // get data to dropdowns
    this.usageTypeList();
    this.categoryPropertyTypeList();
    this.buildingFeatureTypeList();
    this.buildingSubFeatureTypeAll();
    // this.buildingSubFeatureTypeList();
  }

  //reset form
  reset() {
    this.inspectionLandBuildingFeatureModel = new InspectionLandBuildingFeature("", "", "", "" );
    this.model = new InspectionLandBuilding("", "", "", [], "", [], null);
    if (this.mainModel.buildingInformationList.length > 0)
      this.model.noOfBuildings = this.mainModel.buildingInformationList[0].noOfBuildings;
    this.filteredbuildingSubFeatureList = [];
    this.inspectionBuildingConstructionModel = new InspectionBuildingConstruction("", []);
    this.inspectionBuildingConstructionDetailModel = new InspectionBuildingConstructionDetail("", "");
    this.buildingSubFeatureListEntered = [];
    this.buildingFeatureListEntered = [];
    // this.categoryPropertyList = [];
    // this.buildingFeatureList = [];
    // this.buildingSubFeatureList = [];
    // this.inspectionLandBuildingFeatureList = [];
  }

  //Redirect to main page
  redirectToMainPage() {
    this.onPageCountChanged.emit(10);
  }

  loadPropertyType() {
    this.inspectionCommonService.propertyUsageTypeList().subscribe((data) => {
      const filtereddata = data.data.filter(
        (i) => i.id === Constant.INSPECTION_PROPERTY_TYPE_LAND_BUILDING_ID
      );
      this.selectedPropertyType = filtereddata[0];
      this.loadPropertySubType();
    });
  }

  loadPropertySubType() {
    this.inspectionCommonService
      .propertyUsageSubTypeList()
      .subscribe((data) => {
        const filtereddata = data.data.filter(
          (i) => i.inspectionPropertyType.id === this.selectedPropertyType.id
        );
        this.propertySubTypeList = filtereddata;
      });
  }

  removeLandBuildingIndex(){
    localStorage.setItem("indexSelectedLandBuilding", JSON.stringify(null));
  }

  // first nav -- 2nd view back button -- redirect after land save -- redirect after building save --start
  firstNavigatePage() {
    this.firstView = true;
    this.secondView = false;
    this.finalView = false;
    window.scroll(0, 0);
  }
  // first nav -- 2nd view back button -- redirect after land save -- redirect after building save --end

  // second nav -- first view next button -- third view back button --start
  secondNavigatePage() {
    this.firstView = false;
    this.secondView = true;
    this.finalView = false;
    window.scroll(0, 0);

  }
  // second nav -- first view next button -- third view back button --end

  // third nav -- second view next button --start
  thirdNavigatePage() {
    this.firstView = false;
    this.secondView = false;
    this.finalView = true;
    window.scroll(0, 0);
  }
  // third nav -- second view next button --end

  //first view back button
  recreationBackNavigation() {
    let page;
    // this.model.valuationRequestId = this.valuationRequestId;
    // this.model.propertyTypeId = this.selectedPropertyType['id'];
    // this.model.propertySubTypeId = '2';
    // this.model.createdBy = this.userdata['firstName'];
    // this.model.inspectionType = Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM;
    if (this.selectedRecreationType) {
      if (this.selectedRecreationSubPropertyType.length === 3) {
        page = "condominium";
      } else if (this.selectedRecreationSubPropertyType.length > 0) {
        for (
          let i = 0;
          i < this.selectedRecreationSubPropertyType.length;
          i++
        ) {
          if (this.selectedRecreationSubPropertyType[i].id === 1) {
            page = "land";
            break;
          } else if (this.selectedRecreationSubPropertyType[i].id === 2) {
            page = "condominium";
            break;
          }
        }
      }
    }
    if (page === "condominium") {
      localStorage.setItem("isLand", JSON.stringify(true));
      localStorage.setItem(
        "inspectionlandmodel",
        btoa(JSON.stringify(this.modelLand))
      );
      this.onPageCountChanged.emit(18);
    }
    if (page === "land") {
      localStorage.setItem("isLand", JSON.stringify(true));
      localStorage.setItem(
        "inspectionlandmodel",
        btoa(JSON.stringify(this.modelLand))
      );
      this.onPageCountChanged.emit(16);
    }
  }

  //subproperty type selected
  selectedSubPropertyTypeForRecreation(event) {
    const filtereddata = this.propertySubTypeList.filter(
      (i) => i.id === Number(event)
    );
    this.selectedSubPropertyType = filtereddata[0];
    console.log(this.selectedSubPropertyType);
    localStorage.setItem(
      "subPropertyId",
      JSON.stringify(this.selectedSubPropertyType)
    );
  }

  //redirect to land details

  addLandDetails() {
    localStorage.setItem("isLandWithBuildingRecreation", JSON.stringify(true));
    localStorage.setItem(
      "selectedPropertyType",
      btoa(JSON.stringify(this.model))
    );
    this.onPageCountChanged.emit(16);
  }

  //view data of building records
  viewFormData(index) {
    localStorage.setItem("autosaveupdate", JSON.stringify(true));
    localStorage.setItem('indexSelectedLandBuilding', JSON.stringify(index));
    this.selectedview = index;
    this.model = this.mainModel.buildingInformationList[index];
    this.modelBuildingDetails =
      this.mainModel.buildingInformationList[index].inspectionBuildingDetails;
    const filtereddataset = this.categoryPropertyList.filter(
      (i) =>
        i.id ===
        Number(
          this.mainModel.buildingInformationList[this.selectedview]
            .categoryPropertyId
        )
    );
    this.selectedCategoryPropertyType = filtereddataset[0]["name"];
    this.firstView = false;
    this.secondView = true;
    this.finalView = false;
  }

  //number of building change event
  public noOfBuildingsChange(event) {
    if (this.mainModel.buildingInformationList.length > 0) {
      if (event < this.mainModel.buildingInformationList.length) {
        this.notifier.notify(
          "error",
          "Cannot select less number than current building count"
        );
        this.model.noOfBuildings = "";
      } else {
        this.model.noOfBuildings = event;
        this.modelBuildingDetails.inspectionNoOfBuildings =
          this.model.noOfBuildings;

        if (event == this.mainModel.buildingInformationList.length) {
          this.showAddBuildingButtonCount = false;
        } else {
          this.showAddBuildingButtonCount = true;
        }
      }
    } else {
      this.model.noOfBuildings = event;
      this.modelBuildingDetails.inspectionNoOfBuildings =
        this.model.noOfBuildings;
      this.showAddBuildingButtonCount = true;
    }
  }

  // get recreation details by id
  getInspectionRecreationById(id: string) {
    this.inspectionService.getInspectionById(id).subscribe((data) => {
      console.log(data.data);
      this.mainModel = data.data.inspectionLandBuildingDto;

      var length = this.mainModel.buildingInformationList.length; 
      if(this.mainModel.buildingInformationList.length > 0){
        this.model.noOfBuildings = this.mainModel.buildingInformationList[length-1].noOfBuildings;
      }
    });
  }

  //get building category property
  selectedCategoryProperty(event) {
    const filtereddata = this.categoryPropertyList.filter(
      (i) => i.id === Number(event)
    );
    this.selectedCategoryPropertyType = filtereddata[0]["name"];
  }

  //redirect to land component to view saved land
  public redirectToLandComponentAlter() {
    this.addLandDetails();
  }

  // get Land with building boolean status
  initialize() {
    this.landwithbuilding = JSON.parse(
      localStorage.getItem("landwithbuilding")
    );
  }

  //building features -- start
  //remove building feature record details
  removeMainRecord(index) {
    this.model.inspectionLandBuildingFeatures.splice(index, 1);
  }

  // convert sq.m to sq.ft
  convertSqmToSqft() {
    this.inspectionLandBuildingFeatureModel.areaFt = (
      this.inspectionLandBuildingFeatureModel.areaMt * 10.7639
    ).toFixed(2);
  }

  // convert sq.ft to sq.m
  convertSftToSqm() {
    this.inspectionLandBuildingFeatureModel.areaMt = (
      this.inspectionLandBuildingFeatureModel.areaFt / 10.7639
    ).toFixed(2);
  }

  //increment building features
  public incrementInspectionBuildingFeatures() {
    this.inspectionLandBuildingFeatureList.push(this.inspectionLandBuildingFeatureModel);
    this.model.inspectionLandBuildingFeatures.push(this.inspectionLandBuildingFeatureModel);
    this.inspectionLandBuildingFeatureModel = new InspectionLandBuildingFeature("", "", "", "");
    this.initialize();
  }
  //building features -- end

  //building features --  third view -- start

  deleteConstuctionDetail(index) {
    let i;
    let j;
    let count = 0;
    this.model.inspectionBuildingConstructions.splice(index, 1);
    // Calculating the total completion
    // for (i = 0; i < this.model.inspectionBuildingConstructions.length; i++) {
    //   for (j = 0; j < this.model.inspectionBuildingConstructions[i].constructionDetails.length; j++) {
    //     count++;
    //     this.modelBuildingDetails.totalCompletion = (Number(this.modelBuildingDetails.totalCompletion) + Number(this.model.inspectionBuildingConstructions[i].constructionDetails[j]['value'])).toString();
    //   }
    // }
    // this.modelBuildingDetails.totalCompletion = (Number(this.modelBuildingDetails.totalCompletion) / count).toString();
  }

  selectedBuildingFeature(event) {
    this.buildingSubFeatureCount = 0;
    this.buildingSubFeatureListEntered = [];
    this.inspectionBuildingConstructionModel = new InspectionBuildingConstruction('', []);
    this.inspectionBuildingConstructionDetailModel = new InspectionBuildingConstructionDetail('', '');
    // Assigning the selected dropdown id
    this.inspectionBuildingConstructionModel.buildingFeatureTypeId = event;
    // Filtering the sub feature list
    this.buildingSubFeatureTypeList(event);
  }

  counter(i: number) {
    return new Array(i);
  }

  selectedSubBuildingFeature(event, i) {
    if (event !== 'undefined') {
      this.disableSubFeature = true;
      this.disableBuildingFeatureMain = true;
    }
    this.inspectionBuildingConstructionDetailModel.buildingSubFeatureTypeId = event;
  }

  getTypedValue(event, i) {
    if (event !== 'undefined') {
      this.disableCompletedRatio = true;
    }
    this.inspectionBuildingConstructionDetailModel.value = event;
  }

  incrementBuildingSubFeatureCount() {
    this.disableSubFeature = false;
    this.disableCompletedRatio = false;
    this.buildingSubFeatureCount = this.buildingSubFeatureCount + 1;
    this.buildingSubFeatureListEntered.push(this.inspectionBuildingConstructionDetailModel);
    this.inspectionBuildingConstructionDetailModel = new InspectionBuildingConstructionDetail('', '');
  }

  insertRow() {
    if(this.buildingSubFeatureListEntered.length > 0) {
      let i;
      let j;
      let count = 0;
      this.disableBuildingFeatureMain = false;
      this.disableSubFeature = false;
      this.disableCompletedRatio = false;
      for (let i = 0; i < this.buildingSubFeatureListEntered.length; i++) {
        this.inspectionBuildingConstructionModel.constructionDetails.push(this.buildingSubFeatureListEntered[i]);
      }
  
      console.log(this.model.inspectionBuildingConstructions);
      this.model.inspectionBuildingConstructions.push(this.inspectionBuildingConstructionModel);
      this.buildingSubFeatureListEntered = [];
      this.buildingSubFeatureCount = 0;
      this.inspectionBuildingConstructionModel = new InspectionBuildingConstruction('', []);
      this.inspectionBuildingConstructionDetailModel = new InspectionBuildingConstructionDetail('', '');
  
      this.featureType = "undefined";
      this.subFeatureType = [];
      this.filteredbuildingSubFeatureList = [];
      this.ratio = [];
    }else{
      this.notifier.notify('error', 'add all required fields before save');
    }


    // Calculating the total completion
    // for (i = 0; i < this.model.inspectionBuildingConstructions.length; i++) {
    //   for (j = 0; j < this.model.inspectionBuildingConstructions[i].constructionDetails.length; j++) {
    //     count++;
    //     this.modelBuildingDetails.totalCompletion = (Number(this.modelBuildingDetails.totalCompletion) + Number(this.model.inspectionBuildingConstructions[i].constructionDetails[j]['value'])).toString();
    //   }
    // }
    // this.modelBuildingDetails.totalCompletion = (Number(this.modelBuildingDetails.totalCompletion) / count).toString();
  }

  //building features --  third view -- end


  // load default data to dropdowns -- start
  categoryPropertyTypeList() {
    this.inspectionCommonService
      .categoryPropertyTypeList()
      .subscribe((data) => {
        this.categoryPropertyList = data.data;
      });
  }

  buildingFeatureTypeList() {
    this.inspectionCommonService.buildingFeatureTypeList().subscribe(
      data => {
        this.buildingFeatureList = data.data;
      }
    )
  }

  buildingSubFeatureTypeAll() {
    this.inspectionCommonService.buildingSubFeatureTypeList().subscribe(
      data => {
        this.buildingSubFeatures = data.data;
        // this.filteredbuildingSubFeatureList = this.buildingSubFeatureList;
      }
    )
  }

  buildingSubFeatureTypeList(featureId:number) {
    this.inspectionCommonService.buildingSubFeatureTypeListByFeatureId(featureId).subscribe(
      data => {
        this.buildingSubFeatureList = data.data;
        this.filteredbuildingSubFeatureList = this.buildingSubFeatureList;
      }
    )
  }

  usageTypeList() {
    this.inspectionCommonService.condominiumTypeList().subscribe(
      data => {
        this.usageList = data.data;
      }
    )
  }
  // load default data to dropdowns -- end

  //save and update section -- start

  onSubmit() {
    this.disableSaveBtn = true;

    var recreationGroupId;
    if (JSON.parse(localStorage.getItem("recreationGroupId")) != null) {
      recreationGroupId = localStorage.getItem("recreationGroupId");
      this.mainModel.recreationGroupId = JSON.parse(
        localStorage.getItem("recreationGroupId")
      );
    } else {
      recreationGroupId = uuidv4();
      localStorage.setItem(
        "recreationGroupId",
        JSON.stringify(recreationGroupId)
      );
    }
    let haveErrors:boolean = true;
    //selecting previous recreation application details
    if (this.selectedRecreationType) {
      if (this.selectedRecreationSubPropertyType.length > 0) {
        for (
          let i = 0;
          i < this.selectedRecreationSubPropertyType.length;
          i++
        ) {
          if (this.selectedRecreationSubPropertyType[i].id === 1) {
            if (
              JSON.parse(
                localStorage.getItem("inspectionRecreationlandmodel")
              ) != null
            ) {
              this.modelLand = JSON.parse(
                localStorage.getItem("inspectionRecreationlandmodel")
              );
              // JSON.parse(localStorage.getItem("recreationDetails")) === true ?
              //   this.modelLand.id = parseInt(localStorage.getItem("inspectionRecreationLandId")): this.modelLand.id = null
              this.modelLand.id = parseInt(localStorage.getItem("inspectionRecreationLandId"));
              this.modelLand.inspectionType =
                Constant.INSPECTION_TYPE_RECREATION_LAND;
              this.modelLand.recreationGroupId = JSON.parse(
                localStorage.getItem("recreationGroupId")
              );
              if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
                this.recreationModel.parentId = parseInt(this.modelLand.inspectionId);
              }
              this.modelLand.createdBy = this.userdata["firstName"];
              this.modelLand.createdUserId = this.userdata["id"];
              this.recreationModel.isLand = true;
              if(this.modelLand.propertySubTypeId != null && this.modelLand.propertySubTypeId != undefined && this.modelLand.propertySubTypeId != ""){
                haveErrors = false;
              }else{
                haveErrors = true;
              }
            } else {
              haveErrors = true;
              // if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
              //   haveErrors = true;
              //   this.notifier.notify(
              //   "error",
              //   "Please select Land Sub type first."
              // );
              // }else{
              //   haveErrors = false;
              // }
            }
          } else if (this.selectedRecreationSubPropertyType[i].id === 2) {
            this.modelCondominium = JSON.parse(
              localStorage.getItem("inspectionRecreationCondominiummodel")
            );

            // JSON.parse(localStorage.getItem("recreationDetails")) === true ?
            //     this.modelCondominium.id = parseInt(localStorage.getItem("inspectionRecreationCondominiumId")): this.modelCondominium.id  = null;
            this.modelCondominium.id = parseInt(localStorage.getItem("inspectionRecreationCondominiumId"))
            if(this.modelCondominium != null){
                this.modelCondominium.inspectionType =
                Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM;
              this.modelCondominium.recreationGroupId = JSON.parse(
                localStorage.getItem("recreationGroupId")
              );
              this.modelCondominium.createdBy = this.userdata["firstName"];
              this.modelCondominium.createdUserId = this.userdata["id"];
              if (this.recreationModel.isLand === true) {
                this.recreationModel.isCondominium = false;
              } else {
                this.recreationModel.isCondominium = true;
              }
              if(this.recreationModel.parentId === null){
                if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
                  this.recreationModel.parentId = parseInt(this.modelCondominium.inspectionId);
                }
              }
              if(this.modelCondominium.propertySubTypeId != null && this.modelCondominium.propertySubTypeId != undefined && this.modelCondominium.propertySubTypeId != ""){
                haveErrors = false;
              }else{
                haveErrors = true;
              }
            }else {
              haveErrors = true;
              
              // if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
              //   haveErrors = true;
              //   this.notifier.notify(
              //     "error",
              //     "Please select Condominium Sub type first."
              //   );
              // }else{
              //   haveErrors = false;
              // }
            }

          } else if (this.selectedRecreationSubPropertyType[i].id === 3) {
            if (
              JSON.parse(
                localStorage.getItem("inspectionLandBuilding-Land")
              ) != null
            ) {
              // if(JSON.parse(localStorage.getItem("recreationDetails")) === true ){
              //   if(localStorage.getItem("inspectionRecreationLandwithBuilding-landId") != null){
              //     // this.modelLandBuildingLand.id = parseInt(localStorage.getItem("inspectionRecreationLandwithBuilding-landId"));
              //   }
              // }
              this.modelLandBuildingLand = JSON.parse(
                localStorage.getItem("inspectionLandBuilding-Land")
              );
              this.modelLandBuildingLand.id = parseInt(localStorage.getItem("inspectionRecreationLandwithBuilding-landId"));
              this.modelLandBuildingLand.inspectionType =
                Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING_LAND;
              this.modelLandBuildingLand.recreationGroupId = JSON.parse(
                localStorage.getItem("recreationGroupId")
              );
              this.modelLandBuildingLand.createdBy = this.userdata["firstName"];
              this.modelLandBuildingLand.createdUserId = this.userdata["id"];
              if(this.recreationModel.isLand !== true || this.recreationModel.isCondominium !== true){
                this.recreationModel.isLandBuilding = true;
              }else{
                this.recreationModel.isLandBuilding = false;
              }
              if(this.recreationModel.parentId === null){
                if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
                  this.recreationModel.parentId = parseInt(this.modelLandBuildingLand.inspectionId);
                }
              }
              if(this.modelLandBuildingLand.propertySubTypeId != null && this.modelLandBuildingLand.propertySubTypeId != undefined && this.modelLandBuildingLand.propertySubTypeId != ""){
                haveErrors = false;
              }else{
                haveErrors = true;
              }
            } else {
              haveErrors = true;

              // if(JSON.parse(localStorage.getItem("recreationDetails")) === true){
              //   haveErrors = true
              // this.notifier.notify(
              //   "error",
              //   "Please Create Land in Land with Building First."
              // );
              // }else{
              //   haveErrors = false;
              // }
            }
            if(JSON.parse(localStorage.getItem("inspectionLandBuilding-Building")) != null){
              this.submissionModel = JSON.parse(localStorage.getItem("inspectionLandBuilding-Building"));

              JSON.parse(localStorage.getItem("recreationDetails")) === true ?
                this.submissionModel.id = parseInt(localStorage.getItem("inspectionRecreationLandwithBuilding-buildingId")): this.submissionModel.id = null
              
            }
          }
        }
      }
    }
    this.mainModel.propertyTypeId = "";
    if (this.mainModel.propertySubTypeId == 'undefined' || this.mainModel.propertySubTypeId == '' || this.mainModel.propertySubTypeId == null) {
      this.mainModel.propertySubTypeId = '';
    } else {
      this.mainModel.propertySubTypeId = this.selectedSubPropertyType['id'];
    }
    this.mainModel.createdBy = this.userdata['firstName'];
    this.mainModel.createdUserId = this.userdata["id"];
    this.mainModel.inspectionType = Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING_BUILDING;
    this.mainModel.isRecreation = true;
    this.model.inspectionBuildingDetails = this.modelBuildingDetails;
    this.mainModel.valuationRequestId = this.route.snapshot.paramMap.get('id');;

    //update a spesific building info
        var index = JSON.parse(localStorage.getItem("indexSelectedLandBuilding"))
        if( index != null){
          this.mainModel.buildingInformationList[index] = this.model; 
          // this.mainModel.buildingInformationList.push(this.model);
        }else{
          this.mainModel.buildingInformationList.push(this.model);
        }
    

    this.mainModel.isBuilding = true;

    this.modelLandBuildingLand.id = parseInt(localStorage.getItem("inspectionRecreationLandwithBuilding-landId"));
    
    localStorage.setItem("inspectionLandBuilding-Building", JSON.stringify(this.mainModel));
    this.recreationModel.inspectionLandBuildingDto = this.mainModel;
    this.recreationModel.inspectionLandDto = this.modelLand;
    this.recreationModel.inspectionCondominiumDto = this.modelCondominium;
    this.recreationModel.inspectionLandBuildingDto_landDto = this.modelLandBuildingLand;

    if(haveErrors === false) {
      this.saveAndUpdate();
      this.disableSaveBtn = false;

    }else{
      this.disableSaveBtn = false;
      this.notifier.notify(
        "error",
        "Please Fill all Required Fields."
      );
    }

  }

  saveAndUpdate() {
    // if (JSON.parse(localStorage.getItem("recreationDetails")) === true) {
      this.inspectionService
        .inspectionRecreationUpdate(this.recreationModel)
        .subscribe(
          (data) => {
            this.openPopupMessage(this.popupMigrated);
            this.inspectionService
              .getLastInspectionRecreationRecords(
                this.mainModel.createdUserId,
                JSON.parse(localStorage.getItem("recreationGroupId"))
              )
              .subscribe((data) => {
                console.log(data.data);
                for (let i = 0; i < data.data.length; i++) {
                  switch (data.data[i].inspectionType) {
                    case Constant.INSPECTION_TYPE_RECREATION_LAND:
                      localStorage.setItem(
                        "inspectionRecreationLandId",
                        data.data[i].id
                      );
                      break;
                    case Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM:
                      localStorage.setItem(
                        "inspectionRecreationCondominiumId",
                        data.data[i].id
                      );
                      break;
                    case Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING_LAND:
                      localStorage.setItem(
                        "inspectionRecreationLandwithBuilding-landId",
                        data.data[i].id
                      );
                      break;
                    case Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING_BUILDING:
                      localStorage.setItem(
                        "inspectionRecreationLandwithBuilding-buildingId",
                        data.data[i].id
                      );
                      break;
                  }
                }
                localStorage.setItem("recreationDetails", JSON.stringify(true));
                this.mainModel = JSON.parse(localStorage.getItem("inspectionLandBuilding-Building"));
              });
            this.firstNavigatePage();
            this.reset();

          },
          (err) => {
            this.notifier.notify(
              "error",
              "Error occurred while processing. Please try again."
            );
          }
        );
    // } else {
    //   this.inspectionService
    //     .inspectionRecreationInsert(this.recreationModel)
    //     .subscribe(
    //       (data) => {
    //         this.openPopupMessage(this.popupMigrated);
    //         this.inspectionService
    //           .getLastInspectionRecreationRecords(
    //             this.mainModel.createdUserId,
    //             JSON.parse(localStorage.getItem("recreationGroupId"))
    //           )
    //           .subscribe((data) => {
    //             // console.log(data.data);
    //             for (let i = 0; i < data.data.length; i++) {
    //               switch (data.data[i].inspectionType) {
    //                 case Constant.INSPECTION_TYPE_RECREATION_LAND:
    //                   localStorage.setItem(
    //                     "inspectionRecreationLandId",
    //                     data.data[i].id
    //                   );
    //                   break;
    //                 case Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM:
    //                   localStorage.setItem(
    //                     "inspectionRecreationCondominiumId",
    //                     data.data[i].id
    //                   );
    //                   break;
    //                 case Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING_LAND:
    //                   localStorage.setItem(
    //                     "inspectionRecreationLandwithBuilding-landId",
    //                     data.data[i].id
    //                   );
    //                   break;
    //                 case Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING_BUILDING:
    //                   localStorage.setItem(
    //                     "inspectionRecreationLandwithBuilding-buildingId",
    //                     data.data[i].id
    //                   );
    //                   break;
    //               }
    //             }
    //             localStorage.setItem("recreationDetails", JSON.stringify(true));
    //             this.mainModel = JSON.parse(localStorage.getItem("inspectionLandBuilding-Building"));
    //           });
    //           this.firstNavigatePage();
    //           this.reset();
    //       },
    //       (err) => {
    //         this.notifier.notify(
    //           "error",
    //           "Error occurred while processing. Please try again."
    //         );
    //       }
    //     );
    // }
    
  }
  //save and update section -- end

    // model Methods
    closeModel() {
      this.modalService.dismissAll();
    }


  //popup Methods
  openPopupMessage(content) {
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      backdrop: "static",
    });
  }
}


