import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DeleteOfficeComponent} from '../../office/delete-office/delete-office.component';
import {SubSink} from 'subsink';
import {DataService} from '../../../../../../service/data.service';
import {IBank} from '../../../../../../model/interfaces/bank';
import {BankService} from '../../../../../../service/bank/bank.service';
import {UpdateBankComponent} from '../update-bank/update-bank.component';
import {DeleteBankComponent} from '../delete-bank/delete-bank.component';

@Component({
  selector: 'app-view-bank',
  templateUrl: './view-bank.component.html',
  styleUrls: ['./view-bank.component.scss']
})
export class ViewBankComponent implements OnInit, OnDestroy {

  @ViewChild(UpdateBankComponent, {static: false})
  private updateBankComponent: UpdateBankComponent;
  @ViewChild(DeleteBankComponent, {static: false})
  private deleteBankComponent: DeleteBankComponent;


  banks: IBank [];
  private sub = new SubSink();


  updateTable: boolean;

  // pagination
  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];
  currentPage: any;
  totalItems: any;

  constructor(private bankService: BankService, private dataService: DataService) {
  }

  pageNo: number;

  ngOnInit() {
    this.selectedPageNo = 0;
    this.limit = 10;
    
     this.bankService.getAll().subscribe(
       response => {
         var res = response.data.length;
         this.pageNo = Math.round((res / 10) + 1);
       }
     );

    this.sub.add(this.dataService.updateDataTableBank
      .subscribe(update => this.onAdded(update)));

    this.sub.add(this.bankService.getBanks(0, 10)
      .subscribe(
        (res) => {
        console.log(res)

          this.banks = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }
      ));
  }

  ngOnDestroy() {
    this, this.sub.unsubscribe();
  }


  triggerUpdate(id: number,name: string) {
    this.updateBankComponent.setIdAndDescription(id,name);
  }

  triggerDelete(id: number) {
    this.deleteBankComponent.setBankId(id);
  }

  onUpdated(updatedSuccessfully: boolean) {
    if (updatedSuccessfully) {
      this.updateTheTable();
    }
  }

  onDeleted(deletedSuccessfully: boolean) {
    if (deletedSuccessfully) {
      this.updateTheTable();
    }
  }

  onAdded(addedSuccessfully: boolean) {
    if (addedSuccessfully) {
      this.updateTheTable();
    }
  }

  pageble(i: number) {

    this.bankService.getBanks((i-1), 10).subscribe(responce => {
      console.log(responce)
      this.banks = (responce as any).data.content;
      this.currentPage = (responce as any).data.number+1;
      this.totalItems = (responce as any).data.totalElements;
    });
  }

  counter(i: number) {
    return new Array(i);
  }

  /** search company cattagery */
  searchBank(keyword: string) {
    this.selectedPageNo = 0;
    this.sub.add(this.bankService.searchBank(keyword, this.selectedPageNo, this.limit)
      .subscribe((res) => {
        // this.banks = res.data.content;
        // this.paginationButtonCounter(res.data.totalPages);
        // this.numOfPages = res.data.totalPages;
        this.banks = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      }));
  }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

  // update the table
  private updateTheTable() {
    this.sub.add(this.bankService.getBanks(0, 10)
      .subscribe(
        (res) => {
          // this.banks = (res as any).data;
          this.banks = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }


}
