import {Component, OnInit} from '@angular/core';
import {RefundRequestCount} from 'src/app/model/refundRequestCount';
import {Constant} from 'src/app/util/constant';
import {DashboardService} from 'src/app/service/dashboard/dashboard.service';
import {NotificationModelUpdate} from 'src/app/model/notification-model-update';
import {AuthService} from 'src/app/auth/auth.service';
import {ValuationRequestCount} from '../../../../../model/valuation_request_count';
import { RefundOpinionServiceService } from 'src/app/service/RefundOpinionService/refund-opinion-service.service';

@Component({
  selector: 'app-refund-internal-user-dashboard',
  templateUrl: './refund-internal-user-dashboard.component.html',
  styleUrls: ['./refund-internal-user-dashboard.component.scss']
})
export class RefundInternalUserDashboardComponent implements OnInit {

  public refundRequestCountModel = new RefundRequestCount('', '', '');
  public notificationModelUpdate = new NotificationModelUpdate();
  public model = new RefundRequestCount('', '', '');

  refundNotificationCode = Constant.NOTIFICATION_CATEGORY_REFUND
  opinionNotificationCode = Constant.NOTIFICATION_CATEGORY_OPINION
  appealNotificationCode = Constant.NOTIFICATION_CATEGORY_APPEAL
  rimNotificationCode = Constant.NOTIFICATION_CATEGORY_RIM
  
  numberOfNotifications: any;
  newRefundApplicationsCount: any;
  inprogressRefundApplicationsCount: any;
  rejectedRefundApplicationsCount: any;
  furtherRequirementCount: number;

  userData: any = [];
  userRoleCode: string;

  notifications: any = [];

  userName: string;

  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    private refundService:RefundOpinionServiceService
  ) {
  }

  ngOnInit() {
    this.obtainUserData();
    this.listNotifications();
    this.userName = sessionStorage.getItem('username');
  }

  obtainUserData() {
    this.userData = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.refundRequestCountModel.userId = this.userData.id;
    this.model.userId = this.userData.id;
    for (let role of this.userData.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.userRoleCode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.userRoleCode = Constant.ROLE_ACCESSOR;
        break;
      } else if (role.code === Constant.ROLE_TAX_OFFICER) {
        this.userRoleCode = Constant.ROLE_TAX_OFFICER;
        break;
      } else if (role.code === Constant.ROLE_ACCOUNTANT) {
        this.userRoleCode = Constant.ROLE_ACCOUNTANT;
        break;
      }
    }
    this.getNewApplicationCount();
    this.getInprogressApplicationCount();
    this.getRejectedApplicationCount();
    this.getFurtherRequirementAppCount();
  }

  getFurtherRequirementAppCount() {
    this.model.refundApplicationStatus = Constant.VALUATION_REQUEST_FURTHER_REQUIREMENT_CODE;
    this.model.userRoleCode = this.userRoleCode;
    this.dashboardService.getRefundRequestInternalUserCount(this.model).subscribe(value => {
      this.furtherRequirementCount = value;
    });
  }

  getNewApplicationCount() {
    this.refundRequestCountModel.refundApplicationStatus = Constant.REFUND_REQUEST_PENDING_CODE;
    this.refundRequestCountModel.userRoleCode = this.userRoleCode;
    this.dashboardService.getRefundRequestInternalUserCount(this.refundRequestCountModel).subscribe(value => {
      this.newRefundApplicationsCount = value;
    });
  }

  getInprogressApplicationCount() {
    this.refundRequestCountModel.refundApplicationStatus = Constant.REFUND_REQUEST_INPROGRESS_CODE;
    this.refundRequestCountModel.userRoleCode = this.userRoleCode;
    this.dashboardService.getRefundRequestInternalUserCount(this.refundRequestCountModel).subscribe(value => {
      this.inprogressRefundApplicationsCount = value;
    });
  }

  getRejectedApplicationCount() {
    this.refundRequestCountModel.refundApplicationStatus = Constant.REFUND_REQUEST_REJECTED_CODE;
    this.refundRequestCountModel.userRoleCode = this.userRoleCode;
    this.dashboardService.getRefundRequestInternalUserCount(this.refundRequestCountModel).subscribe(value => {
      this.rejectedRefundApplicationsCount = value;
    });
  }

  listNotifications() {
    this.dashboardService.getNotificationList().subscribe(
      data => {
        this.notifications = data.data;
        this.numberOfNotifications = this.notifications.length;
      }
    );
  }

  updateNotification(notificationId,deedNature, url) {

this.refundService.getRefundRequestByID(parseInt(url)).subscribe( (res) => {
   
  localStorage.removeItem('refundRequest');
  sessionStorage.removeItem('hasOpinion');
  sessionStorage.removeItem('deedNo');

  localStorage.setItem('refundRequest' , res.data.id);
  sessionStorage.setItem('hasOpinion', res.data.hasOpinion);
  sessionStorage.setItem('deedNo', res.data.deedNumber);
  sessionStorage.setItem('refundRequest','true');

        })

    this.notificationModelUpdate.notificationId = notificationId;

    this.dashboardService.updateNotification(this.notificationModelUpdate).subscribe(
      data => {
      }
    );
  }

  logout() {
    this.authService.logout();
  }

}
