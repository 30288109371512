import { RefundNotaryDetails } from './refundNotaryDetails';
import { RefundResonDetails } from './refundResonDetails';

export class refundOtherDetails{
deedNumber:number;
name_with_initials:string;
nic:string;
address_line_1:string;
contact_no:string;
office_no:string;
address_line_2:string;
city:string;
email:string;
status:string;

//refundNotaryDetails
initials:string;
nic_passport:string;
reg_no:string;
official_add_l1:string;
office_phone:string;
official_add_l2:string;
official_city:string;
notaryEmail:string;

//refundReasonDetails
reason_for_refund:string;
other_reason_for_refund:string;
ammount:number;
remark:string;

isSameGrantee:boolean;
isSameNotary:boolean;

}
