import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RefundOpinionServiceService } from 'src/app/service/RefundOpinionService/refund-opinion-service.service';
import { refundOpinionDetails } from 'src/app/model/refundOpinionDetails';
import { refundOpinionMainDetails } from 'src/app/model/refundOpinionMainDetails';
import { ValuationStatus } from 'src/app/model/valuation-status';


@Component({
  selector: 'app-view-opinion-details',
  templateUrl: './view-opinion-details.component.html',
  styleUrls: ['./view-opinion-details.component.scss']
})
export class ViewOpinionDetailsComponent implements OnInit {

  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();

  public refundOpinionDetailsModel = new refundOpinionMainDetails();

  public fileNumberFormat: string;
  public refundNumber: string;
  collectionPayment: any[]=[];
  opinionNo:number;
  verificationStatus: number = 0;
  public modelStatus = new ValuationStatus('', '');
  fileNo:String;

  constructor(
    private refundOpinionService: RefundOpinionServiceService
  ) { }

  ngOnInit() {
    this._prepare();
  }

  _prepare() {
    this.retrieveOpinionDetails();
  }

  retrieveOpinionDetails() {
    this.refundOpinionService.getOpinionDetailsByRefundRequestId(+localStorage.getItem('refundRequest')).subscribe(
      data => {
        this.refundOpinionDetailsModel = data.data;
        this.opinionNo = parseInt(data.data.deed_number);
        let fileNumber = data.data.file_number;
        let re =/SD\/OP/gi;
        this.refundNumber = fileNumber.replace(re,'R');
        localStorage.setItem('refundNumber',this.refundNumber);
        localStorage.setItem('fileNumber', data.data.file_number);
        sessionStorage.setItem('fileNo',data.data.file_number);
        this.getOpinionCollectionPayments();
      }
    )
  }

  public redirectComponent(): void {
    this.onPageCountChanged.emit(3);
  }

  public redirectPreviousComponent(): void {
    this.onPageCountChanged.emit(1);
  }
  getOpinionCollectionPayments(){
    this.refundOpinionService.getOpinionPaymentDetails(this.opinionNo).subscribe(data =>{
      this.collectionPayment = data.data;
    })
  }


  updateVerificationStatus(event){
    if (event.target.checked === true) {
      this.verificationStatus = 1;
    }else{
      this.verificationStatus = 0;
    }
    this.modelStatus.id = sessionStorage.getItem('fileNo');
    this.modelStatus.verificationStatus = '1';
    console.log(this.modelStatus,"staus")
    this.refundOpinionService.refundUpdateVerificationStatus(this.modelStatus).subscribe();
  }


}
