import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DeedNatureService } from 'src/app/service/deed-nature/deed-nature.service';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-delete-deed-nature',
  templateUrl: './delete-deed-nature.component.html',
  styleUrls: ['./delete-deed-nature.component.scss']
})
export class DeleteDeedNatureComponent implements OnInit, OnDestroy {

  private deedNatureId: number;
  private sub = new SubSink();

  @Output()deedNatureDeleted = new EventEmitter<boolean>();

  constructor(private deedNatureService: DeedNatureService, private notifier: NotifierService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  deleteDeedNature() {
  this.sub.add(this.deedNatureService.deleteDeedNature(this.deedNatureId)
    .subscribe(response => {
        this.updateTheView(true);
        this.notifier.notify( 'success', (response as any).message);
      }));
  }

  updateTheView(deleted: boolean) {
    this.deedNatureDeleted.emit(deleted);
  }

  setDeedNatureId(id: number) {
    this.deedNatureId = id;
  }

}






