import { Component, OnInit, ViewChild } from '@angular/core';
import { UpdateDocumentTypeComponent } from '../update-document-type/update-document-type.component';
import { DeleteDocumentTypeComponent } from '../delete-document-type/delete-document-type.component';
import { DocumentTypeService } from 'src/app/service/document-type/document-type.service';
import { DataService } from 'src/app/service/data.service';
import { SubSink } from 'subsink';
import {IDocumentType} from "../../../../../../model/interfaces/document-type";

@Component({
  selector: 'app-view-document-type',
  templateUrl: './view-document-type.component.html',
  styleUrls: ['./view-document-type.component.scss']
})
export class ViewDocumentTypeComponent implements OnInit {

  private sub = new SubSink();
  documentTypes: IDocumentType[];
  pageNo: number;

  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];

  @ViewChild(UpdateDocumentTypeComponent, { static: false })
  private updateDocumentTypeComponent: UpdateDocumentTypeComponent;
  @ViewChild(DeleteDocumentTypeComponent, { static: false })
  private deleteDocumentTypeComponent: DeleteDocumentTypeComponent;


  constructor(private documentTypeService: DocumentTypeService, private dataService: DataService) { }

  ngOnInit() {
    this.selectedPageNo = 0;
    this.limit = 10;

    this.documentTypeService.getAll().subscribe(
      response => {
        var res = response.data.length;
        this.pageNo = Math.round((res / 10)+1);

      }
    );

    this.sub.add(this.dataService.updateDataTableDocumentType
      .subscribe(update => this.onAdded(update)));

    this.sub.add(this.documentTypeService.getDocumentTypes(0, 10)
      .subscribe(
        (res) => {
          this.documentTypes = (res as any).data;
        }
      ));
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  pageable(i: number) {
    console.log("i" + i)
    console.log(i * 10 + " - " + ((i * 10) + 10));
    this.documentTypeService.getDocumentTypes((i), ((i * 10) + 10)).subscribe(responce => {
      this.documentTypes = (responce as any).data;
    });
  }
  counter(i: number) {
    return new Array(i);
  }
  triggerUpdate(id: number, code: string, name: string, description: string) {
    this.updateDocumentTypeComponent.setIdAndDescription(id, code, name, description);
  }

  triggerDelete(id: number) {
    this.deleteDocumentTypeComponent.setDocumentTypeId(id);
  }

  onUpdated(updatedSuccessfully: boolean) {
    if (updatedSuccessfully) {
      this.updateTheTable();
    }
  }

  onDeleted(deletedSuccessfully: boolean) {
    if (deletedSuccessfully) {
      this.updateTheTable();
    }
  }

  onAdded(addedSuccessfully: boolean) {
    if (addedSuccessfully) {
      this.updateTheTable();
    }
  }
  private updateTheTable() {
    this.documentTypeService.getDocumentTypes(0, 10).subscribe(
      (res) => {
        this.documentTypes = (res as any).data;
      });
  }

  searchDocumentType(keyword: string) {
    this.selectedPageNo = 0;
    this.sub.add(this.documentTypeService.searchDocumentType(keyword, this.selectedPageNo, this.limit)
      .subscribe((res) =>{
        this.documentTypes = res.data.content;
        this.paginationButtonCounter(res.data.totalPages);
        this.numOfPages = res.data.totalPages;
      })
    );
  }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

}
