import { InspectionBuildingConstructionDetail } from './inspection-building-construction-details';

export class InspectionBuildingConstruction {
  public buildingFeatureTypeId: string;
  public constructionDetails: Array<InspectionBuildingConstructionDetail>;

  constructor(
    buildingFeatureTypeId: string,
    constructionDetails: Array<InspectionBuildingConstructionDetail>
  ) {
    this.buildingFeatureTypeId = buildingFeatureTypeId;
    this.constructionDetails = constructionDetails;
  }
}
