import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PDFProgressData } from 'ng2-pdf-viewer';

import { FileService } from 'src/app/service/file/file.service';
import { IBuilding } from 'src/app/model/interfaces/building';
import { ValuationStatus } from 'src/app/model/valuation-status';
import { BuildingService } from 'src/app/service/building/building.service';
import { ValuationRequestService } from 'src/app/service/valuation-request/valuation-request.service';
import { Constant } from 'src/app/util/constant';
import { NotificationModel } from 'src/app/model/notification-model';
import { NotificationService } from 'src/app/service/notification/notification.service';
import { NotificationEmailSend } from 'src/app/model/notification-email-send';
import { ValuationRequestUpdate } from 'src/app/model/valuation-request-update';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-view-building-iu',
  templateUrl: './view-building-iu.component.html',
  styleUrls: ['./view-building-iu.component.scss']
})
export class ViewBuildingInternalUserComponent implements OnInit {
  getBuildingStatus:Subscription;

  @Input()
  buildings: IBuilding[];

  @Input()
  valuationRequestId: string;

  @Input()
  buildingOtherPartyId: string;

  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() status1: EventEmitter<any> = new EventEmitter<any>();

  buildingId: string;
  verificationStatus: number;

  public modelStatus = new ValuationStatus('', '');
  public notificationModel = new NotificationModel("", "", "", "", "", "", "");
;
  public valuationRequestUpdateModel = new ValuationRequestUpdate('', '', '', '', '', '');
  public notificationEmailSendModel = new NotificationEmailSend();
  selectFileSm = 0;
  typeID: any;
  previewFileData: any;
  type: any;
  deedNatureValue: any;
  currentUserRoleCode: string;
  userdata: any = [];
  public fileNumberFormat: string;
  public generatedFileNumber: string;
  valuationdata: any = [];
  public allowAccess: boolean;
  files;
  images = [];
  originalImages: any[] = [];
  filesList: File[] = [];
  names: string[] = [];
  public imgURL: any;
  public imgURL1: any;
  public documentUploaded: string;
  public documentUploaded1: string;
  totalFlowAreas: number;
  status:boolean;
  isExchangeDeedType:boolean;

  noDocs = false;
  dataDone = false;

  fileNumberFormatAvailable: boolean = true;

  constructor(
    private fileService: FileService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public buildingService: BuildingService,
    public valuationRequestService: ValuationRequestService,
    public router: Router,
    public modalService: NgbModal,
    private notifier: NotifierService,
    public notificationService: NotificationService,
    private spinner: NgxSpinnerService
  ) {
    this.allowAccess = false;
    this.getBuildingStatus = buildingService.getBuildingStatus().subscribe(()=>{
      this.ngOnInit();
    })

  }

  ngOnInit() {
    if (JSON.stringify(this.buildings) === '[]') {
      this.allowAccess = true;
    } else {
      this.allowAccess = false;
    }
    console.log(this.allowAccess)
    this._prepare();
    window.scroll(0,0);

    this.totalFlowAreas =  Math.round(this.buildings[0].totalFloorArea * 100) / 100;
    this.isExchangeDeedType = sessionStorage.getItem('deedType')==='exchange';
    if(this.isExchangeDeedType){
      this.getOtherPartyVerificationStatus()
    }
    console.log(this.buildingOtherPartyId)
  }

  _prepare() {
    this.fileNumberFormat = localStorage.getItem('fileNumberFormat');
    if (this.fileNumberFormat == "null" || this.fileNumberFormat == '') {
      this.fileNumberFormatAvailable = false;
    }

    this.valuationdata = JSON.parse(sessionStorage.getItem('valuationRequest'));
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.checkPermission();
    this.pad(this.valuationdata.id, 7);
    setTimeout(() => {
      if (this.buildings.length > 0) {
        this.buildingId = this.buildings[0]['id'];
        this.getVerificationStatus();
      }
    }, 1000);
    this.typeID = +this.route.snapshot.paramMap.get('id');
    this.retrieveAssignedValuationRequest();
  }

  checkPermission() {
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.currentUserRoleCode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.currentUserRoleCode = Constant.ROLE_ACCESSOR;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER){
        this.currentUserRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.currentUserRoleCode = Constant.ROLE_COMMISSIONER;
        break;
      }
    }
  }

  public checkStatus(type:boolean): void{
    this.status1.emit(type);
  }

  retrieveAssignedValuationRequest() {
    this.valuationRequestService.valuationRequestAssigned(this.route.snapshot.paramMap.get('id')).subscribe(
      data => {
        if (data.data !== null) {
          this.valuationRequestUpdateModel.dcommissionerId = data.data.dcommissionerId;
          this.valuationRequestUpdateModel.acccessorId = data.data.accessorId;
          this.valuationRequestUpdateModel.remark = data.data.remark;
        } else {
          console.log("Valuation Request not found");
        }
      }
    )
  }

  pad(num: number, size: number) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    this.generatedFileNumber = s;
    this.generatedFileNumber = '#VR' + this.generatedFileNumber;
  }

  public redirectComponent(): void {
    this.onPageCountChanged.emit(7);
  }

  public redirectPreviousComponent(): void {
    this.onPageCountChanged.emit(5);
  }

  getVerificationStatus() {
    this.modelStatus.id = this.buildingId;
    this.buildingService.buildingVerificationStatus(this.modelStatus).subscribe(
      data => {
        this.verificationStatus = data.data;
      }
    )
  }

  getOtherPartyVerificationStatus(){
    this.modelStatus.id = this.buildingOtherPartyId;
    this.buildingService.buildingVerificationStatus(this.modelStatus).subscribe(
      data => {
        this.status = data.data;
        console.log(this.status)
      }
    )
  }

  updateVerificationStatus(event) {
    this.modelStatus.id = this.buildingId;
    if (event.target.checked === true) {
      this.modelStatus.verificationStatus = '1';
      this.buildingService.buildingUpdateVerificationStatus(this.modelStatus).subscribe(
        data => {
          this.getVerificationStatus();
          if(this.isExchangeDeedType){
            this.sendBuildingStatusChange();
            this.getOtherPartyVerificationStatus();
          }
        }
      )
    } else {
      this.modelStatus.verificationStatus = '0';
      this.buildingService.buildingUpdateVerificationStatus(this.modelStatus).subscribe(
        data => {
          this.getVerificationStatus();
          if(this.isExchangeDeedType){
            this.sendBuildingStatusChange();
            this.getOtherPartyVerificationStatus();
          }
        }
      )
    }
    this.checkStatus(true);
  }

  sendBuildingStatusChange(){
    this.buildingService.sendBuildingStatus();
  }

  onProgress(progressData: PDFProgressData) {
    if (progressData.loaded == progressData.total) {
      this.spinner.hide();
    }
  }

  previewFile(type) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadFileById(this.valuationRequestId, type).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(()=>{
          this.spinner.hide();
        }, 2000);

      }else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }
}
