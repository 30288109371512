import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProvinceService } from '../../../../../../service/province/province.service';
import { from } from 'rxjs';
import { Province } from 'src/app/model/province';
import { SubSink } from 'subsink';
import { DataService } from 'src/app/service/data.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-add-province',
  templateUrl: './add-province.component.html',
  styleUrls: ['./add-province.component.scss']
})
export class AddProvinceComponent implements OnInit, OnDestroy {

  addProvinceForm: FormGroup;
  private sub = new SubSink();
  constructor(private formBuilder: FormBuilder, private provinceService: ProvinceService, private dataService: DataService, private notifier: NotifierService) { }

  ngOnInit() {
    this.addProvinceForm = this.formBuilder.group({
      code: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  saveProvince() {
    if (this.addProvinceForm.valid) {

      const province = new Province();

      province.description = this.getDescription();
      province.code = this.getCode();
      this.sub.add(this.provinceService.createProvince(province)
        .subscribe(response => {
          this.notifier.notify('success', (response as any).message);
          this.dataService.updateTableProvince(true);
        }));
    }
  }
  getDescription() {
    return this.addProvinceForm.get('description').value;
  }

  getCode() {
    return this.addProvinceForm.get('code').value;
  }


}
