import { OfficeService } from "src/app/service/office/office.service";
import {
  Component,
  OnDestroy,
  OnInit,  
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NotifierService } from "angular-notifier";
import { Employee } from "src/app/model/employee";
import { DataService } from "src/app/service/data.service";
import { EmployeeService } from "src/app/service/employee/employee.service";
import { SubSink } from "subsink";
import * as moment from 'moment';

@Component({
  selector: "app-create-employee",
  templateUrl: "./create-employee.component.html",
  styleUrls: ["./create-employee.component.scss"],
})
export class CreateEmployeeComponent implements OnInit, OnDestroy {  

  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  title: any;
  createEmployeeForm: FormGroup;
  maxDate: Date;
  allOffice: any;
  public employeeModel = new Employee(
    null,
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    ""
  );

  private sub = new SubSink();
  // Validations
  nicPattern =
    "^([0-9]{9}[x|X|v|V]|[0-9]{12}|k[a-p|A-p|R|r|S|s|T|t|U|u|V|v|W|w|Y|y][0-9]{8}|[0-9]{7}[a-p|A-p|R|r|S|s|T|t|U|u|V|v|W|w|Y|y])$";
  emailPattern =
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
  phoneNumberPattern =
    "^(?:0|94|\\+94|0094)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|91)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\\d)\\d{6}$";
  onlyTextPattern = "^[ a-zA-Z]*$";
  onlyTextPatternInitals = "^[ a-zA-Z.]*$";
  addressNumberPattern = /^[ a-zA-Z0-9()-./,\']*$/;
  addressPattern = "^[ a-zA-Z0-9()-.,/]*$";
  cityPattern = "^[ a-zA-Z0-9-]*$";
  futureDateError: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private employeeService: EmployeeService,
    private notifier: NotifierService,
    private dataService: DataService,
    public officeService: OfficeService
  ) {}

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.createEmployeeForm = this.formBuilder.group({
      title: ["", [Validators.required]],
      firstName: [
        "",
        [
          Validators.required,
          Validators.pattern(this.onlyTextPattern),
          Validators.pattern(this.NoWhitespaceRegExp),
        ],
      ],
      lastName: [
        "",
        [
          Validators.required,
          Validators.pattern(this.onlyTextPattern),
          Validators.pattern(this.NoWhitespaceRegExp),
        ],
      ],
      middleName: [
        "",
        [
          Validators.pattern(this.onlyTextPattern),
          Validators.pattern(this.NoWhitespaceRegExp),
        ],
      ],
      nic: [
        "",
        [
          Validators.required,
          Validators.pattern(this.nicPattern),
          Validators.pattern(this.NoWhitespaceRegExp),
        ],
      ],
      designation: [
        "",
        [Validators.required, Validators.pattern(this.NoWhitespaceRegExp)],
      ],
      districtOffice: ["null", [Validators.required]],
      appointedDate: ["", [Validators.required]],
      address: [
        "",
        [Validators.required, Validators.pattern(this.NoWhitespaceRegExp)],
      ],
      mobile: [
        "",
        [
          Validators.required,
          Validators.pattern(this.phoneNumberPattern),
          Validators.pattern(this.NoWhitespaceRegExp),
        ],
      ],
      office: [
        "",
        [
          Validators.required,
          Validators.pattern(this.phoneNumberPattern),
          Validators.pattern(this.NoWhitespaceRegExp),
        ],
      ],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(this.emailPattern),
          Validators.email,
          Validators.pattern(this.NoWhitespaceRegExp),
        ],
      ],
    });

    this.maxDate = new Date();
    this.getAllOffice();

  }

  getAllOffice() {
    this.officeService.getAll().subscribe((data) => {
      this.allOffice = data.data;
    });
  }
  selectTitle($event) {
    this.title = $event.target.value;
  }

  get f() {
    return this.createEmployeeForm.controls;
  }

  setDataToModel() {
    this.employeeModel.title = this.createEmployeeForm.get("title").value;
    this.employeeModel.firstName =
      this.createEmployeeForm.get("firstName").value;
    this.employeeModel.lastName = this.createEmployeeForm.get("lastName").value;
    this.employeeModel.middleName =
      this.createEmployeeForm.get("middleName").value;
    this.employeeModel.nic = this.createEmployeeForm.get("nic").value;
    this.employeeModel.designation =
      this.createEmployeeForm.get("designation").value;
    this.employeeModel.districtOffice =
      this.createEmployeeForm.get("districtOffice").value;
      

    this.employeeModel.appointedDate =
      this.createEmployeeForm.get("appointedDate").value;

    this.employeeModel.address = this.createEmployeeForm.get("address").value;
    this.employeeModel.mobileNo = this.createEmployeeForm.get("mobile").value;
    this.employeeModel.officeNo = this.createEmployeeForm.get("office").value;
    this.employeeModel.email = this.createEmployeeForm.get("email").value;
    this.employeeModel.status = "ACTIVE";
  }

  saveEmployee() {
    if (this.createEmployeeForm.valid) {
      this.setDataToModel();
      this.sub.add(
        this.employeeService
          .createEmployee(this.employeeModel)
          .subscribe((data) => {
            this.notifier.notify("success", (data as any).message);
            this.dataService.updateEmployee(true);
            this.createEmployeeForm.reset();
          })
      );
    } else {
      this.createEmployeeForm.markAllAsTouched();
    }
  }

  reset() {
    this.createEmployeeForm.reset();
  }

  checkDateValidity($event) {
    if (!moment(new Date()).isAfter(moment($event.target.value))) {
      this.createEmployeeForm.controls.appointedDate.setErrors({invalidDate:true})
    }else{
      this.createEmployeeForm.controls.appointedDate.setErrors(null)
    }
  }

}
