import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UpdateOfficeComponent } from '../update-office/update-office.component';
import { DeleteOfficeComponent } from '../delete-office/delete-office.component';
import { OfficeService } from 'src/app/service/office/office.service';
import { SubSink } from 'subsink';
import { DataService } from 'src/app/service/data.service';
import {ICompanyCategory} from '../../../../../../model/interfaces/company-category';
import {IOffice} from '../../../../../../model/interfaces/office';


@Component({
  selector: 'app-view-office',
  templateUrl: './view-office.component.html',
  styleUrls: ['./view-office.component.scss']
})
export class ViewOfficeComponent implements OnInit, OnDestroy {

  @ViewChild(UpdateOfficeComponent, { static: false })
  private updateOfficeComponent: UpdateOfficeComponent;
  @ViewChild(DeleteOfficeComponent, { static: false })
  private deleteOfficeComponent: DeleteOfficeComponent;


  offices : IOffice [];
  private sub = new SubSink();


  updateTable: boolean;

  // pagination
  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];
  currentPage: any;
  totalItems: any;
  constructor(private officeService: OfficeService,private dataService:DataService) { }
pageNo:number;
  ngOnInit() {
    this.selectedPageNo = 0;
    this.limit = 10;
    // this.officeService.getAll().subscribe(
    //   response=>{
    //     var res = response.data.length;
    //     this.pageNo = Math.round((res / 10)+1);
    //   }
    // )

    this.sub.add(this.dataService.updateDataTableOffice
      .subscribe(update => this.onAdded(update)));

    this.sub.add(this.officeService.getOffices(0,10)
    .subscribe(
      (res) => {
        this.offices = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      }
    ));
  }
  ngOnDestroy() {
    this, this.sub.unsubscribe();
  }


  triggerUpdate(id: number, code: string, office: string) {
    this.updateOfficeComponent.setIdAndDescription(id, code, office);
  }

  triggerDelete(id: number) {
    this.deleteOfficeComponent.setOfficeId(id);
  }

  onUpdated(updatedSuccessfully: boolean) {
    if (updatedSuccessfully) {
      this.updateTheTable();
    }
  }

  onDeleted(deletedSuccessfully: boolean) {
    if (deletedSuccessfully) {
      this.updateTheTable();
    }
  }
  onAdded(addedSuccessfully: boolean) {
    if (addedSuccessfully) {
      this.updateTheTable();
    }
  }
  pageble(i:number){

    this.officeService.getOffices((i-1),10).subscribe(responce=>{
      this.offices = (responce as any).data.content;
      this.currentPage = (responce as any).data.number+1;
      this.totalItems = (responce as any).data.totalElements;
    });
  }
  counter(i:number){
    return new Array(i);
  }

  /** search company cattagery */
  searchOffice(keyword: string) {
    this.selectedPageNo = 0;
    this.sub.add(this.officeService.searchOffice(keyword, this.selectedPageNo, this.limit)
      .subscribe((res) => {
        // this.offices =  res.data.content;
        // this.paginationButtonCounter(res.data.totalPages);
        // this.numOfPages = res.data.totalPages;
        this.offices = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      }));
  }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

  // update the table
  private updateTheTable() {
    this.sub.add(this.officeService.getOffices(0,10)
    .subscribe(
      (res) => {
        this.offices = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      }));
  }


}
