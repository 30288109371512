import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PropertyFacilityService } from 'src/app/service/property-facility/property-facility.service';
import { PropertyFacility } from 'src/app/model/property-facility';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-update-property-facility',
  templateUrl: './update-property-facility.component.html',
  styleUrls: ['./update-property-facility.component.scss']
})
export class UpdatePropertyFacilityComponent implements OnInit, OnDestroy {

  updatePropertyFacilityForm: FormGroup;

  private sub = new SubSink();

  @Output() updatePropertyFacility = new EventEmitter<boolean>();


  constructor(private formBuilder: FormBuilder,
              private propertyFacilityService: PropertyFacilityService,
              private notifier: NotifierService) { }

  ngOnInit() {

    this.updatePropertyFacilityForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      code: ['', [Validators.required]],
      name: ['', [Validators.required]],
      description: ['']
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  getId() {
    return this.updatePropertyFacilityForm.get('id').value;
  }
  setIdAndDescription(id: number, code: string, name: string, description: string) {
    this.updatePropertyFacilityForm.get('id').setValue(id);
    this.updatePropertyFacilityForm.get('code').setValue(code);
    this.updatePropertyFacilityForm.get('name').setValue(name);
    this.updatePropertyFacilityForm.get('description').setValue(description);
  }

  getDescription() {
    return this.updatePropertyFacilityForm.get('description').value;
  }
  getCode() {
    return this.updatePropertyFacilityForm.get('code').value;
  }
  getName() {
    return this.updatePropertyFacilityForm.get('name').value;
  }
  updateTheView(updated: boolean) {
    this.updatePropertyFacility.emit(updated);
  }

  updatePropertyFacilities() {
    if (this.updatePropertyFacilityForm.valid) {
      const propertyFacility = new PropertyFacility();
      propertyFacility.description = this.getDescription();
      propertyFacility.code = this.getCode();
      propertyFacility.name = this.getName();
      this.sub.add(this.propertyFacilityService.updatePropertyFacility(this.getId(), propertyFacility)
        .subscribe(response => {

          this.updateTheView(true);
          this.notifier.notify('success', (response as any).message);
        }));
    }
  }


}
