import { FormControl, FormGroup } from '@angular/forms';
import { Injectable } from "@angular/core";

export function FutureDateValidator(controlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const inputDate = new Date(control.value);
    const maxDate = new Date();
    if (inputDate > maxDate) {
      control.setErrors({ dateEntered: true });
    } else {
      control.setErrors(null);
    }
    return;
  };
}
