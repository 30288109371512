import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UpdateCompanyCategoryComponent } from '../../../company/company-category/update-company-category/update-company-category.component';
import { DeleteCompanyCategoryComponent } from '../../../company/company-category/delete-company-category/delete-company-category.component';
import { CompanyCategoryService } from '../../../../../../../service/company-category/company-category.service';
import { UpdatePropertyUsageComponent } from '../update-property-usage/update-property-usage.component';
import { PropertyUsageService } from '../../../../../../../service/property-usage/property-usage.service';
import { DeletePropertyUsageComponent } from '../delete-property-usage/delete-property-usage.component';
import { DataService } from '../../../../../../../service/data.service';
import { SubSink } from 'subsink';
import {PropertyUsage} from '../../../../../../../model/propertyUsage';

@Component({
  selector: 'app-view-property-usage',
  templateUrl: './view-property-usage.component.html',
  styleUrls: ['./view-property-usage.component.scss']
})
export class ViewPropertyUsageComponent implements OnInit, OnDestroy {

  @ViewChild(UpdatePropertyUsageComponent, { static: false })
  private updatePropertyUsageComponent: UpdatePropertyUsageComponent;
  @ViewChild(DeletePropertyUsageComponent, { static: false })
  private deletePropertyUsageComponent: DeletePropertyUsageComponent;

  private sub = new SubSink();
  propertyUsages: PropertyUsage [];
  pageNo: number;

  // pagination
  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];
  currentPage: any;
  totalItems: any;

  constructor(private propertyUsageService: PropertyUsageService,
              private dataService: DataService) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.propertyUsageService.getAll().subscribe(
      response => {
        var res = response.data.length;
        this.pageNo = Math.round((res / 10)+1);

      })

    this.sub.add(this.dataService.updateDataTablePropertyUsage.subscribe(update => {
      this.onAdded(update);
    }));

    this.sub.add(this.propertyUsageService.getPropertyUsages(0, 10).subscribe(
      (res) => {
        // this.propertyUsages = (res as any).data;
        this.propertyUsages = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      }));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  pageable(i: number) {
    this.propertyUsageService.getPropertyUsages((i-1),10).subscribe(responce => {
      this.propertyUsages = (responce as any).data.content;
      this.currentPage = (responce as any).data.number+1;
      this.totalItems = (responce as any).data.totalElements;
    });
  }
  counter(i: number) {
    return new Array(i);
  }

  triggerUpdate(id: number, code: string, name: string, description: string) {
    this.updatePropertyUsageComponent.setIdAndDescription(id, code, name, description);
  }

  triggerDelete(id: number) {
    this.deletePropertyUsageComponent.setPropertyUsageId(id);
  }

  onUpdated(updatedSuccessfully: boolean) {
    if (updatedSuccessfully) {
      this.updateTheTable();
    }
  }

  onDeleted(deletedSuccessfully: boolean) {
    if (deletedSuccessfully) {
      this.updateTheTable();
    }
  }
  onAdded(isAdded: boolean) {
    if (isAdded) {
      this.updateTheTable();
    }
  }
  // update the table
  private updateTheTable() {
    this.sub.add(this.propertyUsageService.getPropertyUsages(0,10).subscribe(
      (res) => {
        // this.propertyUsages = (res as any).data;
        this.propertyUsages = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      }));
  }

  // search the province table
  searchPropertyUsage(keyword: string) {
    this.selectedPageNo = 0;
    this.sub.add(this.propertyUsageService.searchPropertyUsage(keyword, this.selectedPageNo, this.limit)
      .subscribe((res) => {
        // this.propertyUsages = res.data.content;
        // this.paginationButtonCounter(res.data.totalPages);
        // this.numOfPages = res.data.totalPages;
        this.propertyUsages = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      }));
  }
  searchPropertyUsageById(keyword: number) {
    this.selectedPageNo = 0;
    this.sub.add(this.propertyUsageService.searchPropertyUsageById(keyword)
      .subscribe((res) => {
        this.propertyUsages = [];
        this.propertyUsages[0]=  (res as any).data;
        this.currentPage = 1;
        this.totalItems = 1;
      }));
  }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

}
