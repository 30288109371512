import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, MaxLengthValidator, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { InspectionTownModel } from 'src/app/model/inspection-master-data-model/inspection-town-model';
import { InspectionTownService } from 'src/app/service/inspection-master-data-service/inspection-town/inspection-town.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inspection-town',
  templateUrl: './inspection-town.component.html',
  styleUrls: ['./inspection-town.component.scss']
})
export class InspectionTownComponent implements OnInit,OnDestroy {


  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  createTownForm: FormGroup;
  updateTownForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  onlyNumberPattern = '^[0-9]*$';
  propertyTypeList: any =[];
  activationButton: boolean;
  public createTownModel = new InspectionTownModel(null,null,'','','');
  public updateTownModel = new InspectionTownModel(null,null,'','','');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;
  Towns: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionTownService: InspectionTownService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createTownForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.onlyNumberPattern),Validators.maxLength(9),Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['',Validators.pattern(this.NoWhitespaceRegExp)],
    });
    this.updateTownForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.onlyNumberPattern),Validators.maxLength(9),Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['',[Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
    });

    // this.loadPropertyType();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }



  //create Health Type - start

  get f() { return this.createTownForm.controls; }

  setTownModelToSave(){
    this.createTownModel.townNum = this.createTownForm.get('name').value;
    this.createTownModel.description = this.createTownForm.get('description').value;
    this.createTownModel.code = this.createTownForm.get('code').value;
    this.createTownModel.dataStatus = "ACTIVE";
  }

    //save Health Type model
  saveTown(){
    this.inspectionTownService.getTown(0,this.totalItems).subscribe(res=>{
    const allTown = (res as any).data.content
    if (allTown.map((town)=>Number(town.townNum)).includes(Number(this.createTownForm.get('name').value))){
      this.notifier.notify('error', 'Town number '+ this.createTownForm.get('name').value +' is exist');
      return;
    }
    this.setTownModelToSave();
    this.sub.add(
      this.inspectionTownService.createTown(this.createTownModel).subscribe(data=>{
        this.notifier.notify('success', (data as any).message);
        this.updateTheTable();
        this.reset();
      })
      );
  })
  }

  reset(){
    this.createTownForm.reset()
  }
  //create Health Type - end

  //update Health Type - start

    //get update controllers
  get f1() { return this.updateTownForm.controls; }

    //set data To Health Type Type model
  setTown(health:any){
    this.updateTownForm.get('id').setValue(health.id);
    this.updateTownForm.get('name').setValue(health.townNum);
    this.updateTownForm.get('description').setValue(health.description);
    this.updateTownForm.get('code').setValue(health.code);

    if(health.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateTownModel.id = this.updateTownForm.get('id').value;
    this.updateTownModel.townNum = this.updateTownForm.get('name').value;
    this.updateTownModel.description = this.updateTownForm.get('description').value;
    this.updateTownModel.code = this.updateTownForm.get('code').value;

    if(this.activationButton == true){
      this.updateTownModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateTownModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateTown(){
    this.inspectionTownService.getTown(0,this.totalItems).subscribe(res=>{
      const allTown = (res as any).data.content
      if (allTown.map((town)=>Number(town.townNum)).includes(Number(this.updateTownForm.get('name').value))){
        this.notifier.notify('error', 'Town number '+ this.updateTownForm.get('name').value +' is exist');
        return;
      }
    this.setDataToModel();
    this.sub.add(this.inspectionTownService.updateTown(this.updateTownModel.id, this.updateTownModel).subscribe(data=>{
      this.notifier.notify('success', (data as any).message);
      this.updateTheTable();
    }))
  })
  }

  //update Health Type - end

//search and view table start
  pageable(i: number) {
    this.inspectionTownService.getTown((i-1),10).subscribe(res=>{
      this.Towns = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionTownService.getTown(0, 10)
      .subscribe(
        (res) => {
          this.Towns = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search Health Type Type
    searchTown(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionTownService.searchTown(this.keyword,0,10).subscribe(res=>{
          this.Towns = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionTownService.deleteTown(this.selectedId).subscribe(data=>{

        this.notifier.notify('success', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end

}
