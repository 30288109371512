import { Injectable } from '@angular/core';
import { InspectionLand } from 'src/app/model/inspection-land';
import { InspectionCondominium } from 'src/app/model/inspection-condominium';
import { InspectionLandBuilding } from 'src/app/model/inspection-land-building';

@Injectable({
  providedIn: 'root'
})
export class InspectionDataServiceService {

  public inspectionLand: InspectionLand;
  public inspectionCondominium: InspectionCondominium;
  public inspectionLandBuilding: InspectionLandBuilding;

  constructor() { }

  public setInspectionLand(inspectionLand: any) {
    this.inspectionLand = inspectionLand;
  }

  public setInspectionCondominium(inspectionCondominium: any) {
    this.inspectionCondominium = inspectionCondominium;
  }

  public setInspectionLandBuilding(inspectionLandBuilding: any) {
    this.inspectionLandBuilding = inspectionLandBuilding;
  }

  public getInspectionLand(): any {
    return this.inspectionLand;
  }

  public getInspectionCondominium(): any {
    return this.inspectionCondominium;
  }

  public getInspectionLandBuilding(): any {
    return this.inspectionLandBuilding;
  }
}
