import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NotifierService } from "angular-notifier";
import { environment } from "../../../environments/environment";
import { ValuationRequestCount } from "src/app/model/valuation_request_count";
import { Observable } from "rxjs";
import { NotificationModelUpdate } from "src/app/model/notification-model-update";
import { RefundRequestCount } from "src/app/model/refundRequestCount";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  private request = "/valuation-request";
  private notificationUrl = "/notification";
  private refundRequestUrl = "/refund-opinion";
  private rimAplicationUrl = "/reimbursement-application";

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };
  constructor(private http: HttpClient, private notifier: NotifierService) {}

  getRequestCount(type: string, mode: string) {
    return this.http.get<number>(
      environment.api_url + this.request + "/request/" + mode + "/" + type
    );
  }

  getRefundRequestCount(type: string, mode: string) {
    return this.http.get<number>(
      environment.api_url + this.refundRequestUrl + "/request/" + mode + "/" + type
    );
  }

  getRequestInternalUserCount(
    valuationRequestCount: ValuationRequestCount
  ): Observable<any> {
    return this.http.post<any>(
      environment.api_url + "/valuation-request/count",
      valuationRequestCount
    );
  }

  getRequestInternalAppealUserCount(
    valuationRequestCount: ValuationRequestCount
  ): Observable<any> {
    return this.http.post<any>(
      environment.api_url + "/valuation-request/appeal/count",
      valuationRequestCount
    );
  }

  getRefundRequestInternalUserCount(
    refundRequestCountModel: RefundRequestCount
  ): Observable<any> {
    return this.http.post<any>(
      environment.api_url + this.refundRequestUrl + "/application-count",
      refundRequestCountModel
    );
  }

  getRimAplicationCount(
    refundRequestCountModel: RefundRequestCount
  ): Observable<any> {
    return this.http.post<any>(
      environment.api_url + this.rimAplicationUrl + "/rim-application-count",
      refundRequestCountModel
    );
  }

  getNotificationList(): Observable<any> {
    return this.http.get<any>(
      `${environment.api_url}` + this.notificationUrl + "/all"
    );
  }

  updateNotification(
    notificationModelUpdate: NotificationModelUpdate
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.api_url}` + this.notificationUrl + "/updateStatus",
      notificationModelUpdate
    );
  }
}
