import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InspectionElectricityTypeService {

  private inspectionElectricityTypeUrl = '/inspection-electricity-type';


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  createElectricityType(ElectricityTypeModel:InspectionCommonDataModel):Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionElectricityTypeUrl}`,ElectricityTypeModel)
  }

  getElectricityType(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.inspectionElectricityTypeUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  updateElectricityType(id: number, ElectricityTypeModel:InspectionCommonDataModel): Observable<any> {
    return this.http.put(`${environment.api_url}${this.inspectionElectricityTypeUrl}/${id}`, ElectricityTypeModel);
  }

  searchElectricityType(keyWord:string,offset, limit):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.inspectionElectricityTypeUrl +'/search?keyword='+ keyWord + '&offset=' + offset + '&limit=' + limit + '');
  }

  searchElectricityTypeById(id:number):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.inspectionElectricityTypeUrl +'/'+ id);
  }

  deleteElectricityType(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.inspectionElectricityTypeUrl}/${id}`);
  }

  getAllElectricityType():Observable<any> {
    return this.http.get(`${environment.api_url}${this.inspectionElectricityTypeUrl}/all`)
  }
}
