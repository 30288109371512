import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./component/login/login.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ErrorInterceptor } from "./auth/error.interceptor";
import { RequestInterceptor } from "./auth/request.interceptor";
import { DashboardExternalComponent } from "./component/dashboards/dashboard-external/dashboard-external.component";
import { RegistrationComponent } from "./component/registration/registration.component";
import { TopNavigationComponent } from "./component/dashboards/dashboard-external/top-navigation/top-navigation.component";
import { NewApplicationComponent } from "./component/dashboards/dashboard-external/assesment/new-application/new-application.component";
import { ViewAssessmentRequestComponent } from "./component/dashboards/dashboard-external/assesment/view-assesment-request/view-assessment-request.component";
import { DocumentUploadComponent } from "./component/document/document-upload/document-upload.component";
import { DocumentEditComponent } from "./component/document/document-edit/document-edit.component";
import { DocumentPreviewComponent } from "./component/document/document-preview/document-preview.component";
import { SideNavigationComponent } from "./component/dashboards/dashboard-internal/side-navigation/side-navigation.component";
import { DashboardInternalComponent } from "./component/dashboards/dashboard-internal/dashboard-internal.component";
import { ApproveRegistrationComponent } from "./component/dashboards/dashboard-internal/registration-approval/approve-registration/approve-registration.component";
import { ViewAllRegistrationComponent } from "./component/dashboards/dashboard-internal/registration-approval/view-all-registration/view-all-registration.component";
import { HeaderInternalComponent } from "./component/dashboards/dashboard-internal/header-internal/header-internal.component";
import { HeaderExternalComponent } from "./component/dashboards/dashboard-external/header-external/header-external.component";
import { AdminComponent } from "./component/dashboards/dashboard-internal/admin/admin.component";
import { AdminSidenavComponent } from "./component/dashboards/dashboard-internal/admin/admin-sidenav/admin-sidenav.component";
import { CompanyCategoryComponent } from "./component/dashboards/dashboard-internal/admin/company/company-category/company-category.component";
import { CompanyTypeComponent } from "./component/dashboards/dashboard-internal/admin/company/company-type/company-type.component";
import { ProvinceComponent } from "./component/dashboards/dashboard-internal/admin/province/province.component";
import { DistrictComponent } from "./component/dashboards/dashboard-internal/admin/district/district.component";
import { GramaNiladariDivisionComponent } from "./component/dashboards/dashboard-internal/admin/grama-niladari-division/grama-niladari-division.component";
import { DocumentTypeComponent } from "./component/dashboards/dashboard-internal/admin/document-type/document-type.component";
import { FloorSubTypeComponent } from "./component/dashboards/dashboard-internal/admin/floor-sub-type/floor-sub-type.component";
import { FloorTypeComponent } from "./component/dashboards/dashboard-internal/admin/floor-type/floor-type.component";
import { BuildingTypeComponent } from "./component/dashboards/dashboard-internal/admin/building-type/building-type.component";
import { BuildnigSubTypeComponent } from "./component/dashboards/dashboard-internal/admin/buildnig-sub-type/buildnig-sub-type.component";
import { LocalAuthorityComponent } from "./component/dashboards/dashboard-internal/admin/local-authority/local-authority.component";
import { OfficeComponent } from "./component/dashboards/dashboard-internal/admin/office/office.component";
import { PaymentMethodComponent } from "./component/dashboards/dashboard-internal/admin/payment-method/payment-method.component";
import { AddCompanyCategoryComponent } from "./component/dashboards/dashboard-internal/admin/company/company-category/add-company-category/add-company-category.component";
import { SearchCompanyCategoryComponent } from "./component/dashboards/dashboard-internal/admin/company/company-category/search-company-category/search-company-category.component";
import { ViewCompanyCategoryComponent } from "./component/dashboards/dashboard-internal/admin/company/company-category/view-company-category/view-company-category.component";
import { AddDistrictComponent } from "./component/dashboards/dashboard-internal/admin/district/add-district/add-district.component";
import { ViewDistrictComponent } from "./component/dashboards/dashboard-internal/admin/district/view-district/view-district.component";
import { SearchDistrictComponent } from "./component/dashboards/dashboard-internal/admin/district/search-district/search-district.component";
import { UpdateCompanyCategoryComponent } from "./component/dashboards/dashboard-internal/admin/company/company-category/update-company-category/update-company-category.component";
import { AddCompanyTypeComponent } from "./component/dashboards/dashboard-internal/admin/company/company-type/add-company-type/add-company-type.component";
import { SearchCompanyTypeComponent } from "./component/dashboards/dashboard-internal/admin/company/company-type/search-company-type/search-company-type.component";
import { ViewCompanyTypeComponent } from "./component/dashboards/dashboard-internal/admin/company/company-type/view-company-type/view-company-type.component";
import { AddDeedNatureComponent } from "./component/dashboards/dashboard-internal/admin/deed-nature/deed-nature/add-deed-nature/add-deed-nature.component";
import { ViewDeedNatureComponent } from "./component/dashboards/dashboard-internal/admin/deed-nature/deed-nature/view-deed-nature/view-deed-nature.component";
import { SearchDeedNatureComponent } from "./component/dashboards/dashboard-internal/admin/deed-nature/deed-nature/search-deed-nature/search-deed-nature.component";
import { DeedNatureComponent } from "./component/dashboards/dashboard-internal/admin/deed-nature/deed-nature/deed-nature.component";
import { AddFloorTypeComponent } from "./component/dashboards/dashboard-internal/admin/floor-type/add-floor-type/add-floor-type.component";
import { SearchFloorTypeComponent } from "./component/dashboards/dashboard-internal/admin/floor-type/search-floor-type/search-floor-type.component";
import { ViewFloorTypeComponent } from "./component/dashboards/dashboard-internal/admin/floor-type/view-floor-type/view-floor-type.component";
import { AddProvinceComponent } from "./component/dashboards/dashboard-internal/admin/province/add-province/add-province.component";
import { ViewProvinceComponent } from "./component/dashboards/dashboard-internal/admin/province/view-province/view-province.component";
import { SearchProvinceComponent } from "./component/dashboards/dashboard-internal/admin/province/search-province/search-province.component";
// import { AddPropertyTypeComponent } from './component/dashboards/dashboard-internal/admin/proprty-type/add-property-type/add-property-type.component';
// import { ViewPropertyTypeComponent } from './component/dashboards/dashboard-internal/admin/proprty-type/view-property-type/view-property-type.component';
// import { SearchPropertyTypeComponent } from './component/dashboards/dashboard-internal/admin/proprty-type/search-property-type/search-property-type.component';
import { AddDocumentTypeComponent } from "./component/dashboards/dashboard-internal/admin/document-type/add-document-type/add-document-type.component";
import { SearchDocumentTypeComponent } from "./component/dashboards/dashboard-internal/admin/document-type/search-document-type/search-document-type.component";
import { DeleteCompanyCategoryComponent } from "./component/dashboards/dashboard-internal/admin/company/company-category/delete-company-category/delete-company-category.component";
import { DeleteCompanyTypeComponent } from "./component/dashboards/dashboard-internal/admin/company/company-type/delete-company-type/delete-company-type.component";
import { UpdateCompanyTypeComponent } from "./component/dashboards/dashboard-internal/admin/company/company-type/update-company-type/update-company-type.component";
import { DeleteDeedNatureComponent } from "./component/dashboards/dashboard-internal/admin/deed-nature/deed-nature/delete-deed-nature/delete-deed-nature.component";
import { UpdateDeedNatureComponent } from "./component/dashboards/dashboard-internal/admin/deed-nature/deed-nature/update-deed-nature/update-deed-nature.component";
import { DeleteFloorTypeComponent } from "./component/dashboards/dashboard-internal/admin/floor-type/delete-floor-type/delete-floor-type.component";
import { UpdateFloorTypeComponent } from "./component/dashboards/dashboard-internal/admin/floor-type/update-floor-type/update-floor-type.component";
// mport { DeletePropertyTypeComponent } from './component/dashboards/dashboard-internal/admin/proprty-type/delete-property-type/delete-property-type.component';
// imporit { UpdatePropertyTypeComponent } from './component/dashboards/dashboard-internal/admin/proprty-type/update-property-type/update-property-type.component';
import { DeleteProvinceComponent } from "./component/dashboards/dashboard-internal/admin/province/delete-province/delete-province.component";
import { UpdateProvinceComponent } from "./component/dashboards/dashboard-internal/admin/province/update-province/update-province.component";
import { DeleteDocumentTypeComponent } from "./component/dashboards/dashboard-internal/admin/document-type/delete-document-type/delete-document-type.component";
import { UpdateDocumentTypeComponent } from "./component/dashboards/dashboard-internal/admin/document-type/update-document-type/update-document-type.component";
import { AddPaymentMethodComponent } from "./component/dashboards/dashboard-internal/admin/payment-method/add-payment-method/add-payment-method.component";
import { DeletePaymentMethodComponent } from "./component/dashboards/dashboard-internal/admin/payment-method/delete-payment-method/delete-payment-method.component";
import { ViewPaymentMethodComponent } from "./component/dashboards/dashboard-internal/admin/payment-method/view-payment-method/view-payment-method.component";
import { UpdatePaymentMethodComponent } from "./component/dashboards/dashboard-internal/admin/payment-method/update-payment-method/update-payment-method.component";
import { SearchPaymentMethodComponent } from "./component/dashboards/dashboard-internal/admin/payment-method/search-payment-method/search-payment-method.component";
import { AddOfficeComponent } from "./component/dashboards/dashboard-internal/admin/office/add-office/add-office.component";
import { DeleteOfficeComponent } from "./component/dashboards/dashboard-internal/admin/office/delete-office/delete-office.component";
import { UpdateOfficeComponent } from "./component/dashboards/dashboard-internal/admin/office/update-office/update-office.component";
import { ViewOfficeComponent } from "./component/dashboards/dashboard-internal/admin/office/view-office/view-office.component";
import { SearchOfficeComponent } from "./component/dashboards/dashboard-internal/admin/office/search-office/search-office.component";

// import { AddPropertyUsageComponent } from './component/dashboards/dashboard-internal/admin/property-usage/add-property-usage/add-property-usage.component';
// import { UpdatePropertyUsageComponent } from './component/dashboards/dashboard-internal/admin/property-usage/update-property-usage/update-property-usage.component';
// import { ViewPropertyUsageComponent } from './component/dashboards/dashboard-internal/admin/property-usage/view-property-usage/view-property-usage.component';
// import { SearchPropertyUsageComponent } from './component/dashboards/dashboard-internal/admin/property-usage/search-property-usage/search-property-usage.component';
import { WizardComponent } from "./component/dashboards/dashboard-external/assesment/new-application/wizard/wizard.component";
import { GranteeComponent } from "./component/dashboards/dashboard-external/assesment/new-application/wizard/grantee/grantee.component";
import { GrantorComponent } from "./component/dashboards/dashboard-external/assesment/new-application/wizard/grantor/grantor.component";
import { PropertyComponent } from "./component/dashboards/dashboard-external/assesment/new-application/wizard/property/property.component";
import { PropertyDeedDetailsComponent } from "./component/dashboards/dashboard-external/assesment/new-application/wizard/property/property-deed-details/property-deed-details.component";
import { PropertyAssessmentComponent } from "./component/dashboards/dashboard-external/assesment/new-application/wizard/property/property-assessment/property-assessment.component";
import { PropertyExtendComponent } from "./component/dashboards/dashboard-external/assesment/new-application/wizard/property/property-extend/property-extend.component";
import { PropertyOtherDetailsComponent } from "./component/dashboards/dashboard-external/assesment/new-application/wizard/property/property-other-details/property-other-details.component";
import { PropertyLoanDetailsComponent } from "./component/dashboards/dashboard-external/assesment/new-application/wizard/property/property-loan-details/property-loan-details.component";
import { PropertyBuildingComponent } from "./component/dashboards/dashboard-external/assesment/new-application/wizard/property/property-building/property-building.component";
import { OtherComponent } from "./component/dashboards/dashboard-external/assesment/new-application/wizard/other/other.component";
import { PaymentComponent } from "./component/dashboards/dashboard-external/assesment/new-application/wizard/payment/payment.component";
import { DeclarationComponent } from "./component/dashboards/dashboard-external/assesment/new-application/wizard/declaration/declaration.component";
import { FileUploadComponent } from "./component/dashboards/dashboard-external/assesment/file-upload/file-upload.component";
import { PendingRequestsComponent } from "./component/dashboards/dashboard-external/assesment/view-assesment-request/pending-requests/pending-requests.component";
import { CompletedRequestsComponent } from "./component/dashboards/dashboard-external/assesment/view-assesment-request/completed-requests/completed-requests.component";
import { RejectedRequestsComponent } from "./component/dashboards/dashboard-external/assesment/view-assesment-request/rejected-requests/rejected-requests.component";
import { ViewRequestsComponent } from "./component/dashboards/dashboard-external/assesment/view-assesment-request/view-requests/view-requests.component";
import { ViewGranteeComponent } from "./component/dashboards/dashboard-external/assesment/view-assesment-request/view-requests/view-grantee/view-grantee.component";
import { ViewGrantorComponent } from "./component/dashboards/dashboard-external/assesment/view-assesment-request/view-requests/view-grantor/view-grantor.component";
import { ViewNotaryComponent } from "./component/dashboards/dashboard-external/assesment/view-assesment-request/view-requests/view-notary/view-notary.component";
import { ViewPropertyComponent } from "./component/dashboards/dashboard-external/assesment/view-assesment-request/view-requests/view-property/view-property.component";
import { ViewOtherComponent } from "./component/dashboards/dashboard-external/assesment/view-assesment-request/view-requests/view-other/view-other.component";
import { ViewPaymentComponent } from "./component/dashboards/dashboard-external/assesment/view-assesment-request/view-requests/view-payment/view-payment.component";
import { ViewValuationComponent } from "./component/dashboards/dashboard-external/assesment/view-assesment-request/view-requests/view-valuation/view-valuation.component";
import { ExchangeComponent } from "./component/dashboards/dashboard-external/assesment/new-application/exchange/exchange.component";
import { TransferComponent } from "./component/dashboards/dashboard-external/assesment/new-application/transfer/transfer.component";
import { GiftComponent } from "./component/dashboards/dashboard-external/assesment/new-application/gift/gift.component";
import { TextImgComponent } from "./component/dashboards/dashboard-external/assesment/text-img/text-img.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { NotifierModule } from "angular-notifier";
import { ValuationComponent } from "./component/dashboards/dashboard-external/assesment/view-assesment-request/view-requests/valuation/valuation.component";
import { ValuationInternalComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-calculation/valuation-internal/valuation-internal.component";
import { PropertyFacilityComponent } from "./component/dashboards/dashboard-internal/admin/property/property-facility/property-facility.component";
import { ProprtyTypeComponent } from "./component/dashboards/dashboard-internal/admin/property/proprty-type/proprty-type.component";
import { PropertyUsageComponent } from "./component/dashboards/dashboard-internal/admin/property/property-usage/property-usage.component";
import { PropertyUsageSubtypeComponent } from "./component/dashboards/dashboard-internal/admin/property/property-usage-subtype/property-usage-subtype.component";
import { DeletePropertyTypeComponent } from "./component/dashboards/dashboard-internal/admin/property/proprty-type/delete-property-type/delete-property-type.component";
import { UpdatePropertyTypeComponent } from "./component/dashboards/dashboard-internal/admin/property/proprty-type/update-property-type/update-property-type.component";
import { SearchPropertyTypeComponent } from "./component/dashboards/dashboard-internal/admin/property/proprty-type/search-property-type/search-property-type.component";
import { ViewPropertyTypeComponent } from "./component/dashboards/dashboard-internal/admin/property/proprty-type/view-property-type/view-property-type.component";
import { AddPropertyTypeComponent } from "./component/dashboards/dashboard-internal/admin/property/proprty-type/add-property-type/add-property-type.component";

import { AddGramaNiladariDivisionComponent } from "./component/dashboards/dashboard-internal/admin/grama-niladari-division/add-grama-niladari-division/add-grama-niladari-division.component";
import { DeleteGramaNiladariDivisionComponent } from "./component/dashboards/dashboard-internal/admin/grama-niladari-division/delete-grama-niladari-division/delete-grama-niladari-division.component";
import { UpdateGramaNiladariDivisionComponent } from "./component/dashboards/dashboard-internal/admin/grama-niladari-division/update-grama-niladari-division/update-grama-niladari-division.component";
import { ViewGramaNiladariDivisionComponent } from "./component/dashboards/dashboard-internal/admin/grama-niladari-division/view-grama-niladari-division/view-grama-niladari-division.component";
import { SearchGramaNiladariDivisionComponent } from "./component/dashboards/dashboard-internal/admin/grama-niladari-division/search-grama-niladari-division/search-grama-niladari-division.component";
import { ViewDocumentTypeComponent } from "./component/dashboards/dashboard-internal/admin/document-type/view-document-type/view-document-type.component";
import { AddFloorSubTypeComponent } from "./component/dashboards/dashboard-internal/admin/floor-sub-type/add-floor-sub-type/add-floor-sub-type.component";
import { DeleteFloorSubTypeComponent } from "./component/dashboards/dashboard-internal/admin/floor-sub-type/delete-floor-sub-type/delete-floor-sub-type.component";
import { UpdateFloorSubTypeComponent } from "./component/dashboards/dashboard-internal/admin/floor-sub-type/update-floor-sub-type/update-floor-sub-type.component";
import { ViewFloorSubTypeComponent } from "./component/dashboards/dashboard-internal/admin/floor-sub-type/view-floor-sub-type/view-floor-sub-type.component";
import { SearchFloorSubTypeComponent } from "./component/dashboards/dashboard-internal/admin/floor-sub-type/search-floor-sub-type/search-floor-sub-type.component";
import { AddLocalAuthorityComponent } from "./component/dashboards/dashboard-internal/admin/local-authority/add-local-authority/add-local-authority.component";
import { DeleteLocalAuthorityComponent } from "./component/dashboards/dashboard-internal/admin/local-authority/delete-local-authority/delete-local-authority.component";
import { ViewLocalAuthorityComponent } from "./component/dashboards/dashboard-internal/admin/local-authority/view-local-authority/view-local-authority.component";
import { SearchLocalAuthorityComponent } from "./component/dashboards/dashboard-internal/admin/local-authority/search-local-authority/search-local-authority.component";
import { UpdateLocalAuthorityComponent } from "./component/dashboards/dashboard-internal/admin/local-authority/update-local-authority/update-local-authority.component";
import { AddPropertyFacilityComponent } from "./component/dashboards/dashboard-internal/admin/property/property-facility/add-property-facility/add-property-facility.component";
import { DeletePropertyFacilityComponent } from "./component/dashboards/dashboard-internal/admin/property/property-facility/delete-property-facility/delete-property-facility.component";
import { UpdatePropertyFacilityComponent } from "./component/dashboards/dashboard-internal/admin/property/property-facility/update-property-facility/update-property-facility.component";
import { ViewPropertyFacilityComponent } from "./component/dashboards/dashboard-internal/admin/property/property-facility/view-property-facility/view-property-facility.component";
import { SearchPropertyFacilityComponent } from "./component/dashboards/dashboard-internal/admin/property/property-facility/search-property-facility/search-property-facility.component";
import { AddPropertyUsageComponent } from "./component/dashboards/dashboard-internal/admin/property/property-usage/add-property-usage/add-property-usage.component";
import { UpdatePropertyUsageComponent } from "./component/dashboards/dashboard-internal/admin/property/property-usage/update-property-usage/update-property-usage.component";
import { ViewPropertyUsageComponent } from "./component/dashboards/dashboard-internal/admin/property/property-usage/view-property-usage/view-property-usage.component";
import { SearchPropertyUsageComponent } from "./component/dashboards/dashboard-internal/admin/property/property-usage/search-property-usage/search-property-usage.component";
import { AddPropertyUsageSubtypeComponent } from "./component/dashboards/dashboard-internal/admin/property/property-usage-subtype/add-property-usage-subtype/add-property-usage-subtype.component";
import { UpdatePropertyUsageSubtypeComponent } from "./component/dashboards/dashboard-internal/admin/property/property-usage-subtype/update-property-usage-subtype/update-property-usage-subtype.component";
import { DeletePropertyUsageSubtypeComponent } from "./component/dashboards/dashboard-internal/admin/property/property-usage-subtype/delete-property-usage-subtype/delete-property-usage-subtype.component";
import { ViewPropertyUsageSubtypeComponent } from "./component/dashboards/dashboard-internal/admin/property/property-usage-subtype/view-property-usage-subtype/view-property-usage-subtype.component";
import { SearchPropertyUsageSubtypeComponent } from "./component/dashboards/dashboard-internal/admin/property/property-usage-subtype/search-property-usage-subtype/search-property-usage-subtype.component";
import { DeleteDistrictComponent } from "./component/dashboards/dashboard-internal/admin/district/delete-district/delete-district.component";
import { UpdateDistrictComponent } from "./component/dashboards/dashboard-internal/admin/district/update-district/update-district.component";
import { DeletePropertyUsageComponent } from "./component/dashboards/dashboard-internal/admin/property/property-usage/delete-property-usage/delete-property-usage.component";
import { PaymentResponseComponent } from "./component/dashboards/dashboard-external/assesment/new-application/payment-response/payment-response.component";
import { ForgotPasswordTempComponent } from "./component/forgot-password-temp/forgot-password-temp.component";
import { ResetRedirectComponent } from "./component/reset-redirect/reset-redirect.component";
import { ChangePasswordComponent } from "./component/change-password/change-password.component";
import { BankComponent } from "./component/dashboards/dashboard-internal/admin/bank/bank.component";
import { AddBankComponent } from "./component/dashboards/dashboard-internal/admin/bank/add-bank/add-bank.component";
import { DeleteBankComponent } from "./component/dashboards/dashboard-internal/admin/bank/delete-bank/delete-bank.component";
import { SearchBankComponent } from "./component/dashboards/dashboard-internal/admin/bank/search-bank/search-bank.component";
import { UpdateBankComponent } from "./component/dashboards/dashboard-internal/admin/bank/update-bank/update-bank.component";
import { ViewBankComponent } from "./component/dashboards/dashboard-internal/admin/bank/view-bank/view-bank.component";
import { TwoPartyComponent } from "./component/dashboards/dashboard-external/assesment/new-application/wizard/two-party/two-party.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ViewAssessmentRequestInternalComponent } from "./component/dashboards/dashboard-internal/assesment-internal/view-assessment-request-internal/view-assessment-request-internal.component";
import { PendingRequestsInternalComponent } from "./component/dashboards/dashboard-internal/assesment-internal/view-assessment-request-internal/pending-requests-internal/pending-requests-internal.component";
import { CompletedRequestsInternalComponent } from "./component/dashboards/dashboard-internal/assesment-internal/view-assessment-request-internal/completed-requests-internal/completed-requests-internal.component";
import { RejectedRequestsInternalComponent } from "./component/dashboards/dashboard-internal/assesment-internal/view-assessment-request-internal/rejected-requests-internal/rejected-requests-internal.component";
import { ViewRequestsInternalComponent } from "./component/dashboards/dashboard-internal/assesment-internal/view-assessment-request-internal/view-requests-internal/view-requests-internal.component";
import { ViewGranteeInternalComponent } from "./component/dashboards/dashboard-internal/assesment-internal/view-assessment-request-internal/view-requests-internal/view-grantee-internal/view-grantee-internal.component";
import { ViewGrantorInternalComponent } from "./component/dashboards/dashboard-internal/assesment-internal/view-assessment-request-internal/view-requests-internal/view-grantor-internal/view-grantor-internal.component";
import { ViewNotaryInternalComponent } from "./component/dashboards/dashboard-internal/assesment-internal/view-assessment-request-internal/view-requests-internal/view-notary-internal/view-notary-internal.component";
import { ViewPropertyInternalComponent } from "./component/dashboards/dashboard-internal/assesment-internal/view-assessment-request-internal/view-requests-internal/view-property-internal/view-property-internal.component";
import { ViewOtherInternalComponent } from "./component/dashboards/dashboard-internal/assesment-internal/view-assessment-request-internal/view-requests-internal/view-other-internal/view-other-internal.component";
import { ViewPaymentInternalComponent } from "./component/dashboards/dashboard-internal/assesment-internal/view-assessment-request-internal/view-requests-internal/view-payment-internal/view-payment-internal.component";
import { ViewValuationInternalComponent } from "./component/dashboards/dashboard-internal/assesment-internal/view-assessment-request-internal/view-requests-internal/view-valuation-internal/view-valuation-internal.component";
import { ViewCalculationInternalComponent } from "./component/dashboards/dashboard-internal/assesment-internal/view-assessment-request-internal/view-requests-internal/view-calculation-internal/view-calculation-internal.component";
import { DeleteUserComponent } from "./component/dashboards/dashboard-internal/admin/user/delete-user/delete-user.component";
import { SearchUserComponent } from "./component/dashboards/dashboard-internal/admin/user/search-user/search-user.component";
import { UpdateUserComponent } from "./component/dashboards/dashboard-internal/admin/user/update-user/update-user.component";
import { ViewUserComponent } from "./component/dashboards/dashboard-internal/admin/user/view-user/view-user.component";
import { UserTypeComponent } from "./component/dashboards/dashboard-internal/admin/user/user-type.component";
import { AddUserComponent } from "./component/dashboards/dashboard-internal/admin/user/add-user/add-user.component";
// import { RoleComponent } from './component/dashboards/dashboard-internal/admin/role/role.component';
// import { AddRoleComponent } from './component/dashboards/dashboard-internal/admin/role/add-role/add-role.component';
// import { UpdateRoleComponent } from './component/dashboards/dashboard-internal/admin/role/update-role/update-role.component';
// import { DeleteRoleComponent } from './component/dashboards/dashboard-internal/admin/role/delete-role/delete-role.component';
// import { ViewRoleComponent } from './component/dashboards/dashboard-internal/admin/role/view-role/view-role.component';
// import { SearchRoleComponent } from './component/dashboards/dashboard-internal/admin/role/search-role/search-role.component';
import { FileUpload1Component } from "./component/dashboards/dashboard-external/assesment/file-upload-draft-deed/file-upload1.component";
import { FileUpload2Component } from "./component/dashboards/dashboard-external/assesment/file-upload-survey_building_plan/file-upload2.component";
import { FileUpload3Component } from "./component/dashboards/dashboard-external/assesment/file-upload-route_plan/file-upload3.component";
import { FileUpload4Component } from "./component/dashboards/dashboard-external/assesment/file-upload-assessment_valuation/file-upload4.component";
import { FileUpload5Component } from "./component/dashboards/dashboard-external/assesment/file-upload-nic/file-upload5.component";
import { FileUpload6Component } from "./component/dashboards/dashboard-external/assesment/file-upload-other/file-upload6.component";
import { FileUploadGranteeComponent } from "./component/dashboards/dashboard-external/assesment/file-upload-grantee/file-upload-grantee.component";
import { FileUploadGranterComponent } from "./component/dashboards/dashboard-external/assesment/file-upload-granter/file-upload-granter.component";
import { FileUploadComponentBank } from "./component/dashboards/dashboard-external/assesment/file-upload-bank/file-upload-bank.component";
import { FileUploadComponentCash } from "./component/dashboards/dashboard-external/assesment/file-upload-cash/file-upload-cash.component";
import { UsernameValidator } from "./component/validators/username.validator";
import { NgxMaskModule } from "ngx-mask";
import { FileUploadAssessmentComponent } from "./component/dashboards/dashboard-external/assesment/file-upload-assessment/file-upload-assessment.component";
import {DraftApplicationRequestsComponent} from './component/dashboards/dashboard-external/assesment/view-assesment-request/draft-application-requests/draft-application-requests.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { FutureDateValidator } from "./component/validators/futuredate.validator";
import { DownloadModuleComponent } from "./component/download-module/download-module.component";
import { FileUploadOpinionDocumentComponent } from "./component/dashboards/dashboard-internal/assesment-internal/view-assessment-request-internal/file-upload/file-upload-opinion-document/file-upload-opinion-document.component";
import { DashboardInternalUserComponent } from "./component/dashboards/dashboard-internal-user/dashboard-internal-user.component";
import { TopNavigationInternalUserComponent } from "./component/dashboards/dashboard-internal-user/top-navigation-internal-user/top-navigation-internal-user.component";
import { HeaderInternalUserComponent } from "./component/dashboards/dashboard-internal-user/header-internal-user/header-internal-user.component";
import { FileUpload4InternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/file-upload/file-upload-assessment_valuation-internal-user/file-upload4-iuser.component";
import { FileUpload1InternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/file-upload/file-upload-draft-deed-internal-user/file-upload1-iuser.component";
import { FileUploadGranteeInternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/file-upload/file-upload-grantee-internal-user/file-upload-grantee-iuser.component";
import { FileUploadGranterInternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/file-upload/file-upload-granter-internal-user/file-upload-granter-iuser.component";
import { FileUploadInternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/file-upload/file-upload-internal-user/file-upload-iuser.component";
import { FileUpload5InternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/file-upload/file-upload-nic-internal-user/file-upload5-iuser.component";
import { FileUpload6InternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/file-upload/file-upload-other-internal-user/file-upload6-iuser.component";
import { FileUpload3InternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/file-upload/file-upload-route_plan-internal-user/file-upload3-iuser.component";
import { FileUpload2InternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/file-upload/file-upload-survey_building_plan-internal-user/file-upload2-iuser.component";
import { AssignAccessorComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/assign-accessor/assign-accessor.component";
import { CertificateComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/certificate/certificate.component";
import { CompletedReqIUComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/completed-requests-internal-user/completed-req-iu.component";
import { PendingReqIUComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/pending-requests-internal-user/pending-req-iu.component";
import { RejectedReqIUComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/rejected-requests-internal-user/rejected-requests.component";
import {
  SiteVisitScheduleComponent,
  SafeHtmlPipe
} from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/site-visit-schedule/site-visit-schedule.component";
import { WizardInternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/wizard-internal-user.component";
import { InspectionReportBuildingComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/inspection-report-building/inspection-report-building.component";
import { InspectionReportCondominiumComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/inspection-report-condominium/inspection-report-condominium.component";
import { InspectionReportLandComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/inspection-report-land/inspection-report-land.component";
import { InspectionReportMainComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/inspection-report-main/inspection-report-main.component";
import { InspectionReportRecreationComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/inspection-report-recreation/inspection-report-recreation.component";
import { ViewBuildingInternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-building/view-building-iu.component";
import { ViewGranteeInternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-grantee/view-grantee-iu.component";
import { ViewGrantorInternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-grantor/view-grantor-iu.component";
import { ViewNotaryInternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-notary/view-not-iu.component";
import { ViewOtherInternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-other/view-other-iu";
import { ViewPaymentInternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-payment/view-payment-iu.component";
import { ViewPropertyInternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-property/view-property-iu.component";
import { ViewValuationInternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-valuation/view-valuation-iu";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { NewApplicationInternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/new-requestes-internal-user/new-application-iu.component";
import { ViewAssessmentRequestInternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-access-internal-user/view-assessment-request-iu.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FileUploadPaymentComponent } from "./component/dashboards/dashboard-external/assesment/file-upload-payment/file-upload-payment.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { CondominiumComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-calculation/condominium/condominium.component";
import { LandComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-calculation/land/land.component";
import { LandBuildingComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-calculation/land-building/land-building.component";
import { ReCreationComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-calculation/re-creation/re-creation.component";
import { RecreationCondominiumComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-calculation/re-creation/condominium/recreation-condominium.component";
import { RecreationLandBuildingComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-calculation/re-creation/landbuilding-recreation/recreation-land-building.component";
import { RecreationLandComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-calculation/re-creation/land-recreation/recreation-land.component";
import { ViewCalculationComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-calculation/view-calculation.component";
// import { NgxBarcodeModule } from 'ngx-barcode';
// import { InspectionDataServiceService } from './service/data-service/inspection-data-service.service';
// import {NgxPrintModule} from 'ngx-print';
// import { PendingPaymentRequestComponent } from './component/dashboards/dashboard-external/assesment/view-assesment-request/pending-payment-request/pending-payment-request.component';
// import { PendingPaymentInternalUserComponent } from './component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/pending-payment-internal-user/pending-payment-internal-user.component';
// import { CompletedPaymentInternalUserComponent } from './component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/completed-payment-internal-user/completed-payment-internal-user.component';
// import { RegexFormatDirective } from './directives/regex-format.directive';
import {MatDialogModule,MatSlideToggleModule,MatRadioModule, MatSnackBarModule, MatDatepickerModule,MatNativeDateModule, MAT_DATE_LOCALE} from '@angular/material';
//import { OpinionDialogComponent } from './component/dashboards/dashboard-internal-user/refund-application-dialog/opinion-dialog/opinion-dialog.component';
import { RefundApplicationExternalUserComponent } from './component/dashboards/dashboard-external/refund-application-external-user/refund-application-external-user.component';
import { RefundApplicationHeaderComponent } from './component/dashboards/dashboard-external/refund-application-external-user/refund-application-header/refund-application-header.component';
import { RefundApplicationOpinionDetailsComponent } from './component/dashboards/dashboard-external/refund-application-external-user/refund-application-opinion-details/refund-application-opinion-details.component';
import { RefundApplicationRefundDetailsComponent } from './component/dashboards/dashboard-external/refund-application-external-user/refund-application-refund-details/refund-application-refund-details.component';
import { RefundApplicationDocumentsComponent } from './component/dashboards/dashboard-external/refund-application-external-user/refund-application-documents/refund-application-documents.component';
import { from } from "rxjs";
import { NgxBarcodeModule } from "ngx-barcode";
import { InspectionDataServiceService } from "./service/data-service/inspection-data-service.service";
import { NgxPrintModule } from "ngx-print";
import { PendingPaymentRequestComponent } from "./component/dashboards/dashboard-external/assesment/view-assesment-request/pending-payment-request/pending-payment-request.component";
import { PendingPaymentInternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/pending-payment-internal-user/pending-payment-internal-user.component";
import { CompletedPaymentInternalUserComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/completed-payment-internal-user/completed-payment-internal-user.component";
import { RegexFormatDirective } from "./directives/regex-format.directive";
import { ExchangeCalculationComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-calculation/exchange-calculation/exchange-calculation";
import { MatTabsModule } from "@angular/material/tabs";
import { ExchangeCondominiumComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-calculation/exchange-calculation/exchange-condominium/exchange-condominium";
import { WizardInternalExchangeComponent } from './component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-exchange/wizard-internal-exchange.component';
import { GetOpinionDialogComponent } from './component/dashboards/dashboard-external/refund-application-external-user/get-opinion-dialog/get-opinion-dialog.component';
import {MatFormFieldModule,MatInputModule} from '@angular/material';
import { RefundOpinionServiceService } from './service/RefundOpinionService/refund-opinion-service.service';
import { DatePipe } from '@angular/common';
import { ExchangeLandComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-calculation/exchange-calculation/exchange-land/exchange-land";
import { ExchangeLandBuildingComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-calculation/exchange-calculation/exchange-land-building/exchange-land-building";
// import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
import { SiteVisitScheduleExchangeComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/site-visit-schedule-exchange/site-visit-schedule-exchange.component";
import { ExchangeRecreationComponent } from "./component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/view-calculation/exchange-calculation/exchange-recreation/exchange-recreation";
import { ExchangeInspectionMainComponent } from './component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-exchange/exchange-inspection-main/exchange-inspection-main.component';
import { FurtherRequirementComponent } from './component/dashboards/dashboard-external/further-requirement/further-requirement.component';
import { ExchangeFrComponent } from './component/dashboards/dashboard-external/further-requirement/exchange-fr/exchange-fr.component';
import { GiftFrComponent } from './component/dashboards/dashboard-external/further-requirement/gift-fr/gift-fr.component';
import { TransferFrComponent } from './component/dashboards/dashboard-external/further-requirement/transfer-fr/transfer-fr.component';
import { WizardFrComponent } from './component/dashboards/dashboard-external/further-requirement/wizard-fr/wizard-fr.component';
import { FurtherRequirementUpdateComponent } from './component/dashboards/dashboard-external/further-requirement/further-requirement-update/further-requirement-update.component';
import { DeclarationFrComponent } from './component/dashboards/dashboard-external/further-requirement/wizard-fr/declaration-fr/declaration-fr.component';
import { GranteeFrComponent } from './component/dashboards/dashboard-external/further-requirement/wizard-fr/grantee-fr/grantee-fr.component';
import { GrantorFrComponent } from './component/dashboards/dashboard-external/further-requirement/wizard-fr/grantor-fr/grantor-fr.component';
import { OtherFrComponent } from './component/dashboards/dashboard-external/further-requirement/wizard-fr/other-fr/other-fr.component';
import { PaymentFrComponent } from './component/dashboards/dashboard-external/further-requirement/wizard-fr/payment-fr/payment-fr.component';
import { PropertyFrComponent } from './component/dashboards/dashboard-external/further-requirement/wizard-fr/property-fr/property-fr.component';
import { PropertyAssessmentFrComponent } from './component/dashboards/dashboard-external/further-requirement/wizard-fr/property-fr/property-assessment-fr/property-assessment-fr.component';
import { PropertyBuildingFrComponent } from './component/dashboards/dashboard-external/further-requirement/wizard-fr/property-fr/property-building-fr/property-building-fr.component';
import { PropertyDeedDetailsFrComponent } from './component/dashboards/dashboard-external/further-requirement/wizard-fr/property-fr/property-deed-details-fr/property-deed-details-fr.component';
import { PropertyExtendFrComponent } from './component/dashboards/dashboard-external/further-requirement/wizard-fr/property-fr/property-extend-fr/property-extend-fr.component';
import { PropertyLoanDetailsFrComponent } from './component/dashboards/dashboard-external/further-requirement/wizard-fr/property-fr/property-loan-details-fr/property-loan-details-fr.component';
import { PropertyOtherDetailsFrComponent } from './component/dashboards/dashboard-external/further-requirement/wizard-fr/property-fr/property-other-details-fr/property-other-details-fr.component';
import { ViewRefundRequestUiComponent } from './component/dashboards/dashboard-internal-user/refund-internal-user/view-refund-request-ui/view-refund-request-ui.component';
import { ViewRefundNewApplicationsComponent } from './component/dashboards/dashboard-internal-user/refund-internal-user/view-refund-request-ui/view-refund-new-applications/view-refund-new-applications.component';
import { FirstDashboardInternalUserComponent } from './component/dashboards/dashboard-internal-user/first-dashboard-internal-user/first-dashboard-internal-user.component';
import { RefundInternalUserDashboardComponent } from './component/dashboards/dashboard-internal-user/refund-internal-user/refund-internal-user-dashboard/refund-internal-user-dashboard.component';
import { NgxPaginationModule } from "ngx-pagination";
import { AsignAccessorForRefundComponent } from './component/dashboards/dashboard-internal-user/refund-internal-user/assign-assessor-for-refund/asign-accessor-for-refund.component';
import { ViewRefundSideNavigationComponent } from './component/dashboards/dashboard-internal-user/refund-internal-user/view-refund-request-ui/view-refund-new-applications/view-refund-side-navigation/view-refund-side-navigation.component';
import { ViewOpinionDetailsComponent } from './component/dashboards/dashboard-internal-user/refund-internal-user/view-refund-request-ui/view-refund-new-applications/view-refund-side-navigation/view-opinion-details/view-opinion-details.component';
import { ViewRefundDetailsComponent } from './component/dashboards/dashboard-internal-user/refund-internal-user/view-refund-request-ui/view-refund-new-applications/view-refund-side-navigation/view-refund-details/view-refund-details.component';
import { ViewDocumentsComponent } from './component/dashboards/dashboard-internal-user/refund-internal-user/view-refund-request-ui/view-refund-new-applications/view-refund-side-navigation/view-documents/view-documents.component';
import { RefundCalculationComponent } from './component/dashboards/dashboard-internal-user/refund-internal-user/view-refund-request-ui/view-refund-new-applications/view-refund-side-navigation/refund-calculation/refund-calculation.component';
import { ViewRefundCollectionDetailsComponent } from './component/dashboards/dashboard-internal-user/refund-internal-user/view-refund-request-ui/view-refund-collection-details/view-refund-collection-details.component';
import { ViewCollectionDetailsComponent } from './component/dashboards/dashboard-internal-user/refund-internal-user/view-refund-request-ui/view-refund-new-applications/view-refund-side-navigation/view-collection-details/view-collection-details.component';
import { AddPaymentDetailsDialogComponent } from './component/dashboards/dashboard-internal-user/refund-internal-user/view-refund-request-ui/view-refund-new-applications/view-refund-side-navigation/view-collection-details/add-payment-details-dialog/add-payment-details-dialog.component';
import { DocumentUploadOneComponent } from './component/document/document-upload/document-upload-one/document-upload-one.component';
import { DocumentUploadTwoComponent } from './component/document/document-upload/document-upload-two/document-upload-two.component';
import { DocumentUploadThreeComponent } from './component/document/document-upload/document-upload-three/document-upload-three.component';
import { DocumentUploadFourComponent } from './component/document/document-upload/document-upload-four/document-upload-four.component';
import { DocumentUploadFiveComponent } from './component/document/document-upload/document-upload-five/document-upload-five.component';
import { DocumentUploadSixComponent } from './component/document/document-upload/document-upload-six/document-upload-six.component';
import { DocumentUploadSevenComponent } from './component/document/document-upload/document-upload-seven/document-upload-seven.component';
import { DocumentUploadEightComponent } from './component/document/document-upload/document-upload-eight/document-upload-eight.component';
import { DocumentUploadNineComponent } from './component/document/document-upload/document-upload-nine/document-upload-nine.component';
import { DocumentUploadTenComponent } from './component/document/document-upload/document-upload-ten/document-upload-ten.component';
import { DocumentUploadElevenComponent } from './component/document/document-upload/document-upload-eleven/document-upload-eleven.component';
import { DocumentUploadTwelveComponent } from './component/document/document-upload/document-upload-twelve/document-upload-twelve.component';
import { DocumentUploadThirteenComponent } from './component/document/document-upload/document-upload-thirteen/document-upload-thirteen.component';
import { RefundFormsComponent } from './component/dashboards/dashboard-internal-user/refund-internal-user/view-refund-request-ui/view-refund-new-applications/view-refund-side-navigation/refund-forms/refund-forms.component';
import { DocumentUploadFourteenComponent } from './component/document/document-upload/document-upload-fourteen/document-upload-fourteen.component';
import { IssueDetailsComponent } from './component/dashboards/dashboard-internal-user/refund-internal-user/view-refund-request-ui/view-refund-new-applications/view-refund-side-navigation/issue-details/issue-details.component';
import { ReportsComponent } from './component/dashboards/dashboard-internal-user/reports/reports.component';
import { ReportsSidenavComponent } from './component/dashboards/dashboard-internal-user/reports/reports-sidenav/reports-sidenav.component';
import { StampDutyOpinionReportsComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/stamp-duty-opinion-reports.component';
// import { RefundOpinionReportsComponent } from './component/dashboards/dashboard-internal-user/reports/refund-opinion-reports/refund-opinion-reports.component';
// import { ReimbursementReportsComponent } from './component/dashboards/dashboard-internal-user/reports/reimbursement-reports/reimbursement-reports.component';
import { OpinionRegistrationComponent } from './component/dashboards/dashboard-internal-user/reports/opinion-registration/opinion-registration.component';
import{RimFormComponent} from './component/dashboards/dashboard-internal-user/reimb-intenal-user/rim-form/rim-form.component';
import { RimSideNavigationComponent } from './component/dashboards/dashboard-internal-user/reimb-intenal-user/rim-side-navigation/rim-side-navigation.component';
import { RimDocumentComponent } from './component/dashboards/dashboard-internal-user/reimb-intenal-user/rim-document/rim-document.component';
import { RimAssignTaxOfficerComponent } from './component/dashboards/dashboard-internal-user/reimb-intenal-user/rim-assign-tax-officer/rim-assign-tax-officer.component';
import { RimPaybleNonpaybleComponent } from './component/dashboards/dashboard-internal-user/reimb-intenal-user/rim-payble-nonpayble/rim-payble-nonpayble.component';
import { RimPaymentDetailsComponent } from './component/dashboards/dashboard-internal-user/reimb-intenal-user/rim-payment-details/rim-payment-details.component';
import { RimSecondaryDashboardComponent } from './component/dashboards/dashboard-internal-user/reimb-intenal-user/rim-secondary-dashboard/rim-secondary-dashboard.component';
import { RimViewApplicationComponent } from './component/dashboards/dashboard-internal-user/reimb-intenal-user/rim-secondary-dashboard/rim-view-application/rim-view-application.component';
import { RimViewNewApplicationSideNavComponent } from './component/dashboards/dashboard-internal-user/reimb-intenal-user/rim-secondary-dashboard/rim-view-new-application-side-nav/rim-view-new-application-side-nav.component';
import { ViewAppealRequestComponent } from './component/dashboards/dashboard-external/assesment/view-appeal-request/view-appeal-request.component';
import { AppealRequestComponent } from './component/dashboards/dashboard-external/assesment/view-appeal-request/appeal-request/appeal-request.component';
import { OpinionInformationComponent } from './component/dashboards/dashboard-external/assesment/view-appeal-request/opinion-information/opinion-information.component';
import { DashboardAppealComponent } from './component/dashboards/dashboard-appeal/dashboard-appeal.component';
import { ViewAssessmentAppealComponent } from './component/dashboards/dashboard-appeal/view-assessment-appeal/view-assessment-appeal.component';
import { AppealNewApplicationComponent } from './component/dashboards/dashboard-appeal/view-assessment-appeal/appeal-new-application/appeal-new-application.component';
import { AppealPendingApplicationComponent } from './component/dashboards/dashboard-appeal/view-assessment-appeal/appeal-pending-application/appeal-pending-application.component';
import { AppealPendingPaymentApplicationComponent } from './component/dashboards/dashboard-appeal/view-assessment-appeal/appeal-pending-payment-application/appeal-pending-payment-application.component';
import { AppealCompletedApplicationComponent } from './component/dashboards/dashboard-appeal/view-assessment-appeal/appeal-completed-application/appeal-completed-application.component';
import { AppealRejectedApplicationComponent } from './component/dashboards/dashboard-appeal/view-assessment-appeal/appeal-rejected-application/appeal-rejected-application.component';
import { AppealCompletedPaymentApplicationComponent } from './component/dashboards/dashboard-appeal/view-assessment-appeal/appeal-completed-payment-application/appeal-completed-payment-application.component';
import { AppealWizardComponent } from './component/dashboards/dashboard-appeal/view-assessment-appeal/appeal-wizard/appeal-wizard.component';
import { AppealAssignerComponent } from './component/dashboards/dashboard-appeal/view-assessment-appeal/appeal-wizard/appeal-assigner/appeal-assigner.component';
import { AppealRequestFormComponent } from './component/dashboards/dashboard-appeal/view-assessment-appeal/appeal-wizard/appeal-request-form/appeal-request-form.component';
import { AppealOpinionInformationComponent } from './component/dashboards/dashboard-appeal/view-assessment-appeal/appeal-wizard/appeal-opinion-information/appeal-opinion-information.component';
import { DocumentUploadCollectionPaymentComponent } from './component/document/document-upload/document-upload-collection-payment/document-upload-collection-payment.component';

import { RimAccountantDocumentUploadComponent } from './component/document/document-upload/rim-accountant-document-upload/rim-accountant-document-upload.component';
import { FurtherRequirementInternalUserComponent } from './component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/further-requirement-internal-user/further-requirement-internal-user.component';
import { ValuationRequestHistoryComponent } from './component/valuation-request-history/valuation-request-history.component';
import { DocumentUploadDeedInternalPaymentComponent } from './component/document/document-upload/document-upload-deed-internal-payment/document-upload-deed-internal-payment.component';
import { ViewBuildingIuExchangeComponent } from './component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-exchange/view-building-iu-exchange/view-building-iu-exchange.component';
import { ViewOtherIuExchangeComponent } from './component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-exchange/view-other-iu-exchange/view-other-iu-exchange.component';
import { AuthGuard } from "./auth/auth.guard";
import { UserRoleGuard } from "./auth/user-role-guard";
import { InternalUserAuth } from "./auth/internal-user-auth";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PasswordPolicyComponent } from './component/dashboards/dashboard-internal/admin/password-policy/password-policy.component';
import { CreatePasswordPolicyComponent } from './component/dashboards/dashboard-internal/admin/password-policy/create-password-policy/create-password-policy.component';
import { UpdatePasswordPolicyComponent } from './component/dashboards/dashboard-internal/admin/password-policy/update-password-policy/update-password-policy.component';
import { ViewPasswordPolicyComponent } from './component/dashboards/dashboard-internal/admin/password-policy/view-password-policy/view-password-policy.component';
import { EmployeeComponent } from './component/dashboards/dashboard-internal/admin/employee/employee.component';
import { CreateEmployeeComponent } from './component/dashboards/dashboard-internal/admin/employee/create-employee/create-employee.component';
import { UpdateEmployeeComponent } from './component/dashboards/dashboard-internal/admin/employee/update-employee/update-employee.component';
import { ViewEmployeeComponent } from './component/dashboards/dashboard-internal/admin/employee/view-employee/view-employee.component';
import { RefundFurtherRequirementComponent } from './component/dashboards/dashboard-internal-user/refund-internal-user/view-refund-request-ui/refund-further-requirement/refund-further-requirement.component';
import { InspectionMasterDataComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-master-data.component';
import { InspectionSubPropertyComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-sub-property/inspection-sub-property.component';
import { InspectionSidenavComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-sidenav/inspection-sidenav.component';
import { InspectionReservationTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-reservation-type/inspection-reservation-type.component';
import { InspectionLandShapeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-land-shape/inspection-land-shape.component';
import { InspectionLandSlopeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-land-slope/inspection-land-slope.component';
import { InspectionWaterAvailabilityTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-water-availability-type/inspection-water-availability-type.component';
import { InspectionWaterSourceComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-water-source/inspection-water-source.component';
import { InspectionElectricityTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-electricity-type/inspection-electricity-type.component';
import { InspectionElectricitySubTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-electricity-sub-type/inspection-electricity-sub-type.component';
import { InspectionLandSutabilityComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-land-sutability/inspection-land-sutability.component';
import { InspectionTransportedTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-transported-type/inspection-transported-type.component';
import { InspectionEducationTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-education-type/inspection-education-type.component';
import { InspectionIndustrialTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-industrial-type/inspection-industrial-type.component';
import { InspectionHealthTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-health-type/inspection-health-type.component';
import { InspectionSecurityTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-security-type/inspection-security-type.component';
import { InspectionTelecomunicationTypeComponent } from "./component/dashboards/dashboard-internal/inspection-master-data/inspection-telecommunication-type/inspection-telecommunication-type.component";
import { InspectionBusinessTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-business-type/inspection-business-type.component';
import { InspectionIndustrialAreaTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-industrial-area-type/inspection-industrial-area-type.component';
import { InspectionCultivationTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-cultivation-type/inspection-cultivation-type.component';
import { InspectionLandTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-land-type/inspection-land-type.component';
import { InspectionTownComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-town/inspection-town.component';
import { InspectionCondominiumTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-condominium-type/inspection-condominium-type.component';
import { InspectionFacilityTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-facility-type/inspection-facility-type.component';
import { InspectionParkingTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-parking-type/inspection-parking-type.component';
import { InspectionGardenTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-garden-type/inspection-garden-type.component';
import { InspectionViewTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-view-type/inspection-view-type.component';
import { InspectionSurroundTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-surround-type/inspection-surround-type.component';
import { InspectionAgreementTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-agreement-type/inspection-agreement-type.component';
import { InspectionAccomodationTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-accomodation-type/inspection-accomodation-type.component';
import { PropertyUsageSubTypeNewComponent } from './component/dashboards/dashboard-internal/admin/property/property-usage-sub-type-new/property-usage-sub-type-new.component';
import { DistrictNewComponent } from './component/dashboards/dashboard-internal/admin/district/district-new/district-new.component';
import { GramaNiladariDivisionNewComponent } from './component/dashboards/dashboard-internal/admin/grama-niladari-division/grama-niladari-division-new/grama-niladari-division-new.component';
import { CertificateExchangeComponent } from './component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/certificate/certificate-exchange/certificate-exchange.component';
import { PrintNonPayableComponent } from './component/dashboards/dashboard-internal-user/reimb-intenal-user/print-non-payable/print-non-payable.component';
import { PrintPayableComponent } from './component/dashboards/dashboard-internal-user/reimb-intenal-user/print-payable/print-payable.component';
import { LandRegistryComponent } from './component/dashboards/dashboard-internal/admin/land-registry/land-registry.component';
import { InspectionCategoryPropertyTypeComponent } from './component/dashboards/dashboard-internal/inspection-master-data/inspection-category-property-type/inspection-category-property-type.component';
import { InspectionReportRecreationLandComponent } from './component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/inspection-report-recreation-land/inspection-report-recreation-land.component';
import { InspectionReportRecreationBuildingComponent } from './component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/inspection-report-recreation-building/inspection-report-recreation-building.component';
import { InspectionReportRecreationCondominiumComponent } from './component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-user/view-requests-internal-user/inspection-report-recreation-condominium/inspection-report-recreation-condominium.component';
import { QRCodeModule } from "angularx-qrcode";
import { AppOtherParty2Component } from './component/dashboards/dashboard-external/assesment/new-application/wizard/app-other-party2/app-other-party2.component';
import { InspectionReportLandParty2Component } from './component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-exchange/inspection-report-land-party2/inspection-report-land-party2.component';
import { InspectionReportCondominiumParty2Component } from './component/dashboards/dashboard-internal-user/assesment-internal-user/wizard-internal-exchange/inspection-report-condominium-party2/inspection-report-condominium-party2.component';

//epic theeban :- reports

import { OpinionRegistrationReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/opinion-registration-report/opinion-registration-report.component';
import { ApplicationPaymentDetailsReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/application-payment-details-report/application-payment-details-report.component';
import { AssessorDistributionReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/assessor-distribution-report/assessor-distribution-report.component';
import { CertifiedDetailReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/certified-detail-report/certified-detail-report.component';
import { BareLandReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/bare-land-report/bare-land-report.component';
import { RegistrationDetailsReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/registration-details-report/registration-details-report.component';
import { CondominiumReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/condominium-report/condominium-report.component';
import { LandRegistryDetailsReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/land-registry-details-report/land-registry-details-report.component';
import { LandWithBuildReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/land-with-build-report/land-with-build-report.component';
import { NotaryDetailsReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/notary-details-report/notary-details-report.component';
import { RejectedAppealsReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/rejected-appeals-report/rejected-appeals-report.component';
import { RejectedApplicationReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/rejected-application-report/rejected-application-report.component';
import { CertifiedOpinionDetailsReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/certified-opinion-details-report/certified-opinion-details-report.component';
import { CertifiedOpinionCountReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/certified-opinion-count-report/certified-opinion-count-report.component';
// import { RejectedAppReportComponent } from './component/dashboards/dashboard-internal-user/reports/refund-opinion-reports/rejected-app-report/rejected-app-report.component';
import { ApplicationPaymentRejectedReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/application-payment-rejected-report/application-payment-rejected-report.component';
import { SetlledAppealsSummaryReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/setlled-appeals-summary-report/setlled-appeals-summary-report.component';
import { CertifiedOpinionSummaryReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/certified-opinion-summary-report/certified-opinion-summary-report.component';
import { NotCertifiedOpinionReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/not-certified-opinion-report/not-certified-opinion-report.component';
import { EvidencesOpinionReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/evidences-opinion-report/evidences-opinion-report.component';
import { CertifiedOpinionSummaryCountReportComponent } from './component/dashboards/dashboard-internal-user/reports/stamp-duty-opinion-reports/certified-opinion-summary-count-report/certified-opinion-summary-count-report.component';
//epic theeban :- reports

import {CommisionerComponent} from './component/admin-dashboards/commisioner/commisioner.component';
import {DeputyCommisionerComponent} from './component/admin-dashboards/deputy-commisioner/deputy-commisioner.component';
import {TaxOfficerComponent} from './component/admin-dashboards/tax-officer/tax-officer.component';
import {AssessorComponent} from './component/admin-dashboards/assessor/assessor.component';
import {CommisionerOpinionApplicationComponent} from './component/admin-dashboards/commisioner/commisioner-opinion-application/commisioner-opinion-application.component';
import {CommisionerRefundApplicationComponent} from './component/admin-dashboards/commisioner/commisioner-refund-application/commisioner-refund-application.component';
import {DCommisionerOpinionApplicationComponent} from './component/admin-dashboards/deputy-commisioner/d-commisioner-opinion-application/d-commisioner-opinion-application.component';
import {DCommisionerAppealApplicationComponent} from './component/admin-dashboards/deputy-commisioner/d-commisioner-appeal-application/d-commisioner-appeal-application.component';
import {TaxOfficerAppealApplicationComponent} from './component/admin-dashboards/tax-officer/tax-officer-appeal-application/tax-officer-appeal-application.component';
import {CommisionerReimbursementApplicationComponent} from './component/admin-dashboards/commisioner/commisioner-reimbursement-application/commisioner-reimbursement-application.component';
import {DCommisionerReimbursementApplicationComponent} from './component/admin-dashboards/deputy-commisioner/d-commisioner-reimbursement-application/d-commisioner-reimbursement-application.component';
import {TaxOfficerOpinionApplicationComponent} from './component/admin-dashboards/tax-officer/tax-officer-opinion-application/tax-officer-opinion-application.component';
import {AssessorRefundApplicationComponent} from './component/admin-dashboards/assessor/assessor-refund-application/assessor-refund-application.component';
import {CommisionerAppealApplicationComponent} from './component/admin-dashboards/commisioner/commisioner-appeal-application/commisioner-appeal-application.component';
import {DCommisionerRefundApplicationComponent} from './component/admin-dashboards/deputy-commisioner/d-commisioner-refund-application/d-commisioner-refund-application.component';
import {AssessorOpinionApplicationComponent} from './component/admin-dashboards/assessor/assessor-opinion-application/assessor-opinion-application.component';
import {AssessorReimbursementApplicationComponent} from './component/admin-dashboards/assessor/assessor-reimbursement-application/assessor-reimbursement-application.component';
import {AssessorAppealApplicationComponent} from './component/admin-dashboards/assessor/assessor-appeal-application/assessor-appeal-application.component';
import {TaxOfficerRefundApplicationComponent} from './component/admin-dashboards/tax-officer/tax-officer-refund-application/tax-officer-refund-application.component';
import {TaxOfficerReimbursementApplicationComponent} from './component/admin-dashboards/tax-officer/tax-officer-reimbursement-application/tax-officer-reimbursement-application.component';
import { FirstDashboardExternalUserComponent } from './component/dashboards/dashboard-external/first-dashboard-external-user/first-dashboard-external-user.component';
import { RefundExternalUserDashboardComponent } from './component/dashboards/dashboard-external/secondary-dashboards-external/refund-external-user-dashboard/refund-external-user-dashboard.component';
import { ViewDraftRefundApplicationComponent } from './component/dashboards/dashboard-external/refund-application-external-user/view-refund-details/view-draft-refund-application/view-draft-refund-application.component';
import { ViewNewRefundApplicationComponent } from './component/dashboards/dashboard-external/refund-application-external-user/view-refund-details/view-new-refund-application/view-new-refund-application.component';
import { ViewCompletedRefundApplicationComponent } from './component/dashboards/dashboard-external/refund-application-external-user/view-refund-details/view-completed-refund-application/view-completed-refund-application.component';
import { ViewRejectedRefundApplicationComponent } from './component/dashboards/dashboard-external/refund-application-external-user/view-refund-details/view-rejected-refund-application/view-rejected-refund-application.component';
import { ViewFurtherRequirementRefundApplicationComponent } from './component/dashboards/dashboard-external/refund-application-external-user/view-refund-details/view-further-requirement-refund-application/view-further-requirement-refund-application.component';
import { ViewRefundApplicationComponent } from './component/dashboards/dashboard-external/refund-application-external-user/view-refund-details/view-refund-application/view-refund-application.component';
import { RefundOpinionReportsComponent } from './component/dashboards/dashboard-internal-user/reports/refund-opinion-reports/refund-opinion-reports.component';
import { StampDutyRefundApplicationRecievedReportComponent } from './component/dashboards/dashboard-internal-user/reports/refund-opinion-reports/stamp-duty-refund-application-recieved-report/stamp-duty-refund-application-recieved-report.component';
import { PaymentPeriodReportComponent } from './component/dashboards/dashboard-internal-user/reports/refund-opinion-reports/payment-period-report/payment-period-report.component';
import { RefundRejectedApplicationReportComponent } from './component/dashboards/dashboard-internal-user/reports/refund-opinion-reports/refund-rejected-application-report/refund-rejected-application-report.component';
import { ReimbursementReportsComponent } from './component/dashboards/dashboard-internal-user/reports/reimbursement-reports/reimbursement-reports.component';
import { MonthlyRequestedReimbursementReportComponent } from './component/dashboards/dashboard-internal-user/reports/reimbursement-reports/monthly-requested-reimbursement-report/monthly-requested-reimbursement-report.component';
import { DetailsOfReimbursementReportComponent } from './component/dashboards/dashboard-internal-user/reports/reimbursement-reports/details-of-reimbursement-report/details-of-reimbursement-report.component';
import { TotalReimbursementReportComponent } from './component/dashboards/dashboard-internal-user/reports/reimbursement-reports/total-reimbursement-report/total-reimbursement-report.component';
import { FinalBalanceReportComponent } from './component/dashboards/dashboard-internal-user/reports/refund-opinion-reports/final-balance-report/final-balance-report.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardExternalComponent,
    RegistrationComponent,
    TopNavigationComponent,
    NewApplicationComponent,
    ViewAssessmentRequestComponent,
    DocumentUploadComponent,
    DocumentEditComponent,
    DocumentPreviewComponent,
    SideNavigationComponent,
    DashboardInternalComponent,
    ApproveRegistrationComponent,
    ViewAllRegistrationComponent,
    HeaderInternalComponent,
    HeaderExternalComponent,
    AdminComponent,
    AdminSidenavComponent,
    CompanyCategoryComponent,
    CompanyTypeComponent,
    ProvinceComponent,
    DistrictComponent,
    GramaNiladariDivisionComponent,
    DocumentTypeComponent,
    AddDocumentTypeComponent,
    SearchDocumentTypeComponent,
    FloorSubTypeComponent,
    FloorTypeComponent,
    BuildingTypeComponent,
    BuildnigSubTypeComponent,
    LocalAuthorityComponent,
    OfficeComponent,
    PaymentMethodComponent,
    PropertyFacilityComponent,
    ProprtyTypeComponent,
    PropertyUsageComponent,
    PropertyUsageSubtypeComponent,
    AddCompanyCategoryComponent,
    SearchCompanyCategoryComponent,
    ViewCompanyCategoryComponent,
    AddDistrictComponent,
    ViewDistrictComponent,
    SearchDistrictComponent,
    UpdateCompanyCategoryComponent,
    SearchDistrictComponent,
    AddCompanyTypeComponent,
    SearchCompanyTypeComponent,
    ViewCompanyTypeComponent,
    AddDeedNatureComponent,
    ViewDeedNatureComponent,
    SearchDeedNatureComponent,
    DeedNatureComponent,
    AddFloorTypeComponent,
    SearchFloorTypeComponent,
    ViewFloorTypeComponent,
    AddProvinceComponent,
    ViewProvinceComponent,
    SearchProvinceComponent,
    AddPropertyTypeComponent,
    ViewPropertyTypeComponent,
    SearchPropertyTypeComponent,
    DeleteCompanyCategoryComponent,
    DeleteCompanyTypeComponent,
    UpdateCompanyTypeComponent,
    DeleteDeedNatureComponent,
    UpdateDeedNatureComponent,
    DeleteFloorTypeComponent,
    UpdateFloorTypeComponent,
    DeletePropertyTypeComponent,
    UpdatePropertyTypeComponent,
    DeleteProvinceComponent,
    UpdateProvinceComponent,
    DeleteDocumentTypeComponent,
    UpdateDocumentTypeComponent,
    AddPaymentMethodComponent,
    DeletePaymentMethodComponent,
    ViewPaymentMethodComponent,
    UpdatePaymentMethodComponent,
    SearchPaymentMethodComponent,
    AddOfficeComponent,
    DeleteOfficeComponent,
    UpdateOfficeComponent,
    ViewOfficeComponent,
    SearchOfficeComponent,
    WizardComponent,
    GranteeComponent,
    GrantorComponent,
    PropertyComponent,
    PropertyDeedDetailsComponent,
    PropertyAssessmentComponent,
    PropertyExtendComponent,
    PropertyOtherDetailsComponent,
    PropertyLoanDetailsComponent,
    PropertyBuildingComponent,
    OtherComponent,
    PaymentComponent,
    DeclarationComponent,
    FileUploadComponent,
    PendingRequestsComponent,
    CompletedRequestsComponent,
    RejectedRequestsComponent,
    ViewRequestsComponent,
    ViewGranteeComponent,
    ViewGrantorComponent,
    ViewNotaryComponent,
    ViewPropertyComponent,
    ViewOtherComponent,
    ViewPaymentComponent,
    ViewCalculationComponent,
    ViewValuationComponent,
    ExchangeComponent,
    TransferComponent,
    GiftComponent,
    TextImgComponent,
    ValuationComponent,
    ValuationInternalComponent,
    AddGramaNiladariDivisionComponent,
    DeleteGramaNiladariDivisionComponent,
    UpdateGramaNiladariDivisionComponent,
    ViewGramaNiladariDivisionComponent,
    SearchGramaNiladariDivisionComponent,
    ViewDocumentTypeComponent,
    AddFloorSubTypeComponent,
    DeleteFloorSubTypeComponent,
    UpdateFloorSubTypeComponent,
    ViewFloorSubTypeComponent,
    SearchFloorSubTypeComponent,
    SearchFloorSubTypeComponent,
    AddLocalAuthorityComponent,
    DeleteLocalAuthorityComponent,
    ViewLocalAuthorityComponent,
    SearchLocalAuthorityComponent,
    UpdateLocalAuthorityComponent,
    AddPropertyFacilityComponent,
    DeletePropertyFacilityComponent,
    UpdatePropertyFacilityComponent,
    ViewPropertyFacilityComponent,
    SearchPropertyFacilityComponent,
    AddPropertyUsageComponent,
    UpdatePropertyUsageComponent,
    ViewPropertyUsageComponent,
    SearchPropertyUsageComponent,
    AddPropertyUsageSubtypeComponent,
    UpdatePropertyUsageSubtypeComponent,
    DeletePropertyUsageSubtypeComponent,
    ViewPropertyUsageSubtypeComponent,
    SearchPropertyUsageSubtypeComponent,
    DeleteDistrictComponent,
    UpdateDistrictComponent,
    DeletePropertyUsageComponent,
    PaymentResponseComponent,
    DraftApplicationRequestsComponent,
    PaymentResponseComponent,
    ForgotPasswordTempComponent,
    ResetRedirectComponent,
    ChangePasswordComponent,
    BankComponent,
    AddBankComponent,
    DeleteBankComponent,
    SearchBankComponent,
    UpdateBankComponent,
    ViewBankComponent,
    TwoPartyComponent,
    ViewAssessmentRequestInternalComponent,
    PendingRequestsInternalComponent,
    CompletedRequestsInternalComponent,
    RejectedRequestsInternalComponent,
    ViewRequestsInternalComponent,
    ViewGranteeInternalComponent,
    ViewGrantorInternalComponent,
    ViewNotaryInternalComponent,
    ViewPropertyInternalComponent,
    ViewOtherInternalComponent,
    ViewPaymentInternalComponent,
    ViewValuationInternalComponent,
    ViewCalculationInternalComponent,
    DeleteUserComponent,
    SearchUserComponent,
    UpdateUserComponent,
    ViewUserComponent,
    UserTypeComponent,
    AddUserComponent,
    // RoleComponent,
    // AddRoleComponent,
    // UpdateRoleComponent,
    // DeleteRoleComponent,
    // ViewRoleComponent,
    // SearchRoleComponent,
    FileUpload1Component,
    FileUpload2Component,
    FileUpload3Component,
    FileUpload4Component,
    FileUpload5Component,
    FileUpload6Component,
    FileUploadGranteeComponent,
    FileUploadGranterComponent,
    FileUploadComponentBank,
    FileUploadComponentCash,
    FileUploadAssessmentComponent,
    DownloadModuleComponent,
    FileUploadOpinionDocumentComponent,
    DashboardInternalUserComponent,
    TopNavigationInternalUserComponent,
    HeaderInternalUserComponent,
    FileUpload4InternalUserComponent,
    FileUpload1InternalUserComponent,
    FileUploadGranteeInternalUserComponent,
    FileUploadGranterInternalUserComponent,
    FileUploadInternalUserComponent,
    FileUpload5InternalUserComponent,
    FileUpload6InternalUserComponent,
    FileUpload3InternalUserComponent,
    FileUpload2InternalUserComponent,
    AssignAccessorComponent,
    CertificateComponent,
    CompletedReqIUComponent,
    PendingReqIUComponent,
    RejectedReqIUComponent,
    SiteVisitScheduleComponent,
    WizardInternalUserComponent,
    InspectionReportBuildingComponent,
    InspectionReportCondominiumComponent,
    InspectionReportLandComponent,
    InspectionReportMainComponent,
    InspectionReportRecreationComponent,
    ViewBuildingInternalUserComponent,
    ViewGranteeInternalUserComponent,
    ViewGrantorInternalUserComponent,
    ViewNotaryInternalUserComponent,
    ViewOtherInternalUserComponent,
    ViewPaymentInternalUserComponent,
    ViewPropertyInternalUserComponent,
    ViewValuationInternalUserComponent,
    SafeHtmlPipe,
    ViewAssessmentRequestInternalUserComponent,
    NewApplicationInternalUserComponent,
    CondominiumComponent,
    LandComponent,
    LandBuildingComponent,
    ReCreationComponent,
    // ValuationDetailComponent,
    RecreationCondominiumComponent,
    RecreationLandBuildingComponent,
    RecreationLandComponent,
    SafeHtmlPipe,
    ViewAssessmentRequestInternalUserComponent,
    NewApplicationInternalUserComponent,
    FileUploadPaymentComponent,
    PendingPaymentRequestComponent,
    PendingPaymentInternalUserComponent,
    CompletedPaymentInternalUserComponent,
    RegexFormatDirective,
    RefundApplicationExternalUserComponent,
    RefundApplicationHeaderComponent,
    RefundApplicationRefundDetailsComponent,
    RefundApplicationDocumentsComponent,
    RefundApplicationOpinionDetailsComponent,
    ExchangeCalculationComponent,
    ExchangeCondominiumComponent,
    WizardInternalExchangeComponent,
    ExchangeLandComponent,
    ExchangeLandBuildingComponent,
    SiteVisitScheduleExchangeComponent,
    ExchangeRecreationComponent,
    ExchangeInspectionMainComponent,
    FurtherRequirementComponent,
    ExchangeFrComponent,
    GiftFrComponent,
    TransferFrComponent,
    WizardFrComponent,
    FurtherRequirementUpdateComponent,
    DeclarationFrComponent,
    GranteeFrComponent,
    GrantorFrComponent,
    OtherFrComponent,
    PaymentFrComponent,
    PropertyFrComponent,
    PropertyAssessmentFrComponent,
    PropertyBuildingFrComponent,
    PropertyDeedDetailsFrComponent,
    PropertyExtendFrComponent,
    PropertyLoanDetailsFrComponent,
    PropertyOtherDetailsFrComponent,
    GetOpinionDialogComponent,
    ViewRefundRequestUiComponent,
    ViewRefundNewApplicationsComponent,
    FirstDashboardInternalUserComponent,
    RefundInternalUserDashboardComponent,
    AsignAccessorForRefundComponent,
    ViewRefundSideNavigationComponent,
    ViewOpinionDetailsComponent,
    ViewRefundDetailsComponent,
    ViewDocumentsComponent,
    RefundCalculationComponent,
    ViewRefundCollectionDetailsComponent,
    ViewCollectionDetailsComponent,
    AddPaymentDetailsDialogComponent,
    DocumentUploadOneComponent,
    DocumentUploadTwoComponent,
    DocumentUploadThreeComponent,
    DocumentUploadFourComponent,
    DocumentUploadFiveComponent,
    DocumentUploadSixComponent,
    DocumentUploadSevenComponent,
    DocumentUploadEightComponent,
    DocumentUploadNineComponent,
    DocumentUploadTenComponent,
    DocumentUploadElevenComponent,
    DocumentUploadTwelveComponent,
    DocumentUploadThirteenComponent,
    RefundFormsComponent,
    DocumentUploadFourteenComponent,
    IssueDetailsComponent,
    ReportsComponent,
    ReportsSidenavComponent,
    StampDutyOpinionReportsComponent,
    // RefundOpinionReportsComponent,
    // ReimbursementReportsComponent,
    OpinionRegistrationComponent,
    RimFormComponent,
    RimSideNavigationComponent,
    RimDocumentComponent,
    RimAssignTaxOfficerComponent,
    RimPaybleNonpaybleComponent,
    RimPaymentDetailsComponent,
    RimSecondaryDashboardComponent,
    RimViewApplicationComponent,
    RimViewNewApplicationSideNavComponent,
    ViewAppealRequestComponent,
    AppealRequestComponent,
    OpinionInformationComponent,
    DashboardAppealComponent,
    ViewAssessmentAppealComponent,
    AppealNewApplicationComponent,
    AppealPendingApplicationComponent,
    AppealPendingPaymentApplicationComponent,
    AppealCompletedApplicationComponent,
    AppealRejectedApplicationComponent,
    AppealCompletedPaymentApplicationComponent,
    AppealWizardComponent,
    AppealAssignerComponent,
    AppealRequestFormComponent,
    AppealOpinionInformationComponent,
    DocumentUploadCollectionPaymentComponent,
    RimAccountantDocumentUploadComponent,
    FurtherRequirementInternalUserComponent,
    ValuationRequestHistoryComponent,
    DocumentUploadDeedInternalPaymentComponent,
    ViewBuildingIuExchangeComponent,
    ViewOtherIuExchangeComponent,
    PasswordPolicyComponent,
    CreatePasswordPolicyComponent,
    UpdatePasswordPolicyComponent,
    ViewPasswordPolicyComponent,
    EmployeeComponent,
    CreateEmployeeComponent,
    UpdateEmployeeComponent,
    ViewEmployeeComponent,
    RefundFurtherRequirementComponent,
    InspectionMasterDataComponent,
    InspectionSubPropertyComponent,
    InspectionSidenavComponent,
    InspectionReservationTypeComponent,
    InspectionLandShapeComponent,
    InspectionLandSlopeComponent,
    InspectionWaterAvailabilityTypeComponent,
    InspectionWaterSourceComponent,
    InspectionElectricityTypeComponent,
    InspectionElectricitySubTypeComponent,
    InspectionLandSutabilityComponent,
    InspectionTransportedTypeComponent,
    InspectionEducationTypeComponent,
    InspectionIndustrialTypeComponent,
    InspectionHealthTypeComponent,
    InspectionSecurityTypeComponent,
    InspectionTelecomunicationTypeComponent,
    InspectionBusinessTypeComponent,
    InspectionIndustrialAreaTypeComponent,
    InspectionCultivationTypeComponent,
    InspectionLandTypeComponent,
    InspectionTownComponent,
    InspectionCondominiumTypeComponent,
    InspectionFacilityTypeComponent,
    InspectionParkingTypeComponent,
    InspectionGardenTypeComponent,
    InspectionViewTypeComponent,
    InspectionSurroundTypeComponent,
    InspectionAgreementTypeComponent,
    InspectionAccomodationTypeComponent,
    PropertyUsageSubTypeNewComponent,
    DistrictNewComponent,
    GramaNiladariDivisionNewComponent,
    CertificateExchangeComponent,
    PrintNonPayableComponent,
    PrintPayableComponent,
    LandRegistryComponent,
    InspectionCategoryPropertyTypeComponent,
    InspectionReportRecreationLandComponent,
    InspectionReportRecreationBuildingComponent,
    InspectionReportRecreationCondominiumComponent,
    OpinionRegistrationReportComponent,//epic theeban :- report Module
    ApplicationPaymentDetailsReportComponent,//epic theeban :- report Module
    AssessorDistributionReportComponent,//epic theeban :- report Module
    // RejectedAppReportComponent,
    CertifiedDetailReportComponent, BareLandReportComponent, RegistrationDetailsReportComponent, CondominiumReportComponent, LandRegistryDetailsReportComponent, LandWithBuildReportComponent, NotaryDetailsReportComponent, RejectedAppealsReportComponent, RejectedApplicationReportComponent, CertifiedOpinionDetailsReportComponent, CertifiedOpinionCountReportComponent, ApplicationPaymentRejectedReportComponent, SetlledAppealsSummaryReportComponent, CertifiedOpinionSummaryReportComponent,  CertifiedOpinionSummaryReportComponent, NotCertifiedOpinionReportComponent, EvidencesOpinionReportComponent, CertifiedOpinionSummaryCountReportComponent, //epic theeban :- report Module
    AppOtherParty2Component,
    InspectionReportLandParty2Component,
    InspectionReportCondominiumParty2Component,
    CommisionerComponent,
    DeputyCommisionerComponent,
    AssessorComponent,
    TaxOfficerComponent,
    CommisionerOpinionApplicationComponent,
    CommisionerRefundApplicationComponent,
    CommisionerReimbursementApplicationComponent,
    CommisionerAppealApplicationComponent,
    DCommisionerOpinionApplicationComponent,
    DCommisionerRefundApplicationComponent,
    DCommisionerReimbursementApplicationComponent,
    DCommisionerAppealApplicationComponent,
    AssessorOpinionApplicationComponent,
    AssessorRefundApplicationComponent,
    AssessorReimbursementApplicationComponent,
    AssessorAppealApplicationComponent,
    TaxOfficerOpinionApplicationComponent,
    TaxOfficerRefundApplicationComponent,
    TaxOfficerReimbursementApplicationComponent,
    TaxOfficerAppealApplicationComponent,
    FirstDashboardExternalUserComponent,
    RefundExternalUserDashboardComponent,
    ViewDraftRefundApplicationComponent,
    ViewNewRefundApplicationComponent,
    ViewCompletedRefundApplicationComponent,
    ViewRejectedRefundApplicationComponent,
    ViewFurtherRequirementRefundApplicationComponent,
    ViewRefundApplicationComponent,
    RefundOpinionReportsComponent,
    StampDutyRefundApplicationRecievedReportComponent,
    PaymentPeriodReportComponent,
    RefundRejectedApplicationReportComponent,
    ReimbursementReportsComponent,
    MonthlyRequestedReimbursementReportComponent,
    DetailsOfReimbursementReportComponent,
    TotalReimbursementReportComponent,
    RefundOpinionReportsComponent,
    StampDutyRefundApplicationRecievedReportComponent,
    PaymentPeriodReportComponent,
    RefundRejectedApplicationReportComponent,
    FinalBalanceReportComponent,

  ],
  imports: [
    ImageCropperModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatInputModule,
    AppRoutingModule,
    AngularEditorModule,
    CKEditorModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: "right",
          distance: 12
        },
        vertical: {
          position: "top",
          distance: 12,
          gap: 10
        }
      },
      theme: "material",
      behaviour: { autoHide: 4000, onMouseover: "pauseAutoHide" }
    }),
    ImageCropperModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgxSpinnerModule,
    BrowserAnimationsModule,
    PdfViewerModule,
    NgbModule,
    MatExpansionModule,
    NgxBarcodeModule,
    NgxPrintModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatRadioModule,
    NgxPaginationModule,
    MatDatepickerModule,
    MatNativeDateModule,
    Ng2SearchPipeModule,
    QRCodeModule,
    NgMultiSelectDropDownModule, //epic theeban :- select Module
  ],
  entryComponents:[GetOpinionDialogComponent, AddPaymentDetailsDialogComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB'},

    UsernameValidator,
    InspectionDataServiceService,
    RefundOpinionServiceService,
    DatePipe,
    AuthGuard,
    UserRoleGuard,
    InternalUserAuth,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
