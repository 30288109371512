import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/*
 * @Author Suren Anthony Vithanage
 * @Project WPRD
 */

@Directive({
  selector: '[appRegexFormat]'
})
export class RegexFormatDirective {

  @Input('regexType') numericType: string; // number , decimal etc

  private regex = {
    number: new RegExp(/^\d+$/),
    decimal: new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g),
    letter_num: new RegExp(/^[a-zA-Z0-9]+$/g),
    letter_space: new RegExp(/^[a-zA-Z ]+$/g),
    letter_only: new RegExp(/[a-zA-Z]/),
    number_only: new RegExp(/^[0-9]+$/g),
    nic: new RegExp(/[0-9vV]$/g),
    amount_two_decimal_only: new RegExp(/^\d*\.?\d{0,2}$/g),
    remark: new RegExp(/^[a-zA-Z0-9-./(),+?~ ]+$/g),
    buildingNoName: new RegExp(/^[a-zA-Z0-9 '-_/]+$/g),
    buildingMaterial: new RegExp(/^[a-zA-Z0-9 '"-_/()%.,]+$/g),
    condominiumUnit: new RegExp(/^[a-zA-Z0-9 '"-_/()%.,]+$/g)
  };
  private specialKeys = {
    number: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' , 'Control', 'v', 'V'],
    decimal: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Control', 'v', 'V' ],
    letter_num: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' , 'Control', 'v', 'V'],
    letter_space: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' , 'Control', 'v', 'V'],
    letter_only: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' , 'Control', 'v', 'V'],
    nic: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' , 'Control', 'v', 'V'],
    number_only: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' , 'Control', 'v', 'V'],
    amount_two_decimal_only: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' , 'Control', 'v', 'V'],
    remark: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' , 'Control', 'v', 'V'],
    buildingNoName: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' , 'Control', 'v', 'V'],
    buildingMaterial: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' , 'Control', 'v', 'V'],
    condominiumUnit: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' , 'Control', 'v', 'V']
  };

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', [ '$event' ])
  onKeyDown(event: KeyboardEvent) {

    const element = this.el.nativeElement;

    if ((event.key === 'v' || event.key === 'V' || event.ctrlKey)) {
      setTimeout(function (evet) {
        element.click();
      }, 100);
    }

    if (this.specialKeys[this.numericType].indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex[this.numericType])) {
      event.preventDefault();
    }

  }

  @HostListener('click', [ '$event' ])
  onKeyDown2(event: KeyboardEvent) {

    const current: string = this.el.nativeElement.value;
    const element = this.el.nativeElement;

    if (current) {
      if (!String(current).match(this.regex[this.numericType])) {
        this.el.nativeElement.value = '';
        setTimeout(function (evet) {
          element.click();
        }, 100);
      }
    }

  }
}
