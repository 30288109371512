import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { CompanyTypeService } from 'src/app/service/company-type/company-type.service';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/service/data.service';
import { CompanyType } from 'src/app/model/companyType';

@Component({
  selector: 'app-search-company-type',
  templateUrl: './search-company-type.component.html',
  styleUrls: ['./search-company-type.component.scss']
})
export class SearchCompanyTypeComponent implements OnInit, OnDestroy {

  addCompanyTypeForm: FormGroup;

  valid: boolean;
  private sub = new SubSink();

  constructor(private companyTypeService: CompanyTypeService,
              private formBuilder: FormBuilder,
              private notifier: NotifierService,
              private dataService: DataService) { }



  ngOnInit() {

    this.addCompanyTypeForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: ['']
    });
  }

  get f() { return this.addCompanyTypeForm.controls; }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


  saveCompanyType() {
    if (this.addCompanyTypeForm.valid) {

      const companyType = new CompanyType();
      companyType.description = this.getDescription();
      companyType.name = this.getName();
      this.companyTypeService.createCompanyType(companyType)
        .subscribe(response => {
          this.notifier.notify('success', (response as any).message);
          this.dataService.updateTableCompanyType(true);
        });

      this.reset();
    }
  }

  getName() {
    return this.addCompanyTypeForm.get('name').value;
  }

  getDescription() {
    return this.addCompanyTypeForm.get('description').value;
  }

  reset() {
    this.addCompanyTypeForm.reset();
  }
}
