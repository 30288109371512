export class refundRequestCommon{

  commissionerId: string;
  commissionerStatus:string;
  dcommissionerId:string;
  dcommissionerStatus:string;
  assessorId:string;
  assessorStatus:string;
  status:string;
  deedNumber:number;
  refundOpinionId:number;
  refundGrantorId:number;
  refundNotaryId:number;
  refundReasonId:number;
  hasOpinion:boolean;



  constructor(
  commissionerId: string,
  commissionerStatus:string,
  dcommissionerId:string,
  dcommissionerStatus:string,
  assessorId:string,
  assessorStatus:string,
  status:string,
  deedNumber:number,
  refundOpinionId:number,
  refundGrantorId:number,
  refundNotaryId:number,
  refundReasonId:number,
  hasOpinion:boolean,

  ){
    this.commissionerId = commissionerId;
    this.commissionerStatus = commissionerStatus;
    this.dcommissionerId = dcommissionerId;
    this.dcommissionerStatus = dcommissionerStatus;
    this.assessorId = assessorId;
    this.assessorStatus = assessorStatus;
    this.status = status;
    this.deedNumber = deedNumber;
    this.refundOpinionId = refundOpinionId
    this.refundGrantorId = refundGrantorId;
    this.refundNotaryId = refundNotaryId;
    this.refundReasonId = refundReasonId;
    this.hasOpinion = hasOpinion;

  }

}

