import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsernameCheckService {

  constructor(private http: HttpClient) {
  }

  validateUsername(username){
    return this.http.get<number>(environment.user_api_url + '/register/validate-username/' + username);
  }

}
