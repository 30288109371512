import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import { environment } from 'src/environments/environment';
import { Office } from 'src/app/model/office';
import { catchError, tap } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import {IOfficePage} from '../../model/interfaces/office-page';

@Injectable({
  providedIn: 'root'
})
export class OfficeService {

  private officeUrl = '/office';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  getOffices(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.officeUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  getAll(): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.officeUrl + '/all');

  }

  createOffice(office: Office): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.officeUrl}`,
      JSON.stringify(office), this.httpOptions)
      .pipe(tap(_ => this.log(`update office create=${office}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('office  create'))
      );
  }

  updateOffice(id: number, office: Office): Observable<any> {
    return this.http.put(`${environment.api_url}${this.officeUrl}/${id}`, office)
      .pipe(tap(_ => this.log(`update office  id=${office.id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('office update'))
      );
  }

  /** DELETE: delete the company category on the server */
  deleteOffice(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.officeUrl}/${id}`)
      .pipe(tap(_ => this.log(`update office  id=${id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('office'))
      );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      this.notifier.notify('error', `${operation} failed`);
      return of(result as T);
    };
  }
  private log(message: string) {

  }

  /* Search office from server*/
  searchOffice(keyword: string, offset: number, limit: number): Observable<IOfficePage> {
    // Parameters
    const params = {
      params: {
        offset: offset.toString(),
        limit: limit.toString(),
        keyword
      }
    };
    return this.http.get<IOfficePage>(`${environment.api_url}${this.officeUrl}/search`, params)
      .pipe(tap(_ => this.log(`search Offices`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('get Offices'))
      );
  }
}
