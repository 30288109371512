import {Component, Input, OnInit} from '@angular/core';
import {IGrantee} from '../../../../../../../model/interfaces/grantee';
import {Grantee} from '../../../../../../../model/grantee';
import {Province} from '../../../../../../../model/province';
import {CompanyCategory} from '../../../../../../../model/companyCategory';
import {CompanyType} from '../../../../../../../model/companyType';
import {ValuationRequest} from '../../../../../../../model/valuation-request';
import {SubSink} from 'subsink';
import {ProvinceService} from '../../../../../../../service/province/province.service';
import {CompanyCategoryService} from '../../../../../../../service/company-category/company-category.service';
import {CompanyTypeService} from '../../../../../../../service/company-type/company-type.service';
import {ValuationRequestService} from '../../../../../../../service/valuation-request/valuation-request.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FileService} from "../../../../../../../service/file/file.service";
import {DomSanitizer} from "@angular/platform-browser";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-view-grantee-internal',
  templateUrl: './view-grantee-internal.component.html',
  styleUrls: ['./view-grantee-internal.component.scss']
})
export class ViewGranteeInternalComponent implements OnInit {
  @Input()
  grantees: Grantee [];
  @Input()
  valuationRequestId: string;

  provincesorderd: Province[];
  companyCategories: CompanyCategory[];
  companyType: CompanyType[];
  valuationRequest: ValuationRequest;

  previewFileData: any;
  selectFileSm = 0;
  // valuationRequestID: any;
  noDocs = false;
  dataDone = false;
  private sub = new SubSink();

  constructor(private provinceService: ProvinceService,
              private companyCategoryService: CompanyCategoryService,
              private companyTypeService: CompanyTypeService,
              private valuationRequestService: ValuationRequestService,
              private  router: Router, private route: ActivatedRoute,
              private fileService: FileService,
              public sanitizer: DomSanitizer,
              private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.sub.add(this.companyCategoryService.getAll().subscribe(
      res => {
        this.companyCategories = (res as any).data;
      }
    ));

    this.sub.add(this.provinceService.getProvincesOrderd().subscribe(
      (res) => {
        this.provincesorderd = (res as any).data;
      }
    ));

    // get All company Type
    this.sub.add(this.companyTypeService.getAll().subscribe(
      res => {
        this.companyType = (res as any).data;
      }
    ));
  }


  previewFile(type) {
    this.spinner.show();
    this.dataDone=false;
    this.noDocs=false;
    this.fileService.loadFileById(this.valuationRequestId, type).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs=false;
        this.dataDone=true;

        setTimeout(()=>{
          this.spinner.hide();
        }, 2000);

      }else {
        this.spinner.hide();
        this.dataDone=true;
        this.noDocs=true;
      }

    }, () => {
      this.dataDone=true;
      this.noDocs=true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }

}
