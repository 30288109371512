import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { InspectionGardenTypeService } from 'src/app/service/inspection-master-data-service/inspection-garden-type/inspection-garden-type.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inspection-garden-type',
  templateUrl: './inspection-garden-type.component.html',
  styleUrls: ['./inspection-garden-type.component.scss']
})
export class InspectionGardenTypeComponent implements OnInit,OnDestroy {

  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  createGardenTypeForm: FormGroup;
  updateGardenTypeForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  propertyTypeList: any =[];
  activationButton: boolean;
  public createGardenTypeModel = new InspectionCommonDataModel(null,'','','','');
  public updateGardenTypeModel = new InspectionCommonDataModel(null,'','','','');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;
  GardenTypes: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionGardenTypeService: InspectionGardenTypeService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createGardenTypeForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['',Validators.pattern(this.NoWhitespaceRegExp)],
    });
    this.updateGardenTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['',Validators.pattern(this.NoWhitespaceRegExp)],
    });

    // this.loadPropertyType();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }



  //create Garden Type - start

  get f() { return this.createGardenTypeForm.controls; }

  setGardenTypeModelToSave(){
    this.createGardenTypeModel.name = this.createGardenTypeForm.get('name').value;
    this.createGardenTypeModel.description = this.createGardenTypeForm.get('description').value;
    this.createGardenTypeModel.code = this.createGardenTypeForm.get('code').value;
    this.createGardenTypeModel.dataStatus = "ACTIVE";
  }

    //save Garden Type model
  saveGardenType(){
    this.inspectionGardenTypeService.getGardenType(0,this.totalItems).subscribe(res=>{
      const allGardenType = (res as any).data.content
      if (allGardenType.map((gardentype)=>String(gardentype.name.replace(/\s/g,""))).includes(String(this.createGardenTypeForm.get('name').value.replace(/\s/g,"")))){
        this.notifier.notify('error', 'garden type name '+ this.createGardenTypeForm.get('name').value +' is exist');
        return;
      }
    this.setGardenTypeModelToSave();
    this.sub.add(
      this.inspectionGardenTypeService.createGardenType(this.createGardenTypeModel).subscribe(data=>{
        this.notifier.notify('success', (data as any).message);
        this.updateTheTable();
        this.reset();
      })
      );
    })
  }

  reset(){
    this.createGardenTypeForm.reset()
  }
  //create Garden Type - end

  //update Garden Type - start

    //get update controllers
  get f1() { return this.updateGardenTypeForm.controls; }

    //set data To Garden Type Type model
  setGardenType(garden:any){
    this.updateGardenTypeForm.get('id').setValue(garden.id);
    this.updateGardenTypeForm.get('name').setValue(garden.name);
    this.updateGardenTypeForm.get('description').setValue(garden.description);
    this.updateGardenTypeForm.get('code').setValue(garden.code);

    if(garden.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateGardenTypeModel.id = this.updateGardenTypeForm.get('id').value;
    this.updateGardenTypeModel.name = this.updateGardenTypeForm.get('name').value;
    this.updateGardenTypeModel.description = this.updateGardenTypeForm.get('description').value;
    this.updateGardenTypeModel.code = this.updateGardenTypeForm.get('code').value;

    if(this.activationButton == true){
      this.updateGardenTypeModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateGardenTypeModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateGardenType(){
    this.inspectionGardenTypeService.getGardenType(0,this.totalItems).subscribe(res=>{
      const allGardenType = (res as any).data.content
      if (allGardenType.map((gardentype)=>String(gardentype.name.replace(/\s/g,""))).includes(String(this.updateGardenTypeForm.get('name').value.replace(/\s/g,"")))){
        this.notifier.notify('error', 'garden type name '+ this.updateGardenTypeForm.get('name').value +' is exist');
        return;
      }
    this.setDataToModel();
    this.sub.add(this.inspectionGardenTypeService.updateGardenType(this.updateGardenTypeModel.id, this.updateGardenTypeModel).subscribe(data=>{
      this.notifier.notify('success', (data as any).message);
      this.updateTheTable();
    }))
  })
  }

  //update Garden Type - end

//search and view table start
  pageable(i: number) {
    this.inspectionGardenTypeService.getGardenType((i-1),10).subscribe(res=>{
      this.GardenTypes = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionGardenTypeService.getGardenType(0, 10)
      .subscribe(
        (res) => {
          this.GardenTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search Garden Type Type
    searchGardenType(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionGardenTypeService.searchGardenType(this.keyword,0,10).subscribe(res=>{
          this.GardenTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionGardenTypeService.deleteGardenType(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end

}
