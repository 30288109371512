import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DashboardService } from 'src/app/service/dashboard/dashboard.service';
import { NotificationModelUpdate } from 'src/app/model/notification-model-update';
import { Constant } from 'src/app/util/constant';
import { ValuationRequestCount } from 'src/app/model/valuation_request_count';
import { ReimbursementApplicationCreate } from 'src/app/model/rim-application-create';
import { RimApplicationService } from 'src/app/service/reimbursement/rim-application.service';
import { AuthService } from 'src/app/auth/auth.service';
import { RefundOpinionServiceService } from 'src/app/service/RefundOpinionService/refund-opinion-service.service';


@Component({
  selector: 'app-first-dashboard-internal-user',
  templateUrl: './first-dashboard-internal-user.component.html',
  styleUrls: ['./first-dashboard-internal-user.component.scss']
})
export class FirstDashboardInternalUserComponent implements OnInit {

  public notificationModelUpdate = new NotificationModelUpdate();
  public model = new ValuationRequestCount('', '', '');
  public reimbursementApplicationCreateModel = new ReimbursementApplicationCreate();

  refundNotificationCode = Constant.NOTIFICATION_CATEGORY_REFUND
  opinionNotificationCode = Constant.NOTIFICATION_CATEGORY_OPINION
  appealNotificationCode = Constant.NOTIFICATION_CATEGORY_APPEAL
  rimNotificationCode = Constant.NOTIFICATION_CATEGORY_RIM
  
  isTaxOfficerOrAccountant:Boolean;
  userName: string;
  numberOfNotifications: number;
  notifications: any = [];

  userData: any = [];
  userRoleCode: string;
  showToAssessor: boolean;

  constructor(
    private dashboardService: DashboardService,
    private authService: AuthService,
    private reimbursementApplicationservice: RimApplicationService,
    private  router: Router,
    private refundService:RefundOpinionServiceService
  ) {
    this.showToAssessor = false;
   }

  ngOnInit() {
    this.userName = sessionStorage.getItem('username');
    this.listNotifications();
    this.obtainUserData();
    this.getUserPermision();
    sessionStorage.removeItem('deedType');
  }

  obtainUserData() {
    this.userData = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.model.userId = this.userData.id;
    for (let role of this.userData.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.userRoleCode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.userRoleCode = Constant.ROLE_ACCESSOR;
        this.showToAssessor = true;
        break;
      }else if(role.code === Constant.ROLE_TAX_OFFICER){
        this.userRoleCode = Constant.ROLE_TAX_OFFICER;
        break;
      }else if(role.code === Constant.ROLE_ACCOUNTANT){
        this.userRoleCode = Constant.ROLE_ACCOUNTANT;
        break;
      }
    }
  }

  getUserPermision(){
      if(this.userRoleCode === Constant.ROLE_TAX_OFFICER || this.userRoleCode === Constant.ROLE_ACCOUNTANT){
        this.isTaxOfficerOrAccountant = true
      }
      else{
        this.isTaxOfficerOrAccountant =false
      }
  }
  listNotifications() {
    this.dashboardService.getNotificationList().subscribe(
      data => {
        this.notifications = data.data;
        this.numberOfNotifications = this.notifications.length;
      }
    )
  }

  updateNotification(notificationId, deedNature, url) {

    this.refundService.getRefundRequestByID(parseInt(url)).subscribe( (res) => {
   
      localStorage.removeItem('refundRequest');
      sessionStorage.removeItem('hasOpinion');
      sessionStorage.removeItem('deedNo');
    
      localStorage.setItem('refundRequest' , res.data.id);
      sessionStorage.setItem('hasOpinion', res.data.hasOpinion);
      sessionStorage.setItem('deedNo', res.data.deedNumber);
      sessionStorage.setItem('refundRequest','true');
    
            })

    this.notificationModelUpdate.notificationId = notificationId;

    this.dashboardService.updateNotification(this.notificationModelUpdate).subscribe(
      data => {
        console.log(data);
      }
    )
  }

  viewRefundApplication() {
    sessionStorage.setItem('refundRequest', 'true');
    sessionStorage.setItem('selectedHeader', 'dashboard');
  }

  viewOpinionApplication() {
    sessionStorage.setItem('refundRequest', 'false');
  }

  viewReports() {
    sessionStorage.setItem('selectedHeader', 'reports');
  }

  createReimbursementDraft() {
    this.reimbursementApplicationCreateModel.assessorId = this.userData.id;
    this.reimbursementApplicationCreateModel.assessorCode = this.userData.accessorCode;
    this.reimbursementApplicationCreateModel.provinceCode = this.userData.provinceCode;
    this.reimbursementApplicationCreateModel.districtCode = this.userData.districtCode;

    this.reimbursementApplicationservice.createReimbursementDraftApplication(this.reimbursementApplicationCreateModel).subscribe(
      data => {
        localStorage.setItem('rimFileNumberFormat', data.data.fileNumberFormat);
        sessionStorage.setItem('rimId',data.data.id);
        sessionStorage.setItem('newReimbursement', JSON.stringify(true));
        setTimeout(() => this.router.navigate(['/reimbursment-application/new-request']), 1500);
      }
    )
  }

  logout(){
    this.authService.logout();
  }


}
