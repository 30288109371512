import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inspection-report-recreation',
  templateUrl: './inspection-report-recreation.component.html',
  styleUrls: ['./inspection-report-recreation.component.scss']
})
export class InspectionReportRecreationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
