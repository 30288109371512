import {Component, OnInit, ViewChild} from '@angular/core';
import {ViewFloorSubTypeComponent} from './view-floor-sub-type/view-floor-sub-type.component';

@Component({
  selector: 'app-floor-sub-type',
  templateUrl: './floor-sub-type.component.html',
  styleUrls: ['./floor-sub-type.component.scss']
})
export class FloorSubTypeComponent implements OnInit {
  @ViewChild(ViewFloorSubTypeComponent , {static : false})
  private viewFloorType: ViewFloorSubTypeComponent;
  keyword: string;

  constructor() { }

  ngOnInit() {
  }

  triggerSearch(event: any) {
    if (this.keyword === '') {
      this.viewFloorType.onUpdated(true);
    } else {
      this.viewFloorType.searchFloorSubType(this.keyword);
    }
  }

}
