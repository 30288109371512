import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AppealService} from '../../../../../../service/appeal/appeal.service';
import {Appeal} from '../../../../../../model/appeal';
import Swal from 'sweetalert2';
import {NotifierService} from 'angular-notifier';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-appeal-request',
  templateUrl: './appeal-request.component.html',
  styleUrls: ['./appeal-request.component.scss']
})
export class AppealRequestComponent implements OnInit {
  @Input()
  valuationRequestId: number;
  @ViewChild('popupSuccessMessage', null) private popupMigrated: TemplateRef<object>;

  saveGrantee: boolean;
  saveNotary: boolean;
  validateMsg: boolean;

  public appealModel = new Appeal('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '');

  files;
  filesList: File[] = [];
  images = [];
  originalImages: any[] = [];
  names: string[] = [];

  public imgURL1: any;
  public imgURL2: any;

  constructor(
    public appealService: AppealService,
    private notifier: NotifierService,
    public router: Router,
    public modalService: NgbModal,
    private formBuilder: FormBuilder
  ) {
  }

  nicPattern = '^([0-9]{9}[x|X|v|V]|[0-9]{12})$';
  phoneNumberPattern = '^(?:0|94|\\+94|0094)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|91)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\\d)\\d{6}$';
  emailPattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
  addressPattern = /^[ a-zA-Z0-9./,-/']*$/;
  cityPattern = '^[ a-zA-Z0-9-.]*$';
  companyRegNoPattern = '^[ a-zA-Z0-9-/#]*$';
  onlyTextPattern = '^[ a-zA-Z]*$';
  onlyNumberPattern = '^[ 0-9]*$';
  nameWithInitialsPattern = '^[ a-zA-Z.]*$';

  granteeForm = this.formBuilder.group({
    granteeName: ['', [Validators.required, Validators.pattern(this.nameWithInitialsPattern)]],
    granteenic: ['', [Validators.required, Validators.pattern(this.nicPattern)]],
    granteeaddressline1: ['', [Validators.required, Validators.pattern(this.addressPattern)]],
    granteemobile: ['', [Validators.required, Validators.pattern(this.phoneNumberPattern)]],
    granteeaddressno: [''],
    granteeaddressline2: '',
    granteecity: ['', [Validators.required, Validators.pattern(this.cityPattern)]],
    granteeemail: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
  });

  notaryForm = this.formBuilder.group({
    notaryname: ['', [Validators.required, Validators.pattern(this.nameWithInitialsPattern)]],
    notarynic: ['', [Validators.required, Validators.pattern(this.nicPattern)]],
    notaryno: ['', Validators.required],
    notaryaddressno: ['', [Validators.required]],
    notaryaddressline1: ['', [Validators.required, Validators.pattern(this.addressPattern)]],
    notarymobile: ['', [Validators.required, Validators.pattern(this.phoneNumberPattern)]],
    notaryoffice: ['', [Validators.pattern(this.phoneNumberPattern)]],
    notaryaddressline2: '',
    notarycity: ['', [Validators.pattern(this.cityPattern)]],
    notaryemail: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
  });

  reasonsForm = this.formBuilder.group({
    appealreason: ['', [Validators.required]],
  });

  ngOnInit() {
    this._prepare();
    this.validateMsg = false;
  }

  _prepare() {
    this.loadGranteeInfo();
    this.loadNotaryInfo();
  }

  uploadRequestLetterChange(event) {
    this.files = event.target.files;
    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      if (file.type === "application/pdf" || file.type === "image/png" || file.type === "image/jpeg"|| file.type === "image/jpg") {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          if(file.type === "application/pdf"){
            this.imgURL1 = "assets/images/pdf.png"
          }else{
            this.imgURL1 = reader.result;
          }
          this.images.push(_event.currentTarget.result);
          this.originalImages.push(_event.currentTarget.result);
          this.filesList.push(file);
        };
        this.names.push(file.name);
      } else {
        Swal.fire({
          text: 'File type not allowed here!',
          icon: 'warning',
          showCancelButton: false,

        });
        event.srcElement.value = null;
      }
    }
  }

  uploadOtherChange(event) {
    this.files = event.target.files;
    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      if (file.type === "application/pdf" || file.type === "image/png" || file.type === "image/jpeg"|| file.type === "image/jpg") {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          if(file.type === "application/pdf"){
            this.imgURL2 = "assets/images/pdf.png"
          }else{
            this.imgURL2 = reader.result;
          }
          this.images.push(_event.currentTarget.result);
          this.originalImages.push(_event.currentTarget.result);
          this.filesList.push(file);
        };
        this.names.push(file.name);
      } else {
        Swal.fire({
          text: 'File type not allowed here!',
          icon: 'warning',
          showCancelButton: false,

        });
        event.srcElement.value = null;
      }
    }
  }

  loadGranteeInfo() {
    this.appealService.appealGranteeInfo(this.valuationRequestId).subscribe(
      data => {
       /*  this.appealModel.granteeemail = data.data.email;
        this.appealModel.granteenic = data.data.nic;
        this.appealModel.granteeName = data.data.name;
        this.appealModel.granteeaddressno = data.data.addressno;
        this.appealModel.granteeaddressline1 = data.data.addressline1;
        this.appealModel.granteeaddressline2 = data.data.addressline2;
        this.appealModel.granteecity = data.data.city;
        this.appealModel.granteemobile = data.data.mobile; */
        console.log(data.data);
        this.granteeForm.controls['granteeName'].setValue(data.data.name);
        this.granteeForm.controls['granteenic'].setValue(data.data.nic);
        this.granteeForm.controls['granteeaddressno'].setValue(data.data.addressno);
        this.granteeForm.controls['granteeaddressline1'].setValue(data.data.addressline1);
        this.granteeForm.controls['granteeaddressline2'].setValue(data.data.addressline2);
        this.granteeForm.controls['granteemobile'].setValue(data.data.mobile);
        this.granteeForm.controls['granteecity'].setValue(data.data.city);
        this.granteeForm.controls['granteeemail'].setValue(data.data.email);
      }
    );
  }

  loadNotaryInfo() {
    this.appealService.appealNotaryInfo(this.valuationRequestId).subscribe(
      data => {
        
        /* this.appealModel.notaryemail = data.data.email;
        this.appealModel.notarycity = data.data.officialCity;
        this.appealModel.notaryaddressno = data.data.officialNumber;
        this.appealModel.notaryaddressline1 = data.data.officialAddressL1;
        this.appealModel.notaryaddressline2 = data.data.officialAddressL2;
        this.appealModel.notaryoffice = data.data.officePhone;
        this.appealModel.notarymobile = data.data.personalMobile;
        this.appealModel.notarynic = data.data.nicOrPassport;
        this.appealModel.notaryname = data.data.initials;
        this.appealModel.notaryno = data.data.registrationNumber; */
        this.notaryForm.controls['notaryname'].setValue(data.data.firstName + ' ' + data.data.middleName + ' ' + data.data.lastName);
        this.notaryForm.controls['notarynic'].setValue(data.data.nicOrPassport);
        this.notaryForm.controls['notaryno'].setValue(data.data.registrationNumber);
        this.notaryForm.controls['notaryaddressline1'].setValue(data.data.officialAddressL1);
        this.notaryForm.controls['notaryaddressline2'].setValue(data.data.officialAddressL2);
        this.notaryForm.controls['notarycity'].setValue(data.data.officialCity);
        this.notaryForm.controls['notarymobile'].setValue(data.data.personalMobile);
        this.notaryForm.controls['notaryoffice'].setValue(data.data.officePhone);
        this.notaryForm.controls['notaryemail'].setValue(data.data.email);
      }
    );
  }

  public onSaveGranteeChanged(value: boolean) {
    this.saveGrantee = value;
    if (this.saveGrantee) {
      this.resetGrantee();
    } else {
      this.loadGranteeInfo();
    }
  }

  public onSaveNotaryChanged(value: boolean) {
    this.saveNotary = value;
    if (this.saveNotary) {
      this.resetNotary();
    } else {
      this.loadNotaryInfo();
    }
  }

  resetGrantee() {
    this.granteeForm.reset();
/*     this.appealModel.granteeemail = '';
    this.appealModel.granteenic = '';
    this.appealModel.granteeName = '';
    this.appealModel.granteeaddressno = '';
    this.appealModel.granteeaddressline1 = '';
    this.appealModel.granteeaddressline2 = '';
    this.appealModel.granteecity = '';
    this.appealModel.granteemobile = ''; */
  }

  resetNotary() {
    this.notaryForm.reset();
    /* this.appealModel.notaryemail = '';
    this.appealModel.notarycity = '';
    this.appealModel.notaryaddressno = '';
    this.appealModel.notaryaddressline1 = '';
    this.appealModel.notaryaddressline2 = '';
    this.appealModel.notaryoffice = '';
    this.appealModel.notarymobile = '';
    this.appealModel.notarynic = '';
    this.appealModel.notaryname = '';
    this.appealModel.notaryno = ''; */
  }

  openPopupMessage(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static'});
  }
  get grantee() {
    return this.granteeForm.controls;
  }
  get notary() {
    return this.notaryForm.controls;
  }
  get reason() {
    return this.reasonsForm.controls;
  }

  setValuesToModel(){
    this.appealModel.granteeemail = this.granteeForm.get('granteeemail').value;
    this.appealModel.granteenic = this.granteeForm.get('granteenic').value;
    this.appealModel.granteeName = this.granteeForm.get('granteeName').value;
    this.appealModel.granteeaddressno = this.granteeForm.get('granteeaddressno').value;
    this.appealModel.granteeaddressline1 = this.granteeForm.get('granteeaddressline1').value;
    this.appealModel.granteeaddressline2 = this.granteeForm.get('granteeaddressline2').value;
    this.appealModel.granteecity = this.granteeForm.get('granteecity').value;
    this.appealModel.granteemobile = this.granteeForm.get('granteemobile').value;

    this.appealModel.notaryname = this.notaryForm.get('notaryname').value;
    this.appealModel.notarynic = this.notaryForm.get('notarynic').value;
    this.appealModel.notaryno = this.notaryForm.get('notaryno').value;
    this.appealModel.notaryaddressno = this.notaryForm.get('notaryaddressno').value;
    this.appealModel.notaryaddressline1 = this.notaryForm.get('notaryaddressline1').value;
    this.appealModel.notaryaddressline2 = this.notaryForm.get('notaryaddressline2').value;
    this.appealModel.notarycity = this.notaryForm.get('notarycity').value;
    this.appealModel.notarymobile = this.notaryForm.get('notarymobile').value;
    this.appealModel.notaryoffice = this.notaryForm.get('notaryoffice').value;
    this.appealModel.notaryemail = this.notaryForm.get('notaryemail').value;

    this.appealModel.appealreason = this.reasonsForm.get('appealreason').value;
  }

  //grantee validity check
  checkGranteeValidity(): boolean {
    if (this.granteeForm.get('granteeName').valid && this.granteeForm.get('granteenic').valid && this.granteeForm.get('granteeaddressno').valid && this.granteeForm.get('granteeaddressline1').valid && this.granteeForm.get('granteemobile').valid && this.granteeForm.get('granteecity').valid && this.granteeForm.get('granteeemail').valid) {
      return true;
    } else {
      this.notifier.notify('error', 'Grantee form not valid properly');
      return false;
    }
  }

  checkNotaryValidity(): boolean {
    if (this.notaryForm.get('notaryname').valid && this.notaryForm.get('notarynic').valid && this.notaryForm.get('notaryno').valid && this.notaryForm.get('notaryaddressline1').valid && this.notaryForm.get('notarymobile').valid && this.notaryForm.get('notarycity').valid && this.notaryForm.get('notaryemail').valid) {

      return true;
    } else {
      this.notifier.notify('error', 'Notary form not valid properly');
      return false;
    }
  }
  checkReasonValidity(): boolean {
    if (this.reasonsForm.get('appealreason').valid) {
      return true;
    } else {
      this.notifier.notify('error', 'Reason not filled properly');
      return false;
    }
  }

  checkFiles() : boolean {
    if(this.filesList.length > 0){
      var count = 0;
      this.filesList.forEach( (file) => {
        if(file.size >	20000000){
          count = count + 1;
        }
      });
      if(count > 0){
        this.notifier.notify('error','Uploded files are more than 20MB!');
        return false;
      }
      else{
        return true;
      }
    }
    else{
      this.notifier.notify('error', 'Please upload request letter!');
      return false;
    }
  }

  onSubmit() {
    if(this.checkGranteeValidity() && this.checkNotaryValidity() && this.checkReasonValidity() && this.checkFiles()){
      this.setValuesToModel();
      this.appealModel.valuationRequestId = this.valuationRequestId.toString();
      this.appealService.insertAppeal(this.appealModel).subscribe(
        res => {
          let id = (res as any).data.id
          console.log(res.data)
          this.appealService.uploadAppealDocuments(this.filesList, id).subscribe(
            data => {
              this.openPopupMessage(this.popupMigrated);
            }, err => {
              this.notifier.notify('error', 'Error occurred while uploading.');
            }
          );
          this.redirectToMainTable();
        },
        err => {
          this.notifier.notify('error', 'Error occurred while processing the appeal request.');
        }
      );
    }else{
      this.notifier.notify('error', 'forms are not valid');
    }
  }

  redirectToMainTable() {
    this.modalService.dismissAll();
    this.router.navigate(['/view-request/' + this.valuationRequestId]);
  }
}
