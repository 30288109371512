import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UpdateFloorSubTypeComponent } from '../update-floor-sub-type/update-floor-sub-type.component';
import { DeleteFloorSubTypeComponent } from '../delete-floor-sub-type/delete-floor-sub-type.component';
import { FloorSubTypeService } from 'src/app/service/floor-sub-type/floor-sub-type.service';
import { DataService } from 'src/app/service/data.service';
import { SubSink } from 'subsink';
import {FloorSubType} from '../../../../../../model/floor-sub-type';
import {IFloorSubType} from '../../../../../../model/interfaces/floor-sub-type';

@Component({
  selector: 'app-view-floor-sub-type',
  templateUrl: './view-floor-sub-type.component.html',
  styleUrls: ['./view-floor-sub-type.component.scss']
})
export class ViewFloorSubTypeComponent implements OnInit, OnDestroy {

  floorSubTypes: IFloorSubType[];
  private sub = new SubSink();
  pageNo: number;

  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];

  @ViewChild(UpdateFloorSubTypeComponent, {static: false})
  private updateFloorSubTypeComponent: UpdateFloorSubTypeComponent;
  @ViewChild(DeleteFloorSubTypeComponent, {static: false})
  private deleteFloorSubTypeComponent: DeleteFloorSubTypeComponent;
  currentPage: any;
  totalItems: any;


  constructor(private floorSubTypeService: FloorSubTypeService, private dataService: DataService) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.floorSubTypeService.getAll().subscribe(response => {
      let res = response.data.length;
      this.pageNo = Math.round((res / 10) + 1);
      console.log('response' + response.data.length);
    });

    this.sub.add(this.dataService.updateDataTableFloorSubType
      .subscribe(update => this.onAdded(update)));

    this.sub.add(this.floorSubTypeService.getFloorSubTypes(0, 10)
    .subscribe(
      (res) => {
        console.log(res);
        this.floorSubTypes = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      }
    ));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  triggerUpdate(id: number, floorType: number, name: string, description: string) {
    this.updateFloorSubTypeComponent.setIdAndDescription(id, floorType, name, description);
  }

  triggerDelete(id: number) {
    this.deleteFloorSubTypeComponent.setDeedNatureId(id);
  }

  onUpdated(updatedSuccessfully: boolean) {
    if (updatedSuccessfully) {
      this.updateTheTable();
    }
  }

  onDeleted(deletedSuccessfully: boolean) {
    if (deletedSuccessfully) {
      this.updateTheTable();
    }
  }
  onAdded(addedSuccessfully: boolean) {
    if (addedSuccessfully) {
      this.updateTheTable();
    }
  }
  pageable(i: number) {
    console.log('i' + i);
    console.log(i * 10 + ' - ' + ((i * 10) + 10));
    this.floorSubTypeService.getFloorSubTypes((i-1), 10).subscribe(responce => {
      this.floorSubTypes = (responce as any).data.content;
      this.currentPage = (responce as any).data.number+1;
      this.totalItems = (responce as any).data.totalElements;
    });
  }
  counter(i: number) {
    return new Array(i);
  }
    private updateTheTable() {
      this.sub.add(this.floorSubTypeService.getFloorSubTypes(0, 10)
      .subscribe(
        (res) => {
          this.floorSubTypes =  (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
    }

    searchFloorSubType(keyword: string) {
      this.selectedPageNo = 0;
      this.sub.add(this.floorSubTypeService.searchFloorSubType(keyword , this.selectedPageNo, this.limit)
      .subscribe((res) => {
        // this.floorSubTypes = res.data.content;
        // this.paginationButtonCounter(res.data.totalPages);
        // this.numOfPages = res.data.totalPages;
        this.floorSubTypes =  (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      })
    );
    }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

}
