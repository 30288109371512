export class rimSavePayment{
  localAuthorityId:number;
  paymentFromDate:string;
  paymentToDate:string;
  bankId:number;
  branch:string;
  chequeNo:string;
  chequeDate:string;
  advanceAmount:number;
  isAdvancePayment:boolean;
  rimId:number;
  totalAmount:number;

constructor(
  localAuthorityId:number,
  paymentFromDate:string,
  paymentToDate:string,
  bankId:number,
  branch:string,
  chequeNo:string,
  chequeDate:string,
  advanceAmount:number,
  isAdvancePayment:boolean,
  rimId:number,
  totalAmount:number
){
  this.localAuthorityId = localAuthorityId;
  this.paymentFromDate = paymentFromDate;
  this.paymentToDate = paymentToDate;
  this.bankId = bankId;
  this.branch = branch;
  this.chequeNo = chequeNo;
  this.chequeDate = chequeDate;
  this.advanceAmount = advanceAmount;
  this.isAdvancePayment = isAdvancePayment;
  this.rimId = rimId;
  this.totalAmount = totalAmount;
}
}
