import {Building} from './building';
import {Loan} from './loan';
import {PropertyFacility} from './property-facility';

export class Property {
  deedNature: string;
  previousDeedNo: string;
  previousDeedNature: string;
  previousDeedDate: string;
  previousRegistrationNo: string;
  previousRegistrationDate: string;
  folioVolume: string;
  localAuthority: string;
  localAuthorityNo: string;
  cmcNo: string;
  surveyorPlanNo: string;
  surveyorPlanDate: string;
  surveyorName: string;
  surveyorLicenseNo: string;
  lotNo: string;
  nameOfTheProperty: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  province: string;
  district: string;
  gramaNiladariDivision: string;
  nearestTown: string;
  townNo: number;
  distanceFromTheNearestTown: string;
  propertyUsage: string;
  propertyUsageSubType: string;
  otherPropertyUsage: string;
  otherPropertyUsageSubtype: string;
  detailsOfTheCropCultivation: string;
  zoneNo: string;
  otherDetails: string;
  propertyRented: boolean;
  isPropertyUsedLoan: boolean;
  propertyRentedValue: number;
  monthlyRent: number;
  decidedPurchaseValue: number;
  dateForMarketValue: string;
  prevOpinion: boolean;
  prevOpinionNo: number;
  bankValuation: number;
  valuationRequest: string;
  propertyFacilitiesValue: string;
  propertyFacilities: PropertyFacility[] = [];
  buildings: Building[] = [];
  loan: Loan;
  extentFromUnit: string;
  extentToUnit: string;
  extentFromValue: number;
  extentToValue: number;
}
