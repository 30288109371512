export class RefundFormRemarks {
    id: number;
    refundRequestId: string;
    bankCopyRemark: string;
    form21Remark: string;
    form26Remark: string;
    form205Remark: string;
    bankStatementRemark: string;
    otherRemark: string;
    taxOfficerComment: string;
    deputyCommissionerComment: string;
    deputyCommissionerName: string;
    commissionerComment: string;

    constructor() {}
}