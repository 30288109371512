import {Component, OnInit, Input, ViewChild, Output, EventEmitter} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {PropertyExtendFrComponent} from '../../../../dashboard-external/further-requirement/wizard-fr/property-fr/property-extend-fr/property-extend-fr.component';
import {PropertyOtherDetailsFrComponent} from '../../../../dashboard-external/further-requirement/wizard-fr/property-fr/property-other-details-fr/property-other-details-fr.component';
import {Property} from '../../../../../../model/property';
import {PropertyLoanDetailsFrComponent} from '../../../../dashboard-external/further-requirement/wizard-fr/property-fr/property-loan-details-fr/property-loan-details-fr.component';
import {PropertyAssessmentFrComponent} from '../../../../dashboard-external/further-requirement/wizard-fr/property-fr/property-assessment-fr/property-assessment-fr.component';
import {PropertyDeedDetailsFrComponent} from '../../../../dashboard-external/further-requirement/wizard-fr/property-fr/property-deed-details-fr/property-deed-details-fr.component';

@Component({
  selector: 'app-property-fr',
  templateUrl: './property-fr.component.html',
  styleUrls: ['./property-fr.component.scss']
})
export class PropertyFrComponent implements OnInit {
  selectTab = 1;
  @Input()
  properties: Property[];
  @Input() valuationRequest;
  @Output()
  propertyDeedDetailsForms: FormGroup;
  isPropertyDeedDetailsValid: boolean;
  isPropertyAssessmentDetailsValid: boolean;
  isPropertyExtentDetailsValid: boolean;
  isPropertyOtherDetailsValid: boolean;
  isPropertyLoanDetailsValid: boolean;
  @ViewChild(PropertyDeedDetailsFrComponent, {static: true})
  private propertyDeedDetailsComponent: PropertyDeedDetailsFrComponent;
  @ViewChild(PropertyExtendFrComponent, {static: true})
  private propertyExtendComponent: PropertyExtendFrComponent;
  @ViewChild(PropertyAssessmentFrComponent, {static: true})
  private propertyAssessmentComponent: PropertyAssessmentFrComponent;
  @ViewChild(PropertyLoanDetailsFrComponent, {static: true})
  private propertyLoanDetailsComponent: PropertyLoanDetailsFrComponent;
  @ViewChild(PropertyOtherDetailsFrComponent, {static: true})
  private propertyOtherDetailsComponent: PropertyOtherDetailsFrComponent;
  @Output() someEvent = new EventEmitter<string>();

  constructor(private notifier: NotifierService) {
  }

  ngOnInit() {
  }

  addPropertyDeedDetails(): boolean {
    this.isPropertyDeedDetailsValid = this.propertyDeedDetailsComponent.saveDeedDetails();
    if (this.isPropertyDeedDetailsValid === true) {
      this.someEvent.next();
      this.selectTab = this.selectTab + 1;
      return true;
    } else {
      this.notifier.notify('error', 'Please give required deed details correctly');
      return false;
    }
  }

  addPropertyAssessmentDetails(): boolean {
    this.isPropertyAssessmentDetailsValid = this.propertyAssessmentComponent.saveAssessmentDetails();
    if (this.isPropertyAssessmentDetailsValid === true) {
      this.someEvent.next();
      this.selectTab = this.selectTab + 1;
      return true;
    } else {
      this.notifier.notify('error', 'Please give required property details correctly');
      return false;
    }
  }

  backPropertyAssessmentDetails(): boolean {
    this.isPropertyAssessmentDetailsValid = this.propertyAssessmentComponent.saveAssessmentDetails();
    if (this.isPropertyAssessmentDetailsValid === true) {
      this.selectTab = this.selectTab - 1;
      return true;
    } else {
      this.notifier.notify('error', 'Please give required property details correctly');
      return false;
    }
  }

  addPropertyExtendsDetails(): boolean {
    this.isPropertyExtentDetailsValid = this.propertyExtendComponent.saveExtentDetails();
    if (this.isPropertyExtentDetailsValid === true) {
      this.someEvent.next();
      this.selectTab = this.selectTab + 1;
      return true;
    } else {
      this.notifier.notify('error', 'Please give property extent');
      return false;
    }
  }

  backPropertyExtendsDetails(): boolean {
    this.isPropertyExtentDetailsValid = this.propertyExtendComponent.saveExtentDetails();
    if (this.isPropertyExtentDetailsValid === true) {
      this.selectTab = this.selectTab - 1;
      return true;
    } else {
      this.notifier.notify('error', 'Please give property extent');
      return false;
    }
  }

  addPropertyOtherDetails(): boolean {
    this.isPropertyOtherDetailsValid = this.propertyOtherDetailsComponent.saveOtherDetails();
    if (this.isPropertyOtherDetailsValid === true) {
      this.someEvent.next();
      this.selectTab = this.selectTab + 1;
      return true;
    }
  }

  backPropertyOtherDetails(): boolean {
    this.isPropertyOtherDetailsValid = this.propertyOtherDetailsComponent.saveOtherDetails();
    if (this.isPropertyOtherDetailsValid === true) {
      this.selectTab = this.selectTab - 1;
      return true;
    }
  }

  addPropertyLoanDetails(): boolean {
    this.isPropertyLoanDetailsValid = this.propertyLoanDetailsComponent.saveLoanDetails();
    if (this.isPropertyLoanDetailsValid === true) {
      this.someEvent.next();
      this.selectTab = this.selectTab + 1;
      return true;
    }
  }

  backPropertyLoanDetails(): boolean {
    this.selectTab = this.selectTab - 1;
    return true;
  }

  addPropertyBuildingDetails(): boolean {
    return true;
  }

  backPropertyBuildingDetails(): boolean {
    this.selectTab = this.selectTab - 1;
    return true;
  }

  propertyValidationCheck(): boolean {
    this.isPropertyDeedDetailsValid = this.propertyDeedDetailsComponent.saveDeedDetails();
    this.isPropertyAssessmentDetailsValid = this.propertyAssessmentComponent.saveAssessmentDetails();
    this.isPropertyExtentDetailsValid = this.propertyExtendComponent.saveExtentDetails();
    this.isPropertyOtherDetailsValid = this.propertyOtherDetailsComponent.saveOtherDetails();
    this.isPropertyLoanDetailsValid = this.propertyLoanDetailsComponent.saveLoanDetails();
    if ((this.isPropertyDeedDetailsValid === true) && (this.isPropertyAssessmentDetailsValid === true) && (this.isPropertyExtentDetailsValid === true)
      && (this.isPropertyOtherDetailsValid === true) && (this.isPropertyLoanDetailsValid === true)) {
      return true;
    } else {
      this.notifier.notify('error', 'Please Fill Property Details');
      return false;
    }
  }

}
