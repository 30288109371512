import { InspectionCondominiumUnit } from './inspection-condominium-unit';
import { FacilityType } from './facility-type';
import { SpecialViewType } from './special-view-type';
import { SurroundType } from './surround-type';

export class InspectionCondominium {
  public id:number;
  public inspectionId: string;

  public propertyTypeId: string;
  public propertySubTypeId: string;
  public valuationRequestId: string;
  public inspectionType: string;

  public createdBy: string;
  public createdUserId: string;
  public recreationGroupId: string;

  // survey
  public planNo: string;
  public lotNo: string;

  public condominiumName: string;
  public assessmentNo: string;
  public townId: string;
  public mainRoad: string;
  public distanceMainRd: string;
  public districtId: string;
  public localAuthorityId: string;

  public useOfCondominium: string;

  public planNumber: string;
  public datePeriod: string;
  public surveyName: string;
  public landExtent: string;
  public noOfFloors: string;
  public noOfUnits: string;
  public floorsAndUnits: string;
  public otherUnits: string;
  public constructedYear: string;

  public inspectionCondominiumUnits: Array<InspectionCondominiumUnit>;

  public facilityTypes: Array<FacilityType>;
  public parkingId: string;
  public securityId: string;
  public unitNo: string;
  public extent: string;
  public gardenId: string;

  public waterId: string;
  public electricityId: string;
  public drainage: string;
  public gasLines: string;
  public accomadationDetails: string;

  public specialViewTypes: Array<SpecialViewType>;

  public otherResidencies: string;
  public incomeLevelId: string;
  public surroundTypes: Array<SurroundType>;

  public managementCost: string;
  public maintainance: string;
  public rates: string;
  public netAnnualRent: string;

  public undividedShares: string;
  public other: string;

  public agreementTypeId: string;
  public salesAgreementNo: string;
  public notary: string;
  public date: string;
  public value: string;

  public boundary1: string;
  public boundary2: string;
  public boundary3: string;
  public boundary4: string;
  public boundary5: string;
  public boundary6: string;
  public boundary7: string;
  public boundary8: string;

  // location
  public nearestTown: string;
  public accessNearestTown: string;
  

}
