import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OfficeService } from 'src/app/service/office/office.service';
import { Office } from 'src/app/model/office';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-update-office',
  templateUrl: './update-office.component.html',
  styleUrls: ['./update-office.component.scss']
})
export class UpdateOfficeComponent implements OnInit, OnDestroy {

  updateOfficeForm: FormGroup;
  private sub = new SubSink();
  office: string;
  code: string;

  @Output() updateOffice = new EventEmitter<boolean>();


  constructor(private formBuilder: FormBuilder, private officeService: OfficeService, private notifier: NotifierService) { }

  ngOnInit() {

    this.updateOfficeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      code: ['', [Validators.required]],
      office: ['', [Validators.required]]
    })
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  getId() {
    return this.updateOfficeForm.get('id').value;
  }
  setIdAndDescription(id: number, code: string, description: string) {
    this.updateOfficeForm.get('id').setValue(id);
    this.updateOfficeForm.get('code').setValue(code);
    this.updateOfficeForm.get('office').setValue(description);
  }

  getDescription() {
    return this.updateOfficeForm.get('office').value;
  }
  getCode() {
    return this.updateOfficeForm.get('code').value;
  }

  updateTheView(updated: boolean) {
    this.updateOffice.emit(updated);
  }

  updateOffices() {
    if (this.updateOfficeForm.valid) {
      const office = new Office();
      office.office = this.getDescription();
      office.code = this.getCode();

      this.sub.add(this.officeService.updateOffice(this.getId(), office)
        .subscribe(response => {

          this.updateTheView(true);
          this.notifier.notify('success', (response as any).message)
        }));
    }
  }


}
