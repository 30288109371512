import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {NotifierService} from 'angular-notifier';
import {IPropertyUsagePage} from '../../model/interfaces/property-Usage-page';
import {PropertyUsage} from '../../model/propertyUsage';

@Injectable({
  providedIn: 'root'
})
export class PropertyUsageService {

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json',})
  };
  private propertyUsageUrl = '/property-usages';
  private districtLoadUrl = '/districts';
  private gramaNiladariUrl = '/grama-niladari-divisions';

  constructor(private http: HttpClient,
              private notifier: NotifierService) {
  }

  getPropertyUsages(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.propertyUsageUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  getAll(): Observable<{ message: string, data: any }> {
    return this.http.get<{ message: string, data: any }>(environment.api_url + this.propertyUsageUrl + '/allOrdered');

  }

  getAllPropertyUsage(): Observable<{ message: string, data: any }> {
    return this.http.get<{ message: string, data: any }>(environment.api_url + this.propertyUsageUrl + '/all');

  }

  createPropertyUsage(propertyUsage: PropertyUsage): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.propertyUsageUrl}`,
      JSON.stringify(propertyUsage), this.httpOptions)
      .pipe(tap(_ => this.log(`update property usage create=${propertyUsage}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('property usage create'))
      );
  }

  updatePropertyUsage(id: number, propertyTYpe: PropertyUsage): Observable<any> {
    return this.http.put(`${environment.api_url}${this.propertyUsageUrl}/${id}`, propertyTYpe)
      .pipe(tap(_ => this.log(`update property usage id=${propertyTYpe.id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('property usage update'))
      );
  }

  /** DELETE: delete the property usage on the server */
  deletePropertyUsage(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.propertyUsageUrl}/${id}`)
      .pipe(tap(_ => this.log(`update property usage  id=${id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('property usage'))
      );
  }

  /* SEARCH: search Property Usage from server*/
  searchPropertyUsage(keyword: string, offset: number, limit: number): Observable<IPropertyUsagePage> {
    // Parameters
    const params = {
      params: {
        offset: offset.toString(),
        limit: limit.toString(),
        keyword
      }
    };

    return this.http.get<any[]>(`${environment.api_url}${this.propertyUsageUrl}/search`, params)
      .pipe(tap(_ => this.log('search Property usage')),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('get property usage'))
      );
  }

  searchPropertyUsageById(id : number) : Observable<any>{
    return this.http.get<any[]>(`${environment.api_url}${this.propertyUsageUrl}/searchBy/${id}`)
  }

  getPropertyUsageSubTypeByPropertyUsage(propertyUsageId: number, offset: number, limit: number): Observable<any> {
    const params = {
      params: {
        offset: offset.toString(),
        limit: limit.toString()
      }
    };
    return this.http.get(`${environment.api_url}${this.propertyUsageUrl}/${propertyUsageId}/property-usage-sub-types`, params)
      .pipe(tap(_ => this.log(`property usage subType get failed`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('property usage subType get failed'))
      );
  }

  getDistrictByProvince(provinceId: number): Observable<any> {
    return this.http.get(`${environment.api_url}${this.districtLoadUrl}/load-district-by-province/${provinceId}`)
      .pipe(tap(_ => this.log(`district has failed to load`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('district has failed to load'))
      );
  }

  getGramaNiladaryByDistrict(districtId: number): Observable<any> {
    return this.http.get(`${environment.api_url}${this.gramaNiladariUrl}/load-gramaNiladari-by-district/${districtId}`)
      .pipe(tap(_ => this.log(`grama Niladari has failed to load`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('grama Niladari has failed to load'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
  }
  
}
