export class Appeal {
  public granteeName: string;
  public granteenic: string;
  public granteeaddressno: string;
  public granteeaddressline1: string;
  public granteeaddressline2: string;
  public granteecity: string;
  public granteemobile: string;
  public granteeemail: string;

  public notaryname: string;
  public notarynic: string;
  public notaryno: string;
  public notaryaddressno: string;
  public notaryaddressline1: string;
  public notaryaddressline2: string;
  public notarycity: string;
  public notarymobile: string;
  public notaryoffice: string;
  public notaryemail: string;

  public appealreason: string;
  public valuationRequestId: string;

  public comment: string;

  public constructor(
    granteeName: string,
    granteenic: string,
    granteeaddressno: string,
    granteeaddressline1: string,
    granteeaddressline2: string,
    granteecity: string,
    granteemobile: string,
    granteeemail: string,

    notaryname: string,
    notarynic: string,
    notaryno: string,
    notaryaddressno: string,
    notaryaddressline1: string,
    notaryaddressline2: string,
    notarycity: string,
    notarymobile: string,
    notaryoffice: string,
    notaryemail: string,

    appealreason: string,
    valuationRequestId: string,

    comment: string
  ) {
    this.granteeName = granteeName;
    this.granteenic = granteenic;
    this.granteeaddressno = granteeaddressno;
    this.granteeaddressline1 = granteeaddressline1;
    this.granteeaddressline2 = granteeaddressline2;
    this.granteecity = granteecity;
    this.granteeemail = granteeemail;

    this.notaryname = notaryname;
    this.notarynic = notarynic;
    this.notaryno = notaryno;
    this.notaryaddressno = notaryaddressno;
    this.notaryaddressline1 = notaryaddressline1;
    this.notaryaddressline2 = notaryaddressline2;
    this.notarycity = notarycity;
    this.notarymobile = notarymobile;
    this.notaryoffice = notaryoffice;
    this.notaryemail = notaryemail;

    this.appealreason = appealreason;
    this.valuationRequestId = valuationRequestId;

    this.comment = comment;
  }
}
