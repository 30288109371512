export class InspectionTownModel{
  id:number;
  townNum:number;
  code:string;
  description:string;
  dataStatus:string;

  constructor(
      id:number,
      townNum:number,
      code:string,
      description:string,
      dataStatus:string,
  ){

    this.id = id;
    this.townNum = townNum;
    this.code = code;
    this.description = description;
    this.dataStatus = dataStatus;

  }
}
