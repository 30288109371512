import { Injectable } from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NotifierService} from 'angular-notifier';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentUploadService {

  valuationRequestDocUploadUrl = '/documents';
  valuationRequestFurtherReuirementDocUploadUrl = '/furtherRequirementDocuments';
  opinionDocUploadUrl = '/opiniondocuments';
  paymentDocUploadUrl = '/repaymentdocuments';

  // REFUND APPLICATION
  refundRequestUrl = '/refund-opinion';
  refundRequestDocumentUploadUrl = '/documents';
  refundRequestFormsDocumentUploadUrl = '/formsDocuments';
  refundRequestFormsDeputyCommissionerDocumentUploadUrl = '/formsDeputyCommissionerDocuments';
  refundRequestFormsCommissionerDocumentUploadUrl = '/formsCommissionerDocuments';
  refundRequestAccountantDocumentUploadUrl = '/accountantDocuments';
  calculationDocumentUploadUrl = '/calculationDocuments';
  collection_url = "/collection";
  collection_document = "/payment-document"

  // REIMBURSEMENT APPLICATION
  reimbursementApplicationUrl = '/reimbursement-application';
  reimbursementDocumentUploadUrl ='/documents'
  reimbursementDocumentUploadUrl1 ='/documents-accountant'

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  uploadValuationRequestDocuments(selectPaths: any[]): Observable<any> {
    const formData = new FormData();
    //
    for (let i = 0; i < selectPaths.length; i++) {
      for (let x = 0; x < selectPaths[i].length; x++) {
        if (i === 0) {
          formData.append('paymentDoc', selectPaths[i][x], selectPaths[i][x].name);
        }
        if (i === 1) {
          formData.append('copyOfPreviousDeed', selectPaths[i][x], selectPaths[i][x].name);
        }
        if (i === 2) {
          formData.append('draftDeed', selectPaths[i][x], selectPaths[i][x].name);
        }
        if (i === 3) {
          formData.append('surveyPlans', selectPaths[i][x], selectPaths[i][x].name);
        }
        if (i === 4) {
          formData.append('routePlans', selectPaths[i][x], selectPaths[i][x].name);
        }
        if (i === 5) {
          formData.append('valuationRequestReports', selectPaths[i][x], selectPaths[i][x].name);
        }
        if (i === 6) {
          formData.append('photoCopyOfGranteeNIC', selectPaths[i][x], selectPaths[i][x].name);
        }
        if (i === 7) {
          // if (selectPaths[i][x].length !== 0) {
          formData.append('others', selectPaths[i][x], selectPaths[i][x].name);
          // }
        }
        if (i === 8) {
          // if (selectPaths[i][x].length !== 0) {
          formData.append('notice', selectPaths[i][x], selectPaths[i][x].name);
          // }
        }
        if (i === 9) {
          formData.append('grantee-20', selectPaths[i][x], selectPaths[i][x].name);
        }
        if (i === 10) {
          formData.append('grantor-20', selectPaths[i][x], selectPaths[i][x].name);
        }

      }
    }
    //

    console.log('Length of Documents : ........................' + selectPaths.length);
    console.log('Length of Documents Form Data : ........................' + formData.get('opinionDocuments'));

    return  this.http.post<any>(`${environment.api_url}/valuation-request${this.valuationRequestDocUploadUrl}`, formData)
      .pipe(tap(_ => this.log(`upload valuation request Documents=${selectPaths}`)),
        catchError(err => {
          // alert('Error' + err);
          console.log('Service Error : ' + err);
          // this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('upload valuation request Documents'))
      );
  }

  uploadValuationRequestFurtherRequirementDocuments(valuationId: any, selectPaths: any[]): Observable<any> {
    const formData = new FormData();
    formData.append('valuationId', valuationId);
    for (let i = 0; i < selectPaths.length; i++) {
      for (let x = 0; x < selectPaths[i].length; x++) {
        if (i === 0) {
          formData.append('copyOfPreviousDeed', selectPaths[i][x], selectPaths[i][x].name);
        }
        if (i === 1) {
          formData.append('draftDeed', selectPaths[i][x], selectPaths[i][x].name);
        }
        if (i === 2) {
          formData.append('surveyPlans', selectPaths[i][x], selectPaths[i][x].name);
        }
        if (i === 3) {
          formData.append('routePlans', selectPaths[i][x], selectPaths[i][x].name);
        }
        if (i === 4) {
          formData.append('valuationRequestReports', selectPaths[i][x], selectPaths[i][x].name);
        }
        if (i === 5) {
          formData.append('photoCopyOfGranteeNIC', selectPaths[i][x], selectPaths[i][x].name);
        }
        if (i === 6) {
          formData.append('others', selectPaths[i][x], selectPaths[i][x].name);
        }
        if (i === 7) {
          formData.append('notice', selectPaths[i][x], selectPaths[i][x].name);
        }
        if (i === 8) {
          formData.append('grantee-20', selectPaths[i][x], selectPaths[i][x].name);
        }
        if (i === 9) {
          formData.append('grantor-20', selectPaths[i][x], selectPaths[i][x].name);
        }

      }
    }

    console.log('Length of Documents : ........................' + selectPaths.length);
    console.log('Length of Documents Form Data : ........................' + formData.get('opinionDocuments'));

    return  this.http.post<any>(`${environment.api_url}/valuation-request${this.valuationRequestFurtherReuirementDocUploadUrl}`, formData)
      .pipe(tap(_ => this.log(`upload valuation request further requirement Documents=${selectPaths}`)),
        catchError(err => {
          console.log('Service Error : ' + err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('upload valuation request further requirement Documents'))
      );
  }

  uploadOpinionDocuments(selectPaths: any[]): Observable<any>  {
    const formData = new FormData();

    for (let i = 0; i < selectPaths.length; i++) {
      // console.log('Length of Selected paths iiiii : ........................' + selectPaths.length);
      // console.log('Length of Selected paths iiiii[0] : ........................' + selectPaths[0].length);
      // for (let x = 0; x < selectPaths[i].length; x++) {
          formData.append('opinionDocuments', selectPaths[i], selectPaths[i].name);
          // console.log('Length of Selected paths : ........................' + selectPaths[i].length);
      // }
    }

    console.log('Length of Documents : ........................' + selectPaths.length);
    console.log('Length of Documents Form Data : ........................', formData.get('opinionDocuments'));

    return  this.http.post<any>(`${environment.api_url}/valuation-request${this.opinionDocUploadUrl}`, formData)
      .pipe(tap(_ => this.log(`upload opinion documents=${selectPaths}`)),
        catchError(err => {
          // alert('Error' + err);
          console.log('Service Error : ' + err);
          // this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('upload opinion documents'))
      );
  }

  uploadCalculationDocuments(selectPathsCollection: any[], calculationId: string, decisionOfCommittee: string): Observable<any> {
    const formData = new FormData();
    formData.append('calculationId', calculationId);
    formData.append('decisionOfCommittee', decisionOfCommittee);

    for (let i = 0; i < selectPathsCollection.length; i++) {
      for (let x = 0; x < selectPathsCollection[i].length; x++) {
        if (i === 0) {
          formData.append('calculationDocuments', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
      }
    }

    return this.http.post<any>(`${environment.api_url}/valuation-request${this.calculationDocumentUploadUrl}`, formData)
      .pipe(tap(_ => this.log(`upload Calculation Decision of Committee Documents=${selectPathsCollection}`)),
        catchError(err => {
          return throwError(err);
        }),
        catchError(this.handleError<any>('upload Calculation Decision of Committee Documents'))
      );
  }

  uploadRepaymentDocuments(selectPathsCollection: any[]): Observable<any> {
    const formData = new FormData();

    for (let i = 0; i < selectPathsCollection.length; i++) {
      for (let x = 0; x < selectPathsCollection[i].length; x++) {
        if (i === 0) {
          formData.append('paymentDocuments', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
      }
    }

    return  this.http.post<any>(`${environment.api_url}/valuation-request${this.paymentDocUploadUrl}`, formData)
      .pipe(tap(_ => this.log(`upload re-payment documents=${selectPathsCollection}`)),
        catchError(err => {
          console.log('Service Error : ' + err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('upload re-payment documents'))
      );
  }

  uploadPaymentDocuments(selectPaths: any[]): Observable<any>  {
    const formData = new FormData();

    for (let i = 0; i < selectPaths.length; i++) {
      // console.log('Length of Selected paths iiiii : ........................' + selectPaths.length);
      // console.log('Length of Selected paths iiiii[0] : ........................' + selectPaths[0].length);
      // for (let x = 0; x < selectPaths[i].length; x++) {
      formData.append('paymentDocuments', selectPaths[i], selectPaths[i].name);
      // console.log('Length of Selected paths : ........................' + selectPaths[i].length);
      // }
    }

    console.log('Length of Documents : ........................' + selectPaths.length);
    console.log('Length of Documents Form Data : ........................', formData.get('paymentDocuments'));

    return  this.http.post<any>(`${environment.api_url}/valuation-request${this.paymentDocUploadUrl}`, formData)
      .pipe(tap(_ => this.log(`upload payment documents=${selectPaths}`)),
        catchError(err => {
          console.log('Service Error : ' + err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('upload payment documents'))
      );
  }

  uploadRefundRequestDocuments(selectPathsCollection: any[], refundRequestId: string): Observable<any> {
    const formData = new FormData();
    formData.append('refundRequestId', refundRequestId);

    for (let i = 0; i < selectPathsCollection.length; i++) {
      for (let x = 0; x < selectPathsCollection[i].length; x++) {
        if (i === 0) {
          formData.append('nic', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 1) {
          formData.append('affidavit', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 2) {
          formData.append('receipt', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 3) {
          formData.append('certifiedDeed', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 4) {
          formData.append('otherOne', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 5) {
          formData.append('copyOfPreviousDeed', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 6) {
          formData.append('draftDeed', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 7) {
          formData.append('surveyPlanBuildingPlan', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 8) {
          formData.append('routePlan', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 9) {
          formData.append('assessmentNotice', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 10) {
          formData.append('valuationReport', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 11) {
          formData.append('photoCopyOfGranteeNIC', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 12) {
          formData.append('otherTwo', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }

      }
    }

    return this.http.post<any>(`${environment.api_url}${this.refundRequestUrl}${this.refundRequestDocumentUploadUrl}`, formData)
      .pipe(tap(_ => this.log(`upload Refund request Documents=${selectPathsCollection}`)),
        catchError(err => {
          return throwError(err);
        }),
        catchError(this.handleError<any>('upload Refund request Documents'))
      );
  }


  uploadRefundFormsDocuments(selectPathsCollection: any[], refundRequestId: string): Observable<any> {
    const formData = new FormData();
    formData.append('refundRequestId', refundRequestId);

    for (let i = 0; i < selectPathsCollection.length; i++) {
      for (let x = 0; x < selectPathsCollection[i].length; x++) {
        if (i === 0) {
          formData.append('bankCopy', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 1) {
          formData.append('form21', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 2) {
          formData.append('form26', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 3) {
          formData.append('form205', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 4) {
          formData.append('bankStatement', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 5) {
          formData.append('other', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
      }
    }

    return this.http.post<any>(`${environment.api_url}${this.refundRequestUrl}${this.refundRequestFormsDocumentUploadUrl}`, formData)
      .pipe(tap(_ => this.log(`upload Refund request Documents=${selectPathsCollection}`)),
        catchError(err => {
          return throwError(err);
        }),
        catchError(this.handleError<any>('upload Refund request form Documents'))
      );
  }

  uploadCollectionPayment(selectPathsCollection: any[], collectionPaymentId: string): Observable<any> {
    const formData = new FormData();
    formData.append('collectionPaymentId', collectionPaymentId);

    for(let i = 0; i < selectPathsCollection.length; i++){
      for(let x = 0; x < selectPathsCollection[i].length; x++){
        if (i === 0) {
          formData.append('collectionBranchDocument', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
      }
    }
    return this.http.post<any>(`${environment.api_url}${this.collection_url}${this.collection_document}`, formData)
      .pipe(tap(_ => this.log(`upload Refund Collection Documents=${selectPathsCollection}`)),
        catchError(err => {
          return throwError(err);
        }),
        catchError(this.handleError<any>('upload Refund Collection form Documents'))
      );
  }


  uploadRefundFormsDeputyCommissionerDocuments(selectPathsCollection: any[], refundRequestId: string, deputyCommissionerComment: string): Observable<any> {
    const formData = new FormData();
    formData.append('refundRequestId', refundRequestId);
    formData.append('deputyCommissionerComment', deputyCommissionerComment);

    for (let i = 0; i < selectPathsCollection.length; i++) {
      for (let x = 0; x < selectPathsCollection[i].length; x++) {
        if (i === 0) {
          formData.append('deputyCommissionerDocuments', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
      }
    }

    return this.http.post<any>(`${environment.api_url}${this.refundRequestUrl}${this.refundRequestFormsDeputyCommissionerDocumentUploadUrl}`, formData)
      .pipe(tap(_ => this.log(`upload Refund request Documents=${selectPathsCollection}`)),
        catchError(err => {
          return throwError(err);
        }),
        catchError(this.handleError<any>('upload Refund request form Deputy Commissioner Documents'))
      );
  }

  uploadRefundFormsCommissionerDocuments(selectPathsCollection: any[], refundRequestId: string, commissionerComment: string): Observable<any> {
    const formData = new FormData();
    formData.append('refundRequestId', refundRequestId);
    formData.append('commissionerComment', commissionerComment);

    for (let i = 0; i < selectPathsCollection.length; i++) {
      for (let x = 0; x < selectPathsCollection[i].length; x++) {
        if (i === 0) {
          formData.append('commissionerDocuments', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
      }
    }

    return this.http.post<any>(`${environment.api_url}${this.refundRequestUrl}${this.refundRequestFormsCommissionerDocumentUploadUrl}`, formData)
      .pipe(tap(_ => this.log(`upload Refund request Documents=${selectPathsCollection}`)),
        catchError(err => {
          return throwError(err);
        }),
        catchError(this.handleError<any>('upload Refund request form Commissioner Documents'))
      );
  }

  uploadRefundRequestAccountantDocuments(selectPathsCollection: any[], refundRequestId: string): Observable<any> {
    const formData = new FormData();
    formData.append('refundRequestId', refundRequestId);

    for (let i = 0; i < selectPathsCollection.length; i++) {
      for (let x = 0; x < selectPathsCollection[i].length; x++) {
        if (i === 0) {
          formData.append('accountantDocuments', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
      }
    }

    return this.http.post<any>(`${environment.api_url}${this.refundRequestUrl}${this.refundRequestAccountantDocumentUploadUrl}`, formData)
      .pipe(tap(_ => this.log(`upload Refund request Documents=${selectPathsCollection}`)),
        catchError(err => {
          return throwError(err);
        }),
        catchError(this.handleError<any>('upload Refund request Accountant Documents'))
      );
  }

  // REIMBURSEMENT PROCESS DOCUMENT UPLOADS - START
  uploadReimbursementApplicationDocuments(selectPathsCollection: any[], rimApplicationId: string): Observable<any> {
    const formData = new FormData();
    formData.append('rimApplicationId', rimApplicationId);

    for (let i = 0; i < selectPathsCollection.length; i++) {
      for (let x = 0; x < selectPathsCollection[i].length; x++) {
        if (i === 0) {
          formData.append('localAuthorityLetter', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 1) {
          formData.append('landRegisterLetter', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 2) {
          formData.append('paymentVoucher', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 3) {
          formData.append('summary', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 4) {
          formData.append('landRegisterCertificate', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 5) {
          formData.append('furtherRequirementLetter', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
        if (i === 6) {
          formData.append('other', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
      }
    }

    return this.http.post<any>(`${environment.api_url}${this.reimbursementApplicationUrl}${this.reimbursementDocumentUploadUrl}`, formData)
      .pipe(tap(_ => this.log(`upload Reimbursement application Documents=${selectPathsCollection}`)),
        catchError(err => {
          return throwError(err);
        }),
        catchError(this.handleError<any>('upload Reimbursement application Documents'))
      );
  }

  uploadRimAccountantPayment(selectPathsCollection: any[], paymentId: string): Observable<any> {
    const formData = new FormData();
    formData.append('rimPaymentid', paymentId);

    for(let i = 0; i < selectPathsCollection.length; i++){
      for(let x = 0; x < selectPathsCollection[i].length; x++){
        if (i === 0) {
          formData.append('rimAccountantDocuments', selectPathsCollection[i][x], selectPathsCollection[i][x].name);
        }
      }
    }
    return this.http.post<any>(`${environment.api_url}${this.reimbursementApplicationUrl}${this.reimbursementDocumentUploadUrl1}`, formData)
      .pipe(tap(_ => this.log(`upload Reimbursement Accountant Payment Documents=${selectPathsCollection}`)),
        catchError(err => {
          return throwError(err);
        }),
        catchError(this.handleError<any>('upload Reimbursement Accountant Payment form Documents'))
      );
  }
  // REIMBURSEMENT PROCESS DOCUMENT UPLOADS - END

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {

  }

}
