import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { InspectionHealthTypeService } from 'src/app/service/inspection-master-data-service/inspection-health-type/inspection-health-type.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inspection-health-type',
  templateUrl: './inspection-health-type.component.html',
  styleUrls: ['./inspection-health-type.component.scss']
})
export class InspectionHealthTypeComponent implements OnInit,OnDestroy {

  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");

  createHealthTypeForm: FormGroup;
  updateHealthTypeForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  propertyTypeList: any =[];
  activationButton: boolean;
  public createHealthTypeModel = new InspectionCommonDataModel(null,'','','','');
  public updateHealthTypeModel = new InspectionCommonDataModel(null,'','','','');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;
  HealthTypes: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionHealthTypeService: InspectionHealthTypeService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createHealthTypeForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });
    this.updateHealthTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });

    // this.loadPropertyType();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }



  //create Health Type - start

  get f() { return this.createHealthTypeForm.controls; }

  setHealthTypeModelToSave(){
    this.createHealthTypeModel.name = this.createHealthTypeForm.get('name').value;
    this.createHealthTypeModel.description = this.createHealthTypeForm.get('description').value;
    this.createHealthTypeModel.code = this.createHealthTypeForm.get('code').value;
    this.createHealthTypeModel.dataStatus = "ACTIVE";
  }

    //save Health Type model
  saveHealthType(){
    if(this.createHealthTypeForm.valid){

      this.setHealthTypeModelToSave();
      this.sub.add(
        this.inspectionHealthTypeService.createHealthType(this.createHealthTypeModel).subscribe(data=>{
          this.notifier.notify('success', (data as any).message);
          this.updateTheTable();
          this.reset();
        })
        );
    }else{
      this.createHealthTypeForm.markAllAsTouched();
    }

  }

  reset(){
    this.createHealthTypeForm.reset()
  }
  //create Health Type - end

  //update Health Type - start

    //get update controllers
  get f1() { return this.updateHealthTypeForm.controls; }

    //set data To Health Type Type model
  setHealthType(health:any){
    this.updateHealthTypeForm.get('id').setValue(health.id);
    this.updateHealthTypeForm.get('name').setValue(health.name);
    this.updateHealthTypeForm.get('description').setValue(health.description);
    this.updateHealthTypeForm.get('code').setValue(health.code);

    if(health.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateHealthTypeModel.id = this.updateHealthTypeForm.get('id').value;
    this.updateHealthTypeModel.name = this.updateHealthTypeForm.get('name').value;
    this.updateHealthTypeModel.description = this.updateHealthTypeForm.get('description').value;
    this.updateHealthTypeModel.code = this.updateHealthTypeForm.get('code').value;

    if(this.activationButton == true){
      this.updateHealthTypeModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateHealthTypeModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateHealthType(){
    this.setDataToModel();
    this.sub.add(this.inspectionHealthTypeService.updateHealthType(this.updateHealthTypeModel.id, this.updateHealthTypeModel).subscribe(data=>{
      this.notifier.notify('success', (data as any).message);
      this.updateTheTable();
    }))
  }

  //update Health Type - end

//search and view table start
  pageable(i: number) {
    this.inspectionHealthTypeService.getHealthType((i-1),10).subscribe(res=>{
      this.HealthTypes = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionHealthTypeService.getHealthType(0, 10)
      .subscribe(
        (res) => {
          this.HealthTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search Health Type Type
    searchHealthType(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionHealthTypeService.searchHealthType(this.keyword,0,10).subscribe(res=>{
          this.HealthTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionHealthTypeService.deleteHealthType(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end

}
