import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PropertyType } from 'src/app/model/property-type';
import { PropertyTypeService } from '../../../../../../../service/property-type/property-type.service';
import { from } from 'rxjs';
import { SubSink } from 'subsink';
import { DataService } from 'src/app/service/data.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-add-property-type',
  templateUrl: './add-property-type.component.html',
  styleUrls: ['./add-property-type.component.scss']
})
export class AddPropertyTypeComponent implements OnInit, OnDestroy {

  addpropertyTypeForm: FormGroup;

  private sub = new SubSink();

  constructor(private formBuilder: FormBuilder, private propertyTypeService: PropertyTypeService, private dataService: DataService, private notifier: NotifierService) { }

  ngOnInit() {

    this.addpropertyTypeForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  savePropertyType() {
    if (this.addpropertyTypeForm.valid) {

      const propertyType = new PropertyType();

      propertyType.description = this.getDescription();
      propertyType.name = this.getName();
      this.sub.add(this.propertyTypeService.createPropertyType(propertyType)
        .subscribe(response => {
          this.notifier.notify('success', (response as any).message);
          this.dataService.updatePropertyType(true);
        }));
    }
  }
  getDescription() {
    return this.addpropertyTypeForm.get('description').value;
  }
  getName() {
    return this.addpropertyTypeForm.get('name').value;
  }

}
