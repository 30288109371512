import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { ReportServiceService } from "../service/report-service.service";
import { valueReferenceToExpression } from "@angular/compiler-cli/src/ngtsc/annotations/src/util";
import { NotifierService } from "angular-notifier";
import * as XLSX from "xlsx";

@Component({
	selector: 'app-opinion-registration-report',
	templateUrl: './opinion-registration-report.component.html',
	styleUrls: ['./opinion-registration-report.component.scss']
})
export class OpinionRegistrationReportComponent implements OnInit, AfterViewInit {

	dropdownList = [];
	dropdownListAB: any = [];
	selectedItemsLA: any = [];
	selectedItemsAB: any = [];
	dropdownSettingsAB: IDropdownSettings = {};
	dropdownSettingsLA: IDropdownSettings = {};
	dropdownListLA = [];
	fromDate: string = null;
  currentDate: string = null;

  toDate: string = null;
	isAllBranch: boolean = false;
	isAllAuthorities: boolean = false;
	inputs: any = {};
	totalDeclaredValue: number = 0;

	public showPrint: boolean = false;
	public y: number = 0;
	public selectedDetails: any;
	public download_excel_status: boolean = false;

	@ViewChild('TABLE', { static: false }) TABLE: ElementRef;

	public reportDetails: any = [];

	constructor(public modalService: NgbModal,
		public reportService: ReportServiceService,
		private notifier: NotifierService) {

	}

	ngOnInit() {
		this.selectedItemsAB = null;
		this.selectedItemsLA = null;
		this.showPrint = false;

		this.dropdownSettingsLA = {
			singleSelection: false,
			idField: 'id',
			textField: 'localAuthority',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		this.dropdownSettingsAB = {
			singleSelection: false,
			idField: 'id',
			textField: 'office',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		this.get_all_local_authority();
		this.get_all_allocated_branches();
		// setInterval('this.get_all_local_authority', 1000);

		this.selectedDetails = {
			date: "",
			selected_la: ""
		};
	}

	onItemSelect(item: any) {
		console.log(item);
	}
	onSelectAll(items: any) {
		console.log(items);
	}

	onItemDeSelectAB(items: any) {
	}

	onItemSelectLA(item: any) {
		console.log(item);
	}
	onSelectAllLA(items: any) {
		console.log(items);
	}

	onItemDeSelectLA(items: any) {
	}

	ngAfterViewInit(): void {
		this.printReceipt();
	}

	download_excel() {
		this.download_excel_status = true;
		this.get_excel_report();
	}

	captureInputs() {
	/*	// capture selected from date
		if (this.fromDate == null) {
			this.notifier.notify('error', 'Please enter submit from date!');
			return false;
		}

		// capture selected to date
		if (this.toDate == null) {
			this.notifier.notify('error', 'Please enter submit to date!');
			return false;
		}*/
    this.currentDate = new Date().toISOString().slice(0, 10);

    let lastDate = "";
    if(this.toDate == null || this.toDate == ""){
      lastDate = this.currentDate;
    }else {
      lastDate = this.toDate;
    }

    var date_range = ((this.fromDate == null || this.fromDate == "") && (this.toDate == null || this.toDate == "")) ? "All Date" : (this.fromDate == null) ? ' to ' + lastDate : this.fromDate + ' to ' + lastDate;

		// capture selected branche(s)
		var branchesId = "";
		var branchesName = "";
		if (this.selectedItemsAB != null) {
			this.selectedItemsAB.forEach(value => {
				branchesId += value.id + ",";
				branchesName += " " + value.office + ",";
			});

			branchesId = branchesId.slice(0, -1);
			branchesName = branchesName.slice(0, -1);
		}

		if (this.selectedItemsAB != null && this.dropdownListAB != null &&
			this.dropdownListAB.length == this.selectedItemsAB.length) {
			branchesName = "All";
		}

		// capture selected authorit(ies)
		var authoritiesId = "";
		var authoritiesNames = "";
		if (this.selectedItemsLA != null) {
			this.selectedItemsLA.forEach(value => {
				authoritiesId += value.id + ",";
				authoritiesNames += " " + value.localAuthority + ",";
			});

			authoritiesId = authoritiesId.slice(0, -1);
			authoritiesNames = authoritiesNames.slice(0, -1);
		}

		if (this.selectedItemsLA != null && this.dropdownListLA != null &&
			this.selectedItemsLA.length == this.dropdownListLA.length) {
			authoritiesNames = 'All';
		}

		this.inputs = {
			/*fromDate: this.fromDate,
			toDate: this.toDate,*/
      selected_date: date_range,
			selectedBranchIds: branchesId,
			selectedAuthorityIds: authoritiesId,
			selectedBranchNames: branchesName,
			selectedAuthorityNames: authoritiesNames
		};

		console.log(this.inputs);
		return true;
	}

	get_excel_report() {
		if (this.captureInputs()) {
			this.reportService.getOpinionRegistration(this.fromDate, this.toDate, this.inputs.selectedBranchIds, this.inputs.selectedAuthorityIds).subscribe(value => {
				//if (value.data != null && value.data.length > 0) {
					this.reportDetails = value.data;

					setTimeout(() => {
						var printContents = document.getElementById('excel_print');
						const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(printContents);
						const wb: XLSX.WorkBook = XLSX.utils.book_new();
						XLSX.utils.book_append_sheet(wb, ws, 'Opinion Reg.');
						XLSX.writeFile(wb, 'Opinion Registration Report.xlsx');
					}, 1000);


					// this.notifier.notify('error', 'No records found!');
					// console.log("No data found");

			});
		}
	}

	closeFurtherReqRemark() {
		this.modalService.dismissAll();
	}

	get_report() {
		if (this.captureInputs()) {
			this.reportService.getOpinionRegistration(this.fromDate, this.toDate, this.inputs.selectedBranchIds, this.inputs.selectedAuthorityIds).subscribe(value => {
				console.log(value);
				// if (value.data != null && value.data.data != null && value.data.data.length > 0) {
					this.reportDetails = value.data.data;
					this.totalDeclaredValue = value.data.totalDeclared;

					setTimeout(() => {
						// alert("asd");
						this.printReceipt();
					}, 1000);

				// } else {
				// 	this.notifier.notify('error', 'No records found!');
				// 	console.log("No data found");
				// }
			});
		}
	}

	generateReport() {
		this.get_report();
		// this.modalService.dismissAll();
	}

	get_all_local_authority() {
		this.reportService.getAllLA().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListLA = value.data;
			} else {
				console.log("No Local authority return");
			}
		});
	}

	get_all_allocated_branches() {
		this.reportService.getAllAB().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListAB = value.data;
				console.log(value.data);
			} else {
				console.log("No Allocated Regional Branch return");
			}
		});
	}

	// counter(){
	//   console.log(this.y);
	//   return this.y++;
	// }


	// generateReportPDF(){
	//   this.printReceipt();
	// }

	printReceipt(): void {
		this.showPrint = true;
		let printContents: any;
		let popupWin: any;
		printContents = document.getElementById('receipt').innerHTML;

		popupWin = window.open();
		popupWin.document.open();
		popupWin.document.write(`
    <html>
    <head>
        <title>Opinion Registration Report</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <style>
          @page {
            size: legal  landscape;
            @bottom-right {
              content: "Page " counter(page) " of " counter(pages);
            }

            .page{
                padding-bottom: 20px;
                page-break-after: always;
            }
          }

            body
            {
                margin: 10mm 10mm 0mm 10mm;

            }
            table{
              border: 1px solid #000;
              border-collapse: collapse;
            }
            tbody{
              page-break-before: always;
            }

            tfoot tr td {
                border: 1px solid #ffffff;
            }

            table th, table td
            {
                padding: 5px;
                margin: 0px;
                border: 1px solid #000;
            }
            .page{
                padding-bottom: 20px;
                page-break-after: always;
            }

        </style>
    </head>
    <body onload="window.print();">
            ${printContents}
    </body>
    </html>`
		);
		popupWin.document.close();
	}




}
