import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import Swal from 'sweetalert2';
import Cropper from 'cropperjs';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-file-upload-grantee',
  templateUrl: './file-upload-grantee.component.html',
  styleUrls: ['./file-upload-grantee.component.scss']
})

export class FileUploadGranteeComponent implements OnInit {

  selectedElement: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer ) {
  }
  @Input()
  updatedPaths = [];
  @Output()
  outPutImagesGrantee = new EventEmitter<File[]>();
  filesListGrantee: File[] = [];
  imagesGrantee = [];
  originalImages6: any[] = [];
  namesGrantee: string[] = [];
  selectedIndex = 0;
  filesGrantee;
  showEdit = false;
  showEditPdf = false;

  public progress = 0;
  pdfsrc=null;
  cropper;

  ngOnInit() {
    if (this.updatedPaths !== null || this.updatedPaths.length > 0) {
      this.uploadChangeGranteeExternal(this.updatedPaths);
    }
  }

  uploadChangeGrantee(event) {
    this.filesGrantee = event.target.files;
    this.progress = 0;
    for (let i = 0; i < this.filesGrantee.length; i++) {
      const file = this.filesGrantee[i];
      if (file.type==="application/pdf"||file.type==="image/png"||file.type==="image/jpeg"){
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event: any) => {
        this.progress = 100;
        this.imagesGrantee.push(_event.currentTarget.result);
        this.originalImages6.push(_event.currentTarget.result);
        this.filesListGrantee.push(file);
      };
      this.namesGrantee.push(file.name);
      this.progress = 0;
      }else {

        Swal.fire({
          text: 'File type not allowed here!',
          icon: 'warning',
          showCancelButton: false,

        });
        event.srcElement.value = null;
      }
    }

  }
  uploadChangeGranteeExternal(files) {
    this.filesGrantee = files;
    this.progress = 0;
    for (let i = 0; i < this.filesGrantee.length; i++) {
      const file = this.filesGrantee[i];
      if (file.type==="application/pdf"||file.type==="image/png"||file.type==="image/jpeg"){
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          this.progress = 100;
          this.imagesGrantee.push(_event.currentTarget.result);
          this.originalImages6.push(_event.currentTarget.result);
          this.filesListGrantee.push(file);
        };
        this.namesGrantee.push(file.name);
        this.progress = 0;
      }else {

        Swal.fire({
          text: 'File type not allowed here!',
          icon: 'warning',
          showCancelButton: false,

        });

      }
    }

  }

  changeImg(event) {

    if (this.cropper !== undefined) {
      this.cropper.destroy();
    }
    const image = document.getElementById('imageGrantee') as HTMLImageElement;
    image.src = event.target.src;
    this.cropper = new Cropper(image, {
      crop(event) {
      },
    });
  }

  outPutImagesSet() {
    this.outPutImagesGrantee.emit(this.filesListGrantee);
  }

  edit(event, index) {
    // this.selectedElement=this.sanitizer.bypassSecurityTrustResourceUrl(this.imagesGrantee[index]);
    if (this.filesListGrantee[index].type==="application/pdf"){
      this.showEdit = false;
      this.showEditPdf = true;
      this.pdfsrc=null;
      this.pdfsrc=this.imagesGrantee[index];
      // document.getElementById('myiframegrantee').removeAttribute('src');
      // document.getElementById('myiframegrantee').setAttribute('src',this.imagesGrantee[index])

    }else {
      this.showEdit = true;
      this.showEditPdf = false;
      const image = document.getElementById('imageGrantee') as HTMLImageElement;
      image.src = event.target.parentElement.parentElement.children[0].src;
      if (this.cropper !== undefined) {
        this.cropper.destroy();
      }
      this.cropper = new Cropper(image, {
        rotatable: true,
        crop(event) {

        },
      });
    }

    this.selectedIndex = index;
  }

  delete(index) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#6086d6',
      cancelButtonColor: '#d66',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.imagesGrantee.splice(index, 1);
        this.originalImages6.splice(index, 1);
        this.namesGrantee.splice(index, 1);
        this.filesListGrantee.splice(index, 1);
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );

      }
    });

    // this.images.splice(index, 1);
    // this.originalImages.splice(index, 1);
    // this.names.splice(index, 1);
    // this.filesList.splice(index, 1);


  }

  closeEditor() {
    this.showEdit = false;
    this.showEditPdf = false;
  }

  rotate(angle: number) {
    this.cropper.move(1, -1).rotate(angle);
  }

  resetImage() {
    this.cropper.destroy();
    this.imagesGrantee[this.selectedIndex] = this.originalImages6[this.selectedIndex];
    const image = document.getElementById('imageGrantee') as HTMLImageElement;
    image.src = this.imagesGrantee[this.selectedIndex];
    this.cropper = new Cropper(image, {
      rotatable: true,
      crop(event) {
      },
    });
  }

  crop() {
    this.imagesGrantee[this.selectedIndex] = this.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    this.cropper.destroy();
    this.imagesGrantee[this.selectedIndex] = this.imagesGrantee[this.selectedIndex];
    const image = document.getElementById('imageGrantee') as HTMLImageElement;
    image.src = this.imagesGrantee[this.selectedIndex];
    this.cropper = new Cropper(image, {
      rotatable: true,
      crop(event) {
      },
    });

  }

}
