import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { InspectionAccomodationTypeService } from 'src/app/service/inspection-master-data-service/inspection-accomodation-type/inspection-accomodation-type.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inspection-accomodation-type',
  templateUrl: './inspection-accomodation-type.component.html',
  styleUrls: ['./inspection-accomodation-type.component.scss']
})
export class InspectionAccomodationTypeComponent implements OnInit,OnDestroy {

  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");


  createAccomodationTypeForm: FormGroup;
  updateAccomodationTypeForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  propertyTypeList: any =[];
  activationButton: boolean;
  public createAccomodationTypeModel = new InspectionCommonDataModel(null,'','','','');
  public updateAccomodationTypeModel = new InspectionCommonDataModel(null,'','','','');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;
  AccomodationTypes: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionAccomodationTypeService: InspectionAccomodationTypeService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createAccomodationTypeForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['', [Validators.pattern(this.NoWhitespaceRegExp)]],
    });
    this.updateAccomodationTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['', [Validators.pattern(this.NoWhitespaceRegExp)]],
    });

    // this.loadPropertyType();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }



  //create Accomodation Type - start

  get f() { return this.createAccomodationTypeForm.controls; }

  setAccomodationTypeModelToSave(){
    this.createAccomodationTypeModel.name = this.createAccomodationTypeForm.get('name').value;
    this.createAccomodationTypeModel.description = this.createAccomodationTypeForm.get('description').value;
    this.createAccomodationTypeModel.code = this.createAccomodationTypeForm.get('code').value;
    this.createAccomodationTypeModel.dataStatus = "ACTIVE";
  }

    //save Accomodation Type model
  saveAccomodationType(){

    this.inspectionAccomodationTypeService.getAccomodationType(0,this.totalItems).subscribe(res=>{
      const AccomodationTypesArray = (res as any).data.content;
      if (AccomodationTypesArray.map((AccomodationT)=>String(AccomodationT.name.replace(/\s/g, ""))).includes(String(this.createAccomodationTypeForm.get('name').value.replace(/\s/g, "")))){
        this.notifier.notify('error', 'Accomodation type name "'+this.createAccomodationTypeForm.get('name').value +'" is exist');
        return;
      }
      else{
        if(this.createAccomodationTypeForm.valid){
          this.setAccomodationTypeModelToSave();
          this.sub.add(
            this.inspectionAccomodationTypeService.createAccomodationType(this.createAccomodationTypeModel).subscribe(data=>{
              this.notifier.notify('success', (data as any).message);
              this.updateTheTable();
              this.reset();
            })
            );
        }else{
          this.f.name.markAsTouched();
          this.f.code.markAsTouched();
        }
  }}) 

  }

  reset(){
    this.createAccomodationTypeForm.reset()
  }
  //create Accomodation Type - end

  //update Accomodation Type - start

    //get update controllers
  get f1() { return this.updateAccomodationTypeForm.controls; }

    //set data To Accomodation Type Type model
  setAccomodationType(accomodation:any){
    this.updateAccomodationTypeForm.get('id').setValue(accomodation.id);
    this.updateAccomodationTypeForm.get('name').setValue(accomodation.name);
    this.updateAccomodationTypeForm.get('description').setValue(accomodation.description);
    this.updateAccomodationTypeForm.get('code').setValue(accomodation.code);

    if(accomodation.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateAccomodationTypeModel.id = this.updateAccomodationTypeForm.get('id').value;
    this.updateAccomodationTypeModel.name = this.updateAccomodationTypeForm.get('name').value;
    this.updateAccomodationTypeModel.description = this.updateAccomodationTypeForm.get('description').value;
    this.updateAccomodationTypeModel.code = this.updateAccomodationTypeForm.get('code').value;

    if(this.activationButton == true){
      this.updateAccomodationTypeModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateAccomodationTypeModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateAccomodationType(){

    this.inspectionAccomodationTypeService.getAccomodationType(0,this.totalItems).subscribe(res=>{
      const AccomodationTypesArray = (res as any).data.content;
      if (AccomodationTypesArray.map((AccomodationT)=>String(AccomodationT.name.replace(/\s/g, ""))).includes(String(this.updateAccomodationTypeForm.get('name').value.replace(/\s/g, "")))){
        this.notifier.notify('error', 'Accomodation type name "'+this.updateAccomodationTypeForm.get('name').value +'" is exist');
        return;
      }
      else{
        if(this.updateAccomodationTypeForm.valid){
          this.setDataToModel();
          this.sub.add(this.inspectionAccomodationTypeService.updateAccomodationType(this.updateAccomodationTypeModel.id, this.updateAccomodationTypeModel).subscribe(data=>{
            this.notifier.notify('success', (data as any).message);
            this.updateTheTable();
          }))
        }else{
          this.f1.name.markAsTouched();
          this.f1.code.markAsTouched();
        }
  }}) 
  }

  //update Accomodation Type - end

//search and view table start
  pageable(i: number) {
    this.inspectionAccomodationTypeService.getAccomodationType((i-1),10).subscribe(res=>{
      this.AccomodationTypes = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionAccomodationTypeService.getAccomodationType(0, 10)
      .subscribe(
        (res) => {
          this.AccomodationTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search Accomodation Type Type
    searchAccomodationType(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionAccomodationTypeService.searchAccomodationType(this.keyword,0,10).subscribe(res=>{
          this.AccomodationTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionAccomodationTypeService.deleteAccomodationType(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end

}
