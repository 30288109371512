import {Component, OnInit} from '@angular/core';
import {SubSink} from 'subsink';
import {ValuationRequest} from '../../../../../model/valuation-request';
import {ActivatedRoute, Router} from '@angular/router';
import {ValuationRequestService} from '../../../../../service/valuation-request/valuation-request.service';
import {Constant} from '../../../../../util/constant';

@Component({
  selector: 'app-appeal-wizard',
  templateUrl: './appeal-wizard.component.html',
  styleUrls: ['./appeal-wizard.component.scss']
})
export class AppealWizardComponent implements OnInit {

  selectSideNav = 1;
  private sub = new SubSink();
  valuationRequest: ValuationRequest;

  userdata: any = [];

  permissiongranted: boolean;

  // Subscription to monitor the navigation
  navigationSubscription;

  constructor(
    private route: ActivatedRoute,
    private valuationRequestService: ValuationRequestService,
    public router: Router
  ) {
    this.permissiongranted = false;
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.valuationRequest = new ValuationRequest();
  }

  public changeComponent(page: any): void {
    this.selectSideNav = page;
  }

  checkPermission() {
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.permissiongranted = true;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.permissiongranted = true;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.permissiongranted = true;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.permissiongranted = false;
        this.selectSideNav = 2;
        break;
      } else {
        this.permissiongranted = false;
        this.selectSideNav = 2;
      }
    }
  }

  ngOnInit() {
    this._prepare();
  }

  _prepare() {
    this.checkPermission();
    const valuationRequestId = +this.route.snapshot.paramMap.get('id');
    this.valuationRequest.id = valuationRequestId;
    this.getValuationRequest(valuationRequestId);
  }

  getValuationRequest(valuationRequestId: number) {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(valuationRequestId)
      .subscribe((res) => {
        this.valuationRequest = res.data;
        console.log(res.data)
        localStorage.setItem('valuationRequestStatus', this.valuationRequest.valuationRequestStatus);
        sessionStorage.setItem('valuationRequest', JSON.stringify(this.valuationRequest));
        this.valuationRequest.id = valuationRequestId;
      }));
  }

  checkSubmitFurtherRequirementButtonStatus() {
    if (this.valuationRequest.valuationRequestStatus === Constant.VALUATION_REQUEST_FURTHER_REQUIREMENT_CODE) {
      return false;
    }
    else {
      if (this.userdata.roles[0].code === Constant.ROLE_ACCESSOR && this.valuationRequest.accessorStatus === Constant.VALUATION_REQUEST_FURTHER_REQUIREMENT_CODE) {
        return true;
      }
      else if (this.userdata.roles[0].code === Constant.ROLE_DEPUTY_COMMISSIONER && this.valuationRequest.dcommissionerStatus === Constant.VALUATION_REQUEST_FURTHER_REQUIREMENT_CODE) {
        return true;
      }
      else if (this.userdata.roles[0].code === Constant.ROLE_COMMISSIONER && this.valuationRequest.dcommissionerStatus === Constant.VALUATION_REQUEST_FURTHER_REQUIREMENT_CODE) {
        return true;
      }
      else {
        return false;
      }
    }
  }
}
