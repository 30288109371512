import {Component, OnInit} from '@angular/core';
import {Property} from '../../../../../model/property';
import {ValuationRequest} from '../../../../../model/valuation-request';
import {Payment} from '../../../../../model/payment';
import {Loan} from '../../../../../model/loan';
import { ValuationRequestService } from 'src/app/service/valuation-request/valuation-request.service';

@Component({
  selector: 'app-gift-fr',
  templateUrl: './gift-fr.component.html',
  styleUrls: ['./gift-fr.component.scss']
})
export class GiftFrComponent implements OnInit {

  valuationRequest: ValuationRequest;

  constructor(private valuationRequestService: ValuationRequestService) {
    const propertyOne = new Property();
    propertyOne.loan = new Loan();
    this.valuationRequest = new ValuationRequest();
    // this.valuationRequest.properties.push(propertyOne);
    // this.valuationRequest.payment = new Payment();
    // this.valuationRequest.noOfGrantees = 0;
    // this.valuationRequest.noOfGranters = 0;
    // this.valuationRequest.stampDuty = 0.0;
    // this.valuationRequest.deedNature = 'Gift';
    // this.valuationRequest.valuation = 0.0;
    // this.valuationRequest.fileNumberFormat = '';
  }

  ngOnInit() {
    // var id = this.route.snapshot.paramMap.get('id');
    this.updateValuationRequest(localStorage.getItem('valuation_request_id'));
  }

  updateValuationRequest(valuationRequestId) {
    this.valuationRequestService.findByValuationRequestId(valuationRequestId)
      .subscribe((res) => {
        this.valuationRequest = res.data;
        console.log(this.valuationRequest);
      })
  }

}
