import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { District } from 'src/app/model/district';
import { DistrictNewService } from 'src/app/service/district/district-new.service';
import { ProvinceService } from 'src/app/service/province/province.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-district-new',
  templateUrl: './district-new.component.html',
  styleUrls: ['./district-new.component.scss']
})
export class DistrictNewComponent implements OnInit,OnDestroy {


  createDistrictForm: FormGroup;
  updateDistrictForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  province: any =[];
  // activationButton: boolean;
  public createDistrictModel = new District(null,'',null,'');
  public updateDistrictModel = new District(null,'',null,'');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;
  Districts: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionDistrictService: DistrictNewService,
    private provinceService:ProvinceService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createDistrictForm = this.formBuilder.group({
      province: ['', [Validators.required]],
      code: ['', [Validators.required]],
      description: ['',[Validators.required]],
    });
    this.updateDistrictForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      province: ['', [Validators.required]],
      code: ['', [Validators.required]],
      description: ['',[Validators.required]],
    });

    this.getAllProvices();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }

  getAllProvices(){
    this.provinceService.getAll().subscribe(res=>{
      this.province = (res as any).data;
      console.log(res)
    })
  }

  //create District - start

  get f() { return this.createDistrictForm.controls; }

  setDistrictModelToSave(){
    this.createDistrictModel.province = this.createDistrictForm.get('province').value;
    this.createDistrictModel.description = this.createDistrictForm.get('description').value;
    this.createDistrictModel.code = this.createDistrictForm.get('code').value;
  }

    //save District model
  saveDistrict(){
    this.setDistrictModelToSave();
    this.sub.add(
      this.inspectionDistrictService.createDistrict(this.createDistrictModel).subscribe(data=>{
        this.notifier.notify('success', (data as any).message);
        this.updateTheTable();
        this.reset();
      })
      );
  }

  reset(){
    this.createDistrictForm.reset()
  }
  //create District - end

  //update District - start

    //get update controllers
  get f1() { return this.updateDistrictForm.controls; }

    //set data To District Type model
  setDistrict(district:any){
    this.updateDistrictForm.get('id').setValue(district.id);
    this.updateDistrictForm.get('province').setValue(district.province.id);
    this.updateDistrictForm.get('description').setValue(district.description);
    this.updateDistrictForm.get('code').setValue(district.code);

  }


    //setting data model to update
  setDataToModel(){
    this.updateDistrictModel.id = this.updateDistrictForm.get('id').value;
    this.updateDistrictModel.province = this.updateDistrictForm.get('province').value;
    this.updateDistrictModel.description = this.updateDistrictForm.get('description').value;
    this.updateDistrictModel.code = this.updateDistrictForm.get('code').value;

  }


    //send updated data to db
  updateDistrict(){
    this.setDataToModel();
    this.sub.add(this.inspectionDistrictService.updateDistrict(this.updateDistrictModel.id, this.updateDistrictModel).subscribe(data=>{
      this.notifier.notify('success', (data as any).message);
      this.updateTheTable();
    }))
  }

  //update District - end

//search and view table start
  pageable(i: number) {
    this.inspectionDistrictService.getDistrict((i-1),10).subscribe(res=>{
      this.Districts = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionDistrictService.getDistrict(0, 10)
      .subscribe(
        (res) => {
          this.Districts = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;

          console.log(res)
        }));
  }

    //Search District Type
    searchDistrict(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionDistrictService.searchDistrict(this.keyword,0,10).subscribe(res=>{
          this.Districts = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionDistrictService.deleteDistrict(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end

}
