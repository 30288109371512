import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import { environment } from 'src/environments/environment';
import { PropertyType } from 'src/app/model/property-type';
import { tap, catchError } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import {IPropertyFacilityPage} from '../../model/interfaces/property-facility-page';
import {IPropertyTypePage} from '../../model/interfaces/property-type-page';

@Injectable({
  providedIn: 'root'
})
export class PropertyTypeService {

  private propertyTypeUrl = '/property-types';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  getPropertyTypes(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.propertyTypeUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  getAll(): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.propertyTypeUrl + '/all');

  }

  createPropertyType(propertyTYpe: PropertyType): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.propertyTypeUrl}`,
      JSON.stringify(propertyTYpe), this.httpOptions)
      .pipe(tap(_ => this.log(`update property type create=${propertyTYpe}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('property type create'))
      );
  }

  updatePropertyType(id: number, propertyTYpe: PropertyType): Observable<any> {
    return this.http.put(`${environment.api_url}${this.propertyTypeUrl}/${id}`, propertyTYpe)
      .pipe(tap(_ => this.log(`update property TYpe  id=${propertyTYpe.id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
      catchError(this.handleError<any>('property TYpe update'))
    );
  }

  /** DELETE: delete the company category on the server */
  deletePropertyType(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.propertyTypeUrl}/${id}`)
      .pipe(tap(_ => this.log(`update property TYpe  id=${id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
      catchError(this.handleError<any>('propertyTYpe'))
    );
  }

  /* Search Property Type from server*/
  searchPropertyType(keyword: string, offset: number, limit: number): Observable<IPropertyTypePage> {
    // Parameters
    const params = {
      params: {
        offset: offset.toString(),
        limit: limit.toString(),
        keyword
      }
    };
    return this.http.get<IPropertyFacilityPage>(`${environment.api_url}${this.propertyTypeUrl}/search`, params)
      .pipe(tap(_ => this.log('search Property Type')),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('get property Type'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      this.notifier.notify('error', `${operation} failed`);
      return of(result as T);
    };
  }
  private log(message: string) {

  }


}
