import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { RefundOpinionServiceService } from 'src/app/service/RefundOpinionService/refund-opinion-service.service';
import { RefundRequestModal } from "src/app/model/refundRequests";
import { ActivatedRoute, Router } from '@angular/router';
import { getUserDetails } from 'src/app/model/getUserDetails';
import { Constant } from 'src/app/util/constant';
import { refundRejectRequest } from "src/app/model/refundRejectRequest"
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ValuationReject } from 'src/app/model/valuation-reject';



@Component({
  selector: 'app-view-refund-new-applications',
  templateUrl: './view-refund-new-applications.component.html',
  styleUrls: ['./view-refund-new-applications.component.scss']
})
export class ViewRefundNewApplicationsComponent implements OnInit {

  @ViewChild('popupSuccessMessage', null) private popupMigrated: TemplateRef<object>;

  @ViewChild('rejectIrrelevant', null) private enterReason: TemplateRef<object>;


  userdata: any = [];
  userrolecode: string;
  id:string;
  status:string = this.getStatusType();
  pageHedding:string;
  refundRequest:RefundRequestModal [] =[];
  public getUserDetails  = new getUserDetails('','');
  public refundreject = new refundRejectRequest('',null)
  alowedRejectButton:boolean;
  noResults = false;
  public showDynamicGrid: boolean;
  rejectForm: FormGroup;
  public rejectModel = new ValuationReject('','','','','');

    constructor(
      private refundService:RefundOpinionServiceService, 
      private router:Router, 
      private ARoute:ActivatedRoute, 
      private notifier:NotifierService,
      public modalService: NgbModal,
      private formBuilder: FormBuilder,
      ) {
        this.showDynamicGrid= false;
  }

  ngOnInit() {
    this.getUsers()
  }
  // pagination
  totalRecords:number
  page:number =1

  getStatusType():string{
    let type = this.ARoute.snapshot.paramMap.get('type');
    let status;
    if(type == 'new-application'){
      status = Constant.REFUND_REQUEST_PENDING_CODE;
      this.pageHedding = "REFUND NEW APPLICATIONS";
    }
    else if(type== 'pending-requests'){
      status = Constant.REFUND_REQUEST_INPROGRESS_CODE;
      this.pageHedding = "REFUND INPROGRESS APPLICATIONS";
    }else if(type == 'further-requirement'){
      status = Constant.REFUND_REQUEST_FURTHER_REQUIERMENT_CODE;
      this.pageHedding = "REFUND FURTHER REQUIERMENT APPLICATIONS";
    }else if( type == 'completed'){
      status = Constant.REFUND_REQUEST_COMPLETED_CODE;
      this.pageHedding = "REFUND COMPLETED APPLICATIONS";
    }
    else if( type == 'reject'){
      status = Constant.REFUND_REQUEST_REJECTED_CODE
      this.pageHedding = "REFUND REJECTED APPLICATIONS"
    }
    else if( type == 'completed-payment'){
      status = Constant.REFUND_PAYMENT_COMPLETED
      this.pageHedding = "REFUND NEW APPLICATIONS"
    }

    return status;
  }

  getUsers(){
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.getUserDetails.userId = this.userdata.id;

    for (let role of this.userdata.roles) {
          if (role.code === Constant.ROLE_SUPER_ADMIN) {
            this.userrolecode = Constant.ROLE_SUPER_ADMIN;
            this.alowedRejectButton = false;
            break;
          } else if (role.code === Constant.ROLE_COMMISSIONER) {
            this.userrolecode = Constant.ROLE_COMMISSIONER;
            this.alowedRejectButton = false;
            break;
          } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
            this.userrolecode = Constant.ROLE_DEPUTY_COMMISSIONER;
            this.alowedRejectButton = true;
            break;
          } else if (role.code === Constant.ROLE_ACCESSOR) {
            this.userrolecode = Constant.ROLE_ACCESSOR;
            this.alowedRejectButton = true;
            break;
          } else if (role.code === Constant.ROLE_TAX_OFFICER) {
            this.userrolecode = Constant.ROLE_TAX_OFFICER;
            this.alowedRejectButton = false;
            break;
          } else if (role.code === Constant.ROLE_ACCOUNTANT) {
            this.userrolecode = Constant.ROLE_ACCOUNTANT;
            this.alowedRejectButton = false;
            break;
          } else if (role.code === Constant.ROLE_Collection_Branch) {
            this.userrolecode = Constant.ROLE_Collection_Branch;
            this.alowedRejectButton = false;
            break;
          }
        }
      this.getUserDetails.userRole = this.userrolecode;
      this.refundService.getRefundRequestAsUser(this.getUserDetails.userId, this.getUserDetails.userRole, this.status).subscribe(
        data =>{
          this.refundRequest = data.data;
          this.totalRecords = data.data.length;
        }
      )
    // this.refundService.getRefundRequests().subscribe(
    //   data =>{
    //     this.refundRequest = data.data;
    //     this.totalRecords = data.data.length;
    //   }
    // );
  }

  sendRefundId(id, hasOpinion,deedNo){
    localStorage.removeItem('refundRequest');
    sessionStorage.removeItem('hasOpinion');
    sessionStorage.removeItem('deedNo');

    localStorage.setItem('refundRequest' , id);
    sessionStorage.setItem('hasOpinion', hasOpinion);
    sessionStorage.setItem('deedNo', deedNo);
    this.router.navigate(['/view-refund-internal-user-application/', id]);
    sessionStorage.setItem('refundRequest','true');
  }

  search(){
    if(this.id == ""){
      this.ngOnInit();
      this.noResults = false;
    }else if(Object.keys(this.refundRequest).length === 0){
      this.noResults = true;
    }
    else{
      this.noResults = false;
      this.refundRequest =this.refundRequest.filter(data=>{
        return data.id.match(this.id);
      })  
    }
    console.log(this.refundRequest);
  }

  rejectIrrelevantValuationRequestPopup(refundId){
    this.refundreject.refundId = refundId;
    this.showDynamicGrid = true;
    this.openPopupMessage(this.enterReason);
  }

  closeRejectIrrelevant() {
    this.rejectForm.reset();
    this.modalService.dismissAll();
  }

  openPopupMessage(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });
    this.rejectForm = this.formBuilder.group({
      remarkTextarea: ['', [Validators.required]]
    });
  }

  get f() {
    return this.rejectForm.controls;
  }

  rejectRefundRequest(refundId:number){
      this.rejectModel.remark = this.rejectForm.get('remarkTextarea').value;
      this.rejectForm.reset();
    this.refundreject.refundId = refundId;
    this.refundreject.userRole = this.userrolecode;

    this.refundService.rejectRefundRequest(this.refundreject).subscribe(data=>{
      this.notifier.notify('success','Refund Rejected Successfully')
      // location.reload();
      this.ngOnInit();
    })
    this.modalService.dismissAll();
  }

}
