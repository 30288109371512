import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SubSink} from "subsink";
import {ValuationRequestService} from "../../../../../../service/valuation-request/valuation-request.service";
import FileSaver from "file-saver";
@Component({
  selector: 'app-rejected-requests-internal',
  templateUrl: './rejected-requests-internal.component.html',
  styleUrls: ['./rejected-requests-internal.component.scss']
})
export class RejectedRequestsInternalComponent implements OnInit {

  // pagination
  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];
  searchKeyword = "";
  nextPage: number;
  // valuationRequests: IValuationRequest [] = [];
  valuationRequests: any;
  @Output() rejectedRequestCount = new EventEmitter<number>();
  noResults: boolean = false;
  private sub = new SubSink();

  constructor(private valuationRequestService: ValuationRequestService) {
  }

  ngOnInit() {
    this.selectedPageNo = 0;
    this.limit = 10;
    this.nextPage = 0;

    this.searchAll();
  }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

  getPage(pageNo: number) {
    this.selectedPageNo = pageNo;

    this.sub.add(this.valuationRequestService.findValuationRequestByStatus(
      'REJECTED', this.selectedPageNo, this.limit, 'INTERNAL').subscribe(
      (res) => {
        this.valuationRequests = res.data.content;
        if (Object.keys(this.valuationRequests).length === 0) {
          this.noResults = true;
        } else this.noResults = false;
        this.paginationButtonCounter(res.data.totalPages);
        this.numOfPages = res.data.totalPages;

        //Setting the pagination for the table No. of Requests
        if (this.selectedPageNo != 0) {
          this.nextPage = 10 * this.selectedPageNo;
        } else {
          this.nextPage = 0;
        }
      }));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  search() {
    this.selectedPageNo = 0;
    this.limit = 10;

    if (this.searchKeyword.trim() !== "" && this.searchKeyword != null) {
      this.sub.add(this.valuationRequestService.searchValuationRequest(this.searchKeyword,
        'REJECTED', this.selectedPageNo, this.limit, 'INTERNAL').subscribe(
        (res) => {
          this.valuationRequests = res.data.content;
          if (Object.keys(this.valuationRequests).length === 0) {
            this.noResults = true;
          } else this.noResults = false;
          this.paginationButtonCounter(res.data.totalPages);
          this.numOfPages = res.data.totalPages;

        }));
    } else {
      this.searchAll()
    }


  }

  searchAll() {
    this.sub.add(this.valuationRequestService.findValuationRequestByStatus(
      'REJECTED', this.selectedPageNo, this.limit, 'INTERNAL').subscribe(
      (res) => {
        this.valuationRequests = res.data.content;
        if (Object.keys(this.valuationRequests).length === 0) {
          this.noResults = true;
        } else this.noResults = false;
        this.paginationButtonCounter(res.data.totalPages);
        this.numOfPages = res.data.totalPages;
        this.rejectedRequestCount.emit(res.data.totalElements);
      }));
  }

  downloadpdf(id) {
    this.valuationRequestService.downloadpdf(id).subscribe(response => {
      let blob: any = new Blob([response], {type: 'application/pdf'});
      // const url= window.URL.createObjectURL(blob);
      // // window.open(url);
      // window.location.href = url;
      FileSaver.saveAs(blob, "valuation_application_" + id + ".pdf");
    }), error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
    // console.log('lllll')
    // return this.valuationRequestService.downloadpdf(id);
  }
}
