import {Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {ProvinceService} from 'src/app/service/province/province.service';
import {SubSink} from 'subsink';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Grantee} from 'src/app/model/grantee';
import {CompanyCategoryService} from 'src/app/service/company-category/company-category.service';
import {CompanyTypeService} from 'src/app/service/company-type/company-type.service';
import {Company} from '../../../../../../../model/company';
import {CompanyCategory} from 'src/app/model/companyCategory';
import {NotifierService} from 'angular-notifier';
import {ActivatedRoute} from '@angular/router';
import {Province} from '../../../../../../../model/province';
import {CompanyType} from '../../../../../../../model/companyType';
import {FileUploadGranteeComponent} from '../../../file-upload-grantee/file-upload-grantee.component';
import {ValuationRequest} from '../../../../../../../model/valuation-request';
import {FutureDateValidator} from '../../../../../../validators/futuredate.validator';

@Component({
  selector: 'app-grantee',
  templateUrl: './grantee.component.html',
  styleUrls: ['./grantee.component.scss']
})
export class GranteeComponent implements OnInit, OnDestroy {

  @ViewChild('fileUploadForm20', {static: false})
  fileUploadForm20: FileUploadGranteeComponent;
  @ViewChild('modelCloseBtn', {static: false}) modelCloseBtn: ElementRef;
  orderedProvinces: Province[];
  companyCategories: CompanyCategory[];
  companyType: CompanyType[];
  granteeCheck = false;
  result = false;
  addButtonEnability = true;
  granteeForm: FormGroup;
  other: any;
  @Input()
  grantees: Grantee[];
  @Input()
  updatedPaths = [];
  tempgrantees: Grantee[];
  selectPaths = [];
  activeUrl: string;
  selectedProvince: string;
  contactdetofficeno: number;
  isNewGranteeBtnSelected: boolean;
  deletedIndex: number;
  granteeIndex: number;
  OtherCompany = true;
  isUpdate = false;
  isGranteeIndividual = true;
  isGranteeCompany = true;
  otherCompanyValue: any;
  maxDate: any;
  @Input()
  valuationRequest: ValuationRequest;

  // Validation
  nicPattern = '^([0-9]{9}[x|X|v|V]|[0-9]{12})$';
  // phoneNumberPattern = '0[1,2,5,6,7,8,9][0-9]+' ;
  phoneNumberPattern = '^(?:0|94|\\+94|0094)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|91)(0|2|3|4|5|7|9)|7[0-9]\\d)\\d{6}$';
  emailPattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
  addressPattern = /^[ a-zA-Z0-9./,-/']*$/;
  cityPattern = '^[ a-zA-Z0-9-.]*$';
  companyRegNoPattern = '^[ a-zA-Z0-9-/#]*$';
  onlyTextPattern = '^[ a-zA-Z]*$';
  onlyNumberPattern = '^[ 0-9]*$';
  nameWithInitialsPattern = '^[ a-zA-Z.]*$';
  private sub = new SubSink();

  // granteeFiles: any[];


  constructor(private provinceService: ProvinceService, private formBuilder: FormBuilder,
              private companyCategoryService: CompanyCategoryService,
              private activatedRoute: ActivatedRoute,
              private companyTypeService: CompanyTypeService,
              private notifier: NotifierService) {
    this.activeUrl = this.activatedRoute.snapshot.url[0].path;
  }

  get f() {
    return this.granteeForm.controls;
  }

  get f2() {
    return this.granteeForm.controls;
  }

  ngOnInit() {

    this.contactdetofficeno = 12345678;
    this.isNewGranteeBtnSelected = false;
    this.tempgrantees = [];

    this.granteeForm = this.formBuilder.group({
      nameWithInitials: ['', [Validators.required, Validators.pattern(this.nameWithInitialsPattern)]],
      firstName: ['', [Validators.required, Validators.pattern(this.onlyTextPattern)]],
      lastName: ['', [Validators.required, Validators.pattern(this.onlyTextPattern)]],
      middleName: ['', [Validators.pattern(this.onlyTextPattern)]],
      addressLine1: ['', [Validators.required, Validators.pattern(this.addressPattern)]],
      addressLine2: ['', [Validators.pattern(this.addressPattern)]],
      city: ['', [Validators.required, Validators.pattern(this.cityPattern)]],
      province: ['', [Validators.required]],
      postalCode: ['', [Validators.pattern(this.onlyNumberPattern)]],
      nic: ['', [Validators.required, Validators.pattern(this.nicPattern)]],
      contactNo: ['', [Validators.required, Validators.pattern(this.phoneNumberPattern)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      // companyCategory: ['', [Validators.required]],
      companyCategory: [''],
      companyCategoryOther: ['', [Validators.required]],
      name: ['', [Validators.required]],
      addressLine1Company: ['', [Validators.required, Validators.pattern(this.addressPattern)]],
      addressLine2Company: ['', [Validators.pattern(this.addressPattern)]],
      cityCompany: ['', [Validators.required, Validators.pattern(this.cityPattern)]],
      provinceCompany: ['', [Validators.required]],
      postalCodeCompany: ['', [Validators.pattern(this.onlyNumberPattern)]],
      type: ['', [Validators.required]],
      // companyTypeOther: ['', [Validators.required]],
      // companyRegistrationNo: ['', [Validators.required, Validators.pattern(this.companyRegNoPattern)]],
      companyRegistrationNo: [''],
      // companyRegistrationDate: ['', [Validators.required]],
      companyRegistrationDate: [''],
      ownerName: ['', [Validators.required, Validators.pattern(this.nameWithInitialsPattern)]],
      ownerContactNum: ['', [Validators.required, Validators.pattern(this.phoneNumberPattern)]],
      // document: ['', [Validators.required]],
      nameWithInitialsCompanyContact: ['', [Validators.required, Validators.pattern(this.nameWithInitialsPattern)]],
      firstNameCompanyContact: ['', [Validators.required, Validators.pattern(this.onlyTextPattern)]],
      lastNameCompanyConatact: ['', [Validators.required, Validators.pattern(this.onlyTextPattern)]],
      middleNameCompanyConatact: ['', [Validators.pattern(this.onlyTextPattern)]],
      nicCompanyConatact: ['', [Validators.required, Validators.pattern(this.nicPattern)]],
      emailCompanyConatact: ['', [Validators.required, Validators.required, Validators.pattern(this.emailPattern)]],
      addressLine1CompanyContact: ['', [Validators.required, Validators.pattern(this.addressPattern)]],
      addressLine2CompanyConatact: ['', [Validators.pattern(this.addressPattern)]],
      cityCompanyConatact: ['', [Validators.required, Validators.pattern(this.cityPattern)]],
      provinceComapnyConatact: ['', [Validators.required]],
      contactdetmobileno: ['', [Validators.required, Validators.pattern(this.phoneNumberPattern)]],
      contactdetofficeno: ['', [Validators.required, Validators.pattern(this.phoneNumberPattern)]],
    }, {
      validator: FutureDateValidator('companyRegistrationDate')
    });
    this.granteeForm.get('companyCategoryOther').disable();
    // // get All Provinces
    // this.sub.add(this.provinceService.getProvinces(0, 100).subscribe(
    //   (res) => {
    //     this.provinces = (res as any).data;
    //   }
    // ));

    // get All provinces ordered
    this.sub.add(this.provinceService.getProvincesOrderd().subscribe(
      (res) => {
        this.orderedProvinces = (res as any).data;
      }
    ));

    // get All Company Categories
    this.sub.add(this.companyCategoryService.getAll().subscribe(
      res => {
        this.companyCategories = (res as any).data;
      }
    ));

    // get All company Type
    this.sub.add(this.companyTypeService.getAll().subscribe(
      res => {
        this.companyType = (res as any).data;
      }
    ));
    this.maxDate = new Date();
  }

  addGrantee() {
    if (this.checkFormValidity()) {
      if (this.isUpdate) {
        const grantee = this.buildGrantee();
        this.grantees[this.granteeIndex] = grantee;
        this.tempgrantees.push(grantee);
        this.isNewGranteeBtnSelected = true;
        localStorage.setItem('grantees', JSON.stringify(this.grantees));
        this.modelClose();
      } else {
        const grantee = this.buildGrantee();
        console.log(this.buildGrantee());
        this.grantees.push(grantee);
        this.tempgrantees.push(grantee);
        this.isNewGranteeBtnSelected = true;
        localStorage.setItem('grantees', JSON.stringify(this.grantees));
        this.modelClose();
      }
    } else {
      this.notifier.notify('error', 'Grantee Addition Failed due to Invalid Data');
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  setOtherVal(ev) {
    if (ev.target.value === 'Other') {
      this.granteeForm.get('companyCategoryOther').enable();
      this.granteeForm.get('companyCategoryOther').setValidators([Validators.required]);
      this.granteeForm.get('companyCategoryOther').updateValueAndValidity();
    } else {
      this.granteeForm.get('companyCategoryOther').clearValidators();
      this.granteeForm.get('companyCategoryOther').updateValueAndValidity();
      this.granteeForm.get('companyCategoryOther').disable();
      this.granteeForm.get('companyCategoryOther').setValue('');
    }
  }

  refresh() {
    this.granteeForm.reset();
    this.selectPaths=[];
    this.updatedPaths=[];
    this.granteeCheck=false;
  }

  removeIndividual() {
    this.grantees.splice(this.deletedIndex, 1);
    this.notifier.notify('success', 'Grantee is removed successfully');
    this.selectPaths=[];
    this.updatedPaths=[];
  }

  setForm20DocArray(images: File[]) {
    this.selectPaths = images;
  }

  setEveryChildComponentUploadingDocuments() {
    if (this.fileUploadForm20){
      this.fileUploadForm20.outPutImagesSet();
    }else {
      this.selectPaths=this.updatedPaths;
    }


    // this.setGranteeDocsStorage();
  }

  // setGranteeDocsStorage() {
  //   let base64images = [];
  //   for (let i = 0; i < this.selectPaths.length; i++) {
  //     const file = this.selectPaths[i];
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = (_event: any) => {
  //       base64images.push(JSON.stringify(_event.currentTarget.result));
  //       console.log(i, _event.currentTarget.result)
  //     };
  //
  //   }
  //   this.granteeFiles=base64images;
  //   // localStorage.setItem('granteersDocs', JSON.stringify(base64images));
  //   // console.log('granteer docus', base64images)
  //   // console.log('JSON.stringify(base64images)', JSON.stringify(base64images))
  // }

  buildGrantee(): Grantee {
    const grantee = new Grantee();
    if (this.granteeCheck === false) {
      grantee.granteeCompany = false;
      grantee.isGranteeIndividual = true;
      grantee.nameWithInitials = this.granteeForm.get('nameWithInitials').value;
      grantee.firstName = this.granteeForm.get('firstName').value;
      grantee.lastName = this.granteeForm.get('lastName').value;
      grantee.middleName = this.granteeForm.get('middleName').value;
      grantee.addressLine1 = this.granteeForm.get('addressLine1').value;
      grantee.addressLine2 = this.granteeForm.get('addressLine2').value;
      grantee.postalCode = this.granteeForm.get('postalCode').value;
      grantee.city = this.granteeForm.get('city').value;
      grantee.province = this.granteeForm.get('province').value;
      grantee.email = this.granteeForm.get('email').value;
      grantee.nic = this.granteeForm.get('nic').value;
      grantee.contactNo = this.granteeForm.get('contactNo').value;
      return grantee;
    } else {
      grantee.granteeCompany = true;
      grantee.nameWithInitials = this.granteeForm.get('nameWithInitialsCompanyContact').value;
      grantee.firstName = this.granteeForm.get('firstNameCompanyContact').value;
      grantee.lastName = this.granteeForm.get('lastNameCompanyConatact').value;
      grantee.middleName = this.granteeForm.get('middleNameCompanyConatact').value;
      grantee.addressLine1 = this.granteeForm.get('addressLine1CompanyContact').value;
      grantee.addressLine2 = this.granteeForm.get('addressLine2CompanyConatact').value;
      grantee.city = this.granteeForm.get('cityCompanyConatact').value;
      grantee.province = this.granteeForm.get('provinceComapnyConatact').value;
      grantee.email = this.granteeForm.get('emailCompanyConatact').value;
      grantee.nic = this.granteeForm.get('nicCompanyConatact').value;

      const company = new Company();
      company.companyCategory = this.granteeForm.get('companyCategory').value;
      company.companyCategoryOther = this.granteeForm.get('companyCategoryOther').value;
      company.name = this.granteeForm.get('name').value;
      company.companyRegistrationDate = this.granteeForm.get('companyRegistrationDate').value;
      company.companyRegistrationNo = this.granteeForm.get('companyRegistrationNo').value;
      company.city = this.granteeForm.get('cityCompany').value;
      company.province = this.granteeForm.get('provinceCompany').value;
      company.postalCode = this.granteeForm.get('postalCodeCompany').value;
      company.addressLine1 = this.granteeForm.get('addressLine1Company').value;
      company.addressLine2 = this.granteeForm.get('addressLine2Company').value;
      company.type = this.granteeForm.get('type').value;
      company.ownerName = this.granteeForm.get('ownerName').value;
      company.ownerContactNum = this.granteeForm.get('ownerContactNum').value;
      company.companyEmail = this.granteeForm.get('emailCompanyConatact').value;
      company.companyMobile = this.granteeForm.get('contactdetmobileno').value;
      company.companyOfficeNo = this.granteeForm.get('contactdetofficeno').value;

      grantee.company = company;
      return grantee;
    }
  }

  checkFormValidity(): boolean {
    if (this.granteeCheck) {
      this.granteeForm.get('nameWithInitialsCompanyContact').markAsTouched();
      this.granteeForm.get('firstNameCompanyContact').markAsTouched();
      this.granteeForm.get('lastNameCompanyConatact').markAsTouched();
      this.granteeForm.get('addressLine1CompanyContact').markAsTouched();
      this.granteeForm.get('cityCompanyConatact').markAsTouched();
      this.granteeForm.get('provinceComapnyConatact').markAsTouched();
      this.granteeForm.get('emailCompanyConatact').markAsTouched();
      this.granteeForm.get('nicCompanyConatact').markAsTouched();
      this.granteeForm.get('companyCategory').markAsTouched();
      this.granteeForm.get('companyCategoryOther').markAsTouched();
      this.granteeForm.get('name').markAsTouched();
      this.granteeForm.get('companyRegistrationDate').markAsTouched();
      this.granteeForm.get('companyRegistrationNo').markAsTouched();
      this.granteeForm.get('cityCompany').markAsTouched();
      this.granteeForm.get('provinceCompany').markAsTouched();
      this.granteeForm.get('addressLine1Company').markAsTouched();
      this.granteeForm.get('ownerName').markAsTouched();
      this.granteeForm.get('type').markAsTouched();
      this.granteeForm.get('ownerContactNum').markAsTouched();
      this.granteeForm.get('nameWithInitialsCompanyContact').markAsTouched();
      this.granteeForm.get('firstNameCompanyContact').markAsTouched();
      this.granteeForm.get('lastNameCompanyConatact').markAsTouched();
      this.granteeForm.get('addressLine1CompanyContact').markAsTouched();
      this.granteeForm.get('cityCompanyConatact').markAsTouched();
      this.granteeForm.get('provinceComapnyConatact').markAsTouched();
      this.granteeForm.get('emailCompanyConatact').markAsTouched();
      this.granteeForm.get('nicCompanyConatact').markAsTouched();
      this.granteeForm.get('contactdetmobileno').markAsTouched();
      this.granteeForm.get('contactdetofficeno').markAsTouched();

      if (this.checkGranteeCompanyFields()) {
        return true;
      } else {
        return false;
      }

    } else {
      this.granteeForm.get('nameWithInitials').markAsTouched();
      this.granteeForm.get('firstName').markAsTouched();
      this.granteeForm.get('lastName').markAsTouched();
      // this.granteeForm.get('middleName').markAsTouched();
      this.granteeForm.get('addressLine1').markAsTouched();
      // this.granteeForm.get('addressLine2').markAsTouched();
      // this.granteeForm.get('postalCode').markAsTouched();
      this.granteeForm.get('city').markAsTouched();
      this.granteeForm.get('province').markAsTouched();
      this.granteeForm.get('email').markAsTouched();
      this.granteeForm.get('nic').markAsTouched();
      this.granteeForm.get('contactNo').markAsTouched();
      // check validity
      if (this.checkGranteeIndividualFields()) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkGrantee() {
    this.isUpdate = false;
    this.granteeForm.get('companyCategoryOther').disable();
    this.granteeForm.reset();
    if (this.grantees.length >= 1) {
      if (this.grantees[0].granteeCompany) {
        this.isGranteeIndividual = false;
        this.notifier.notify('error', 'Only One Company Can Be Added as a Grantee');
        return false;
      } else {
        this.granteeCheck = false;
        this.isGranteeIndividual = true;
      }

    } else {
      this.granteeForm.reset();
    }

    if (this.isNewGranteeBtnSelected) {
      this.tempgrantees = [];
    } else {
      // this.isNewGranteeBtnSelected = true;
    }

  }

  checkEditGrantee(index) {
    this.tempgrantees = [];
    this.tempgrantees.push(this.grantees[index]);
    this.granteeForm.patchValue(this.grantees[index]);
    this.isUpdate = true;
    if (this.tempgrantees[0].granteeCompany) {
      this.granteeCheck = true;
      this.granteeForm.patchValue({
        companyCategory: this.grantees[0].company.companyCategory,
        companyCategoryOther: this.grantees[0].company.companyCategoryOther,
        name: this.grantees[0].company.name,
        addressLine1Company: this.grantees[0].company.addressLine1,
        addressLine2Company: this.grantees[0].company.addressLine2,
        cityCompany: this.grantees[0].company.city,
        provinceCompany: this.grantees[0].company.province,
        postalCodeCompany: this.grantees[0].company.postalCode,
        type: this.grantees[0].company.type,
        companyRegistrationNo: this.grantees[0].company.companyRegistrationNo,
        companyRegistrationDate: this.grantees[0].company.companyRegistrationDate,
        ownerName: this.grantees[0].company.ownerName,
        ownerContactNum: this.grantees[0].company.ownerContactNum,
        nameWithInitialsCompanyContact: this.grantees[0].nameWithInitials,
        firstNameCompanyContact: this.grantees[0].firstName,
        lastNameCompanyConatact: this.grantees[0].lastName,
        middleNameCompanyConatact: this.grantees[0].middleName,
        nicCompanyConatact: this.grantees[0].nic,
        addressLine1CompanyContact: this.grantees[0].addressLine1,
        addressLine2CompanyConatact: this.grantees[0].addressLine2,
        cityCompanyConatact: this.grantees[0].city,
        provinceComapnyConatact: this.grantees[0].province,
        contactdetmobileno: this.grantees[0].company.companyMobile,
        contactdetofficeno: this.grantees[0].company.companyOfficeNo,
        emailCompanyConatact: this.grantees[0].company.companyEmail
      });
    }
    this.granteeIndex = index;
  }

  deleteGrantee(index) {
    this.deletedIndex = index;
    this.isGranteeIndividual = true;
    this.refresh();
  }

  checkGranteeAvailability(): boolean {
    return this.grantees.length >= 1;
  }

  modelClose() {
    // if (!this.isUpdate) {
    //   this.refresh();
    // }
    this.modelCloseBtn.nativeElement.click();
    // this.granteeCheck = true;
  }

  changeIndividualtoCompany() {
    this.granteeCheck = !this.granteeCheck;
    // this.granteeForm.reset();
    this.onclickCheck();
  }

  onclickCheck() {
    if (this.grantees.length > 0) {
      if (this.grantees[0].isGranteeIndividual) {
        this.isGranteeCompany = false;
        this.notifier.notify('error', 'Can not add Company as Grantee with Individual Grantees');
        this.granteeCheck = false;
      } else if(!this.granteeCheck) {
        this.granteeCheck = true;
      } else {
        this.granteeCheck = false;
      }
    } else if(!this.granteeCheck) {
      this.granteeCheck = true;
    } else {
      this.granteeCheck = false;
    }
  }

  addButtonEnabled(result: boolean) {
    this.addButtonEnability = result;
  }

  checkGranteeIndividualFields(): boolean {
    if (this.granteeForm.get('nameWithInitials').valid &&
      this.granteeForm.get('firstName').valid &&
      this.granteeForm.get('lastName').valid &&
      this.granteeForm.get('addressLine1').valid &&
      this.granteeForm.get('city').valid &&
      this.granteeForm.get('province').valid &&
      this.granteeForm.get('email').valid &&
      this.granteeForm.get('nic').valid &&
      this.granteeForm.get('contactNo').valid) {
      return true;
    } else {
      return false;
    }
  }

  checkGranteeCompanyFields(): boolean {
    if (this.granteeForm.get('nameWithInitialsCompanyContact').valid && this.granteeForm.get('firstNameCompanyContact').valid &&
      this.granteeForm.get('lastNameCompanyConatact').valid && this.granteeForm.get('addressLine1CompanyContact').valid &&
      this.granteeForm.get('cityCompanyConatact').valid && this.granteeForm.get('provinceComapnyConatact').valid &&
      this.granteeForm.get('emailCompanyConatact').valid && this.granteeForm.get('nicCompanyConatact').valid &&
      this.granteeForm.get('companyCategory').valid &&
      this.granteeForm.get('name').valid && this.granteeForm.get('companyRegistrationDate').valid &&
      this.granteeForm.get('companyRegistrationNo').valid && this.granteeForm.get('cityCompany').valid &&
      this.granteeForm.get('provinceCompany').valid && this.granteeForm.get('addressLine1Company').valid &&
      this.granteeForm.get('ownerName').valid && this.granteeForm.get('type').valid &&
      this.granteeForm.get('ownerContactNum').valid && this.granteeForm.get('nameWithInitialsCompanyContact').valid &&
      this.granteeForm.get('firstNameCompanyContact').valid && this.granteeForm.get('lastNameCompanyConatact').valid &&
      this.granteeForm.get('addressLine1CompanyContact').valid && this.granteeForm.get('cityCompanyConatact').valid &&
      this.granteeForm.get('provinceComapnyConatact').valid && this.granteeForm.get('emailCompanyConatact').valid &&
      this.granteeForm.get('nicCompanyConatact').valid &&
      this.granteeForm.get('contactdetmobileno').valid && this.granteeForm.get('contactdetofficeno').valid && this.validOther()) {

      if (this.checkDocument()) {
        return true;
      } else {
        this.notifier.notify('error', 'Grantee Addition Failed due to Document not being present');
      }


    } else {
      return false;
    }
  }

  checkDocument(): boolean {
    if (this.fileUploadForm20.filesListGrantee.length === 0) {
      return false;
    }
    return true;
  }

  validOther(): boolean {
    if (!this.granteeForm.get('companyCategoryOther').disabled) {
      if (!this.granteeForm.get('companyCategoryOther').valid) {
        return false;
      }
      return true;

    }
    return true;
  }
}
