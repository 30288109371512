import { Component, Input, OnInit, EventEmitter, Output, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";
import { NotifierService } from 'angular-notifier';
import { PDFProgressData } from 'ng2-pdf-viewer';
import { SubSink } from "subsink";

import { ValuationRequestService } from 'src/app/service/valuation-request/valuation-request.service';
import { FileService } from 'src/app/service/file/file.service';
import { Constant } from 'src/app/util/constant';
import { NotaryService } from 'src/app/service/notary/notary.service';
import { ValuationStatus } from 'src/app/model/valuation-status';
import { NotificationModel } from 'src/app/model/notification-model';
import { NotificationService } from 'src/app/service/notification/notification.service';
import { NotificationEmailSend } from 'src/app/model/notification-email-send';
import { ValuationRequestUpdate } from 'src/app/model/valuation-request-update';
import { NotaryDetails } from 'src/app/model/notary-details';

@Component({
  selector: 'app-view-not-iu',
  templateUrl: './view-not-iu.component.html',
  styleUrls: ['./view-not-iu.component.scss']
})
export class ViewNotaryInternalUserComponent implements OnInit {
  @Input()
  userId: string;
  notaryDetails: NotaryDetails;
  private sub = new SubSink();
  notaryId: string;
  verificationStatus: number;
  deedNatureValue: any;
  currentUserRoleCode: string;
  userdata: any = [];
  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() status: EventEmitter<any> = new EventEmitter<any>();
  files;
  images = [];
  originalImages: any[] = [];
  filesList: File[] = [];
  names: string[] = [];
  public imgURL: any;
  public imgURL1: any;
  public documentUploaded: string;
  public documentUploaded1: string;

  noDocs = false;
  dataDone = false;

  public modelStatus = new ValuationStatus('', '');
  public notificationModel = new NotificationModel("", "", "", "", "", "", "");
;
  public valuationRequestUpdateModel = new ValuationRequestUpdate('', '', '', '', '', '');
  public notificationEmailSendModel = new NotificationEmailSend();
  selectFileSm = 0;
  typeID: any;
  previewFileData: any;
  type: any;

  public fileNumberFormat: string;
  public generatedFileNumber: string;
  valuationdata: any = [];

  fileNumberFormatAvailable: boolean = true;

  constructor(
    private valuationRequestService: ValuationRequestService,
    private fileService: FileService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public notaryService: NotaryService,
    public router: Router,
    public modalService: NgbModal,
    private notifier: NotifierService,
    public notificationService: NotificationService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this._prepare();
    window.scroll(0,0);
  }

  _prepare() {
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));

    this.fileNumberFormat = localStorage.getItem('fileNumberFormat');
    this.fileNumberFormat = localStorage.getItem('fileNumberFormat');
    if (this.fileNumberFormat == "null" || this.fileNumberFormat == '') {
      this.fileNumberFormatAvailable = false;
    }

    const notaryUserID = this.userId;
    this.typeID = +this.route.snapshot.paramMap.get('id');
    this.getNotaryDetails(notaryUserID);
    this.valuationdata = JSON.parse(sessionStorage.getItem('valuationRequest'));
    this.pad(this.valuationdata.id, 7);
    this.checkPermission();
    this.retrieveAssignedValuationRequest();
  }

  checkPermission() {
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.currentUserRoleCode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.currentUserRoleCode = Constant.ROLE_ACCESSOR;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER){
        this.currentUserRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.currentUserRoleCode = Constant.ROLE_COMMISSIONER;
        break;
      }
    }
  }

  pad(num: number, size: number) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    this.generatedFileNumber = s;
    this.generatedFileNumber = '#VR' + this.generatedFileNumber;
  }

  public redirectComponent(): void {
    this.onPageCountChanged.emit(5);
  }

  retrieveAssignedValuationRequest() {
    this.valuationRequestService.valuationRequestAssigned(this.route.snapshot.paramMap.get('id')).subscribe(
      data => {
        if (data.data !== null) {
          this.valuationRequestUpdateModel.dcommissionerId = data.data.dcommissionerId;
          this.valuationRequestUpdateModel.acccessorId = data.data.accessorId;
          this.valuationRequestUpdateModel.remark = data.data.remark;
        } else {
          console.log("Valuation Request not found");
        }
      }
    )
  }

  public redirectPreviousComponent(): void {
    this.onPageCountChanged.emit(3);
  }

  getVerificationStatus() {
    this.modelStatus.id = this.notaryId;
    this.notaryService.notaryVerificationStatus(this.modelStatus).subscribe(
      data => {
        this.verificationStatus = data.data;
      }
    )
  }

  updateVerificationStatus(event) {
    this.modelStatus.id = this.notaryId;
    if (event.target.checked === true) {
      this.modelStatus.verificationStatus = '1';
      this.notaryService.notaryUpdateVerificationStatus(this.modelStatus).subscribe(
        data => {
          this.getVerificationStatus();
        }
      )
    } else {
      this.modelStatus.verificationStatus = '0';
      this.notaryService.notaryUpdateVerificationStatus(this.modelStatus).subscribe(
        data => {
          this.getVerificationStatus();
        }
      )
    }
    this.checkStatus(true);
  }

  // getNotaryDetails(notaryUserID: string) {
  //   console.log(notaryUserID);
  //   this.sub.add(this.valuationRequestService.getNotaryDetailsByUserID(notaryUserID)
  //     .subscribe((res) => {
  //       this.notaryDetails = res.data;
  //       if ( this.notaryDetails['id'] !== null ) {
  //         this.notaryId = this.notaryDetails['id'].toString();
  //         this.getVerificationStatus();
  //       }
  //     }));
  // }

  getNotaryDetails(notaryUserID: string) {
    console.log(notaryUserID);
    this.sub.add(this.valuationRequestService.getNotaryDetailsByUserID(notaryUserID).subscribe(
      data => {
        console.log(data);
        this.notaryDetails = data.data;
        if ( this.notaryDetails.id !== null ) {
          this.notaryId = this.notaryDetails.id.toString();
          this.getVerificationStatus();
        }
      }));
  }

  onProgress(progressData: PDFProgressData) {
    if (progressData.loaded == progressData.total) {
      this.spinner.hide();
    }
  }

  previewFile(type) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadFileById(this.typeID, type).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(()=>{
          this.spinner.hide();
        }, 2000);

      }else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }

  public checkStatus(type:boolean): void{
    this.status.emit(type);
  }
}
