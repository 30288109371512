import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubSink} from 'subsink';
import {NotifierService} from 'angular-notifier';
import {DataService} from '../../../../../../service/data.service';
import {Bank} from '../../../../../../model/bank';
import {BankService} from '../../../../../../service/bank/bank.service';

@Component({
  selector: 'app-add-bank',
  templateUrl: './add-bank.component.html',
  styleUrls: ['./add-bank.component.scss']
})
export class AddBankComponent implements OnInit, OnDestroy {

  addBankForm: FormGroup;
  private sub = new SubSink();

  constructor(private formBuilder: FormBuilder,
              private bankService: BankService,
              private notifier: NotifierService,
              private dataService: DataService) {
  }

  ngOnInit() {
    this.addBankForm = this.formBuilder.group({
      // code: ['', [Validators.required]],
      bank: ['', [Validators.required]]
    });
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  saveBank() {
    if (this.addBankForm.valid) {

      const bank = new Bank();

      bank.bankName = this.getDescription();
      // bank.code = this.getCode();

      this.sub.add(this.bankService.createBank(bank)
        .subscribe(response => {
          this.notifier.notify('success', 'Successfully Added Bank');
          this.dataService.updateTableOffice(true);
        }));
    }
  }

  getCode() {
    return this.addBankForm.get('code').value;
  }

  getDescription() {
    return this.addBankForm.get('bank').value;
  }

}
