export class RefundRequestGrantorDetails {
    address_line_1: string;
    address_line_2: string;
    city: string;
    contact_no: string;
    email: string;
    name_with_initials: string;
    nic: string;
    isSameGrantee: boolean;
}
