import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  Output,
  EventEmitter,
  Input
} from "@angular/core";
import { CalculationService } from "src/app/service/calculation/calculation.service";
import { ActivatedRoute } from "@angular/router";
import { CalculationDataProcess } from "src/app/service/calculationDataProcess/calculation-data-process.service";
import { NotifierService } from "angular-notifier";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CondominiumComponent } from "./condominium/condominium.component";
import { LandComponent } from "./land/land.component";
import { LandBuildingComponent } from "./land-building/land-building.component";
import Swal from "sweetalert2";
import { ReCreationComponent } from "./re-creation/re-creation.component";
import { ExchangeCalculationComponent } from "./exchange-calculation/exchange-calculation";
import { ValuationRequestService } from "src/app/service/valuation-request/valuation-request.service";
import { checkCompletedOrPaypend } from "../Util/checkStatus";

@Component({
  selector: "app-view-calculation",
  templateUrl: "./view-calculation.component.html",
  styleUrls: ["./view-calculation.component.scss"]
})
export class ViewCalculationComponent implements OnInit {
  @Input()
  isReject:boolean;
  @Input()
  isAppeal:boolean;
  @Input()
  valuationRequestStatus: string;

  appealCount:number;

  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() status: EventEmitter<any> = new EventEmitter<any>();
  // selectedNatureOfDeed = 0;
  // selectedDate = 0;
  // selectedPropertyType = 0;
  panelNumber = 1;
  // refNo;

  refundRequest: boolean;

  calculationList = [];
  checkStatusApplication  = checkCompletedOrPaypend;


  dataModel = {
    selectedNatureOfDeed: null,
    selectedDate: null,
    selectedPropertyType: null,
    considerCurrentDate: null,
    subPropertyType: null,
    refNo: null
  };

  selectedRecord = null;

  isUpdate = false;

  dropdownList = [];
  selectedItems = [];
  // dropdownSettings = {};
  showSubPanel = {
    showCondominium: false,
    showLand: false,
    showLandBuilding: false
  };

  saveData = null;

  isExchangeApplication: boolean = false;

  @ViewChild(CondominiumComponent, { static: false })
  condominiumComponent: CondominiumComponent;

  @ViewChild(LandComponent, { static: false })
  landComponent: LandComponent;

  @ViewChild(LandBuildingComponent, { static: false })
  landBuildingComponent: LandBuildingComponent;

  @ViewChild(ReCreationComponent, { static: false })
  reCreationComponent: ReCreationComponent;

  @ViewChild(ExchangeCalculationComponent, { static: false })
  exchangeComponent: ExchangeCalculationComponent;

  @ViewChild("popupSuccessMessage", null) private popupMigrated: TemplateRef<
    object
  >;

  dropdownSettings: NgMultiSelectDropDownModule = {
    unSelectAllText: "Unselect"
  };
  maxDate: Date;

  public generatedFileNumber: string;

  valuationdata: any = [];

  public fileNumberFormat: string;

  fileNumberFormatAvailable: boolean = true;

  constructor(
    public valuationRequestService:ValuationRequestService,
    public apiService: CalculationService,
    private activatedRoute: ActivatedRoute,
    private dataProcess: CalculationDataProcess,
    private notifier: NotifierService,
    private route: ActivatedRoute,
    public modalService: NgbModal
  ) {
    this.refundRequest = false;
    this.appealCount = 0;
  }

  ngOnInit() {
    this.valuationdata = JSON.parse(sessionStorage.getItem('valuationRequest'));
    
    this.getAppealCount();
    this.activatedRoute.params.subscribe(params => {
      this.dataModel.refNo = params["id"];
    });

    if(sessionStorage.getItem('refundRequest') == 'true') {
      this.refundRequest = true;
      this.fetchRefundOpinionCalculationRecordByRefundRequest();
      this.fileNumberFormat = localStorage.getItem('fileNumber');
      if (this.fileNumberFormat == "null" || this.fileNumberFormat == '') {
        this.fileNumberFormatAvailable = false;
      }
      
      this.generatedFileNumber = localStorage.getItem('refundNumber');
    } else {
      this.refundRequest = false;
      this.fetchCalculationRecordByRefNo();
      this.fileNumberFormat = localStorage.getItem('fileNumberFormat');
      if (this.fileNumberFormat == "null" || this.fileNumberFormat == '') {
        this.fileNumberFormatAvailable = false;
      }

      this.pad(this.valuationdata.id, 7);
    }

    this.getValuationRequestDetail();

    this.dropdownList = [
      { id: 1, text: "Condominium" },
      { id: 2, text: "Land" },
      { id: 3, text: "Land with Building" }
    ];
    this.selectedItems = [];
    window.scroll(0,0);

    this.maxDate = new Date();

    this.checkStatus(true);

  }

  onItemSelect(item: any) {}
  onSelectAll(items: any) {
    console.log(items);
  }

  resetCalculationMainPage(){
    this.ngOnInit();
    this.dataModel={
      selectedNatureOfDeed: null,
      selectedDate: null,
      selectedPropertyType: null,
      considerCurrentDate: null,
      subPropertyType: null,
      refNo: null
    }
    this.showSubPanel = {
      showCondominium: false,
      showLand: false,
      showLandBuilding: false
    };
  }

  pad(num: number, size: number) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    this.generatedFileNumber = s;
    this.generatedFileNumber = '#VR' + this.generatedFileNumber;
  }

  getAppealCount(){
    this.valuationRequestService.getAppealCount(this.route.snapshot.paramMap.get('id')).subscribe(data=>{
      this.appealCount = data.data;
    })
  }

  public checkStatus(type:boolean): void{
    this.status.emit(type);
  }

  getValuationRequestDetail() {
    console.log(this.dataModel.refNo);
    this.apiService
      .getValuationRequestDetail(this.dataModel.refNo)
      .subscribe(data => {
        console.log(data);
        if (data.data.exchangeId != null || data.data.deedNature == 'Exchange') {
          this.isExchangeApplication = true;
          this.dataModel.selectedNatureOfDeed = data.data.deedNature.toLowerCase();
        }
      });
  }

  selectDeedNature(){
    if(this.dataModel.selectedNatureOfDeed != "gift"){
      this.dataModel.selectedDate = null;
      this.dataModel.considerCurrentDate = null;
    }
  }

  jumpToPanel(number) {

    if(this.dataModel.selectedDate == "after"){
      this.jumpPanelCommon(number);
      if(this.dataModel.considerCurrentDate != null){
      this.jumpPanelCommon(number);
      }else{
      this.notifier.notify("error", "Select Consider Date of Acquisition");
      //Swal.fire("Error!", "Please select Date ", "error");
      return;
      }
    }else{
      this.jumpPanelCommon(number);
    }

  }

  jumpPanelCommon(number) {
        // Used to enable the Save button
        sessionStorage.setItem('disableSave', btoa(JSON.stringify(false)));

        if (this.dataModel.selectedNatureOfDeed == null) {
          Swal.fire("Error!", "Please select nature of deed ", "error");
          return;
        }
    
        if (
          number != 1 &&
          this.dataModel.selectedDate == null &&
          this.dataModel.selectedNatureOfDeed == "gift"
        ) {
          Swal.fire("Error!", "Please select Date ", "error");
          return;
        }
        if (
          number != 1 &&
          this.dataModel.selectedDate == "after" &&
          this.dataModel.selectedNatureOfDeed == "gift" &&
          this.dataModel.considerCurrentDate == null
        ) {
          Swal.fire("Error!", "Select Consider Date of Acquisition", "error");
          return;
        }
    
        if (
          this.dataModel.selectedNatureOfDeed != "exchange" &&
          this.dataModel.selectedPropertyType == null
        ) {
          Swal.fire("Error!", "Please select Property type ", "error");
          return;
        }
        if(this.dataModel.selectedPropertyType == "recreation"){
          if(number != 1){
            if(this.selectedItems.length != 0){
              this.selectedItems.forEach(element => {
                if (element.id == "1") {
                  this.showSubPanel.showCondominium = true;
                }
                if (element.id == "2") {
                  this.showSubPanel.showLand = true;
                }
                if (element.id == "3") {
                  this.showSubPanel.showLandBuilding = true;
                }
              });
            } else{
              Swal.fire("Error!", "Please select Sub Property type ", "error");
              return;
            }
          }
        }
        // if (
        //   this.dataModel.selectedPropertyType == "recreation" &&
        //   this.selectedItems.length < 2
        // ) {
        //   Swal.fire(
        //     "Error!",
        //     "You should select atleast two sub property types",
        //     "error"
        //   );
        //   return;
        // }
        this.selectedRecord = null;
        this.panelNumber = number;
  }

  fetchCalculationRecordByRefNo() {
    this.apiService
      .getCalculationRecords(this.dataModel.refNo)
      .subscribe(data => {
        this.calculationList = data.data;
      });
  }

  fetchRefundOpinionCalculationRecordByRefundRequest() {
    this.apiService.getRedundOpinionCalculationRecords(localStorage.getItem('refundRequest')).subscribe(data => {
        this.calculationList = data.data;
      });
  }

  async saveCalculationData(data) {
    if (this.refundRequest != true && (this.dataModel.selectedPropertyType == "condominium" || (this.selectedRecord != null && this.selectedRecord.propertyType == "condominium"))) {
      let dataReq = await this.dataProcess.condominiumDataProcessToSave(
        data,
        this.dataModel,
        this.isUpdate
      );
      this.apiService
        .saveCalculationCondominiumData(dataReq)
        .subscribe(data => {
          this.panelNumber = 1;
          this.condominiumComponent.saveDocumentsAndDecision(data.data.id);
          this.fetchCalculationRecordByRefNo();
          this.openPopupMessage();
          this.condominiumComponent.setInitiate();
          this.dataModel.selectedDate = null;
          this.dataModel.selectedPropertyType = null;
          this.dataModel.considerCurrentDate = null;
          this.dataModel.subPropertyType = null;
        });
    }

    if (this.refundRequest == true && (this.dataModel.selectedPropertyType == "condominium" || (this.selectedRecord != null && this.selectedRecord.propertyType == "condominium"))) {
      let dataReq = await this.dataProcess.condominiumDataProcessToSave(data, this.dataModel, this.isUpdate);
      this.apiService.saveCalculationCondominiumData(dataReq).subscribe(data => {
          this.panelNumber = 1;
          this.condominiumComponent.saveDocumentsAndDecision(data.data.id);
          this.fetchRefundOpinionCalculationRecordByRefundRequest();
          this.openPopupMessage();
          this.condominiumComponent.setInitiate();
          this.dataModel.selectedNatureOfDeed = null;
          this.dataModel.selectedDate = null;
          this.dataModel.selectedPropertyType = null;
          this.dataModel.considerCurrentDate = null;
          this.dataModel.subPropertyType = null;
        });
    }

    if (this.refundRequest != true && (this.dataModel.selectedPropertyType == "land-with-building" || (this.selectedRecord != null && this.selectedRecord.propertyType == "land-with-building"))) {
      let dataReq = await this.dataProcess.landAndBuildingDataProcessToSave(
        data,
        this.dataModel,
        this.isUpdate
      );

      this.apiService.saveCalculationBuildingData(dataReq).subscribe(data => {
        this.panelNumber = 1;
        this.landBuildingComponent.saveDocumentsAndDecision(data.data.id);
        this.openPopupMessage();
        this.fetchCalculationRecordByRefNo();
        this.landBuildingComponent.setInitiate();
        this.dataModel.selectedNatureOfDeed = null;
        this.dataModel.selectedDate = null;
        this.dataModel.selectedPropertyType = null;
        this.dataModel.considerCurrentDate = null;
        this.dataModel.subPropertyType = null;
      });
    }

    if (this.refundRequest == true && (this.dataModel.selectedPropertyType == "land-with-building" || (this.selectedRecord != null && this.selectedRecord.propertyType == "land-with-building"))) {
      let dataReq = await this.dataProcess.landAndBuildingDataProcessToSave(data, this.dataModel, this.isUpdate);

      this.apiService.saveCalculationBuildingData(dataReq).subscribe(data => {
        this.panelNumber = 1;
        this.landBuildingComponent.saveDocumentsAndDecision(data.data.id);
        this.openPopupMessage();
        this.fetchRefundOpinionCalculationRecordByRefundRequest();
        this.landBuildingComponent.setInitiate();
        this.dataModel.selectedNatureOfDeed = null;
        this.dataModel.selectedDate = null;
        this.dataModel.selectedPropertyType = null;
        this.dataModel.considerCurrentDate = null;
        this.dataModel.subPropertyType = null;
      });
    }

    if (this.refundRequest != true && (this.dataModel.selectedPropertyType == "land" || (this.selectedRecord != null && this.selectedRecord.propertyType == "land"))) {
      let dataReq = await this.dataProcess.landDataProcessToSave(
        data,
        this.dataModel,
        this.isUpdate
      );

      this.apiService.saveCalculationBuildingData(dataReq).subscribe(data => {
        this.panelNumber = 1;
        this.landComponent.saveDocumentsAndDecision(data.data.id);
        this.fetchCalculationRecordByRefNo();
        this.openPopupMessage();
        this.landComponent.setInitiate();
        this.dataModel.selectedNatureOfDeed = null;
        this.dataModel.selectedDate = null;
        this.dataModel.selectedPropertyType = null;
        this.dataModel.considerCurrentDate = null;
        this.dataModel.subPropertyType = null;
      });
    }

    if (this.refundRequest == true && (this.dataModel.selectedPropertyType == "land" || (this.selectedRecord != null && this.selectedRecord.propertyType == "land"))) {
      let dataReq = await this.dataProcess.landDataProcessToSave(data, this.dataModel, this.isUpdate);

      this.apiService.saveRefundOpinionCalculationData(dataReq).subscribe(data => {
        this.panelNumber = 1;
        this.landComponent.saveDocumentsAndDecision(data.data.id);
        this.fetchRefundOpinionCalculationRecordByRefundRequest();
        this.openPopupMessage();
        this.landComponent.setInitiate();
        this.dataModel.selectedNatureOfDeed = null;
        this.dataModel.selectedDate = null;
        this.dataModel.selectedPropertyType = null;
        this.dataModel.considerCurrentDate = null;
        this.dataModel.subPropertyType = null;
      });
    }

    if (this.refundRequest != true && (this.dataModel.selectedPropertyType == "recreation" || (this.selectedRecord != null && this.selectedRecord.propertyType == "recreation"))) {
      let dataReq = data;

      this.apiService.saveCalculationBuildingData(dataReq).subscribe(data => {
        this.panelNumber = 1;
        this.reCreationComponent.saveDocumentsAndDecision(data.data.id);
        this.fetchCalculationRecordByRefNo();
        this.openPopupMessage();
        this.dataModel.selectedNatureOfDeed = null;
        this.dataModel.selectedDate = null;
        this.dataModel.selectedPropertyType = null;
        this.dataModel.considerCurrentDate = null;
        this.dataModel.subPropertyType = null;
      });
    }

    if (this.refundRequest == true && (this.dataModel.selectedPropertyType == "recreation" || (this.selectedRecord != null && this.selectedRecord.propertyType == "recreation"))) {
      let dataReq = data;

      this.apiService.saveCalculationBuildingData(dataReq).subscribe(data => {
        this.panelNumber = 1;
        this.reCreationComponent.saveDocumentsAndDecision(data.data.id);
        this.fetchRefundOpinionCalculationRecordByRefundRequest();
        this.openPopupMessage();
        this.dataModel.selectedNatureOfDeed = null;
        this.dataModel.selectedDate = null;
        this.dataModel.selectedPropertyType = null;
        this.dataModel.considerCurrentDate = null;
        this.dataModel.subPropertyType = null;
      });
    }
    // this.resetCalculationMainPage();
  }

  selectRow(index) {
    this.isUpdate = true;
    this.panelNumber = 2;
    this.dataModel.selectedNatureOfDeed = this.calculationList[
      index
    ].natureOfDeed;
    this.selectedRecord = this.calculationList[index];

    // Used to disable the save
    sessionStorage.setItem('disableSave', btoa(JSON.stringify(true)));
  }

  public closeMessageBox(): void {
    this.modalService.dismissAll();
  }

  openPopupMessage() {
    this.modalService.open(this.popupMigrated, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      backdrop: "static"
    });
  }

  redirectToNextComponent() {
    if(this.calculationList.length == 0) {
      this.notifier.notify('error', 'One or more calculations are required to proceed');
    } else {
      this.onPageCountChanged.emit(15);
    }
  }

  redirectBack() {
    this.onPageCountChanged.emit(10);
  }

  emitedDataFromExchange(data) {
    this.saveCalculationExchangeData(data);
  }

  async saveCalculationExchangeData(data) {
    this.saveData = null;
    let isUpdate = false;
    if (this.selectedRecord != null) {
      isUpdate = true;
    } else {
      isUpdate = false;
    }
    this.dataModel.selectedPropertyType = data.partyOne.type + "," + data.partyTwo.type;

    this.dataModel.selectedNatureOfDeed = "exchange";

    if (data.partyOne.type == "condominium") {
      let dataReq = await this.dataProcess.condominiumDataProcessToSave(
        data.partyOne.proceedData,
        this.dataModel,
        this.selectedRecord
      );

      if (this.saveData == null) {
        this.saveData = dataReq;
      } else {
        if (this.saveData.calculationCondominiums == undefined) {
          this.saveData.calculationCondominiums = dataReq.calculationCondominiums;
        } else {
          dataReq.calculationCondominiums.forEach(element => {
            this.saveData.calculationCondominiums.push(element);
          });
        }
      }
    }

    if (data.partyTwo.type == "condominium") {
      let dataReq = await this.dataProcess.condominiumDataProcessToSave(
        data.partyTwo.proceedData,
        this.dataModel,
        this.selectedRecord
      );
      if (this.saveData == null) {
        this.saveData = dataReq;
      } else {
        // dataReq.calculationCondominiums.forEach(element => {
        //   this.saveData.calculationCondominiums.push(element);
        // });
        if (this.saveData.calculationCondominiums == undefined) {
          this.saveData.calculationCondominiums =
            dataReq.calculationCondominiums;
        } else {
          dataReq.calculationCondominiums.forEach(element => {
            this.saveData.calculationCondominiums.push(element);
          });
        }
      }
    }

    if (data.partyOne.type == "land-with-building") {
      let dataReq = await this.dataProcess.landAndBuildingDataProcessToSave(
        data.partyOne.proceedData,
        this.dataModel,
        this.selectedRecord
      );
      if (this.saveData == null) {
        this.saveData = dataReq;
      } else {
        // dataReq.calculationLandBuilding.forEach(element => {
        //   this.saveData.calculationLandBuilding.push(element);
        // });

        if (this.saveData.calculationLandBuilding == undefined) {
          this.saveData.calculationLandBuilding =
            dataReq.calculationLandBuilding;
        } else {
          dataReq.calculationLandBuilding.forEach(element => {
            this.saveData.calculationLandBuilding.push(element);
          });
        }
      }
    }
    if (data.partyTwo.type == "land-with-building") {
      let dataReq = await this.dataProcess.landAndBuildingDataProcessToSave(
        data.partyTwo.proceedData,
        this.dataModel,
        this.selectedRecord
      );

      if (this.saveData == null) {
        this.saveData = dataReq;
      } else {
        // dataReq.calculationLandBuilding.forEach(element => {
        //   this.saveData.calculationLandBuilding.push(element);
        // });

        if (this.saveData.calculationLandBuilding == undefined) {
          this.saveData.calculationLandBuilding =
            dataReq.calculationLandBuilding;
        } else {
          dataReq.calculationLandBuilding.forEach(element => {
            this.saveData.calculationLandBuilding.push(element);
          });
        }
      }
    }

    if (data.partyOne.type == "land") {
      let dataReq = await this.dataProcess.landDataProcessToSave(
        data.partyOne.proceedData,
        this.dataModel,
        this.selectedRecord
      );
      if (this.saveData == null) {
        this.saveData = dataReq;
      } else {
        if (this.saveData.calculationLand == undefined) {
          this.saveData.calculationLand = dataReq.calculationLand;
        } else {
          if (this.selectedRecord != null) {
            this.saveData.calculationLand = dataReq.calculationLand;
          } else {
            dataReq.calculationLand.forEach(element => {
              this.saveData.calculationLand.push(element);
            });
          }
        }
      }
    }

    if (data.partyTwo.type == "land") {
      let dataReq = await this.dataProcess.landDataProcessToSave(
        data.partyTwo.proceedData,
        this.dataModel,
        this.selectedRecord
      );
      if (this.saveData == null) {
        this.saveData = dataReq;
      } else {
        if (this.saveData.calculationLand == undefined) {
          this.saveData.calculationLand = dataReq.calculationLand;
        } else {
          if (this.selectedRecord != null) {
            this.saveData.calculationLand = dataReq.calculationLand;
          } else {
            dataReq.calculationLand.forEach(element => {
              this.saveData.calculationLand.push(element);
            });
          }
        }
      }
    }

    // Implement by prashan
    if(data.partyOneValue > data.partyTwoValue)
    {
      this.saveData.stampDuty = data.stampDutyForm.get("stampDuty").value;
      this.saveData.partyTwoStampDuty = 0;
    }
    else
    {
      this.saveData.stampDuty = 0;
      this.saveData.partyTwoStampDuty = data.stampDutyForm.get("stampDuty").value;
    }
    this.saveData.valueOfTheProperty = data.partyOneValue;
    this.saveData.partyTwoValueOfTheProperty = data.partyTwoValue;
    // end

    // this.saveData.stampDuty = data.stampDutyForm.get("stampDuty").value;
    this.saveData.roundOffValue = data.stampDutyForm.get("roundOffValue").value;
    // this.saveData.roundOffValue = this.saveData.roundOffValue ;
    // this.saveData.valueOfTheProperty = data.lowestValue;
    this.apiService
      .saveCalculationBuildingData(this.saveData)
      .subscribe(data => {
        this.panelNumber = 1;
        this.exchangeComponent.saveDocumentsAndDecision(data.data.id);
        this.fetchCalculationRecordByRefNo();
        this.selectedRecord = null;
        this.selectedItems = [];
        this.dataModel.selectedNatureOfDeed = null;
        this.dataModel.selectedDate = null;
        this.dataModel.selectedPropertyType = null;
        this.dataModel.considerCurrentDate = null;
        this.dataModel.subPropertyType = null;
        this.openPopupMessage();
      });
  }

  saveExchangeRecreationData(data) {
    this.apiService.saveCalculationBuildingData(data).subscribe(data => {
      this.panelNumber = 1;
      this.fetchCalculationRecordByRefNo();
      this.selectedRecord = null;
      this.selectedItems = [];
      this.dataModel.selectedNatureOfDeed = null;
      this.dataModel.selectedDate = null;
      this.dataModel.selectedPropertyType = null;
      this.dataModel.considerCurrentDate = null;
      this.dataModel.subPropertyType = null;

      this.openPopupMessage();
    });
  }
}
