import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { ProvinceService } from "src/app/service/province/province.service";
import { DistrictService } from "src/app/service/district/district.service";
import { Constant } from "src/app/util/constant";
import { FileService } from "src/app/service/file/file.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { UserService } from "src/app/service/user/user.service";
import { refundRequestUpdate } from "src/app/model/refundRequestUpdate";
import { NgForm } from "@angular/forms";
import { ValuationRequestService } from "src/app/service/valuation-request/valuation-request.service";
import { NotifierService } from "angular-notifier";
import { NotificationModel } from "src/app/model/notification-model";
import { ValuationRequest } from "src/app/model/valuation-request";
import { NotificationService } from "src/app/service/notification/notification.service";
import { PropertyHistoryModel } from "src/app/model/propertyHistory";
import { Property } from "src/app/model/property";
import { SurveyModel } from "src/app/model/survery";
import { RefundRequestModal } from "src/app/model/refundRequests";
import { RefundOpinionServiceService } from "src/app/service/RefundOpinionService/refund-opinion-service.service";
import { Role } from "src/app/model/role";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-asign-accessor-for-refund",
  templateUrl: "./asign-accessor-for-refund.component.html",
  styleUrls: ["./asign-accessor-for-refund.component.scss"],
})
export class AsignAccessorForRefundComponent implements OnInit {
  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("f", null)
  f: NgForm;

  provincelist: any;
  districtlist: any;
  provinceselectedstatus: boolean;
  userdata: any = [];
  refundData: any = [];
  dcommissionpermissiongranted: boolean;
  accessorpermissiongranted: boolean;
  dcommissionerSelectedStatus: boolean;
  districtselectedstatus: boolean;
  isReadOnly: boolean = false;

  userlist: any = [];
  dcommissionerlist: any = [];
  accessorlist: any = [];
  filteredcommissionerlist: any = [];
  filteredaccessorlist: any = [];

  selectFileSm = 0;
  typeID: any;
  type: any;
  deedNatureValue: any;
  userrolecode: string;
  deputyCommissionerAllocated: boolean;
  accessorAllocated: boolean;

  dataDone = false;
  noDocs = false;
  previewFileData: any;

  roleCode: string;

  public selectedProvinceCode: string;
  public selectedDistrictCode: string;

  public assignedprovinceCode: any;
  public assigneddistrictCode: any;

  currentYear: number = new Date().getFullYear();

  public model = new refundRequestUpdate("", "", "", "", "", "");
  public notificationModel = new NotificationModel("", "", "", "", "", "", "");
  public valuationRequest = new ValuationRequest();
  public valuationRequestHistory = new ValuationRequest();

  public propertyModel = new PropertyHistoryModel();
  properties: Property[];

  public surveyModel = new SurveyModel("");

  public valuationHistoryList: any = [];
  public audittraceList: any = [];

  refundRequest: RefundRequestModal[] = [];

  constructor(
    public provinceService: ProvinceService,
    public districtService: DistrictService,
    private fileService: FileService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public userService: UserService,
    public valuationRequestService: ValuationRequestService,
    private notifier: NotifierService,
    public notificationService: NotificationService,
    private refundService: RefundOpinionServiceService
  ) {
    this.provinceselectedstatus = true;
    this.districtselectedstatus = true;
    this.dcommissionpermissiongranted = false;
    this.accessorpermissiongranted = false;
    this.userdata = JSON.parse(atob(sessionStorage.getItem("userinfo")));
    this.refundData = JSON.parse(localStorage.getItem("refundRequest"));
    this.valuationRequestHistory = JSON.parse(
      sessionStorage.getItem("valuationRequest")
    );
    this.dcommissionerSelectedStatus = true;
    this.deputyCommissionerAllocated = false;
    this.accessorAllocated = false;
    this.assignedprovinceCode = 25;
  }

  ngOnInit() {
    this._prepare();
    this.checkForDCommissioner();
  }

  _prepare() {
    this.loadProvinceList();
    this.checkPermission();
    this.retrieveUserList();
    this.retrievedAssignedRefundRequest();
  }

  retrieveUserList() {
    this.userService.retrieveAssessorAndDComList().subscribe((data) => {
      this.userlist = data.data;
      this.dcommissionerlist = data.data.dComList;
      this.accessorlist = data.data.assessorList;
    });
  }

  retrievedAssignedRefundRequest() {
    this.refundService
      .refundRequestAssigned(parseInt(this.refundData))
      .subscribe((data) => {
        if (data.data !== null) {
          this.assignedprovinceCode = data.data.provinceCode;
          this.assigneddistrictCode = data.data.districtCode;
          this.model.dcommissionerId = data.data.dcommissionerId;
          this.model.acccessorId = data.data.accessorId;
          this.model.remark = data.data.remark;
          this.retrieveUserListFiltered(this.assigneddistrictCode);
          this.selectedProvince(this.assignedprovinceCode);
        } else {
          this.retrieveUserList();
          this.selectedProvince(this.assignedprovinceCode);
        }
      });
  }

  retrieveUserListFiltered(assigneddistrictCode: any) {
    this.userService.retrieveAssessorAndDComList().subscribe((data) => {
      this.userlist = data.data;
      this.dcommissionerlist = data.data.dComList;
      this.accessorlist = data.data.assessorList;
      this.filteredcommissionerlist = this.dcommissionerlist.filter(
        (i) => i.districtCode === assigneddistrictCode
      );
      this.filteredaccessorlist = this.accessorlist.filter(
        (i) => i.districtCode === assigneddistrictCode
      );
    });
  }

  checkPermission() {
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.dcommissionpermissiongranted = true;
        this.accessorpermissiongranted = true;
        this.userrolecode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.dcommissionpermissiongranted = true;
        this.accessorpermissiongranted = true;
        this.userrolecode = Constant.ROLE_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.dcommissionpermissiongranted = false;
        this.accessorpermissiongranted = true;
        this.isReadOnly = true;
        this.userrolecode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else {
        this.dcommissionpermissiongranted = false;
        this.accessorpermissiongranted = false;
      }
    }
  }

  loadProvinceList() {
    this.provinceService.getAll().subscribe((data) => {
      this.provincelist = data.data;
    });
  }

  selectedProvince(event) {
    const selectedProvince = this.provincelist.filter(
      (i) => i.id === Number(event)
    );
    if (JSON.stringify(selectedProvince) !== "[]") {
      const selectedProvinceCode = selectedProvince[0]["description"];
      var provincematcher = selectedProvinceCode.match(/\b(\w)/g);
      var provinceacronym = provincematcher.join("");
      this.selectedProvinceCode = provinceacronym;
    }
    this.districtService.searchDistrictDetails(event).subscribe((data) => {
      this.provinceselectedstatus = false;
      this.districtlist = data.data;
    });
  }

  selectedDistrict(event) {
    const selectedDistrict = this.districtlist.filter(
      (i) => i.id === Number(event)
    );
    if (JSON.stringify(selectedDistrict) !== "[]") {
      const selectedDistrictCode = selectedDistrict[0]["description"];
      var districtmatcher = selectedDistrictCode.match(/\b(\w)/g);
      var districtacronym = districtmatcher.join("");
      this.selectedDistrictCode = districtacronym;
    }
    this.filteredcommissionerlist = this.dcommissionerlist.filter(
      (i) => i.districtCode === event
    );
    this.filteredaccessorlist = this.accessorlist.filter(
      (i) => i.districtCode === event
    );
    this.districtselectedstatus = false;
  }

  previewFile(title) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService
      .loadDocumentsByRefundRequestId(
        localStorage.getItem("refundRequest"),
        title
      )
      .subscribe(
        (result) => {
          if (result) {
            const temp = [];
            temp.push(result.data);
            this.previewFileData = result.data;
            this.noDocs = false;
            this.dataDone = true;

            setTimeout(() => {
              this.spinner.hide();
            }, 2000);
          } else {
            this.spinner.hide();
            this.dataDone = true;
            this.noDocs = true;
          }
        },
        () => {
          this.dataDone = true;
          this.noDocs = true;
          this.spinner.hide();
        }
      );
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      "data:" + type + ";base64," + base64
    );
  }

  selectedDeputyCommissioner(event) {
    this.model.dcommissionerId = event;
    this.dcommissionerSelectedStatus = false;
    this.deputyCommissionerAllocated = true;
  }

  selectedAccessor(event) {
    const selectedUser = this.filteredaccessorlist.filter(
      (i) => i.id === event
    );
    this.refundData = JSON.parse(localStorage.getItem("refundRequest"));
    const valuationId = JSON.stringify(this.refundData);
    var strSequenceId = "" + valuationId;
    while (strSequenceId.length < 6) {
      strSequenceId = "0" + strSequenceId;
    }
    const referenceNumber =
      this.selectedProvinceCode +
      "/" +
      this.selectedDistrictCode +
      "/R/" +
      this.currentYear +
      "/" +
      selectedUser[0]["accessorCode"] +
      "/" +
      strSequenceId;
    this.model.referenceNumber = referenceNumber;
    this.model.acccessorId = event;
    this.accessorAllocated = true;
  }

  onSubmit() {

    this.refundData = JSON.parse(localStorage.getItem("refundRequest"));
    this.model.refundId = JSON.stringify(this.refundData);
    this.model.userRole = this.userrolecode;

    this.refundService.refundRequestUpdate(this.model).subscribe((data) => {
      if (data.data === true) {
        this.notifier.notify("success", "Successfully Assigned");
        this.router.navigate(["/main-dashboard-internal-user"]);
      }
    });
    if (sessionStorage.getItem("hasOpinion") == "true") {
      this.valuationRequestService
        .findByValuationRequestId(parseInt(sessionStorage.getItem("deedNo")))
        .subscribe((res) => {
          this.notificationModel.deedNature = "Refund-" + res.data.deedNature;
          this.notificationModel.subject = Constant.NEW_REFUND_APPLICATION;
          this.notificationModel.url = this.model.refundId;
          this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_REFUND

         if(this.model.acccessorId){
          this.notificationModel.userId = this.model.acccessorId;
          this.notificationModel.userRoleCode = this.filteredaccessorlist.filter(
            (data) => data.id === this.model.acccessorId
          )[0].userRoleCode;

          this.notificationService
            .createNotification(this.notificationModel)
            .subscribe((data) => {
              console.log(data);
            });
         }
         if(sessionStorage.getItem('username') != 'dcommissioner'){
          this.notificationModel.userId = this.model.dcommissionerId;
              this.notificationModel.userRoleCode = this.filteredcommissionerlist.filter(
                (data) => data.id === this.model.dcommissionerId
              )[0].userRoleCode;
              this.notificationService
                .createNotification(this.notificationModel)
                .subscribe((data) => {
                  console.log(data);
                });
         }

        });
    }

  }

  checkForDCommissioner() {
    if (this.dcommissionpermissiongranted === false) {
      this.dcommissionerSelectedStatus = false;
    }
  }

  public redirectNextComponent(): void {
    this.onPageCountChanged.emit(2);
  }

  rejectRequest() {
    // TODO: Implement code for Reject request functionality
    // This empty function is a temporary solution for getting the Production build
  }
}
