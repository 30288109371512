import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { District } from 'src/app/model/district';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DistrictNewService {

  private inspectionDistrictUrl = '/district-new';


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  createDistrict(DistrictModel:District):Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionDistrictUrl}`,DistrictModel)
  }

  getDistrict(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.inspectionDistrictUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  updateDistrict(id: number, DistrictModel:District): Observable<any> {
    return this.http.put(`${environment.api_url}${this.inspectionDistrictUrl}/${id}`, DistrictModel);
  }

  searchDistrict(keyWord:string,offset, limit):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.inspectionDistrictUrl +'/search?keyword='+ keyWord + '&offset=' + offset + '&limit=' + limit + '');
  }

  deleteDistrict(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.inspectionDistrictUrl}/${id}`);
  }

  getAllDistrictLists():Observable<any>{
    return this.http.get(`${environment.api_url}${this.inspectionDistrictUrl}/get-all-district`);
  }
}
