import {Injectable} from '@angular/core';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {environment} from 'src/environments/environment';
import {CompanyType} from '../../model/companyType';
import {tap, catchError} from 'rxjs/operators';
import {NotifierService} from 'angular-notifier';
import {ICompanyTypePage} from '../../model/interfaces/company-type-page';


@Injectable({
  providedIn: 'root'
})
export class CompanyTypeService {

  private companyTypeUrl = '/company-types';  // URL to web api

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  constructor(private http: HttpClient, private notifier: NotifierService) {
  }


  getCompanyTypes(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.companyTypeUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  getAll(): Observable<{ message: string, data: any }> {
    return this.http.get<{ message: string, data: any }>(environment.api_url + this.companyTypeUrl + '/all');

  }

  /* Search company type from the server*/
  searchCompanyType(keyword: string, offset: number, limit: number): Observable<ICompanyTypePage> {
    const params = {
      params: {
        offset: offset.toString(),
        limit: limit.toString(),
        keyword
      }
    };
    return this.http.get<any[]>(`${environment.api_url}${this.companyTypeUrl}/search`, params)
      .pipe(tap(_ => this.log('search company type')),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('get company types'))
      );
  }

  createCompanyType(companyType: CompanyType): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.companyTypeUrl}`,
      JSON.stringify(companyType), this.httpOptions)
      .pipe(tap(_ => this.log(`create company type=${companyType}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('company type create'))
      );
  }

  updateCompanyType(id: number, companyType: CompanyType): Observable<any> {
    return this.http.put(`${environment.api_url}${this.companyTypeUrl}/${id}`, companyType, this.httpOptions)
      .pipe(tap(_ => this.log(`update company type id=${companyType.id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('company type update'))
      );
  }

  /** DELETE: delete the company category on the server */
  deleteCompanyType(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.companyTypeUrl}/${id}`)
      .pipe(tap(_ => this.log(`update company type id=${id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('company type'))
      );
  }



  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */

  private handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      console.error(error.message);

      this.log(`${operation} failed: ${error.message}`);
      // notify
      this.notifier.notify('error', `${operation} failed`);
      return of(result as T);

    };
  }

  private log(message: string) {

  }
}
