import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { InspectionParkingTypeService } from 'src/app/service/inspection-master-data-service/inspection-parking-type/inspection-parking-type.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inspection-parking-type',
  templateUrl: './inspection-parking-type.component.html',
  styleUrls: ['./inspection-parking-type.component.scss']
})
export class InspectionParkingTypeComponent implements OnInit,OnDestroy {

  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  createParkingTypeForm: FormGroup;
  updateParkingTypeForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  propertyTypeList: any =[];
  activationButton: boolean;
  public createParkingTypeModel = new InspectionCommonDataModel(null,'','','','');
  public updateParkingTypeModel = new InspectionCommonDataModel(null,'','','','');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;
  ParkingTypes: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionParkingTypeService: InspectionParkingTypeService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createParkingTypeForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['',Validators.pattern(this.NoWhitespaceRegExp)],
    });
    this.updateParkingTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['',Validators.pattern(this.NoWhitespaceRegExp)],
    });

    // this.loadPropertyType();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }



  //create Parking Type - start

  get f() { return this.createParkingTypeForm.controls; }

  setParkingTypeModelToSave(){
    this.createParkingTypeModel.name = this.createParkingTypeForm.get('name').value;
    this.createParkingTypeModel.description = this.createParkingTypeForm.get('description').value;
    this.createParkingTypeModel.code = this.createParkingTypeForm.get('code').value;
    this.createParkingTypeModel.dataStatus = "ACTIVE";
  }

    //save Parking Type model
  saveParkingType(){
    this.inspectionParkingTypeService.getParkingType(0,this.totalItems).subscribe(res=>{
      const allParkingType = (res as any).data.content
      if (allParkingType.map((parking)=>String(parking.name.replace(/\s/g,""))).includes(String(this.createParkingTypeForm.get('name').value.replace(/\s/g,"")))){
        this.notifier.notify('error', 'Parking Type name '+ this.createParkingTypeForm.get('name').value +' is exist');
        return;
      }
    this.setParkingTypeModelToSave();
    this.sub.add(
      this.inspectionParkingTypeService.createParkingType(this.createParkingTypeModel).subscribe(data=>{
        this.notifier.notify('success', (data as any).message);
        this.updateTheTable();
        this.reset();
      })
      );
    })
    }

  reset(){
    this.createParkingTypeForm.reset()
  }
  //create Parking Type - end

  //update Parking Type - start

    //get update controllers
  get f1() { return this.updateParkingTypeForm.controls; }

    //set data To Parking Type Type model
  setParkingType(parking:any){
    this.updateParkingTypeForm.get('id').setValue(parking.id);
    this.updateParkingTypeForm.get('name').setValue(parking.name);
    this.updateParkingTypeForm.get('description').setValue(parking.description);
    this.updateParkingTypeForm.get('code').setValue(parking.code);

    if(parking.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateParkingTypeModel.id = this.updateParkingTypeForm.get('id').value;
    this.updateParkingTypeModel.name = this.updateParkingTypeForm.get('name').value;
    this.updateParkingTypeModel.description = this.updateParkingTypeForm.get('description').value;
    this.updateParkingTypeModel.code = this.updateParkingTypeForm.get('code').value;

    if(this.activationButton == true){
      this.updateParkingTypeModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateParkingTypeModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateParkingType(){
    this.inspectionParkingTypeService.getParkingType(0,this.totalItems).subscribe(res=>{
      const allParkingType = (res as any).data.content
      if (allParkingType.map((parking)=>String(parking.name.replace(/\s/g,""))).includes(String(this.updateParkingTypeForm.get('name').value.replace(/\s/g,"")))){
        this.notifier.notify('error', 'Parking Type name '+ this.updateParkingTypeForm.get('name').value +' is exist');
        return;
      }
    this.setDataToModel();
    this.sub.add(this.inspectionParkingTypeService.updateParkingType(this.updateParkingTypeModel.id, this.updateParkingTypeModel).subscribe(data=>{
      this.notifier.notify('success', (data as any).message);
      this.updateTheTable();
    }))
  })
  }

  //update Parking Type - end

//search and view table start
  pageable(i: number) {
    this.inspectionParkingTypeService.getParkingType((i-1),10).subscribe(res=>{
      this.ParkingTypes = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionParkingTypeService.getParkingType(0, 10)
      .subscribe(
        (res) => {
          this.ParkingTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search Parking Type Type
    searchParkingType(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionParkingTypeService.searchParkingType(this.keyword,0,10).subscribe(res=>{
          this.ParkingTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionParkingTypeService.deleteParkingType(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end

}
