import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { InspectionLandSutabilityService } from 'src/app/service/inspection-master-data-service/inspection-land-sutability/inspection-land-sutability.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inspection-land-sutability',
  templateUrl: './inspection-land-sutability.component.html',
  styleUrls: ['./inspection-land-sutability.component.scss']
})
export class InspectionLandSutabilityComponent implements OnInit,OnDestroy {

  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  createLandSutabilityForm: FormGroup;
  updateLandSutabilityForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  propertyTypeList: any =[];
  activationButton: boolean;
  public createLandSutabilityModel = new InspectionCommonDataModel(null,'','','','');
  public updateLandSutabilityModel = new InspectionCommonDataModel(null,'','','','');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;
  LandSutabilitys: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionLandSutabilityService: InspectionLandSutabilityService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createLandSutabilityForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });
    this.updateLandSutabilityForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });

    // this.loadPropertyType();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }



  //create Land Suitability - start

  get f() { return this.createLandSutabilityForm.controls; }

  setLandSutabilityModelToSave(){
    this.createLandSutabilityModel.name = this.createLandSutabilityForm.get('name').value;
    this.createLandSutabilityModel.description = this.createLandSutabilityForm.get('description').value;
    this.createLandSutabilityModel.code = this.createLandSutabilityForm.get('code').value;
    this.createLandSutabilityModel.dataStatus = "ACTIVE";
  }

    //save Land Suitability model
  saveLandSutability(){
    if(this.createLandSutabilityForm.valid){
      this.setLandSutabilityModelToSave();
      this.sub.add(
        this.inspectionLandSutabilityService.createLandSutability(this.createLandSutabilityModel).subscribe(data=>{
          this.notifier.notify('success', (data as any).message);
          this.updateTheTable();
          this.reset();
        })
        );

    }else{
      this.createLandSutabilityForm.markAllAsTouched();
    }

  }

  reset(){
    this.createLandSutabilityForm.reset()
  }
  //create Land Suitability - end

  //update Land Suitability - start

    //get update controllers
  get f1() { return this.updateLandSutabilityForm.controls; }

    //set data To Land Suitability Type model
  setLandSutability(subProperty:any){
    this.updateLandSutabilityForm.get('id').setValue(subProperty.id);
    this.updateLandSutabilityForm.get('name').setValue(subProperty.name);
    this.updateLandSutabilityForm.get('description').setValue(subProperty.description);
    this.updateLandSutabilityForm.get('code').setValue(subProperty.code);

    if(subProperty.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateLandSutabilityModel.id = this.updateLandSutabilityForm.get('id').value;
    this.updateLandSutabilityModel.name = this.updateLandSutabilityForm.get('name').value;
    this.updateLandSutabilityModel.description = this.updateLandSutabilityForm.get('description').value;
    this.updateLandSutabilityModel.code = this.updateLandSutabilityForm.get('code').value;

    if(this.activationButton == true){
      this.updateLandSutabilityModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateLandSutabilityModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateLandSutability(){
    this.setDataToModel();
    this.sub.add(this.inspectionLandSutabilityService.updateLandSutability(this.updateLandSutabilityModel.id, this.updateLandSutabilityModel).subscribe(data=>{
      this.notifier.notify('success', (data as any).message);
      this.updateTheTable();
    }))
  }

  //update Land Suitability - end

//search and view table start
  pageable(i: number) {
    this.inspectionLandSutabilityService.getLandSutability((i-1),10).subscribe(res=>{
      this.LandSutabilitys = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionLandSutabilityService.getLandSutability(0, 10)
      .subscribe(
        (res) => {
          this.LandSutabilitys = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search Land Suitability Type
    searchLandSutability(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionLandSutabilityService.searchLandSutability(this.keyword,0,10).subscribe(res=>{
          this.LandSutabilitys = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionLandSutabilityService.deleteLandSutability(this.selectedId).subscribe(data=>{

        this.notifier.notify('success', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end
}
