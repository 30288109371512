import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LocalAuthorityService } from 'src/app/service/local-authority/local-authority.service';
import { LocalAuthrority } from 'src/app/model/local-authrority';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/service/data.service';
import {SubSink} from 'subsink';
@Component({
  selector: 'app-add-local-authority',
  templateUrl: './add-local-authority.component.html',
  styleUrls: ['./add-local-authority.component.scss']
})
export class AddLocalAuthorityComponent implements OnInit, OnDestroy {

  addLocalAuthorityForm: FormGroup;
  private sub = new SubSink();

  constructor(private localAuthorityService: LocalAuthorityService,
              private formBuilder: FormBuilder,
              private notifier: NotifierService,
              private dataService: DataService) { }

  ngOnInit() {
    this.addLocalAuthorityForm = this.formBuilder.group({
      localAuthorityNo: ['', [Validators.required]],
      localAuthority: ['', [Validators.required]]
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  saveLocalAuthority() {
    if (this.addLocalAuthorityForm.valid) {

      const localAuthority = new LocalAuthrority();

      localAuthority.localAuthorityNo = this.getLocalAuthorityNo();
      localAuthority.localAuthority = this.getLocalAuthorityName();

      this.sub.add(this.localAuthorityService.createLocalAuthrority(localAuthority)
        .subscribe(response => {
          this.dataService.updateLocalAuthority(true);
          this.notifier.notify('success', (response as any).message);
        }));
    }
  }
  getLocalAuthorityName() {
    return this.addLocalAuthorityForm.get('localAuthority').value;
  }
  getLocalAuthorityNo() {
    return this.addLocalAuthorityForm.get('localAuthorityNo').value;
  }

}
