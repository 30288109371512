import { Component, OnInit } from '@angular/core';
import {ValuationRequestCount} from '../../../model/valuation_request_count';
import {NotificationModelUpdate} from '../../../model/notification-model-update';
import {DashboardService} from '../../../service/dashboard/dashboard.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Constant} from '../../../util/constant';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-dashboard-appeal',
  templateUrl: './dashboard-appeal.component.html',
  styleUrls: ['./dashboard-appeal.component.scss']
})
export class DashboardAppealComponent implements OnInit {

  public model = new ValuationRequestCount('', '', '');

  public notificationModelUpdate = new NotificationModelUpdate();

  refundNotificationCode = Constant.NOTIFICATION_CATEGORY_REFUND
  opinionNotificationCode = Constant.NOTIFICATION_CATEGORY_OPINION
  appealNotificationCode = Constant.NOTIFICATION_CATEGORY_APPEAL
  rimNotificationCode = Constant.NOTIFICATION_CATEGORY_RIM

  pendingCount: number;
  completedCount: number;
  rejectedCount: number;
  inprogressCount: number;
  pendingPaymentCount: number;
  completedPaymentCount: number;
  furtherRequirementCount: number;
  completedApplicationCount: number;

  numberOfNotifications: number;
  userName: string;
  selectedComponent: string;

  userdata: any = [];
  userrolecode: string;

  notifications: any = [];

  constructor(
    private dashboardService: DashboardService,
    private rout: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this._prepare();
    this.userName = sessionStorage.getItem('username');
  }

  _prepare() {
    this.obtainUserData();
    this.listNotifications();
  }

  obtainUserData() {
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.model.userId = this.userdata.id;
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.userrolecode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.userrolecode = Constant.ROLE_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.userrolecode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.userrolecode = Constant.ROLE_ACCESSOR;
        break;
      }
    }
    this.newApplicationCount();
    this.inprogressApplicationCount();
    this.rejectedCountChange();
    this.completedCountChange();
    this.pendingPaymentCountChange();
    this.completedPaymentCountChange();
    this.completedApplicationCountChange();
  }

  newApplicationCount() {
    this.model.valuationStatus = Constant.VALUATION_REQUEST_PENDING_CODE;
    this.model.userRole = this.userrolecode;
    this.dashboardService.getRequestInternalAppealUserCount(this.model).subscribe(value => {
      this.pendingCount = value;
    });
  }

  inprogressApplicationCount() {
    this.model.valuationStatus = Constant.VALUATION_REQUEST_INPROGRESS_CODE;
    this.model.userRole = this.userrolecode;
    this.dashboardService.getRequestInternalAppealUserCount(this.model).subscribe(value => {
      this.inprogressCount = value;
    });
  }

  rejectedCountChange() {
    this.model.valuationStatus = Constant.VALUATION_REQUEST_REJECTED_CODE;
    this.model.userRole = this.userrolecode;
    this.dashboardService.getRequestInternalAppealUserCount(this.model).subscribe(value => {
      this.rejectedCount = value;
    });
  }

  completedCountChange() {
    this.model.valuationStatus = Constant.VALUATION_REQUEST_COMPLETED_CODE;
    this.model.userRole = this.userrolecode;
    this.dashboardService.getRequestInternalAppealUserCount(this.model).subscribe(value => {
      this.completedCount = value;
    });
  }

  pendingPaymentCountChange() {
    this.model.valuationStatus = Constant.VALIATION_PAYMENT_PENDING;
    this.model.userRole = this.userrolecode;
    this.dashboardService.getRequestInternalAppealUserCount(this.model).subscribe(value => {
      this.pendingPaymentCount = value;
    });
  }

  completedPaymentCountChange() {
    this.model.valuationStatus = Constant.VALIATION_PAYMENT_COMPLETED;
    this.model.userRole = this.userrolecode;
    this.dashboardService.getRequestInternalAppealUserCount(this.model).subscribe(value => {
      this.completedPaymentCount = value;
    });
  }

  completedApplicationCountChange() {
    this.model.valuationStatus = Constant.VALIATION_COMPLETED_APPLICATION;
    this.model.userRole = this.userrolecode;
    this.dashboardService.getRequestInternalAppealUserCount(this.model).subscribe(value => {
      this.completedApplicationCount = value;
    });
  }


  clickGift() {
    this.selectedComponent = 'GIFT';
    sessionStorage.setItem('selectedType', this.selectedComponent);
    this.router.navigate(['/new-application/gift']);
  }

  clickTransfer() {
    this.selectedComponent = 'Transfer';
    sessionStorage.setItem('selectedType', this.selectedComponent);
    this.router.navigate(['/new-application/transfer']);
  }

  clickExchange() {
    this.selectedComponent = 'Exchange';
    sessionStorage.setItem('selectedType', this.selectedComponent);
    this.router.navigate(['/new-application/exchange']);
  }

  listNotifications() {
    this.dashboardService.getNotificationList().subscribe(
      data => {
        this.notifications = data.data;
        this.numberOfNotifications = this.notifications.length;
        console.log(data);
      }
    );
  }

  updateNotification(notificationId) {
    this.notificationModelUpdate.notificationId = notificationId;

    this.dashboardService.updateNotification(this.notificationModelUpdate).subscribe(
      data => {
        console.log(data);
      }
    );
  }

  logout(){
    this.authService.logout();
  }
}
