import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Constant} from '../../../../../../util/constant';
import {AppealService} from '../../../../../../service/appeal/appeal.service';
import {SubSink} from 'subsink';
import {Router} from '@angular/router';

@Component({
  selector: 'app-appeal-opinion-information',
  templateUrl: './appeal-opinion-information.component.html',
  styleUrls: ['./appeal-opinion-information.component.scss']
})
export class AppealOpinionInformationComponent implements OnInit, OnDestroy {
  @Input()
  valuationRequestId: number;
  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();

  private sub = new SubSink();
  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];
  noResults: boolean = false;

  appealrequestlist: any = [];
  appealRequests: any;

  private mywindow;

  constructor(public appealService: AppealService, public router: Router) {
    if (this.mywindow) {
      this.mywindow.close();
    }
  }

  ngOnInit() {
    this._prepare();
  }

  _prepare() {
    this.loadAppealList();
  }

  public redirectBackComponent(): void {
    this.onPageCountChanged.emit(2);
  }

  public redirectComponent(): void {
    this.onPageCountChanged.emit(9);
  }

  loadAppealList() {
    this.appealService.previousAppealList(this.valuationRequestId).subscribe(
      data => {
        // this.appealrequestlist = data.data.content;
        this.appealrequestlist = data.data;
        console.log(data)
        if(data.data.length === 0){
          this.noResults = true;
        }else{
          this.noResults = false;
        }
        // if (Object.keys(this.appealRequests).length === 0) {
        //   this.noResults = true;
        // } else {
        //   this.noResults = false;
        //   // this.paginationButtonCounter(data.data.totalPages);
        //   // this.numOfPages = data.data.totalPages;
        // }
      }
    );
  }

  obtainFileNumber(fileNumber, valuationRequestStatus) {
    localStorage.setItem('fileNumberFormat', fileNumber);
  }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

  getPage(pageNo: number) {
    this.selectedPageNo = pageNo;
    this.sub.add(this.appealService.searchAppeal(
      this.valuationRequestId.toString(), this.selectedPageNo, this.limit).subscribe(
      (res) => {
        this.appealRequests = res.data.content;
        if (Object.keys(this.appealRequests).length === 0) {
          this.noResults = true;
        }
        this.paginationButtonCounter(res.data.totalPages);
        this.numOfPages = res.data.totalPages;
      }));
  }

  viewPreviousData(id: any) {
    if (this.mywindow) {
      this.mywindow.close();
    }
    const url = window.location.origin + '/view-internal-user-application/' + id;
    this.mywindow = window.open(url.toString(), 'lpopp', 'resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=1200, height=600, left=60, top=100');
    if (this.mywindow) {
      this.mywindow.focus();
    }
  }

  ngOnDestroy() {
    if (this.mywindow) {
      this.mywindow.close();
    }
  }

}
