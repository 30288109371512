import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {CompanyCategoryService} from '../../../../../../../service/company-category/company-category.service';
import {NotifierService} from 'angular-notifier';
import {SubSink} from 'subsink';

@Component({
  selector: 'app-delete-company-category',
  templateUrl: './delete-company-category.component.html',
  styleUrls: ['./delete-company-category.component.scss']
})
export class DeleteCompanyCategoryComponent implements OnInit, OnDestroy {

  private sub = new SubSink();
  private companyCategoryId: number;
  @Output() companyCategoryDeleted = new EventEmitter<boolean>();

  constructor(private companyCategoryService: CompanyCategoryService,
              private notifier: NotifierService) { }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  deleteCompanyCategory() {
    this.sub.add(this.companyCategoryService.deleteCompanyCategory(this.companyCategoryId)
      .subscribe(response => {
        this.updateTheView(true);
        this.notifier.notify( 'success', (response as any).message);
      }));
  }

  setCompanyCategoryId(id: number) {
    this.companyCategoryId = id;
  }

  updateTheView(deleted: boolean) {
    this.companyCategoryDeleted.emit(deleted);
  }
}
