export class passwordPolicy{

  id:number;
  minLength:string;
  maxLength:string;
  minNumaric:string;
  maxNumaric:string;
  minSpecialChar:string;
  maxSpecialChar:string;
  minUpperChar:string;
  createDate:string;
  dataStatus:string;

  constructor(
    id:number,
    minLength:string,
    maxLength:string,
    minNumaric:string,
    maxNumaric:string,
    minSpecialChar:string,
    maxSpecialChar:string,
    minUpperChar:string,
    createDate:string,
    dataStatus:string,
  ){
    this.id = id;
    this.minLength = minLength;
    this.maxLength = maxLength;
    this.minNumaric = minNumaric;
    this.maxNumaric = maxNumaric;
    this.minSpecialChar = minSpecialChar;
    this.maxSpecialChar = maxSpecialChar;
    this.minUpperChar = minUpperChar;
    this.createDate = createDate;
    this.dataStatus = dataStatus;
  }
}
