import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SubSink} from 'subsink';
import {ValuationRequestService} from '../../../../../../service/valuation-request/valuation-request.service';
import {ValuationRequest} from '../../../../../../model/valuation-request';
import {NotaryDetails} from '../../../../../../model/notary-details';

@Component({
  selector: 'app-payment-response',
  templateUrl: './payment-response.component.html',
  styleUrls: ['./payment-response.component.scss']
})
export class PaymentResponseComponent implements OnInit {

  paymentStatus: boolean;
  isCardOrBank: boolean;
  referenceNum: string;
  paymentGatewayName: string;
  convenienceFee: string;
  amount: string;
  date: string;
  paymentMethod: string;
  totalAmount: number;
  requestID: string;

  notaryId: string;
  notaryNumber: string;
  notaryName: string;
  officialContact: string;
  personalContact: string;
  notaryDetails: NotaryDetails;

  private sub = new SubSink();

  constructor(private route: ActivatedRoute,  private  router: Router,
              private valuationRequestService: ValuationRequestService) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (this.decodeBase64(params.paymentMethod) === 'Online-Payment') {
        this.isCardOrBank = true;
        this.referenceNum = this.decodeBase64(params.transactionRefNo);
        this.paymentGatewayName = this.decodeBase64(params.paymentGatewayName);
        this.convenienceFee = this.decodeBase64(params.convenienceFee);
        this.amount = this.decodeBase64(params.amount);
        this.date = this.decodeBase64(params.date);
        this.getReferenceNumber(params.requestId);
        this.getNotary(this.decodeBase64(params.userId));
        this.paymentMethod = this.decodeBase64(params.paymentMethod);
        if (this.decodeBase64(params.transactionStatus) === 'true') {
          this.paymentStatus = true;
          this.totalAmount = Number(this.convenienceFee) + Number(this.amount);
        } else {
          this.paymentStatus = false;
        }
      }

      if (this.decodeBase64(params.paymentMethod) === 'Bank-Payment') {
        this.isCardOrBank = true;
        this.referenceNum = this.decodeBase64(params.transactionRefNo);
        this.totalAmount = Number(this.decodeBase64(params.amount));
        this.date = this.decodeBase64(params.date);
        this.getReferenceNumber(params.requestId);
        this.getNotary(this.decodeBase64(params.userId));
        this.paymentMethod = this.decodeBase64(params.paymentMethod);
        this.paymentStatus = true;
      }

      if (this.decodeBase64(params.paymentMethod) === 'Cash-Payment') {
        this.isCardOrBank = false;
        this.referenceNum = this.decodeBase64(params.transactionRefNo);
        this.totalAmount = Number(this.decodeBase64(params.amount));
        this.date = this.decodeBase64(params.date);
        this.getReferenceNumber(params.requestId);
        this.getNotary(this.decodeBase64(params.userId));
        this.paymentMethod = this.decodeBase64(params.paymentMethod);
        this.paymentStatus = true;
      }
    });

    // this.getNotary();

    // transactionRefNo
    // paymentGatewayName
    // convenienceFee
  }

  decodeBase64(encriptedData: string): string {
    return atob(encriptedData);
  }

  getReferenceNumber(requestId: string) {
    this.requestID = '#VR' + this.decodeBase64(requestId).padStart(10, '0');
  }

  print() {
    window.print();
  }

  payAgain() {
    this.router.navigate(['/view-assessment/all-request']);
  }

  getNotary(notaryUserID: string) {
    this.sub.add(this.valuationRequestService.getNotaryDetails(notaryUserID)
        .subscribe((res) => {
          this.notaryDetails = res.data;
          console.log(this.notaryDetails);

          this.notaryId = this.notaryDetails.id;
          this.notaryNumber = this.notaryDetails.registrationNumber;
          this.notaryName = this.notaryDetails.initials;
          this.officialContact = this.notaryDetails.officePhone;
          this.personalContact = this.notaryDetails.personalMobile;
          // alert(this.notaryId + this.notaryNumber + this.notaryName + this.officialContact + this.personalContact);
        }));
  }
}
