import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ValuationRequest } from "src/app/model/valuation-request";
import { ValuationRequestList } from "src/app/model/valuation_request_list";
import { RefundOpinionServiceService } from "src/app/service/RefundOpinionService/refund-opinion-service.service";
import { ValuationRequestService } from "src/app/service/valuation-request/valuation-request.service";
import { Constant } from "src/app/util/constant";
import { SubSink } from "subsink";

@Component({
  selector: "app-view-rejected-refund-application",
  templateUrl: "./view-rejected-refund-application.component.html",
  styleUrls: ["./view-rejected-refund-application.component.scss"],
})
export class ViewRejectedRefundApplicationComponent implements OnInit {
  // pagination
  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];
  searchKeyword = "";
  nextPage: number;

  @Output() pendingRequestCount = new EventEmitter<number>();
  valuationRequests: any;
  filteredValuationRequests = [];
  noResults = false;
  exchangeId: number;
  valuation: ValuationRequest;
  private sub = new SubSink();

  public model = new ValuationRequestList("", "", "", "", "", "");
  searchKeywordDeed: any;
  searchKeywordDate: any;

  constructor(
    private valuationRequestService: ValuationRequestService,
    private refundOpinionService: RefundOpinionServiceService,
    private router: Router
  ) {}

  ngOnInit() {
    this.selectedPageNo = 0;
    this.limit = 10;
    this.searchKeyword = "";
    this.nextPage = 0;

    this.getAllResults();
  }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

  getPage(pageNo: number) {
    this.selectedPageNo = pageNo;

    this.sub.add(
      this.refundOpinionService
        .getRefundRequestDetailsByUserIdStatus(
          "PENDING",
          this.selectedPageNo,
          this.limit
        )
        .subscribe((res) => {
          this.valuationRequests = res.data.content;
          if (Object.keys(this.valuationRequests).length === 0) {
            this.noResults = true;
          } else this.noResults = false;
          this.paginationButtonCounter(res.data.totalPages);
          this.numOfPages = res.data.totalPages;

          //Setting the pagination for the table No. of Requests
          if (this.selectedPageNo != 0) {
            this.nextPage = 10 * this.selectedPageNo;
          } else {
            this.nextPage = 0;
          }
        })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  // search() {
  //   this.selectedPageNo = 0;
  //   this.limit = 10;
  //   if (this.searchKeyword.trim() !== "" && this.searchKeyword != null) {
  //     this.sub.add(
  //       this.refundOpinionService
  //         .searchValuationRequest(
  //           this.searchKeyword,
  //           "PENDING",
  //           this.selectedPageNo,
  //           this.limit,
  //           "EXTERNAL"
  //         )
  //         .subscribe((res) => {
  //           this.valuationRequests = res.data.content;
  //           console.log(res.data);
  //           if (Object.keys(this.valuationRequests).length === 0) {
  //             this.noResults = true;
  //           } else this.noResults = false;
  //           this.paginationButtonCounter(res.data.totalPages);
  //           this.numOfPages = res.data.totalPages;
  //           this.pendingRequestCount.emit(res.data.totalElements);
  //         })
  //     );
  //   } else {
  //     this.getAllResults();
  //   }
  // }

  // searchByString() {
  //   this.selectedPageNo = 0;
  //   this.limit = 10;
  //   this.model.valuationStatus = Constant.VALUATION_REQUEST_PENDING_CODE;

  //   if (
  //     this.searchKeywordDeed.trim() !== "" &&
  //     this.searchKeywordDeed != null
  //   ) {
  //     this.sub.add(
  //       this.refundOpinionService
  //         .searchValuationRequestByString(
  //           this.searchKeywordDeed,
  //           this.model.valuationStatus,
  //           0,
  //           this.limit,
  //           "EXTERNAL"
  //         )
  //         .subscribe((res) => {
  //           this.valuationRequests = res.data.content;
  //           if (Object.keys(this.valuationRequests).length === 0) {
  //             this.noResults = true;
  //           } else this.noResults = false;
  //           this.paginationButtonCounter(res.data.totalPages);
  //           this.numOfPages = res.data.totalPages;
  //         })
  //     );
  //   } else {
  //     this.getAllResults();
  //   }
  // }

  // searchDate() {
  //   this.model.offset = `0`;
  //   this.model.limit = "10";
  //   this.model.valuationStatus = Constant.VALUATION_REQUEST_PENDING_CODE;

  //   if (
  //     this.searchKeywordDate.trim() !== "" &&
  //     this.searchKeywordDate != null
  //   ) {
  //     this.sub.add(
  //       this.refundOpinionService
  //         .searchValuationRequestByDate(
  //           this.searchKeywordDate,
  //           this.model.valuationStatus,
  //           parseInt(this.model.offset),
  //           parseInt(this.model.limit),
  //           "EXTERNAL",
  //           this.model.userId,
  //           this.model.userType
  //         )
  //         .subscribe((res) => {
  //           this.valuationRequests = res.data.content;
  //           if (Object.keys(this.valuationRequests).length === 0) {
  //             this.noResults = true;
  //           } else this.noResults = false;
  //           this.paginationButtonCounter(res.data.totalPages);
  //           this.numOfPages = res.data.totalPages;
  //         })
  //     );
  //   } else {
  //     this.getAllResults();
  //   }
  // }

  // filterValuationRequests() {
  //   for (this.valuation of this.valuationRequests) {
  //     if (this.valuation.exchangeId !== null) {
  //       if (this.exchangeId !== this.valuation.exchangeId) {
  //         this.exchangeId = this.valuation.exchangeId;
  //         this.filteredValuationRequests.push(this.valuation);
  //       }
  //     } else {
  //       this.filteredValuationRequests.push(this.valuation);
  //     }
  //   }
  // }

  getAllResults() {
    this.sub.add(
      this.refundOpinionService
        .getRefundRequestDetailsByUserIdStatus(
          "PENDING",
          this.selectedPageNo,
          this.limit
        )
        .subscribe((res) => {
          this.valuationRequests = res.data.content;
          // this.filterValuationRequests();
          if (Object.keys(this.valuationRequests).length === 0) {
            this.noResults = true;
          } else {
            this.noResults = false;
          }
          this.paginationButtonCounter(res.data.totalPages);
          this.numOfPages = res.data.totalPages;
          this.pendingRequestCount.emit(res.data.totalElements);
        })
    );
  }

  openApplication(hasOpinion: boolean, opinionNo: number, refundId: string) {
    if (hasOpinion) {
      sessionStorage.setItem("hasOpinion", "true");
      sessionStorage.setItem("hasPreviousOpinion", "true");
      localStorage.setItem("opinionNo", opinionNo.toString());
      this.router.navigate(["/refund-application", opinionNo]);
    } else {
      sessionStorage.setItem("hasOpinion", "false");
      sessionStorage.setItem("hasPreviousOpinion", "false");
      this.router.navigate(["/refund-application"]);
    }
    localStorage.setItem("refundId", refundId);
    sessionStorage.setItem("refundRequestId", refundId);
    sessionStorage.setItem("viewRefundExternal", "true")
  }
}
