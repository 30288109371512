import { Component, OnInit } from '@angular/core';
import { Chart } from 'node_modules/chart.js';

@Component({
  selector: 'app-tax-officer-reimbursement-application',
  templateUrl: './tax-officer-reimbursement-application.component.html',
  styleUrls: ['./tax-officer-reimbursement-application.component.scss']
})
export class TaxOfficerReimbursementApplicationComponent implements OnInit {

  constructor() { }
  logout() {

  }
  ngOnInit() {

    const myChart = new Chart("targetPercentage3", {
      type: 'horizontalBar',
      data: {
        labels: ['1', '2', '3'],
        datasets: [{
          label: 'Target Percentage',
          data: [50, 30, 70],
          backgroundColor: [
            '#7821C0',
            '#F8981F',
            '#A52465'
          ]
        }]
      },
      options: {
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true,
              max: 100,
              fontColor: '#fff'
            },
            gridLines: {
              zeroLineColor: '#fff'
            }
          }],
          yAxes: [{
            display: false,
            gridLines: {
              zeroLineColor: '#fff'
            }
          }]
        },
        legend: {
          display: false
        }
      }
    });


    const pieChart = new Chart("pieChart3", {
      type: 'pie',
      data: {
        labels: ['Received Application', 'Accepted Application', 'Rejected Application', 'Issued Application', 'Pending Application'],
        datasets: [{
          label: 'Target Percentage',
          data: [60, 30, 10, 30, 20],
          backgroundColor: [
            '#1C436F',
            '#E74677',
            '#9C0E0E',
            '#F8981F',
            '#4C1A48'
          ]
        }]
      },
      options: {
        legend: {
          position: 'bottom',
        }
      }
    });


    const groupedBar = new Chart("groupedBarChart3", {
      type: 'bar',
      data: {
        labels: ["Received Application", "Accepted Application", "Issued Application", "Pending Application", "Rejected Application"],
        datasets: [{
          label: "1",
          backgroundColor: "#1C436F",
          data: [30, 70, 40, 30, 50]
        },
        {
          label: "2",
          backgroundColor: "#E74677",
          data: [90, 35, 50, 70, 45]
        },
        {
          label: "3",
          backgroundColor: "#F8981F",
          data: [50, 10, 80, 30, 40]
        },
        {
          label: "4",
          backgroundColor: "#A91F9E",
          data: [20, 40, 60, 80, 50]
        },
        {
          label: "5",
          backgroundColor: "#F94242",
          data: [95, 20, 30, 60, 70]
        }
        ]
      },
      options: {
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: '#fff'
            },
            gridLines: {
              zeroLineColor: '#fff'
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: '#fff'
            },
            gridLines: {
              zeroLineColor: '#fff'
            }
          }]
        },
        legend: {
          display: false
        }
      }
    });



    const bankAmountChart = new Chart("bankAmount3", {
      type: 'bar',
      data: {
        labels: ["People's Bank", "Bank Of Ceylon"],
        datasets: [{
          label: 'Target Percentage',
          data: [1100, 1300],
          backgroundColor: [
            '#3073BE',
            '#C71149'
          ]
        }]
      },
      options: {
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: '#fff'
            },
            gridLines: {
              zeroLineColor: '#fff'
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: '#fff'
            },
            gridLines: {
              zeroLineColor: '#fff'
            }
          }]
        },
        legend: {
          display: false
        }
      }
    });


    const marketValue = new Chart("marketValue3", {
      type: 'line',
      data: {
        labels: ["Date 1", "Date 2", "Date 3"],
        datasets: [
          {
          label: 'Date 1',
          data: [500, 700, 1200],
          borderColor: '#E5CF1A'
        },
        {
          label: 'Date 2',
          data: [0, 400, 900],
          borderColor: '#F376ED'
        },
        {
          label: 'Date 3',
          data: [100, 600, 300],
          borderColor: '#F3951F'
        }
      ]
      }
      ,
      options: {
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: '#fff'
            },
            gridLines: {
              zeroLineColor: '#fff'
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: '#fff'
            },
            gridLines: {
              zeroLineColor: '#fff'
            }
          }]
        },
        legend: {
          display: false
        }
      }
    });

  }

}
