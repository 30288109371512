import { Improvements } from "./improvements";
import { ValueOfTheLand } from "./landValues";
import { BuildingValues } from "./buildingValues";

export class FristSet {
  id: any = null;
  valueType: any = true;
  rentPerSqFt: any = null;
  extent: any = null;
  monthlyRent: any = null;
  annualRent: any = null;
  deductionPrecentage: any = null;
  deductionOutgoing: any = null;
  annualIncome: any = null;
  marketValue: any = null;
  yp: any = null;
  rateOfReturn: any =null;
  externalTotal: any = null;
  improvements: Improvements[] = [
    {
      id: null,
      description: null,
      extent: null,
      rentPerSqFt: null,
      value: null
    }
  ];
  total: any = 0;
  valueOfTheProperty: any = 0;
  valueOfTheLand: ValueOfTheLand[] = [
    {
      id: null,
      landExtend: null,
      perPerchValue: null,
      value: null
    }
  ];
  valueOfTheLandRemark: any = null;
  valueOfTheBuildings: BuildingValues[] = [
    {
      id: null,
      buildingNo: null,
      floorNo: null,
      extent: null,
      constructionCost: null,
      contractionCost: null
    }
  ];
  valueOfTheBuildingsRemark: any = null;
  deductionPrecentag: any = 0;
  depreciation: any = 0;
  depreciationContruction: any = 0;

  getFirstSet() {
    return this;
  }
}
