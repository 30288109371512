import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {SubSink} from 'subsink';
import {NotifierService} from 'angular-notifier';
import {BankService} from '../../../../../../service/bank/bank.service';

@Component({
  selector: 'app-delete-bank',
  templateUrl: './delete-bank.component.html',
  styleUrls: ['./delete-bank.component.scss']
})
export class DeleteBankComponent implements OnInit, OnDestroy {

  private bankId: number;
  private sub = new SubSink();

  @Output() bankDeleted = new EventEmitter<boolean>();



  constructor(private bankService: BankService, private notifier: NotifierService) { }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  deleteOffice() {
    this.sub.add(this.bankService.deleteBank(this.bankId)
      .subscribe(response => {

        this.updateTheView(true);
        this.notifier.notify('success', 'Successfully Deleted Bank');
      }));
  }

  updateTheView(deleted: boolean) {
    this.bankDeleted.emit(deleted);
  }

  setBankId(id: number) {
    this.bankId = id;
  }


}
