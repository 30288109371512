import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { Employee } from 'src/app/model/employee';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  private employeeUrl = "/employee";


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };
  constructor(private http: HttpClient, private notifier: NotifierService) { }


  createEmployee(employee:Employee): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.employeeUrl}`,employee)
  }

  getEmployeis(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.employeeUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  updateEmployee(id: number, employee: Employee): Observable<any> {
    return this.http.put(`${environment.api_url}${this.employeeUrl}/${id}`, employee);
  }

  getASelectedEmployee(id:number):Observable<any>{
    return this.http.get(`${environment.api_url}${this.employeeUrl}/get-employee/${id}`);
  }

  searchEmployee(keyWord:string,offset, limit):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.employeeUrl +'/search?keyword='+ keyWord + '&offset=' + offset + '&limit=' + limit + '');
  }
}
