import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SubSink } from 'subsink';
import { Constant } from 'src/app/util/constant';
import { ValuationRequest } from 'src/app/model/valuation-request';
import { ValuationRequestService } from 'src/app/service/valuation-request/valuation-request.service';
import { CheckBoxStatus } from 'src/app/model/checkBoxStatus';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-wizard-internal-user',
  templateUrl: './wizard-internal-user.component.html',
  styleUrls: ['./wizard-internal-user.component.scss']
})
export class WizardInternalUserComponent implements OnInit {
  selectSideNav = 1;
  private sub = new SubSink();
  valuationRequest: ValuationRequest;
  saveButtonFurtherRequirment = 'Submit Further Requirment';
  userdata: any = [];
  isReject:boolean;
  userRole:string;


  permissiongranted: boolean;

  // Subscription to monitor the navigation
  navigationSubscription;

  public checkBoxStatusModel = new CheckBoxStatus('','','','','','');

  public grantaccess: boolean;

  constructor(
    private route: ActivatedRoute,
    private valuationRequestService: ValuationRequestService,
    public router: Router,
    private notifier: NotifierService,
  ) {
    this.permissiongranted = false;
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.valuationRequest = new ValuationRequest();
    this.grantaccess = false;
    this.isReject = false;
    this.userRole="";
  }

  public changeComponent(page: any): void {
    this.selectSideNav = page;
  }

  public checkStatus(type:boolean) : void{
    if(type === true){
      this.getCheckboxStatus()
    }
  }

  checkPermission() {
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.permissiongranted = true;
        this.userRole = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.permissiongranted = true;
        this.userRole = Constant.ROLE_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.permissiongranted = true;
        this.userRole = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.permissiongranted = false;
        this.userRole = Constant.ROLE_ACCESSOR;
        this.selectSideNav = 2;
        break;
      } else {
        this.permissiongranted = false;
        this.selectSideNav = 2;
      }
    }
  }

  ngOnInit() {
    this._prepare();
    sessionStorage.removeItem('deedType');
  }

  _prepare() {
    this.checkPermission();
    const valuationRequestId = +this.route.snapshot.paramMap.get('id');
    this.valuationRequest.id = valuationRequestId;
    this.getValuationRequest(valuationRequestId);
  }


  getValuationRequest(valuationRequestId: number) {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(valuationRequestId)
      .subscribe((res) => {
        this.valuationRequest = res.data;
        localStorage.setItem('valuationRequestStatus', this.valuationRequest.valuationRequestStatus);

        if(this.userRole == Constant.ROLE_ACCESSOR){
          if(this.valuationRequest.accessorStatus == 'REJECTED'){
          this.selectSideNav = 0;
          this.isReject = true;
          }else{
              this.isReject = false;
          }
        }else if(this.userRole == Constant.ROLE_DEPUTY_COMMISSIONER){
          if(this.valuationRequest.dcommissionerStatus == 'REJECTED'){
          this.selectSideNav = 0;
          this.isReject = true;
          }else{
              this.isReject = false;
          }
        }else if(this.userRole == Constant.ROLE_COMMISSIONER){
          if(this.valuationRequest.commissionerStatus == 'REJECTED'){
          this.selectSideNav = 0;
          this.isReject = true;
          }else{
              this.isReject = false;
          }
        }

        sessionStorage.setItem('valuationRequest', JSON.stringify(this.valuationRequest));
        this.valuationRequest.id = valuationRequestId;
        this.getCheckboxStatus();
        console.log(res.data)
      }));
  }

  getCheckboxStatus() {
    this.valuationRequestService.checkboxValuationRequestStatus(this.valuationRequest.id).subscribe(
      data => {
        this.checkBoxStatusModel = data.data[0]
        if(this.valuationRequest.properties[0].buildings.length>0){
          if (this.checkBoxStatusModel.granter_status === '1' &&
          this.checkBoxStatusModel.grantee_status === '1' &&
          // this.checkBoxStatusModel.notary_status === '1' &&
          this.checkBoxStatusModel.building_status === '1' &&
          this.checkBoxStatusModel.property_status === '1') {
            this.grantaccess = true;
          } else {
            this.grantaccess = false;
          }
        } else{
          if (this.checkBoxStatusModel.granter_status === '1' &&
          this.checkBoxStatusModel.grantee_status === '1' &&
          // this.checkBoxStatusModel.notary_status === '1' &&
          this.checkBoxStatusModel.property_status === '1') {
            this.grantaccess = true;
            this.checkBoxStatusModel.building_status = '1';
          } else {
            this.grantaccess = false;
            this.checkBoxStatusModel.building_status = '1';
          }
        }

      }
    )
  }

  submitValuationRequestFurtherRequirment()
  {
    console.log(this.valuationRequest);
      this.saveButtonFurtherRequirment = 'Saving..'
      // this.valuationRequest.id = Number(localStorage.getItem('valuation_request_id'));

      this.sub.add(this.valuationRequestService.valuationRequestSubmitFurtherRequirement(this.valuationRequest)
          .subscribe(response => {
            // const data = response as any;
            // this.notifier.notify('success', 'Further requirement submitted successfully');
            // localStorage.removeItem('grantees');
            // localStorage.removeItem('granters');
            // localStorage.removeItem('deed');
            // localStorage.removeItem('assessment');
            // localStorage.removeItem('extend');
            // localStorage.removeItem('other');
            // localStorage.removeItem('loan');
            // localStorage.removeItem('building');
            // localStorage.removeItem('valuation_request_id');
            // window.location = data.data.encryptedRequest;
            window.location.href = '/main-dashboard-internal-user'

          }, (error => {
            this.saveButtonFurtherRequirment = 'Submit Further Requirment';
            this.notifier.notify('error', 'Please try again');
          })));

  }

  checkSubmitFurtherRequirementButtonStatus()
  {
    if(this.valuationRequest.valuationRequestStatus ===  Constant.VALUATION_REQUEST_FURTHER_REQUIREMENT_CODE)
    {
      return false;
    }
    else
    {
      if(this.userdata.roles[0].code === Constant.ROLE_ACCESSOR && this.valuationRequest.accessorStatus === Constant.VALUATION_REQUEST_FURTHER_REQUIREMENT_CODE)
      {
        return true;
      }
      else if(this.userdata.roles[0].code === Constant.ROLE_DEPUTY_COMMISSIONER && this.valuationRequest.dcommissionerStatus === Constant.VALUATION_REQUEST_FURTHER_REQUIREMENT_CODE)
      {
        return true;
      }
      else if(this.userdata.roles[0].code === Constant.ROLE_COMMISSIONER && this.valuationRequest.dcommissionerStatus === Constant.VALUATION_REQUEST_FURTHER_REQUIREMENT_CODE)
      {
        return true;
      }
      else
      {
        return false;
      }
    }
  }

}

