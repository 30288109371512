export class RefundCalculation {
  id: number;
  refundRequestId: string;
  stampDutyAmount: string;
  refundRequestAmount: string;
  actualPaidAmount: string;
  penaltyAmount: string;
  additionalStampDuty: string;
  totalRefundAmount: string;
  roundOffValue: string;
  collectionNumber: string;
  installmentCount: string;
  remark: string;
  taxOfficerId: string;

  constructor() {
  }
}
