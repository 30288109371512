import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalAuthrority } from 'src/app/model/local-authrority';
import { tap, catchError } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import {ILocalAuthrority} from '../../model/interfaces/local-authority';
import {ILocalAuthorityPage} from '../../model/interfaces/local-authority-page';


@Injectable({
  providedIn: 'root'
})
export class LocalAuthorityService {

  private localAuthorityUrl = '/local-authorities';  // URL to web api

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  getLocalAuthorities(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.localAuthorityUrl + '?offset=' + offset + '&limit=' + limit + '');
  }
  getAll(): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.localAuthorityUrl + '/all');
  }

  getLocalAuthoritiesByDistrictId(district_id:number):Observable<any>{
    return this.http.get(environment.api_url+this.localAuthorityUrl+'/get-local-authorities-by-district/'+district_id)
  }

  getAllByOrderByLocalAuthorityAsc(): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.localAuthorityUrl + '/all/local-authority-by-asc');
  }


  createLocalAuthrority(localAuthority: LocalAuthrority): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.localAuthorityUrl}`,
      JSON.stringify(localAuthority), this.httpOptions)
      .pipe(tap(_ => this.log(`update local authority create=${localAuthority}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('local authority create'))
      );
  }

  updateLocalAuthority(id: number, localAuthority: LocalAuthrority): Observable<any> {
    return this.http.put(`${environment.api_url}${this.localAuthorityUrl}/${id}`, localAuthority)
      .pipe(tap(_ => this.log(`update local authority  id=${localAuthority.id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
      catchError(this.handleError<any>('local authority update'))
    );
  }

  /** DELETE: delete the company category on the server */
  deleteLocalAuthority(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.localAuthorityUrl}/${id}`)
      .pipe(tap(_ => this.log(`update local authority category id=${id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
      catchError(this.handleError<any>('local authority'))
    );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      this.notifier.notify('error', `${operation} failed`);
      return of(result as T);
    };
  }
  private log(message: string) {

  }
  /* Search District from server*/
  searchLocalAuthority(keyword: string, offset: number, limit: number): Observable<ILocalAuthorityPage> {
    const params = {
      params: {
        offset: offset.toString(),
        limit: limit.toString(),
        keyword
      }
    };
    return this.http.get<any[]>(`${environment.api_url}${this.localAuthorityUrl}/search`, params)
      .pipe(tap(_ => this.log('search local authority')),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('get local authority'))
      );
  }



  searchLocalAuthorityByIdAndNo(keyword: number, offset: number, limit: number): Observable<ILocalAuthorityPage> {
    const params = {
      params: {
        offset: offset.toString(),
        limit: limit.toString(),
        keyword:keyword.toString()
        
      }
    };
    return this.http.get<any[]>(`${environment.api_url}${this.localAuthorityUrl}/searchByIdAndNo`,params)
      .pipe(tap(_ => this.log('search local authority')),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('get local authority'))
      );
  }

  searchLocalAuthorityById(id : number) : Observable<ILocalAuthorityPage>{
    return this.http.get<ILocalAuthorityPage[]>(`${environment.api_url}${this.localAuthorityUrl}/search/${id}`)
    .pipe(tap(_ => this.log('search local authority')),
      catchError(err => {
        this.notifier.notify('error', err);
        return throwError(err);
      }),
      catchError(this.handleError<any>('get local authority'))
    );
  }
  /* Search District from server*/
  // searchLocalAuthority(keyword: string): Observable<any> {
  //   return this.http.get<any[]>(`${environment.api_url}${this.localAuthorityUrl}/search`)
  //     .pipe(tap(_ => this.log('search local authority')),
  //       catchError(this.handleError<any>('get local authority'))
  //     );
  // }

}
