import {Component, OnInit, OnDestroy, Input, ViewChild, ElementRef} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {SubSink} from 'subsink';
import {Granter} from 'src/app/model/granter';
import {ProvinceService} from 'src/app/service/province/province.service';
import {CompanyCategoryService} from 'src/app/service/company-category/company-category.service';
import {CompanyTypeService} from 'src/app/service/company-type/company-type.service';
import {Company} from '../../../../../../../model/company';
import {CompanyCategory} from 'src/app/model/companyCategory';
import {FileUploadComponent} from '../../../file-upload/file-upload.component';
import {NotifierService} from 'angular-notifier';
import {ActivatedRoute} from '@angular/router';
import {Province} from '../../../../../../../model/province';
import {CompanyType} from '../../../../../../../model/companyType';
import {FileUploadGranterComponent} from '../../../file-upload-granter/file-upload-granter.component';
import {FutureDateValidator} from '../../../../../../validators/futuredate.validator';

@Component({
  selector: 'app-grantor',
  templateUrl: './grantor.component.html',
  styleUrls: ['./grantor.component.scss']
})
export class GrantorComponent implements OnInit, OnDestroy {

  // @ViewChild('fileUploadForm20', {static: false}) fileUploadForm20: FileUploadComponent;

  @ViewChild('fileUploadForm20', {static: false})
  fileUploadForm20: FileUploadGranterComponent;

  @ViewChild('modelCloseBtn', {static: false})
  modelCloseBtn: ElementRef;

  grantorCheck = false;
  @Input()
  granters: Granter[];
  @Input()
  updatedPaths = [];

  tempgranters: Granter[];
  isNewGranterBtnSelected: boolean;

  granterForm: FormGroup;
  provinces: [];
  provincesorderd: Province[];
  companyCategories: CompanyCategory[];
  companyType: CompanyType[];
  buttonSubmit = false;
  addButtonEnability = true;
  selectPaths = [];
  other: any;
  deletedIndex: number;
  granterIndex: number;
  OtherCompany = true;
  otherCompanyValue: any;
  activeUrl: string;
  isUpdate = false;
  maxDate: any;
  isGranterIndividual = true;
  isGrantorCompany = true;
  // Validation
  nicPattern = '^([0-9]{9}[x|X|v|V]|[0-9]{12})$';
  phoneNumberPattern = '^(?:0|94|\\+94|0094)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|91)(0|2|3|4|5|7|9)|7[0-9]\\d)\\d{6}$';
  emailPattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
  addressPattern = /^[ a-zA-Z0-9./,-/']*$/;
  cityPattern = '^[ a-zA-Z0-9-.]*$';
  companyRegNoPattern = '^[ a-zA-Z0-9-/#]*$';
  onlyTextPattern = '^[ a-zA-Z]*$';
  onlyNumberPattern = '^[ 0-9]*$';
  nameWithInitialsPattern = '^[ a-zA-Z.]*$';

  private sub = new SubSink();

  // grantorFiles: any[];

  constructor(private provinceService: ProvinceService, private formBuilder: FormBuilder,
              private companyCategoryService: CompanyCategoryService,
              private activatedRoute: ActivatedRoute,
              private companyTypeService: CompanyTypeService,
              private notifier: NotifierService) {
    this.activeUrl = this.activatedRoute.snapshot.url[0].path;
  }

  get f() {
    return this.granterForm.controls;
  }

  get f2() {
    return this.granterForm.controls;
  }

  ngOnInit() {
    this.isNewGranterBtnSelected = false;
    this.tempgranters = [];

    this.granterForm = this.formBuilder.group({
      nameWithInitials: ['', [Validators.required, Validators.pattern(this.nameWithInitialsPattern)]],
      firstName: ['', [Validators.required, Validators.pattern(this.onlyTextPattern)]],
      lastName: ['', [Validators.required, Validators.pattern(this.onlyTextPattern)]],
      middleName: ['', [Validators.pattern(this.onlyTextPattern)]],
      addressLine1: ['', [Validators.required, Validators.pattern(this.addressPattern)]],
      addressLine2: ['', [Validators.pattern(this.addressPattern)]],
      city: ['', [Validators.required, Validators.pattern(this.cityPattern)]],
      province: ['', [Validators.required]],
      postalCode: ['', [Validators.pattern(this.onlyNumberPattern)]],
      nic: ['', [Validators.required, Validators.pattern(this.nicPattern)]],
      contactNo: ['', [Validators.required, Validators.pattern(this.phoneNumberPattern)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      // companyCategory: ['', [Validators.required]],
      companyCategory: [''],
      companyCategoryOther: ['', [Validators.required]],
      name: ['', [Validators.required]],
      addressLine1Company: ['', [Validators.required, Validators.pattern(this.addressPattern)]],
      addressLine2Company: ['', [Validators.pattern(this.addressPattern)]],
      cityCompany: ['', [Validators.required, Validators.pattern(this.cityPattern)]],
      provinceCompany: ['', [Validators.required]],
      postalCodeCompany: ['', [Validators.pattern(this.onlyNumberPattern)]],
      type: ['', [Validators.required]],
      // companyTypeOther: ['', [Validators.required]],
      // companyRegistrationNo: ['', [Validators.required, Validators.pattern(this.companyRegNoPattern)]],
      companyRegistrationNo: [''],
      // companyRegistrationDate: ['', [Validators.required]],
      companyRegistrationDate: [''],
      ownerName: ['', [Validators.required, Validators.pattern(this.nameWithInitialsPattern)]],
      ownerContactNum: ['', [Validators.required, Validators.pattern(this.phoneNumberPattern)]],
      // document: ['', [Validators.required]],
      nameWithInitialsCompanyContact: ['', [Validators.required, Validators.pattern(this.nameWithInitialsPattern)]],
      firstNameCompanyContact: ['', [Validators.required, Validators.pattern(this.onlyTextPattern)]],
      lastNameCompanyConatact: ['', [Validators.required, Validators.pattern(this.onlyTextPattern)]],
      middleNameCompanyConatact: ['', [Validators.pattern(this.onlyTextPattern)]],
      nicCompanyConatact: ['', [Validators.required, Validators.pattern(this.nicPattern)]],
      emailCompanyConatact: ['', [Validators.required, Validators.required, Validators.pattern(this.emailPattern)]],
      addressLine1CompanyContact: ['', [Validators.required, Validators.pattern(this.addressPattern)]],
      addressLine2CompanyConatact: ['', [Validators.pattern(this.addressPattern)]],
      cityCompanyConatact: ['', [Validators.required, Validators.pattern(this.cityPattern)]],
      provinceComapnyConatact: ['', [Validators.required]],
      contactdetmobileno: ['', [Validators.required, Validators.pattern(this.phoneNumberPattern)]],
      contactdetofficeno: ['', [Validators.required, Validators.pattern(this.phoneNumberPattern)]],
    }, {
      validator: FutureDateValidator('companyRegistrationDate')
    });
    this.granterForm.get('companyCategoryOther').disable();
    // this.createEmptyObject();
    this.sub.add(this.provinceService.getProvinces(0, 10).subscribe(
      (res) => {
        this.provinces = (res as any).data;
      }
    ));

    // get All provinces odered
    this.sub.add(this.provinceService.getProvincesOrderd().subscribe(
      (res) => {
        this.provincesorderd = (res as any).data;
      }
    ));

    // get All Company Categories
    this.sub.add(this.companyCategoryService.getAll().subscribe(
      res => {
        this.companyCategories = (res as any).data;
      }
    ));

    // get All company Type
    this.sub.add(this.companyTypeService.getAll().subscribe(
      res => {
        this.companyType = (res as any).data;
      }
    ));
    this.maxDate = new Date();
    // this.onChanges();
  }

  // onChanges(): void {
  //   this.granterForm.reset();
  // }


  // onChangeCategory() {
  //   if (this.granterForm.value.companyCategory === 'Other') {
  //     this.granterForm.get('companyCategoryOther').enable();
  //   } else {
  //     this.granterForm.patchValue({companyCategoryOther: null});
  //     this.granterForm.get('companyCategoryOther').disable();
  //   }
  // }
  addGranter() {
    if (this.checkFormValidity()) {
      if (this.isUpdate) {
        const granter = this.buildGranter();
        this.granters[this.granterIndex] = granter;
        this.tempgranters.push(granter);
        this.isNewGranterBtnSelected = true;
        localStorage.setItem('granters', JSON.stringify(this.granters));
        this.modelClose();
      } else {
        const granter = this.buildGranter();
        this.granters.push(granter);
        this.tempgranters.push(granter);
        this.isNewGranterBtnSelected = true;
        localStorage.setItem('granters', JSON.stringify(this.granters));
        this.modelClose();
      }
    } else {
      this.notifier.notify('error', 'Grantor Addition Failed due to Invalid Data');
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  setOtherVal(ev) {
    if (ev.target.value === 'Other') {
      this.granterForm.get('companyCategoryOther').enable();
      this.granterForm.get('companyCategoryOther').setValidators([Validators.required]);
      this.granterForm.get('companyCategoryOther').updateValueAndValidity()

    } else {
      this.granterForm.get('companyCategoryOther').disable();
      this.granterForm.get('companyCategoryOther').setValue('');
      this.granterForm.get('companyCategoryOther').clearValidators();
      this.granterForm.get('companyCategoryOther').updateValueAndValidity()
    }
  }

  refresh() {
    this.selectPaths = [];
    this.updatedPaths = [];
    this.grantorCheck = false;
    this.granterForm.reset();
  }

  removeIndividualGranter() {
    this.granters.splice(this.deletedIndex, 1);
    this.selectPaths = [];
    this.updatedPaths = [];
    this.notifier.notify('success', 'Grantor is removed successfully');
  }

  setForm20DocArrayor(images: File[]) {
    this.selectPaths = images;
  }

  setEveryChildComponentUploadingDocuments() {

    if (this.fileUploadForm20) {
      this.fileUploadForm20.outPutImagesSet();
    } else {
      this.selectPaths = this.updatedPaths;
    }
    // this.setGrantorDocsStorage();
  }

//   setGrantorDocsStorage() {
// let base64images=[];
//     for (let i = 0; i < this.selectPaths.length; i++) {
//       const file = this.selectPaths[i];
//         const reader = new FileReader();
//         reader.readAsDataURL(file);
//         reader.onload = (_event: any) => {
//           base64images.push(_event.currentTarget.result)
//         };
//
//     }
//     this.grantorFiles=base64images;
//   }

  buildGranter() {
    const granter = new Granter();
    if (this.grantorCheck === false) {
      granter.granterCompany = false;
      granter.isGrantorIndividual = true;
      granter.nameWithInitials = this.granterForm.get('nameWithInitials').value;
      granter.firstName = this.granterForm.get('firstName').value;
      granter.lastName = this.granterForm.get('lastName').value;
      granter.middleName = this.granterForm.get('middleName').value;
      granter.addressLine1 = this.granterForm.get('addressLine1').value;
      granter.addressLine2 = this.granterForm.get('addressLine2').value;
      granter.postalCode = this.granterForm.get('postalCode').value;
      granter.city = this.granterForm.get('city').value;
      granter.province = this.granterForm.get('province').value;
      granter.email = this.granterForm.get('email').value;
      granter.nic = this.granterForm.get('nic').value;
      granter.contactNo = this.granterForm.get('contactNo').value;
      return granter;
    } else {
      granter.granterCompany = true;
      granter.nameWithInitials = this.granterForm.get('nameWithInitialsCompanyContact').value;
      granter.firstName = this.granterForm.get('firstNameCompanyContact').value;
      granter.lastName = this.granterForm.get('lastNameCompanyConatact').value;
      granter.middleName = this.granterForm.get('middleNameCompanyConatact').value;
      granter.addressLine1 = this.granterForm.get('addressLine1CompanyContact').value;
      granter.addressLine2 = this.granterForm.get('addressLine2CompanyConatact').value;
      granter.city = this.granterForm.get('cityCompanyConatact').value;
      granter.province = this.granterForm.get('provinceComapnyConatact').value;
      granter.email = this.granterForm.get('emailCompanyConatact').value;
      granter.nic = this.granterForm.get('nicCompanyConatact').value;

      const company = new Company();
      company.name = this.granterForm.get('name').value;
      company.companyCategory = this.granterForm.get('companyCategory').value;
      company.companyCategoryOther = this.granterForm.get('companyCategoryOther').value;
      company.companyRegistrationDate = this.granterForm.get('companyRegistrationDate').value;
      company.companyRegistrationNo = this.granterForm.get('companyRegistrationNo').value;
      company.city = this.granterForm.get('cityCompany').value;
      company.province = this.granterForm.get('provinceCompany').value;
      company.postalCode = this.granterForm.get('postalCodeCompany').value;
      company.addressLine1 = this.granterForm.get('addressLine1Company').value;
      company.addressLine2 = this.granterForm.get('addressLine2Company').value;
      company.type = this.granterForm.get('type').value;
      company.ownerName = this.granterForm.get('ownerName').value;
      company.ownerContactNum = this.granterForm.get('ownerContactNum').value;
      company.companyEmail = this.granterForm.get('emailCompanyConatact').value;
      company.companyMobile = this.granterForm.get('contactdetmobileno').value;
      company.companyOfficeNo = this.granterForm.get('contactdetofficeno').value;

      granter.company = company;
      return granter;
    }
  }

  checkFormValidity(): boolean {
    if (this.grantorCheck) {
      this.granterForm.get('nameWithInitialsCompanyContact').markAsTouched();
      this.granterForm.get('firstNameCompanyContact').markAsTouched();
      this.granterForm.get('lastNameCompanyConatact').markAsTouched();
      this.granterForm.get('addressLine1CompanyContact').markAsTouched();
      this.granterForm.get('cityCompanyConatact').markAsTouched();
      this.granterForm.get('provinceComapnyConatact').markAsTouched();
      this.granterForm.get('emailCompanyConatact').markAsTouched();
      this.granterForm.get('nicCompanyConatact').markAsTouched();
      this.granterForm.get('companyCategory').markAsTouched();
      this.granterForm.get('companyCategoryOther').markAsTouched();
      this.granterForm.get('name').markAsTouched();
      this.granterForm.get('companyRegistrationDate').markAsTouched();
      this.granterForm.get('companyRegistrationNo').markAsTouched();
      this.granterForm.get('cityCompany').markAsTouched();
      this.granterForm.get('provinceCompany').markAsTouched();
      this.granterForm.get('addressLine1Company').markAsTouched();
      this.granterForm.get('ownerName').markAsTouched();
      this.granterForm.get('type').markAsTouched();
      this.granterForm.get('ownerContactNum').markAsTouched();
      this.granterForm.get('nameWithInitialsCompanyContact').markAsTouched();
      this.granterForm.get('firstNameCompanyContact').markAsTouched();
      this.granterForm.get('lastNameCompanyConatact').markAsTouched();
      this.granterForm.get('addressLine1CompanyContact').markAsTouched();
      this.granterForm.get('cityCompanyConatact').markAsTouched();
      this.granterForm.get('provinceComapnyConatact').markAsTouched();
      this.granterForm.get('emailCompanyConatact').markAsTouched();
      this.granterForm.get('nicCompanyConatact').markAsTouched();
      this.granterForm.get('contactdetmobileno').markAsTouched();
      this.granterForm.get('contactdetofficeno').markAsTouched();
      if (this.checkGrantorCompanyFields()) {
        return true;
      } else {
        return false;
      }
    } else {
      this.granterForm.get('nameWithInitials').markAsTouched();
      this.granterForm.get('firstName').markAsTouched();
      this.granterForm.get('lastName').markAsTouched();
      this.granterForm.get('addressLine1').markAsTouched();
      this.granterForm.get('city').markAsTouched();
      this.granterForm.get('province').markAsTouched();
      this.granterForm.get('email').markAsTouched();
      this.granterForm.get('nic').markAsTouched();
      this.granterForm.get('contactNo').markAsTouched();

      // check validity
      if (this.checkGrantorIndividualFields()) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkGranter() {
    this.isUpdate = false;
    this.granterForm.get('companyCategoryOther').disable();
    this.granterForm.reset();
    if (this.granters.length >= 1) {
      if (this.granters[0].granterCompany) {
        this.isGranterIndividual = false;
        this.notifier.notify('error', 'Only One Company Can Be Added as a Grantor');
        return false;
      } else {
        this.grantorCheck = false;
        this.isGranterIndividual = true;
      }
    } else {
      this.refresh();
    }

    if (this.isNewGranterBtnSelected) {
      this.tempgranters = [];
    } else {
      // this.isNewGarateeBtnSelected = true;
      // console.log('elseeee======');
    }
  }

  checkEditGranter(index) {
    this.tempgranters = [];
    this.tempgranters.push(this.granters[index]);
    this.granterForm.patchValue(this.granters[index]);
    this.isUpdate = true;
    if (this.tempgranters[0].granterCompany) {
      this.grantorCheck = true;
      this.granterForm.patchValue({
        companyCategory: this.granters[0].company.companyCategory,
        companyCategoryOther: this.granters[0].company.companyCategoryOther,
        name: this.granters[0].company.name,
        addressLine1Company: this.granters[0].company.addressLine1,
        addressLine2Company: this.granters[0].company.addressLine2,
        cityCompany: this.granters[0].company.city,
        provinceCompany: this.granters[0].company.province,
        postalCodeCompany: this.granters[0].company.postalCode,
        type: this.granters[0].company.type,
        companyRegistrationNo: this.granters[0].company.companyRegistrationNo,
        companyRegistrationDate: this.granters[0].company.companyRegistrationDate,
        ownerName: this.granters[0].company.ownerName,
        ownerContactNum: this.granters[0].company.ownerContactNum,
        nameWithInitialsCompanyContact: this.granters[0].nameWithInitials,
        firstNameCompanyContact: this.granters[0].firstName,
        lastNameCompanyConatact: this.granters[0].lastName,
        middleNameCompanyConatact: this.granters[0].middleName,
        nicCompanyConatact: this.granters[0].nic,
        addressLine1CompanyContact: this.granters[0].addressLine1,
        addressLine2CompanyConatact: this.granters[0].addressLine2,
        cityCompanyConatact: this.granters[0].city,
        provinceComapnyConatact: this.granters[0].province,
        contactdetmobileno: this.granters[0].company.companyMobile,
        contactdetofficeno: this.granters[0].company.companyOfficeNo,
        emailCompanyConatact: this.granters[0].company.companyEmail
      });
    }
    this.granterIndex = index;
  }

  deleteGranter(index) {
    this.deletedIndex = index;
    this.isGranterIndividual = true;
    this.refresh();
  }

  checkGranterAvailability(): boolean {
    return this.granters.length >= 1;
  }

  modelClose() {
    if (!this.isUpdate) {
      // this.refresh();
    }
    this.modelCloseBtn.nativeElement.click();
    // this.grantorCheck = true;
  }

  changeIndividualtoCompany() {
    this.grantorCheck = !this.grantorCheck;
    // this.granterForm.reset();
    this.onclickCheck();
  }

  onclickCheck() {
    if (this.granters.length > 0) {
      if (this.granters[0].isGrantorIndividual) {
        this.isGrantorCompany = false;
        this.notifier.notify('error', 'Can not add Company as Grantee with Individual Grantees');
        this.grantorCheck = false;
      } else if(!this.grantorCheck) {
        this.grantorCheck = true;
      } else {
        this.grantorCheck = false;
      }
    } else if(!this.grantorCheck) {
      this.grantorCheck = true;
    } else {
      this.grantorCheck = false;
    }
  }

  addButtonEnabled(result: boolean) {
    this.addButtonEnability = result;
  }

  checkGrantorIndividualFields(): boolean {
    if (this.granterForm.get('nameWithInitials').valid &&
      this.granterForm.get('firstName').valid &&
      this.granterForm.get('lastName').valid &&
      this.granterForm.get('addressLine1').valid &&
      this.granterForm.get('city').valid &&
      this.granterForm.get('province').valid &&
      this.granterForm.get('email').valid &&
      this.granterForm.get('nic').valid &&
      this.granterForm.get('contactNo').valid) {
      return true;
    } else {
      return false;
    }
  }

  checkGrantorCompanyFields(): boolean {
    if (this.granterForm.get('nameWithInitialsCompanyContact').valid && this.granterForm.get('firstNameCompanyContact').valid &&
      this.granterForm.get('lastNameCompanyConatact').valid && this.granterForm.get('addressLine1CompanyContact').valid &&
      this.granterForm.get('addressLine1CompanyContact').valid && this.granterForm.get('cityCompanyConatact').valid &&
      this.granterForm.get('provinceComapnyConatact').valid && this.granterForm.get('emailCompanyConatact').valid &&
      this.granterForm.get('nicCompanyConatact').valid && this.granterForm.get('companyCategory').valid && this.granterForm.get('name').valid &&
      this.granterForm.get('companyRegistrationDate').valid && this.granterForm.get('companyRegistrationNo').valid &&
      this.granterForm.get('cityCompany').valid && this.granterForm.get('provinceCompany').valid &&
      this.granterForm.get('addressLine1Company').valid && this.granterForm.get('ownerName').valid &&
      this.granterForm.get('type').valid && this.granterForm.get('ownerContactNum').valid &&
      this.granterForm.get('nameWithInitialsCompanyContact').valid && this.granterForm.get('firstNameCompanyContact').valid &&
      this.granterForm.get('lastNameCompanyConatact').valid && this.granterForm.get('addressLine1CompanyContact').valid &&
      this.granterForm.get('cityCompanyConatact').valid && this.granterForm.get('provinceComapnyConatact').valid &&
      this.granterForm.get('emailCompanyConatact').valid && this.granterForm.get('nicCompanyConatact').valid &&
      this.granterForm.get('contactdetmobileno').valid && this.granterForm.get('contactdetofficeno').valid && this.validOther()) {
      if (this.checkDocument()) {
        return true;
      } else {
        this.notifier.notify('error', 'Grantor Addition Failed due to Document not being present');
      }
    } else {
      return false;
    }
  }

  checkDocument(): boolean {
    if (this.fileUploadForm20.filesListGranter.length === 0) {
      return false;
    }
    return true;
  }

  validOther(): boolean {
    if (!this.granterForm.get('companyCategoryOther').disabled) {
      if (!this.granterForm.get('companyCategoryOther').valid) {
        return false;
      }
      return true;

    }
    return true;
  }
}
