import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { LocalAuthorityService } from 'src/app/service/local-authority/local-authority.service';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-delete-local-authority',
  templateUrl: './delete-local-authority.component.html',
  styleUrls: ['./delete-local-authority.component.scss']
})
export class DeleteLocalAuthorityComponent implements OnInit, OnDestroy {

  private localAuthorityId: number;

  private sub = new SubSink();
  @Output() localAuthorityDeleted = new EventEmitter<boolean>();

  constructor(private localAuthorityService: LocalAuthorityService, private notifier: NotifierService) { }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  deleteLocalAuthority() {
    this.sub.add(this.localAuthorityService.deleteLocalAuthority(this.localAuthorityId)
      .subscribe(response => {

        this.updateTheView(true);
        this.notifier.notify('success', (response as any).message);
      }));
  }

  updateTheView(deleted: boolean) {
    this.localAuthorityDeleted.emit(deleted);
  }

  setLocalAuthorityId(id: number) {
    this.localAuthorityId = id;
  }

}



