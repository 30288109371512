import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InspectionAgreementTypeService {

  private inspectionAgreementTypeUrl = '/inspection-agreement-type';


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  createAgreementType(AgreementTypeModel:InspectionCommonDataModel):Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionAgreementTypeUrl}`,AgreementTypeModel)
  }

  getAgreementType(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.inspectionAgreementTypeUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  updateAgreementType(id: number, AgreementTypeModel:InspectionCommonDataModel): Observable<any> {
    return this.http.put(`${environment.api_url}${this.inspectionAgreementTypeUrl}/${id}`, AgreementTypeModel);
  }

  searchAgreementType(keyWord:string,offset, limit):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.inspectionAgreementTypeUrl +'/search?keyword='+ keyWord + '&offset=' + offset + '&limit=' + limit + '');
  }

  deleteAgreementType(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.inspectionAgreementTypeUrl}/${id}`);
  }
}
