import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {SubSink} from 'subsink';
import {DocumentTypeService} from '../../../../../../service/document-type/document-type.service';
import {NotifierService} from 'angular-notifier';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss']
})
export class DeleteUserComponent implements OnInit,OnDestroy {

  private userTypeId: any;
  private sub = new SubSink();

  @Output()userTypeDelete = new EventEmitter<boolean>();

  constructor(private userTypeService: DocumentTypeService, private notifier: NotifierService,    private userService: UserService,) { }

  ngOnInit() {
  }


  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  deleteUserType() {
    // this.sub.add(this.userTypeService.deleteUserType(this.documentTypeId)
    //   .subscribe(response => {
    //     this.updateTheView(true);
    //     this.notifier.notify( 'success', (response as any).message);
    //   }));
    this.sub.add(this.userService.inactivateUser(this.userTypeId.id).subscribe(res=>{
      this.updateTheView(true);
      this.notifier.notify( 'success', (res as any).message);
    }))
  }

  updateTheView(deleted: boolean) {
    this.userTypeDelete.emit(deleted);
  }

  setUserId(user: any) {
    this.userTypeId = user;
    console.log(user)
  }

}



