import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class InternalUserAuth implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {  }

  canActivate(): boolean{
        // check whether user is authenticated
        if (this.authService.isNotNotary()) {
          return true;
        }

        // if not navigate to login page
        this.router.navigate(['/']);
        // you can save redirect url so after
        // authenticating we can move them back to the page they requested
        return false;
  }
}
