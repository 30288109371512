import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DistrictService } from 'src/app/service/district/district.service';
import { GramaNiladariDivisionService } from 'src/app/service/grama-niladari-division/grama-niladari-division.service';
import { GramaNiladariDivision } from 'src/app/model/grama-niladari-division';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-update-grama-niladari-division',
  templateUrl: './update-grama-niladari-division.component.html',
  styleUrls: ['./update-grama-niladari-division.component.scss']
})
export class UpdateGramaNiladariDivisionComponent implements OnInit, OnDestroy {

  updateGramaNildariDivisionForm: FormGroup;

  districts = [];
  private sub = new SubSink();

  @Output() gramaNiladariDivisionUpdated = new EventEmitter<boolean>();

  constructor(private formBuilder: FormBuilder, private notifier: NotifierService,

    private districtService: DistrictService, private gramaNiladariDivisionService: GramaNiladariDivisionService) { }

  ngOnInit() {

    this.updateGramaNildariDivisionForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      code: ['', [Validators.required]],
      district: ['', [Validators.required]],
      description: ['', [Validators.required]]
      // floorTypeName: ['', [Validators.required]]
    })

    this.sub.add(this.districtService.getDistricts(0,10).subscribe(
      (res) => {
        this.districts = (res as any).data;
      }
    ))

  }
ngOnDestroy(){
  this.sub.unsubscribe();
}
  updateGramaNiladariDivision() {
    // 6("daojcnao")
    if (this.updateGramaNildariDivisionForm.valid) {
      const gramaNiladariDivision = new GramaNiladariDivision();

      gramaNiladariDivision.description = this.getDescription();
      gramaNiladariDivision.code = this.getCode();
      gramaNiladariDivision.district = this.getDistrict();
      this.sub.add(this.gramaNiladariDivisionService.updateGramaNiladariDivision(this.getId(), gramaNiladariDivision)
        .subscribe(response => {

          this.updateTheView(true);
          this.notifier.notify('success', (response as any).message)
        }));
    }
  }

  getId() {
    return this.updateGramaNildariDivisionForm.get('id').value;
  }


  getDescription() {
    return this.updateGramaNildariDivisionForm.get('description').value;
  }
  getCode() {
    return this.updateGramaNildariDivisionForm.get('code').value;
  }
  getDistrict() {
    return this.updateGramaNildariDivisionForm.get('district').value;
  }
  setIdAndDescription(id: number, code: string, district: number, description: string) {
    this.updateGramaNildariDivisionForm.get('id').setValue(id);
    this.updateGramaNildariDivisionForm.get('code').setValue(code);
    this.updateGramaNildariDivisionForm.get('district').setValue(district);
    this.updateGramaNildariDivisionForm.get('description').setValue(description);
    // this.updateFloorSubTypeForm.get('floorType').setValue(floorType)
  }

  updateTheView(updated: boolean) {
    this.gramaNiladariDivisionUpdated.emit(updated);
  }



}
