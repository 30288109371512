import { Component, OnInit, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier'
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

import { DocumentUploadFourteenComponent } from 'src/app/component/document/document-upload/document-upload-fourteen/document-upload-fourteen.component';
import { RefundIssueDetails } from 'src/app/model/refundIssueDetails';
import { refundRequestUpdate } from 'src/app/model/refundRequestUpdate';
import { DocumentUploadService } from 'src/app/service/document-upload/document-upload.service';
import { RefundOpinionServiceService } from 'src/app/service/RefundOpinionService/refund-opinion-service.service';


@Component({
  selector: 'app-issue-details',
  templateUrl: './issue-details.component.html',
  styleUrls: ['./issue-details.component.scss']
})
export class IssueDetailsComponent implements OnInit {

  @ViewChild('documentUploadAccountant', {static: false}) documentUploadAccountant: DocumentUploadFourteenComponent;

  public refundIssueDetailsModel = new RefundIssueDetails();
  refundRequestUpdate = new refundRequestUpdate('','','','','','')

  fileNumber: string;
  refundNumber: string;

  issueDate: any;
  chequeNo: any;
  issueAmount: any;
  name: any;
  nic: any;
  address: any;
  mobile: any;
  email: any;
  issuedOffice: any;

  selectPaths = [];
  otherSelectedPaths = [];
  selectPathsCollection = [];
  errorList = []

  constructor(
    private documentUploadService: DocumentUploadService,
    private notifier: NotifierService,
    private refundOpinionService: RefundOpinionServiceService,
    public router: Router
  ) { }

  ngOnInit() {
    this.fileNumber = localStorage.getItem('fileNumber');
    this.refundNumber = localStorage.getItem('refundNumber');
  }

  // DOCUMENT UPLOAD - START
  setAccountantDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  checkAccountantDocuments() {
    this.errorList = [];
    if (this.documentUploadAccountant.filesList14.length === 0) {
      this.errorList.push('Accountant\'s Documents');
    }

    if (this.errorList.length === 0) {
      this.documentUploadAccountant.outputDocumentsFourteenSet();
      this.otherSelectedPaths = this.selectPaths;
      this.saveAccountantDocuments();
    } else {
      const re = /,/gi;
      const errormsg = this.errorList.toString().replace(re, '<br/>');
    }
  }

  saveAccountantDocuments() {
    this.selectPathsCollection.push(this.otherSelectedPaths[0]);

    this.documentUploadService.uploadRefundRequestAccountantDocuments(this.selectPathsCollection, localStorage.getItem('refundRequest')).subscribe(
      data => {
      },
      err => {
        this.notifier.notify('error', 'Document upload unsuccessful');
      }
    );
  }
  // DOCUMENT UPLOAD - END

  saveIssueDetails() {
    if (
      this.name == null || this.name == "" ||
      this.nic == null || this.nic == "" ||
      this.address == null || this.address == "" ||
      this.mobile == null || this.mobile == "" ||
      this.email == null || this.email == "" ||
      this.issuedOffice == null || this.issuedOffice == ""
      ) {
      this.notifier.notify('error', 'Please fill the required fields');
    } else {

      this.checkAccountantDocuments();

      this.refundIssueDetailsModel.refundRequestId = localStorage.getItem('refundRequest');
      this.refundIssueDetailsModel.issueDate = this.issueDate;
      this.refundIssueDetailsModel.chequeNo = this.chequeNo;
      this.refundIssueDetailsModel.issueAmount = this.issueAmount;
      this.refundIssueDetailsModel.name = this.name;
      this.refundIssueDetailsModel.nic = this.nic;
      this.refundIssueDetailsModel.address = this.address;
      this.refundIssueDetailsModel.mobile = this.mobile;
      this.refundIssueDetailsModel.email = this.email;
      this.refundIssueDetailsModel.issuedOffice = this.issuedOffice;

      this.refundOpinionService.saveRefundIssueDetails(this.refundIssueDetailsModel).subscribe(
        data => {
          Swal.fire({
            icon: 'success',
            title: 'Successfully Updated',
            showConfirmButton: false,
            timer: 2000
          });
          this.router.navigate(['/view-refund-req/all-request']);
        }, err => {
          this.notifier.notify('error', 'Refund Application update unsuccessful');
        }
      )
    }
  }

  updateRefundRequest() {
    Swal.fire({
      title: 'Please Confirm',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#589C0E',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Continue!'
    }).then((result) => {
      this.saveIssueDetails();
    })
  }

  cancelIssueDetails() {
    // TODO: Implement the functionality
  }

}
