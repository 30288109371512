import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { InspectionLandSlopeService } from 'src/app/service/inspection-master-data-service/inspection-land-slope/inspection-land-slope.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inspection-land-slope',
  templateUrl: './inspection-land-slope.component.html',
  styleUrls: ['./inspection-land-slope.component.scss']
})
export class InspectionLandSlopeComponent implements OnInit,OnDestroy {
  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  createLandSlopeForm: FormGroup;
  updateLandSlopeForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  propertyTypeList: any =[];
  activationButton: boolean;
  public createLandSlopeModel = new InspectionCommonDataModel(null,'','','','');
  public updateLandSlopeModel = new InspectionCommonDataModel(null,'','','','');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;
  LandSlopes: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionLandSlopeService: InspectionLandSlopeService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createLandSlopeForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });
    this.updateLandSlopeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });

    // this.loadPropertyType();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }



  //create land slope - start

  get f() { return this.createLandSlopeForm.controls; }

  setLandSlopeModelToSave(){
    this.createLandSlopeModel.name = this.createLandSlopeForm.get('name').value;
    this.createLandSlopeModel.description = this.createLandSlopeForm.get('description').value;
    this.createLandSlopeModel.code = this.createLandSlopeForm.get('code').value;
    this.createLandSlopeModel.dataStatus = "ACTIVE";
  }

    //save land slope model
  saveLandSlope(){

    if (this.createLandSlopeForm.valid) {
      this.setLandSlopeModelToSave();
      this.sub.add(
        this.inspectionLandSlopeService.createLandSlope(this.createLandSlopeModel).subscribe(data=>{
          this.notifier.notify('success', (data as any).message);
          this.updateTheTable();
          this.reset();
        })
        );
    }else {
      this.createLandSlopeForm.markAllAsTouched();
    }

  }

  reset(){
    this.createLandSlopeForm.reset()
  }
  //create land slope - end

  //update land slope - start

    //get update controllers
  get f1() { return this.updateLandSlopeForm.controls; }

    //set data To land slope Type model
  setLandSlope(subProperty:any){
    this.updateLandSlopeForm.get('id').setValue(subProperty.id);
    this.updateLandSlopeForm.get('name').setValue(subProperty.name);
    this.updateLandSlopeForm.get('description').setValue(subProperty.description);
    this.updateLandSlopeForm.get('code').setValue(subProperty.code);

    if(subProperty.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateLandSlopeModel.id = this.updateLandSlopeForm.get('id').value;
    this.updateLandSlopeModel.name = this.updateLandSlopeForm.get('name').value;
    this.updateLandSlopeModel.description = this.updateLandSlopeForm.get('description').value;
    this.updateLandSlopeModel.code = this.updateLandSlopeForm.get('code').value;

    if(this.activationButton == true){
      this.updateLandSlopeModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateLandSlopeModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateLandSlope(){
    this.setDataToModel();
    this.sub.add(this.inspectionLandSlopeService.updateLandSlope(this.updateLandSlopeModel.id, this.updateLandSlopeModel).subscribe(data=>{
      this.notifier.notify('success', (data as any).message);
      this.updateTheTable();
    }))
  }

  //update land slope - end

//search and view table start
  pageable(i: number) {
    this.inspectionLandSlopeService.getLandSlope((i-1),10).subscribe(res=>{
      this.LandSlopes = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionLandSlopeService.getLandSlope(0, 10)
      .subscribe(
        (res) => {
          this.LandSlopes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search land slope Type
    searchLandSlope(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionLandSlopeService.searchLandSlope(this.keyword,0,10).subscribe(res=>{
          this.LandSlopes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionLandSlopeService.deleteLandSlope(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end
}
