import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { ReportServiceService } from "../service/report-service.service";
import { NotifierService } from "angular-notifier";
import * as XLSX from 'xlsx';

@Component({
	selector: 'app-certified-detail-report',
	templateUrl: './certified-detail-report.component.html',
	styleUrls: ['./certified-detail-report.component.scss']
})
export class CertifiedDetailReportComponent implements OnInit, AfterViewInit {

	fromDate: string = null;
	toDate: string = null;
	yearOO: string = null;

	selectedItemsARB: any = [];
	selectedItemsASS: any = [];

	dropdownListASS: any = [];
	dropdownListARB: any = [];

	fromDate_charge: string = null;
	toDate_charge: string = null;

	public showPrint: boolean = false;

	dropdownSettingsASS: IDropdownSettings = {};
	dropdownSettingsARB: IDropdownSettings = {};

	public reportDetails: any = [];

	public selectedDetails: any;

	public totalGivenAmount: number = 0;
	public totalDeclaredAmount: number = 0;
	public totalStampDutyOnOpinionAmount: number = 0;
	public totalStampDutyOnDeclaredAmount: number = 0;
	public totalValueDifferenceAmount: number = 0;
	public totalStampDutyDifferenceAmount: number = 0;

	constructor(public modalService: NgbModal,
		public reportService: ReportServiceService,
		private notifier: NotifierService) { }

	ngOnInit() {

		this.dropdownSettingsASS = {
			singleSelection: false,
			idField: 'id',
			textField: 'name',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		this.dropdownSettingsARB = {
			singleSelection: false,
			idField: 'id',
			textField: 'office',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		this.get_all_assessors();
		this.get_all_allocated_branches();

	}

	get_all_assessors() {
		this.reportService.getAllASS().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListASS = value.data;
				console.log(value.data);
			} else {
				console.log("No Allocated Regional Branch return");
			}
		});
	}

	get_all_allocated_branches() {
		this.reportService.getAllAB().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListARB = value.data;
				console.log(value.data);
			} else {
				console.log("No Allocated Regional Branch return");
			}
		});
	}

	ngAfterViewInit() {
		this.printReceipt();
	}


	onItemSelectASS(item: any) {
		console.log(item);
	}
	onSelectAllASS(items: any) {
		console.log(items);
	}

	onItemSelectARB(item: any) {
		console.log(item);
	}
	onSelectAllARB(items: any) {
		console.log(items);
	}

	closeFurtherReqRemark() {
		this.modalService.dismissAll();
	}

	/******** CAPTURE INPUTS **************/
	captureSelectedDetails() {
	/*	if (this.fromDate == null || this.toDate == null) {
			this.notifier.notify('error', 'Please enter endorsment date!');
			return false;
		}*/
    let lastDate = "";
    if(this.toDate == null){
      lastDate = "Today";
    }else {
      lastDate = this.toDate;
    }

    var date_range = ((this.fromDate == null) && (this.toDate == null)) ? "All Date" : this.fromDate + ' to ' + lastDate;

		// capture selected assessors
		var selectedASS = "";
		var selectedASS_Names = "";
		if (this.selectedItemsASS != null) {
			this.selectedItemsASS.forEach(value => {
				selectedASS += "'" + value.id + "',";
				selectedASS_Names += value.name + ",";
			});

			selectedASS = selectedASS.slice(0, -1);
			selectedASS_Names = selectedASS_Names.slice(0, -1);

			if (this.selectedItemsASS.length == this.dropdownListASS.length) {
				selectedASS_Names = "All";
			}
		} else {
			selectedASS = "";
			selectedASS_Names = "N/A";
		}

		// capture selected allocated regional branches
		var selectedARB = "";
		var selectedARB_Names = "";
		if (this.selectedItemsARB != null) {
			this.selectedItemsARB.forEach(value => {
				selectedARB += "'" + value.id + "',";
				selectedARB_Names += value.office + ", ";
			});

			selectedARB = selectedARB.slice(0, -1);
			selectedARB_Names = selectedARB_Names.slice(0, -1);
		} else {
			selectedARB = "";
			selectedARB_Names = "N/A";
		}

		this.selectedDetails = {
      selected_date: date_range,
			selected_from: this.fromDate,
			selected_to: this.toDate,
			selected_year: this.yearOO == null ? "" : this.yearOO,

			selected_charge_from: this.fromDate_charge,
			selected_charge_to: this.toDate_charge,

			selectedASS_id: selectedASS,
			selectedASS_names: selectedASS_Names,

			selectedARB_id: selectedARB,
			selectedARB_names: selectedARB_Names
		};

		return true;
	}
	/******** CAPTURE INPUTS **************/

	/******** LOAD REPORT DATA **************/
	getReportData() {
		this.reportService.getCertified_Detail_Report(this.selectedDetails.selected_from, this.selectedDetails.selected_to, this.selectedDetails.selectedARB_id, this.selectedDetails.selected_year, this.selectedDetails.selectedASS_id, this.selectedDetails.selected_charge_from, this.selectedDetails.selected_charge_to).subscribe(value => {
			this.reportDetails = null;
			if (value.data != null && value.data.data != null && value.data.data.length > 0) {
				this.reportDetails = value.data.data;

				this.totalGivenAmount = value.data.totalGivenAmount;
				this.totalDeclaredAmount = value.data.totalDeclaredAmount;
				this.totalStampDutyOnOpinionAmount = value.data.totalStampDutyOnOpinionAmount;
				this.totalStampDutyOnDeclaredAmount = value.data.totalStampDutyOnDeclaredAmount;
				this.totalValueDifferenceAmount = value.data.totalValueDifferenceAmount;
				this.totalStampDutyDifferenceAmount = value.data.totalStampDutyDifferenceAmount;
				console.log(this.reportDetails);
				this.showPrint = true;

				// this.getTotalColumns(value.data);
			} else {
				this.notifier.notify('error', 'No records found! ');
				console.log("No data found");
				this.showPrint = false;
				// this.reportStatus = false;
			}
		});
	}
	/******** LOAD REPORT DATA **************/

	captureInputs() {
		if (this.fromDate == null) {
			this.notifier.notify('error', 'Please enter from date!');
			return false;
		}

		if (this.toDate == null) {
			this.notifier.notify('error', 'Please enter to date!');
			return false;
		}

		var selectedItemsARB_id = "";
		var selectedItemsARB_name = "";
		if (this.selectedItemsARB != null) {
			this.selectedItemsARB.forEach(value => {
				selectedItemsARB_id += "'" + value.office + "',";
				selectedItemsARB_name += value.office + ",";
			});

			selectedItemsARB_id = selectedItemsARB_id.slice(0, -1);
			selectedItemsARB_name = selectedItemsARB_name.slice(0, -1);

			if (this.selectedItemsARB.length == this.dropdownListARB.length) {
				selectedItemsARB_name = "All";
			}
		}

		var selectedItemsASS_id = "";
		var selectedItemsASS_name = "";
		if (this.selectedItemsASS != null) {
			this.selectedItemsASS.forEach(value => {
				selectedItemsASS_id += "'" + value.id + "',";
				selectedItemsASS_name += value.name + ",";
			});

			selectedItemsASS_id = selectedItemsASS_id.slice(0, -1);
			selectedItemsASS_name = selectedItemsASS_name.slice(0, -1);

			if (this.selectedItemsASS.length == this.dropdownListASS.length) {
				selectedItemsASS_name = "All";
			}
		}


	}

	get_pdf_report() {
		var selectedLA = "";
		var selectedarbString = " ";

		var selectedASS = "";
		var selectedASSString = " ";

		if (this.selectedItemsARB != null) {
			this.selectedItemsARB.forEach(value => {
				// selectedLA += value.item_id +",";
				selectedarbString += " " + value.item_text + ",";
			});
		}

		if (this.selectedItemsASS != null) {
			this.selectedItemsASS.forEach(value => {
				selectedASS += value.id + ",";
				selectedASSString += " " + value.name + ",";
			});
		}

		var date_range = ((this.fromDate == null) && (this.toDate == null)) ? "All Date" : this.fromDate + ' to ' + this.toDate;
		var selected_arbStrings = (selectedarbString == " ") ? "All Allocated Regional Branches" : selectedarbString;
		var year_of_openion = (this.yearOO == null) ? "All Years" : this.yearOO;
		var selected_ASSStrings = (selectedASSString == " ") ? "All Assessors" : selectedASSString;
		var charge_range = ((this.fromDate_charge == null) && (this.toDate_charge == null)) ? "All Date" : this.fromDate_charge + ' to ' + this.toDate_charge;

		this.selectedDetails = {
			date: date_range,
			selected_arb: selected_arbStrings,
			year_of_openion: year_of_openion,
			assessor: selected_ASSStrings,
			charge_no: charge_range
		};


		console.log('calling event fired');

		this.reportService.getCertified_Detail_Report(
			this.fromDate,
			this.toDate,
			(selectedLA.slice(0, -1) == "") ? null : selectedLA.slice(0, -1),
			this.yearOO,
			(selectedASS.slice(0, -1) == "") ? null : selectedASS.slice(0, -1),
			this.fromDate_charge, this.toDate_charge).subscribe(value => {
				console.log(value);
				if (value.data.report.length > 0) {
					this.reportDetails = value.data;

					setTimeout(() => {
						this.printReceipt();
					}, 1000);

				} else {
					this.notifier.notify('error', 'No records found!');
					console.log("No data found");
				}
			});
	}

	get_excel_report() {
		var selectedLA = "";
		var selectedarbString = " ";

		var selectedASS = "";
		var selectedASSString = " ";

		if (this.selectedItemsARB != null) {
			this.selectedItemsARB.forEach(value => {
				selectedLA += value.item_id + ",";
				selectedarbString += " " + value.item_text + ",";
			});
		}

		if (this.selectedItemsASS != null) {
			this.selectedItemsASS.forEach(value => {
				selectedASS += value.id + ",";
				selectedASSString += " " + value.name + ",";
			});
		}

		var date_range = ((this.fromDate == null) && (this.toDate == null)) ? "All Date" : this.fromDate + ' to ' + this.toDate;
		var selected_arbStrings = (selectedarbString == " ") ? "All Allocated Regional Branches" : selectedarbString;
		var year_of_openion = (this.yearOO == null) ? "All Years" : this.yearOO;
		var selected_ASSStrings = (selectedASSString == " ") ? "All Assessors" : selectedASSString;
		var charge_range = ((this.fromDate_charge == null) && (this.toDate_charge == null)) ? "All Date" : this.fromDate_charge + ' to ' + this.toDate_charge;


		this.selectedDetails = {
			date: date_range,
			selected_arb: selected_arbStrings,
			year_of_openion: year_of_openion,
			assessor: selected_ASSStrings,
			charge_no: charge_range
		};

		this.reportService.getCertified_Detail_Report(
			this.fromDate,
			this.toDate,
			(selectedLA.slice(0, -1) == "") ? null : selectedLA.slice(0, -1),
			this.yearOO,
			(selectedASS.slice(0, -1) == "") ? null : selectedASS.slice(0, -1),
			this.fromDate_charge, this.toDate_charge).subscribe(value => {
				if (value.data.report.length > 0) {
					this.reportDetails = value.data;

					setTimeout(() => {
						var printContents = document.getElementById('excel_download');
						const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(printContents);
						const wb: XLSX.WorkBook = XLSX.utils.book_new();
						XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
						XLSX.writeFile(wb, 'ScoreSheet.xlsx');

						// this.printReceipt();
					}, 1000);

				} else {
					this.notifier.notify('error', 'No records found!');
					console.log("No data found");
				}
			});
	}

	download_excel() {
		this.captureSelectedDetails();
		this.getReportData();
		this.showPrint = true;
		setTimeout(() => {
			var printContents = document.getElementById('excel_download');
			const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(printContents);
			const wb: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'Certified Details');
			XLSX.writeFile(wb, 'Certified Details Report.xlsx');
		}, 1000);
	}

	generateReport() {
		// this.get_pdf_report();
		this.captureSelectedDetails();
		this.getReportData();
		this.showPrint = true;
		setTimeout(() => {
			this.printReceipt();
		}, 1000);
	}

	printReceipt(): void {

		let printContents: any;
		let popupWin: any;
		printContents = document.getElementById('receipt').innerHTML;

		popupWin = window.open();
		popupWin.document.open();
		popupWin.document.write(`
    <html>
    <head>
        <title>Certificate Details Report</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <style>
          @page {
            size: A3 landscape;
            @bottom-right {
              content: "Page " counter(page) " of " counter(pages);
            }

            .page{
                padding-bottom: 20px;
                page-break-after: always;
            }
          }

            body
            {
                margin: 10mm 10mm 0mm 10mm;

            }
            table{
              border: 1px solid #000;
              border-collapse: collapse;
            }
            tbody{
              page-break-before: always;
            }

            tfoot tr td {
                border: 1px solid #ffffff;
            }

            table th, table td
            {
                padding: 5px;
                margin: 0px;
                border: 1px solid #000;
            }

            .page{
                padding-bottom: 20px;
                page-break-after: always;
            }

        </style>
    </head>
    <body onload="window.print();">
            ${printContents}
    </body>
    </html>`
		);
		popupWin.document.close();

		// this.printPreview.emit(false);
	}


}
