import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private http: HttpClient) {
  }

  registerService(body): Observable<any> {
    return this.http.post<any>(environment.user_api_url + '/register/registration/notary', body);
  }

  registerPersonalImageService(body): Observable<any> {
    return this.http.post<any>(environment.user_api_url + '/register/personalImage', body);
  }

  registerSignatureImageService(body): Observable<any> {
    return this.http.post<any>(environment.user_api_url + '/register/signatureImage', body);
  }

  getActivatedPasswordPolicy():Observable<any>{
    return this.http.get<any>(environment.user_api_url+'/register/activated-password=policy');
  }
}
