export class RimForm {
    id: number;
    localAuthorityId: number;
    landRegistryId: number;
    landRegistry: string;
    durationFrom: any;
    durationTo: any;
    reimbursementApplicationId: string;
    rimApplicationFormExcelList: any;
}
