import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import {ReportServiceService} from '../../stamp-duty-opinion-reports/service/report-service.service';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-refund-rejected-application-report',
  templateUrl: './refund-rejected-application-report.component.html',
  styleUrls: ['./refund-rejected-application-report.component.scss']
})
export class RefundRejectedApplicationReportComponent implements OnInit, AfterViewInit {
  // submitted date range
  fromDate: string = null;
  toDate: string = null;

  // report data
  public reportDetails: any = [];
  public showPrint: boolean = false;
  public selectedDetails: any;
  public download_excel_status: boolean = false;

  // report data footer data
  public total_count: number = 0;
  public total_requested_value: number = 0;

  constructor(
    public modalService: NgbModal,
    public reportService: ReportServiceService,
    private notifier: NotifierService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  captureSelectedDetails(): void {
    var date_range = ((this.fromDate == null) && (this.toDate == null)) ? "All Date" : this.fromDate + ' to ' + this.toDate;

    this.selectedDetails = {
      selected_date: date_range
    };
  }
  /******** CAPTURE INPUTS **************/

  /******** LOAD REPORT DATA **************/
  getReportData() {
    this.reportService.getRejectedApplicationReport(this.fromDate, this.toDate).subscribe(value => {
      // if (value.data.length > 0) {
        this.reportDetails = value.data.reportOfTheRejectedApplicationReportDtoList;
        console.log('value'+ value.data.reportOfTheRejectedApplicationReportDtoList);
        this.total_count = value.data.reportOfTheRejectedApplicationReportDtoList.length;
        console.log('amount '+ value.data.requestedAmountTotal);
        this.total_requested_value = value.data.requestedAmountTotal;
      // } else {
      //   this.notifier.notify('error', 'No records found! ');
      //   console.log("No data found");
      // }
    });
  }
  /******** LOAD REPORT DATA **************/

  /*************** GENERATE REPORT ***************/
  createPdfReport(): void {

    let printContents: any;
    let popupWin: any;
    printContents = document.getElementById('rejectedApplicationReportContent').innerHTML;

    popupWin = window.open();
    popupWin.document.open();
    popupWin.document.write(`
    <html>
    <head>
        <title>Application Rejected Details Report</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <!--<script src="../../../../assets/js/paged.js"></script>-->
        <!--<script src="../../../../assets/js/paged.polyfill.js"></script>-->
        <style>
          @page {
            size: A3 landscape;
            @bottom-right {
              content: "Page " counter(page) " of " counter(pages);
            }
            .page{
                page-break-after: always;
            }
          }

            body
            {
                margin: 10mm 10mm 0mm 10mm;

            }
            table{
              border: 1px solid #000;
              border-collapse: collapse;
            }
            tbody{
              page-break-before: always;
            }

            table th, table td
            {
                padding: 5px;
                margin: 0px;
                border: 1px solid #000;
            }
            .page{
                page-break-after: always;
            }

        </style>
    </head>
    <body onload="window.print();">
            ${printContents}
    </body>
    </html>`
    );
    popupWin.document.close();
  }
  /*************** GENERATE REPORT ***************/

  /*************** UI EVENT ***************/
  // event click on close button
  closeFurtherReqRemark() {
    this.modalService.dismissAll();
  }

  createExcel() {
    console.log('after excel');
    this.captureSelectedDetails();
    this.getReportData();
    this.showPrint = true;
    console.log('after capture');
    setTimeout(() => {
      var printContents = document.getElementById('excel_print');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(printContents);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Rejected App. Payment');
      XLSX.writeFile(wb, 'Application Rejected Details Report.xlsx');
    }, 1000);
  }

  generatePdfReport() {
    this.captureSelectedDetails();
    this.getReportData();
    this.showPrint = true;
    setTimeout(() => {
      this.createPdfReport();
    }, 1000);

  }

}
