import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DocumentTypeService } from 'src/app/service/document-type/document-type.service';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-delete-document-type',
  templateUrl: './delete-document-type.component.html',
  styleUrls: ['./delete-document-type.component.scss']
})
export class DeleteDocumentTypeComponent implements OnInit, OnDestroy {

  private documentTypeId: number;
  private sub = new SubSink();

  @Output()documentTypeDelete = new EventEmitter<boolean>();

  constructor(private documentTypeService: DocumentTypeService, private notifier: NotifierService) { }

  ngOnInit() {
  }


  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  deleteDocumentType() {
    this.sub.add(this.documentTypeService.deleteDocumentType(this.documentTypeId)
    .subscribe(response => {
        this.updateTheView(true);
        this.notifier.notify( 'success', (response as any).message);
      }));
  }

  updateTheView(deleted: boolean) {
    this.documentTypeDelete.emit(deleted);
  }

  setDocumentTypeId(id: number) {
    this.documentTypeId = id;
  }

}



