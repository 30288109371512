import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubSink} from 'subsink';
import {NotifierService} from 'angular-notifier';
import {DataService} from '../../../../../../service/data.service';
import {Bank} from '../../../../../../model/bank';
import {BankService} from '../../../../../../service/bank/bank.service';

@Component({
  selector: 'app-search-bank',
  templateUrl: './search-bank.component.html',
  styleUrls: ['./search-bank.component.scss']
})
export class SearchBankComponent implements OnInit, OnDestroy {

  addBankForm: FormGroup;
  private sub = new SubSink();

  constructor(private formBuilder: FormBuilder,
              private bankService: BankService,
              private notifier: NotifierService,
              private dataService: DataService) {
  }

  ngOnInit() {
    this.addBankForm = this.formBuilder.group({
      bank: ['', [Validators.required]]
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  saveBank() {

     
    this.bankService.getAll().subscribe(
      response => {
        const totalItems = response.data.length;
        this.bankService.getBanks(0,totalItems).subscribe(res=>{
          const bankArray = (res as any).data.content;
          if (bankArray.map((banks)=>String(banks.bankName.replace(/\s/g, ""))).includes(String(this.addBankForm.get('bank').value.replace(/\s/g, "")))){
            this.notifier.notify('error', 'Bank name "'+this.addBankForm.get('bank').value +'" is exist');
            return;
          }else{
            if (this.addBankForm.valid) {
              const bank = new Bank();
        
              bank.bankName = this.getDescription();
        
              this.sub.add(this.bankService.createBank(bank)
                .subscribe(response => {
                  this.notifier.notify('success', 'Successfully Added Bank');
                  this.dataService.updateTableOffice(true);
                }));
        
              this.reset();
            }else{
              this.addBankForm.markAllAsTouched();
            }
          }})
      });

  }

  get f() {
    return this.addBankForm.controls;
  }

  getDescription() {
    return this.addBankForm.get('bank').value;
  }

  reset() {
    this.addBankForm.reset();
  }

}
