import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {SubSink} from 'subsink';
import {ValuationRequestService} from '../../../../service/valuation-request/valuation-request.service';
import {Router} from '@angular/router';
import { ValuationRequestList } from '../../../../model/valuation_request_list';
import { Constant } from 'src/app/util/constant';
import FileSaver from "file-saver";
import { NgxSpinnerService } from 'ngx-spinner';
import { PDFProgressData } from 'ng2-pdf-viewer';
import { FileService } from 'src/app/service/file/file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationDetails } from '../../../../model/notificationDetails';
import { NotificationService } from 'src/app/service/notification/notification.service';

@Component({
  selector: 'app-further-requirement',
  templateUrl: './further-requirement.component.html',
  styleUrls: ['./further-requirement.component.scss']
})
export class FurtherRequirementComponent implements OnInit, OnDestroy {
  // pagination
  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];
  searchKeyword = '';
  nextPage: number;
  public model = new ValuationRequestList('', '', '', '', '', '');
  public notificationDetails = new NotificationDetails('','');
  // valuationRequests: IValuationRequest [] = [];
  valuationRequests: any;
  @Output() furtherRequestCount = new EventEmitter<number>();
  noResults: boolean = false;
  private sub = new SubSink();
  userdata: any = [];
  userrolecode: string;
  valuationRequestId: number;
  valuationRequest: any = [];
  public loggedInAsCommissioner: boolean;
  valuationrequestlist: any = [];
  currentUserRoleCode: string;



  constructor(
    private valuationRequestService: ValuationRequestService,
    private notificationService: NotificationService,
    private router: Router,

  ) {
  }

  ngOnInit() {
    this.selectedPageNo = 0;
    this.limit = 10;
    this.nextPage = 0;
    console.log('test')
    this.searchAll();
    // this._prepare();
  }

  // Prashan

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

  getPage(pageNo: number) {
    this.selectedPageNo = pageNo;

    this.sub.add(this.valuationRequestService.findValuationRequestByStatus(
      'FURTHER_REQUIREMENT', this.selectedPageNo, this.limit, 'EXTERNAL').subscribe(
      (res) => {
        this.valuationRequests = res.data.content;
        if (Object.keys(this.valuationRequests).length === 0) {
          this.noResults = true;
        } else this.noResults = false;
        this.paginationButtonCounter(res.data.totalPages);
        this.numOfPages = res.data.totalPages;

        //Setting the pagination for the table No. of Requests
        if (this.selectedPageNo != 0) {
          this.nextPage = 10 * this.selectedPageNo;
        } else {
          this.nextPage = 0;
        }
      }));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  search() {
    this.selectedPageNo = 0;
    this.limit = 10;

    if (this.searchKeyword.trim() !== "" && this.searchKeyword != null) {
      this.sub.add(this.valuationRequestService.searchValuationRequest(this.searchKeyword,
        'FURTHER_REQUIREMENT', this.selectedPageNo, this.limit, 'EXTERNAL').subscribe(
        (res) => {
          this.valuationRequests = res.data.content;
          if (Object.keys(this.valuationRequests).length === 0) {
            this.noResults = true;
          } else this.noResults = false;
          this.paginationButtonCounter(res.data.totalPages);
          this.numOfPages = res.data.totalPages;
        }));
    } else {

    this.searchAll();
    }


  }

  searchAll() {
    this.sub.add(this.valuationRequestService.findValuationRequestByStatus(
      'FURTHER_REQUIREMENT', this.selectedPageNo, this.limit, 'EXTERNAL').subscribe(
      (res) => {
        this.valuationRequests = res.data.content;
        if (Object.keys(this.valuationRequests).length === 0) {
          this.noResults = true;
        } else this.noResults = false;
        this.paginationButtonCounter(res.data.totalPages);
        this.numOfPages = res.data.totalPages;
        this.furtherRequestCount.emit(res.data.totalElements);
      }));
  }
  downloadpdf(id) {
    this.valuationRequestService.downloadpdf(id).subscribe(response => {
      let blob: any = new Blob([response], {type: 'application/pdf'});
      // const url= window.URL.createObjectURL(blob);
      // // window.open(url);
      // window.location.href = url;
      FileSaver.saveAs(blob, "valuation_application_" + id + ".pdf");
    }), error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
    // console.log('lllll')
    // return this.valuationRequestService.downloadpdf(id);
  }

  updateValuationRequest(valuationRequestId) {
    this.notificationDetails.subject = Constant.REQUESTED_FOR_FURTHER_REQUIREMENT;
    this.notificationDetails.valuationRequestId = valuationRequestId;

    this.notificationService.updateReleventNotification(this.notificationDetails).subscribe();

    this.sub.add(this.valuationRequestService.findByValuationRequestId(valuationRequestId)
      .subscribe((res) => {
        if (res.data.deedNature === 'Gift') {
          // Below localStorage items are being used in "other-fr.components.ts"
          localStorage.setItem('valuation_request_id', valuationRequestId);
          sessionStorage.setItem('valuationUpdate', 'true');
          sessionStorage.setItem('selectedType', 'Gift');
          this.router.navigate(['/further-requirement-update/gift-further-requirement']);
        } else if (res.data.deedNature === 'Transfer') {
          localStorage.setItem('valuation_request_id', valuationRequestId);
          sessionStorage.setItem('valuationUpdate', 'true');
          sessionStorage.setItem('selectedType', 'Transfer');
          this.router.navigate(['/further-requirement-update/transfer-further-requirement']);
        } else if (res.data.deedNature === 'Exchange') {
          localStorage.setItem('valuation_request_id', valuationRequestId);
          sessionStorage.setItem('valuationUpdate', 'true');
          sessionStorage.setItem('selectedType', 'Exchange');
          this.router.navigate(['/further-requirement-update/exchange-further-requirement']);
        }
      }));
  }



  // _prepare() {
  //   this.obtainUserData();
  //   this.checkPermission();
  // }

  // obtainUserData() {
  //   this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
  //   this.model.userId = this.userdata.id;
  //   for (let role of this.userdata.roles) {
  //     if (role.code === Constant.ROLE_SUPER_ADMIN) {
  //       this.userrolecode = Constant.ROLE_SUPER_ADMIN;
  //       break;
  //     } else if (role.code === Constant.ROLE_COMMISSIONER) {
  //       this.loggedInAsCommissioner = true;
  //       this.userrolecode = Constant.ROLE_COMMISSIONER;
  //       break;
  //     } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
  //       this.userrolecode = Constant.ROLE_DEPUTY_COMMISSIONER;
  //       break;
  //     } else if (role.code === Constant.ROLE_ACCESSOR) {
  //       this.userrolecode = Constant.ROLE_ACCESSOR;
  //       break;
  //     } else if (role.code === Constant.ROLE_NOTARY) {
  //       this.userrolecode = Constant.ROLE_NOTARY;
  //       break;
  //     }

  //   }
  //   this.model.userType = this.userrolecode;
  //   this.loadNewApplicationData();
  // }

  searchReference() {
    // To get production build
  }

  // searchReference() {
  //   if (this.model.referenceNumber === null) {
  //     this.model.referenceNumber = '';
  //   }
  //   this.model.valuationStatus = Constant.VALUATION_REQUEST_FURTHER_REQUIREMENT_CODE;
  //   this.model.offset = '0';
  //   this.model.limit = '20';
  //   this.valuationRequestService.valuationRequestList(this.model).subscribe(
  //     data => {
  //       this.valuationrequestlist = data.data.content;
  //       if (this.valuationrequestlist.length === 0) {
  //         this.noResults = true;
  //       } else {
  //         this.noResults = false;
  //         this.paginationButtonCounter(data.data.totalPages);
  //         this.numOfPages = data.data.totalPages;
  //         this.furtherRequestCount.emit(data.data.totalElements);
  //       }
  //     }
  //   )
  // }

  // loadNewApplicationData() {
  //   this.model.valuationStatus = Constant.VALUATION_REQUEST_FURTHER_REQUIREMENT_CODE;
  //   this.model.offset = '0';
  //   this.model.limit = '20';
  //   this.valuationRequestService.valuationRequestList(this.model).subscribe(
  //     data => {
  //       this.valuationrequestlist = data.data.content;
  //       if (this.valuationrequestlist.length === 0) {
  //         this.noResults = true;
  //       } else {
  //         this.noResults = false;
  //         this.paginationButtonCounter(data.data.totalPages);
  //         this.numOfPages = data.data.totalPages;
  //         this.furtherRequestCount.emit(data.data.totalElements);
  //       }
  //     }
  //   )
  // }

  // checkPermission() {
  //   for (let role of this.userdata.roles) {
  //     if (role.code === Constant.ROLE_ACCESSOR) {
  //       this.currentUserRoleCode = Constant.ROLE_ACCESSOR;
  //       break;
  //     } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER){
  //       this.currentUserRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
  //       break;
  //     } else if (role.code === Constant.ROLE_COMMISSIONER) {
  //       this.currentUserRoleCode = Constant.ROLE_COMMISSIONER;
  //       break;
  //     }
  //   }
  // }

  // paginationButtonCounter(numOfPages: number) {
  //   this.pageButtons = new Array(numOfPages);
  // }

  // getPage(pageNo: number) {
  //   this.selectedPageNo = pageNo;
  //   this.sub.add(this.valuationRequestService.findValuationRequestByStatus(
  //     'FURTHER_REQUIREMENT', this.selectedPageNo, this.limit, 'EXTERNAL').subscribe(
  //     (res) => {
  //       console.log(res);
  //       this.valuationRequests = res.data.content;
  //       if (Object.keys(this.valuationRequests).length === 0) {
  //         this.noResults = true;
  //       }
  //       this.paginationButtonCounter(res.data.totalPages);
  //       this.numOfPages = res.data.totalPages;
  //       if (this.selectedPageNo != 0) {
  //         this.nextPage = 10 * this.selectedPageNo;
  //       } else {
  //         this.nextPage = 0;
  //       }
  //     }));
  // }

  // ngOnDestroy() {
  //   this.sub.unsubscribe();
  // }

  // updateValuationRequest(valuationRequestId) {
  //   this.sub.add(this.valuationRequestService.findByValuationRequestId(valuationRequestId)
  //     .subscribe((res) => {
  //       if (res.data.deedNature === 'Gift') {
  //         localStorage.setItem('valuation_request_id', valuationRequestId);
  //         sessionStorage.setItem('valuationUpdate', 'true');
  //         sessionStorage.setItem('selectedType', 'Gift');
  //         this.router.navigate(['/further-requirement-update/gift-further-requirement']);
  //       } else if (res.data.deedNature === 'Transfer') {
  //         localStorage.setItem('valuation_request_id', valuationRequestId);
  //         sessionStorage.setItem('valuationUpdate', 'true');
  //         sessionStorage.setItem('selectedType', 'Transfer');
  //         this.router.navigate(['/further-requirement-update/transfer-further-requirement']);
  //       } else if (res.data.deedNature === 'Exchange') {
  //         localStorage.setItem('valuation_request_id', valuationRequestId);
  //         sessionStorage.setItem('valuationUpdate', 'true');
  //         sessionStorage.setItem('selectedType', 'Exchange');
  //         this.router.navigate(['/further-requirement-update/exchange-further-requirement']);
  //       }
  //     }));
  // }
}
