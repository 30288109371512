import {Component, OnInit, ViewChild} from '@angular/core';
import {ViewCompanyCategoryComponent} from '../company/company-category/view-company-category/view-company-category.component';
import {ViewPaymentMethodComponent} from './view-payment-method/view-payment-method.component';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {
  @ViewChild(ViewPaymentMethodComponent, {static: false})
  private viewPaymentMethodComponent: ViewPaymentMethodComponent;
  keyword: string;
  constructor() { }

  ngOnInit() {
  }
  triggerSearch(event: any) {
    if (this.keyword === '') {
      this.viewPaymentMethodComponent.onUpdated(true);
    } else {
      this.viewPaymentMethodComponent.searchPaymentMethod(this.keyword);
    }
  }

}
