import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ForgotPasswordService} from '../../service/forgot_password.service';
import {NotifierService} from 'angular-notifier';
import {Router} from '@angular/router';
import Swal from "sweetalert2";

@Component({
  selector: 'app-forgot-password-temp',
  templateUrl: './forgot-password-temp.component.html',
  styleUrls: ['./forgot-password-temp.component.scss']
})
export class ForgotPasswordTempComponent implements OnInit {

  resetEmail: any;
  resetPasswordForm: FormGroup;
  emailPattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;

  constructor(private router: Router,
              private notifier: NotifierService,
              private forgotPasswordService: ForgotPasswordService,
              private formBuilder: FormBuilder) {
  }

  get f() {
    return this.resetPasswordForm.controls;
  }

  ngOnInit() {
    // this.resetPasswordForm = new FormGroup({
    //   resetPasswordMail: new FormControl(null, Validators.required)
    // });
    this.resetPasswordForm = this.formBuilder.group({
      resetEmail: ['', [Validators.required, Validators.pattern(this.emailPattern)]]
    });
  }

  resetPassword() {
    this.resetPasswordForm.markAllAsTouched();
    if (this.resetPasswordForm.valid === true) {
      Swal.fire({
        position: 'top-end',
        icon: 'info',
        title: 'Please wait!',
        text: 'Your password reset link is generating..',
        showConfirmButton: false,
        allowOutsideClick: false
      })
      this.forgotPasswordService.resetPassword(this.resetEmail).subscribe(result => {
        if (result != null) {
          if (result.message === 'PASSWORD RESET EMAIL SENT!') {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Password reset email sent!',
              showConfirmButton: false,
              timer: 5000
            })

            // this.notifier.notify('success', 'PASSWORD RESET EMAIL SENT!');
            this.router.navigate(['/login']);
          } else {

            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: result.message,
              showConfirmButton: false,
              timer: 5000
            })
            // this.notifier.notify('error', result.message);
          }
        } else {

        }
      }, error => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Something went wrong!',
          showConfirmButton: false,
          timer: 5000
        })
      });
    }
  }
}
