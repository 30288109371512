export class InspectionBuildingDetails {
  public totalCompletion: string;
  public materials: string;
  public fittings: string;
  public kitchenPantry: string;
  public pantryCupboards: string;
  public inspectionNoOfBuildings: string;
  public incompletedFloors: string;
  public completedFloors: string;
  public totalCompletedRatio: string;
  public usageId: string;

  constructor(
    totalCompletion: string,
    materials: string,
    fittings: string,
    kitchenPantry: string,
    pantryCupboards: string,
    inspectionNoOfBuildings: string,
    incompletedFloors: string,
    completedFloors: string,
    totalCompletedRatio: string,
    usageId: string
  ) {
    this.totalCompletion = totalCompletion;
    this.materials = materials;
    this.fittings = fittings;
    this.kitchenPantry = kitchenPantry;
    this.pantryCupboards = pantryCupboards;
    this.inspectionNoOfBuildings = inspectionNoOfBuildings;
    this.incompletedFloors = incompletedFloors;
    this.completedFloors = completedFloors;
    this.totalCompletedRatio = totalCompletedRatio;
    this.usageId = usageId;
  }
}
