import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CompanyTypeService } from 'src/app/service/company-type/company-type.service';
import { CompanyType } from 'src/app/model/companyType';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';


@Component({
  selector: 'app-update-company-type',
  templateUrl: './update-company-type.component.html',
  styleUrls: ['./update-company-type.component.scss']
})
export class UpdateCompanyTypeComponent implements OnInit, OnDestroy {

  updateCompanyTypeForm: FormGroup;

  description: string;
  private sub = new SubSink();
  @Output() companytypeUpdated = new EventEmitter<boolean>();

  constructor(private formBuilder: FormBuilder, private companyTypeService: CompanyTypeService, private notifier: NotifierService) { }

  ngOnInit() {
    this.updateCompanyTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required]],
      description: ['']
    })
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  updateCompanyType() {
    if (this.updateCompanyTypeForm.valid) {
      const companyType = new CompanyType();
      companyType.description = this.getDescription();
      companyType.name = this.getName();
      this.sub.add(this.companyTypeService.updateCompanyType(this.getId(), companyType)
        .subscribe(response => {
          this.updateTheView(true);
          this.notifier.notify('success', (response as any).message);
        }));
    }
  }

  getId() {
    return this.updateCompanyTypeForm.get('id').value;
  }
  getName(){
    return this.updateCompanyTypeForm.get('name').value;
  }
  setIdAndDescription(id: number,name:string, description: string) {
    this.updateCompanyTypeForm.get('id').setValue(id);
    this.updateCompanyTypeForm.get('name').setValue(name);
    this.updateCompanyTypeForm.get('description').setValue(description);
  }

  getDescription() {
    return this.updateCompanyTypeForm.get('description').value;
  }

  updateTheView(updated: boolean) {
    this.companytypeUpdated.emit(updated);
  }

}



