import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Constant } from 'src/app/util/constant';
import { NotifierService } from 'angular-notifier';
import { InspectionCommonService } from 'src/app/service/inspection-common/inspection-common.service';
import { InspectionService } from 'src/app/service/inspection/inspection.service';
import { InspectionCommon } from 'src/app/model/inspection-common';
import { ActivatedRoute } from '@angular/router';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { InspectionLand } from 'src/app/model/inspection-land';
import { InspectionCondominium } from 'src/app/model/inspection-condominium';
import { InspectionLandBuilding } from 'src/app/model/inspection-land-building';
import { ValuationRequestService } from 'src/app/service/valuation-request/valuation-request.service';
declare let jsPDF;
import FileSaver from "file-saver";
import { checkCompletedOrPaypend } from '../../wizard-internal-user/view-requests-internal-user/Util/checkStatus';

@Component({
  selector: 'app-exchange-inspection-main',
  templateUrl: './exchange-inspection-main.component.html',
  styleUrls: ['./exchange-inspection-main.component.scss']
})
export class ExchangeInspectionMainComponent implements OnInit {

  @Input()
  valuationRequestId: string;

  @Input()
  valuationRequestId2: string;

  @Input()
  isReject:boolean;

  @Input()
  valuationRequestStatus: string;

  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() status: EventEmitter<any> = new EventEmitter<any>();

  @Output() selectedParty:EventEmitter<any> = new EventEmitter<any>();

  @Output() selectedType:EventEmitter<any> = new EventEmitter<any>();

  public propertyTypeList: any = [];
  public propertySubTypeList: any = [];
  public filteredPropertySubTypeList: any = [];

  public subPropertyTypeDisabled: boolean;

  public disableGeneratePage: boolean;

  public redirectPageNumber: number;

  public inspectionList: any = [];
  public inspectionList2: any = [];

  public inspectionCommonModel = new InspectionCommon('');
  public inspectionCommonModel2 = new InspectionCommon('');

  public recreationCommonModel = new InspectionCommon('');

  public fileNumberFormat: string;

  // Multi Select Dropdown Settings
  dropdownSettings1: NgMultiSelectDropDownModule;

  // Selected dropdown values
  selectedSubPropertyTypeItems = [];

  filteredSelectedSubPropertyTypeItems = [];

  public filteredSubPropertyTypeRecreationList: any = [];

  public selectedRecreationType: boolean;

  public modelLand = new InspectionLand();

  public modelCondominium = new InspectionCondominium();
  // Selected dropdown values
  selectedFacilityTypeItems = [];
  selectedSpecialViewItems = [];
  selectedSurroundTypeItems = [];

  public modelBuilding = new InspectionLandBuilding('', '', '', [], '', [], null);

  public recreationlandreport: string;
  public recreationcondominiumreport: string;
  public recreationbuildingreport: string;

  public generatedFileNumber: string;
  valuationdata: any = [];
  appealCount: number;
  checkStatusApplication  = checkCompletedOrPaypend;


  constructor(
    public inspectionCommonService: InspectionCommonService,
    public inspectionService: InspectionService,
    private route: ActivatedRoute,
    private valuationRequestService:ValuationRequestService,
    private notifier: NotifierService
  ) {
    this.subPropertyTypeDisabled = true;
    this.redirectPageNumber = 10;
    this.disableGeneratePage = true;
    this.selectedRecreationType = false;
    this.recreationlandreport = null;
    this.recreationcondominiumreport = null;
    this.recreationbuildingreport = null;
    this.appealCount =0;
  }

  ngOnInit() {
    this._prepare();
  }

  pad(num: number, size: number) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    this.generatedFileNumber = s;
    this.generatedFileNumber = '#VR' + this.generatedFileNumber;
  }

  getAppealCount(){
    this.valuationRequestService.getAppealCount(this.route.snapshot.paramMap.get('id')).subscribe(data=>{
      this.appealCount = data.data;
    })
  }

  retrieveInspectionLandDetails(inspectionId: any) {
    this.inspectionCommonModel.code = inspectionId;
    this.inspectionService.inspectionLandDetailList(this.inspectionCommonModel).subscribe(
      data => {
        localStorage.setItem('viewlandwithbuildingdata', JSON.stringify(false));
        localStorage.setItem('landwithbuilding', JSON.stringify(false));
        localStorage.setItem('inspectionId', btoa(inspectionId));

        localStorage.setItem("isLand", JSON.stringify(false));
        localStorage.setItem("isCondominium", JSON.stringify(false));
        localStorage.setItem("inspectioncondominiummodel", btoa(JSON.stringify(null)));
        localStorage.setItem("inspectionlandmodel", btoa(JSON.stringify(null)));
        localStorage.setItem('landwithbuilding', JSON.stringify(false));
        localStorage.setItem('recreationupdatereq', JSON.stringify(null));
        localStorage.setItem('selectedRecreationType', btoa(JSON.stringify(false)));

        localStorage.setItem('selectedRecreationUpdateType', btoa(JSON.stringify(false)));

        localStorage.setItem('recreationLandInspectionId', btoa(undefined));
        localStorage.setItem('recreationCondominiumInspectionId', btoa(undefined));
        localStorage.setItem('recreationLandBuildingInspectionId', btoa(undefined));

        const filteredPropertyType = this.propertyTypeList.filter(i => i.id === Number(data.data.propertyTypeId));
        localStorage.setItem('propertyType', btoa(JSON.stringify(filteredPropertyType[0])));
        const filteredSubPropertyType = this.propertySubTypeList.filter(i => i.id === Number(data.data.propertySubTypeId));
        localStorage.setItem('subPropertyType', btoa(JSON.stringify(filteredSubPropertyType[0])));
        if (data.data.inspectionType === Constant.INSPECTION_TYPE_LAND) {
          this.onPageCountChanged.emit(11);
        } else if (data.data.inspectionType === Constant.INSPECTION_TYPE_CONDOMINIUM) {
          this.onPageCountChanged.emit(12);
        } else if (data.data.inspectionType === Constant.INSPECTION_TYPE_BUILDING) {
          this.onPageCountChanged.emit(13);
        } else if (data.data.inspectionType === Constant.INSPECTION_TYPE_RECREATION_LAND) {
          localStorage.setItem('propertyType', btoa(JSON.stringify(null)));
          localStorage.setItem('subPropertyType', btoa(JSON.stringify(null)));

          this.recreationCommonModel.code = inspectionId;
          this.inspectionService.inspectionRecreationChildList(this.recreationCommonModel).subscribe(
            data => {
              localStorage.setItem('inspectionId', btoa(undefined));
              localStorage.setItem('recreationLandInspectionId', btoa(inspectionId));
              localStorage.setItem('recreationCondominiumInspectionId', btoa(undefined));
              localStorage.setItem('recreationLandBuildingInspectionId', btoa(undefined));
              localStorage.setItem('selectedRecreationType', btoa(JSON.stringify(false)));
              localStorage.setItem('selectedRecreationUpdateType', btoa(JSON.stringify(true)));
              localStorage.setItem('recreationupdatereq', btoa(JSON.stringify(data.data)));
              this.onPageCountChanged.emit(11);
            }
          )
        } else if (data.data.inspectionType === Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM) {
          localStorage.setItem('propertyType', btoa(JSON.stringify(null)));
          localStorage.setItem('subPropertyType', btoa(JSON.stringify(null)));

          this.recreationCommonModel.code = inspectionId;
          this.inspectionService.inspectionRecreationChildList(this.recreationCommonModel).subscribe(
            data => {
              localStorage.setItem('inspectionId', btoa(undefined));
              localStorage.setItem('recreationLandInspectionId', btoa(undefined));
              localStorage.setItem('recreationCondominiumInspectionId', btoa(inspectionId));
              localStorage.setItem('recreationLandBuildingInspectionId', btoa(undefined));
              localStorage.setItem('selectedRecreationType', btoa(JSON.stringify(false)));
              localStorage.setItem('selectedRecreationUpdateType', btoa(JSON.stringify(true)));
              localStorage.setItem('recreationupdatereq', btoa(JSON.stringify(data.data)));
              this.onPageCountChanged.emit(12);
            }
          )
        } else if (data.data.inspectionType === Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING) {
          localStorage.setItem('propertyType', btoa(JSON.stringify(null)));
          localStorage.setItem('subPropertyType', btoa(JSON.stringify(null)));

          this.recreationCommonModel.code = inspectionId;
          this.inspectionService.inspectionRecreationChildList(this.recreationCommonModel).subscribe(
            data => {
              localStorage.setItem('inspectionId', btoa(undefined));
              localStorage.setItem('recreationLandInspectionId', btoa(undefined));
              localStorage.setItem('recreationCondominiumInspectionId', btoa(undefined));
              localStorage.setItem('recreationLandBuildingInspectionId', btoa(inspectionId));
              localStorage.setItem('selectedRecreationType', btoa(JSON.stringify(false)));
              localStorage.setItem('selectedRecreationUpdateType', btoa(JSON.stringify(true)));
              localStorage.setItem('recreationupdatereq', btoa(JSON.stringify(data.data)));
              this.onPageCountChanged.emit(13);
            }
          )
        }
      }
    )
  }

  public checkStatus(type:boolean): void{
    this.status.emit(type);
  }

  generateReport(content: any) {
    if (content.inspectionType === Constant.INSPECTION_TYPE_LAND) {
      this.generateReportForLand(content.id);
    } else if (content.inspectionType === Constant.INSPECTION_TYPE_CONDOMINIUM) {
      this.generateReportForCondominium(content.id);
    } else if (content.inspectionType === Constant.INSPECTION_TYPE_BUILDING) {
      this.generateReportForBuilding(content.id);
    } else if (content.inspectionType === Constant.INSPECTION_TYPE_RECREATION_LAND) {
      this.generateReportForRecreation(content.id);
    } else if (content.inspectionType === Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM) {
      this.generateReportForRecreation(content.id);
    } else if (content.inspectionType === Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING) {
      this.generateReportForRecreation(content.id);
    }
  }

  generateReportForLand(inspectionId: any) {
    this.inspectionCommonModel.code = inspectionId;
    this.inspectionService.inspectionLandDetailList(this.inspectionCommonModel).subscribe(
      data => {
        this.modelLand = data.data;
        this.landReport(this.modelLand);
      }
    )
  }

  downloadInspectionReport(inspection:any){
    var reportType;
    switch(inspection.inspectionType){
      case Constant.INSPECTION_TYPE_LAND:
        reportType = "land";
        break;
      case Constant.INSPECTION_TYPE_CONDOMINIUM:
        reportType = "condominium";
        break;
      case Constant.INSPECTION_TYPE_BUILDING:
        reportType = "landWithBuilding";
        break;
      default:
        reportType = "recreation"
        break;
    }

    this.inspectionService.downloadInspectionReport(inspection.valuationRequestId, inspection.id, reportType).subscribe(response=>{
      let blob: any = new Blob([response], {type: 'application/pdf'});
      FileSaver.saveAs(blob, "Inspection_Report_"+ reportType+"_"+ inspection.valuationRequestId,+"_"+inspection.id+ ".pdf");
    }), error => console.log('Error downloading the file'),
    () => console.info('File downloaded successfully');

  }

  landReport(landDetails: any) {
    const doc = new jsPDF();
    doc.setFontStyle("bold");
    doc.text("Inspection Request.", 105, 20, null, null, "center");
    doc.setFontSize(10);
    doc.text("Land Request", 20, 30)
    doc.setFont("times");
    doc.setFontType('normal');
    doc.text("Property Type ID", 20, 40);
    doc.text(landDetails.propertyTypeId !== null ? landDetails.propertyTypeId : "--", 100, 40);
    doc.text("Property Sub Type ID", 20, 50);
    doc.text(landDetails.propertySubTypeId !== null ? landDetails.propertySubTypeId : "--", 100, 50);
    doc.text("Valuation Request ID", 20, 60);
    doc.text(landDetails.valuationRequestId !== null ? landDetails.valuationRequestId : "--", 100, 60);
    doc.text("Inspection Type", 20, 70);
    doc.text(landDetails.inspectionType !== null ? landDetails.inspectionType : "--", 100, 70);
    doc.text("Created by", 20, 80);
    doc.text(landDetails.createdBy !== null ? landDetails.createdBy : "--", 100, 80);
    doc.text("Plan No", 20, 90);
    doc.text(landDetails.planNo !== null ? landDetails.planNo : "--", 100, 90);
    doc.text("Lot No(s) / Condominium Unit No(s)", 20, 100);
    doc.text(landDetails.lotNo !== null ? landDetails.lotNo : "--", 100, 100);
    doc.text("Building line", 20, 110);
    doc.text(landDetails.buildingLine !== null ? landDetails.buildingLine : "--", 100, 110);
    doc.text("Street line", 20, 120);
    doc.text(landDetails.streetLine !== null ? landDetails.streetLine : "--", 100, 120);
    doc.text("Restriction", 20, 130);
    doc.text(landDetails.restriction !== null ? landDetails.restriction : "--", 100, 130);
    doc.text("Reservation ID", 20, 140);
    doc.text(landDetails.reservationId !== null ? landDetails.reservationId : "--", 100, 140);
    doc.text("Central Government Rules", 20, 150);
    doc.text(landDetails.centralGovernmentRules !== null ? landDetails.centralGovernmentRules : "--", 100, 150);
    doc.text("Local Government Rules", 20, 160);
    doc.text(landDetails.localGovernmentRules !== null ? landDetails.localGovernmentRules : "--", 100, 160);
    doc.text("UDA regulations", 20, 170);
    doc.text(landDetails.udaRegulations !== null ? landDetails.udaRegulations : "--", 100, 170);
    doc.text("Other Rules", 20, 180);
    doc.text(landDetails.otherRules !== null ? landDetails.otherRules : "--", 100, 180);
    doc.text("Assessment No", 20, 190);
    doc.text(landDetails.assessmentNo !== null ? landDetails.assessmentNo : "--", 100, 190);
    doc.text("Nearest Town", 20, 200);
    doc.text(landDetails.nearestTown !== null ? landDetails.nearestTown : "--", 100, 200);
    doc.text("Access Nearest Town", 20, 210);
    doc.text(landDetails.accessNearestTown !== null ? landDetails.accessNearestTown : "--", 100, 210);
    doc.text("Distance Main Road", 20, 220);
    doc.text(landDetails.distanceMainRd !== null ? landDetails.distanceMainRd : "--", 100, 220);
    doc.text("Land Mark", 20, 230);
    doc.text(landDetails.landMark !== null ? landDetails.landMark : "--", 100, 230);
    doc.text("Road Width", 20, 240);
    doc.text(landDetails.roadWidth !== null ? landDetails.roadWidth : "--", 100, 240);
    doc.text("Land Extent", 20, 250);
    doc.text(landDetails.landExtent !== null ? landDetails.landExtent : "--", 100, 250);
    doc.text("Land Shape ID", 20, 260);
    doc.text(landDetails.landShapeId !== null ? landDetails.landShapeId : "--", 100, 260);
    doc.text("Road Frontage", 20, 270);
    doc.text(landDetails.roadFrontage !== null ? landDetails.roadFrontage : "--", 100, 270);
    doc.text("Length", 20, 280);
    doc.text(landDetails.length !== null ? landDetails.length : "--", 100, 280);
    doc.text("Width", 20, 290);
    doc.text(landDetails.width !== null ? landDetails.width : "--", 100, 290);

    doc.addPage();

    doc.setFont("times");
    doc.setFontType('normal');
    doc.text("Land Location ID", 20, 40);
    doc.text(landDetails.landLocationId !== null ? landDetails.landLocationId : "--", 100, 40);
    doc.text("Land Slope ID", 20, 50);
    doc.text(landDetails.landSlopeId !== null ? landDetails.landSlopeId : "--", 100, 50);
    doc.text("Water Availability", 20, 60);
    doc.text(landDetails.waterAvailabilityId !== null ? landDetails.waterAvailabilityId : "--", 100, 60);
    doc.text("Water ID", 20, 70);
    doc.text(landDetails.waterId !== null ? landDetails.waterId : "--", 100, 70);
    doc.text("Boundary North", 20, 80);
    doc.text(landDetails.boundaryNorth !== null ? landDetails.boundaryNorth : "--", 100, 80);
    doc.text("Boundary South", 20, 90);
    doc.text(landDetails.boundarySouth !== null ? landDetails.boundarySouth : "--", 100, 90);
    doc.text("Boundary West", 20, 100);
    doc.text(landDetails.boundaryWest !== null ? landDetails.boundaryWest : "--", 100, 100);
    doc.text("Boundary East", 20, 110);
    doc.text(landDetails.boundaryEast !== null ? landDetails.boundaryEast : "--", 100, 110);
    doc.text("Electricity Type ID", 20, 120);
    doc.text(landDetails.electricityTypeId !== null ? landDetails.electricityTypeId : "--", 100, 120);
    doc.text("Electricity Sub Type ID", 20, 130);
    doc.text(landDetails.electricitySubTypeId !== null ? landDetails.electricitySubTypeId : "--", 100, 130);
    doc.text("Land Suitability ID", 20, 140);
    doc.text(landDetails.landSuitabilityId !== null ? landDetails.landSuitabilityId : "--", 100, 140);
    doc.text("Cultivation", 20, 150);
    doc.text(landDetails.cultivation !== null ? landDetails.cultivation : "--", 100, 150);
    doc.text("Transport ID", 20, 160);
    doc.text(landDetails.transportId !== null ? landDetails.transportId : "--", 100, 160);
    doc.text("Education ID", 20, 170);
    doc.text(landDetails.educationId !== null ? landDetails.educationId : "--", 100, 170);
    doc.text("Health ID", 20, 180);
    doc.text(landDetails.healthId !== null ? landDetails.healthId : "--", 100, 180);
    doc.text("Industrial ID", 20, 190);
    doc.text(landDetails.industrialId !== null ? landDetails.industrialId : "--", 100, 190);
    doc.text("Security ID", 20, 200);
    doc.text(landDetails.securityId !== null ? landDetails.securityId : "--", 100, 200);
    doc.text("Telecommunication ID", 20, 210);
    doc.text(landDetails.telecommunicationId !== null ? landDetails.telecommunicationId : "--", 100, 210);
    doc.text("Drainage Facility Availability", 20, 220);
    doc.text(landDetails.drainageFacilityAvailability !== null ? landDetails.drainageFacilityAvailability : "--", 100, 220);
    doc.text("Garbage Disposal Availability", 20, 230);
    doc.text(landDetails.garbageDisposalAvailability !== null ? landDetails.garbageDisposalAvailability : "--", 100, 230);
    doc.text("Community", 20, 240);
    doc.text(landDetails.community !== null ? landDetails.community : "--", 100, 240);
    doc.text("Neighbourhood", 20, 250);
    doc.text(landDetails.neighbourhood !== null ? landDetails.neighbourhood : "--", 100, 250);
    doc.text("Bus Route", 20, 260);
    doc.text(landDetails.busRoute !== null ? landDetails.busRoute : "--", 100, 260);
    doc.text("Railways", 20, 270);
    doc.text(landDetails.railways !== null ? landDetails.railways : "--", 100, 270);

    doc.save('landrequest.pdf');
  }

  generateReportForCondominium(inspectionId: any) {
    this.inspectionCommonModel.code = inspectionId;
    this.inspectionService.inspectionCondominimumDetailList(this.inspectionCommonModel).subscribe(
      data => {
        this.modelCondominium = data.data;
        this.selectedFacilityTypeItems = this.modelCondominium.facilityTypes;
        this.selectedSpecialViewItems = this.modelCondominium.specialViewTypes;
        this.selectedSurroundTypeItems = this.modelCondominium.surroundTypes;
        this.condominiumReport(this.modelCondominium);
      }
    )
  }

  condominiumReport(landDetails: any) {
    const doc = new jsPDF();
    doc.setFontStyle("bold");
    doc.text("Inspection Request.", 105, 20, null, null, "center");
    doc.setFontSize(10);
    doc.text("Condominium Request", 20, 30)
    doc.setFont("times");
    doc.setFontType('normal');
    doc.text("Property Type ID", 20, 40);
    doc.text(landDetails.propertyTypeId !== null ? landDetails.propertyTypeId : "--", 100, 40);
    doc.text("Property Sub Type ID", 20, 50);
    doc.text(landDetails.propertySubTypeId !== null ? landDetails.propertySubTypeId : "--", 100, 50);
    doc.text("Valuation Request ID", 20, 60);
    doc.text(landDetails.valuationRequestId !== null ? landDetails.valuationRequestId : "--", 100, 60);
    doc.text("Inspection Type", 20, 70);
    doc.text(landDetails.inspectionType !== null ? landDetails.inspectionType : "--", 100, 70);
    doc.text("Created by", 20, 80);
    doc.text(landDetails.createdBy !== null ? landDetails.createdBy : "--", 100, 80);
    doc.text("Plan No", 20, 90);
    doc.text(landDetails.planNo !== null ? landDetails.planNo : "--", 100, 90);
    doc.text("Lot No(s) / Condominium Unit No(s)", 20, 100);
    doc.text(landDetails.lotNo !== null ? landDetails.lotNo : "--", 100, 100);
    doc.text("Condominium Name", 20, 110);
    doc.text(landDetails.condominiumName !== null ? landDetails.condominiumName : "--", 100, 110);
    doc.text("Assessment No", 20, 120);
    doc.text(landDetails.assessmentNo !== null ? landDetails.assessmentNo : "--", 100, 120);
    doc.text("Town ID", 20, 130);
    doc.text(landDetails.townId !== null ? landDetails.townId : "--", 100, 130);
    doc.text("Main Road", 20, 140);
    doc.text(landDetails.mainRoad !== null ? landDetails.mainRoad : "--", 100, 140);
    doc.text("Distance Main Road", 20, 150);
    doc.text(landDetails.distanceMainRd !== null ? landDetails.distanceMainRd : "--", 100, 150);
    doc.text("District ID", 20, 160);
    doc.text(landDetails.districtId !== null ? landDetails.districtId : "--", 100, 160);
    doc.text("Local Authority ID", 20, 170);
    doc.text(landDetails.localAuthorityId !== null ? landDetails.localAuthorityId : "--", 100, 170);
    doc.text("Use of Condominium", 20, 180);
    doc.text(landDetails.useOfCondominium !== null ? landDetails.useOfCondominium : "--", 100, 180);
    doc.text("Plan Number", 20, 190);
    doc.text(landDetails.planNumber !== null ? landDetails.planNumber : "--", 100, 190);
    doc.text("Date Period", 20, 200);
    doc.text(landDetails.datePeriod !== null ? landDetails.datePeriod : "--", 100, 200);
    doc.text("Survey Name", 20, 210);
    doc.text(landDetails.surveyName !== null ? landDetails.surveyName : "--", 100, 210);
    doc.text("Land Extent", 20, 220);
    doc.text(landDetails.landExtent !== null ? landDetails.landExtent : "--", 100, 220);
    doc.text("No of floors", 20, 230);
    doc.text(landDetails.noOfFloors !== null ? landDetails.noOfFloors : "--", 100, 230);
    doc.text("No of units", 20, 240);
    doc.text(landDetails.noOfUnits !== null ? landDetails.noOfUnits : "--", 100, 240);
    doc.text("Floors and Units", 20, 250);
    doc.text(landDetails.floorsAndUnits !== null ? landDetails.floorsAndUnits : "--", 100, 250);
    doc.text("Other units", 20, 260);
    doc.text(landDetails.otherUnits !== null ? landDetails.otherUnits : "--", 100, 260);
    doc.text("Constructed Year", 20, 270);
    doc.text(landDetails.constructedYear !== null ? landDetails.constructedYear : "--", 100, 270);
    doc.text("Parking ID", 20, 280);
    doc.text(landDetails.parkingId !== null ? landDetails.parkingId : "--", 100, 280);
    doc.text("Security ID", 20, 290);
    doc.text(landDetails.securityId !== null ? landDetails.securityId : "--", 100, 290);

    doc.addPage();

    doc.setFont("times");
    doc.setFontType('normal');
    doc.text("Unit No", 20, 40);
    doc.text(landDetails.unitNo !== null ? landDetails.unitNo : "--", 100, 40);
    doc.text("Extent", 20, 50);
    doc.text(landDetails.extent !== null ? landDetails.extent : "--", 100, 50);
    doc.text("Garden ID", 20, 60);
    doc.text(landDetails.gardenId !== null ? landDetails.gardenId : "--", 100, 60);
    doc.text("Water ID", 20, 70);
    doc.text(landDetails.waterId !== null ? landDetails.waterId : "--", 100, 70);
    doc.text("Electricity ID", 20, 80);
    doc.text(landDetails.electricityId !== null ? landDetails.electricityId : "--", 100, 80);
    doc.text("Drainage", 20, 90);
    doc.text(landDetails.drainage !== null ? landDetails.drainage : "--", 100, 90);
    doc.text("Gas Lines", 20, 100);
    doc.text(landDetails.gasLines !== null ? landDetails.gasLines : "--", 100, 100);
    doc.text("Accomadation Details", 20, 110);
    doc.text(landDetails.accomadationDetails !== null ? landDetails.accomadationDetails : "--", 100, 110);
    doc.text("Other Residencies", 20, 120);
    doc.text(landDetails.otherResidencies !== null ? landDetails.otherResidencies : "--", 100, 120);
    doc.text("Income Level ID", 20, 130);
    doc.text(landDetails.incomeLevelId !== null ? landDetails.incomeLevelId : "--", 100, 130);
    doc.text("Management Cost", 20, 140);
    doc.text(landDetails.managementCost !== null ? landDetails.managementCost : "--", 100, 140);
    doc.text("Maintainance", 20, 150);
    doc.text(landDetails.maintainance !== null ? landDetails.maintainance : "--", 100, 150);
    doc.text("Rates", 20, 160);
    doc.text(landDetails.rates !== null ? landDetails.rates : "--", 100, 160);
    doc.text("Net Annual Rent", 20, 170);
    doc.text(landDetails.netAnnualRent !== null ? landDetails.netAnnualRent : "--", 100, 170);
    doc.text("Undivided Shares", 20, 180);
    doc.text(landDetails.undividedShares !== null ? landDetails.undividedShares : "--", 100, 180);
    doc.text("Other", 20, 190);
    doc.text(landDetails.other !== null ? landDetails.other : "--", 100, 190);
    doc.text("Agreement Type ID", 20, 200);
    doc.text(landDetails.agreementTypeId !== null ? landDetails.agreementTypeId : "--", 100, 200);
    doc.text("Sales Agreement No", 20, 210);
    doc.text(landDetails.salesAgreementNo !== null ? landDetails.salesAgreementNo : "--", 100, 210);
    doc.text("Notary", 20, 220);
    doc.text(landDetails.notary !== null ? landDetails.notary : "--", 100, 220);
    doc.text("Date ", 20, 230);
    doc.text(landDetails.date !== null ? landDetails.date : "--", 100, 230);
    doc.text("Value", 20, 240);
    doc.text(landDetails.value !== null ? landDetails.value : "--", 100, 240);

    doc.save('condominiumrequest.pdf');
  }

  generateReportForBuilding(inspectionId: any) {
    this.inspectionCommonModel.code = inspectionId;
    this.inspectionService.inspectionLandBuildingDetailList(this.inspectionCommonModel).subscribe(
      data => {
        this.modelBuilding = data.data;
        this.buildingReport(this.modelBuilding);
      }
    )
  }

  generateReportForRecreation(inspectionId: any) {
    this.inspectionCommonModel.code = inspectionId;
    this.inspectionService.inspectionRecreationChildList(this.inspectionCommonModel).subscribe(
      data => {
        const filteredland = data.data.filter(i => i.inspectionType === Constant.INSPECTION_TYPE_RECREATION_LAND);
        const filteredcondomoinium = data.data.filter(i => i.inspectionType === Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM);
        const filteredbuilding = data.data.filter(i => i.inspectionType === Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING);
        if (JSON.stringify(filteredland) !== '[]') {
          this.inspectionCommonModel.code = filteredland[0]['inspectionId'];
          this.inspectionService.inspectionLandDetailList(this.inspectionCommonModel).subscribe(
            data => {
              this.recreationlandreport = data.data;
            }
          )
        }

        if (JSON.stringify(filteredcondomoinium) !== '[]') {
          this.inspectionCommonModel.code = filteredcondomoinium[0]['inspectionId'];
          this.inspectionService.inspectionCondominimumDetailList(this.inspectionCommonModel).subscribe(
            data => {
              this.recreationcondominiumreport = data.data;
            }
          )
        }

        if (JSON.stringify(filteredbuilding) !== '[]') {
          this.inspectionCommonModel.code = filteredbuilding[0]['inspectionId'];
          this.inspectionService.inspectionLandBuildingDetailList(this.inspectionCommonModel).subscribe(
            data => {
              this.recreationbuildingreport = data.data;
            }
          )
        }
        this.recreationReport(this.recreationlandreport, this.recreationcondominiumreport, this.recreationbuildingreport);

      }
    )
  }

  buildingReport(landDetails: any) {
    const doc = new jsPDF();
    doc.setFontStyle("bold");
    doc.text("Inspection Request.", 105, 20, null, null, "center");
    doc.setFontSize(10);
    doc.text("Land with Building Request", 20, 30)
    doc.setFont("times");
    doc.setFontType('normal');
    doc.text("Property Type ID", 20, 40);
    doc.text(landDetails.propertyTypeId !== null ? landDetails.propertyTypeId : "--", 100, 40);
    doc.text("Property Sub Type ID", 20, 50);
    doc.text(landDetails.propertySubTypeId !== null ? landDetails.propertySubTypeId : "--", 100, 50);
    doc.text("Valuation Request ID", 20, 60);
    doc.text(landDetails.valuationRequestId !== null ? landDetails.valuationRequestId : "--", 100, 60);
    doc.text("Inspection Type", 20, 70);
    doc.text(landDetails.inspectionType !== null ? landDetails.inspectionType : "--", 100, 70);
    doc.text("Created by", 20, 80);
    doc.text(landDetails.createdBy !== null ? landDetails.createdBy : "--", 100, 80);
    doc.text("Category Property ID", 20, 90);
    doc.text(landDetails.categoryPropertyId !== null ? landDetails.categoryPropertyId : "--", 100, 90);
    doc.text("Building No", 20, 100);
    doc.text(landDetails.buildingNo !== null ? landDetails.buildingNo : "--", 100, 100);
    doc.text("Building Name", 20, 110);
    doc.text(landDetails.buildingName !== null ? landDetails.buildingName : "--", 100, 110);
    doc.text("No of Building", 20, 120);
    doc.text(landDetails.noOfBuildings !== null ? landDetails.noOfBuildings : "--", 100, 120);
    doc.text("Total Completion", 20, 130);
    doc.text(landDetails.totalCompletion !== null ? landDetails.totalCompletion : "--", 100, 130);
    doc.text("Materials", 20, 140);
    doc.text(landDetails.materials !== null ? landDetails.materials : "--", 100, 140);
    doc.text("Fittings", 20, 150);
    doc.text(landDetails.fittings !== null ? landDetails.fittings : "--", 100, 150);
    doc.text("Kitchen Pantry", 20, 160);
    doc.text(landDetails.kitchenPantry !== null ? landDetails.kitchenPantry : "--", 100, 160);
    doc.text("Pantry Cupboards", 20, 170);
    doc.text(landDetails.pantryCupboards !== null ? landDetails.pantryCupboards : "--", 100, 170);
    doc.text("Inspection No of Buildings", 20, 180);
    doc.text(landDetails.inspectionNoOfBuildings !== null ? landDetails.inspectionNoOfBuildings : "--", 100, 180);
    doc.text("Incompleted Floors", 20, 190);
    doc.text(landDetails.incompletedFloors !== null ? landDetails.incompletedFloors : "--", 100, 190);
    doc.text("Completed Floors", 20, 200);
    doc.text(landDetails.completedFloors !== null ? landDetails.completedFloors : "--", 100, 200);
    doc.text("Total Completed Ratio", 20, 210);
    doc.text(landDetails.totalCompletedRatio !== null ? landDetails.totalCompletedRatio : "--", 100, 210);
    doc.text("Usage ID", 20, 220);
    doc.text(landDetails.usageId !== null ? landDetails.usageId : "--", 100, 220);

    doc.save('landbuildingrequest.pdf');
  }

  _prepare() {
    this.getAppealCount();
    this.fileNumberFormat = localStorage.getItem('fileNumberFormat');
    this.valuationdata = JSON.parse(sessionStorage.getItem('valuationRequest'));
    this.pad(this.valuationdata.id, 7);
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      unSelectAllText: "Unselect"
    };
    this.inspectionCommonModel.code = this.valuationRequestId;
    this.inspectionCommonModel2.code = this.valuationRequestId2;
    this.loadPropertyType();
    this.loadPropertySubType();
    this.retrieveInspectionList();
    this.retrieveInspectionList2();
    this.checkStatus(true)
  }

  retrieveInspectionList() {
    this.inspectionService.inspectionList(this.inspectionCommonModel).subscribe(
      data => {
        this.inspectionList = data.data;
      }
    )
  }

  retrieveInspectionList2() {
    this.inspectionService.inspectionList(this.inspectionCommonModel2).subscribe(
      data => {
        this.inspectionList2 = data.data;
      }
    )
  }

  loadPropertyType() {
    this.inspectionCommonService.propertyUsageTypeList().subscribe(
      data => {
        this.propertyTypeList = data.data;
        const filtereddata = this.propertyTypeList.filter(i => i.id !== Constant.INSPECTION_PROPERTY_TYPE_RECREATION_ID);
        this.filteredSubPropertyTypeRecreationList = filtereddata;
      }
    )
  }

  loadPropertySubType() {
    this.inspectionCommonService.propertyUsageSubTypeList().subscribe(
      data => {
        this.propertySubTypeList = data.data;
        this.filteredPropertySubTypeList = data.data;
      }
    )
  }

  selectedPropertyType(event) {
    if (Constant.INSPECTION_PROPERTY_TYPE_RECREATION_ID === Number(event)) {
      this.selectedRecreationType = true;
      this.disableGeneratePage = false;
    } else {
      this.selectedRecreationType = false;
      const filteredPropertyType = this.propertyTypeList.filter(i => i.id === Number(event));
      localStorage.setItem('propertyType', btoa(JSON.stringify(filteredPropertyType[0])));
      this.filteredPropertySubTypeList = this.propertySubTypeList;
      const filtereddata = this.filteredPropertySubTypeList.filter(i => i.inspectionPropertyType.id === Number(event));
      this.filteredPropertySubTypeList = filtereddata;
      this.subPropertyTypeDisabled = false;
      if (Number(event) === 1) {
        this.redirectPageNumber = 11;
      } else if (Number(event) === 2) {
        this.redirectPageNumber = 12;
      } else if (Number(event) === 3) {
        this.redirectPageNumber = 13;
      }
    }
  }

  selectedSubPropertyType(event) {
    this.disableGeneratePage = false;
    const filteredSubPropertyType = this.propertySubTypeList.filter(i => i.id === Number(event));
    localStorage.setItem('subPropertyType', btoa(JSON.stringify(filteredSubPropertyType[0])));
  }

  public redirectBack() {
    this.onPageCountChanged.emit(9);
  }

  public redirectComponentErrorMessage() {
    this.notifier.notify('error', 'Please select property type and sub property type to continue.');
  }

  public redirectComponent(): void {
    if (this.disableGeneratePage == true) {
      this.notifier.notify('error', 'Please select a Property type and a Sub Property type');
    } else {
      localStorage.setItem("isLand", JSON.stringify(false));
      localStorage.setItem("isCondominium", JSON.stringify(false));
      localStorage.setItem("inspectioncondominiummodel", btoa(JSON.stringify(null)));
      localStorage.setItem("inspectionlandmodel", btoa(JSON.stringify(null)));
      localStorage.setItem('landwithbuilding', JSON.stringify(false));
      localStorage.setItem('recreationupdatereq', JSON.stringify(null));
      localStorage.setItem('selectedRecreationUpdateType', btoa(JSON.stringify(false)));
      localStorage.setItem('recreationLandInspectionId', btoa(undefined));
      localStorage.setItem('recreationCondominiumInspectionId', btoa(undefined));
      localStorage.setItem('recreationLandBuildingInspectionId', btoa(undefined));
      localStorage.setItem('inspectionId', btoa(undefined));
      localStorage.setItem('childinspectionId', btoa(undefined));

      // Used to control the current selected party
      // Check 'switchedToParty1()' and 'switchedToPart2()' functions in 'wizard-internal-exchange.component.ts'
      // Party is set to 1
      sessionStorage.setItem('selectedPartyInExchange', '1');

      if (this.selectedRecreationType) {
        this.filteredSelectedSubPropertyTypeItems = this.selectedSubPropertyTypeItems.sort((obj1, obj2) => {
          if (obj1.id > obj2.id) {
            return 1;
          }
          if (obj1.id < obj2.id) {
            return -1;
          }
          return 0;
        });
        for (let i = 0; i < this.filteredSelectedSubPropertyTypeItems.length; i++) {
          if (this.filteredSelectedSubPropertyTypeItems[i].id === 1) {
            this.redirectPageNumber = 11;
            break;
          } else if (this.filteredSelectedSubPropertyTypeItems[i].id === 2) {
            this.redirectPageNumber = 12;
            break;
          } else if (this.filteredSelectedSubPropertyTypeItems[i].id === 3) {
            this.redirectPageNumber = 13;
            break;
          }
        }
        // if (this.filteredSelectedSubPropertyTypeItems.length > 1) {
        // } else {
        //   this.notifier.notify('error', 'Please select atleast 2 types.');
        // }
        localStorage.setItem('propertyType', btoa(JSON.stringify(null)));
        localStorage.setItem('subPropertyType', btoa(JSON.stringify(null)));
        localStorage.setItem('selectedRecreationType', btoa(JSON.stringify(this.selectedRecreationType)));
        localStorage.setItem('selectedRecreationSubPropertyType', btoa(JSON.stringify(this.selectedSubPropertyTypeItems)));
        this.onPageCountChanged.emit(this.redirectPageNumber);
      } else {
        this.onPageCountChanged.emit(this.redirectPageNumber);
        localStorage.setItem('selectedRecreationType', btoa(JSON.stringify(false)));
        localStorage.setItem('selectedRecreationSubPropertyType', btoa(JSON.stringify(null)));
      }
    }

    // localStorage.setItem("isLand", JSON.stringify(false));
    // localStorage.setItem("isCondominium", JSON.stringify(false));
    // localStorage.setItem("inspectioncondominiummodel", btoa(JSON.stringify(null)));
    // localStorage.setItem("inspectionlandmodel", btoa(JSON.stringify(null)));
    // localStorage.setItem('landwithbuilding', JSON.stringify(false));
    // localStorage.setItem('recreationupdatereq', JSON.stringify(null));
    // localStorage.setItem('selectedRecreationUpdateType', btoa(JSON.stringify(false)));
    // localStorage.setItem('recreationLandInspectionId', btoa(undefined));
    // localStorage.setItem('recreationCondominiumInspectionId', btoa(undefined));
    // localStorage.setItem('recreationLandBuildingInspectionId', btoa(undefined));
    // localStorage.setItem('inspectionId', btoa(undefined));
    // if (this.selectedRecreationType) {
    //   this.filteredSelectedSubPropertyTypeItems = this.selectedSubPropertyTypeItems.sort((obj1, obj2) => {
    //     if (obj1.id > obj2.id) {
    //       return 1;
    //     }
    //     if (obj1.id < obj2.id) {
    //       return -1;
    //     }
    //     return 0;
    //   });
    //   if (this.filteredSelectedSubPropertyTypeItems.length > 0) {
    //     for (let i = 0; i < this.filteredSelectedSubPropertyTypeItems.length; i++) {
    //       if (this.filteredSelectedSubPropertyTypeItems[i].id === 1) {
    //         this.redirectPageNumber = 11;
    //         break;
    //       } else if (this.filteredSelectedSubPropertyTypeItems[i].id === 2) {
    //         this.redirectPageNumber = 12;
    //         break;
    //       } else if (this.filteredSelectedSubPropertyTypeItems[i].id === 3) {
    //         this.redirectPageNumber = 13;
    //         break;
    //       }
    //     }
    //   }
    //   localStorage.setItem('propertyType', btoa(JSON.stringify(null)));
    //   localStorage.setItem('subPropertyType', btoa(JSON.stringify(null)));
    //   localStorage.setItem('selectedRecreationType', btoa(JSON.stringify(this.selectedRecreationType)));
    //   localStorage.setItem('selectedRecreationSubPropertyType', btoa(JSON.stringify(this.selectedSubPropertyTypeItems)));
    //   this.onPageCountChanged.emit(this.redirectPageNumber);
    // } else {
    //   this.onPageCountChanged.emit(this.redirectPageNumber);
    //   localStorage.setItem('selectedRecreationType', btoa(JSON.stringify(false)));
    //   localStorage.setItem('selectedRecreationSubPropertyType', btoa(JSON.stringify(null)));
    // }
  }

  public selectedPartyNumber(party:any): void {
    this.selectedParty.emit(party);
  }

  public selectType(type:string): void {
    this.selectedType.emit(type);
  }

  public redirectToNextComponent(): void {
    this.onPageCountChanged.emit(14);
  }

  recreationReport(landDetails: any, condominiumDetails: any, landBuildingDetails: any) {
    const doc = new jsPDF();
    if (landDetails !== null) {
      doc.setFontStyle("bold");
      doc.text("Inspection Request.", 105, 20, null, null, "center");
      doc.setFontSize(10);
      doc.text("Land Request", 20, 30)
      doc.setFont("times");
      doc.setFontType('normal');
      doc.text("Property Type ID", 20, 40);
      doc.text(landDetails.propertyTypeId !== null ? landDetails.propertyTypeId : "--", 100, 40);
      doc.text("Property Sub Type ID", 20, 50);
      doc.text(landDetails.propertySubTypeId !== null ? landDetails.propertySubTypeId : "--", 100, 50);
      doc.text("Valuation Request ID", 20, 60);
      doc.text(landDetails.valuationRequestId !== null ? landDetails.valuationRequestId : "--", 100, 60);
      doc.text("Inspection Type", 20, 70);
      doc.text(landDetails.inspectionType !== null ? landDetails.inspectionType : "--", 100, 70);
      doc.text("Created by", 20, 80);
      doc.text(landDetails.createdBy !== null ? landDetails.createdBy : "--", 100, 80);
      doc.text("Plan No", 20, 90);
      doc.text(landDetails.planNo !== null ? landDetails.planNo : "--", 100, 90);
      doc.text("Lot No(s) / Condominium Unit No(s)", 20, 100);
      doc.text(landDetails.lotNo !== null ? landDetails.lotNo : "--", 100, 100);
      doc.text("Building line", 20, 110);
      doc.text(landDetails.buildingLine !== null ? landDetails.buildingLine : "--", 100, 110);
      doc.text("Street line", 20, 120);
      doc.text(landDetails.streetLine !== null ? landDetails.streetLine : "--", 100, 120);
      doc.text("Restriction", 20, 130);
      doc.text(landDetails.restriction !== null ? landDetails.restriction : "--", 100, 130);
      doc.text("Reservation ID", 20, 140);
      doc.text(landDetails.reservationId !== null ? landDetails.reservationId : "--", 100, 140);
      doc.text("Central Government Rules", 20, 150);
      doc.text(landDetails.centralGovernmentRules !== null ? landDetails.centralGovernmentRules : "--", 100, 150);
      doc.text("Local Government Rules", 20, 160);
      doc.text(landDetails.localGovernmentRules !== null ? landDetails.localGovernmentRules : "--", 100, 160);
      doc.text("UDA regulations", 20, 170);
      doc.text(landDetails.udaRegulations !== null ? landDetails.udaRegulations : "--", 100, 170);
      doc.text("Other Rules", 20, 180);
      doc.text(landDetails.otherRules !== null ? landDetails.otherRules : "--", 100, 180);
      doc.text("Assessment No", 20, 190);
      doc.text(landDetails.assessmentNo !== null ? landDetails.assessmentNo : "--", 100, 190);
      doc.text("Nearest Town", 20, 200);
      doc.text(landDetails.nearestTown !== null ? landDetails.nearestTown : "--", 100, 200);
      doc.text("Access Nearest Town", 20, 210);
      doc.text(landDetails.accessNearestTown !== null ? landDetails.accessNearestTown : "--", 100, 210);
      doc.text("Distance Main Road", 20, 220);
      doc.text(landDetails.distanceMainRd !== null ? landDetails.distanceMainRd : "--", 100, 220);
      doc.text("Land Mark", 20, 230);
      doc.text(landDetails.landMark !== null ? landDetails.landMark : "--", 100, 230);
      doc.text("Road Width", 20, 240);
      doc.text(landDetails.roadWidth !== null ? landDetails.roadWidth : "--", 100, 240);
      doc.text("Land Extent", 20, 250);
      doc.text(landDetails.landExtent !== null ? landDetails.landExtent : "--", 100, 250);
      doc.text("Land Shape ID", 20, 260);
      doc.text(landDetails.landShapeId !== null ? landDetails.landShapeId : "--", 100, 260);
      doc.text("Road Frontage", 20, 270);
      doc.text(landDetails.roadFrontage !== null ? landDetails.roadFrontage : "--", 100, 270);
      doc.text("Length", 20, 280);
      doc.text(landDetails.length !== null ? landDetails.length : "--", 100, 280);
      doc.text("Width", 20, 290);
      doc.text(landDetails.width !== null ? landDetails.width : "--", 100, 290);

      doc.addPage();

      doc.setFont("times");
      doc.setFontType('normal');
      doc.text("Land Location ID", 20, 40);
      doc.text(landDetails.landLocationId !== null ? landDetails.landLocationId : "--", 100, 40);
      doc.text("Land Slope ID", 20, 50);
      doc.text(landDetails.landSlopeId !== null ? landDetails.landSlopeId : "--", 100, 50);
      doc.text("Water Availability", 20, 60);
      doc.text(landDetails.waterAvailabilityId !== null ? landDetails.waterAvailabilityId : "--", 100, 60);
      doc.text("Water ID", 20, 70);
      doc.text(landDetails.waterId !== null ? landDetails.waterId : "--", 100, 70);
      doc.text("Boundary North", 20, 80);
      doc.text(landDetails.boundaryNorth !== null ? landDetails.boundaryNorth : "--", 100, 80);
      doc.text("Boundary South", 20, 90);
      doc.text(landDetails.boundarySouth !== null ? landDetails.boundarySouth : "--", 100, 90);
      doc.text("Boundary West", 20, 100);
      doc.text(landDetails.boundaryWest !== null ? landDetails.boundaryWest : "--", 100, 100);
      doc.text("Boundary East", 20, 110);
      doc.text(landDetails.boundaryEast !== null ? landDetails.boundaryEast : "--", 100, 110);
      doc.text("Electricity Type ID", 20, 120);
      doc.text(landDetails.electricityTypeId !== null ? landDetails.electricityTypeId : "--", 100, 120);
      doc.text("Electricity Sub Type ID", 20, 130);
      doc.text(landDetails.electricitySubTypeId !== null ? landDetails.electricitySubTypeId : "--", 100, 130);
      doc.text("Land Suitability ID", 20, 140);
      doc.text(landDetails.landSuitabilityId !== null ? landDetails.landSuitabilityId : "--", 100, 140);
      doc.text("Cultivation", 20, 150);
      doc.text(landDetails.cultivation !== null ? landDetails.cultivation : "--", 100, 150);
      doc.text("Transport ID", 20, 160);
      doc.text(landDetails.transportId !== null ? landDetails.transportId : "--", 100, 160);
      doc.text("Education ID", 20, 170);
      doc.text(landDetails.educationId !== null ? landDetails.educationId : "--", 100, 170);
      doc.text("Health ID", 20, 180);
      doc.text(landDetails.healthId !== null ? landDetails.healthId : "--", 100, 180);
      doc.text("Industrial ID", 20, 190);
      doc.text(landDetails.industrialId !== null ? landDetails.industrialId : "--", 100, 190);
      doc.text("Security ID", 20, 200);
      doc.text(landDetails.securityId !== null ? landDetails.securityId : "--", 100, 200);
      doc.text("Telecommunication ID", 20, 210);
      doc.text(landDetails.telecommunicationId !== null ? landDetails.telecommunicationId : "--", 100, 210);
      doc.text("Drainage Facility Availability", 20, 220);
      doc.text(landDetails.drainageFacilityAvailability !== null ? landDetails.drainageFacilityAvailability : "--", 100, 220);
      doc.text("Garbage Disposal Availability", 20, 230);
      doc.text(landDetails.garbageDisposalAvailability !== null ? landDetails.garbageDisposalAvailability : "--", 100, 230);
      doc.text("Community", 20, 240);
      doc.text(landDetails.community !== null ? landDetails.community : "--", 100, 240);
      doc.text("Neighbourhood", 20, 250);
      doc.text(landDetails.neighbourhood !== null ? landDetails.neighbourhood : "--", 100, 250);
      doc.text("Bus Route", 20, 260);
      doc.text(landDetails.busRoute !== null ? landDetails.busRoute : "--", 100, 260);
      doc.text("Railways", 20, 270);
      doc.text(landDetails.railways !== null ? landDetails.railways : "--", 100, 270);
      doc.addPage();
    }

    if (condominiumDetails !== null) {
      doc.setFontStyle("bold");
      doc.text("Inspection Request.", 105, 20, null, null, "center");
      doc.setFontSize(10);
      doc.text("Condominium Request", 20, 30)
      doc.setFont("times");
      doc.setFontType('normal');
      doc.text("Property Type ID", 20, 40);
      doc.text(condominiumDetails.propertyTypeId !== null ? condominiumDetails.propertyTypeId : "--", 100, 40);
      doc.text("Property Sub Type ID", 20, 50);
      doc.text(condominiumDetails.propertySubTypeId !== null ? condominiumDetails.propertySubTypeId : "--", 100, 50);
      doc.text("Valuation Request ID", 20, 60);
      doc.text(condominiumDetails.valuationRequestId !== null ? condominiumDetails.valuationRequestId : "--", 100, 60);
      doc.text("Inspection Type", 20, 70);
      doc.text(condominiumDetails.inspectionType !== null ? condominiumDetails.inspectionType : "--", 100, 70);
      doc.text("Created by", 20, 80);
      doc.text(condominiumDetails.createdBy !== null ? condominiumDetails.createdBy : "--", 100, 80);
      doc.text("Plan No", 20, 90);
      doc.text(condominiumDetails.planNo !== null ? condominiumDetails.planNo : "--", 100, 90);
      doc.text("Lot No(s) / Condominium Unit No(s)", 20, 100);
      doc.text(condominiumDetails.lotNo !== null ? condominiumDetails.lotNo : "--", 100, 100);
      doc.text("Condominium Name", 20, 110);
      doc.text(condominiumDetails.condominiumName !== null ? condominiumDetails.condominiumName : "--", 100, 110);
      doc.text("Assessment No", 20, 120);
      doc.text(condominiumDetails.assessmentNo !== null ? condominiumDetails.assessmentNo : "--", 100, 120);
      doc.text("Town ID", 20, 130);
      doc.text(condominiumDetails.townId !== null ? condominiumDetails.townId : "--", 100, 130);
      doc.text("Main Road", 20, 140);
      doc.text(condominiumDetails.mainRoad !== null ? condominiumDetails.mainRoad : "--", 100, 140);
      doc.text("Distance Main Road", 20, 150);
      doc.text(condominiumDetails.distanceMainRd !== null ? condominiumDetails.distanceMainRd : "--", 100, 150);
      doc.text("District ID", 20, 160);
      doc.text(condominiumDetails.districtId !== null ? condominiumDetails.districtId : "--", 100, 160);
      doc.text("Local Authority ID", 20, 170);
      doc.text(condominiumDetails.localAuthorityId !== null ? condominiumDetails.localAuthorityId : "--", 100, 170);
      doc.text("Use of Condominium", 20, 180);
      doc.text(condominiumDetails.useOfCondominium !== null ? condominiumDetails.useOfCondominium : "--", 100, 180);
      doc.text("Plan Number", 20, 190);
      doc.text(condominiumDetails.planNumber !== null ? condominiumDetails.planNumber : "--", 100, 190);
      doc.text("Date Period", 20, 200);
      doc.text(condominiumDetails.datePeriod !== null ? condominiumDetails.datePeriod : "--", 100, 200);
      doc.text("Survey Name", 20, 210);
      doc.text(condominiumDetails.surveyName !== null ? condominiumDetails.surveyName : "--", 100, 210);
      doc.text("Land Extent", 20, 220);
      doc.text(condominiumDetails.landExtent !== null ? condominiumDetails.landExtent : "--", 100, 220);
      doc.text("No of floors", 20, 230);
      doc.text(condominiumDetails.noOfFloors !== null ? condominiumDetails.noOfFloors : "--", 100, 230);
      doc.text("No of units", 20, 240);
      doc.text(condominiumDetails.noOfUnits !== null ? condominiumDetails.noOfUnits : "--", 100, 240);
      doc.text("Floors and Units", 20, 250);
      doc.text(condominiumDetails.floorsAndUnits !== null ? condominiumDetails.floorsAndUnits : "--", 100, 250);
      doc.text("Other units", 20, 260);
      doc.text(condominiumDetails.otherUnits !== null ? condominiumDetails.otherUnits : "--", 100, 260);
      doc.text("Constructed Year", 20, 270);
      doc.text(condominiumDetails.constructedYear !== null ? condominiumDetails.constructedYear : "--", 100, 270);
      doc.text("Parking ID", 20, 280);
      doc.text(condominiumDetails.parkingId !== null ? condominiumDetails.parkingId : "--", 100, 280);
      doc.text("Security ID", 20, 290);
      doc.text(condominiumDetails.securityId !== null ? condominiumDetails.securityId : "--", 100, 290);

      doc.addPage();

      doc.setFont("times");
      doc.setFontType('normal');
      doc.text("Unit No", 20, 40);
      doc.text(condominiumDetails.unitNo !== null ? condominiumDetails.unitNo : "--", 100, 40);
      doc.text("Extent", 20, 50);
      doc.text(condominiumDetails.extent !== null ? condominiumDetails.extent : "--", 100, 50);
      doc.text("Garden ID", 20, 60);
      doc.text(condominiumDetails.gardenId !== null ? condominiumDetails.gardenId : "--", 100, 60);
      doc.text("Water ID", 20, 70);
      doc.text(condominiumDetails.waterId !== null ? condominiumDetails.waterId : "--", 100, 70);
      doc.text("Electricity ID", 20, 80);
      doc.text(condominiumDetails.electricityId !== null ? condominiumDetails.electricityId : "--", 100, 80);
      doc.text("Drainage", 20, 90);
      doc.text(condominiumDetails.drainage !== null ? condominiumDetails.drainage : "--", 100, 90);
      doc.text("Gas Lines", 20, 100);
      doc.text(condominiumDetails.gasLines !== null ? condominiumDetails.gasLines : "--", 100, 100);
      doc.text("Accomadation Details", 20, 110);
      doc.text(condominiumDetails.accomadationDetails !== null ? condominiumDetails.accomadationDetails : "--", 100, 110);
      doc.text("Other Residencies", 20, 120);
      doc.text(condominiumDetails.otherResidencies !== null ? condominiumDetails.otherResidencies : "--", 100, 120);
      doc.text("Income Level ID", 20, 130);
      doc.text(condominiumDetails.incomeLevelId !== null ? condominiumDetails.incomeLevelId : "--", 100, 130);
      doc.text("Management Cost", 20, 140);
      doc.text(condominiumDetails.managementCost !== null ? condominiumDetails.managementCost : "--", 100, 140);
      doc.text("Maintainance", 20, 150);
      doc.text(condominiumDetails.maintainance !== null ? condominiumDetails.maintainance : "--", 100, 150);
      doc.text("Rates", 20, 160);
      doc.text(condominiumDetails.rates !== null ? condominiumDetails.rates : "--", 100, 160);
      doc.text("Net Annual Rent", 20, 170);
      doc.text(condominiumDetails.netAnnualRent !== null ? condominiumDetails.netAnnualRent : "--", 100, 170);
      doc.text("Undivided Shares", 20, 180);
      doc.text(condominiumDetails.undividedShares !== null ? condominiumDetails.undividedShares : "--", 100, 180);
      doc.text("Other", 20, 190);
      doc.text(condominiumDetails.other !== null ? condominiumDetails.other : "--", 100, 190);
      doc.text("Agreement Type ID", 20, 200);
      doc.text(condominiumDetails.agreementTypeId !== null ? condominiumDetails.agreementTypeId : "--", 100, 200);
      doc.text("Sales Agreement No", 20, 210);
      doc.text(condominiumDetails.salesAgreementNo !== null ? condominiumDetails.salesAgreementNo : "--", 100, 210);
      doc.text("Notary", 20, 220);
      doc.text(condominiumDetails.notary !== null ? condominiumDetails.notary : "--", 100, 220);
      doc.text("Date ", 20, 230);
      doc.text(condominiumDetails.date !== null ? condominiumDetails.date : "--", 100, 230);
      doc.text("Value", 20, 240);
      doc.text(condominiumDetails.value !== null ? condominiumDetails.value : "--", 100, 240);
    }

    if (landBuildingDetails !== null) {
      doc.setFontStyle("bold");
      doc.text("Inspection Request.", 105, 20, null, null, "center");
      doc.setFontSize(10);
      doc.text("Land with Building Request", 20, 30)
      doc.setFont("times");
      doc.setFontType('normal');
      doc.text("Property Type ID", 20, 40);
      doc.text(landBuildingDetails.propertyTypeId !== null ? landBuildingDetails.propertyTypeId : "--", 100, 40);
      doc.text("Property Sub Type ID", 20, 50);
      doc.text(landBuildingDetails.propertySubTypeId !== null ? landBuildingDetails.propertySubTypeId : "--", 100, 50);
      doc.text("Valuation Request ID", 20, 60);
      doc.text(landBuildingDetails.valuationRequestId !== null ? landBuildingDetails.valuationRequestId : "--", 100, 60);
      doc.text("Inspection Type", 20, 70);
      doc.text(landBuildingDetails.inspectionType !== null ? landBuildingDetails.inspectionType : "--", 100, 70);
      doc.text("Created by", 20, 80);
      doc.text(landBuildingDetails.createdBy !== null ? landBuildingDetails.createdBy : "--", 100, 80);
      doc.text("Category Property ID", 20, 90);
      doc.text(landBuildingDetails.categoryPropertyId !== null ? landBuildingDetails.categoryPropertyId : "--", 100, 90);
      doc.text("Building No", 20, 100);
      doc.text(landBuildingDetails.buildingNo !== null ? landBuildingDetails.buildingNo : "--", 100, 100);
      doc.text("Building Name", 20, 110);
      doc.text(landBuildingDetails.buildingName !== null ? landBuildingDetails.buildingName : "--", 100, 110);
      doc.text("No of Building", 20, 120);
      doc.text(landBuildingDetails.noOfBuildings !== null ? landBuildingDetails.noOfBuildings : "--", 100, 120);
      doc.text("Total Completion", 20, 130);
      doc.text(landBuildingDetails.totalCompletion !== null ? landBuildingDetails.totalCompletion : "--", 100, 130);
      doc.text("Materials", 20, 140);
      doc.text(landBuildingDetails.materials !== null ? landBuildingDetails.materials : "--", 100, 140);
      doc.text("Fittings", 20, 150);
      doc.text(landBuildingDetails.fittings !== null ? landBuildingDetails.fittings : "--", 100, 150);
      doc.text("Kitchen Pantry", 20, 160);
      doc.text(landBuildingDetails.kitchenPantry !== null ? landBuildingDetails.kitchenPantry : "--", 100, 160);
      doc.text("Pantry Cupboards", 20, 170);
      doc.text(landBuildingDetails.pantryCupboards !== null ? landBuildingDetails.pantryCupboards : "--", 100, 170);
      doc.text("Inspection No of Buildings", 20, 180);
      doc.text(landBuildingDetails.inspectionNoOfBuildings !== null ? landBuildingDetails.inspectionNoOfBuildings : "--", 100, 180);
      doc.text("Incompleted Floors", 20, 190);
      doc.text(landBuildingDetails.incompletedFloors !== null ? landBuildingDetails.incompletedFloors : "--", 100, 190);
      doc.text("Completed Floors", 20, 200);
      doc.text(landBuildingDetails.completedFloors !== null ? landBuildingDetails.completedFloors : "--", 100, 200);
      doc.text("Total Completed Ratio", 20, 210);
      doc.text(landBuildingDetails.totalCompletedRatio !== null ? landBuildingDetails.totalCompletedRatio : "--", 100, 210);
      doc.text("Usage ID", 20, 220);
      doc.text(landBuildingDetails.usageId !== null ? landBuildingDetails.usageId : "--", 100, 220);
      doc.addPage();
    }

    doc.save('recreationrequest.pdf');
  }
}
