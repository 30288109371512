import {Component, Input, OnInit} from '@angular/core';
import {INotary} from '../../../../../../../model/interfaces/notary';
import {ValuationRequestService} from '../../../../../../../service/valuation-request/valuation-request.service';
import {SubSink} from 'subsink';
import {ValuationRequest} from '../../../../../../../model/valuation-request';
import {NotaryDetails} from '../../../../../../../model/notary-details';
import {Router} from "@angular/router";

@Component({
  selector: 'app-view-notary-internal',
  templateUrl: './view-notary-internal.component.html',
  styleUrls: ['./view-notary-internal.component.scss']
})
export class ViewNotaryInternalComponent implements OnInit {
  @Input()
  notary: INotary [];
  @Input()
  valuationRequestId: string;
  @Input()
  userId: string;
  private sub = new SubSink();

  notaryDetails: NotaryDetails;
  valuationRequest: ValuationRequest;

  constructor(private valuationRequestService: ValuationRequestService,
              private  router: Router) {
  }

  ngOnInit() {
    const notaryuserID = this.userId;
    this.getNotaryDetails(notaryuserID);
  }

  getNotaryDetails(notaryUserID: string) {
    this.sub.add(this.valuationRequestService.getNotaryDetails(notaryUserID)
      .subscribe((res) => {
        this.notaryDetails = res.data;
        console.log(this.notaryDetails);
      }));
  }

}
