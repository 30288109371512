import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { InspectionReservationTypeService } from 'src/app/service/inspection-master-data-service/inspection-reservation-type/inspection-reservation-type.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inspection-reservation-type',
  templateUrl: './inspection-reservation-type.component.html',
  styleUrls: ['./inspection-reservation-type.component.scss']
})
export class InspectionReservationTypeComponent implements OnInit, OnDestroy {
  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  createReservationTypeForm: FormGroup;
  updateReservationTypeForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  propertyTypeList: any =[];
  activationButton: boolean;
  public createReservationTypeModel = new InspectionCommonDataModel(null,'','','','');
  public updateReservationTypeModel = new InspectionCommonDataModel(null,'','','','');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;
  reservationTypes: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionReservationTypeService: InspectionReservationTypeService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createReservationTypeForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });
    this.updateReservationTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });

    // this.loadPropertyType();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }



  //create reservation type - start

  get f() { return this.createReservationTypeForm.controls; }

  setReservationTypeModelToSave(){
    this.createReservationTypeModel.name = this.createReservationTypeForm.get('name').value;
    this.createReservationTypeModel.description = this.createReservationTypeForm.get('description').value;
    this.createReservationTypeModel.code = this.createReservationTypeForm.get('code').value;
    this.createReservationTypeModel.dataStatus = "ACTIVE";
  }

    //save reservation type model
  saveReservationType(){

    if (this.createReservationTypeForm.valid) {

      this.setReservationTypeModelToSave();
      this.sub.add(
        this.inspectionReservationTypeService.createReservationType(this.createReservationTypeModel).subscribe(data=>{
          this.notifier.notify('success', (data as any).message);
          this.updateTheTable();
          this.reset();
        })
        );
    }else {
      this.createReservationTypeForm.markAllAsTouched();
    }

  }

  reset(){
    this.createReservationTypeForm.reset()
  }
  //create reservation type - end

  //update reservation type - start

    //get update controllers
  get f1() { return this.updateReservationTypeForm.controls; }

    //set data To Reservation Type model
  setReservationType(subProperty:any){
    this.updateReservationTypeForm.get('id').setValue(subProperty.id);
    this.updateReservationTypeForm.get('name').setValue(subProperty.name);
    this.updateReservationTypeForm.get('description').setValue(subProperty.description);
    this.updateReservationTypeForm.get('code').setValue(subProperty.code);

    if(subProperty.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateReservationTypeModel.id = this.updateReservationTypeForm.get('id').value;
    this.updateReservationTypeModel.name = this.updateReservationTypeForm.get('name').value;
    this.updateReservationTypeModel.description = this.updateReservationTypeForm.get('description').value;
    this.updateReservationTypeModel.code = this.updateReservationTypeForm.get('code').value;

    if(this.activationButton == true){
      this.updateReservationTypeModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateReservationTypeModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateReservationType(){
    this.setDataToModel();
    this.sub.add(this.inspectionReservationTypeService.updateReservationType(this.updateReservationTypeModel.id, this.updateReservationTypeModel).subscribe(data=>{
      this.notifier.notify('success', (data as any).message);
      this.updateTheTable();
    }))
  }

  //update reservation type - end

//search and view table start
  pageable(i: number) {
    this.inspectionReservationTypeService.getReservationType((i-1),10).subscribe(res=>{
      this.reservationTypes = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionReservationTypeService.getReservationType(0, 10)
      .subscribe(
        (res) => {
          this.reservationTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search Reservation Type
    searchReservationType(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionReservationTypeService.searchReservationType(this.keyword,0,10).subscribe(res=>{
          this.reservationTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionReservationTypeService.deleteReservationType(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end
}
