import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {ReportServiceService} from "../service/report-service.service";
import {NotifierService} from "angular-notifier";
import * as XLSX from "xlsx";

@Component({
  selector: 'app-application-payment-details-report',
  templateUrl: './application-payment-details-report.component.html',
  styleUrls: ['./application-payment-details-report.component.scss']
})
export class ApplicationPaymentDetailsReportComponent implements OnInit,AfterViewInit {

  dropdownList = [];
  selectedItems = [];
  selectedItemsLA = [];
  dropdownSettings:IDropdownSettings = {};
  dropdownListLA= [];

  fromDate: string = null;
  toDate: string = null;
  public showPrint: boolean = false;
  public  reportDetails:any = [];

  public selectedDetails:any;
  public grand_total:number = 0;

  constructor(public modalService: NgbModal,
              public reportService: ReportServiceService,
              private notifier: NotifierService) { }

  ngOnInit() {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'localAuthority',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.get_all_local_authority();

    this.selectedDetails = {
      date: "",
      selected_la: ""
    };
  }

  ngAfterViewInit() {
    this.printReceipt();

  }

  onItemSelectLA(item: any){
    console.log(item);
  }
  onSelectAllLA(items: any){
    console.log(items);
  }

  closeFurtherReqRemark() {
    this.modalService.dismissAll();
  }

  generateReport() {
    this.modalService.dismissAll();
  }

  download_excel(){
    this.get_excel_report();
  }

  get_excel_report(){

    // capture selected from date
    if (this.fromDate == null) {
      this.notifier.notify('error', 'Please enter submit from date!');
      return false;
    }

    // capture selected to date
    if (this.toDate == null) {
      this.notifier.notify('error', 'Please enter submit to date!');
      return false;
    }


    var selectedLA = "";
    var selectedLAString = " ";

    if(this.selectedItemsLA != null){
      this.selectedItemsLA.forEach(value => {
        selectedLA += value.id +",";
        selectedLAString += " "+value.localAuthority+",";
      });
    }

    var date_range = ((this.fromDate == null) && (this.toDate == null)) ? "All Date" : this.fromDate + ' to '+ this.toDate ;
    var selectedLAStrings = (selectedLAString == " ")? "All local Authority": selectedLAString;

    this.selectedDetails = {
      date: date_range,
      selected_la: selectedLAStrings
    };

      this.reportService.getApplicationPaymentDetails(this.fromDate, this.toDate, (selectedLA.slice(0, -1) == "") ? null : selectedLA.slice(0, -1)).subscribe(value => {
        if (value.data.report.length > 0) {
          this.reportDetails = value.data;

          console.log(this.reportDetails, "this.reportDetails");

          this.grand_total = value.data.report.length;

          setTimeout(() => {
            var printContents = document.getElementById('excel_download');
            const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(printContents);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'App payments');
            XLSX.writeFile(wb, 'Application Payment Details Report.xlsx');
          }, 1000);

        } else {
          this.notifier.notify('error', 'No records found!');
          console.log("No data found");
        }
      });


  }

  get_all_local_authority(){
    this.reportService.getAllLA().subscribe(value => {
      if(value.data.length > 0){
        this.dropdownListLA = value.data;
      }else{
        console.log("No Local authority return");
      }
    });
  }

  get_report(){

  // capture selected from date
    if (this.fromDate == null) {
      this.notifier.notify('error', 'Please enter submit from date!');
      return false;
    }

    // capture selected to date
    if (this.toDate == null) {
      this.notifier.notify('error', 'Please enter submit to date!');
      return false;
    }


    var selectedLA = "";
    var selectedLAString = " ";

    if(this.selectedItemsLA != null){
      this.selectedItemsLA.forEach(value => {
        selectedLA += value.id +",";
        selectedLAString += " "+value.localAuthority+",";
      });
    }

    var date_range = ((this.fromDate == null) && (this.toDate == null)) ? "All Date" : this.fromDate + ' to '+ this.toDate ;
    var selectedLAStrings = (selectedLAString == " ")? "All local Authority": selectedLAString;

    this.selectedDetails = {
      date: date_range,
      selected_la: selectedLAStrings
    };

        this.reportService.getApplicationPaymentDetails(this.fromDate, this.toDate, (selectedLA.slice(0, -1) == "") ? null : selectedLA.slice(0, -1)).subscribe(value => {
          if (value.data.report.length > 0) {
            this.reportDetails = value.data;
            this.grand_total = value.data.report.length;

            setTimeout(() => {
              this.printReceipt();
            }, 1000);

          } else {
            this.notifier.notify('error', 'No records found!');
            console.log("No data found");
          }
        });

  }


  generateReportPDF(){
    this.get_report();
    this.showPrint = true;
    setTimeout(() => {
      this.printReceipt();
    }, 1000);
  }

  printReceipt(): void {

    this.showPrint = true;
    let printContents: any;
    let popupWin: any;
    printContents = document.getElementById('receipt').innerHTML;

    popupWin = window.open();
    popupWin.document.open();
    popupWin.document.write(`
    <html>
    <head>
        <title>Application Payment Details</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <!--<script src="../../../../assets/js/paged.js"></script>-->
        <!--<script src="../../../../assets/js/paged.polyfill.js"></script>-->
        <style>
          @page {
            size: A4 landscape;
            @bottom-right {
              content: "Page " counter(page) " of " counter(pages);
            }
          }

            body
            {
                margin: 10mm 10mm 0mm 10mm;

            }
            table{
              border: 1px solid #000;
              border-collapse: collapse;
            }
            tbody{
              page-break-before: always;
            }



            table th, table td
            {
                padding: 5px;
                margin: 0px;
                border: 1px solid #000;
            }
            .page{
                padding-bottom: 20px;
                page-break-after: always;
            }

        </style>
    </head>
    <body>
      <body onload="window.print();">
            ${printContents}
    </body>
    </html>`
    );
    popupWin.document.close();

    // this.printPreview.emit(false);
  }
}
