import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import Swal from 'sweetalert2';
import Cropper from 'cropperjs';

@Component({
  selector: 'app-file-upload-opinion-document',
  templateUrl: './file-upload-opinion-document.component.html',
  styleUrls: ['./file-upload-opinion-document.component.scss']
})
export class FileUploadOpinionDocumentComponent implements OnInit {

  selectedElement: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
  }

  @Output()
  outPutImagesOpinionDocuments = new EventEmitter<File[]>();
  filesListOpinionDocuments: File[] = [];
  imagesOpinionDocuments = [];
  originalImages6: any[] = [];
  namesOpinionDocument: string[] = [];
  selectedIndex = 0;
  filesOpinionDocuments;
  showEdit = false;
  showEditPdf = false;

  public progress = 0;
  pdfsrc = null;
  cropper;

  ngOnInit() {

  }

  // uploadChangeOpinionDocuments(event) {
  //   this.filesListOpinionDocuments = event.target.files;
  //   this.progress = 0;
  //   for (let i = 0; i < this.filesOpinionDocuments.length; i++) {
  //     const file = this.filesOpinionDocuments[i];
  //     if (file.type === 'application/pdf' || file.type === 'image/png' || file.type === 'image/jpeg') {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = (_event: any) => {
  //         this.progress = 100;
  //         this.imagesOpinionDocuments.push(_event.currentTarget.result);
  //         this.originalImages6.push(_event.currentTarget.result);
  //         this.filesListOpinionDocuments.push(file);
  //       };
  //       this.namesOpinionDocument.push(file.name);
  //       this.progress = 0;
  //     } else {
  //
  //       Swal.fire({
  //         text: 'File type not allowed here!',
  //         icon: 'warning',
  //         showCancelButton: false,
  //
  //       });
  //       event.srcElement.value = null;
  //     }
  //   }
  //
  // }
  uploadChangeOpinionDocuments(event) {
    this.filesOpinionDocuments = event.target.files;
    this.progress = 0;
    for (let i = 0; i < this.filesOpinionDocuments.length; i++) {
      const file = this.filesOpinionDocuments[i];
      if (file.type === "application/pdf" || file.type === "image/png" || file.type === "image/jpeg") {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          this.progress = 100;
          this.imagesOpinionDocuments.push(_event.currentTarget.result);
          this.originalImages6.push(_event.currentTarget.result);
          this.filesListOpinionDocuments.push(file);
        };
        this.namesOpinionDocument.push(file.name);
        this.progress = 0;
      } else {

        Swal.fire({
          text: 'File type not allowed here!',
          icon: 'warning',
          showCancelButton: false,

        });
        event.srcElement.value = null;
      }
    }

  }

  changeImg(event) {

    if (this.cropper !== undefined) {
      this.cropper.destroy();
    }
    const image = document.getElementById('imageOpinionDocuments') as HTMLImageElement;
    image.src = event.target.src;
    this.cropper = new Cropper(image, {
      crop(event) {
      },
    });
  }

  outPutImagesSet() {
    this.outPutImagesOpinionDocuments.emit(this.filesListOpinionDocuments);
  }

  edit(event, index) {
    // this.selectedElement=this.sanitizer.bypassSecurityTrustResourceUrl(this.images5[index]);
    if (this.filesListOpinionDocuments[index].type==="application/pdf"){

      // document.getElementById('myiframe-nic').setAttribute('src',this.images5[index])
      this.showEdit = false;
      this.showEditPdf = true;
      this.pdfsrc=null;
      this.pdfsrc=this.imagesOpinionDocuments[index];
    }else {
      this.showEdit = true;
      this.showEditPdf = false;
      const image = document.getElementById('imageOpinion') as HTMLImageElement;
      image.src = event.target.parentElement.parentElement.children[0].src;
      if (this.cropper !== undefined) {
        this.cropper.destroy();
      }
      this.cropper = new Cropper(image, {
        rotatable: true,
        crop(event) {

        },
      });
    }

    this.selectedIndex = index;
  }

  delete(index) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#6086d6',
      cancelButtonColor: '#d66',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.imagesOpinionDocuments.splice(index, 1);
        this.originalImages6.splice(index, 1);
        this.namesOpinionDocument.splice(index, 1);
        this.filesListOpinionDocuments.splice(index, 1);
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );

      }
    });

    // this.images.splice(index, 1);
    // this.originalImages.splice(index, 1);
    // this.names.splice(index, 1);
    // this.filesList.splice(index, 1);


  }

  closeEditor() {
    this.showEdit = false;
    this.showEditPdf = false;
  }

  rotate(angle: number) {
    this.cropper.move(1, -1).rotate(angle);
  }

  resetImage() {
    this.cropper.destroy();
    this.imagesOpinionDocuments[this.selectedIndex] = this.originalImages6[this.selectedIndex];
    const image = document.getElementById('imageOpinionDocuments') as HTMLImageElement;
    image.src = this.imagesOpinionDocuments[this.selectedIndex];
    this.cropper = new Cropper(image, {
      rotatable: true,
      crop(event) {
      },
    });
  }

  crop() {
    this.imagesOpinionDocuments[this.selectedIndex] = this.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    this.cropper.destroy();
    this.imagesOpinionDocuments[this.selectedIndex] = this.imagesOpinionDocuments[this.selectedIndex];
    const image = document.getElementById('imageOpinionDocuments') as HTMLImageElement;
    image.src = this.imagesOpinionDocuments[this.selectedIndex];
    this.cropper = new Cropper(image, {
      rotatable: true,
      crop(event) {
      },
    });

  }
}
