export class CertificateDetails{
    fileNumberFormat:string;
    createdDate:string;
    granteeAddress:string;
    previousDeedNo:string;
    localAuthority:string;
    propertyAddress:string;
    surveyorName:string;
    surveyorPlanNo:string;
    surveyorPlanDate: string;
    lotNo:string;
    extentFromValueAndUnit:string;
    deedNature:string;
    notaryDetails:string;
    granteeDetails:string;
    granterDetails:string;
    valueOfTheProperty:string;
    calculationHeadingDateOne: string;
    calculationHeadingDateTwo: string;
    roundOffValue:string;
    stampDuty:string;
    calculationCreatedDate: string;
    currentDate:string;
    assessorName: string;
    deputyCommissionerName: string;
    qrCode;
    assessorOffice:string;
    numberOfGrantees:number;
    numberOfGranters:number;
}