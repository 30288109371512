
export class UserDetails {
  public grant_type: string;
  public username: string;
  public password: string;

  constructor(grant_type: string, username: string, password: string) {
    this.grant_type = grant_type;
    this.username = username;
    this.password = password;
  }
}
