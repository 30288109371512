import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { InspectionSurroundTypeService } from 'src/app/service/inspection-master-data-service/inspection-surround-type/inspection-surround-type.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inspection-surround-type',
  templateUrl: './inspection-surround-type.component.html',
  styleUrls: ['./inspection-surround-type.component.scss']
})
export class InspectionSurroundTypeComponent implements OnInit,OnDestroy {

  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");


  createSurroundTypeForm: FormGroup;
  updateSurroundTypeForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  propertyTypeList: any =[];
  activationButton: boolean;
  public createSurroundTypeModel = new InspectionCommonDataModel(null,'','','','');
  public updateSurroundTypeModel = new InspectionCommonDataModel(null,'','','','');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;
  SurroundTypes: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionSurroundTypeService: InspectionSurroundTypeService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createSurroundTypeForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['',[Validators.pattern(this.NoWhitespaceRegExp)]],
    });
    this.updateSurroundTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['',[Validators.pattern(this.NoWhitespaceRegExp)]],
    });

    // this.loadPropertyType();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }



  //create Surround Type - start

  get f() { return this.createSurroundTypeForm.controls; }

  setSurroundTypeModelToSave(){
    this.createSurroundTypeModel.name = this.createSurroundTypeForm.get('name').value;
    this.createSurroundTypeModel.description = this.createSurroundTypeForm.get('description').value;
    this.createSurroundTypeModel.code = this.createSurroundTypeForm.get('code').value;
    this.createSurroundTypeModel.dataStatus = "ACTIVE";
  }

    //save Surround Type model
  saveSurroundType(){

    this.inspectionSurroundTypeService.getSurroundType(0,this.totalItems).subscribe(res=>{
      const SurroundTypesArray = (res as any).data.content;
      if (SurroundTypesArray.map((SurroundT)=>String(SurroundT.name.replace(/\s/g, ""))).includes(String(this.createSurroundTypeForm.get('name').value.replace(/\s/g, "")))){
        this.notifier.notify('error', 'Surround type type name "'+this.createSurroundTypeForm.get('name').value +'" is exist');
        return;
      }
      else{
        if(this.createSurroundTypeForm.valid){
          this.setSurroundTypeModelToSave();
          this.sub.add(
            this.inspectionSurroundTypeService.createSurroundType(this.createSurroundTypeModel).subscribe(data=>{
              this.notifier.notify('success', (data as any).message);
              this.updateTheTable();
              this.reset();
            })
            );
        }else{
          this.f.name.markAsTouched();
          this.f.code.markAsTouched();
        }
  }}) 


  }

  reset(){
    this.createSurroundTypeForm.reset()
  }
  //create Surround Type - end

  //update Surround Type - start

    //get update controllers
  get f1() { return this.updateSurroundTypeForm.controls; }

    //set data To Surround Type Type model
  setSurroundType(surround:any){
    this.updateSurroundTypeForm.get('id').setValue(surround.id);
    this.updateSurroundTypeForm.get('name').setValue(surround.name);
    this.updateSurroundTypeForm.get('description').setValue(surround.description);
    this.updateSurroundTypeForm.get('code').setValue(surround.code);

    if(surround.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateSurroundTypeModel.id = this.updateSurroundTypeForm.get('id').value;
    this.updateSurroundTypeModel.name = this.updateSurroundTypeForm.get('name').value;
    this.updateSurroundTypeModel.description = this.updateSurroundTypeForm.get('description').value;
    this.updateSurroundTypeModel.code = this.updateSurroundTypeForm.get('code').value;

    if(this.activationButton == true){
      this.updateSurroundTypeModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateSurroundTypeModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateSurroundType(){

    this.inspectionSurroundTypeService.getSurroundType(0,this.totalItems).subscribe(res=>{
      const SurroundTypesArray = (res as any).data.content;
      if (SurroundTypesArray.map((SurroundT)=>String(SurroundT.name.replace(/\s/g, ""))).includes(String(this.updateSurroundTypeForm.get('name').value.replace(/\s/g, "")))){
        this.notifier.notify('error', 'Surround type type name "'+this.updateSurroundTypeForm.get('name').value +'" is exist');
        return;
      }
      else{
        if(this.updateSurroundTypeForm.valid){
          this.setDataToModel();
          this.sub.add(this.inspectionSurroundTypeService.updateSurroundType(this.updateSurroundTypeModel.id, this.updateSurroundTypeModel).subscribe(data=>{
            this.notifier.notify('success', (data as any).message);
            this.updateTheTable();
          }))
        }else{
          this.f1.name.markAsTouched();
          this.f1.code.markAsTouched();
        }
  }}) 
  }

  //update Surround Type - end

//search and view table start
  pageable(i: number) {
    this.inspectionSurroundTypeService.getSurroundType((i-1),10).subscribe(res=>{
      this.SurroundTypes = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionSurroundTypeService.getSurroundType(0, 10)
      .subscribe(
        (res) => {
          this.SurroundTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search Surround Type Type
    searchSurroundType(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionSurroundTypeService.searchSurroundType(this.keyword,0,10).subscribe(res=>{
          this.SurroundTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionSurroundTypeService.deleteSurroundType(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end

}
