import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';
import {ForgotPasswordService} from '../../service/forgot_password.service';
import Swal from "sweetalert2";
import {ConfirmedValidator} from "../validators/confirmed.validator";

@Component({
  selector: 'app-reset-redirect',
  templateUrl: './reset-redirect.component.html',
  styleUrls: ['./reset-redirect.component.scss']
})
export class ResetRedirectComponent implements OnInit {
  newPassword: any;
  resetPasswordForm: FormGroup;
  confirmPassword: any;
  email: any;
  key: any;
  showForm: boolean = false;
  inValidKey: boolean = false;
  validationError;

  passwordNoOfCharacters = '^.{6,}$';

  constructor(private router: Router, private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private notifier: NotifierService, private forgotPasswordService: ForgotPasswordService) {
  }

  get f() {
    return this.resetPasswordForm.controls;
  }

  ngOnInit() {
    this.forgotPasswordService.getValidators().subscribe(data=>{
      this.validationError = data.message;
      this.resetPasswordForm = this.formBuilder.group({
        newPassword: ['', [Validators.required, Validators.pattern(data.data)]],
        confirmPassword: ['', [Validators.required]],
      }, {
        validator: ConfirmedValidator('newPassword', 'confirmPassword')
      });

    })

    this.route.queryParams.subscribe(params => {
      this.key = params['key'];
      this.email = params['email'];

      this.forgotPasswordService.resetPasswordValidate(this.key).subscribe(result => {
        if (result) {
          if (result['message'] === 'PASSWORD RESET REQUEST IS VALID!') {
            this.notifier.notify('success', result['message']);
            this.showForm = true;
          } else {
            this.inValidKey = true;
            this.notifier.notify('error', result['message']);
            this.showForm = false;
          }
        } else {
          this.inValidKey = true;
          this.showForm = false;
        }
      }, error => {
        this.inValidKey = true;
        this.notifier.notify('error', '');
        this.showForm = false;
      });

    });
  }

  resetPassword() {
    if (this.resetPasswordForm.valid === true) {
      this.forgotPasswordService.setupNewPassword(this.key, this.email, this.newPassword).subscribe(setupNewPassword => {
        if (setupNewPassword) {
          if (setupNewPassword['message'] === 'PASSWORD RESET REQUEST SUCCESS! PROCEED TO LOGIN!') {
            // this.notifier.notify('success', setupNewPassword['message']);
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: setupNewPassword['message'],
              showConfirmButton: false,
              timer: 3000
            })
            this.router.navigate(['/login']);
          } else {
            this.inValidKey = true;
          }


        } else {

        }
      }, error => {
      });
    } else {
      this.validateAllFormFields(this.resetPasswordForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
