import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {RegistrationService} from '../../service/registration.service';
import {NotifierService} from 'angular-notifier';
import Swal from 'sweetalert2';
import {ConfirmedValidator} from '../validators/confirmed.validator';
import {UsernameValidator} from '../validators/username.validator';
import {FileUploadGranterComponent} from '../dashboards/dashboard-external/assesment/file-upload-granter/file-upload-granter.component';
import {FutureDateValidator} from '../validators/futuredate.validator';
import { PasswordPolicyService } from 'src/app/service/passwordPolicy/password-policy.service';
import { passwordPolicy } from 'src/app/model/passwordPolicy';
import { SubSink } from 'subsink';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, OnDestroy {

  @ViewChild('fileUploadForm20', {static: false})
  fileUploadForm20: FileUploadGranterComponent;

  selectPaths = [];
  selectPaths2 = [];
  registerForm: FormGroup;
  NameWithInitials: any;
  NIC: any;
  RegistrationNo: any;
  RegisteredLandRegister: any;
  JudicialZone: any;
  Duplicates: any;
  OfficeAddress1: any;
  OfficeAddress2: any;
  OfficeCity: any;
  PersonalAddress1: any;
  PersonalAddress2: any;
  PersonalCity: any;
  Email: any;
  profileImageFile: any;
  uploadSignatureFile: any;
  title: any;
  FirstName: any;
  MiddleName: any;
  LastName: any;
  RegistrationDate: any;
  OfficeNumber: any;
  PersonalNumber: any;
  OfficeContactNo: any;
  OfficeContactMobileNo: any;
  password: any;
  confirmPassword: any;
  maxDate: any;
  isSubmitted: boolean;
  saveButton = 'Submit';
  private sub = new SubSink();

  // Validations
  nicPattern = '^([0-9]{9}[x|X|v|V]|[0-9]{12}|[a-p|A-p|R|r|S|s|T|t|U|u|V|v|W|w|Y|y][0-9]{8}|[0-9]{7}[a-p|A-p|R|r|S|s|T|t|U|u|V|v|W|w|Y|y])$';
  emailPattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
  phoneNumberPattern = '^(?:0|94|\\+94|0094)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|91)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\\d)\\d{6}$';
  onlyTextPattern = '^[ a-zA-Z]*$';
  onlyTextPatternInitals = '^[ a-zA-Z.]*$';
  addressNumberPattern = /^[ a-zA-Z0-9()-./,\']*$/;
  addressPattern = '^[ a-zA-Z0-9()-.,/]*$';
  cityPattern = '^[ a-zA-Z0-9-]*$';
  passwordNoOfCharacters = '^.{6,}$';

  passwordPolicyPattern ='^(?=(.*[A-Z]){$7@})(?=(.*[0-9]){$1@})(?=(.*[#$@!%&*?]){$3@})[A-Za-z0-9#$@!%&*?]{$5@,$6@}$';
  passwordPattern:any;
  public passwordPolicyModel = new passwordPolicy(null,'','','','','','','','','');

  constructor(private router: Router,
              private registrationService: RegistrationService,
              private notifier: NotifierService,
              private formBuilder: FormBuilder,
              public usernameValidator: UsernameValidator) {
  }

  get f() {
    return this.registerForm.controls;
  }

  ngOnInit() {
    //get password policy and edit pattern
    this.getPasswordPolicy();
    this.passwordPattern = new RegExp(sessionStorage.getItem('passwordPolicy'));
    this.registerForm = this.formBuilder.group({
        title: ['', [Validators.required]],
        NameWithInitials: ['', [Validators.required, Validators.pattern(this.onlyTextPatternInitals)]],
        FirstName: ['', [Validators.required, Validators.pattern(this.onlyTextPattern)]],
        MiddleName: ['', [Validators.pattern(this.onlyTextPattern)]],
        LastName: ['', [Validators.required, Validators.pattern(this.onlyTextPattern)]],
        NIC: ['', [Validators.required, Validators.pattern(this.nicPattern)]],
        Email: ['', [Validators.required, Validators.pattern(this.emailPattern)], this.usernameValidator.checkUsername.bind(this.usernameValidator)],
        RegistrationNo: ['', [Validators.required]],
        RegistrationDate: ['', [Validators.required]],
        RegisteredLandRegister: ['', [Validators.pattern(this.addressNumberPattern)]],
        JudicialZone: ['', [Validators.required, Validators.pattern(this.addressNumberPattern)]],
        Duplicates: ['', [Validators.required, Validators.pattern(this.addressNumberPattern)]],
        OfficeNumber: ['', [Validators.required, Validators.pattern(this.addressNumberPattern)]],
        OfficeAddress1: ['', [Validators.required, Validators.pattern(this.addressPattern)]],
        OfficeAddress2: ['', [Validators.required, Validators.pattern(this.addressPattern)]],
        OfficeCity: ['', [Validators.pattern(this.cityPattern)]],
        PersonalNumber: ['', [Validators.required, Validators.pattern(this.addressNumberPattern)]],
        PersonalAddress1: ['', [Validators.required, Validators.pattern(this.addressPattern)]],
        PersonalAddress2: ['', [Validators.required, Validators.pattern(this.addressPattern)]],
        PersonalCity: ['', [Validators.pattern(this.cityPattern)]],
        OfficeContactNo: ['', [Validators.required, Validators.pattern(this.phoneNumberPattern)]],
        OfficeContactMobileNo: ['', [Validators.required, Validators.pattern(this.phoneNumberPattern)]],
        password: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
        confirmPassword: ['', [Validators.required]]
      },
      {
        validator: ConfirmedValidator('password', 'confirmPassword'),
        validatorDate: FutureDateValidator('RegistrationDate')
      });
    // this.registerForm = new FormGroup({
    //   'NameWithInitials': new FormControl(null, Validators.required),
    //   'FirstName': new FormControl(null, Validators.required),
    //   'MiddleName': new FormControl(null),
    //   'LastName': new FormControl(null, Validators.required),
    //   'NIC': new FormControl(null, Validators.required),
    //   'RegistrationNo': new FormControl(null, Validators.required),
    //   'RegistrationDate': new FormControl(null, Validators.required),
    //   'RegisteredLandRegister': new FormControl(null, Validators.required),
    //   'JudicialZone': new FormControl(null, Validators.required),
    //   'Duplicates': new FormControl(null, Validators.required),
    //   'OfficeNumber': new FormControl(null, Validators.required),
    //   'OfficeAddress1': new FormControl(null, Validators.required),
    //   'OfficeAddress2': new FormControl(null, Validators.required),
    //   'OfficeCity': new FormControl(null),
    //   'PersonalNumber': new FormControl(null, Validators.required),
    //   'PersonalAddress1': new FormControl(null, Validators.required),
    //   'PersonalAddress2': new FormControl(null),
    //   'PersonalCity': new FormControl(null),
    //   'Email': new FormControl(null, Validators.required),
    //   'OfficeContactNo': new FormControl(null, Validators.required),
    //   'OfficeContactMobileNo': new FormControl(null, Validators.required),
    //   'ProfileImage': new FormControl(null),
    //   'Signature': new FormControl(null),
    //   'password': new FormControl(null, Validators.required),
    //   'confirmPassword': new FormControl(null, Validators.required),
    // });
    this.maxDate = new Date();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
    sessionStorage.removeItem('passwordPolicy');
  }

  getPasswordPolicy(){
    this.sub.add(
      this.registrationService.getActivatedPasswordPolicy().subscribe(data=>{
        if(data.data == null){
          this.passwordPolicyModel.minNumaric = "03";
          // this.passwordPolicyModel.maxNumaric = "";
          this.passwordPolicyModel.minSpecialChar = "03";
          // this.passwordPolicyModel.maxSpecialChar = "";
          this.passwordPolicyModel.minLength = "06";
          this.passwordPolicyModel.maxLength = "08";
          this.passwordPolicyModel.minUpperChar = "01";
        }else{
          this.passwordPolicyModel=(data as any).data;
        }
        this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$1@",this.passwordPolicyModel.minNumaric);
        // this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$2@",this.passwordPolicyModel.maxNumaric);
        this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$3@",this.passwordPolicyModel.minSpecialChar);
        // this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$4@",this.passwordPolicyModel.maxSpecialChar);
        this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$5@",this.passwordPolicyModel.minLength);
        this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$6@",this.passwordPolicyModel.maxLength);
        this.passwordPolicyPattern = this.passwordPolicyPattern.replace("$7@",this.passwordPolicyModel.minUpperChar);

        sessionStorage.setItem('passwordPolicy',this.passwordPolicyPattern);
      })
    );
  }

  SubmitForm() {
    if (this.checkFormValidation()) {
      this.saveButton = 'saving..';
      if (this.title === undefined) {
        this.title = 'mr';
      }
      const body = {
        title: this.title,
        initials: this.NameWithInitials,
        firstName: this.FirstName,
        middleName: this.MiddleName,
        lastName: this.LastName,
        nicOrPassport: this.NIC,
        registrationNumber: this.RegistrationNo,
        registrationDate: this.RegistrationDate,
        landRegister: this.RegisteredLandRegister,
        judicialZone: this.JudicialZone,
        duplicates: this.Duplicates,
        officialNumber: this.OfficeNumber,
        officialAddressL1: this.OfficeAddress1,
        officialAddressL2: this.OfficeAddress2,
        officialCity: this.OfficeCity,
        personalNumber: this.PersonalNumber,
        personalAddressL1: this.PersonalAddress1,
        personalAddressL2: this.PersonalAddress2,
        personalCity: this.PersonalCity,
        email: this.Email,
        officePhone: this.OfficeContactNo,
        password: this.password,
        personalMobile: this.OfficeContactMobileNo,
        personalImage: this.profileImageFile,
        signatureImage: this.uploadSignatureFile
      };
      // console.log(body);
      this.registrationService.registerService(body).subscribe(result => {
        if (result) {
          this.isSubmitted = true;
          if (result.message === 'SUCCESSFULLY REGISTERED NOTARY USER!') {
            this.notifier.notify('success', 'Notary/Lawyer Registered Successfully');
            // Swal.fire({
            //   position: 'top-end',
            //   icon: 'success',
            //   title: 'Notary/Lawyer Registered Successfully',
            //   showConfirmButton: false,
            //   timer: 7000
            // });
            this.router.navigate(['/']);
          }
        } else {

        }
      }, error => {
        console.log(error);
        this.isSubmitted = false;
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Something went wrong!',
          showConfirmButton: false,
          timer: 5000
        });
        this.saveButton = 'Submit';
      });

    } else {
      this.isSubmitted = false;
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Invalid Data',
        showConfirmButton: false,
        timer: 2000
      });
      this.saveButton = 'Submit';
    }


  }

  setForm20DocArray(images: File[]) {
    this.selectPaths = images;
    this.uploadProfileImage();
  }

  setFormDocArray(images: File[]) {
    this.selectPaths2 = images;
    this.uploadSignature();
  }

  uploadProfileImage() {
    const fd = new FormData();
    fd.append('file', this.selectPaths[0]);
    this.registrationService.registerPersonalImageService(fd).subscribe(result => {
      if (result) {
        this.profileImageFile = result.message;
      } else {

      }
    });
  }

  uploadSignature() {
    const fd = new FormData();
    fd.append('file', this.selectPaths2[0]);
    this.registrationService.registerSignatureImageService(fd).subscribe(result => {
      if (result) {
        this.uploadSignatureFile = result.message;
      } else {

      }
    });
  }

  selectTitle($event) {
    this.title = $event.target.value;
  }

  checkFormValidation(): boolean {
    if (this.registerForm.get('title').valid &&
      this.registerForm.get('NameWithInitials').valid &&
      this.registerForm.get('FirstName').valid &&
      this.registerForm.get('LastName').valid &&
      this.registerForm.get('NIC').valid &&
      this.registerForm.get('Email').valid &&
      this.registerForm.get('RegistrationNo').valid &&
      this.registerForm.get('RegistrationDate').valid &&
      this.registerForm.get('JudicialZone').valid &&
      this.registerForm.get('Duplicates').valid &&
      this.registerForm.get('OfficeNumber').valid &&
      this.registerForm.get('OfficeAddress1').valid &&
      this.registerForm.get('OfficeAddress2').valid &&
      this.registerForm.get('OfficeCity').valid &&
      this.registerForm.get('PersonalNumber').valid &&
      this.registerForm.get('PersonalAddress1').valid &&
      this.registerForm.get('PersonalAddress2').valid &&
      this.registerForm.get('PersonalCity').valid &&
      this.registerForm.get('Email').valid &&
      this.registerForm.get('OfficeContactNo').valid && this.registerForm.get('password').valid && this.registerForm.get('confirmPassword').valid &&
      this.registerForm.get('OfficeContactMobileNo').valid) {
      return true;
    } else {
      this.registerForm.get('title').markAsTouched();
      this.registerForm.get('NameWithInitials').markAsTouched();
      this.registerForm.get('FirstName').markAsTouched();
      this.registerForm.get('LastName').markAsTouched();
      this.registerForm.get('NIC').markAsTouched();
      this.registerForm.get('Email').markAsTouched();
      this.registerForm.get('RegistrationNo').markAsTouched();
      this.registerForm.get('RegistrationDate').markAsTouched();
      this.registerForm.get('JudicialZone').markAsTouched();
      this.registerForm.get('Duplicates').markAsTouched();
      this.registerForm.get('OfficeNumber').markAsTouched();
      this.registerForm.get('OfficeAddress1').markAsTouched();
      this.registerForm.get('OfficeAddress2').markAsTouched();
      this.registerForm.get('OfficeCity').markAsTouched();
      this.registerForm.get('PersonalNumber').markAsTouched();
      this.registerForm.get('PersonalAddress1').markAsTouched();
      this.registerForm.get('PersonalAddress2').markAsTouched();
      this.registerForm.get('PersonalCity').markAsTouched();
      this.registerForm.get('Email').markAsTouched();
      this.registerForm.get('OfficeContactNo').markAsTouched();
      this.registerForm.get('OfficeContactMobileNo').markAsTouched();
      this.registerForm.get('password').markAsTouched();
      this.registerForm.get('confirmPassword').markAsTouched();
      return false;
    }
  }
}
