import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LocalAuthorityService} from '../../../../../../../service/local-authority/local-authority.service';
import {LocalAuthrority} from '../../../../../../../model/local-authrority';
import {Property} from '../../../../../../../model/property';
import {FutureDateValidator} from '../../../../../../validators/futuredate.validator';
import {DeedNatureService} from '../../../../../../../service/deed-nature/deed-nature.service';
import {SubSink} from 'subsink';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';

@Component({
  selector: 'app-property-deed-details-fr',
  templateUrl: './property-deed-details-fr.component.html',
  styleUrls: ['./property-deed-details-fr.component.scss']
})
export class PropertyDeedDetailsFrComponent implements OnInit {
  @Input() propertyOne: Property;
  @Input() valuationRequest;
  @Output() valueChange = new EventEmitter();
  propertyDeedDetailsForm: FormGroup;
  deedNatures = [];
  localAuthorities: LocalAuthrority[];
  localAuthorityNumber: number;
  localAuthorityID: number;
  maxDate: any;
  selectedComponent: string;
  futureDateError: boolean;
  map: any;


  // Validation
  onlyCharactersNumbersPattern = '^[_A-z0-9 ]*((-|\\s)*[_A-z0-9 ])*$';
  onlyCharactersPattern = '^[ a-zA-Z]*$';
  surveyorsNamePattern = '^[ a-zA-Z.]*$';
  lastRegDate: any;
  lastDeedRegDate: any;
  private sub = new SubSink();

  constructor(private deedNatureService: DeedNatureService,
              private localAuthorityService: LocalAuthorityService,
              private formBuilder: FormBuilder,
              private notifier: NotifierService) {
  }

  get f() {
    return this.propertyDeedDetailsForm.controls;
  }

  get localAuthority() {
    return this.propertyDeedDetailsForm.get('localAuthority') as FormControl;
  }

  get localAuthorityNo() {
    return this.propertyDeedDetailsForm.get('localAuthorityNo') as FormControl;
  }

  ngOnInit() {
    this.propertyDeedDetailsForm = this.formBuilder.group({
      deedNature: [this.propertyOne.deedNature],
      // previousDeedNo: [this.propertyOne.previousDeedNo, [Validators.required]],
      previousDeedNo: [this.propertyOne.previousDeedNo],
      previousDeedNature: [this.propertyOne.previousDeedNature, [Validators.required]],
      // previousDeedDate: [this.propertyOne.previousDeedDate, [Validators.required]],
      previousDeedDate: [this.propertyOne.previousDeedDate],
      // previousRegistrationNo: [this.propertyOne.previousRegistrationNo, [Validators.required]],
      previousRegistrationNo: [this.propertyOne.previousRegistrationNo],
      // folioVolume: [this.propertyOne.folioVolume, Validators.required],
      folioVolume: [this.propertyOne.folioVolume],
      // previousRegistrationDate: [this.propertyOne.previousRegistrationDate, [Validators.required]],
      previousRegistrationDate: [this.propertyOne.previousRegistrationDate],
      localAuthority: [this.propertyOne.localAuthority, [Validators.required]],
      localAuthorityNo: [this.propertyOne.localAuthorityNo],
      cmcNo: [this.propertyOne.cmcNo],
      surveyorPlanNo: [this.propertyOne.surveyorPlanNo, [Validators.required]],
      surveyorPlanDate: [this.propertyOne.surveyorPlanDate, [Validators.required]],
      surveyorName: [this.propertyOne.surveyorName, [Validators.required, Validators.pattern(this.surveyorsNamePattern)]],
      // surveyorLicenseNo: [this.propertyOne.surveyorLicenseNo, [Validators.required]],
      surveyorLicenseNo: [this.propertyOne.surveyorLicenseNo],
      lotNo: [this.propertyOne.lotNo, [Validators.required]],
      nameOfTheProperty: [this.propertyOne.nameOfTheProperty]
    }, {
      validator: [FutureDateValidator('previousDeedDate'), FutureDateValidator('previousRegistrationDate'), FutureDateValidator('surveyorPlanDate')]
    });
    this.propertyDeedDetailsForm.value.previousDeedNature = null;
    this.propertyDeedDetailsForm.value.deedNature = null;
    this.propertyDeedDetailsForm.value.localAuthority = null;

    this.valueChange.emit(this.propertyDeedDetailsForm);

    this.sub.add(this.deedNatureService.getAll()
      .subscribe(
        (res) => {
          this.deedNatures = (res as any).data;
        }
      ));

    this.sub.add(this.localAuthorityService.getAllByOrderByLocalAuthorityAsc()
      .subscribe(
        (res) => {
          this.localAuthorities = (res as any).data;

          this.map = new Map();
          this.localAuthorities.forEach(element => {
            if (element.localAuthority === 'Colombo') {
              this.localAuthorityID = element.id;
            }
            this.map.set(element.id, element.localAuthority);
          });
        }
      ));

    this.localAuthority.valueChanges.subscribe(
      value => {
        const index = this.localAuthorities.findIndex(val => val.id == value);
        this.localAuthorityNumber = this.localAuthorities[index].localAuthorityNo;
        console.log('this.localAuthorityNumber', this.localAuthorityNumber);
        this.localAuthorityNo.setValue(this.localAuthorityNumber);
      }
    );
    this.maxDate = new Date();
    this.setSelectedDeedNature();
  }

  getlastRegDeedDate() {
    return this.propertyDeedDetailsForm.get('previousDeedDate').value;
  }

  getlastRegDate() {
    return this.propertyDeedDetailsForm.get('previousRegistrationDate').value;
  }

  saveDeedDetails(): boolean {
    this.propertyDeedDetailsForm.markAllAsTouched();
    if (this.propertyDeedDetailsForm.valid) {
      if (this.getlastRegDate() !== null && this.getlastRegDate() !== '') {
        if (this.checkDates()) {
          this.setPropertyDeedDetails();
          return true;
        } else {
          this.notifier.notify('error', 'Previous Deed Date should be a past date compared to Previous Deed Registered Date');
          return false;
        }
      } else {
        this.setPropertyDeedDetails();
        return true;
      }
    } else {
      return false;
    }
  }

  checkDates() {
    this.lastRegDate = this.getlastRegDate().split('-');
    this.lastDeedRegDate = this.getlastRegDeedDate().split('-');
    if (this.lastRegDate > this.lastDeedRegDate) {
      return true;
    } else {
      return false;
    }
  }

  checkValidity(): void {
    if (!this.propertyDeedDetailsForm.valid) {
      return;
    }
  }

  onChange(data) {
    if (data === this.localAuthorityID.toString()) {
      this.propertyDeedDetailsForm.get('cmcNo').enable();
    } else {
      this.propertyDeedDetailsForm.get('cmcNo').setValue(0);
      this.propertyDeedDetailsForm.get('cmcNo').disable();
    }
  }

  setSelectedDeedNature() {
    this.selectedComponent = sessionStorage.getItem('selectedType');
  }

  private setPropertyDeedDetails() {
    this.propertyOne.deedNature = this.selectedComponent;
    this.propertyOne.previousDeedNo = this.propertyDeedDetailsForm.get('previousDeedNo').value;
    this.propertyOne.previousDeedNature = this.propertyDeedDetailsForm.get('previousDeedNature').value;
    this.propertyOne.previousDeedDate = this.propertyDeedDetailsForm.get('previousDeedDate').value;
    this.propertyOne.previousRegistrationDate = this.propertyDeedDetailsForm.get('previousRegistrationDate').value;
    this.propertyOne.previousRegistrationNo = this.propertyDeedDetailsForm.get('previousRegistrationNo').value;
    this.propertyOne.localAuthority = this.propertyDeedDetailsForm.get('localAuthority').value;
    this.propertyOne.localAuthorityNo = this.propertyDeedDetailsForm.get('localAuthorityNo').value;
    this.propertyOne.folioVolume = this.propertyDeedDetailsForm.get('folioVolume').value;
    this.propertyOne.cmcNo = this.propertyDeedDetailsForm.get('cmcNo').value;
    this.propertyOne.surveyorLicenseNo = this.propertyDeedDetailsForm.get('surveyorLicenseNo').value;
    this.propertyOne.surveyorPlanDate = this.propertyDeedDetailsForm.get('surveyorPlanDate').value;
    this.propertyOne.surveyorName = this.propertyDeedDetailsForm.get('surveyorName').value;
    this.propertyOne.surveyorPlanNo = this.propertyDeedDetailsForm.get('surveyorPlanNo').value;
    this.propertyOne.lotNo = this.propertyDeedDetailsForm.get('lotNo').value;
    this.propertyOne.nameOfTheProperty = this.propertyDeedDetailsForm.get('nameOfTheProperty').value;
    localStorage.setItem('deed', JSON.stringify(this.propertyOne));
  }

}
