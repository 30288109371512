import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SubSink } from 'subsink';
import { Constant } from 'src/app/util/constant';
import { ValuationRequest } from 'src/app/model/valuation-request';
import { ValuationRequestService } from 'src/app/service/valuation-request/valuation-request.service';
import { CheckBoxStatus } from 'src/app/model/checkBoxStatus';

@Component({
  selector: 'app-wizard-internal-exchange',
  templateUrl: './wizard-internal-exchange.component.html',
  styleUrls: ['./wizard-internal-exchange.component.scss']
})
export class WizardInternalExchangeComponent implements OnInit {
  selectSideNav = 1;
  private sub = new SubSink();
  valuationRequest: ValuationRequest;
  valuationRequest2: ValuationRequest;

  userdata: any = [];

  permissiongranted: boolean;

  valuationExchangeId: any;

  // Subscription to monitor the navigation
  navigationSubscription;

  public checkBoxStatusModel = new CheckBoxStatus('','','','','','');

  public grantaccess: boolean;

  public showsitevisit1: boolean;
  public showsitevisit2: boolean;

  public showcal1: boolean;
  public showcal2: boolean;
  party1 = "party1";
  party2 = "party2";
  isReject: boolean;
  userRole: string;

  selectedParty:string;
  selectedType: string;

  constructor(
    private route: ActivatedRoute,
    private valuationRequestService: ValuationRequestService,
    public router: Router
  ) {
    this.permissiongranted = false;
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.valuationRequest = new ValuationRequest();
    this.grantaccess = false;

    this.showsitevisit1 = true;
    this.showsitevisit2 = false;

    this.showcal1 = true;
    this.showcal2 = false;

    this.userRole="";
    this.isReject = false;

    this.selectedParty ="";
    this.selectedType = "";
  }

  public changeComponent(page: any): void {
    this.selectSideNav = page;
  }

  public partySelection(party:any):void{
    this.selectedParty = party;
  }

  public typeSelection(type:string):void{
    this.selectedType = type;
  }

  viewSite1() {
    this.showsitevisit1 = true;
    this.showsitevisit2 = false;
  }

  viewSite2() {
    this.showsitevisit1 = false;
    this.showsitevisit2 = true;
  }

  viewCalculation1() {
    this.showcal1 = true;
    this.showcal2 = false;
  }

  viewCalculation2() {
    this.showcal1 = false;
    this.showcal2 = true;
  }

  checkPermission() {
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.permissiongranted = true;
        this.userRole = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.permissiongranted = true;
        this.userRole = Constant.ROLE_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.permissiongranted = true;
        this.userRole = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.permissiongranted = false;
        this.userRole = Constant.ROLE_ACCESSOR;
        this.selectSideNav = 2;
        break;
      } else {
        this.permissiongranted = false;
        this.selectSideNav = 2;
      }
    }
  }

  ngOnInit() {
    this._prepare();
    sessionStorage.setItem('deedType', 'exchange');
    this.checkStatus(true);
  }

  _prepare() {
    this.checkPermission();
    const valuationRequestId = +this.route.snapshot.paramMap.get('id');
    this.valuationRequest.id = valuationRequestId;
    this.getValuationRequest(valuationRequestId);

  }

  switchedToParty1() {
    console.log("Switched to party 1");
    sessionStorage.setItem('selectedPartyInExchange', '1');
    this.selectedParty ='1';
  }

  switchedToParty2() {
    console.log("Switched to party 2");
    sessionStorage.setItem('selectedPartyInExchange', '2');
    this.selectedParty ='2';
  }

  getValuationRequest(valuationRequestId: number) {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(valuationRequestId)
      .subscribe((res) => {
        this.valuationRequest = res.data;
        if(this.valuationRequest.exchangeId != null){
          this.getExchangeRequest(this.valuationRequest.exchangeId);
        }
        localStorage.setItem('valuationRequestStatus', this.valuationRequest.valuationRequestStatus);

        if(this.userRole == Constant.ROLE_ACCESSOR){
          if(this.valuationRequest.accessorStatus == 'REJECTED'){
          this.selectSideNav = 0;
          this.isReject = true;
          }else{
              this.isReject = false;
          }
        }else if(this.userRole == Constant.ROLE_DEPUTY_COMMISSIONER){
          if(this.valuationRequest.dcommissionerStatus == 'REJECTED'){
          this.selectSideNav = 0;
          this.isReject = true;
          }else{
              this.isReject = false;
          }
        }else if(this.userRole == Constant.ROLE_COMMISSIONER){
          if(this.valuationRequest.commissionerStatus == 'REJECTED'){
          this.selectSideNav = 0;
          this.isReject = true;
          }else{
              this.isReject = false;
          }
        }
        sessionStorage.setItem('valuationRequest', JSON.stringify(this.valuationRequest));
        this.valuationRequest.id = valuationRequestId;
      }));
      this.getCheckboxStatus();
  }

  getExchangeRequest(id: any) {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(id)
    .subscribe((res) => {
      console.log(id);
      
      this.valuationRequest2 = res.data;
      console.log(this.valuationRequest2)
      localStorage.setItem('valuationRequestStatus2', this.valuationRequest2.valuationRequestStatus);
      sessionStorage.setItem('valuationRequest2', JSON.stringify(this.valuationRequest2));
      // this.valuationRequest2.id = id;
      console.log(this.valuationRequest2.id);
      this.valuationExchangeId = this.valuationRequest2.id;

    }));
  }

  getCheckboxStatus() {
    this.valuationRequestService.checkboxValuationRequestStatus(this.valuationRequest.id).subscribe(
      data => {
        this.checkBoxStatusModel = data.data[0]
        if(this.valuationRequest.properties[0].buildings.length>0){
          if (this.checkBoxStatusModel.granter_status === '1' &&
          this.checkBoxStatusModel.grantee_status === '1' &&
          // this.checkBoxStatusModel.notary_status === '1' &&
          this.checkBoxStatusModel.building_status === '1' &&
          this.checkBoxStatusModel.property_status === '1') {
            this.grantaccess = true;
          } else {
            this.grantaccess = false;
          }
        }else{
          if (this.checkBoxStatusModel.granter_status === '1' &&
          this.checkBoxStatusModel.grantee_status === '1' &&
          // this.checkBoxStatusModel.notary_status === '1' &&
          this.checkBoxStatusModel.property_status === '1') {
            this.grantaccess = true;
            this.checkBoxStatusModel.building_status = '1';
          } else {
            this.grantaccess = false;
            this.checkBoxStatusModel.building_status = '1';
          }
        }
      }
    )
  }

  public checkStatus(type:boolean) : void{
    if(type === true){
      this.getCheckboxStatus()
    }
  }

  checkSubmitFurtherRequirementButtonStatus()
  {
    console.log(this.valuationRequest)
    if(this.valuationRequest.valuationRequestStatus ===  Constant.VALUATION_REQUEST_FURTHER_REQUIREMENT_CODE)
    {
      return false;
    }
    else
    {
      if(this.userdata.roles[0].code === Constant.ROLE_ACCESSOR && this.valuationRequest.accessorStatus === Constant.VALUATION_REQUEST_FURTHER_REQUIREMENT_CODE)
      {
        return true;
      }
      else if(this.userdata.roles[0].code === Constant.ROLE_DEPUTY_COMMISSIONER && this.valuationRequest.dcommissionerStatus === Constant.VALUATION_REQUEST_FURTHER_REQUIREMENT_CODE)
      {
        return true;
      }
      else if(this.userdata.roles[0].code === Constant.ROLE_COMMISSIONER && this.valuationRequest.dcommissionerStatus === Constant.VALUATION_REQUEST_FURTHER_REQUIREMENT_CODE)
      {
        return true;
      }
      else
      {
        return false;
      }
    }
  }

}
