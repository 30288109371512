import {AfterViewInit, Component, OnInit} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ReportServiceService} from '../../stamp-duty-opinion-reports/service/report-service.service';
import {NotifierService} from 'angular-notifier';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-stamp-duty-refund-application-recieved-report',
  templateUrl: './stamp-duty-refund-application-recieved-report.component.html',
  styleUrls: ['./stamp-duty-refund-application-recieved-report.component.scss']
})
export class StampDutyRefundApplicationRecievedReportComponent implements OnInit, AfterViewInit {

  // submitted date range
  fromDate: string = null;
  toDate: string = null;
  notary: string = null;
  requestedAmount: string = null;
  reason: string = null;
  currentDate =new Date().toISOString().split('T')[0];

  public reportDetails: any = [];
  public showPrint: boolean = false;
  public selectedDetails: any;
  public download_excel_status: boolean = false;

  // for section
  selectedItemsSection = [];
  dropdownListSection = [];
  dropdownSettingsSection: IDropdownSettings = {};

  // report data footer data
  public total_count: number = 0;
  public total_declare_value: number = 0;

  constructor(public modalService: NgbModal,
              public reportService: ReportServiceService,
              private notifier: NotifierService) { }

  ngOnInit() {

    // set up local authority dropdown
    this.dropdownSettingsSection = {
      singleSelection: false,
      idField: 'id',
      textField: 'section',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.setDatatoSectionDropDown();

  }

  ngAfterViewInit(): void {
  }

  onSelectAllSection(items: any) {

  }

  generateExcelReport() {
    this.getReportData();
    this.download_excel_status = true;
    setTimeout(() => {
      this.getExcelReportView();
    }, 1000);
  }

  generatePdfReport() {
    this.getReportData();
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }

  setDatatoSectionDropDown(){
    var sectionObject65 = {
      id : 1,
      name: "Section 65"
    }
    var sectionObject101 = {
      id : 2,
      name: "Section 101"
    }
    this.dropdownListSection.push(sectionObject65);
    this.dropdownListSection.push(sectionObject101);
  }

  onItemSelectSection(item: any) {
    // console.log(item);
  }

  getReportData(){
    this.captureSelectedDetails();
    this.showPrint = true;
    let dataLength = 0;
    this.reportService.getStampDutyRefundApplicationReceivedReport(this.fromDate, this.toDate, this.selectedDetails.selected_section_type,this.selectedDetails.selected_requested_amount,this.selectedDetails.selected_reason,this.selectedDetails.selected_notary).subscribe(value => {
      dataLength = value.data.length;
      if(value.data.length > 0){
        this.reportDetails = value.data;
        this.total_count = value.data.length;
        this.getTotalColumns(value.data);
        setTimeout(()=>{
          this.getPdfReportView();
        },1000);
      } else {
        this.notifier.notify('error', 'No records found!');
      }
    });
    return dataLength;
  }

  captureSelectedDetails(): void{
    let lastDate = "";
    if(this.toDate == null){
      lastDate = "Today";
    } else {
      lastDate = this.toDate;
    }
    var date_range = ((this.fromDate == null) && (this.toDate == null)) ? "All Date" : this.fromDate + ' to ' + lastDate;
    var selectedSection_Names = "";
    var selectedSection = "";
    if(this.selectedItemsSection != null){
      this.selectedItemsSection.forEach(value => {
        selectedSection += value.id + ",";
        selectedSection_Names += value.name + ",";
      });
    } else {
      selectedSection_Names = "N/A";
      selectedSection = "";
    }

    this.selectedDetails = {
      selected_date: date_range,
      selected_section_type: selectedSection,
      selected_requested_amount: this.requestedAmount,
      selected_reason: this.reason,
      selected_notary: this.notary
    };

  }

  getTotalColumns(data: []): void {
    data.forEach((record: any) => {
      this.total_declare_value += +record.declaredValue;
    });
  }

  getPdfReportView(): void{
    let printContents: any;
    let popupWin: any;

    printContents = document.getElementById('stampDutyRefundApllicationReceivedReportContent').innerHTML;

    popupWin = window.open();
    popupWin.document.open();
    popupWin.document.write(`
    <html>
    <head>
        <title>Registration Details</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <!--<script src="../../../../assets/js/paged.js"></script>-->
        <!--<script src="../../../../assets/js/paged.polyfill.js"></script>-->
        <style>
          @page {
            size: A3 landscape;
            @bottom-right {
              content: "Page " counter(page) " of " counter(pages);
            }
            .page{
                page-break-after: always;
            }
          }

            body
            {
                margin: 10mm 10mm 0mm 10mm;

            }
            table{
              border: 1px solid #000;
              border-collapse: collapse;
            }
            tbody{
              page-break-before: always;
            }

            table th, table td
            {
                padding: 5px;
                margin: 0px;
                border: 1px solid #000;
            }
            .page{
                page-break-after: always;
            }

        </style>
    </head>
    <body onload="window.print();">
            ${printContents}
    </body>
    </html>`
    );
    popupWin.document.close();
  }

  getExcelReportView(): void {
    var printContents = document.getElementById('excel_print');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(printContents);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Stamp. Details');
    XLSX.writeFile(wb, 'Stamp Duty Refund Application Received Report.xlsx');
  }

}
