import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { GramaniladariDivisionModel } from 'src/app/model/gramaNiladariDivisionModel';
import { DistrictNewService } from 'src/app/service/district/district-new.service';
import { GramaNiladariDivisionNewService } from 'src/app/service/grama-niladari-division/grama-niladari-division-new.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-grama-niladari-division-new',
  templateUrl: './grama-niladari-division-new.component.html',
  styleUrls: ['./grama-niladari-division-new.component.scss']
})
export class GramaNiladariDivisionNewComponent implements OnInit,OnDestroy {


  createGramaNiladariDivisionForm: FormGroup;
  updateGramaNiladariDivisionForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  districts: any =[];
  // activationButton: boolean;
  public createGramaNiladariDivisionModel = new GramaniladariDivisionModel(null,'','',null);
  public updateGramaNiladariDivisionModel = new GramaniladariDivisionModel(null,'','',null);

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;
  GramaNiladariDivisions: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionGramaNiladariDivisionService: GramaNiladariDivisionNewService,
    private districtService : DistrictNewService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createGramaNiladariDivisionForm = this.formBuilder.group({
      district: ['', [Validators.required]],
      code: ['', [Validators.required]],
      description: ['',[Validators.required]],
    });
    this.updateGramaNiladariDivisionForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      district: ['', [Validators.required]],
      code: ['', [Validators.required]],
      description: ['',[Validators.required]],
    });

    this.getAllDistricts();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }

  getAllDistricts(){
    this.districtService.getAllDistrictLists().subscribe(res=>{
      this.districts = (res as any).data;
      console.log(res)
    })
  }

  //create GramaNiladariDivision - start

  get f() { return this.createGramaNiladariDivisionForm.controls; }

  setGramaNiladariDivisionModelToSave(){
    this.createGramaNiladariDivisionModel.district = this.createGramaNiladariDivisionForm.get('district').value;
    this.createGramaNiladariDivisionModel.description = this.createGramaNiladariDivisionForm.get('description').value;
    this.createGramaNiladariDivisionModel.code = this.createGramaNiladariDivisionForm.get('code').value;
  }

    //save GramaNiladariDivision model
  saveGramaNiladariDivision(){
    this.setGramaNiladariDivisionModelToSave();
    this.sub.add(
      this.inspectionGramaNiladariDivisionService.createGramaNiladariDivision(this.createGramaNiladariDivisionModel).subscribe(data=>{
        this.notifier.notify('success', (data as any).message);
        this.updateTheTable();
        this.reset();
      })
      );
  }

  reset(){
    this.createGramaNiladariDivisionForm.reset()
  }
  //create GramaNiladariDivision - end

  //update GramaNiladariDivision - start

    //get update controllers
  get f1() { return this.updateGramaNiladariDivisionForm.controls; }

    //set data To GramaNiladariDivision Type model
  setGramaNiladariDivision(district:any){
    this.updateGramaNiladariDivisionForm.get('id').setValue(district.id);
    this.updateGramaNiladariDivisionForm.get('district').setValue(district.district.id);
    this.updateGramaNiladariDivisionForm.get('description').setValue(district.description);
    this.updateGramaNiladariDivisionForm.get('code').setValue(district.code);

  }


    //setting data model to update
  setDataToModel(){
    this.updateGramaNiladariDivisionModel.id = this.updateGramaNiladariDivisionForm.get('id').value;
    this.updateGramaNiladariDivisionModel.district = this.updateGramaNiladariDivisionForm.get('district').value;
    this.updateGramaNiladariDivisionModel.description = this.updateGramaNiladariDivisionForm.get('description').value;
    this.updateGramaNiladariDivisionModel.code = this.updateGramaNiladariDivisionForm.get('code').value;

  }


    //send updated data to db
  updateGramaNiladariDivision(){
    this.setDataToModel();
    this.sub.add(this.inspectionGramaNiladariDivisionService.updateGramaNiladariDivision(this.updateGramaNiladariDivisionModel.id, this.updateGramaNiladariDivisionModel).subscribe(data=>{
      this.notifier.notify('success', (data as any).message);
      this.updateTheTable();
    }))
  }

  //update GramaNiladariDivision - end

//search and view table start
  pageable(i: number) {
    if(this.keyword != null){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionGramaNiladariDivisionService.searchGramaNiladariDivision(this.keyword,(i-1),10).subscribe(res=>{
          this.GramaNiladariDivisions = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }else{
      this.inspectionGramaNiladariDivisionService.getGramaNiladariDivision((i-1),10).subscribe(res=>{
        this.GramaNiladariDivisions = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      })
    }
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionGramaNiladariDivisionService.getGramaNiladariDivision(0, 10)
      .subscribe(
        (res) => {
          this.GramaNiladariDivisions = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;

          console.log(res)
        }));
  }

    //Search GramaNiladariDivision Type
    searchGramaNiladariDivision(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionGramaNiladariDivisionService.searchGramaNiladariDivision(this.keyword,0,10).subscribe(res=>{
          this.GramaNiladariDivisions = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionGramaNiladariDivisionService.deleteGramaNiladariDivision(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end

}
