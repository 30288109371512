import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NotifierService} from 'angular-notifier';
import {Observable, of, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {PropertyUsageSubtype} from '../../model/propertyUsageSubtype';

@Injectable({
  providedIn: 'root'
})
export class PropertyUsageSubtypeService {
  private propertyUsageSubtypeUrl = '/property-usage-subtypes';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient,
              private notifier: NotifierService) { }

  getPropertyUsageSubtypes(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.propertyUsageSubtypeUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  getAll(): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.propertyUsageSubtypeUrl + '/all');

  }

  createPropertyUsageSubType(propertyUsageId: number, propertyUsageSubtype: PropertyUsageSubtype): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/property-usages/${propertyUsageId}/property-usage-sub-types`,
      JSON.stringify(propertyUsageSubtype), this.httpOptions)
      .pipe(tap(_ => this.log(`property usage sub type create=${propertyUsageSubtype}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('property usage sub type create'))
      );
  }

  updatePropertyUsageSubtype(id: number, propertyUsageSubtype: PropertyUsageSubtype): Observable<any> {
    return this.http.put(`${environment.api_url}${this.propertyUsageSubtypeUrl}/${id}`, propertyUsageSubtype)
      .pipe(tap(_ => this.log(`property usage sub type id=${propertyUsageSubtype.id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('property usage sub type update'))
      );
  }

  /** DELETE: delete the property usage sub type on the server */
  deletePropertyUsageSubtype(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.propertyUsageSubtypeUrl}/${id}`)
      .pipe(tap(_ => this.log(`property usage sub type  id=${id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('property usage sub type'))
      );
  }

  /* SEARCH: search Property Usage SubType from server*/
  searchPropertyUsageSubType(keyword: string, offset: number, limit: number): Observable<any> {

    // Parameters
    const params = {
      params: {
        offset: offset.toString(),
        limit: limit.toString(),
        keyword
      }
    };
    return this.http.get<any[]>(`${environment.api_url}${this.propertyUsageSubtypeUrl}/search`, params)
      .pipe(tap(_ => this.log('search Property usage sub-type')),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('get property usage sub-type'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {

  }

}
