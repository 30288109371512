import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InspectionCategoryPropertyTypeService {

  private inspectionCategoryPropertyTypeUrl = '/inspection-category-property-type';


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  createCategoryPropertyType(CategoryPropertyTypeModel:InspectionCommonDataModel):Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.inspectionCategoryPropertyTypeUrl}`,CategoryPropertyTypeModel)
  }

  getCategoryPropertyType(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.inspectionCategoryPropertyTypeUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  updateCategoryPropertyType(id: number, CategoryPropertyTypeModel:InspectionCommonDataModel): Observable<any> {
    return this.http.put(`${environment.api_url}${this.inspectionCategoryPropertyTypeUrl}/${id}`, CategoryPropertyTypeModel);
  }

  searchCategoryPropertyType(keyWord:string,offset, limit):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.inspectionCategoryPropertyTypeUrl +'/search?keyword='+ keyWord + '&offset=' + offset + '&limit=' + limit + '');
  }

  deleteCategoryPropertyType(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.inspectionCategoryPropertyTypeUrl}/${id}`);
  }
}
