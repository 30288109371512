import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PropertyTypeService } from 'src/app/service/property-type/property-type.service';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-delete-property-type',
  templateUrl: './delete-property-type.component.html',
  styleUrls: ['./delete-property-type.component.scss']
})
export class DeletePropertyTypeComponent implements OnInit, OnDestroy {

  private propertyTypeId: number;

  private sub = new SubSink();
  @Output() propertyTypeDeleted = new EventEmitter<boolean>();


  constructor(private propertyTypeService: PropertyTypeService,
              private notifier: NotifierService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  updateTheView(deleted: boolean) {
    this.propertyTypeDeleted.emit(deleted);
  }

  setDeedNatureId(id: number) {
    this.propertyTypeId = id;
  }

  deletePropertyType() {
    this.sub.add(this.propertyTypeService.deletePropertyType(this.propertyTypeId)
      .subscribe(response => {
        this.updateTheView(true);
        this.notifier.notify('success', (response as any).message);
      }));
  }
}






