import {Component, Input, OnInit} from '@angular/core';
import {FileService} from '../../../../../../../service/file/file.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {IGrantee} from '../../../../../../../model/interfaces/grantee';
import {IGranter} from '../../../../../../../model/interfaces/granter';
import {SubSink} from 'subsink';
import {ValuationRequestService} from '../../../../../../../service/valuation-request/valuation-request.service';
import {ValuationRequest} from '../../../../../../../model/valuation-request';
import {NgxSpinnerService} from 'ngx-spinner';
import {  PDFDocumentProxy, PDFProgressData } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-view-other',
  templateUrl: './view-other.component.html',
  styleUrls: ['./view-other.component.scss']
})
export class ViewOtherComponent implements OnInit {

  @Input()
  grantees: IGrantee [];

   @Input()
  granters: IGranter [];

  selectFileSm = 0;
  valuationRequestId: any;
  exchangeValuationRequestId: any;
  previewFileData: any;
  type: any;
  isExchange: boolean;
  valuationRequest: ValuationRequest;

  private sub = new SubSink();
  noDocs = false;
  dataDone = false;
  
  constructor(private fileService: FileService, private route: ActivatedRoute, private  router: Router,
              public sanitizer: DomSanitizer, private valuationRequestService: ValuationRequestService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.valuationRequestId = +this.route.snapshot.paramMap.get('id');
    this.getValuationRequest(this.valuationRequestId);
  }

  onProgress(progressData: PDFProgressData) {
    if (progressData.loaded == progressData.total) {
      this.spinner.hide();
    }
  }

  previewFile(type) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadFileById(this.valuationRequestId, type).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(() => {
          this.spinner.hide();
        }, 2000);

      } else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }

  getValuationRequest(valuationRequestId: number) {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(valuationRequestId)
      .subscribe((res) => {
        this.valuationRequest = res.data;

        if (this.valuationRequest.deedNature === 'Exchange') {
          this.isExchange = true;
          this.exchangeValuationRequestId = this.valuationRequest.exchangeId;
        } else {
          this.isExchange = false;
        }
      }));
  }

  previewParty2File(type) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadFileById(this.exchangeValuationRequestId, type).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.dataDone = true;
        this.noDocs = false;
        setTimeout(() => {
          this.spinner.hide();
        }, 2000);

      } else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.spinner.hide();
      this.dataDone = true;
      this.noDocs = true;
    });
  }

  // backToList() {
  //   this.sub.add(this.valuationRequestService.findByValuationRequestId(Number(this.valuationRequestId))
  //     .subscribe((res) => {
  //       this.valuationRequest = res.data;
  //
  //       console.log(this.valuationRequest.valuationRequestStatus + '................');
  //
  //       if (this.valuationRequest.valuationRequestStatus === 'PENDING') {
  //         this.router.navigate(['/view-assessment/pending-requests']);
  //       }
  //       if (this.valuationRequest.valuationRequestStatus === 'COMPLETED') {
  //         this.router.navigate(['/view-assessment/completed-requests']);
  //       }
  //       if (this.valuationRequest.valuationRequestStatus === 'REJECTED') {
  //         this.router.navigate(['/view-assessment/rejected-requests']);
  //       }
  //     }));
  // }
}
