import {Component, OnInit, ViewChild} from '@angular/core';
import {ViewCompanyTypeComponent} from './view-company-type/view-company-type.component';

@Component({
  selector: 'app-company-type',
  templateUrl: './company-type.component.html',
  styleUrls: ['./company-type.component.scss']
})
export class CompanyTypeComponent implements OnInit {
  @ViewChild(ViewCompanyTypeComponent, {static: false})
  private viewCompanyTypeComponent: ViewCompanyTypeComponent;
  keyword: string;

  constructor() { }

  ngOnInit() {
  }

  triggerSearch(event: any) {
    if (this.keyword === '') {
      this.viewCompanyTypeComponent.onUpdated(true);
    } else {
      this.viewCompanyTypeComponent.searchCompanyType(this.keyword);
    }
  }

}
