import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inspection-master-data',
  templateUrl: './inspection-master-data.component.html',
  styleUrls: ['./inspection-master-data.component.scss']
})
export class InspectionMasterDataComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
