import {AfterViewInit, Component, OnInit} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ReportServiceService} from '../../stamp-duty-opinion-reports/service/report-service.service';
import {NotifierService} from 'angular-notifier';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-total-reimbursement-report',
  templateUrl: './total-reimbursement-report.component.html',
  styleUrls: ['./total-reimbursement-report.component.scss']
})
export class TotalReimbursementReportComponent implements OnInit {
  // submitted date range
  fromDate: string = null;
  public showPrint: boolean = false;
  toDate: string = null;
  reimbursementFileNo: string = null;
  currentDate = new Date().toISOString().split('T')[0];

  public total_count: number = 0;
  public reportDetails: any = [];

  public total_declare_value: number = 0;

  public selectedDetails: any;

  public download_excel_status: boolean = false;
  // for local authorities
  selectedItemsLA = [];
  dropdownListLA = [];
  dropdownSettingsLA: IDropdownSettings = {};

  // for Land Registry
  selectedItemsLR = [];
  dropdownListLR = [];
  dropdownSettingsLR: IDropdownSettings = {};
  constructor(public modalService: NgbModal,
              public reportService: ReportServiceService,
              private notifier: NotifierService) { }

  ngOnInit() {
    // set up local authority dropdown
    this.dropdownSettingsLA = {
      singleSelection: false,
      idField: 'id',
      textField: 'localAuthority',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    // set up land registry dropdown
    this.dropdownSettingsLR = {
      singleSelection: false,
      idField: 'id',
      textField: 'landRegistry',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.get_all_local_authority();
    this.get_all_land_registry();
  }

  /*************** DROP DOWN SERVICES ***************/
  // retrieve local authority data from service
  get_all_local_authority() {
    this.reportService.getAllLA().subscribe(value => {
      if (value.data.length > 0) {
        this.dropdownListLA = value.data;
      } else {
        // console.log("No Local authority return");
      }
    });
  }
  // retrieve property type data from service
  get_all_land_registry() {
    this.reportService.getAllLandRegistry().subscribe(value => {
      if (value.data.length > 0) {
        this.dropdownListLR = value.data;
      } else {
        // console.log("No Land Registry return");
      }
    });
  }
  /*************** DROP DOWN SERVICES ***************/

  /*************** UI EVENT ***************/
  generatePdfReport() {
    this.getReportData();
  }
  closeModal(): void {
    this.modalService.dismissAll();
  }
  onSelectAllLR(items: any) {

  }
  onItemSelectLR(item: any) {
    // console.log(item);
  }

  captureSelectedDetails():void{
    let lastDate = "";
    if(this.toDate == null || this.toDate == ""){
      lastDate = "Today";
    }else {
      lastDate = this.toDate;
    }

    var date_range = ((this.fromDate == null || this.fromDate == "") && (this.toDate == null || this.toDate == "")) ? "All Date" : (this.fromDate == null) ? ' to ' + lastDate : this.fromDate + ' to ' + lastDate;

    // capture selected local authorities
    var selectedLA_Names = "";
    var selectedLA = "";
    if (this.selectedItemsLA != null) {
      this.selectedItemsLA.forEach(value => {
        selectedLA += value.id + ",";
        selectedLA_Names += value.name + ",";
      });
    } else {
      selectedLA_Names = "ALL";
      selectedLA = "";
    }

    var selectedLR = "";
    var selectedLR_Names = "";
    if (this.selectedItemsLR != null) {
      this.selectedItemsLR.forEach(value => {
        selectedLR += value.id + ",";
        selectedLR_Names += value.name + ",";
      });
    } else {
      selectedLR = "";
      selectedLR_Names = "ALL";
    }

    this.selectedDetails = {
      selected_date: date_range,

      selectedLA_id: selectedLA,
      selectedLA_names: selectedLA_Names,

      selectedLR_id: selectedLR,
      selectedLR_names: selectedLR_Names,

      selected_reimbursement_file_no: this.reimbursementFileNo
    };
  }

  getReportData(){
    this.captureSelectedDetails();
    this.showPrint = true;
    let dataLength = 0;
    this.reportService.getTotalReimbursementDetailsReport(this.fromDate, this.toDate, this.selectedDetails.selectedLR_id,this.selectedDetails.selectedLA_id,this.reimbursementFileNo).subscribe(value => {
      dataLength = value.data.length;
      if(value.data.length > 0){
        this.reportDetails = value.data;
        this.total_count = value.data.length;
        this.getTotalColumns(value.data);
        setTimeout(()=>{
          this.getPdfReportView();
        },1000);
      }else {
        this.notifier.notify('error', 'No records found!');
      }
    });
    return dataLength;
  }

  getTotalColumns(data: []): void {
    data.forEach((record: any) => {
      this.total_declare_value += +record.declaredValue;
    });
  }

  getPdfReportView(): void{
    let printContents: any;
    let popupWin: any;

    printContents = document.getElementById('totlaReimbursementReportContent').innerHTML;

    popupWin = window.open();
    popupWin.document.open();
    popupWin.document.write(`
    <html>
    <head>
        <title>Total Reimbursement Report Details</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <!--<script src="../../../../assets/js/paged.js"></script>-->
        <!--<script src="../../../../assets/js/paged.polyfill.js"></script>-->
        <style>
          @page {
            size: A3 landscape;
            @bottom-right {
              content: "Page " counter(page) " of " counter(pages);
            }
            .page{
                page-break-after: always;
            }
          }

            body
            {
                margin: 10mm 10mm 0mm 10mm;

            }
            table{
              border: 1px solid #000;
              border-collapse: collapse;
            }
            tbody{
              page-break-before: always;
            }

            table th, table td
            {
                padding: 5px;
                margin: 0px;
                border: 1px solid #000;
            }
            .page{
                page-break-after: always;
            }

        </style>
    </head>
    <body onload="window.print();">
            ${printContents}
    </body>
    </html>`
    );
    popupWin.document.close();
  }
  generateExcelReport() {
    this.getReportData();
    this.download_excel_status = true;
    setTimeout(() => {
      this.getExcelReportView();
    }, 1000);
  }

  getExcelReportView(): void {
    var printContents = document.getElementById('excel_print');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(printContents);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Stamp. Details');
    XLSX.writeFile(wb, 'Total Reimbursement Report.xlsx');
  }
  /*************** UI EVENT ***************/
}
