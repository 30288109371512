import { Component, Input, OnInit, ViewChild, TemplateRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { InspectionCommonService } from 'src/app/service/inspection-common/inspection-common.service';
import { InspectionService } from 'src/app/service/inspection/inspection.service';
import { ActivatedRoute } from '@angular/router';
import { InspectionLandBuildingFeature } from 'src/app/model/inspection-land-building-feature';
import { InspectionLandBuilding } from 'src/app/model/inspection-land-building';
import { InspectionBuildingConstruction } from 'src/app/model/inspection-building-construction';
import { InspectionBuildingConstructionDetail } from 'src/app/model/inspection-building-construction-details';
import { InspectionCommon } from 'src/app/model/inspection-common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constant } from 'src/app/util/constant';
import { InspectionDataServiceService } from 'src/app/service/data-service/inspection-data-service.service';
import { InspectionCondominium } from 'src/app/model/inspection-condominium';
import { InspectionLand } from 'src/app/model/inspection-land';
import { InspectionRecreation } from 'src/app/model/inspection-recreation';
import { NotifierService } from 'angular-notifier';
import { InspectionLandBuildingMain } from 'src/app/model/inspection-building-main';
import { InspectionBuildingDetails } from 'src/app/model/inspection-building-details';
import { checkCompletedOrPaypend } from '../Util/checkStatus';

@Component({
  selector: 'app-inspection-report-building',
  templateUrl: './inspection-report-building.component.html',
  styleUrls: ['./inspection-report-building.component.scss']
})
export class InspectionReportBuildingComponent implements OnInit, OnDestroy {
  @ViewChild('popupSuccessMessage', null) private popupMigrated: TemplateRef<object>;
  @ViewChild('popupSuccessMessageRecord', null) private popupSuccessMessageRecord: TemplateRef<object>;

  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  valuationRequestId: string;

  @Input()
  isAppeal:boolean;
  @Input()
  valuationRequestStatus: string;

  public initialView: boolean;
  public secondView: boolean;
  public thirdView: boolean;

  selectedPropertyType: any;
  selectedSubPropertyType: any;
  selectedCategoryPropertyType: any;

  featureType:any = "undefined";
  subFeatureType:any = [];
  ratio:any = [];

  disableSaveBtn: boolean;


  public categoryPropertyList: any = [];
  public buildingFeatureList: any = [];
  public buildingSubFeatureList: any = [];
  public usageList: any = [];

  public inspectionLandBuildingFeatureList: any = [];

  public inspectionLandBuildingFeatureModel = new InspectionLandBuildingFeature('', '', '', '');

  public model = new InspectionLandBuilding('', '', '', [], '', [], null);

  public modelDetails = new InspectionBuildingDetails('', '', '', '', '', '', '', '', '', '');

  public mainModelSubmission = new InspectionLandBuildingMain(null,'', '', '', '', null, [], null, '', '', null, null);

  public mainModel = new InspectionLandBuildingMain(null,'', '', '', '', null, [], null, '', '', null, null);

  public modelInspectionLand = new InspectionLand();

  public buildingFeatureInitial: string;
  public nosInitial: string;
  public sqFtInitial: string;
  public sqMtInitial: string;

  public buildingSubFeatureCount: number;
  public filteredbuildingSubFeatureList: any = [];

  public inspectionBuildingConstructionModel = new InspectionBuildingConstruction('', []);
  public inspectionBuildingConstructionDetailModel = new InspectionBuildingConstructionDetail('', '');

  public buildingSubFeatureListEntered: any = [];
  public buildingFeatureListEntered: any = [];

  public retrieveBuildingInformationList: any = [];

  public userdata: any = [];

  public inspectionCommonModel = new InspectionCommon('');

  public inspectionReportId: string;
  public recreationInspectionReportId: string;

  public isUpdate: boolean;

  public selectedview: number;

  public selectedRecreationSubPropertyType: any;
  public selectedRecreationType: boolean;
  public selectedRecreationSubmitType: boolean;

  public propertySubTypeList: any = [];

  public hidelanddetails: boolean;

  public inspectionDataServiceService: InspectionDataServiceService;

  public modelcondominium = new InspectionCondominium();
  public modelland = new InspectionLand();

  public onlylandbuildingselected: boolean;

  public inspectionRecreationModel = new InspectionRecreation(false, false, false, null, null, null, null, null);

  public landwithbuilding: boolean;

  public selectedRecreationUpdateType: boolean;

  public disableBuildingFeatureMain: boolean;
  public disableSubFeature: boolean;
  public disableCompletedRatio: boolean;
  public showAddBuildingButton: boolean;
  public showAddBuildingButtonCount : boolean;
  public is2ndview : boolean;

  valuationdata: any = [];

  checkStatusApplication  = checkCompletedOrPaypend;

  constructor(
    public modalService: NgbModal,
    public inspectionCommonService: InspectionCommonService,
    public inspectionService: InspectionService,
    private route: ActivatedRoute,
    private notifier: NotifierService
  ) {
    this.selectedview = 0;
    this.onlylandbuildingselected = false;
    this.hidelanddetails = false;
    this.isUpdate = false;
    this.selectedRecreationSubmitType = false;
    this.selectedRecreationType = false;
    this.buildingSubFeatureCount = 0;
    this.landwithbuilding = false;
    this.selectedRecreationUpdateType = false;
    this.disableBuildingFeatureMain = false;
    this.disableSubFeature = false;
    this.disableCompletedRatio = false;
    this.showAddBuildingButton = false;
    this.showAddBuildingButtonCount = false;
    this.is2ndview = false;
    this.disableSaveBtn = false;
  }

  ngOnInit() {
    this.valuationdata = JSON.parse(sessionStorage.getItem('valuationRequest'));
    this.selectedRecreationUpdateType = JSON.parse(atob(localStorage.getItem('selectedRecreationUpdateType')));
    this.selectedRecreationType = JSON.parse(atob(localStorage.getItem('selectedRecreationType')));
    this.selectedRecreationSubPropertyType = JSON.parse(atob(localStorage.getItem('selectedRecreationSubPropertyType')));
    this._prepare();
    this.initialize();
  }

  exitForm() {
    this.onPageCountChanged.emit(10);
  }

  initialize() {
    this.landwithbuilding = JSON.parse(localStorage.getItem('landwithbuilding'));
  }

  public incrementInspectionBuildingFeatures() {
    this.inspectionLandBuildingFeatureList.push(this.inspectionLandBuildingFeatureModel);
    this.model.inspectionLandBuildingFeatures.push(this.inspectionLandBuildingFeatureModel);
    this.inspectionLandBuildingFeatureModel = new InspectionLandBuildingFeature('', '', '', '');
    this.initialize();
  }

  public redirectPreviousComponent(): void {
    this.onPageCountChanged.emit(10);
  }

  public redirectToLandComponentAlter() {
    if (atob(localStorage.getItem('inspectionId')) === 'undefined') {

      let exchangeSelectedParty = sessionStorage.getItem('selectedPartyInExchange');
      // Valuation request ID is assigned according to selected party
      if(exchangeSelectedParty === '1') {
        this.mainModel.valuationRequestId = this.valuationdata.id;
      } else if(exchangeSelectedParty === '2') {
        this.mainModel.valuationRequestId = this.valuationdata.id + 1;
      }

      // this.mainModel.valuationRequestId = this.valuationdata.id;
      this.mainModel.propertyTypeId = this.selectedPropertyType['id'];
      this.mainModel.propertySubTypeId = this.selectedSubPropertyType['id'];
      this.mainModel.createdBy = this.userdata['firstName'];
      this.mainModel.inspectionType = Constant.INSPECTION_TYPE_BUILDING;

      if (this.selectedRecreationType == true) {
        this.mainModel.isRecreation = true;
      } else {
        this.mainModel.isRecreation = false;
      }

      // Valuation request ID is assigned according to selected party
      if(exchangeSelectedParty === '1') {
        this.modelInspectionLand.valuationRequestId = this.valuationdata.id;
      } else if(exchangeSelectedParty === '2') {
        this.modelInspectionLand.valuationRequestId = this.valuationdata.id + 1;
      }
      // this.modelInspectionLand.valuationRequestId = this.valuationdata.id;;
      this.modelInspectionLand.createdBy = this.userdata['firstName'];
      this.modelInspectionLand.inspectionType = Constant.INSPECTION_TYPE_LAND;

      this.mainModel.inspectionLandDto = this.modelInspectionLand;

      this.inspectionService.inspectionLandBuildingInsert(this.mainModel).subscribe(
        data => {
          localStorage.setItem('inspectionId', btoa(data.data.parentId));
          localStorage.setItem('childinspectionId', btoa(data.data.childId));
          localStorage.setItem('recreationLandId',  data.data.childId);
          this.inspectionCommonModel.code = atob(localStorage.getItem('childinspectionId'));
          this.inspectionService.inspectionLandDetailList(this.inspectionCommonModel).subscribe(
            data => {
              this.modelInspectionLand = data.data;
              this.isUpdate = true;
              if (atob(localStorage.getItem('inspectionId')) !== "undefined") {
                localStorage.setItem('landwithbuilding', JSON.stringify(true));
                localStorage.setItem('disableLandBuildingLandSubType', JSON.stringify(false));
                this.onPageCountChanged.emit(11);
              } else {
                this.notifier.notify('error', 'Error occurred while processing. Please try again.');
              }
            }
          )
        },
        err => {
          this.notifier.notify('error', 'Error occurred while processing. Please try again.');
        }
      )
    } else {
      this.inspectionCommonModel.code = atob(localStorage.getItem('inspectionId'));
      localStorage.setItem('recreationLandId',  this.inspectionCommonModel.code);
      this.inspectionService.inspectionChildId(this.inspectionCommonModel).subscribe(
        data => {
          localStorage.setItem('landwithbuilding', JSON.stringify(true));
          localStorage.setItem('disableLandBuildingLandSubType', JSON.stringify(true));
          localStorage.setItem('childinspectionId', btoa(data.data['code']));
          this.onPageCountChanged.emit(11);
        }
      ),
        err => {
          // this.notifier.notify('error', 'Error occurred while processing. Please try again.');
        }
    }
  }

  public noOfBuildingsChange(event) {
    if(this.mainModel.buildingInformationList.length>0)
    {
      if(event < this.mainModel.buildingInformationList.length)
      {
        this.notifier.notify('error', 'Cannot select less number than current building count');
        this.model.noOfBuildings = ''
        

      }
      else
      {
        this.model.noOfBuildings = event;
        this.modelDetails.inspectionNoOfBuildings = this.model.noOfBuildings;

        if(event == this.mainModel.buildingInformationList.length)
        {
          this.showAddBuildingButtonCount = false;
        }
        else
        {
          this.showAddBuildingButtonCount = true;
        }

      }
    }
    else
    {
      this.model.noOfBuildings = event;
      this.modelDetails.inspectionNoOfBuildings = this.model.noOfBuildings;
      this.showAddBuildingButtonCount = true;
    }

  }

  public viewLandDetails(): void {
    if (atob(localStorage.getItem('inspectionId')) !== "undefined") {
      this.inspectionCommonModel.code = atob(localStorage.getItem('inspectionId'));
      this.inspectionService.inspectionChildId(this.inspectionCommonModel).subscribe(
        data => {
          localStorage.setItem('landwithbuilding', JSON.stringify(true));
          localStorage.setItem('childinspectionId', btoa(data.data['code']));
          this.onPageCountChanged.emit(11);
        }
      ),
        err => {
          this.notifier.notify('error', 'Error occurred while processing. Please try again.');
        }
    } else {
      this.notifier.notify('error', 'Please save the form before continuing.');
    }
  }

  openPopupMessage(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });
  }

  counter(i: number) {
    return new Array(i);
  }

  _prepare() {
    this.recreationInspectionReportId = atob(localStorage.getItem('recreationLandBuildingInspectionId'));
    if (this.recreationInspectionReportId !== "undefined") {
      this.mainModel.inspectionId = this.recreationInspectionReportId;
      this.loadCustomPropertyType();
    }
    this.inspectionReportId = atob(localStorage.getItem('inspectionId'));
    if (this.inspectionReportId !== "undefined") {
      this.isUpdate = true;
      this.showAddBuildingButton = true;
      this.retrieveUpdateFormDate();
    }
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    // this.valuationRequestId = this.route.snapshot.paramMap.get('id');
    // Check whether if it is a recreation
    if (this.selectedRecreationType) {
      this.loadPropertyType();
    } else {
      this.selectedPropertyType = JSON.parse(atob(localStorage.getItem('propertyType')));
      this.selectedSubPropertyType = JSON.parse(atob(localStorage.getItem('subPropertyType')));
    }
    this.firstNavigatePage();
    this.categoryPropertyTypeList();
    this.buildingFeatureTypeList();
    this.buildingSubFeatureTypeList();
    this.usageTypeList();
    this.buildingInformationList();
  }

  loadCustomPropertyType() {
    this.inspectionCommonService.propertyUsageTypeList().subscribe(
      data => {
        const filtereddata = data.data.filter(i => i.id === Constant.INSPECTION_PROPERTY_TYPE_LAND_ID);
        this.selectedPropertyType = filtereddata[0];
        this.loadCustomPropertySubType();
      }
    )
  }

  loadCustomPropertySubType() {
    this.inspectionCommonService.propertyUsageSubTypeList().subscribe(
      data => {
        const filtereddata = data.data.filter(i => i.inspectionPropertyType.id === this.selectedPropertyType.id);
        this.propertySubTypeList = filtereddata;
        this.recreationRetrieveUpdateFormDate();
      }
    )
  }

  recreationRetrieveUpdateFormDate() {
    this.inspectionCommonModel.code = this.recreationInspectionReportId;
    this.inspectionService.inspectionLandBuildingDetailList(this.inspectionCommonModel).subscribe(
      data => {
        this.mainModel = data.data;
        this.mainModel.inspectionId = this.recreationInspectionReportId;
        const filtereddata = this.propertySubTypeList.filter(i => i.id === Number(this.mainModel.propertySubTypeId));
        this.selectedSubPropertyType = filtereddata[0];
        const filtereddataset = this.categoryPropertyList.filter(i => i.id === Number(this.mainModel.buildingInformationList[this.selectedview].categoryPropertyId));
        this.selectedCategoryPropertyType = filtereddataset[0]['name'];
      }
    )
  }

  loadPropertyType() {
    this.inspectionCommonService.propertyUsageTypeList().subscribe(
      data => {
        const filtereddata = data.data.filter(i => i.id === Constant.INSPECTION_PROPERTY_TYPE_LAND_ID);
        this.selectedPropertyType = filtereddata[0];
        this.loadPropertySubType();
      }
    )
  }

  loadPropertySubType() {
    this.inspectionCommonService.propertyUsageSubTypeList().subscribe(
      data => {
        const filtereddata = data.data.filter(i => i.inspectionPropertyType.id === this.selectedPropertyType.id);
        this.propertySubTypeList = filtereddata;
        this.checkLandDetailsStatus();
      }
    )
  }

  checkLandDetailsStatus() {
    let page;
    if (this.selectedRecreationType) {
      if (this.selectedRecreationSubPropertyType.length > 0) {
        for (let i = 0; i < this.selectedRecreationSubPropertyType.length; i++) {
          if (this.selectedRecreationSubPropertyType[i].id === 1) {
            page = "land";
            break;
          }
        }
      }
    }
    if (page === "land") {
      this.hidelanddetails = true;
    }
    if (this.selectedRecreationSubPropertyType.length === 1) {
      this.onlylandbuildingselected = true;
    }
  }

  selectedSubPropertyTypeForRecreation(event) {
    const filtereddata = this.propertySubTypeList.filter(i => i.id === Number(event));
    this.selectedSubPropertyType = filtereddata[0];
    console.log(this.selectedSubPropertyType)
    localStorage.setItem('subPropertyId',JSON.stringify(this.selectedSubPropertyType))
  }

  recreationNavigation() {
    this.selectedRecreationSubmitType = true;
  }

  retrieveUpdateFormDate() {
    this.inspectionCommonModel.code = this.inspectionReportId;
    this.inspectionService.inspectionLandBuildingDetailList(this.inspectionCommonModel).subscribe(
      data => {
        this.mainModel = data.data;
        if(this.mainModel.buildingInformationList.length>0)  this.model.noOfBuildings = this.mainModel.buildingInformationList[0].noOfBuildings;
        if(Number(this.model.noOfBuildings) == this.mainModel.buildingInformationList.length ){
          this.showAddBuildingButtonCount = false;
        }
        else
        {
          this.showAddBuildingButtonCount = true;
        }
        if (JSON.parse(localStorage.getItem('autosaveupdate'))) {
          this.onUpdate();
        }
        localStorage.setItem('autosaveupdate', JSON.stringify(false));
      }
    )
  }

  viewFormData(index) {
    localStorage.setItem('autosaveupdate', JSON.stringify(true));
    this.selectedview = index;
    this.model = this.mainModel.buildingInformationList[index];
    this.modelDetails = this.mainModel.buildingInformationList[index].inspectionBuildingDetails;
    const filtereddataset = this.categoryPropertyList.filter(i => i.id === Number(this.mainModel.buildingInformationList[this.selectedview].categoryPropertyId));
    this.selectedCategoryPropertyType = filtereddataset[0]['name'];
    this.initialView = false;
    this.secondView = true;
    this.thirdView = false;
  }


  buildingInformationList() {
    this.inspectionCommonModel.code = this.route.snapshot.paramMap.get('id');
    this.inspectionService.buildingInformationList(this.inspectionCommonModel).subscribe(
      data => {
        this.retrieveBuildingInformationList = data.data;
      }
    )
  }

  insertRow() {
    if(this.buildingSubFeatureListEntered.length > 0) {
      let i;
      let j;
      let count = 0;
      this.disableBuildingFeatureMain = false;
      this.disableSubFeature = false;
      this.disableCompletedRatio = false;
      for (let i = 0; i < this.buildingSubFeatureListEntered.length; i++) {
        this.inspectionBuildingConstructionModel.constructionDetails.push(this.buildingSubFeatureListEntered[i]);
      }

      this.model.inspectionBuildingConstructions.push(this.inspectionBuildingConstructionModel);
      this.buildingSubFeatureCount = 0;
      this.buildingSubFeatureListEntered = [];
      this.inspectionBuildingConstructionModel = new InspectionBuildingConstruction('', []);
      this.inspectionBuildingConstructionDetailModel = new InspectionBuildingConstructionDetail('', '');
  
      this.featureType = "undefined";
      this.subFeatureType = [];
      this.filteredbuildingSubFeatureList = [];
      this.ratio = [];
    }else{
      this.notifier.notify('error', 'add all required fields before save');
    }

    // Calculating the total completion
    // for (i = 0; i < this.model.inspectionBuildingConstructions.length; i++) {
    //   for (j = 0; j < this.model.inspectionBuildingConstructions[i].constructionDetails.length; j++) {
    //     count++;
    //     this.modelDetails.totalCompletion = (Number(this.modelDetails.totalCompletion) + Number(this.model.inspectionBuildingConstructions[i].constructionDetails[j]['value'])).toString();
    //   }
    // }
    // this.modelDetails.totalCompletion = (Number(this.modelDetails.totalCompletion) / count).toString();
  }

  incrementBuildingSubFeatureCount() {
    if(this.inspectionBuildingConstructionDetailModel.value == ""){
      this.notifier.notify('error', 'Fill in all the required details before continuing.');
    }
    else{
      this.disableSubFeature = false;
      this.disableCompletedRatio = false;
      this.buildingSubFeatureCount = this.buildingSubFeatureCount + 1;
      this.buildingSubFeatureListEntered.push(this.inspectionBuildingConstructionDetailModel);
      this.inspectionBuildingConstructionDetailModel = new InspectionBuildingConstructionDetail('', '');
    }
  }

  selectedBuildingFeature(event) {
    this.buildingSubFeatureCount = 0;
    this.buildingSubFeatureListEntered = [];
    this.inspectionBuildingConstructionModel = new InspectionBuildingConstruction('', []);
    this.inspectionBuildingConstructionDetailModel = new InspectionBuildingConstructionDetail('', '');
    if(event !== "undefined") {
      // Assigning the selected dropdown id
      this.inspectionBuildingConstructionModel.buildingFeatureTypeId = event;
      // Filtering the sub feature list
      const filtereddata = this.buildingSubFeatureList.filter(i => i.buildingFeatureTypeId === event);
      this.filteredbuildingSubFeatureList = filtereddata;
    }
  }

  selectedSubBuildingFeature(event, i) {
    if (event !== 'undefined') {
      this.disableSubFeature = true;
      this.disableBuildingFeatureMain = true;
    }
    this.inspectionBuildingConstructionDetailModel.buildingSubFeatureTypeId = event;
  }

  getTypedValue(event, i) {
    if (event !== 'undefined' && event !== "") {
      this.disableCompletedRatio = true;
    }
    this.inspectionBuildingConstructionDetailModel.value = event;
  }

  selectedCategoryProperty(event) {
    const filtereddata = this.categoryPropertyList.filter(i => i.id === Number(event));
    this.selectedCategoryPropertyType = filtereddata[0]['name'];
  }

  firstNavigatePage() {
    this.initialView = true;
    this.secondView = false;
    this.thirdView = false;
    this.model = new InspectionLandBuilding('', '', '', [], '', [], null);
    this.modelDetails = new InspectionBuildingDetails('', '', '', '', '', '', '', '', '', '');
    if (this.mainModel.buildingInformationList.length > 0) {
      this.usageTypeList();
      this.categoryPropertyTypeList();
      this.buildingFeatureTypeList();
      this.buildingSubFeatureTypeList();
      if(this.mainModel.buildingInformationList.length > 0)
      {0
        this.model.noOfBuildings = this.mainModel.buildingInformationList[0].noOfBuildings;
        if(Number(this.model.noOfBuildings) == this.mainModel.buildingInformationList.length ){
          this.showAddBuildingButtonCount = false;
        }
        else
        {
          this.showAddBuildingButtonCount = true;
        }
      }
    }
    this.is2ndview = true;
    window.scroll(0,0);
  }

  secondNavigatePage() {
    this.initialView = false;
    this.secondView = true;
    this.thirdView = false;
    this.is2ndview = false;
    window.scroll(0,0);
  }

  thirdNavigatePage() {
    this.initialView = false;
    this.secondView = false;
    this.thirdView = true;
    this.is2ndview = false;
    window.scroll(0,0);
  }

  categoryPropertyTypeList() {
    this.inspectionCommonService.categoryPropertyTypeList().subscribe(
      data => {
        this.categoryPropertyList = data.data;
      }
    )
  }

  addBuildingWarning() {
    this.notifier.notify('error', 'Fill in all the details before continuing.');
  }

  buildingFeatureTypeList() {
    this.inspectionCommonService.buildingFeatureTypeList().subscribe(
      data => {
        this.buildingFeatureList = data.data;
      }
    )
  }

  buildingSubFeatureTypeList() {
    this.inspectionCommonService.buildingSubFeatureTypeList().subscribe(
      data => {
        this.buildingSubFeatureList = data.data;
        this.filteredbuildingSubFeatureList = this.buildingSubFeatureList;
      }
    )
  }

  usageTypeList() {
    this.inspectionCommonService.condominiumTypeList().subscribe(
      data => {
        this.usageList = data.data;
      }
    )
  }

  reset() {
    this.initialView = true;
    this.secondView = false;
    this.thirdView = false;
    this.inspectionLandBuildingFeatureModel = new InspectionLandBuildingFeature('', '', '', '');
    this.model = new InspectionLandBuilding('', '', '', [], '', [], null);
    if(this.mainModel.buildingInformationList.length>0)  this.model.noOfBuildings = this.mainModel.buildingInformationList[0].noOfBuildings;
    this.filteredbuildingSubFeatureList = [];
    this.inspectionBuildingConstructionModel = new InspectionBuildingConstruction('', []);
    this.inspectionBuildingConstructionDetailModel = new InspectionBuildingConstructionDetail('', '');
    this.buildingSubFeatureListEntered = [];
    this.buildingFeatureListEntered = [];
    this.categoryPropertyList = [];
    this.buildingFeatureList = [];
    this.buildingSubFeatureList = [];
    this.inspectionLandBuildingFeatureList = [];
  }

  redirectToMainTable() {
    this.modalService.dismissAll();
    this.redirectPreviousComponent();
  }

  onSubmit() {
    this.disableSaveBtn = true;
    let exchangeSelectedParty = sessionStorage.getItem('selectedPartyInExchange');
    // Valuation request ID is assigned according to selected party
    if(exchangeSelectedParty === '1') {
      this.mainModel.valuationRequestId = this.valuationdata.id;
    } else if(exchangeSelectedParty === '2') {
      this.mainModel.valuationRequestId = this.valuationdata.id + 1;
    }

    // this.mainModel.valuationRequestId = this.valuationdata.id;
    this.mainModel.propertyTypeId = this.selectedPropertyType['id'];
    if (this.mainModel.propertySubTypeId == 'undefined' || this.mainModel.propertySubTypeId == '') {
      this.mainModel.propertySubTypeId = '0';
    } else {
      this.mainModel.propertySubTypeId = this.selectedSubPropertyType['id'];
    }
    this.mainModel.createdBy = this.userdata['firstName'];
    this.mainModel.inspectionType = Constant.INSPECTION_TYPE_BUILDING;

    if (this.selectedRecreationType == true) {
      this.mainModel.isRecreation = true;
    } else {
      this.mainModel.isRecreation = false;
    }

    this.model.inspectionBuildingDetails = this.modelDetails;
    this.mainModel.buildingInformationList.push(this.model);
    this.mainModelSubmission = this.mainModel;

    this.modelInspectionLand.valuationRequestId = this.valuationdata.id;
    this.modelInspectionLand.createdBy = this.userdata['firstName'];
    this.modelInspectionLand.inspectionType = Constant.INSPECTION_TYPE_LAND;

    this.mainModelSubmission.inspectionLandDto = this.modelInspectionLand;

    this.inspectionService.inspectionLandBuildingInsert(this.mainModelSubmission).subscribe(
      data => {
        localStorage.setItem('inspectionId', btoa(data.data.parentId));
        localStorage.setItem('childinspectionId', btoa(data.data.childId));
        this.inspectionCommonModel.code = atob(localStorage.getItem('childinspectionId'));
        this.inspectionService.inspectionLandDetailList(this.inspectionCommonModel).subscribe(
          data => {
            this.modelInspectionLand = data.data;
          }
        )
        this.isUpdate = true;
        this.disableSaveBtn = false;
        this.inspectionLandBuildingFeatureList = [];
        this.inspectionLandBuildingFeatureModel = new InspectionLandBuildingFeature('', '', '', '');
        this.model = new InspectionLandBuilding('', '', '', [], '', [], null);
        this.modelDetails = new InspectionBuildingDetails('', '', '', '', '', '', '', '', '', '');
        this.firstNavigatePage();
        this.reset();
      },
      err => {
        this.isUpdate = false;
        this.notifier.notify('error', 'Error occurred while processing. Please try again.');
      }
    )
  }

  onBuildingAdd() {
    console.log('hi');
  }

  onUpdate() {
    this.disableSaveBtn = true;
    this.mainModel.valuationRequestId = this.valuationdata.id;
    this.mainModel.propertyTypeId = this.selectedPropertyType['id'];
    // this.mainModel.propertySubTypeId = this.selectedSubPropertyType['id'];
    this.mainModel.createdBy = this.userdata['firstName'];
    this.mainModel.inspectionType = Constant.INSPECTION_TYPE_BUILDING;
    this.mainModel.inspectionId = atob(localStorage.getItem('inspectionId'));
    this.model.inspectionBuildingDetails = this.modelDetails;
    if (!JSON.parse(localStorage.getItem('autosaveupdate'))) {
      this.mainModel.buildingInformationList.push(this.model);
    }
    this.mainModelSubmission = this.mainModel;

    if (JSON.parse(atob(localStorage.getItem("inspectionlandmodel"))) !== null) {
      this.modelInspectionLand = JSON.parse(atob(localStorage.getItem("inspectionlandmodel")));
      this.modelInspectionLand.inspectionId = atob(localStorage.getItem('childinspectionId'));
      this.modelInspectionLand.valuationRequestId = this.valuationdata.id;
      this.modelInspectionLand.createdBy = this.userdata['firstName'];
      this.modelInspectionLand.inspectionType = Constant.INSPECTION_TYPE_LAND;
    } else {
      this.modelInspectionLand.inspectionId = atob(localStorage.getItem('childinspectionId'));
      this.modelInspectionLand.valuationRequestId = this.valuationdata.id;
      this.modelInspectionLand.createdBy = this.userdata['firstName'];
      this.modelInspectionLand.inspectionType = Constant.INSPECTION_TYPE_LAND;
    }
    this.mainModelSubmission.inspectionLandDto = this.modelInspectionLand;

    this.inspectionService.inspectionLandBuildingUpdate(this.mainModelSubmission).subscribe(
      data => {
        this.isUpdate = true;
        this.disableSaveBtn = false;
        this.inspectionLandBuildingFeatureList = [];
        this.inspectionLandBuildingFeatureModel = new InspectionLandBuildingFeature('', '', '', '');
        this.model = new InspectionLandBuilding('', '', '', [], '', [], null);
        this.modelDetails = new InspectionBuildingDetails('', '', '', '', '', '', '', '', '', '');
        this.mainModel.buildingInformationList = data.data.buildingInformations.sort((a, b) => b.id - a.id);
        this.notifier.notify('success', 'Data Successfully Saved!');
        this.firstNavigatePage();
        this.reset();

        // Dropdown list
        this.usageTypeList();
        this.categoryPropertyTypeList();
        this.buildingFeatureTypeList();
        this.buildingSubFeatureTypeList();
        localStorage.setItem('autosaveupdate', JSON.stringify(false));
      },
      err => {
        this.notifier.notify('error', 'Error occurred while processing. Please try again.');
        localStorage.setItem('autosaveupdate', JSON.stringify(false));
      }
    );
  }

  removeMainRecord(index) {
    this.model.inspectionLandBuildingFeatures.splice(index, 1);
  }

  deleteConstuctionDetail(index) {
    let i;
    let j;
    let count = 0;
    this.model.inspectionBuildingConstructions.splice(index, 1);
    // Calculating the total completion
    // for (i = 0; i < this.model.inspectionBuildingConstructions.length; i++) {
    //   for (j = 0; j < this.model.inspectionBuildingConstructions[i].constructionDetails.length; j++) {
    //     count++;
    //     this.modelDetails.totalCompletion = (Number(this.modelDetails.totalCompletion) + Number(this.model.inspectionBuildingConstructions[i].constructionDetails[j]['value'])).toString();
    //   }
    // }
    // this.modelDetails.totalCompletion = (Number(this.modelDetails.totalCompletion) / count).toString();
  }

  onSubmitRecreation() {
    this.mainModel.valuationRequestId = this.valuationdata.id;
    // this.mainModel.propertyTypeId = this.selectedPropertyType['id'];
    // this.mainModel.propertySubTypeId = this.selectedSubPropertyType['id'];
    this.mainModel.createdBy = this.userdata['firstName'];
    this.mainModel.inspectionType = Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING;
    this.model.inspectionBuildingDetails = this.modelDetails;
    this.mainModel.buildingInformationList.push(this.model);
    this.mainModelSubmission = this.mainModel;

    this.inspectionRecreationModel.isLand = JSON.parse(localStorage.getItem("isLand"));
    this.inspectionRecreationModel.isCondominium = JSON.parse(localStorage.getItem("isCondominium"));
    this.inspectionRecreationModel.isLandBuilding = true;

    this.inspectionRecreationModel.inspectionLandDto = JSON.parse(atob(localStorage.getItem("inspectionlandmodel")));
    this.inspectionRecreationModel.inspectionCondominiumDto = JSON.parse(atob(localStorage.getItem("inspectioncondominiummodel")));
    this.inspectionRecreationModel.inspectionLandBuildingDto = this.mainModelSubmission;

    this.inspectionService.inspectionRecreationInsert(this.inspectionRecreationModel).subscribe(
      data => {
        this.openPopupMessage(this.popupMigrated);
        this.reset();
      },
      err => {
        this.notifier.notify('error', 'Error occurred while processing. Please try again.');
      }
    )
  }


  onUpdateRecreation() {
    this.mainModel.valuationRequestId = this.valuationdata.id;
    // this.mainModel.propertyTypeId = this.selectedPropertyType['id'];
    // this.mainModel.propertySubTypeId = this.selectedSubPropertyType['id'];
    this.mainModel.createdBy = this.userdata['firstName'];
    this.model.inspectionBuildingDetails = this.modelDetails;
    this.mainModel.buildingInformationList.push(this.model);
    this.mainModelSubmission = this.mainModel;

    this.inspectionRecreationModel.isLand = JSON.parse(localStorage.getItem("isLand"));
    this.inspectionRecreationModel.isCondominium = JSON.parse(localStorage.getItem("isCondominium"));
    this.inspectionRecreationModel.isLandBuilding = true;

    this.inspectionRecreationModel.inspectionLandDto = JSON.parse(atob(localStorage.getItem("inspectionlandmodel")));
    this.inspectionRecreationModel.inspectionCondominiumDto = JSON.parse(atob(localStorage.getItem("inspectioncondominiummodel")));
    this.inspectionRecreationModel.inspectionLandBuildingDto = this.mainModelSubmission;

    this.inspectionService.inspectionRecreationUpdate(this.inspectionRecreationModel).subscribe(
      data => {
        if (data.data) {
          this.openPopupMessage(this.popupMigrated);
          this.reset();
        } else {
          this.notifier.notify('error', 'Error occurred while processing. Please try again.');
        }
      },
      err => {
        this.notifier.notify('error', 'Error occurred while processing. Please try again.');
      }
    )
  }

  convertSqmToSqft() {
    this.inspectionLandBuildingFeatureModel.areaFt = (this.inspectionLandBuildingFeatureModel.areaMt * 10.7639).toFixed(2);
  }

  convertSftToSqm() {
    this.inspectionLandBuildingFeatureModel.areaMt = (this.inspectionLandBuildingFeatureModel.areaFt / 10.7639).toFixed(2);
  }


  ngOnDestroy() {
    this.isUpdate = false;
    if (!JSON.parse(localStorage.getItem('landwithbuilding'))) {
      localStorage.setItem('inspectionId', btoa(undefined));
    }
  }
}
