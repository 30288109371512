import { Component, OnInit, Input, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';
import { DomSanitizer } from '@angular/platform-browser';

import { Constant } from 'src/app/util/constant';
import { CertificateService } from 'src/app/service/certificate/certificate.service';
import { OpinionLetterService } from 'src/app/service/opinion-letter/opinion.letter.service';
import { Certificate } from 'src/app/model/certificate';
import { OpinionLetter } from 'src/app/model/opinion-letter';
import { CertificateDetails } from 'src/app/model/certificateDetails';
import { ValuationRequestService } from 'src/app/service/valuation-request/valuation-request.service';
import { NotificationModel } from 'src/app/model/notification-model';
import { NotificationService } from 'src/app/service/notification/notification.service';
import { ValuationRequestList } from 'src/app/model/valuation_request_list';
import { NotificationEmailSend } from 'src/app/model/notification-email-send';
import { FurtherRequirementAndReject } from 'src/app/model/further-requirement-and-reject';
import { NotificationDetails } from 'src/app/model/notificationDetails';
import * as moment from 'moment';
import { checkCompletedOrPaypend } from '../../view-requests-internal-user/Util/checkStatus';

@Component({
  selector: 'app-certificate-exchange',
  templateUrl: './certificate-exchange.component.html',
  styleUrls: ['./certificate-exchange.component.scss']
})
export class CertificateExchangeComponent implements OnInit {

  @ViewChild('furtherReqNotification', null) private enterRemark: TemplateRef<object>;
  @ViewChild('rejectApplication', null) private enterRejectRemark: TemplateRef<object>;

  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() status1: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  valuationRequestId: number;
  @Input()
  isAppeal:boolean;
  @Input()
  valuationRequestStatus: string;

  appealCount:number;
  @Input()
  furtherRequirementStatus:boolean;
  @Input()
  dcStatus: string;

  imageUrl;
  openCertificateFlag: boolean;
  editCertificateFlag: boolean;
  opinionLetterHeader: string;
  opinionLetterContent: string;
  certificateContent: string;
  userdata: any = [];
  userrolecode: string;
  private sub = new SubSink();
  certificateList: Certificate[];
  private certificate = new Certificate();
  opinionLetterList: OpinionLetter[];
  private opinionLetter = new OpinionLetter();
  selectedLetter: string;
  editCertificate: Certificate;
  comment: string;
  certificateData: CertificateDetails;
  barCode: string;
  barCodeEelement: any;
  barCodeImage: string;

  qrCode: string = null;
  qrCodeEelement: any;
  qrCodeImage: any;

  status : string;
  statusWord : string;

  deedNatureValue: any;
  assessorUserId: string;
  dComUserId: string;
  comUserId: string;
  notaryUserId: string;
  currentUserRoleCode: string;

  public generatedFileNumber: string;
  valuationdata: any = [];

  public model = new ValuationRequestList('', '', '', '', '', '');
  public notificationModel = new NotificationModel("", "", "", "", "", "", "");
  public notificationDetails = new NotificationDetails('','');
  public notificationEmailSendModel = new NotificationEmailSend();

  public rejectModel = new FurtherRequirementAndReject();
  public furtherRequirementModel = new FurtherRequirementAndReject();
  public showDynamicGrid: boolean;
  public showRemarkValidation: boolean;
  furtherRequirementRemarkContent: string;
  applicationRejectRemarkContent: string;
  rejectForm: FormGroup;
  furtherRequirementForm: FormGroup;
  userName: string;
  furtherRequirementFile: File;
  rejectFile: File;

  public fileNumberFormat: string;

  files;
  filesList: File[] = [];
  images = [];
  originalImages: any[] = [];
  names: string[] = [];
  public imgURL1: any;
  public imgURL2: any;
  public documentUploaded: string;
  public documentUploaded1: string;
  frButtonStatus: boolean;

  fileNumberFormatAvailable: boolean = true;
  certificateHeader: string;
  checkStatusApplication  = checkCompletedOrPaypend;


  constructor(
    private certificateService: CertificateService,
    private valuationRequestService: ValuationRequestService,
    private opinionLetterService: OpinionLetterService,
    private route: ActivatedRoute,
    private notifier: NotifierService,
    public notificationService: NotificationService,
    private formBuilder: FormBuilder,
    public modalService: NgbModal,
    public router: Router,
    public sanitizer: DomSanitizer
  ) {
    this.showRemarkValidation = false;
    this.editCertificateFlag = false;
    this.openCertificateFlag = false;
    this.frButtonStatus = false;
    this.appealCount = 0;
    // this.qrCode = localStorage.getItem('fileNumberFormat');
  }

  ngOnInit() {
    this.openCertificateFlag = false;
    this._prepare();
    this.certificateData = new CertificateDetails();
    window.scroll(0,0);
    this.showDynamicGrid = false;
    this.findCurrentUserRole();
  }
  getAppealCount(){
    this.valuationRequestService.getAppealCount(this.route.snapshot.paramMap.get('id')).subscribe(data=>{
      this.appealCount = data.data;
    })
  }
  _prepare() {
    this.getAppealCount();
    this.valuationdata = JSON.parse(sessionStorage.getItem('valuationRequest'));

    this.fileNumberFormat = localStorage.getItem('fileNumberFormat');
    if (this.fileNumberFormat == "null" || this.fileNumberFormat == '') {
      this.fileNumberFormatAvailable = false;
    }

    this.pad(this.valuationdata.id, 7);
    this.opinionLetterHeader =
    '   <div style="font-size: 11px; line-height:1.3; width: 100%; font-family: \"Roboto\", sans-serif; position: relative; font-weight: 400; margin: 0; padding: 0;">  '  +
    '           <div style="padding: 0px 20px;">  '  +
    '               <header style="border-bottom: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    '                 <div style="display: flex; width: 100%;">  '  +
    '                   <div style="width: 15%; display: flex; align-items: right;"><img src="/assets/images/emblm_black_white.jpg" style="height: 120px;"></div>  '  +
    '                   <div style="width: 85%; margin-top: 15px; text-align: justify;"> ' +
    '                     <span> ' +
    '                        <span style="font-size: 18px; display: flex; align-items: center; width: 100%; font-weight: 900;"><span style="width: 43%; test-align: justify">ආදායම් දෙපාර්තමේන්තුව</span><span style="width: 2%; test-align: center"> - </span><span style="width: 2%; test-align: center"></span><span style="test-align: left">බස්නාහිර පළාත් සභාව</span></span>  ' +
    '                        <span style="font-size: 14px; display: flex; align-items: center; width: 100%; font-weight: 900;"><span style="width: 43%; test-align: left">இறைவரித் திணைக்களம்</span><span style="width: 2%; test-align: center"> - </span><span style="width: 2%; test-align: center"></span><span style="test-align: left">மேல் மாகாண சபை</span></span> ' +
    '                        <span style="font-size: 13px; display: flex; align-items: center; width: 100%; font-weight: 900;"><span style="width: 43%; test-align: left">DEPARTMENT OF REVENUE</span><span style="width: 2%; test-align: center"> - </span><span style="width: 2%; test-align: center"></span><span style="test-align: left">WESTERN PROVINCIAL COUNCIL</span></span>  ' +
    '                     <span><br> ' +
                          // ඩෙන්සිල් කොබ්බැකඩුව මාවත and මගේ අංකය division
    '                     <div style="display: flex; width: 100%;">  '  +
                            // ඩෙන්සිල් කොබ්බැකඩුව මාවත
    '                       <div style="width: 62%; vertical-align: top;"> ' +
    '                         <span style="font-size: 11px; font-weight: 600;">No.204, ඩෙන්සිල් කොබ්බැකඩුව මාවත, බත්තරමුල්ල. </span>  '  +
    '                         </br> ' +
    '                         <span style="font-size: 10px;">இல.204, டென்சில் கொப்பேகடுவ மாவத்தை, பத்தரமுல்ல. </span>  '  +
    '                         </br> ' +
    '                         <span style="font-size: 10px;">No.204, Denzil Kobbekaduwa Mawatha, Battaramulla. </span>  '  +
    '                       </div> ' +
                            // මගේ අංකය
    '                       <div style="width: 38%; line-height: 12px;">  ' +
    '                         <div style="width: 100%; display: flex; align-items: center;">  ' +
    '                           <div style="width: 27%; font-size: 9px;">  ' +
    '                             <span> මගේ අංකය </span>  '  +
    '                             </br> ' +
    '                             <span> எனது இல </span>  '  +
    '                             </br> ' +
    '                             <span> My No. </span>  '  +
    '                           </div>  '  +
    '                           <div style="width: 73%;"> ' +
    '                             <span style="font-size: 25px;"> } <span style="font-size: 11px;">$51@</span> </span> ' +
    '                           </div>  '  +
    '                         </div>  '  +
    '                       </div> ' +
    '                     </div> ' +
    '                   </div> ' +
    '                 </div>  '  +
                      // Contact details + ඔබේ අංකය + දිනය
    '                 <div style="display: flex; align-items: center; width: 100%; margin-bottom: 10px;">  '  +
                        // Contact details
    '                   <div style="width: 60%;"> ' +
    '                     <span style="font-size: 11px; font-weight: 600;"> ආදායම් කොමසාරිස් / இறைவரி ஆணையாளர் / </span> ' +
    '                     <span style="font-size: 11px; font-weight: 600;"> Commissioner of Revenue </span> ' +
    '                     <br/> ' +
    '                     <div style="display: flex; align-items: center; width: 100%;">  '  +
    '                       <div style="width: 50%; line-height: 14px;"> ' +
    '                         <span> දුරකථන / தொலைபேசி / Telephone </span> ' +
    '                         <br/> ' +
    '                         <span> ෆැක්ස් / தொலை நகல் / Fax  </span> ' +
    '                       </div>  '  +
    '                       <div style="width: 50%;"> ' +
    '                         <span style="font-size: 25px; padding-right: 5px;">} <span style="font-size: 12px;">0112 077270</span></span> ' +
    '                       </div>  '  +
    '                     </div>  '  +
    '                   </div>  '  +
    '                   <div style="width: 7.50%;"> ' +
    '                   </div> ' +
                        // ඔබේ අංකය + දිනය
    '                   <div style="width: 32.50%;"> ' +
    '                     <div style="width: 100%; display: flex; align-items: center;">  ' +
    '                       <div style="width: 27%; font-size: 9px;">  ' +
    '                         <span> ඔබේ අංකය </span>  '  +
    '                         </br> ' +
    '                         <span> உமது இல </span>  '  +
    '                         </br> ' +
    '                         <span> Your No. </span>  '  +
    '                       </div>  '  +
    '                       <div style="width: 73%;"> ' +
    '                         <span style="font-size: 25px; padding-right: 5px;"> } <span style="font-size: 11px;">$52@</span></span> ' +
    '                       </div>  '  +
    '                     </div>  '  +
    '                     <div style="width: 100%; display: flex; align-items: center; margin-top: 5px;">  ' +
    '                       <div style="width: 27%; font-size: 9px;">  ' +
    '                         <span> දිනය </span>  '  +
    '                         </br> ' +
    '                         <span> திகதி </span>  '  +
    '                         </br> ' +
    '                         <span> Date </span>  '  +
    '                       </div>  '  +
    '                       <div style="width: 73%;"> ' +
    '                         <span style="font-size: 25px; padding-right: 5px;"> } <span style="font-size: 11px;">$50@</span></span></span> ' +
    '                       </div>  '  +
    '                     </div>  '  +
    '                   </div>  '  +
    '                 </div>  '  +
    '               </header>  '  +
    '           </div>  ' +
    '   </div>  ';

    this.certificateHeader =
    '   <div style="font-size: 11px; line-height:1.3; width: 100%; font-family: \"Roboto\", sans-serif; position: relative; font-weight: 400; margin: 0; padding: 0;">  '  +
    '           <div style="padding: 0px 20px;">  '  +
    '               <header style="border-bottom: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    '                 <div style="display: flex; width: 100%;">  '  +
    '                   <div style="width: 15%; display: flex; align-items: right;"><img src="/assets/images/emblm_black_white.jpg" style="height: 120px;"></div>  '  +
    '                   <div style="width: 85%; margin-top: 15px; text-align: justify;"> ' +
    '                     <span> ' +
    '                        <span style="font-size: 16px; display: flex; align-items: center; width: 100%; font-weight: 900;"><span style="width: 43%; test-align: justify">ආදායම් දෙපාර්තමේන්තුව</span><span style="width: 2%; test-align: center"> - </span><span style="width: 2%; test-align: center"></span><span style="test-align: left">බස්නාහිර පළාත් සභාව</span></span>  ' +
    '                        <span style="font-size: 15px; display: flex; align-items: center; width: 100%; font-weight: 900;"><span style="width: 43%; test-align: left">இறைவரித் திணைக்களம்</span><span style="width: 2%; test-align: center"> - </span><span style="width: 2%; test-align: center"></span><span style="test-align: left">மேல் மாகாண சபை</span></span> ' +
    '                        <span style="font-size: 14px; display: flex; align-items: center; width: 100%; font-weight: 900;"><span style="width: 43%; test-align: left">DEPARTMENT OF REVENUE</span><span style="width: 2%; test-align: center"> - </span><span style="width: 2%; test-align: center"></span><span style="test-align: left">WESTERN PROVINCIAL COUNCIL</span></span>  ' +
    '                     <span><br> ' +
                          // ඩෙන්සිල් කොබ්බැකඩුව මාවත and මගේ අංකය division
    '                     <div style="display: flex; width: 100%;">  '  +
                            // ඩෙන්සිල් කොබ්බැකඩුව මාවත
    '                       <div style="width: 62%; vertical-align: top;"> ' +
    '                         <span style="font-size: 11px; font-weight: 600;">No.204, ඩෙන්සිල් කොබ්බැකඩුව මාවත, බත්තරමුල්ල. </span>  '  +
    '                         </br> ' +
    '                         <span style="font-size: 10px;">இல.204, டென்சில் கொப்பேகடுவ மாவத்தை, பத்தரமுல்ல. </span>  '  +
    '                         </br> ' +
    '                         <span style="font-size: 10px;">No.204, Denzil Kobbekaduwa Mawatha, Battaramulla. </span>  '  +
    '                       </div> ' +
                            // මගේ අංකය
    '                       <div style="width: 38%; line-height: 12px;">  ' +
    '                         <div style="width: 100%; display: flex; align-items: center;">  ' +
    '                           <div style="width: 27%; font-size: 9px;">  ' +
    '                             <span> මගේ අංකය </span>  '  +
    '                             </br> ' +
    '                             <span> எனது இல </span>  '  +
    '                             </br> ' +
    '                             <span> My No. </span>  '  +
    '                           </div>  '  +
    '                           <div style="width: 73%;"> ' +
    '                             <span style="font-size: 25px;"> } <span style="font-size: 13px;">$51@</span> </span> ' +
    '                           </div>  '  +
    '                         </div>  '  +
    '                       </div> ' +
    '                     </div> ' +
    '                   </div> ' +
    '                 </div>  '  +
                      // Contact details + ඔබේ අංකය + දිනය
    '                 <div style="display: flex; align-items: center; width: 100%; margin-bottom: 10px;">  '  +
                        // Contact details
    '                   <div style="width: 60%;"> ' +
    '                     <span style="font-size: 11px; font-weight: 600;"> ආදායම් කොමසාරිස් / இறைவரி ஆணையாளர் / </span> ' +
    '                     <span style="font-size: 11px; font-weight: 600;"> Commissioner of Revenue </span> ' +
    '                     <br/> ' +
    '                     <div style="display: flex; align-items: center; width: 100%;">  '  +
    '                       <div style="width: 50%; line-height: 14px;"> ' +
    '                         <span> දුරකථන / தொலைபேசி / Telephone </span> ' +
    '                         <br/> ' +
    '                         <span> ෆැක්ස් / தொலை நகல் / Fax  </span> ' +
    '                       </div>  '  +
    '                       <div style="width: 50%;"> ' +
    '                         <span style="font-size: 25px; padding-right: 5px;">} <span style="font-size: 12px;">0112 077270</span></span> ' +
    '                       </div>  '  +
    '                     </div>  '  +
    '                   </div>  '  +
    '                   <div style="width: 7.50%;"> ' +
    '                   </div> ' +
                        // ඔබේ අංකය + දිනය
    '                   <div style="width: 32.50%;"> ' +
    '                     <div style="width: 100%; display: flex; align-items: center;">  ' +
    '                       <div style="width: 27%; font-size: 9px;">  ' +
    '                         <span> ඔබේ අංකය </span>  '  +
    '                         </br> ' +
    '                         <span> உமது இல </span>  '  +
    '                         </br> ' +
    '                         <span> Your No. </span>  '  +
    '                       </div>  '  +
    '                       <div style="width: 73%;"> ' +
    '                         <span style="font-size: 25px; padding-right: 5px;"> } <span style="font-size: 13px;">$52@</span></span> ' +
    '                       </div>  '  +
    '                     </div>  '  +
    '                     <div style="width: 100%; display: flex; align-items: center; margin-top: 5px;">  ' +
    '                       <div style="width: 27%; font-size: 9px;">  ' +
    '                         <span> දිනය </span>  '  +
    '                         </br> ' +
    '                         <span> திகதி </span>  '  +
    '                         </br> ' +
    '                         <span> Date </span>  '  +
    '                       </div>  '  +
    '                       <div style="width: 73%;"> ' +
    '                         <span style="font-size: 25px; padding-right: 5px;"> } <span style="font-size: 13px;">$50@</span></span></span> ' +
    '                       </div>  '  +
    '                     </div>  '  +
    '                   </div>  '  +
    '                 </div>  '  +
    '               </header>  '  +
    '           </div>  ' +
    '   </div>  ';

    this.opinionLetterContent =
    '   <div style="padding: 0px 20px; display: flex; width: 100%; font-size: 13px; margin-top: 10px;">  '  +
    '       <span>$55@</span> '  +
    '   </div> ' +
    '   <div style="text-align: center; margin-top: 15px; text-decoration: underline; font-weight: 900;"> ' +
          // 1990 අංක 06 දරණ බස්නාහිර පළාතේ මුදල් ප්‍රඥප්තියේ 62 වන වගන්තිය ප්‍රකාරව මුද්දර ගාස්තු මතය ලබාදීම
    '     <span style="font-size: 12px;">1990 &#3461;&#3458;&#3482; 06 &#3503;&#3515;&#3499; &#3510;&#3523;&#3530;&#3505;&#3535;&#3524;&#3538;&#3515; &#3508;&#3525;&#3535;&#3501;&#3546; &#3512;&#3540;&#3503;&#3517;&#3530; &#3508;&#3530;&#8205;&#3515;&#3493;&#3508;&#3530;&#3501;&#3538;&#3514;&#3546; 62 &#3520;&#3505;&#160;  '  +
    '     &#3520;&#3484;&#3505;&#3530;&#3501;&#3538;&#3514; &#3508;&#3530;&#8205;&#3515;&#3482;&#3535;&#3515;&#3520; &#3512;&#3540;&#3503;&#3530;&#3503;&#3515; &#3484;&#3535;&#3523;&#3530;&#3501;&#3540; &#3512;&#3501;&#3514; &#3517;&#3510;&#3535;&#3503;&#3539;&#3512;</span>  '  +
    '     <br>  ' +
    '     <span style="font-size: 14px;">To Obtain the Stamp Duty Opinion under the Section 62 of Financial Statute of the Western Province No 06 of 1990</span> '  +
    '   </div>  ' +
    // First Sinhala sentence
    '   <br>  ' +
    '   <div style="display: flex; align-items: center; width: 100%;">  ' +
    '     <div style="width: 2%;">  ' +
    '     </div>  ' +
    '     <div style="width: 96%;">  ' +
              // උක්ත කරුණ සම්බන්ධයෙන් 05/01/2021 දිනැති ඔබගේ ඉල්ලීම පරිදි මුදල් ප්‍රඥප්තියේ ඉහත වගන්තිය
    '       <p style="font-size: 12px;">&#3467;&#3482;&#3530;&#3501; &#3482;&#3515;&#3540;&#3499; &#3523;&#3512;&#3530;&#3510;&#3505;&#3530;&#3504;&#3514;&#3545;&#3505;&#3530; $53@ &#3503;&#3538;&#3505;&#3536;&#3501;&#3538; &#3476;&#3510;&#3484;&#3546; &#3465;&#3517;&#3530;&#3517;&#3539;&#3512; &#3508;&#3515;&#3538;&#3503;&#3538; &#3512;&#3540;&#3503;&#3517;&#3530; &#3508;&#3530;&#8205;&#3515;&#3493;&#3508;&#3530;&#3501;&#3538;&#3514;&#3546; &#3465;&#3524;&#3501; &#3520;&#3484;&#3505;&#3530;&#3501;&#3538;&#3514;&#160; ' +
              // ප්‍රකාරව තක්සේරුකරුගේ මතයට අනුව දේපළෙහි මුද්දර ගාස්තු ගෙවීම සඳහා වටිනාකම හා ඊට අදාල මුද්දර
    '         &#3508;&#3530;&#8205;&#3515;&#3482;&#3535;&#3515;&#3520; &#3501;&#3482;&#3530;&#3523;&#3546;&#3515;&#3540;&#3482;&#3515;&#3540;&#3484;&#3546; &#3512;&#3501;&#3514;&#3495; &#3461;&#3505;&#3540;&#3520; &#3503;&#3546;&#3508;&#3525;&#3545;&#3524;&#3538; &#3512;&#3540;&#3503;&#3530;&#3503;&#3515; &#3484;&#3535;&#3523;&#3530;&#3501;&#3540; &#3484;&#3545;&#3520;&#3539;&#3512; &#3523;&#3507;&#3524;&#3535; &#3520;&#3495;&#3538;&#3505;&#3535;&#3482;&#3512; &#3524;&#3535; &#3466;&#3495; &#3461;&#3503;&#3535;&#3517; &#3512;&#3540;&#3503;&#3530;&#3503;&#3515;&#160; ' +
              // ගාස්තුව ඇමුණුම  1හි දැක්වේ.
    '         &#3484;&#3535;&#3523;&#3530;&#3501;&#3540;&#3520; &#3463;&#3512;&#3540;&#3499;&#3540;&#3512;&#160; 1&#3524;&#3538; &#3503;&#3536;&#3482;&#3530;&#3520;&#3546;. ' +
    '       </p> ' +
    '     </div>  ' +
    '     <div style="width: 2%;">  ' +
    '     </div>  ' +
    '   </div>  ' +
    // Second Sinhala sentence
    '   <div style="display: flex; align-items: center; width: 100%; margin-top: 5px;">  ' +
    '     <div style="width: 2%;">  ' +
    '     </div>  ' +
    '     <div style="width: 96%;">  ' +
              // 02. කරුණාකර අදාළ මුද්දර ගාස්තුව ගෙවා සාධන පත්‍රයේ මුල් පිටපත හා දෙවන පිටපත සමඟ එම සාධන පත්‍රයේ
    '       <p style="font-size: 12px;">02. &#3482;&#3515;&#3540;&#3499;&#3535;&#3482;&#3515; &#3461;&#3503;&#3535;&#3525; &#3512;&#3540;&#3503;&#3530;&#3503;&#3515; &#3484;&#3535;&#3523;&#3530;&#3501;&#3540;&#3520; &#3484;&#3545;&#3520;&#3535; &#3523;&#3535;&#3504;&#3505; &#3508;&#3501;&#3530;&#8205;&#3515;&#3514;&#3546; &#3512;&#3540;&#3517;&#3530; &#3508;&#3538;&#3495;&#3508;&#3501; &#3524;&#3535; &#3503;&#3545;&#3520;&#3505; &#3508;&#3538;&#3495;&#3508;&#3501; &#3523;&#3512;&#3487; &#3473;&#3512; &#3523;&#3535;&#3504;&#3505; &#3508;&#3501;&#3530;&#8205;&#3515;&#3514;&#3546; ' +
              // ඡායා පිටපතක් සහ ගෙවීම් පතෙහි ඡායා පිටපත් දෙකක් සමඟ ඉදිරිපත් කර නිසි පරිදි මුද්දර ගාස්තු ගෙවූ සාධන පත්‍රයක්
    '         &#3489;&#3535;&#3514;&#3535; &#3508;&#3538;&#3495;&#3508;&#3501;&#3482;&#3530; &#3523;&#3524; &#3484;&#3545;&#3520;&#3539;&#3512;&#3530; &#3508;&#3501;&#3545;&#3524;&#3538; &#3489;&#3535;&#3514;&#3535; &#3508;&#3538;&#3495;&#3508;&#3501;&#3530; &#3503;&#3545;&#3482;&#3482;&#3530; &#3523;&#3512;&#3487; &#3465;&#3503;&#3538;&#3515;&#3538;&#3508;&#3501;&#3530; &#3482;&#3515; &#3505;&#3538;&#3523;&#3538; &#3508;&#3515;&#3538;&#3503;&#3538; &#3512;&#3540;&#3503;&#3530;&#3503;&#3515; &#3484;&#3535;&#3523;&#3530;&#3501;&#3540; &#3484;&#3545;&#3520;&#3542; &#3523;&#3535;&#3504;&#3505; &#3508;&#3501;&#3530;&#8205;&#3515;&#3514;&#3482;&#3530; ' +
              // ලෙස සහතික කරගන්න.
    '         &#3517;&#3545;&#3523; &#3523;&#3524;&#3501;&#3538;&#3482; &#3482;&#3515;&#3484;&#3505;&#3530;&#3505;. ' +
    '       </p> ' +
    '     </div>  ' +
    '     <div style="width: 2%;">  ' +
    '     </div>  ' +
    '   </div>  ' +
    // First English sentence
    '   <div style="display: flex; align-items: center; width: 100%; margin-top: 5px;">  ' +
    '     <div style="width: 2%;">  ' +
    '     </div>  ' +
    '     <div style="width: 96%;">  ' +
    '       <p style="font-size: 15px;">With reference to your request of the above mentioned matter dated $54@ the value of the property according to the Assessor\'s Opinion and&#160; the stamp duty to be paid as in annex one.</p> ' +
    '     </div>  ' +
    '     <div style="width: 2%;">  ' +
    '     </div>  ' +
    '   </div>  ' +
    // Second English sentence
    '   <div style="display: flex; align-items: center; width: 100%; margin-top: 5px;">  ' +
    '     <div style="width: 2%;">  ' +
    '     </div>  ' +
    '     <div style="width: 96%;">  ' +
    '       <p style="font-size: 15px;">02. Please get certified the Original and the duplicate of the instrument, as duly stamped by the Department of Revenue - Western Province, ' +
    '         with a photo copy of said instrument and two photo copies of the pay-in slip.</p>  '  +
    '     </div>  ' +
    '     <div style="width: 2%;">  ' +
    '     </div>  ' +
    '   </div>  ' +
    '   <div><span><br></span></div>  '  +
        // Commissioner's Address
    '   <div style="padding: 0px 20px; width: 100%; margin-bottom: 150px;">  ' +
    '     <p style="font-size: 12px; margin-bottom: 0px; !important">'+
    '     <span style="font-size: 13px;">$57@</span><br> '+
    '     &#3505;&#3538;&#3514;&#3549;&#3490;&#3530;&#8205;&#3514; &#3462;&#3503;&#3535;&#3514;&#3512;&#3530; &#3482;&#3548;&#3512;&#3523;&#3535;&#3515;&#3538;&#3523;&#3530; ,<br> '  +
    '     &#3510;&#3523;&#3530;&#3505;&#3535;&#3524;&#3538;&#3515; &#3508;&#3525;&#3535;&#3501;&#160;<br>  '  +
    '     Deputy Commissioner Of Revenue<br>  '  +
    '     Western Province</p>  '  +
    '   </div>  ' +
    '   <div style="padding-top:150px; bottom:20px;">  '  +
    '     <table style="width: 100%; border: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    '   		<tbody>  '  +
    '   			<tr style="font-size: 10px;">  '  +
    '            <td style="padding-left: 10px; border-right: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    '   						නියෝජ්‍ය කොමසාරිස් - කොළඹ <br>  '  +
    '		            Deputy Commissioner - Colombo<br>  '  +
    '   	          Telephone : 0112077235<br>  '  +
    '   						Fax       : 0112077235  '  +
    '	           </td>  '  +
    '            <td style="padding-left: 10px; border-right: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    '   						 නියෝජ්‍ය කොමසාරිස් - මුද්දර අංශය  <br>  '  +
    '		             Deputy Commissioner - Stamp Division<br>  '  +
    '                Telephone : 0112077248<br>  '  +
    '   						 Fax       : 0112077248  '  +
    '            </td>  '  +
    '   			  <td style="padding-left: 10px; border-right: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    '   					  නියෝජ්‍ය කොමසාරිස් - මහරගම  <br>  '  +
    '   						Deputy Commissioner - Maharagama<br>  '  +
    '   				    Telephone : 0112840099<br>  '  +
    '   				    Fax       : 0112840099  '  +
    '   				</td>  '  +
    '           <td style="padding-left: 10px; border-right: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    '   				  නියෝජ්‍ය කොමසාරිස් - කළුතර <br>  '  +
    '   				  Deputy Commissioner - Kaluthara<br>  '  +
    '   				  Telephone : 0342223124<br>  '  +
    '   				  Fax       : 0342223124  '  +
    '   				</td>  '  +
    '   				<td style="padding-left: 10px;">  '  +
    '   				  නියෝජ්‍ය කොමසාරිස් - ගම්පහ <br>  '  +
    '   				  Deputy Commissioner - Gampaha<br>  '  +
    '   				  Telephone : 0332226530<br>  '  +
    '   				  Fax       : 0332226530  '  +
    '   				</td>  '  +
    '         </tr>  '  +
    '       </tbody>  '  +
    '   	</table>  '  +
    '  </div>  ' 
    // '   <div style="padding: 0px 20px 0px 20px; width: 100%; margin-top: 40px;">  '  +
    // '     <table style="width: 98%; border: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    // '       <tbody>  '  +
    // '         <tr style="height: 50px;">  '  +
    // '           <td style="width: 33%; font-weight: 600; padding-left: 15px; border-right: 1px solid rgba(0, 0, 0, 0.4); border-bottom: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    //               // නියෝජ්‍ය ආදායම් කොමසාරිස්
    // '             <span style="font-size: 11px;">&#3505;&#3538;&#3514;&#3549;&#3490;&#3530;&#8205;&#3514; &#3462;&#3503;&#3535;&#3514;&#3512;&#3530; &#3482;&#3548;&#3512;&#3523;&#3535;&#3515;&#3538;&#3523;&#3530;</span> <br>  '  +
    // '             <span style="font-size: 13px;">Deputy Commissioner of Revenue : </span> <span style="font-size: 13px;">$57@</span><br>  '  +
    // '           </td>  '  +
    // '           <td style="width: 33%; font-weight: 600; padding-left: 15px; border-bottom: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    //               // ජ්‍යෙෂ්ඨ තක්සේරුකරු / තක්සේරුකරු
    // '             <span style="font-size: 11px;">&#3490;&#3530;&#8205;&#3514;&#3545;&#3522;&#3530;&#3496; &#3501;&#3482;&#3530;&#3523;&#3546;&#3515;&#3540;&#3482;&#3515;&#3540; / &#3501;&#3482;&#3530;&#3523;&#3546;&#3515;&#3540;&#3482;&#3515;&#3540;<br>  '  +
    // '             <span style="font-size: 13px;">Senior Assessor / Assessor : </span> <span style="font-size: 13px;">$56@</span><br> ' +
    // '           </td>  '  +
    // '         </tr>  '  +
    // '   			<tr style="height: 50px;">  '  +
    // '           <td style="font-size: 13px; padding-left: 15px; border-right: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    // '   					Telephone : 0112077235<br>  '  +
    // '   					Fax : 0112077235  '  +
    // '   				</td>  '  +
    // '           <td style="font-size: 13px; padding-left: 15px;">  '  +
    // '   					Telephone : 0112078886  <br>  '  +
    // '   					Fax       : '  +
    // '   				</td>  '  +
    // '         </tr>  '  +
    // '   		</tbody>  '  +
    // '   	</table>  '  +
    // '  	</div>  ' ;

    this.certificateContent =
    '   <div style="font-size: 11px; line-height:1.3; width: 100%; height: 100%; font-family: \"Roboto\", sans-serif; position: relative; font-weight: 400; margin: 0; padding: 0;">  '  +
    '           <div style="padding: 0px 20px;">  '  +
    '               <header style="border-bottom: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    '                 <div style="display: flex; width: 100%;">  '  +
    '                   <div style="width: 15%; display: flex; align-items: right;"><span style="font-size: 15px;">Annex 01</span></div>  '  +
    '                   <div style="width: 70%; display: flex;"></div>  '  +
    '                   <div style="width: 15%; display: flex;"><span>$26@</span></div>  '  +
    '                 </div>  '  +
    '                 <div style="display: flex; width: 100%;">  '  +
    '                   <div style="width: 15%; display: flex; align-items: right;"><img src="/assets/images/emblm_black_white.jpg" style="height: 120px;"></div>  '  +
    '                   <div style="width: 85%; margin-top: 15px; text-align: justify;"> ' +
    '                     <span> ' +
    '                        <span style="font-size: 22px; display: flex; align-items: center; width: 100%; font-weight: 900;"><span style="width: 42%; test-align: justify">ආදායම් දෙපාර්තමේන්තුව</span><span style="width: 2%; test-align: center"> - </span><span style="width: 3%; test-align: center"></span><span style="test-align: left">බස්නාහිර පළාත් සභාව</span></span>  ' +
    '                        <span style="font-size: 18px; display: flex; align-items: center; width: 100%; font-weight: 900;"><span style="width: 42%; test-align: left">இறைவரித் திணைக்களம்</span><span style="width: 2%; test-align: center"> - </span><span style="width: 3%; test-align: center"></span><span style="test-align: left">மேல் மாகாண சபை</span></span> ' +
    '                        <span style="font-size: 17px; display: flex; align-items: center; width: 100%; font-weight: 900;"><span style="width: 42%; test-align: left">DEPARTMENT OF REVENUE</span><span style="width: 2%; test-align: center"> - </span><span style="width: 3%; test-align: center"></span><span style="test-align: left">WESTERN PROVINCIAL COUNCIL</span></span>  ' +
    '                     <span><br> ' +
    '                     <div style="display: flex; width: 100%;">  '  +
    '                       <div style="width: 65%; vertical-align: top;"> ' +
    '                         <span style="font-weight: 700;">No.204, ඩෙන්සිල් කොබ්බැකඩුව මාවත, බත්තරමුල්ල. </span>  '  +
    '                         </br> ' +
    '                         <span>இல.204, டென்சில் கொப்பேகடுவ மாவத்தை, பத்தரமுல்ல. </span>  '  +
    '                         </br> ' +
    '                         <span>No.204, Denzil Kobbekaduwa Mawatha, Battaramulla. </span>  '  +
    '                       </div> ' +
    '                       <div style="width: 35%; line-height: 12px;">  ' +
    '                         <div style="width: 100%; display: flex; align-items: center;">  ' +
    '                           <div style="width: 30%; font-size: 10px;">  ' +
    '                             <span> මගේ අංකය </span>  '  +
    '                             </br> ' +
    '                             <span> எனது இல </span>  '  +
    '                             </br> ' +
    '                             <span> My No. </span>  '  +
    '                           </div>  '  +
    '                           <div style="width: 70%;"> ' +
    '                             <span style="font-size: 25px; padding-right: 5px;"> } <span style="font-size: 10px;">$1@</span> </span> ' +
    '                           </div>  '  +
    '                         </div>  '  +
    '                         </br> ' +
    '                         <div style="width: 100%; display: flex; align-items: center;">  ' +
    '                           <div style="width: 30%; font-size: 10px;">  ' +
    '                             <span> ඔබේ අංකය </span>  '  +
    '                             </br> ' +
    '                             <span> உமது இல </span>  '  +
    '                             </br> ' +
    '                             <span> Your No. </span>  '  +
    '                           </div>  '  +
    '                           <div style="width: 70%;"> ' +
    '                             <span style="font-size: 25px; padding-right: 5px;"> } <span style="font-size: 10px;">$24@</span></span> ' +
    '                           </div>  '  +
    '                         </div>  '  +
    '                       </div> ' +
    '                     </div> ' +
    '                   </div> ' +
    '                 </div>  '  +
    '                 <div style="display: flex; align-items: center; width: 100%;">  '  +
    '                   <div style="width: 50%;"> ' +
    '                     <span style="font-weight: 600; font-size: 12px;"> ආදායම් කොමසාරිස් / இறைவரி ஆணையாளர் / Commissioner of Revenue </span> ' +
    '                     <br/> ' +
    '                     <div style="display: flex; align-items: center; width: 100%;">  '  +
    '                       <div style="width: 50%; line-height: 14px;"> ' +
    '                         <span> දුරකථන / தொலைபேசி / Telephone </span> ' +
    '                         <br/> ' +
    '                         <span> ෆැක්ස් / தொலை நகல் / Fax </span> ' +
    '                       </div>  '  +
    '                       <div style="width: 50%;"> ' +
    '                         <span style="font-size: 25px; padding-right: 5px;">} <span style="font-size: 15px;">0112 077270</span></span> ' +
    '                       </div>  '  +
    '                     </div>  '  +
    '                   </div>  '  +
    '                   <div style="width: 20.5%;"> ' +
    '                   </div> ' +
    '                   <div style="display: flex; align-items: center; width: 29.5%; margin-top: 15px; margin-bottom: 10px;"> ' +
    '                     </br></br> ' +
    '                     <div style="width: 30%; font-size: 10px;">  ' +
    '                       <span> දිනය </span>  '  +
    '                       </br> ' +
    '                       <span> திகதி </span>  '  +
    '                       </br> ' +
    '                       <span> Date </span>  '  +
    '                     </div>  '  +
    '                     <div style="width: 70%;"> ' +
    '                       <span style="font-size: 25px; padding-right: 5px;"> } <span style="font-size: 10px;">$23@</span></span></span> ' +
    '                     </div>  '  +
    '                   </div>  '  +
    '                 </div>  '  +
    '               </header>  '  +
    '               <main>  '  +
    '                   <div style="padding-top: 5px">  '  +
    '                       <div style="display: flex; width: 100%;">  '  +
    '                         <div style="width: 85%; display: flex; align-items: center;">  ' +
    '                             <span>$3@</span> '  +
    '                         </div> '  +
    '                         <div style="width: 15%;">  '  +
    // '                             <span><img src=$27@ style="height: 120px;"></span><span>$22@</span>  '  +
    '                             <span>$22@</span>  '  +
    '                         </div>  '  +
    '                       </div>  '  +
    '                       <p style="font-size: 13px;">මහත්මයාණෙනි / මහත්මියනි /මහත්වරුනි ,<br> <span style="font-size: 15px;"> Dear Sir / Madam / Sirs, </span></p>  '  +
    '                       <div style="text-align: center; font-style: normal; font-weight: bold; text-decoration: underline; font-size: 13px;">  '  +
    '                           <p>1990 අංක 06 දරන බස්නාහිර පළාතේ මුදල් ප්‍රඥප්තියේ 62 වගන්තිය ප්‍රකාරව මුද්දර ගාස්තු මතය <br>The Opinion for Stamp Duty Under Section 62 of Financial Statute of the Western Province No. 06 of 1990</p>  '  +
    '                       </div>  '  +
    '                       <div>  '  +
    '                           <p style="font-size: 13px;">උක්ත කරුණ සම්බන්ධයෙන් ඔබගේ $4@ දිනැති ඉල්ලුම් පත්‍රයට අනුව පහත විස්තර ඇතුළත් දේපළෙහි &nbsp; $5@ දිනට වටිනාකම මේ සමග දැක්වේ.<br> <span style="font-size: 15px;">With reference to your application dated $4@ for the above subject, market value of below mentioned property to the date of $5@ is given below.</span> </p>  '  +
    '                           <div style="padding-bottom: 10px">  '  +
    '                               <table style="width: 100%; border: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    '                                   <tbody>  '  +
    '                                       <tr style="font-size: 13px;" height="43px">  '  +
    '                                           <th style="padding-left: 15px; width: 4%; border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);" scope="row">1</th>  '  +
    '                                           <td style="padding-left: 15px; width: 42%; border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);">ඔප්පු අංකය / <span style="font-size: 15px;">Deed No</span> : </td>  '  +
    '                                           <td style="padding-left: 15px; width: 54%; border-bottom: 1px solid rgba(0, 0, 0, 0.4); font-size: 15px;">Not Applicable</td>  '  +
    '                                       </tr>  '  +
    '                                       <tr style="font-size: 13px;" height="43px">  '  +
    '                                           <th style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);" scope="row">2</th>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);">ප්‍රසිද්ධ නොතාරිස් : නම සහ ලිපිනය : <br> <span style="font-size: 15px;">Notary\'s Name & Address</span></td>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); font-size: 15px;"> $7@ </td>  '  +
    '                                       </tr>  '  +
    '                                       <tr style="font-size: 13px;" height="43px">  '  +
    '                                           <th style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);" scope="row">3</th>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);">දේපළ පිහිටි පළාත් පාලන ප්‍රදේශය : <br> <span style="font-size: 15px;">Local Authority Where Property Situated</span> </td>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); font-size: 15px;"> $8@ </td>  '  +
    '                                       </tr>  '  +
    '                                       <tr style="font-size: 13px;" height="43px">  '  +
    '                                           <th style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);" scope="row">4</th>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);">ඉඩමේ නම සහ ලිපිනය / <span style="font-size: 15px;">Name of the Land and Address</span> </td>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); font-size: 15px;"> $9@ </td>  '  +
    '                                       </tr>  '  +
    '                                       <tr style="font-size: 13px;" height="43px">  '  +
    '                                           <th style="padding-left: 15px; border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);" scope="row">5</th>  '  +
    '                                           <td style="padding-left: 15px; border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);">මිනින්දෝරු  තැනගේ  නම : <br><span style="font-size: 15px;">Name of the Surveyor</span></td>  '  +
    '                                           <td style="padding-left: 15px; border-bottom: 1px solid rgba(0, 0, 0, 0.4); font-size: 15px;"> $10@ </td>  '  +
    '                                       </tr>  '  +
    '                                       <tr style="font-size: 13px;" height="43px">  '  +
    '                                           <th style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);" scope="row">6</th>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);">දේපළට අදාළ මිනින්දෝරු සැලසුම් අංකය හෝ <br> සභාධිපත්‍ය සැලසුම් අංකය: <br><span style="font-size: 15px;">Plan No. & Condominium Plan No.</span></td>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); font-size: 15px;"> $11@ </td>  '  +
    '                                       </tr>  '  +
    '                                       <tr style="font-size: 13px;" height="43px">  '  +
    '                                           <th style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);" scope="row">7</th>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);">කැබලි අංකය හෝ ඒකක අංකය : <br><span style="font-size: 15px;">Lot No(s) / Condominium Unit No(s) or Unit No.</span></td>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); font-size: 15px;"> $12@ </td>  '  +
    '                                       </tr>  '  +
    '                                       <tr style="font-size: 13px;" height="43px">  '  +
    '                                           <th style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);" scope="row">8</th>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);">විශාලත්වය / <span style="font-size: 15px;">Extent</span> : </td>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); font-size: 15px;"> $13@ </td>  '  +
    '                                       </tr>  '  +
    '                                       <tr style="font-size: 13px;" height="43px">  '  +
    '                                           <th style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);" scope="row">9</th>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);">ඔප්පුවේ ස්වභාවය සහ අයිතිය : <br><span style="font-size: 15px;">Nature & Legal Interest of the Deed</span></td>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); font-size: 15px;"> $14@ </td>  '  +
    '                                       </tr>  '  +
    '                                       <tr style="font-size: 13px;" height="43px">  '  +
    '                                           <th style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);" scope="row">10</th>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);">ගැණුම්කරුගේ / ත්‍යාගලාභියාගේ නම සහ ලිපිනය : <br><span style="font-size: 15px;">Name & Address of the Grantee</span> </td>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); font-size: 15px;"> $15@ </td>  '  +
    '                                       </tr>  '  +
    '                                       <tr style="font-size: 13px;" height="43px">  '  +
    '                                           <th style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);" scope="row">11</th>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);">විකුණුම්කරුගේ / ත්‍යාගදායකයාගේ නම සහ ලිපිනය : <br><span style="font-size: 15px;">Name & Address of the Grantor</span> </td>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); font-size: 15px;"> $16@ </td>  '  +
    '                                       </tr>  '  +
    '                                       <tr style="font-size: 13px;" height="43px">  '  +
    '                                           <th style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);" scope="row">12</th>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); border-right: 1px solid rgba(0, 0, 0, 0.4);"> $19@ &nbsp; දිනට දේපළෙහි වටිනාකම :<br> <span style="font-size: 15px;">Value of the Property to the Date</span> &nbsp; $19@ </td>  '  +
    '                                           <td style="padding-left: 15px;  border-bottom: 1px solid rgba(0, 0, 0, 0.4); font-size: 15px;">Rs. $17@ /= <small style="font-weight: 500;">( $20@  '  +
    '                                                   )</small>  '  +
    '                                           </td>  '  +
    '                                       </tr>  '  +
    '                                       <tr style="font-size: 13px;" height="43px">  '  +
    '                                           <th style="padding-left: 15px; border-right: 1px solid rgba(0, 0, 0, 0.4);" scope="row">13</th>  '  +
    '                                           <td style="padding-left: 15px; border-right: 1px solid rgba(0, 0, 0, 0.4);">ගෙවිය යුතු මුද්දර ගාස්තුව / <span style="font-size: 15px;">Stamp Duty Payable</span> : </td>  '  +
    '                                           <td style="padding-left: 15px; font-size: 15px;">Rs. $18@ /= <small style="font-weight: 500;">( $21@  '  +
    '                                                   )</small>  '  +
    '                                           </td>  '  +
    '                                       </tr>  '  +
    '                                   </tbody>  '  +
    '                               </table>  '  +
    '     '  +
    '                           </div>  '  +
    '                           <p style="font-size: 13px;">මෙම මුද්දර ගාස්තු මහජන බැංකුව / ලංකා බැංකුව වෙත ගෙවා සාධන පත්‍රයේ දෙවන පිටපත ඉදිරිපත් කිරීමෙන් පසුව නිසි පරිදි මුද්දර ගාස්තු ගෙවූ සාධන පත්‍රයක් ලෙස සහතික කරනු ලැබේ. තවද, මෙම මතය සම්බන්ධයෙන් යම් විරෝධතාවක් ඇත්නම් මෙම ප්‍රඥප්තියේ 79 වගන්තිය යටතේ දින 30 ක් තුළ අභියාචනයක් ඉදිරිපත් කළහැක.<br> <span style="font-size: 15px;">This stamp duty should be paid to People’s Bank or Bank of Ceylon & submit second copy of the  '  +
    '                               deed to the department to endorsement or any objection regarding this opinion, may submit a  '  +
    '                               written  '  +
    '                               appeal within 30 days under the provision of section 79 of this statute.</span></p>  '  +
    '                           <p style="font-size: 13px;">මෙම වටිනාකම 1990 අංක 06 දරන බස්නාහිර පළාතේ මුදල් ප්‍රඥප්තියේ මුද්දර ගාස්තු නිශ්චය කිරීමේ කාර්යය සඳහා පමණක් වලංගු වන බව දන්වමි.<br><span style="font-size: 15px;">This value is valid only for adjudication of stamp duty under Financial Statute of the Western Province No. 06 of 1990</span></p>  '  +
    '                       </div>  '  +
    '                   </div>  '  +
    '                   <div> ' +
    // '                     <div style="float: left"> <br><br> '  +
    // '   					            <span>$25@</span>  '  +
    // '   				          </div>  '  +
    '                     <div style="float: right"> <br><br> '  +
    '   					            <p><span>$25@</span><br>ජ්‍යෙෂ්ඨ තක්සේරුකරු / තක්සේරුකරු <br><span>$28@</span></p>  '  +
    '   				          </div>  '  +
    '                   </div> ' +
    '   				        <div>  '  +
    '   					         <table style="width: 100%; border: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    '   						           <tbody>  '  +
    '   							            <tr style="font-size: 10px;">  '  +
    '                                 <td style="padding-left: 15px; border-right: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    '   								                  නියෝජ්‍ය කොමසාරිස් - කොළඹ <br>  '  +
    '   								                  Deputy Commissioner - Colombo<br>  '  +
    '   								                  Telephone : 0112077235<br>  '  +
    '   								                  Fax       : 0112077235  '  +
    '   							                </td>  '  +
    '                                 <td style="padding-left: 15px; border-right: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    '   								                  නියෝජ්‍ය කොමසාරිස් - මුද්දර අංශය  <br>  '  +
    '   								                  Deputy Commissioner - Stamp Division<br>  '  +
    '   								                  Telephone : 0112077248<br>  '  +
    '   								                  Fax       : 0112077248  '  +
    '   							                </td>  '  +
    '   							                <td style="padding-left: 15px; border-right: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    '   								                  නියෝජ්‍ය කොමසාරිස් - මහරගම  <br>  '  +
    '   								                  Deputy Commissioner - Maharagama<br>  '  +
    '   								                  Telephone : 0112840099<br>  '  +
    '   								                  Fax       : 0112840099  '  +
    '   							                </td>  '  +
    '                                 <td style="padding-left: 15px; border-right: 1px solid rgba(0, 0, 0, 0.4);">  '  +
    '   								                  නියෝජ්‍ය කොමසාරිස් - කළුතර <br>  '  +
    '   								                  Deputy Commissioner - Kaluthara<br>  '  +
    '   								                  Telephone : 0342223124<br>  '  +
    '   								                  Fax       : 0342223124  '  +
    '   							                </td>  '  +
    '   							                <td style="padding-left: 15px;">  '  +
    '   								                  නියෝජ්‍ය කොමසාරිස් - ගම්පහ <br>  '  +
    '   								                  Deputy Commissioner - Gampaha<br>  '  +
    '   								                  Telephone : 0332226530<br>  '  +
    '   								                  Fax       : 0332226530  '  +
    '   							                 </td>  '  +
    '                              </tr>  '  +
    '   						         </tbody>  '  +
    '   					        </table>  '  +
    '  				          </div>  ' +
    '               </main>  '  +
    '           </div>  '  +
    '      </div>  ' ;

    this.obtainUserData();
    this.loadCertificateData();
    this.loadOpinionLetterData();
    this.loadCertificateDetails();
  }

  findCurrentUserRole() {
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.model.userId = this.userdata.id;
    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_ACCESSOR) {
        this.currentUserRoleCode = Constant.ROLE_ACCESSOR;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER){
        this.currentUserRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.currentUserRoleCode = Constant.ROLE_COMMISSIONER;
        break;
      }
    }
  }

  pad(num: number, size: number) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    this.generatedFileNumber = s;
    if(this.isAppeal == true){
      this.generatedFileNumber = '#A' + this.generatedFileNumber;
    }else{
      this.generatedFileNumber = '#VR' + this.generatedFileNumber;
    }
  }

  loadCertificateDetails() {
    this.sub.add(this.certificateService.fetchCertificateDataByValuationReqId(this.valuationRequestId)
      .subscribe(
        (res) => {
          this.certificateData = (res as any).data;
          if (localStorage.getItem('fileNumberFormat') == "null" || localStorage.getItem('fileNumberFormat') == "") {
            this.barCode = "WP/C/SD/OP/2021/001/000000";
          } else {
            this.barCode = localStorage.getItem('fileNumberFormat');
          }

          this.certificateContent = this.certificateContent.replace("$1@", this.certificateData.fileNumberFormat ? this.certificateData.fileNumberFormat : " ");
          this.certificateContent = this.certificateContent.replace("$2@", this.certificateData.currentDate);
          this.certificateContent = this.certificateContent.replace("$3@", this.certificateData.notaryDetails);
          this.certificateContent = this.certificateContent.replace("$4@", this.certificateData.createdDate);
          this.certificateContent = this.certificateContent.replace("$4@", this.certificateData.createdDate);
          this.certificateContent = this.certificateContent.replace("$5@", this.certificateData.calculationHeadingDateTwo ? this.certificateData.calculationHeadingDateTwo : this.certificateData.calculationCreatedDate);
          this.certificateContent = this.certificateContent.replace("$5@", this.certificateData.calculationHeadingDateTwo ? this.certificateData.calculationHeadingDateTwo : this.certificateData.calculationCreatedDate);
          this.certificateContent = this.certificateContent.replace("$6@", this.certificateData.previousDeedNo);
          this.certificateContent = this.certificateContent.replace("$7@", this.certificateData.notaryDetails);
          this.certificateContent = this.certificateContent.replace("$8@", this.certificateData.localAuthority);
          this.certificateContent = this.certificateContent.replace("$9@", this.certificateData.propertyAddress);
          this.certificateContent = this.certificateContent.replace("$10@", this.certificateData.surveyorName);
          let planDateEx = moment(this.certificateData.surveyorPlanDate).format("DD/MM/yyyy")
          this.certificateContent = this.certificateContent.replace("$11@", this.certificateData.surveyorPlanNo + "<br>" + planDateEx);
          this.certificateContent = this.certificateContent.replace("$12@", this.certificateData.lotNo);
          this.certificateContent = this.certificateContent.replace("$13@", this.certificateData.extentFromValueAndUnit);
          this.certificateContent = this.certificateContent.replace("$14@", this.certificateData.deedNature);
          if(this.certificateData.numberOfGrantees<3){
            this.certificateContent = this.certificateContent.replace("$15@", this.certificateData.granteeDetails);
          }else{
            this.certificateContent = this.certificateContent.replace("$15@", `${this.certificateData.granteeDetails} & others.`);
          }
          if(this.certificateData.numberOfGranters<3){
            this.certificateContent = this.certificateContent.replace("$16@", this.certificateData.granterDetails);
          }else{
            this.certificateContent = this.certificateContent.replace("$16@", `${this.certificateData.granterDetails} & others.`);
          }
          this.certificateContent = this.certificateContent.replace("$17@", this.certificateData.roundOffValue.replace(/\B(?=(\d{3})+(?!\d))/g, ","));
          this.certificateContent = this.certificateContent.replace("$18@", this.certificateData.stampDuty.replace(/\B(?=(\d{3})+(?!\d))/g, ","));
          this.certificateContent = this.certificateContent.replace("$19@", this.certificateData.calculationHeadingDateTwo ? this.certificateData.calculationHeadingDateTwo : this.certificateData.currentDate);
          this.certificateContent = this.certificateContent.replace("$19@", this.certificateData.calculationHeadingDateTwo ? this.certificateData.calculationHeadingDateTwo : this.certificateData.currentDate);
          this.certificateContent = this.certificateContent.replace("$20@", this.numberWordConvert(this.certificateData.roundOffValue.replace(',', '').split('.')[0])+" and " +
          this.numberWordConvert(this.certificateData.roundOffValue.replace(',', '').split('.')[1])+ " Cents Only");
          this.certificateContent = this.certificateContent.replace("$21@", this.numberWordConvert(this.certificateData.stampDuty.replace(',', '').split('.')[0])+" and " +
          this.numberWordConvert(this.certificateData.stampDuty.replace(',', '').split('.')[1])+ " Cents Only");
          this.certificateContent = this.certificateContent.replace("$23@", this.certificateData.currentDate);
          this.certificateContent = this.certificateContent.replace("$24@", this.generatedFileNumber);
          this.certificateContent = this.certificateContent.replace("$25@", this.certificateData.assessorName);
          this.certificateContent = this.certificateContent.replace("$28@", this.certificateData.assessorOffice);


          this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + this.certificateData.qrCode);

          // Opinion Letter Content
          this.opinionLetterHeader = this.opinionLetterHeader.replace("$50@", this.certificateData.currentDate);
          this.opinionLetterHeader = this.opinionLetterHeader.replace("$51@", this.certificateData.fileNumberFormat);
          this.opinionLetterHeader = this.opinionLetterHeader.replace("$52@", this.generatedFileNumber);
          this.certificateHeader = this.certificateHeader.replace("$50@", this.certificateData.currentDate);
          this.certificateHeader = this.certificateHeader.replace("$51@", this.certificateData.fileNumberFormat);
          this.certificateHeader = this.certificateHeader.replace("$52@", this.generatedFileNumber);
          this.opinionLetterContent = this.opinionLetterContent.replace("$53@", this.certificateData.createdDate);
          this.opinionLetterContent = this.opinionLetterContent.replace("$54@", this.certificateData.createdDate);
          this.opinionLetterContent = this.opinionLetterContent.replace("$55@", this.certificateData.notaryDetails);
          this.opinionLetterContent = this.opinionLetterContent.replace("$56@", this.certificateData.assessorName);
          this.opinionLetterContent = this.opinionLetterContent.replace("$57@", this.certificateData.deputyCommissionerName);
        }
      ));
  }

  loadCertificateData() {
    this.sub.add(this.certificateService.fetchAllCertificatesByValuationReqId(this.valuationRequestId).subscribe(
        (res) => {
          this.certificateList = (res as any).data;
        }
      ));
  }

  loadOpinionLetterData() {
    this.sub.add(this.opinionLetterService.fetchAllOpinionLettersByValuationReqId(+this.route.snapshot.paramMap.get('id'))
      .subscribe(
        (res) => {
          this.opinionLetterList = (res as any).data;
        }
      ));
  }


  openOpinoinLetter(letter) { 
    this.selectedLetter = letter.content;

    this.notificationDetails.subject = Constant.STAMP_DUTY_LETTER_CREATED;
    this.notificationDetails.valuationRequestId = this.route.snapshot.paramMap.get('id');
    console.log(this.notificationDetails.valuationRequestId);
    this.notificationService.updateReleventNotification(this.notificationDetails).subscribe();
  }

  obtainUserData() {
    this.userdata = JSON.parse(atob(sessionStorage.getItem('userinfo')));

    for (let role of this.userdata.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.userrolecode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.userrolecode = Constant.ROLE_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.userrolecode = Constant.ROLE_DEPUTY_COMMISSIONER;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.userrolecode = Constant.ROLE_ACCESSOR;
        break;
      }
    }
  }

  checkCommisionerRole() {
    if (this.userrolecode === Constant.ROLE_COMMISSIONER) {
      return true;
    } else {
      return false;
    }
  }

  checkDCommissionerRole(){
    if (this.userrolecode === Constant.ROLE_DEPUTY_COMMISSIONER) {
      return true;
    } else {
      return false;
    }
  }

  checkAccessorRole() {
    if (this.userrolecode === Constant.ROLE_ACCESSOR) {
      return true;
    } else {
      return false;
    }
  }

  openCertificate() {
    this.barCodeEelement = document.getElementsByClassName('barcode');
    this.barCodeImage = this.barCodeEelement[0].innerHTML;
    // this.barCodeImage = this.barCodeImage.replace("height=\"142px\"", "height=\"50px\"");
    this.barCodeImage = this.barCodeImage.replace("height=\"142px\"", "height=\"1px\"");
    this.certificateContent = this.certificateContent.replace("$22@", this.barCodeImage);
    this.openCertificateFlag = true;

    // https://www.npmjs.com/package/angularx-qrcode/v/1.6.4
    // this.qrCodeEelement = document.getElementsByClassName('qrcode');
    // this.qrCodeImage = this.qrCodeEelement[0].innerHTML;
    // this.qrCodeImage = this.qrCodeImage.replace("height=\"100%\"", "height=\"80px\"");
    // if (this.qrCode == "null" || this.qrCode == "") {
      // this.certificateContent = this.certificateContent.replace("$26@", " ");
    // } else {
    //   this.certificateContent = this.certificateContent.replace("$26@", this.qrCodeImage);
    // }

    this.qrCodeEelement = document.getElementsByClassName('qrcode');
    this.qrCodeImage = this.qrCodeEelement[0].outerHTML;
    this.certificateContent = this.certificateContent.replace("$26@", this.qrCodeImage);
    this.openCertificateFlag = true;
  }

  cancelCertificate() {
    this.openCertificateFlag = false;
  }

  openEditCertificate(certificate: Certificate) {
    this.editCertificate = certificate;
    this.editCertificateFlag = true;
  }

  cancelEditCertificate() {
    this.editCertificate = null;
    this.editCertificateFlag = false;
  }

  saveCertificate() {
    this.openCertificateFlag = false;
    this.certificate.valuationReqId = this.valuationRequestId;
    this.certificate.content = this.certificateContent;
    this.certificate.name = "Stamp Duty Certificate-V." + this.certificateList.length;
    this.certificateService.saveCertificate(this.certificate).subscribe(
      (res) => {
        this.loadCertificateData();
        this.openCertificateFlag = false;

        // **** SEND NOTIFICATIONS - START ****
        this.valuationRequestService.findByValuationRequestId(this.valuationRequestId).subscribe(
          data => {
            this.deedNatureValue = data.data.deedNature;
            this.assessorUserId = data.data.accessorId;
            this.dComUserId = data.data.dcommissionerId;
            this.comUserId = data.data.commissionerId;
            this.notaryUserId = data.data.user_id;

            if (this.currentUserRoleCode == Constant.ROLE_ACCESSOR) {
              this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
              this.notificationModel.deedNature = this.deedNatureValue;
              this.notificationModel.url = this.route.snapshot.paramMap.get('id');
              this.notificationModel.subject = Constant.STAMP_DUTY_CERTIFICATE_CREATED;
              this.notificationModel.userId = this.comUserId;

              this.notificationService.createNotification(this.notificationModel).subscribe(
                data => {
                  console.log(data);

                  // *** SEND SYSTEM NOTIFICATION TO DEP. COMMISSIONER - START ***
                  this.notificationModel.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
                  this.notificationModel.deedNature = this.deedNatureValue;
                  this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                  this.notificationModel.subject = Constant.STAMP_DUTY_CERTIFICATE_CREATED;
                  this.notificationModel.userId = this.dComUserId;

                  this.notificationService.createNotification(this.notificationModel).subscribe(
                    data => {
                      console.log(data);
                    }
                  )
                  // *** SEND SYSTEM NOTIFICATION TO DEP. COMMISSIONER - END ***

                }
              )
            }
          }
        )
        // **** SEND NOTIFICATIONS - END ****

      }
    );
  }

  saveOpinionLetter() {
    this.opinionLetter.valuationReqId = +this.route.snapshot.paramMap.get('id');
    this.opinionLetter.content = this.opinionLetterHeader + this.opinionLetterContent;
    this.opinionLetter.name = "Opinion Letter - v." + this.opinionLetterList.length;
    this.opinionLetterService.saveOpinionLetter(this.opinionLetter).subscribe(
      (res) => {
        this.loadOpinionLetterData();

        // **** SEND NOTIFICATIONS - START ****
        this.valuationRequestService.findByValuationRequestId(this.valuationRequestId).subscribe(
          data => {
            this.deedNatureValue = data.data.deedNature;
            this.assessorUserId = data.data.accessorId;
            this.dComUserId = data.data.dcommissionerId;
            this.comUserId = data.data.commissionerId;
            this.notaryUserId = data.data.user_id;


            if (this.currentUserRoleCode == Constant.ROLE_ACCESSOR) {
              this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
              this.notificationModel.deedNature = this.deedNatureValue;
              this.notificationModel.url = this.route.snapshot.paramMap.get('id');
              this.notificationModel.subject = Constant.STAMP_DUTY_LETTER_CREATED;
              this.notificationModel.userId = this.comUserId;
              this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

              this.notificationService.createNotification(this.notificationModel).subscribe(
                data => {
                  console.log(data);

                  // *** SEND SYSTEM NOTIFICATION TO DEP. COMMISSIONER - START ***
                  this.notificationModel.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
                  this.notificationModel.deedNature = this.deedNatureValue;
                  this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                  this.notificationModel.subject = Constant.STAMP_DUTY_LETTER_CREATED;
                  this.notificationModel.userId  = this.dComUserId;
                  this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                  this.notificationService.createNotification(this.notificationModel).subscribe(
                    data => {
                      console.log(data);
                    }
                  )
                  // *** SEND SYSTEM NOTIFICATION TO DEP. COMMISSIONER - END ***

                }
              )
            }
          }
        )
        // **** SEND NOTIFICATIONS - END ****
      }
    );
  }

  setCertificateState(status: string){
    this.status = status;
    switch(status){
      case 'FURTHER': this.statusWord = "get Futher Requirement";
                      break;

      case 'REJECTED' : this.statusWord = "Reject";
                        break;

      case 'HOLD' : this.statusWord = "Hold";
                        break;

      case 'APPROVED' : this.statusWord = "Approve";
                        break;
    }
    this.notificationDetails.subject = Constant.STAMP_DUTY_CERTIFICATE_CREATED;
    this.notificationDetails.valuationRequestId = this.route.snapshot.paramMap.get('id');
    this.notificationService.updateReleventNotification(this.notificationDetails).subscribe();
  }

  updateEditCertificate() {
    this.editCertificate.status = this.status;
    this.certificateService.updateCertificate(this.editCertificate).subscribe(
      (res) => {

        if(!res.data){
          this.loadCertificateData();
          this.notifier.notify('error', (res as any).message);
        }else{
          this.loadCertificateData();
          this.editCertificateFlag = false;
          if (this.status == "APPROVED") {
            // **** SEND NOTIFICATIONS - START ****
            this.valuationRequestService.findByValuationRequestId(+this.route.snapshot.paramMap.get('id')).subscribe(
              data => {
                this.deedNatureValue = data.data.deedNature;
                this.assessorUserId = data.data.accessorId;
                this.dComUserId = data.data.dcommissionerId;
                this.comUserId = data.data.commissionerId;
                this.notaryUserId = data.data.user_id;


                  this.notificationModel.userRoleCode = Constant.ROLE_ACCESSOR;
                  this.notificationModel.deedNature = this.deedNatureValue;
                  this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                  this.notificationModel.subject = Constant.CERTIFICATE_APPROVED_BY_COMMISSIONER;
                  this.notificationModel.userId = this.assessorUserId;
                  this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                  this.notificationService.createNotification(this.notificationModel).subscribe(
                    data => {
                      console.log(data);

                      this.notificationModel.userRoleCode = Constant.ROLE_NOTARY;
                      this.notificationModel.deedNature = this.deedNatureValue;
                      this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                      this.notificationModel.subject = Constant.CERTIFICATE_APPROVED_BY_COMMISSIONER;
                      this.notificationModel.userId = this.notaryUserId;
                      this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                      this.notificationService.createNotification(this.notificationModel).subscribe(
                        data => {
                          console.log(data);

                          // *** SEND EMAIL NOTIFICATIONS TO NOTARY - START ***
                          this.notificationEmailSendModel.valuationRequestId = +this.route.snapshot.paramMap.get('id');
                          this.notificationService.sendEmailNotification(this.notificationEmailSendModel).subscribe(
                            data => {
                              console.log(data);
                            }
                          )
                          // *** SEND EMAIL NOTIFICATIONS TO NOTARY - END ***
                        }
                      )
                      this.notifier.notify('success', 'Certificate Successfully Approved');
                    }
                  )


              }
            )
            // **** SEND NOTIFICATIONS - END ****

          }
        }
      }
    );
  }

  // numberWordConvert(num) {
  //   var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
  //   var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];


  //   if ((num = num.toString()).length > 9) return 'overflow';
  //   var n = [];
  //   n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  //   if (!n) return; var str = '';
  //   str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
  //   str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
  //   str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
  //   str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
  //   str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
  //   return str;

  // }


  numberWordConvert(num) {
    var bigNumArry = new Array('', ' Thousand', ' Million', ' Billion', ' Trillion', ' Quadrillion', ' Quintillion');

    var output = '';
    var numString = num+"";
    var finlOutPut = new Array();

    if (numString == '0' || numString == '00') {
      return 'Zero';
    }
    var i = numString.length;
    i = i - 1;

    //cut the number to grups of three digits and add them to the Arry
    while (numString.length > 3) {
      var triDig = new Array(3);
      triDig[2] = numString.charAt(numString.length - 1);
      triDig[1] = numString.charAt(numString.length - 2);
      triDig[0] = numString.charAt(numString.length - 3);

      var varToAdd = triDig[0] + triDig[1] + triDig[2];
      finlOutPut.push(varToAdd);
      i--;
      numString = numString.substring(0, numString.length - 3);
    }
    finlOutPut.push(numString);
    finlOutPut.reverse();

    //conver each grup of three digits to english word
    //if all digits are zero the triConvert
    //function return the string "dontAddBigSufix"
    for (let j = 0; j < finlOutPut.length; j++) {
      finlOutPut[j] = this.triConvert(parseInt(finlOutPut[j], 10));
    }

    var bigScalCntr = 0; //this int mark the million billion trillion... Arry

    for (let b = finlOutPut.length - 1; b >= 0; b--) {
      if (finlOutPut[b] != "dontAddBigSufix") {
        finlOutPut[b] = finlOutPut[b] + bigNumArry[bigScalCntr] +'';
        bigScalCntr++;
      } else {
        //replace the string at finlOP[b] from "dontAddBigSufix" to empty String.
        finlOutPut[b] = ' ';
        bigScalCntr++; //advance the counter
      }
    }

    //convert The output Arry to , more printable string
    for (let n = 0; n < finlOutPut.length; n++) {
      output += finlOutPut[n];
    }

    return output; //print the output
  }

  //simple function to convert from numbers to words from 1 to 999
  triConvert(num) {
    var ones = new Array('', ' One', ' Two', ' Three', ' Four', ' Five', ' Six', ' Seven', ' Eight', ' Nine', ' Ten', ' Eleven', ' Twelve', ' Thirteen', ' Fourteen', ' Fifteen', ' Sixteen', ' Seventeen', ' Eighteen', ' Nineteen');
    var tens = new Array('', '', ' Twenty', ' Thirty', ' Forty', ' Fifty', ' Sixty', ' Seventy', ' Eighty', ' Ninety');
    var hundred = ' Hundred';
    var output = '';
    var numString = num.toString();

    if (num == 0) {
      return 'dontAddBigSufix';
    }
    //the case of 10, 11, 12 ,13, .... 19
    if (num < 20) {
      output = ones[num];
      return output;
    }

    //100 and more
    if (numString.length == 3) {
      output = ones[parseInt(numString.charAt(0))] + hundred;
      if(ones[parseInt(numString.charAt(1) + numString.charAt(2))]) {
        output += ones[parseInt(numString.charAt(1) + numString.charAt(2))];
        return output;
      }

      output += tens[parseInt(numString.charAt(1))];
      output += ones[parseInt(numString.charAt(2))];
      return output;
    }

    output += tens[parseInt(numString.charAt(0))];
    output += ones[parseInt(numString.charAt(1))];

    return output;
  }

  get f() {
    return this.rejectForm.controls;
  }

  get f1() {
    return this.furtherRequirementForm.controls;
  }

  openPopupMessage(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });

    this.rejectForm = this.formBuilder.group({
      remarkTextarea: ['', [Validators.required]]
    });

    this.furtherRequirementForm = this.formBuilder.group({
      furtherRequirementTextarea: ['', [Validators.required]]
    });
  }

  /* REJECT APPLICATION - START */
  openRejectPopUp() {
    this.showDynamicGrid = true;
    this.openPopupMessage(this.enterRejectRemark);
  }

  rejectValuationRequest() {
    this.rejectModel.valuationId = this.route.snapshot.paramMap.get('id');
    this.userName = this.userdata.firstName + " " + this.userdata.lastName;
    this.rejectModel.userName = this.userName;
    this.rejectModel.userRole = this.currentUserRoleCode;
    this.rejectModel.remark = this.rejectForm.get('remarkTextarea').value;
    this.rejectModel.furtherRequirementPosition = "CERTIFICATE";
    this.rejectModel.uploadedFile = this.rejectFile;

    this.valuationRequestService.rejectApplication(this.rejectModel).subscribe(
      data => {
        this.router.navigate(['dashboard-internal-user']);
        this.notifier.notify('success', 'Application Rejected');

        // **** SEND NOTIFICATIONS - START ****
        this.valuationRequestService.findByValuationRequestId(+this.route.snapshot.paramMap.get('id')).subscribe(
          data => {
            this.deedNatureValue = data.data.deedNature;
            this.assessorUserId = data.data.accessorId;
            this.dComUserId = data.data.dcommissionerId;
            this.comUserId = data.data.commissionerId;
            this.notaryUserId = data.data.user_id;

            this.modelReset();
              if (this.currentUserRoleCode == Constant.ROLE_ACCESSOR) {
                this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
                this.notificationModel.deedNature = this.deedNatureValue;
                this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                this.notificationModel.subject = Constant.APPLICATION_REJECTED;
                this.notificationModel.userId = this.comUserId;
                this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                this.notificationService.createNotification(this.notificationModel).subscribe(
                  data => {
                    console.log(data);

                    //// *** SEND NOTIFICATION TO DEP. COMMISSIONER - START ***
                    // this.notificationModel.userRoleCode = Constant.ROLE_ACCESSOR;
                    // this.notificationModel.deedNature = this.deedNatureValue;
                    // this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                    // this.notificationModel.dataStatus = "ACTIVE";

                    // this.valuationRequestService.createNotification(this.notificationModel).subscribe(
                    //   data => {
                    //     console.log(data);
                    //   }
                    // )
                    //// *** SEND NOTIFICATION TO DEP. COMMISSIONER - END ***

                  }
                )
              } else if (this.currentUserRoleCode == Constant.ROLE_DEPUTY_COMMISSIONER) {
                this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
                this.notificationModel.deedNature = this.deedNatureValue;
                this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                this.notificationModel.subject = Constant.APPLICATION_REJECTED;
                this.notificationModel.userId = this.comUserId;
                this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                this.notificationService.createNotification(this.notificationModel).subscribe(
                  data => {
                    console.log(data);
                  }
                )
              } else if (this.currentUserRoleCode == Constant.ROLE_COMMISSIONER) {
                this.notificationModel.userRoleCode = Constant.ROLE_NOTARY;
                this.notificationModel.deedNature = this.deedNatureValue;
                this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                this.notificationModel.subject = Constant.APPLICATION_REJECTED;
                this.notificationModel.userId = this.notaryUserId;
                this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                this.notificationService.createNotification(this.notificationModel).subscribe(
                  data => {
                    console.log(data);
                    this.notificationEmailSendModel.valuationRequestId = +this.route.snapshot.paramMap.get('id');
                    this.notificationService.sendEmailNotification(this.notificationEmailSendModel).subscribe(
                      data => {
                        console.log(data);
                      }
                    )
                  }
                )
              }
            }
          )
          // **** SEND NOTIFICATIONS - END ****

      }
    )

    this.modalService.dismissAll();
  }

  getRejectRemark() {
    return this.rejectForm.get('remarkTextarea').value;
  }

  uploadChangeReject(event) {
    this.files = event.target.files;
    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      if (file.type === "image/png" || file.type === "image/jpeg") {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          this.imgURL1 = reader.result;
          this.documentUploaded1 = 'image';
          this.images.push(_event.currentTarget.result);
          this.originalImages.push(_event.currentTarget.result);
          this.rejectFile = file;
        };
        this.names.push(file.name);
      } else if (file.type == "application/pdf" ) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          this.imgURL1 = "assets/images/adobe-pdf-icon.png";
          this.documentUploaded1 = 'pdf';
          this.rejectFile = file;
        }
      } else {
        this.notifier.notify('error', 'File type not allowed here');
        event.srcElement.value = null;
      }
    }
  }
  /* REJECT APPLICATION - END */

  /* REQUEST FURTHER REQUIREMENT - START */
  furtherReqValuationRequest() {
    this.showDynamicGrid = true;
    this.openPopupMessage(this.enterRemark);
  }

  uploadChangeFurtherRequirement(event) {
    this.files = event.target.files;
    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      if (file.type === "image/png" || file.type === "image/jpeg") {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          this.imgURL2 = reader.result;
          this.documentUploaded = 'image';
          this.images.push(_event.currentTarget.result);
          this.originalImages.push(_event.currentTarget.result);
          this.furtherRequirementFile = file;
        };
        this.names.push(file.name);
      } else if (file.type == "application/pdf" ) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          this.imgURL2 = "assets/images/adobe-pdf-icon.png";
          this.images.push("assets/images/adobe-pdf-icon.png");
          this.documentUploaded = 'pdf';
          this.furtherRequirementFile = file;
        }
      } else {
        this.notifier.notify('error', 'File type not allowed');
        event.srcElement.value = null;
      }
    }
  }

  requestFurtherReqRemark() {
    this.furtherRequirementModel.valuationId = this.route.snapshot.paramMap.get('id');
    this.userName = this.userdata.firstName + " " + this.userdata.lastName;
    this.furtherRequirementModel.userName = this.userName;
    this.furtherRequirementModel.userRole = this.currentUserRoleCode;
    this.furtherRequirementModel.remark = this.furtherRequirementForm.get('furtherRequirementTextarea').value;
    this.furtherRequirementModel.furtherRequirementPosition = "CERTIFICATE";
    this.furtherRequirementModel.uploadedFile = this.furtherRequirementFile;


    this.valuationRequestService.requestFurtherRequirement(this.furtherRequirementModel).subscribe(
      data => {
        console.log(data);
        if (data.data === true) {
          this.notifier.notify('success', 'Successfully sent for Further Requirement');
          this.router.navigate(['/dashboard-internal-user']);

          // **** SEND NOTIFICATIONS - START ****
          this.valuationRequestService.findByValuationRequestId(+this.route.snapshot.paramMap.get('id')).subscribe(
            data => {
              this.deedNatureValue = data.data.deedNature;
              this.assessorUserId = data.data.accessorId;
              this.dComUserId = data.data.dcommissionerId;
              this.comUserId = data.data.commissionerId;
              this.notaryUserId = data.data.user_id;
              this.modelReset();
              // ** SEND SYSTEM NOTIFICATION TO NOTARY - START **
              // this.notificationModel.userRoleCode = Constant.ROLE_NOTARY;
              // this.notificationModel.deedNature = this.deedNatureValue;
              // this.notificationModel.url = this.route.snapshot.paramMap.get('id');
              // this.notificationModel.subject = Constant.REQUESTED_FOR_FURTHER_REQUIREMENT;

              // this.notificationService.createNotification(this.notificationModel).subscribe(
              //   data => {
              //     console.log(data);
              //   }
              // )
              // ** SEND SYSTEM NOTIFICATION TO NOTARY - END **


              if (this.currentUserRoleCode == Constant.ROLE_COMMISSIONER) {
                this.notificationModel.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
                this.notificationModel.deedNature = this.deedNatureValue;
                this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                this.notificationModel.subject = Constant.REQUESTED_FOR_FURTHER_REQUIREMENT;
                this.notificationModel.userId = this.dComUserId;
                this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                this.notificationService.createNotification(this.notificationModel).subscribe(
                  data => {
                    console.log(data);

                    // *** SEND SYSTEM NOTIFICATION TO DEP. COMMISSIONER - START ***
                    this.notificationModel.userRoleCode = Constant.ROLE_ACCESSOR;
                    this.notificationModel.deedNature = this.deedNatureValue;
                    this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                    this.notificationModel.subject = Constant.REQUESTED_FOR_FURTHER_REQUIREMENT;
                    this.notificationModel.userId = this.assessorUserId;
                    this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                    this.notificationService.createNotification(this.notificationModel).subscribe(
                      data => {
                        console.log(data);
                      }
                    )
                    // *** SEND SYSTEM NOTIFICATION TO DEP. COMMISSIONER - END ***

                  }
                )
              } else if (this.currentUserRoleCode == Constant.ROLE_DEPUTY_COMMISSIONER) {
                this.notificationModel.userRoleCode = Constant.ROLE_ACCESSOR;
                this.notificationModel.deedNature = this.deedNatureValue;
                this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                this.notificationModel.subject = Constant.REQUESTED_FOR_FURTHER_REQUIREMENT;
                this.notificationModel.userId = this.assessorUserId;
                this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                this.notificationService.createNotification(this.notificationModel).subscribe(
                  data => {
                    console.log(data);
                  }
                )
              }
            }
          )
          // **** SEND NOTIFICATIONS - END ****
        }
      }
    )


    this.modalService.dismissAll();
  }
  /* REQUEST FURTHER REQUIREMENT - END */

  closeModal() {
    this.modalService.dismissAll();
    this.modelReset();
  }

  modelReset(){
    this.furtherRequirementForm.reset();
    this.rejectForm.reset();
    this.furtherRequirementFile = null;
    this.rejectFile = null;
    this.names =[];
    this.documentUploaded = "";
    this.documentUploaded1 = "";
  }

  redirectBack(){
    this.onPageCountChanged.emit(14);
  }

  // send further details
  sendFutherDetails(){
    this.valuationRequestService.sendFurtherDetails(parseInt(this.route.snapshot.paramMap.get('id'))).subscribe(data=>{
      if(data.data === true){
        this.notifier.notify('success', 'Successfully sent for Further Requirement Details');
        this.frButtonStatus = true;
        this.valuationRequestService.findByValuationRequestId(this.valuationRequestId).subscribe(
          data => {
            this.deedNatureValue = data.data.deedNature;
            this.assessorUserId = data.data.accessorId;
            this.dComUserId = data.data.dcommissionerId;
            this.comUserId = data.data.commissionerId;
            this.notaryUserId = data.data.user_id;


                    // *** SEND SYSTEM NOTIFICATION TO ACCESSOR - START ***
            this.notificationModel.userRoleCode = Constant.ROLE_ACCESSOR;
            this.notificationModel.deedNature = this.deedNatureValue;
            this.notificationModel.url = this.route.snapshot.paramMap.get('id');
            this.notificationModel.subject = Constant.SEND_FURTHER_REQUIREMENT;
            this.notificationModel.userId = this.assessorUserId;
            this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

            this.notificationService.createNotification(this.notificationModel).subscribe(
              data => {
                console.log(data);

                        // *** SEND SYSTEM NOTIFICATION TO DEP. COMMISSIONER - START ***
                  this.notificationModel.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
                  this.notificationModel.deedNature = this.deedNatureValue;
                  this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                  this.notificationModel.subject = Constant.SEND_FURTHER_REQUIREMENT;
                  this.notificationModel.userId = this.dComUserId;
                  this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                  this.notificationService.createNotification(this.notificationModel).subscribe(
                  data => {
                    console.log(data);

                   // *** SEND SYSTEM NOTIFICATION TO DEP. COMMISSIONER - START ***
                  this.notificationModel.userRoleCode = Constant.ROLE_COMMISSIONER;
                  this.notificationModel.deedNature = this.deedNatureValue;
                  this.notificationModel.url = this.route.snapshot.paramMap.get('id');
                  this.notificationModel.subject = Constant.SEND_FURTHER_REQUIREMENT;
                  this.notificationModel.userId = this.comUserId;
                  this.notificationModel.categoryStatus = Constant.NOTIFICATION_CATEGORY_OPINION

                  this.notificationService.createNotification(this.notificationModel).subscribe(
                     data => {
                      console.log(data);
                  }
            )
            // *** SEND SYSTEM NOTIFICATION TO DEP. COMMISSIONER - END ***
              }
            )
            // *** SEND SYSTEM NOTIFICATION TO DEP. COMMISSIONER - END ***
              }
            )
            // *** SEND SYSTEM NOTIFICATION TO ACCESSOR - END ***
              })
      }else{
        this.notifier.notify('error', 'Further Requirement Details are not send');
        this.frButtonStatus = false;
      }
    })
  }

  public checkStatus(type:boolean): void{
    this.status1.emit(type);
  }

  public refreshComponent(){
    this.ngOnInit();
  }
}
