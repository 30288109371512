import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import Swal from 'sweetalert2';
import { NotifierService } from 'angular-notifier';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PDFDocumentProxy, PDFProgressData } from 'ng2-pdf-viewer';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';

import { Constant } from 'src/app/util/constant';
import { DocumentUploadService } from 'src/app/service/document-upload/document-upload.service';
import { DocumentUploadOneComponent } from 'src/app/component/document/document-upload/document-upload-one/document-upload-one.component';
import { DocumentUploadTwoComponent } from 'src/app/component/document/document-upload/document-upload-two/document-upload-two.component';
import { DocumentUploadThreeComponent } from 'src/app/component/document/document-upload/document-upload-three/document-upload-three.component';
import { DocumentUploadFourComponent } from 'src/app/component/document/document-upload/document-upload-four/document-upload-four.component';
import { DocumentUploadFiveComponent } from 'src/app/component/document/document-upload/document-upload-five/document-upload-five.component';
import { DocumentUploadSixComponent } from 'src/app/component/document/document-upload/document-upload-six/document-upload-six.component';
import { DocumentUploadSevenComponent } from 'src/app/component/document/document-upload/document-upload-seven/document-upload-seven.component';
import { FileService } from 'src/app/service/file/file.service';
import { RimApplicationService } from 'src/app/service/reimbursement/rim-application.service';

@Component({
  selector: 'app-rim-document',
  templateUrl: './rim-document.component.html',
  styleUrls: ['./rim-document.component.scss']
})
export class RimDocumentComponent implements OnInit {

  @Output() onPageTypeChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('documentUploadLocalAuthorityLetter', {static: false}) documentUploadLocalAuthorityLetter: DocumentUploadOneComponent;
  @ViewChild('documentUploadLandRegisterLetter', {static: false}) documentUploadLandRegisterLetter: DocumentUploadTwoComponent;
  @ViewChild('documentUploadPaymentVoucher', {static: false}) documentUploadPaymentVoucher: DocumentUploadThreeComponent;
  @ViewChild('documentUploadSummary', {static: false}) documentUploadSummary: DocumentUploadFourComponent;
  @ViewChild('documentUploadLandRegisterCertificate', {static: false}) documentUploadLandRegisterCertificate: DocumentUploadFiveComponent;
  @ViewChild('documentUploadFurtherRequirementLetter', {static: false}) documentUploadFurtherRequirementLetter: DocumentUploadSixComponent;
  @ViewChild('documentUploadOther', {static: false}) documentUploadOther: DocumentUploadSevenComponent;

  reimbursementFileNumber: string;
  filescount: number;

  selectPaths = [];
  documents : any = [];
  otherSelectedPaths = [];
  selectPathsCollection = [];
  errorList = [];
  docTemp=[];

  userData: any = [];
  userRoleCode: string;

  showToCommissionerAndDeputyCommissioner: boolean;
  showToAssessor: boolean;
  showToTaxOfficer: boolean;
  showToAccountant: boolean;
  filesexist : boolean;

  dataDone = false;
  noDocs = false;
  previewFileData: any;

  constructor(
    private notifier: NotifierService,
    private documentUploadService: DocumentUploadService,
    public rimService: RimApplicationService,
    public modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private fileService: FileService,
    public sanitizer: DomSanitizer
  ) {
    this.showToCommissionerAndDeputyCommissioner = false;
    this.showToAssessor = false;
    this.showToTaxOfficer = false;
    this.showToAccountant = false;
    this.filesexist = false;
    
  }

  ngOnInit() {
    this.reimbursementFileNumber = localStorage.getItem('rimFileNumberFormat');
    this.obtainUserData();
    this.check();
  }

  obtainUserData() {
    this.userData = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    for (let role of this.userData.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.userRoleCode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_COMMISSIONER;
        this.showToCommissionerAndDeputyCommissioner = true;
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
        this.showToCommissionerAndDeputyCommissioner = true;
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.userRoleCode = Constant.ROLE_ACCESSOR;
        this.showToAssessor = true;
        break;
      } else if (role.code === Constant.ROLE_TAX_OFFICER) {
        this.userRoleCode = Constant.ROLE_TAX_OFFICER;
        this.showToTaxOfficer = true;
        break;
      } else if (role.code === Constant.ROLE_ACCOUNTANT) {
        this.userRoleCode = Constant.ROLE_ACCOUNTANT;
        this.showToAccountant = true;
        break;
      }
    }
  }

  check(){
    var id = +sessionStorage.getItem('rimId');
     this.rimService.getUploadedFilesByReimbursementId(id).subscribe((data) => {
      this.documents = data.data;
      this.filescount = this.documents.length;
      const result = this.documents;
        this.filesexist = true;
    }, error => {
      this.filesexist = false;
    });
    
  }

  setLocalAuthorityLetterDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setLandRegisterLetterDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setPaymentVoucherDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setSummaryDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setLandRegisterCertificateDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setFurtherRequirementLetterDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setOtherDocumentArray(documents: File[]) {
    this.selectPaths.push(documents);
  }

  setEveryChildComponentUploadingDocuments() {
    this.documentUploadLocalAuthorityLetter.outputDocumentsOneSet();
    this.documentUploadLandRegisterLetter.outputDocumentsTwoSet();
    this.documentUploadPaymentVoucher.outputDocumentsThreeSet();
    this.documentUploadSummary.outputDocumentsFourSet();
    this.documentUploadLandRegisterCertificate.outputDocumentsFiveSet();
    this.documentUploadFurtherRequirementLetter.outputDocumentsSixSet();
    this.documentUploadOther.outputDocumentsSevenSet();
  }

  checkDocuments() {
    this.errorList = [];
    if (this.documentUploadLocalAuthorityLetter.filesList1.length === 0) {
      this.errorList.push('Request letter from Local Authority');
    }

    if (this.documentUploadLandRegisterLetter.filesList2.length === 0) {
      this.errorList.push('Letter from Land Register (Per Year)');
    }

    if (this.documentUploadPaymentVoucher.filesList3.length === 0) {
      this.errorList.push('Payment Voucher (Per Year)');
    }

    if (this.documentUploadSummary.filesList4.length === 0) {
      this.errorList.push('Summary (Per Month)');
    }

    if (this.documentUploadLandRegisterCertificate.filesList5.length === 0) {
      this.errorList.push('Certificate of Land Register (Per Month)');
    }

    if (this.errorList.length === 0) {
      this.setEveryChildComponentUploadingDocuments();
      this.otherSelectedPaths = this.selectPaths;
      this.saveDocuments();
      this.onPageTypeChanged.emit('assign-tax-officer');
    } else {
      const re = /,/gi;
      const errormsg = this.errorList.toString().replace(re, '<br/>');

      Swal.fire({
        title: 'Please upload following required documents to proceed',
        icon: 'error',
        html: '<div style="color: red;text-align: left;">' + errormsg + '</div>',
        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText:
          'OK',
      });
    }
  }

  saveDocuments() {
    this.selectPathsCollection.push(this.otherSelectedPaths[0]);
    this.selectPathsCollection.push(this.otherSelectedPaths[1]);
    this.selectPathsCollection.push(this.otherSelectedPaths[2]);
    this.selectPathsCollection.push(this.otherSelectedPaths[3]);
    this.selectPathsCollection.push(this.otherSelectedPaths[4]);
    this.selectPathsCollection.push(this.otherSelectedPaths[5]);
    this.selectPathsCollection.push(this.otherSelectedPaths[6]);

    this.docTemp.push(this.selectPathsCollection);
    sessionStorage.setItem('docLinks', JSON.stringify(this.docTemp));

    this.documentUploadService.uploadReimbursementApplicationDocuments(this.selectPathsCollection, sessionStorage.getItem('rimId')).subscribe(
      res => {}, err => {
        this.notifier.notify('error', 'Document upload failed');
      }
    );
  }

  changeComponent() {
    if (this.showToCommissionerAndDeputyCommissioner || this.showToTaxOfficer) {
      this.onPageTypeChanged.emit('payble-nonpayable');
    } else {
      this.checkDocuments();
    }
  }

  // RETRIEVE DOCUMENTS - START
  onProgress(progressData: PDFProgressData) {
    if (progressData.loaded == progressData.total) {
      this.spinner.hide();
    }
  }

  previewFile(title) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadDocumentsByReimbursementApplicationId(sessionStorage.getItem('rimId'), title).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(() => {
          this.spinner.hide();
        }, 2000);

      } else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }
  // RETRIEVE DOCUMENTS - END

  public redirectPreviousComponent(): void {
    this.onPageTypeChanged.emit("application")
  }

}
