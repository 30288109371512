import { Component, OnInit } from '@angular/core';
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ReportServiceService} from "../service/report-service.service";
import {NotifierService} from "angular-notifier";
import * as XLSX from "xlsx";

@Component({
  selector: 'app-notary-details-report',
  templateUrl: './notary-details-report.component.html',
  styleUrls: ['./notary-details-report.component.scss']
})
export class NotaryDetailsReportComponent implements OnInit {


  // for allocated regional branches
  selectedItemsARB = [];
  dropdownListARB= [];
  dropdownSettingsARB: IDropdownSettings = {};

  // for attested date range
  fromDate: string = null;
  toDate: string = null;
  currentDate: string = null;

  // for deed no
  deedNo: string = null;
  notary: string = null;

  // report data
  public  reportDetails:any = [];
  public showPrint: boolean = false;
  public selectedDetails: any;

  constructor(
    public modalService: NgbModal,
    public reportService: ReportServiceService,
    private notifier: NotifierService
  ) { }

  ngOnInit() {

    // set up allocated regional branches dropdown
    this.dropdownSettingsARB = {
      singleSelection: false,
      idField: 'id',
      textField: 'office',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.get_all_allocated_branches();
  }

  /*************** DROP DOWN SERVICES ***************/
  // retrieve allocated branches data from service
  get_all_allocated_branches(){
    this.reportService.getAllAB().subscribe(value => {
      if(value.data.length > 0){
        this.dropdownListARB = value.data;
      }else{
        console.log("No Allocated Regional Branch return");
      }
    });
  }
  /*************** DROP DOWN SERVICES ***************/

  /*************** CAPTURE UI DATA ***************/
  captureSelectedDetails(): void {
    this.currentDate = new Date().toISOString().slice(0, 10);

    let lastDate = "";
    if(this.toDate == null || this.toDate == ""){
      lastDate = this.currentDate;
    }else {
      lastDate = this.toDate;
    }

   // var date_range = ((this.fromDate == null) && (this.toDate == null)) ? "All Date" : this.fromDate + ' to '+ this.toDate ;
    var date_range = ((this.fromDate == null || this.fromDate == "") && (this.toDate == null || this.toDate == "")) ? "All Date" : (this.fromDate == null) ? ' to ' + lastDate : this.fromDate + ' to ' + lastDate;

    // capture selected allocated regional branches
    var selectedARB = "";
    var selectedARB_Names = "";
    if(this.selectedItemsARB != null){
      this.selectedItemsARB.forEach(value => {
        selectedARB += value.id +",";
        selectedARB_Names += value.office + ", ";
      });
    } else {
      selectedARB = "";
      selectedARB_Names = "N/A";
    }

    this.selectedDetails = {
      selected_date: date_range,

      selectedN_names: this.notary,

      selectedARB_id: selectedARB,
      selectedARB_names: selectedARB_Names,

      selectedDeedNo_names: this.deedNo
    };
  }
  /*************** CAPTURE UI DATA ***************/

  /*************** GET REPORT DATA ***************/
  getReportData(): void {
    console.log("LAD " + this.selectedDetails.selectedARB_names);
    this.reportService.getNotaryDetailsReport(this.fromDate, this.toDate, this.notary, this.deedNo, this.selectedDetails.selectedARB_names).subscribe(value => {
      console.log(value);
      if(value.data.length > 0 ) {
        this.reportDetails = value;

      }else{
        this.notifier.notify('error', 'No records found!');
        console.log("No data found");
      }
    });
  }
  /*************** GET REPORT DATA ***************/

  /*************** GENERATE REPORT ***************/
  createPdfReport(): void {

    let printContents: any;
    let popupWin: any;
    printContents = document.getElementById('notaryDetailsReportContent').innerHTML;

    popupWin = window.open();
    popupWin.document.open();
    popupWin.document.write(`
    <html>
    <head>
        <title>Notary Details</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <!--<script src="../../../../assets/js/paged.js"></script>-->
        <!--<script src="../../../../assets/js/paged.polyfill.js"></script>-->
        <style>
          @page {
            size: A3 landscape;
            @bottom-right {
              content: "Page " counter(page) " of " counter(pages);
            }
            .page{
                page-break-after: always;
            }
          }

            body
            {
                margin: 10mm 10mm 0mm 10mm;

            }
            table{
              border: 1px solid #000;
              border-collapse: collapse;
            }
            tbody{
              page-break-before: always;
            }

            table th, table td
            {
                padding: 5px;
                margin: 0px;
                border: 1px solid #000;
            }
            .page{
                page-break-after: always;
            }

        </style>
    </head>
    <body onload="window.print();">
            ${printContents}
    </body>
    </html>`
    );
    popupWin.document.close();
  }
  /*************** GENERATE REPORT ***************/

  /*************** UI EVENT ***************/
  // event click on close button
  closeFurtherReqRemark() {
    this.modalService.dismissAll();
  }

  createExcel() {
    this.captureSelectedDetails();
    this.getReportData();
    this.showPrint = true;
    setTimeout(() => {
      var printContents = document.getElementById('excel_print');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(printContents);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Notary Dtls.');
      XLSX.writeFile(wb, 'Notary Details Report.xlsx');
    }, 1000);
  }

  generatePdfReport() {
    this.captureSelectedDetails();
    this.getReportData();
    this.showPrint = true;
    setTimeout(() => {
      this.createPdfReport();
    }, 1000);

  }

  onItemSelectARB(item: any) {
		// console.log(item);
	}
	onSelectAllARB(items: any) {
		// console.log(items);
	}
  /*************** UI EVENT ***************/
}
