import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { InspectionEducationTypeService } from 'src/app/service/inspection-master-data-service/inspection-education-type/inspection-education-type.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inspection-education-type',
  templateUrl: './inspection-education-type.component.html',
  styleUrls: ['./inspection-education-type.component.scss']
})
export class InspectionEducationTypeComponent implements OnInit,OnDestroy {

  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  createEducationTypeForm: FormGroup;
  updateEducationTypeForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  propertyTypeList: any =[];
  activationButton: boolean;
  public createEducationTypeModel = new InspectionCommonDataModel(null,'','','','');
  public updateEducationTypeModel = new InspectionCommonDataModel(null,'','','','');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;
  EducationTypes: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionEducationTypeService: InspectionEducationTypeService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createEducationTypeForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });
    this.updateEducationTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });

    // this.loadPropertyType();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }



  //create Education Type - start

  get f() { return this.createEducationTypeForm.controls; }

  setEducationTypeModelToSave(){
    this.createEducationTypeModel.name = this.createEducationTypeForm.get('name').value;
    this.createEducationTypeModel.description = this.createEducationTypeForm.get('description').value;
    this.createEducationTypeModel.code = this.createEducationTypeForm.get('code').value;
    this.createEducationTypeModel.dataStatus = "ACTIVE";
  }

    //save Education Type model
  saveEducationType(){
    if(this.createEducationTypeForm.valid){

      this.setEducationTypeModelToSave();
      this.sub.add(
        this.inspectionEducationTypeService.createEducationType(this.createEducationTypeModel).subscribe(data=>{
          this.notifier.notify('success', (data as any).message);
          this.updateTheTable();
          this.reset();
        })
        );
    }else{
      this.createEducationTypeForm.markAllAsTouched();
    }

  }

  reset(){
    this.createEducationTypeForm.reset()
  }
  //create Education Type - end

  //update Education Type - start

    //get update controllers
  get f1() { return this.updateEducationTypeForm.controls; }

    //set data To Education Type Type model
  setEducationType(education:any){
    this.updateEducationTypeForm.get('id').setValue(education.id);
    this.updateEducationTypeForm.get('name').setValue(education.name);
    this.updateEducationTypeForm.get('description').setValue(education.description);
    this.updateEducationTypeForm.get('code').setValue(education.code);

    if(education.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateEducationTypeModel.id = this.updateEducationTypeForm.get('id').value;
    this.updateEducationTypeModel.name = this.updateEducationTypeForm.get('name').value;
    this.updateEducationTypeModel.description = this.updateEducationTypeForm.get('description').value;
    this.updateEducationTypeModel.code = this.updateEducationTypeForm.get('code').value;

    if(this.activationButton == true){
      this.updateEducationTypeModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateEducationTypeModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateEducationType(){
    this.setDataToModel();
    this.sub.add(this.inspectionEducationTypeService.updateEducationType(this.updateEducationTypeModel.id, this.updateEducationTypeModel).subscribe(data=>{
      this.notifier.notify('success', (data as any).message);
      this.updateTheTable();
    }))
  }

  //update Education Type - end

//search and view table start
  pageable(i: number) {
    this.inspectionEducationTypeService.getEducationType((i-1),10).subscribe(res=>{
      this.EducationTypes = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionEducationTypeService.getEducationType(0, 10)
      .subscribe(
        (res) => {
          this.EducationTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search Education Type Type
    searchEducationType(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionEducationTypeService.searchEducationType(this.keyword,0,10).subscribe(res=>{
          this.EducationTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionEducationTypeService.deleteEducationType(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end
}
