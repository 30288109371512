export class assignTaxOfficer{
  province:number;
  district:number;
  localAuthority:number;
  taxOfficer:string;
  remark:string;

  constructor(
    province:number,
    district:number,
    localAuthority:number,
    taxOfficer:string,
    remark:string,){

      this.province = province;
      this.district = district;
      this.localAuthority = localAuthority;
      this.taxOfficer = taxOfficer;
      this.remark = remark;
  }
}
