import { Component, OnInit } from '@angular/core';
import { Chart } from 'node_modules/chart.js';
import {NotificationModelUpdate} from '../../../model/notification-model-update';
import {ValuationRequestCount} from '../../../model/valuation_request_count';
import {ReimbursementApplicationCreate} from '../../../model/rim-application-create';
import {DashboardService} from '../../../service/dashboard/dashboard.service';
import {AuthService} from '../../../auth/auth.service';
import {RimApplicationService} from '../../../service/reimbursement/rim-application.service';
import {Router} from '@angular/router';
import {AdminDashboardService} from '../../../service/admin-dashboard/admin-dashboard.service';
import {Constant} from '../../../util/constant';
import { RefundOpinionServiceService } from 'src/app/service/RefundOpinionService/refund-opinion-service.service';

@Component({
  selector: 'app-deputy-commisioner',
  templateUrl: './deputy-commisioner.component.html',
  styleUrls: ['./deputy-commisioner.component.scss']
})
export class DeputyCommisionerComponent implements OnInit {


  public notificationModelUpdate = new NotificationModelUpdate();
  public model = new ValuationRequestCount('', '', '');
  public reimbursementApplicationCreateModel = new ReimbursementApplicationCreate();

  refundNotificationCode = Constant.NOTIFICATION_CATEGORY_REFUND
  opinionNotificationCode = Constant.NOTIFICATION_CATEGORY_OPINION
  appealNotificationCode = Constant.NOTIFICATION_CATEGORY_APPEAL
  rimNotificationCode = Constant.NOTIFICATION_CATEGORY_RIM
  
  isTaxOfficerOrAccountant: boolean;
  userName: string;
  numberOfNotifications: number;
  notifications: any = [];

  userData: any = [];
  userRoleCode: string;
  showToAssessor: boolean;
  userId: any;

  bankDetailsList: any;
  bankTotalAmount: number = 0;
  fromDateTotalCollection: any = null;
  toDateTotalCollection: any = null;

  formYear: any = null;
  toYear: any = null;

  allApplications: any;

  constructor(
    private dashboardService: DashboardService,
    private authService: AuthService,
    private reimbursementApplicationservice: RimApplicationService,
    private router: Router,
    private adminDashboardService: AdminDashboardService,
    private refundService:RefundOpinionServiceService
  ) {
    this.showToAssessor = false;
  }


  ngOnInit() {

    this.userName = sessionStorage.getItem('username');
    this.listNotifications();
    this.obtainUserData();
    this.getUserPermision();
    sessionStorage.removeItem('deedType');


    // const myChart2 = new Chart('targetPercentage2', {
    //   type: 'horizontalBar',
    //   data: {
    //     labels: ['1', '2', '3'],
    //     datasets: [{
    //       label: 'Target Percentage',
    //       data: [50, 30, 70],
    //       backgroundColor: [
    //         '#7821C0',
    //         '#F8981F',
    //         '#A52465'
    //       ]
    //     }]
    //   },
    //   options: {
    //     scales: {
    //       xAxes: [{
    //         ticks: {
    //           beginAtZero: true,
    //           max: 100,
    //           fontColor: '#fff'
    //         },
    //         gridLines: {
    //           zeroLineColor: '#fff'
    //         }
    //       }],
    //       yAxes: [{
    //         display: false,
    //         gridLines: {
    //           zeroLineColor: '#fff'
    //         }
    //       }]
    //     },
    //     legend: {
    //       display: false
    //     }
    //   }
    // });
    //
    //
    // const marketValue2 = new Chart('marketValue2', {
    //   type: 'line',
    //   data: {
    //     labels: ['Date 1', 'Date 2', 'Date 3'],
    //     datasets: [
    //       {
    //         label: 'Date 1',
    //         data: [500, 700, 1200],
    //         borderColor: '#E5CF1A'
    //       },
    //       {
    //         label: 'Date 2',
    //         data: [0, 400, 900],
    //         borderColor: '#F376ED'
    //       },
    //       {
    //         label: 'Date 3',
    //         data: [100, 600, 300],
    //         borderColor: '#F3951F'
    //       }
    //     ]
    //   }
    //   ,
    //   options: {
    //     scales: {
    //       xAxes: [{
    //         ticks: {
    //           beginAtZero: true,
    //           fontColor: '#fff'
    //         },
    //         gridLines: {
    //           zeroLineColor: '#fff'
    //         }
    //       }],
    //       yAxes: [{
    //         ticks: {
    //           beginAtZero: true,
    //           fontColor: '#fff'
    //         },
    //         gridLines: {
    //           zeroLineColor: '#fff'
    //         }
    //       }]
    //     },
    //     legend: {
    //       display: false
    //     }
    //   }
    // });


  }


  obtainUserData() {
    this.userData = JSON.parse(atob(sessionStorage.getItem('userinfo')));
    this.model.userId = this.userData.id;
    this.userId = this.userData.id;
    for (let role of this.userData.roles) {
      if (role.code === Constant.ROLE_SUPER_ADMIN) {
        this.userRoleCode = Constant.ROLE_SUPER_ADMIN;
        break;
      } else if (role.code === Constant.ROLE_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_COMMISSIONER;
        this.loadSectionDetails();
        this.commonAll();
        break;
      } else if (role.code === Constant.ROLE_DEPUTY_COMMISSIONER) {
        this.userRoleCode = Constant.ROLE_DEPUTY_COMMISSIONER;
        this.loadSectionDetails();
        this.commonAll();
        break;
      } else if (role.code === Constant.ROLE_ACCESSOR) {
        this.userRoleCode = Constant.ROLE_ACCESSOR;
        this.showToAssessor = true;
        this.loadSectionDetails();
        this.commonAll();
        break;
      } else if (role.code === Constant.ROLE_TAX_OFFICER) {
        this.userRoleCode = Constant.ROLE_TAX_OFFICER;
        this.loadSectionDetails();
        // this.commonAll();
        break;
      } else if (role.code === Constant.ROLE_ACCOUNTANT) {
        this.userRoleCode = Constant.ROLE_ACCOUNTANT;
        this.loadSectionDetails();
        // this.commonAll();
        break;
      }
    }

  }

  allApplicationStartDate: any = null;
  allApplicationEndDate: any = null;
  allApplicationBranch: any = null;
  allApplicationTaxOfficer: any = null;

  dashboardSectionDetails: any;

  loadSectionDetails() {
    this.adminDashboardService.fetchSectionCount(this.userId, this.userRoleCode).subscribe(result => {
      this.dashboardSectionDetails = result;
    });
  }

  commonAll() {
    this.loadAllApplications();
    this.loadAllBarChartDetails();
    this.loadAllPieChartDetails();
    this.loadAllBankChartDetails();
  }

  commonAppeal() {
    this.loadAppealApplications();
    this.loadAppealBankChartDetails();
    this.loadAppealPieChartDetails();
    this.loadAppealPieChartDetails();
  }

  commonOpinion() {
    this.loadOpinionApplications();
    this.loadOpinionPieChartDetails();
    this.loadOpinionBankChartDetails();
    this.loadOpinionBarChartDetails();
  }


  commonRefund() {
    this.loadRefundApplications();
    this.loadRefundPieChartDetails();
  }

  applicationTitle: any = 'All';

  selectApplicationType(ev) {
    const value = ev.target.value;
    if (value === 'ALL') {
      this.applicationTitle = 'All';
      this.commonAll();
    } else if (value === 'OPINION') {
      this.applicationTitle = 'Opinion';
      this.commonOpinion();
    } else if (value === 'REFUND') {
      this.applicationTitle = 'Refund';
      this.commonRefund();
    } else if (value === 'REIMBURSEMENT') {
      //do something
    } else if (value === 'APPEAL') {
      this.applicationTitle = 'Appeal';
      this.commonAppeal();
    }
  }

  loadAppealApplications() {
    this.adminDashboardService.fetchAppealApplications(this.userId, this.userRoleCode, this.allApplicationStartDate, this.allApplicationEndDate, this.allApplicationBranch, this.allApplicationTaxOfficer).subscribe(result => {
      this.allApplications = result;
    });
  }

  loadAllApplications() {
    this.adminDashboardService.fetchAllApplications(this.userId, this.userRoleCode, this.allApplicationStartDate, this.allApplicationEndDate, this.allApplicationBranch, this.allApplicationTaxOfficer).subscribe(result => {
      this.allApplications = result;
    });
  }

  loadOpinionApplications() {
    this.adminDashboardService.fetchOpinionApplications(this.userId, this.userRoleCode, this.allApplicationStartDate, this.allApplicationEndDate, this.allApplicationBranch, this.allApplicationTaxOfficer).subscribe(result => {
      this.allApplications = result;
    });
  }

  loadRefundApplications() {
    this.adminDashboardService.fetchRefundApplications(this.userId, this.userRoleCode, this.allApplicationStartDate, this.allApplicationEndDate, this.allApplicationBranch, this.allApplicationTaxOfficer).subscribe(result => {
      this.allApplications = result;
    });
  }

  getUserPermision() {
    this.isTaxOfficerOrAccountant = this.userRoleCode === Constant.ROLE_TAX_OFFICER || this.userRoleCode === Constant.ROLE_ACCOUNTANT;
  }

  listNotifications() {
    this.dashboardService.getNotificationList().subscribe(
      data => {
        this.notifications = data.data;
        this.numberOfNotifications = this.notifications.length;
      }
    )
  }

  updateNotification(notificationId, deedNature, url) {
    console.log(url);
        this.refundService.getRefundRequestByID(parseInt(url)).subscribe( (res) => {
       
          localStorage.removeItem('refundRequest');
          sessionStorage.removeItem('hasOpinion');
          sessionStorage.removeItem('deedNo');
        
          localStorage.setItem('refundRequest' , res.data.id);
          sessionStorage.setItem('hasOpinion', res.data.hasOpinion);
          sessionStorage.setItem('deedNo', res.data.deedNumber);
          sessionStorage.setItem('refundRequest','true');
        
                })
    this.notificationModelUpdate.notificationId = notificationId;

    this.dashboardService.updateNotification(this.notificationModelUpdate).subscribe(
      data => {
        console.log(data);
      }
    )
  }

  viewRefundApplication() {
    sessionStorage.setItem('refundRequest', 'true');
    sessionStorage.setItem('selectedHeader', 'dashboard');
  }

  viewOpinionApplication() {
    sessionStorage.setItem('refundRequest', 'false');
  }

  viewReports() {
    sessionStorage.setItem('selectedHeader', 'reports');
  }

  createReimbursementDraft() {
    this.reimbursementApplicationCreateModel.assessorId = this.userData.id;
    this.reimbursementApplicationCreateModel.assessorCode = this.userData.accessorCode;
    this.reimbursementApplicationCreateModel.provinceCode = this.userData.provinceCode;
    this.reimbursementApplicationCreateModel.districtCode = this.userData.districtCode;

    this.reimbursementApplicationservice.createReimbursementDraftApplication(this.reimbursementApplicationCreateModel).subscribe(
      data => {
        localStorage.setItem('rimFileNumberFormat', data.data.fileNumberFormat);
        sessionStorage.setItem('rimId', data.data.id);
        sessionStorage.setItem('newReimbursement', JSON.stringify(true));
        setTimeout(() => this.router.navigate(['/reimbursment-application/new-request']), 1500);
      }
    )
  }

  logout() {
    this.authService.logout();
  }

  loadOpinionPieChartDetails() {
    this.adminDashboardService.fetchOpinionPieChartDetails(this.userId, this.userRoleCode).subscribe(data => {
      this.commonPieChart(data);
    });
  }

  loadAppealPieChartDetails() {
    this.adminDashboardService.fetchAppealPieChartDetails(this.userId, this.userRoleCode).subscribe(data => {
      this.commonPieChart(data);
    });
  }

  loadAllPieChartDetails() {
    this.adminDashboardService.fetchAllPieChartDetails(this.userId, this.userRoleCode).subscribe(data => {
      this.commonPieChart(data);
    });
  }

  loadRefundPieChartDetails() {
    this.adminDashboardService.fetchRefundPieChartDetails(this.userId, this.userRoleCode).subscribe(data => {
      this.commonPieChart(data);
    });
  }


  commonPieChart(result) {
    new Chart('pieChart2', {
      type: 'pie',
      data: {
        labels: ['Received Application', 'Accepted Application', 'Rejected Application', 'Issued Application', 'Pending Application'],
        datasets: [{
          label: 'Target Percentage',
          data: [result['counts'][0], result['counts'][1], result['counts'][2], result['counts'][3], result['counts'][4]],
          backgroundColor: [
            '#1C436F',
            '#E74677',
            '#9C0E0E',
            '#F8981F',
            '#4C1A48'
          ]
        }]
      },
      options: {
        legend: {
          position: 'bottom',
        }
      }
    });
  }

  loadOpinionBankChartDetails() {
    this.adminDashboardService.fetchOpinionBankChartDetails(this.userId, this.userRoleCode, this.fromDateTotalCollection, this.toDateTotalCollection).subscribe(data => {
      this.commonOpinionBankChart(data);
    });
  }

  loadAppealBankChartDetails() {
    this.adminDashboardService.fetchAppealBankChartDetails(this.userId, this.userRoleCode, this.fromDateTotalCollection, this.toDateTotalCollection).subscribe(data => {
      this.commonOpinionBankChart(data);
    });
  }

  loadAllBankChartDetails() {
    this.adminDashboardService.fetchAllBankChartDetails(this.userId, this.userRoleCode, this.fromDateTotalCollection, this.toDateTotalCollection).subscribe(data => {
      this.commonOpinionBankChart(data);
    });
  }

  commonOpinionBankChart(result) {
    this.bankDetailsList = result['bankDetails'];
    let bankNameList = [];
    let bankAmount = [];
    for (let bank of result['bankDetails']) {
      bankNameList.push(bank['bankName']);
      bankAmount.push(bank['amount']);
      this.bankTotalAmount = this.bankTotalAmount + parseFloat(bank['amount']);
    }

    new Chart('bankAmount2', {
      type: 'bar',
      data: {
        labels: bankNameList,
        datasets: [{
          label: 'Target Percentage',
          data: bankAmount,
          backgroundColor: '#E74677'
        }]
      },
      options: {
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: '#fff'
            },
            gridLines: {
              zeroLineColor: '#fff'
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: '#fff'
            },
            gridLines: {
              zeroLineColor: '#fff'
            }
          }]
        },
        legend: {
          display: false
        }
      }
    });
  }

  selectTotalCollectionDateRange() {
    if(this.fromDateTotalCollection !== null && this.toDateTotalCollection !== null){
      this.bankDetailsList = undefined;
      this.bankDetailsList = 0;
      this.bankTotalAmount = 0;
      this.loadOpinionBankChartDetails();
    }
  }

  loadOpinionBarChartDetails() {
    this.adminDashboardService.fetchOpinionBarChartDetails(this.userId, this.userRoleCode, this.formYear, this.toYear).subscribe(data => {
      this.commonOpinionBarChart(data);
    });
  }

  loadAppealBarChartDetails() {
    this.adminDashboardService.fetchAppealBarChartDetails(this.userId, this.userRoleCode, this.formYear, this.toYear).subscribe(data => {
      this.commonOpinionBarChart(data);
    });
  }

  loadAllBarChartDetails() {
    this.adminDashboardService.fetchAllBarChartDetails(this.userId, this.userRoleCode, this.formYear, this.toYear).subscribe(data => {
      this.commonOpinionBarChart(data);
    });
  }


  commonOpinionBarChart(result) {
    new Chart('groupedBarChart2', {
      type: 'bar',
      data: {
        labels: result['status'],
        datasets: [
          {
            label: '1',
            backgroundColor: '#1C436F',
            data: result['dataList'][0]
          },
          {
            label: '2',
            backgroundColor: '#E74677',
            data: result['dataList'][1]
          },
          {
            label: '3',
            backgroundColor: '#F8981F',
            data: result['dataList'][2]
          },
          {
            label: '4',
            backgroundColor: '#A91F9E',
            data: result['dataList'][3]
          },
          {
            label: '5',
            backgroundColor: '#F94242',
            data: result['dataList'][4]
          }
        ]
      },
      options: {
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: '#fff'
            },
            gridLines: {
              zeroLineColor: '#fff'
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: '#fff'
            },
            gridLines: {
              zeroLineColor: '#fff'
            }
          }]
        },
        legend: {
          display: false
        }
      }
    });
  }

  filterApplicationsByDate() {
    if (this.allApplicationStartDate !== null && this.allApplicationEndDate !== null) {
      if (this.applicationTitle === 'All') {
        this.commonAll();
      } else if (this.applicationTitle === 'Opinion') {
        this.commonOpinion();
      } else if (this.applicationTitle === 'Refund') {
        this.commonRefund();
      } else if (this.applicationTitle === 'REIMBURSEMENT') {
        //do something
      } else if (this.applicationTitle === 'Appeal') {
        this.commonAppeal();
      }
    }
  }
}
