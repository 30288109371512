import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-header-internal',
  templateUrl: './header-internal.component.html',
  styleUrls: ['./header-internal.component.scss']
})
export class HeaderInternalComponent implements OnInit {

  constructor(private authService: AuthService,) { }
  role :any;
  ngOnInit() {
    this.role=localStorage.getItem('rc')
  }

  logout(){
    this.authService.logout();
  }

}
