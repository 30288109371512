import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UpdateDistrictComponent } from '../update-district/update-district.component';
import { DeleteDistrictComponent } from '../delete-district/delete-district.component';
import { DistrictService } from 'src/app/service/district/district.service';
import { SubSink } from 'subsink';
import { DataService } from 'src/app/service/data.service';
import {IDistricts} from "../../../../../../model/interfaces/districts";

@Component({
  selector: 'app-view-district',
  templateUrl: './view-district.component.html',
  styleUrls: ['./view-district.component.scss']
})
export class ViewDistrictComponent implements OnInit, OnDestroy {

  districts: IDistricts [];
  private sub = new SubSink();
  pageNo: number;

  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];

  @ViewChild(UpdateDistrictComponent, { static: false })
  private updateDistrictComponent: UpdateDistrictComponent;
  @ViewChild(DeleteDistrictComponent, { static: false })
  private deleteDistrictComponent: DeleteDistrictComponent;
  currentPage: any;
  totalItems: any;


  constructor(private districtService: DistrictService, private dataService: DataService) { }

  ngOnInit() {
    this.selectedPageNo = 0;
    this.limit = 10;

    this.districtService.getAll().subscribe(
      response => {
        var res = response.data.length;
        this.pageNo = Math.round((res / 10)+1);
      })

    this.sub.add(this.dataService.updateDtaTableDistrict
      .subscribe(update => this.onAdded(update)));

    this.sub.add(this.districtService.getDistricts(0, 10)
      .subscribe(
        (res) => {
          this.districts = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
          console.log(res)
        }));
  }
  pageable(i: number) {

    this.districtService.getDistricts((i-1),10).subscribe(responce => {
      this.districts = (responce as any).data.content;
      this.currentPage = (responce as any).data.number+1;
      this.totalItems = (responce as any).data.totalElements;
    });
  }
  counter(i: number) {
    return new Array(i);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  triggerUpdate(id: number, code: string, province: number, description: string) {
    this.updateDistrictComponent.setIdAndDescription(id, code, province, description);
  }

  triggerDelete(id: number) {
    this.deleteDistrictComponent.setDistrictId(id);
  }

  onUpdated(updatedSuccessfully: boolean) {
    if (updatedSuccessfully) {
      this.updateTheTable();
    }
  }

  onDeleted(deletedSuccessfully: boolean) {
    if (deletedSuccessfully) {
      this.updateTheTable();
    }
  }

  onAdded(addedSuccessfully: boolean) {
    if (addedSuccessfully) {
      this.updateTheTable();
    }
  }
  private updateTheTable() {
    this.sub.add(this.districtService.getDistricts(0, 10)
      .subscribe(
        (res) => {
          this.districts = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

  searchDistrict(keyword: string) {
    this.selectedPageNo = 0;
    this.sub.add(this.districtService.searchDistrict(keyword ,this.selectedPageNo, this.limit)
      .subscribe((res) => {
        // this.districts = res.data.content;
        // this.paginationButtonCounter(res.data.totalPages);
        // this.numOfPages = res.data.totalPages;
        this.districts = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      })
    );
  }


  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }
}
