import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ReportServiceService } from "../service/report-service.service";
import { NotifierService } from "angular-notifier";
import * as XLSX from "xlsx";

@Component({
	selector: 'app-certified-opinion-details-report',
	templateUrl: './certified-opinion-details-report.component.html',
	styleUrls: ['./certified-opinion-details-report.component.scss']
})
export class CertifiedOpinionDetailsReportComponent implements OnInit {

	// for local authorities
	selectedItemsP = [];
	dropdownListP = [];
	dropdownSettingsP: IDropdownSettings = {};

	period = [
		{id: "1-6", name: '1st 6 months'},
		{id: "7-12", name: '2nd 6 Months'}
	]

	// for assosser
	selectedItemsASS = [];
	dropdownListASS = [];
	dropdownSettingsASS: IDropdownSettings = {};

	// for allocated regional branches
	selectedItemsARB = [];
	dropdownListARB = [];
	dropdownSettingsARB: IDropdownSettings = {};

	// submitted date range
	fromDate: string = null;
	toDate: string = null;

	// report data
	public reportDetails: any = [];
	public showPrint: boolean = false;
	public selectedDetails: any;
	public download_excel_status: boolean = false;

	// report data footer data
	public total_count: number = 0;
	public total_previous_opinion_value: number = 0;
	public total_opinion_given_value: number = 0;
	public total_jan_value: number = 0;
	public total_feb_value: number = 0;
	public total_mar_value: number = 0;
	public total_apr_value: number = 0;
	public total_may_value: number = 0;
	public total_jun_value: number = 0;
	public total_jul_value: number = 0;
	public total_aug_value: number = 0;
	public total_sep_value: number = 0;
	public total_oct_value: number = 0;
	public total_nov_value: number = 0;
	public total_dec_value: number = 0;
	public total_transfer_value: number = 0;
	public total_gift_value: number = 0;
	public total_exchange_value: number = 0;

	constructor(
		public modalService: NgbModal,
		public reportService: ReportServiceService,
		private notifier: NotifierService
	) { }

	ngOnInit() {
		// set up assessor dropdown
		this.dropdownSettingsASS = {
			singleSelection: false,
			idField: 'id',
			textField: 'name',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		// set up local authority dropdown
		this.dropdownSettingsP = {
			singleSelection: false,
			idField: 'id',
			textField: 'name',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		// set up allocated regional branches dropdown
		this.dropdownSettingsARB = {
			singleSelection: false,
			idField: 'id',
			textField: 'office',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 3,
			allowSearchFilter: true
		};

		this.get_all_allocated_branches();
		this.get_all_assessors();
		this.get_all_period();
	}

	/******** LOAD DROP DOWN DATA **************/
	// retrieve period
	get_all_period() {
		this.dropdownListP = this.period;
	}

	// retrieve allocated branches data from service
	get_all_allocated_branches() {
		this.reportService.getAllAB().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListARB = value.data;
			} else {
				console.log("No Allocated Regional Branch return");
			}
		});
	}

	// retrieve allocated branches data from service
	get_all_assessors() {
		this.reportService.getAllASS().subscribe(value => {
			if (value.data.length > 0) {
				this.dropdownListASS = value.data;
			} else {
				console.log("No Assorssors return");
			}
		});
	}
	/******** LOAD DROP DOWN DATA **************/

	/******** CAPTURE INPUTS **************/
	captureSelectedDetails(): void {
		var date_range = ((this.fromDate == null) && (this.toDate == null)) ? "All Date" : this.fromDate + ' to ' + this.toDate;

		// capture selected period
		var selectedP_Names = "";
		var selectedP = "";
		if (this.selectedItemsP != null) {
			this.selectedItemsP.forEach(value => {
				selectedP += value.id + ",";
				selectedP_Names += value.name + ",";
			});
		} else {
			selectedP_Names = "N/A";
			selectedP = "";
		}

		// capture selected assessors
		var selectedASS = "";
		var selectedASS_Names = "";
		if (this.selectedItemsASS != null) {
			this.selectedItemsASS.forEach(value => {
				selectedASS += value.id + ",";
				selectedASS_Names += value.name + ", ";
			});
		} else {
			selectedASS = "";
			selectedASS_Names = "N/A";
		}

		// capture selected allocated regional branches
		var selectedARB = "";
		var selectedARB_Names = "";
		if (this.selectedItemsARB != null) {
			this.selectedItemsARB.forEach(value => {
				selectedARB += value.id + ",";
				selectedARB_Names += value.office + ", ";
			});
		} else {
			selectedARB = "";
			selectedARB_Names = "N/A";
		}

		this.selectedDetails = {
			selected_date: date_range,

			selectedP_id: selectedP,
			selectedP_names: selectedP_Names,

			selectedASS_id: selectedASS,
			selectedASS_names: selectedASS_Names,

			selectedARB_id: selectedARB,
			selectedARB_names: selectedARB_Names
		};
	}
	/******** CAPTURE INPUTS **************/

	/******** LOAD REPORT DATA **************/
	getReportData() {
		this.reportService.getCertifiedOpinionDetailReport(this.fromDate, this.toDate,this.selectedDetails.selectedASS_id, this.selectedDetails.selectedARB_id, this.selectedDetails.selectedP_id).subscribe(value => {
			console.log("certified detail values"+value.data);
			if (value.data.data != null && value.data.data.length > 0) {
				this.reportDetails = value.data.data;
				this.total_count = value.data.length;
				this.total_jan_value = value.data.janAmount;
				this.total_feb_value = value.data.febAmount;
				this.total_mar_value = value.data.marAmount;
				this.total_apr_value = value.data.aprAmount;
				this.total_may_value = value.data.mayAmount;
				this.total_jun_value = value.data.junAmount;
				this.total_jul_value = value.data.julAmount;
				this.total_aug_value = value.data.augAmount;
				this.total_sep_value = value.data.sepAmount;
				this.total_oct_value = value.data.octAmount;
				this.total_nov_value = value.data.novAmount;
				this.total_dec_value = value.data.decAmount;

				this.total_transfer_value = value.data.transferAmount;
				this.total_gift_value = value.data.exchangeAmount;
				this.total_exchange_value = value.data.giftAmount;

				// this.getTotalColumns(value.data);
			} else {
				this.notifier.notify('error', 'No records found! ');
				console.log("No data found");
			}
		});
	}
	/******** LOAD REPORT DATA **************/

	/*************** GENERATE REPORT ***************/
	createPdfReport(): void {

		let printContents: any;
		let popupWin: any;
		printContents = document.getElementById('certifiedOpinionDetailsReportContent').innerHTML;

		popupWin = window.open();
		popupWin.document.open();
		popupWin.document.write(`
    <html>
    <head>
        <title>Detail Report for Certified Opinion</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <!--<script src="../../../../assets/js/paged.js"></script>-->
        <!--<script src="../../../../assets/js/paged.polyfill.js"></script>-->
        <style>
          @page {
            size: A3 landscape;
            @bottom-right {
              content: "Page " counter(page) " of " counter(pages);
            }
            .page{
                page-break-after: always;
            }
          }

            body
            {
                margin: 10mm 10mm 0mm 10mm;

            }
            table{
              border: 1px solid #000;
              border-collapse: collapse;
            }
            tbody{
              page-break-before: always;
            }

            table th, table td
            {
                padding: 5px;
                margin: 0px;
                border: 1px solid #000;
            }
            .page{
                page-break-after: always;
            }

        </style>
    </head>
    <body onload="window.print();">
            ${printContents}
    </body>
    </html>`
		);
		popupWin.document.close();
	}
	/*************** GENERATE REPORT ***************/

	/*************** UI EVENT ***************/
	// event click on close button
	closeFurtherReqRemark() {
		this.modalService.dismissAll();
	}

	createExcel() {
		this.captureSelectedDetails();
		this.getReportData();
		this.showPrint = true;
		setTimeout(() => {
			var printContents = document.getElementById('excel_print');
			const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(printContents);
			const wb: XLSX.WorkBook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'Certified opinion');
			XLSX.writeFile(wb, 'Detail Report of Certified Opinion.xlsx');
		}, 1000);
	}

	generatePdfReport() {
		this.captureSelectedDetails();
		this.getReportData();
		this.showPrint = true;
		setTimeout(() => {
			console.log(this.reportDetails);
			this.createPdfReport();
		}, 1000);

	}

	onItemSelectASS(item: any) {
		// console.log(item);
	}
	onSelectAllASS(items: any) {
		// console.log(items);
	}

	onItemSelectARB(item: any) {
		// console.log(item);
	}
	onSelectAllARB(items: any) {
		// console.log(items);
	}

	onItemSelectP(items: any) {
		// console.log(items);
	}

	onSelectAllP(items: any) {
		// console.log(items);
	}
	/*************** UI EVENT ***************/
}
