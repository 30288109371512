import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { PropertyType } from 'src/app/model/property-type';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { PropertyTypeService } from 'src/app/service/property-type/property-type.service';
import { DataService } from 'src/app/service/data.service';
import { NotifierService } from 'angular-notifier';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-search-property-type',
  templateUrl: './search-property-type.component.html',
  styleUrls: ['./search-property-type.component.scss']
})
export class SearchPropertyTypeComponent implements OnInit, OnDestroy {
  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  addpropertyTypeForm: FormGroup;

  private sub = new SubSink();

  constructor(private formBuilder: FormBuilder, private propertyTypeService: PropertyTypeService, private dataService: DataService, private notifier: NotifierService) { }

  ngOnInit() {

    this.addpropertyTypeForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]]
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  savePropertyType() {
 
    this.propertyTypeService.getAll().subscribe(
      response => {
        const totalItems = response.data.length;
        this.propertyTypeService.getPropertyTypes(0,totalItems).subscribe(res=>{
          const propertyTypesArray = (res as any).data.content;
          if (propertyTypesArray.map((PropertyT)=>String(PropertyT.name)).includes(String(this.addpropertyTypeForm.get('name').value))){
            this.notifier.notify('error', 'Property type name "'+this.addpropertyTypeForm.get('name').value +'" is exist');
            return;
          }else{
            if (this.addpropertyTypeForm.valid) {
              const propertyType = new PropertyType();
              propertyType.description = this.getDescription();
              propertyType.name = this.getName();
              this.sub.add(this.propertyTypeService.createPropertyType(propertyType)
                .subscribe(response => {
                  this.notifier.notify('success', (response as any).message);
                  this.dataService.updatePropertyType(true);
                }));
        
              this.reset();
            }else{
              this.addpropertyTypeForm.markAllAsTouched();
            }
          }})
      });
   
  }

  getDescription() {
    return this.addpropertyTypeForm.get('description').value;
  }
  getName() {
    return this.addpropertyTypeForm.get('name').value;
  }
  get f() { return this.addpropertyTypeForm.controls; }

  reset() {
    this.addpropertyTypeForm.reset();
  }

}
