import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { LandRegistry } from 'src/app/model/landRegistry';

@Injectable({
  providedIn: 'root'
})
export class LandRegistryService {

  private landRegistryUrl = '/land-registries';

  constructor(
    private http: HttpClient
  ) { }

  getAllLandRegistriesOrderByAscending(): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.landRegistryUrl + '/all/land-registries-by-asc');
  }

  createLandRegistry(LandRegistryModel:LandRegistry):Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.landRegistryUrl}`,LandRegistryModel)
  }

  getLandRegistry(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.landRegistryUrl + '?offset=' + offset + '&limit=' + limit + '');
  }

  updateLandRegistry(id: number, LandRegistryModel:LandRegistry): Observable<any> {
    return this.http.put(`${environment.api_url}${this.landRegistryUrl}/${id}`, LandRegistryModel);
  }

  searchLandRegistry(keyWord:string,offset, limit):Observable<any>{
    return this.http.get<any[]>(environment.api_url + this.landRegistryUrl +'/search?keyword='+ keyWord + '&offset=' + offset + '&limit=' + limit + '');
  }

  deleteLandRegistry(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.landRegistryUrl}/${id}`);
  }
}
