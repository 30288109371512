import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { InspectionElectricityTypeService } from 'src/app/service/inspection-master-data-service/inspection-electricity-type/inspection-electricity-type.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inspection-electricity-type',
  templateUrl: './inspection-electricity-type.component.html',
  styleUrls: ['./inspection-electricity-type.component.scss']
})
export class InspectionElectricityTypeComponent implements OnInit,OnDestroy {

  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  createElectricityTypeForm: FormGroup;
  updateElectricityTypeForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  propertyTypeList: any =[];
  activationButton: boolean;
  public createElectricityTypeModel = new InspectionCommonDataModel(null,'','','','');
  public updateElectricityTypeModel = new InspectionCommonDataModel(null,'','','','');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:any;
  selectedId:number;
  ElectricityTypes: any;


  constructor(
    private formBuilder: FormBuilder,
    private inspectionElectricityTypeService: InspectionElectricityTypeService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createElectricityTypeForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });
    this.updateElectricityTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: [''],
    });

    // this.loadPropertyType();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }



  //create Electricity type - start

  get f() { return this.createElectricityTypeForm.controls; }

  setElectricityTypeModelToSave(){
    this.createElectricityTypeModel.name = this.createElectricityTypeForm.get('name').value;
    this.createElectricityTypeModel.description = this.createElectricityTypeForm.get('description').value;
    this.createElectricityTypeModel.code = this.createElectricityTypeForm.get('code').value;
    this.createElectricityTypeModel.dataStatus = "ACTIVE";
  }

    //save Electricity type model
  saveElectricityType(){
    if(this.createElectricityTypeForm.valid){
      this.setElectricityTypeModelToSave();
      this.sub.add(
        this.inspectionElectricityTypeService.createElectricityType(this.createElectricityTypeModel).subscribe(data=>{
          this.notifier.notify('success', (data as any).message);
          this.updateTheTable();
          this.reset();
        })
        );
    }else{
      this.createElectricityTypeForm.markAllAsTouched();
    }

  }

  reset(){
    this.createElectricityTypeForm.reset()
  }
  //create Electricity type - end

  //update Electricity type - start

    //get update controllers
  get f1() { return this.updateElectricityTypeForm.controls; }

    //set data To Electricity Type model
  setElectricityType(subProperty:any){
    this.updateElectricityTypeForm.get('id').setValue(subProperty.id);
    this.updateElectricityTypeForm.get('name').setValue(subProperty.name);
    this.updateElectricityTypeForm.get('description').setValue(subProperty.description);
    this.updateElectricityTypeForm.get('code').setValue(subProperty.code);

    if(subProperty.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateElectricityTypeModel.id = this.updateElectricityTypeForm.get('id').value;
    this.updateElectricityTypeModel.name = this.updateElectricityTypeForm.get('name').value;
    this.updateElectricityTypeModel.description = this.updateElectricityTypeForm.get('description').value;
    this.updateElectricityTypeModel.code = this.updateElectricityTypeForm.get('code').value;

    if(this.activationButton == true){
      this.updateElectricityTypeModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateElectricityTypeModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateElectricityType(){
    this.setDataToModel();
    this.sub.add(this.inspectionElectricityTypeService.updateElectricityType(this.updateElectricityTypeModel.id, this.updateElectricityTypeModel).subscribe(data=>{
      this.notifier.notify('success', (data as any).message);
      this.updateTheTable();
    }))
  }

  //update Electricity type - end

//search and view table start
  pageable(i: number) {
    this.inspectionElectricityTypeService.getElectricityType((i-1),10).subscribe(res=>{
      this.ElectricityTypes = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionElectricityTypeService.getElectricityType(0, 10)
      .subscribe(
        (res) => {
          this.ElectricityTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search Electricity Type
    searchElectricityType(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else if(!isNaN(this.keyword)){
        this.sub.add(this.inspectionElectricityTypeService.searchElectricityTypeById(this.keyword).subscribe(res=>{
          this.ElectricityTypes = [];
          this.ElectricityTypes[0]=  (res as any).data;
          this.currentPage = 1;
          this.totalItems = 1;
        }))
      }else{
        this.sub.add(this.inspectionElectricityTypeService.searchElectricityType(this.keyword,0,10).subscribe(res=>{
          this.ElectricityTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionElectricityTypeService.deleteElectricityType(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end
}
