import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import {Observable, of, throwError} from 'rxjs';
import { environment } from 'src/environments/environment';
import { FloorSubType } from 'src/app/model/floor-sub-type';
import { tap, catchError } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import {IFloorSubType} from '../../model/interfaces/floor-sub-type';
import {IFloorSubTypePage} from '../../model/interfaces/floor-sub-type-page';

@Injectable({
  providedIn: 'root'
})
export class FloorSubTypeService {
  private floorTypeUrl = '/floor-sub-types';  // URL to web api

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  getFloorSubTypes(offset, limit): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + this.floorTypeUrl + '?offset=' + offset + '&limit=' + limit + '');
    // console.log(this.http.get<any[]>(environment.api_url + this.floorTypeUrl));
  }

  getAll(): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.floorTypeUrl + '/all');

  }

  /* Search floor sub type from server*/
  searchFloorSubType(keyword: string, offset: number, limit: number): Observable<IFloorSubTypePage> {
    const params = {
      params: {
        offset: offset.toString(),
        limit: limit.toString(),
        keyword
      }
    };
    return this.http.get<any[]>(`${environment.api_url}${this.floorTypeUrl}/search`, params)
      .pipe(tap(_ => this.log('search floor sub type')),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('get floor sub type'))
      );
  }

  createFloorSubType(floorSubType: FloorSubType): Observable<any> {
    console.log('serv' + JSON.stringify(floorSubType));
    return this.http.post<any>(`${environment.api_url}${this.floorTypeUrl}`,
      JSON.stringify(floorSubType), this.httpOptions)
      .pipe(tap(_ => this.log(`update floor sub type create=${floorSubType}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('floor sub type create'))
      );
  }

  updateFloorSubType(id: number, floorsubType: FloorSubType): Observable<any> {
    console.log('url id' + id);
    console.log('data' + floorsubType.floorType);
    // alert();
    return this.http.put(`${environment.api_url}${this.floorTypeUrl}/${id}`, floorsubType)
      .pipe(tap(_ => this.log(`update floor sub type category id=${floorsubType.id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
      catchError(this.handleError<any>('floor sub type update'))
    );
  }


  /** DELETE: delete the company category on the server */
  deleteFloorSubType(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.floorTypeUrl}/${id}`)
      .pipe(tap(_ => this.log(`update floor sub type category id=${id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
      catchError(this.handleError<any>('floorSubType'))
    );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {


      console.error(error);

      this.log(`${operation} failed: ${error.message}`);
      this.notifier.notify('error', `${operation} failed`);
      return of(result as T);
    };
  }
  private log(message: string) {

  }

}
