import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CompanyTypeService } from 'src/app/service/company-type/company-type.service';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';



@Component({
  selector: 'app-delete-company-type',
  templateUrl: './delete-company-type.component.html',
  styleUrls: ['./delete-company-type.component.scss']
})
export class DeleteCompanyTypeComponent implements OnInit, OnDestroy {

  private companytypeId: number;
  private sub = new SubSink();
@Output()companyTypeDeleted = new EventEmitter<boolean>();

  constructor(private companyTypeService: CompanyTypeService,  private notifier: NotifierService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  deleteCompanyType() {
    this.sub.add(this.companyTypeService.deleteCompanyType(this.companytypeId)
    .subscribe(response => {
        this.updateTheView(true);
        this.notifier.notify( 'success', (response as any).message);
        this.updateTheView(true);
      }));
  }
  updateTheView(deleted: boolean) {
    this.companyTypeDeleted.emit(deleted);
  }

  setCompanyTypeId(id: number) {
    this.companytypeId = id;
  }

}






