import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ValuationStatus } from 'src/app/model/valuation-status';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  private subject = new Subject<any>();
  private requestUrl = '/property-facilities';  // URL to web api
  private valuationRequestUrl = '/valuation-request';
  constructor(
    private http: HttpClient
  ) { }

  sendPropertyStatus(){
    this.subject.next();
  }

  getPropertyStatus():Observable<any>{
    return this.subject.asObservable();
  }
  
  propertyVerificationStatus(ValuationStatus: ValuationStatus): Observable<any> {
    return this.http.post<any>(`${environment.api_url}` + this.requestUrl + '/status', ValuationStatus);
  }

  propertyUpdateVerificationStatus(ValuationStatus: ValuationStatus): Observable<any> {
    return this.http.post<any>(`${environment.api_url}` + this.requestUrl + '/updatestatus', ValuationStatus);
  }

  findPropertyByValuationRequest(valuationRequestId: number): Observable<any> {
    return this.http.get<any>(`${environment.api_url}${this.valuationRequestUrl}/property/${valuationRequestId}`);
  }

  findSiteVisitReasorses(valuationRequestId: number):Observable<any>{
    return this.http.get<any>(environment.api_url + this.valuationRequestUrl +'/get-resourses/'+valuationRequestId);
  }

}
