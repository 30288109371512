import { Component, OnInit, EventEmitter , Output} from '@angular/core';

@Component({
  selector: 'app-admin-sidenav',
  templateUrl: './admin-sidenav.component.html',
  styleUrls: ['./admin-sidenav.component.scss']
})
export class AdminSidenavComponent implements OnInit {

  @Output() btn_click = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }


}
