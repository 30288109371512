import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FloorSubTypeService } from 'src/app/service/floor-sub-type/floor-sub-type.service';
import { FloorSubType } from 'src/app/model/floor-sub-type';
import { of } from 'rxjs';
import { FloorTypeService } from 'src/app/service/floor-type/floor-type.service';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-update-floor-sub-type',
  templateUrl: './update-floor-sub-type.component.html',
  styleUrls: ['./update-floor-sub-type.component.scss']
})
export class UpdateFloorSubTypeComponent implements OnInit, OnDestroy {

  updateFloorSubTypeForm: FormGroup;

  private sub = new SubSink();

  description: string;
  // floorType:number;

  floorTypes = [];

  @Output() floorSubTypeUpdated = new EventEmitter<boolean>();


  constructor(private formBuilder: FormBuilder, private floorSubTypeService: FloorSubTypeService,
    private FloorTypeService: FloorTypeService, private notifier: NotifierService, private floorTypeService: FloorTypeService,) { }

  ngOnInit() {

    this.updateFloorSubTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      floorType: ['', [Validators.required]],
      description: ['', [Validators.required]],
      name: ['', [Validators.required]]
    })

    this.sub.add(this.floorTypeService.getAll()
      .subscribe(
        (res) => {
          this.floorTypes = (res as any).data;
        }
      ));

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updateFloorSubType() {
    // 6("daojcnao")
    if (this.updateFloorSubTypeForm.valid) {
      const floorSubType = new FloorSubType();

      floorSubType.description = this.getDescription();
      floorSubType.floorType = this.getFloorType();
      console.log("las" + floorSubType.floorType);

      this.sub.add(this.floorSubTypeService.updateFloorSubType(this.getId(), floorSubType)
        .subscribe(response => {

          this.updateTheView(true);
          this.notifier.notify('success', (response as any).message);
        }));
    }
  }

  getId() {
    return this.updateFloorSubTypeForm.get('id').value;
  }
  setIdAndDescription(id: number, floorType: number,name:string, description: string) {
    this.updateFloorSubTypeForm.get('id').setValue(id);
    this.updateFloorSubTypeForm.get('floorType').setValue(floorType);
    this.updateFloorSubTypeForm.get('name').setValue(name);
    this.updateFloorSubTypeForm.get('description').setValue(description);
    // this.updateFloorSubTypeForm.get('floorType').setValue(floorType)
  }
  getName() {
    return this.updateFloorSubTypeForm.get('name').value;
  }
  getDescription() {
    return this.updateFloorSubTypeForm.get('description').value;
  }
  getFloorType() {
    console.log("11" + this.updateFloorSubTypeForm.get('floorType').value)
    return this.updateFloorSubTypeForm.get('floorType').value;
  }

  updateTheView(updated: boolean) {
    this.floorSubTypeUpdated.emit(updated);
  }


}
