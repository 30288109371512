import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NotificationModel } from 'src/app/model/notification-model';
import { NotificationEmailSend } from 'src/app/model/notification-email-send';
import { NotificationDetails } from 'src/app/model/notificationDetails';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  private notificationUrl = '/notification';

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  createNotification(notificationModel: NotificationModel): Observable<any> {
    // sessionStorage.setItem('notification', JSON.stringify(notificationModel));
    return this.http.post<any>(`${environment.api_url}` + this.notificationUrl + '/createNotification', notificationModel);
  }

  sendEmailNotification(notificationEmailSend: NotificationEmailSend): Observable<any> {
    return this.http.post<any>(`${environment.api_url}` + this.notificationUrl + '/sendEmail',
      JSON.stringify(notificationEmailSend), this.httpOptions)
      .pipe(tap(_ => this.log(`notification create=${notificationEmailSend}`)),
        catchError(err => {
          // this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('notification creation Error')));
  }

  updateReleventNotification(notificationDetails:NotificationDetails):Observable<any>{
    return this.http.post<any>(environment.api_url + this.notificationUrl + '/update-notification-status', notificationDetails)
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a company category message with the audit service */
  private log(message: string) {
    // Todo: create a service and send operational audit message
  }

}
