export class RefundNotaryDetails {
    initials: string;
    nic_passport: string;
    reg_no: string;
    official_add_l1: string;
    office_phone: string;
    official_add_l2: string;
    official_city: string;
    email: string;
    isSameNotary:boolean;
}
