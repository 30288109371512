import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FloorTypeService } from 'src/app/service/floor-type/floor-type.service';
import { FloorType } from 'src/app/model/floor-type';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-update-floor-type',
  templateUrl: './update-floor-type.component.html',
  styleUrls: ['./update-floor-type.component.scss']
})
export class UpdateFloorTypeComponent implements OnInit, OnDestroy {

  updateFloorTypeForm: FormGroup;
  private sub = new SubSink();
  description: string;

  @Output() floorTypeUpdated = new EventEmitter<boolean>();


  constructor(private formBuilder: FormBuilder, private floorTypeService: FloorTypeService, private notifier: NotifierService) { }

  ngOnInit() {

    this.updateFloorTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      code: ['', [Validators.required]],
      name: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updateFloorType() {
    if (this.updateFloorTypeForm.valid) {
      const floorType = new FloorType();
      floorType.description = this.getDescription();
      floorType.code = this.getCode();
      floorType.name = this.getName();
      this.sub.add(this.floorTypeService.updateFloorType(this.getId(), floorType)
        .subscribe(response => {

          this.updateTheView(true);
          this.notifier.notify('success', (response as any).message);
        }));
    }
  }

  getId() {
    return this.updateFloorTypeForm.get('id').value;
  }
  getCode() {
    return this.updateFloorTypeForm.get('code').value;
  }
  getName() {
    return this.updateFloorTypeForm.get('name').value;
  }
  getDescription() {
    return this.updateFloorTypeForm.get('description').value;
  }

  updateTheView(updated: boolean) {
    this.floorTypeUpdated.emit(updated);
  }

  setIdAndDescription(id: number, code: string, name: string, description: string) {
    this.updateFloorTypeForm.get('id').setValue(id);
    this.updateFloorTypeForm.get('code').setValue(code);
    this.updateFloorTypeForm.get('name').setValue(name);
    this.updateFloorTypeForm.get('description').setValue(description);
  }

}









