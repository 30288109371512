import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InspectionCommonService } from 'src/app/service/inspection-common/inspection-common.service';
import { InspectionService } from 'src/app/service/inspection/inspection.service';
import { InspectionCommon } from 'src/app/model/inspection-common';
import { ActivatedRoute } from '@angular/router';
import { Constant } from 'src/app/util/constant';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { InspectionLand } from 'src/app/model/inspection-land';
import { InspectionCondominium } from 'src/app/model/inspection-condominium';
import { InspectionLandBuilding } from 'src/app/model/inspection-land-building';
import { NotifierService } from 'angular-notifier';
import { ValuationRequestService } from 'src/app/service/valuation-request/valuation-request.service';
declare let jsPDF;
import FileSaver from "file-saver";
import { checkCompletedOrPaypend } from '../Util/checkStatus';

@Component({
  selector: 'app-inspection-report-main',
  templateUrl: './inspection-report-main.component.html',
  styleUrls: ['./inspection-report-main.component.scss']
})
export class InspectionReportMainComponent implements OnInit {

  @Input()
  valuationRequestId: string;

  @Input()
  isReject:boolean;

  @Input()
  isAppeal:boolean;

  @Input()
  valuationRequestStatus: string;

  appealCount:number;

  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();

  public propertyTypeList: any = [];
  public propertySubTypeList: any = [];
  public filteredPropertySubTypeList: any = [];

  public subPropertyTypeDisabled: boolean;

  public disableGeneratePage: boolean;

  public redirectPageNumber: number;

  public inspectionList: any = [];

  public inspectionCommonModel = new InspectionCommon('');

  public recreationCommonModel = new InspectionCommon('');

  public fileNumberFormat: string;

  // Multi Select Dropdown Settings
  dropdownSettings1: NgMultiSelectDropDownModule;

  // Selected dropdown values
  selectedSubPropertyTypeItems = [];

  filteredSelectedSubPropertyTypeItems = [];

  public filteredSubPropertyTypeRecreationList: any = [];

  public selectedRecreationType: boolean;

  public modelLand = new InspectionLand();

  public modelCondominium = new InspectionCondominium();
  // Selected dropdown values
  selectedFacilityTypeItems = [];
  selectedSpecialViewItems = [];
  selectedSurroundTypeItems = [];

  public modelBuilding = new InspectionLandBuilding('', '', '', [], '', [], null);

  public recreationlandreport: string;
  public recreationcondominiumreport: string;
  public recreationbuildingreport: string;

  public generatedFileNumber: string;
  valuationdata: any = [];
  
  fileNumberFormatAvailable: boolean = true;

  recreationTypes:any = [];
  checkStatusApplication  = checkCompletedOrPaypend;
  

  constructor(
    public inspectionCommonService: InspectionCommonService,
    private valuationRequestService:ValuationRequestService,
    public inspectionService: InspectionService,
    private route: ActivatedRoute,
    private notifier: NotifierService
  ) {
    this.subPropertyTypeDisabled = true;
    this.redirectPageNumber = 10;
    this.disableGeneratePage = true;
    this.selectedRecreationType = false;
    this.recreationlandreport = null;
    this.recreationcondominiumreport = null;
    this.recreationbuildingreport = null;
    this.appealCount - 0;
  }

  ngOnInit() {
    this._prepare();
    window.scroll(0,0);
  }

  pad(num: number, size: number) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    this.generatedFileNumber = s;
    if(this.isAppeal == true){
      this.generatedFileNumber = '#A' + this.generatedFileNumber;
    }else{
      this.generatedFileNumber = '#VR' + this.generatedFileNumber;
    }
  }

  retrieveInspectionLandDetails(inspectionId: any) {
    this.inspectionCommonModel.code = inspectionId;
    this.inspectionService.inspectionLandDetailList(this.inspectionCommonModel).subscribe(
      data => {
        localStorage.setItem('viewlandwithbuildingdata', JSON.stringify(false));
        localStorage.setItem('landwithbuilding', JSON.stringify(false));
        localStorage.setItem('disableLandBuildingLandSubType', JSON.stringify(false));
        localStorage.setItem('inspectionId', btoa(inspectionId));
        localStorage.setItem('recreationGroupId',JSON.stringify(null));
        localStorage.setItem("isLand", JSON.stringify(false));
        localStorage.setItem("isCondominium", JSON.stringify(false));
        localStorage.setItem("inspectioncondominiummodel", btoa(JSON.stringify(null)));
        localStorage.setItem("inspectionlandmodel", btoa(JSON.stringify(null)));
        localStorage.setItem("isLandWithBuildingRecreation", JSON.stringify(false));
        localStorage.removeItem("inspectionLandBuilding-Land");
        localStorage.removeItem("inspectionLandBuilding-Building");
        localStorage.setItem('indexSelectedLandBuilding', JSON.stringify(null));
        
        // localStorage.setItem("inspectionRecreationlandmodel", JSON.stringify(null));
        // localStorage.setItem("inspectionRecreationCondominiummodel", JSON.stringify(null));
        localStorage.setItem('recreationupdatereq', JSON.stringify(null));
        console.log(data.data)
        if (data.data.isRecreation) {
          localStorage.setItem('selectedRecreationType', btoa(JSON.stringify(true)));
          localStorage.setItem('selectedRecreationUpdateType', btoa(JSON.stringify(true)));
        } else {
          localStorage.setItem('selectedRecreationType', btoa(JSON.stringify(false)));
          localStorage.setItem('selectedRecreationUpdateType', btoa(JSON.stringify(false)));
        }

        // localStorage.setItem('selectedRecreationUpdateType', btoa(JSON.stringify(false)));

        localStorage.setItem('recreationLandInspectionId', btoa(undefined));
        localStorage.setItem('recreationCondominiumInspectionId', btoa(undefined));
        localStorage.setItem('recreationLandBuildingInspectionId', btoa(undefined));

        localStorage.setItem("recreationGroupId", JSON.stringify(data.data.recreationGroupId));

        const filteredPropertyType = this.propertyTypeList.filter(i => i.id === Number(data.data.propertyTypeId));
        localStorage.setItem('propertyType', btoa(JSON.stringify(filteredPropertyType[0])));
        const filteredSubPropertyType = this.propertySubTypeList.filter(i => i.id === Number(data.data.propertySubTypeId));
        localStorage.setItem('subPropertyType', btoa(JSON.stringify(filteredSubPropertyType[0])));
        if (data.data.inspectionType === Constant.INSPECTION_TYPE_LAND) {
          this.onPageCountChanged.emit(11);
        } else if (data.data.inspectionType === Constant.INSPECTION_TYPE_CONDOMINIUM) {
          this.onPageCountChanged.emit(12);
        } else if (data.data.inspectionType === Constant.INSPECTION_TYPE_BUILDING) {
          this.onPageCountChanged.emit(13);
        }else if (data.data.isRecreation){
          this.inspectionService.getRecreationList(data.data.recreationGroupId).subscribe(
            data=>{
              localStorage.setItem('propertyType', btoa(JSON.stringify(null)));
              localStorage.setItem('subPropertyType', btoa(JSON.stringify(null)));
              if(data.data.inspectionLandDto !== null){
                localStorage.setItem('inspectionRecreationlandmodel', JSON.stringify(data.data.inspectionLandDto))
                localStorage.setItem('isLand', JSON.stringify(data.data.isLand))
                // localStorage.setItem('landId', data.data.inspectionLandDto.id)
                this.recreationTypes.push({id:1 , name:"Land"})
              }
              if(data.data.inspectionCondominiumDto !== null){
                localStorage.setItem('inspectionRecreationCondominiummodel', JSON.stringify(data.data.inspectionCondominiumDto))
                console.log()
                localStorage.setItem('isCondominium', JSON.stringify(data.data.isCondominium))
                this.recreationTypes.push({id:2 , name:"Condominium"})
                // localStorage.setItem('condominiumId', JSON.stringify(data.data.inspectionCondominiumDto.id))
              }
              if(data.data.inspectionLandBuildingDto_landDto !== null){
                // localStorage.setItem('inspectionRecreationLandBuildingmodel', JSON.stringify(data.data.inspectionLandBuildingDto_landDto))
                localStorage.setItem('inspectionLandBuilding-Land', JSON.stringify(data.data.inspectionLandBuildingDto_landDto))
                localStorage.setItem('isLandBuilding', JSON.stringify(data.data.isLandBuilding))
                this.recreationTypes.push({id:3 , name:"Land with building"})
                // localStorage.setItem('landBuildingId', JSON.stringify(data.data.inspectionLandBuildingDto.id))
              }
              if(data.data.inspectionLandBuildingDto !== null){
                localStorage.setItem('inspectionLandBuilding-Building', JSON.stringify(data.data.inspectionLandBuildingDto))
                localStorage.setItem('isLandBuilding', JSON.stringify(data.data.isLandBuilding));
              }

              localStorage.setItem('selectedRecreationSubPropertyType', btoa(JSON.stringify(this.recreationTypes)));
              localStorage.setItem("recreationDetails",JSON.stringify(false));

              if(data.data.inspectionLandDto !== null){
                this.onPageCountChanged.emit(16);
              }else{
                if(data.data.inspectionCondominiumDto !== null){
                  this.onPageCountChanged.emit(18);
                }else{
                  this.onPageCountChanged.emit(17);
                }
              }
            }
          )
        } 
        // else if (data.data.inspectionType === Constant.INSPECTION_TYPE_RECREATION_LAND) {
        //   localStorage.setItem('propertyType', btoa(JSON.stringify(null)));
        //   localStorage.setItem('subPropertyType', btoa(JSON.stringify(null)));

        //   this.recreationCommonModel.code = inspectionId;
        //   this.inspectionService.inspectionRecreationChildList(this.recreationCommonModel).subscribe(
        //     data => {
        //       console.log(data.data)
        //       localStorage.setItem('inspectionId', btoa(undefined));
        //       localStorage.setItem('inspectionRecreationLandId', btoa(inspectionId));
        //       localStorage.setItem('inspectionRecreationCondominiumId', btoa(undefined));
        //       localStorage.setItem('inspectionRecreationLandwithBuildingId', btoa(undefined));
        //       localStorage.setItem('selectedRecreationType', btoa(JSON.stringify(false)));
        //       localStorage.setItem('selectedRecreationUpdateType', btoa(JSON.stringify(true)));
        //       localStorage.setItem('recreationupdatereq', btoa(JSON.stringify(data.data)));
        //       this.onPageCountChanged.emit(16);
        //     }
        //   )
        // } else if (data.data.inspectionType === Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM) {
        //   localStorage.setItem('propertyType', btoa(JSON.stringify(null)));
        //   localStorage.setItem('subPropertyType', btoa(JSON.stringify(null)));

        //   this.recreationCommonModel.code = inspectionId;
        //   this.inspectionService.inspectionRecreationChildList(this.recreationCommonModel).subscribe(
        //     data => {
        //       console.log(data.data)
        //       localStorage.setItem('inspectionId', btoa(undefined));
        //       localStorage.setItem('inspectionRecreationLandId', btoa(undefined));
        //       localStorage.setItem('inspectionRecreationCondominiumId', btoa(inspectionId));
        //       localStorage.setItem('inspectionRecreationLandwithBuildingId', btoa(undefined));
        //       localStorage.setItem('selectedRecreationType', btoa(JSON.stringify(false)));
        //       localStorage.setItem('selectedRecreationUpdateType', btoa(JSON.stringify(true)));
        //       localStorage.setItem('recreationupdatereq', btoa(JSON.stringify(data.data)));
        //       this.onPageCountChanged.emit(18);
        //     }
        //   )
        // } else if (data.data.inspectionType === Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING) {
        //   localStorage.setItem('propertyType', btoa(JSON.stringify(null)));
        //   localStorage.setItem('subPropertyType', btoa(JSON.stringify(null)));

        //   this.recreationCommonModel.code = inspectionId;
        //   this.inspectionService.inspectionRecreationChildList(this.recreationCommonModel).subscribe(
        //     data => {
        //       console.log(data.data)
        //       localStorage.setItem('inspectionId', btoa(undefined));
        //       localStorage.setItem('inspectionRecreationLandId', btoa(undefined));
        //       localStorage.setItem('inspectionRecreationCondominiumId', btoa(undefined));
        //       localStorage.setItem('inspectionRecreationLandwithBuildingId', btoa(inspectionId));
        //       localStorage.setItem('selectedRecreationType', btoa(JSON.stringify(false)));
        //       localStorage.setItem('selectedRecreationUpdateType', btoa(JSON.stringify(true)));
        //       localStorage.setItem('recreationupdatereq', btoa(JSON.stringify(data.data)));
        //       this.onPageCountChanged.emit(17);
        //     }
        //   )
        // }
      }
    )
  }

  downloadInspectionReport(inspection:any){
    var reportType;
    switch(inspection.inspectionType){
      case Constant.INSPECTION_TYPE_LAND:
        reportType = "land";
        break;
      case Constant.INSPECTION_TYPE_CONDOMINIUM:
        reportType = "condominium";
        break;
      case Constant.INSPECTION_TYPE_BUILDING:
        reportType = "landWithBuilding";
        break;
      default:
        reportType = "recreation"
        break;
    }

    this.inspectionService.downloadInspectionReport(inspection.valuationRequestId, inspection.id, reportType).subscribe(response=>{
      let blob: any = new Blob([response], {type: 'application/pdf'});
      FileSaver.saveAs(blob, "Inspection_Report_"+ reportType+"_"+ inspection.valuationRequestId,+"_"+inspection.id+ ".pdf");
    }), error => console.log('Error downloading the file'),
    () => console.info('File downloaded successfully');

  }
  generateReport(content: any) {
    if (content.inspectionType === Constant.INSPECTION_TYPE_LAND) {
      this.generateReportForLand(content.id);
    } else if (content.inspectionType === Constant.INSPECTION_TYPE_CONDOMINIUM) {
      this.generateReportForCondominium(content.id);
    } else if (content.inspectionType === Constant.INSPECTION_TYPE_BUILDING) {
      this.generateReportForBuilding(content.id);
    } else if (content.inspectionType === Constant.INSPECTION_TYPE_RECREATION_LAND) {
      this.generateReportForRecreation(content.id);
    } else if (content.inspectionType === Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM) {
      this.generateReportForRecreation(content.id);
    } else if (content.inspectionType === Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING) {
      this.generateReportForRecreation(content.id);
    }
  }

  generateReportForLand(inspectionId: any) {
    this.inspectionCommonModel.code = inspectionId;
    this.inspectionService.inspectionLandDetailList(this.inspectionCommonModel).subscribe(
      data => {
        this.modelLand = data.data;
        this.landReport(this.modelLand);
      }
    )
  }

  landReport(landDetails: any) {
    const doc = new jsPDF();
    // doc.addImage(this.firstPage, 'PNG', 0,0,210,297)
    doc.setFontStyle("bold");
    doc.text("Inspection Request.", 105, 55, null, null, "center");
    doc.line(75,56,135,56);
    doc.setFontSize(10);
    doc.text("Land Request", 20, 65)
    doc.setFont("times");
    doc.setFontType('normal');
    doc.text("Property Type ID", 20, 73);
    doc.text(landDetails.propertyTypeId !== null ? landDetails.propertyTypeId : "--", 100, 73);
    doc.text("Property Sub Type ID", 20, 78);
    doc.text(landDetails.propertySubTypeId !== null ? landDetails.propertySubTypeId : "--", 100, 78);
    doc.text("Valuation Request ID", 20, 83);
    doc.text(landDetails.valuationRequestId !== null ? landDetails.valuationRequestId : "--", 100, 83);
    doc.text("Inspection Type", 20, 88);
    doc.text(landDetails.inspectionType !== null ? landDetails.inspectionType : "--", 100, 88);
    doc.text("Created by", 20, 93);
    doc.text(landDetails.createdBy !== null ? landDetails.createdBy : "--", 100, 93);

    doc.setFontStyle("bold");
    doc.setFontSize(12);
    doc.text("SURVEY DETAILS", 25, 120, null, null, "left");
    doc.line(20,121,190,121);
    doc.setFontSize(10);
    doc.setFont("times");
    doc.setFontType('normal');
    doc.text("Plan No", 20, 128);
    doc.text(landDetails.planNo !== null ? landDetails.planNo : "--", 100, 128);
    doc.text("Lot No(s) / Condominium Unit No(s)", 20, 133);
    doc.text(landDetails.lotNo !== null ? landDetails.lotNo : "--", 100, 133);

    doc.setFontStyle("bold");
    doc.setFontSize(12);
    doc.text("LEGAL ASPECTS", 25, 140, null, null, "left");
    doc.line(20,141,190,141);
    doc.setFontSize(10);
    doc.setFont("times");
    doc.setFontType('normal');
    doc.text("Building line", 20, 148);
    doc.text(landDetails.buildingLine !== null ? landDetails.buildingLine : "--", 100, 148);
    doc.text("Street line", 20, 153);
    doc.text(landDetails.streetLine !== null ? landDetails.streetLine : "--", 100, 153);
    doc.text("Restriction", 20, 158);
    doc.text(landDetails.restriction !== null ? landDetails.restriction : "--", 100, 158);
    doc.text("Reservation ID", 20, 163);
    doc.text(landDetails.reservationId !== null ? landDetails.reservationId : "--", 100, 163);
    doc.text("Central Government Rules", 20, 168);
    doc.text(landDetails.centralGovernmentRules !== null ? landDetails.centralGovernmentRules : "--", 100, 168);
    doc.text("Local Government Rules", 20, 173);
    doc.text(landDetails.localGovernmentRules !== null ? landDetails.localGovernmentRules : "--", 100, 173);
    doc.text("UDA regulations", 20, 178);
    doc.text(landDetails.udaRegulations !== null ? landDetails.udaRegulations : "--", 100, 178);

    doc.setFontStyle("bold");
    doc.setFontSize(12);
    doc.text("LOCATION AND NATURE OF LAND", 25, 185, null, null, "left");
    doc.line(20,186,190,186);
    doc.setFontSize(10);
    doc.setFont("times");
    doc.setFontType('normal');
    doc.text("Other Rules", 20, 193);
    doc.text(landDetails.otherRules !== null ? landDetails.otherRules : "--", 100, 193);
    doc.text("Assessment No", 20, 198);
    doc.text(landDetails.assessmentNo !== null ? landDetails.assessmentNo : "--", 100, 198);
    doc.text("Nearest Town", 20, 203);
    doc.text(landDetails.nearestTown !== null ? landDetails.nearestTown : "--", 100, 203);
    doc.text("Access Nearest Town", 20, 208);
    doc.text(landDetails.accessNearestTown !== null ? landDetails.accessNearestTown : "--", 100, 208);
    doc.text("Distance Main Road", 20, 213);
    doc.text(landDetails.distanceMainRd !== null ? landDetails.distanceMainRd : "--", 100, 213);
    doc.text("Land Mark", 20, 218);
    doc.text(landDetails.landMark !== null ? landDetails.landMark : "--", 100, 218);
    doc.text("Road Width", 20, 223);
    doc.text(landDetails.roadWidth !== null ? landDetails.roadWidth : "--", 100, 223);
    doc.text("Land Extent", 20, 228);
    doc.text(landDetails.landExtent !== null ? landDetails.landExtent : "--", 100, 228);
    doc.text("Land Shape ID", 20, 233);
    doc.text(landDetails.landShapeId !== null ? landDetails.landShapeId : "--", 100, 233);
    doc.text("Road Frontage", 20, 238);
    doc.text(landDetails.roadFrontage !== null ? landDetails.roadFrontage : "--", 100, 238);
    doc.text("Length", 20, 243);
    doc.text(landDetails.length !== null ? landDetails.length : "--", 100, 243);
    doc.text("Width", 20, 248);
    doc.text(landDetails.width !== null ? landDetails.width : "--", 100, 248);
    doc.text("Land Location ID", 20, 253);
    doc.text(landDetails.landLocationId !== null ? landDetails.landLocationId : "--", 100, 253);
    doc.text("Land Slope ID", 20, 258);
    doc.text(landDetails.landSlopeId !== null ? landDetails.landSlopeId : "--", 100, 258);


    doc.addPage();

    // doc.addImage(this.otherPage, 'PNG', 0,0,210,297);

    doc.setFontStyle("bold");
    doc.setFontSize(12);
    doc.text("BOUNDRY AND FACILITIES", 25, 20, null, null, "left");
    doc.line(20,21,190,21);
    doc.setFontSize(10);
    doc.setFont("times");
    doc.setFontType('normal');

    doc.text("Water Availability", 20, 28);
    doc.text(landDetails.waterAvailabilityId !== null ? landDetails.waterAvailabilityId : "--", 100, 28);
    doc.text("Water ID", 20, 33);
    doc.text(landDetails.waterId !== null ? landDetails.waterId : "--", 100, 33);
    doc.text("Boundary North", 20, 38);
    doc.text(landDetails.boundaryNorth !== null ? landDetails.boundaryNorth : "--", 100, 38);
    doc.text("Boundary South", 20, 43);
    doc.text(landDetails.boundarySouth !== null ? landDetails.boundarySouth : "--", 100, 43);
    doc.text("Boundary West", 20, 48);
    doc.text(landDetails.boundaryWest !== null ? landDetails.boundaryWest : "--", 100, 48);
    doc.text("Boundary East", 20, 53);
    doc.text(landDetails.boundaryEast !== null ? landDetails.boundaryEast : "--", 100, 53);
    doc.text("Electricity Type ID", 20, 58);
    doc.text(landDetails.electricityTypeId !== null ? landDetails.electricityTypeId : "--", 100, 58);
    doc.text("Electricity Sub Type ID", 20, 63);
    doc.text(landDetails.electricitySubTypeId !== null ? landDetails.electricitySubTypeId : "--", 100, 63);
    doc.text("Land Suitability ID", 20, 68);
    doc.text(landDetails.landSuitabilityId !== null ? landDetails.landSuitabilityId : "--", 100, 68);
    doc.text("Cultivation", 20, 73);
    doc.text(landDetails.cultivation !== null ? landDetails.cultivation : "--", 100, 73);


    doc.setFontStyle("bold");
    doc.setFontSize(12);
    doc.text("INFRASTRUCTURE & FUTURE DEVELOPMENT", 25, 85, null, null, "left");
    doc.line(20,86,190,86);
    doc.setFontSize(10);
    doc.setFont("times");
    doc.setFontType('normal');

    doc.text("Transport ID", 20, 93);
    doc.text(landDetails.transportId !== null ? landDetails.transportId : "--", 100, 93);
    doc.text("Education ID", 20, 98);
    doc.text(landDetails.educationId !== null ? landDetails.educationId : "--", 100, 98);
    doc.text("Health ID", 20, 103);
    doc.text(landDetails.healthId !== null ? landDetails.healthId : "--", 100, 103);
    doc.text("Industrial ID", 20, 108);
    doc.text(landDetails.industrialId !== null ? landDetails.industrialId : "--", 100, 108);
    doc.text("Security ID", 20, 113);
    doc.text(landDetails.securityId !== null ? landDetails.securityId : "--", 100, 113);
    doc.text("Telecommunication ID", 20, 118);
    doc.text(landDetails.telecommunicationId !== null ? landDetails.telecommunicationId : "--", 100, 118);
    doc.text("Drainage Facility Availability", 20, 123);
    doc.text(landDetails.drainageFacilityAvailability !== null ? landDetails.drainageFacilityAvailability : "--", 100, 123);
    doc.text("Garbage Disposal Availability", 20, 128);
    doc.text(landDetails.garbageDisposalAvailability !== null ? landDetails.garbageDisposalAvailability : "--", 100, 128);
    doc.text("Community", 20, 133);
    doc.text(landDetails.community !== null ? landDetails.community : "--", 100, 133);
    doc.text("Neighbourhood", 20, 138);
    doc.text(landDetails.neighbourhood !== null ? landDetails.neighbourhood : "--", 100, 138);
    doc.text("Bus Route", 20, 143);
    doc.text(landDetails.busRoute !== null ? landDetails.busRoute : "--", 100, 143);
    doc.text("Railways", 20, 148);
    doc.text(landDetails.railways !== null ? landDetails.railways : "--", 100, 148);

    doc.setFontStyle("bold");
    doc.setFontSize(12);
    doc.text("SPECIFIC FEATURES OF MIX MODE LAND", 25, 160, null, null, "left");
    doc.line(20,161,190,161);
    doc.setFontSize(10);
    doc.setFont("times");
    doc.setFontType('normal');

    doc.text("Nature of Land", 20, 166);
    doc.text(landDetails.landNature !== null ? landDetails.landNature : "--", 100, 166);
    doc.text("Economic Purpose of the Land", 20, 171);
    doc.text(landDetails.landPurpose !== null ? landDetails.landPurpose : "--", 100, 171);
    doc.text("Future Expectation", 20, 176);
    doc.text(landDetails.futureExpectation !== null ? landDetails.futureExpectation : "--", 100, 176);
    doc.text("Return of Invesment", 20, 181);
    doc.text(landDetails.roiId !== null ? landDetails.roiId : "--", 100, 181);
    doc.text("Expected Yield", 20, 186);
    doc.text(landDetails.expectedYield !== null ? landDetails.expectedYield : "--", 100, 186);

    doc.save('Inspection Report - Land.pdf');
  }

  generateReportForCondominium(inspectionId: any) {
    this.inspectionCommonModel.code = inspectionId;
    this.inspectionService.inspectionCondominimumDetailList(this.inspectionCommonModel).subscribe(
      data => {
        this.modelCondominium = data.data;
        this.selectedFacilityTypeItems = this.modelCondominium.facilityTypes;
        this.selectedSpecialViewItems = this.modelCondominium.specialViewTypes;
        this.selectedSurroundTypeItems = this.modelCondominium.surroundTypes;
        this.condominiumReport(this.modelCondominium);
      }
    )
  }

  condominiumReport(landDetails: any) {
    const doc = new jsPDF();
    // doc.addImage(this.firstPage, 'PNG', 0,0,210,297)
    doc.setFontStyle("bold");
    doc.text("Inspection Request.", 105, 55, null, null, "center");
    doc.line(75,56,135,56);

    doc.setFontSize(10);
    doc.text("Condominium Request", 20, 65)
    doc.setFont("times");
    doc.setFontType('normal');
    doc.text("Property Type ID", 20, 73);
    doc.text(landDetails.propertyTypeId !== null ? landDetails.propertyTypeId : "--", 100, 73);
    doc.text("Property Sub Type ID", 20, 78);
    doc.text(landDetails.propertySubTypeId !== null ? landDetails.propertySubTypeId : "--", 100, 78);
    doc.text("Valuation Request ID", 20, 83);
    doc.text(landDetails.valuationRequestId !== null ? landDetails.valuationRequestId : "--", 100, 83);
    doc.text("Inspection Type", 20, 88);
    doc.text(landDetails.inspectionType !== null ? landDetails.inspectionType : "--", 100, 88);
    doc.text("Created by", 20, 93);
    doc.text(landDetails.createdBy !== null ? landDetails.createdBy : "--", 100, 93);

    doc.setFontStyle("bold");
    doc.setFontSize(12);
    doc.text("SURVEY DETAILS", 25, 120, null, null, "left");
    doc.line(20,121,190,121);
    doc.setFontSize(10);
    doc.setFont("times");
    doc.setFontType('normal');
    doc.text("Plan No", 20, 128);
    doc.text(landDetails.planNo !== null ? landDetails.planNo : "--", 100, 128);
    doc.text("Lot No(s) / Condominium Unit No(s)", 20, 133);
    doc.text(landDetails.lotNo !== null ? landDetails.lotNo : "--", 100, 133);

    doc.setFontStyle("bold");
    doc.setFontSize(12);
    doc.text("USE OF THE CONDOMINIUM", 25, 140, null, null, "left");
    doc.line(20,141,190,141);
    doc.setFontSize(10);
    doc.setFont("times");
    doc.setFontType('normal');
    doc.text("Use of Condominium", 20, 147);
    doc.text(landDetails.useOfCondominium !== null ? landDetails.useOfCondominium : "--", 100, 147);

    doc.setFontStyle("bold");
    doc.setFontSize(12);
    doc.text("DETAILS OF THE CONDOMINIUM", 25, 155, null, null, "left");
    doc.line(20,156,190,156);
    doc.setFontSize(10);
    doc.setFont("times");
    doc.setFontType('normal');
    doc.text("Condominium Name", 20, 163);
    doc.text(landDetails.condominiumName !== null ? landDetails.condominiumName : "--", 100, 163);
    doc.text("Assessment No", 20, 168);
    doc.text(landDetails.assessmentNo !== null ? landDetails.assessmentNo : "--", 100, 168);
    doc.text("Town ID", 20, 173);
    doc.text(landDetails.townId !== null ? landDetails.townId : "--", 100, 173);
    doc.text("Main Road", 20, 178);
    doc.text(landDetails.mainRoad !== null ? landDetails.mainRoad : "--", 100, 178);
    doc.text("Distance Main Road", 20, 183);
    doc.text(landDetails.distanceMainRd !== null ? landDetails.distanceMainRd : "--", 100, 183);
    doc.text("District ID", 20, 188);
    doc.text(landDetails.districtId !== null ? landDetails.districtId : "--", 100, 188);
    doc.text("Local Authority ID", 20, 193);
    doc.text(landDetails.localAuthorityId !== null ? landDetails.localAuthorityId : "--", 100, 193);
    doc.text("Plan Number", 20, 198);
    doc.text(landDetails.planNumber !== null ? landDetails.planNumber : "--", 100, 198);
    doc.text("Date Period", 20, 203);
    doc.text(landDetails.datePeriod !== null ? landDetails.datePeriod : "--", 100, 203);
    doc.text("Survey Name", 20, 208);
    doc.text(landDetails.surveyName !== null ? landDetails.surveyName : "--", 100, 208);
    doc.text("Land Extent", 20, 213);
    doc.text(landDetails.landExtent !== null ? landDetails.landExtent : "--", 100, 213);
    doc.text("No of floors", 20, 218);
    doc.text(landDetails.noOfFloors !== null ? landDetails.noOfFloors : "--", 100, 218);
    doc.text("No of units", 20, 223);
    doc.text(landDetails.noOfUnits !== null ? landDetails.noOfUnits : "--", 100, 223);
    doc.text("Floors and Units", 20, 228);
    doc.text(landDetails.floorsAndUnits !== null ? landDetails.floorsAndUnits : "--", 100, 228);
    doc.text("Other units", 20, 233);
    doc.text(landDetails.otherUnits !== null ? landDetails.otherUnits : "--", 100, 233);
    doc.text("Constructed Year", 20, 238);
    doc.text(landDetails.constructedYear !== null ? landDetails.constructedYear : "--", 100, 238);
    doc.text("Parking ID", 20, 243);
    doc.text(landDetails.parkingId !== null ? landDetails.parkingId : "--", 100, 243);
    doc.text("Security ID", 20, 248);
    doc.text(landDetails.securityId !== null ? landDetails.securityId : "--", 100, 248);
    doc.text("Unit No", 20, 253);
    doc.text(landDetails.unitNo !== null ? landDetails.unitNo : "--", 100, 253);
    doc.text("Extent", 20, 258);
    doc.text(landDetails.extent !== null ? landDetails.extent : "--", 100, 258);
    doc.text("Garden ID", 20, 263);
    doc.text(landDetails.gardenId !== null ? landDetails.gardenId : "--", 100, 263);
    doc.text("Water ID", 20, 268);
    doc.text(landDetails.waterId !== null ? landDetails.waterId : "--", 100, 268);
    doc.text("Electricity ID", 20, 273);
    doc.text(landDetails.electricityId !== null ? landDetails.electricityId : "--", 100, 273);
    doc.text("Drainage", 20, 278);
    doc.text(landDetails.drainage !== null ? landDetails.drainage : "--", 100, 278);
    doc.text("Gas Lines", 20, 283);
    doc.text(landDetails.gasLines !== null ? landDetails.gasLines : "--", 100, 283);


    doc.addPage();

    // doc.addImage(this.otherPage, 'PNG', 0,0,210,297);

    doc.setFont("times");
    doc.setFontType('normal');

    doc.text("Accomadation Details", 20, 17);
    doc.text(landDetails.accomadationDetails !== null ? landDetails.accomadationDetails : "--", 100, 17);
    doc.text("Other Residencies", 20, 23);
    doc.text(landDetails.otherResidencies !== null ? landDetails.otherResidencies : "--", 100, 23);
    doc.text("Income Level ID", 20, 28);
    doc.text(landDetails.incomeLevelId !== null ? landDetails.incomeLevelId : "--", 100, 28);
    doc.text("Management Cost", 20, 33);
    doc.text(landDetails.managementCost !== null ? landDetails.managementCost : "--", 100, 33);
    doc.text("Maintainance", 20, 38);
    doc.text(landDetails.maintainance !== null ? landDetails.maintainance : "--", 100, 38);
    doc.text("Rates", 20, 43);
    doc.text(landDetails.rates !== null ? landDetails.rates : "--", 100, 43);
    doc.text("Net Annual Rent", 20, 48);
    doc.text(landDetails.netAnnualRent !== null ? landDetails.netAnnualRent : "--", 100, 48);
    doc.text("Undivided Shares", 20, 53);
    doc.text(landDetails.undividedShares !== null ? landDetails.undividedShares : "--", 100, 53);
    doc.text("Other", 20, 58);
    doc.text(landDetails.other !== null ? landDetails.other : "--", 100, 58);
    doc.text("Agreement Type ID", 20, 63);
    doc.text(landDetails.agreementTypeId !== null ? landDetails.agreementTypeId : "--", 100, 63);
    doc.text("Sales Agreement No", 20, 68);
    doc.text(landDetails.salesAgreementNo !== null ? landDetails.salesAgreementNo : "--", 100, 68);
    doc.text("Notary", 20, 73);
    doc.text(landDetails.notary !== null ? landDetails.notary : "--", 100, 73);
    doc.text("Date ", 20, 78);
    doc.text(landDetails.date !== null ? landDetails.date : "--", 100, 78);
    doc.text("Value", 20, 83);
    doc.text(landDetails.value !== null ? landDetails.value : "--", 100, 83);

    doc.save('condominiumrequest.pdf');
  }

  generateReportForBuilding(inspectionId: any) {
    this.inspectionCommonModel.code = inspectionId;
    this.inspectionService.inspectionLandBuildingDetailList(this.inspectionCommonModel).subscribe(
      data => {
        this.modelBuilding = data.data;
        this.buildingReport(this.modelBuilding);
      }
    )
  }

  generateReportForRecreation(inspectionId: any) {
    this.inspectionCommonModel.code = inspectionId;
    this.inspectionService.inspectionRecreationChildList(this.inspectionCommonModel).subscribe(
      data => {
        const filteredland = data.data.filter(i => i.inspectionType === Constant.INSPECTION_TYPE_RECREATION_LAND);
        const filteredcondomoinium = data.data.filter(i => i.inspectionType === Constant.INSPECTION_TYPE_RECREATION_CONDOMINIUM);
        const filteredbuilding = data.data.filter(i => i.inspectionType === Constant.INSPECTION_TYPE_RECREATION_LAND_BUILDING);
        if (JSON.stringify(filteredland) !== '[]') {
          this.inspectionCommonModel.code = filteredland[0]['inspectionId'];
          this.inspectionService.inspectionLandDetailList(this.inspectionCommonModel).subscribe(
            data => {
              this.recreationlandreport = data.data;
            }
          )
        }

        if (JSON.stringify(filteredcondomoinium) !== '[]') {
          this.inspectionCommonModel.code = filteredcondomoinium[0]['inspectionId'];
          this.inspectionService.inspectionCondominimumDetailList(this.inspectionCommonModel).subscribe(
            data => {
              this.recreationcondominiumreport = data.data;
            }
          )
        }

        if (JSON.stringify(filteredbuilding) !== '[]') {
          this.inspectionCommonModel.code = filteredbuilding[0]['inspectionId'];
          this.inspectionService.inspectionLandBuildingDetailList(this.inspectionCommonModel).subscribe(
            data => {
              this.recreationbuildingreport = data.data;
            }
          )
        }
        this.recreationReport(this.recreationlandreport, this.recreationcondominiumreport, this.recreationbuildingreport);

      }
    )
  }

  buildingReport(landDetails: any) {
    const doc = new jsPDF();

    // doc.addImage(this.firstPage, 'PNG', 0,0,210,297)
    doc.setFontStyle("bold");
    doc.text("Inspection Request.", 105, 55, null, null, "center");
    doc.line(75,56,135,56);
    doc.setFontSize(10);
    doc.text("Land with Building Request", 20, 65);

    doc.setFont("times");
    doc.setFontType('normal');
    doc.text("Property Type ID", 20, 73);
    doc.text(landDetails.propertyTypeId !== null ? landDetails.propertyTypeId : "--", 100, 73);
    doc.text("Property Sub Type ID", 20, 78);
    doc.text(landDetails.propertySubTypeId !== null ? landDetails.propertySubTypeId : "--", 100, 78);
    doc.text("Valuation Request ID", 20, 83);
    doc.text(landDetails.valuationRequestId !== null ? landDetails.valuationRequestId : "--", 100, 83);
    doc.text("Inspection Type", 20, 88);
    doc.text(landDetails.inspectionType !== null ? landDetails.inspectionType : "--", 100, 88);
    doc.text("Created by", 20, 93);
    doc.text(landDetails.createdBy !== null ? landDetails.createdBy : "--", 100, 93);
    for(let i = 0; i<landDetails.buildingInformationList.length; i++){
      if(i==0){

        doc.setFontStyle("bold");
        doc.setFontSize(14);
        doc.text("Building Details "+ (i+1), 25, 110, null, null, "left");
        doc.line(20,111,190,111);
        doc.setFontSize(10);
        doc.setFont("times");
        doc.setFontType('normal');

        doc.text("Category Property ID", 20, 118);
        doc.text(landDetails.buildingInformationList[i].categoryPropertyId !== null ? landDetails.buildingInformationList[i].categoryPropertyId : "--", 100, 118);
        doc.text("Building No", 20, 123);
        doc.text(landDetails.buildingInformationList[i].buildingNo !== null ? landDetails.buildingInformationList[i].buildingNo : "--", 100, 123);
        doc.text("Building Name", 20, 128);
        doc.text(landDetails.buildingInformationList[i].buildingName !== null ? landDetails.buildingInformationList[i].buildingName : "--", 100, 128);
        doc.text("No of Building", 20, 133);
        doc.text(landDetails.buildingInformationList[i].noOfBuildings !== null ? landDetails.buildingInformationList[i].noOfBuildings : "--", 100, 133);
        doc.text("Total Completion", 20, 138);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.totalCompletion !== "" ? landDetails.buildingInformationList[i].inspectionBuildingDetails.totalCompletion : "--", 100, 138);


        doc.setFontStyle("bold");
        doc.setFontSize(12);
        doc.text("Building Features", 25, 165, null, null, "left");
        doc.line(20,166,190,166);
        doc.setFontSize(10);
        doc.setFont("times");
        doc.setFontType('normal');

        doc.text("Materials", 20, 173);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.materials !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.materials : "--", 100, 173);
        doc.text("Fittings", 20, 178);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.fittings !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.fittings : "--", 100, 178);
        doc.text("Kitchen Pantry", 20, 183);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.kitchenPantry !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.kitchenPantry : "--", 100, 183);
        doc.text("Pantry Cupboards", 20, 188);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.pantryCupboards !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.pantryCupboards : "--", 100, 188);

        doc.setFontStyle("bold");
        doc.setFontSize(12);
        doc.text("Other Details Of The Building", 25, 215, null, null, "left");
        doc.line(20,216,190,216);
        doc.setFontSize(10);
        doc.setFont("times");
        doc.setFontType('normal');

        doc.text("Inspection No of Buildings", 20, 223);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.inspectionNoOfBuildings !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.inspectionNoOfBuildings : "--", 100, 223);
        doc.text("Incompleted Floors", 20, 228);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.incompletedFloors !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.incompletedFloors : "--", 100, 228);
        doc.text("Completed Floors", 20, 233);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.completedFloors !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.completedFloors : "--", 100, 233);
        doc.text("Total Completed Ratio", 20, 238);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.totalCompletedRatio !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.totalCompletedRatio : "--", 100, 238);
        doc.text("Usage ID", 20, 243);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.usageId !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.usageId : "--", 100, 243);
      }else{

        // doc.addImage(this.otherPage, 'PNG', 0,0,210,297);

        doc.setFontStyle("bold");
        doc.setFontSize(14);
        doc.text("Building Details "+ (i+1), 25, 20, null, null, "left");
        doc.line(20,21,190,21);
        doc.setFontSize(10);
        doc.setFont("times");
        doc.setFontType('normal');

        doc.text("Category Property ID", 20, 28);
        doc.text(landDetails.buildingInformationList[i].categoryPropertyId !== "" ? landDetails.buildingInformationList[i].categoryPropertyId : "--", 100, 28);
        doc.text("Building No", 20, 33);
        doc.text(landDetails.buildingInformationList[i].buildingNo !== "" ? landDetails.buildingInformationList[i].buildingNo : "--", 100, 33);
        doc.text("Building Name", 20, 38);
        doc.text(landDetails.buildingInformationList[i].buildingName !== "" ? landDetails.buildingInformationList[i].buildingName : "--", 100, 38);
        doc.text("No of Building", 20, 43);
        doc.text(landDetails.buildingInformationList[i].noOfBuildings !== null ? landDetails.buildingInformationList[i].noOfBuildings : "--", 100, 43);
        doc.text("Total Completion", 20, 48);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.totalCompletion !== "" ? landDetails.buildingInformationList[i].inspectionBuildingDetails.totalCompletion : "--", 100, 48);

        doc.setFontStyle("bold");
        doc.setFontSize(12);
        doc.text("Building Features", 25, 75, null, null, "left");
        doc.line(20,76,190,76);
        doc.setFontSize(10);
        doc.setFont("times");
        doc.setFontType('normal');

        doc.text("Materials", 20, 83);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.materials !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.materials : "--", 100, 83);
        doc.text("Fittings", 20, 88);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.fittings !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.fittings : "--", 100, 88);
        doc.text("Kitchen Pantry", 20, 93);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.kitchenPantry !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.kitchenPantry : "--", 100, 93);
        doc.text("Pantry Cupboards", 20, 98);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.pantryCupboards !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.pantryCupboards : "--", 100, 98);

        doc.setFontStyle("bold");
        doc.setFontSize(12);
        doc.text("Other Details Of The Building", 25, 125, null, null, "left");
        doc.line(20,126,190,126);
        doc.setFontSize(10);
        doc.setFont("times");
        doc.setFontType('normal');

        doc.text("Inspection No of Buildings", 20, 133);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.inspectionNoOfBuildings !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.inspectionNoOfBuildings : "--", 100, 133);
        doc.text("Incompleted Floors", 20, 138);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.incompletedFloors !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.incompletedFloors : "--", 100, 138);
        doc.text("Completed Floors", 20, 143);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.completedFloors !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.completedFloors : "--", 100, 143);
        doc.text("Total Completed Ratio", 20, 148);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.totalCompletedRatio !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.totalCompletedRatio : "--", 100, 148);
        doc.text("Usage ID", 20, 153);
        doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.usageId !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.usageId : "--", 100, 153);
      }

      if(i!=landDetails.buildingInformationList.length-1){
        doc.addPage();
      }
    }


    doc.save('landbuildingrequest.pdf');
  }

  _prepare() {
    this.getAppealCount();
    this.valuationdata = JSON.parse(sessionStorage.getItem('valuationRequest'));
    this.pad(this.valuationdata.id, 7);
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      unSelectAllText: "Unselect"
    };

    this.fileNumberFormat = localStorage.getItem('fileNumberFormat');
    if (this.fileNumberFormat == "null" || this.fileNumberFormat == '') {
      this.fileNumberFormatAvailable = false;
    }

    this.inspectionCommonModel.code = this.route.snapshot.paramMap.get('id');
    this.loadPropertyType();
    this.loadPropertySubType();
    this.retrieveInspectionList();
  }

  retrieveInspectionList() {
    this.inspectionService.inspectionList(this.inspectionCommonModel).subscribe(
      data => {
        this.inspectionList = data.data;
        console.log(this.inspectionList);
      }
    )
  }

  getAppealCount(){
    this.valuationRequestService.getAppealCount(this.route.snapshot.paramMap.get('id')).subscribe(data=>{
      this.appealCount = data.data;
    })
  }

  loadPropertyType() {
    this.inspectionCommonService.propertyUsageTypeList().subscribe(
      data => {
        this.propertyTypeList = data.data;
        const filtereddata = this.propertyTypeList.filter(i => i.id !== Constant.INSPECTION_PROPERTY_TYPE_RECREATION_ID);
        this.filteredSubPropertyTypeRecreationList = filtereddata;
      }
    )
  }

  loadPropertySubType() {
    this.inspectionCommonService.propertyUsageSubTypeList().subscribe(
      data => {
        this.propertySubTypeList = data.data;
        this.filteredPropertySubTypeList = data.data;
      }
    )
  }

  selectedPropertyType(event) {
    if (Constant.INSPECTION_PROPERTY_TYPE_RECREATION_ID === Number(event)) {
      this.selectedRecreationType = true;
      this.disableGeneratePage = false;
    } else {
      this.selectedRecreationType = false;
      const filteredPropertyType = this.propertyTypeList.filter(i => i.id === Number(event));
      localStorage.setItem('propertyType', btoa(JSON.stringify(filteredPropertyType[0])));
      this.filteredPropertySubTypeList = this.propertySubTypeList;
      const filtereddata = this.filteredPropertySubTypeList.filter(i => i.inspectionPropertyType.id === Number(event));
      this.filteredPropertySubTypeList = filtereddata;
      this.subPropertyTypeDisabled = false;
      if (Number(event) === 1) {
        this.redirectPageNumber = 11;
      } else if (Number(event) === 2) {
        this.redirectPageNumber = 12;
      } else if (Number(event) === 3) {
        this.redirectPageNumber = 13;
      }
    }
  }

  selectedSubPropertyType(event) {
    this.disableGeneratePage = false;
    const filteredSubPropertyType = this.propertySubTypeList.filter(i => i.id === Number(event));
    localStorage.setItem('subPropertyType', btoa(JSON.stringify(filteredSubPropertyType[0])));
  }

  public redirectBack() {
    this.onPageCountChanged.emit(9);
  }

  public redirectComponentErrorMessage() {
    this.notifier.notify('error', 'Please select property type and sub property type to continue.');
  }

  public redirectComponent(): void {
    if (this.disableGeneratePage == true) {
      this.notifier.notify('error', 'Please select a Property type and a Sub Property type');
    } else {
      localStorage.setItem("isLand", JSON.stringify(false));
      localStorage.setItem("isCondominium", JSON.stringify(false));
      localStorage.setItem("inspectioncondominiummodel", btoa(JSON.stringify(null)));
      localStorage.setItem("inspectionlandmodel", btoa(JSON.stringify(null)));
      localStorage.setItem("inspectionRecreationlandmodel", JSON.stringify(null));
      localStorage.setItem("inspectionRecreationCondominiummodel", JSON.stringify(null));
      localStorage.setItem('inspectionLandBuilding-land', JSON.stringify(null));
      localStorage.setItem('inspectionLandBuilding-Building', JSON.stringify(null));
      localStorage.setItem('landwithbuilding', JSON.stringify(false));
      localStorage.setItem('disableLandBuildingLandSubType', JSON.stringify(false));
      localStorage.setItem('recreationupdatereq', JSON.stringify(null));
      localStorage.setItem('selectedRecreationUpdateType', btoa(JSON.stringify(false)));
      localStorage.setItem('recreationLandInspectionId', btoa(undefined));
      localStorage.setItem('recreationCondominiumInspectionId', btoa(undefined));
      localStorage.setItem('recreationLandBuildingInspectionId', btoa(undefined));
      localStorage.setItem('inspectionId', btoa(undefined));
      localStorage.setItem('childinspectionId', btoa(undefined));
      localStorage.setItem('recreationGroupId',JSON.stringify(null));
      localStorage.setItem("isLandWithBuildingRecreation", JSON.stringify(false))
      localStorage.setItem("inspectionRecreationLandwithBuilding-landId", null);
      localStorage.setItem("inspectionLandBuilding-Land",null);
      localStorage.setItem("inspectionLandBuilding-Building",null);
      localStorage.setItem('indexSelectedLandBuilding', JSON.stringify(null));


      // Party is set to 1
      sessionStorage.setItem('selectedPartyInExchange', '1');

      if (this.selectedRecreationType) {
        localStorage.setItem('selectedRecreationType', btoa(JSON.stringify(true)));
        localStorage.setItem('recreationDetails',JSON.stringify(false));
        this.filteredSelectedSubPropertyTypeItems = this.selectedSubPropertyTypeItems.sort((obj1, obj2) => {
          if (obj1.id > obj2.id) {
            return 1;
          }
          if (obj1.id < obj2.id) {
            return -1;
          }
          return 0;
        });
        for (let i = 0; i < this.filteredSelectedSubPropertyTypeItems.length; i++) {
          if (this.filteredSelectedSubPropertyTypeItems[i].id === 1) {
            // Redirect to Land component
            this.redirectPageNumber = 16;
            break;
          } else if (this.filteredSelectedSubPropertyTypeItems[i].id === 2) {
            // Redirect to Condominium component
            this.redirectPageNumber = 18;
            break;
          } else if (this.filteredSelectedSubPropertyTypeItems[i].id === 3) {
            // Redirect to Building component
            this.redirectPageNumber = 17;
            break;
          }
        }
        // if (this.filteredSelectedSubPropertyTypeItems.length > 1) {
        // } else {
        //   this.notifier.notify('error', 'Please select atleast 2 types.');
        // }
        localStorage.setItem('propertyType', btoa(JSON.stringify(null)));
        localStorage.setItem('subPropertyType', btoa(JSON.stringify(null)));
        localStorage.setItem('selectedRecreationType', btoa(JSON.stringify(this.selectedRecreationType)));
        localStorage.setItem('selectedRecreationSubPropertyType', btoa(JSON.stringify(this.selectedSubPropertyTypeItems)));
        this.onPageCountChanged.emit(this.redirectPageNumber);
      } else {
        localStorage.setItem('selectedRecreationType', btoa(JSON.stringify(false)));
        this.onPageCountChanged.emit(this.redirectPageNumber);
        localStorage.setItem('selectedRecreationType', btoa(JSON.stringify(false)));
        localStorage.setItem('selectedRecreationSubPropertyType', btoa(JSON.stringify(null)));
      }
    }

    // localStorage.setItem("isLand", JSON.stringify(false));
    // localStorage.setItem("isCondominium", JSON.stringify(false));
    // localStorage.setItem("inspectioncondominiummodel", btoa(JSON.stringify(null)));
    // localStorage.setItem("inspectionlandmodel", btoa(JSON.stringify(null)));
    // localStorage.setItem('landwithbuilding', JSON.stringify(false));
    // localStorage.setItem('recreationupdatereq', JSON.stringify(null));
    // localStorage.setItem('selectedRecreationUpdateType', btoa(JSON.stringify(false)));
    // localStorage.setItem('recreationLandInspectionId', btoa(undefined));
    // localStorage.setItem('recreationCondominiumInspectionId', btoa(undefined));
    // localStorage.setItem('recreationLandBuildingInspectionId', btoa(undefined));
    // localStorage.setItem('inspectionId', btoa(undefined));
    // if (this.selectedRecreationType) {
    //   this.filteredSelectedSubPropertyTypeItems = this.selectedSubPropertyTypeItems.sort((obj1, obj2) => {
    //     if (obj1.id > obj2.id) {
    //       return 1;
    //     }
    //     if (obj1.id < obj2.id) {
    //       return -1;
    //     }
    //     return 0;
    //   });
    //   if (this.filteredSelectedSubPropertyTypeItems.length > 0) {
    //     for (let i = 0; i < this.filteredSelectedSubPropertyTypeItems.length; i++) {
    //       if (this.filteredSelectedSubPropertyTypeItems[i].id === 1) {
    //         this.redirectPageNumber = 11;
    //         break;
    //       } else if (this.filteredSelectedSubPropertyTypeItems[i].id === 2) {
    //         this.redirectPageNumber = 12;
    //         break;
    //       } else if (this.filteredSelectedSubPropertyTypeItems[i].id === 3) {
    //         this.redirectPageNumber = 13;
    //         break;
    //       }
    //     }
    //   }
    //   localStorage.setItem('propertyType', btoa(JSON.stringify(null)));
    //   localStorage.setItem('subPropertyType', btoa(JSON.stringify(null)));
    //   localStorage.setItem('selectedRecreationType', btoa(JSON.stringify(this.selectedRecreationType)));
    //   localStorage.setItem('selectedRecreationSubPropertyType', btoa(JSON.stringify(this.selectedSubPropertyTypeItems)));
    //   this.onPageCountChanged.emit(this.redirectPageNumber);
    // } else {
    //   this.onPageCountChanged.emit(this.redirectPageNumber);
    //   localStorage.setItem('selectedRecreationType', btoa(JSON.stringify(false)));
    //   localStorage.setItem('selectedRecreationSubPropertyType', btoa(JSON.stringify(null)));
    // }
  }

  public redirectToNextComponent(): void {
    this.onPageCountChanged.emit(14);
  }

  recreationReport(landDetails: any, condominiumDetails: any, landBuildingDetails: any) {
    const doc = new jsPDF();
    if (landDetails !== null) {
      // doc.setFontStyle("bold");
      // doc.text("Inspection Request.", 105, 20, null, null, "center");
      // doc.setFontSize(10);
      // doc.text("Land Request", 20, 30)
      // doc.setFont("times");
      // doc.setFontType('normal');
      // doc.text("Property Type ID", 20, 40);
      // doc.text(landDetails.propertyTypeId !== null ? landDetails.propertyTypeId : "--", 100, 40);
      // doc.text("Property Sub Type ID", 20, 50);
      // doc.text(landDetails.propertySubTypeId !== null ? landDetails.propertySubTypeId : "--", 100, 50);
      // doc.text("Valuation Request ID", 20, 60);
      // doc.text(landDetails.valuationRequestId !== null ? landDetails.valuationRequestId : "--", 100, 60);
      // doc.text("Inspection Type", 20, 70);
      // doc.text(landDetails.inspectionType !== null ? landDetails.inspectionType : "--", 100, 70);
      // doc.text("Created by", 20, 80);
      // doc.text(landDetails.createdBy !== null ? landDetails.createdBy : "--", 100, 80);
      // doc.text("Plan No", 20, 90);
      // doc.text(landDetails.planNo !== null ? landDetails.planNo : "--", 100, 90);
      // doc.text("Lot No(s) / Condominium Unit No(s)", 20, 100);
      // doc.text(landDetails.lotNo !== null ? landDetails.lotNo : "--", 100, 100);
      // doc.text("Building line", 20, 110);
      // doc.text(landDetails.buildingLine !== null ? landDetails.buildingLine : "--", 100, 110);
      // doc.text("Street line", 20, 120);
      // doc.text(landDetails.streetLine !== null ? landDetails.streetLine : "--", 100, 120);
      // doc.text("Restriction", 20, 130);
      // doc.text(landDetails.restriction !== null ? landDetails.restriction : "--", 100, 130);
      // doc.text("Reservation ID", 20, 140);
      // doc.text(landDetails.reservationId !== null ? landDetails.reservationId : "--", 100, 140);
      // doc.text("Central Government Rules", 20, 150);
      // doc.text(landDetails.centralGovernmentRules !== null ? landDetails.centralGovernmentRules : "--", 100, 150);
      // doc.text("Local Government Rules", 20, 160);
      // doc.text(landDetails.localGovernmentRules !== null ? landDetails.localGovernmentRules : "--", 100, 160);
      // doc.text("UDA regulations", 20, 170);
      // doc.text(landDetails.udaRegulations !== null ? landDetails.udaRegulations : "--", 100, 170);
      // doc.text("Other Rules", 20, 180);
      // doc.text(landDetails.otherRules !== null ? landDetails.otherRules : "--", 100, 180);
      // doc.text("Assessment No", 20, 190);
      // doc.text(landDetails.assessmentNo !== null ? landDetails.assessmentNo : "--", 100, 190);
      // doc.text("Nearest Town", 20, 200);
      // doc.text(landDetails.nearestTown !== null ? landDetails.nearestTown : "--", 100, 200);
      // doc.text("Access Nearest Town", 20, 210);
      // doc.text(landDetails.accessNearestTown !== null ? landDetails.accessNearestTown : "--", 100, 210);
      // doc.text("Distance Main Road", 20, 220);
      // doc.text(landDetails.distanceMainRd !== null ? landDetails.distanceMainRd : "--", 100, 220);
      // doc.text("Land Mark", 20, 230);
      // doc.text(landDetails.landMark !== null ? landDetails.landMark : "--", 100, 230);
      // doc.text("Road Width", 20, 240);
      // doc.text(landDetails.roadWidth !== null ? landDetails.roadWidth : "--", 100, 240);
      // doc.text("Land Extent", 20, 250);
      // doc.text(landDetails.landExtent !== null ? landDetails.landExtent : "--", 100, 250);
      // doc.text("Land Shape ID", 20, 260);
      // doc.text(landDetails.landShapeId !== null ? landDetails.landShapeId : "--", 100, 260);
      // doc.text("Road Frontage", 20, 270);
      // doc.text(landDetails.roadFrontage !== null ? landDetails.roadFrontage : "--", 100, 270);
      // doc.text("Length", 20, 280);
      // doc.text(landDetails.length !== null ? landDetails.length : "--", 100, 280);
      // doc.text("Width", 20, 290);
      // doc.text(landDetails.width !== null ? landDetails.width : "--", 100, 290);

      // doc.addPage();

      // doc.setFont("times");
      // doc.setFontType('normal');
      // doc.text("Land Location ID", 20, 40);
      // doc.text(landDetails.landLocationId !== null ? landDetails.landLocationId : "--", 100, 40);
      // doc.text("Land Slope ID", 20, 50);
      // doc.text(landDetails.landSlopeId !== null ? landDetails.landSlopeId : "--", 100, 50);
      // doc.text("Water Availability", 20, 60);
      // doc.text(landDetails.waterAvailabilityId !== null ? landDetails.waterAvailabilityId : "--", 100, 60);
      // doc.text("Water ID", 20, 70);
      // doc.text(landDetails.waterId !== null ? landDetails.waterId : "--", 100, 70);
      // doc.text("Boundary North", 20, 80);
      // doc.text(landDetails.boundaryNorth !== null ? landDetails.boundaryNorth : "--", 100, 80);
      // doc.text("Boundary South", 20, 90);
      // doc.text(landDetails.boundarySouth !== null ? landDetails.boundarySouth : "--", 100, 90);
      // doc.text("Boundary West", 20, 100);
      // doc.text(landDetails.boundaryWest !== null ? landDetails.boundaryWest : "--", 100, 100);
      // doc.text("Boundary East", 20, 110);
      // doc.text(landDetails.boundaryEast !== null ? landDetails.boundaryEast : "--", 100, 110);
      // doc.text("Electricity Type ID", 20, 120);
      // doc.text(landDetails.electricityTypeId !== null ? landDetails.electricityTypeId : "--", 100, 120);
      // doc.text("Electricity Sub Type ID", 20, 130);
      // doc.text(landDetails.electricitySubTypeId !== null ? landDetails.electricitySubTypeId : "--", 100, 130);
      // doc.text("Land Suitability ID", 20, 140);
      // doc.text(landDetails.landSuitabilityId !== null ? landDetails.landSuitabilityId : "--", 100, 140);
      // doc.text("Cultivation", 20, 150);
      // doc.text(landDetails.cultivation !== null ? landDetails.cultivation : "--", 100, 150);
      // doc.text("Transport ID", 20, 160);
      // doc.text(landDetails.transportId !== null ? landDetails.transportId : "--", 100, 160);
      // doc.text("Education ID", 20, 170);
      // doc.text(landDetails.educationId !== null ? landDetails.educationId : "--", 100, 170);
      // doc.text("Health ID", 20, 180);
      // doc.text(landDetails.healthId !== null ? landDetails.healthId : "--", 100, 180);
      // doc.text("Industrial ID", 20, 190);
      // doc.text(landDetails.industrialId !== null ? landDetails.industrialId : "--", 100, 190);
      // doc.text("Security ID", 20, 200);
      // doc.text(landDetails.securityId !== null ? landDetails.securityId : "--", 100, 200);
      // doc.text("Telecommunication ID", 20, 210);
      // doc.text(landDetails.telecommunicationId !== null ? landDetails.telecommunicationId : "--", 100, 210);
      // doc.text("Drainage Facility Availability", 20, 220);
      // doc.text(landDetails.drainageFacilityAvailability !== null ? landDetails.drainageFacilityAvailability : "--", 100, 220);
      // doc.text("Garbage Disposal Availability", 20, 230);
      // doc.text(landDetails.garbageDisposalAvailability !== null ? landDetails.garbageDisposalAvailability : "--", 100, 230);
      // doc.text("Community", 20, 240);
      // doc.text(landDetails.community !== null ? landDetails.community : "--", 100, 240);
      // doc.text("Neighbourhood", 20, 250);
      // doc.text(landDetails.neighbourhood !== null ? landDetails.neighbourhood : "--", 100, 250);
      // doc.text("Bus Route", 20, 260);
      // doc.text(landDetails.busRoute !== null ? landDetails.busRoute : "--", 100, 260);
      // doc.text("Railways", 20, 270);
      // doc.text(landDetails.railways !== null ? landDetails.railways : "--", 100, 270);

      // doc.addImage(this.firstPage, 'PNG', 0,0,210,297)
      doc.setFontStyle("bold");
      doc.text("Inspection Request.", 105, 55, null, null, "center");
      doc.line(75,56,135,56);
      doc.setFontSize(10);
      doc.text("Land Request", 20, 65)
      doc.setFont("times");
      doc.setFontType('normal');
      doc.text("Property Type ID", 20, 73);
      doc.text(landDetails.propertyTypeId !== null ? landDetails.propertyTypeId : "--", 100, 73);
      doc.text("Property Sub Type ID", 20, 78);
      doc.text(landDetails.propertySubTypeId !== null ? landDetails.propertySubTypeId : "--", 100, 78);
      doc.text("Valuation Request ID", 20, 83);
      doc.text(landDetails.valuationRequestId !== null ? landDetails.valuationRequestId : "--", 100, 83);
      doc.text("Inspection Type", 20, 88);
      doc.text(landDetails.inspectionType !== null ? landDetails.inspectionType : "--", 100, 88);
      doc.text("Created by", 20, 93);
      doc.text(landDetails.createdBy !== null ? landDetails.createdBy : "--", 100, 93);

      doc.setFontStyle("bold");
      doc.setFontSize(12);
      doc.text("SURVEY DETAILS", 25, 120, null, null, "left");
      doc.line(20,121,190,121);
      doc.setFontSize(10);
      doc.setFont("times");
      doc.setFontType('normal');
      doc.text("Plan No", 20, 128);
      doc.text(landDetails.planNo !== null ? landDetails.planNo : "--", 100, 128);
      doc.text("Lot No(s) / Condominium Unit No(s)", 20, 133);
      doc.text(landDetails.lotNo !== null ? landDetails.lotNo : "--", 100, 133);

      doc.setFontStyle("bold");
      doc.setFontSize(12);
      doc.text("LEGAL ASPECTS", 25, 140, null, null, "left");
      doc.line(20,141,190,141);
      doc.setFontSize(10);
      doc.setFont("times");
      doc.setFontType('normal');
      doc.text("Building line", 20, 148);
      doc.text(landDetails.buildingLine !== null ? landDetails.buildingLine : "--", 100, 148);
      doc.text("Street line", 20, 153);
      doc.text(landDetails.streetLine !== null ? landDetails.streetLine : "--", 100, 153);
      doc.text("Restriction", 20, 158);
      doc.text(landDetails.restriction !== null ? landDetails.restriction : "--", 100, 158);
      doc.text("Reservation ID", 20, 163);
      doc.text(landDetails.reservationId !== null ? landDetails.reservationId : "--", 100, 163);
      doc.text("Central Government Rules", 20, 168);
      doc.text(landDetails.centralGovernmentRules !== null ? landDetails.centralGovernmentRules : "--", 100,  168);
      doc.text("Local Government Rules", 20, 173);
      doc.text(landDetails.localGovernmentRules !== null ? landDetails.localGovernmentRules : "--", 100, 173);
      doc.text("UDA regulations", 20, 178);
      doc.text(landDetails.udaRegulations !== null ? landDetails.udaRegulations : "--", 100, 178);

      doc.setFontStyle("bold");
      doc.setFontSize(12);
      doc.text("LOCATION AND NATURE OF LAND", 25, 185, null, null, "left");
      doc.line(20,186,190,186);
      doc.setFontSize(10);
      doc.setFont("times");
      doc.setFontType('normal');
      doc.text("Other Rules", 20, 193);
      doc.text(landDetails.otherRules !== null ? landDetails.otherRules : "--", 100, 193);
      doc.text("Assessment No", 20, 198);
      doc.text(landDetails.assessmentNo !== null ? landDetails.assessmentNo : "--", 100, 198);
      doc.text("Nearest Town", 20, 203);
      doc.text(landDetails.nearestTown !== null ? landDetails.nearestTown : "--", 100, 203);
      doc.text("Access Nearest Town", 20, 208);
      doc.text(landDetails.accessNearestTown !== null ? landDetails.accessNearestTown : "--", 100, 208);
      doc.text("Distance Main Road", 20, 213);
      doc.text(landDetails.distanceMainRd !== null ? landDetails.distanceMainRd : "--", 100, 213);
      doc.text("Land Mark", 20, 218);
      doc.text(landDetails.landMark !== null ? landDetails.landMark : "--", 100, 218);
      doc.text("Road Width", 20, 223);
      doc.text(landDetails.roadWidth !== null ? landDetails.roadWidth : "--", 100, 223);
      doc.text("Land Extent", 20, 228);
      doc.text(landDetails.landExtent !== null ? landDetails.landExtent : "--", 100, 228);
      doc.text("Land Shape ID", 20, 233);
      doc.text(landDetails.landShapeId !== null ? landDetails.landShapeId : "--", 100, 233);
      doc.text("Road Frontage", 20, 238);
      doc.text(landDetails.roadFrontage !== null ? landDetails.roadFrontage : "--", 100, 238);
      doc.text("Length", 20, 243);
      doc.text(landDetails.length !== null ? landDetails.length : "--", 100, 243);
      doc.text("Width", 20, 248);
      doc.text(landDetails.width !== null ? landDetails.width : "--", 100, 248);
      doc.text("Land Location ID", 20, 253);
      doc.text(landDetails.landLocationId !== null ? landDetails.landLocationId : "--", 100, 253);
      doc.text("Land Slope ID", 20, 258);
      doc.text(landDetails.landSlopeId !== null ? landDetails.landSlopeId : "--", 100, 258);


      doc.addPage();

      // doc.addImage(this.otherPage, 'PNG', 0,0,210,297);

      doc.setFontStyle("bold");
      doc.setFontSize(12);
      doc.text("BOUNDRY AND FACILITIES", 25, 20, null, null, "left");
      doc.line(20,21,190,21);
      doc.setFontSize(10);
      doc.setFont("times");
      doc.setFontType('normal');

      doc.text("Water Availability", 20, 28);
      doc.text(landDetails.waterAvailabilityId !== null ? landDetails.waterAvailabilityId : "--", 100, 28);
      doc.text("Water ID", 20, 33);
      doc.text(landDetails.waterId !== null ? landDetails.waterId : "--", 100, 33);
      doc.text("Boundary North", 20, 38);
      doc.text(landDetails.boundaryNorth !== null ? landDetails.boundaryNorth : "--", 100, 38);
      doc.text("Boundary South", 20, 43);
      doc.text(landDetails.boundarySouth !== null ? landDetails.boundarySouth : "--", 100, 43);
      doc.text("Boundary West", 20, 48);
      doc.text(landDetails.boundaryWest !== null ? landDetails.boundaryWest : "--", 100, 48);
      doc.text("Boundary East", 20, 53);
      doc.text(landDetails.boundaryEast !== null ? landDetails.boundaryEast : "--", 100, 53);
      doc.text("Electricity Type ID", 20, 58);
      doc.text(landDetails.electricityTypeId !== null ? landDetails.electricityTypeId : "--", 100, 58);
      doc.text("Electricity Sub Type ID", 20, 63);
      doc.text(landDetails.electricitySubTypeId !== null ? landDetails.electricitySubTypeId : "--", 100, 63);
      doc.text("Land Suitability ID", 20, 68);
      doc.text(landDetails.landSuitabilityId !== null ? landDetails.landSuitabilityId : "--", 100, 68);
      doc.text("Cultivation", 20, 73);
      doc.text(landDetails.cultivation !== null ? landDetails.cultivation : "--", 100, 73);


      doc.setFontStyle("bold");
      doc.setFontSize(12);
      doc.text("INFRASTRUCTURE & FUTURE DEVELOPMENT", 25, 85, null, null, "left");
      doc.line(20,86,190,86);
      doc.setFontSize(10);
      doc.setFont("times");
      doc.setFontType('normal');

      doc.text("Transport ID", 20, 93);
      doc.text(landDetails.transportId !== null ? landDetails.transportId : "--", 100, 93);
      doc.text("Education ID", 20, 98);
      doc.text(landDetails.educationId !== null ? landDetails.educationId : "--", 100, 98);
      doc.text("Health ID", 20, 103);
      doc.text(landDetails.healthId !== null ? landDetails.healthId : "--", 100, 103);
      doc.text("Industrial ID", 20, 108);
      doc.text(landDetails.industrialId !== null ? landDetails.industrialId : "--", 100, 108);
      doc.text("Security ID", 20, 113);
      doc.text(landDetails.securityId !== null ? landDetails.securityId : "--", 100, 113);
      doc.text("Telecommunication ID", 20, 118);
      doc.text(landDetails.telecommunicationId !== null ? landDetails.telecommunicationId : "--", 100, 118);
      doc.text("Drainage Facility Availability", 20, 123);
      doc.text(landDetails.drainageFacilityAvailability !== null ? landDetails.drainageFacilityAvailability : "--", 100, 123);
      doc.text("Garbage Disposal Availability", 20, 128);
      doc.text(landDetails.garbageDisposalAvailability !== null ? landDetails.garbageDisposalAvailability : "--", 100, 128);
      doc.text("Community", 20, 133);
      doc.text(landDetails.community !== null ? landDetails.community : "--", 100, 133);
      doc.text("Neighbourhood", 20, 138);
      doc.text(landDetails.neighbourhood !== null ? landDetails.neighbourhood : "--", 100, 138);
      doc.text("Bus Route", 20, 143);
      doc.text(landDetails.busRoute !== null ? landDetails.busRoute : "--", 100, 143);
      doc.text("Railways", 20, 148);
      doc.text(landDetails.railways !== null ? landDetails.railways : "--", 100, 148);
      doc.addPage();
    }

    if (condominiumDetails !== null) {
      // doc.setFontStyle("bold");
      // doc.text("Inspection Request.", 105, 20, null, null, "center");
      // doc.setFontSize(10);
      // doc.text("Condominium Request", 20, 30)
      // doc.setFont("times");
      // doc.setFontType('normal');
      // doc.text("Property Type ID", 20, 40);
      // // doc.text(condominiumDetails.propertyTypeId !== null ? condominiumDetails.propertyTypeId : "--", 100, 40);
      // doc.text("Property Sub Type ID", 20, 50);
      // // doc.text(condominiumDetails.propertySubTypeId !== null ? condominiumDetails.propertySubTypeId : "--", 100, 50);
      // doc.text("Valuation Request ID", 20, 60);
      // // doc.text(condominiumDetails.valuationRequestId !== null ? condominiumDetails.valuationRequestId : "--", 100, 60);
      // doc.text("Inspection Type", 20, 70);
      // // doc.text(condominiumDetails.inspectionType !== null ? condominiumDetails.inspectionType : "--", 100, 70);
      // doc.text("Created by", 20, 80);
      // doc.text(condominiumDetails.createdBy !== null ? condominiumDetails.createdBy : "--", 100, 80);
      // doc.text("Plan No", 20, 90);
      // doc.text(condominiumDetails.planNo !== null ? condominiumDetails.planNo : "--", 100, 90);
      // doc.text("Lot No(s) / Condominium Unit No(s)", 20, 100);
      // doc.text(condominiumDetails.lotNo !== null ? condominiumDetails.lotNo : "--", 100, 100);
      // doc.text("Condominium Name", 20, 110);
      // // doc.text(condominiumDetails.condominiumName !== null ? condominiumDetails.condominiumName : "--", 100, 110);
      // doc.text("Assessment No", 20, 120);
      // doc.text(condominiumDetails.assessmentNo !== null ? condominiumDetails.assessmentNo : "--", 100, 120);
      // doc.text("Town ID", 20, 130);
      // doc.text(condominiumDetails.townId !== null ? condominiumDetails.townId : "--", 100, 130);
      // doc.text("Main Road", 20, 140);
      // doc.text(condominiumDetails.mainRoad !== null ? condominiumDetails.mainRoad : "--", 100, 140);
      // doc.text("Distance Main Road", 20, 150);
      // // doc.text(condominiumDetails.distanceMainRd !== null ? condominiumDetails.distanceMainRd : "--", 100, 150);
      // doc.text("District ID", 20, 160);
      // doc.text(condominiumDetails.districtId !== null ? condominiumDetails.districtId : "--", 100, 160);
      // doc.text("Local Authority ID", 20, 170);
      // // doc.text(condominiumDetails.localAuthorityId !== null ? condominiumDetails.localAuthorityId : "--", 100, 170);
      // doc.text("Use of Condominium", 20, 180);
      // // doc.text(condominiumDetails.useOfCondominium !== null ? condominiumDetails.useOfCondominium : "--", 100, 180);
      // doc.text("Plan Number", 20, 190);
      // doc.text(condominiumDetails.planNumber !== null ? condominiumDetails.planNumber : "--", 100, 190);
      // doc.text("Date Period", 20, 200);
      // doc.text(condominiumDetails.datePeriod !== null ? condominiumDetails.datePeriod : "--", 100, 200);
      // doc.text("Survey Name", 20, 210);
      // doc.text(condominiumDetails.surveyName !== null ? condominiumDetails.surveyName : "--", 100, 210);
      // doc.text("Land Extent", 20, 220);
      // doc.text(condominiumDetails.landExtent !== null ? condominiumDetails.landExtent : "--", 100, 220);
      // doc.text("No of floors", 20, 230);
      // doc.text(condominiumDetails.noOfFloors !== null ? condominiumDetails.noOfFloors : "--", 100, 230);
      // doc.text("No of units", 20, 240);
      // doc.text(condominiumDetails.noOfUnits !== null ? condominiumDetails.noOfUnits : "--", 100, 240);
      // doc.text("Floors and Units", 20, 250);
      // // doc.text(condominiumDetails.floorsAndUnits !== null ? condominiumDetails.floorsAndUnits : "--", 100, 250);
      // doc.text("Other units", 20, 260);
      // doc.text(condominiumDetails.otherUnits !== null ? condominiumDetails.otherUnits : "--", 100, 260);
      // doc.text("Constructed Year", 20, 270);
      // // doc.text(condominiumDetails.constructedYear !== null ? condominiumDetails.constructedYear : "--", 100, 270);
      // doc.text("Parking ID", 20, 280);
      // doc.text(condominiumDetails.parkingId !== null ? condominiumDetails.parkingId : "--", 100, 280);
      // doc.text("Security ID", 20, 290);
      // doc.text(condominiumDetails.securityId !== null ? condominiumDetails.securityId : "--", 100, 290);
//
      // doc.addPage();
//
      // doc.setFont("times");
      // doc.setFontType('normal');
      // doc.text("Unit No", 20, 40);
      // doc.text(condominiumDetails.unitNo !== null ? condominiumDetails.unitNo : "--", 100, 40);
      // doc.text("Extent", 20, 50);
      // doc.text(condominiumDetails.extent !== null ? condominiumDetails.extent : "--", 100, 50);
      // doc.text("Garden ID", 20, 60);
      // doc.text(condominiumDetails.gardenId !== null ? condominiumDetails.gardenId : "--", 100, 60);
      // doc.text("Water ID", 20, 70);
      // doc.text(condominiumDetails.waterId !== null ? condominiumDetails.waterId : "--", 100, 70);
      // doc.text("Electricity ID", 20, 80);
      // doc.text(condominiumDetails.electricityId !== null ? condominiumDetails.electricityId : "--", 100, 80);
      // doc.text("Drainage", 20, 90);
      // doc.text(condominiumDetails.drainage !== null ? condominiumDetails.drainage : "--", 100, 90);
      // doc.text("Gas Lines", 20, 100);
      // doc.text(condominiumDetails.gasLines !== null ? condominiumDetails.gasLines : "--", 100, 100);
      // doc.text("Accomadation Details", 20, 110);
      // // doc.text(condominiumDetails.accomadationDetails !== null ? condominiumDetails.accomadationDetails : "--", 100, 110);
      // doc.text("Other Residencies", 20, 120);
      // // doc.text(condominiumDetails.otherResidencies !== null ? condominiumDetails.otherResidencies : "--", 100, 120);
      // doc.text("Income Level ID", 20, 130);
      // // doc.text(condominiumDetails.incomeLevelId !== null ? condominiumDetails.incomeLevelId : "--", 100, 130);
      // doc.text("Management Cost", 20, 140);
      // // doc.text(condominiumDetails.managementCost !== null ? condominiumDetails.managementCost : "--", 100, 140);
      // doc.text("Maintainance", 20, 150);
      // doc.text(condominiumDetails.maintainance !== null ? condominiumDetails.maintainance : "--", 100, 150);
      // doc.text("Rates", 20, 160);
      // doc.text(condominiumDetails.rates !== null ? condominiumDetails.rates : "--", 100, 160);
      // doc.text("Net Annual Rent", 20, 170);
      // // doc.text(condominiumDetails.netAnnualRent !== null ? condominiumDetails.netAnnualRent : "--", 100, 170);
      // doc.text("Undivided Shares", 20, 180);
      // // doc.text(condominiumDetails.undividedShares !== null ? condominiumDetails.undividedShares : "--", 100, 180);
      // doc.text("Other", 20, 190);
      // doc.text(condominiumDetails.other !== null ? condominiumDetails.other : "--", 100, 190);
      // doc.text("Agreement Type ID", 20, 200);
      // // doc.text(condominiumDetails.agreementTypeId !== null ? condominiumDetails.agreementTypeId : "--", 100, 200);
      // doc.text("Sales Agreement No", 20, 210);
      // // doc.text(condominiumDetails.salesAgreementNo !== null ? condominiumDetails.salesAgreementNo : "--", 100, 210);
      // doc.text("Notary", 20, 220);
      // doc.text(condominiumDetails.notary !== null ? condominiumDetails.notary : "--", 100, 220);
      // doc.text("Date ", 20, 230);
      // doc.text(condominiumDetails.date !== null ? condominiumDetails.date : "--", 100, 230);
      // doc.text("Value", 20, 240);
      // doc.text(condominiumDetails.value !== null ? condominiumDetails.value : "--", 100, 240);


      // doc.addImage(this.firstPage, 'PNG', 0,0,210,297)
      doc.setFontStyle("bold");
      doc.text("Inspection Request.", 105, 55, null, null, "center");
      doc.line(75,56,135,56);

      doc.setFontSize(10);
      doc.text("Condominium Request", 20, 65)
      doc.setFont("times");
      doc.setFontType('normal');
      doc.text("Property Type ID", 20, 73);
      doc.text(landDetails.propertyTypeId !== null ? landDetails.propertyTypeId : "--", 100, 73);
      doc.text("Property Sub Type ID", 20, 78);
      doc.text(landDetails.propertySubTypeId !== null ? landDetails.propertySubTypeId : "--", 100, 78);
      doc.text("Valuation Request ID", 20, 83);
      doc.text(landDetails.valuationRequestId !== null ? landDetails.valuationRequestId : "--", 100, 83);
      doc.text("Inspection Type", 20, 88);
      doc.text(landDetails.inspectionType !== null ? landDetails.inspectionType : "--", 100, 88);
      doc.text("Created by", 20, 93);
      doc.text(landDetails.createdBy !== null ? landDetails.createdBy : "--", 100, 93);

      doc.setFontStyle("bold");
      doc.setFontSize(12);
      doc.text("SURVEY DETAILS", 25, 120, null, null, "left");
      doc.line(20,121,190,121);
      doc.setFontSize(10);
      doc.setFont("times");
      doc.setFontType('normal');
      doc.text("Plan No", 20, 128);
      doc.text(landDetails.planNo !== null ? landDetails.planNo : "--", 100, 128);
      doc.text("Lot No(s) / Condominium Unit No(s)", 20, 133);
      doc.text(landDetails.lotNo !== null ? landDetails.lotNo : "--", 100, 133);

      doc.setFontStyle("bold");
      doc.setFontSize(12);
      doc.text("USE OF THE CONDOMINIUM", 25, 140, null, null, "left");
      doc.line(20,141,190,141);
      doc.setFontSize(10);
      doc.setFont("times");
      doc.setFontType('normal');
      doc.text("Use of Condominium", 20, 147);
      doc.text(landDetails.useOfCondominium !== null ? landDetails.useOfCondominium : "--", 100, 147);

      doc.setFontStyle("bold");
      doc.setFontSize(12);
      doc.text("DETAILS OF THE CONDOMINIUM", 25, 155, null, null, "left");
      doc.line(20,156,190,156);
      doc.setFontSize(10);
      doc.setFont("times");
      doc.setFontType('normal');
      doc.text("Condominium Name", 20, 163);
      doc.text(landDetails.condominiumName !== null ? landDetails.condominiumName : "--", 100, 163);
      doc.text("Assessment No", 20, 168);
      doc.text(landDetails.assessmentNo !== null ? landDetails.assessmentNo : "--", 100, 168);
      doc.text("Town ID", 20, 173);
      doc.text(landDetails.townId !== null ? landDetails.townId : "--", 100, 173);
      doc.text("Main Road", 20, 178);
      doc.text(landDetails.mainRoad !== null ? landDetails.mainRoad : "--", 100, 178);
      doc.text("Distance Main Road", 20, 183);
      doc.text(landDetails.distanceMainRd !== null ? landDetails.distanceMainRd : "--", 100, 183);
      doc.text("District ID", 20, 188);
      doc.text(landDetails.districtId !== null ? landDetails.districtId : "--", 100, 188);
      doc.text("Local Authority ID", 20, 193);
      doc.text(landDetails.localAuthorityId !== null ? landDetails.localAuthorityId : "--", 100, 193);
      doc.text("Plan Number", 20, 198);
      doc.text(landDetails.planNumber !== null ? landDetails.planNumber : "--", 100, 198);
      doc.text("Date Period", 20, 203);
      doc.text(landDetails.datePeriod !== null ? landDetails.datePeriod : "--", 100, 203);
      doc.text("Survey Name", 20, 208);
      doc.text(landDetails.surveyName !== null ? landDetails.surveyName : "--", 100, 208);
      doc.text("Land Extent", 20, 213);
      doc.text(landDetails.landExtent !== null ? landDetails.landExtent : "--", 100, 213);
      doc.text("No of floors", 20, 218);
      doc.text(landDetails.noOfFloors !== null ? landDetails.noOfFloors : "--", 100, 218);
      doc.text("No of units", 20, 223);
      doc.text(landDetails.noOfUnits !== null ? landDetails.noOfUnits : "--", 100, 223);
      doc.text("Floors and Units", 20, 228);
      doc.text(landDetails.floorsAndUnits !== null ? landDetails.floorsAndUnits : "--", 100, 228);
      doc.text("Other units", 20, 233);
      doc.text(landDetails.otherUnits !== null ? landDetails.otherUnits : "--", 100, 233);
      doc.text("Constructed Year", 20, 238);
      doc.text(landDetails.constructedYear !== null ? landDetails.constructedYear : "--", 100, 238);
      doc.text("Parking ID", 20, 243);
      doc.text(landDetails.parkingId !== null ? landDetails.parkingId : "--", 100, 243);
      doc.text("Security ID", 20, 248);
      doc.text(landDetails.securityId !== null ? landDetails.securityId : "--", 100, 248);
      doc.text("Unit No", 20, 253);
      doc.text(landDetails.unitNo !== null ? landDetails.unitNo : "--", 100, 253);
      doc.text("Extent", 20, 258);
      doc.text(landDetails.extent !== null ? landDetails.extent : "--", 100, 258);
      doc.text("Garden ID", 20, 263);
      doc.text(landDetails.gardenId !== null ? landDetails.gardenId : "--", 100, 263);
      doc.text("Water ID", 20, 268);
      doc.text(landDetails.waterId !== null ? landDetails.waterId : "--", 100, 268);
      doc.text("Electricity ID", 20, 273);
      doc.text(landDetails.electricityId !== null ? landDetails.electricityId : "--", 100, 273);
      doc.text("Drainage", 20, 278);
      doc.text(landDetails.drainage !== null ? landDetails.drainage : "--", 100, 278);
      doc.text("Gas Lines", 20, 283);
      doc.text(landDetails.gasLines !== null ? landDetails.gasLines : "--", 100, 283);


      doc.addPage();

      // doc.addImage(this.otherPage, 'PNG', 0,0,210,297);

      doc.setFont("times");
      doc.setFontType('normal');

      doc.text("Accomadation Details", 20, 17);
      doc.text(landDetails.accomadationDetails !== null ? landDetails.accomadationDetails : "--", 100, 17);
      doc.text("Other Residencies", 20, 23);
      doc.text(landDetails.otherResidencies !== null ? landDetails.otherResidencies : "--", 100, 23);
      doc.text("Income Level ID", 20, 28);
      doc.text(landDetails.incomeLevelId !== null ? landDetails.incomeLevelId : "--", 100, 28);
      doc.text("Management Cost", 20, 33);
      doc.text(landDetails.managementCost !== null ? landDetails.managementCost : "--", 100, 33);
      doc.text("Maintainance", 20, 38);
      doc.text(landDetails.maintainance !== null ? landDetails.maintainance : "--", 100, 38);
      doc.text("Rates", 20, 43);
      doc.text(landDetails.rates !== null ? landDetails.rates : "--", 100, 43);
      doc.text("Net Annual Rent", 20, 48);
      doc.text(landDetails.netAnnualRent !== null ? landDetails.netAnnualRent : "--", 100, 48);
      doc.text("Undivided Shares", 20, 53);
      doc.text(landDetails.undividedShares !== null ? landDetails.undividedShares : "--", 100, 53);
      doc.text("Other", 20, 58);
      doc.text(landDetails.other !== null ? landDetails.other : "--", 100, 58);
      doc.text("Agreement Type ID", 20, 63);
      doc.text(landDetails.agreementTypeId !== null ? landDetails.agreementTypeId : "--", 100, 63);
      doc.text("Sales Agreement No", 20, 68);
      doc.text(landDetails.salesAgreementNo !== null ? landDetails.salesAgreementNo : "--", 100, 68);
      doc.text("Notary", 20, 73);
      doc.text(landDetails.notary !== null ? landDetails.notary : "--", 100, 73);
      doc.text("Date ", 20, 78);
      doc.text(landDetails.date !== null ? landDetails.date : "--", 100, 78);
      doc.text("Value", 20, 83);
      doc.text(landDetails.value !== null ? landDetails.value : "--", 100, 83);

      doc.addPage();
    }

    if (landBuildingDetails !== null) {
      // doc.setFontStyle("bold");
      // doc.text("Inspection Request.", 105, 20, null, null, "center");
      // doc.setFontSize(10);
      // doc.text("Land with Building Request", 20, 30)
      // doc.setFont("times");
      // doc.setFontType('normal');
      // doc.text("Property Type ID", 20, 40);
      // doc.text(landBuildingDetails.propertyTypeId !== null ? landBuildingDetails.propertyTypeId : "--", 100, 40);
      // doc.text("Property Sub Type ID", 20, 50);
      // doc.text(landBuildingDetails.propertySubTypeId !== null ? landBuildingDetails.propertySubTypeId : "--", 100, 50);
      // doc.text("Valuation Request ID", 20, 60);
      // doc.text(landBuildingDetails.valuationRequestId !== null ? landBuildingDetails.valuationRequestId : "--", 100, 60);
      // doc.text("Inspection Type", 20, 70);
      // doc.text(landBuildingDetails.inspectionType !== null ? landBuildingDetails.inspectionType : "--", 100, 70);
      // doc.text("Created by", 20, 80);
      // doc.text(landBuildingDetails.createdBy !== null ? landBuildingDetails.createdBy : "--", 100, 80);
      // doc.text("Category Property ID", 20, 90);
      // doc.text(landBuildingDetails.categoryPropertyId !== null ? landBuildingDetails.categoryPropertyId : "--", 100, 90);
      // doc.text("Building No", 20, 100);
      // doc.text(landBuildingDetails.buildingNo !== null ? landBuildingDetails.buildingNo : "--", 100, 100);
      // doc.text("Building Name", 20, 110);
      // doc.text(landBuildingDetails.buildingName !== null ? landBuildingDetails.buildingName : "--", 100, 110);
      // doc.text("No of Building", 20, 120);
      // doc.text(landBuildingDetails.noOfBuildings !== null ? landBuildingDetails.noOfBuildings : "--", 100, 120);
      // doc.text("Total Completion", 20, 130);
      // doc.text(landBuildingDetails.totalCompletion !== null ? landBuildingDetails.totalCompletion : "--", 100, 130);
      // doc.text("Materials", 20, 140);
      // doc.text(landBuildingDetails.materials !== null ? landBuildingDetails.materials : "--", 100, 140);
      // doc.text("Fittings", 20, 150);
      // doc.text(landBuildingDetails.fittings !== null ? landBuildingDetails.fittings : "--", 100, 150);
      // doc.text("Kitchen Pantry", 20, 160);
      // doc.text(landBuildingDetails.kitchenPantry !== null ? landBuildingDetails.kitchenPantry : "--", 100, 160);
      // doc.text("Pantry Cupboards", 20, 170);
      // doc.text(landBuildingDetails.pantryCupboards !== null ? landBuildingDetails.pantryCupboards : "--", 100, 170);
      // doc.text("Inspection No of Buildings", 20, 180);
      // doc.text(landBuildingDetails.inspectionNoOfBuildings !== null ? landBuildingDetails.inspectionNoOfBuildings : "--", 100, 180);
      // doc.text("Incompleted Floors", 20, 190);
      // doc.text(landBuildingDetails.incompletedFloors !== null ? landBuildingDetails.incompletedFloors : "--", 100, 190);
      // doc.text("Completed Floors", 20, 200);
      // doc.text(landBuildingDetails.completedFloors !== null ? landBuildingDetails.completedFloors : "--", 100, 200);
      // doc.text("Total Completed Ratio", 20, 210);
      // doc.text(landBuildingDetails.totalCompletedRatio !== null ? landBuildingDetails.totalCompletedRatio : "--", 100, 210);
      // doc.text("Usage ID", 20, 220);
      // doc.text(landBuildingDetails.usageId !== null ? landBuildingDetails.usageId : "--", 100, 220);

      // doc.addImage(this.firstPage, 'PNG', 0,0,210,297);
      doc.setFontStyle("bold");
      doc.text("Inspection Request.", 105, 55, null, null, "center");
      doc.line(75,56,135,56);
      doc.setFontSize(10);
      doc.text("Land with Building Request", 20, 65);

      doc.setFont("times");
      doc.setFontType('normal');
      doc.text("Property Type ID", 20, 73);
      doc.text(landDetails.propertyTypeId !== null ? landDetails.propertyTypeId : "--", 100, 73);
      doc.text("Property Sub Type ID", 20, 78);
      doc.text(landDetails.propertySubTypeId !== null ? landDetails.propertySubTypeId : "--", 100, 78);
      doc.text("Valuation Request ID", 20, 83);
      doc.text(landDetails.valuationRequestId !== null ? landDetails.valuationRequestId : "--", 100, 83);
      doc.text("Inspection Type", 20, 88);
      doc.text(landDetails.inspectionType !== null ? landDetails.inspectionType : "--", 100, 88);
      doc.text("Created by", 20, 93);
      doc.text(landDetails.createdBy !== null ? landDetails.createdBy : "--", 100, 93);
      for(let i = 0; i<landDetails.buildingInformationList.length; i++){
        if(i==0){

          doc.setFontStyle("bold");
          doc.setFontSize(14);
          doc.text("Building Details "+ (i+1), 25, 110, null, null, "left");
          doc.line(20,111,190,111);
          doc.setFontSize(10);
          doc.setFont("times");
          doc.setFontType('normal');

          doc.text("Category Property ID", 20, 118);
          doc.text(landDetails.buildingInformationList[i].categoryPropertyId !== null ? landDetails.buildingInformationList[i].categoryPropertyId : "--", 100, 118);
          doc.text("Building No", 20, 123);
          doc.text(landDetails.buildingInformationList[i].buildingNo !== null ? landDetails.buildingInformationList[i].buildingNo : "--", 100, 123);
          doc.text("Building Name", 20, 128);
          doc.text(landDetails.buildingInformationList[i].buildingName !== null ? landDetails.buildingInformationList[i].buildingName : "--", 100, 128);
          doc.text("No of Building", 20, 133);
          doc.text(landDetails.buildingInformationList[i].noOfBuildings !== null ? landDetails.buildingInformationList[i].noOfBuildings : "--", 100, 133);
          doc.text("Total Completion", 20, 138);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.totalCompletion !== "" ? landDetails.buildingInformationList[i].inspectionBuildingDetails.totalCompletion : "--", 100, 138);


          doc.setFontStyle("bold");
          doc.setFontSize(12);
          doc.text("Building Features", 25, 165, null, null, "left");
          doc.line(20,166,190,166);
          doc.setFontSize(10);
          doc.setFont("times");
          doc.setFontType('normal');

          doc.text("Materials", 20, 173);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.materials !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.materials : "--", 100, 173);
          doc.text("Fittings", 20, 178);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.fittings !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.fittings : "--", 100, 178);
          doc.text("Kitchen Pantry", 20, 183);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.kitchenPantry !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.kitchenPantry : "--", 100, 183);
          doc.text("Pantry Cupboards", 20, 188);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.pantryCupboards !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.pantryCupboards : "--", 100, 188);

          doc.setFontStyle("bold");
          doc.setFontSize(12);
          doc.text("Other Details Of The Building", 25, 215, null, null, "left");
          doc.line(20,216,190,216);
          doc.setFontSize(10);
          doc.setFont("times");
          doc.setFontType('normal');

          doc.text("Inspection No of Buildings", 20, 223);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.inspectionNoOfBuildings !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.inspectionNoOfBuildings : "--", 100, 223);
          doc.text("Incompleted Floors", 20, 228);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.incompletedFloors !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.incompletedFloors : "--", 100, 228);
          doc.text("Completed Floors", 20, 233);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.completedFloors !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.completedFloors : "--", 100, 233);
          doc.text("Total Completed Ratio", 20, 238);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.totalCompletedRatio !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.totalCompletedRatio : "--", 100, 238);
          doc.text("Usage ID", 20, 243);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.usageId !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.usageId : "--", 100, 243);
        }else{

          // doc.addImage(this.otherPage, 'PNG', 0,0,210,297);

          doc.setFontStyle("bold");
          doc.setFontSize(14);
          doc.text("Building Details "+ (i+1), 25, 20, null, null, "left");
          doc.line(20,21,190,21);
          doc.setFontSize(10);
          doc.setFont("times");
          doc.setFontType('normal');

          doc.text("Category Property ID", 20, 28);
          doc.text(landDetails.buildingInformationList[i].categoryPropertyId !== "" ? landDetails.buildingInformationList[i].categoryPropertyId : "--", 100, 28);
          doc.text("Building No", 20, 33);
          doc.text(landDetails.buildingInformationList[i].buildingNo !== "" ? landDetails.buildingInformationList[i].buildingNo : "--", 100, 33);
          doc.text("Building Name", 20, 38);
          doc.text(landDetails.buildingInformationList[i].buildingName !== "" ? landDetails.buildingInformationList[i].buildingName : "--", 100, 38);
          doc.text("No of Building", 20, 43);
          doc.text(landDetails.buildingInformationList[i].noOfBuildings !== null ? landDetails.buildingInformationList[i].noOfBuildings : "--", 100, 43);
          doc.text("Total Completion", 20, 48);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.totalCompletion !== "" ? landDetails.buildingInformationList[i].inspectionBuildingDetails.totalCompletion : "--", 100, 48);

          doc.setFontStyle("bold");
          doc.setFontSize(12);
          doc.text("Building Features", 25, 75, null, null, "left");
          doc.line(20,76,190,76);
          doc.setFontSize(10);
          doc.setFont("times");
          doc.setFontType('normal');

          doc.text("Materials", 20, 83);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.materials !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.materials : "--", 100, 83);
          doc.text("Fittings", 20, 88);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.fittings !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.fittings : "--", 100, 88);
          doc.text("Kitchen Pantry", 20, 93);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.kitchenPantry !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.kitchenPantry : "--", 100, 93);
          doc.text("Pantry Cupboards", 20, 98);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.pantryCupboards !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.pantryCupboards : "--", 100, 98);

          doc.setFontStyle("bold");
          doc.setFontSize(12);
          doc.text("Other Details Of The Building", 25, 125, null, null, "left");
          doc.line(20,126,190,126);
          doc.setFontSize(10);
          doc.setFont("times");
          doc.setFontType('normal');

          doc.text("Inspection No of Buildings", 20, 133);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.inspectionNoOfBuildings !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.inspectionNoOfBuildings : "--", 100, 133);
          doc.text("Incompleted Floors", 20, 138);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.incompletedFloors !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.incompletedFloors : "--", 100, 138);
          doc.text("Completed Floors", 20, 143);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.completedFloors !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.completedFloors : "--", 100, 143);
          doc.text("Total Completed Ratio", 20, 148);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.totalCompletedRatio !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.totalCompletedRatio : "--", 100, 148);
          doc.text("Usage ID", 20, 153);
          doc.text(landDetails.buildingInformationList[i].inspectionBuildingDetails.usageId !== null ? landDetails.buildingInformationList[i].inspectionBuildingDetails.usageId : "--", 100, 153);
        }

        if(i!=landDetails.buildingInformationList.length-1){
          doc.addPage();
        }
      }
      doc.addPage();
    }

    doc.save('recreationrequest.pdf');
  }
}
