import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DashboardService} from '../../../../../service/dashboard/dashboard.service';

@Component({
  selector: 'app-view-assessment-request-internal',
  templateUrl: './view-assessment-request-internal.component.html',
  styleUrls: ['./view-assessment-request-internal.component.scss']
})
export class ViewAssessmentRequestInternalComponent implements OnInit {
  pendingCount: number;
  completedCount: number;
  rejectedCount: number;
  allRequestCount: number;
  selectValRequest: string;

  type: string;

  constructor(private route: ActivatedRoute,
              private dashboardService: DashboardService) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      console.log(params);
      if (params.get('type') === 'all-request') {
        this.type = 'pending-requests';
      } else {
        this.type = params.get('type');
      }
      this.selectValRequest = this.type;
    });

    this.pendingCountChange();
    this.completedCountChange();
    this.rejectedCountChange();
  }

  allCountChange() {
    this.dashboardService.getRequestCount('all','INTERNAL').subscribe(value => {
      this.allRequestCount = value;
    });
  }

  pendingCountChange() {
    this.dashboardService.getRequestCount('PENDING','INTERNAL').subscribe(value => {
      this.pendingCount = value;
    });
  }

  completedCountChange() {
    this.dashboardService.getRequestCount('COMPLETED','INTERNAL').subscribe(value => {
      this.completedCount = value;
    });
  }

  rejectedCountChange() {
    this.dashboardService.getRequestCount('REJECTED','INTERNAL').subscribe(value => {
      this.rejectedCount = value;
    });
  }


}
