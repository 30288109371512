import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {NotifierService} from "angular-notifier";
import {environment} from "../../../../../../../environments/environment";
import {Observable} from "rxjs/index";

@Injectable({
  providedIn: 'root'
})
export class ReportServiceService {
  private localAuthorityUrl = '/local-authorities';  // URL to web api

  private reportUrl = '/report';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', })
  };

  constructor(private http: HttpClient, private notifier: NotifierService) { }

  //get local Authority
  getAllLA(): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.localAuthorityUrl + '/all');
  }

  //get Assessor
  getAllASS(): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/get_all_assessor');
  }

  // get Allocated Regional Branch
  getAllAB(): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/get_All_Alocated_Regional_Branch');
  }

  getOpinionRegistration(fromDate: string,toDate: string,selectedItems: string,selectedItemsLA: string): Observable<{message: string, data: any}>  {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/opinion_registration_report_data?start_date='+fromDate+'&end_date='+toDate+'&branch='+selectedItems+'&local_authority='+selectedItemsLA);
  }

  // Application Payment Details
  getApplicationPaymentDetails(fromDate: string,toDate: string,selectedItemsLA: string): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/getApplicationPaymentDetails?start_date='+fromDate+'&end_date='+toDate+'&local_authority='+selectedItemsLA);
  }

  //Assessor Distribution Report
  getAssessorDistributionReport(fromDate: string,toDate: string,selectedItemsLA: string,assessor: string,nature_of_deed: string,selectedItems: string): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/getAssessorDistributionReport?start_date='+fromDate+'&end_date='+toDate+'&local_authority='+selectedItemsLA+'&assessor='+assessor+'&nature_of_deed='+nature_of_deed+'&branch='+selectedItems);
  }

  getCertified_Detail_Report(fromDate_endor: string,toDate_endor: string,selectedItemsARB: any,year_of_openion: string,selectedItemsASS: any,fromDate_charge: string,toDate_charge: string): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/getCertified_Detail_Report?fromDate_endor='+fromDate_endor+'&toDate_endor='+toDate_endor+'&selectedItemsARB=' + selectedItemsARB + '&year_of_openion='+year_of_openion+'&selectedItemsASS='+selectedItemsASS+'&fromDate_charge='+fromDate_charge+'&toDate_charge='+toDate_charge);
  }
//?fromDate_endor='+fromDate_endor+'&toDate_endor='+toDate_endor+'&selectedItemsARB='+null+'&year_of_openion='+year_of_openion+'&selectedItemsASS='+null+'&fromDate_charge='+fromDate_charge+'&toDate_charge='+toDate_charge

  // ************ THIWA ADDED SERVICES **************
  getAllGramaNiladhari(){
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/get_grama_niladhari');
  }

  getAllPropertyType(){
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/get_property_type');
  }

  getAllDeedNature(){
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/get_deed_nature');
  }

  getAllLandRegistry(){
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/get_land_registry');
  }

  getAllNotaries(){
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/get-notary-listy');
  }

  getBearLandReport(fromDate_endor: string, toDate_endor: string, local_authority: string, selectedAssessor: string, selectedARB: string){
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/get_bare_land_details?fromDate_endor=' + fromDate_endor + '&toDate_endor=' + toDate_endor + '&local_authority=' + local_authority + '&selectedAssessor=' + selectedAssessor +  '&allocated_regional_branch=' + selectedARB);
  }

  getRegisterDetailsReport(start_date: string, end_date: string, allocated_regional_branch: string, local_authority: string, natureof_deed: string) {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/registration-details-report?start_date=' + start_date + '&end_date=' + end_date + '&allocated_regional_branch=' + allocated_regional_branch + '&local_authority=' + local_authority + '&natureof_deed=' + natureof_deed);
  }

  getMonthlyRequestedReimbursementReport(start_date: string, end_date: string, land_Registry: string, local_authority: string, year: string, taxOfficer: string, status: string) {
    return this.http.get<{ message: string, data: any }>(environment.api_url + this.reportUrl + '/monthly-requested-reimbursemnt-report?start_date=' + start_date + '&end_date=' + end_date + '&land_register=' + land_Registry + '&local_authority=' + local_authority + '&yearSelected=' + year + '&tax_officer=' + taxOfficer + '&statusOf=' + status);
  }

  getCondominiumReport(start_date: string, end_date: string, assessor: string, allocated_regional_branch: string, local_authority: string, condominium_name: string) {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/condominium-report?start_date=' + start_date + '&end_date=' + end_date + '&assessor=' + assessor +  '&allocated_regional_branch=' + allocated_regional_branch + '&local_authority=' + local_authority + '&condominium_name=' + condominium_name);
  }

  getLandRegistryDetailsReport(start_date: string, end_date: string, allocated_regional_branch: string, land_registry: string, local_authority: string, grama_niladhari_division: string, natureof_deed: string, property_type: string) {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/land-registry-details?start_date=' + start_date + '&end_date=' + end_date + '&allocated_regional_branch=' + allocated_regional_branch +  '&land_registry=' + land_registry + '&local_authority=' + local_authority + '&grama_niladhari_division=' + grama_niladhari_division + '&natureof_deed=' + natureof_deed + '&property_type=' + property_type);
  }

  getLandWithBuildReport(start_date: string, end_date: string, local_authority: string, selectedAssessor: string, branches: string) {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/land-with-building-report-service?start_date=' + start_date + '&end_date=' + end_date + '&local_authority=' + local_authority +  '&selectedAssessor=' + selectedAssessor +  '&branches=' + branches);
  }

  getNotaryDetailsReport(start_date: string, end_date: string, notary: string, deedNumber: string, selectedItemsARB: string) {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/notary-details-report?start_date=' + start_date + '&end_date=' + end_date + '&notary=' + notary +  '&deedNumber=' + deedNumber + '&selectedItemsARB=' + selectedItemsARB);
  }

  getRejectedAppealsReport(start_date: string, end_date: string, assessor: string, allocated_regional_branch: string, local_authority: string) {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/rejected-appeals-report?start_date=' + start_date + '&end_date=' + end_date + '&assessor=' + assessor +  '&allocated_regional_branch=' + allocated_regional_branch + '&local_authority=' + local_authority);
  }

  getRejectedApplicationReport(start_date: string, end_date: string): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/rejected-application-report?start_date=' + start_date + '&end_date=' + end_date);
  }
  getSettledAppealsSummaryReport(start_date: string, end_date: string, local_authority: string, method: string, assessor: string, branch: string) {
    console.log()
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/settled-appeals-details-summary?start_date=' + start_date + '&end_date=' + end_date + '&method=' + method  + '&assessor=' + assessor + '&branch=' + branch + '&local_authority=' + local_authority);
  }

  getAppPymntRejectReport(start_date: string, end_date: string, local_authority: string) {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/app-payment-rejected-details?start_date=' + start_date + '&end_date=' + end_date + '&local_authority=' + local_authority);
  }

  getCertifiedOpinionSummaryReport(start_date: string, end_date: string, branch: string, period: string) {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/certified-opinion-summary?start_date=' + start_date + '&end_date=' + end_date + '&branch=' + branch + '&period=' + period);
  }

  getCertifiedOpinionSummaryCountReport(start_date: string, end_date: string, branch: string, period: string) {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/certified-opinion-summary-count?start_date=' + start_date + '&end_date=' + end_date + '&branch=' + branch + '&period=' + period);
  }

  getCertifiedOpinionDetailReport(start_date: string, end_date: string,assessor: string, branch: string, period: string) {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/certified-opinion-details?start_date=' + start_date + '&end_date=' + end_date + '&assessor=' + assessor + '&branch=' + branch + '&period=' + period);
  }

  getNotCertifiedOpinionDetailReport(start_date: string, end_date: string, branch: string, period: string, year: string) {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/not-certified-for-assessor?start_date=' + start_date + '&end_date=' + end_date + '&branch=' + branch + '&period=' + period + '&year=' + year);
  }

  getEvidenceOpinionReport(start_date: string, end_date: string, branch: string, local_authority: string, gn: string, nature_of_deed: string, property_type: string, condo_name: string, notary: string) {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/evidences-opinion?start_date=' + start_date + '&end_date=' + end_date + '&local_authority=' + local_authority + '&branch=' + branch + '&gn=' + gn + '&nature_of_deed=' + nature_of_deed + '&property_type=' + property_type + '&condo_name=' + condo_name + '&notary=' + notary);
  }

  getPaymentPeriodReport(start_date: string, end_date: string, section_type: string) {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/payment-period?start_date=' + start_date + '&end_date=' + end_date  + '&section=' + section_type);
  }
  getCertifiedOpinionCountReport(start_date: string, end_date: string, assessor: string, branch: string, period: string) {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/certified-opinion-count?start_date=' + start_date + '&end_date=' + end_date + '&assessor=' + assessor + '&branch=' + branch + '&period=' + period);
  }

  getFinalBalanceReport(start_date: string, end_date: string, section: string, request_Amount: string) {
    return this.http.get<{ message: string, data: any }>(environment.api_url + this.reportUrl + '/final_balance?start_date=' + start_date + '&end_date=' + end_date + '&section=' + section + '&requested_amount=' +request_Amount);
  }

  getStampDutyRefundApplicationReceivedReport(start_date: string, end_date: string, section: string, requestedAmount: string, reason: string, notary: string) {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/stamp-duty-refund-received?start_date=' + start_date + '&end_date=' + end_date + '&section=' + section + '&requestedAmount=' + requestedAmount + '&reason=' + reason + '&notary=' + notary );
  }

  getTotalReimbursementDetailsReport(start_date: string, end_date: string,land_registry: string, local_authority: string, reimbursement_file_number: string) {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/total-reimbursement-details?start_date=' + start_date + '&end_date=' + end_date + '&land_registry=' + land_registry + '&local_authority=' + local_authority + '&reimbursement_file_number=' + reimbursement_file_number);
  }

  getAllTO(){
    return this.http.get<{message: string, data: any}>(environment.api_url + this.reportUrl + '/get_TO');
  }
}
