import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ReportServiceService} from '../../stamp-duty-opinion-reports/service/report-service.service';
import {NotifierService} from 'angular-notifier';
import * as XLSX from "xlsx";
import {ListItem} from 'ng-multiselect-dropdown/multiselect.model';

@Component({
  selector: 'app-payment-period-report',
  templateUrl: './payment-period-report.component.html',
  styleUrls: ['./payment-period-report.component.scss']
})
export class PaymentPeriodReportComponent implements OnInit, AfterViewInit {

  public download_excel_status: boolean = false;
  selectedItems = [];
  selectedItemsLA = [];
  selectedItemsPT = [];
  dropdownListPT = [];

  inputs: any = {};

  //date range
  fromDate: string = null;
  toDate: string = null;
  currentDate = new Date().toISOString().split('T')[0];


  //section data
  dropdownListST: any = [];
  selectedItemsST: any = [];
  dropdownSettingsST: IDropdownSettings = {};

  section = [
    {id: 1, name: 'Under 65'},
    {id: 2, name: 'Under 101'}
  ];


  // report data
  selectedDetails: any;
  reportDetails = [];
  showPrint: boolean;

  //footer data
  public total_Under_65: number = 0;
  public total_Under_101: number = 0;
  public total_requested_Amount: number = 0;
  public additional_Stamp_Amount:number = 0;

  @ViewChild('TABLE', {static: false}) TABLE: ElementRef;


  constructor(public modalService: NgbModal,
              public reportService: ReportServiceService,
              private notifier: NotifierService) {
  }

  ngOnInit() {

    //setup section dropdown
    this.dropdownSettingsST = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

    this.get_all_section_type();
  }

  ngAfterViewInit(): void {
    this.printReceipt();
  }

  /*************** UI EVENT ***************/
  // event click on close button
  closeFurtherReqRemark() {
    this.modalService.dismissAll();
  }

  createExcel() {
    this.download_excel_status = true;
    this.get_excel_report();
  }

  generatePdfReport() {
    this.get_report();
  }

  get_all_section_type() {
    this.dropdownListST = this.section;
  }

  onItemSelectST($event: ListItem) {

  }

  onSelectAllST($event: Array<ListItem>) {

  }


  captureData(): void {

    this.currentDate = new Date().toISOString().slice(0, 10);

    let lastDate = '';
    if (this.toDate == null || this.toDate == '') {
      lastDate = this.currentDate;
    } else {
      lastDate = this.toDate;
    }

    var date_range = ((this.fromDate == null || this.fromDate == '') && (this.toDate == null || this.toDate == '')) ? 'All Date' : (this.fromDate == null) ? ' to ' + lastDate : this.fromDate + ' to ' + lastDate;

    // capture Section  type
    var selectedS_Names = "";
    var selectedS = "";
    if (this.selectedItemsST != null) {
      this.selectedItemsST.forEach(value => {
        selectedS += value.id + ',';
        selectedS_Names += value.name + ',';
      });

    } else {
      selectedS = "";
      selectedS_Names = "N/A";
    }

    this.inputs = {
      selected_date: date_range,
      selectedS_id: selectedS,
      selectedS_names: selectedS_Names,
    };

  }


  getReportData(): void {
    this.reportService.getPaymentPeriodReport(this.fromDate, this.toDate, this.inputs.selectedS_id).subscribe(value => {
      console.log(value);
      if (value.data.data.length > 0) {
        this.reportDetails = value.data.data;
      } else {
        this.notifier.notify('error', 'No records found!');
        console.log('No data found');
      }
    });
  }

  get_excel_report() {
    if (this.captureData() !== null) {
      this.reportService.getPaymentPeriodReport(this.fromDate, this.toDate, this.inputs.selectedS_id).subscribe(value => {
        this.reportDetails = value.data.data;
        this.total_requested_Amount = value.data.totalRequestedAmount;
        this.total_Under_65 = value.data.totalunderSectionOneAmount;
        this.total_Under_101 = value.data.totalunderSectionTwoAmount;
        this.additional_Stamp_Amount = value.data.totaladditionalStampDutyAmount;

        setTimeout(() => {
          var printContents = document.getElementById('excel_print');
          const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(printContents);
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Payment period.');
          XLSX.writeFile(wb, 'Payment period Report.xlsx');
        }, 1000);
      });
    }
  }

  get_report() {
    if (this.captureData() !== null) {
      this.reportService.getPaymentPeriodReport(this.fromDate, this.toDate, this.inputs.selectedS_id).subscribe(value => {
        this.reportDetails = value.data.data;
        this.total_requested_Amount = value.data.totalRequestedAmount;
        this.total_Under_65 = value.data.totalunderSectionOneAmount;
        this.total_Under_101 = value.data.totalunderSectionTwoAmount;
        this.additional_Stamp_Amount = value.data.totaladditionalStampDutyAmount;
        setTimeout(() => {
          // alert("asd");
          this.printReceipt();
        }, 1000);
      });
    }
  }


  printReceipt(): void {
    this.showPrint = true;
    let printContents: any;
    let popupWin: any;
    printContents = document.getElementById('paymentPeriod').innerHTML;

    popupWin = window.open();
    popupWin.document.open();
    popupWin.document.write(`
    <html>
    <head>
        <title>Payments for the Period</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <style>
          @page {
            size: legal  landscape;
            @bottom-right {
              content: "Page " counter(page) " of " counter(pages);
            }

            .page{
                padding-bottom: 20px;
                page-break-after: always;
            }
          }

            body
            {
                margin: 10mm 10mm 0mm 10mm;

            }
            table{
              border: 1px solid #000;
              border-collapse: collapse;
            }
            tbody{
              page-break-before: always;
            }

            tfoot tr td {
                border: 1px solid #ffffff;
            }

            table th, table td
            {
                padding: 5px;
                margin: 0px;
                border: 1px solid #000;
            }
            .page{
                padding-bottom: 20px;
                page-break-after: always;
            }

        </style>
    </head>
    <body onload="window.print();">
            ${printContents}
    </body>
    </html>`
    );
    popupWin.document.close();
  }


}


