import { Injectable } from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {CompanyCategory} from '../../model/companyCategory';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {NotifierService} from 'angular-notifier';
import {ICompanyCategoryPage} from '../../model/interfaces/company-category-page';

@Injectable({
  providedIn: 'root'
})
export class CompanyCategoryService {

  private companyCategoryUrl = '/company-categories';  // URL to web api

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient,
              private notifier: NotifierService) { }

  /** GET company categories from the server */
  getCompanyCategories(offset: number, limit: number): Observable<ICompanyCategoryPage> {
    // Parameters
    const params = {
      params: {
        offset: offset.toString(),
          limit: limit.toString()
      }
    };

    return this.http.get<ICompanyCategoryPage>(`${environment.api_url}${this.companyCategoryUrl}`, params)
      .pipe(tap(_ => this.log(`get all company categories`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('get categories'))
      );
  }

  getAll(): Observable<{message: string, data: any}> {
    return this.http.get<{message: string, data: any}>(environment.api_url + this.companyCategoryUrl + '/all');

  }

  /** Search company categories from the server */
  searchCompanyCategories(keyword: string, offset: number, limit: number): Observable<ICompanyCategoryPage> {
    // Parameters
    const params = {
      params: {
        offset: offset.toString(),
        limit: limit.toString(),
        keyword
      }
    };

    return this.http.get<ICompanyCategoryPage>(`${environment.api_url}${this.companyCategoryUrl}/search`, params)
      .pipe(tap(_ => this.log(`search company categories`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('get categories'))
      );
  }

  /** POST company categories to the server */
  createCompanyCategory(companyCategory: CompanyCategory): Observable<any> {
    return this.http.post<any>(`${environment.api_url}${this.companyCategoryUrl}`,
      JSON.stringify(companyCategory), this.httpOptions)
      .pipe(tap(_ => this.log(`create company category=${companyCategory}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('company category create'))
      );
  }

  /** PUT: update the company category on the server */
  updateCompanyCategory(id: number, companyCategory: CompanyCategory): Observable<any> {
    return this.http.put(`${environment.api_url}${this.companyCategoryUrl}/${id}`, companyCategory)
      .pipe(tap(_ => this.log(`update company category id=${companyCategory.id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
        catchError(this.handleError<any>('company category update')));
  }

  /** DELETE: delete the company category on the server */
  deleteCompanyCategory(id: number): Observable<any> {
    return this.http.delete(`${environment.api_url}${this.companyCategoryUrl}/${id}`)
      .pipe(tap(_ => this.log(`update company category id=${id}`)),
        catchError(err => {
          this.notifier.notify('error', err);
          return throwError(err);
        }),
      catchError(this.handleError<any>('company category delete'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error.message); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
      // notify user
      this.notifier.notify('error', `${operation} failed`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  /** Log a company category message with the audit service */
  private log(message: string) {
    // Todo: create a service and send operational audit message
  }
}
