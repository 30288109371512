import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-refund-opinion-reports',
  templateUrl: './refund-opinion-reports.component.html',
  styleUrls: ['./refund-opinion-reports.component.scss']
})
export class RefundOpinionReportsComponent implements OnInit {

  @ViewChild('stamp_duty_refund_application_recieved', null) private stamp_duty_refund: TemplateRef<object>;
  @ViewChild('payment_period', null) private payment_period: TemplateRef<object>;
  @ViewChild('rejected_application', null) private rejected_application: TemplateRef<object>;
  @ViewChild('final_balance', null) private final_balance: TemplateRef<object>;

  public showDynamicGrid: boolean;

  constructor(public modalService: NgbModal) {
  }

  ngOnInit() {
    this.showDynamicGrid = false;
  }

  openPopupMessage(content) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      size: 'lg'
    });
  }

  stamp_duty_refund_application_popup() {
    this.showDynamicGrid = true;
    this.openPopupMessage(this.stamp_duty_refund);
  }

  rejected_application_popup() {
    this.showDynamicGrid = true;
    this.openPopupMessage(this.rejected_application);
  }

  payment_period_popup() {
    this.showDynamicGrid = true;
    this.openPopupMessage(this.payment_period);
  }

  final_balance_popup() {
    console.log("Oshdaid");
    this.showDynamicGrid = true;
    this.openPopupMessage(this.final_balance);
  }

}
