import {Component, OnInit, ViewChild} from '@angular/core';
import {ViewDistrictComponent} from './view-district/view-district.component';

@Component({
  selector: 'app-district',
  templateUrl: './district.component.html',
  styleUrls: ['./district.component.scss']
})
export class DistrictComponent implements OnInit {
  @ViewChild(ViewDistrictComponent, {static: false})
  private viewDistrictComponent: ViewDistrictComponent;
  keyword: string;

  constructor() { }

  ngOnInit() {
  }

  triggerSearch(event: any) {
    if (this.keyword === '') {
      this.viewDistrictComponent.onUpdated(true);
    } else {
      this.viewDistrictComponent.searchDistrict(this.keyword);
    }
  }

}
