import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { InspectionCommonDataModel } from 'src/app/model/inspection-master-data-model/inspection-common-data-model';
import { InspectionAgreementTypeService } from 'src/app/service/inspection-master-data-service/inspection-agreement-type/inspection-agreement-type.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-inspection-agreement-type',
  templateUrl: './inspection-agreement-type.component.html',
  styleUrls: ['./inspection-agreement-type.component.scss']
})
export class InspectionAgreementTypeComponent implements OnInit,OnDestroy {


  readonly NoWhitespaceRegExp: RegExp = new RegExp("\\S");
  createAgreementTypeForm: FormGroup;
  updateAgreementTypeForm: FormGroup;
  onlyTextPattern = '^[ a-zA-Z]*$';
  propertyTypeList: any =[];
  activationButton: boolean;
  public createAgreementTypeModel = new InspectionCommonDataModel(null,'','','','');
  public updateAgreementTypeModel = new InspectionCommonDataModel(null,'','','','');

  private sub = new SubSink();
  currentPage: any;
  totalItems: any;
  selectedPageNo: number;
  limit: number;
  keyword:string;
  selectedId:number;
  AgreementTypes: any;
  
  constructor(
    private formBuilder: FormBuilder,
    private inspectionAgreementTypeService: InspectionAgreementTypeService,
    private notifier: NotifierService,
  ) { }

  ngOnInit() {

    this.selectedPageNo = 0;
    this.limit = 10;

    this.createAgreementTypeForm = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['', [Validators.pattern(this.NoWhitespaceRegExp)]],
    });
    this.updateAgreementTypeForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      code: ['', [Validators.required,Validators.pattern(this.NoWhitespaceRegExp)]],
      description: ['', [Validators.pattern(this.NoWhitespaceRegExp)]],
    });

    // this.loadPropertyType();
    this.updateTheTable();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }



  //create Agreement Type - start

  get f() { return this.createAgreementTypeForm.controls; }

  setAgreementTypeModelToSave(){
    this.createAgreementTypeModel.name = this.createAgreementTypeForm.get('name').value;
    this.createAgreementTypeModel.description = this.createAgreementTypeForm.get('description').value;
    this.createAgreementTypeModel.code = this.createAgreementTypeForm.get('code').value;
    this.createAgreementTypeModel.dataStatus = "ACTIVE";
  }

    //save Agreement Type model
  saveAgreementType(){
    this.inspectionAgreementTypeService.getAgreementType(0,this.totalItems).subscribe(res=>{
      const AgreementTypesArray = (res as any).data.content;
      if (AgreementTypesArray.map((agreement)=>String(agreement.name.replace(/\s/g, ""))).includes(String(this.createAgreementTypeForm.get('name').value.replace(/\s/g, "")))){
        this.notifier.notify('error', 'Agreement type name "'+this.createAgreementTypeForm.get('name').value +'" is exist');
        return;
      }
      else{
        if(this.createAgreementTypeForm.valid){
          this.setAgreementTypeModelToSave();
          this.sub.add(
            this.inspectionAgreementTypeService.createAgreementType(this.createAgreementTypeModel).subscribe(data=>{
              this.notifier.notify('success', (data as any).message);
              this.updateTheTable();
              this.reset();
            })
            );
        }else{
          this.f.name.markAsTouched();
          this.f.code.markAsTouched();
        }
  }}) 
  }


  reset(){
    this.createAgreementTypeForm.reset()
  }
  //create Agreement Type - end

  //update Agreement Type - start

    //get update controllers
  get f1() { return this.updateAgreementTypeForm.controls; }

    //set data To Agreement Type Type model
  setAgreementType(agreement:any){
    this.updateAgreementTypeForm.get('id').setValue(agreement.id);
    this.updateAgreementTypeForm.get('name').setValue(agreement.name);
    this.updateAgreementTypeForm.get('description').setValue(agreement.description);
    this.updateAgreementTypeForm.get('code').setValue(agreement.code);

    if(agreement.dataStatus == "ACTIVE"){
      this.activationButton = true;
    }else{
      this.activationButton = false;
    }
  }


    // toggle buttton value change
  changeActivation(){
    if(this.activationButton == true){
      this.activationButton = false;
    }else{
      this.activationButton = true;
    }
  }

    //setting data model to update
  setDataToModel(){
    this.updateAgreementTypeModel.id = this.updateAgreementTypeForm.get('id').value;
    this.updateAgreementTypeModel.name = this.updateAgreementTypeForm.get('name').value;
    this.updateAgreementTypeModel.description = this.updateAgreementTypeForm.get('description').value;
    this.updateAgreementTypeModel.code = this.updateAgreementTypeForm.get('code').value;

    if(this.activationButton == true){
      this.updateAgreementTypeModel.dataStatus = "ACTIVE";
    }else if(this.activationButton == false){
      this.updateAgreementTypeModel.dataStatus = "DEACTIVE";
    }
  }


    //send updated data to db
  updateAgreementType(){
    this.inspectionAgreementTypeService.getAgreementType(0,this.totalItems).subscribe(res=>{
      const AgreementTypesArray = (res as any).data.content;
      if (AgreementTypesArray.map((agreement)=>String(agreement.name.replace(/\s/g, ""))).includes(String(this.updateAgreementTypeForm.get('name').value.replace(/\s/g, "")))){
        this.notifier.notify('error', 'Agreement type name '+this.updateAgreementTypeForm.get('name').value +' is exist');
        return;
      }else{
        if(this.updateAgreementTypeForm.valid){
          this.setDataToModel();
          this.sub.add(this.inspectionAgreementTypeService.updateAgreementType(this.updateAgreementTypeModel.id, this.updateAgreementTypeModel).subscribe(data=>{
            this.notifier.notify('success', (data as any).message);
            this.updateTheTable();
          }))
        }else{
          this.f1.name.markAsTouched();
          this.f1.code.markAsTouched();
        }
      }
  })
  }

  //update Agreement Type - end

//search and view table start
  pageable(i: number) {
    this.inspectionAgreementTypeService.getAgreementType((i-1),10).subscribe(res=>{
      this.AgreementTypes = (res as any).data.content;
      this.currentPage = (res as any).data.number+1;
      this.totalItems = (res as any).data.totalElements;
    })
  }

    // update the table
  updateTheTable() {
    this.sub.add(this.inspectionAgreementTypeService.getAgreementType(0, 10)
      .subscribe(
        (res) => {
          this.AgreementTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }));
  }

    //Search Agreement Type Type
    searchAgreementType(event:any){
      if(this.keyword == ''){
        this.updateTheTable();
      }else{
        this.sub.add(this.inspectionAgreementTypeService.searchAgreementType(this.keyword,0,10).subscribe(res=>{
          this.AgreementTypes = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;
        }))
      }
    }
    //search and view table end

    //delete record -- start
    getSelectedId(id:any){
      this.selectedId = id;
    }
    deleteSelectedRecord(){
      this.sub.add(this.inspectionAgreementTypeService.deleteAgreementType(this.selectedId).subscribe(data=>{

        this.notifier.notify('default', (data as any).message);
        this.updateTheTable();

      }))
    }
    //delete record -- end

}
