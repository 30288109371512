import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {Constant} from '../../../../../util/constant';
import {Payment} from '../../../../../model/payment';
import {Property} from '../../../../../model/property';
import {Loan} from '../../../../../model/loan';
import {DocumentUploadService} from '../../../../../service/document-upload/document-upload.service';
import {ValuationRequestExchange} from '../../../../../model/valuation-request-exchange';
import {ValuationRequest} from '../../../../../model/valuation-request';
import {ValuationRequestService} from '../../../../../service/valuation-request/valuation-request.service';
import {GranteeFrComponent} from '../../../dashboard-external/further-requirement/wizard-fr/grantee-fr/grantee-fr.component';
import {GrantorFrComponent} from '../../../dashboard-external/further-requirement/wizard-fr/grantor-fr/grantor-fr.component';
import {PropertyFrComponent} from '../../../dashboard-external/further-requirement/wizard-fr/property-fr/property-fr.component';
import {OtherFrComponent} from '../../../dashboard-external/further-requirement/wizard-fr/other-fr/other-fr.component';
import {DeclarationFrComponent} from '../../../dashboard-external/further-requirement/wizard-fr/declaration-fr/declaration-fr.component';
import { NotifierService } from 'angular-notifier';
import {SubSink} from 'subsink';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileService } from 'src/app/service/file/file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PDFProgressData } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-exchange-fr',
  templateUrl: './exchange-fr.component.html',
  styleUrls: ['./exchange-fr.component.scss']
})
export class ExchangeFrComponent implements OnInit {

  valuationRequest: ValuationRequest;
  valuationRequest2: ValuationRequest;
  deedNatureStatus: string;
  granteeSelectedPaths = [];
  grantorSelectedPaths = [];


  errorList = [];
  documentsIdList1 = [];
  documentsIdList2 = [];
  fileUploadList1 = [] = [];
  fileUploadList2 = [] = [];
  saveButtonFurtherRequirment = 'Submit Further Requirment';
  onlineSelectedPaths = [];


  selectMenu = 1;
  buttonDisabled = false;
  @ViewChild(GranteeFrComponent, { static: false })
  private granteeComponent1: GranteeFrComponent;
  @ViewChild(GrantorFrComponent, { static: false })
  private grantorComponent1: GrantorFrComponent;
  @ViewChild('appproperty1', { static: false })
  private propertyComponent1: PropertyFrComponent;
  @ViewChild('appproperty2', { static: false })
  private propertyComponent2: PropertyFrComponent;
  @ViewChild('otherComponent1', { static: false })
  private otherComponent1: OtherFrComponent;
  @ViewChild('otherComponent2', { static: false })
  private otherComponent2: OtherFrComponent;
  @ViewChild(DeclarationFrComponent, { static: false })
  private declarationComponent: DeclarationFrComponent;
  private sub = new SubSink();

  public updateStatus: string;
  public updateValuationRequestId: number;


  dataDone = false;
  private noDocs = false;
  previewFileData: any;
  image: any;
  selectPathsCollection = [];
  selectPathsCollection2 = [];
  otherSelectedPaths2 = [];
  otherSelectedPaths1 = [];



  constructor(private valuationRequestService: ValuationRequestService,
              private notifier: NotifierService,
              private documentUploadService: DocumentUploadService,
              private spinner: NgxSpinnerService,
              private fileService: FileService,
              public sanitizer: DomSanitizer) {
    const propertyOne = new Property();
    const propertyTwo = new Property();
    const commonPayment = new Payment();
    //
    this.valuationRequest = new ValuationRequest();
    propertyOne.loan = new Loan();
    this.valuationRequest.properties.push(propertyOne);
    this.valuationRequest.payment = commonPayment;
    this.valuationRequest.noOfGrantees = 0;
    this.valuationRequest.noOfGranters = 0;
    this.valuationRequest.stampDuty = 0.0;
    this.valuationRequest.deedNature = 'Exchange';
    this.valuationRequest.valuation = 0.0;
    this.valuationRequest.fileNumberFormat = '';
    //
    this.valuationRequest2 = new ValuationRequest();
    propertyTwo.loan = new Loan();
    this.valuationRequest2.properties.push(propertyTwo);
    this.valuationRequest2.payment = commonPayment;
    this.valuationRequest2.noOfGrantees = 0;
    this.valuationRequest2.noOfGranters = 0;
    this.valuationRequest2.stampDuty = 0.0;
    this.valuationRequest2.deedNature = 'Exchange';
    this.valuationRequest2.valuation = 0.0;
    this.valuationRequest2.fileNumberFormat = '';
  }

  ngOnInit() {
    this.deedNatureStatus = 'Exchange';
    this.updateValuationRequestId = Number(localStorage.getItem('valuation_request_id'));
    this.updateStatus = sessionStorage.getItem('valuationUpdate');
    if (this.updateStatus === 'true') {
      this.loadUpdatedValuationInfo();
    }
  }

  loadUpdatedValuationInfo() {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(this.updateValuationRequestId)
      .subscribe((res) => {
        this.valuationRequest= res.data;
        this.loadExchangeUpdatedValuationInfo(res.data.exchangeId)
      }));
  }

  loadExchangeUpdatedValuationInfo(exchangeId: any) {
    this.sub.add(this.valuationRequestService.findByValuationRequestId(exchangeId)
      .subscribe((res) => {
        this.valuationRequest2 = res.data;
      }));
  }

  addGrantersAndGranteesToValuationRequest() {
    if (this.granteeComponent1.checkGranteeAvailability() && this.grantorComponent1.checkGranterAvailability()) {
      if (!this.granteeComponent1.grantees[0].isGranteeIndividual) {
        this.granteeComponent1.setEveryChildComponentUploadingDocuments();
        this.granteeSelectedPaths = this.granteeComponent1.selectPaths;
      }
      if (!this.grantorComponent1.granters[0].isGrantorIndividual) {
        this.grantorComponent1.setEveryChildComponentUploadingDocuments();
        this.grantorSelectedPaths = this.grantorComponent1.selectPaths;
      }
      this.autoSaving(this.valuationRequest,this.valuationRequest2,'GranteeAndGrantor');

    } else {
      this.notifier.notify('error', 'Please Add Granter and Grantee');
    }
  }

  addPropertiesToValuationRequest() {
    if (this.propertyComponent1.propertyValidationCheck() && this.propertyComponent2.propertyValidationCheck()) {
      this.autoSaving(this.valuationRequest,this.valuationRequest2,'Property');
    }
  }

  addPaymentDetailsToValuationRequest() {
    // if (this.paymentComponent.setPaymentDetailsToValuationRequest()) {
    //   if (this.paymentComponent.paymentMethod === 'Bank-Payment') {
    //     this.paymentComponent.setBankChildComponentUploadingDocuments();
    //   }
    //   if (this.paymentComponent.paymentMethod === 'Cash-Payment') {
    //     this.paymentComponent.setCashChildComponentUploadingDocuments();
    //   }
    //   return true;
    // } else {
    //   return false;
    // }
    return true;
  }

  exchangeAutoSave() {
    if (JSON.parse(localStorage.getItem('first_valuation_request_id')) === null
      && JSON.parse(localStorage.getItem('second_valuation_request_id')) === null) {
      this.saveInitialValuationRequest();
    } else {
      this.updateValuationRequest();
    }
  }

  saveInitialValuationRequest() {
    this.valuationRequest.id = Number(localStorage.getItem('first_valuation_request_id'));
    this.valuationRequest2.id = Number(localStorage.getItem('second_valuation_request_id'));
    this.valuationRequest.created_date = new Date();
    this.valuationRequest2.created_date = new Date();
    this.valuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
    this.valuationRequest.noOfGranters = this.valuationRequest.granters.length;
    this.valuationRequest2.noOfGrantees = this.valuationRequest.granters.length;
    this.valuationRequest2.noOfGranters = this.valuationRequest.grantees.length;
    this.valuationRequest.valuationRequestStatus = Constant.VALUATION_REQUEST_DRAFT_STATUS;
    this.valuationRequest2.valuationRequestStatus = Constant.VALUATION_REQUEST_DRAFT_STATUS;

    const valuationRequestExchange = new ValuationRequestExchange();
    valuationRequestExchange.valuationOne = this.valuationRequest;
    valuationRequestExchange.valuationTwo = this.valuationRequest2;
    this.sub.add(this.valuationRequestService.createValuationRequestExchange(valuationRequestExchange)
      .subscribe(res => {
        localStorage.setItem('first_valuation_request_id', res.data[0]);
        localStorage.setItem('second_valuation_request_id', res.data[1]);
      }));
  }

  updateValuationRequest() {
    // this.valuationRequest.id = Number(localStorage.getItem('first_valuation_request_id'));
    // this.valuationRequest2.id = Number(localStorage.getItem('second_valuation_request_id'));
    // this.valuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
    // this.valuationRequest.noOfGranters = this.valuationRequest.granters.length;
    // this.valuationRequest2.noOfGrantees = this.valuationRequest.granters.length;
    // this.valuationRequest2.noOfGranters = this.valuationRequest.grantees.length;
    // this.valuationRequest.valuationRequestStatus = Constant.VALUATION_REQUEST_DRAFT_STATUS;
    // this.valuationRequest2.valuationRequestStatus = Constant.VALUATION_REQUEST_DRAFT_STATUS;

    // const valuationRequestExchange = new ValuationRequestExchange();
    // valuationRequestExchange.valuationOne = this.valuationRequest;
    // valuationRequestExchange.valuationTwo = this.valuationRequest2;
    // this.sub.add(this.valuationRequestService.createValuationRequestExchange(valuationRequestExchange)
    //   .subscribe(res => {
    //   }));
  }

  // submitValuationRequest() {
  //   try {
  //     if (this.addPaymentDetailsToValuationRequest()) {
  //       this.holdDocumentsUntilValuationRequestLastStep();
  //       this.saveButton = 'Saving..';
  //     } else {
  //       this.fileUploadList1 = [];
  //       this.fileUploadList2 = [];
  //       this.saveButton = 'Submit';
  //       this.notifier.notify('error', 'Please check payment details');
  //     }
  //   } catch (e) {
  //     this.fileUploadList1 = [];
  //     this.fileUploadList2 = [];
  //     this.saveButton = 'Submit';
  //     this.notifier.notify('error', 'Please try again');
  //   }
  // }

  holdDocumentsUntilValuationRequestLastStep() {

    // if (this.paymentComponent.paymentMethod === 'Online-Payment') {
    //   this.paymentComponent.selectPaths.push(this.onlineSelectedPaths);
    // }

    // this.paymentComponent.selectPaths.push(this.otherSelectedPaths1[0]);
    // this.paymentComponent.selectPaths.push(this.otherSelectedPaths1[1]);
    // this.paymentComponent.selectPaths.push(this.otherSelectedPaths1[2]);
    // this.paymentComponent.selectPaths.push(this.otherSelectedPaths1[3]);
    // this.paymentComponent.selectPaths.push(this.otherSelectedPaths1[4]);
    // this.paymentComponent.selectPaths.push(this.otherSelectedPaths1[5]);
    // this.paymentComponent.selectPaths.push(this.otherSelectedPaths1[6]);
    // this.paymentComponent.selectPaths.push(this.otherSelectedPaths1[7]);
    // this.paymentComponent.selectPaths.push(this.granteeSelectedPaths);
    // this.paymentComponent.selectPaths.push(this.grantorSelectedPaths);

    // this.paymentComponent.selectPaths.push(this.otherSelectedPaths2[0]);
    // this.paymentComponent.selectPaths.push(this.otherSelectedPaths2[1]);
    // this.paymentComponent.selectPaths.push(this.otherSelectedPaths2[2]);
    // this.paymentComponent.selectPaths.push(this.otherSelectedPaths2[3]);
    // this.paymentComponent.selectPaths.push(this.otherSelectedPaths2[4]);
    // this.paymentComponent.selectPaths.push(this.otherSelectedPaths2[5]);
    // this.paymentComponent.selectPaths.push(this.otherSelectedPaths2[6]);
    // this.paymentComponent.selectPaths.push(this.otherSelectedPaths2[7]);
    // this.paymentComponent.selectPaths.push(this.grantorSelectedPaths);
    // this.paymentComponent.selectPaths.push(this.granteeSelectedPaths);

    // this.fileUploadList1.push(this.paymentComponent.selectPaths[0]); // payment receipt
    // this.fileUploadList1.push(this.paymentComponent.selectPaths[1]); // other component1 docs
    // this.fileUploadList1.push(this.paymentComponent.selectPaths[2]);
    // this.fileUploadList1.push(this.paymentComponent.selectPaths[3]);
    // this.fileUploadList1.push(this.paymentComponent.selectPaths[4]);
    // this.fileUploadList1.push(this.paymentComponent.selectPaths[5]);
    // this.fileUploadList1.push(this.paymentComponent.selectPaths[6]);
    // this.fileUploadList1.push(this.paymentComponent.selectPaths[7]);
    // this.fileUploadList1.push(this.paymentComponent.selectPaths[8]); // end other
    // this.fileUploadList1.push(this.paymentComponent.selectPaths[9]); // grantor doc
    // this.fileUploadList1.push(this.paymentComponent.selectPaths[10]); // grantor doc

    // this.fileUploadList2.push(this.paymentComponent.selectPaths[0]); // payment receipt
    // this.fileUploadList2.push(this.paymentComponent.selectPaths[11]); // payment receipt
    // this.fileUploadList2.push(this.paymentComponent.selectPaths[12]); // other component2 docs
    // this.fileUploadList2.push(this.paymentComponent.selectPaths[13]);
    // this.fileUploadList2.push(this.paymentComponent.selectPaths[14]);
    // this.fileUploadList2.push(this.paymentComponent.selectPaths[15]);
    // this.fileUploadList2.push(this.paymentComponent.selectPaths[16]);
    // this.fileUploadList2.push(this.paymentComponent.selectPaths[17]);
    // this.fileUploadList2.push(this.paymentComponent.selectPaths[18]);
    // this.fileUploadList2.push(this.paymentComponent.selectPaths[19]);
    // this.fileUploadList2.push(this.paymentComponent.selectPaths[20]); // granter doc

    // this.documentService.uploadValuationRequestDocuments(this.fileUploadList1).subscribe(
    //   response => {
    //     this.documentsIdList1 = (response as any).data;

    //     this.documentService.uploadValuationRequestDocuments(this.fileUploadList2).subscribe(
    //       res => {
    //         this.documentsIdList2 = (res as any).data;
    //         this.valuationRequest.documentsIdList = this.documentsIdList1;
    //         this.valuationRequest2.documentsIdList = this.documentsIdList2;
    //         this.valuationRequest.created_date = new Date();
    //         this.valuationRequest2.created_date = new Date();
    //         this.valuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
    //         this.valuationRequest.noOfGranters = this.valuationRequest.granters.length;
    //         this.valuationRequest2.noOfGrantees = this.valuationRequest.granters.length;
    //         this.valuationRequest2.noOfGranters = this.valuationRequest.grantees.length;
    //         this.valuationRequest.valuationRequestStatus = Constant.VALUATION_REQUEST_COMPLETE_STATUS;
    //         this.valuationRequest2.valuationRequestStatus = Constant.VALUATION_REQUEST_COMPLETE_STATUS;
    //         const valuationRequestExchange = new ValuationRequestExchange();
    //         valuationRequestExchange.valuationOne = this.valuationRequest;
    //         valuationRequestExchange.valuationTwo = this.valuationRequest2;
    //         this.sub.add(this.valuationRequestService.createValuationRequestExchange(valuationRequestExchange)
    //           .subscribe(response1 => {
    //             const data = response1 as any;
    //             this.notifier.notify('success', 'Request created successfully');
    //             localStorage.removeItem('grantees');
    //             localStorage.removeItem('granters');
    //             localStorage.removeItem('deed');
    //             localStorage.removeItem('assessment');
    //             localStorage.removeItem('extend');
    //             localStorage.removeItem('other');
    //             localStorage.removeItem('loan');
    //             localStorage.removeItem('building');
    //             window.location = data.data.encryptedRequest;
    //           }, (error => {
    //             this.saveButton = 'Submit';
    //             this.notifier.notify('error', 'Please try again');
    //           })));
    //         console.log(this.valuationRequest);
    //       }, err => {
    //         this.saveButton = 'Submit';
    //         this.notifier.notify('error', 'Party 2 Document upload failed...');
    //       });
    //   }, err => {
    //     this.saveButton = 'Submit';
    //     this.notifier.notify('error', 'Party 1 Document upload failed...');
    //   });
  }

  submitValuationRequestFurtherRequirment()
  {
    if (this.declarationComponent.checkOrNot()) {
      console.log(this.valuationRequest);
      this.saveButtonFurtherRequirment = 'Saving..'
      this.valuationRequest.id = Number(localStorage.getItem('valuation_request_id'));

      this.sub.add(this.valuationRequestService.valuationRequestSubmitFurtherRequirement(this.valuationRequest)
          .subscribe(response => {
            // const data = response as any;
            this.notifier.notify('success', 'Further requirement submitted successfully');
            localStorage.removeItem('grantees');
            localStorage.removeItem('granters');
            localStorage.removeItem('deed');
            localStorage.removeItem('assessment');
            localStorage.removeItem('extend');
            localStorage.removeItem('other');
            localStorage.removeItem('loan');
            localStorage.removeItem('building');
            localStorage.removeItem('valuation_request_id');
            // window.location = data.data.encryptedRequest;
            window.location.href = '/dashboard'

          }, (error => {
            this.saveButtonFurtherRequirment = 'Submit Further Requirment';
            this.notifier.notify('error', 'Please try again');
          })));
    } else {
      this.notifier.notify('error', 'Please agree before submitting');
    }

  }

  checkOrNot() {
    if (this.declarationComponent.checkOrNot()) {
      this.selectMenu = this.selectMenu + 1;
    } else {
      this.notifier.notify('error', 'Please be agreed before move payment');
    }
  }

  autoSaving(requestOne : any, requestTwo : any, position : String)
  {
    let object = {
      valuationRequest : requestOne,
      valuationRequestTwo : requestTwo,
      savingPosition : position,
      type : "Exchange"
    }
    console.log(object)

    this.valuationRequestService.valuationRequestAutoSaveFurtherRequirement(object).subscribe(
     res => {
       console.log(res.data);
      this.selectMenu = this.selectMenu + 1;
     },
     err => {}
    );
  }

  checkDocuments() {
    this.errorList = [];

    // For party 1
    // if (this.otherComponent1.fileUploadCopyOfPreviousDeed.filesList.length === 0) {
    //   this.errorList.push('Party 1 : Copy of Previous Deed');
    // }

    // if (this.otherComponent1.fileUploadRoutePlan.filesList3.length === 0) {
    //   this.errorList.push('Party 1 : Route Plan');
    // }

    // if (this.otherComponent1.fileUploadDraftDeed.filesList1.length === 0) {
    //   this.errorList.push('Party 1 : Draft Deed');
    // }

    // if (this.otherComponent1.fileUploadGranteeNic.filesList5.length === 0) {
    //   this.errorList.push('Party 1 : Photo Copy of Grantee\'s NIC');
    // }

    // if (this.otherComponent1.fileUploadSurveyPlan.filesList2.length === 0) {
    //   this.errorList.push('Party 1 :Survey Plan / Building Plan');
    // }


    // // For party 2
    // if (this.otherComponent2.fileUploadCopyOfPreviousDeed.filesList.length === 0) {
    //   this.errorList.push('Party 2 : Copy of Previous Deed');
    // }

    // if (this.otherComponent2.fileUploadRoutePlan.filesList3.length === 0) {
    //   this.errorList.push('Party 2 : Route Plan');
    // }

    // if (this.otherComponent2.fileUploadDraftDeed.filesList1.length === 0) {
    //   this.errorList.push('Party 2 : Draft Deed');
    // }

    // if (this.otherComponent2.fileUploadGranteeNic.filesList5.length === 0) {
    //   this.errorList.push('Party 2 : Photo Copy of Grantee\'s NIC');
    // }

    // if (this.otherComponent2.fileUploadSurveyPlan.filesList2.length === 0) {
    //   this.errorList.push('Party 2 :Survey Plan / Building Plan');
    // }

    if (this.errorList.length === 0) {
      this.otherComponent1.setEveryChildComponentUploadingDocuments();
      this.otherComponent2.setEveryChildComponentUploadingDocuments();
      this.otherSelectedPaths1 = this.otherComponent1.selectPaths;
      this.otherSelectedPaths2 = this.otherComponent2.selectPaths;
      this.saveDocumentsParty1();
      this.saveDocumentsParty2();

      this.selectMenu = this.selectMenu + 1;
    } else {
      const re = /,/gi;
      const errormsg = this.errorList.toString().replace(re, '<br/>');
      Swal.fire({
        title: 'Please upload following required documents to proceed',
        icon: 'error',
        html: '<div style="color: red;text-align: left;">' + errormsg + '</div>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
          'OK',
      });
    }
  }

  saveDocumentsParty1() {
    this.selectPathsCollection.push(this.otherSelectedPaths1[0]);
    this.selectPathsCollection.push(this.otherSelectedPaths1[1]);
    this.selectPathsCollection.push(this.otherSelectedPaths1[2]);
    this.selectPathsCollection.push(this.otherSelectedPaths1[3]);
    this.selectPathsCollection.push(this.otherSelectedPaths1[4]);
    this.selectPathsCollection.push(this.otherSelectedPaths1[5]);
    this.selectPathsCollection.push(this.otherSelectedPaths1[6]);
    this.selectPathsCollection.push(this.otherSelectedPaths1[7]);
    this.selectPathsCollection.push(this.granteeSelectedPaths);
    this.selectPathsCollection.push(this.grantorSelectedPaths);
    this.documentUploadService.uploadValuationRequestFurtherRequirementDocuments(+localStorage.getItem('valuation_request_id'), this.selectPathsCollection).subscribe(
      res => {
        this.documentsIdList1 = (res as any).data;
        this.valuationRequest.documentsIdList = this.documentsIdList1;
        this.valuationRequest.created_date = new Date();
        this.valuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
        this.valuationRequest.noOfGranters = this.valuationRequest.granters.length;
        this.valuationRequest.id = Number(localStorage.getItem('valuation_request_id'));
        this.valuationRequest.valuationRequestStatus = Constant.VALUATION_REQUEST_COMPLETE_STATUS;
      }, err => {
        this.notifier.notify('error', 'Document upload failed...');
      }
    );
  }

  saveDocumentsParty2() {
    this.selectPathsCollection2.push(this.otherSelectedPaths2[0]);
    this.selectPathsCollection2.push(this.otherSelectedPaths2[1]);
    this.selectPathsCollection2.push(this.otherSelectedPaths2[2]);
    this.selectPathsCollection2.push(this.otherSelectedPaths2[3]);
    this.selectPathsCollection2.push(this.otherSelectedPaths2[4]);
    this.selectPathsCollection2.push(this.otherSelectedPaths2[5]);
    this.selectPathsCollection2.push(this.otherSelectedPaths2[6]);
    this.selectPathsCollection2.push(this.otherSelectedPaths2[7]);
    this.selectPathsCollection2.push(this.granteeSelectedPaths);
    this.selectPathsCollection2.push(this.grantorSelectedPaths);
    this.documentUploadService.uploadValuationRequestFurtherRequirementDocuments(+this.valuationRequest.exchangeId, this.selectPathsCollection2).subscribe(
      res => {
        this.documentsIdList2 = (res as any).data;
        this.valuationRequest.documentsIdList = this.documentsIdList2;
        this.valuationRequest.created_date = new Date();
        this.valuationRequest.noOfGrantees = this.valuationRequest.grantees.length;
        this.valuationRequest.noOfGranters = this.valuationRequest.granters.length;
        this.valuationRequest.id = Number(localStorage.getItem('valuation_request_id'));
        this.valuationRequest.valuationRequestStatus = Constant.VALUATION_REQUEST_COMPLETE_STATUS;
      }, err => {
        this.notifier.notify('error', 'Document upload failed...');
      }
    );
  }

      // RETRIEVE DOCUMENTS - START
      onProgress(progressData: PDFProgressData) {
        if (progressData.loaded == progressData.total) {
          this.spinner.hide();
        }
      }

      previewFile(historyId, title) {
        this.spinner.show();
        this.dataDone = false;
        this.noDocs = false;
        this.fileService.loadDocumentsByHistoryId(historyId, title).subscribe(result => {
          if (result) {
            this.image = result.data;
            this.noDocs = false;
            this.dataDone = true;

            setTimeout(() => {
              this.spinner.hide();
            }, 2000);

          } else {
            this.spinner.hide();
            this.dataDone = true;
            this.noDocs = true;
          }

        }, () => {
          this.dataDone = true;
          this.noDocs = true;
          this.spinner.hide();
        });
      }

      imagePreview(index, type, name, base64) {
        return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
      }
      // RETRIEVE DOCUMENTS - END
}
