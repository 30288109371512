import { EventEmitter, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NotifierService } from 'angular-notifier';
import { PDFProgressData } from 'ng2-pdf-viewer';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { RimAccountantDocumentUploadComponent } from 'src/app/component/document/document-upload/rim-accountant-document-upload/rim-accountant-document-upload.component';
import { rimSavePayment } from 'src/app/model/rimSavePayment';
import { BankService } from 'src/app/service/bank/bank.service';
import { DocumentUploadService } from 'src/app/service/document-upload/document-upload.service';
import { FileService } from 'src/app/service/file/file.service';
import { LocalAuthorityService } from 'src/app/service/local-authority/local-authority.service';
import { RimApplicationService } from 'src/app/service/reimbursement/rim-application.service';

@Component({
  selector: 'app-rim-payment-details',
  templateUrl: './rim-payment-details.component.html',
  styleUrls: ['./rim-payment-details.component.scss']
})
export class RimPaymentDetailsComponent implements OnInit {
  @Output() onPageTypeChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('documentAccountantPaymnet', {static: false}) documentAccountantPaymnet: RimAccountantDocumentUploadComponent;

  selectPaths = [];
  selectPathsCollection = [];
  otherSelectedPaths = [];

  noDocs = false;
  dataDone = false;
  previewFileData: any;

  rimNumber:any;
  rimId:number;
  rimPayment:any[]=[];
  paymentDateTime:any[]=[]

  assignedLocalAuthority:any;
  assignedBank:any;
  filterdLocalAuthorties:any;
  isAdvancePayment: boolean = true;

  totalAmount:number = 0.00;
  advanceAmount:number =0.00;
  balanceAmount:number;
  maxDate: Date;

  public rimSavePayment = new rimSavePayment(null,'','',null,'','','',null,null,null,null);

  bankList:any =[];
  localAuthorities:any =[];
  errorList: any[];

  constructor(
    public router: Router,
    private bankService: BankService,
    private formBuilder: FormBuilder,
    private localAuthorityService: LocalAuthorityService,
    private rimService:RimApplicationService,
    private notifier:NotifierService,
    private documentUploadService:DocumentUploadService,
    private spinner: NgxSpinnerService,
    private fileService: FileService,
    public sanitizer: DomSanitizer) {

    this.balanceAmount = this.totalAmount-this.advanceAmount;
    this.rimNumber = localStorage.getItem('rimFileNumberFormat');
    this.rimId = parseInt(sessionStorage.getItem('rimId'));

  }


  paymentForm = this.formBuilder.group({
    localAuthority:["", [Validators.required]],
    startDate:["", [Validators.required]],
    endDate:[''],
    Bank:["", [Validators.required]],
    Branch:["", [Validators.required]],
    chequeNo:[''],
    chequeDate:[''],
    totalAmount1:["", [Validators.required]],
    advanceAmount1:["", [Validators.required]],
    balanceAmount:['0.00'],

  })

  ngOnInit() {
    this._prepare();
    this.assignedLocalAuthority="undifined";
    this.assignedBank="undifined";
    this.maxDate = new Date();
  }

  _prepare(){
    this.getAllBank();
    this.getLocalAuthority();
    this.getRimPaymentDetails();
  }

  getAllBank(){
    this.bankService.getAll().subscribe(data=>{
      this.bankList = data.data;
      console.log(this.bankList)
    })
  }

  getLocalAuthority(){
    this.localAuthorityService.getAll().subscribe(
      data =>{
        this.localAuthorities = data.data;
      }
    )
  }

  selectLocalAuthority(event) {
    //get selected local authority
  }

  selectBank(event) {
    //get select bank
  }

  changePaymentStatus(){
    if(this.isAdvancePayment){
      this.isAdvancePayment = false;
      this.advanceAmount = 0.00;
    }else{
      this.isAdvancePayment = true

    }
  }

  getBalanceAmount(){
    if(this.isAdvancePayment == false){
      this.balanceAmount = this.totalAmount;
    }else{
      this.balanceAmount = (this.totalAmount-this.advanceAmount);
    }
  }

  get f() {
    return this.paymentForm.controls;
  }

  updatePayment(){
    if(this.paymentForm.valid){
      console.log("helllo")
      this.rimSavePayment.advanceAmount = this.paymentForm.controls['advanceAmount1'].value;
      this.rimSavePayment.totalAmount = this.paymentForm.controls['totalAmount1'].value;
      this.rimSavePayment.bankId = parseInt(this.paymentForm.controls['Bank'].value);
      this.rimSavePayment.branch = this.paymentForm.controls['Branch'].value;
      this.rimSavePayment.chequeDate = this.paymentForm.controls['chequeDate'].value;
      this.rimSavePayment.chequeNo = this.paymentForm.controls['chequeNo'].value;
      if(this.isAdvancePayment){
        this.rimSavePayment.isAdvancePayment = true;
      }else{
        this.rimSavePayment.isAdvancePayment =false;
      }
      this.rimSavePayment.localAuthorityId = this.paymentForm.controls['localAuthority'].value;
      this.rimSavePayment.paymentFromDate = this.paymentForm.controls['startDate'].value;
      this.rimSavePayment.paymentToDate = this.paymentForm.controls['endDate'].value;
      this.rimSavePayment.rimId = this.rimId;
      this.rimService.saveRimPayment(this.rimSavePayment).subscribe(
        data => {
          let pamentId= (data as any).data.id;
          this.checkAvailability(pamentId);
          this.notifier.notify('success','Payment Successfully Recoded');
          this.paymentForm.reset();
          this.ngOnInit();
          // window.location.reload();
        }
      )
    }else{
      this.notifier.notify("error", "Please fill the form data…!");
    }

  }

  getRimPaymentDetails(){
    this.rimService.getRimPayment(this.rimId).subscribe(data=>{
      this.rimPayment = data.data;
    })
  }

  setEveryChildComponentDocumentUploaded(){
    this.documentAccountantPaymnet.outputDocumentsAccountantUploadSet();
  }

  setRimAccountanPaymentArray(documents: File[]){
    this.selectPaths.push(documents);
  }

  saveDocuments(paymentId) {
    this.selectPathsCollection.push(this.otherSelectedPaths[0])
    this.documentUploadService.uploadRimAccountantPayment(this.selectPathsCollection,paymentId).subscribe(
      () => {
      },
      () => {
        this.notifier.notify('error', 'Document upload unsuccessful');
      }
    );

  }

  checkAvailability(paymentId){
    this.errorList = [];

    if(this.documentAccountantPaymnet.filesListAccountantUpload.length === 0){
      this.errorList.push('Recipts');
    }
    if(this.errorList.length === 0){
      this.setEveryChildComponentDocumentUploaded();
      this.otherSelectedPaths = this.selectPaths;
      this.saveDocuments(paymentId);

    } else {
      const re = /,/gi;
      const errormsg = this.errorList.toString().replace(re, '<br/>');

      Swal.fire({
        title: 'Please upload following required documents to proceed',
        icon: 'error',
        html: '<div style="color: red;text-align: left;">' + errormsg + '</div>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
          'OK',
      });
    }
  }

  // RETRIEVE DOCUMENTS - START
  onProgress(progressData: PDFProgressData) {
    if (progressData.loaded == progressData.total) {
      this.spinner.hide();
    }
  }

  previewFile(title,id) {
    this.spinner.show();
    this.dataDone = false;
    this.fileService.loadDocumentByRimPaymentId(id, title).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.dataDone = true;

        setTimeout(() => {
          this.spinner.hide();
        }, 2000);

      } else {
        this.spinner.hide();
        this.dataDone = true;
      }

    }, () => {
      this.dataDone = true;
      this.spinner.hide();
    });
  }
  imagePreview(type, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }

  public redirectPreviousComponent(): void {
      this.onPageTypeChanged.emit('payble-nonpayable');
  }

  closePaymentDetails() {
    Swal.fire({
      icon: 'success',
      title: 'Successfully Closed',
      showConfirmButton: false,
      timer: 2000
    });
    this.router.navigate(['/main-dashboard-internal-user']);
  }
}
