import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import {RefundOpinionServiceService} from 'src/app/service/RefundOpinionService/refund-opinion-service.service';
import {refundOtherDetails} from 'src/app/model/refundOtherDetails';
import {NotifierService} from 'angular-notifier';
import {Constant} from 'src/app/util/constant';
import {refundRequestCommon} from 'src/app/model/refundRequestCommon';
import { Router } from '@angular/router';


@Component({
  selector: 'app-refund-application-refund-details',
  templateUrl: './refund-application-refund-details.component.html',
  styleUrls: ['./refund-application-refund-details.component.scss']
})
export class RefundApplicationRefundDetailsComponent implements OnInit {

  @Output() onPageCountChanged: EventEmitter<any> = new EventEmitter<any>();

  hasOpinion: boolean;
  opinionNo = parseInt(localStorage.getItem('opinionNo'));
  fileNo = localStorage.getItem('fileNo');

  refundId = parseInt(localStorage.getItem('refundId'));
  RefNo: string;
  isExists: boolean;
  otherReasonActivate: boolean = true;
  granteeChecked: boolean;
  notaryCheked: boolean;
  notaryDetails: any[];
  grantorDetails: any[];
  reasonDetails: any[];
  granteeCheck = false;
  notaryCheck = false;
  reaasonCheck = false;
  getRefundReasons: any = [];


  public common = new refundRequestCommon('', '', '', '', '', '', '', null, null, null, null, null, null);


  // Validation
  nicPattern = '^([0-9]{9}[x|X|v|V]|[0-9]{12})$';
  phoneNumberPattern = '^(?:0|94|\\+94|0094)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|91)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\\d)\\d{6}$';
  emailPattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
  addressPattern = /^[ a-zA-Z0-9./,-/']*$/;
  cityPattern = '^[ a-zA-Z0-9-.]*$';
  companyRegNoPattern = '^[ a-zA-Z0-9-/#]*$';
  onlyTextPattern = '^[ a-zA-Z]*$';
  onlyNumberPattern = '^[ 0-9]*$';
  nameWithInitialsPattern = '^[ a-zA-Z.]*$';

  otherDetail: refundOtherDetails;

  granteeForm = this.formBuilder.group({
    name_with_initials: ['', [Validators.required, Validators.pattern(this.nameWithInitialsPattern)]],
    nic: ['', [Validators.required, Validators.pattern(this.nicPattern)]],
    address_line_1: ['', [Validators.required, Validators.pattern(this.addressPattern)]],
    contact_no: ['', [Validators.required, Validators.pattern(this.phoneNumberPattern)]],
    office_no: ['', [Validators.required, Validators.pattern(this.phoneNumberPattern)]],
    address_line_2: '',
    city: ['', [Validators.required, Validators.pattern(this.cityPattern)]],
    email: ['', [Validators.required, Validators.email, Validators.pattern(this.emailPattern)]],
  });

  notaryForm = this.formBuilder.group({
    initials: ['', [Validators.required, Validators.pattern(this.nameWithInitialsPattern)]],
    nic_passport: ['', [Validators.required, Validators.pattern(this.nicPattern)]],
    reg_no: ['', Validators.required],
    official_add_l1: ['', [Validators.required, Validators.pattern(this.addressPattern)]],
    office_phone: ['', [Validators.required, Validators.pattern(this.phoneNumberPattern), Validators.minLength(9)]],
    official_add_l2: '',
    official_city: ['', [Validators.required, Validators.pattern(this.cityPattern)]],
    emailNotary: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
  });

  reasonsForm = this.formBuilder.group({
    reason_for_refund: ['undefined', [Validators.required]],
    other_reason_for_refund: [''],
    ammount: ['', [Validators.required, Validators.pattern(this.onlyNumberPattern)]],
    remark: '',
  });

  constructor(private refundService: RefundOpinionServiceService,
              private notifier: NotifierService, private formBuilder: FormBuilder,
              private router:Router
  ) {
    this.hasOpinion = false;
  }

  //create grantee controls
  get grantee() {
    return this.granteeForm.controls;
  }

  //getting notary controls
  get notary() {
    return this.notaryForm.controls;
  }

  //getting reason controls
  get reason() {
    return this.reasonsForm.controls;
  }

  //grantee validity check
  checkGranteeValidity(): boolean {
    if (this.granteeForm.get('name_with_initials').valid && this.granteeForm.get('nic').valid && this.granteeForm.get('address_line_1').valid && this.granteeForm.get('contact_no').valid && this.granteeForm.get('office_no').valid && this.granteeForm.get('city').valid && this.granteeForm.get('email').valid) {
      return true;
    } else {
      this.notifier.notify('error', 'Please fill the form data…!');
      return false;
    }
  }

  //notary validity check
  checkNotaryValidity(): boolean {
    if (this.notaryForm.get('initials').valid && this.notaryForm.get('nic_passport').valid && this.notaryForm.get('reg_no').valid && this.notaryForm.get('official_add_l1').valid && this.notaryForm.get('office_phone').valid && this.notaryForm.get('official_city').valid && this.notaryForm.get('emailNotary').valid) {

      return true;
    } else {
      this.notifier.notify('error', 'Notary form not valid properly');
      return false;
    }
  }

  //Reason validity check
  checkReasonValidity(): boolean {
    if (this.reasonsForm.get('reason_for_refund').value !== 'undefined' && this.reasonsForm.get('other_reason_for_refund').valid && this.reasonsForm.get('ammount').valid) {
      return true;
    } else {
      this.notifier.notify('error', 'Reason form not valid properly');
      return false;
    }
  }

  formValidity() {
    if (this.granteeCheck == false) {
      this.granteeForm.get('name_with_initials').markAsTouched();
      this.granteeForm.get('nic').markAsTouched();
      this.granteeForm.get('address_line_1').markAsTouched();
      this.granteeForm.get('contact_no').markAsTouched();
      this.granteeForm.get('office_no').markAsTouched();
      this.granteeForm.get('city').markAsTouched();
      this.granteeForm.get('email').markAsTouched();
    }
    if (this.notaryCheck == false) {
      this.notaryForm.get('initials').markAsTouched();
      this.notaryForm.get('nic_passport').markAsTouched();
      this.notaryForm.get('reg_no').markAsTouched();
      this.notaryForm.get('official_add_l1').markAsTouched();
      this.notaryForm.get('office_phone').markAsTouched();
      this.notaryForm.get('official_city').markAsTouched();
      this.notaryForm.get('emailNotary').markAsTouched();
    }
    if (this.reaasonCheck == false) {
      this.reasonsForm.get('reason_for_refund').markAsTouched();
      this.reasonsForm.get('other_reason_for_refund').markAsTouched();
      this.reasonsForm.get('ammount').markAsTouched();
    }
  }

  ngOnInit() {
    this.checkAndFilldata();
    this.findRefundReasons();

  }

//this fumction is created for get grantor details
  getGrantorDetails() {
    this.refundService.getRefundGrantorDetails(this.opinionNo).subscribe(data => {
      this.grantorDetails = data.data;

      this.granteeForm.controls['name_with_initials'].setValue(this.grantorDetails[0].name_with_initials);
      this.granteeForm.controls['nic'].setValue(this.grantorDetails[0].nic);
      this.granteeForm.controls['address_line_1'].setValue(this.grantorDetails[0].address_line_1);
      this.granteeForm.controls['address_line_2'].setValue(this.grantorDetails[0].address_line_2);
      this.granteeForm.controls['contact_no'].setValue(this.grantorDetails[0].email);
      this.granteeForm.controls['office_no'].setValue(this.grantorDetails[0].office_no);
      this.granteeForm.controls['city'].setValue(this.grantorDetails[0].city);
      this.granteeForm.controls['email'].setValue(this.grantorDetails[0].contact_no);


    });
  }

//this fumction is created for get notary details
  getNotaryDetails() {
    this.refundService.getRefundNotaryDetails(this.opinionNo).subscribe(data => {
      this.notaryDetails = data.data;

      this.notaryForm.controls['initials'].setValue(this.notaryDetails[0].initials);
      this.notaryForm.controls['nic_passport'].setValue(this.notaryDetails[0].nic_passport);
      this.notaryForm.controls['official_add_l1'].setValue(this.notaryDetails[0].official_add_l1);
      this.notaryForm.controls['official_add_l2'].setValue(this.notaryDetails[0].official_add_l2);
      this.notaryForm.controls['office_phone'].setValue(this.notaryDetails[0].office_phone);
      this.notaryForm.controls['official_city'].setValue(this.notaryDetails[0].official_city);
      this.notaryForm.controls['reg_no'].setValue(this.notaryDetails[0].reg_no);
      this.notaryForm.controls['emailNotary'].setValue(this.notaryDetails[0].email);
    });
  }

//getting last updated details
  getLastUpdatedDetails() {
    this.refundService.getContactPersonDetailsByRefundRequestId(this.refundId).subscribe(data => {
      this.grantorDetails = data.data;


      this.granteeForm.controls['name_with_initials'].setValue(this.grantorDetails['name_with_initials']);
      this.granteeForm.controls['nic'].setValue(this.grantorDetails['nic']);
      this.granteeForm.controls['address_line_1'].setValue(this.grantorDetails['address_line_1']);
      this.granteeForm.controls['address_line_2'].setValue(this.grantorDetails['address_line_2']);
      this.granteeForm.controls['contact_no'].setValue(this.grantorDetails['contact_no']);
      this.granteeForm.controls['office_no'].setValue(this.grantorDetails['contact_no']);
      this.granteeForm.controls['city'].setValue(this.grantorDetails['city']);
      this.granteeForm.controls['email'].setValue(this.grantorDetails['email']);

      this.granteeChecked = this.grantorDetails['isSameGrantee'];


    });

    this.refundService.getNotaryDetailsByRefundRequestId(this.refundId).subscribe(data => {
      this.notaryDetails = data.data;


      this.notaryForm.controls['initials'].setValue(this.notaryDetails['initials']);
      this.notaryForm.controls['nic_passport'].setValue(this.notaryDetails['nic_passport']);
      this.notaryForm.controls['official_add_l1'].setValue(this.notaryDetails['official_add_l1']);
      this.notaryForm.controls['official_add_l2'].setValue(this.notaryDetails['official_add_l2']);
      this.notaryForm.controls['office_phone'].setValue(this.notaryDetails['office_phone']);
      this.notaryForm.controls['official_city'].setValue(this.notaryDetails['official_city']);
      this.notaryForm.controls['reg_no'].setValue(this.notaryDetails['reg_no']);
      this.notaryForm.controls['emailNotary'].setValue(this.notaryDetails['email']);

      this.notaryCheked = this.notaryDetails['isSameNotary'];


    });
    this.refundService.getReasonForRefundContentsByRefundRequestId(this.refundId).subscribe(data => {
      this.reasonDetails = data.data;

      this.reasonsForm.controls['reason_for_refund'].setValue(this.reasonDetails['reason_for_refund']);
      this.reasonsForm.controls['other_reason_for_refund'].setValue(this.reasonDetails['other_reason_for_refund']);
      this.reasonsForm.controls['ammount'].setValue(this.reasonDetails['ammount']);
      this.reasonsForm.controls['remark'].setValue(this.reasonDetails['remark']);

      this.activateOtherReason(this.reasonDetails['reason_for_refund']);
    });
  }

  checkAndFilldata() {
    this.refundService.checkRefundIdExist(this.refundId).subscribe(data => {
      this.isExists = data.data;
      console.log(this.isExists);
      if (this.isExists == true) {

        if (sessionStorage.getItem('hasOpinion') == 'true') {
          this.hasOpinion = true;
          this.getLastUpdatedDetails();


        } else {
          this.hasOpinion = false;
          this.getLastUpdatedDetails();
        }
        console.log('this is true');

      } else {
        if (sessionStorage.getItem('hasOpinion') == 'true') {
          this.hasOpinion = true;
          this.getGrantorDetails();
          this.getNotaryDetails();
          this.granteeChecked = false;
          this.notaryCheked = false;
          this.changeDisableGrantee();
          this.changeDisableNotary();
        } else {
          this.hasOpinion = false;
          this.granteeChecked = false;
          this.notaryCheked = false;

        }
        console.log('this is not true');

      }
    });
  }

//this method used to change disable status of notaryForm
  changeDisableNotary() {
    if (this.notaryCheked == true) {
      this.notaryCheked = false;
      this.notaryForm.reset();

    } else {
      this.notaryCheked = true;
      this.getNotaryDetails();
    }
  }

//this method used to change disable status of granteeForm
  changeDisableGrantee() {
    if (this.granteeChecked == true) {
      this.granteeChecked = false;

      this.granteeForm.reset();
    } else {
      this.granteeChecked = true;

      this.getGrantorDetails();
    }
  }

  submitDetails() {
    this.formValidity();
    if (this.checkGranteeValidity() && this.checkNotaryValidity() && this.checkReasonValidity()) {
      this.otherDetail = new refundOtherDetails();


      // grator details
      if (this.hasOpinion) {
        this.otherDetail.deedNumber = this.opinionNo;
      } else {
        this.otherDetail.deedNumber = null;
      }
      this.otherDetail.name_with_initials = this.granteeForm.get('name_with_initials').value;
      this.otherDetail.nic = this.granteeForm.get('nic').value;
      this.otherDetail.address_line_1 = this.granteeForm.get('address_line_1').value;
      this.otherDetail.address_line_2 = this.granteeForm.get('address_line_2').value;
      this.otherDetail.contact_no = this.granteeForm.get('contact_no').value;
      this.otherDetail.office_no = this.granteeForm.get('office_no').value;
      this.otherDetail.city = this.granteeForm.get('city').value;
      this.otherDetail.email = this.granteeForm.get('email').value;
      //this.otherDetail.status = Constant.REFUND_REQUEST_DRAFT_CODE;

      // notary Details
      this.otherDetail.initials = this.notaryForm.get('initials').value;
      this.otherDetail.nic_passport = this.notaryForm.get('nic_passport').value;
      this.otherDetail.reg_no = this.notaryForm.get('reg_no').value;
      this.otherDetail.official_add_l1 = this.notaryForm.get('official_add_l1').value;
      this.otherDetail.office_phone = this.notaryForm.get('office_phone').value;
      this.otherDetail.official_add_l2 = this.notaryForm.get('official_add_l2').value;
      this.otherDetail.official_city = this.notaryForm.get('official_city').value;
      this.otherDetail.notaryEmail = this.notaryForm.get('emailNotary').value;

      // Refund reason details
      this.otherDetail.other_reason_for_refund = this.reasonsForm.get('other_reason_for_refund').value;
      this.otherDetail.reason_for_refund = this.reasonsForm.get('reason_for_refund').value;
      this.otherDetail.ammount = Number(this.reasonsForm.get('ammount').value);
      this.otherDetail.remark = this.reasonsForm.get('remark').value;

      if (this.hasOpinion) {
        this.otherDetail.isSameGrantee = this.granteeChecked;
        this.otherDetail.isSameNotary = this.notaryCheked;
      } else {
        this.otherDetail.isSameGrantee = false;
        this.otherDetail.isSameNotary = false;
      }

      console.log(this.otherDetail,"hello")
      this.refundService.checkRefundIdExist(parseInt(localStorage.getItem('refundId'))).subscribe(data => {

        const isExist = data.data;

        if (isExist) {
          this.refundService.updateOtherDetails(this.refundId, this.otherDetail).subscribe(data => {
            console.log(data.data);
          });
        } else {
          this.refundService.saveOtherDetails(this.otherDetail).subscribe(data => {
            console.log(data);
            let otherIds = (data as any).data;


            let refundGrantor = otherIds.id;
            let refundNotaryId = otherIds.refundOpinionNotaryDetails.id;
            let refundReasonId = otherIds.refundReason.id;
            let refundRequestId = parseInt(localStorage.getItem('refundId'));

            localStorage.setItem('rGrantorId', refundGrantor);
            localStorage.setItem('rNotaryId', refundNotaryId);
            localStorage.setItem('rReasonId', refundReasonId);


            this.common.refundGrantorId = refundGrantor;
            this.common.refundNotaryId = refundNotaryId;
            this.common.refundReasonId = refundReasonId;


            this.refundService.updateRefundTableById(refundRequestId, this.common).subscribe(data => {
            });

          });
        }

      });


      this.redirectComponent();
      // this.notifier.notify('success', 'Documents are submitted');


    } 
    /*
    else {
      this.notifier.notify('error', 'forms are not valid');
    }
    */
  }

  findRefundReasons() {
    this.refundService.getAllRefundReason().subscribe(data => {
      this.getRefundReasons = data.data;
      console.log(this.getRefundReasons);
    });
  }

  activateOtherReason(event) {
    if (event == Constant.OTHER_REFUND_REASON_CODE) {
      this.otherReasonActivate = false;
    } else {
      this.otherReasonActivate = true;
      this.reasonsForm.controls['other_reason_for_refund'].setValue('');
    }

  }

  public redirectComponent(): void {
    this.onPageCountChanged.emit(3);
  }

  public redirectPreviousComponent(): void {
    this.onPageCountChanged.emit(1);
  }

  public redirectDashboard(): void {
    this.router.navigate(['/dashboard'])
  }
}
