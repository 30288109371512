import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-opinion-registration',
  templateUrl: './opinion-registration.component.html',
  styleUrls: ['./opinion-registration.component.scss']
})
export class OpinionRegistrationComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  navigateToPrevious() {
    this.router.navigate(['/reports/stamp-duty-opinion-reports']);
  }

}
