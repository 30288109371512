import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { CalculationDataProcess } from "src/app/service/calculationDataProcess/calculation-data-process.service";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { CALCULATION_HEADINGS } from "../../constant-headings/heading";
import * as moment from "moment";
import Swal from "sweetalert2";
import { Constant } from "src/app/util/constant";
import { ActivatedRoute } from "@angular/router";
import { CalculationService } from "src/app/service/calculation/calculation.service";
declare var $: any;

@Component({
  selector: "exchange-land-building",
  templateUrl: "./exchange-land-building.html",
  styleUrls: ["./exchange-land-building.scss"]
})
export class ExchangeLandBuildingComponent implements OnInit {
  @Output() emitData: EventEmitter<any> = new EventEmitter<any>();

  @Output() enableTab: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  state;

  @Input()
  updateData;

  @Input()
  selectedDataRow;

  landBuildingForm: FormGroup;

  maxDate: any;

  valueOfTheLand: FormArray;
  improvements: FormArray;
  valueOfTheBuildings: FormArray;

  improvementTotal: number = 0;
  valueOfTheLandTotal: number = 0;
  valueOfTheBuildingTotal: number = 0;

  previousValues:any[] = [];
  totalRecords:number;
  page=1;

  _CALCULATION_HEADINGS = CALCULATION_HEADINGS;

  constructor(
    public formBuilder: FormBuilder,
    private dataProcess: CalculationDataProcess,
    private activatedRoute: ActivatedRoute,
    public apiService: CalculationService,
  ) {}

  ngOnInit() {
    this.landBuildingForm = this.formBuilder.group({
      valueOfTheLand: this.formBuilder.array([this.createValueOfTheLandItem()]),
      valueOfTheBuildings: this.formBuilder.array([
        this.createValueOfTheBuildingsItem()
      ]),
      improvements: this.formBuilder.array([this.createImprovemntItem()]),
      valueOfTheLandRemark: null,
      valueOfTheBuildingsRemark: null,
      total: null,
      deductionPrecentage: null,
      depreciation: null,
      depreciationContruction: null,
      valueOfTheProperty: [null, Validators.required],
      marketValue: [null, Validators.required],
      previousDeedValue: null,
      headingDate: null
    });

    if (this.updateData != null) {
      this.setData(this.updateData);
    }

    this.getPreviousMarketValue();
    this.maxDate = new Date();
  }

  getPreviousMarketValue(){
    let refNo;
    this.activatedRoute.params.subscribe(params => {
      refNo = params["id"];
    });
    console.log(refNo)
    this.apiService.getPreviousMarket(refNo,Constant.CALCULATION_LAND_BUILDING).subscribe(data=>{
      if(data.data !== null){
        this.previousValues.push(data.data);
      }      
      this.totalRecords=this.previousValues.length;
    })
  }

  viewOpinionDetails(valuationId:any):void{
    window.open('view-internal-user-application/' + valuationId)
  }

  setData(data) {
    for (let i = 0; i < data.valueOfTheLand.length; i++) {
      if (i > 0) {
        this.valueOfTheLand = this.landBuildingForm.get(
          "valueOfTheLand"
        ) as FormArray;
        this.valueOfTheLand.push(this.createValueOfTheLandItem());
      }

      const formLand = (<FormArray>(
        this.landBuildingForm.get("valueOfTheLand")
      )).at(i);
      formLand.patchValue({
        value: data.valueOfTheLand[i].value,
        landExtend: data.valueOfTheLand[i].landExtend,
        perPerchValue: data.valueOfTheLand[i].perPerchValue
      });
    }

    for (let i = 0; i < data.valueOfTheBuilding.length; i++) {
      if (i > 0) {
        this.valueOfTheBuildings = this.landBuildingForm.get(
          "valueOfTheBuildings"
        ) as FormArray;
        this.valueOfTheBuildings.push(this.createValueOfTheBuildingsItem());
      }
      const valueOfTheBuilding = (<FormArray>(
        this.landBuildingForm.get("valueOfTheBuildings")
      )).at(i);
      valueOfTheBuilding.patchValue({
        constructionCost: data.valueOfTheBuilding[i].constructionCost.toFixed(2),
        contractionCost: data.valueOfTheBuilding[i].contractionCost.toFixed(2),
        extent: data.valueOfTheBuilding[i].extent,
        floorNo: data.valueOfTheBuilding[i].floorNo,
        buildingNo: data.valueOfTheBuilding[i].buildingNo
      });
    }

    for (let i = 0; i < data.improvements.length; i++) {
      if (i > 0) {
        this.improvements = this.landBuildingForm.get(
          "improvements"
        ) as FormArray;
        this.improvements.push(this.createImprovemntItem());
      }
      const formImprovements = (<FormArray>(
        this.landBuildingForm.get("improvements")
      )).at(i);
      formImprovements.patchValue({
        description: data.improvements[i].description,
        extent: data.improvements[i].extent,
        rentPerSqFt: data.improvements[i].rentPerSqFt,
        totValue: data.improvements[i].extent * data.improvements[i].rentPerSqFt
      });
    }
    this.landBuildingForm
      .get("deductionPrecentage")
      .setValue(data.deductionPrecentage);

      if(data.previousDeedValue !== undefined && data.previousDeedValue !== "" && data.previousDeedValue !== null){
        this.landBuildingForm
        .get("previousDeedValue")
        .setValue((data.previousDeedValue).toFixed(2));
      }


    this.landBuildingForm
      .get("headingDate")
      .setValue(moment(data.headingDate).format("YYYY-MM-DD"));

    this.landBuildingForm.get("valueOfTheLandRemark").setValue(data.landRemark);
    this.landBuildingForm.get("valueOfTheBuildingsRemark").setValue(data.buildingRemark);

    this.calculateData();
  }

  get _landBuildingForm() {
    return this.landBuildingForm.controls;
  }

  isValidField(formControl) {
    return (
      (this._landBuildingForm[formControl].touched ||
        this._landBuildingForm[formControl].dirty) &&
      this._landBuildingForm[formControl].errors
    );
  }

  createValueOfTheLandItem(): FormGroup {
    return this.formBuilder.group({
      id: null,
      landExtend: null,
      perPerchValue: null,
      value: null
    });
  }

  createImprovemntItem(): FormGroup {
    return this.formBuilder.group({
      id: null,
      description: null,
      extent: null,
      rentPerSqFt: null,
      totValue: null
    });
  }

  createValueOfTheBuildingsItem(): FormGroup {
    return this.formBuilder.group({
      id: null,
      buildingNo: null,
      floorNo: null,
      extent: null,
      constructionCost: null,
      contractionCost: null
    });
  }

  addItem(type): void {
    if (type == "vl") {
      this.valueOfTheLand = this.landBuildingForm.get(
        "valueOfTheLand"
      ) as FormArray;
      this.valueOfTheLand.push(this.createValueOfTheLandItem());
    }
    if (type == "im") {
      this.improvements = this.landBuildingForm.get(
        "improvements"
      ) as FormArray;
      this.improvements.push(this.createImprovemntItem());
    }
    if (type == "vb") {
      this.valueOfTheBuildings = this.landBuildingForm.get(
        "valueOfTheBuildings"
      ) as FormArray;
      this.valueOfTheBuildings.push(this.createValueOfTheBuildingsItem());
    }
  }

  getValueOfTheBuilding(): FormArray {
    return this.landBuildingForm.get("valueOfTheBuildings") as FormArray;
  }

  getValueOfTheLand(): FormArray {
    return this.landBuildingForm.get("valueOfTheLand") as FormArray;
  }

  getImprovement(): FormArray {
    return this.landBuildingForm.get("improvements") as FormArray;
  }

  removeRow(ti, type) {
    if (type == "vl") {
      this.getValueOfTheLand().removeAt(ti);
    }
    if (type == "im") {
      this.getImprovement().removeAt(ti);
    }
    if (type == "vb") {
      this.getValueOfTheBuilding().removeAt(ti);
    }

    this.calculateData();
    this.updateValidations();
  }

  calculateData() {
    this.valueOfTheLandTotal = 0;
    this.improvementTotal = 0;
    this.valueOfTheBuildingTotal = 0;

    for (let imp of this.landBuildingForm.get("valueOfTheBuildings").value) {
      this.valueOfTheBuildingTotal =
        this.valueOfTheBuildingTotal + imp.extent * imp.constructionCost;
    }

    for (let imp of this.landBuildingForm.get("valueOfTheLand").value) {
      this.valueOfTheLandTotal =
        this.valueOfTheLandTotal + imp.landExtend * imp.perPerchValue;
    }
    this.landBuildingForm
      .get("total")
      .setValue(this.improvementTotal.toFixed(2));

    for (let imp of this.landBuildingForm.get("improvements").value) {
      // this.improvementTotal =
      //   this.improvementTotal + imp.extent * imp.rentPerSqFt;
      this.improvementTotal = this.improvementTotal + imp.totValue;
    }
    this.landBuildingForm
      .get("total")
      .setValue(this.improvementTotal.toFixed(2));
    let depreciationConstruction = 0;
    if (this.valueOfTheBuildingTotal > 0) {
      let depreciation =
        (this.valueOfTheBuildingTotal / 100) *
        this.landBuildingForm.get("deductionPrecentage").value;

      this.landBuildingForm
        .get("depreciation")
        .setValue(depreciation.toFixed(2));

      depreciationConstruction =
        this.valueOfTheBuildingTotal -
        this.landBuildingForm.get("depreciation").value;

      this.landBuildingForm
        .get("depreciationContruction")
        .setValue(depreciationConstruction.toFixed(2));
    } else {
      this.landBuildingForm.get("deductionPrecentage").setValue(0);
      this.landBuildingForm.get("depreciation").setValue(0);
      this.landBuildingForm.get("depreciationContruction").setValue(0);
    }

    let valueOfTheProperty =
      this.valueOfTheLandTotal +
      this.improvementTotal +
      depreciationConstruction;

    this.landBuildingForm
      .get("valueOfTheProperty")
      .setValue(valueOfTheProperty.toFixed(2));

    this.landBuildingForm
      .get("marketValue")
      .setValue(valueOfTheProperty.toFixed(2));

    // let valueOfTheProperty = this.improvementTotal + this.valueOfTheLandTotal;
    // this.landBuildingForm
    //   .get("valueOfTheProperty")
    //   .setValue(valueOfTheProperty.toFixed(2));
  }

  calculateImprovementTableValue(index, type) {
    const formCon = (<FormArray>this.landBuildingForm.get("improvements")).at(
      index
    );
    // let currentVal = !formCon.value.totValue;
    // formCon.patchValue({
    //   totValue: (formCon.value.extent * formCon.value.rentPerSqFt).toFixed(2)
    // });

    if (type != "totValue") {
      let currentVal = !formCon.value.totValue;
      formCon.patchValue({
        totValue: formCon.value.extent * formCon.value.rentPerSqFt
      });
    } else {
      formCon.patchValue({
        totValue: formCon.value.totValue
      });
    }
    this.calculateData();
  }

  calculateValuationOfTheland(index, type) {
    const formLand = (<FormArray>(
      this.landBuildingForm.get("valueOfTheLand")
    )).at(index);
    formLand.patchValue({
      value: (formLand.value.landExtend * formLand.value.perPerchValue).toFixed(
        2
      )
    });
    this.calculateData();
  }

  calculateValuationOfTheBuilding(index) {
    const formBuilding = (<FormArray>(
      this.landBuildingForm.get("valueOfTheBuildings")
    )).at(index);
    formBuilding.patchValue({
      contractionCost: (
        formBuilding.value.extent * formBuilding.value.constructionCost
      ).toFixed(2)
    });
    this.calculateData();
    this.updateValidations();
  }

  updateValidations() {
    const deductionPrecentage = this.landBuildingForm.get(
      "deductionPrecentage"
    );
    if (this.valueOfTheBuildingTotal > 0) {
      deductionPrecentage.setValidators([Validators.required]);
      deductionPrecentage.updateValueAndValidity();
    } else {
      deductionPrecentage.setValidators(null);
      deductionPrecentage.updateValueAndValidity();
    }
  }

  emitValueOfTheProperty(state) {
    let data = null;
    if (this.landBuildingForm.get("headingDate").value == null || this.landBuildingForm.get("headingDate").value == "" || this.landBuildingForm.get("headingDate").value == undefined) {
      Swal.fire("Error", "Please select  heading date ", "error");
      data = {
        dateSelected: false,
        state: this.state
      };
    } else {
      if (state == this.state) {
        let data = {
          proceedData: this.processDataToSave(),
          valueOfTheProperty: this.landBuildingForm.get("valueOfTheProperty")
            .value
        };
        this.emitData.next(data);
      }
    }
    this.enableTab.emit(data);
  }

  processDataToSave() {
    return this.dataProcess.processExchangeLandBuildingData(
      this.landBuildingForm
    );
  }
}
