import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ProvinceService } from '../../../../../../service/province/province.service';
import { UpdateProvinceComponent } from '../update-province/update-province.component';
import { DeleteProvinceComponent } from '../delete-province/delete-province.component';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';
import { DataService } from 'src/app/service/data.service';
import {IProvince} from '../../../../../../model/interfaces/province';



@Component({
  selector: 'app-view-province',
  templateUrl: './view-province.component.html',
  styleUrls: ['./view-province.component.scss']
})
export class ViewProvinceComponent implements OnInit, OnDestroy {


  private sub = new SubSink();
  pageNo: number;

  // pagination
  selectedPageNo: number;
  numOfPages: number;
  limit: number;
  pageButtons = [];

  @ViewChild(UpdateProvinceComponent, { static: false })
  private updateProvinceComponent: UpdateProvinceComponent;
  @ViewChild(DeleteProvinceComponent, { static: false })
  private deleteProvinceComponent: DeleteProvinceComponent;

  province: IProvince [];
  currentPage: any;
  totalItems: any;


  constructor(private provinceService: ProvinceService, private notifier: NotifierService, private dataService: DataService) { }

  ngOnInit() {
    this.selectedPageNo = 0;
    this.limit = 10;

    this.provinceService.getAll().subscribe(
      response => {
        var res = response.data.length;
        this.pageNo = Math.round((res / 10)+1);

      })

    this.sub.add(this.dataService.UpdateDataTableProvince.subscribe(update => this.onAdded(update)));
    this.sub.add(this.provinceService.getProvinces(0, 10)
      .subscribe(
        (res) => {
          this.province = (res as any).data.content;
          this.currentPage = (res as any).data.number+1;
          this.totalItems = (res as any).data.totalElements;;
        }
      ))
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  triggerUpdate(id: number, code: string, description: string) {
    this.updateProvinceComponent.setIdAndDescription(id, code, description);
  }

  triggerDelete(id: number) {
    this.deleteProvinceComponent.setProvinceId(id);
  }

  onUpdated(updatedSuccessfully: boolean) {
    if (updatedSuccessfully) {
      this.updateTheTable();
    }
  }

  onDeleted(deletedSuccessfully: boolean) {
    if (deletedSuccessfully) {
      this.updateTheTable();
    }
  }
  onAdded(addedSuccessfully: boolean) {
    if (addedSuccessfully) {
      this.updateTheTable();
    }
  }
  pageable(i: number) {
    console.log("i" + i)
    console.log(i * 10 + " - " + ((i * 10) + 10));
    this.provinceService.getProvinces((i-1),10).subscribe(responce => {
      this.province = (responce as any).data.content;
      this.currentPage = (responce as any).data.number+1;
      this.totalItems = (responce as any).data.totalElements;
    });
  }
  counter(i: number) {
    return new Array(i);
  }
  private updateTheTable() {
    this.sub.add(this.provinceService.getProvinces(0, 10).subscribe(
      (res) => {
        this.province = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
      }));
  }

  searchProvince(keyword: string) {
    this.selectedPageNo = 0;
    this.sub.add(this.provinceService.searchProvince(keyword, this.selectedPageNo, this.limit)
      .subscribe((res) => {
        this.province = (res as any).data.content;
        this.currentPage = (res as any).data.number+1;
        this.totalItems = (res as any).data.totalElements;
        // this.province = res.data.content;
        // this.paginationButtonCounter(res.data.totalPages);
        // this.numOfPages = res.data.totalPages;
      }));
  }

  paginationButtonCounter(numOfPages: number) {
    this.pageButtons = new Array(numOfPages);
  }

}
