import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import Swal from 'sweetalert2';
import Cropper from 'cropperjs';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-file-upload1',
  templateUrl: './file-upload1.component.html',
  styleUrls: ['./file-upload1.component.scss']
})

export class FileUpload1Component implements OnInit {

  selectedElement: SafeResourceUrl;
  pdfsrc: any;

  constructor(private sanitizer: DomSanitizer ) {
  }

  @Output()
  outPutImages1 = new EventEmitter<File[]>();
  filesList1: File[] = [];
  images1 = [];
  originalImages1: any[] = [];
  names1: string[] = [];
  selectedIndex = 0;
  files1;
  showEdit = false;
  showEditPdf= false;

  public progress = 0;

  cropper;
  imgid=uuidv4();
  ngOnInit() {

  }

  uploadChange1(event) {
    this.files1 = event.target.files;
    this.progress = 0;
    for (let i = 0; i < this.files1.length; i++) {
      const file = this.files1[i];
      if (file.type==="application/pdf"||file.type==="image/png"||file.type==="image/jpeg"){
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event: any) => {
        this.progress = 100;
        this.images1.push(_event.currentTarget.result);
        this.originalImages1.push(_event.currentTarget.result);
        this.filesList1.push(file);
      };
      this.names1.push(file.name);
      this.progress = 0;
    }else {

      Swal.fire({
        text: 'File type not allowed here!',
        icon: 'warning',
        showCancelButton: false,

      });
      event.srcElement.value = null;
    }
    }
  }

  changeImg(event) {

    if (this.cropper !== undefined) {
      this.cropper.destroy();
    }
    const image = document.getElementById(this.imgid) as HTMLImageElement;
    image.src = event.target.src;
    this.cropper = new Cropper(image, {
      crop(event) {
      },
    });
  }

  outPutImagesSet() {
    this.outPutImages1.emit(this.filesList1);
  }

  edit(event, index) {
    // this.selectedElement=this.sanitizer.bypassSecurityTrustResourceUrl(this.images1[index]);
    if (this.filesList1[index].type==="application/pdf"){
      this.showEdit = false;
      this.showEditPdf = true;
      this.pdfsrc=null;
      this.pdfsrc=this.images1[index];
      // document.getElementById('myiframedeed').setAttribute('src',this.images1[index])
    }else {
      this.showEdit = true;
      this.showEditPdf = false;
      const image = document.getElementById(this.imgid) as HTMLImageElement;
      image.src = event.target.parentElement.parentElement.children[0].src;
      if (this.cropper !== undefined) {
        this.cropper.destroy();
      }
      this.cropper = new Cropper(image, {
        rotatable: true,
        crop(event) {

        },
      });
    }

    this.selectedIndex = index;
  }

  delete(index) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.images1.splice(index, 1);
        this.originalImages1.splice(index, 1);
        this.names1.splice(index, 1);
        this.filesList1.splice(index, 1);
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );

      }
    });

    // this.images.splice(index, 1);
    // this.originalImages.splice(index, 1);
    // this.names.splice(index, 1);
    // this.filesList.splice(index, 1);


  }

  closeEditor() {
    this.showEdit = false;
    this.showEditPdf = false;
  }

  rotate(angle: number) {
    this.cropper.move(1, -1).rotate(angle);
  }

  resetImage() {
    this.cropper.destroy();
    this.images1[this.selectedIndex] = this.originalImages1[this.selectedIndex];
    const image = document.getElementById(this.imgid) as HTMLImageElement;
    image.src = this.images1[this.selectedIndex];
    this.cropper = new Cropper(image, {
      rotatable: true,
      crop(event) {
      },
    });
  }

  crop() {
    this.images1[this.selectedIndex] = this.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    this.cropper.destroy();
    this.images1[this.selectedIndex] = this.images1[this.selectedIndex];
    const image = document.getElementById(this.imgid) as HTMLImageElement;
    image.src = this.images1[this.selectedIndex];
    this.cropper = new Cropper(image, {
      rotatable: true,
      crop(event) {
      },
    });
  }

}
