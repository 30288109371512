import { InspectionCondominium } from './inspection-condominium';
import { InspectionLandBuilding } from './inspection-land-building';
import { InspectionLand } from './inspection-land';
import {InspectionLandBuildingMain} from './inspection-building-main';

export class InspectionRecreation {
  public isLand: boolean;
  public isCondominium: boolean;
  public isLandBuilding: boolean;

  public inspectionLandDto: InspectionLand;
  public inspectionCondominiumDto: InspectionCondominium;
  public inspectionLandBuildingDto: InspectionLandBuildingMain;
  public inspectionLandBuildingDto_landDto: InspectionLand;
  public parentId:number;


  constructor(
    isLand: boolean,
    isCondominium: boolean,
    isLandBuilding: boolean,
    inspectionLandDto: InspectionLand,
    inspectionCondominiumDto: InspectionCondominium,
    inspectionLandBuildingDto: InspectionLandBuildingMain,
    inspectionLandBuildingDto_landDto: InspectionLand,
    parentId:number,
  ) {
    this.isLand = isLand;
    this.isCondominium = isCondominium;
    this.isLandBuilding = isLandBuilding;
    this.inspectionLandDto = inspectionLandDto;
    this.inspectionCondominiumDto = inspectionCondominiumDto;
    this.inspectionLandBuildingDto = inspectionLandBuildingDto;
    this.inspectionLandBuildingDto_landDto = inspectionLandBuildingDto_landDto;
    this.parentId = parentId;
  }
}
