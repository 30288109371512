import { Component, Input, OnInit } from '@angular/core';
import { PDFProgressData } from 'ng2-pdf-viewer';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';

import { ValuationRequest } from 'src/app/model/valuation-request';
import { FileService } from 'src/app/service/file/file.service';

@Component({
  selector: 'app-valuation-request-history',
  templateUrl: './valuation-request-history.component.html',
  styleUrls: ['./valuation-request-history.component.scss']
})
export class ValuationRequestHistoryComponent implements OnInit {

  dataDone = false;
  noDocs = false;
  previewFileData: any;
  image: any;
  noResults:boolean;

  // pagination
  totalRecords:number;
  page:number =1;

  @Input()
  valuationRequest: ValuationRequest;

  constructor(
    private spinner: NgxSpinnerService,
    private fileService: FileService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.historyList();
  }

  historyList(){
    this.totalRecords = this.valuationRequest.historyList.length;
    if(this.valuationRequest.historyList.length === 0){
      this.noResults = true;
    }
    else{
      this.noResults = false;
    }
  }

  // RETRIEVE DOCUMENTS - START
  onProgress(progressData: PDFProgressData) {
    if (progressData.loaded == progressData.total) {
      this.spinner.hide();
    }
  }

  previewFile(historyId, title) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadDocumentsByHistoryId(historyId, title).subscribe(result => {
      if (result) {
        this.image = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(() => {
          this.spinner.hide();
        }, 2000);

      } else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }
  // RETRIEVE DOCUMENTS - END

}
