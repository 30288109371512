import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubSink} from 'subsink';
import {OfficeService} from '../../../../../../service/office/office.service';
import {NotifierService} from 'angular-notifier';
import {Office} from '../../../../../../model/office';
import {Bank} from '../../../../../../model/bank';
import {BankService} from '../../../../../../service/bank/bank.service';

@Component({
  selector: 'app-update-bank',
  templateUrl: './update-bank.component.html',
  styleUrls: ['./update-bank.component.scss']
})
export class UpdateBankComponent implements OnInit, OnDestroy {


  updateBankForm: FormGroup;
  private sub = new SubSink();
  bank: string;
  code: string;

  @Output() updateBank = new EventEmitter<boolean>();


  constructor(private formBuilder: FormBuilder, private bankService: BankService, private notifier: NotifierService) {
  }

  get f() {
    return this.updateBankForm.controls;
  }

  ngOnInit() {

    this.updateBankForm = this.formBuilder.group({
      code: ['', [Validators.required]],
      bank: ['', [Validators.required]]
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getId() {
    return this.updateBankForm.get('code').value;
  }

  setIdAndDescription(code: number, description: string) {
    this.updateBankForm.get('code').setValue(code);
    this.updateBankForm.get('bank').setValue(description);
  }

  getDescription() {
    return this.updateBankForm.get('bank').value;
  }

  getCode() {
    return this.updateBankForm.get('code').value;
  }

  updateTheView(updated: boolean) {
    this.updateBank.emit(updated);
  }

  updateBankBtn() {

    this.bankService.getAll().subscribe(
      response => {
        const totalItems = response.data.length;
        this.bankService.getBanks(0,totalItems).subscribe(res=>{
          const bankArray = (res as any).data.content;
          if (bankArray.map((banks)=>String(banks.bankName.replace(/\s/g, ""))).includes(String(this.updateBankForm.get('bank').value.replace(/\s/g, "")))){
            this.notifier.notify('error', 'Bank name "'+this.updateBankForm.get('bank').value +'" is exist');
            return;
          }else{
            if (this.updateBankForm.valid) {
              const bank = new Bank();
              bank.bankName = this.getDescription();
              bank.id = this.getCode();
        
              this.sub.add(this.bankService.updateBank(this.getId(), bank)
                .subscribe(response => {
        
                  this.updateTheView(true);
                  this.notifier.notify('success', (response as any).message);
                }));
            }else{
              this.updateBankForm.markAllAsTouched();
            }
          }})
      });
  }


}
