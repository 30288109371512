import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PaymentMethodService } from 'src/app/service/payment-method/payment-method.service';
import { PaymentMethod } from 'src/app/model/payment-method';
import { SubSink } from 'subsink';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-update-payment-method',
  templateUrl: './update-payment-method.component.html',
  styleUrls: ['./update-payment-method.component.scss']
})
export class UpdatePaymentMethodComponent implements OnInit, OnDestroy {

  updatePaymentMethodForm: FormGroup;

  description: string;
  code: string;
  private sub = new SubSink();
  @Output() paymentMethodUpdated = new EventEmitter<boolean>();


  constructor(private formBuilder: FormBuilder, private paymentMethodService: PaymentMethodService, private notifier: NotifierService) { }

  ngOnInit() {

    this.updatePaymentMethodForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      code: ['', [Validators.required]],
      paymentMethod: ['', [Validators.required]]
    })
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  getId() {
    return this.updatePaymentMethodForm.get('id').value;
  }
  setIdAndDescription(id: number, code: string, description: string) {
    this.updatePaymentMethodForm.get('id').setValue(id);
    this.updatePaymentMethodForm.get('code').setValue(code);
    this.updatePaymentMethodForm.get('paymentMethod').setValue(description);
  }

  getDescription() {
    return this.updatePaymentMethodForm.get('paymentMethod').value;
  }
  getCode() {
    return this.updatePaymentMethodForm.get('code').value;
  }

  updateTheView(updated: boolean) {
    this.paymentMethodUpdated.emit(updated);
  }

  updatePaymentMethod() {
    if (this.updatePaymentMethodForm.valid) {
      const paymentMethod = new PaymentMethod();
      paymentMethod.paymentMethod = this.getDescription();
      paymentMethod.code = this.getCode();
      this.sub.add(this.paymentMethodService.updatePaymentMethod(this.getId(), paymentMethod)
        .subscribe(response => {

          this.updateTheView(true);
          this.notifier.notify('success', (response as any).message)
        }));
    }
  }

}








