import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import Swal from 'sweetalert2';
import Cropper from 'cropperjs';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-file-upload-granter',
  templateUrl: './file-upload-granter.component.html',
  styleUrls: ['./file-upload-granter.component.scss']
})

export class FileUploadGranterComponent implements OnInit {

  selectedElement: SafeResourceUrl;
  pdfsrc: null;

  constructor(private sanitizer: DomSanitizer) {
  }

  @Input()
  updatedPaths = [];

  @Output()
  outPutImagesGranter = new EventEmitter<File[]>();
  filesListGranter: File[] = [];
  imagesGranter = [];
  originalImages6: any[] = [];
  namesGranter: string[] = [];
  selectedIndex = 0;
  filesGranter;
  showEdit = false;
  showEditPdf = false;

  public progress = 0;

  cropper;

  ngOnInit() {

    if (this.updatedPaths !== null || this.updatedPaths.length > 0) {
      this.uploadChangeGranterExternal(this.updatedPaths);
    }

  }

  uploadChangeGranter(event) {
    this.filesGranter = event.target.files;
    this.progress = 0;
    for (let i = 0; i < this.filesGranter.length; i++) {
      const file = this.filesGranter[i];
      if (file.type === "application/pdf" || file.type === "image/png" || file.type === "image/jpeg") {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          this.progress = 100;
          this.imagesGranter.push(_event.currentTarget.result);
          this.originalImages6.push(_event.currentTarget.result);
          this.filesListGranter.push(file);
        };


        this.namesGranter.push(file.name);
        this.progress = 0;
      } else {

        Swal.fire({
          text: 'File type not allowed here!',
          icon: 'warning',
          showCancelButton: false,

        });
        event.srcElement.value = null;
      }
    }

  }

  uploadChangeGranterExternal(files) {
    this.filesGranter = files;
    this.progress = 0;
    for (let i = 0; i < this.filesGranter.length; i++) {
      const file = this.filesGranter[i];
      if (file.type === "application/pdf" || file.type === "image/png" || file.type === "image/jpeg") {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event: any) => {
          this.progress = 100;
          this.imagesGranter.push(_event.currentTarget.result);
          this.originalImages6.push(_event.currentTarget.result);
          this.filesListGranter.push(file);
        };


        this.namesGranter.push(file.name);
        this.progress = 0;
      } else {

        Swal.fire({
          text: 'File type not allowed here!',
          icon: 'warning',
          showCancelButton: false,

        });

      }
    }

  }

  changeImg(event) {

    if (this.cropper !== undefined) {
      this.cropper.destroy();
    }
    const image = document.getElementById('imageGranter') as HTMLImageElement;
    image.src = event.target.src;
    this.cropper = new Cropper(image, {
      crop(event) {
      },
    });
  }

  outPutImagesSet() {
    this.outPutImagesGranter.emit(this.filesListGranter);
  }

  edit(event, index) {
    // this.selectedElement=this.sanitizer.bypassSecurityTrustResourceUrl(this.imagesGranter[index]);
    if (this.filesListGranter[index].type === "application/pdf") {
      this.showEdit = false;
      this.showEditPdf = true;
      this.pdfsrc = null;
      this.pdfsrc = this.imagesGranter[index];
      // document.getElementById('myiframe-granter').removeAttribute('src');
      // document.getElementById('myiframe-granter').setAttribute('src',this.imagesGranter[index])

    } else {
      this.showEdit = true;
      this.showEditPdf = false;
      const image = document.getElementById('imageGranter') as HTMLImageElement;
      image.src = event.target.parentElement.parentElement.children[0].src;
      if (this.cropper !== undefined) {
        this.cropper.destroy();
      }
      this.cropper = new Cropper(image, {
        rotatable: true,
        crop(event) {

        },
      });
    }

    this.selectedIndex = index;
  }

  delete(index) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#6086d6',
      cancelButtonColor: '#d66',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.imagesGranter.splice(index, 1);
        this.originalImages6.splice(index, 1);
        this.namesGranter.splice(index, 1);
        this.filesListGranter.splice(index, 1);
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );

      }
    });

    // this.images.splice(index, 1);
    // this.originalImages.splice(index, 1);
    // this.names.splice(index, 1);
    // this.filesList.splice(index, 1);


  }

  closeEditor() {
    this.showEdit = false;
    this.showEditPdf = false;
  }

  rotate(angle: number) {
    this.cropper.move(1, -1).rotate(angle);
  }

  resetImage() {
    this.cropper.destroy();
    this.imagesGranter[this.selectedIndex] = this.originalImages6[this.selectedIndex];
    const image = document.getElementById('imageGranter') as HTMLImageElement;
    image.src = this.imagesGranter[this.selectedIndex];
    this.cropper = new Cropper(image, {
      rotatable: true,
      crop(event) {
      },
    });
  }

  crop() {
    this.imagesGranter[this.selectedIndex] = this.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    this.cropper.destroy();
    this.imagesGranter[this.selectedIndex] = this.imagesGranter[this.selectedIndex];
    const image = document.getElementById('imageGranter') as HTMLImageElement;
    image.src = this.imagesGranter[this.selectedIndex];
    this.cropper = new Cropper(image, {
      rotatable: true,
      crop(event) {
      },
    });

  }

}
