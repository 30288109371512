import {Component, Input, OnInit} from '@angular/core';
import {IGranter} from '../../../../../../../model/interfaces/granter';
import {Granter} from '../../../../../../../model/granter';
import {ProvinceService} from '../../../../../../../service/province/province.service';
import {CompanyCategoryService} from '../../../../../../../service/company-category/company-category.service';
import {CompanyTypeService} from '../../../../../../../service/company-type/company-type.service';
import {Province} from '../../../../../../../model/province';
import {CompanyCategory} from '../../../../../../../model/companyCategory';
import {CompanyType} from '../../../../../../../model/companyType';
import {SubSink} from 'subsink';
import {FileService} from '../../../../../../../service/file/file.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {ValuationRequestService} from '../../../../../../../service/valuation-request/valuation-request.service';
import {ValuationRequest} from '../../../../../../../model/valuation-request';
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-view-grantor',
  templateUrl: './view-grantor.component.html',
  styleUrls: ['./view-grantor.component.scss']
})
export class ViewGrantorComponent implements OnInit {

  @Input()
  granters: Granter [];

  @Input()
  valuationRequestId: string;

  selectSideNav = 1;
  previewFileData: any;
  selectFileSm = 0;
  provincesorderd: Province[];
  companyCategories: CompanyCategory[];
  companyType: CompanyType[];
  valuationRequest: ValuationRequest;
  noDocs = false;
  dataDone = false;
  private sub = new SubSink();

  constructor(private provinceService: ProvinceService,
              private companyCategoryService: CompanyCategoryService,
              private companyTypeService: CompanyTypeService,
              private fileService: FileService,
              private valuationRequestService: ValuationRequestService,
              public sanitizer: DomSanitizer,
              private  router: Router,
              private spinner: NgxSpinnerService) { }

  ngOnInit() {
    console.log('Granter......... ' + JSON.stringify(this.granters));

    this.sub.add(this.companyCategoryService.getAll().subscribe(
      res => {
        this.companyCategories = (res as any).data;
      }
    ));

    this.sub.add(this.provinceService.getProvincesOrderd().subscribe(
      (res) => {
        this.provincesorderd = (res as any).data;
      }
    ));

    // get All company Type
    this.sub.add(this.companyTypeService.getAll().subscribe(
      res => {
        this.companyType = (res as any).data;
      }
    ));

  }

  previewFile(type) {
    this.spinner.show();
    this.dataDone = false;
    this.noDocs = false;
    this.fileService.loadFileById(this.valuationRequestId, type).subscribe(result => {
      if (result) {
        const temp = [];
        temp.push(result.data);
        this.previewFileData = result.data;
        this.noDocs = false;
        this.dataDone = true;

        setTimeout(()=>{
          this.spinner.hide();
        }, 2000);

      }else {
        this.spinner.hide();
        this.dataDone = true;
        this.noDocs = true;
      }

    }, () => {
      this.dataDone = true;
      this.noDocs = true;
      this.spinner.hide();
    });
  }

  imagePreview(index, type, name, base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:' + type + ';base64,' + base64);
  }

  // backToList() {
  //   this.sub.add(this.valuationRequestService.findByValuationRequestId(Number(this.valuationRequestId))
  //     .subscribe((res) => {
  //       this.valuationRequest = res.data;
  //
  //       console.log(this.valuationRequest.valuationRequestStatus + '................');
  //
  //       if (this.valuationRequest.valuationRequestStatus === 'PENDING') {
  //         this.router.navigate(['/view-assessment/pending-requests']);
  //       }
  //       if (this.valuationRequest.valuationRequestStatus === 'COMPLETED') {
  //         this.router.navigate(['/view-assessment/completed-requests']);
  //       }
  //       if (this.valuationRequest.valuationRequestStatus === 'REJECTED') {
  //         this.router.navigate(['/view-assessment/rejected-requests']);
  //       }
  //     }));
  // }
}
